import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CartItemCardAcc from '../../components/cartItemCardAcc/CartItemCardAcc';
import CheckoutCom from '../../components/checkoutCom/CheckoutCom';
import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import './paymentMethod.scss';
import cardAcceptedList from '../../assets/icon/cardAcceptedList.svg';
import ChildNameModal from '../../components/childNameModal/ChildNameModal';

const PaymentMethod = () => {
	const [defaultAdd, setDefaultAdd] = useState(true);

	const [defaultAddTwo, setDefaultAddTwo] = useState(false);
	const [defaultAddThree, setDefaultAddThree] = useState(false);
	const [childNameModal, setChildNameModal] = useState(true);

	const history = useNavigate();

	const closeChildNameBtn = () => {
		setChildNameModal(false);
	};
	const openChildNameBtn = () => {
		setChildNameModal(true);
	};

	const handleChange = () => {
		setDefaultAdd(!defaultAdd);
		if (defaultAdd === true) {
			setDefaultAddTwo(false);
			setDefaultAddThree(false);
		} else if (defaultAddTwo === true || defaultAddThree === true) {
			setDefaultAddTwo(false);
			setDefaultAddThree(false);
		}
	};

	const handleChangeTwo = () => {
		setDefaultAddTwo(!defaultAddTwo);
		if (defaultAddTwo === true) {
			setDefaultAdd(false);
			setDefaultAddThree(false);
		} else if (defaultAdd === true || defaultAddThree === true) {
			setDefaultAdd(false);
			setDefaultAddThree(false);
		}
	};

	const handleChangeThree = () => {
		setDefaultAddThree(!defaultAddThree);
		if (defaultAddThree === true) {
			setDefaultAdd(false);
			setDefaultAddTwo(false);
		} else if (defaultAdd === true || defaultAddTwo === true) {
			setDefaultAdd(false);
			setDefaultAddTwo(false);
		}
	};
	const onPlaceNow = () => {
		history.push('/order-success');
	};

	return (
		<div>
			<Navbar />
			<div className='paymentMet-add-fam'>
				{/* <CheckoutCom /> */}
				<div className='paymentMet-add-cont-main1'>
					<div className='paymentMet-chekoutText-cont-main'>
						<p>Checkout</p>
					</div>
					<div className='paymentMet-left-background'>
						<div className='paymentMet-left-meth-text'>
							<p>Payment method</p>
						</div>

						<div className='paymentMet-left-meth-line'></div>
						<div className='paymentMet-left-select-method'>
							<p>Select payment method</p>
						</div>

						<div className='paymentMet-left-methcards-fam'>
							<div
								className='paymentMet-methcard-card'
								style={{
									border:
										defaultAdd === true
											? '1px solid #0C8DBA'
											: '1px solid rgba(89, 95, 98, 0.1)',
									background:
										defaultAdd === true
											? 'rgba(12, 141, 186, 0.05) '
											: 'rgba(12, 141, 186, 0.02)',
								}}
							>
								<div className='paymentMet-checked-fam'>
									<div className='paymentMet-left-checked'>
										<input
											type='radio'
											checked={defaultAdd === true}
											onChange={handleChange}
										/>
									</div>
									<div className='paymentMet-checked-card-text'>
										<p>Pay Now</p>
									</div>
								</div>
								<div className='paymentMet-checked-fam-cardss'>
									<img src={cardAcceptedList} />
								</div>
							</div>

							<div
								className='paymentMet-methcard-card'
								style={{
									border:
										defaultAddTwo === true
											? '1px solid #0C8DBA'
											: '1px solid rgba(89, 95, 98, 0.1)',
									background:
										defaultAddTwo === true
											? 'rgba(12, 141, 186, 0.05) '
											: 'rgba(12, 141, 186, 0.02)',
								}}
							>
								<div className='paymentMet-checked-fam'>
									<div className='paymentMet-left-checked'>
										<input
											type='radio'
											checked={defaultAddTwo === true}
											onChange={handleChangeTwo}
										/>
									</div>
									<div className='paymentMet-checked-card-text'>
										<p>Pay On Delivery</p>
									</div>
								</div>
								<div className='paymentMet-checked-fam-cardss'>
									{/* <img src={cardAcceptedList} /> */}

									<div className='paymentMet-checked-fam-textyy'>
										<p>Pay with cash or POS on delivery</p>
									</div>
								</div>
							</div>

							<div
								className='paymentMet-methcard-card'
								style={{
									border:
										defaultAddThree === true
											? '1px solid #0C8DBA'
											: '1px solid rgba(89, 95, 98, 0.1)',
									background:
										defaultAddThree === true
											? 'rgba(12, 141, 186, 0.05) '
											: 'rgba(12, 141, 186, 0.02)',
								}}
							>
								<div className='paymentMet-checked-fam'>
									<div className='paymentMet-left-checked'>
										<input
											type='radio'
											checked={defaultAddThree === true}
											onChange={handleChangeThree}
										/>
									</div>
									<div className='paymentMet-checked-card-text'>
										<p>Pay with Loyalty Point</p>
									</div>
								</div>
								<div className='paymentMet-checked-fam-cardss'>
									{/* <img src={cardAcceptedList} /> */}

									<div className='paymentMet-checked-fam-textyyloya'>
										<p>
											You have a balance of{' '}
											<strong>50034 bubble colony loyalty point. </strong>
										</p>
									</div>
								</div>
							</div>
						</div>
						<form className='paymentMet-checked-formFam'>
							{defaultAdd && (
								<div>
									<div className='paymentMet-checked-pppp'>Payment Detais</div>
									<div className='paymentMet-checked-cardname-formfgg'>
										<div className='paymentMet-checked-cardname-poo'>
											Name on card *
										</div>
										<div className='paymentMet-checked-cardname-input'>
											<input type='text' />
										</div>
									</div>

									<div className='paymentMet-checked-cardname-formfgg'>
										<div className='paymentMet-checked-cardname-poo'>
											Card Number *
										</div>
										<div className='paymentMet-checked-cardname-input'>
											<input type='number' />
										</div>
									</div>
									<div className='paymentMet-checked-cardcvv-flex'>
										<div className='paymentMet-checked-cardcvv-conrr'>
											<div className='paymentMet-checked-cardcvvText'>CVV</div>
											<div className='paymentMet-checked-cardcvv-inputu'>
												<input type='number' />
											</div>
										</div>

										<div className='paymentMet-checked-cardcvv-conrr'>
											<div className='paymentMet-checked-cardcvvText'>
												Expiry Date
											</div>
											<div className='paymentMet-checked-cardcvv-inputu'>
												<input type='number' />
											</div>
										</div>
									</div>
								</div>
							)}
							<Link to='/order-success' style={{ textDecoration: 'none' }}>
								<div
									// onClick={onPlaceNow}
									className='paymentMet-checked-cardcvv-submit'
								>
									Place Order
								</div>
							</Link>
						</form>
					</div>
				</div>

				<div className='paymentMet-add-cont-main2'>
					<div className='paymentMet-add-header-two'>
						<div className='paymentMet-add-header-step1'>
							<div className='paymentMet-step-1-cont'>1</div>{' '}
							<div className='paymentMet-step-1-text'>
								<h3>Delivery address</h3>
							</div>
						</div>
						<div className='paymentMet-step-dash'>-</div>
						<div className='paymentMet-add-header-step2'>
							<div className='paymentMet-step-2-cont'>2</div>
							<div className='paymentMet-step-2-text'>
								<h3>Payment Method</h3>
							</div>
						</div>
					</div>

					<CartItemCardAcc />
				</div>
				{childNameModal && (
					<ChildNameModal closeChildNameBtn={closeChildNameBtn} />
				)}
			</div>

			<Footer />
		</div>
	);
};

export default PaymentMethod;
