import React, { useState, useEffect, useRef } from 'react'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import Modal from '../../components/modal/Modal'
import SingleProductsCard from '../../components/singleProductsCard/SingleProductsCard'
import './style.scss'
import { axiosCalls } from '../../_api'
import { useParams } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import NewAdditions from '../../components/newAdditions/NewAdditions'

const SingleProductsPage = () => {
  const ref = useRef()
  const [auth, setAuth] = useState(false)
  const param = useParams()
  const [product, setProduct] = useState({})
  const [productImages, setProductImages] = useState([])
  const [activeProductImage, setActiveProductImage] = useState([])
  const [loading, setLoading] = useState(true)
  const [similarLoading, setSimilarLoading] = useState(true)
  const [SimilarProduct, setSimilarProduct] = useState([])

  useEffect(() => {
    // ref.current.scrollIntoView({ behavior: 'smooth' })

    const token = localStorage.getItem('token')

    if (token === '' || token == null) {
      setAuth(false)
    } else {
      // navigate("/cart-page");
      setAuth(true)
    }
  }, [])
  const getProduct = async (id) => {
    setLoading(true)

    const res = await axiosCalls(`product/${id}`, 'get')

    if (res) {
      if (res.er) {
        return
      }

      setProduct(res.data)

      let img =
        res?.data?.product.images.length === 0 ? [] : res?.data?.product.images

      setActiveProductImage(res?.data.product?.main_image)
      setProductImages([res?.data?.product.main_image, ...img])
      setLoading(false)
    }
  }
  useEffect(() => {
    getProduct(param.id)
  }, [])
  const getSimilarProduct = async () => {
    setSimilarLoading(true)
    const res = await axiosCalls(
      `parent-sub-category/${product.product.parent_sub_category.id}`,
      'get'
    )

    if (res) {
      if (res.er) {
        return
      }
      setSimilarProduct(
        res.data.data.filter((item) => item.id !== product.product.id)
      )
      setSimilarLoading(false)
    }
  }
  useEffect(() => {
    if (!loading) {
      getSimilarProduct()
    }
  }, [loading])
  return loading ? (
    <Loader direction={'center'} />
  ) : (
    <div ref={ref}>
      <Navbar />
      <div className='single-product-fam-links'>
        <div className='single-product-fam-link'>
          <p>Home{' >'}</p>
        </div>
        <div className='single-product-fam-link'>
          <p>
            {product.product.product_category.name}
            {' >'}
          </p>
        </div>
        <div className='single-product-fam-link'>
          <p>
            {product.product.parent_category.name}
            {' >'}
          </p>
        </div>
        <div className='single-product-fam-link'>
          <p>{product.product.parent_sub_category.name}</p>
        </div>
      </div>
      <SingleProductsCard
        auth={auth}
        product={product.product}
        activeProductImage={activeProductImage}
        setActiveProductImage={setActiveProductImage}
      />

      {/* {openLoginModal && <Modal closeLoginModal={closeLoginModal} />} */}
      <div className='similar-cover'>
        <NewAdditions
          headingTag='Similar products'
          data={SimilarProduct.slice(0, 4)}
          loading={similarLoading}
          extra={getProduct}
          link={`/product-category/${product.product.product_category.id}`}
        />
      </div>

      <Footer />
    </div>
  )
}

export default SingleProductsPage
