import React, { useState, useEffect, useContext } from 'react'
import { axiosCalls } from '../../_api'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import validator from 'validator'
import { showLoader, hideLoader } from '../../loader'
import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg'
import eye from '../../assets/icon/eye.svg'
import './style.scss'
import { Toast } from '../../helpers/toast'
import { useDispatch } from 'react-redux'
import { setUser, setAuthenticated } from '../../reducers/auth'
import capitalize from '../../helpers/capitalize'
import { CartContext } from '../../context/cart-context'

const Login = () => {
  const { getCart } = useContext(CartContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const locationValue = new URLSearchParams(location.search).get('location')

  const [auth, setAuth] = useState({
    email: '',
    password: '',
  })

  const [checked, setChecked] = useState(false)
  const [emailAd, setEmailAd] = useState('')
  const [emailError, setEmailError] = useState('')
  const [showPass, setShowPass] = useState(false)

  const passwordVisiblity = () => {
    setShowPass((showPass) => !showPass)
  }

  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Please Enter valid a Email!')
    }
  }

  const loginAuth = async (e) => {
    e.preventDefault()

    const data = {
      email: auth.email,
      password: auth.password,
    }

    showLoader()

    const res = await axiosCalls('login', 'POST', data)

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)
        return
      }

      localStorage.setItem('token', res.data.auth.token)
      dispatch(setUser(res.data.profile))
      dispatch(setAuthenticated(true))
      Toast('success', `Welcome ${capitalize(res.data.profile.first_name)}`)
      getCart()
      if (locationValue === 'cart') {
        navigate(-1)
      } else {
        navigate('/', { replace: true })
      }
    }

    // window.location.href = '/homepage';
  }

  const initiateGuestSession = async () => {
    const res = await axiosCalls(`guest-access-code`, 'get')

    if (res) {
      if (res.er) {
        return
      }

      localStorage.setItem('bubble_guest_code', res.data)
      navigate(-1)
    }
  }

  const handleCheckedChange = () => {
    setChecked(!checked)
  }

  return (
    <div className='login-page-container'>
      <div className='login-page-header'>
        <div className='login-page-logo'>
          <img
            src={bubbleColonyLogoHome}
            alt='home_logo'
            onClick={() => navigate('/')}
          />
        </div>

        {/* <Link to='/create-account' style={{ textDecoration: 'none' }}>
          <div className='create-account-btn'>Create account</div>{' '}
        </Link> */}
      </div>

      <div className='login-content'>
        <div className='login-form-container'>
          <h1>Login</h1>

          <form className='login-form' onSubmit={loginAuth}>
            <div className='form-group'>
              <label>Email</label>

              <input
                type='text'
                placeholder='Enter email address'
                name='uname'
                required
                onChange={(e) => {
                  setAuth({ ...auth, email: e.target.value })
                  validateEmail(e)
                  setEmailAd(e.target.value)
                }}
              />
            </div>

            {emailError && (
              <div
                style={{
                  fontSize: '10px',
                  marginTop: '5px',
                  marginLeft: '20px',
                  color: 'red',
                }}
              >
                {emailError}
              </div>
            )}

            <div className='form-group'>
              <label>Password</label>

              <div>
                <input
                  type={showPass ? 'text' : 'password'}
                  placeholder='Enter password'
                  name='pass'
                  required
                  onChange={(e) => {
                    setAuth({ ...auth, password: e.target.value })
                  }}
                />

                <span onClick={passwordVisiblity}>
                  {showPass ? (
                    <img className='password-icon' src={eye} alt='eye' />
                  ) : (
                    <img className='password-icon' src={eye} alt='eye' />
                  )}
                </span>
              </div>
            </div>

            <Link to='/forgot-password' style={{ textDecoration: 'none' }}>
              <div className='forgot-pass'>
                <p>Forgot Password?</p>
              </div>
            </Link>

            <div className='login-btn'>
              <input type='submit' value='Log In' />
            </div>
            <div className='info-new'>
              <p>
                Don't have an account?
                <Link to='/create-account' style={{ textDecoration: 'none' }}>
                  <span>Create one.</span>
                </Link>
              </p>
            </div>

            {/* <div className='newTo'>
							<p>New to Bubble colony?</p> <a href='/create-account'>Sign up</a>
						</div> */}
          </form>
        </div>

        {/* <div className='divider'></div> */}

        {/* <div className='guest-checkout'>
          <h1>Continue as a Guest</h1>

          <div className='guest-checkout-container'>
            <p>
              You can continue as a guest, you will just need to indicate the
              essential details to place your order.
            </p>

            <p>
              If You’d like you can register and save your details for future
              purposes at the end of the purchase process.
            </p>

            <div className='login-btn' onClick={initiateGuestSession}>
              <button>Continue as a Guest</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Login
