import React from "react";
import vendorOvalImg from "../../../../../../assets/icon/vendorOvalImg.png";
import "./singleVendorRight.scss";

const SingleVendorRightProduct = () => {
  return (
    <div className="singleVendorRightProduct-Gen">
      <div className="singleVendorRightProduct-GenTwo">
        <div className="singleVendorRightProduct-GenImg">
          <img src={vendorOvalImg} />
        </div>
        <div className="singleVendorRightProduct-PrName">
          The Product Name, shouldn't be long{" "}
        </div>
      </div>
      <div className="singleVendorRightProduct-PrPrice">₦15,000.00</div>
    </div>
  );
};

export default SingleVendorRightProduct;
