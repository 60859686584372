import React from "react";
import { GoCalendar } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
// import { Chart, Pallets, Table } from "./components";
import { Chart } from "../Chart";
import AdminArrowUp from "../../../../../assets/icon/AdminArrowUp.svg";
import "./conversionRate.scss";

const ConversionRate = () => {
  return (
    <div className="conversionRate-bodyGen">
      {/* <Pallets /> */}
      <div className="chartff">
        <div className="chartHeader conversionRate-AverageHeading">
          Average Order Value
          <select name="" id="">
            <GoCalendar />
            <option value="">This year</option>
          </select>
        </div>

        <div className="conversionRate-infoGen">
          <div className="conversionRate-price">₦40,000.00</div>
          <div className="conversionRate-percent">+ 13.6%</div>
          <div className="conversionRate-arrow">
            <img src={AdminArrowUp} />
          </div>
        </div>
        <div style={{ height: "80%" }}>
          <Chart />
        </div>
      </div>
    </div>
  );
};

export default ConversionRate;
