/* eslint-disable jsx-a11y/no-redundant-roles */
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import closeOrderModal from "../../../../../assets/icon/closeOrderModal.svg";

import orderAdminDots from "../../../../../assets/icon/orderAdminDots.svg";

import orderAdminProduct from "../../../../../assets/icon/orderAdminProduct.png";

import Modal from "react-modal";
// @ts-ignore
import ProductImage from "../../../../../assets/images/shopDenim.png";
// @ts-ignore
import styles from "./index.module.css";
import "./newProductModal.scss";

import productImgOne from "../../../../../assets/icon/productImgOne.png";

import productImgTwo from "../../../../../assets/icon/productImgTwo.png";
import productImgThree from "../../../../../assets/icon/productImgThree.png";
import productTrashIcon from "../../../../../assets/icon/productTrashIcon.svg";

import productUploadImg from "../../../../../assets/icon/productUploadImg.png";

import { useState } from "react";
import Toggle from "react-toggle";
import Switch from "react-switch";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPEG", "PNG", "GIF"];

Modal.setAppElement("#root");

function NewProductModal({
  showNewModal,

  handleToggleNewModal,
}) {
  const [showOpt, setShowOpt] = useState(false);
  const [checked, setChecked] = useState(false);

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };
  const toggleShowOpt = () => {
    setShowOpt(!showOpt);
  };

  const handleCheckedChange = () => {
    setChecked(!checked);
  };

  return (
    <Modal
      isOpen={showNewModal}
      onRequestClose={handleToggleNewModal}
      shouldCloseOnOverlayClick={true}
      className={`${styles.ordersModal}`}
      overlayClassName={`${styles.ordersModal__overlay}`}
    >
      {/* The Flow Class is in the root > index.css */}
      {/* Use --flow-spacer: 2rem..etc to overwrite it */}
      <div className="adminOrders-headingFam">
        <div className="adminOrders-headingNo">Product Details</div>
        <div
          className="adminOrders-headingClose"
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Product Name</div>
        <div className="newProductForm-NameInput">
          <input type="text" />
        </div>
        <div className="newProductForm-NameInputSub">
          Give your product a short name
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Brand</div>
        <div className="newProductForm-NameInput">
          <input type="text" />
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Product Description</div>
        <div className="newProductForm-NameInput">
          <input type="text" />
        </div>
        <div className="newProductForm-NameInputSub">
          Give your product a short and clear description
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Product Category</div>
        <div className="newProductForm-selectCate">
          <select>
            <option>Select category</option>
          </select>
        </div>
      </div>

      <div className="newProductForm-selectFlex">
        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Parent subcategory</div>
          <div className="newProductForm-PareselectCate">
            <select>
              <option>Select category</option>
            </select>
          </div>
        </div>

        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Child subcategory</div>
          <div className="newProductForm-ChilselectCate">
            <select>
              <option>Select category</option>
            </select>
          </div>
        </div>
      </div>

      <div className="newProductForm-selectFlex">
        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Retail Price</div>
          <div className="newProductForm-PareNairCate">
            <span className="newProductForm-nairaSign">₦</span>
            <input type="number" />
          </div>
        </div>

        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Markup percentage</div>
          <div className="newProductForm-ChilNairCate">
            <span className="newProductForm-nairaSign">₦</span>
            <input type="number" />
          </div>
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Price</div>
        <div className="newProductForm-ChilPriceCate">
          <span className="newProductForm-nairaSign">₦</span>
          <input type="number" />
        </div>
      </div>

      <div className="newProductForm-addDiscount">
        <div className="newProductForm-addDiscountText">
          Does this product have a discounted price? Add discount!
        </div>
        <div className="newProductForm-discountToggle">
          {" "}
          <Switch
            onChange={handleCheckedChange}
            checked={checked}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Vendor </div>
        <div className="newProductForm-selectCate">
          <select>
            <option>Select Vendor</option>
          </select>
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">Shop</div>
        <div className="newProductForm-selectCate">
          <select>
            <option>Select Shop</option>
          </select>
        </div>
      </div>

      <div className="newProductForm-NameGen">
        <div className="newProductForm-NameGenTag">
          Enter percentage discount
        </div>
        <div className="newProductForm-ChilPriceCate">
          <span className="newProductForm-nairaSign">%</span>
          <input type="number" />
        </div>
      </div>
      <div className="newProductForm-NameInputSub">
        The value of the percentage discount will be deducted from the the price
      </div>

      <div className="newProductForm-selectFlex">
        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Vendor</div>
          <div className="newProductForm-PareselectCate">
            <select>
              <option>E.g Summit Mobile</option>
            </select>
          </div>
        </div>

        <div className="newProductForm-NameGen">
          <div className="newProductForm-NameGenTag">Product Quanity</div>
          <div className="newProductForm-QuantNairCate">
            <input type="number" />
          </div>
        </div>
      </div>

      <div className="newProductForm-ProdImgText">Product Images</div>

      <div className="newProductForm-fileUploader">
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          label="Drop your images here, or click to browse 1600 x 1200 recommend, up to 10MB each."
        />
        <p className="newProductForm-fileUploadName">
          {file ? `File name: ${file[0].name}` : "no files uploaded yet"}
        </p>
      </div>
      <div className="newProductForm-fileUploadText">
        Add up to 8 images to your products. Used to represent your products
        during checkout, in email, social sharing and more.
      </div>

      <div className="newProductForm-fileFlexS">
        <div className="newProductForm-fileFlexI">Image</div>
        <div className="newProductForm-fileFlexM">Select Main Image</div>
      </div>
      <div className="newProductForm-fileImgListGen">
        <div className="newProductForm-fileImgListFam">
          <div className="newProductForm-fileImgMain">
            <img src={productUploadImg} />
          </div>
          <div className="newProductForm-fileImgName">IMG_123000</div>
        </div>
        <div className="newProductForm-fileImgSwitch">
          {" "}
          <Switch
            onChange={handleCheckedChange}
            checked={checked}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <div className="newProductForm-fileImgDel">
          <img src={productTrashIcon} />
        </div>
      </div>

      <div className="newProductForm-fileImgListGen">
        <div className="newProductForm-fileImgListFam">
          <div className="newProductForm-fileImgMain">
            <img src={productUploadImg} />
          </div>
          <div className="newProductForm-fileImgName">IMG_123000</div>
        </div>
        <div className="newProductForm-fileImgSwitch">
          {" "}
          <Switch
            onChange={handleCheckedChange}
            checked={checked}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <div className="newProductForm-fileImgDel">
          <img src={productTrashIcon} />
        </div>
      </div>

      <div className="newProductForm-fileImgListGen">
        <div className="newProductForm-fileImgListFam">
          <div className="newProductForm-fileImgMain">
            <img src={productUploadImg} />
          </div>
          <div className="newProductForm-fileImgName">IMG_123000</div>
        </div>
        <div className="newProductForm-fileImgSwitch">
          {" "}
          <Switch
            onChange={handleCheckedChange}
            checked={checked}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={35}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <div className="newProductForm-fileImgDel">
          <img src={productTrashIcon} />
        </div>
      </div>
      <div className="newProductForm-SaveGen">
        <div className="newProductForm-SaveDraft">Save as Draft</div>
        <div className="newProductForm-SaveChanges">Save Changes</div>
      </div>
    </Modal>
  );
}

export { NewProductModal };

// <div>
//   <button onClick={this.handleOpenModal}>Trigger Modal</button>
//   <ReactModal
//      isOpen={this.state.showModal}
//      contentLabel="onRequestClose Example"
//      onRequestClose={this.handleCloseModal}
//      shouldCloseOnOverlayClick={true}
//   >
//     <p>Modal text!</p>
//     <button onClick={this.handleCloseModal}>Close Modal</button>
//   </ReactModal>
// </div>
