import React, { useState, useEffect } from 'react'
import closeOrderModal from '../../../../assets/icon/closeOrderModal.svg'
import { Toast } from '../../../../helpers/toast'
import { axiosCalls } from '../../../../_api'
import { hideLoader, showLoader } from '../../../../loader'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'

function AddProductCategory({ handleToggleNewModal, selectedData }) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [newCat, setNewCat] = useState(true)
  async function handleSubmit() {
    if (name.length < 1 && description.length < 1) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const data = {
      name: name,
      description: description,
    }
    const response = await axiosCalls(`admin/experience-category`, 'post', data)
    if (response) {
      hideLoader()
      if (response.er) {
        Toast('error', response.er.message)
        return
      }
      Toast('success', 'Category saved successfully.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  async function handleEditSubmit() {
    if (name.length < 1 && description.length < 1) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const data = {
      name: name,
      description: description,
    }
    const response = await axiosCalls(
      `admin/experience-category/${selectedData.id}`,
      'post',
      data
    )
    if (response) {
      hideLoader()
      if (response.er) {
        Toast('error', response.er.message)
        return
      }
      Toast('success', 'Category saved successfully.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  useEffect(() => {
    if (selectedData) {
      setNewCat(false)
      setName(selectedData.name)
      setDescription(selectedData.description)
    }
  }, [])

  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>
          {newCat ? 'New Category' : 'Edit Category'}
        </div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Description</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-SaveGen' style={{ marginTop: '30px' }}>
        {selectedData ? (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleEditSubmit()
            }}
          >
            Edit
          </div>
        ) : (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleSubmit()
            }}
          >
            Create
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export default AddProductCategory
