const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const ImageUrl = (url) => {
  return new Promise((resolve, reject) => {
    toDataURL(url)
      .then((dataUrl) => {
        const fileName = url.substring(url.lastIndexOf('/') + 1)
        const file = dataURLtoFile(dataUrl, fileName)
        resolve(file)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default ImageUrl
