import React from 'react'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import './orderSuccess.scss'
import onSuccessOrder from '../../assets/icon/onSuccessOrder.svg'
import { Link } from 'react-router-dom'

const OrderSuccess = () => {
  return (
    <div>
      <Navbar />
      <div className='order-success-famcon'>
        <div className='order-success-icon-dis'>
          <img src={onSuccessOrder} />
        </div>

        <div
          className='order-success-thank-tes'
          style={{ textAlign: 'center' }}
        >
          Thank you, your order has been placed.
        </div>

        <div
          className='order-success-thank-mailll'
          style={{ textAlign: 'center' }}
        >
          <p>
            Please check your email for order confirmation and detailed delivery
            information.
            {/* <strong> 50 loyalty point for this purchase. </strong> */}
          </p>
        </div>

        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className='order-success-shipping-cont-btn'>
            Continue Shopping
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  )
}

export default OrderSuccess
