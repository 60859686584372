import React from 'react';
import { Toast } from '../../helpers/toast';
import { hideLoader, showLoader } from '../../loader';
import { axiosCalls } from '../../_api';
import { useSelector } from 'react-redux';
import capitalize from '../../helpers/capitalize';
import './rightForm.scss';

const AddressForm = ({
	formTitle,
	onSuccessSave,
	btnTitle,
	mode,
	id,
	edit,
}) => {
	const userInfo = useSelector((state) => state);

	const [info, setInfo] = React.useState({
		full_name:
			`${capitalize(userInfo?.auth?.user?.first_name)} ${capitalize(
				userInfo?.auth?.user?.last_name
			)}` ?? '',
		phone_no: capitalize(userInfo?.auth?.user?.phone_no) ?? '',
		address: '',
		city_id: 1,
		state_id: 1,
	});

	React.useEffect(() => {
		if (mode == 'edit') {
			setInfo(edit);
			// alert(2);
		}
	}, [mode]);
	const create = async (from) => {
		showLoader();
		const data = info;

		let request = null;

		if (mode == 'edit') {
			request = axiosCalls(`address-book/${id}`, 'put', data);
		} else {
			request = axiosCalls(`address-book`, 'post', data);
		}
		const res = await request;
		if (res) {
			hideLoader();
			if (res.er) {
				Toast('error', res.er.message);
				return;
			}
			Toast('success', 'Address saved successfully.');
			onSuccessSave();
		}
	};

	return (
		<div>
			<div className=' rightForm-over-fam'>
				<div className='rightForm-over-heading-cont'>
					<h3>{formTitle ?? 'New Address'}</h3>
				</div>
				<div className='rightForm-over-fam-line'></div>

				<form>
					<div className='checkoutOne-form-first-flex'>
						<div className='checkoutOne-form-name'>
							<div className='checkoutOne-form-label'>
								<p>Full Name *</p>
							</div>
							<input
								type='text'
								onChange={(e) => {
									setInfo({ ...info, full_name: e.target.value });
								}}
								value={info.full_name}
							/>
						</div>
						<div className='checkoutOne-form-phone'>
							<div className='checkoutOne-form-label'>
								<p>Phone Number *</p>
							</div>
							<input
								type='number'
								onChange={(e) => {
									setInfo({ ...info, phone_no: e.target.value });
						
								}}
								value={info.phone_no}
							/>
						</div>
					</div>

					<div>
						<div className='checkoutOne-textarea-label'>
							<p>Address</p>
						</div>
						<div className='checkoutOne-textarea'>
							<textarea
								rows='4'
								cols='50'
								placeholder='Enter address'
								onChange={(e) => {
									setInfo({ ...info, address: e.target.value });
						
								}}
								value={info.address}
							/>
						</div>
					</div>

					<div className='checkoutOne-form-third-flex'>
						<div className='checkoutOne-form-name'>
							<div className='checkoutOne-form-label'>
								<p>City </p>
							</div>
							<input type='text' />
						</div>
						<div className='checkoutOne-form-phone'>
							<div className='checkoutOne-form-label'>
								<p>State </p>
							</div>
							<input type='text' />
						</div>
					</div>

					<div className='continueOne-to-pay-btn' onClick={create}>
						{btnTitle ?? 'Save'}
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddressForm;
