import React, { useState, useEffect } from 'react'
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import './index.css'
// import { axiosCalls } from "../../../../src/_api";
import { axiosCalls } from '../../../../../_api'

const Pallets = () => {
  const [loading1, setLoading1] = useState(true)

  const [transactionData, setTransactionData] = useState([])

  useEffect(() => {
    getAllTransactionData()
  }, [])

  const getAllTransactionData = async () => {
    setLoading1(true)
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setTransactionData(res?.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  return (
    <div className='palleteCards'>
      <article className='palleteCardsChild'>
        <div className='palleteCardsChildTotal-Rev-col1'>
          <p>Total Sales</p>
        </div>
        <div className='palleteCardsChildTotal-Rev-col2'>
          <h2>{transactionData?.total_sales_value}</h2>
          <p>
            <span>+ 1%</span>
            <BiUpArrowAlt className='palleteCardsChildTotal-Rev-col2-iconSuc' />
          </p>
        </div>
      </article>

      <article className='palleteCardsChild'>
        <div className='palleteCardsChildTotal-Rev-col1'>
          <p>Processed Orders</p>
        </div>
        <div className='palleteCardsChildTotal-Rev-col2'>
          <h2>{transactionData?.total_sales_volume}</h2>
          <p>
            <span>+ 1%</span>
            <BiDownArrowAlt className='palleteCardsChildTotal-Rev-col2-iconfail' />
          </p>
        </div>
      </article>

      <article className='palleteCardsChild'>
        <div className='palleteCardsChildTotal-Rev-col1'>
          <p>Cancelled Orders</p>
        </div>
        <div className='palleteCardsChildTotal-Rev-col2'>
          <h2>
            {!transactionData?.total_sales_cancelled
              ? 'no data from server'
              : transactionData?.total_sales_cancelled}
          </h2>
          <p>
            <span>+ 1%</span>
            <BiUpArrowAlt className='palleteCardsChildTotal-Rev-col2-iconSuc' />
          </p>
        </div>
      </article>
    </div>
  )
}

export { Pallets }
