import React, { useEffect, useState } from 'react'
import Navbar from '../../components/layout/navbar'
import eventsBanner from '../../assets/images/events.png'
import './events.scss'
import Footer from '../../components/layout/footer'
import { hideLoader, showLoader } from '../../loader'
import { axiosCalls } from '../../_api'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'

function Events() {
  const [eventList, setEventList] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const getAllEvents = async () => {
    setLoading(true)
    const res = await axiosCalls('landing-page-experiences', 'get')
    if (res) {
      setLoading(false)
      if (res.er) {
        setLoading(false)
        return
      }
      setEventList(res?.data)
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getAllEvents()
  }, [])

  return loading ? (
    <Loader align={'center'} />
  ) : (
    <div className='events'>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${eventsBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '366px',
          backgroundPosition: 'bottom',
        }}
        className='home-banner-family-cont'
      >
        <div className='home-banner-text'>
          <h2>Kids Events</h2>
        </div>
      </div>
      <div className='event-filters'>
        <div className='one'>
          <p>
            Home <span>Kids Events</span>
          </p>
        </div>
        <div className='two'>
          <div className='left'>
            <p>Showing {eventList.total.toLocaleString()} results</p>
          </div>
        </div>
      </div>
      <div className='events-cards'>
        {eventList.data.map((item, index) => (
          <div
            className='card'
            key={index}
            onClick={() => navigate(`/single-experience/${item.id}`)}
          >
            <img src={item.main_image} alt='' />
            <div className='texts'>
              <h2>{item.name}</h2>
              <p>₦{parseInt(item.adult_price).toLocaleString()}</p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default Events
