import React from "react";
import Footer from "../../components/layout/footer";
import Navbar from "../../components/layout/navbar";
import "./orderDecline.scss";
import onDeclineOrder from "../../assets/icon/onDeclineOrder.svg";

const OrderDecline = () => {
  return (
    <div>
      <Navbar />
      <div className="order-decline-famcon">
        <div className="order-decline-icon-dis">
          <img src={onDeclineOrder} />
        </div>

        <div className="order-decline-thank-tes">
          Sorry your purchase wasn’t completed
        </div>

        <div className="order-decline-thank-check">
          Check you payment method and try again.
        </div>

        <div className="order-decline-shipping-cont-btn">Go back</div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderDecline;
