// ModalLayout.js
import React, { useState } from 'react'
import './ModalLayout.scss' // Import your modal styles here
import { Toast } from '../../../helpers/toast'

const ModalLayout = ({ children, type }) => {
  return (
    <div className={`modalLayout-container ${type}`}>
      <div className={`modal-overlay ${type}`}>
        <div className='modal-content'>{children}</div>
      </div>
    </div>
  )
}

export default ModalLayout
