import React, { useState } from 'react';
import './confirmAddressModal.scss';
import cancelIcon from '../../assets/icon/cancelIcon.svg';
import { Link } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { Toast } from '../../helpers/toast';

const ConfirmAddressModal = ({
	closeLoginModal,
	address,
	setSelectedAddress,
	handleOnSuccess,
	handleOnClose,
	cartSummary,
	user,
	selectedAddress,
}) => {
	const [defaultAdd, setDefaultAdd] = useState(false);

	const [defaultAddTwo, setDefaultAddTwo] = useState(false);

	const [sel, setSel] = useState();

	//     const resetRadioState = () => {
	//     setGender(false);
	//   }

	const handleSelected = (id, data) => {
		setSel(id);
		setSelectedAddress(data);
	};

	return (
		<>
			<div className='confirm-add-modal'>
				<div className='confirm-add-modal-content'>
					<div className='cancel-icon-btn' onClick={closeLoginModal}>
						<img src={cancelIcon} />
					</div>
					<div className='confirmedAddress-header'>
						{' '}
						<h2>Checkout</h2>
					</div>

					<div className='confirmedAddress-line'></div>
					<div className='confirmedAddress-default'>
						<h3>Default Address</h3>
					</div>

					{address.length == 0
						? null
						: address.map((adr) => {
								return (
									<div
										className='confirmedAddress-first-flex'
										style={{
											border:
												sel === adr.id
													? '1px solid #0C8DBA'
													: '1px solid rgba(89, 95, 98, 0.1)',
											background:
												sel === adr.id
													? 'rgba(12, 141, 186, 0.05) '
													: 'rgba(12, 141, 186, 0.02)',
										}}
									>
										<div className='confirmedAddress-left'>
											<input
												type='radio'
												checked={sel === adr.id}
												onChange={() => handleSelected(adr.id, adr)}
											/>
										</div>
										<div className='confirmedAddress-right'>
											<div className='confirmedAddress-name'>
												<h3>{adr?.full_name}</h3>
											</div>
											<div className='confirmedAddress-address'>
												<p>{adr?.address}</p>
											</div>
											<div className='confirmedAddress-phoneNo'>
												<p>Phone number:</p>
											</div>
											<div className='confirmedAddress-tel'>
												<p>{adr?.phone_no}</p>
											</div>
											<div className='confirmedAddress-edit-fam'>
												{/* <div className="confirmedAddress-remove">
                          <p>Remove</p>
                        </div> */}
												{/* <div className="confirmedAddress-edit">
                          <p>Edit</p>
                        </div> */}
											</div>
										</div>
									</div>
								);
						  })}

					{/* <div
            className="confirmedAddress-first-flex"
            style={{
              border:
                defaultAddTwo === true
                  ? "1px solid #0C8DBA"
                  : "1px solid rgba(89, 95, 98, 0.1)",
              background:
                defaultAddTwo === true
                  ? "rgba(12, 141, 186, 0.05) "
                  : "rgba(12, 141, 186, 0.02)",
            }}
          >
            <div className="confirmedAddress-left">
              <input
                type="radio"
                checked={defaultAddTwo === true}
                onChange={handleChangeTwo}
              />
            </div>
            <div className="confirmedAddress-right">
              <div className="confirmedAddress-name">
                <h3>Ifeanyi Umunnakwe</h3>
              </div>
              <div className="confirmedAddress-address">
                <p>
                  Quits Aviation Services Free Zone Murtala Muhammed
                  International Airport 23401, Lagos
                </p>
              </div>
              <div className="confirmedAddress-phoneNo">
                <p>Phone number:</p>
              </div>
              <div className="confirmedAddress-tel">
                <p>080454395930</p>
              </div>
              <div className="confirmedAddress-edit-fam">
                <div className="confirmedAddress-remove">
                  <p>Remove</p>
                </div>
                <div className="confirmedAddress-edit">
                  <p>Edit</p>
                </div>
              </div>
            </div>
          </div> */}
					{/* <Link
            className="confirmedAddress-btn"
            // to="/payment-method"
            style={{ textDecoration: "none", padding: "20px" }}
          >
            Use this Address
          </Link> */}
					<div className='paymentTotalbtn'>
						{selectedAddress == null ? (
							<button
								onClick={() => {
									Toast('error', 'Please select address');
								}}
							>
								PROCEED TO PAYMENT
							</button>
						) : (
							<div className='paymentTotalbtn'>
								<PaystackButton
									reference={new Date().getTime().toString()}
									email={user?.email ?? 'andy@gmail.com'}
									amount={parseInt(cartSummary?.estimated_total * 100)}
									publicKey={'pk_test_5d63ef54ed41265fd32396ddf37d383b3b5a8f3a'}
									text='PROCEED TO PAYMENT'
									onSuccess={handleOnSuccess}
									onClose={handleOnClose} // Optional
									className='font-main'
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmAddressModal;
