import React, { useState, useEffect } from 'react'
import DashboardTop from '../../../../components/adminLayout/dashboardTop/dashboardTop'
import AddEventCategory from './AddEventCategory'
import { axiosCalls } from '../../../../_api'
import { Toast } from '../../../../helpers/toast'
import Loader from '../../../../components/loader/Loader'
import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import '../../events/productTable.css'
import icons from '../../events/components/Table/icons'
import { Icon } from '@iconify/react'
import DeleteEventCategories from './DeleteEventCategories'

function EventCategories() {
  const [showAddNew, setShowAddNew] = useState(false)
  const [showDel, setShowDel] = useState(null)
  const [loading, setLoading] = useState(true)
  const [categoriesList, setCategoriesList] = useState([])
  const [selectedData, setSelectedData] = useState(null)

  function handleToggleNewModal() {
    setShowAddNew(!showAddNew)
    setSelectedData(null)
  }
  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = async (from) => {
    setLoading(true)

    const res = await axiosCalls(`admin/experience-category`, 'get')

    if (res) {
      setLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setCategoriesList([])
        return
      }
      setCategoriesList(res?.data ?? [])
      // Toast("success", "Addresses retrieved successfully");
      return
    }
    setCategoriesList([])
  }
  function deleteCategory(params) {
    setShowDel(params)
  }
  return loading ? (
    <Loader size={'small'} direction={'center'} />
  ) : (
    <div className='product-categories'>
      <DashboardTop
        tag={'Event Categories'}
        buttonTag={'Add Category'}
        onclick={handleToggleNewModal}
      />
      <div className='categoryList'>
        <MaterialTable
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          icons={icons}
          options={{
            exportButton: false,
            pageSize: 5,
          }}
          columns={[
            { title: 'Name', field: 'name', width: '30%' },
            { title: 'Date', field: 'date', width: '30%' },
            { title: 'Description', field: 'description', width: '30%' },
            { title: 'Action', field: 'Action', width: '20%' },
          ]}
          data={categoriesList.map((data) => {
            return {
              name: (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    // setSelectedExperience(data)
                    // handleToggleModal()
                  }}
                  className='productName-GenFam'
                >
                  <div className='productName-GenImgName'>{data?.name}</div>
                  {/* {!data?.vendor ? "-" : data?.vendor}{" "} */}
                </div>
              ),
              date: new Date(data?.created_at).toDateString(),
              description: data.description,
              adult: `0`,
              Action: (
                <div className='actions-here'>
                  <div className='edit'>
                    <Icon
                      icon={'line-md:edit-twotone'}
                      onClick={() => {
                        setSelectedData(data)
                        setShowAddNew(true)
                      }}
                    />
                  </div>
                  <div className='delete'>
                    <Icon
                      icon={'icomoon-free:bin'}
                      onClick={() => {
                        deleteCategory(data)
                      }}
                    />
                  </div>
                </div>
              ),
              Check: (
                <div className='publicationCheckbox'>
                  <input id='c1' type='checkbox' />
                </div>
              ),
            }
          })}
          title=''
        />
      </div>
      {showAddNew && (
        <AddEventCategory
          handleToggleNewModal={handleToggleNewModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
      {showDel && (
        <DeleteEventCategories showDel={showDel} setShowDel={setShowDel} />
      )}
    </div>
  )
}

export default EventCategories
