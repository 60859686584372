import React, { useState, useEffect } from 'react'
import './style.scss'
import { Icon } from '@iconify/react'

const InputToObjectArray = ({ tag, onItemsChange, initialItems }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [timeTaken, setTimeTaken] = useState('')
  const [admissionTicketFree, setAdmissionTicketFree] = useState(false)
  const [items, setItems] = useState([])

  const handleAddItem = () => {
    if (name.trim() && description.trim() && timeTaken.trim()) {
      const newItem = {
        name: name.trim(),
        description: description.trim(),
        time_taken: timeTaken.trim(),
        admission_ticket_free: admissionTicketFree,
      }
      const newItems = [...items, newItem]
      setItems(newItems)
      onItemsChange && onItemsChange(newItems)

      // Clear fields after adding item
      setName('')
      setDescription('')
      setTimeTaken('')
      setAdmissionTicketFree(false)
    }
  }

  const handleRemoveItem = (indexToRemove) => {
    const newItems = items.filter((_, index) => index !== indexToRemove)
    setItems(newItems)
    onItemsChange && onItemsChange(newItems)
  }

  useEffect(() => {
    setItems(initialItems ?? [])
    onItemsChange && onItemsChange(initialItems)
  }, [initialItems, onItemsChange])

  return (
    <div className=' newProductForm-NameGen inputToObjectArray'>
      <div className='newProductForm-NameGenTag'>{tag}</div>
      <div className='newProductForm-NameInput'>
        <div className='inputToObjectArray-Form'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Name'
          />
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Description'
          />
          <input
            type='text'
            value={timeTaken}
            onChange={(e) => setTimeTaken(e.target.value)}
            placeholder='Time Taken'
          />
          <label className='flex checkbox-wrap items-center'>
            Admission Ticket Free
            <input
              type='checkbox'
              checked={admissionTicketFree}
              onChange={() => setAdmissionTicketFree(!admissionTicketFree)}
            />
          </label>
          <button onClick={handleAddItem}>Add Item</button>
        </div>
        <ul>
          {items.map((item, index) => (
            <li key={index} className='flex justify-between'>
              <div>
                <p>
                  <strong>Step:</strong> {index + 1}
                </p>
                <p>
                  <strong>Name:</strong> {item.name}
                </p>
                <p>
                  <strong>Description:</strong> {item.description}
                </p>
                <p>
                  <strong>Time Taken:</strong> {item.time_taken}
                </p>
                <p>
                  <strong>Admission Ticket Free:</strong>{' '}
                  {item.admission_ticket_free ? 'Yes' : 'No'}
                </p>
              </div>
              <Icon
                icon='humbleicons:times'
                onClick={() => handleRemoveItem(index)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default InputToObjectArray
