import React, { useState } from 'react'
import { GoCalendar } from 'react-icons/go'
import { HiDownload } from 'react-icons/hi'
import { OrdersModal, Table } from './components'
import { NewProductModal } from './components/newProductModal'
import './index.css'
import { Container } from './styled'

const Orders = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showNewModal, setShowNewModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  function handleToggleModal() {
    setShowModal((prevState) => !prevState)
  }

  function handleToggleNewModal() {
    setShowNewModal((prevState) => !prevState)
  }
  return (
    <div className='app-scroll'>
      <Container>
        <header>
          <h1 className='u--typo__title2'>Products</h1>
          <div className='action--container'>
            {/* <div className='select--container'>
              <select>
                <option>Filter by</option>
              </select>
            </div> */}

            {/* <GoCalendar />
              <select>
                <option>This week</option>
              </select> */}

            {/* <div className='product-exportBtn'>
              <HiDownload />
              Export
            </div> */}

            <div
              onClick={handleToggleNewModal}
              className='products-newProductsbtn'
            >
              New Product
            </div>
          </div>
        </header>
        <div className='orders__table'>
          <Table
            setSelectedProduct={setSelectedProduct}
            setShowDetails={setShowDetails}
            handleToggleNewModal={handleToggleNewModal}
          />
        </div>
        {showDetails && (
          <OrdersModal
            setShowDetails={setShowDetails}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
        {showNewModal && (
          <NewProductModal
            handleToggleNewModal={handleToggleNewModal}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
      </Container>
    </div>
  )
}

export default Orders
