import React from "react";
import "./successScreen.scss";
// import cancelIcon from "../../assets/icon/cancelIcon.svg";
import cancelIcon from "../../../../assets/icon/cancelIcon.svg";
import adminSuccess from "../../../../assets/icon/adminSuccess.svg";

import { Link } from "react-router-dom";

const SuccessScreen = ({ hideSuccessPage, successText }) => {
  return (
    <div className="adminSuccess-tag-modal">
      <div className="adminSuccess-tag-modal-content">
        <div className="cancel-icon-btn" onClick={hideSuccessPage}>
          <img src={cancelIcon} />
        </div>
        <div className="adminSuccess-fammm">
          <div className="adminSuccess-Img">
            <img src={adminSuccess} />
          </div>

          <div className="adminSuccess-textt">Success</div>
          <div className="adminSuccess-texttrr">{successText}</div>

          <div className="adminSuccess-textBtn">Okay, Go Back</div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
