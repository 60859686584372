import './onSuccessSignup.scss';

import React, { useState, useEffect } from 'react';
import { axiosCalls } from '../../_api';
import { Link } from 'react-router-dom';

import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg';
import eye from '../../assets/icon/eye.svg';

const OnSuccessSignup = () => {
	return (
		<div>
			<div className='SuccessSignup-page-header'>
				<div className='SuccessSignup-page-logo'>
					<img src={bubbleColonyLogoHome} alt='home_logo' />
				</div>
				<Link to='/create-account' style={{ textDecoration: 'none' }}>
					<div className='create-account-btn'>Create account</div>{' '}
				</Link>
			</div>
			<div className='SuccessSignup-page-fam'>
				<div className='SuccessSignup-page-famoo'>
					REGISTRATION SUCCESSFUL CHECK YOUR MAIL FOR VERIFICATION LINK
				</div>
				<br />
				<a href='./login'>
					<button>Login</button>
				</a>
			</div>
		</div>
	);
};

export default OnSuccessSignup;
