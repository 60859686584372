import React from 'react'
import './InfoModal.scss' // Optional: for styling
import { Icon } from '@iconify/react'

const InfoModal = ({ showModal, closeModal, children }) => {
  if (!showModal) return null // Don't render if showModal is false

  return (
    <div className='modal-overlays'>
      <div className='modal-contents'>
        <div className='top'>
          <Icon icon={'pepicons-pencil:times-circle'} onClick={closeModal} />
        </div>
        {children}
      </div>
    </div>
  )
}

export default InfoModal
