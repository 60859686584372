/* eslint-disable jsx-a11y/no-redundant-roles */
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import closeOrderModal from "../../../../../assets/icon/closeOrderModal.svg";

import orderAdminDots from "../../../../../assets/icon/orderAdminDots.svg";

import orderAdminProduct from "../../../../../assets/icon/orderAdminProduct.png";

import Modal from "react-modal";
// @ts-ignore
import ProductImage from "../../../../../assets/images/shopDenim.png";
// @ts-ignore
import styles from "./index.module.css";
import "./ordersModal.scss";
import OrdersProduct from "./OrdersProduct";

Modal.setAppElement("#root");

function OrdersModal({ showModal, handleToggleModal }) {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleToggleModal}
      shouldCloseOnOverlayClick={true}
      className={`${styles.ordersModal}`}
      overlayClassName={`${styles.ordersModal__overlay}`}
    >
      {/* The Flow Class is in the root > index.css */}
      {/* Use --flow-spacer: 2rem..etc to overwrite it */}
      <div className="adminOrders-headingFam">
        <div className="adminOrders-headingNo">Orders #121091</div>
        <div className="adminOrders-headingClose" onClick={handleToggleModal}>
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className="adminOrders-SubheadingStaFam">
        <div className="adminOrders-SubheadingStaFlex">
          <div className="adminOrders-SubheadingStaText">Status:</div>
          <div className="adminOrders-SubheadingStaIconFlex">
            <div className="adminOrders-SubheadingStaIcon"></div>
            <div className="adminOrders-SubheadingStaSt">Pending Delivery</div>
          </div>
        </div>
        <div className="adminOrders-SubheadingStaOpt">
          <img src={orderAdminDots} />
        </div>
      </div>
      <div className="adminOrders-SubheadingDateFam">
        <div className="adminOrders-SubheadingDateHead">Date:</div>
        <div className="adminOrders-SubheadingDate">24 February, 2021</div>
      </div>
      <div className="adminOrders-summaryFam">
        <div className="adminOrders-summaryFamText">Order Summary</div>
        <OrdersProduct />
        <OrdersProduct />
        <div className="adminOrders-subTotalFlex">
          <div className="adminOrders-subTotalText">Subtotal</div>
          <div className="adminOrders-subTotalPrice">₦15,000.00</div>
        </div>

        <div className="adminOrders-TotalFam">
          <div className="adminOrders-TotalShip">Shipping Fees</div>
          <div className="adminOrders-TotalShipPrice">₦1,500.00</div>
        </div>
        <div className="adminOrders-TotalAmountFam">
          <div className="adminOrders-TotalAmountext">Total Amount</div>
          <div className="adminOrders-TotalAmountPrice">₦16,500.00</div>
        </div>
      </div>

      <div className="adminOrders-customerFam">
        <div className="adminOrders-customerDetai">Customer Details</div>
        <div className="adminOrders-customerDeiFlex">
          <div className="adminOrders-customerNameLabel">Customer name</div>
          <div className="adminOrders-customerPhoneLabel">Phone number</div>
        </div>

        <div className="adminOrders-customerNameGen">
          <div className="adminOrders-customerName">Adebusayo Obisesan</div>
          <div className="adminOrders-customerPhone">07045495450</div>
        </div>

        <div className="adminOrders-customerAddressFam">Shipping Address</div>
        <div className="adminOrders-customerAddress">
          12 Quits Aviation Services Free Zone Murtala Muhammed International
          Airport{" "}
        </div>
      </div>
      <div className="adminOrders-vendorFam">
        <div className="adminOrders-vendorDetail">Vendor Details</div>
        <div className="adminOrders-vendorDetailF">
          <div className="adminOrders-vendorDetailN">Vendor Name</div>
          <div className="adminOrders-vendorDetailP">Phone number</div>
        </div>

        <div className="adminOrders-vendorDetailStore">
          <div className="adminOrders-vendorDetailStoreName">
            Summit Mobile Ltd
          </div>
          <div className="adminOrders-vendorDetailStorePhone">07045495450</div>
        </div>
      </div>
    </Modal>
  );
}

export { OrdersModal };

// <div>
//   <button onClick={this.handleOpenModal}>Trigger Modal</button>
//   <ReactModal
//      isOpen={this.state.showModal}
//      contentLabel="onRequestClose Example"
//      onRequestClose={this.handleCloseModal}
//      shouldCloseOnOverlayClick={true}
//   >
//     <p>Modal text!</p>
//     <button onClick={this.handleCloseModal}>Close Modal</button>
//   </ReactModal>
// </div>
