import React from "react";
import "./personalSettings.scss";

const PersonalSettings = () => {
  return (
    <div>
      <div className="personalSettings-headText">Personal Information </div>
      <div className="personalSettings-headBorder"></div>
      <div className="personalSettings-BodyFlex">
        <div className="personalSettings-formText">
          {" "}
          Change and update your basic personal information.
        </div>
        <div className="personalSettings-FirstFormBody">
          <div className="personalSettings-FirstFormLabel">First Name</div>
          <div className="personalSettings-FirstFormInput">
            <input type="text" />
          </div>
        </div>

        <div className="personalSettings-SecondFormBody">
          <div className="personalSettings-FirstFormLabel">Last Name</div>
          <div className="personalSettings-FirstFormInput">
            <input type="text" />
          </div>
        </div>
      </div>

      <div className="personalSettings-BodyFlex">
        <div className="personalSettings-formText"></div>
        <div className="personalSettings-FirstFormBody">
          <div
            className="personalSettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          >
            Phone Number
          </div>
          <div className="personalSettings-FirstFormInput">
            <input type="text" />
          </div>
        </div>

        <div className="personalSettings-SecondFormBody">
          <div
            className="personalSettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          ></div>
        </div>
      </div>

      <div className="personalSettings-BodyFlex">
        <div className="personalSettings-formText"></div>
        <div className="personalSettings-SaveBtnBody">
          <div
            className=" personalSettings-FirstFormBtn"
            style={{ marginTop: "10px" }}
          >
            Save Changes
          </div>
        </div>
        <div className="personalSettings-SecondFormBody"></div>
      </div>
    </div>
  );
};

export default PersonalSettings;
