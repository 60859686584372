import React from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../helpers/toast";
import { hideLoader, showLoader } from "../../loader";
import { axiosCalls } from "../../_api";
import AddressForm from "../addressForm/addressFormCreate";
import "./rightForm.scss";

const RightForm = () => {
  const [info, setInfo] = React.useState({
    full_name: "",
    phone_no: "",
    address: "",
    city_id: 1,
    state_id: 1,
  });

  const navigation = useNavigate();

  const create = async (from) => {
    showLoader();
    const data = info;
    const res = await axiosCalls(`address-book`, "post", data);
    if (res) {
      hideLoader();
      if (res.er) {
        Toast("error", res.er.message);
        return;
      }
      Toast("success", "Address saved successfully.");
    }
  };

  return (
    <div>
      <AddressForm
        formTitle="Add New Address"
        onSuccessSave={() => {
          navigation(-1);
        }}
        btnTitle="Save"
      />
    </div>
  );
};

export default RightForm;
