import React from 'react'
import './myOrdersBlank.scss'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import LeftBar from '../../components/leftBar/LeftBar'
import backIconBlue from '../../assets/icon/backIconBlue.svg'
import { Link, useNavigate } from 'react-router-dom'
import MyOrdersHeaders from '../../components/myOrdersHeaders/MyOrdersHeaders'
import OrderCard from './orderCard'
import { hideLoader, showLoader } from '../../loader'
import { axiosCalls } from '../../_api'
import { Toast } from '../../helpers/toast'
import { useState } from 'react'
import { useEffect } from 'react'

const MyOrdersBlank = () => {
  const navigate = useNavigate()
  const [orders, setOrders] = useState([])

  useEffect(() => {
    getOrders()
  }, [])

  const getOrders = async (from) => {
    showLoader()

    const res = await axiosCalls(`order`, 'get')

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)
        setOrders([])
        return
      }

      setOrders(res?.data?.data ?? [])
      // Toast("success", "Addresses retrieved successfully");
      return
    }
    setOrders([])
  }

  return (
    <div>
      <Navbar />
      <div className='myOrderBlank-checkout-add'>
        <LeftBar />
        <div className='myOrderBlank-checkout-rightForm'>
          <div className=' myOrderBlank-over-fam'>
            <MyOrdersHeaders />
            {orders.map((data, index) => {
              return <OrderCard key={index} data={data} />
            })}

            {orders.length === 0 ? (
              <div className='myOrderBlank-selection-textFamCont'>
                <div className='myOrderBlank-selection-havText'>
                  You haven’t made any orders.
                </div>

                <div className='myOrderBlank-selection-startShopFamc'>
                  <div className='myOrderBlank-selection-startShopText'>
                    Start Shopping?
                  </div>
                  <div className='myOrderBlank-selection-startChildPro'>
                    Browse through our collection of children products.{' '}
                  </div>
                  <div
                    className='myOrderBlank-selection-shopNBtn'
                    onClick={() => {
                      navigate('/homepage')
                    }}
                  >
                    Shop Now
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MyOrdersBlank
