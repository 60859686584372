import React, { useState } from 'react';
import './personalInfoCheckout.scss';
import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import LeftBar from '../../components/leftBar/LeftBar';
import backIconBlue from '../../assets/icon/backIconBlue.svg';
import eye from '../../assets/icon/eye.svg';
import { Link, useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../loader';
import { axiosCalls } from '../../_api';
import { Toast } from '../../helpers/toast';
import { useSelector } from 'react-redux';

const PersonalInfoCheckout = () => {
	const userInfo = useSelector((state) => state.auth);
	const [showPass, setShowPass] = useState(false);
	const [showPassTwo, setShowPassTwo] = useState(false);
	const [userProfile, setUserProfile] = useState({
		first_name: '',
		last_name: '',
		phone_no: '',
	});

	const [password, setPassword] = useState({
		newPass: '',
		oldPass: '',
	});

	const navigate = useNavigate();

	React.useEffect(() => {
		setUserProfile({
			first_name: userInfo.user.first_name,
			last_name: userInfo.user.last_name,
			phone_no: userInfo.user.phone_no,
		});
	}, []);

	const passwordVisiblity = () => {
		setShowPass((showPass) => !showPass);
	};

	const passwordVisiblityTwo = () => {
		setShowPassTwo((showPassTwo) => !showPassTwo);
	};

	const onSubmitAddress = () => {
		navigate('/personal-checkout-address');
	};

	const updateProfile = async (from) => {
		showLoader();
		const res = await axiosCalls(
			`update-profile/${userInfo.user.user_id}`,
			'post',
			userProfile
		);
		if (res) {
			hideLoader();
			if (res.er) {
				Toast('error', 'Please try again');
				return;
			}
			Toast('success', 'Profile updated successfully.');
			return;
		}
	};
	return (
		<div>
			<Navbar />
			<div className='personalCheck-checkout-fam'>
				<LeftBar />
				<div className='personalCheck-over-famwww'>
					<div className='personalCheck-over-goBack'>
						{/* <Link to="/account-checkout" style={{ textDecoration: "none" }}> */}
						<div
							className='personalCheck-over-goBackImg'
							onClick={() => navigate(-1)}
						>
							<img src={backIconBlue} />
						</div>
						{/* </Link> */}
						<div className='personalCheck-over-text'>Go Back</div>
					</div>

					<div className=' personalCheck-over-fam'>
						<div className='personalCheck-over-heading-cont'>
							<h3>Profile Information</h3>
						</div>
						<div className='personalCheck-over-fam-line'></div>
						<div className='personalCheck-over-form-family'>
							<div className='personalCheck-over-fam-fullfam'>
								<div className='personalCheck-over-fam-fullName'>
									First name
								</div>{' '}
								<div className='personalCheck-overFullNameInput'>
									<input
										type='text'
										onChange={(e) => {
											setUserProfile({
												...userProfile,
												first_name: e.target.value,
											});
										}}
										value={userProfile.first_name}
									/>
								</div>
							</div>

							<div className='personalCheck-over-fam-fullfam'>
								<div className='personalCheck-over-fam-fullName'>Last name</div>{' '}
								<div className='personalCheck-overFullNameInput'>
									<input
										type='text'
										onChange={(e) => {
											setUserProfile({
												...userProfile,
												last_name: e.target.value,
											});
										}}
										value={userProfile.last_name}
									/>
								</div>
							</div>

							<div className='personalCheck-over-fam-fullfam'>
								<div className='personalCheck-over-fam-fullName'>
									Phone number
								</div>{' '}
								<div className='personalCheck-overFullNameInput'>
									<input
										type='tel'
										onChange={(e) => {
											setUserProfile({
												...userProfile,
												phone_no: e.target.value,
											});
										}}
										value={userProfile.phone_no}
									/>
								</div>
							</div>

							<div className='personalCheck-over-fam-fullfam'>
								<div className='personalCheck-over-fam-fullName'>
									Email address
								</div>{' '}
								<div className='personalCheck-overFullNameInput'>
									<input type='text' disabled={true} />
								</div>
							</div>

							<div className='personalCheck-overFull-line'></div>
							<div
								onClick={updateProfile}
								className='personalCheck-overFull-save'
							>
								Update profile
							</div>
							<div className='personalCheck-overFull-line'></div>

							<div className='personalCheck-password-cont'>
								<div className='personalCheck-ppppppp'>
									<div className='personalCheck-password-label'>Password</div>
									<div className='personalCheck-password-input'>
										<input
											type={showPass ? 'text' : 'password'}
											placeholder='Enter passsword'
											onChange={(e) => {
												setPassword({ ...password, oldPass: e.target.value });
											}}
											value={password.oldPass}
										/>

										<span onClick={passwordVisiblity}>
											{showPass ? (
												<img className='password-icon' src={eye} alt='eye' />
											) : (
												<img className='password-icon' src={eye} alt='eye' />
											)}
										</span>
									</div>
								</div>

								<div className='personalCheck-ppppppp'>
									<div className='personalCheck-password-label'>
										New Password
									</div>
									<div className='personalCheck-password-input'>
										<input
											type={showPassTwo ? 'text' : 'password'}
											placeholder='Enter New Passsword'
											onChange={(e) => {
												setPassword({ ...password, newPass: e.target.value });
											}}
											value={password.newPass}
										/>

										<span onClick={passwordVisiblityTwo}>
											{showPassTwo ? (
												<img className='password-icon' src={eye} alt='eye' />
											) : (
												<img className='password-icon' src={eye} alt='eye' />
											)}
										</span>
									</div>
								</div>
							</div>

							<div
								onClick={onSubmitAddress}
								className='personalCheck-overFull-save'
							>
								Update password
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default PersonalInfoCheckout;
