import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { axiosCalls } from '../../_api'
import validator from 'validator'
import { showLoader, hideLoader } from '../../loader'

import { Link } from 'react-router-dom'
import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg'
import eye from '../../assets/icon/eye.svg'
import './createAccount.scss'
import { Toast } from '../../helpers/toast/index'

const CreateAccount = () => {
  const [auth, setAuth] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    password_confirmation: '',
    has_child: 0,
  })

  const [emailAd, setEmailAd] = useState('')

  const [emailError, setEmailError] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)

  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Please Enter valid a Email!')
    }
  }

  const createAccountAuth = async (e) => {
    e.preventDefault()

    const data = {
      first_name: auth.first_name,
      last_name: auth.last_name,
      email: auth.email,
      phone_no: auth.phone_number,
      password: auth.password,
      password_confirmation: auth.password_confirmation,
      has_child: auth.has_child,
    }

    showLoader()

    const res = await axiosCalls('register', 'POST', data)

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)

        return
      }

      Toast(
        'success',
        'Account created successfully, Please check your email to continue.'
      )

      setTimeout(() => {
        window.location.href = '/login'
      }, 3000)
      // localStorage.setItem("token", res.data.auth.token);

      // window.location.href = "/success-signup";
      // window.location.href = "/success-signup";
    }

    // window.location.href = "/cart-page";
  }

  return (
    <div>
      <div className='cAccount-page-header'>
        <div className='cAccount-page-logo'>
          <img src={bubbleColonyLogoHome} alt='home_logo' />
        </div>

        {/* <Link to='/login' style={{ textDecoration: 'none' }}>
          <div className='create-acc-btn'>Login</div>
        </Link> */}
      </div>

      <div className='cAccount-page-fam'>
        <div className='cAccount-text-page'>Create account</div>
        <form onSubmit={createAccountAuth} className='cAccount-input-fam'>
          <div
            className='input-cont'
            style={{
              marginTop: '10px',
            }}
          >
            <label>First Name</label>
            <input
              type='text'
              placeholder='Enter first name'
              onChange={(e) => {
                setAuth({ ...auth, first_name: e.target.value })
              }}
            />
          </div>

          <div className='input-cont'>
            <label>Last Name</label>
            <input
              type='text'
              placeholder='Enter last name'
              onChange={(e) => {
                setAuth({ ...auth, last_name: e.target.value })
              }}
            />
          </div>

          <div className='input-cont'>
            <label>Email address</label>
            <input
              type='email'
              placeholder='Enter email address'
              onChange={(e) => {
                setAuth({ ...auth, email: e.target.value })
                validateEmail(e)
                // setEmailAd(e.target.value);
              }}
            />

            {emailError && (
              <div
                style={{
                  fontSize: '10px',
                  marginLeft: '20px',
                  color: 'red',
                }}
              >
                {emailError}
              </div>
            )}
          </div>

          <div className='input-cont'>
            <label>Phone number</label>
            <input
              type='tel'
              placeholder='Enter phone number'
              onChange={(e) => {
                setAuth({ ...auth, phone_number: e.target.value })
              }}
            />
          </div>

          <div className='input-cont'>
            <label>Password</label>

            <div className='pass-cont'>
              <input
                type={showPass ? 'text' : 'password'}
                placeholder='Enter password'
                onChange={(e) => {
                  setAuth({ ...auth, password: e.target.value })
                }}
              />

              <span onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <img className='password-icon' src={eye} alt='eye' />
                ) : (
                  <img className='password-icon' src={eye} alt='eye' />
                )}
              </span>
            </div>
          </div>

          <div className='cAccount-password-requirement'>
            <span>Password must be at least 8 characters.</span>
            <span>
              Password must contain at least one uppercase and one lowercase
              letter.
            </span>
            <span>The password must contain at least one symbol.</span>
            <span>The password must contain at least one number.</span>
          </div>

          <div className='input-cont'>
            <label>Confirm Password</label>
            <div className='pass-cont'>
              <input
                type={showPassConfirm ? 'text' : 'password'}
                placeholder='Enter password again'
                onChange={(e) => {
                  setAuth({ ...auth, password_confirmation: e.target.value })
                }}
              />

              <span onClick={() => setShowPassConfirm(!showPassConfirm)}>
                {showPassConfirm ? (
                  <img className='password-icon' src={eye} alt='eye' />
                ) : (
                  <img className='password-icon' src={eye} alt='eye' />
                )}
              </span>
            </div>
          </div>

          <div className='cAccount-btn'>
            <input type='submit' value='Create Account' />
          </div>

          <div className='cAccount-terms'>
            By signing up you accept our terms and conditions & privacy policy
          </div>

          <div className='newTo'>
            <p>Already have an account?</p> <a href='/login'>Sign in</a>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateAccount
