import React from "react";
import "./singleVendorLeft.scss";
import customerOwner from "../../../../../assets/icon/customerOwner.svg";

const SingleVendorLeft = () => {
  return (
    <div className="singleVendor-bigFlexOne">
      <div className="singleCustomer-salesDetails">
        <div className="singleCustomer-salesSingle">
          <div className="singleCustomer-sales">Total Order Placed</div>
          <div className="singleCustomer-salesNo">12</div>
        </div>

        <div className="singleCustomer-salesSingle">
          <div className="singleCustomer-sales">Total Order Placed</div>
          <div className="singleCustomer-salesNo">
            <span className="singleCustomer-salesImg">
              <img src={customerOwner} />
            </span>
            12
          </div>
        </div>
      </div>

      <div className="singleCustomer-saleConFam">
        <div className="singleCustomer-saleConNam">Contact Name</div>
        <div className="singleCustomer-salePhone">Contact Phone number</div>
      </div>
      <div className="singleCustomer-saleNameFam">
        <div className="singleCustomer-saleNameText">Adebusayo Obisesan</div>
        <div className="singleCustomer-salePhoeNum">07045495450</div>
      </div>

      <div className="singleCustomer-saleEmail">Email address</div>
      <div className="singleCustomer-saleEmailText">
        michael.mitc@example.com
      </div>
    </div>
  );
};

export default SingleVendorLeft;
