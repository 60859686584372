import React, { useContext, useEffect, useState } from 'react'
import './style.scss'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import { CartContext } from '../../context/cart-context'
import { useParams } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import NewAdditions from '../../components/newAdditions/NewAdditions'
import { axiosCalls } from '../../_api'
import Pagination from '../../components/pagination/Pagination'

const CategoryProducts = () => {
  const { productCategory, productCategoryLoading } = useContext(CartContext)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCategoryLoading, setSelectedCategoryLoading] = useState(true)
  const { category_id } = useParams()

  //Fetch Category products
  const [product, setProduct] = useState([])
  const [productLoading, setProductLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const getProduct = async (num) => {
    setProductLoading(true)
    const res = await axiosCalls(
      `product-category/${category_id}?page=${num}&number=12`,
      'get'
    )
    if (res) {
      setProductLoading(false)
      if (res.er) {
        setProductLoading(false)
        return
      }
      setProduct(res.data)
    }
    setTimeout(() => {
      setProductLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getProduct(1)
  }, [])
  const paginationClick = (num) => {
    getProduct(num)
  }

  useEffect(() => {
    if (!productCategoryLoading) {
      const category = productCategory.find(
        (item) => item.id === parseInt(category_id)
      )
      setSelectedCategory(category)
      setSelectedCategoryLoading(false)
      getProduct(1)
    }
  }, [productCategoryLoading, productCategory, category_id])
  return selectedCategoryLoading ? (
    <Loader direction={'center'} />
  ) : (
    <div>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${selectedCategory.banner_image})`,
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '350px',
        }}
        className='banner'
      >
        <h2>{selectedCategory.name}</h2>
      </div>
      <div className='category-content'>
        <p>Showing {product.total} results</p>

        <NewAdditions
          headingTag=''
          data={product.data}
          loading={productLoading}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={
            Math.ceil(product.total / product.per_page)
              ? Math.ceil(product.total / product.per_page)
              : 0
          }
          pageSize={12}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      </div>
      <Footer />
    </div>
  )
}

export default CategoryProducts
