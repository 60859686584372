const Cities = {
  Rwanda: ['Kigali', 'Huye'],
  Somalia: [
    'Oddur',
    'Mogadishu',
    'Merca',
    'Giohar',
    'Hargeisa',
    'Garbahaarrey',
    'Gaalkacyo',
    'Dusa Marreb',
    'Erigavo',
    'Burao',
    "Bu'aale",
    'Bosaso',
    'Beledweyne',
    'Baidoa',
    'Las Anod',
    'Afgooye',
    'Baki',
  ],
  Yemen: [
    'Zinjibar',
    'Sanaa',
    'Lahij',
    'Ibb',
    'Hajjah',
    'Hadibu',
    'Dhamar',
    'Bayt Ghadir',
    'Ataq',
    'As Salif',
    'Mocha',
    'Mukalla',
    'Al Mahwit',
    'Al Hudaydah',
    'Al Ghayḑah',
    'Dhalie',
    'Al Jabin',
    'Al Hazm',
    'Al Bayda',
    'Aden',
    'Marwath',
    'Nayd al Kharb',
  ],
  Libya: [
    'Tobruk',
    'Massah',
    'Darnah',
    'Benghazi',
    'At Taj',
    'Al Marj',
    'Al Jawf',
    "Al Bayda'",
    'Al Abraq',
    'Ajdabiya',
    'Zuwarah',
    'Waddan',
    'Tripoli',
    'Sirte',
    'Sabratah',
    'Nalut',
    'Murzuq',
    'Misratah',
    'Hun',
    'Zawiya',
    'Ghat',
    'Gharyan',
    'Ghadames',
    'Ubari',
    'Al Khums',
    "Al 'Aziziyah",
    'Idri',
  ],
  Iraq: [
    'Tikrit',
    'Raniye',
    'Kaway',
    'Kirkuk',
    'Karbala',
    'Kelar',
    'Erbil',
    'Diwaniyah',
    'Dahuk',
    'Baqubah',
    'Baghdad',
    'Madinat Babil',
    'Sulaymaniyah',
    'As Samawah',
    'Nasiriyah',
    'Najaf',
    'Al Musayyib',
    'Mosul',
    'Kut',
    'Al Hillah',
    'Nahiyat Ghammas',
    'Basrah',
    'Amara',
    "'Afak",
    'Ad Diwaniyah',
    'Ad Dawrah',
    'Abi al Khasib',
    'Wasit',
    'Sinjar',
    "Qaryat al 'Ankur",
  ],
  'Saudi Arabia': [
    'Yanbu',
    'Unaizah',
    'Tabuk',
    'Tubarjal',
    'Sayhat',
    'Sakakah',
    'Safwa',
    'Sabya',
    'Ras Tanura',
    "Ra's al Khafji",
    'Rafha',
    'Rabigh',
    'Najran',
    'Muhayil',
    'Marat',
    'Makkah',
    'Khulays',
    'Khaybar',
    'Khamis Mushait',
    'Jizan',
    'Jeddah',
    "Ha'il",
    'Farasan',
    'Damad',
    'Buraidah',
    'Abqaiq',
    'Badr',
    'Az Zulfi',
    'Dhahran',
    "Ta'if",
    'Ath Thuqbah',
    'Sajir',
    'Ash Shuqayq',
    'Riyadh',
    'Arar',
    'Qurayyat',
    'Al `Udayliyah',
    'Al Qatif',
    'Al Mubarraz',
    'Al Mindak',
    'Medina',
    'Khobar',
    'Al Kharj',
    'Jubail',
    'Al Jubayl',
    'Al Hufuf',
    'Al Hariq',
    'Al Bahah',
    'Dammam',
    'Abha',
    'Thuwal',
    'Al Madinah',
    'Al Faruq',
    'Riman',
    'Saghir',
  ],
  Iran: [
    'Zanjan',
    'Yazd',
    'Tonekabon',
    'Tehran',
    'Tabriz',
    'Shirvan',
    'Shiraz',
    'Sari',
    'Sanandij',
    'Rasht',
    'Qazvin',
    'Urmia',
    'Mashhad',
    'Marand',
    'Maragheh',
    'Malayer',
    'Kish',
    'Khowy',
    'Khorramabad',
    'Kermanshah',
    'Kerman',
    'Karaj',
    'Ilam',
    'Hamadan',
    'Garmsar',
    'Bandar-e Genaveh',
    'Farsun',
    'Damavand',
    'Bushehr',
    'Birjand',
    'Babol',
    'Ardabil',
    'Arak',
    'Amol',
    'Ahvaz',
    'Abadan',
    'Isfahan',
    'Zahedan',
  ],
  Angola: [
    'Saurimo',
    'Lucapa',
    'Lumeje',
    'Viana',
    'Uíge',
    'Soyo',
    'Sanza Pombo',
    'Piri',
    "N'dalatando",
    'Muxaluando',
    'Malanje',
    'Luanda',
    'Benfica',
    'Cazeta',
    'Camabatela',
    'Cabinda',
    'Ambriz',
    'Talatona',
    'Sumbe',
    'Ondjiva',
    'Namibe',
    'Menongue',
    'Lubango',
    'Lobito',
    'Kuvango',
    'Kuito',
    'Huambo',
    'Catumbela',
    'Benguela',
    'Paredes',
  ],
  Cyprus: [
    'Germasogeia',
    'Geri',
    'Xylotymbou',
    'Tseri',
    'Voroklini',
    'Strovolos',
    'Sotira',
    'Pyrga',
    'Prodromi',
    'Polis',
    'Pissouri',
    'Frenaros',
    'Foinikaria',
    'Pegeia',
    'Perivolia',
    'Paralimni',
    'Paphos',
    'Pano Platres',
    'Pano Lefkara',
    'Ormidhia',
    'Nicosia',
    'Mosfiloti',
    'Moni',
    'Mesa Geitonia',
    'Meneou',
    'Mazotos',
    'Mandria',
    'Lythrodontas',
    'Livadia',
    'Liopetri',
    'Limassol',
    'Latsia',
    'Larnaca',
    'Lapta',
    'Kyrenia',
    'Kouklia',
    'Kornos',
    'Kofinou',
    'Konia',
    'Kokkinotrimithia',
    'Kiti',
    'Kissonerga',
    'Chlorakas',
    'Kato Lakatameia',
    'Kathikas',
    'Kampia',
    'Kakopetria',
    'Famagusta',
    'Aglantzia',
    'Erimi',
    'Egkomi',
    'Dhromolaxia',
    'Deryneia',
    'Dali',
    'Agios Tychon',
    'Saint Athanasios',
    'Agia Varvara',
    'Ayia Napa',
    'Agia Marina',
    'Agia Anna',
    'Avgorou',
    'Achna',
    'Athienou',
    'Astromeritis',
    'Aradippou',
    'Anarita',
    'Akrounta',
    'Akaki',
    'Agros',
    'Agrokipia',
    'Geroskipou Municipality',
  ],
  Azerbaijan: [
    'Nakhchivan',
    'Zaqatala',
    'Sumqayit',
    'Qusar',
    'Qax',
    'Xirdalan',
    'İsmayıllı',
    'Ganja',
    'Baku',
    'Absheron',
  ],
  Tanzania: [
    'Zanzibar',
    'Wete',
    'Ushirombo',
    'Tunduma',
    'Tanga',
    'Tabora',
    'Sumbawanga',
    'Somanda',
    'Singida',
    'Shinyanga',
    'Njombe',
    'Mwanza',
    'Musoma',
    'Mpanda',
    'Moshi',
    'Morogoro',
    'Mbeya',
    'Masaki',
    'Makumbako',
    'Mafinga',
    'Korogwe',
    'Koani',
    'Kisiju',
    'Kigoma',
    'Kibaha',
    'Kasulu',
    'Kahama',
    'Iringa',
    'Inyonga',
    'Handeni',
    'Geita',
    'Gamba',
    'Dodoma',
    'Dar es Salaam',
    'Chake Chake',
    'Bukoba',
    'Babati',
    'Arusha',
    'Songea',
    'Mtwara',
    'Lindi',
  ],
  Turkmenistan: [
    'Kurtli',
    'Gyami',
    'Gumdag',
    'Ashgabat',
    'Boldumsaz',
    'Yoloeten',
    'Atamyrat',
    'Gowurdak',
    'Farap',
    'Bayramaly',
    'Birata',
  ],
  Syria: ['Hama', 'Aleppo', 'Damascus', 'Latakia', 'Ad Darah'],
  Israel: [
    'Majdal Shams',
    'Jerusalem',
    'Har Adar',
    'Tekoa',
    'Modi‘in Makkabbim Re‘ut',
    'Gadot',
    "Be'er Toviyya",
    'Zur Moshe',
    'Zur Hadassa',
    'Zofit',
    'Ziqim',
    'Zippori',
    'Zelafon',
    'Misgav Dov',
    'Zekharya',
    'Safed',
    'Zafririm',
    'Yuval',
    'Yotvata',
    'Yirka',
    'Yinnon',
    'Yiftah',
    'Yeruham',
    'Yehud',
    'Yedidya',
    'Yaziz',
    "Yavne'el",
    'Yavne',
    'Yashresh',
    'Yarqona',
    'Yarhiv',
    'Yaqum',
    'Yanuv',
    'Yagur',
    'Yagel',
    'Jaffa',
    'Yad Rambam',
    'Yad Natan',
    'Yad Hanna',
    'Yad Binyamin',
    'Usha',
    'Urim',
    'Umm el Fahm',
    'Udim',
    'Tuba Zangariya',
    'Tirat Zevi',
    'Tirat Yehuda',
    'Tirat Carmel',
    'Timrat',
    'Timmorim',
    'Tiberias',
    'Tequma',
    'Tenuvot',
    'Tel Yosef',
    'Tel Mond',
    'Tel Aviv',
    'Tarum',
    "Ma'alot-Tarshiha",
    'Tamra',
    'Tal Shahar',
    'Talme Yafe',
    'Talme Menashe',
    'Talme El`azar',
    'Sitriyya',
    'Shuva',
    'Shoval',
    'Shoresh',
    'Shoqeda',
    'Shetulim',
    'Shelomi',
    'Shefer',
    'Shefayim',
    'Shave Ziyyon',
    'Sharona',
    'Shamir',
    'Shahar',
    'Shafir',
    'Shadmot Devora',
    'Segev',
    'Sdot Yam',
    'Sde Zevi',
    "Sde Ya'aqov",
    'Sde Warburg',
    'Sderot',
    'Sde Moshe',
    'Sde Dawid',
    'Sde Boker',
    'Savyon',
    'Sasa',
    'Sarid',
    'Sakhnin',
    'Sa`ad',
    'Ruhama',
    'Rosh Pinna',
    'Rosh Ha‘Ayin',
    'Rishpon',
    'Rishon LeZiyyon',
    'Rinnatya',
    'Revivim',
    'Revadim',
    'Reshafim',
    'Rekhasim',
    'Rehovot',
    'Regba',
    'Regavim',
    'Rannen',
    'Ramot Naftali',
    'Ramot Menashe',
    'Ramot HaShavim',
    'Ramla',
    'Ramat Yishai',
    'Ramat HaShofet',
    'Ramat HaSharon',
    'Ramat HaKovesh',
    'Ramat Gan',
    'Ramat Dawid',
    'Ramat Aviv',
    'Rama',
    'Raanana',
    'Qiryat Ye`arim',
    'Qiryat Yam',
    'Qiryat Tiv`on',
    'Qiryat Shemona',
    'Qiryat Moẕqin',
    "Qiryat Mal'akhi",
    'Kiryat Gat',
    'Qiryat Bialik',
    'Qiryat Ata',
    'Qiryat `Anavim',
    'Qidron',
    'Qevuzat Yavne',
    'Qelahim',
    'Qalansuwa',
    'Kadima',
    'Poriyya',
    'Porat',
    'Nefat Petah Tiqwa',
    'Petaẖ Tiqwa',
    'Pedaya',
    'Pattish',
    'Pardesiyya',
    'Pardes Hanna Karkur',
    'Palmahim',
    'Or Yehuda',
    'Orot',
    'Or HaNer',
    'Or Akiva',
    'Oranim',
    'Ora',
    'Qiryat Ono',
    'Omez',
    'Omen',
    '`Olesh',
    'Ofakim',
    'Nordiyya',
    'Nofekh',
    'Nir Zevi',
    "Nir Yisra'el",
    'Nir Yafe',
    'Nir Moshe',
    'Nirim',
    'Nir Hen',
    'Nir Dawid',
    'Nir Banim',
    'Nir `Aqiva',
    'Newe Yamin',
    'Newe Ur',
    'Newe Mivtah',
    'Newe Ilan',
    'Nevatim',
    'Netivot',
    'Netiv HaLamed He',
    'Netanya',
    'Ness Ziona',
    'Nesher',
    'Nes Harim',
    'Nes `Ammim',
    'Nein',
    'Nehora',
    'Nehalim',
    'Negba',
    'Nazerat `Illit',
    'Nazareth',
    'Nahsholim',
    'Nahariya',
    'Nahal `Oz',
    'Nahalal',
    'Moledet',
    'Mitzpe Ramon',
    'Mizpa',
    'Mishmeret',
    'Mishmarot',
    'Mishmar HaYarden',
    'Mishmar HaSharon',
    'Mishmar HaNegev',
    'Mikhmoret',
    'Migdal Ha‘Emeq',
    'Migdal',
    'Mevo Beiter',
    'Meshar',
    'Merhavya',
    'Menahemya',
    'Megiddo',
    'Megadim',
    'Mefallesim',
    'Mazliah',
    'Mazkeret Batya',
    "Massu'a",
    'Massada',
    'Ma`oz Hayyim',
    'Majd el Kurum',
    'Mahanayim',
    'Magshimim',
    'Maghar',
    'Maggal',
    'Magen',
    'Mabbu`im',
    "Ma`agan Mikha'el",
    'Luzit',
    'Lod',
    'Li On',
    'Lavi',
    'Lakhish',
    'Lahavot HaBashan',
    'Kissufim',
    'Kisra',
    'Kinneret',
    'Kesalon',
    'Kerem Maharal',
    'Kfar Yona',
    'Kefar Yehezqel',
    'Kefar Vitqin',
    'Kefar Tavor',
    'Kefar Sirkin',
    'Kfar Saba',
    'Kefar Kisch',
    'Kefar Netter',
    'Kefar Menahem',
    'Kefar Hittim',
    'Kfar Hess',
    'Kefar HaRif',
    'Kefar HaNagid',
    'Kefar HaMakkabbi',
    'Kefar HaHoresh',
    'Kefar Daniyyel',
    'Kefar Ahim',
    'Kaukab Abu el Hija',
    'Karmi’el',
    'Karkur',
    'Karkom',
    'Kannot',
    'Kfar Yasif',
    'Kafr Manda',
    'Kafr Kamma',
    'Kabul',
    'Julis',
    'Jish',
    'Jatt',
    '`Isfiya',
    'Ilaniyya',
    'Iksal',
    'Hurfeish',
    'Hulda',
    'Hulata',
    'Hosen',
    'Holon',
    'Hogla',
    'Hofit',
    'Hod HaSharon',
    'Hibbat Ziyyon',
    'Hever',
    'Herzliya',
    'Herut',
    "Herev Le'et",
    'Hemed',
    'Haifa',
    'HaZore`im',
    'Hazor Ashdod',
    'Hazor HaGelilit',
    'Hazeva',
    'Hazav',
    'HaYogev',
    'Havazzelet HaSharon',
    'HaSolelim',
    'Haruzim',
    'Ha`Ogen',
    "Hanni'el",
    'Hanita',
    'Merkaz HaKarmel',
    'HaHoterim',
    'HaGosherim',
    'Hagor',
    'Hadid',
    'Hadera',
    'Hadar `Am',
    'HaBonim',
    'Goren',
    'Giv‘at Shmuel',
    'Giv`at Hayyim Ihud',
    'Giv‘atayim',
    'Ginnosar',
    'Ginnegar',
    'Ginnaton',
    'Gimzo',
    'Gilat',
    'Gibbeton',
    'Gezer',
    'Gevulot',
    'Gevim',
    'Gevat',
    "Ge'ullim",
    'Gesher HaZiw',
    'Gesher',
    'Gelil Yam',
    'Gedera',
    "Ge'a",
    'Gazit',
    'Gat Rimmon',
    'Gan Yoshiyya',
    'Gan Yavne',
    'Gan Soreq',
    'Gannot',
    'Ganne Yohanan',
    'Ganei Tikva',
    'Gan HaDarom',
    'Pasuta',
    '`Evron',
    'Even Yehuda',
    'Et Taiyiba',
    'Taiyiba',
    'Etan',
    'Eshkolot',
    'Ein Vered',
    '`En Hod',
    '`En HaShelosha',
    'Ein Gedi',
    '`Enat',
    'Emunim',
    'Elyakhin',
    'Elqosh',
    'Elot',
    'Elon',
    'El Makr',
    'Elifelet',
    'El Fureidis',
    'Eilat',
    'Dorot',
    'Dor',
    'Dishon',
    'Dimona',
    'Deir Hanna',
    'Deir el Asad',
    'Dan',
    'Daliyya',
    'Daliyat al Karmel',
    'Dabburiya',
    'Bustan HaGalil',
    'Buregeta',
    'Bizzaron',
    'Bitan Aharon',
    'Binyamina',
    'Bezet',
    'Bet Zera`',
    'Bet Yosef',
    'Bet Yehoshua`',
    'Bet Yannay',
    'Bet Shiqma',
    'Bet Shemesh',
    'Bet She’an',
    'Bet Neqofa',
    'Bet Nehemya',
    "Bet Me'ir",
    'Bet Lehem HaGelilit',
    'Bet Hillel',
    'Bet Hanan',
    'Bet HaLewi',
    'Bet Dagan',
    'Bet `Arif',
    'Bet Alfa',
    'Berosh',
    'Beqoa`',
    'Ben Zakkay',
    'Ben Shemen',
    'Bene Darom',
    'Bnei Brak',
    'Benaya',
    'Ben `Ammi',
    'Beit Jann',
    'Beersheba',
    "Be'erotayim",
    'Bazra',
    'Bat Yam',
    'Barqay',
    'Barnea`',
    'Bareqet',
    'Baraq',
    'Baqa el Gharbiya',
    'Bahan',
    '`Azriqam',
    'Azor',
    'Ayyelet HaShahar',
    'Avital',
    'Avihayil',
    'Avigedor',
    '`Avdon',
    'Athlit',
    'Ashquelon',
    'Ashdod',
    '`Aseret',
    '`Arugot',
    'Arbel',
    'Arad',
    '`Ammi`oz',
    'Amirim',
    '`Amir',
    'Alummot',
    '`Alumim',
    'Almagor',
    'Allonim',
    'Allone Abba',
    'Acre',
    'Ahuzzam',
    'Ahituv',
    'Ahihud',
    'Afula',
    'Afiqim',
    'Adamit',
    'Abu Sinan',
    'Zofar',
    'Shibli',
    'Massad',
    'Avtalyon',
    'Hararit',
    'Shekhanya',
    'Misgav Regional Council',
    'Kallanit',
    'Lotem',
    'Sallama',
    'Kammon',
    'Zurit',
    'Tuval',
    'Harashim',
    'Kefar Weradim',
    'Bet Hashmonay',
    'Mazor',
    'Ramat Ef`al',
    'Qatzrin',
    'Newe Ativ',
    'Ashalim',
    'Ketura',
    'Lehavim',
    'maale adumim',
    'Caesarea',
    'Meitar',
    'Lavon',
    'Yated',
    'Sufa',
    'Zohar',
    'Netiv Ha`Asara',
    'Bat Hadar',
    'Merkaz Shapira',
    'Kahal',
    "Kokhav Ya'ir",
    'Bat Hefer',
    'Harish',
    'Qazir',
    'Ramat Poleg',
    'Zemer',
    'Karme Yosef',
    'Gizo',
    'Gonen',
    'Rahat',
    'Modiin Ilit',
    'Nof Ayalon',
    'Shilat',
    'Hashmonaim',
    'Lapid',
    'Mevo horon',
    'Beit Horon',
    'Nili',
    'Naale',
    'Nahliel',
    'Talmon',
    'Ariel',
    'Revava',
    'Manof',
    'Zarzir',
    'Shimshit',
    'Nofit',
    'Mattat',
    'Zoran',
    'Hannaton',
    'Gan Ner',
    'Metar',
    'Shoham',
    'Matan',
    'Yehud-Monosson',
    'Nataf',
    'Kfar Giladi',
    'Kadima Zoran',
    'Yoqneam',
    'Maale Iron',
    'Kiryat Ono',
  ],
  Armenia: [
    'Yeghegnadzor',
    'Malishka',
    'Jermuk',
    'Artashat',
    'Ararat',
    'Agarak',
    'Yerevan',
    'Vagharshapat',
    'Yeghvard',
    'Tsaghkadzor',
    'Spitak',
    'Shirak',
    'Sevan',
    'Nor Geghi',
    'Nor Hachyn',
    'Mayakovski',
    'Maralik',
    'Lerrnanist',
    'Aragatsotn',
    'Vanadzor',
    'Gavarr',
    'Ijevan',
    'Hrazdan',
    'Gyumri',
    'Gogaran',
    'Dilijan',
    'Charentsavan',
    'Berd',
    'Ashtarak',
    'Aparan',
    'Aghavnatun',
    'Abovyan',
    'Azatamut',
  ],
  Kenya: [
    'Vipingo',
    'Vihiga',
    'Ukunda',
    'Timau',
    'Thika',
    'Sotik',
    'Ruiru',
    'Ruaraka',
    'Nyeri',
    'Ngecha',
    'Nanyuki',
    'Nakuru',
    'Naivasha',
    'Nairobi',
    'Mombasa',
    'Migori',
    'Meru',
    'Maseno',
    'Mandera',
    'Malindi',
    'Machakos',
    'Lugulu',
    'Kitale',
    'Kisumu',
    'Kisii',
    'Kinoo',
    'Kikuyu',
    'Kigumo',
    'Kiambu',
    'Kerugoya',
    'Keroka',
    'Kericho',
    'Karatina',
    'Kalimoni',
    'Kajiado',
    'Juja',
    'Gatundu',
    'Garissa',
    'Embu',
    'Eldoret',
    'Eldama Ravine',
    'Chuka',
    'Athi River',
    'Diani',
    'Lavington',
  ],
  Congo: [
    'Yangambi',
    'Yanga-Libenge',
    'Sungu-Monga',
    'Mwene-Ditu',
    'Mbuji-Mayi',
    'Maluku',
    'Luebo',
    'Lodja',
    'Kongolo',
    'Kindu',
    'Isiro',
    'Goma',
    'Gandajika',
    'Bunia',
    'Bukavu',
    'Boende',
    'Beni',
    'Aketi',
    'Lubumbashi',
    'Kolwezi',
    'Mbanza-Ngungu',
    'Mbandaka',
    'Kinshasa',
    'Kikwit',
    'Inongo',
    'Banana',
  ],
  Djibouti: [
    'Tadjoura',
    'Obock',
    'Djibouti',
    'Dikhil',
    'Danan',
    'Arta',
    'Ali Sabih',
    'Balbala',
  ],
  Uganda: [
    'Mukono',
    'Mityana',
    'Mbarara',
    'Mbale',
    'Kolokolo',
    'Kampala',
    'Jinja',
    'Ibanda',
    'Gulu',
    'Ggaba',
  ],
  'Central African Republic': [
    'Zemio',
    'Ouango',
    'Ouanda Djallé',
    'Ouadda',
    'Obo',
    'Kembe',
    'Ippy',
    'Bria',
    'Birao',
    'Bangassou',
    'Bamingui',
    'Bambari',
    'Alindao',
    'Paoua',
    'Nola',
    'Mongoumba',
    'Mbaïki',
    'Kaga-Bandoro',
    'Kabo',
    'Dekoa',
    'Damara',
    'Carnot',
    'Bozoum',
    'Bouar',
    'Bimbo',
    'Berbérati',
    'Baoro',
    'Bangui',
    'Bambio',
  ],
  Seychelles: [
    'Victoria',
    'Takamaka',
    'Port Glaud',
    'Cascade',
    'Bel Ombre',
    'Beau Vallon',
    'Anse Royale',
    'Anse Boileau',
    'Anse aux Pins',
    'Baie Lazare',
    'La Passe',
    'Pointe La Rue',
  ],
  Chad: [
    'Fada',
    'Biltine',
    'Am Timan',
    'Abéché',
    'Ziguey',
    'Tchiming',
    'Dordom Soutoa',
    'Sarh',
    'Salal',
    'Poumgou',
    "N'Djamena",
    'Melfi',
    'Massenya',
    'Massakory',
    'Massaguet',
    'Goundi',
    'Faya-Largeau',
    'Djamena',
    'Bongor',
    'Benoy',
    'Bebedja',
    'Ati',
    'Aozou',
    'Am Djarass',
  ],
  'Hashemite Kingdom of Jordan': [
    'Sakib',
    'Raymun',
    'Madaba',
    'Jerash',
    'Irbid',
    'Busayra',
    'Zarqa',
    'At Tafilah',
    'Salt',
    'As Safi',
    'Ash Shawbak',
    'Ar Ramtha',
    'Amman',
    'Mafraq',
    'Al Burj',
    'Aqaba',
    'Karak',
    'Al Katrah',
    'Hanina',
  ],
  Greece: [
    'Zakynthos',
    'Zacharo',
    'Gytheion',
    'Gyali',
    'Gerakas',
    'Xylokastro',
    'Vouliagmeni',
    'Voula',
    'Vonitsa',
    'Volos',
    'Moschato',
    'Vyronas',
    'Vilia',
    'Veria',
    'Velestino',
    'Vagia',
    'Vathy',
    'Vasilika',
    'Vartholomio',
    'Varkiza',
    'Vari',
    'Varda',
    'Vamos',
    'Tripoli',
    'Trikala',
    'Ialysos',
    'Tyrnavos',
    'Tinos',
    'Tympaki',
    'Thebes',
    'Fira',
    'Thespiae',
    'Thermo',
    'Theologos',
    'Tavros',
    'Stoupa',
    'Stamata',
    'Stalida',
    'Spetses',
    'Spata',
    'Sparti',
    'Sparta',
    'Souda',
    'Sofades',
    'Skopelos Town',
    'Skiathos',
    'Schimatari',
    'Skala Kallonis',
    'Skala',
    'Sitia',
    'Symi',
    'Samos',
    'Salamina',
    'Salakos',
    'Rethymno',
    'Rafina',
    'Psychiko',
    'Preveza',
    'Poros',
    'Plomari',
    'Pyrgos Dirou',
    'Pyrgos',
    'Piraeus',
    'Pylos',
    'Pikermi',
    'Pefki',
    'Petroupolis',
    'Petras',
    'Peristeri',
    'Perama',
    'Gaios',
    'Pátrai',
    'Patmos',
    'Paros',
    'Parga',
    'Paramythia',
    'Paralio Astros',
    'Paralia Avlidos',
    'Pallini',
    'Agioi Anargyroi',
    'Palamas',
    'Old Faliron',
    'Palaia Fokaia',
    'Palaia Epidavros',
    'Oropos',
    'Oreoi',
    'Oia',
    'Nikaia',
    'Karlovasi',
    'Neo Faliro',
    'Nemea',
    'Nea Smirni',
    'Nea Smyrni',
    'Neapoli',
    'Nea Peramos',
    'Nea Makri',
    'Nea Liosia',
    'Nea Kios',
    'Nea Ionia',
    'Nea Erythraia',
    'Nea Epidavros',
    'Nea Artaki',
    'Naxos',
    'Nafplion',
    'Nafpaktos',
    'Monemvasía',
    'Moires',
    'Mytilene',
    'Molivo',
    'Milos',
    'Metsovo',
    'Methana',
    'Metamorfosi Troizinos',
    'Messini',
    'Messolonghi',
    'Melissia',
    'Meligalas',
    'Megara',
    'Megalopolis',
    'Matala',
    'Marmari',
    'Markopoulo Mesogaias',
    'Marathon',
    'Marathokampos',
    'Mandra',
    'Malia',
    'Malesina',
    'Magoula',
    'Loutsa',
    'Loutraki',
    'Loutra Aidipsou',
    'Lixouri',
    'Agios Andreas',
    'Livanates',
    'Porto Rafti',
    'Lykovrysi',
    'Lefkada',
    'Livadeia',
    'Leonidio',
    'Lazarina',
    'Lavrio',
    'Larissa',
    'Larymna',
    'Lamia',
    'Kryoneri',
    'Krestena',
    'Kranidi',
    'Kouvaras',
    'Kos',
    'Koropi',
    'Koroni',
    'Corinth',
    'Domvraina',
    'Korydallos',
    'Kokla',
    'Kythnos',
    'Cythera',
    'Kissamos',
    'Kirinthos',
    'Kyriaki',
    'Kyparissia',
    'Kymi',
    'Kifissia',
    'Kiato',
    'Cholargos',
    'Chios',
    'Agios Konstantinos',
    'Chania',
    'Chalkoutsi',
    'Chalcis',
    'Chalandri',
    'Chaidari',
    'Keratsini',
    'Keratea',
    'Kefalos',
    'Kea',
    'Metamorfosi',
    'Kato Tithorea',
    'Kato Achaia',
    'Katakolo',
    'Kastelli',
    'Karpenisi',
    'Karystos',
    'Karditsa',
    'Kardamena',
    'Kapandriti',
    'Kanalaki',
    'Kamena Vourla',
    'Kamatero',
    'Kalloni',
    'Kallithea',
    'Kalyvia Thorikou',
    'Kalyvia Livadiou',
    'Kalymnos',
    'Kalambaka',
    'Kalamata',
    'Alimos',
    'Ithaki',
    'Itea',
    'Istiaia',
    'Irakleio',
    'Heraklion',
    'Ios',
    'Ioannina',
    'Ilias',
    'Igoumenitsa',
    'Ierapetra',
    'Hydra',
    'Anoixi',
    'Glyfada',
    'Gastouni',
    'Gargalianoi',
    'Galaxidi',
    'Galatsi',
    'Galatas',
    'Filothei',
    'Filippiada',
    'Filiatra',
    'Farsala',
    'Farkadona',
    'Ermoupoli',
    'Ermioni',
    'Eretria',
    'Eresos',
    'Elounda',
    'Elliniko',
    'Elefsina',
    'Elassona',
    'Ekali',
    'Domokos',
    'Doliana',
    'Dionysos',
    'Deskati',
    'Derveni',
    'Agios Nikolaos',
    'Agia',
    'Avlonarion',
    'Attiki',
    'Athens',
    'Athikia',
    'Atalanti',
    'Astros',
    'Aspropyrgos',
    'Aspra Spitia',
    'Asopos',
    'Arta',
    'Ampelakia',
    'Arkitsa',
    'Arkalochori',
    'Argostoli',
    'Argos',
    'Argalasti',
    'Ano Syros',
    'Ano Liosia',
    'Andravida',
    'Anavyssos',
    'Amorgos',
    'Amfissa',
    'Amfilochia',
    'Amfiklia',
    'Ampelokipoi',
    'Marousi',
    'Amarynthos',
    'Amaliada',
    'Almyros',
    'Aliveri',
    'Akrata',
    'Acharnes',
    'Aigeira',
    'Aigio',
    'Aegina',
    'Aigaleo',
    'Agrinio',
    'Adámas',
    'Rhodes',
    'Afantou',
    'Limnos',
    'Perifereiaki Enotita Androu',
    'Perifereiaki Enotita Karpathou',
    'Zografou',
    'Zagklivéri',
    'Giannitsa',
    'Xanthi',
    'Vergina',
    'Veroia',
    'Tsotili',
    'Thivais',
    'Thessaloniki',
    'Thermi',
    'Thassos',
    'Stavroupoli',
    'Soufli',
    'Sochos',
    'Skiti Kafsokalyvion',
    'Skydra',
    'Sindos',
    'Sidirokastro',
    'Siatista',
    'Serres',
    'Sarti',
    'Sapes',
    'Samothraki',
    'Rodopoli',
    'Ptolemaida',
    'Polygyros',
    'Polykastro',
    'Platy',
    'Chalastra',
    'Peristerion',
    'Peristasi',
    'Peraia',
    'Pella',
    'Paralia Katerinis',
    'Panorama',
    'Ormylia',
    'Orestiada',
    'Nikiti',
    'Nigrita',
    'Neos Marmaras',
    'Nea Triglia',
    'Nea Moudania',
    'Nea Michaniona',
    'Nea Kallikrateia',
    'Nea Iraklitsa',
    'Naousa',
    'Monoxilitai',
    'Methoni',
    'Meliki',
    'Litochoro',
    'Lazochori',
    'Lavara',
    'Lagkadás',
    'Kozani',
    'Koufalia',
    'Korinos',
    'Kopanos',
    'Komotini',
    'Kilkis',
    'Chrysoupoli',
    'Chalkidona',
    'Kavala',
    'Katerini',
    'Kastoria',
    'Kassandreia',
    'Kardia',
    'Kalochori',
    'Kalyves Polygyrou',
    'Kalamaria',
    'Ierissos',
    'Grevena',
    'Goumenissa',
    'Gorgopi',
    'Galatista',
    'Flogita',
    'Florina',
    'Filotas',
    'Nea Filadelfeia',
    'Epanomi',
    'Eleftherio - Kordelio',
    'Edessa',
    'Drama',
    'Draviskos',
    'Diavata',
    'Agia Paraskevi',
    'Axioupolis',
    'Asprovalta',
    'Arnaia',
    'Aridaea',
    'Argos Orestiko',
    'Ano Poroia',
    'Amyntaio',
    'Alexandroupoli',
    'Alexandreia',
    'Adendro',
    'Triandría',
    'Roda',
    'Corfu',
    'Agios Dimitrios',
    'Vrilissia',
    'Faliraki',
    'Tigaki',
    'Naoussa',
    'Kefalonia',
    'Saronida',
    'Kolonaki',
    'Ilioupoli',
    'Agios Ioannis Rentis',
    'Perifereiaki Enotita Mykonou',
    'Glyka Nera',
    'Drapetsona',
    'Kolonos',
    'Thrakomakedones',
    'Nea Penteli',
    'Nea Chalkidona',
    'Hanioti',
  ],
  Lebanon: [
    'Zgharta',
    'Zahle',
    'Tripoli',
    'Tabarja',
    'Tyre',
    'Chhim',
    'Sidon',
    'Saoufar',
    'Sibline',
    'Qalhat',
    'Miziara',
    'Lala',
    'Kfar Hazir',
    'Jounieh',
    'Byblos',
    'Halba',
    'Halat',
    'Habbouch',
    'Ghazir',
    'Ghadir',
    'Faraiya',
    'Fanar',
    'Dmit el Berraniye',
    'Ed Daoura',
    'Bterram',
    'Bteghrine',
    'Bsalim',
    'Beirut',
    'Barr Elias',
    'Baalbek',
    'Baabda',
    'Ain Aar',
    'Ech Chouaifat',
    'Chiyah',
    'Aaraiya',
    'Aaramoun',
    'Antelias',
    'Insar',
    'Amchit',
    'El Fanar',
    'El Hermel',
    'El Bouar',
    'Batroun',
    'Aaley',
    'Aajaltoun',
    'Adma',
    'Dbaiye',
    'Kobayat',
    'Ksara',
    'Mar Mkhayel',
    'Chekka',
    'Tayouneh',
    'Sin el Fil',
    'Nabatieh',
  ],
  Palestine: [
    'Rafah',
    'Nahal Qatif',
    'Gaza',
    'Al Burayj',
    'Tulkarm',
    'Salfit',
    'Ramallah',
    'Nablus',
    'Jenin',
    'Bethlehem',
    'Bayt Jala',
    'Jericho',
    'Hebron',
    'Remal',
    'East Jerusalem',
  ],
  Kuwait: [
    'Shumaymah',
    'Kayfan',
    'Janub as Surrah',
    'Hawalli',
    'Bayan',
    'As Sulaybikhat',
    'As Salimiyah',
    'Ash Shuwaykh',
    'Ash Shu`aybah',
    'Al Shamiya',
    'Ar Rumaythiyah',
    'Ar Riqqah',
    'An Nuqayyat',
    'Kuwait City',
    'Al Jahra',
    'Al Hujayjah',
    'Al Funaytis',
    'Al Fahahil',
    'Al Fintas',
    'Al Farwaniyah',
    'Al Ahmadi',
    'Ad Dawhah',
    'Abraq Khaytan',
    'Salwa',
    'Mishrif',
    "`Ashish al Jahra'",
    'Qurtubah',
    'Sabah as Salim',
    'Mubarak al Kabir',
    'Jaber Al Ali',
    'Safat',
    'Al-Masayel',
    'Abu Fatira',
  ],
  Oman: [
    'Al `Udhaybah ash Shamaliyah',
    'Sur',
    'Sohar',
    "Sufalat Sama'il",
    'Salalah',
    'Saham',
    'Ruwi',
    'Ar Raddah',
    'Nizwa',
    'Mirbat',
    'Al Mawalih al Janubiyah',
    'Mutrah',
    'Muscat',
    'Khasab',
    'Izki',
    'Ibri',
    "Ibra'",
    "Hayma'",
    'Halban',
    'Ghala',
    'Dank',
    'Bawshar',
    'Seeb',
    'Rustaq',
    'Buraimi',
    'Yanqul',
    'Bayt al `Awabi',
    'Al Amarat',
    'Bidiyah',
    'Matrah al Kubra',
  ],
  Qatar: [
    'Umm Salal Muhammad',
    'Umm Salal `Ali',
    'Madinat ash Shamal',
    'Dukhan',
    'Az Za`ayin',
    'Ash Shihaniyah',
    'Ar Rayyan',
    'Al Wukayr',
    'Al Wakrah',
    'Al Khor',
    'Doha',
  ],
  Bahrain: [
    'Tubli',
    'Sufalah',
    'Sitrah',
    'Shahrakkan',
    'Sar',
    'Sanad',
    'Sanabis',
    'Salmabad',
    'Sadad',
    'Qalali',
    'Nur Juruft',
    'Maqaba',
    'Madinat `Isa',
    'Karbabad',
    'Jabalat Habashi',
    'Madinat Hamad',
    'Dumistan',
    'Dar Kulayb',
    'Buri',
    'Bu Quwah',
    'Barbar',
    'Bani Jamrah',
    'Az Zinj',
    'Az Zallaq',
    'Oil City',
    'As Sakhir',
    'Ash Shakhurah',
    'An Nuwaydirat',
    'Al Qadam',
    'Al Muharraq',
    'Al Markh',
    'Manama',
    'Al Malikiyah',
    'Al Jasrah',
    'Al Janabiyah',
    'Al Hamalah',
    'Al Hadd',
    'Al Budayyi`',
    "Ar Rifa'",
    'Seef',
    "A'ali",
    'Adliya',
  ],
  'United Arab Emirates': [
    'Umm Al Quwain City',
    'Ras al-Khaimah',
    'Qidfa`',
    'Lazimah',
    "Khor'fakkan",
    'Kalba',
    'Dubai',
    'Dibba Al-Hisn',
    'Dayrah',
    'Dadna',
    'Sharjah',
    'Ar Ruways',
    'Al Khan',
    'Hatta',
    'Al Fujairah City',
    'Al Ain City',
    'Ajman',
    'Adh Dhayd',
    'Abu Dhabi',
    'Bur Dubai',
    'Al Hazaywah',
    'Al Nahda',
    'International City',
    'Jebel Ali',
    'Musaffah City',
  ],
  Turkey: [
    'Zara',
    'Yunak',
    'Yozgat',
    'Yesilyurt',
    'Erzin',
    'Yenimahalle',
    'Didim',
    'Yatagan',
    'Payas',
    'Van',
    'Uşak',
    'Urla',
    'Sanliurfa',
    'Arsuz',
    'Ula',
    'Turgutlu',
    'Tunceli',
    'Tokus',
    'Tire',
    'Guzelyurt',
    'Tatvan',
    'Tarsus',
    'Soma',
    'Sivas',
    'Sirnak',
    'Sincan',
    'Simav',
    'Silifke',
    'Siirt',
    'Seyhan',
    'Serik',
    'Sereflikochisar',
    'Seferihisar',
    'Sarkisla',
    'Sarigol',
    'Saricam',
    'Samandag',
    'Salihli',
    'Sakarya',
    'Pamukkale',
    'Palu',
    'Osmaniye',
    'OEdemis',
    'Nizip',
    'Niğde',
    'Nevşehir',
    'Nazilli',
    'Muş',
    'Muradiye',
    'Mugla',
    'Milas',
    'Mezitli',
    'Mersin',
    'Akdeniz',
    'Meram',
    'Menemen',
    'Melikgazi',
    'Mecidiye',
    'Marmaris',
    'Mardin',
    'Magnesia ad Sipylum',
    'Manavgat',
    'Mamak',
    'Malatya',
    'Kuyucak',
    'Kusadasi',
    'Cigli',
    'Kozan',
    'Koycegiz',
    'Konya',
    'Konak',
    'Kocaeli',
    'Kiziltepe',
    'Kırşehir',
    'Kirkagac',
    'Kirikkale',
    'Kilis',
    'Kibris',
    'Kemerkoy',
    'Kecioeren',
    'Keban',
    'Kayseri',
    'Kayapinar',
    'Karsiyaka',
    'Aladag',
    'Karaman',
    'Ağrı',
    'Kahta',
    'Kahramanmaraş',
    'Kadirli',
    'Kadikoy',
    'Izmir',
    'Isparta',
    'Islahiye',
    'İskenderun',
    'Ikiyaka',
    'Iğdır',
    'Selcuklu',
    'Hizan',
    'Akdag',
    'Havran',
    'Hasanbeyli',
    'Hani',
    'Guzelbahce',
    'Gulecler',
    'Golbasi',
    'Germencik',
    'Gaziemir',
    'Gaziantep',
    'Foça',
    'Finike',
    'Fethiye',
    'Etimesgut',
    'Eskişehir',
    'Erzurum',
    'Erzincan',
    'Ergani',
    'Erdemli',
    'Elbistan',
    'Elâzığ',
    'Kocasinan',
    'Edremit',
    'Dosemealti',
    'Doertyol',
    'Domanic',
    'Doğubeyazıt',
    'Diyarbakır',
    'Derebucak',
    'Denizli',
    'Cukurova',
    'Cukurca',
    'Hakkari',
    'Cizre',
    'Ceyhan',
    'Cesme',
    'Buharkent',
    'Burdur',
    'Bugdayli',
    'Buca',
    'Bornova',
    'Bodrum',
    'Bitlis',
    'Bismil',
    'Bingol',
    'Beysehir',
    'Bergama',
    'Belen',
    'Bayrakli',
    'Batman',
    'Balıkesir',
    'Bala',
    'Aydin',
    'Antalya',
    'Antakya',
    'Ankara',
    'Andac',
    'Aliaga',
    'Alaybey',
    'Alaşehir',
    'Alanya',
    'Aksu',
    'Aksaray',
    'Akhisar',
    'Akcakale',
    'Gulagac',
    'Afyonkarahisar',
    'Afsin',
    'Adıyaman',
    'Adana',
    'Acibadem',
    'Merkez',
    'Sahinbey',
    'Zonguldak',
    'Zile',
    'Zeytinburnu',
    'Yomra',
    'Yesilkoy',
    'Yenişehir',
    'Korfez',
    'Yaman',
    'Yalova',
    'Yakakent',
    'Vize',
    'Vezirkopru',
    'Uzunkoeprue',
    'Skutari',
    'UEnye',
    'Umraniye',
    'Tuzla',
    'Turkoba',
    'Turhal',
    'Trabzon',
    'Tosya',
    'Tortum',
    'Tokat Province',
    'Tesvikiye',
    'Terme',
    'Tekirdağ',
    'Tarabya',
    'Sungurlu',
    'Suadiye',
    'Sisli',
    'Sinop',
    'Siliviri',
    'Sile',
    'Serdivan',
    'Sariyer',
    'Saray',
    'Samsun',
    'Safranbolu',
    'Rize',
    'Pursaklar',
    'Pendik',
    'Osmaneli',
    'Osmancik',
    'Ortakoy',
    'Orhangazi',
    'Ordu',
    'Of',
    'Niksar',
    'Mustafakemalpasa',
    'Mudanya',
    'Merzifon',
    'Maltepe',
    'Malkara',
    'Lüleburgaz',
    'Kucukcekmece',
    'Kosekoy',
    'Kocaali',
    'Kırklareli',
    'Kestel',
    'Kesan',
    'Kazan',
    'Kaynasli',
    'Kavak',
    'Kastamonu',
    'Kartal',
    'Kars',
    'Karamursel',
    'Karabük',
    'Kagithane',
    'İzmit',
    'Istanbul',
    'Iskilip',
    'İnegöl',
    'Horasan',
    'Hendek',
    'Havza',
    'Gursu',
    'Gümüşhane',
    'Goynuk',
    'Görele',
    'Gonen',
    'Goelcuek',
    'Girne',
    'Giresun',
    'Gemlik',
    'Gebze',
    'Fatih',
    'Eyuepsultan',
    'Eskipazar',
    'Esenyurt',
    'Esenler',
    'Erbaa',
    'Edirne',
    'Duezce',
    'Derince',
    'Darica',
    'Cubuk',
    'Çorum',
    'Çorlu',
    'Cinarcik',
    'Ciftlikkoy',
    'Çerkezköy',
    'Cekmekoey',
    'Catalca',
    'Çankırı',
    'Çanakkale',
    'Can',
    'Adalar',
    'Burunucu',
    'Bursa',
    'Boyabat',
    'Bostanci',
    'Bolu',
    'Bilecik',
    'Biga',
    'Beypazari',
    'Beyoğlu',
    'Beykoz',
    'Besiktas',
    'Bayrampasa',
    'Bayburt',
    'Basak',
    'Bartin',
    'Panderma',
    'Bakirkoey',
    'Bagcilar',
    'Bafra',
    'Babaeski',
    'Ayas',
    'Avcilar',
    'Artvin',
    'Ardeşen',
    'Ardahan',
    'Amasya',
    'Alapli',
    'Akyurt',
    'Akdere',
    'Akcakoca',
    'Akçaabat',
    'Akalan',
    'Ahmetalan',
    'Agva',
    'Adapazarı',
    'Espiye',
    'Ayazaga',
    'Karatay',
    'Atasehir',
    'Basaksehir',
    'Beylikduezue',
    'Bueyuekcekmece',
    'Cankaya',
    'Ihlas Marmara Evleri - I. Kisim',
    'Sehitkamil',
    'Sultangazi',
    'Sultanbeyli',
    'Karabaglar',
    'Osmangazi',
    'Kepez',
    'Muratpasa',
    'Gayrettepe',
    'Nilufer',
    'Merkezefendi',
  ],
  Ethiopia: [
    'Warder',
    'Nazret',
    'Kuyera',
    'Jijiga',
    'Harar',
    'Gondar',
    'Gonder',
    'Gambela',
    'Dubti',
    'Dire Dawa',
    'Dessie',
    'Bishoftu',
    'Bahir Dar',
    'Awasa',
    'Asosa',
    'Axum',
    'Addis Ababa',
    'Adigrat',
    'Adama',
    'Semera',
  ],
  Eritrea: [
    'Massawa',
    'Keren',
    'Edd',
    'Barentu',
    'Asmara',
    "Ak'ordat",
    'Mendefera',
  ],
  Egypt: [
    'Zefta',
    'Toukh',
    'Tanta',
    'Tanan',
    'Talkha',
    'Tala',
    'Tahta',
    'Sohag',
    'Sinnuris',
    'Sidi Bishr',
    'Shubra al Khaymah',
    'Shubra',
    'Shirbin',
    'Shibin al Kawm',
    'Sharm el Sheikh',
    'Rafah',
    'Quwaysina',
    'Kousa',
    'Qina',
    'Qift',
    'Qalyub',
    'Nuweiba',
    'Nag Hammâdi',
    'Mit Ghamr',
    'Heliopolis',
    'Munuf',
    'Mallawi',
    'Madinat as Sadat',
    'Madinat an Nasr',
    "Al 'Ashir min Ramadan",
    'Kawm Hamadah',
    'Kafr ash Shaykh',
    '`Izbat Jamasah al Gharbiyah',
    'Esna',
    'Imbabah',
    'Edfu',
    'Helwan',
    'Hihya',
    'Fukah',
    'Faraskur',
    'Faqus',
    'Damietta',
    'Diyarb Najm',
    'Disuq',
    'Dikirnis',
    'Maadi Cornish',
    'Damanhur Shubra',
    'Damanhur',
    'Port Said',
    'Bilbeis',
    'Biba',
    'Bani Suwayf',
    'Bani Mazar',
    'Banha',
    'Baltim',
    'Zagazig',
    'Az Zamalik',
    'Az Zabu',
    'Assiut',
    'Aswan',
    'Suez',
    "Ash Shuhada'",
    'Armant',
    'Al Wasitah',
    'Luxor',
    'Cairo',
    'Al Minya',
    'Minya',
    'Almazah',
    'Al Mansurah',
    'Al Mahallah al Kubra',
    'Al Khankah',
    'Giza',
    'Ismailia',
    'Alexandria',
    'Al Haram',
    'Hurghada',
    'Al Fayyum',
    'El Alamein',
    'Aga',
    'El Dabaa',
    'Abu Hammad',
    'Abu al Matamir',
    'Badr',
    'Madinaty',
    'Smouha',
    'El Gouna',
    '6th of October City',
    'New Cairo',
    'El Shorouk',
    'Faisal',
    'Embaba',
    '15 May City',
    'Dokki',
    'Saint Catherine',
    'Mohandessin',
    'Agouza',
  ],
  Albania: [
    'Sarandë',
    'Tepelenë',
    'Pogradec',
    'Peshkopi',
    'Permet',
    'Maqellare',
    'Maliq',
    'Librazhd',
    'Kurbnesh',
    'Korçë',
    'Gramsh',
    'Gjirokastër',
    'Elbasan',
    'Bulqize',
    'Bilisht',
    'Vlorë',
    'Ura Vajgurore',
    'Tirana',
    'Shkoder',
    'Rubik',
    'Peqin',
    'Lushnje',
    'Lezhë',
    'Lac',
    'Kucove',
    'Kamez',
    'Kamenice',
    'Fier',
    'Durrës',
    'Cerrik',
    'Berat',
    'Afrimi i Ri',
  ],
  Sudan: [
    'Dongola',
    'Umm Kaddadah',
    'Umm Durman',
    'Singa',
    'Rabak',
    'Kassala',
    'Port Sudan',
    'Babel',
    'Es Sumeih',
    'Ar Rahad',
    'An Nuhud',
    'Al Qadarif',
    'Al Lagowa',
    'Khartoum',
    'Geneina',
    'Ad-Damazin',
    'Gereida',
  ],
  'South Sudan': ['Juba'],
  Burundi: [
    'Makamba',
    'Bururi',
    'Rumonge',
    'Mwaro',
    'Isale',
    'Bujumbura',
    'Muramvya',
    'Gitega',
    'Ruyigi',
    'Cankuzo',
    'Karuzi',
    'Bubanza',
    'Cibitoke',
    'Ngozi',
    'Kayanza',
    'Muyinga',
    'Kirundo',
    'Rutana',
  ],
  Russia: [
    'Udomlya',
    'Gornyak',
    'Sasovo',
    'Novozavidovskiy',
    'Kosterevo',
    'Ozagir',
    'Zyukayka',
    'Zvenigovo',
    'Zvenigorod',
    'Zuyevka',
    'Zubtsov',
    'Zmiyëvka',
    'Zlynka',
    'Zlatoust',
    'Zirgan',
    'Zimovniki',
    'Zhukovskiy',
    'Zhukovo',
    'Zhukovka',
    'Zhostovo',
    'Zhizdra',
    'Zhirnovsk',
    'Zhilina',
    'Zhigulevsk',
    'Zheshart',
    'Zherdevka',
    'Zheleznovodsk',
    'Zheleznogorsk',
    'Zheleznodorozhnyy',
    'Zernograd',
    'Zelenokumsk',
    'Zelenogradskiy',
    'Zelenogradsk',
    'Zelenograd',
    'Zelenogorsk',
    'Zelenodolsk',
    'Zelenoborskiy',
    'Zelenets',
    'Zelënaya Roshcha',
    "Zavolzh'ye",
    'Zavolzhsk',
    'Zaraysk',
    'Zaprudnya',
    'Zapolyarnyy',
    'Zaokskiy',
    'Zainsk',
    'Zagoryanskiy',
    'Zadonsk',
    'Yuzha',
    "Yuryuzan'",
    'Yurino',
    'Yukhnov',
    'Yoshkar-Ola',
    'Yeysk',
    'Yessentukskaya',
    'Yessentuki',
    'Yershov',
    'Yermolayevo',
    "Yermish'",
    'Yermilovo',
    'Yarlukovo',
    'Yemva',
    'Yelets',
    'Yelabuga',
    "Yegor'yevsk",
    'Yegorlykskaya',
    'Yefremov',
    'Yayva',
    'Yavas',
    'Yasnyy',
    'Yasnogorsk',
    'Yartsevo',
    'Yaroslavl',
    'Yarega',
    'Yanaul',
    'Yamkino',
    'Yalta',
    "Yakshur-Bod'ya",
    'Yakshur',
    "Yakot'",
    'Yakhroma',
    'Yadrin',
    'Yablonovsky',
    'Vyyezdnoye',
    'Vytegra',
    'Vysokovsk',
    'Vysokinichi',
    'Vyshniy Volochëk',
    'Vyshkov',
    'Vyselki',
    'Vyritsa',
    'Vyksa',
    'Vychegodskiy',
    'Vyborg',
    'Vyazniki',
    "Vyaz'ma",
    'Vyatskiye Polyany',
    'Vurnary',
    'Vtoryye Terbuny',
    'Vsevolozhsk',
    'Voyvozh',
    'Voybokalo',
    'Votkinsk',
    'Voskresensk',
    'Vorsma',
    'Voronezh',
    'Volzhskiy',
    'Volzhsk',
    "Vol'sk",
    'Volosovo',
    'Volokonovka',
    'Volokolamsk',
    'Vologda',
    'Volodarskiy',
    'Volodarsk',
    'Volkhov',
    'Volgorechensk',
    'Volgograd',
    'Volgodonsk',
    "Vol'ginskiy",
    'Vokhtoga',
    'Vlasikha',
    'Vladimir',
    'Vladikavkaz',
    'Vizinga',
    'Virki',
    'Vinogradnyy',
    'Vidnoye',
    'Vichuga',
    'Veydelevka',
    'Vetluga',
    'Veshkayma',
    "Verkhov'ye",
    "Verkhopen'ye",
    'Verkhniy Mamon',
    'Verkhniye Tatyshly',
    'Verkhneyarkeyevo',
    'Verkhnerusskoye',
    'Verkhnedneprovskiy',
    'Vereya',
    'Vereshchagino',
    'Venëv',
    'Velsk',
    'Velikoye',
    'Velikiy Ustyug',
    'Velikiye Luki',
    'Vavozh',
    'Vatutinki',
    'Varvaro-Borki',
    'Valuyki',
    "Val'divatskoye",
    'Valday',
    'Vad',
    'Vacha',
    'Uzytamak',
    'Uzlovaya',
    'Uva-Tuklya',
    'Uvarovo',
    'Uvarovka',
    'Uva',
    'Utevka',
    'Ustyuzhna',
    "Ust'ye",
    "Ust'-Labinsk",
    "Ust'-Kishert'",
    "Ust'-Katav",
    "Ust'-Donetskiy",
    'Uspenskoye',
    "Usol'ye",
    'Usman-Tashly',
    "Usman'",
    'Uslybash',
    'Uryupinsk',
    'Urus-Martan',
    'Urman',
    "Uren'",
    'Urdoma',
    'Urazovo',
    "Ural'skiy",
    "Untsukul'",
    'Unecha',
    'Umba',
    'Ulyanovsk',
    "Ul'yanovka",
    'Ukhta',
    'Uinskoye',
    'Uglich',
    'Ufa',
    'Uchkeken',
    'Uchaly',
    'Ubeyevo',
    "Tyul'gan",
    'Tver',
    'Tuymazy',
    'Tutayev',
    'Tumbotino',
    'Tula',
    'Tugolesskiy Bor',
    'Tuchkovo',
    'Tuapse',
    "Tsivil'sk",
    'Tsimlyansk',
    "Tsil'na",
    'Tselina',
    'Trudobelikovskiy',
    'Trubchëvsk',
    'Troyekurovo',
    'Trosna',
    'Troitskoye',
    'Troitsk',
    'Trëkhstenki',
    'Trekhgornyy',
    'Tosno',
    'Torzhok',
    'Torbeyevo',
    'Tomilino',
    'Tomarovka',
    'Tolyatti',
    'Tirlyanskiy',
    'Tindi',
    'Timashëvsk',
    'Timashëvo',
    'Tikhvin',
    'Tikhoretsk',
    'Teykovo',
    'Adygeysk',
    "Teren'ga",
    'Terbuny',
    'Tëplaya Gora',
    'Temryuk',
    'Temnikov',
    'Temizhbekskaya',
    'Tbilisskaya',
    'Tatarka',
    'Tarusa',
    'Taptykovo',
    'Tambov',
    "Taman'",
    'Talovaya',
    'Taldom',
    'Agidel',
    'Taganrog',
    'Tabashino',
    "Syzran'",
    'Sylva',
    'Syktyvkar',
    'Sychevo',
    "Syas'stroy",
    'Syamzha',
    'Svoboda',
    'Svetlyy Yar',
    'Svetlyy',
    'Svetlograd',
    'Svetlogorsk',
    'Sverdlovskiy',
    'Suzëmka',
    'Suzdal',
    'Suvorovskaya',
    'Suvorov',
    'Surovikino',
    'Surgut',
    'Surazh',
    'Suoyarvi',
    'Suna',
    'Suksun',
    'Sukhobezvodnoye',
    'Sukhinichi',
    'Sudzha',
    'Subkhankulovo',
    'Stupino',
    'Strunino',
    "Stroitel'",
    'Strizhi',
    'Stolbovaya',
    'Sterlitamak',
    'Sterlibashevo',
    'Stepnoye',
    "Stavropol'",
    'Stavropol',
    'Stary Oskol',
    'Starozhilovo',
    'Staroye Dubovoye',
    'Starosubkhangulovo',
    'Staroshcherbinovskaya',
    'Starodub',
    'Starobaltachevo',
    'Staraya Stanitsa',
    'Staraya Russa',
    'Staraya Poltavka',
    'Staraya Maluksa',
    'Staraya Kupavna',
    "Star'",
    'Spas-Klepiki',
    'Sovetskiy',
    'Sovetsk',
    'Sosnovyy Bor',
    'Sosnovo',
    'Sosnogorsk',
    'Sosenskiy',
    'Sortavala',
    "Oktyabr'skiy",
    'Sorochinsk',
    'Sonkovo',
    "Sol'vychegodsk",
    'Solnechnogorsk',
    "Sol'-Iletsk",
    'Solikamsk',
    'Sokol',
    'Sofrino',
    'Sochi',
    'Sobinka',
    'Snovitsy',
    'Snegiri',
    'Smolensk',
    'Slobodskoy',
    'Sloboda',
    'Slavyansk-na-Kubani',
    'Slavsk',
    'Slavinsk',
    'Slantsy',
    'Skopin',
    'Skhodnya',
    'Sinyavskoye',
    'Sinodskoye',
    'Sim',
    'Sibay',
    'Shvartsevskiy',
    'Shuya',
    'Shushary',
    'Shumerlya',
    'Mikhaylovsk',
    "Shlissel'burg",
    'Shirokoye Boloto',
    'Shilovo',
    'Shikhany',
    'Shigony',
    'Shepsi',
    'Shentala',
    'Shemursha',
    'Shelkovo',
    'Shelanger',
    'Sheksna',
    'Shebekino',
    'Shchigry',
    'Shcherbinovskiy',
    'Shchemilovo',
    'Shchelkovo',
    'Shchëkino',
    'Shaykovka',
    'Shaturtorf',
    'Shatura',
    'Shatki',
    "Shar'ya",
    'Sharapovo',
    'Sharan',
    'Shalya',
    'Shali',
    "Shakhun'ya",
    'Shakhty',
    'Shakhovskaya',
    'Shafranovo',
    'Sevsk',
    'Severskaya',
    "Severoural'sk",
    'Severomorsk',
    'Severodvinsk',
    'Severnyy',
    "Sevastopol'",
    'Sestroretsk',
    'Seshcha',
    'Sertolovo',
    'Serpukhov',
    'Sernur',
    'Sergiyevsk',
    'Sergiyev Posad',
    'Serebryanyye Prudy',
    'Serebryanskiy',
    'Serdobsk',
    'Serafimovskiy',
    'Sennoy',
    'Semiluki',
    'Semiletka',
    'Semikarakorsk',
    'Semibratovo',
    'Semyonov',
    'Selyatino',
    "Sel'tso",
    'Segezha',
    'Sechenovo',
    'Sebezh',
    'Sayukino',
    'Satka',
    'Satis',
    'Sarov',
    'Saratov',
    'Sarapul',
    'Saransk',
    'St Petersburg',
    'Sanino',
    'Samoylovka',
    'Samara',
    "Sal'skiy Kagal'nik",
    "Sal'sk",
    'Salavat',
    'Saki',
    'Sagitovo',
    'Safonovo',
    'Saburovo',
    'Rzhev',
    'Rzhavki',
    "Rzhavka-Step'",
    'Rybnoye',
    'Rybnaya Sloboda',
    'Rybinsk',
    'Ryazhsk',
    "Ryazan'",
    'Ryazan',
    'Ruzayevka',
    'Ruza',
    'Russkiy Brod',
    'Rudnya',
    'Rtishchevo',
    'Rozhdestvenskaya',
    'Rozhdestveno',
    'Rovnoye',
    'Rostov-on-Don',
    'Rostov',
    "Rossosh'",
    "Roslavl'",
    'Roshchino',
    "Roshal'",
    'Romodanovo',
    'Romashkovo',
    'Romashki',
    'Romanovka',
    'Rodniki',
    'Rodionovo-Nesvetayskaya',
    'Rikasovo',
    'Revda',
    'Reutov',
    'Reshetnikovo',
    'Reshetikha',
    'Redkino',
    'Rayevskiy',
    'Rasskazovo',
    "Ramon'",
    'Rameshki',
    'Ramenskoye',
    'Rakitnoye',
    "Rakh'ya",
    'Radionovo',
    'Rabotki',
    'Rabititsy',
    'Pytalovo',
    'Pyatigorskiy',
    'Pyatigorsk',
    'Putilkovo',
    'Pushkino',
    'Tsarskoye Selo',
    'Pushchino',
    'Pugachev',
    'Puchezh',
    "Ptich'ye",
    'Pskov',
    'Pryamitsyno',
    'Protvino',
    'Protasovo',
    'Proletarskiy',
    'Proletarsk',
    'Priyutovo',
    'Privolzhsk',
    'Privodino',
    "Pristen'",
    'Priozersk',
    'Primorsko-Akhtarsk',
    'Primorsk',
    "Pribel'skiy",
    'Pravdinskiy',
    'Pravdinsk',
    'Praskoveya',
    'Povenets',
    'Povarovo',
    "Poshekhon'ye",
    'Poputnaya',
    'Popov',
    'Polyarnyye Zori',
    'Polyarnyy',
    'Polazna',
    'Pokrovskoye',
    'Pokrov',
    'Pokhvistnevo',
    'Pogar',
    'Podstepki',
    "Podporozh'ye",
    'Podolsk',
    'Podolino',
    'Podgorenskiy',
    'Pochinki',
    'Pochep',
    'Plës',
    'Plavsk',
    'Plastunovskaya',
    'Pizhma',
    'Pitkyaranta',
    'Piskaly',
    'Pionerskiy',
    "Pil'na",
    'Pikalyovo',
    'Pichuga',
    'Petushki',
    'Petrozavodsk',
    'Petrov Val',
    'Petrovskoye',
    'Petrovsk',
    'Petergof',
    'Pestyaki',
    'Pestretsovo',
    'Peshkovo',
    'Pervouralsk',
    'Pervomayskiy',
    'Perm',
    'Pereyaslovskaya',
    'Perevoz',
    'Perevolotskiy',
    "Pereslavl'-Zalesskiy",
    'Penza',
    'Pechora',
    'Pechenga',
    'Pavlovskiy Posad',
    'Pavlovskiy',
    'Pavlovskaya Sloboda',
    'Pavlovskaya',
    'Pavlovsk',
    'Pavlovka',
    'Pallasovka',
    'Palekh',
    "Ozherel'ye",
    'Ozyory',
    'Otradnyy',
    'Otradnoye',
    'Otradnaya',
    'Ostrov',
    'Ostrogozhsk',
    'Ostashkov',
    'Osa',
    'Orsk',
    'Orlovka',
    'Orichi',
    'Orenburg',
    'Orël',
    'Oryol',
    'Orekhovo-Zuyevo',
    'Orda',
    'Opochka',
    'Onega',
    'Omutninsk',
    'Oma',
    "Ol'ginskaya",
    'Olenegorsk',
    'Oktyabrsky',
    "Oktyabr'sk",
    'Ogudnëvo',
    'Ogarëvka',
    'Odoyev',
    'Odintsovo',
    'Ocher',
    'Obukhovo',
    'Obrochnoye',
    'Obozërskiy',
    "Oboyan'",
    'Oboldino',
    'Obninsk',
    'Nyuksenitsa',
    'Nytva',
    'Nyazepetrovsk',
    'Nyandoma',
    'Nurlat',
    'Novyy Toryal',
    'Novyy Oskol',
    'Novyy Nekouz',
    'Novozybkov',
    'Novozhivotinnoye',
    'Novoye Devyatkino',
    'Novovoronezh',
    'Novovelichkovskaya',
    "Novoul'yanovsk",
    'Novotroitskaya',
    'Novotroitsk',
    'Novospasskoye',
    "Novosokol'niki",
    'Novosmolinskiy',
    'Novosheshminsk',
    'Novoshakhtinsk',
    'Novosergiyevka',
    'Novosemeykino',
    'Novorozhdestvenskaya',
    'Novorossiysk',
    'Novopokrovskaya',
    'Novopodrezkovo',
    'Novopavlovsk',
    'Novonikolayevskiy',
    'Novomyshastovskaya',
    'Novomoskovsk',
    'Novomikhaylovskiy',
    'Novomichurinsk',
    'Novokuybyshevsk',
    'Novokubansk',
    'Novokhopërskiy',
    'Novokhopyorsk',
    'Novokayakent',
    'Novokabanovo',
    'Novodvinsk',
    'Novoderevyankovskaya',
    'Novocherkassk',
    'Novocheremshansk',
    'Novocheboksarsk',
    'Novobessergenevka',
    'Novobataysk',
    'Novoaleksandrovsk',
    'Veliky Novgorod',
    "Novaya Usman'",
    'Novaya Otradovka',
    'Novaya Ladoga',
    'Novaya Adygeya',
    'Noginsk',
    'Nizino',
    'Nizhny Tagil',
    'Nizhniy Odes',
    'Nizhniy Novgorod',
    'Nizhniy Lomov',
    'Nizhniye Sergi',
    'Nizhniy Chiryurt',
    'Nizhnekamsk',
    'Nizhneivkino',
    'Niskasy',
    'Niny',
    "Nikol'skoye",
    'Nikologory',
    'Nikolo-Berëzovka',
    'Nikolayevsk',
    'Nikolayevka',
    'Nikitovka',
    'Nikel',
    'Nevinnomyssk',
    'Nerekhta',
    'Nemchinovka',
    'Neman',
    'Nelidovo',
    'Nekrasovskoye',
    'Nekrasovskiy',
    'Neftekamsk',
    'Neftegorsk',
    'Nechayevo',
    'Nazran',
    'Naziya',
    'Nazar’yevo',
    'Navoloki',
    'Navlya',
    'Navashino',
    'Nasontov',
    'Naryshkino',
    'Naryan-Mar',
    'Nartkala',
    'Narovchat',
    'Naro-Fominsk',
    'Narimanov',
    "Nal'chik",
    'Nakhabino',
    'Nagayevo',
    'Naberezhnyye Chelny',
    'Mytishchi',
    'Myshkin',
    'Mutsalaul',
    'Murygino',
    'Murom',
    'Murmashi',
    'Murmansk',
    'Murino',
    'Mulino',
    'Mtsensk',
    'Mozhga',
    'Mozhaysk',
    'Mozdok',
    'Mostovskoy',
    'Moscow',
    'Moskovskoye',
    'Morshansk',
    'Morozovsk',
    'Morgaushi',
    'Monino',
    'Monchegorsk',
    'Molokovo',
    'Mokshan',
    'Mokrous',
    'Mishutino',
    'Mirnyy',
    "Min'yar",
    'Mineralnye Vody',
    'Milovka',
    'Millerovo',
    "Mikun'",
    'Mikhnëvo',
    'Mikhaylovskaya',
    'Mikhaylovka',
    'Mikhaylov',
    'Michurinsk',
    'Mga',
    'Menzelinsk',
    'Mendeleyevsk',
    'Mendeleyevo',
    'Meleuz',
    'Melenki',
    "Medyn'",
    "Medvezh'yegorsk",
    'Medvedevo',
    'Mednogorsk',
    'Mayskiy',
    'Maykop',
    "Maslova Pristan'",
    'Marushkino',
    'Marks',
    'Mari-Turek',
    'Marfino',
    'Manturovo',
    'Mamyri',
    'Mamkheg',
    'Maloyaroslavets',
    "Maloarkhangel'sk",
    'Malino',
    'Malgobek',
    'Malaya Vishera',
    'Malaya Dubna',
    'Malakhovka',
    'Makhachkala',
    'Magnitogorsk',
    'Magnitka',
    'Madzhalis',
    'Lyudinovo',
    'Lyubytino',
    'Lyubuchany',
    'Lyubertsy',
    'Lytkino',
    'Lytkarino',
    "Lys'va",
    'Lyskovo',
    'Lukoyanov',
    'Lukhovitsy',
    'Luga',
    'Lozhgolovo',
    'Lotoshino',
    'Losino-Petrovskiy',
    'Losevo',
    'Lopatino',
    'Lonikha',
    'Lomonosov',
    'Lodeynoye Pole',
    'Lobnya',
    'Livny',
    'Liski',
    'Lipovka',
    'Lipki',
    'Lipetsk',
    'Liman',
    'Likino-Dulevo',
    "Likhoslavl'",
    "L'govskiy",
    'Levashovo',
    'Letnyaya Stavka',
    'Lesnoy Gorodok',
    'Lesnoy',
    'Leskolovo',
    'Lermontov',
    'Leninsk',
    'Leninogorsk',
    'Lenino',
    'Leningradskaya',
    'Leninavan',
    'Lenina',
    "Lebedyan'",
    'Lazarevo',
    'Laykovo',
    'Lakinsk',
    "Lakhdenpokh'ya",
    'Laishevo',
    'Ladushkin',
    'Ladozhskaya',
    'Labinsk',
    'Kuzyayevo',
    'Kuznetsk',
    'Kuzhener',
    'Kuytezha',
    'Kuybyshevskiy Zaton',
    'Kuybyshevo',
    'Kuvandyk',
    'Kushva',
    'Kushchëvskaya',
    'Kusa',
    'Kurumoch',
    'Kurskaya',
    'Kursk',
    'Kursavka',
    'Kurovskoye',
    'Kurkino',
    'Kurganinsk',
    'Kurgan',
    'Kurchatov',
    'Kungur',
    'Kumertau',
    'Kumëny',
    'Kuleshovka',
    'Kulebaki',
    'Kukushtan',
    'Kukmor',
    "Kugul'ta",
    'Kugesi',
    'Kudymkar',
    'Kudrovo',
    'Kudinovo',
    'Kubrinsk',
    'Kubinka',
    "Kuban'",
    'Kstovo',
    'Krymsk',
    'Kropotkin',
    'Kronshtadt',
    'Kromy',
    'Kresttsy',
    'Kremenki',
    'Kratovo',
    'Krasnyy Sulin',
    'Krasnyy Profintern',
    'Krasnyy Kholm',
    'Krasnyy Gulyay',
    'Krasnyye Barrikady',
    'Krasnyye Baki',
    'Krasnoznamensk',
    'Krasnozavodsk',
    'Krasnoye-na-Volge',
    'Krasnovishersk',
    "Krasnousol'skiy",
    'Krasnoufimsk',
    'Krasnoslobodsk',
    'Krasnomayskiy',
    'Krasnokumskoye',
    'Krasnokamsk',
    'Krasnogorskiy',
    'Krasnogorsk',
    'Krasnodar',
    'Krasnoarmeyskoye',
    'Krasnoarmeyskaya',
    'Krasnoarmeysk',
    'Krasnaya Poyma',
    'Krasnaya Polyana',
    'Kraskovo',
    "Koz'modem'yansk",
    'Kozhva',
    "Kozel'sk",
    'Kovylkino',
    'Kovrov',
    'Kovrigino',
    'Kovernino',
    'Kovdor',
    'Kotovsk',
    'Kotovo',
    'Kotlas',
    "Kotel'nikov",
    "Kotel'niki",
    "Kotel'nich",
    'Kostroma',
    'Kostomuksha',
    'Kosmynino',
    "Koshekhabl'",
    'Korystovo',
    'Koryazhma',
    'Kortkeros',
    'Korotoyak',
    'Korocha',
    'Korkatovo',
    'Korenovsk',
    'Korablino',
    'Konstantinovskiy',
    'Konosha',
    'Kondrovo',
    'Kondopoga',
    'Konakovo',
    "Komsomol'sk",
    'Komsomolets',
    'Kommunarka',
    'Kommunar',
    'Komarichi',
    'Kolyubakino',
    'Kolyshley',
    'Koltushi',
    'Kolpino',
    'Kolomna',
    'Kologriv',
    "Kol'chugino",
    'Kola',
    'Kokhma',
    'Knyaginino',
    'Klintsy',
    'Kliny',
    'Klin',
    'Klimovsk',
    'Klimovo',
    'Kizner',
    'Kizlyar',
    'Kizilyurt',
    'Kizel',
    'Kiyasovo',
    'Kislovodsk',
    'Kirzhach',
    'Kirsanov',
    'Kirs',
    'Kirovsk',
    'Kirovo-Chepetsk',
    'Kirov',
    'Kirishi',
    'Kirillov',
    'Kireyevsk',
    'Kingisepp',
    'Kineshma',
    "Kinel'",
    'Kimry',
    'Kimovsk',
    'Kilp”yavr',
    'Kiknur',
    'Khvalynsk',
    'Khutorka',
    'Khuchni',
    "Khot'kovo",
    'Khorlovo',
    'Kholmogory',
    'Khmelevoye',
    'Khlyupino',
    'Khlevnoye',
    'Khimki',
    'Khasavyurt',
    'Kharovsk',
    'Orlov',
    'Khadzhalmakhi',
    'Khadyzhensk',
    'Khabez',
    "Kem'",
    'Kazinka',
    'Kazan’',
    'Kavkazskaya',
    'Kaspiysk',
    'Kasimov',
    'Kashpir',
    'Kashira',
    'Kashin',
    'Karsun',
    'Karpogory',
    'Karinskoye',
    'Kardymovo',
    'Karachev',
    'Karabulak',
    'Kapyrevshchina',
    'Kanevskaya',
    'Kanelovskaya',
    'Kandry',
    'Kandalaksha',
    'Kanash',
    'Kamyzyak',
    'Kamyshin',
    "Kamskoye Ust'ye",
    'Kaminskiy',
    'Kameshkovo',
    'Kamensk-Shakhtinskiy',
    'Kamensk',
    'Kamenolomni',
    'Kamennomostskoye',
    'Kamenka',
    'Kambarka',
    'Kalyazin',
    'Kaluga',
    'Kalnibolotskaya',
    'Kalino',
    'Kalininskaya',
    'Kalininsk',
    'Korolyov',
    'Kaliningrad',
    'Kalinovka',
    'Kalashnikovo',
    'Kalach-na-Donu',
    'Kalach',
    "Kagal'nik",
    'Kaduy',
    'Kadoshkino',
    'Kadnikov',
    'Kachkanar',
    'Kabakovo',
    'Izvara',
    "Izobil'nyy",
    'Izmaylovskiy',
    'Izmalkovo',
    'Izhevsk',
    'Izberbash',
    'Ivotok',
    'Ivanteyevka',
    'Ivanovo',
    'Ivangorod',
    'Istra',
    'Ishimbay',
    'Iriston',
    'Ipatovo',
    'Ostrovnoy',
    'Inzhavino',
    'Insar',
    'Inozemtsevo',
    'Imeni Sverdlova',
    'Imeni Morozova',
    'Kuybyshev',
    "Il'skiy",
    'Ilovlya',
    "Il'inskiy",
    'Iksha',
    'Iglino',
    "Gzhel'",
    'Gvardeysk',
    "Gus'-Khrustal'nyy",
    'Gusev',
    "Gur'yevsk",
    'Gunib',
    'Gulyay-Borisovka',
    "Gul'kevichi",
    'Gukovo',
    'Gudermes',
    'Gubkin',
    'Gubakha',
    'Gryazovets',
    'Gryazi',
    'Grushevskaya',
    'Grozny',
    "Grigor'yevskoye",
    'Grigoropolisskaya',
    'Gremyachinsk',
    'Grayvoron',
    'Gostagayevskaya',
    'Goryachiy Klyuch',
    'Gorokhovets',
    'Gorodishche',
    'Gorodets',
    'Gornozavodsk',
    "Gornoural'skiy",
    'Gorki Vtoryye',
    'Gorki-10',
    'Gorenki',
    'Gorbunki',
    'Gorbovo',
    'Golitsyno',
    'Glubokiy',
    'Glazunovka',
    'Glazov',
    'Giaginskaya',
    "Gergebil'",
    'Georgiyevsk',
    'Gelendzhik',
    'Gdov',
    'Gay',
    'Gavrilov-Yam',
    'Gavrilov Posad',
    'Gavrilovka',
    'Gatchina',
    'Garbolovo',
    'Gagarin',
    'Gadzhiyevo',
    'Furmanov',
    'Fryazino',
    'Fryazevo',
    'Fryanovo',
    'Frolovo',
    'Fornosovo',
    'Fokino',
    'Fedorovskoye',
    'Faustovo',
    "Ertil'",
    "Engel's",
    'Energetik',
    'Enem',
    'Elista',
    'Elektrougli',
    'Elektrostal',
    'Elektrogorsk',
    'Dzhubga',
    'Dzheguta',
    "Dzhalil'",
    'Dzerzhinskiy',
    'Dzerzhinsk',
    'Dyurtyuli',
    "Dyat'kovo",
    'Dvinskoy',
    'Duminichi',
    'Dubrovka',
    'Dubovka',
    'Dubna',
    'Druzhba',
    'Droskovo',
    'Drezna',
    'Dorokhovo',
    'Dorogobuzh',
    'Donskoy',
    'Donetsk',
    'Domodedovo',
    'Dolgorukovo',
    'Dolgoprudnyy',
    'Dobryanka',
    'Dobrinka',
    'Dmitrov',
    'Dmitriyev-Lgovsky',
    'Dinskaya',
    'Dimitrovgrad',
    'Digora',
    'Detchino',
    'Desnogorsk',
    'Derbent',
    'Dedovsk',
    'Dedovichi',
    'Dedenevo',
    'Davydovo',
    'Davydovka',
    'Davlekanovo',
    'Dankov',
    'Danilov',
    'Poselok Vnukovo',
    'Chusovoy',
    'Chudovo',
    'Chkalovsk',
    "Chistopol'",
    'Chishmy',
    'Chesnokovka',
    'Chernyanka',
    'Chernyakhovsk',
    'Chernushka',
    'Chernogolovka',
    'Chernava',
    'Cherkessk',
    'Cherepovets',
    'Cherdakly',
    'Chekmagush',
    'Chekhov',
    'Chekalin',
    'Chechulino',
    'Cheboksary',
    'Chaykovskiy',
    'Chastsy',
    'Chaplygin',
    'Chapayevsk',
    'Chamzinka',
    'Chagoda',
    'Chaadayevka',
    'Bykovo',
    'Buzuluk',
    'Buzdyak',
    'Buynaksk',
    'Buy',
    'Buturlinovka',
    'Buturlino',
    'Burmakino',
    'Burayevo',
    'Bulgakovo',
    'Buinsk',
    'Buguruslan',
    "Bugul'ma",
    "Budogoshch'",
    'Budënnovsk',
    'Bryansk',
    'Bronnitsy',
    'Breytovo',
    'Bratovshchina',
    'Bereznetsovo',
    'Borskoye',
    'Borovsk',
    'Borovichi',
    'Borisovka',
    'Borisoglebskiy',
    'Borisoglebsk',
    'Bor',
    "Bol'shoy Tsaryn",
    "Bol'shoye Gryzlovo",
    "Bol'shiye Klyuchishchi",
    "Bol'shaya Vruda",
    "Bol'shaya Izhora",
    'Bologoye',
    'Bolkhov',
    'Boksitogorsk',
    'Boguchar',
    'Bogorodsk',
    'Bogoroditsk',
    'Bogolyubovo',
    'Bobrovo',
    'Bobrovka',
    'Bobrov',
    'Blizhnyaya Igumenka',
    'Blagoveshchensk',
    'Blagovar',
    'Bizhbulyak',
    'Bishnya',
    "Bisert'",
    'Biryuch',
    'Birsk',
    'Bezhetsk',
    'Bezhanitsy',
    'Bezenchuk',
    'Bestuzhevka',
    'Beslan',
    'Bersenevka',
    'Berezniki',
    'Benokovo',
    'Belyayevka',
    'Belousovo',
    'Beloretsk',
    'Belorechensk',
    'Beloozërskiy',
    'Belinskiy',
    'Belgorod',
    'Belëv',
    'Belebey',
    'Belaya Kholunitsa',
    'Belaya Kalitva',
    'Belaya Berëzka',
    'Bazarnyy Syzgan',
    'Baymak',
    'Bavly',
    'Batetskiy',
    'Bataysk',
    'Krasnaya Batareya',
    'Barysh',
    'Barvikha',
    'Barsuki',
    'Barda',
    'Barabanovo',
    'Balezino',
    'Balashikha',
    'Balakovo',
    'Balakhna',
    'Balabanovo',
    'Baksan',
    'Bakhtyzino',
    'Bakaly',
    'Bakal',
    'Bagrationovsk',
    'Babynino',
    'Babino',
    'Babayevo',
    'Azov',
    'Azino',
    'Aysha',
    'Aykino',
    'Avdon',
    'Atyashevo',
    'Atkarsk',
    'Astrakhan',
    'Astapovo',
    'Asha',
    'Arzamas',
    'Arti',
    'Arsk',
    'Armavir',
    'Arkhangelsk',
    'Ardon',
    'Ardatov',
    'Apsheronsk',
    'Aprelevka',
    'Apraksino',
    'Apatity',
    'Apastovo',
    'Anna',
    'Andreyevka',
    'Anapskaya',
    'Anapa',
    'Alnashi',
    "Al'met'yevsk",
    'Aleksin',
    'Alekseyevka',
    'Aleksandrovskoye',
    'Aleksandrovsk',
    'Aleksandrov',
    "Alatyr'",
    'Aktushi',
    'Aksay',
    'Akhtyrskiy',
    'Akhtyrskaya',
    'Akhtubinsk',
    'Akbulak',
    'Agryz',
    'Agronom',
    'Agoy',
    'Agapovka',
    'Agalatovo',
    'Afipskiy',
    'Adler',
    'Achit',
    'Abinsk',
    'Abdulino',
    "Vasil'yevo",
    "Ryl'sk",
    'Snezhnogorsk',
    'Adagum',
    'Dzuarikau',
    'Sorgovyy',
    'Alagir',
    'Yasnaya Polyana',
    'Khokhol',
    'Sklyayevo',
    'Shcherbinka',
    'Vorotynsk',
    'Ulitino',
    'Chupryakovo',
    'Verzilovo',
    'Moskovskiy',
    'Razvilka',
    'Gribki',
    'Topki',
    'Bugry',
    'Bibayevo-Chelny',
    'Korkmaskala',
    'Pavlovo',
    'Oni',
    "Oktyabr'",
    'Zaozersk',
    'Kalinikha',
    'Kondratovo',
    'Znamensk',
    'Zarechnyy',
    'Kochubeyevskoye',
    'Novokumskiy',
    'Zheleznovodskiy',
    "Solnechnodol'sk",
    'Piter',
    "Net'inka",
    'Dorozhnyy',
    'Usinsk',
    'Volovo',
    'Priladozhskiy',
    'Tyumenskiy',
    'Tyube',
    "Yunost'",
    'Zhdanovskiy',
    "Zonal'noye",
    'Zmeinogorsk',
    'Zavodoukovsk',
    "Zaural'skiy",
    'Zarubino',
    'Zarinsk',
    'Zaozërnyy',
    'Yuzhnyy',
    "Yuzhnoural'sk",
    'Yushala',
    'Yevsino',
    'Yeniseysk',
    'Yemanzhelinsk',
    'Yelanskiy',
    'Yekaterinburg',
    'Yaya',
    'Yar-Sale',
    'Yarovoy',
    'Yar',
    'Yalutorovsk',
    'Vorkuta',
    'Volchikha',
    'Volchansk',
    'Vinzili',
    'Vikulovo',
    'Veseloyarsk',
    'Vershina Tei',
    "Verkhotur'ye",
    'Verkhnyaya Salda',
    'Verkhnyaya Pyshma',
    'Verkhniy Ufaley',
    'Verkhneye Dubrovo',
    'Verkhne-Katunskoye',
    'Vargashi',
    'Uzhur',
    'Uyar',
    "Uvel'skiy",
    "Ust'-Tarka",
    "Ust'-Kalmanka",
    "Ust'-Charyshskaya Pristan'",
    "Ust'-Abakan",
    'Urengoy',
    'Urazbayeva',
    'Uray',
    'Uporovo',
    'Tyumen',
    "Tyumen'",
    'Tyazhinskiy',
    'Turinskaya Sloboda',
    'Turinsk',
    'Tselinnoye',
    'Trudarmeyskiy',
    'Tomsk',
    'Toguchin',
    "Tobol'sk",
    'Temirtau',
    'Tayshet',
    'Tayga',
    'Tavda',
    'Tatarsk',
    'Tashtagol',
    'Tarko-Sale',
    'Tara',
    "Tal'menka",
    'Tabat',
    "Sysert'",
    'Svetlolobovo',
    'Suzun',
    'Sungurova',
    'Sukhoy Log',
    'Strezhevoy',
    "Staraya Kozul'ka",
    'Staraya Yurga',
    'Sredneuralsk',
    'Sovetskoye',
    'Sosnovoborsk',
    'Sorsk',
    'Sokolovo',
    'Smolenskoye',
    'Slavgorod',
    'Shushenskoye',
    'Shumikha',
    'Shumanovka',
    'Shishino',
    'Shevchenko',
    'Sheregesh',
    "Sherbakul'",
    'Shatrovo',
    'Sharypovo',
    'Shagonar',
    'Shadrinsk',
    'Serov',
    'Sayanogorsk',
    'Sapogov',
    "Samus'",
    'Salym',
    'Salekhard',
    'Salair',
    'Sakhapta',
    'Rybalovo',
    'Rubtsovsk',
    'Rodino',
    'Rezh',
    'Reftinskiy',
    'Rebrikha',
    'Rastsvet',
    'Rassvet',
    'Promyshlennaya',
    "Prokop'yevsk",
    'Predivinsk',
    'Poykovskiy',
    'Pospelikha',
    'Posevnaya',
    'Polysayevo',
    'Polevskoy',
    'Poletayevo',
    'Podlomsk',
    'Pobochnoye',
    'Plast',
    'Petukhovo',
    'Pervomayskoye',
    'Perevalovo',
    'Peregrebnoye',
    'Pavlogradka',
    'Pangody',
    'Ozerki',
    'Osinniki',
    'Ordynskoye',
    'Omsk',
    "Ob'",
    'Nyagan',
    'Noyabrsk',
    'Novy Urengoy',
    'Novosibirsk',
    'Novosëlovo',
    'Novoromanovka',
    'Novokuznetsk',
    'Novochernorechenskiy',
    'Novoaltaysk',
    'Novaya Lyalya',
    "Noril'sk",
    'Nizhnyaya Tavda',
    'Nizhnyaya Salda',
    'Nizhnyaya Omka',
    'Nizhnevartovsk',
    'Nizhneudinsk',
    'Neyvo-Rudyanka',
    "Nev'yansk",
    'Nefteyugansk',
    'Nazarovo',
    'Nadym',
    'Myski',
    'Muzhi',
    'Mundybash',
    'Moryakovskiy Zaton',
    'Monetnyy',
    'Minusinsk',
    'Minayevka',
    'Miasskoye',
    'Miass',
    'Mezhdurechenskiy',
    'Mezhdurechensk',
    "Mel'nikovo",
    'Megion',
    'Mayna',
    'Maslyanino',
    "Mar'yanovka",
    'Mariinsk',
    'Malysheva',
    'Maltsevo',
    'Malinovka',
    'Novoisetskoye',
    'Linevo',
    'Lesosibirsk',
    'Lesogorsk',
    'Leninsk-Kuznetsky',
    'Leboter',
    'Lakino',
    'Labytnangi',
    'Kyzyl',
    'Kyshtym',
    'Kuyagan',
    'Kurtamysh',
    'Kupino',
    'Kulunda',
    'Kulakovo',
    'Krutinka',
    'Krivosheino',
    "Kremenkul'",
    'Krasnoyarsk',
    "Krasnoural'sk",
    "Krasnotur'insk",
    'Krasnoobsk',
    "Kozul'ka",
    'Kozhevnikovo',
    'Koyelga',
    'Korkino',
    'Kopylova',
    'Kopeysk',
    'Yugorsk',
    "Kolyvan'",
    "Kol'tsovo",
    'Kolpashevo',
    'Kodinsk',
    'Kochenevskiy',
    'Kiselëvsk',
    'Kirovo',
    'Kirovgrad',
    'Khovu-Aksy',
    'Khokhlovo',
    'Kharp',
    'Khanzhino',
    'Khanty-Mansiysk',
    'Khandagayty',
    'Khalyasavey',
    'Kemerovo',
    'Kataysk',
    'Kasli',
    'Kartaly',
    'Karpinsk',
    'Karkatayevy',
    'Kargat',
    'Kargasok',
    'Karasuk',
    'Kansk',
    'Kamyshlov',
    "Kamensk-Ural'skiy",
    "Kamen'-na-Obi",
    'Kaltan',
    'Kalachinsk',
    "Ivdel'",
    'Iskitim',
    "Isil'kul'",
    'Ishim',
    'Isetskoye',
    'Irbit',
    'Inta',
    'Ilanskiy',
    'Gorno-Altaysk',
    'Golyshmanovo',
    'Glyadyanskoye',
    'Firsovo',
    "D'yektiyek",
    'Dudinka',
    'Dubinino',
    "Dovol'noye",
    'Dorogino',
    'Divnogorsk',
    "Dem'yanovka",
    'Degtyarsk',
    'Dalmatovo',
    'Chunskiy',
    'Chulym',
    'Cheuskiny',
    'Chernogorsk',
    'Cherepanovo',
    'Chelyabinsk',
    "Chebarkul'",
    'Chazhemto',
    'Chastoostrovskoye',
    'Chany',
    'Chadan',
    'Bystryanka',
    'Bulanash',
    'Borodino',
    'Bolotnoye',
    'Boguchany',
    'Bogotol',
    'Bogdanovich',
    'Bogandinskiy',
    'Bobrovskiy',
    'Bobkovo',
    'Biysk',
    'Bezmenovo',
    'Beya',
    'Berezovka',
    'Beryozovo',
    'Berdsk',
    'Belyy Yar',
    'Beloyarovka',
    'Belovo',
    'Belokurikha',
    "Bashakul'",
    'Baryshevo',
    'Barnaul',
    'Barabinsk',
    'Balakhta',
    'Atamanovo',
    'Askiz',
    'Asino',
    'Asbestovskiy',
    'Asbest',
    'Artëmovskiy',
    'Argayash',
    'Aramil',
    'Anzhero-Sudzhensk',
    'Altayskoye',
    'Aleysk',
    'Alapayevsk',
    'Achinsk',
    'Abaza',
    'Abatskiy',
    'Abakan',
    'Pervomansk',
    'Unyugan',
    'Snezhinsk',
    'Gagarskiy',
    'Ozersk',
    "Novoural'sk",
    'Seversk',
    'Solnechnyy',
    'Svobodnyy',
    'Purovsk',
    'Gubkinskiy',
    'Purpe',
    'Lempino',
    'Raduzhny',
    'Nagornyy',
    'Lyantor',
    'Uyskoye',
    'Zima',
    'Zheleznogorsk-Ilimskiy',
    'Zharikovo',
    'Zavitinsk',
    'Zalari',
    'Zakamensk',
    'Zaigrayevo',
    "Zabaykal'sk",
    'Yekaterinoslavka',
    'Yaroslavskiy',
    'Yakutsk',
    'Vyazemskiy',
    'Vozzhayevka',
    "Vol'no-Nadezhdinskoye",
    'Vladivostok',
    'Vladimiro-Aleksandrovskoye',
    'Vilyuysk',
    'Vikhorevka',
    'Verkhnevilyuysk',
    'Vanavara',
    "Ust'-Ordynskiy",
    "Ust'-Kut",
    "Ust'-Ilimsk",
    'Ussuriysk',
    "Usol'ye-Sibirskoye",
    "Urul'ga",
    'Ulety',
    'Ulan-Ude',
    'Ulakhan-An',
    'Uktur',
    'Udachny',
    'Tynda',
    'Turuntayevo',
    'Tulun',
    'Tsolga',
    "Tel'ma",
    'Tayturka',
    'Tarbagatay',
    'Tambovka',
    'Talakan',
    'Taksimo',
    'Svirsk',
    'Spasskoye',
    "Spassk-Dal'niy",
    'Smidovich',
    'Slyudyanka',
    'Slavyanka',
    'Skovorodino',
    'Sibirtsevo',
    'Shimanovsk',
    'Shilka',
    'Sherlovaya Gora',
    'Shelekhov',
    'Sharalday',
    "Severobaykal'sk",
    'Seryshevo',
    'Selenginsk',
    'Raychikhinsk',
    'Progress',
    'Priamurskiy',
    "Pos'yet",
    'Pokrovsk',
    'Pokrovka',
    'Pogranichnyy',
    'Petropavlovka',
    'Peleduy',
    'Partizansk',
    'Oymur',
    "Onokhoy-Shibir'",
    'Olovyannaya',
    'Oktëmtsy',
    "Obluch'ye",
    'Nyurba',
    'Novoshakhtinskiy',
    'Novonezhino',
    'Novokurovka',
    "Novaya Bryan'",
    'Nizhneangarsk',
    'Neryungri',
    'Nerchinsk',
    'Nekrasovka',
    'Nakhodka',
    "Mukhor-Shibir'",
    'Mokhsogollokh',
    'Mogoytuy',
    'Mogocha',
    'Meget',
    'Mayya',
    'Makkaveyevo',
    "Magistral'nyy",
    'Magdagachi',
    'Luchegorsk',
    'Lipovtsy',
    'Lesozavodsk',
    'Lensk',
    'Leninskoye',
    'Novolenino',
    'Kyakhta',
    'Kuytun',
    'Kutulik',
    "Kul'dur",
    'Krasnokamensk',
    'Kraskino',
    'Konstantinovka',
    'Komsomolsk-on-Amur',
    'Kiyevka',
    'Kirovskiy',
    'Kirensk',
    'Kiinsk',
    'Khurmuli',
    'Khorol',
    'Khocho',
    'Khilok',
    'Khabarovsk',
    'Karymskoye',
    "Kamen'-Rybolov",
    'Kabansk',
    'Ivolginsk',
    'Ivanovka',
    'Irkutsk',
    'Gusinoozyorsk',
    'Granovshchina',
    'Gayter',
    'Galenki',
    "El'ban",
    'Dauriya',
    'Darasun',
    'Dalnerechensk',
    'Churapcha',
    "Chul'man",
    'Chita',
    'Chernyshevsk',
    'Chernigovka',
    'Cheremkhovo',
    'Borzya',
    "Bol'shoy Kamen'",
    'Bokhan',
    'Bodaybo',
    'Birobidzhan',
    'Berezovyy',
    'Berezovskiy',
    'Belogorsk',
    'Beklemishevo',
    "Baykal'sk",
    'Bada',
    'Babushkin',
    'Aykhal',
    'Artëm',
    "Arsen'yev",
    'Arkhara',
    'Angarsk',
    'Amursk',
    'Amga',
    'Aldan',
    'Aginskoye',
    'Chistyye Klyuchi',
    'Dalnegorsk',
    'Bratsk',
    'Sayansk',
    "Yantal'",
    'Vilyuchinsk',
    'Zaporozhskoye',
    'Yuzhno-Sakhalinsk',
    'Yuzhno-Kurilsk',
    'Yelizovo',
    'Vanino',
    'Vakhrushev',
    'Uglegorsk',
    "Tigil'",
    "Sovetskaya Gavan'",
    'Smirnykh',
    'Shakhtersk',
    'Poronaysk',
    'Petropavlovsk-Kamchatsky',
    'Palatka',
    'Palana',
    'Omsukchan',
    'Ola',
    'Okha',
    'Nikolayevsk-on-Amur',
    "Nevel'sk",
    'Myaundzha',
    "Moskal'vo",
    'Makarov',
    'Magadan',
    'Korsakov',
    'Kholmsk',
    'Dolinsk',
    'Chapayevo',
    'Boshnyakovo',
    'Aniva',
    'Anadyr',
    'Aleksandrovsk-Sakhalinskiy',
    'Mamonovo',
    'Donskoye',
    'Baltiysk',
    'Lavrentiya',
    'Egvekinot',
    'Vorsino',
    'Mysy',
    'Savinskoye',
    'Semrino',
    'Villozi',
    'Voyskorovo',
    "Tel'mana",
    'Bakuli',
    "Zarech'ye",
    'Magas',
    'Kazanskoye',
    'Agroles',
    'Lekarstvennoye',
    'Kurilovo',
    'Ptichnoye',
    'Izmaylovo',
    'Shishkin Les',
    'Rozhnovka',
    'Novogorsk',
    'Bychikha',
    'Povedniki',
    'Belorechenskiy',
    'Ordynskiy',
    'Volgovo',
    'Kogalym',
    'Pyt-Yakh',
    'Langepas',
    'Severomorsk-3',
    'Obolensk',
    'Peresvet',
    'Sarafonovo',
    'Mokino',
    'Pokachi',
    'Khanymey',
    'Izluchinsk',
    "Vasil'yevskoye",
    "Maloye Sal'kovo",
    'Fedotovo',
    "Kut'-Yakh",
    'Duslyk',
    'Bakhchisaray',
    'Borok',
    'Sitniki',
    'Noginsk-9',
    'Tutaul',
    'Rechitsa',
    'Magnitnyy',
    'Beregovoy',
    'Gubkinsky',
    'Dzerzhinsky',
    'Remmash',
    'Innopolis',
    'Zhdankovskiyi',
    'Vniissok',
  ],
  Estonia: [
    'Vändra',
    'Vosu',
    'Võru',
    'Vonnu',
    'Voka',
    'Võhma',
    'Viru-Nigula',
    'Vinni',
    'Viljandi',
    'Viimsi',
    'Vihtra',
    'Vihasoo',
    'Kivi-Vigala',
    'Veriora',
    'Vasalemma',
    'Vastseliina',
    'Vao',
    'Vanamoisa',
    'Valga',
    'Väimela',
    'Väike-Maarja',
    'Vaida',
    'Vaeaena',
    'Ülenurme',
    'Türi',
    'Turba',
    'Tostamaa',
    'Tõrva',
    'Torma',
    'Tootsi',
    'Toila',
    'Tartu',
    'Tapa',
    'Tamsalu',
    'Tallinn',
    'Tahkuranna',
    'Taebla',
    'Tabasalu',
    'Suurupi',
    'Suure-Jaani',
    'Sonda',
    'Sindi',
    'Simuna',
    'Sillamäe',
    'Saue',
    'Saku',
    'Riisipere',
    'Rebala',
    'Rapla',
    'Raepina',
    'Randvere',
    'Rakvere',
    'Raikküla Parish',
    'Rae Parish',
    'Püssi',
    'Puhja',
    'Pringi',
    'Porkuni',
    'Põlva',
    'Põltsamaa',
    'Peetri',
    'Pärnu-Jaagupi',
    'Pärnu',
    'Palivere',
    'Paldiski',
    'Pajusti',
    'Paide',
    'Otepaeae',
    'Oru',
    'Orissaare',
    'Oisu',
    'Karksi-Nuia',
    'Nõo',
    'Narva-Jõesuu',
    'Narva',
    'Naage',
    'Mustla',
    'Muraste',
    'Moisakuela',
    'Märjamaa',
    'Maardu',
    'Lueganuse',
    'Loo',
    'Loksa',
    'Liiva',
    'Liikva',
    'Lihula',
    'Lelle',
    'Laulasmaa',
    'Laimjala',
    'Laehte',
    'Lagedi',
    'Kuusalu',
    'Kuressaare',
    'Kuremäe',
    'Kunda',
    'Kulna',
    'Külitse',
    'Kose',
    'Kohtla-Järve',
    'Kohtla-Nomme',
    'Kohila',
    'Klooga',
    'Kiviõli',
    'Kilingi-Nõmme',
    'Kiisa',
    'Kiili',
    'Keila',
    'Kehtna',
    'Kehra',
    'Kase',
    'Kaerkna',
    'Kärdla',
    'Kambja',
    'Kaiu',
    'Kadrina',
    'Jüri',
    'Jõhvi',
    'Jõgeva',
    'Jõelähtme Parish',
    'Jaervekuela',
    'Järva-Madise',
    'Järvakandi',
    'Jaeneda',
    'Ilmandu',
    'Iisaku Parish',
    'Helme',
    'Harkujaerve',
    'Harku',
    'Haljava',
    'Haljala',
    'Haapsalu',
    'Haabneeme',
    'Emmaste',
    'Elva',
    'Avinurme',
    'Assaku',
    'Aseri',
    'Aruvalla',
    'Arukuela',
    'Ardu',
    'Aravete',
    'Antsla',
    'Ämari',
    'Albu',
    'Alatskivi',
    'Aespa',
    'Aegviidu',
    'Adavere',
    'Abja-Paluoja',
    'Rahivere',
    'Someru',
    'Soinaste',
    'Laane',
    'Meeliku',
    'Maegiste',
    'Poeoera',
    'Vaeaena-Joesuu',
    'Laagri',
    'Aaviku',
    'Kaasikvaelja',
    'Saaremaa',
  ],
  Latvia: [
    'Valmiera',
    'Zvejniekciems',
    'Zilupe',
    'Zelmeni',
    'Vircava',
    'Viļāni',
    'Viesīte',
    'Vidriži',
    'Vecumnieki',
    'Vergale',
    'Ventspils',
    'Varakļāni',
    'Vangaži',
    'Valka',
    'Valdemarpils',
    'Vainode',
    'Upesciems',
    'Ulbroka',
    'Tukums',
    'Tireli',
    'Tilža',
    'Talsi',
    'Subinaite',
    'Strenči',
    'Stende',
    'Spunciems',
    'Smiltene',
    'Smarde',
    'Skrunda',
    'Skrīveri',
    'Skaistkalne',
    'Silene',
    'Sigulda',
    'Seda',
    'Sece',
    'Saulkrasti',
    'Sauleskalns',
    'Saldus',
    'Salaspils',
    'Salacgrīva',
    'Sabile',
    'Rūjiena',
    'Rucava',
    'Garkalne',
    'Ropaži',
    'Roya',
    'Rigas Rajons',
    'Riga',
    'Ribene',
    'Rēzekne',
    'Renda',
    'Rencēni',
    'Ranka',
    'Ragana',
    'Pure',
    'Priekuļi',
    'Priekule',
    'Preiļi',
    'Pļaviņas',
    'Pinki',
    'Piltene',
    'Pavilosta',
    'Palsmane',
    'Ozolnieki',
    'Olaine',
    'Ogresgals',
    'Ogre',
    'Dumciems',
    'Nikrace',
    'Nereta',
    'Naukšēni',
    'Nakotne',
    'Mersrags',
    'Medemciems',
    'Mazsalaca',
    'Matīši',
    'Malpils',
    'Madona',
    'Ludza',
    'Lubāna',
    'Lizums',
    'Līvāni',
    'Limbaži',
    'Līgatne',
    'Liepupe',
    'Liepāja',
    'Liepa',
    'Lielvārde',
    'Lici',
    'Lēdmane',
    'Lauciene',
    'Lapmezciems',
    'Kuldīga',
    'Sunisi',
    'Krāslava',
    'Kolka',
    'Koknese',
    'Kociena',
    'Kesterciems',
    'Ķekava',
    'Ķegums',
    'Kazdanga',
    'Kauguri',
    'Katlakalns',
    'Kārsava',
    'Kapsede',
    'Kandava',
    'Kakenieki',
    'Jūrmala',
    'Jumprava',
    'Jelgava',
    'Jēkabpils',
    'Jaunpiebalga',
    'Jaunolaine',
    'Jaunmarupe',
    'Jaunjelgava',
    'Inčukalns',
    'Ilukste',
    'Ikšķile',
    'Iecava',
    'Gulbene',
    'Grundzale',
    'Grobina',
    'Gaujiena',
    'Gauja',
    'Garciems',
    'Evele',
    'Ērgļi',
    'Engure',
    'Eleja',
    'Dzērbene',
    'Dundaga',
    'Druviena',
    'Drusti',
    'Dobele',
    'Dagda',
    'Code',
    'Cesvaine',
    'Cēsis',
    'Carnikava',
    'Broceni',
    'Berzkalni',
    'Berzaune',
    'Bene',
    'Bauska',
    'Baravaja',
    'Balvi',
    'Baltezers',
    'Balozi',
    'Baldone',
    'Babite',
    'Auri',
    'Augstkalne',
    'Auce',
    'Ape',
    'Anuzi',
    'Ane',
    'Alūksne',
    'Alsunga',
    'Aloja',
    'Allazi',
    'Aknīste',
    'Aizpute',
    'Aizkraukle',
    'Aizklani',
    'Ainaži',
    'Kadaga',
    'Adazi',
    'Priezciems',
    'Baloži',
    'Sauriesi',
    'Priedkalne',
    'Valdlauci',
  ],
  'Republic of Lithuania': [
    'Zygenai',
    'Zujunai',
    'Žiežmariai',
    'Žemaičių Naumiestis',
    'Želva',
    'Zarasai',
    'Zapyškis',
    'Zagare',
    'Ylakiai',
    'Visaginas',
    'Virbalis',
    'Vilnius',
    'Vilkija',
    'Vilkaviškis',
    'Vievis',
    'Versiai',
    'Velzys',
    'Veiviržėnai',
    'Veiveriai',
    'Veisiejai',
    'Varena',
    'Vandziogala',
    'Vaidotai',
    'Vadžgirys',
    'Užpaliai',
    'Utena',
    'Ukmerge',
    'Tytuvenai',
    'Tulnikiai',
    'Tryskiai',
    'Trakai',
    'Tirksliai',
    'Telšiai',
    'Tauragė',
    'Sventoji',
    'Švenčionys',
    'Švenčionėliai',
    'Švėkšna',
    'Sudervė',
    'Subacius',
    'Stakliškės',
    'Snaukstai',
    'Smalininkai',
    'Sliktine',
    'Skuodas',
    'Skirsnemunė',
    'Širvintos',
    'Simnas',
    'Šilutė',
    'Šilėnai',
    'Šilalė',
    'Šiauliai',
    'Šėta',
    'Seredzius',
    'Seirijai',
    'Šeduva',
    'Seda',
    'Saugos',
    'Sariai',
    'Santaika',
    'Šalčininkai',
    'Salantai',
    'Salakas',
    'Sakyna',
    'Sakūčiai',
    'Šakiai',
    'Ryskenai',
    'Rumšiškės',
    'Rukla',
    'Rūdiškės',
    'Rokiškis',
    'Rinkuskiai',
    'Rietavas',
    'Riese',
    'Raseiniai',
    'Ramygala',
    'Rainiai',
    'Radviliškis',
    'Puskelniai',
    'Prienai',
    'Priekulė',
    'Pociūnėliai',
    'Plungė',
    'Piestuvenai',
    'Pjauliai',
    'Pelednagiai',
    'Pasvalys',
    'Pašiaušė',
    'Panevezys',
    'Paluobiai',
    'Paliuniskis',
    'Palanga',
    'Pakruojis',
    'Pajuralis',
    'Pagiriai',
    'Pagėgiai',
    'Padovinys',
    'Pabradė',
    'Nida',
    'Netoniai',
    'Nemezis',
    'Nemenčinė',
    'Naujoji Akmenė',
    'Naisiai',
    'Mosedis',
    'Molėtai',
    'Mockava',
    'Micaiciai',
    'Meskuiciai',
    'Merkinė',
    'Mažeikiai',
    'Mazeikiai',
    'Matlaukys',
    'Marijampolė',
    'Marijampolis',
    'Maneikiai',
    'Maišiagala',
    'Maciuiciai',
    'Macikai',
    'Luksnenai',
    'Lubiai',
    'Lentvaris',
    'Leipalingis',
    'Lazdijai',
    'Lapes',
    'Labūnava',
    'Kybartai',
    'Kužiai',
    'Kuršėnai',
    'Kurmaičiai',
    'Kupiškis',
    'Kumzaiciai',
    'Kumpikai',
    'Kulva',
    'Kūlupėnai',
    'Kuliai',
    'Kulautuva',
    'Kuciunai',
    'Kruonis',
    'Kretingalė',
    'Kretinga',
    'Krekenava',
    'Klaipėda',
    'Kelmė',
    'Kėdainiai',
    'Kazlų Rūda',
    'Kaunas',
    'Karmėlava',
    'Karkle',
    'Kapčiamiestis',
    'Kampiskiai',
    'Kalvarija',
    'Kalnujai',
    'Kaišiadorys',
    'Kačerginė',
    'Juskonys',
    'Yure',
    'Jurbarkas',
    'Juodšiliai',
    'Josvainiai',
    'Joniškis',
    'Joniskis',
    'Joniškėlis',
    'Jonava',
    'Jieznas',
    'Jašiūnai',
    'Ilgiziukai',
    'Ignalina',
    'Gruzdziai',
    'Gruzdiske',
    'Grinkiškis',
    'Grigiškės',
    'Girkalnis',
    'Gineitiskes',
    'Giedraičiai',
    'Gelgaudiškis',
    'Garliava',
    'Gargždai',
    'Ežerėlis',
    'Endriejavas',
    'Elektrėnai',
    'Eišiškės',
    'Einoraiciai',
    'Dusetos',
    'Druskininkai',
    'Dreverna',
    'Drasuciai',
    'Dovilai',
    'Dovainonys',
    'Domeikava',
    'Daugai',
    'Darzininkai',
    'Darbenai',
    'Butkiske',
    'Blauzdziunai',
    'Biržai',
    'Birštonas',
    'Bezdonys',
    'Berniunai',
    'Bategala',
    'Bartkuskis',
    'Bariunai',
    'Balbieriškis',
    'Bajorai',
    'Baisogala',
    'Babtai',
    'Ariogala',
    'Anykščiai',
    'Alytus',
    'Alsedziai',
    'Akmene',
    'Akademija',
    'Linksmakalnis',
    'Sudmantai',
    'Truseliai',
    'Saulazoliai',
    'Noreikiskes',
    'Kreivalauziai',
    'Akavitai',
    'Narepai',
    'Pravieniskes I',
  ],
  Uzbekistan: [
    'Nukus',
    'Samarkand',
    'Namangan',
    'Denov',
    'Boysun',
    'Uzbekistan',
    'Urgench',
    'Tashkent',
    'Kokand',
    'Navoiy',
    'Margilan',
    'Khiva',
    'Jizzakh',
    'Gurlan',
    'Guliston',
    'Fergana',
    'Amir Timur',
    'Chust',
    'Yangiovul',
  ],
  Sweden: [
    'Vuollerim',
    'Vittangi',
    'Vidsel',
    'Ursviken',
    'Umeå',
    'Toere',
    'Tavelsjoe',
    'Taerendoe',
    'Taeftea',
    'Soedra Sunderbyn',
    'Skelleftehamn',
    'Skellefteå',
    'Sjulsmark',
    'Seittenkaari',
    'Ranea',
    'Saevar',
    'Rosvik',
    'Roknaes',
    'Robertsfors',
    'Roebaeck',
    'Piteå',
    'Pajala',
    'OEvertornea',
    'OEverkalix',
    'OErviken',
    'Ojebyn',
    'Obbola',
    'Norrfjaerden',
    'Malmberget',
    'Luleå',
    'Loevanger',
    'Lannavaara',
    'Langtrask',
    'Kusmark',
    'Koskullskulle',
    'Korpilombolo',
    'Kiruna',
    'Karesuando',
    'Kalix',
    'Kage',
    'Junosuando',
    'Jukkasjaervi',
    'Joern',
    'Hortlax',
    'Holmsund',
    'Hemmingsmark',
    'Harads',
    'Haparanda',
    'Gronbo',
    'Gammelstad',
    'Gällivare',
    'Ersmark',
    'Byske',
    'Bygdsiljum',
    'Bygdea',
    'Burtraesk',
    'Burea',
    'Bullmark',
    'Botsmark',
    'Boliden',
    'Boden',
    'Blasmark',
    'Arnemark',
    'Anaeset',
    'AElvsbyn',
    'Alunda',
    'Balinge',
    'Bjorklinge',
    'Nasviken',
    'Zinkgruvan',
    'Ytterby',
    'Ystad',
    'Yngsjoe',
    'Vrigstad',
    'Vretstorp',
    'Vreta Kloster',
    'Vrena',
    'Vollsjoe',
    'Vittskoevle',
    'Vittsjoe',
    'Vittinge',
    'Vittaryd',
    'Vitaby',
    'Visseltofta',
    'Vissefjaerda',
    'Vislanda',
    'Viskafors',
    'Visingso',
    'Visby',
    'Virserum',
    'Virsbo Bruk',
    'Virestad',
    'Vintrosa',
    'Vintrie',
    'Vinsloev',
    'Vinninga',
    'Vingaker',
    'Vindeln',
    'Vimmerby',
    'Vilshult',
    'Villshaerad',
    'Vilhelmina',
    'Viksjoefors',
    'Vikmanshyttan',
    'Vikingstad',
    'Viken',
    'Vikarbyn',
    'Vetlanda',
    'Vessigebro',
    'Venjan',
    'Vendelso',
    'Vemdalen',
    'Vellinge',
    'Vejbystrand',
    'Veinge',
    'Vegby',
    'Vega',
    'Vedum',
    'Vedevag',
    'Vederslov',
    'Veddige',
    'Veberoed',
    'Vaxtorp',
    'Vaxjo',
    'Vaxholm',
    'Vattholma',
    'Vastra Torup',
    'Vaestra Karup',
    'Vaestra Froelunda',
    'Västervik',
    'Vaesterljung',
    'Vasterlanda',
    'Vaesterfaernebo',
    'Västerås',
    'Vassmoloesa',
    'Vaese',
    'Vasby',
    'Vartofta',
    'Vaersas',
    'Vaeroebacka',
    'Varnhem',
    'Vaernamo',
    'Vargon',
    'Vargarda',
    'Varekil',
    'Varby',
    'Varberg',
    'Vara',
    'Vansbro',
    'Vaennaesby',
    'Vaennaes',
    'Vankiva',
    'Vanga',
    'Vaenersborg',
    'Vamhus',
    'Valskog',
    'Valsjobyn',
    'Vallsta',
    'Vallentuna',
    'Vallda',
    'Vallberga',
    'Vallakra',
    'Valla',
    'Valdemarsvik',
    'Valbo',
    'Valberg',
    'Vajern',
    'Vaeja',
    'Vagnhaerad',
    'Vaggeryd',
    'Vadstena',
    'Vaederstad',
    'Vaddo',
    'Vaeckelsang',
    'Uttran',
    'Uto',
    'Urshult',
    'Urasa',
    'Uppsala',
    'Upplands Vasby',
    'Upphaerad',
    'Hjaerup',
    'Unnaryd',
    'Undrom',
    'Undersaker',
    'Undenaes',
    'Ulricehamn',
    'Ullared',
    'Ugglarp',
    'Uddevalla',
    'Uddeholm',
    'Ucklum',
    'Tystberga',
    'Tyringe',
    'Tyreso Strand',
    'Tygelsjoe',
    'Tvaaker',
    'Tungelsta',
    'Tun',
    'Tumba',
    'Tullinge',
    'Trosa',
    'Trollhättan',
    'Gimo',
    'Transum',
    'Trelleborg',
    'Trekanten',
    'Trehorningsjo',
    'Travad',
    'Traslovslage',
    'Traryd',
    'Trangsviken',
    'Trangsund',
    'Tranemo',
    'Tranas',
    'Traedet',
    'Totebo',
    'Tostared',
    'Toesse',
    'Torup',
    'Torso',
    'Torslanda',
    'Torshaella',
    'Torsby',
    'Torsas',
    'Torsaker',
    'Torpshammar',
    'Tormestorp',
    'Torestorp',
    'Torekov',
    'Toereboda',
    'Tomelilla',
    'Toellsjoe',
    'Tollered',
    'Tollarp',
    'Tolg',
    'Toftbyn',
    'Tofta',
    'Toecksfors',
    'Tjurko',
    'Tjureda',
    'Tjoernarp',
    'Tjaellmo',
    'Tingstaede',
    'Tingsryd',
    'Timra',
    'Timmernabben',
    'Timmele',
    'Tierp',
    'Tidan',
    'Tidaholm',
    'Tibro',
    'Tenhult',
    'Teckomatorp',
    'Taevelsas',
    'Taernsjoe',
    'Taernaby',
    'Tanumshede',
    'Tandsbyn',
    'Taellberg',
    'Tagarp',
    'Taby',
    'Taberg',
    'Sysslebaeck',
    'Svenstavik',
    'Svenshoegen',
    'Svenljunga',
    'Sveg',
    'Svedala',
    'Svartsjo',
    'Svaertinge',
    'Svarta',
    'Svaerdsjoe',
    'Svanskog',
    'Svaengsta',
    'Svanesund',
    'Svaneholm',
    'Svaloev',
    'Surte',
    'Surahammar',
    'Sunne',
    'Sundsvall',
    'Sundsbruk',
    'Sundbyberg',
    'Sundborn',
    'Styrsoe',
    'Sturefors',
    'Stugun',
    'Stroevelstorp',
    'Stroemsund',
    'Stroemstad',
    'Stroemsnaesbruk',
    'Stromslund',
    'Stroemsholm',
    'Stroemsbruk',
    'Stravalla',
    'Stratjara',
    'Strassa',
    'Straengnaes',
    'Strandbaden',
    'Storvreta',
    'Storvik',
    'Storuman',
    'Storfors',
    'Storebro',
    'Stora Vika',
    'Stora Skedvi',
    'Stora Melloesa',
    'Stora Levene',
    'Stora Hoega',
    'Stora',
    'Stoellet',
    'Stoede',
    'Stocksund',
    'Stockholm',
    'Stockaryd',
    'Stocka',
    'Stoby',
    'Stjarnsund',
    'Stillingson',
    'Stigtomta',
    'Stenungsund',
    'Stenstorp',
    'Stensele',
    'Stenkullen',
    'Steninge',
    'Stenhamra',
    'Stehag',
    'Stavsjo',
    'Stanga',
    'Staelldalen',
    'Stallarholmen',
    'Staffanstorp',
    'Spekeroed',
    'Sparsor',
    'Sparreholm',
    'Soesdala',
    'Sorsele',
    'Sorberge',
    'Soeraker',
    'Sommen',
    'Sölvesborg',
    'Solna',
    'Solleroen',
    'Sollentuna',
    'Solleftea',
    'Sollebrunn',
    'Solberga',
    'Soedra Vi',
    'Soedra Sandby',
    'Sodra Hogsater',
    'Farsta strand',
    'Södertälje',
    'Soederkoeping',
    'Söderhamn',
    'Soederfors',
    'Soderby',
    'Soederbaerke',
    'Soderala',
    'Snofleboda',
    'Smygehamn',
    'Smoegen',
    'Smedstorp',
    'Smedjebacken',
    'Smalandsstenar',
    'Slussfors',
    'Slottsbron',
    'Sloeinge',
    'Slite',
    'Slagnas',
    'Skyllberg',
    'Skurup',
    'Skummeslovsstrand',
    'Skummeslov',
    'Skultuna',
    'Skruv',
    'Skövde',
    'Skondal',
    'Skoellersta',
    'Skoeldinge',
    'Skogstorp',
    'Skoghall',
    'Skogas',
    'Skivarp',
    'Skinnskatteberg',
    'Skillinge',
    'Skillingaryd',
    'Skeppshult',
    'Skepplanda',
    'Skene',
    'Skegrie',
    'Skattkaerr',
    'Skaerplinge',
    'Skaerholmen',
    'Skaerhamn',
    'Skaerblacka',
    'Skara',
    'Skapafors',
    'Skaenninge',
    'Skanes Fagerhult',
    'Skane',
    'Skaellinge',
    'Ska',
    'Sjoevik',
    'Sjoetorp',
    'Sjotofta',
    'Sjomarken',
    'Sjoebo',
    'Sjalevad',
    'Simrishamn',
    'Simlangsdalen',
    'Silverdalen',
    'Siljansnaes',
    'Sigtuna',
    'Sifferbo',
    'Sidensjoe',
    'Sibbhult',
    'Sexdrega',
    'Sennan',
    'Segmon',
    'Seglora',
    'Segersta',
    'Segersaeng',
    'Segeltorp',
    'Saxtorp',
    'Saxnaes',
    'Savsjostrom',
    'Saevsjoe',
    'Savedalen',
    'Kyrkobyn',
    'Saetila',
    'Saeter',
    'Saro',
    'Sankt Olof',
    'Sandviken',
    'Sandsjofors',
    'Sandhem',
    'Sandarne',
    'Sandared',
    'Saltsjo-Duvnas',
    'Saltsjoebaden',
    'Saelen',
    'Sala',
    'Sagmyra',
    'Saeffle',
    'Ryssby',
    'Rydsnaes',
    'Rydsgard',
    'Rydoebruk',
    'Rydebaeck',
    'Rydboholm',
    'Rydaholm',
    'Ryd',
    'Runhallen',
    'Rundvik',
    'Ruda',
    'Rottne',
    'Roestanga',
    'Rosersberg',
    'Roervik',
    'Roeroe',
    'Roerbaecksnaes',
    'Ronninge',
    'Ronneby',
    'Roennaeng',
    'Romelanda',
    'Roma',
    'Rolfstorp',
    'Roinge',
    'Roedeby',
    'Rockneby',
    'Rinkaby',
    'Ringarum',
    'Rimforsa',
    'Rimbo',
    'Riddarhyttan',
    'Reso',
    'Rengsjoe',
    'Rekarne',
    'Rejmyre',
    'Reftele',
    'Raevlanda',
    'Raettvik',
    'Ransta',
    'Ransater',
    'Rangedala',
    'Ranaes',
    'Ramvik',
    'Ramsjo',
    'Ramsele',
    'Ramsberg',
    'Ramnaes',
    'Ramloesa',
    'Ramdala',
    'Rada',
    'Raa',
    'Pilgrimstad',
    'Perstorp',
    'Paulistroem',
    'Paskallavik',
    'Paryd',
    'Partille',
    'Palsboda',
    'Paarp',
    'Oxie',
    'Oxeloesund',
    'OExabaeck',
    'Oviken',
    'OEverum',
    'Overturingen',
    'OEverlida',
    'Otterbaecken',
    'OEstra Soennarsloev',
    'OEstra Ryd',
    'Ostra Ljungby',
    'OEstra Karup',
    'OEstra Froelunda',
    'Ostmark',
    'OEsthammar',
    'OEstervala',
    'Östersund',
    'Osterskar',
    'Östermalm',
    'Osterhaninge',
    'OEsterbymo',
    'OEsterbybruk',
    'OEstansjoe',
    'OEssjoe',
    'OEsmo',
    'Oskarstroem',
    'Oskarshamn',
    'Osby',
    'Ortrask',
    'OErsundsbro',
    'Orsa',
    'Orrviken',
    'Orrefors',
    'Örnsköldsvik',
    'OErkelljunga',
    'OEregrund',
    'Örebro',
    'OErbyhus',
    'Orby',
    'Or',
    'Onslunda',
    'OEnnestad',
    'Olsremma',
    'Olsfors',
    'Olofstroem',
    'Olofstorp',
    'OEjersjoe',
    'Oje',
    'Offerdal',
    'OEdsmal',
    'OEdeshoeg',
    'Odensbacken',
    'OEdeborg',
    'OEdakra',
    'OEckeroe',
    'Ockelbo',
    'Nyvang',
    'Nynaeshamn',
    'Nyland',
    'Nykvarn',
    'Nyköping',
    'Nykil',
    'Nyhamnslage',
    'Nyhammar',
    'Nygard',
    'Nye',
    'Nybro',
    'Nyaker',
    'Nosund',
    'Nossebro',
    'Norsjoe',
    'Norsholm',
    'Norsborg',
    'Norrtaelje',
    'Norrsundet',
    'Norrköping',
    'Norrhult',
    'Norrala',
    'Norrahammar',
    'Norje',
    'Nordmaling',
    'Nordingra',
    'Norberg',
    'Norastrom',
    'Nora',
    'Nol',
    'Noedinge',
    'Njurundabommen',
    'Knivsta',
    'Navlinge',
    'Naettraby',
    'Naesviken',
    'Naesum',
    'Naessjoe',
    'Nashulta',
    'Naesaker',
    'Namdo',
    'Naelden',
    'Nacka',
    'Myresjoe',
    'Myggenaes',
    'Muskoe',
    'Munkfors',
    'Munkedal',
    'Munka-Ljungby',
    'Mullsjoe',
    'Mullhyttan',
    'Motala',
    'Moersil',
    'Moerrum',
    'Moerlunda',
    'Morgongava',
    'Moerbylanga',
    'Moerarp',
    'Mora',
    'Moensteras',
    'Mölnlycke',
    'Moelndal',
    'Moelnbo',
    'Moelltorp',
    'Molkom',
    'Moliden',
    'Moeklinta',
    'Moja',
    'Moholm',
    'Moheda',
    'Mockfjaerd',
    'Mjoelby',
    'Mjoebaeck',
    'Melloesa',
    'Mellerud',
    'Mellbystrand',
    'Mellansel',
    'Mehedeby',
    'Mattmar',
    'Matfors',
    'Marstrand',
    'Maersta',
    'Marma',
    'Markaryd',
    'Mariestad',
    'Marieholm',
    'Mariefred',
    'Mariannelund',
    'Mardaklev',
    'Mantorp',
    'Mansarp',
    'Mankarbo',
    'Malungsfors',
    'Malung',
    'Malsryd',
    'Malmoen',
    'Malmo',
    'Malmkoeping',
    'Malmbaeck',
    'Malilla',
    'Mala',
    'Lysekil',
    'Lyrestad',
    'Lycksele',
    'Lyckeby',
    'Lycke',
    'Lur',
    'Lundsbrunn',
    'Lunde',
    'Lund',
    'Ludvika',
    'Ludvigsborg',
    'Lovstabruk',
    'Loevestad',
    'Loettorp',
    'Lotorp',
    'Los',
    'Loensboda',
    'Lonashult',
    'Lomma',
    'Loegdea',
    'Loftahammar',
    'Lofsdalen',
    'Loedoese',
    'Loederup',
    'Loeddekoepinge',
    'Loeberoed',
    'Ljustorp',
    'Ljustero',
    'Ljusnedal',
    'Ljusne',
    'Ljusfallshammar',
    'Ljusdal',
    'Ljungskile',
    'Ljungsbro',
    'Ljungbyholm',
    'Ljungbyhed',
    'Ljungby',
    'Ljungaverk',
    'Ljung',
    'Lit',
    'Listerby',
    'Linneryd',
    'Linköping',
    'Linghem',
    'Linghed',
    'Lindome',
    'Lindesberg',
    'Linderoed',
    'Lindas',
    'Limmared',
    'Limhamn',
    'Lima',
    'Lillkyrka',
    'Lillhaerdal',
    'Lilla Edet',
    'Liljeholmen',
    'Likenas',
    'Lidkoeping',
    'Lidingoe',
    'Lidhult',
    'Lessebo',
    'Lesjoefors',
    'Lerum',
    'Lerdala',
    'Lerberget',
    'Lenhovda',
    'Leksand',
    'Lekeryd',
    'Laxa',
    'Larv',
    'Laerbro',
    'Laenna',
    'Langviksmon',
    'Langshyttan',
    'Langsele',
    'Laenghem',
    'Langasjoe',
    'Langas',
    'Landvetter',
    'Landskrona',
    'Landsbro',
    'Landfjaerden',
    'Landeryd',
    'Lammhult',
    'Lakene',
    'Laisvall',
    'Laholm',
    'Lagan',
    'Kyrkhult',
    'Kyrkesund',
    'Kvissleby',
    'Kvillsfors',
    'Kvidinge',
    'Kvicksund',
    'Kvibille',
    'Kvaenum',
    'Kungsoer',
    'Kungshamn',
    'Kungsgarden',
    'Kungsbacka',
    'Kungsaeter',
    'Kungsaengen',
    'Kungälv',
    'Kumla',
    'Kulltorp',
    'Kullavik',
    'Krylbo',
    'Krokom',
    'Kristinehamn',
    'Kristineberg',
    'Kristianstad',
    'Kristdala',
    'Kramfors',
    'Kosta',
    'Korsberga',
    'Koppom',
    'Kopparberg',
    'Kopmannebro',
    'Koepmanholmen',
    'Koepingebro',
    'Koeping',
    'Konga',
    'Kolsva',
    'Kolmarden',
    'Kolbaeck',
    'Kode',
    'Knutby',
    'Knislinge',
    'Knaered',
    'Kloevedal',
    'Klockrike',
    'Klippan',
    'Klintehamn',
    'Klavrestrom',
    'Klaessbol',
    'Klagstorp',
    'Klagshamn',
    'Klagerup',
    'Klaedesholmen',
    'Kivik',
    'Kista',
    'Kisa',
    'Kinnarumma',
    'Kinnahult',
    'Kinna',
    'Kimstad',
    'Killeberg',
    'Kilafors',
    'Kil',
    'Kaevlinge',
    'Katthammarsvik',
    'Kattarp',
    'Katrineholm',
    'Kaseberga',
    'Karra',
    'Kaerna',
    'Karlstad',
    'Karlskrona',
    'Karlskoga',
    'Karlshamn',
    'Karlsborg',
    'Karlholmsbruk',
    'Kareby',
    'Kalmar',
    'Kallinge',
    'Kallhall',
    'Kallered',
    'Kaellby',
    'Kaelarne',
    'Kageroed',
    'Junsele',
    'Joerlanda',
    'Jordbro',
    'Jonsered',
    'Jönköping',
    'Joenaker',
    'Jokkmokk',
    'Johannishus',
    'Johanneshov',
    'Jat',
    'Jaervsoe',
    'Jarved',
    'Jaerpen',
    'Jaerpas',
    'Jaernforsen',
    'Jaerna',
    'Jaerlasa',
    'Jaerbo',
    'Jaemshoeg',
    'Jaemjoe',
    'Jakobsberg',
    'Jadraas',
    'Istorp',
    'Insjoen',
    'Ingelstad',
    'Immeln',
    'Ilsbo',
    'Ilnestorp',
    'Iggesund',
    'Idre',
    'Idkerberget',
    'Hyssna',
    'Hyppeln',
    'Hyltebruk',
    'Hyllinge',
    'Husum',
    'Huskvarna',
    'Husby',
    'Hunnebostrand',
    'Hultsfred',
    'Hult',
    'Hudiksvall',
    'Huddinge',
    'Huaroed',
    'Hovmantorp',
    'Hoeviksnaes',
    'Hovas',
    'Hova',
    'Hoting',
    'Horred',
    'Hoernefors',
    'Horndal',
    'Horn',
    'Hoerja',
    'Horda',
    'Hoerby',
    'Hoeoer',
    'Hoenoe',
    'Holsljunga',
    'Holsbybrunn',
    'Hoeloe',
    'Holmsveden',
    'Hoellviken',
    'Hoekerum',
    'Hok',
    'Hoegsjoe',
    'Hoegsby',
    'Hoeganaes',
    'Hofors',
    'Hjortsberga',
    'Hjo',
    'Hjaertum',
    'Hjaernarp',
    'Hjaeltevad',
    'Hjalteby',
    'Hishult',
    'Hindas',
    'Hillerstorp',
    'Hillared',
    'Hestra',
    'Herrljunga',
    'Herrangen',
    'Henan',
    'Hemse',
    'Hemavan',
    'Helsingborg',
    'Helgum',
    'Hedesunda',
    'Hedemora',
    'Hedekas',
    'Hede',
    'Hedared',
    'Heby',
    'Heberg',
    'Haverdal',
    'Haestveda',
    'Hassloe',
    'Hässleholm',
    'Hasslarp',
    'Hasselfors',
    'Haesselby strand',
    'Hasselby',
    'Haerryda',
    'Harplinge',
    'Härnösand',
    'Harmanger',
    'Harloesa',
    'Harestad',
    'Harbo',
    'Handen',
    'Hanaskog',
    'Hamneda',
    'Hammerdal',
    'Hammenhoeg',
    'Hammarstrand',
    'Hammaro',
    'Hammarby',
    'Hamburgsund',
    'Halta',
    'Haelsoe',
    'Halmstad',
    'Hallstavik',
    'Hallstahammar',
    'Hallsta',
    'Hallsberg',
    'Haellnaes',
    'Hallnas',
    'Haellingsjoe',
    'Halleviksstrand',
    'Haellevadsholm',
    'Hallestad',
    'Haellekis',
    'Haelleforsnaes',
    'Haellefors',
    'Hallaryd',
    'Hallarod',
    'Hallabro',
    'Halla',
    'Haeljarp',
    'Hajom',
    'Haeggenas',
    'Haggeby',
    'Hagfors',
    'Hagby',
    'Hackas',
    'Habo',
    'Gyttorp',
    'Gysinge',
    'Gusum',
    'Gustavsberg',
    'Gustafs',
    'Gusselby',
    'Gunnilse',
    'Gunnebo',
    'Gunnarskog',
    'Gunnarn',
    'Gullspang',
    'Gullringen',
    'Gullbrandstorp',
    'Gullabo',
    'Guldsmedshyttan',
    'Gualoev',
    'Grythyttan',
    'Grytgoel',
    'Gryt',
    'Grycksbo',
    'Grundsund',
    'Grums',
    'Gronahog',
    'Grisslehamn',
    'Grimstorp',
    'Grimsloev',
    'Grimsas',
    'Grimeton',
    'Grillby',
    'Grevie',
    'Grebbestad',
    'Graestorp',
    'Grasmyr',
    'Granoe',
    'Gränna',
    'Graningebruk',
    'Graengesberg',
    'Grangarde',
    'Graeddoe',
    'Grabo',
    'Goetene',
    'Gothenburg',
    'Gnosjoe',
    'Gnesta',
    'Gnarp',
    'Glumsloev',
    'Glommen',
    'Glimakra',
    'Glava',
    'Glanshammar',
    'Gislaved',
    'Gidea',
    'Getinge',
    'Genarp',
    'Gemla',
    'Gävle',
    'Gaersnaes',
    'Garphyttan',
    'Garpenberg',
    'Gaerds Koepinge',
    'Gardsjo',
    'Ganghester',
    'Gamleby',
    'Gaellstad',
    'Gagnef',
    'Gaeddede',
    'Furuvik',
    'Furusund',
    'Furulund',
    'Funaesdalen',
    'Frufallan',
    'Froevi',
    'Froso',
    'Froseke',
    'Fritsla',
    'Fristad',
    'Frillesas',
    'Fridlevstad',
    'Fredriksberg',
    'Fredrika',
    'Fraensta',
    'Fraendefors',
    'Fotskal',
    'Forsvik',
    'Foersloev',
    'Forsheda',
    'Forshaga',
    'Forserum',
    'Forsbacka',
    'Forsa',
    'Fornasa',
    'Foellinge',
    'Flyinge',
    'Floda',
    'Floby',
    'Fliseryd',
    'Flisby',
    'Fleninge',
    'Flen',
    'Fjugesta',
    'Fjaerdhundra',
    'Fjaeras kyrkby',
    'Fjaellbacka',
    'Fjaelkinge',
    'Fiskebaeckskil',
    'Finspang',
    'Finneroedja',
    'Finja',
    'Filipstad',
    'Figeholm',
    'Fengersfors',
    'Fellingsbro',
    'Fegen',
    'Faroesund',
    'Farnas',
    'Faerloev',
    'Faerjestaden',
    'Faerila',
    'Farhult',
    'Faergelanda',
    'Farbo',
    'Falun',
    'Falköping',
    'Falkenberg',
    'Faker',
    'Fagervik',
    'Fagersta',
    'Fagersanna',
    'Fagerhult',
    'Fageras',
    'Fagelmara',
    'Fagelfors',
    'Esloev',
    'Eskilstuna',
    'Ervalla',
    'Eriksmala',
    'Enviken',
    'Enstaberga',
    'Enkoeping',
    'Eneryda',
    'Enebyberg',
    'Enanger',
    'Emmaboda',
    'Elloes',
    'Eldsberga',
    'Eksjoe',
    'Ekshaerad',
    'Ekerö',
    'Ekenaessjoen',
    'Edsele',
    'Edsbyn',
    'Edsbruk',
    'Edsbro',
    'Edane',
    'Ed',
    'Dyltabruk',
    'Dvaersaett',
    'Duved',
    'Drottningskaer',
    'Drottningholm',
    'Doesjebro',
    'Dorotea',
    'Donsoe',
    'Domsten',
    'Domsjo',
    'Docksta',
    'Djursholm',
    'Djurmo',
    'Djurhamn',
    'Djuras',
    'Dioe',
    'Dingle',
    'Delsbo',
    'Deje',
    'Degerhamn',
    'Degerfors',
    'Degeberga',
    'Danderyd',
    'Dals Rostock',
    'Dals Langed',
    'Dalskog',
    'Dalsjoefors',
    'Dalby',
    'Dalaroe',
    'Dala-Jarna',
    'Dala-Floda',
    'Charlottenberg',
    'Byxelkrok',
    'Burseryd',
    'Burgsvik',
    'Bunkeflostrand',
    'Bua',
    'Brunflo',
    'Brottby',
    'Broesarp',
    'Bromoella',
    'Bromma',
    'Brokind',
    'Broddetorp',
    'Broby',
    'Broaryd',
    'Bro',
    'Bredaryd',
    'Bredared',
    'Brastad',
    'Brandstorp',
    'Bramhult',
    'Bralanda',
    'Braekne-Hoby',
    'Bracke',
    'Braecke',
    'Braas',
    'Boxholm',
    'Bottnaryd',
    'Botkyrka',
    'Borrby',
    'Borlänge',
    'Borgvik',
    'Borgstena',
    'Borgholm',
    'Borgafjaell',
    'Borensberg',
    'Borås',
    'Bor',
    'Bollstabruk',
    'Bollnaes',
    'Bollebygd',
    'Bohus',
    'Bodafors',
    'Bockara',
    'Boalt',
    'Blomstermala',
    'Blikstorp',
    'Blentarp',
    'Bleket',
    'Blankaholm',
    'Bjuv',
    'Bjursas',
    'Bjurholm',
    'Bjuraker',
    'Bjoernlunda',
    'Bjoerneborg',
    'Bjorna',
    'Bjoerkvik',
    'Bjoerkoe',
    'Bjaesta',
    'Bjartra',
    'Bjaerred',
    'Bjaernum',
    'Billingsfors',
    'Billinge',
    'Billesholm',
    'Billeberga',
    'Billdal',
    'Bettna',
    'Bestorp',
    'Bergvik',
    'Bergsjoe',
    'Bergshamra',
    'Bergkvara',
    'Berghem',
    'Bergeforsen',
    'Bergby',
    'Berga',
    'Bengtsfors',
    'Beddinge Lage',
    'Bastutrask',
    'Bastad',
    'Barsebaeck',
    'Barkarby',
    'Bara',
    'Bankeryd',
    'Balsta',
    'Ballingsloev',
    'Baeckefors',
    'Backaryd',
    'Axvall',
    'Avesta',
    'Atvidaberg',
    'Atran',
    'Atorp',
    'Astorp',
    'AEsperoed',
    'AEspered',
    'Aspas',
    'Asmundtorp',
    'Asljunga',
    'Askim',
    'Askersund',
    'Asensbruk',
    'Asenhoga',
    'Asele',
    'Aseda',
    'Asbro',
    'Asarum',
    'Asarp',
    'Asarna',
    'Asa',
    'As',
    'Aryd',
    'Arvika',
    'Arvidsjaur',
    'Arsunda',
    'Arsta Havsbad',
    'Arsta',
    'Arnasvall',
    'Arloev',
    'AErla',
    'Arkelstorp',
    'Arjeplog',
    'Arjaeng',
    'Arbra',
    'Arboga',
    'AEppelbo',
    'Aplared',
    'Annerstad',
    'Anneberg',
    'Ankarsvik',
    'Ankarsrum',
    'Animskog',
    'Angered',
    'Ängelholm',
    'AEnge',
    'Ange',
    'Aneby',
    'Anderstorp',
    'Andersloev',
    'Amotfors',
    'Ammeberg',
    'Ambjoernarp',
    'Amal',
    'Alvsjo',
    'AElvkarleby',
    'Alvesta',
    'AElvdalen',
    'Älvängen',
    'Alta',
    'Alstermo',
    'Alsterbro',
    'Alsen',
    'Alno',
    'Almunge',
    'AElmhult',
    'Almhult',
    'Almestad',
    'Almeboda',
    'Allerum',
    'Alingsas',
    'AElghult',
    'Algaras',
    'Algafall',
    'Alfta',
    'Alem',
    'Aled',
    'Alby',
    'Alberga',
    'AElandsbro',
    'Alafors',
    'Akers Styckebruk',
    'Akersberga',
    'Akarp',
    'Åhus',
    'Agunnaryd',
    'Agnesberg',
    'Adak',
    'Aby',
    'Abbekas',
    'Backa',
    'Sund',
    'Ekeby',
    'Skanoer med Falsterbo',
    'Saltsjoe-Boo',
    'Julita',
    'Onsala',
    'Enskede-Arsta-Vantoer',
    'Bagarmossen',
    'Haesselby',
    'Bullaren',
    'Tived',
    'Bandhagen',
    'Åre',
    'Stora Sundby',
    'Adelsoe',
    'Taeby Kyrkby',
    'Kovland',
    'Hisings Kaerra',
    'Hisings Backa',
  ],
  'Svalbard and Jan Mayen': ['Sveagruva', 'Longyearbyen', 'Olonkinbyen'],
  Kazakhstan: [
    'Oral',
    'Mortyq',
    'Karagandy',
    'Karaganda',
    'Fort-Shevchenko',
    'Atyrau',
    'Aktobe',
    'Aktau',
    'Aksay',
    'Taraz',
    'Turkestan',
    'Tonkeris',
    'Temirtau',
    'Talghar',
    'Taldykorgan',
    'Smīrnovo',
    'Shymkent',
    'Shortandy',
    'Shchūchīnsk',
    'Shakhtinsk',
    'Semey',
    'Rudnyy',
    'Kyzylorda',
    'Kostanay',
    'Kaskelen',
    'Karatau',
    'Petropavl',
    'Pavlodar',
    'Ust-Kamenogorsk',
    'Osakarovka',
    'Sarykemer',
    'Līsakovsk',
    'Baikonur',
    'Ridder',
    'Lenger',
    'Kokshetau',
    'Kazakhstan',
    'Fedorovka',
    'Aqsū',
    'Ekibastuz',
    'Burunday',
    'Bayqongyr',
    'Balqash',
    'Arys',
    'Nur-Sultan',
    'Almaty',
    'Alatay',
    'Aktas',
    'Aksu-Ayuly',
    'Minkeser',
    'Stepnogorsk',
    'Rakhat',
    'Kokkaynar',
  ],
  Georgia: [
    'Zugdidi',
    'Zhinvali',
    'Zestaponi',
    'Vake',
    "Ts'khinvali",
    'Tbilisi',
    'Sukhumi',
    'Mtskheta',
    'Lentekhi',
    'Kutaisi',
    'Stepantsminda',
    'Gori',
    'Gogolesubani',
    'Gali',
    'Gagra',
    'Batumi',
  ],
  Ukraine: [
    'Lebedyn',
    'Druzhkovka',
    'Zhydachiv',
    'Ivano-Frankove',
    'Zvenigorodka',
    'Zuya',
    'Zolotonosha',
    'Zolochiv',
    'Znamianka',
    'Zmiyiv',
    'Zhytomyr',
    'Zhvyrka',
    'Zhvanets',
    'Zhovkva',
    'Zhmerynka',
    'Zhovti Vody',
    'Zhdanivka',
    'Zhashkiv',
    "Zin'kiv",
    'Zdolbuniv',
    'Zboriv',
    'Zbarazh',
    'Zarichovo',
    'Zaporizhia',
    'Zalukva',
    'Zalishchyky',
    'Zabolotiv',
    'Yerky',
    'Yenakiyeve',
    "Yemil'chyne",
    'Yelanets',
    'Yavoriv',
    'Yasinovataya',
    'Yarmolyntsi',
    'Yaremche',
    'Yalta',
    'Yahotyn',
    'Vyshhorod',
    'Vynohradiv',
    'Vylok',
    'Vylkove',
    'Vydranytsya',
    'Voznesensk',
    "Vorzel'",
    'Volovets',
    'Volosskoye',
    'Khoroshiv',
    'Volodarka',
    'Vodyane',
    'Volnovakha',
    "Vovchynets'",
    "Vovchans'k",
    'Volodymyr-Volynskyi',
    'Vyshneve',
    'Vinnytsia',
    'Vesele',
    'Verkhovyna',
    "Verkhn'odniprovs'k",
    'Veresy',
    'Verenchanka',
    'Velyki Mosty',
    'Vatutine',
    "Vasyl'kivka",
    'Vasylkiv',
    'Varva',
    'Vapnyarka',
    'Valky',
    'Uzyn',
    'Uzhhorod',
    'Utkivka',
    'Utkonosivka',
    'Usatove',
    'Nyzhni Petrivtsi',
    "Uman'",
    'Ulashkivtsi',
    'Ukrainsk',
    'Ukrayinka',
    'Ugledar',
    'Tyachiv',
    'Turka',
    "Turiys'k",
    'Turbiv',
    'Tulchyn',
    'Tsyurupinsk',
    "Tsuman'",
    "Truskavets'",
    "Trostyanets'",
    'Trokovychi',
    'Chystyakove',
    'Tomakivka',
    'Tokmak',
    'Tlumach',
    'Ternovka',
    'Ternopil',
    'Teresva',
    'Terebovlya',
    'Teofipol',
    'Talne',
    "Syeverodonets'k",
    'Svyatogorsk',
    'Svetlyy',
    "Svitlovods'k",
    'Svessa',
    "Sverdlovs'k",
    'Svatove',
    'Svalyava',
    'Sutysky',
    'Sumy',
    'Sukhodil',
    'Sudak',
    'Stryi',
    "Storozhynets'",
    'Stebnyk',
    'Starokostiantyniv',
    "Starobil's'k",
    'Starychi',
    'Stanislav',
    'Sosnivka',
    'Sosnytsya',
    'Solotvyno',
    'Solonka',
    'Solonytsivka',
    'Solone',
    "Sokal'",
    'Sofiyevka',
    'Sniatyn',
    'Snihurivka',
    'Smila',
    'Sloviansk',
    'Slobidka',
    'Slavuta',
    'Slatyne',
    'Skvyra',
    'Skole',
    'Skalat',
    'Skadowsk',
    "Synel'nykove",
    'Simferopol',
    'Shpola',
    'Shostka',
    'Shevchenkove',
    'Shepetivka',
    'Sharovechka',
    'Sharhorod',
    'Shalanky',
    'Shabo',
    'Seversk',
    'Sevastopol',
    'Selyatyn',
    'Selidovo',
    "Savran'",
    'Sarny',
    'Sambir',
    'Saki',
    'Rykhtychi',
    'Rubizhne',
    'Rozhyshche',
    'Rozdilna',
    'Rivne',
    'Rovenki',
    'Romny',
    'Rokytne',
    'Rohatyn',
    "Rodyns'ke",
    'Reni',
    'Ratne',
    'Rakhiv',
    'Radio',
    "Radens'k",
    'Pryluky',
    "Prymors'k",
    'Posad-Pokrovs’ke',
    'Popilnia',
    'Popasnaya',
    'Pomichna',
    'Polyana',
    'Poltava',
    'Polohy',
    "Pokrovs'ke",
    'Pohrebyshche',
    'Pidvolochysk',
    'Pidhirtsi',
    'Zaporozhe',
    'Pyryatyn',
    'Pesochin',
    'Piskivka',
    'Pershotravneve',
    "Pervomays'kyy",
    "Pervomays'k",
    'Pershotravensk',
    "Pereval'sk",
    'Pereshchepino',
    'Perechyn',
    'Pavlivka',
    'Pavlohrad',
    'Ovruch',
    'Ovidiopol',
    'Otyniya',
    'Ostroh',
    'Ostër',
    'Orativ',
    'Oleksandriya',
    'Odesa',
    'Ochakiv',
    'Obukhiv',
    'Obariv',
    "Novovolyns'k",
    'Novoukrayinka',
    'Novopskov',
    'Novopetrivka',
    'Novomoskovsk',
    'Novokrasnivka',
    'Novodnistrovs’k',
    'Nova Kakhovka',
    "Novaya Basan'",
    'Nizhyn',
    'Nyzhni Sirohozy',
    'Nikopol',
    'Netishyn',
    'Neresnytsya',
    'Nedryhayliv',
    'Nebyliv',
    'Nadvirna',
    'Mykolaiv',
    'Mykolayiv',
    'Mukacheve',
    'Motovylivka',
    "Mostys'ka",
    'Monastyryshche',
    'Molnitsa',
    'Mohyliv-Podilskyi',
    'Mizoch',
    'Myrhorod',
    'Mezhova',
    'Merefa',
    'Mena',
    'Melitopol',
    'Matviyivka',
    'Mariupol',
    "Marhanets'",
    'Mankivka',
    'Manevychi',
    'Malyn',
    'Mala Vyska',
    'Mala Danylivka',
    'Mala Bilozerka',
    'Malashivtsi',
    'Makiyivka',
    'Makariv',
    'Lysyanka',
    'Lviv',
    'Luzhany',
    'Lutugino',
    'Lutsk',
    'Lunka',
    'Lukovtsy',
    'Luka',
    'Luhansk',
    'Lugansk',
    'Lubny',
    'Lozovaya',
    'Lozova',
    'Lopatyn',
    'Lokachi',
    'Lityn',
    'Lisichansk',
    'Lipovets',
    'Lyman',
    'Letychiv',
    'Ladyzhyn',
    'Kyiv',
    'Kvasyliv',
    'Kurakhovo',
    'Kupiansk',
    'Kulykiv',
    "Kryzhopol'skiy Rayon",
    "Kryzhopil'",
    'Kryvyi Rih',
    'Krolevets',
    'Kryvyy Rih',
    'Kreminna',
    'Kremenets',
    'Kremenchuk',
    'Krasnyy Luch',
    'Krasne',
    "Krasnoperekops'k",
    'Krasnokutsk',
    'Krasnohrad',
    'Sorokyne',
    'Pokrovsk',
    'Krasyliv',
    'Kramatorsk',
    'Kozyn',
    'Kovel',
    "Podil's'k",
    "Kotel'va",
    "Kostopil'",
    'Kosiv',
    'Koshmaky',
    'Koryukivka',
    "Korsun'-Shevchenkivs'kyy",
    'Korostyshiv',
    'Korosten',
    'Korop',
    'Korets',
    'Kordelivka',
    'Kopychyntsi',
    'Kopani',
    'Kostyantynivka',
    'Kostiantynivka',
    'Konstantinovka',
    'Konotop',
    'Kolomyia',
    'Kolodenka',
    'Kobelyaky',
    'Klevan',
    'Kivertsi',
    'Kitsman',
    'Kropyvnytskyi',
    'Kiliya',
    'Shevchenka',
    'Khust',
    'Khrystynivka',
    'Khotyn',
    'Khorol',
    'Khodoriv',
    'Khmelnytskyi',
    'Khmilnyk',
    'Kherson',
    'Khartsyzsk',
    'Kharkiv',
    'Kerch',
    "Kel'mentsi",
    'Kozyatyn',
    'Karlivka',
    'Soledar',
    'Kaniv',
    'Komyshany',
    'Kamianets-Podilskyi',
    'Kamenskoye',
    'Kamenskaya Sloboda',
    "Kam'yanka",
    'Kamianka',
    'Kalush',
    'Kalinovka',
    'Kalynivka',
    'Kakhovka',
    'Kaharlyk',
    'Izyum',
    'Izmail',
    'Ivanopil',
    'Ivano-Frankivsk',
    'Ivanikovka',
    'Irshava',
    'Irpin',
    "Ilovays'k",
    "Chornomors'k",
    'Ichnya',
    'Horodok',
    'Horlivka',
    'Hlukhiv',
    'Husiatyn',
    'Grigorovka',
    'Hrebinky',
    'Hrebinka',
    'Hostomel',
    'Horokhiv',
    'Horodnya',
    'Horodkivka',
    'Horodenka',
    'Hirnyk',
    'Goncharov',
    "Holovanivs'k",
    "Hola Prystan'",
    'Hnizdychiv',
    'Hlobyne',
    'Hlevakha',
    'Genichesk',
    'Haisyn',
    'Halych',
    'Hadyach',
    'Fontanka',
    'Feodosiya',
    'Fastiv',
    'Eskhar',
    'Energodar',
    'Dzhankoy',
    "Dzerzhyns'k",
    'Dymer',
    'Dvorichna',
    'Dunaivtsi',
    'Dudchany',
    'Dubrovytsya',
    'Dubrynychi',
    'Dubno',
    'Dublyany',
    'Drohobych',
    'Drabiv',
    'Dovhe',
    'Donetsk',
    'Dolyna',
    "Dolyns'ka",
    'Dolina',
    'Dokuchayevsk',
    'Bene',
    'Dobropillya',
    'Dnipro',
    'Kamianske',
    'Myrnohrad',
    'Desna',
    'Derhachi',
    'Delyatyn',
    'Chudniv',
    'Chortkiv',
    'Chop',
    "Chkalovs'ke",
    'Chistovodovka',
    'Chynadiyovo',
    'Chykalivka',
    'Chyhyryn',
    "Chervonyy Donets'",
    'Chervonohrad',
    'Radyvyliv',
    'Chornobay',
    'Chernivtsi',
    'Chernihiv',
    'Cherkasy',
    'Chasov Yar',
    'Chaplynka',
    "Bus'k",
    'Bushtyno',
    "Buryn'",
    'Burshtyn',
    'Budy',
    'Zamozhne',
    'Buchach',
    'Bucha',
    'Bryanka',
    'Brovary',
    'Kryve',
    'Brody',
    'Britovka',
    'Brazolove',
    'Boyarka',
    'Borzna',
    'Borivka',
    'Borodianka',
    'Borki',
    'Boryspil',
    'Boryslav',
    'Bolekhiv',
    'Bohuslav',
    'Bohorodchany',
    "Bobrynets'",
    'Blahodatne',
    'Bilhorod-Dnistrovs’kyy',
    'Bila Tserkva',
    'Bila',
    'Bezlyudivka',
    'Bershad',
    'Beryslav',
    'Berezne',
    'Berezhany',
    "Berezan'",
    'Berehove',
    'Berehomet',
    'Berdychiv',
    "Berdyans'k",
    'Belozërskoye',
    "Bilovods'k",
    'Bilopillya',
    'Bilokurakyne',
    'Bilohirsk',
    'Belogorodka',
    'Belitskoye',
    'Bila Hora',
    'Belaya',
    'Bayrak',
    'Baturyn',
    'Bashtanka',
    'Baryshivka',
    'Barvinkove',
    'Barsuki',
    'Baranivka',
    'Bar',
    'Banilov',
    'Balta',
    'Balky',
    'Balakliya',
    'Bakhchisaray',
    'Artsyz',
    "Artemivs'k",
    'Arbuzynka',
    'Apostolove',
    'Antratsit',
    'Hannivka',
    'Ananyiv',
    'Alushta',
    'Alupka',
    'Aleksandrovka',
    'Oleksandrivka',
    'Aleksandriya',
    "Alchevs'k",
    'Okhtyrka',
    "Petrivs'ke",
    "Korsun'",
    'Samara',
    'Marchenky',
    'Avilovka',
    'Novaya',
    'Vinnitsa',
    'Dzenzelivka',
    'Teplodar',
    'Horishni Plavni',
    'Slavutych',
    'Yuzhnoukrainsk',
    'Bir',
    "Udryts'k",
    'Varash',
    'Rovantsi',
    'Brodiv',
    'Kryukivshchyna',
    'Prolisky',
    'Anastasivka',
    'Kharkove',
    'Nizhenka',
    'Pryovrazhne',
    'Chervona Dolyna',
    'Cherevani',
    "L'viv",
    'Skorodystyk',
    'Lapaivka',
    'Khrabuzna',
  ],
  'Republic of Moldova': [
    'Zaicani',
    'Edineţ',
    'Vulcanesti',
    'Vorniceni',
    'Valcinet',
    'Viisoara',
    'Andrusul de Sus',
    'Vadul lui Voda',
    'Ungheni',
    'Todiresti',
    'Tiraspol',
    'Telenesti',
    'Tarasova',
    'Singera',
    'Stefan Voda',
    'Suruceni',
    'Straseni',
    'Stauceni',
    'Fundurii Vechi',
    'Boghenii Vechi',
    'Soroca',
    'Soldanesti',
    'Riscani',
    'Rîbniţa',
    'Rezina',
    'Pepeni',
    'Pelinia',
    'Orhei',
    'Oxentea',
    'Ocnita',
    'Anenii Noi',
    'Nisporeni',
    'Nimereuca',
    'Mindresti',
    'Miresti',
    'Mingir',
    'Corlateni',
    'Singerei',
    'Chiscareni',
    'Larga',
    'Cricova',
    'Cojusna',
    'Hincesti',
    'Cosnita',
    'Corjova',
    'Corjeuti',
    'Colonita',
    'Cocieri',
    'Chirianca',
    'Cazanesti',
    'Cazaclia',
    'Căușeni',
    'Catranic',
    'Ialoveni',
    'Glodeni',
    'Ghidighici',
    'Floresti',
    'Fălești',
    'Giurgiulesti',
    'Durlesti',
    'Drochia',
    'Drepcauti',
    'Dorotcaia',
    'Donduseni',
    'Dnestrovsc',
    'Dancu',
    'Criuleni',
    'Comrat',
    'Cimislia',
    'Chisinau',
    'Cantemir',
    'Calarasi',
    'Cahul',
    'Bulboaca',
    'Budesti',
    'Briceni',
    'Bobulesti',
    'Tighina',
    'Basarabeasca',
    'Bardar',
    'Bălţi',
    'Bacioi',
    'Andreevca',
    'Vatra',
    'Ciorescu',
    'Bic',
    'Buneti',
    'Magdacesti',
    'Flutura',
  ],
  Belarus: [
    'Zhodzina',
    'Zhlobin',
    'Zaslawye',
    'Vitebsk',
    'Vilyeyka',
    'Vyetka',
    'Vawkavysk',
    'Uzda',
    'Ushachy',
    'Svislach',
    'Svyetlahorsk',
    'Stolin',
    "Smarhon'",
    'Smalyavichy',
    'Smilavichy',
    'Slutsk',
    'Slonim',
    'Shumilina',
    'Shklow',
    'Shchuchyn',
    'Syanno',
    'Salihorsk',
    'Rudensk',
    'Rahachow',
    'Rechytsa',
    'Ratomka',
    'Polatsk',
    'Pogranichnyy',
    'Pinsk',
    'Pastavy',
    'Astravyets',
    'Osipovichi',
    'Asipovichy',
    'Orsha',
    "Novolukoml'",
    'Navapolatsk',
    'Navahrudak',
    'Minsk',
    'Mazyr',
    'Masty',
    'Malaryta',
    'Maladzyechna',
    'Mogilev',
    'Machulishchy',
    "Lyepyel'",
    'Lunin',
    'Lahoysk',
    'Lida',
    'Kossovo',
    'Kobryn',
    'Klichaw',
    'Klyetsk',
    'Kalinkavichy',
    'Hrodna',
    'Horki',
    "Homyel'",
    'Gomel',
    'Hlybokaye',
    'Gorbovichi',
    'Golochin',
    'Hlusk',
    'Gezgaly',
    'Dzyarzhynsk',
    'Dubrowna',
    'Dubrovka',
    'Drahichyn',
    'Dokshytsy',
    'Dobrinevo',
    "Chervyen'",
    'Cherykaw',
    'Brest',
    'Braslaw',
    'Barowka',
    'Byarozawka',
    'Berezovka',
    'Beloozersk',
    'Borisov',
    'Baranovichi',
    'Babruysk',
    'Obchak',
    'Fanipol',
    'Bogdyuki',
    'Kamenka',
    'Fakel',
    'Borovlyany',
    'Izobka',
    'Gorovatka',
    'Dubasnik',
  ],
  Finland: [
    'Ytteresse',
    'Ypaejae',
    'Ylöjärvi',
    'Ylivieska',
    'Ylivalli',
    'Ylitornio',
    'Ylistaro',
    'Yli-Lesti',
    'Ylikiiminki',
    'Ylihaermae',
    'Ylaene',
    'Ylaemylly',
    'Ylämaa',
    'Vuokatti',
    'Vörå',
    'Vojakkala',
    'Voiluoto',
    'Voikkaa',
    'Virrat',
    'Virolahden Kirkonkylä',
    'Virenoja',
    'Vinkkilae',
    'Vimpeli',
    'Vilppula',
    'Villähde',
    'Viljakkala',
    'Viitasaari',
    'Viinijärvi',
    'Vihti',
    'Vihtavuori',
    'Vihanti',
    'Vieremä',
    'Veteli',
    'Vesivehmaa',
    'Vesilahti',
    'Vesanka',
    'Veneskoski',
    'Venesjaervi',
    'Velaatta',
    'Veikkola',
    'Vestlax',
    'Vastila',
    'Vaesterhankmo',
    'Varpaisjärvi',
    'Varkaus',
    'Vantaa',
    'Vanhakylae',
    'Vampula',
    'Vammala',
    'Valkom',
    'Valkeala',
    'Valkeakoski',
    'Vahto',
    'Vähäkyrö',
    'Vaasa',
    'Vaaranniva',
    'Vaala',
    'Vaeaeksy',
    'Vaajakoski',
    'Uusikylae',
    'Uusikaupunki',
    'Uurainen',
    'Uudenmaan Laani',
    'Utsjoki',
    'Utajärvi',
    'Urjala',
    'Urajärvi',
    'Upinniemi',
    'Ummeljoki',
    'Ulvila',
    'Tyrnaevae',
    'Tynkae',
    'Tuusula',
    'Tuuri',
    'Tuulos',
    'Turtola',
    'Turku',
    'Turenki',
    'Tupos',
    'Tuovilanlahti',
    'Tuomikylae',
    'Toeysae',
    'Tottijarvi',
    'Tornio',
    'Tolkis',
    'Toivala',
    'Toivakka',
    'Akaa',
    'Toholampi',
    'Tjöck',
    'Tikkala',
    'Tikkakoski',
    'Teuva',
    'Tervola',
    'Tervakoski',
    'Tervajoki',
    'Terttilae',
    'Terjaerv',
    'Teraelahti',
    'Tennilae',
    'Tenala',
    'Teijo',
    'Tavastila',
    'Tarvasjoki',
    'Tapionkylae',
    'Tampere',
    'Tammijärvi',
    'Tammela',
    'Taekter',
    'Taklax',
    'Taivassalo',
    'Taivalkoski',
    'Taipalsaari',
    'Taavetti',
    'Sysmä',
    'Suonenjoki',
    'Suomussalmi',
    'Suolahti',
    'Sundsberg',
    'Sundby',
    'Sulkava',
    'Suinula',
    'Sotkamo',
    'Soeoermarkku',
    'Sonkajärvi',
    'Somero',
    'Somerniemi',
    'Solkei',
    'Solv',
    'Soini',
    'Soedra Vallgrund',
    'Soederkulla',
    'Sodankylä',
    'Skuru',
    'Skogby',
    'Skinnarby',
    'Siuntio',
    'Siuro',
    'Sippola',
    'Simpele',
    'Broby',
    'Siivikkala',
    'Siilinjärvi',
    'Siikajoki',
    'Siikainen',
    'Sievi',
    'Sideby',
    'Sipoo',
    'Selki',
    'Seinäjoki',
    'Säynätsalo',
    'Savukoski',
    'Savonlinna',
    'Savitaipale',
    'Savero',
    'Sauvo',
    'Sasi',
    'Sarfvik',
    'Sarkola',
    'Särkisalo',
    'Särkilahti',
    'Sanginkylae',
    'Salo',
    'Salla',
    'Saekylae',
    'Sairaalanmaeki',
    'Sahalahti',
    'Sahakylae',
    'Saarijaervi',
    'Saari',
    'Saarenkylae',
    'Saaksmaki',
    'Sääksjärvi',
    'Ryttylae',
    'Rytinki',
    'Ruutana',
    'Ruukki',
    'Rutava',
    'Rusko',
    'Ruovesi',
    'Ruorasmäki',
    'Ruhtinaansalmi',
    'Roeykkae',
    'Rovaniemi',
    'Roismala',
    'Ristijärvi',
    'Ristiina',
    'Rimal',
    'Riihimäki',
    'Riihikoski',
    'Revonlahti',
    'Renko',
    'Reitkalli',
    'Reisjärvi',
    'Reila',
    'Raeyrinki',
    'Rautavaara',
    'Raussila',
    'Rauma',
    'Rauha',
    'Rasivaara',
    'Raseborg',
    'Ranua',
    'Rantsila',
    'Rajamäki',
    'Raisio',
    'Raikku',
    'Rahula',
    'Raatala',
    'Rääkkylä',
    'Raahe',
    'Pyhtää',
    'Pyhäsalmi',
    'Pyhaejoki',
    'Pyhäjärvi',
    'Puumala',
    'Purmo',
    'Puolanka',
    'Punkalaidun',
    'Punkaharju',
    'Pulp',
    'Pulkkila',
    'Pukkila',
    'Pudasjärvi',
    'Prunkila',
    'Poeytyae',
    'Porras',
    'Pornainen',
    'Pori',
    'Pojanluoma',
    'Etelae-Poitsila',
    'Pirkkala',
    'Pinsioe',
    'Piikkiö',
    'Pihtipudas',
    'Pihlajakoski',
    'Pielavesi',
    'Pieksämäki',
    'Piehinki',
    'Petalax',
    'Petäjävesi',
    'Pertunmaa',
    'Perttula',
    'Pertteli',
    'Pernioe',
    'Peraeseinaejoki',
    'Pensala',
    'Pattijoki',
    'Parkano',
    'Parikkala',
    'Pargas',
    'Pankakoski',
    'Panelia',
    'Paltaniemi',
    'Palokka',
    'Palojoki',
    'Paelkaene',
    'Paippinen',
    'Paimio',
    'Paihola',
    'Padasjoki',
    'Paattinen',
    'Paalasmaa',
    'OEversundom',
    'OEvermark',
    'OEvermalax',
    'Outokumpu',
    'Oulunsalo',
    'Oulu',
    'Oulainen',
    'Otava',
    'Otalampi',
    'Orivesi',
    'Oripää',
    'Oriniemi',
    'Orimattila',
    'Oravisalo',
    'Oravasaari',
    'Oravais',
    'Ollila',
    'Olhava',
    'Oja',
    'Oitti',
    'Nykarleby',
    'Nurmo',
    'Nurmijärvi',
    'Nurmes',
    'Nuppulinna',
    'Nuoramoinen',
    'Numminen',
    'Nummi-Pusula',
    'Nummenkylae',
    'Nummela',
    'Nukari',
    'Noeykkioe',
    'Nousiainen',
    'Norinkylae',
    'Nopankylae',
    'Noormarkku',
    'Nokia',
    'Nivala',
    'Nilsiä',
    'Niinikoski',
    'Niemenkylae',
    'Nerkoo',
    'Alaveteli',
    'Lappfors',
    'Nastola',
    'Närpes',
    'Nakkila',
    'Naantali',
    'Myrskylä',
    'Mynaemaeki',
    'Myllykoski',
    'Muurla',
    'Muurasjärvi',
    'Muurame',
    'Mutala',
    'Svarta',
    'Muonio',
    'Munsala',
    'Muhos',
    'Mouhijaervi',
    'Möttönen',
    'Monninkylä',
    'Molpe',
    'Mjoesund',
    'Mikkeli',
    'Metsaekylae',
    'Metsaekansa',
    'Merikarvia',
    'Merikaarto',
    'Maxmo',
    'Matkaniva',
    'Mätäsvaara',
    'Masku',
    'Masala',
    'Marttila',
    'Marjovaara',
    'Marinkainen',
    'Maentyharju',
    'Maenttae',
    'Mäntsälä',
    'Maalahti',
    'Lyly',
    'Luvia',
    'Luumäki',
    'Luopioinen',
    'Luopa',
    'Luode',
    'Loviisa',
    'Loppi',
    'Lokalahti',
    'Loimaa',
    'Lohtaja',
    'Lohja',
    'Liperi',
    'Linnankylae',
    'Liminka',
    'Liljendal',
    'Lihavanpaeae',
    'Lievestuore',
    'Lieto',
    'Lieksa',
    'Lepsaemae',
    'Leppävirta',
    'Leppävesi',
    'Leppaelaenkylae',
    'Lemu',
    'Lempyy',
    'Lempäälä',
    'Lemi',
    'Lehtola',
    'Lehmo',
    'Laeyliaeinen',
    'Lavia',
    'Lautiosaari',
    'Launonen',
    'Laukkoski',
    'Laukaa',
    'Lauhala',
    'Latovainio',
    'Larsmo',
    'Lapua',
    'Lappohja',
    'Lappfjärd',
    'Lappeenranta',
    'Lappajaervi',
    'Lapinneva',
    'Lapinlahti',
    'Lapinjärvi',
    'Lammi',
    'Laitila',
    'Laihia',
    'Lahti',
    'Kyyjärvi',
    'Kyroeskoski',
    'Kyroe',
    'Kirkkonummi',
    'Kylmaelae',
    'Kvevlax',
    'Kuusisto',
    'Kuusankoski',
    'Kuusamo',
    'Kustavi',
    'Kurikka',
    'Kurejoki',
    'Kuortane',
    'Kuorevaara',
    'Kuopio',
    'Kuohu',
    'Kuohijoki',
    'Kuni',
    'Kullo',
    'Kulho',
    'Kukko',
    'Kukkila',
    'Kuivaniemi',
    'Kuikka',
    'Kuhmoinen',
    'Kuhmo',
    'Kronoby',
    'Kristinestad',
    'Köyliö',
    'Kovelahti',
    'Kouvola',
    'Koura',
    'Kotka',
    'Koski',
    'Koski Tl',
    'Koskenkorva',
    'Kortesjaervi',
    'Korsnaes',
    'Korsholm',
    'Korppoo',
    'Korkeakoski',
    'Koria',
    'Korholanmaeki',
    'Kopsa',
    'Kontioniemi',
    'Kontiolahti',
    'Konnuslahti',
    'Konnevesi',
    'Komi',
    'Kolla',
    'Kolho',
    'Kokkola',
    'Kokemäki',
    'Kojonkulma',
    'Koikkala',
    'Klaukkala',
    'Klamila',
    'Kiuruvesi',
    'Kiukainen',
    'Kittilä',
    'Kitinoja',
    'Kitee',
    'Kirjala',
    'Kinnula',
    'Kimola',
    'Kimo',
    'Kimito',
    'Kiiminki',
    'Kiihtelysvaara',
    'Kihnioe',
    'Keuruu',
    'Kesälahti',
    'Kerimäki',
    'Kerava',
    'Kempele',
    'Kemijärvi',
    'Keminmaa',
    'Kemi',
    'Kellokoski',
    'Kello',
    'Kauttua',
    'Kaustinen',
    'Kausala',
    'Kauniainen',
    'Kauklahti',
    'Kaukalampi',
    'Kauhava',
    'Kauhajoki',
    'Kaskinen',
    'Karvia',
    'Karstula',
    'Kärsämäki',
    'Karperoe',
    'Kärkölä',
    'Karkkila',
    'Kaerjenniemi',
    'Karjalohja',
    'Karis',
    'Karinainen',
    'Karijoki',
    'Karhunpaeae',
    'Kanunki',
    'Kanteenmaa',
    'Kannus',
    'Kannonkoski',
    'Kankaanpaeae',
    'Kangasniemi',
    'Kangasala',
    'Kaemmenniemi',
    'Kälviä',
    'Kållby',
    'Kalanti',
    'Kalajoki',
    'Kajaani',
    'Kaasmarkku',
    'Kaarina',
    'Jyväskylä',
    'Jyskae',
    'Juva',
    'Juupajoki',
    'Juuka',
    'Jurva',
    'Jurmu',
    'Juorkuna',
    'Juonikylae',
    'Joutseno',
    'Joutsa',
    'Jorvas',
    'Joroinen',
    'Jokipii',
    'Jokioinen',
    'Jokihaara',
    'Jokela',
    'Joensuu',
    'Kesti',
    'Järvenpää',
    'Janakkala',
    'Jämsänkoski',
    'Jämsä',
    'Jaemijaervi',
    'Jalasjaervi',
    'Jakokoski',
    'Jakobstad',
    'Jääli',
    'Ivalo',
    'Itaekylae',
    'Istunmäki',
    'Isokyrö',
    'Isojoki',
    'Isnäs',
    'Inkeroinen',
    'Ingå',
    'Impioe',
    'Imatra',
    'Ilveskorpi',
    'Ilvesjoki',
    'Ilomantsi',
    'Ilmajoki',
    'Ikkelaejaervi',
    'Ikaalinen',
    'Iittala',
    'Iisalmi',
    'Ii',
    'Huuvari',
    'Husula',
    'Humppila',
    'Humppi',
    'Huittinen',
    'Huhmari',
    'Houtskär',
    'Honkakoski',
    'Honkajoki',
    'Hollola',
    'Hirvihaara',
    'Hinnerjoki',
    'Hinthaara',
    'Himanka',
    'Hiltulanlahti',
    'Hikiä',
    'Herttuala',
    'Herrala',
    'Helsinki',
    'Helsingby',
    'Hellanmaa',
    'Heinola',
    'Heinlahti',
    'Heinävesi',
    'Heinävaara',
    'Heinaekylae',
    'Hausjaervi',
    'Haukivuori',
    'Haukipudas',
    'Hauho',
    'Hattula',
    'Haermae',
    'Haerkmeri',
    'Haerkaepaeae',
    'Harjavalta',
    'Hanko',
    'Hammaslahti',
    'Hamari',
    'Hamina',
    'Hämeenlinna',
    'Hämeenkyrö',
    'Halsua',
    'Halli',
    'Halkivaha',
    'Halikko',
    'Hailuoto',
    'Haarajoki',
    'Haapavesi',
    'Haapamäki',
    'Haapajärvi',
    'Gammelby',
    'Forssa',
    'Forsby',
    'Foerby',
    'Fiskars',
    'Fagervik',
    'Evijaervi',
    'Eurajoki',
    'Eura',
    'Eugmo',
    'Esse',
    'Espoo',
    'Eno',
    'Elimäki',
    'Ekenäs',
    'Dragsvik',
    'Dragsfjaerd',
    'Degerby',
    'Dalsbruk',
    'Dagsmark',
    'Bromarv',
    'Box',
    'Bosund',
    'Porvoo',
    'Bjoerkoeby',
    'Bjoerkboda',
    'Billnäs',
    'Bergoe',
    'Bennäs',
    'Aura',
    'Askola',
    'Askainen',
    'Aminnefors',
    'Alavus',
    'Ala-Viirre',
    'Alavieska',
    'Alajärvi',
    'AEkaeslompolo',
    'Ähtäri',
    'Aavasaksa',
    'Äänekoski',
    'Evitskog',
    'Lapinkylae',
    'Piispanristi',
    'Littoinen',
    'Vanhalinna',
    'Huutjaervi',
    'Lahela',
    'Vaesterskog',
    'Kalkstrand',
    'Ilmarinen',
    'Vuorentausta',
    'Kuvansi',
    'Puuppola',
    'Hyvinkaeae',
    'Lampikangas',
    'Julkujaervi',
    'Hirsilae',
    'Nakertaja',
    'Sastamala',
    'Jarhoinen',
    'Ojakkala',
    'Vuorela',
  ],
  Åland: [
    'Sund',
    'Saltvik',
    'Lemland',
    'Kumlinge',
    'Föglö',
    'Tjudoe',
    'Mariehamn',
    'Jomala',
    'Hammarland',
    'Gottby',
    'Geta',
    'Eckerö',
  ],
  Romania: [
    'Zlatna',
    'Zimnicea',
    'Zimandcuz',
    'Zarnesti',
    'Zalău',
    'Zadareni',
    'Vulturesti',
    'Vulcan',
    'Voslabeni',
    'Voluntari',
    'Volovat',
    'Vlahita',
    'Vlaha',
    'Vladuceni',
    'Vladimirescu',
    'Vladesti',
    'Viziru',
    'Viseu de Sus',
    'Vartop',
    'Vanju-Mare',
    'Vanatori',
    'Viisoara',
    'Vidra',
    'Videle',
    'Vicovu de Sus',
    'Vetel',
    'Vedea',
    'Vatra Dornei',
    'Vaslui',
    'Varias',
    'Vama Veche',
    'Valu lui Traian',
    'Valenii de Munte',
    'Valea Ursului',
    'Valea Lupului',
    'Valea Lunga-Cricov',
    'Valea lui Mihai',
    'Valea Dacilor',
    'Valea Calugareasca',
    'Valea Adanca',
    'Vadu Pasii',
    'Vadastra',
    'Utvin',
    'Urziceni',
    'Urlati',
    'Uricani',
    'Unirea',
    'Ungheni',
    'Tuzla',
    'Turnu Ruieni',
    'Turnu Magurele',
    'Turda',
    'Turburea',
    'Tunari',
    'Tulcea',
    'Troianul',
    'Trestieni',
    'Traian',
    'Totoi',
    'Topoloveni',
    'Toplita',
    'Tomesti',
    'Titu',
    'Târnăveni',
    'Targu Trotus',
    'Targu Secuiesc',
    'Targu Ocna',
    'Targu Neamt',
    'Târgu Mureş',
    'Targu Lapus',
    'Târgu Jiu',
    'Targu Gangulesti',
    'Targu Frumos',
    'Targoviste',
    'Târgovişte',
    'Timișoara',
    'Tileagd',
    'Tiganesti',
    'Tifesti',
    'Tetcani',
    'Teregova',
    'Telesti',
    'Teius',
    'Teisani',
    'Teghes',
    'Tecuci',
    'Techirghiol',
    'Tatarusi',
    'Tatarani',
    'Tasnad',
    'Tartasesti',
    'Tarna Mare',
    'Tarian',
    'Tarcaia',
    'Tandarei',
    'Tamaseu',
    'Tamadau Mare',
    'Talmaciu',
    'Suveica',
    'Suseni',
    'Suraia',
    'Suplacu de Barcau',
    'Suncuius',
    'Sulina',
    'Suciu de Sus',
    'Suceava',
    'Strejnicu',
    'Stelnica',
    'Stefanestii de Sus',
    'Stefanestii de Jos',
    'Stefanesti',
    'Stefan cel Mare',
    'Stanisesti',
    'Spataresti',
    'Spantov',
    'Sovata',
    'Somcuta Mare',
    'Soldanu',
    'Soimus',
    'Sofronea',
    'Socond',
    'Socodor',
    'Soceni',
    'Snagov',
    'Smig',
    'Smeeni',
    'Slobozia Moara',
    'Slatina',
    'Slanic',
    'Sita Buzaului',
    'Siret',
    'Santion',
    'Santandrei',
    'Santana de Mures',
    'Santana',
    'Sansimion',
    'Sannicolau Roman',
    'Sânnicolau Mare',
    'Sanmihaiu German',
    'Sinmartin',
    'Sanmartin',
    'Sanleani',
    'Sangeorz-Bai',
    'Sangeorgiu de Padure',
    'Sangeorgiu de Mures',
    'Sandominic',
    'Sancrai',
    'Sinaia',
    'Simleu Silvaniei',
    'Simian',
    'Simeria',
    'Sambotin',
    'Silistea Snagovului',
    'Sighetu Marmaţiei',
    'Sieu-Odorhei',
    'Sic',
    'Sibiu',
    'Sfantu Ilie',
    'Sfantu Gheorghe',
    'Serdanu',
    'Serboeni',
    'Sendreni',
    'Selimbar',
    'Sederhat',
    'Sebes',
    'Scheiu de Sus',
    'Scarisoara',
    'Saveni',
    'Savastreni',
    'Satu Nou',
    'Satu Mare',
    'Satchinez',
    'Sasar',
    'Sarmasu',
    'Sant',
    'Salonta',
    'Saldabagiu de Munte',
    'Salcioara',
    'Salcea',
    'Salacea',
    'Sacele',
    'Sacalaz',
    'Sacadat',
    'Sabareni',
    'Sabaoani',
    'Rusu de Sus',
    'Rupea',
    'Ruginoasa',
    'Rucar',
    'Pecica',
    'Rovinari',
    'Ratesti',
    'Rosu',
    'Rosioru',
    'Rosiorii de Vede',
    'Rosia Montana',
    'Romos',
    'Roman',
    'Rogoz',
    'Rodna',
    'Roata de Jos',
    'Râşnov',
    'Valcea',
    'Ramnicu Sarat',
    'Reteag',
    'Reşiţa',
    'Remeti',
    'Remetea Mare',
    'Remetea',
    'Reghin',
    'Razvad',
    'Razoare',
    'Razboieni-Cetate',
    'Raucesti',
    'Rasinari',
    'Rapoltu Mare',
    'Radulesti',
    'Radauti',
    'Racu',
    'Racari',
    'Putna',
    'Pucioasa',
    'Prundu',
    'Pricaz',
    'Prejmer',
    'Predeal',
    'Preajba',
    'Potlogi',
    'Potlogeni',
    'Potcoava',
    'Postavari',
    'Posta Calnau',
    'Popesti-Leordeni',
    'Pomezeu',
    'Poienile de sub Munte',
    'Poienii de Jos',
    'Poiana Marului (Brasov)',
    'Poiana Lacului',
    'Poian',
    'Poeni',
    'Podu Turcului',
    'Podu Iloaiei',
    'Podu Dambovitei',
    'Podoleni',
    'Poarta Alba',
    'Plosca',
    'Plopeni',
    'Ploieşti',
    'Plaviceni',
    'Piteşti',
    'Piscu',
    'Pipirig',
    'Pipera',
    'Pancota',
    'Pildesti',
    'Pietraria',
    'Pielesti',
    'Piatra Neamţ',
    'Pianu de Jos',
    'Petroşani',
    'Petrila',
    'Petresti',
    'Petrachioaia',
    'Glavile',
    'Pesac',
    'Peris',
    'Perieti',
    'Peretu',
    'Peciu Nou',
    'Paunesti',
    'Paulis',
    'Paulestii Noi',
    'Paulesti',
    'Patroaia Vale',
    'Pasarea',
    'Partestii de Jos',
    'Pantelimon',
    'Panic',
    'Panet',
    'Panciu',
    'Panatau',
    'Paleu',
    'Palazu Mare',
    'Padureni',
    'Padina',
    'Ozun',
    'Ovidiu',
    'Otopeni',
    'Otelu Rosu',
    'Ostroveni',
    'Ostratu',
    'Orsova',
    'Oreavu',
    'Orbeasca de Sus',
    'Oravita',
    'Orastie',
    'Oradea',
    'Oprisor',
    'Oneaga',
    'Oltet',
    'Oltenita',
    'Oituz',
    'Ogrezeni',
    'Odorheiu Secuiesc',
    'Odoreu',
    'Odobesti',
    'Odobeasca',
    'Ocolisu Mare',
    'Ocnita',
    'Ocna Sugatag',
    'Ocna Sibiului',
    'Ocna Mures',
    'Obreja',
    'Nuci',
    'Novaci',
    'Niculitel',
    'Nicolesti',
    'Nermed',
    'Nehoiu',
    'Negru Voda',
    'Negresti-Oas',
    'Negresti',
    'Negoiesti',
    'Navodari',
    'Nasaud',
    'Nanov',
    'Nanesti',
    'Nana',
    'Nadrag',
    'Nădlac',
    'Murfatlar',
    'Mrenesti',
    'Mozaceni',
    'Movileni',
    'Movila',
    'Motru Sec',
    'Motru',
    'Motca',
    'Motaieni',
    'Mosoaia',
    'Mosnita Noua',
    'Morteni',
    'Moroeni',
    'Moreni',
    'Moldova Noua',
    'Moisei',
    'Moinesti',
    'Moimesti',
    'Mogosoaia',
    'Moeciu de Sus',
    'Mocira',
    'Moara Vlasiei',
    'Mizil',
    'Mitocasi',
    'Mirosi',
    'Calarasi',
    'Mircea Voda',
    'Militari',
    'Milisauti',
    'Mihalt',
    'Mihai Voda',
    'Mihail Kogălniceanu',
    'Miercurea-Ciuc',
    'Micula',
    'Mesteacan',
    'Mediaş',
    'Medgidia',
    'Matasari',
    'Mariuta',
    'Marginea',
    'Marghita',
    'Marga',
    'Marca',
    'Maracineni',
    'Mangalia',
    'Maneciu-Ungureni',
    'Manasia',
    'Mamaia',
    'Malu Mare',
    'Maieru',
    'Magurele',
    'Macin',
    'Lupeni',
    'Lumina',
    'Lugoj',
    'Ludus',
    'Livezeni',
    'Liteni',
    'Lisaura',
    'Lipova',
    'Lipanesti',
    'Libotin',
    'Leordeni',
    'Lehliu-Gara',
    'Lazuri',
    'Lancram',
    'Joseni',
    'Jimbolia',
    'Jilava',
    'Jijila',
    'Jigodin-Bai',
    'Jibou',
    'Ivesti',
    'Isaccea',
    'Iratosu',
    'Ipotesti',
    'Ip',
    'I. L. Caragiale',
    'Intorsura Buzaului',
    'Insuratei',
    'Ineu',
    'Ilisua',
    'Ilisesti',
    'Ieud',
    'Iermata',
    'Iasi',
    'Ianova',
    'Husi',
    'Hunedoara',
    'Huedin',
    'Hudum',
    'Hudesti',
    'Horia',
    'Horezu',
    'Homoraciu',
    'Harsova',
    'Harlau',
    'Hangulesti',
    'Herina',
    'Hemeius',
    'Hateg',
    'Harman',
    'Halmeu',
    'Halmeag',
    'Halaucesti',
    'Gurbediu',
    'Gurba',
    'Gura Vitioarei',
    'Gura Sutii',
    'Gura Raului',
    'Gura Humorului',
    'Gura Badilitei',
    'Gugesti',
    'Gostilele',
    'Gorgota',
    'Golesti',
    'Glogova',
    'Glina',
    'Glambocata',
    'Giurgiu',
    'Girov',
    'Giroc',
    'Garcina',
    'Garceiu',
    'Garbova de Jos',
    'Gilau',
    'Giarmata',
    'Ghiroda',
    'Ghindari',
    'Ghimpati',
    'Ghimbav',
    'Ghermanesti',
    'Gherla',
    'Gherghita',
    'Gheraseni',
    'Gheorgheni',
    'Oneşti',
    'Ghelinta',
    'Geoagiu-Bai',
    'Gemenele',
    'Ganesti',
    'Ganeasa',
    'Gamanesti',
    'Galesti',
    'Galda de Jos',
    'Galati',
    'Gageni',
    'Gaesti',
    'Fundu Moldovei',
    'Fundulea',
    'Fundu Hertii',
    'Fundeni',
    'Fulga de Jos',
    'Frumusani',
    'Frasin',
    'Forotic',
    'Focuri',
    'Focşani',
    'Floresti',
    'Flamanzi',
    'Fantanele',
    'Filipestii de Padure',
    'Filiasi',
    'Filia',
    'Fieni',
    'Fibis',
    'Fetesti',
    'Feliceni',
    'Feleacu',
    'Feldru',
    'Feldioara',
    'Faurei',
    'Fartatesti',
    'Faraoani',
    'Falticeni',
    'Faget',
    'Fagaras',
    'Facaeni',
    'Ernei',
    'Eforie Sud',
    'Eforie Nord',
    'Eforie',
    'Dumbravita',
    'Dumbraveni',
    'Dumbrava Rosie',
    'Dumbrava',
    'Dulcesti',
    'Dudu',
    'Dudestii Vechi',
    'Drobeta-Turnu Severin',
    'Dridu',
    'Drencova',
    'Drasov',
    'Drajna de Sus',
    'Drajna de Jos',
    'Dragomiresti-Deal',
    'Dragomiresti',
    'Dragodana',
    'Dragasani',
    'Draganeasca',
    'Dragalina',
    'Draxini',
    'Dorohoi',
    'Dor Marunt',
    'Domnesti-Sat',
    'Domnesti',
    'Domanesti',
    'Doicesti',
    'Dofteana',
    'Stei',
    'Dobroesti',
    'Dobreni',
    'Ditrau',
    'Darza',
    'Darvari',
    'Deva',
    'Deta',
    'Denta',
    'Delnita',
    'Deleni',
    'Dej',
    'Dascalu',
    'Darova',
    'Darmanesti',
    'Darasti-Ilfov',
    'Danes',
    'Daia Romana',
    'Dabuleni',
    'Cuzap',
    'Cuzdrioara',
    'Curtici',
    'Curtea de Argeş',
    'Cumpana',
    'Cugir',
    'Crucea',
    'Crovu',
    'Cristuru Secuiesc',
    'Cristesti',
    'Criseni',
    'Crevedia',
    'Crasna',
    'Craiova',
    'Cracaoani',
    'Covasna',
    'Cotu Vames',
    'Cotesti',
    'Costinesti',
    'Costesti',
    'Cosoba',
    'Cosmesti',
    'Cosereni',
    'Corund',
    'Cornetu',
    'Corbu',
    'Corbii Mari',
    'Corbeanca',
    'Corabia',
    'Copsa Mica',
    'Copalnic',
    'Copacel',
    'Constanța',
    'Conduratu',
    'Comlosu Mic',
    'Comarnic',
    'Comanesti',
    'Cogeasca',
    'Codlea',
    'Cocorastii Caplii',
    'Cociuba Mica',
    'Cochirleni',
    'Cluj-Napoca',
    'Clocotici',
    'Clinceni',
    'Cizer',
    'Ciurea',
    'Ciumeghiu',
    'Cisnadioara',
    'Cisnadie',
    'Ciorogarla',
    'Cioranii de Jos',
    'Ciumarna',
    'Ciohorani',
    'Ciofliceni',
    'Ciocani',
    'Ciocanesti',
    'Ciobanu',
    'Campulung Moldovenesc',
    'Campulung Muscel',
    'Campineanca',
    'Campina',
    'Campia Turzii',
    'Campeni',
    'Ciceu',
    'Chitila',
    'Chitid',
    'Chisoda',
    'Chisineu-Cris',
    'Chinteni',
    'Chinari',
    'Chilisoaia',
    'Chiciora',
    'Chiajna',
    'Cheveresu Mare',
    'Chereusa',
    'Cheia',
    'Cernica',
    'Cernavodă',
    'Cergau Mare',
    'Ceptura de Jos',
    'Ceica',
    'Cehu Silvaniei',
    'Cazasu',
    'Cavnic',
    'Carei',
    'Carasova',
    'Caransebes',
    'Caracal',
    'Camar',
    'Calimanesti',
    'Calan',
    'Calafat',
    'Cacica',
    'Buzias',
    'Buzescu',
    'Buzau',
    'Busteni',
    'Bumbesti-Jiu',
    'Bulbucata',
    'Bulai',
    'Targu Bujor',
    'Buhusi',
    'Buftea',
    'Buesti',
    'Budureasa',
    'Budesti',
    'Bucharest',
    'Bucureasa',
    'Bucesti',
    'Bucecea',
    'Bucea',
    'Brancoveanu',
    'Brezoi',
    'Bretea Streiului',
    'Brebu Manastirei',
    'Brebeni',
    'Breaza',
    'Bratasanca',
    'Brasov',
    'Branesti',
    'Bran',
    'Braila',
    'Bragadiru',
    'Bradu',
    'Bradesti',
    'Brad',
    'Bozovici',
    'Bozanta Mare',
    'Botosani',
    'Borsec',
    'Borsa',
    'Borlesti',
    'Bolintin Vale',
    'Boldu',
    'Boldesti-Scaeni',
    'Boian',
    'Bogati',
    'Bod',
    'Bocsa',
    'Blejoi',
    'Blaj',
    'Bistriţa',
    'Bistra',
    'Bârlad',
    'Bilbor',
    'Biharia',
    'Bicaz',
    'Bethausen',
    'Berzovia',
    'Berveni',
    'Berinta',
    'Beresti',
    'Beregsau Mare',
    'Berceni',
    'Berca',
    'Beiuș',
    'Beclean',
    'Bechet',
    'Batos',
    'Baru',
    'Baraolt',
    'Balteni',
    'Bals',
    'Balotesti',
    'Balomiru de Camp',
    'Balcesti',
    'Balcauti',
    'Băile Tuşnad',
    'Bailesti',
    'Baile Govora',
    'Baiculesti',
    'Baicoi',
    'Baia Mare',
    'Baia de Fier',
    'Baia de Cris',
    'Baia de Aries',
    'Baia de Arama',
    'Bagesti',
    'Baciu',
    'Bacau',
    'Babeni-Oltetu',
    'Babeni',
    'Azuga',
    'Avrig',
    'Atea',
    'Astileu',
    'Aroneanu',
    'Aricestii-Rahtivani',
    'Ardud',
    'Arcus',
    'Arad',
    'Apostolache',
    'Apateu',
    'Apahida',
    'Aninoasa',
    'Anini',
    'Anina',
    'Andrasesti',
    'Alexandria',
    'Alesd',
    'Albesti-Paleologu',
    'Albesti',
    'Albeni',
    'Alba Iulia',
    'Albac',
    'Aiud',
    'Agnita',
    'Agigea',
    'Agaua',
    'Afumati',
    'Afrimesti',
    'Adunatii-Copaceni',
    'Adjudeni',
    'Adjud',
    'Adancata',
    'Adea',
    'Abrud',
    'Abramut',
    '1 Decembrie',
    'Pascani',
    'Mioveni',
    'Dragoslavele',
    'Sighisoara',
    'Slobozia',
    'Gheorghe Doja',
    'Sector 1',
    'Sector 2',
    'Sector 3',
    'Sector 4',
    'Sector 5',
    'Sector 6',
  ],
  Hungary: [
    'Zubogy',
    'Zsadany',
    'Ziliz',
    'Zemplenagard',
    'Zarank',
    'Zahony',
    'Zagyvarekas',
    'Zadorfalva',
    'Visonta',
    'Vilmany',
    'Veszto',
    'Verpelet',
    'Veresegyhaz',
    'Vegegyhaza',
    'Vecs',
    'Vatta',
    'Vasmegyer',
    'Vasarosnameny',
    'Varbo',
    'Vancsod',
    'Vamospercs',
    'Vallaj',
    'Vaja',
    'Vadna',
    'Ujtikos',
    'Ujszentmargita',
    'Ujszentivan',
    'Ujszasz',
    'Ujleta',
    'Ujkigyos',
    'Ujiraz',
    'Ujfeherto',
    'Tyukod',
    'Tuzser',
    'Turkeve',
    'Tunyogmatolcs',
    'Totkomlos',
    'Toszeg',
    'Torokszentmiklos',
    'Tornyospalca',
    'Tornyosnemeti',
    'Tomorkeny',
    'Tomajmonostora',
    'Tolcsva',
    'Tokaj',
    'Tiszavid',
    'Tiszavasvari',
    'Tiszavalk',
    'Tiszaujvaros',
    'Tiszatenyo',
    'Tiszatarjan',
    'Tiszatardos',
    'Tiszasziget',
    'Tiszaszentmarton',
    'Tiszaszentimre',
    'Tiszaszalka',
    'Tiszaroff',
    'Tiszapuspoki',
    'Tiszapalkonya',
    'Tiszaors',
    'Tiszanana',
    'Tiszanagyfalu',
    'Tiszamogyoros',
    'Tiszaluc',
    'Tiszalok',
    'Tiszakurt',
    'Tiszakeszi',
    'Tiszakecske',
    'Tiszajeno',
    'Tiszagyulahaza',
    'Tiszafured',
    'Tiszafoldvar',
    'Tiszaeszlar',
    'Tiszadob',
    'Tiszaderzs',
    'Tiszadada',
    'Tiszacsege',
    'Tiszabura',
    'Tiszabo',
    'Tiszabezded',
    'Tiszabercel',
    'Tiszabecs',
    'Tiborszallas',
    'Tibolddaroc',
    'Terem',
    'Tepe',
    'Tenk',
    'Telkibanya',
    'Telekgerendas',
    'Teglas',
    'Tarpa',
    'Tarnaszentmiklos',
    'Tarnaoers',
    'Tarnamera',
    'Tarnabod',
    'Tardona',
    'Tard',
    'Tarcal',
    'Tallya',
    'Taktaszada',
    'Taktakenez',
    'Taktaharkany',
    'Taktabaj',
    'Szuhogy',
    'Szuhakallo',
    'Szorgalmatos',
    'Szomolya',
    'Szolnok',
    'Szirmabesenyo',
    'Szilvasvarad',
    'Szikszo',
    'Szikancs',
    'Szihalom',
    'Szerep',
    'Szerencs',
    'Szentistvan',
    'Szentes',
    'Szendrolad',
    'Szendro',
    'Szekkutas',
    'Szegvar',
    'Szegi',
    'Szeghalom',
    'Szeged',
    'Szatymaz',
    'Szatmarcseke',
    'Szaszberek',
    'Szarvas',
    'Szamosszeg',
    'Szakoly',
    'Szajol',
    'Szabolcsveresmart',
    'Szabolcsbaka',
    'Szabadkigyos',
    'Sonkad',
    'Sirok',
    'Serenyfalva',
    'Sátoraljaújhely',
    'Sarud',
    'Sarretudvari',
    'Sarospatak',
    'Sarkadkeresztur',
    'Sarkad',
    'Sarazsadany',
    'Sarand',
    'Sandorfalva',
    'Sajovamos',
    'Sajoszoged',
    'Sajoszentpeter',
    'Sajopetri',
    'Sajooeroes',
    'Sajolad',
    'Sajokeresztur',
    'Sajokaza',
    'Sajoecseg',
    'Sajobabony',
    'Rudabanya',
    'Roszke',
    'Rohod',
    'Ricse',
    'Retkozberencs',
    'Repashuta',
    'Recsk',
    'Ramocsahaza',
    'Rakocziujfalu',
    'Rakoczifalva',
    'Rakamaz',
    'Ragaly',
    'Putnok',
    'Pusztafoldvar',
    'Pusztadobos',
    'Puspokladany',
    'Poroszlo',
    'Porcsalma',
    'Polgar',
    'Pocspetri',
    'Pocsaj',
    'Piricse',
    'Petnehaza',
    'Penyige',
    'Peneszlek',
    'Pely',
    'Patyod',
    'Patroha',
    'Paszab',
    'Parad',
    'Pap',
    'Palhaza',
    'Pacin',
    'Ozd',
    'Oszlar',
    'Ostoros',
    'Oroshaza',
    'Ormosbanya',
    'Ormenykut',
    'Ormenyes',
    'Opusztaszer',
    'Opalyi',
    'Onod',
    'Onga',
    'Olcsva',
    'Olaszliszka',
    'Okany',
    'Ofoldeak',
    'Ofeherto',
    'Ocsod',
    'Nyirvasvari',
    'Nyirtura',
    'Nyirtet',
    'Nyirtelek',
    'Nyirtass',
    'Nyirpazony',
    'Nyirmada',
    'Nyirlugos',
    'Nyirlovo',
    'Nyirkercs',
    'Nyirkarasz',
    'Nyirjako',
    'Nyirgyulaj',
    'Nyiregyhaza',
    'Nyircsaszari',
    'Nyirbogat',
    'Nyirbeltek',
    'Nyirbator',
    'Nyiradony',
    'Nyiracsad',
    'Nyirabrany',
    'Nyekladhaza',
    'Novajidrany',
    'Novaj',
    'Noszvaj',
    'Napkor',
    'Nagytalya',
    'Nagyszenas',
    'Nagyrozvagy',
    'Nagyrabe',
    'Nagymagocs',
    'Nagykallo',
    'Nagyivan',
    'Nagyhegyes',
    'Nagyhalasz',
    'Nagyfuged',
    'Nagydobos',
    'Nagybarca',
    'Nagyar',
    'Nadudvar',
    'Murony',
    'Mucsony',
    'Monostorpalyi',
    'Miskolc',
    'Mindszent',
    'Mikepercs',
    'Mezozombor',
    'Mezotur',
    'Mezotarkany',
    'Mezonyarad',
    'Mezoladany',
    'Mezokovesd',
    'Mezokovacshaza',
    'Mezokeresztes',
    'Mezohegyes',
    'Mezogyan',
    'Mezocsat',
    'Mezobereny',
    'Mesterszallas',
    'Merk',
    'Mera',
    'Mehkerek',
    'Megyaszo',
    'Medgyesegyhaza',
    'Medgyesbodzas',
    'Matraderecske',
    'Matraballa',
    'Mateszalka',
    'Martfu',
    'Martely',
    'Maroslele',
    'Markaz',
    'Malyi',
    'Mako',
    'Maklar',
    'Magyarhomorog',
    'Magyarbanhegyes',
    'Lokoshaza',
    'Levelek',
    'Letavertes',
    'Legyesbenye',
    'Laskod',
    'Kurityan',
    'Kunszentmarton',
    'Kunmadaras',
    'Kunhegyes',
    'Kuncsorba',
    'Kunagota',
    'Kubekhaza',
    'Kotelek',
    'Korostetetlen',
    'Korostarcsa',
    'Korosszegapati',
    'Korosnagyharsany',
    'Korosladany',
    'Korom',
    'Konyar',
    'Kondoros',
    'Kondo',
    'Kompolt',
    'Komoro',
    'Komlo',
    'Komadi',
    'Kolcse',
    'Kocsord',
    'Kiszombor',
    'Kisvarsany',
    'Kisvarda',
    'Kisujszallas',
    'Kistokaj',
    'Kispalad',
    'Kisleta',
    'Kiskore',
    'Kiskinizs',
    'Kishuta',
    'Kisgyor',
    'Kiralyhegyes',
    'Kevermes',
    'Ketegyhaza',
    'Kesznyeten',
    'Kerecsend',
    'Kenezlo',
    'Kenderes',
    'Kemecse',
    'Kekcse',
    'Kek',
    'Kazsmark',
    'Kazincbarcika',
    'Karos',
    'Kardoskut',
    'Karcsa',
    'Karcag',
    'Karacsond',
    'Kapolna',
    'Kantorjanosi',
    'Kamut',
    'Kal',
    'Kacs',
    'Kaba',
    'Josvafo',
    'Jasztelek',
    'Jaszszentandras',
    'Jaszladany',
    'Jaszkiser',
    'Jaszjakohalma',
    'Jaszdozsa',
    'Jaszboldoghaza',
    'Jaszapati',
    'Jaszalsoszentgyorgy',
    'Jardanhaza',
    'Janoshida',
    'Jankmajtis',
    'Izsofalva',
    'Istenmezeje',
    'Inancs',
    'Ibrany',
    'Hunya',
    'Hosszupalyi',
    'Hortobagy',
    'Homrogd',
    'Hodmezovasarhely',
    'Hodasz',
    'Hidvegardo',
    'Hidasnemeti',
    'Hevesvezekeny',
    'Hevesaranyos',
    'Heves',
    'Hernadnemeti',
    'Hencida',
    'Hejoszalonta',
    'Hejopapi',
    'Hejokeresztur',
    'Hejobaba',
    'Harsany',
    'Hangony',
    'Halmajugra',
    'Halmaj',
    'Hajduszovat',
    'Hajduszoboszlo',
    'Hajdusamson',
    'Hajdunanas',
    'Hajduhadhaz',
    'Hajdudorog',
    'Hajduboszormeny',
    'Hajdubagos',
    'Gyure',
    'Gyulahaza',
    'Gyula',
    'Gyorgytarlo',
    'Gyomaendrod',
    'Gorbehaza',
    'Goncruszka',
    'Gonc',
    'Girincs',
    'Geszt',
    'Gemzse',
    'Geberjen',
    'Gavavencsello',
    'Gadoros',
    'Gacsaly',
    'Gaborjan',
    'Fuzesgyarmat',
    'Fuzesabony',
    'Fuzerradvany',
    'Furta',
    'Fulop',
    'Fony',
    'Foldes',
    'Foldeak',
    'Fenyeslitke',
    'Felsozsolca',
    'Felsotarkany',
    'Felsonyarad',
    'Felsokelecseny',
    'Felsodobsza',
    'Felgyo',
    'Feldebro',
    'Fehergyarmat',
    'Fegyvernek',
    'Fabiansebestyen',
    'Fabianhaza',
    'Esztar',
    'Erdotelek',
    'Erdobenye',
    'Eperjeske',
    'Eperjes',
    'Encsencs',
    'Encs',
    'Emod',
    'Elek',
    'Egyek',
    'Egerlovo',
    'Egerfarmos',
    'Egercsehi',
    'Egerbakta',
    'Eger',
    'Edeleny',
    'Ecsegfalva',
    'Ebes',
    'Dormand',
    'Domoszlo',
    'Dombrad',
    'Dombiratos',
    'Dombegyhaz',
    'Domaszek',
    'Doge',
    'Doboz',
    'Devavanya',
    'Detk',
    'Detek',
    'Deszk',
    'Derekegyhaz',
    'Derecske',
    'Demjen',
    'Demecser',
    'Debrecen',
    'Damoc',
    'Csorvas',
    'Csongrad',
    'Csokmo',
    'Csobad',
    'Csincse',
    'Cserkeszolo',
    'Cserepfalu',
    'Csepa',
    'Csengerujfalu',
    'Csenger',
    'Csegold',
    'Csardaszallas',
    'Csanytelek',
    'Csanadpalota',
    'Csanadalberti',
    'Csabacsud',
    'Cibakhaza',
    'Bukkzserc',
    'Buekkszenterzsebet',
    'Bukkaranyos',
    'Bukkabrany',
    'Bucsa',
    'Borsodszirak',
    'Borsodnadasd',
    'Borsodivanka',
    'Boldva',
    'Boldogkovaralja',
    'Boldogkoujfalu',
    'Bokony',
    'Bogacs',
    'Bodrogolaszi',
    'Bodrogkisfalud',
    'Bodrogkeresztur',
    'Bodroghalom',
    'Bodorszek',
    'Bodaszolo',
    'Bocskaikert',
    'Bocs',
    'Boconad',
    'Biharugra',
    'Biharnagybajom',
    'Biharkeresztes',
    'Beszterec',
    'Besenyszog',
    'Besenyotelek',
    'Berkesz',
    'Berettyoujfalu',
    'Berente',
    'Berekfurdo',
    'Beregsurany',
    'Beregdaroc',
    'Belmegyer',
    'Belapatfalva',
    'Bekolce',
    'Bekesszentandras',
    'Békéscsaba',
    'Békés',
    'Bekecs',
    'Battonya',
    'Bator',
    'Barand',
    'Barabas',
    'Banreve',
    'Banhorvati',
    'Balmazujvaros',
    'Balkany',
    'Balastya',
    'Baktaloranthaza',
    'Baktakek',
    'Baks',
    'Bakonszeg',
    'Bagamer',
    'Aszalo',
    'Arpadhalom',
    'Arnot',
    'Arlo',
    'Batorliget',
    'Apatfalva',
    'Apagy',
    'Andornaktalya',
    'Anarcs',
    'Ambrozfalva',
    'Alsozsolca',
    'Alsovarsany',
    'Almaskamaras',
    'Aldebro',
    'Alattyan',
    'Ajak',
    'Abony',
    'Abaujszanto',
    'Abasar',
    'Abadszalok',
    'Zsombo',
    'Zsira',
    'Zsambek',
    'Zomba',
    'Zok',
    'Zirc',
    'Zimany',
    'Zanka',
    'Zamoly',
    'Zamardi',
    'Zalavar',
    'Zalaszentivan',
    'Zalaszentgyorgy',
    'Zalaszentgrot',
    'Zalaszanto',
    'Zalaszabar',
    'Zalalovo',
    'Zalakaros',
    'Zalahalap',
    'Zalagyomoro',
    'Zalaegerszeg',
    'Zalacseb',
    'Zalabaksa',
    'Zalaapati',
    'Zakanyszek',
    'Zakany',
    'Zagyvaszanto',
    'Zador',
    'Vors',
    'Vonyarcvashegy',
    'Vizslas',
    'Vitnyed',
    'Visegrád',
    'Vilonya',
    'Villany',
    'Veszpremvarsany',
    'Veszprém',
    'Veszkeny',
    'Vertesszolos',
    'Vertessomlo',
    'Vertesboglar',
    'Vertesacsa',
    'Versend',
    'Verseg',
    'Veroce',
    'Vereb',
    'Vep',
    'Velence',
    'Vekeny',
    'Vecses',
    'Vat',
    'Vaszar',
    'Vasvar',
    'Vasszilvagy',
    'Vasszecseny',
    'Vassurany',
    'Vaskut',
    'Vasegerszeg',
    'Vasboldogasszony',
    'Vasarosmiske',
    'Vasarosdombo',
    'Vasad',
    'Varvolgy',
    'Varpalota',
    'Varosfoeld',
    'Vargesztes',
    'Vardomb',
    'Varda',
    'Varbalog',
    'Varaszlo',
    'Varalja',
    'Vanyola',
    'Vanyarc',
    'Vamosszabadi',
    'Vamosgyork',
    'Valko',
    'Val',
    'Vajta',
    'Vajszlo',
    'Vag',
    'Vacszentlaszlo',
    'Vacratot',
    'Vackisujfalu',
    'Vachartyan',
    'Vác',
    'Uszod',
    'Urom',
    'Urkut',
    'Uri',
    'Urhida',
    'Uraiujfalu',
    'Uny',
    'Ullo',
    'Ulles',
    'Ujvarfalva',
    'Ujszilvas',
    'Ujronafo',
    'Ujmohacs',
    'Ujlengyel',
    'Ujker',
    'Ujhartyan',
    'Ugod',
    'Tuskevar',
    'Tura',
    'Totvazsony',
    'Tottos',
    'Totszerdahely',
    'Totszentmarton',
    'Tortel',
    'Toeroekbalint',
    'Tordas',
    'Tompa',
    'Tomord',
    'Toltestava',
    'Tolnanemedi',
    'Tolna',
    'Toekoel',
    'Tokod',
    'Toalmas',
    'Tihany',
    'Tevel',
    'Tet',
    'Tes',
    'Tereske',
    'Tenyo',
    'Tengelic',
    'Telki',
    'Teklafalu',
    'Tazlar',
    'Tatarszentgyorgy',
    'Tatabánya',
    'Tata',
    'Tat',
    'Taszár',
    'Tass',
    'Tarnok',
    'Tarkany',
    'Tardos',
    'Tarany',
    'Tar',
    'Tapszentmiklos',
    'Tapsony',
    'Tapolca',
    'Taplanszentkereszt',
    'Tapioszolos',
    'Tapioszentmarton',
    'Tapioszele',
    'Tapioszecso',
    'Suelysap',
    'Tapiosag',
    'Tapiogyorgye',
    'Tapiobicske',
    'Tap',
    'Tanakajd',
    'Tamasi',
    'Taliandorogd',
    'Taksony',
    'Takacsi',
    'Tahitotfalu',
    'Tac',
    'Taborfalva',
    'Tabdi',
    'Tabajd',
    'Tab',
    'Szurdokpuspoki',
    'Szulok',
    'Szuha',
    'Szugy',
    'Szomor',
    'Szomod',
    'Szombathely',
    'Szokolya',
    'Szodliget',
    'Szod',
    'Szob',
    'Szirak',
    'Szilsarkany',
    'Szil',
    'Szijartohaza',
    'Szigliget',
    'Szigetvar',
    'Szigetujfalu',
    'Szigetszentmiklos',
    'Szigetszentmarton',
    'Szigetmonostor',
    'Szigethalom',
    'Szigetcsep',
    'Szerecseny',
    'Szepetnek',
    'Szentpeterur',
    'Szentpeterfa',
    'Szentmartonkata',
    'Szentlorinckata',
    'Szentlorinc',
    'Szentkiralyszabadja',
    'Szentjakabfa',
    'Szentgyorgyvolgy',
    'Szentgotthard',
    'Szentgalpuszta',
    'Szentendre',
    'Szentdenes',
    'Szentbekkalla',
    'Szentantalfa',
    'Szenna',
    'Szendehely',
    'Szemely',
    'Szekszárd',
    'Székesfehérvár',
    'Szedres',
    'Szederkeny',
    'Szecsenyfelfalu',
    'Szecseny',
    'Szecsenke',
    'Szebeny',
    'Szazhalombatta',
    'Szaszvar',
    'Szarliget',
    'Szarazd',
    'Szar',
    'Szapar',
    'Szank',
    'Szalkszentmarton',
    'Szakszend',
    'Szakony',
    'Szakmar',
    'Szakcs',
    'Szakaly',
    'Szajk',
    'Szada',
    'Szabadszentkiraly',
    'Szabadszallas',
    'Szabadhidveg',
    'Szabadbattyan',
    'Sutto',
    'Surd',
    'Sur',
    'Sumegpraga',
    'Sumeg',
    'Sukosd',
    'Soskut',
    'Sorokpolany',
    'Sorkikapolna',
    'Sorkifalud',
    'Sopte',
    'Sopronkovesd',
    'Sopron',
    'Soponya',
    'Somoskoujfalu',
    'Somogyvar',
    'Somogyvamos',
    'Somogyszil',
    'Somogysard',
    'Somogysamson',
    'Somogyjad',
    'Somogyharsagy',
    'Somogyaszalo',
    'Somlovasarhely',
    'Somberek',
    'Solymar',
    'Soltvadkert',
    'Soltszentimre',
    'Solt',
    'Sojtor',
    'Siojut',
    'Siófok',
    'Simontornya',
    'Simasag',
    'Siklos',
    'Seregelyes',
    'Sellye',
    'Se',
    'Sasd',
    'Sarvar',
    'Sarszentmihaly',
    'Sarszentlorinc',
    'Sarszentagota',
    'Sarrod',
    'Sarosd',
    'Sarmellek',
    'Sarkeszi',
    'Sarkeresztur',
    'Sarkeresztes',
    'Sarisap',
    'Sarhida',
    'Saregres',
    'Sarbogard',
    'Sand',
    'Salgotarjan',
    'Salfold',
    'Ruzsa',
    'Rum',
    'Rozsaszentmarton',
    'Ronok',
    'Romonya',
    'Romhany',
    'Romand',
    'Rinyaujlak',
    'Rimoc',
    'Rigyac',
    'Rezi',
    'Revfueloep',
    'Retsag',
    'Retalap',
    'Resznek',
    'Repceszentgyorgy',
    'Repceszemere',
    'Repcelak',
    'Rem',
    'Regoly',
    'Redics',
    'Rede',
    'Ravazd',
    'Ratot',
    'Raksi',
    'Rajka',
    'Radockolked',
    'Rad',
    'Rackeve',
    'Rackeresztur',
    'Racalmas',
    'Rabaszentandras',
    'Rabapordany',
    'Rabapaty',
    'Rabapatona',
    'Rabahidveg',
    'Rabagyarmat',
    'Pusztavam',
    'Pusztavacs',
    'Pusztaszentlaszlo',
    'Pusztaszabolcs',
    'Pusztamonostor',
    'Pusztamerges',
    'Pusztakovacsi',
    'Pusztahencse',
    'Pusztacsalad',
    'Puspokszilagy',
    'Puspokmolnari',
    'Puspokhatvan',
    'Puski',
    'Potrete',
    'Potony',
    'Porva',
    'Pornoapati',
    'Porboly',
    'Pomaz',
    'Polgardi',
    'Poganyszentpeter',
    'Pogany',
    'Pirto',
    'Pinnye',
    'Pincehely',
    'Pilisvorosvar',
    'Pilisszentlaszlo',
    'Pilisszentkereszt',
    'Pilisszentivan',
    'Pilisszanto',
    'Pilismarot',
    'Piliscsev',
    'Piliscsaba',
    'Pilisborosjeno',
    'Pilis',
    'Petrivente',
    'Petrikeresztur',
    'Petohaza',
    'Petofiszallas',
    'Petofibanya',
    'Peteri',
    'Pest',
    'Perocseny',
    'Perkata',
    'Perenye',
    'Perbal',
    'Per',
    'Penc',
    'Pellerd',
    'Pecsvarad',
    'Pécs',
    'Pecol',
    'Pecel',
    'Pazmandfalu',
    'Paty',
    'Patvarc',
    'Patka',
    'Pasztori',
    'Paszto',
    'Papkeszi',
    'Papateszer',
    'Papasalamon',
    'Papakovacsi',
    'Papa',
    'Pannonhalma',
    'Pand',
    'Palotas',
    'Palmonostora',
    'Palfa',
    'Paks',
    'Pakozd',
    'Paka',
    'Pacsa',
    'Ozora',
    'Otteveny',
    'Oszko',
    'Osli',
    'Osku',
    'Osi',
    'Oroszlany',
    'Orkeny',
    'Oriszentpeter',
    'Orgovany',
    'Oreglak',
    'Ordacsehi',
    'Orci',
    'Orbottyan',
    'Olbo',
    'Olaszfalu',
    'Ocseny',
    'Ocsa',
    'Obarok',
    'Nyul',
    'Nyoger',
    'Nyirad',
    'Nyergesujfalu',
    'Nyarsapat',
    'Nyarlorinc',
    'Nyaregyhaza',
    'Nyarad',
    'Notincs',
    'Nogradsipek',
    'Nogradsap',
    'Nogradmegyer',
    'Nograd',
    'Nikla',
    'Nezsa',
    'Nemetker',
    'Nemetbanya',
    'Nemesvamos',
    'Nemessandorhaza',
    'Nemesrempehollos',
    'Nemespatro',
    'Nemesnep',
    'Nemesnadudvar',
    'Nemeskolta',
    'Nemeskocs',
    'Nemeshetes',
    'Nemeshany',
    'Nemesgorzsony',
    'Nemesded',
    'Narai',
    'Nak',
    'Nagyvaty',
    'Nagytarcsa',
    'Nagyszentjanos',
    'Nagyszekely',
    'Nagysimonyi',
    'Nagysap',
    'Nagyrede',
    'Nagyrecse',
    'Nagypall',
    'Nagyoroszi',
    'Nagymizdo',
    'Nagymaros',
    'Nagymanyok',
    'Nagylok',
    'Nagyloc',
    'Nagykozar',
    'Nagykovacsi',
    'Nagykoros',
    'Nagykonyi',
    'Nagykokenyes',
    'Nagykata',
    'Nagykaracsony',
    'Nagykapornak',
    'Nagykanizsa',
    'Nagyigmand',
    'Nagygyimot',
    'Nagyesztergar',
    'Nagydorog',
    'Nagydem',
    'Nagycenk',
    'Nagyberki',
    'Nagybereny',
    'Nagybarkany',
    'Nagybaracska',
    'Nagybajom',
    'Nagyatad',
    'Nagyalasony',
    'Nadasdladany',
    'Nadap',
    'Murakeresztur',
    'Mucsfa',
    'Mozsgo',
    'Mosonszolnok',
    'Mosonmagyaróvár',
    'Mosdos',
    'Morahalom',
    'Mor',
    'Monorierdo',
    'Monor',
    'Molvany',
    'Molnaszecsod',
    'Mohács',
    'Mogyorosbanya',
    'Mogyorod',
    'Mocsa',
    'Miske',
    'Mihalyhaza',
    'Mezoszentgyorgy',
    'Mezoors',
    'Mezolak',
    'Mezofalva',
    'Mezocsokonya',
    'Mesztegnyo',
    'Mesteri',
    'Mernye',
    'Mende',
    'Melykut',
    'Mekenyes',
    'Meggyeskovacsi',
    'Mecseknadasd',
    'Matty',
    'Matraverebely',
    'Matraterenye',
    'Matraszolos',
    'Matraszentimre',
    'Matraszele',
    'Matranovak',
    'Matramindszent',
    'Martonvasar',
    'Marko',
    'Marianosztra',
    'Mariakalnok',
    'Marcalto',
    'Marcali',
    'Marcalgergelyi',
    'Many',
    'Makad',
    'Majs',
    'Majoshaza',
    'Magyarpolany',
    'Magyarnandor',
    'Magyarlak',
    'Magyarkeszi',
    'Magyarkeresztur',
    'Magyargencs',
    'Magyargec',
    'Magyaregres',
    'Magyaregregy',
    'Magyarboly',
    'Magyaratad',
    'Magyaralmas',
    'Magocs',
    'Maglod',
    'Madocsa',
    'Madaras',
    'Lukacshaza',
    'Lovo',
    'Lovaszpatona',
    'Lovas',
    'Lorinci',
    'Lorev',
    'Lokut',
    'Litke',
    'Liter',
    'Liszo',
    'Lippo',
    'Lipot',
    'Letkes',
    'Letenye',
    'Lesencetomaj',
    'Lesenceistvand',
    'Lepseny',
    'Lenti',
    'Lengyeltoti',
    'Legend',
    'Lebeny',
    'Leanyvar',
    'Leanyfalu',
    'Latrany',
    'Lanycsok',
    'Lakitelek',
    'Lajosmizse',
    'Lajoskomarom',
    'Ladanybene',
    'Lad',
    'Labatlan',
    'Kutas',
    'Kunsziget',
    'Kunszentmiklos',
    'Kunszallas',
    'Kunfeherto',
    'Kunbaracs',
    'Kunadacs',
    'Kulcs',
    'Kozarmisleny',
    'Koveskal',
    'Kovagoszolos',
    'Kovagoors',
    'Kotcse',
    'Koszegszerdahely',
    'Koszeg',
    'Koszarhegy',
    'Kospallag',
    'Kosd',
    'Koroshegy',
    'Koronco',
    'Kornye',
    'Kormend',
    'Kophaza',
    'Kony',
    'Komlod',
    'Komárom',
    'Kolesd',
    'Koka',
    'Kocsola',
    'Kocser',
    'Kocs',
    'Kisvejke',
    'Kistelek',
    'Kisszallas',
    'Kisoroszi',
    'Kisnemedi',
    'Kismanyok',
    'Kislod',
    'Kislang',
    'Kiskunmajsa',
    'Kiskunlachaza',
    'Kiskunhalas',
    'Kiskunfelegyhaza',
    'Kiskoros',
    'Kiskassa',
    'Kisigmand',
    'Kishartyan',
    'Kisharsany',
    'Kisdorog',
    'Kisbudmer',
    'Kisber',
    'Kisapostag',
    'Kiralyszentistvan',
    'Kiralyegyhaza',
    'Kincsesbanya',
    'Kimle',
    'Kethely',
    'Keszu',
    'Kesztolc',
    'Keszthely',
    'Keszeg',
    'Kerta',
    'Kerkateskand',
    'Kerkaszentkiraly',
    'Kistarcsa',
    'Kerekharaszt',
    'Kerekegyhaza',
    'Kerecseny',
    'Kemenfa',
    'Kemenesmagasi',
    'Kemendollar',
    'Keleshalom',
    'Kelebia',
    'Kehidakustany',
    'Kecskemét',
    'Kecel',
    'Kava',
    'Katymar',
    'Kaskantyu',
    'Kartal',
    'Karmacs',
    'Karancssag',
    'Karancskeszi',
    'Karancsalja',
    'Kapuvar',
    'Kaptalantoti',
    'Kaposvár',
    'Kaposujlak',
    'Kaposszerdahely',
    'Kaposszekcso',
    'Kapospula',
    'Kaposmero',
    'Kaposhomok',
    'Kapolnasnyek',
    'Kanya',
    'Kam',
    'Kalocsa',
    'Kallo',
    'Kald',
    'Kakucs',
    'Kakasd',
    'Kajaszo',
    'Kajarpec',
    'Juta',
    'Jobbagyi',
    'Jeno',
    'Jaszszentlaszlo',
    'Jaszfenyszaru',
    'Pilisjaszfalu',
    'Jaszbereny',
    'Jaszarokszallas',
    'Jasd',
    'Janossomorja',
    'Janoshaza',
    'Janoshalma',
    'Fueloepjakab',
    'Jakabszallas',
    'Jak',
    'Izsak',
    'Izmeny',
    'Ivancsa',
    'Ivan',
    'Isztimer',
    'Iszkaz',
    'Isaszeg',
    'Iregszemcse',
    'Ipolyvece',
    'Ipolydamasd',
    'Inarcs',
    'Imrehegy',
    'Ikreny',
    'Ikervar',
    'Hugyag',
    'Hovej',
    'Hosszupereszteg',
    'Hosszuheteny',
    'Horvatzsidany',
    'Hort',
    'Homokbodoge',
    'Hogyesz',
    'Hobol',
    'Hidas',
    'Hévíz',
    'Hernad',
    'Herend',
    'Hereg',
    'Hercegszanto',
    'Herceghalom',
    'Helvecia',
    'Helesfa',
    'Hegyszentmarton',
    'Hegyko',
    'Hegyhathodasz',
    'Hegyfalu',
    'Hegyeshalom',
    'Hedervar',
    'Hatvan',
    'Nagyvenyim',
    'Harta',
    'Harskut',
    'Harkapuszta',
    'Harkany',
    'Harkakotony',
    'Harka',
    'Hangacsdulo',
    'Halimba',
    'Halasztelek',
    'Halaszi',
    'Hajos',
    'Hajmasker',
    'Hahot',
    'Hagyarosborond',
    'Hacs',
    'Gyuro',
    'Gyulakeszi',
    'Gyorzamoly',
    'Gyorujfalu',
    'Gyorujbarat',
    'Gyorszemere',
    'Gyorladamer',
    'Gyorasszonyfa',
    'Győr',
    'Gyonk',
    'Gyongyostarjan',
    'Gyongyossolymos',
    'Gyongyospata',
    'Gyongyoshalasz',
    'Gyongyosfalu',
    'Gyongyos',
    'Gyomro',
    'Gyomore',
    'Gyermely',
    'Gyenesdias',
    'Gyaloka',
    'Gyal',
    'Gutorfolde',
    'Gorgeteg',
    'Gorcsony',
    'Gonyu',
    'Gomba',
    'Gödöllő',
    'God',
    'Gige',
    'Gic',
    'Gerjen',
    'Gerce',
    'Gencsapati',
    'Gelse',
    'Gellenhaza',
    'Gederlak',
    'Gardony',
    'Galgamacsa',
    'Galgaheviz',
    'Galgagyork',
    'Galgaguta',
    'Galambok',
    'Furged',
    'Fulopszallas',
    'Fot',
    'Forropuszta',
    'Forraskut',
    'Fonyod',
    'Fokto',
    'Fityehaz',
    'Fertoszentmiklos',
    'Fertod',
    'Fertoboz',
    'Felsoszentivan',
    'Felsorajk',
    'Felsopakony',
    'Felsopahok',
    'Felsoors',
    'Felsonyek',
    'Felpec',
    'Felcsut',
    'Feketeerdo',
    'Fehervarcsurgo',
    'Farmos',
    'Fadd',
    'Etyek',
    'Esztergom',
    'Erteny',
    'Ersekvadkert',
    'Ersekhalma',
    'Erdokertes',
    'Érd',
    'Ercsi',
    'Epol',
    'Epleny',
    'Enying',
    'Enese',
    'Endrefalva',
    'Eloszallas',
    'Egyhazaskozar',
    'Egyhazashollos',
    'Egyhazashetye',
    'Egyhazasgerge',
    'Egyhazasfalu',
    'Egyed',
    'Egervolgy',
    'Egervar',
    'Ecser',
    'Ecsed',
    'Ecs',
    'Ebergoc',
    'Duzs',
    'Dusnok',
    'Dunavecse',
    'Dunavarsany',
    'Dunaújváros',
    'Dunaszentpal',
    'Dunaszentgyorgy',
    'Dunaszentbenedek',
    'Dunaszeg',
    'Dunapataj',
    'Dunakiliti',
    'Dunakeszi',
    'Dunaharaszti',
    'Dunafoldvar',
    'Dunafalva',
    'Dunaegyhaza',
    'Dunabogdany',
    'Dunaalmas',
    'Dregelypalank',
    'Dravafok',
    'Dorog',
    'Dor',
    'Domsod',
    'Domos',
    'Domony',
    'Dombovar',
    'Nagydobsza',
    'Dobrokoz',
    'Doba',
    'Diosviszlo',
    'Diosjeno',
    'Diosd',
    'Diosbereny',
    'Devecser',
    'Dencshaza',
    'Delegyhaza',
    'Dejtar',
    'Deg',
    'Decs',
    'Darnozseli',
    'Dany',
    'Danszentmiklos',
    'Dalmand',
    'Daka',
    'Dad',
    'Dabronc',
    'Dabas',
    'Csurgo',
    'Csot',
    'Csorotnek',
    'Csoeroeg',
    'Csornyefold',
    'Csorna',
    'Csor',
    'Csopak',
    'Csomor',
    'Csomoder',
    'Csomad',
    'Csolnok',
    'Csokonyavisonta',
    'Csokako',
    'Csobanka',
    'Csipkerek',
    'Csikvand',
    'Csikeria',
    'Csevharaszt',
    'Csesztve',
    'Csesztreg',
    'Cserto',
    'Cserszegtomaj',
    'Cserhatsurany',
    'Csepreg',
    'Csep',
    'Csengod',
    'Csengele',
    'Csempeszkopacs',
    'Csemo',
    'Csehbanya',
    'Csecse',
    'Csavoly',
    'Csatar',
    'Csatalja',
    'Csaszar',
    'Csapod',
    'Csapi',
    'Csany',
    'Csakvar',
    'Csakbereny',
    'Csabrendek',
    'Csabdi',
    'Celldomolk',
    'Cegledbercel',
    'Cegled',
    'Cece',
    'Buzsak',
    'Buekkoesd',
    'Buk',
    'Bujak',
    'Bugyi',
    'Bugac',
    'Budapest',
    'Budaors',
    'Budakeszi',
    'Budakalasz',
    'Budajeno',
    'Bucsuszentlaszlo',
    'Aszod',
    'Botykapeterd',
    'Boszenfa',
    'Borzavar',
    'Borsfa',
    'Borota',
    'Borjad',
    'Bordany',
    'Borcs',
    'Bonyhad',
    'Bony',
    'Bonnya',
    'Boly',
    'Boldog',
    'Bolcske',
    'Bokod',
    'Bohonye',
    'Bogyoszlo',
    'Bogyiszlo',
    'Bodonhely',
    'Bodmer',
    'Bodajk',
    'Bocsa',
    'Bocfolde',
    'Boba',
    'Birjan',
    'Bicske',
    'Biatorbagy',
    'Bezenye',
    'Besnyo',
    'Berzence',
    'Bernecebarati',
    'Berkesd',
    'Berhida',
    'Beremend',
    'Bercel',
    'Ber',
    'Benye',
    'Belvardgyula',
    'Beloiannisz',
    'Belezna',
    'Beleg',
    'Bejcgyertyanos',
    'Bedegker',
    'Becsehely',
    'Beb',
    'Bazakerettye',
    'Batya',
    'Batonyterenye',
    'Batmonostor',
    'Bate',
    'Bataszek',
    'Bata',
    'Barcs',
    'Barbacs',
    'Baracs',
    'Bar',
    'Band',
    'Bana',
    'Balotaszallas',
    'Balogunyom',
    'Balloszog',
    'Balinka',
    'Balatonvilagos',
    'Balatonujlak',
    'Balatonudvari',
    'Balatonszentgyorgy',
    'Balatonszemes',
    'Balatonszabadi',
    'Balatonoszod',
    'Balatonmariafurdo',
    'Balatonlelle',
    'Balatonkeresztur',
    'Balatonkenese',
    'Balatonfuzfo',
    'Balatonfured',
    'Balatonfoldvar',
    'Balatonfokajar',
    'Balatonfenyves',
    'Balatonendred',
    'Balatonederics',
    'Balatonboglar',
    'Balatonalmadi',
    'Balatonakarattya',
    'Balatonakali',
    'Balassagyarmat',
    'Baktuttos',
    'Baksa',
    'Bakonytamasi',
    'Bakonyszombathely',
    'Bakonyszentkiraly',
    'Bakonysarkany',
    'Bakonypeterd',
    'Bakonyoszlop',
    'Bakonynana',
    'Bakonykoppany',
    'Bakonygyirot',
    'Bakonycsernye',
    'Bakonybel',
    'Bakonybank',
    'Bak',
    'Bajot',
    'Bajna',
    'Baja',
    'Bagyogszovat',
    'Bagod',
    'Bag',
    'Badacsonytomaj',
    'Bacsalmas',
    'Babot',
    'Babonymegyer',
    'Babolna',
    'Atkar',
    'Asvanyraro',
    'Asotthalom',
    'Apostag',
    'Aporka',
    'Apc',
    'Aparhant',
    'Apaj',
    'Alsoszolnok',
    'Alsoszentivan',
    'Alsopeteny',
    'Alsopahok',
    'Alsoors',
    'Alsonemedi',
    'Alsonana',
    'Almasfuzito',
    'Almamellek',
    'Alcsutdoboz',
    'Albertirsa',
    'Alap',
    'Akaszto',
    'Aka',
    'Ajka',
    'Agyagosszergeny',
    'Agfalva',
    'Agasegyhaza',
    'Agard',
    'Adony',
    'Adasztevel',
    'Adand',
    'Adacs',
    'Acsteszer',
    'Acsa',
    'Acs',
    'Abda',
    'Aba',
    'Tiszaalpar',
    'Algyo',
    'Kismaros',
    'Alsoujlak',
    'Hehalom',
    'Mosonszentmiklos',
    'Kerepes',
    'Kisdobsza',
    'Adorjanhaza',
    'Remeteszolos',
  ],
  'North Macedonia': [
    'Vinica',
    'Vevcani',
    'Veles',
    'Tetovo',
    'Sveti Nikole',
    'Strumica',
    'Struga',
    'Shtip',
    'Star Dojran',
    'Skopje',
    'Saraj',
    'Rostusa',
    'Rosoman',
    'Resen',
    'Radovis',
    'Probistip',
    'Prilep',
    'Ohrid',
    'Novo Selo',
    'Negotino',
    'Demir Hisar',
    'Mozdivnjak',
    'Kumanovo',
    'Kriva Palanka',
    'Kratovo',
    'Kočani',
    'Kicevo',
    'Kazani',
    'Kavadarci',
    'Kamenjane',
    'Grupcin',
    'Gostivar',
    'Gevgelija',
    'Dolni Disan',
    'Demir Kapija',
    'Delcevo',
    'Debar',
    'Davidovo',
    'Ciflik',
    'Cegrane',
    'Brusnik',
    'Makedonski Brod',
    'Bosilovo',
    'Bogovinje',
    'Bogdanci',
    'Bitola',
    'Berovo',
    'Balin Dol',
    'Badar',
    'Arvati',
    'Agino Selo',
    'Bezovo',
    'Oslomej',
  ],
  Slovakia: [
    'Zlate',
    'Zemplinske Hradiste',
    'Zemplinska Teplica',
    'Zdana',
    'Zborov',
    'Zamutov',
    'Zakarovce',
    'Zacharovce',
    'Zaborske',
    'Vysoké Tatry',
    'Vysny Hrusov',
    'Vysne Raslavice',
    'Vysna Sunava',
    'Vysna Slana',
    'Vrbov',
    'Vranov nad Topľou',
    'Vojcice',
    'Vlachovo',
    'Vitaz',
    'Vinne',
    'Vernar',
    'Velky Slavkov',
    'Velky Saris',
    'Velky Kazimir',
    'Velky Hores',
    'Velky Blh',
    'Velke Kapusany',
    'Velka Poloma',
    'Velka Lomnica',
    'Velka Frankova',
    'Vechec',
    'Valaliky',
    'Ulic',
    'Udol',
    'Udavske',
    'Tvarozna',
    'Turna nad Bodvou',
    'Tulcik',
    'Trebišov',
    'Tornaľa',
    'Toporec',
    'Teply Vrch',
    'Tatranská Lomnica',
    'Svit',
    'Svinice',
    'Svinia',
    'Svidník',
    'Svedlar',
    'Svabovce',
    'Stropkov',
    'Streda nad Bodrogom',
    'Strba',
    'Strazske',
    'Stará Ľubovňa',
    'Stara Lesna',
    'Stakcin',
    'Spissky Stvrtok',
    'Spissky Stiavnik',
    'Spissky Hrusov',
    'Spisske Vlachy',
    'Spisske Tomasovce',
    'Spišské Podhradie',
    'Spisske Hanusovce',
    'Spisske Bystre',
    'Spisska Teplica',
    'Spisska Stara Ves',
    'Spišská Nová Ves',
    'Spisska Bela',
    'Somotor',
    'Sobrance',
    'Snina',
    'Smizany',
    'Slovenske Nove Mesto',
    'Slovenska Ves',
    'Slavkovce',
    'Slanec',
    'Siroke',
    'Sena',
    'Semsa',
    'Sedlice',
    'Secovska Polianka',
    'Secovce',
    'Sarisske Jastrabie',
    'Sarisske Dravce',
    'Sady',
    'Sacurov',
    'Sabinov',
    'Ruskov',
    'Rudnany',
    'Roznavske Bystre',
    'Rožňava',
    'Rozhanovce',
    'Rostar',
    'Rimavská Sobota',
    'Revúca',
    'Resica',
    'Raznany',
    'Ratkova',
    'Puste Cemerne',
    'Proc',
    'Prešov',
    'Prakovce',
    'Pozdisovce',
    'Porubka',
    'Porostov',
    'Poproc',
    'Poprad',
    'Podolinec',
    'Plesivec',
    'Plechotice',
    'Plavnica',
    'Plavec',
    'Pichne',
    'Petrovany',
    'Petrikovce',
    'Pecovska Nova Ves',
    'Pcoline',
    'Parchovany',
    'Papin',
    'Palin',
    'Odorin',
    'Novy Salas',
    'Novosad',
    'Nova Lesna',
    'Nizny Zipov',
    'Nizny Klatov',
    'Nizny Hrusov',
    'Nizny Hrabovec',
    'Nizne Repase',
    'Nalepkovo',
    'Nacina Ves',
    'Muran',
    'Mosurov',
    'Moldava nad Bodvou',
    'Modra',
    'Mlynceky',
    'Michalovce',
    'Mengusovce',
    'Medzilaborce',
    'Medzev',
    'Markusovce',
    'Marhan',
    'Margecany',
    'Malcov',
    'Mala Ida',
    'Majerovce',
    'Luhyna',
    'Lucivna',
    'Lubovec',
    'lubotin',
    'Lubotice',
    'lubisa',
    'Lubica',
    'Lubenik',
    'Lipovnik',
    'Lipany',
    'Licartovce',
    'Levoča',
    'Letanovce',
    'Lendak',
    'Lemesany',
    'Leles',
    'Lekarovce',
    'Lastovce',
    'Lastomir',
    'Kuzmice',
    'Kurima',
    'Kruzlov',
    'Krompachy',
    'Krasnohorske Podhradie',
    'Krasna Luka',
    'Kralovsky Chlmec',
    'Kralovce',
    'Koskovce',
    'Kosicka Bela',
    'Košice',
    'Korejovce',
    'Komarovce',
    'Kokosovce',
    'Kojsov',
    'Kluknava',
    'Klenov',
    'Klcov',
    'Kežmarok',
    'Kendice',
    'Kamienka',
    'Jovsa',
    'Jesenske',
    'Jelšava',
    'Javorina',
    'Jastrabie',
    'Jasov',
    'Jarovnice',
    'Janov',
    'Jankovce',
    'Jakubany',
    'Jaklovce',
    'Jablonov nad Turnou',
    'Huta',
    'Huncovce',
    'Humenné',
    'Hudcovce',
    'Hranovnica',
    'Hran',
    'Hrabusice',
    'Hrabovec',
    'Horka',
    'Hodkovce',
    'Hnilec',
    'Hniezdne',
    'Hazlin',
    'Hatalov',
    'Hanusovce nad Toplou',
    'Haniska',
    'Gynov',
    'Gregorovce',
    'Granc-Petrovce',
    'Giraltovce',
    'Gerlachov',
    'Gemerske Michalovce',
    'Gemerska Horka',
    'Gelnica',
    'Geca',
    'Ganovce',
    'Gaboltov',
    'Fintice',
    'Filice',
    'Dulova Ves',
    'Druzstevna pri Hornade',
    'Drienov',
    'Dravce',
    'Dargov',
    'Dolany',
    'Dlhe nad Cirochou',
    'Cierna nad Tisou',
    'Chyzne',
    'Cestice',
    'Cerveny Klastor',
    'Cejkov',
    'Cecejovce',
    'Cana',
    'Bystre',
    'Brzotin',
    'Bracovce',
    'Botany',
    'Borsa',
    'Boliarov',
    'Bohdanovce',
    'Biel',
    'Bidovce',
    'Betliar',
    'Betlanovce',
    'Benadikovce',
    'Bela nad Cirochou',
    'Batizovce',
    'Bardejov',
    'Bajerov',
    'Arnutovce',
    'Ardovo',
    'Zvolenska Slatina',
    'Zvolen',
    'Zubrohlava',
    'Zuberec',
    'Zolna',
    'Zlatna na Ostrove',
    'Zlate Moravce',
    'Zirany',
    'Žilina',
    'Ziharec',
    'Ziar nad Hronom',
    'Zemne',
    'Zemliare',
    'Zemianske Sady',
    "Zemianske Kostol'any",
    'Zelovce',
    'Zeliezovce',
    'Zelenec',
    'Zborov nad Bystricou',
    'Zbehy',
    'Zazriva',
    'Zavazna Poruba',
    'Zavar',
    'Zavadka nad Hronom',
    'Zastranie',
    'Zaskov',
    'Zarnovica',
    'Zakamenne',
    'Zahorce',
    'Zabokreky nad Nitrou',
    'Vysoka nad Kysucou',
    'Vyhne',
    'Vycapy-Opatovce',
    'Vrutky',
    'Vrsatske Podhradie',
    'Vrbové',
    'Vrakuna',
    'Vrakun',
    'Vradiste',
    'Vrable',
    'Vozokany nad Hronom',
    'Vozokany',
    'Volkovce',
    'Batorove Kosihy',
    'Vojka nad Dunajom',
    'Voderady',
    'Vlky',
    'Vlkanova',
    'Vlachy',
    'Vitanova',
    'Vinicne',
    'Vinica',
    'Vesele',
    'Velky Meder',
    'Velky Lapas',
    'Velky Kyr',
    'Velky Krtis',
    'Velky Cetin',
    'Velky Biel',
    'Velke Zaluzie',
    'Velke Ulany',
    'Velke Surovce',
    'Velke Rovne',
    'Velke Ripnany',
    'Velke Orviste',
    'Velke Ludince',
    'Velke Lovce',
    'Velke Levare',
    'Velke Kozmalovce',
    'Velke Kostolany',
    'Velke Kosihy',
    "Vel'ke Chlievany",
    'Velke Canikovce',
    'Velke Brestovany',
    "Vel'ka Udica",
    'Velka Paka',
    'Velka Mana',
    'Velka Maca',
    'Velka Luc',
    "Vel'ka Hradna",
    'Velka Cierna',
    'Chocholna - Velcice',
    'Velicna',
    'Varin',
    'Valaska Dubova',
    'Valaska',
    'Urmince',
    'Ulany nad Zitavou',
    'Uhrovec',
    'Tvrdosovce',
    'Tvrdosin',
    'Turzovka',
    'Turova',
    'Turčianske Teplice',
    'Turany',
    'Trstice',
    'Trstena',
    'Trnovec nad Vahom',
    'Trnavka',
    'Trnava Hora',
    'Trnava',
    'Trhova Hradska',
    'Trenčín',
    'Trencianske Teplice',
    'Trencianske Jastrabie',
    'Trencianska Turna',
    'Trencianska Tepla',
    'Trebatice',
    'Trakovice',
    'Topolniky',
    'Topolcianky',
    'Topoľčany',
    'Tomasov',
    'Tomasikovo',
    'Tlmace',
    'Tisovec',
    'Timoradza',
    'Terchova',
    'Terany',
    'Teplicka nad Vahom',
    'Tekovske Nemce',
    'Tekovske Luzany',
    'Tekovska Breznica',
    'Tajov',
    'Tachty',
    'Svodin',
    'Svinna',
    'Svaety Peter',
    'Svätý Jur',
    'Svaetoplukovo',
    'Surianky',
    'Šurany',
    'Sulovce',
    'Sulov',
    'Sulekovo',
    'Sucha nad Parnou',
    'Sucha Hora',
    'Sucany',
    'Stvrtok na Ostrove',
    'Stvrtok',
    'Štúrovo',
    'Stupava',
    'Studienka',
    'Strekov',
    'Strecno',
    'Stranavy',
    'Stiavnik',
    'Stiavnicke Bane',
    'Sterusy',
    'Staskov',
    'Stary Tekov',
    'Stary Hradok',
    'Stara Tura',
    'Spacince',
    'Soporna',
    'Solosnica',
    'Solcany',
    'Sokolovce',
    'Sobotiste',
    'Soblahov',
    'Sneznica',
    'Smolenice',
    'Slovensky Grob',
    'Slovenske Pravno',
    'Slovenska Lupca',
    'Sliace',
    'Sliač',
    'Slepcany',
    'Slavnica',
    'Slatina nad Bebravou',
    'Sladkovicovo',
    'Sklabina',
    'Skalka nad Vahom',
    'Skalite',
    'Skalica',
    'Skacany',
    'Sipkove',
    'Sintava',
    'Sereg',
    'Senohrad',
    'Senica',
    'Senec',
    'Semerovo',
    'Selice',
    'Selec',
    'Selce',
    'Sebedrazie',
    'Sebechleby',
    'Sastin',
    'Sasinkovo',
    'Sasa',
    'Santovka',
    'Samorin',
    'Salgovce',
    'Šaľa',
    'Sajdikove Humence',
    'Šahy',
    'Rybnik',
    'Rybky',
    'Rybany',
    'Ružomberok',
    'Ruzindol',
    'Ruskovce',
    'Rudina',
    'Rovinka',
    'Rovensko',
    'Rosina',
    'Rohoznik',
    'Risnovce',
    'Rimavska Bana',
    'Reca',
    'Rastice',
    'Rapovce',
    'Rakovice',
    'Rakova',
    'Rajecke Teplice',
    'Rajec',
    'Radvan nad Dunajom,Slovakia',
    'Radostka',
    'Radosovce',
    'Radosina',
    'Radola',
    'Radava',
    'Rabca',
    'Puste Ulany',
    'Pukanec',
    'Púchov',
    'Pruzina',
    'Pruske',
    'Prievidza',
    'Prietrz',
    'Pribeta',
    'Predmier',
    'Predajna',
    'Precin',
    'Pravenec',
    'Prasnik',
    'Prasice',
    'Praha',
    'Považská Bystrica',
    'Potor',
    'Poruba',
    'Poniky',
    'Poltar',
    'Polomka',
    'Pohronska Polhora',
    'Pohranice',
    'Podvysoka',
    'Podolie',
    'Podhradie',
    'Podbrezova',
    'Podbiel',
    'Pobedim',
    'Pliesovce',
    'Plave Vozokany',
    'Plavecky Stvrtok',
    'Piešťany',
    'Pezinok',
    'Petržalka',
    'Petrovice',
    'Petrova Ves',
    'Pernek',
    'Pecenady',
    'Pata',
    'Partizánske',
    'Partizanska lupca',
    'Papradno',
    'Palarikovo',
    'Ostra Luka',
    'Oscadnica',
    'Orechova Poton',
    'Oravsky Podzamok',
    'Oravske Vesele',
    'Oravska Polhora',
    'Oponice',
    'Opoj',
    'Opatovce',
    'Olesna',
    'Okolicna na Ostrove',
    'Okoc',
    'Ocova',
    'Ochodnica',
    'Novy Tekov',
    'Nové Zámky',
    'Nove Sady',
    'Nové Mesto nad Váhom',
    'Nova Vieska',
    'Nova Ves nad Zitavou',
    'Nová Dubnica',
    'Nova Bystrica',
    'Nova Bana',
    'Nizna',
    'Nitrianske Rudno',
    'Nitrianske Pravno',
    'Nitrianske Hrnciarovce',
    'Nitrianska Streda',
    'Nitrianska Blatnica',
    'Nitra nad Iplom',
    'Nitra',
    'Nimnica',
    'Nesvady',
    'Neslusa',
    'Neresnica',
    'Nedozery - Brezany',
    'Nededza',
    'Neded',
    'Nedasovce',
    'Necpaly',
    'Narad',
    'Námestovo',
    'Nadlice',
    'Mytna',
    'Myjava',
    'Muzla',
    'Most pri Bratislave',
    'Mošovce',
    'Moravsky Svaety Jan',
    'Moravske Lieskove',
    'Moravany nad Vahom',
    'Mokry Haj',
    'Mojzesovo',
    'Mojs',
    'Mojmirovce',
    'Modry Kamen',
    'Mochovce',
    'Mocenok',
    'Mlynany',
    'Miloslavov',
    'Michalova',
    'Melek',
    'Melcice',
    'Medzibrod',
    'Matuskovo',
    'Martin',
    'Marianka',
    'Marcelova',
    'Malzenice',
    'Maly Cepcin',
    'Malinovo',
    'Malinova',
    'Male Krstenany',
    'Male Dvorniky',
    'Malacky',
    'Makov',
    'Majcichov',
    'Madunice',
    'Lysa pod Makytou',
    'Luzianky',
    'Luzany',
    'Lutila',
    'Luka',
    'Ludanice',
    'Lúčky',
    'Lučenec',
    'luborec',
    'Lubochna',
    'Lubina',
    'lubietova',
    'lubela',
    'Lozorno',
    'Lovinobana',
    'Lovcica',
    'Lopej',
    'Lontov',
    'Lokca',
    'Liptovsky Ondrej',
    'Liptovský Mikuláš',
    'Liptovsky Hradok',
    'Liptovska Tepla',
    'Liptovska Sielnica',
    'Liptovska Osada',
    'Liptovska Luzna',
    'Liptovska Kokava',
    'Likavka',
    'Lietavska Lucka',
    'Lietava',
    "Liest'any",
    'Liesek',
    'Levice',
    'Leopoldov',
    'Lehota',
    'Lehnice',
    'Lednicke Rovne',
    'Lednica',
    'Lazy pod Makytou',
    'Latky',
    'Laksarska Nova Ves',
    'Ladice',
    'Ladce',
    'Kysucky Lieskovec',
    'Kysucké Nové Mesto',
    'Kyselica',
    'Kvetoslavov',
    'Kuty',
    'Kutniky',
    'Kunerad',
    'Kuklov',
    'Kuchyna',
    'Krusovce',
    'Krusetnica',
    'Krupina',
    'Krpelany',
    'Krnca',
    'Krizovany nad Dudvahom',
    'Kremnica',
    'Krasno nad Kysucou',
    'Krasno',
    'Krasna Ves',
    'Krasnany',
    'Kralovicove Kracany',
    'Kralova nad Vahom',
    'Kralova',
    'Krakovany',
    'Krajne',
    'Kozarovce',
    'Kováčová',
    'Kotesova',
    'Kosuty',
    'Kostolne Kracany',
    'Kostany nad Turcom',
    'Kosolna',
    'Kosihovce',
    'Koseca',
    'Kordiky',
    'Komjatna',
    'Komjatice',
    'Komárno',
    'Kolta',
    'Kolinany',
    'Kolarovo',
    'Kolarovice',
    'Kokava nad Rimavicou',
    'Kocovce',
    'Klin',
    'Klenovec',
    'Klatova Nova Ves',
    'Klastor pod Znievom',
    'Klacany',
    'Katov',
    'Katlovce',
    'Kanianka',
    'Kamenne Kosihy',
    'Kamenin',
    'Kamenicna',
    'Kamenica nad Hronom',
    'Kalna',
    'Kalinovo',
    'Kalameny',
    'Kajal',
    'Jelsovce',
    'Jelka',
    'Jelenec',
    'Jedlove Kostolany',
    'Jazernica',
    'Jasova',
    'Jaslovske Bohunice',
    'Jasenove',
    'Jasenie',
    'Jasenica',
    'Jarovce',
    'Janova Lehota',
    'Janiky',
    'Jamnik',
    'Jahodniky',
    'Jahodna',
    'Jacovce',
    'Jablonove',
    'Jablonovce',
    'Jablonica',
    'Ivanovce',
    'Ivanka pri Nitre',
    'Ivanka pri Dunaji',
    'Istebne',
    'Ilava',
    'Hviezdoslavov',
    'Hurbanovo',
    'Hubice',
    'Hrustin',
    'Hrusovany',
    'Hruby Sur',
    'Hronske Kosihy',
    'Hronske Klacany',
    'Hronec',
    'Hrnciarske Zaluzany',
    'Hrnciarovce nad Parnou',
    'Hrinova',
    'Hradiste',
    'Hrabicov',
    'Hostovce',
    'Hoste',
    'Horovce',
    'Horny Vadicov',
    'Horny Pial',
    'Horny Hricov',
    'Horny Harmanec',
    'Horny Bar',
    'Horny Badin',
    'Horne Turovce',
    'Horne Trhoviste',
    'Horne Semerovce',
    'Horne Saliby',
    'Horne Otrokovce',
    'Horne Oresany',
    'Horne Lefantovce',
    'Horne Hamre',
    'Horna Stubna',
    'Horna Streda',
    'Horna Sec',
    'Horna Poton',
    'Horna Poruba',
    'Hornany',
    'Horna Kralova',
    'Hontianske Tesare',
    'Hontianske Nemce',
    'Hontianska Vrbica',
    'Holice',
    'Holic',
    'Hnusta',
    'Hlohovec',
    'Hlinik nad Hronom',
    'Hlinik',
    'Hiadel',
    'Helpa',
    'Handlova',
    'Hamuliakovo',
    'Hajna Nova Ves',
    'Habovka',
    'Golianovo',
    'Glabusovce',
    'Gbely',
    'Galanta',
    'Gabcikovo',
    'Filakovo',
    'Farna',
    'Fackov',
    'Eliasovce',
    'Dvory nad Zitavou',
    'Dvorniky',
    'Dvorec',
    'Dvorany nad Nitrou',
    'Dunajská Streda',
    'Dunajov',
    'Dulovce',
    'Dulov',
    'Dudince',
    'Ducove',
    'Dubodiel',
    'Dubnik',
    'Dubnica nad Váhom',
    'Drietoma',
    'Plevnik-Drienove',
    'Drazovce',
    'Drazkovce',
    'Drahovce',
    'Domaniza',
    'Dolny Stal',
    'Dolny Pial',
    'Dolny Ohaj',
    'Dolný Kubín',
    'Dolny Bar',
    'Dolne Trhoviste',
    'Dolne Slazany',
    'Dolne Saliby',
    'Dolne Plachtince',
    'Dolne Oresany',
    'Dolne Lefantovce',
    'Dolne Kockovce',
    'Dolne Dubove',
    'Dolna Tizina',
    'Dolna Suca',
    'Dolna Strehova',
    'Dolna Streda',
    'Dolna Poruba',
    'Dolna Krupa',
    'Dojc',
    'Dohnany',
    'Dlhe Pole',
    'Dlha nad Oravou',
    'Dlha',
    'Diakovce',
    'Detva',
    'Demandice',
    'Dechtice',
    'Cilizska Radvan',
    'Cifer',
    'Cierny Balog',
    'Cierne',
    'Cicov',
    'Chynorany',
    'Chtelnica',
    'Chotin',
    'Chorvatsky Grob',
    'Chlebnice',
    'Cerveny Hradok',
    'Cervenik',
    'Cerovo',
    'Cerencany',
    'Celadince',
    'Celadice',
    'Cechynce',
    'Cebovce',
    'Cavoj',
    'Cataj',
    'Cata',
    'Casta',
    'Caradice',
    'Caka',
    'Cajkov',
    'Čadca',
    'Cachtice',
    'Cabaj',
    'Bzovik',
    'Bzince pod Javorinou',
    'Bytča',
    'Bystricka',
    'Busince',
    'Bukova',
    'Budmerice',
    'Budca',
    'Bucany',
    'Buc',
    'Brusno',
    'Brodzany',
    'Brodske',
    'Brezovica',
    'Brezova pod Bradlom',
    'Brezno',
    'Breza',
    'Brestovec',
    'Bratislava',
    'Branc',
    'Bosany',
    'Bosaca',
    'Borsky Mikulas',
    'Borovce',
    'Borinka',
    'Bolesov',
    'Boleraz',
    'Bojnicky',
    'Bojnice',
    'Bojna',
    'Bohunice nad Vahom',
    'Bohdanovce nad Trnavou',
    'Bobrovec',
    'Bobrov',
    'Blh nad Iplom',
    'Blazovce',
    'Blatne',
    'Blatna na Ostrove',
    'Bitarova',
    'Binovce',
    'Biely Kostol',
    'Bielovce',
    'Besenov',
    'Bernolakovo',
    'Benus',
    'Belusa',
    'Beckovska Vieska',
    'Bardonovo',
    'Banská Štiavnica',
    'Banská Bystrica',
    'Banska Bela',
    'Banovce nad Bebravou',
    'Banov',
    'Banka',
    'Balon',
    'Baka',
    'Bajc',
    'Bahon',
    'Badin',
    'Bac',
    'Bab',
    'Andovce',
    'Aleksince',
    'Abraham',
    'Zohor',
    'Vysoka pri Morave',
    'Lab',
    'Tatranska Strba',
    'Oravska Lesna',
    'Patince',
    'Podhajska',
    'Dunajska Luzna',
    'Sastin-Straze',
  ],
  Bulgaria: [
    'Beloslav',
    'Zverino',
    'Zornitsa',
    'Zlokuchene',
    'Zlatograd',
    'Zlatitsa',
    'Zlatitrap',
    'Momina Tsarkva',
    'Zavet',
    'Yasen',
    'Yambol',
    'Yakoruda',
    'Yablanitsa',
    'Varshets',
    'Varbitsa',
    'Valkosel',
    'Vratsa',
    'Voysil',
    'Voluyak',
    'Saint Vlas',
    'Vlado Trichkov',
    'Vidin',
    'Vetren',
    'Vetovo',
    'Velingrad',
    'Veliko Tarnovo',
    'Velika',
    'Vasil Levski',
    'Varna',
    'Ustina',
    'Turtucaia',
    'Targovishte',
    'Tsarevets',
    'Tsaratsovo',
    'Tsalapitsa',
    'Tryavna',
    'Troyan Municipality',
    'Topolovo',
    'Topolovgrad',
    'Dobrich',
    'Teteven',
    'Tervel Municipality',
    'Telish',
    'Svoge',
    'Sistov',
    'Svilengrad',
    'Suvorovo',
    'Sarnitsa',
    'Sungurlare',
    'Suhindol',
    'Saedinenie',
    'Studena',
    'Strumyani',
    'Stroevo',
    'Strelcha',
    'Straldzha',
    'Starozagorski Bani',
    'Staro Selo',
    'Stara Zagora',
    'Dupnitsa',
    'Stamboliyski',
    'Srem',
    'Sozopol',
    'Sopot',
    'Sokolino',
    'Sokolets',
    'Sofia',
    'Smyadovo',
    'Smolyan',
    'Slivo Pole',
    'Sliven',
    'Slavyanovo',
    'Slatina',
    'Slashten',
    'Skalak',
    'Sinemorec',
    'Simitli',
    'Simeonovgrad',
    'Silistra',
    'Shumen',
    'Shivachevo',
    'Shipkovo',
    'Shabla',
    'Sevlievo',
    'Septemvri',
    'Senovo',
    'Selanovtsi',
    'Satovcha',
    'Sandanski',
    'Samokov',
    'Samodiva',
    'Rousse',
    'Rudozem',
    'Rudnik',
    'Roman',
    'Rogozen',
    'Rogosh',
    'Rogachevo',
    'Resen',
    'Rebrovo',
    'Razlog',
    'Razgrad',
    'Razboyna',
    'Ravna Gora',
    'Ravda',
    'Rakovski',
    'Rakitovo',
    'Raduil',
    'Butan',
    'Radomir',
    'Radnevo',
    'Parvomay',
    'Provadia',
    'Pristoe',
    'Prisovo',
    'Primorsko',
    'Preslav',
    'Pravo Bardo',
    'Pravets',
    'Portitovtsi',
    'Popovo',
    'Pomorie',
    'Polski Trambesh',
    'Polkovnik Lambrinovo',
    'Polikrayshte',
    'Plovdiv',
    'Pleven',
    'Pirdop',
    'Petrich',
    'Peshtera',
    'Pernik',
    'Pazardzhik',
    'Pavlikeni',
    'Pavel Banya',
    'Pashintsi',
    'Panagyurishte',
    'Rahova',
    'Opaka',
    'Omurtag',
    'Odarne',
    'Obzor',
    'Oborishte',
    'Novo Selo',
    'Novi Pazar',
    'Novi Han',
    'Novi Iskar',
    'Nova Zagora',
    'Nikolovo',
    'Nesebar',
    'Nedelino',
    'Nane',
    'Muselievo',
    'Maglizh',
    'Momchilgrad',
    'Mirkovo',
    'Montana',
    'Tsarevo',
    'Mezdra',
    'Merdanya',
    'Marikostinovo',
    'Malko Tarnovo',
    'Madzharovo',
    'Madan',
    'Lyulin',
    'Lyubimets',
    'Lyaskovets',
    'Lukovit',
    'Lozenets',
    'Lozen',
    'Lovech',
    'Lom',
    'Levski',
    'Toros',
    'Kyustendil',
    'Kardzhali',
    'Kula',
    'Kuklen',
    'Kubrat',
    'Krumovgrad',
    'Krivodol',
    'Krichim',
    'Kresna',
    'Kozloduy',
    'Koynare',
    'Kostinbrod',
    'Kostenets',
    'Koprivlen',
    'Knezha',
    'Klisura',
    'Kliment',
    'Kiten',
    'Kirkovo',
    'Kipra',
    'Kilifarevo',
    'Hristo-Danovo',
    'Hisarya',
    'Haskovo',
    'Harmanli',
    'Kazanlak',
    'Kavarna',
    'Katuntsi',
    'Katunitsa',
    'Kaspichan',
    'Karnobat',
    'Karlovo',
    'Kameno',
    'Kaloyanovo',
    'Kalofer',
    'Kalekovets',
    'Kabile',
    'Ivaylovgrad',
    'Ivanovo',
    'Isperih',
    'Iskra',
    'Ihtiman',
    'Gurkovo',
    'Gulubovo',
    'Sredets',
    'Grivka',
    'Gotse Delchev',
    'Gorna Oryahovitsa',
    'Godech',
    'Glozhene',
    'Dolni Chiflik',
    'General Toshevo',
    'Gara Elin Pelin',
    'Galata',
    'Gabrovo',
    'Etropole',
    'Elhovo',
    'Elin Pelin',
    'Elenska',
    'Elena',
    'Dzhebel',
    'Dyankovo',
    'Dve Mogili',
    'Dushintsi',
    'Durankulak',
    'Dulovo',
    'Dalgo Pole',
    'Dalgopol',
    'Dalboki',
    'Dryanovo',
    'Dragoman',
    'Dragoevo',
    'Draginovo',
    'Dospat',
    'Domlyan',
    'Dolno Osenovo',
    'Dolni Dabnik',
    'Dolna Banya',
    'Dimitrovgrad',
    'Devnya',
    'Devin',
    'Dekov',
    'Debelets',
    'Chirpan',
    'Chiprovtsi',
    'Cherven Bryag',
    'Cherven',
    'Chernomorets',
    'Cherniche',
    'Chepintsi',
    'Chepelare',
    'Chavdar',
    'Byala Slatina',
    'Byala',
    'Barkachevo',
    'Burgas',
    'Bardarski Geran',
    'Buhovo',
    'Brusartsi',
    'Breznitsa',
    'Brenitsa',
    'Bozhurishte',
    'Boychinovtsi',
    'Bov',
    'Botevgrad',
    'Borovan',
    'Borima',
    'Stambolovo',
    'Bobov Dol',
    'Blagoevgrad',
    'Berkovitsa Municipality',
    'Belovo',
    'Belogradchik',
    'Belene',
    'Belasitsa',
    'Batak',
    'Bansko',
    'Bankya',
    'Baltchik',
    'Balanite',
    'Aytos',
    'Asenovgrad',
    'Ardino',
    'Antonovo',
    'Alfatar',
    'Aksakovo Municipality',
    'Ahtopol',
    'Ablanitsa',
    'Golden Sands',
    'Sunny Beach',
    'Aheloy',
  ],
  Poland: [
    'Zyrzyn',
    'Żyrardów',
    'Zyrakow',
    'Zwolen',
    'Zurawica',
    'Nowe Zukowice',
    'Zrecin',
    'Zolynia',
    'Zolwin',
    'Zolkiewka',
    'Znamirowice',
    'Gmina Żmudź',
    'Złotokłos',
    'Zlotniki',
    'Gmina Złota',
    'Zielonka',
    'Zglobice',
    'Zelechow',
    'Zegrze',
    'Zegocina',
    'Zdziarzec',
    'Zbuczyn',
    'Zawichost',
    'Zarzecze',
    'Zaruzie',
    'Zarszyn',
    'Zarnow',
    'Zamość',
    'Zamienie',
    'Zambrów',
    'Zaluski',
    'Zalubice Stare',
    'Zaleszany',
    'Zalesie Górne',
    'Zakrzowek',
    'Zakrzew',
    'Zakrzewska Wola',
    'Zakret',
    'Zaklikow',
    'Zakliczyn',
    'Gmina Zakliczyn',
    'Zahutyn',
    'Zagosciniec',
    'Zagorzany',
    'Zagorz',
    'Zagnansk',
    'Zabraniec',
    'Zaborow',
    'Zabno',
    'Zabłudów',
    'Ząbki',
    'Zabierzow Bochenski',
    'Gmina Żabia Wola',
    'Wyszków',
    'Wysokie Mazowieckie',
    'Wysoka Strzyzowska',
    'Wygoda',
    'Wygledy',
    'Wrocanka',
    'Woszczele',
    'Wołomin',
    'Wolka Tanewska',
    'Wolka Lysowska',
    'Wolica',
    'Wola Zycka',
    'Wola Zarczycka',
    'Wola Rzedzinska',
    'Wola Rzeczycka',
    'Wola Rebkowska',
    'Wola Ranizowska',
    'Wolanow',
    'Wola Mrokowska',
    'Wola Grulowska',
    'Wola Ducka',
    'Wola Batorska',
    'Wola Baranowska',
    'Wola',
    'Wokowice',
    'Wojtowo',
    'Wojslawice',
    'Wojnicz',
    'Wojaszowka',
    'Wohyn',
    'Wlodawa',
    'Wizna',
    'Wisniowa',
    'Wisniew',
    'Wiskitki',
    'Winnica',
    'Wilkolaz',
    'Wilga',
    'Wilczyce',
    'Wilcza Wola',
    'Wietrzychowice',
    'Wierzchoslawice',
    'Wierzbica',
    'Wieniawa',
    'Wielopole Skrzynskie',
    'Wielopole',
    'Wieloglowy',
    'Wieliszew',
    'Wieliczka',
    'Wiazownica',
    'Wiazowna',
    'Werbkowice',
    'Werachanie',
    'Wegrzce Wielkie',
    'Węgrów',
    'Węgorzewo',
    'Wawrzenczyce',
    'Wawal',
    'Wasosz',
    'Wasniow',
    'Wasilkow',
    'Warsaw',
    'Warka',
    'Waleriany',
    'Waksmund',
    'Wadowice Gorne',
    'Wachock',
    'Uszew',
    'Ustrzyki Dolne',
    'Uscimow Stary',
    'Urzut',
    'Urzedow',
    'Urszulin',
    'Ubieszyn',
    'Tymbark',
    'Tykocin',
    'Tyczyn',
    'Tuszow Narodowy',
    'Turowiec',
    'Turosl',
    'Turobin',
    'Turka',
    'Turbia',
    'Tuczna',
    'Tuchow',
    'Trzydnik Duzy',
    'Trzemesnia',
    'Trzebownisko',
    'Trzebos',
    'Trzebieszow',
    'Trzcianne',
    'Trzcianiec',
    'Tryncza',
    'Truskaw',
    'Tromity',
    'Trawniki',
    'Tomaszów Mazowiecki',
    'Tomaszow Lubelski',
    'Tomaszkowo',
    'Tłuszcz',
    'Tereszpol',
    'Terespol',
    'Teresin',
    'Telatyn',
    'Tarnowiec',
    'Tarnów',
    'Tarnogrod',
    'Tarnobrzeg',
    'Tarnawa Dolna',
    'Tarlo',
    'Tarczyn',
    'Taraskowo',
    'Szynwald',
    'Szymbark',
    'Szymanow',
    'Szydlowiec',
    'Szumowo',
    'Sztabin',
    'Szowsko',
    'Szlachtowa',
    'Szkodna',
    'Szewna',
    'Szerzyny',
    'Szepietowo',
    'Szebnie',
    'Szczytno',
    'Szczyrzyc',
    'Szczurowa',
    'Szczucin',
    'Szczebrzeszyn',
    'Szczawnica',
    'Szastarka',
    'Szaflary',
    'Sygneczow',
    'Swilcza',
    'Swieta Katarzyna',
    'Świętajno',
    'Swidnik',
    'Suwałki',
    'Susiec',
    'Supraśl',
    'Sulejowek',
    'Suchożebry',
    'Suchowola',
    'Suchodol',
    'Suchedniow',
    'Sucha Nowa',
    'Stubno',
    'Strzyzow',
    'Strzeniowka',
    'Strzegowo',
    'Strzegocice',
    'Stromiec',
    'Strazow',
    'Strawczyn',
    'Strachówka',
    'Stopnica',
    'Stoczek Łukowski',
    'Stoczek',
    'Stefkowa',
    'Stawiski',
    'Stawiguda',
    'Staszow',
    'Stary Sacz',
    'Stare Babice',
    'Starachowice',
    'Gmina Stąporków',
    'Stanin',
    'Stalowa Wola',
    'Stadla',
    'Sromowce Wyzne',
    'Srokowo',
    'Spychowo',
    'Sporniak',
    'Solonka',
    'Sokoły',
    'Sokołów Podlaski',
    'Sokołów Małopolski',
    'Sokółka',
    'Sochonie',
    'Sochocin',
    'Sochaczew',
    'Sobolew',
    'Smugi',
    'Slupno',
    'Slomniki',
    'Slomczyn',
    'Slepowron',
    'Slawiny',
    'Slabomierz',
    'Skwarne',
    'Skrzyszow',
    'Skrzydlna',
    'Skopanie',
    'Skolyszyn',
    'Skloby',
    'Skierniewice',
    'Skarzysko-Kamienna',
    'Skaryszew',
    'Skalbmierz',
    'Sitno',
    'Osiedle-Nowiny',
    'Sietesz',
    'Sieradza',
    'Sieprawice',
    'Siennica',
    'Sieniawa',
    'Siemiatycze',
    'Siekierczyna',
    'Siedlce',
    'Siedlanka',
    'Serokomla',
    'Serock',
    'Serniki',
    'Sekowa',
    'Sejny',
    'Sedziszow Malopolski',
    'Sedziszow',
    'Sarnaki',
    'Sanok',
    'Sandomierz',
    'Samborzec',
    'Gmina Sadowne',
    'Sadowa',
    'Gmina Sadkowice',
    'Sabnie',
    'Rzuchowa',
    'Rzezawa',
    'Rzewnie',
    'Rzeszów',
    'Rzepedz',
    'Rzekuń',
    'Rzasnik',
    'Rytro',
    'Ryn',
    'Rymanow',
    'Rykoszyn',
    'Ryki',
    'Ryglice',
    'Rybno',
    'Rybie',
    'Rutki',
    'Rutka Tartak',
    'Rusiec',
    'Rudno',
    'Rudnik nad Sanem',
    'Ruda Wielka',
    'Ruda',
    'Ruciane-Nida',
    'Rozwienica',
    'Roznowice',
    'Roznow',
    'Siennica Rozana',
    'Rozan',
    'Rowne',
    'Ropczyce',
    'Ropa',
    'Rokietnica',
    'Reszel',
    'Rejowiec Fabryczny',
    'Rejowiec',
    'Regut',
    'Reguly',
    'Rawa Mazowiecka',
    'Raszyn',
    'Rasztow',
    'Ranizow',
    'Rakszawa',
    'Rakowo',
    'Rakowiska',
    'Rajszew',
    'Rajbrot',
    'Radzyn Podlaski',
    'Radzymin',
    'Radziejowice',
    'Radymno',
    'Radoszyce',
    'Radomysl Wielki',
    'Radomysl',
    'Radom',
    'Radlow',
    'Raczki',
    'Raciechowice',
    'Raciaz',
    'Pysznica',
    'Gmina Puszcza Mariańska',
    'Pustkow',
    'Puńsk',
    'Pułtusk',
    'Puławy',
    'Przytyk',
    'Przyszowa',
    'Przysucha',
    'Przysietnica',
    'Przylek',
    'Przewrotne',
    'Przeworsk',
    'Przesmyki',
    'Przemysl',
    'Przeclaw',
    'Przasnysz',
    'Pruszków',
    'Proszowice',
    'Prostki',
    'Promnik',
    'Promna',
    'Procwin',
    'Pralkowce',
    'Potok Gorny',
    'Potok',
    'Poswietne',
    'Poronin',
    'Poręba',
    'Porabka Uszewska',
    'Poniatowa',
    'Pomiechowek',
    'Gmina Policzna',
    'Gmina Połaniec',
    'Polanczyk',
    'Pogwizdow',
    'Pogorska Wola',
    'Podniebyle',
    'Podleze',
    'Podlesie',
    'Podkowa Leśna',
    'Podegrodzie',
    'Płośnica',
    'Płońsk',
    'Plochocin',
    'Plesna',
    'Plaska',
    'Piwniczna-Zdroj',
    'Piszczac',
    'Pisz',
    'Pisarowce',
    'Pisanica',
    'Piotrkowice Male',
    'Piotrkow',
    'Pionki',
    'Pinczow',
    'Pilzno',
    'Pilawa',
    'Pierzchnica',
    'Piekoszow',
    'Piecki',
    'Piatnica',
    'Piastow',
    'Piaski',
    'Piaseczno',
    'Pecice',
    'Gmina Pawłów',
    'Pawlokoma',
    'Pasym',
    'Parysow',
    'Parczew',
    'Palmiry',
    'Palecznica',
    'Padew Narodowa',
    'Pacanow',
    'Ozarow Mazowiecki',
    'Ozarow',
    'Otwock',
    'Otrebusy',
    'Ostrow Mazowiecka',
    'Ostrow Lubelski',
    'Ostrowiec Świętokrzyski',
    'Ostrow',
    'Ostrołęka',
    'Osowiec',
    'Osiek Jasielski',
    'Gmina Osiek',
    'Osieck',
    'Orzysz',
    'Orneta',
    'Orly',
    'Gmina Opole Lubelskie',
    'Opoczno',
    'Opinogora',
    'Opatow',
    'Opaleniec',
    'Olszyny',
    'Olsztynek',
    'Olsztyn',
    'Olszewo Borki',
    'Olpiny',
    'Olimpow',
    'Oleszyce',
    'Olesno',
    'Olesnica',
    'Olesin',
    'Olecko',
    'Okuniew',
    'Okol',
    'Ojrzen',
    'Odrzywol',
    'Odporyszow',
    'Ochotnica Gorna',
    'Ochotnica Dolna',
    'Ochota',
    'Obryte',
    'Obrazow',
    'Nowy Wisnicz',
    'Nowy Targ',
    'Nowy Sącz',
    'Nowy Korczyn',
    'Nowy Dwor Mazowiecki',
    'Nowosielce-Gniewosz',
    'Nowogród',
    'Nowodworce',
    'Nowodwor',
    'Nowiny',
    'Nowe Miasto nad Pilica',
    'Nowa Wola',
    'Nowa Wies Elcka',
    'Nowa Slupia',
    'Nowa Sarzyna',
    'Nowa Deba',
    'Nosy Poniatki',
    'Nisko',
    'Niewodnica Korycka',
    'Niestachow',
    'Nieradowo',
    'Nieporęt',
    'Niepolomice',
    'Niemce',
    'Niedzwiada Duza',
    'Niedzwiada',
    'Niedzica',
    'Niedrzwica Duza',
    'Niedomice',
    'Niebylec',
    'Nieborow',
    'Nidzica',
    'Nawojowa',
    'Natalin',
    'Nasutow',
    'Nasielsk',
    'Gmina Narol',
    'Naleczow',
    'Nagoszyn',
    'Nadma',
    'Nadarzyn',
    'Nacpolsk',
    'Mystkow',
    'Myslec',
    'Mysiadlo',
    'Muszyna',
    'Muszaki',
    'Munina',
    'Mszczonów',
    'Mszana Gorna',
    'Mszana Dolna',
    'Mrozy',
    'Mrowla',
    'Mragowo',
    'Motycz',
    'Motwica',
    'Moszczenica',
    'Mosciska',
    'Mońki',
    'Mokrzyska',
    'Mokobody',
    'Mogielnica',
    'Mniszew',
    'Mniow',
    'Mlynarze',
    'Mława',
    'Mirzec',
    'Mirow Stary',
    'Mircze',
    'Mińsk Mazowiecki',
    'Milowka',
    'Milejow',
    'Milanówek',
    'Milanow',
    'Miłakowo',
    'Mielnik',
    'Mielec',
    'Miejsce Piastowe',
    'Międzyrzec Podlaski',
    'Miedzyborow',
    'Miedzna',
    'Miedziana Gora',
    'Miechow',
    'Michow',
    'Miastkow Koscielny',
    'Melgiew',
    'Medynia Glogowska',
    'Medyka',
    'Medrzechow',
    'Mecina',
    'Maslow',
    'Markuszow',
    'Markowa',
    'Marki',
    'Maniowy',
    'Manasterz',
    'Malogoszcz',
    'Malkinia Gorna',
    'Malaszewicze',
    'Makow Mazowiecki',
    'Makowiska',
    'Makow',
    'Majdan Krolewski',
    'Magnuszew',
    'Maciejowice',
    'Machocice',
    'Luszowice Gorne',
    'Lupstych',
    'Łukta',
    'Lukow',
    'Lubzina',
    'Lubycza Krolewska',
    'Luborzyca',
    'Lubochnia',
    'Lubnice',
    'Lublin',
    'Lubinka',
    'Lubenia',
    'Lubcza',
    'Lubartow',
    'Lubaczow',
    'Lowcza',
    'Lowce',
    'Lososina Dolna',
    'Łosice',
    'Lopuszno',
    'Loniow',
    'Łomża',
    'Lomianki',
    'Lomazy',
    'Lochow',
    'Lisia Gora',
    'Lipsko',
    'Lipsk',
    'Lipowiec Koscielny',
    'Lipnik',
    'Lipie',
    'Limanowa',
    'Lidzbark Warmiński',
    'Libusza',
    'Lezajsk',
    'Lesznowola',
    'Leszno',
    'Lesko',
    'Leoncin',
    'Leki Dolne',
    'Lekawica',
    'Legionowo',
    'Leczna',
    'Łazy',
    'Lawy',
    'Latowicz',
    'Laszczow',
    'Łapy',
    'Lapanow',
    'Lancut',
    'Laka',
    'Ladna',
    'Laczna',
    'Kurylowka',
    'Kurów',
    'Kupiski Stare',
    'Ksiaz Wielki',
    'Krzywda',
    'Krzywcza',
    'Krzynowloga Mala',
    'Krypno',
    'Krynki',
    'Krynice',
    'Krynica-Zdroj',
    'Kruzlowa Wyzna',
    'Krosno',
    'Krolowka',
    'Krolewo',
    'Kreczki',
    'Krasocin',
    'Krasnystaw',
    'Krasnosielc',
    'Krasnopol',
    'Krasnobrod',
    'Krasnik',
    'Krasniczyn',
    'Krasne',
    'Kraczkowa',
    'Kozlow Biskupi',
    'Kozienin',
    'Kozienice',
    'Kowale Oleckie',
    'Kowala',
    'Kotuń',
    'Koszyce',
    'Kosina',
    'Korzenna',
    'Korytow',
    'Korytnica',
    'Korycin',
    'Korsze',
    'Kornica',
    'Korczyna',
    'Kopytowa',
    'Koprzywnica',
    'Kopiec',
    'Konstantynów',
    'Konstancin-Jeziorna',
    'Końskowola',
    'Gmina Końskie',
    'Konopki',
    'Konina',
    'Komorow',
    'Kombornia',
    'Kolonia Kornica',
    'Kolonia Ignatki',
    'Kolno',
    'Kolbuszowa',
    'Kołbiel',
    'Kolaki',
    'Kolaczyce',
    'Kokotow',
    'Koden',
    'Kocmyrzow',
    'Kock',
    'Kocina',
    'Kobylnica',
    'Kobylka',
    'Knyszyn',
    'Klukowo',
    'Kloczew',
    'Klimontow',
    'Klikawa',
    'Kleszczowka',
    'Kleszczele',
    'Klementowice',
    'Klaudyn',
    'Klaj',
    'Kisielowka',
    'Kiezliny',
    'Kielnarowa',
    'Kielce',
    'Kicznia',
    'Kętrzyn',
    'Kepie Zaleszanskie',
    'Kedzierz',
    'Kazimierz Dolny',
    'Kazimierza Wielka',
    'Kaszyce',
    'Kasinka',
    'Kasina Wielka',
    'Karszewo',
    'Karczmiska',
    'Karczew',
    'Karakule',
    'Kanie',
    'Kanczuga',
    'Kamionka Wielka',
    'Kamianka',
    'Kamionka',
    'Kamien',
    'Kałuszyn',
    'Kalisz',
    'Kaleczyn',
    'Kakolewnica Wschodnia',
    'Kadzidło',
    'Jurkow',
    'Julianow',
    'Juchnowiec Koscielny',
    'Juchnowiec Dolny',
    'Jozefow',
    'Jozefoslaw',
    'Jozefin',
    'Jodlowa',
    'Jezowe',
    'Jeziorany',
    'Jedwabno',
    'Jedwabne',
    'Gmina Jędrzejów',
    'Jednorożec',
    'Jedlnia-Letnisko',
    'Gmina Jedlińsk',
    'Jedlicze',
    'Jedlanka',
    'Jazowsko',
    'Jaworki',
    'Jaszczew',
    'Jastrzebia',
    'Jastrzab',
    'Jastkowice',
    'Jaslo',
    'Jasionka',
    'Jasienica Rosielna',
    'Jasienica',
    'Jarosław',
    'Jarocin',
    'Janow Lubelski',
    'Janowice',
    'Janow',
    'Janik',
    'Janczowa',
    'Jaktorów',
    'Jadowniki Mokre',
    'Jadowniki',
    'Jadów',
    'Jachranka',
    'Jablonna Lacka',
    'Jablonna',
    'Izdebki',
    'Izbica',
    'Iwonicz-Zdroj',
    'Iwonicz',
    'Iwkowa',
    'Ilza',
    'Igolomia',
    'Huta Dabrowa',
    'Hurko',
    'Hucisko',
    'Hucina',
    'Hrubieszów',
    'Hozna',
    'Horodlo',
    'Hornowek',
    'Hoczew',
    'Helenow',
    'Harasiuki',
    'Handzlowka',
    'Halinow',
    'Hajnówka',
    'Gzy',
    'Guzów',
    'Gutkowo',
    'Grywald',
    'Grybow',
    'Gruszow',
    'Grudusk',
    'Grudki',
    'Gron',
    'Gromnik',
    'Grojec',
    'Grodzisko Dolne',
    'Grodzisk Mazowiecki',
    'Grodziec',
    'Grodek Nad Dunajcem',
    'Grodek',
    'Grebow',
    'Grębków',
    'Grajow',
    'Grajewo',
    'Gradzanowo',
    'Grabow nad Pilica',
    'Grabowka',
    'Grabiny',
    'Gozd',
    'Goworowo',
    'Gowarczow',
    'Goszczyn',
    'Goscieradow',
    'Gorzyce',
    'Gorowo Ilaweckie',
    'Gorno',
    'Gorlice',
    'Gora Kalwaria',
    'Goraj',
    'Golkowice',
    'Gołdap',
    'Golcowa',
    'Golaszyn',
    'Godzianow',
    'Gniewoszow',
    'Gniewczyna',
    'Glupczow',
    'Glowienka',
    'Glowaczow',
    'Gloskow',
    'Glogow Malopolski',
    'Glina',
    'Giżycko',
    'Gietrzwald',
    'Giby',
    'Gasiory',
    'Garwolin',
    'Garbow',
    'Garbatka-Letnisko',
    'Gac',
    'Gabon',
    'Frysztak',
    'Fredropol',
    'Frampol',
    'Florczaki',
    'Firlej',
    'Fasty',
    'Faliszowka',
    'Falenty',
    'Ełk',
    'Dzwola',
    'Dzwierzuty',
    'Dziurow',
    'Dzikow Stary',
    'Dzikowiec',
    'Dziewin',
    'Dziekanow Polski',
    'Dziekanow Lesny',
    'Dzialoszyce',
    'Działdowo',
    'Dywity',
    'Dys',
    'Dynow',
    'Dydnia',
    'Dybowo',
    'Durdy',
    'Dulcza Wielka',
    'Dukla',
    'Duchnow',
    'Dubienka',
    'Dubiecko',
    'Drwinia',
    'Drohiczyn',
    'Dominow',
    'Dolhobyczow',
    'Dolhobrody',
    'Dobrzyniewo Duze',
    'Dobrzechow',
    'Dobre Miasto',
    'Dobczyce',
    'Długosiodło',
    'Dluga Szlachecka',
    'Debowa Kloda',
    'Debow',
    'Deblin',
    'Debina',
    'Debica',
    'Debe Wielkie',
    'Deba',
    'Dawidy',
    'Daleszyce',
    'Dabrowka Tuchowska',
    'Dąbrowa Tarnowska',
    'Dabrowa Chotomowska',
    'Dabrowa Bialostocka',
    'Dabrowa',
    'Czyżew-Osada',
    'Czuszow',
    'Czudec',
    'Czosnow',
    'Czerwinsk Nad Wisla',
    'Czerniecin',
    'Czernice Borowe',
    'Czermno',
    'Czermin',
    'Czemierniki',
    'Czchow',
    'Czarny Kierz',
    'Czarna Białostocka',
    'Czarna',
    'Cycow',
    'Cmolas',
    'Cimochy',
    'Ciezkowice',
    'Cieszanow',
    'Cieklin',
    'Cieciwa',
    'Ciecierzyn',
    'Gmina Ciechanowiec',
    'Ciechanów',
    'Cichostow',
    'Chylice',
    'Chotomow',
    'Chorzelow',
    'Chorupnik',
    'Choroszcz',
    'Chorkowka',
    'Chodel',
    'Chmielnik',
    'Chlewiska',
    'Chełm',
    'Checiny',
    'Celinow',
    'Celestynów',
    'Cegłów',
    'Bystra',
    'Bychawa',
    'Buzyska',
    'Busko-Zdrój',
    'Burkat',
    'Bukwald',
    'Bukowsko',
    'Budy Niemianowskie',
    'Budy Barczackie',
    'Budry',
    'Budki',
    'Brzyska Wola',
    'Brzozow',
    'Brzostek',
    'Brzeznica Bychawska',
    'Brzeznica',
    'Brzezie',
    'Brzezanka',
    'Brzesko',
    'Brzegi',
    'Gmina Brwinów',
    'Brodacze',
    'Brnik',
    'Bratkowice',
    'Branszczyk',
    'Brańsk',
    'Branice',
    'Borzecin Duzy',
    'Borowie',
    'Borowa Gora',
    'Borki',
    'Bolimów',
    'Boleslaw',
    'Bolechowice',
    'Bojanow',
    'Boiska',
    'Boguszewo',
    'Boguchwala',
    'Bodzentyn',
    'Bodzanów',
    'Boduszow',
    'Boćki',
    'Bochnia',
    'Bobrza',
    'Bobrowiec',
    'Bobrek',
    'Bobowa',
    'Błonie',
    'Blizne',
    'Blazowa',
    'Bisztynek',
    'Biszcza',
    'Biskupiec',
    'Biskupice',
    'Bircza',
    'Biłgoraj',
    'Bikowek',
    'Bieniewice',
    'Bieniasze',
    'Bielsk Podlaski',
    'Bielin',
    'Bielawa',
    'Bieganow',
    'Biecz',
    'Biczyce Dolne',
    'Białystok',
    'Bialy Dunajec',
    'Bialuty',
    'Bialusny Lasek',
    'Bialowieza',
    'Bialobrzegi',
    'Bialka Tatrzanska',
    'Biała Rawska',
    'Biała Podlaska',
    'Biała Piska',
    'Bialaczow',
    'Besko',
    'Bemowo Piskie',
    'Belzyce',
    'Belsk Duzy',
    'Begno',
    'Bedziemysl',
    'Bartoszyce',
    'Bartkow Nowy',
    'Barszczewo',
    'Bargłów Kościelny',
    'Barczewo',
    'Barczewko',
    'Barcice',
    'Baranowo',
    'Baniocha',
    'Balupiany',
    'Baltow',
    'Bakow',
    'Gmina Bakałarzewo',
    'Baczyna',
    'Bachorz',
    'Babino',
    'Augustów',
    'Augustow',
    'Arcelin',
    'Antoninow',
    'Antonin',
    'Annopol',
    'Annobor',
    'Andrzejow',
    'Alojzow',
    'Aleksandrowo',
    'Aleksandrow',
    'Adamowka',
    'Gmina Adamów',
    'Adamow',
    'Abramow',
    'Żywiec',
    'Zydowo',
    'Żychlin',
    'Zychlin',
    'Żuromin',
    'Zulawa',
    'Zukowo',
    'Zubrzyca Gorna',
    'Żory',
    'Zorawina',
    'Znin',
    'Zmigrod',
    'Zloty Stok',
    'Zlotow',
    'Złotoryja',
    'Złotoria',
    'Zlotkowo',
    'Zloczew',
    'Zlocieniec',
    'Zlochowice',
    'Gmina Zławieś Wielka',
    'Zielona Góra',
    'Zielona Gora',
    'Zieleniewo',
    'Zielecice',
    'Ziebice',
    'Zgorzelec',
    'Zgierz',
    'Zerniki Wroclawskie',
    'Zernica',
    'Zerkow',
    'Zendek',
    'Zembrzyce',
    'Zelow',
    'Zelkow',
    'Zelice',
    'Zegrze Pomorskie',
    'Zedowice',
    'Zederman',
    'Zebrzydowice',
    'Zebowice',
    'Zdzieszowice',
    'Zduny',
    'Zdunska Wola',
    'Zdow',
    'Zbrudzewo',
    'Zbroslawice',
    'Zbójno',
    'Zblewo',
    'Zbiersk',
    'Zbiczno',
    'Zbaszynek',
    'Zbaszyn',
    'Zawoja',
    'Zawiercie',
    'Zawidz',
    'Zawidow',
    'Zawadzkie',
    'Zawada',
    'Zator',
    'Zaskale',
    'Zary',
    'Zarow',
    'Zarnowiec',
    'Zarki',
    'Zaniemysl',
    'Zaleze',
    'Zalewo',
    'Zalasewo',
    'Zakrzewo',
    'Zakopane',
    'Zagorze',
    'Żagań',
    'Zadzim',
    'Zaduszniki',
    'Zabrze',
    'Zabnica',
    'Ząbkowice Śląskie',
    'Zabinko',
    'Gmina Zabierzów',
    'Wyszanowo',
    'Wysoka Kamienska',
    'Wysoka',
    'Wysogotowo',
    'Wyrzysk',
    'Wyrzeka',
    'Wykroty',
    'Wygoda Tokarska',
    'Wschowa',
    'Wrzosowa',
    'Wrzesnia',
    'Wrzeczko',
    'Wronowy',
    'Wronki',
    'Wrocław',
    'Wroblowice',
    'Wroblew',
    'Wreczyca Wielka',
    'Wreczyca Mala',
    'Wolsztyn',
    'Wolowice',
    'Wolow',
    'Wolczyn',
    'Wolczkowo',
    'Wolbrom',
    'Wolborz',
    'Wola Zaradzynska',
    'Wola Wiazowa',
    'Wola Krzysztoporska',
    'Wola Hankowska',
    'Wola Filipowska',
    'Wojnowice',
    'Wojkowice Koscielne',
    'Wojkowice',
    'Wojcieszow',
    'Wodzisław Śląski',
    'Wodzierady',
    'Wloszczowa',
    'Wlosan',
    'Wlodzimierzow',
    'Włocławek',
    'Władysławowo',
    'Gmina Władysławów',
    'Witow',
    'Witkowo',
    'Wislina',
    'Wisla Wielka',
    'Wisla',
    'Wiselka',
    'Wiry',
    'Winowno',
    'Wilkowice',
    'Wilkow',
    'Wilczyn',
    'Wilczkow',
    'Wilcza',
    'Wilamowice',
    'Wijewo',
    'Wieszowa',
    'Wierzchucino',
    'Wierzchowo',
    'Wierzchowie',
    'Wieruszow',
    'Wieluń',
    'Wielowies',
    'Wielki Klincz',
    'Wielkie Drogi',
    'Wielichowo',
    'Wielgie',
    'Wielen Zaobrzanski',
    'Wielen',
    'Wielawino',
    'Wieckowice',
    'Wiecbork',
    'Widuchowa',
    'Gmina Widawa',
    'Gmina Wicko',
    'Wiazow',
    'Welnica',
    'Wejherowo',
    'Wegrzce',
    'Wegorzyno',
    'Wegorza',
    'Wegliniec',
    'Wegierska Gorka',
    'Wawrowice',
    'Warzymice',
    'Wartkowice',
    'Warta Boleslawiecka',
    'Warszowice',
    'Warlubie',
    'Wapno',
    'Wapnica',
    'Wapiennik',
    'Wapielsk',
    'Walcz',
    'Walce',
    'Wałbrzych',
    'Wagrowiec',
    'Waganiec',
    'Wadowice',
    'Wabrzezno',
    'Ustronie Morskie',
    'Ustron',
    'Ustowo',
    'Ustka',
    'Unisław',
    'Uniescie',
    'Uniejów',
    'Ujsoly',
    'Ujscie',
    'Ujazd',
    'Uchylsko',
    'Tyniec Maly',
    'Tymien',
    'Tychy',
    'Tychowo',
    'Tworog',
    'Twardogora',
    'Turze',
    'Turzak',
    'Turek',
    'Turawa',
    'Tulowice',
    'Tuliszków',
    'Tulce',
    'Tuczno',
    'Tuchorza',
    'Tuchom',
    'Tuchola',
    'Trzyciaz',
    'Trzemzal',
    'Trzemeszno',
    'Trzebunia',
    'Trzebnica',
    'Trzebinia',
    'Trzebicz',
    'Trzebiatów',
    'Trzcinica',
    'Trzciel',
    'Trzcianka',
    'Truskolasy',
    'Trojanowice',
    'Trebaczew',
    'Trabki Wielkie',
    'Toszek',
    'Torzym',
    'Toruń',
    'Toporowice',
    'Tomaszowice',
    'Tolkmicko',
    'Tokary',
    'Tluchowo',
    'Tetyn',
    'Tenczynek',
    'Tczew',
    'Tarnowskie Gory',
    'Tarnow Opolski',
    'Tarnowo Podgorne',
    'Targanice',
    'Tanowo',
    'Szynczyce',
    'Szymanowice',
    'Szydlowo',
    'Gmina Szubin',
    'Sztum',
    'Szreniawa',
    'Szprotawa',
    'Szklarska Poręba',
    'Szemud',
    'Szczytna',
    'Szczyrk',
    'Szczyglice',
    'Szczutowo',
    'Szczercow',
    'Szczekociny',
    'Szczecinek',
    'Szczecin',
    'Szczawno-Zdroj',
    'Szczaniec',
    'Szamotuły',
    'Szamocin',
    'Szadow Ksiezy',
    'Szadlowice',
    'Szadek',
    'Syrynia',
    'Sycow',
    'Swornegacie',
    'Świnoujście',
    'Swinna',
    'Swietoszowka',
    'Swietoszow',
    'Świętochłowice',
    'Swieszyno',
    'Swierzno',
    'Swierzawa',
    'Swierklany',
    'Swierklaniec',
    'Swieradow-Zdroj',
    'Swiekatowo',
    'Gmina Świebodzin',
    'Swiecie nad Osa',
    'Swieciechowa',
    'Gmina Świecie',
    'Swiebodzice',
    'Swidwin',
    'Swidnica',
    'Swibie',
    'Swiatniki Gorne',
    'Swarzedz',
    'Swarozyn',
    'Swadzim',
    'Suszec',
    'Susz',
    'Sumina',
    'Sumin',
    'Sulow',
    'Suloszowa',
    'Sulkowice',
    'Suliszowice',
    'Borne Sulinowo',
    'Sulejow',
    'Sulecinek',
    'Sulecin',
    'Sulechow',
    'Sukowy',
    'Suchy Las',
    'Suchy Bor',
    'Sucha Beskidzka',
    'Studzionka',
    'Studzienki',
    'Studzienice',
    'Strzelno',
    'Gmina Strzelin',
    'Strzelce Wielkie',
    'Strzelce Opolskie',
    'Strzelce Krajenskie',
    'Strzelce',
    'Strzegom',
    'Strzebielino',
    'Strzalkowo',
    'Stryszow',
    'Strykowo',
    'Strykow',
    'Strumien',
    'Stroza',
    'Strojec',
    'Strobin',
    'Straszyn',
    'Stradunia',
    'Stobno',
    'Stoblow',
    'Gmina Stężyca',
    'Steszew',
    'Stepnica',
    'Stegna',
    'Stefanow',
    'Stary Torun',
    'Stary Targ',
    'Stary Kisielin',
    'Starowa Gora',
    'Starogard Gdański',
    'Stargard',
    'Stare Pole',
    'Stare Miasto',
    'Stare Bogaczowice',
    'Stare Bielice',
    'Starcza',
    'Stara Kiszewa',
    'Stara Kamienica',
    'Stara Biala',
    'Stanowice',
    'Sroda Wielkopolska',
    'Środa Śląska',
    'Gmina Śrem',
    'Srebrna Gora',
    'Spytkowice',
    'Spalona',
    'Sowno',
    'Sowinki',
    'Sosnowiec',
    'Sosnowice',
    'Sosno',
    'Sosnie',
    'Sosnicowice',
    'Sopot',
    'Sopieszyno',
    'Sompolno',
    'Solec Kujawski',
    'Sokolniki',
    'Gmina Sokolniki',
    'Sobotka',
    'Smolnica',
    'Smolec',
    'Smoldzino',
    'Smogorzow',
    'Smigiel',
    'Smicz',
    'Smialowice',
    'Smardze',
    'Slupy',
    'Słupsk',
    'Słupno',
    'Slupia',
    'Slupca',
    'Słubice',
    'Slonsk',
    'Sliwniki',
    'Sleszowice Dolne',
    'Ślesin',
    'Sławno',
    'Slawkow',
    'Slawa',
    'Skwierzyna',
    'Skulsk',
    'Skrzypne',
    'Skrzynki',
    'Skrzydlow',
    'Skrwilno',
    'Skrobotowo',
    'Skowarcz',
    'Skorzewo',
    'Skoroszyce',
    'Skoroszow',
    'Skorogoszcz',
    'Skomlin',
    'Skoki',
    'Skoczow',
    'Skidzin',
    'Skępe',
    'Skawina',
    'Skarszewy',
    'Skalagi',
    'Skala',
    'Siniarzewo',
    'Silno',
    'Siewierz',
    'Sierzchow',
    'Sierpc',
    'Sieroszewice',
    'Sierakowo',
    'Gmina Sierakowice',
    'Sierakow',
    'Sieradz',
    'Siepraw',
    'Sienna',
    'Sieniawka',
    'Sieniawa Zarska',
    'Siemysl',
    'Siemonia',
    'Siemkowice',
    'Siemirowice',
    'Siemianowice Śląskie',
    'Siedlecin',
    'Siedlec',
    'Siechnice',
    'Sidzina',
    'Gmina Sicienko',
    'Siaszyce',
    'Sianow',
    'Sepolno Krajenskie',
    'Gmina Sędziejowice',
    'Scinawka Srednia',
    'Scinawa',
    'Sciegnica',
    'Schodnia Stara',
    'Sasino',
    'Sarbinowo',
    'Santok',
    'Santocko',
    'Sanniki',
    'Samostrzel',
    'Sady',
    'Sadlinki',
    'Sadkow',
    'Sadki',
    'Rzyki',
    'Rzeskowo',
    'Rzerzeczyce',
    'Rzepin',
    'Rzaska',
    'Rytel',
    'Rypin',
    'Ryman',
    'Ryjewo',
    'Rydzynki',
    'Rydzyna',
    'Rydułtowy',
    'Gmina Ryczywół',
    'Ryczow',
    'Rychwal',
    'Rychtal',
    'Rychliki',
    'Rybnik',
    'Rybarzowice',
    'Ruszow',
    'Rusocice',
    'Rusinowo',
    'Rusinowice',
    'Gmina Rusiec',
    'Rumia',
    'Rudnik',
    'Rudna',
    'Rudawa',
    'Rudaw',
    'Ruda Śląska',
    'Ruchocinek',
    'Rozyny',
    'Roztoka',
    'Rozprza',
    'Rozmierz',
    'Rozanki',
    'Rostarzewo',
    'Rosnowko',
    'Rościszewo',
    'Rokiciny',
    'Rogóźno',
    'Rogozno',
    'Rogozino',
    'Rogow Sobocki',
    'Rogow Opolski',
    'Rogowo',
    'Rogow',
    'Robakowo',
    'Resko',
    'Renska Wies',
    'Regulice',
    'Redziny',
    'Redzikowo',
    'Reda',
    'Reczno',
    'Recz',
    'Rebiechowo',
    'Rawicz',
    'Raszowka',
    'Raszkow',
    'Rakoniewice',
    'Rajsko',
    'Rajcza',
    'Radziszow',
    'Radzionkow',
    'Radziejów',
    'Radwanki',
    'Radomsko',
    'Radocza',
    'Radlin',
    'Radkow',
    'Raczna',
    'Racula',
    'Raclawiczki',
    'Racibórz',
    'Raciborowice Dolne',
    'Rabka-Zdroj',
    'Rabino',
    'Rabien',
    'Raba Wyzna',
    'Pyzdry',
    'Pyskowice',
    'Pyrzyce',
    'Pyrzowice',
    'Puszczykowo',
    'Puszczew',
    'Puncow',
    'Puck',
    'Pszow',
    'Pszczyna',
    'Pszczolki',
    'Pszczew',
    'Psary',
    'Przytoczna',
    'Przyszowice',
    'Przystajn',
    'Przysiersk',
    'Przyrow',
    'Przymilowice',
    'Przykona',
    'Przyjaznia',
    'Przyjazn',
    'Przygodzice',
    'Przybiernowo',
    'Przybiernow',
    'Przodkowo',
    'Przezmierowo',
    'Przeworno',
    'Przemkow',
    'Przemet',
    'Przelewice',
    'Przelazy',
    'Przedecz',
    'Przeczyce',
    'Przeczno',
    'Przeciszow',
    'Przechlewo',
    'Pruszcz Pomorski',
    'Pruszcz Gdanski',
    'Prusice',
    'Prudnik',
    'Pruchna',
    'Proszkow',
    'Prochowice',
    'Preczow',
    'Praszka',
    'Praszczyki',
    'Prabuty',
    'Pozowice',
    'Poznan',
    'Potulice',
    'Potrzanowo',
    'Gmina Potęgowo',
    'Poreba',
    'Poraj',
    'Porabka',
    'Popow',
    'Poniec',
    'Polska Nowa Wies',
    'Polnica',
    'Polkowice',
    'Police',
    'Polczyn-Zdroj',
    'Polanka Wielka',
    'Polanica-Zdrój',
    'Polajewo',
    'Pokrzydowo',
    'Pogorze',
    'Pogodki',
    'Podgorzyn',
    'Gmina Poddębice',
    'Poczesna',
    'Poblocie Wielkie',
    'Pobierowo',
    'Pobiedziska',
    'Pniewy',
    'Pluznica',
    'Ploty',
    'Płoskinia',
    'Płock',
    'Plewiska',
    'Pleszew',
    'Plawno',
    'Pisarzowice',
    'Piotrow',
    'Piotrków Trybunalski',
    'Piotrkow Kujawski',
    'Pilica',
    'Pilchowo',
    'Pilchowice',
    'Pilawa Gorna',
    'Pila',
    'Pietrzykowice',
    'Pietrowice Wielkie',
    'Pieszyce',
    'Piensk',
    'Pielgrzymka',
    'Piekary Slaskie',
    'Piechowice',
    'Piechcin',
    'Piece',
    'Gmina Piątek',
    'Piasek',
    'Pezino',
    'Pewel Mala',
    'Perzyny',
    'Pepowo',
    'Pelplin',
    'Pelczyce',
    'Pegow',
    'Peckowo',
    'Pcim',
    'Pawlowice',
    'Patrzykow',
    'Paszkowka',
    'Pastuchow',
    'Pasłęk',
    'Gmina Parzęczew',
    'Parlin',
    'Parkowo',
    'Parchowo',
    'Paprotnia',
    'Papowo Torunskie',
    'Papowo Biskupie',
    'Paniowki',
    'Palck',
    'Palaty',
    'Pakoslawice',
    'Pakoslaw',
    'Pakość',
    'Pajeczno',
    'Paczkow',
    'Pabianice',
    'Ozorowice',
    'Ozorkow',
    'Ozimek',
    'Owsiszcze',
    'Owinska',
    'Otomin',
    'Otok',
    'Otmuchow',
    'Oświęcim',
    'Ostrzeszow',
    'Ostrowy',
    'Ostrowy nad Oksza',
    'Ostrów Wielkopolski',
    'Ostrowek',
    'Ostrorog',
    'Ostróda',
    'Ostaszewo',
    'Ossy',
    'Osowo Nowogardzkie',
    'Osno Lubuskie',
    'Gmina Osjaków',
    'Osiny',
    'Osina',
    'Osielsko',
    'Osiek nad Notecia',
    'Osiek',
    'Osięciny',
    'Osie',
    'Orzesze',
    'Orzechowo',
    'Orzech',
    'Ornontowice',
    'Orle',
    'Oporow',
    'Opole',
    'Opatowek',
    'Opalenica',
    'Olza',
    'Olszyniec',
    'Olszyna',
    'Olszanka',
    'Olkusz',
    'Olawa',
    'Kolaczkowo',
    'Ogrodzona',
    'Ogrodzieniec',
    'Odrowaz',
    'Odolanow',
    'Ochojno',
    'Obra',
    'Oborniki Śląskie',
    'Oborniki',
    'Obora',
    'Nysa',
    'Nowy Tomysl',
    'Nowy Staw',
    'Nowy Dwor Gdanski',
    'Nowy Dwor',
    'Nowy Barkoczyn',
    'Nowogrodziec',
    'Nowogrodek Pomorski',
    'Gmina Nowogród Bobrzański',
    'Nowogard',
    'Nowe Skalmierzyce',
    'Nowe Miasto Lubawskie',
    'Nowe Miasteczko',
    'Nowe Chechlo',
    'Nowe',
    'Nowa Wies Wielka',
    'Nowa Wies',
    'Nowa Sol',
    'Nowa Ruda',
    'Nochowo',
    'Niwica',
    'Niewiadow',
    'Nietkow',
    'Nieszawa',
    'Niepruszewo',
    'Niemodlin',
    'Niemcza',
    'Niemcz',
    'Nielubia',
    'Niegowonice',
    'Niegowa',
    'Niegoslawice',
    'Niechorze',
    'Niechlow',
    'Niechanowo',
    'Nieborowice',
    'Nekla',
    'Nedza',
    'Namyslow',
    'Nakło nad Notecią',
    'Naklo',
    'Nadolice Wielkie',
    'Nadnia',
    'Myszkow',
    'Myszecin',
    'Mysłowice',
    'Mysliborz',
    'Myslenice',
    'Myslakowice',
    'Myslachowice',
    'Mykanow',
    'Muszkowice',
    'Murowaniec',
    'Murowana Goslina',
    'Murow',
    'Mszana',
    'Msciszewice',
    'Mrzezyno',
    'Mrzezino',
    'Mrozow',
    'Mroczen',
    'Mrocza',
    'Gmina Moszczenica',
    'Mosty',
    'Mostkowo',
    'Mosina',
    'Moryn',
    'Morawica',
    'Morąg',
    'Mokrzeszow',
    'Mogilno',
    'Mogilany',
    'Modrzyca',
    'Modlnica',
    'Mochowo',
    'Mnich',
    'Młynary',
    'Mloszowa',
    'Mirsk',
    'Miroslawiec',
    'Mirkow',
    'Minoga',
    'Minikowo',
    'Miloslaw',
    'Milosc',
    'Miloradz',
    'Milobadz',
    'Milkow',
    'Milicz',
    'Mikstat',
    'Mikołów',
    'Mietkow',
    'Mieszkowice',
    'Mieszkow',
    'Miescisko',
    'Mierzyn',
    'Mierzecice',
    'Mieroszow',
    'Mielno',
    'Mieleszyn',
    'Gmina Miękinia',
    'Miejsce',
    'Miedzyzdroje',
    'Międzyrzecz',
    'Miedzylesie',
    'Międzychód',
    'Miedzybrodzie Zywieckie',
    'Miedzyborz',
    'Miedzno',
    'Miedzichowo',
    'Miedziana',
    'Michalowice',
    'Miastko',
    'Miasteczko Krajenskie',
    'Metkow',
    'Mecinka',
    'Mechnica',
    'Maszewo',
    'Marklowice',
    'Margonin',
    'Marciszow',
    'Gmina Marcinowice',
    'Małdyty',
    'Malczyce',
    'Malbork',
    'Malanow',
    'Mała Nieszawka',
    'Malachin',
    'Makow Podhalanski',
    'Lysomice',
    'Lysniewo Sierakowskie',
    'Lyski',
    'Gmina Lwówek Śląski',
    'Gmina Lwówek',
    'Luzino',
    'Lutynia',
    'Gmina Lututów',
    'Gmina Lutomiersk',
    'Lusina',
    'Lukecin',
    'Ludwikowice Klodzkie',
    'Lubstów',
    'Lubsko',
    'Lubrza',
    'Lubraniec',
    'Lubon',
    'Lubomierz',
    'Lubniewice',
    'Gmina Łubniany',
    'Lubliniec',
    'Lubiszyn',
    'Lubin',
    'Lubien Kujawski',
    'Lubien',
    'Lubicz',
    'Lubiana',
    'Lubczyna',
    'Lubawka',
    'Lubawa',
    'Lubasz',
    'Lubanie',
    'Luban',
    'Lubachow',
    'Łowinek',
    'Lowicz',
    'Lomnica',
    'Lodz',
    'Łódź',
    'Lochowo',
    'Lobzenica',
    'Lobez',
    'Liszki',
    'Liskow',
    'Lisewo',
    'Lipusz',
    'Lipowa',
    'Lipno',
    'Gmina Lipno',
    'Lipniki',
    'Lipnica Wielka',
    'Lipnica Mala',
    'Lipka',
    'Lipinki Luzyckie',
    'Lipie Gory',
    'Lipiany',
    'Lipa',
    'Liniewo',
    'Linia',
    'Ligota Dolna',
    'Ligota',
    'Lidzbark',
    'Lichnowy',
    'Lichen',
    'Libiaz',
    'Lezno',
    'Lewin Brzeski',
    'Lewice',
    'Leszno Gorne',
    'Lesnica',
    'Lesna',
    'Leknica',
    'Leka Opatowska',
    'Legowo',
    'Legnickie Pole',
    'Legnica',
    'Ledziny',
    'Ledyczek',
    'Ledowo',
    'Łęczyca',
    'Lebunia',
    'Lebork',
    'Leba',
    'Lazy',
    'Laziska Gorne',
    'Laszczyn',
    'Lasowice Wielkie',
    'Lasowice Male',
    'Laskowice',
    'Lask',
    'Łasin',
    'Łanięta',
    'Landek',
    'Lanckorona',
    'Lance',
    'Lambinowice',
    'Laliki',
    'Lakorz',
    'Lagow',
    'Lagiewniki',
    'Lądek Zdrój',
    'Ladek',
    'Lacznik',
    'Laczany',
    'Lacza',
    'Lachowice',
    'Labiszyn',
    'Kwilcz',
    'Kwidzyn',
    'Kuznia Raciborska',
    'Kutno',
    'Kuslin',
    'Kurzetnik',
    'Kurowice',
    'Kunice',
    'Kudowa-Zdrój',
    'Kuczkow',
    'Gmina Książ Wielkopolski',
    'Ksiazki',
    'Ksiazenice',
    'Ksawerow',
    'Krzyzanowice',
    'Krzyz Wielkopolski',
    'Krzywin',
    'Krzywaczka',
    'Krzymow',
    'Krzeszyce',
    'Krzeszowice',
    'Krzeszow',
    'Krzepice',
    'Krzczonow',
    'Krzanowice',
    'Kryry',
    'Kryniczno',
    'Krynica Morska',
    'Kruszyn',
    'Kruszwica',
    'Kruszow',
    'Krupski Mlyn',
    'Krotoszyn',
    'Krosno Odrzanskie',
    'Krośniewice',
    'Krolikowice',
    'Krokowa',
    'Kroczyce',
    'Krobia',
    'Kretomino',
    'Krepsko',
    'Krepna',
    'Krapkowice',
    'Kramsk',
    'Krakow',
    'Krajenka',
    'Kozy',
    'Kozuchow',
    'Kozminek',
    'Kozmin Wielkopolski',
    'Kozieglowy',
    'Kowary',
    'Kowalewo Pomorskie',
    'Kowale',
    'Kowal',
    'Kotlin',
    'Kotlarnia',
    'Kotla',
    'Koszelew',
    'Koszarawa',
    'Koszalin',
    'Kostrzyn nad Odra',
    'Kostrzyn',
    'Gmina Kostomłoty',
    'Kostkowo',
    'Kossowa',
    'Kosow',
    'Kosmidry',
    'Gmina Koszęcin',
    'Koscierzyna',
    'Koscierzyce',
    'Koscielna Wies',
    'Koscielisko',
    'Kościelec',
    'Kościan',
    'Kosakowo',
    'Korzeniew',
    'Korytowo',
    'Koronowo',
    'Kornik',
    'Konstantynow Lodzki',
    'Konstantynow',
    'Konopnica',
    'Konopiska',
    'Konin',
    'Koniecpol',
    'Koneck',
    'Konczyce Wielkie',
    'Konczyce Male',
    'Gmina Konarzyny',
    'Konarskie',
    'Komprachcice',
    'Komorowice',
    'Komorniki',
    'Koluszki',
    'Kolsko',
    'Kolonowskie',
    'Kolonia Gorka Klonowska',
    'Kołobrzeg',
    'Koło',
    'Koleczkowo',
    'Kolczewo',
    'Kolbudy',
    'Kolbaskowo',
    'Kokanin',
    'Kochcice',
    'Kochanowice',
    'Kobysewo',
    'Kobylin',
    'Kobylczyce',
    'Kobylany',
    'Kobyla Gora',
    'Kobyla',
    'Kobior',
    'Kobierzyce',
    'Kobiernice',
    'Kobiele Wielkie',
    'Knybawa',
    'Knurow',
    'Kmiecin',
    'Kluki',
    'Kluczewsko',
    'Klucze',
    'Kluczbork',
    'Klotyldzin',
    'Klomnice',
    'Kłodzko',
    'Klodawa',
    'Kłodawa',
    'Kloda',
    'Klobuck',
    'Klikuszowa',
    'Kleszczow',
    'Kleszczewo',
    'Klenica',
    'Kleczew',
    'Klecza Gorna',
    'Klecko',
    'Klasztorek',
    'Gmina Kiszkowo',
    'Kiszewo',
    'Kisielice',
    'Kijewo Krolewskie',
    'Kietrz',
    'Kiełpino',
    'Kielno',
    'Kielczow',
    'Kielcza',
    'Kiekrz',
    'Kidow',
    'Kety',
    'Kesowo',
    'Kępno',
    'Kedzierzyn-Kozle',
    'Keblowo',
    'Kcynia',
    'Kazmierz',
    'Kazimierz Biskupi',
    'Katy Wroclawskie',
    'Katowice',
    'Kartuzy',
    'Karsko',
    'Karsin',
    'Karpniki',
    'Karpacz',
    'Karniowice',
    'Karlowice',
    'Karlino',
    'Kargowa',
    'Kaniow',
    'Kamionki',
    'Kamienskie Mlyny',
    'Kamien Pomorski',
    'Kamienna Gora',
    'Kamien Krajenski',
    'Kamieniec Zabkowicki',
    'Kamieniec Wroclawski',
    'Kamesznica',
    'Kalwaria Zebrzydowska',
    'Kalisz Pomorski',
    'Gmina Kalisz Pomorski',
    'Kaliska',
    'Kalety',
    'Kakolewo',
    'Kadlub Wolny',
    'Kadlub',
    'Kaczyce',
    'Kaczory',
    'Juszkowo',
    'Jugowa',
    'Jugow',
    'Jordanow Slaski',
    'Jordanow',
    'Jezow Sudecki',
    'Jeziora Wielkie',
    'Jerzmanowice',
    'Jerka',
    'Jenkowice',
    'Jemielnica',
    'Jelowa',
    'Jelesnia',
    'Jelenia Góra',
    'Jejkowice',
    'Jegłownik',
    'Jedlina-Zdroj',
    'Jedlina',
    'Jaworzynka',
    'Jaworzyna Slaska',
    'Jaworzno',
    'Jaworze',
    'Jawor',
    'Jawiszowice',
    'Jaszkowa Gorna',
    'Jastrzębie Zdrój',
    'Jastrzebie',
    'Jastrzebia Gora',
    'Jastrowie',
    'Jastarnia',
    'Jasin',
    'Jasien',
    'Jaroszowiec',
    'Jaroszow',
    'Jaraczewo',
    'Jantar',
    'Janowiec Wielkopolski',
    'Janowice Wielkie',
    'Jankowice',
    'Janikowo',
    'Jamielnik',
    'Jaksice',
    'Jaczow',
    'Jackow',
    'Jachowka',
    'Jacewo',
    'Jabłonowo Pomorskie',
    'Izdebnik',
    'Izbica Kujawska',
    'Iwiny',
    'Iwanowice',
    'Istebna',
    'Inowrocław',
    'Imielin',
    'Ilowa',
    'Iława',
    'Huta',
    'Henrykow',
    'Hel',
    'Hazlach',
    'Hadra',
    'Gubin',
    'Grzybowo',
    'Gryfow Slaski',
    'Gryfino',
    'Gryfice',
    'Grupa',
    'Grudzielec Nowy',
    'Grudziądz',
    'Grudynia Wielka',
    'Gronowo Elblaskie',
    'Gronow',
    'Gromadka',
    'Grodziszcze',
    'Grodzisk Wielkopolski',
    'Grodkow',
    'Grochowy',
    'Grebocin',
    'Grebocice',
    'Gmina Grabów nad Prosną',
    'Grabowiec',
    'Grabiny-Zameczek',
    'Gowino',
    'Gowidlino',
    'Gowarzewo',
    'Goszczanow',
    'Gostynin',
    'Gostyn',
    'Gostycyn',
    'Goscino',
    'Gościcino',
    'Gorzyn',
    'Gorzyce Wielkie',
    'Gorzów Wielkopolski',
    'Gorzkowice',
    'Gorki Wielkie',
    'Gorki Male',
    'Goreczyno',
    'Gora Siewierska',
    'Gora',
    'Gmina Gołuchów',
    'Golub-Dobrzyn',
    'Goleszow',
    'Goleniów',
    'Golczewo',
    'Golanice',
    'Golancz',
    'Gogolin',
    'Godziesze Wielkie',
    'Godziesze Male',
    'Goczalkowice Zdroj',
    'Gniezno',
    'Gniewkowo',
    'Gniewino',
    'Gniew',
    'Gluszyna',
    'Gluszyca',
    'Gluchow Gorny',
    'Glucholazy',
    'Glubczyce',
    'Glowno',
    'Glogowo',
    'Glogowek',
    'Głogów',
    'Gliwice',
    'Glisno',
    'Glinno Wielkie',
    'Glincz',
    'Glebinow',
    'Gilowice',
    'Gieraltowice',
    'Gieczno',
    'Gidle',
    'Gesiniec',
    'Gebice',
    'Gdynia',
    'Gdańsk',
    'Gaszowice',
    'Garnek',
    'Gardna Wielka',
    'Gardeja',
    'Garczegorze',
    'Galow',
    'Galkow Maly',
    'Galkow Duzy',
    'Galewice',
    'Galazczyce',
    'Gajkow',
    'Gajec',
    'Gaj',
    'Gadka Stara',
    'Gąbin',
    'Fugasowka',
    'Frombork',
    'Frank',
    'Falmirowice',
    'Falkowo',
    'Fabianki',
    'Elblag',
    'Dziwnow',
    'Dzimierz',
    'Dziewietlice',
    'Dzietrzkowice',
    'Dzierzoniow',
    'Dzierzgon',
    'Dziergowice',
    'Dziembowo',
    'Dziarnowo',
    'Dzialoszyn',
    'Dylow',
    'Duszniki-Zdroj',
    'Gmina Duszniki',
    'Dulowa',
    'Drzycim',
    'Drzonek',
    'Drzewociny',
    'Drzewce',
    'Druzbice',
    'Drozdzyce',
    'Droniowice',
    'Drezdenko',
    'Drawsko Pomorskie',
    'Drawsko',
    'Drawski Mlyn',
    'Drawno',
    'Dragasz',
    'Doruchow',
    'Dopiewo',
    'Dopiewiec',
    'Domatowko',
    'Domaslaw',
    'Gmina Domaniów',
    'Domaniewice',
    'Dolsk',
    'Dolna',
    'Dolice',
    'Dobrzyca',
    'Dobrzen Wielki',
    'Dobrzejewice',
    'Dobrzany',
    'Dobroszyce',
    'Dobron',
    'Dobromierz',
    'Dobrodzien',
    'Dobre',
    'Gmina Dobrcz',
    'Dobra',
    'Dobieszowice',
    'Dmosin',
    'Dlutow',
    'Dlugoleka',
    'Dlugopole-Zdroj',
    'Dlugie Stare',
    'Dlugie Nowe',
    'Deszczno',
    'Debrzno',
    'Debowa Leka',
    'Debowa Gora',
    'Debogorze',
    'Debno',
    'Debnica Kaszubska',
    'Debnialki Kaliskie',
    'Debiec',
    'Debki',
    'Daszewice',
    'Darłowo',
    'Dankowice',
    'Dalwin',
    'Dalikow',
    'Dabrowa Zielona',
    'Dabrowa Nowogardzka',
    'Dabrowa Nowa',
    'Dąbrowa Górnicza',
    'Dabrowa Biskupia',
    'Czyzowice',
    'Czluchow',
    'Czeszow',
    'Częstochowa',
    'Czerwonak',
    'Czerwionka-Leszczyny',
    'Czerwiensk',
    'Czersk',
    'Czerniejewo',
    'Czernichow',
    'Czernica',
    'Czerlejno',
    'Czempin',
    'Czeluscin',
    'Czeladz',
    'Czekanow',
    'Czechowice-Dziedzice',
    'Czechow',
    'Czastary',
    'Czarny Dunajec',
    'Czarny Bor',
    'Czarnozyly',
    'Czarnowo',
    'Czarnkow',
    'Czarne Bloto',
    'Czarna Woda',
    'Czarkow',
    'Czapury',
    'Czaplinek',
    'Czaniec',
    'Cyprzanow',
    'Cybinka',
    'Cwiklice',
    'Cisiec',
    'Cisew',
    'Cieszyn',
    'Cieszkow',
    'Cierpice',
    'Ciepielow',
    'Ciele',
    'Ciechow',
    'Ciechocinek',
    'Ciechocin',
    'Ciasna',
    'Cianowo',
    'Cianowice Duze',
    'Chyby',
    'Chwaszczyno',
    'Chudoba',
    'Chrzypsko Wielkie',
    'Chrzastowice',
    'Chrzanów',
    'Chroscielow',
    'Chotkow',
    'Choszczno',
    'Chorzów',
    'Chorowice',
    'Choron',
    'Chomecice',
    'Chojnów',
    'Chojnice',
    'Chojna',
    'Chodzież',
    'Chocznia',
    'Choczewo',
    'Chocz',
    'Chociwel',
    'Chocianowiec',
    'Chocianow',
    'Chocen',
    'Chobienia',
    'Chełmża',
    'Chelm Slaski',
    'Chełmno',
    'Chelmek',
    'Chelm',
    'Chechlo',
    'Charzyno',
    'Charzykowy',
    'Chartow',
    'Charsznica',
    'Chabowka',
    'Ceradz Koscielny',
    'Celiny',
    'Cekow',
    'Cekcyn',
    'Bytów',
    'Bytonia',
    'Bytom Odrzanski',
    'Bytom',
    'Bystrzyca Klodzka',
    'Byslaw',
    'Bykowina',
    'Bydgoszcz',
    'Bychlew',
    'Burzenin',
    'Bukowno',
    'Bukowiec',
    'Bukowa',
    'Buk',
    'Budzyn',
    'Budzistowo',
    'Budzislaw Koscielny',
    'Budachow',
    'Brzozie',
    'Brzoza',
    'Brzezno',
    'Brzeziny',
    'Brzezinki',
    'Brzezia Laka',
    'Brzeszcze',
    'Brzeg Dolny',
    'Brzeg',
    'Brwilno',
    'Brusy',
    'Brunow',
    'Brudzowice',
    'Bronow',
    'Broniszowice',
    'Stary Broniszew',
    'Brojce',
    'Brodnica',
    'Gmina Brodnica',
    'Brodna',
    'Brenna',
    'Brdow',
    'Bratoszewice',
    'Bratian',
    'Braszewice',
    'Braniewo',
    'Bialy Kosciol',
    'Bralin',
    'Boznow',
    'Bozkow',
    'Boszkowo',
    'Borzeta',
    'Boruja Koscielna',
    'Boronow',
    'Borkowo',
    'Borkowo Lostowickie',
    'Borek Wielkopolski',
    'Bonin',
    'Bonikowo',
    'Boniewo',
    'Bolszewo',
    'Bolkow',
    'Bolewice',
    'Bolesławiec',
    'Boleslawiec',
    'Bolechowo',
    'Bolechow',
    'Bojszowy Nowe',
    'Bojszowy',
    'Bojanowo',
    'Bojano',
    'Bojadla',
    'Boguszów-Gorce',
    'Bogunow',
    'Bogucino',
    'Bogucin Maly',
    'Bogucice',
    'Bogdanowo',
    'Bogdaniec',
    'Bogatynia',
    'Bogaczow',
    'Boczow',
    'Bocianicha',
    'Bobrowniki',
    'Bobrowice',
    'Bobolin',
    'Bobolice',
    'Bluszczow',
    'Bloto',
    'Blotnica',
    'Blizanow',
    'Blazejewo',
    'Bladnice Dolne',
    'Blachownia Slaska',
    'Blachownia',
    'Biskupiczki',
    'Biskupice Oloboczne',
    'Biskupice Olawskie',
    'Binkowo',
    'Biezdrowo',
    'Biestrzykowice',
    'Biesiekierz',
    'Bierzwnik',
    'Bierzglin',
    'Biery',
    'Bierutow',
    'Bierun',
    'Biernacice',
    'Bierna',
    'Bierawa',
    'Bienkowka',
    'Bienkowice',
    'Bielsko-Biala',
    'Bielsk',
    'Bielice',
    'Bielawy',
    'Bielawa Dolna',
    'Bielany Wroclawskie',
    'Bielany',
    'Biedrzychowice Dolne',
    'Biedrzychowice',
    'Biedrzychowa',
    'Biedrusko',
    'Bieczyno',
    'Bibice',
    'Bialosliwie',
    'Białogard',
    'Bialochowo',
    'Biale Blota',
    'Biala Rzadowa',
    'Biala',
    'Biała Prudnicka',
    'Biadki',
    'Biadacz',
    'Bestwinka',
    'Bestwina',
    'Benice',
    'Bełchatów',
    'Bedzymin',
    'Bedzino',
    'Będzin',
    'Nowy Bedon',
    'Bedlno',
    'Bedlewo',
    'Bedkowice',
    'Barwice',
    'Baruchowo',
    'Barlinek',
    'Barlewice',
    'Bardo',
    'Barcin',
    'Barany',
    'Baranowko',
    'Baranow',
    'Banino',
    'Baldram',
    'Baldowo',
    'Baki',
    'Bachowice',
    'Baborow',
    'Babimost',
    'Gmina Babiak',
    'Anieliny',
    'Anglity',
    'Andrychow',
    'Andrespol',
    'Alwernia',
    'Aleksandrów Łódzki',
    'Aleksandrów Kujawski',
    'Kroscienko nad Dunajcem',
    'Czarny Las',
    'Musuly',
    'Sade Budy',
    'Bedon Przykoscielny',
    'Zelkow Kolonia',
    'Duchnice',
    'Rzgow',
    'Jelcz Laskowice',
    'Nowa Iwiczna',
    'Sztutowo',
    'Zarki-Letnisko',
    'Przybedza',
    'Koszyce Wielkie',
    'Jerzmanowa',
    'Radwanice',
    'Porajow',
    'Lubicz Dolny',
    'Lubicz Gorny',
    'Gmina Mała Wieś',
    'Izabelin',
    'Czarna Dabrowka',
    'Dobrzykowice',
    'Piekary',
    'Belzec',
    'Rokiciny-Kolonia',
    'Witkowo Drugie',
    'Kocierzew Poludniowy',
    'Sleza',
    'Rotmanka',
    'Fabryczna',
    'Lazniewek',
    'Falenty Nowe',
    'Jedlno Drugie',
    'Iglowice',
    'Mierzyn k. Szczecina',
    'Swietokrzyskie',
    'Wieprzyce',
    'Sobin',
    'Karczowiska',
    'Stara Iwiczna',
    'Czerwony Kosciol',
    'Brennik',
    'Wierzbica Gorna',
    'Kajetany',
    'Slawoszowice',
    'Izabelin C',
    'Budy Glogowskie',
    'Wilkszyn',
    'Kleosin',
    'Nowe Proboszczewice',
    'Chylice-Kolonia',
    'Macierzysz',
    'Twardorzeczka',
    'Wlynkowko',
    'Kapino',
    'Blizne Laszczynskiego',
    'Dawidy Bankowe',
    'Pustyny',
    'Jazgarzewszczyzna',
    'Gorzkie Pole',
    'Emow',
    'Lapino Kartuskie',
    'Hanulin',
    'Iwanowice Dworskie',
    'Dziekanow Nowy',
    'Lniska',
    'Bystrzejowice Pierwsze',
    'Laryszow',
    'Grazowice',
    'Sobczyce',
    'Blok Dobryszyce',
    'Bezrzecze',
    'Kalinowka',
    'Milejow-Osada',
    'Wladyslawow',
    'Jeszkowice',
    'Wolka Pracka',
    'Przychowa',
  ],
  Norway: [
    'Ytre Kiberg',
    'Vardø',
    'Varangerbotn',
    'Vadsø',
    'Talvik',
    'Storslett',
    'Sorvaer',
    'Sorkjosen',
    'Skoganvarre',
    'Skjervoy',
    'Ivgobahta',
    'Samuelsberg',
    'Rotsund',
    'Olderdalen',
    'Oksfjord',
    'Nordvagen',
    'Nordlenangen',
    'Mehamn',
    'Masoy',
    'Manndalen',
    'Lyngseidet',
    'Lebesby',
    'Lakselv',
    'Kvalsund',
    'Kjollefjord',
    'Kirkenes',
    'Kautokeino',
    'Karasjok',
    'Vestre Jakobselv',
    'Honningsvåg',
    'Hesseng',
    'Havøysund',
    'Hasvik',
    'Hammerfest',
    'Gamvik',
    'Burfjord',
    'Bjornevatn',
    'Birtavarre',
    'Berlevåg',
    'Båtsfjord',
    'Alta',
    'Rypefjord',
    'Ytre Arna',
    'Vraliosen',
    'Vossestrand',
    'Voss',
    'Vormsund',
    'Vollen',
    'Voll',
    'Volda',
    'Vinstra',
    'Vinjeora',
    'Vikran',
    'Vikesa',
    'Vikersund',
    'Vikedal',
    'Vikebukt',
    'Vik',
    'Vigrestad',
    'Vevelstad',
    'Vevang',
    'Vestre Gausdal',
    'Vestfossen',
    'Vestby',
    'Verdal',
    'Vennesla',
    'Venabygd',
    'Veggli',
    'Veblungsnes',
    'Vatne',
    'Vassenden',
    'Vartdal',
    'Varhaug',
    'Vanse',
    'Vangsnes',
    'Valsoyfjord',
    'Valsoybotn',
    'Valle',
    'Valldal',
    'Valevag',
    'Valer',
    'Valen',
    'Vagland',
    'Værøy',
    'Vadheim',
    'Uvdal',
    'Utskarpen',
    'Utsira',
    'Utne',
    'Uskedalen',
    'Undeim',
    'Ulvik',
    'Ulsteinvik',
    'Ulnes',
    'Ulefoss',
    'Uggdal',
    'Tyssedal',
    'Tysse',
    'Tysnes',
    'Torvikbygd',
    'Tyristrand',
    'Tynset',
    'Tveit',
    'Tvedestrand',
    'Innbygda',
    'Trones',
    'Trondheim',
    'Tromsø',
    'Tromsdalen',
    'Trofors',
    'Treungen',
    'Tretten',
    'Trengereid',
    'Tranby',
    'Tovik',
    'Torvikbukt',
    'Torp',
    'Torod',
    'Tornes',
    'Torberget',
    'Tonstad',
    'Tønsberg',
    'Tomter',
    'Tomra',
    'Tomasjorda',
    'Tofte',
    'Todalen',
    'Tjotta',
    'Tjorvag',
    'Tjong',
    'Tjøme',
    'Tjelta',
    'Tjeldsto',
    'Tistedal',
    'Tiller',
    'Tertnes',
    'Terrak',
    'Tennfjord',
    'Tennevollen',
    'Tau',
    'Tangen',
    'Tanem',
    'Tananger',
    'Taelavag',
    'Syvik',
    'Sylling',
    'Svortevik',
    'Svorkland',
    'Svolvær',
    'Svensby',
    'Svene',
    'Svelvik',
    'Svelgen',
    'Sveio',
    'Svarstad',
    'Sunndalsora',
    'Sunde',
    'Sulisjielmma',
    'Suldalsosen',
    'Stryn',
    'Strusshamn',
    'Strommen',
    'Straumsbukta',
    'Straumen',
    'Saltstraumen',
    'Straume',
    'Strandebarm',
    'Stranda',
    'Storsteinnes',
    'Storforshei',
    'Eide',
    'Stordal',
    'Storebo',
    'Stonglandseidet',
    'Stongfjorden',
    'Stol',
    'Stokmarknes',
    'Stokke',
    'Steinsland',
    'Steinkjer',
    'Steinberg',
    'Steigen',
    'Stavern',
    'Stavanger',
    'Staubo',
    'Stathelle',
    'Starheim',
    'Stanghelle',
    'Stange',
    'Stamsund',
    'Stakkvik',
    'Stabekk',
    'Spydeberg',
    'Spillum',
    'Sperrebotn',
    'Sparbu',
    'Sorvagen',
    'Sorumsand',
    'Sorum',
    'Sortland',
    'Sorstraumen',
    'Soreide',
    'Sorbo',
    'Songe',
    'Son',
    'Somna',
    'Sommaroy',
    'Sollihogda',
    'Solbergelva',
    'Sola',
    'Soknedal',
    'Sokndal',
    'Sokna',
    'Søgne',
    'Snaase',
    'Snartemo',
    'Slinda',
    'Slemmestad',
    'Slattum',
    'Slastad',
    'Skutvika',
    'Skui',
    'Skudeneshavn',
    'Skreia',
    'Skotterud',
    'Skoppum',
    'Skogn',
    'Skodje',
    'Skjolden',
    'Skjold',
    'Skjeberg',
    'Skjaerhalden',
    'Skien',
    'Ski',
    'Skaun',
    'Skarnes',
    'Skare',
    'Skanevik',
    'Skammestein',
    'Skaland',
    'Skage',
    'Sjovegan',
    'Skjonhaug',
    'Sjoholt',
    'Sistranda',
    'Sirevag',
    'Sira',
    'Singsas',
    'Siljan',
    'Siggerud',
    'Setermoen',
    'Sem',
    'Sellebakk',
    'Seljord',
    'Selje',
    'Selfors',
    'Seim',
    'Saudasjoen',
    'Sauda',
    'Sarpsborg',
    'Sannidal',
    'Sandvoll',
    'Sandvika',
    'Sandve',
    'Sandtorg',
    'Sand',
    'Sandnessjøen',
    'Sandnes',
    'Sander',
    'Sandeid',
    'Sandefjord',
    'Sandshamn',
    'Sande',
    'Sandane',
    'Saltnes',
    'Saltrod',
    'Salhus',
    'Saksvik',
    'Sagvag',
    'Saeveland',
    'Saetre',
    'Saebovik',
    'Saebo',
    'Rysstad',
    'Rykene',
    'Rygge',
    'Rud',
    'Rubbestadneset',
    'Royken',
    'Roverud',
    'Rovde',
    'Rost',
    'Rossland',
    'Rosendal',
    'Rørvik',
    'Rorvik',
    'Røros',
    'Rong',
    'Rolvsoy',
    'Roldal',
    'Rokland',
    'Rognes',
    'Rogne',
    'Rognan',
    'Rodoy',
    'Roa',
    'Rjukan',
    'Risoyhamn',
    'Risør',
    'Ringsaker',
    'Feios',
    'Rindal',
    'Rena',
    'Reistad',
    'Reipa',
    'Reinsvoll',
    'Reinli',
    'Reine',
    'Revsnes',
    'Rebbenes',
    'Rauland',
    'Raufoss',
    'Raudeberg',
    'Ranheim',
    'Randaberg',
    'Ranasfoss',
    'Ramsund',
    'Ramfjordbotn',
    'Ramberg',
    'Rakvag',
    'Rakkestad',
    'Raholt',
    'Ragrendi',
    'Karlshus',
    'Prestfoss',
    'Porsgrunn',
    'Oystese',
    'Oysletta',
    'Oyrnes',
    'Oye',
    'Mosby',
    'Ovrebo',
    'Overbygd',
    'Ottestad',
    'Ottersoya',
    'Otta',
    'Cavkkus',
    'Oslo',
    'Os',
    'Orre',
    'Ornes',
    'Orkanger',
    'Orje',
    'Opphaug',
    'Oppdal',
    'Onarheim',
    'Oma',
    'Oltedal',
    'Olen',
    'Oksvoll',
    'Auklandshamn',
    'Odnes',
    'Odda',
    'Nøtterøy',
    'Notodden',
    'Norheimsund',
    'Noresund',
    'Nore',
    'Nordvik',
    'Nordstrono',
    'Gargan',
    'Nordkisa',
    'Nordfold',
    'Nordfjordeid',
    'Nordby Bruk',
    'Nodeland',
    'Neverdal',
    'Nesttun',
    'Nesna',
    'Neslandsvatn',
    'Nesflaten',
    'Nesbyen',
    'Nerskogen',
    'Nedstrand',
    'Nedre Eiker',
    'Nedenes',
    'Naustdal',
    'Narvik',
    'Napp',
    'Namsskogan',
    'Namsos',
    'Naersnes',
    'Naeroset',
    'Naerbo',
    'Mysen',
    'Myre',
    'Myklebostad',
    'Myken',
    'Mosterhamn',
    'Moss',
    'Mosjøen',
    'Morvik',
    'Morgedal',
    'Moldjord',
    'Molde',
    'Fyresdal',
    'Mo i Rana',
    'Moi',
    'Moen',
    'Moelv',
    'Mjondalen',
    'Mjolkeraen',
    'Mittet',
    'Minnesund',
    'Miland',
    'Midsund',
    'Mestervik',
    'Meraker',
    'Melsomvik',
    'Meloy',
    'Melhus',
    'Melbu',
    'Meisingset',
    'Maura',
    'Ovre Maudal',
    'Matrand',
    'Mathopen',
    'Masfjorden',
    'Mortenhals',
    'Marheim',
    'Manger',
    'Mandal',
    'Malvik',
    'Maloy',
    'Malm',
    'Magnor',
    'Lysoysundet',
    'Lysaker',
    'Lyngstad',
    'Lyngdal',
    'Lunner',
    'Lunde',
    'Lundamo',
    'Lovund',
    'Loten',
    'Longva',
    'Lonevag',
    'Lom',
    'Lokken Verk',
    'Loken',
    'Lofthus',
    'Loen',
    'Lodingen',
    'Lindas',
    'Lillestrøm',
    'Lillesand',
    'Lillehammer',
    'Lier',
    'Levanger',
    'Ler',
    'Lepsoy',
    'Lensvik',
    'Lena',
    'Leirvik',
    'Leirsund',
    'Leirfjord',
    'Leira',
    'Lavik',
    'Lauvstad',
    'Larvik',
    'Larsnes',
    'Larkollen',
    'Langevag',
    'Langesund',
    'Lalm',
    'Laksevag',
    'Lærdal',
    'Kyrksaeterora',
    'Kyrkjebo',
    'Kviteseid',
    'Kvinesdal',
    'Kverva',
    'Kvelde',
    'Kvanne',
    'Kvammen',
    'Kvam',
    'Kvalevag',
    'Kval',
    'Krokstadelva',
    'Kroderen',
    'Kristiansund',
    'Kristiansand',
    'Krakeroy',
    'Kragero',
    'Korsvegen',
    'Kornsjo',
    'Korgen',
    'Koppera',
    'Koppang',
    'Kopervik',
    'Konsvikosen',
    'Konsmo',
    'Kongsvinger',
    'Kongsvika',
    'Kongsmoen',
    'Kongsberg',
    'Kolvereid',
    'Kolsas',
    'Kolnes',
    'Kolltveit',
    'Kolbu',
    'Kolbotn',
    'Kolbeinsvik',
    'Knarrevik',
    'Knappstad',
    'Klofta',
    'Kleppesto',
    'Kleppe',
    'Klepp',
    'Kleive',
    'Klaebu',
    'Kjopsvik',
    'Kjerringoy',
    'Kjeller',
    'Kirkenaer',
    'Kaupanger',
    'Karlstad',
    'Kaldfarnes',
    'Kabelvag',
    'Jostolen',
    'Jorpeland',
    'Jondal',
    'Jevnaker',
    'Jessheim',
    'Jaren',
    'Jar',
    'Ise',
    'Isdalsto',
    'Innvik',
    'Inndyr',
    'Leines',
    'Indre Kvaroya',
    'Indre Karvik',
    'Ilseng',
    'Ikornnes',
    'Ibestad',
    'Hyllestad',
    'Hylkje',
    'Hyggen',
    'Hvittingfoss',
    'Hvam',
    'Hvalstad',
    'Hustad',
    'Hunndalen',
    'Hundven',
    'Hundeidvik',
    'Hoyanger',
    'Hovland',
    'Hovin',
    'Hovik verk',
    'Hovden',
    'Hovag',
    'Hov',
    'Hosteland',
    'Horten',
    'Horte',
    'Hornnes',
    'Hordvik',
    'Honefoss',
    'Hommelvik',
    'Holter',
    'Holmsbu',
    'Holmestrand',
    'Holmefjord',
    'Holmedal',
    'Holen',
    'Hokksund',
    'Hjorungavag',
    'Hjelset',
    'Hjelmas',
    'Hjellestad',
    'Hitra',
    'Hildre',
    'Hestvika',
    'Herre',
    'Heroysund',
    'Hernes',
    'Herdla',
    'Heradsbygd',
    'Henningsvaer',
    'Hemsedal',
    'Hemnesberget',
    'Hemnes',
    'Hellvik',
    'Hellevik',
    'Helleland',
    'Helle',
    'Hell',
    'Helgeroa',
    'Hegra',
    'Heggenes',
    'Heggedal',
    'Hedalen',
    'Hebnes',
    'Havik',
    'Haukeli',
    'Haukeland',
    'Haugesund',
    'Hauge i Dalane',
    'Hatlestranda',
    'Haslum',
    'Hasle',
    'Harstad',
    'Harran',
    'Hareid',
    'Hardbakke',
    'Haram',
    'Hansnes',
    'Hamnvik',
    'Hamarvik',
    'Hamar',
    'Haltdalen',
    'Halsa',
    'Hallingby',
    'Halden',
    'Hakadal',
    'Hageberg',
    'Hagavik',
    'Hagan',
    'Haegeland',
    'Haddal',
    'Gvarv',
    'Gullaug',
    'Gudvangen',
    'Gryllefjord',
    'Grua',
    'Grong',
    'Groa',
    'Grinder',
    'Grimstad',
    'Gressvik',
    'Greaker',
    'Gravdal',
    'Gratangen',
    'Senjahopen',
    'Granvin',
    'Gran',
    'Gol',
    'Godoy',
    'Glomfjord',
    'Glesvaer',
    'Glamos',
    'Gjøvik',
    'Gjora',
    'Gjerstad',
    'Geithus',
    'Geilo',
    'Gaupen',
    'Garnes',
    'Gardermoen',
    'Gan',
    'Frogner',
    'Fresvik',
    'Frekhaug',
    'Nedre Frei',
    'Fredrikstad',
    'Fotlandsvag',
    'Fosslandsosen',
    'Fosser',
    'Fosnavag',
    'Fornebu',
    'Forde',
    'Førde',
    'Fonnes',
    'Follese',
    'Follebu',
    'Folldal',
    'Folkestad',
    'Folderoy',
    'Foldereid',
    'Follafoss',
    'Fluberg',
    'Florvag',
    'Floro',
    'Flesnes',
    'Flesberg',
    'Flemma',
    'Flekkefjord',
    'Flekke',
    'Flateby',
    'Flatdal',
    'Flam',
    'Fla',
    'Fjellstrand',
    'Fjelldal',
    'Fjaerland',
    'Fitjar',
    'Fiskum',
    'Fiska',
    'Finnsnes',
    'Filtvet',
    'Fillan',
    'Fiksdal',
    'Figgjo',
    'Fevik',
    'Fetsund',
    'Fenstad',
    'Feiring',
    'Feda',
    'Fauske',
    'Farsund',
    'Farstad',
    'Fardal',
    'Fall',
    'Fagerstrand',
    'Fagernes',
    'Faervik',
    'Faberg',
    'Eydehavn',
    'Evje',
    'Evenskjer',
    'Etne',
    'Espeland',
    'Erdal',
    'Enger',
    'Engenes',
    'Engalsvik',
    'Enga',
    'Elverum',
    'Elvegard',
    'Eivindvik',
    'Eina',
    'Eiksund',
    'Eiken',
    'Eikelandsosen',
    'Eikanger',
    'Eidsvoll verk',
    'Eidsvoll',
    'Eidsvag',
    'Eidslandet',
    'Eidsfoss',
    'Eidsdal',
    'Eidsberg',
    'Eidsa',
    'Eidfjord',
    'Eggedal',
    'Egersund',
    'Edland',
    'Dyrvika',
    'Dverberg',
    'Drobak',
    'Drevsjo',
    'Drangedal',
    'Drammen',
    'Drag',
    'Dovre',
    'Dombas',
    'Dokka',
    'Disena',
    'Dirdal',
    'Dimmelsvik',
    'Dilling',
    'Deknepollen',
    'Dalsoyra',
    'Dalholen',
    'Dalen',
    'Dale',
    'Dal',
    'Byrknes',
    'Byrkjelo',
    'Byremo',
    'Bykle',
    'Bygstad',
    'Bygland',
    'Buskerud',
    'Bud',
    'Bryne',
    'Brygghaugen',
    'Brumunddal',
    'Bru',
    'Bronnoysund',
    'Brevik',
    'Brennasen',
    'Svortland',
    'Brekstad',
    'Brekke',
    'Breistein',
    'Brattvag',
    'Brattholmen',
    'Braskereidfoss',
    'Brandval',
    'Botnhamn',
    'Bostad',
    'Borkenes',
    'Borhaug',
    'Bones',
    'Bo',
    'Bomlo',
    'Bolstadoyri',
    'Bogen',
    'Bodø',
    'Bleik',
    'Blakset',
    'Bjorkelangen',
    'Bjorke',
    'Bjordal',
    'Bjoneroa',
    'Bjerland',
    'Bjerkvik',
    'Bjerkreim',
    'Birkeland',
    'Bessaker',
    'Berger',
    'Bergen',
    'Berge',
    'Bekkjarvik',
    'Beitostolen',
    'Beiarn',
    'Batnfjordsora',
    'Barkaker',
    'Bangsund',
    'Ballstad',
    'Ballangen',
    'Balestrand',
    'Boverfjorden',
    'Baerums verk',
    'Austvatn',
    'Austmarka',
    'Austbygdi',
    'Aursmoen',
    'Aure',
    'Aurdal',
    'Auli',
    'Aukra',
    'Askim',
    'Asker',
    'Ask',
    'Asgardstrand',
    'Asen',
    'Asane',
    'As',
    'Aas',
    'Aros',
    'Arnes',
    'Arneberg',
    'Indre Arna',
    'Arendal',
    'Ardalstangen',
    'Ardal',
    'Anneland',
    'Ankenes',
    'Angvika',
    'Andenes',
    'Andalsnes',
    'Ana-Sira',
    'Amot',
    'Amli',
    'Amdals verk',
    'Indre Alvik',
    'Alvdal',
    'Alsvag',
    'Algard',
    'Ålesund',
    'Alen',
    'Al',
    'Akrehamn',
    'Aheim',
    'Agskardet',
    'Agotnes',
    'Afarnes',
    'Adalsbruk',
    'Åfjord',
    'Vormedal',
    'Vikebygd',
    'Klokkarvik',
    'Tingvoll',
    'Ytrehovden',
    'Vanvikan',
    'Silsand',
    'Kolbjornsvik',
    'Royneberg',
    'Hommersak',
    'Nykirke',
    'Godvik',
    'Kjerrgarden',
    'Bovagen',
    'Sletta',
    'Hopland',
    'Loddefjord',
    'Svartskog',
    'Nesoddtangen',
    'Skedsmokorset',
    'Askøy',
    'Ørsta',
    'Brandbu',
    'Vedavagen',
    'Heimdal',
    'Vestnes',
    'Bardufoss',
    'Biri',
    'Evenes',
    'Oppakermoen',
    'Blaker',
    'Hafslo',
    'Kinsarvik',
    'Skjetten',
    'Geiranger',
    'Sandsli',
    'Hamresanden',
    'Stjordal',
    'Frosta',
    'Bardu',
    'Spikkestad',
    'Ryfoss',
    'Romskog',
    'Forsand',
    'Fjerdingby',
    'Hurdal',
    'Flisa',
    'Engerdal',
    'Lesja',
    'Ringebu',
    'Slidre',
    'Lierbyen',
    'Lampeland',
    'Revetal',
    'Andebu',
    'Borgheim',
    'Sauland',
    'Bokn',
    'Aksdal',
    'Gaupne',
    'Askvoll',
    'Hornindal',
    'Sykkylven',
    'Valderoy',
    'Elnesvagen',
    'Surnadal',
    'Rissa',
    'Roan',
    'Storen',
    'Borsa',
    'Leksvik',
    'Mosvik',
    'Namdalseid',
    'Hoylandet',
    'Joa',
    'Hattfjelldal',
    'Vagaholmen',
    'Sorreisa',
    'Brostadbotn',
    'Breivikbotn',
    'Tolvsrod',
    'Fannrem',
    'Nedre Heidal',
    'Radal',
    'Hjartdal',
    'Jostedal',
    'Hommelsto',
    'Lysekloster',
    'Romsas',
    'Vestskogen',
    'Tuddal',
    'Nesbru',
    'Aurland',
    'Vigra',
    'Billingstad',
    'Boverbru',
    'Kapp',
    'Ron',
    'Vingrom',
    'Luster',
    'Degernes',
    'Langhus',
    'Skarer',
    'Skulestadmo',
    'Favang',
    'Lesjaverk',
    'Akkerhaugen',
    'Heidal',
    'Royse',
    'Ostre Gausdal',
    'Sagstua',
    'Straumsjoen',
    'Yven',
    'Flatasen',
    'Bergenhus',
    'Fana',
    'Valestrandfossen',
    'Ovre Ardal',
    'Eikefjord',
    'Isfjorden',
    'Osteras',
    'His',
    'Kodal',
    'Vear',
    'Vikanes',
    'Finneidfjord',
    'Kvernaland',
    'Borre',
    'Hauge',
    'Nordagutu',
    'Oppegard',
    'Sundbyfoss',
    'Husnes',
    'Skotselv',
    'Harestua',
    'Haus',
    'Namna',
    'Sel',
    'Fiskabygd',
    'Svorkmo',
    'Spongdal',
    'Gralum',
    'Klokkarstua',
    'Brarud',
    'Olve',
    'Kattem',
    'Malmefjorden',
    'Bybrua',
    'Avaldsnes',
    'Manstad',
    'Fusa',
    'Skollenborg',
    'Seimsfoss',
    'Voyenenga',
    'Sofiemyr',
    'Algarheim',
    'Flaktveit',
    'Paradis',
    'Lonset',
    'Klepstad',
    'Borgen',
    'Asvang',
    'Liabygda',
    'Aureosen',
    'Beitstad',
    'Ekne',
    'Sundebru',
    'Vengsoya',
    'Sorskogbygda',
    'Alversund',
    'Bofjorden',
    'Sennesvik',
    'Kjopmannskjaer',
    'Iveland',
    'Oveland',
    'Homborsund',
    'Tobol',
    'Olensvag',
    'Vettre',
    'Leikanger',
    'Rasta',
    'Finstadjordet',
    'Skatval',
    'Tresfjord',
    'Fjellhamar',
    'Hauglandshella',
    'Stadsbygd',
    'Blomsterdalen',
    'Lommedalen',
    'Slependen',
    'Vuku',
    'Klavestadhaugen',
    'Eiksmarka',
    'Ytre Enebakk',
    'Krokkleiva',
    'Gjettum',
    'Karmsund',
    'Bjorbekk',
    'Torvastad',
    'Blommenholm',
    'Vallset',
    'Brottum',
    'Veitastrond',
    'Lierskogen',
    'Gjesasen',
    'Asnes Finnskog',
    'Skallestad',
    'Kurland',
    'Tarnasen',
    'Passebekk',
    'Stavsjo',
    'Amotsdal',
    'Arnatveit',
    'Ostereidet',
    'Mesnali',
    'Gardvik',
    'Mogreina',
    'Lovenstad',
    'Jonsvatnet',
    'Undrumsdal',
    'Varteig',
    'Strandvik',
    'Blystadlia',
    'Hoyheimsvik',
    'Duken',
    'Rossfjordstraumen',
    'Eidsvagneset',
    'Setskog',
    'Trollasen',
    'Bindalseidet',
    'Lierstranda',
    'Bjoa',
    'Biristrand',
    'Bulandet',
    'Nordnesoy',
    'Trogstad',
  ],
  Kosovo: [
    'Zvecan',
    'Shtime',
    'Prizren',
    'Pristina',
    'Orahovac',
    'Malisevo',
    'Lipa',
    'Leposaviq',
    'Mitrovica',
    'Klina',
    'Dragash',
    'Bresje',
    'Bare',
    'Brezovica',
    'Krivaca',
  ],
  Serbia: [
    'Zrenjanin',
    'Zitiste',
    'Semlin',
    'Zajecar',
    'Zagubica',
    'Zabalj',
    'Vršac',
    'Vrnjacka Banja',
    'Vranjska Banja',
    'Vranje',
    'Vojka',
    'Vlasotince',
    'Vlaole',
    'Gradiste',
    'Varvarin',
    'Uzdin',
    'Ugrinovci',
    'Ub',
    'Tutin',
    'Trstenik',
    'Tribrode',
    'Topola',
    'Titel',
    'Svrljig',
    'Svilajnac',
    'Surdulica',
    'Surduk',
    'Starcevo',
    'Stara Pazova',
    'Srpski Itebej',
    'Srpska Crnja',
    'Sremcica',
    'Sopot',
    'Smederevska Palanka',
    'Smederevo',
    'Sljivar',
    'Simanovci',
    'Senta',
    'Resavica',
    'Ralja',
    'Radinac',
    'Prokuplje',
    'Presevo',
    'Pozega',
    'Požarevac',
    'Porodin',
    'Popovac',
    'Pirot',
    'Paracin',
    'Pančevo',
    'Padina',
    'Ostruznica',
    'Opovo',
    'Obrenovac',
    'Novi Pazar',
    'Novi Belgrade',
    'Novi Banovci',
    'Nova Pazova',
    'Niš',
    'Negotin',
    'Adorjan',
    'Mladenovac',
    'Milosevac',
    'Melenci',
    'Mataruska Banja',
    'Martonos',
    'Majdanpek',
    'Lozovik',
    'Lokve',
    'Ljig',
    'Leskovac',
    'Lebane',
    'Lazarevac',
    'Kursumlija',
    'Kumane',
    'Kucevo',
    'Kruševac',
    'Krnjevo',
    'Krepoljin',
    'Kraljevo',
    'Kragujevac',
    'Kovin',
    'Kostolac',
    'Konak',
    'Klek',
    'Kladovo',
    'Kanjiza',
    'Kacarevo',
    'Jermenovci',
    'Jasa Tomic',
    'Jagodina',
    'Jablanka',
    'Izvarica',
    'Ivanjica',
    'Ingija',
    'Grocka',
    'Gornji Milanovac',
    'Macani',
    'Golubinci',
    'Ecka',
    'Durdevo',
    'Duga Poljana',
    'Drmno',
    'Donji Bunibrod',
    'Dobanovci',
    'Despotovac',
    'Debeljaca',
    'Curug',
    'Cuprija',
    'Cukarica',
    'Cubra',
    'Cortanovci',
    'Coka',
    'Cibukovac',
    'Cestereg',
    'Čačak',
    'Buvce',
    'Busur',
    'Bujanovc',
    'Brusnica',
    'Brus',
    'Brezane',
    'Branicevo',
    'Bosilegrad',
    'Borca',
    'Bor',
    'Boljevac',
    'Bogunovac',
    'Bogovina',
    'Bliznak',
    'Belgrade',
    'Beljevac',
    'Beli Potok',
    'Beli Breg',
    'Bela Crkva',
    'Bela',
    'Begaljica',
    'Becej',
    'Batajnica',
    'Basaid',
    'Banicina',
    'Banatsko Visnjicevo',
    'Banatsko Novo Selo',
    'Banatski Karlovac',
    'Bakionica',
    'Bajevac',
    'Badince',
    'Backo Gradiste',
    'Arilje',
    'Aradac',
    'Aleksinac',
    'Aleksandrovac',
    'Aldinac',
    'Alakince',
    'Adrovac',
    'Ada',
    'Raska',
    'Niska Banja',
    'Beljina',
    'Vozdovac',
    'Savski Venac',
    'Stari Grad',
    'Zmajevo',
    'Vrbas',
    'Veternik',
    'Valjevo',
    'Užice',
    'Tornjos',
    'Temerin',
    'Subotica',
    'Stara Moravica',
    'Srpski Miletic',
    'Karloca',
    'Sremska Mitrovica',
    'Kamenitz',
    'Srbobran',
    'Sonta',
    'Sombor',
    'Sivac',
    'Sirig',
    'Sid',
    'Sevojno',
    'Selenca',
    'Sabac',
    'Ruski Krstur',
    'Rumenka',
    'Ruma',
    'Ravno Selo',
    'Prigrevica',
    'Petrovaradin',
    'Pecinci',
    'Palic',
    'Odzaci',
    'Novi Sad',
    'Nikinci',
    'Nadalj',
    'Maradik',
    'Mali Zvornik',
    'Mali Radinci',
    'Majur',
    'Macvanska Mitrovica',
    'Loznica',
    'Lovcenac',
    'Ljubovija',
    'Lipnicki Sor',
    'Kula',
    'Kukujevci',
    'Krasava',
    'Kolut',
    'Kokin Brod',
    'Klenak',
    'Kac',
    'Jelenca',
    'Grgeteg',
    'Gospoginci',
    'Futog',
    'Donji Petrovci',
    'Deronje',
    'Cvetulja',
    'Conoplja',
    'Celarevo',
    'Bogatic',
    'Bezdan',
    'Beocin',
    'Begec',
    'Banjevac',
    'Bajsa',
    'Bajmok',
    'Bajina Basta',
    'Backo Dobro Polje',
    'Backi Petrovac',
    'Backa Topola',
    'Palanka',
    'Bac',
    'Apatin',
    'Visnjevac',
    'Zabari',
    'Zlatibor',
    'Knjazevac',
  ],
  Montenegro: [
    'Rozaje',
    'Zabljak',
    'Vladimir',
    'Ulcinj',
    'Tivat',
    'Sutomore',
    'Stari Bar',
    'Spuz',
    'Šavnik',
    'Podgorica',
    'Plužine',
    'Pljevlja',
    'Plav',
    'Petrovac na Moru',
    'Niksic',
    'Mojkovac',
    'Kotor',
    'Kolašin',
    'Berane',
    'Igalo',
    'Herceg Novi',
    'Gusinje',
    'Cetinje',
    'Budva',
    'Bar',
    'Andrijevica',
    'Kotorac',
    'Jasena',
    'Susanj',
    'Becici',
    'Dragija',
    'Dobre Vode',
    'Pernica',
    'Zabjelo',
  ],
  Namibia: [
    'Mpapuka',
    'Katima Mulilo',
    'Windhoek',
    'Tsumeb',
    'Swakopmund',
    'Rundu',
    'Rehoboth',
    'Otjiwarongo',
    'Oshikango',
    'Oshakati',
    'Oranjemund',
    'Ongwediva',
    'Ondangwa',
    'Okahandja',
    'Nkurenkuru',
    'Mariental',
    'Lüderitz',
    'Khorixas',
    'Keetmanshoop',
    'Grootfontein',
    'Eenhana',
    'Walvis Bay',
    'Outapi',
  ],
  Zimbabwe: [
    'Victoria Falls',
    'Ruwa',
    'Harare',
    'Gwanda',
    'Chinhoyi',
    'Bulawayo',
    'Beitbridge',
    'Mount Pleasant Park',
  ],
  Zambia: [
    'Sesheke',
    'Samfya',
    'Ndola',
    'Mpika',
    'Mongu',
    'Mansa',
    'Macha',
    'Lusaka',
    'Luanshya',
    'Livingstone',
    'Kitwe',
    'Kasama',
    'Kalomo',
    'Kabwe',
    'Isoka',
    'Chongwe',
    'Chipata',
    'Chingola',
    'Chama',
  ],
  Comoros: ['Ouanani', 'Ouani', 'Moutsamoudou', 'Moroni'],
  Mayotte: [
    'Sada',
    'Passamainty',
    'Pamandzi',
    'Mamoudzou',
    'Dzaoudzi',
    'Dembeni',
    'Acoua',
    'Handrema',
    'Combani',
    'Koungou',
    'Chirongui',
    'Kani Keli',
  ],
  Malawi: ['Mzuzu', 'Made', 'Lilongwe', 'Blantyre'],
  Lesotho: [
    'Teyateyaneng',
    'Roma',
    'Quthing',
    'Peka',
    'Mokhotlong',
    'Maseru',
    'Maputsoe',
    'Mafeteng',
    'Hlotse',
    'Butha-Buthe',
    'Thaba-Tseka',
    'Mokopung',
    'Ha Fou',
  ],
  Botswana: [
    'Tshabong',
    'Tlokweng',
    'Serowe',
    'Palapye',
    'Orapa',
    'Molepolole',
    'Mogoditshane',
    'Mochudi',
    'Maun',
    'Masunga',
    'Mahalapye',
    'Lobatse',
    'Letlhakane',
    'Kasane',
    'Kanye',
    'Ghanzi',
    'Gaborone',
    'Francistown',
    'Dekar',
    'Jwaneng',
  ],
  Mauritius: [
    'Vacoas',
    'Trou aux Biches',
    'Triolet',
    'Trianon',
    'The Vale',
    'Terre Rouge',
    'Tamarin',
    'Surinam',
    'Saint Pierre',
    'Saint Hubert',
    'Morcellement Saint Andre',
    'Rose Belle',
    'Roches Brunes',
    'Roches Noire',
    'Grande Riviere Noire',
    'Riviere du Rempart',
    'Riviere des Anguilles',
    'Ripailles',
    'Quatre Bornes',
    'Quartier Militaire',
    'Port Sainte Rita',
    'Port Louis',
    'Plaine Magnien',
    'Petit Raffray',
    'Petite Riviere',
    'Pamplemousses',
    'Pailles',
    'Nouvelle France',
    'New Grove',
    'Montagne Blanche',
    'Moka',
    'Midlands',
    'Mahebourg',
    "L'Escalier",
    'La Rosa',
    'Lalmatie',
    'Dagotiere',
    'Highlands',
    'Grand Gaube',
    'Grand Baie',
    'Grand Bois',
    'Goodlands',
    'Forbach',
    'Fond du Sac',
    'Floreal',
    'Flic en Flac',
    'Esperance Trebuchet',
    'Curepipe',
    'Chemin Grenier',
    'Centre de Flacq',
    'Cap Malheureux',
    'Camp Diable',
    'Brisee Verdiere',
    'Bois Cheri',
    'Belle Rose',
    'Beau Vallon',
    'Beau Bassin-Rose Hill',
    'Bambous',
    'Albion',
    'Cottage',
    'Pereybere',
    'Pointe aux Piments',
    'Baie du Tombeau',
    'Congomah',
    'Union Flacq',
    'Ville Noire',
    'La Gaulette',
    'Forest Side',
    'Pointe aux Sables',
    'Mapou',
    'Nouvelle Decouverte',
    'Arsenal',
    'Coromandel',
    'Port Mathurin',
    'Cargados Carajos',
    'Bain Boeuf',
    'Calodyne',
    'Beau-Bassin',
  ],
  Eswatini: [
    'Nhlangano',
    'Mhlambanyatsi',
    'Mbabane',
    'Matsapha',
    'Manzini',
    'Lobamba',
    'Kubuta',
    'Ezulwini',
    'Big Bend',
  ],
  Réunion: [
    'Salazie',
    'Saint-Pierre',
    'Saint-Philippe',
    'Saint-Paul',
    'Saint-Louis',
    'Saint-Leu',
    'Saint-Joseph',
    'Saint-Gilles les Bains',
    'Sainte-Suzanne',
    'Sainte-Rose',
    'Sainte-Marie',
    'Sainte-Clotilde',
    'Sainte-Anne',
    'Saint-Denis',
    'Saint-Benoit',
    'Saint-Andre',
    'La Ravine des Cabris',
    'Ravine des Cabris',
    'La Cour du Piton',
    'Petite-Ile',
    'Le Tampon',
    'Les Avirons',
    'Le Port',
    'La Saline',
    'La Riviere',
    'La Possession',
    'La Plaine-des-Palmistes',
    'La Plaine des Cafres',
    'La Montagne',
    "L'Etang-Sale les Bains",
    "L'Etang-Sale",
    'Cilaos',
    'Bras-Panon',
    "Bois d'Olives",
    'Le Grand Tampon les Bas',
    'Montauban',
    'Le Guillaume Jardin',
    'Saint-Coeur',
    'La Plaine',
    "L'Entre-Deux",
  ],
  'South Africa': [
    'Roodepoort',
    'Zeerust',
    'Wolmaransstad',
    'Witsieshoek',
    'White River',
    'Witpoortjie',
    'Witbank',
    'Winterton',
    'Wingate Park',
    'Windhoek',
    'Willowmore',
    'Wilderness',
    'Whittlesea',
    'Westville',
    'Westonaria',
    'Westdene',
    'Westbrook',
    'Wesselsbron',
    'Welkom',
    'Waverley',
    'Waterval Boven',
    'Waterfall',
    'Warrenton',
    'Walkerville',
    'Vryheid',
    'Vryburg',
    'Vredefort',
    'Vrede',
    'Vosloorus',
    'Volksrust',
    'Vivo',
    'Virginia',
    'Villiers',
    'Verulam',
    'Vereeniging',
    'Ventersdorp',
    'Ventersburg',
    'Vanderbijlpark',
    'Valhalla',
    'Vaalwater',
    'Vaalpark',
    'Vaal',
    'Uvongo Beach',
    'Utrecht',
    'Upington',
    'Umzumbe',
    'eMuziwezinto',
    'eMthenteni',
    'Mthatha',
    'eMkhomazi',
    'Umhlanga',
    'eMdloti',
    'Ulundi',
    'Uitenhage',
    'Ugie',
    'Tzaneen',
    'Turffontein',
    'Tshaneng',
    'Tsakane',
    'Trompsburg',
    'Trichardt',
    'oThongathi',
    'Three Rivers',
    'Thohoyandou',
    'Theunissen',
    'Thabazimbi',
    'Thaba Nchu',
    'Tembisa',
    'Temba',
    'Tarkastad',
    'Swellendam',
    'Swartruggens',
    'Suurbraak',
    'Sutherland',
    'Sunrise-on-Sea',
    'Stutterheim',
    'Stilfontein',
    'Still Bay',
    'KwaDukuza',
    'Standerton',
    'Springs',
    'Soweto',
    'Soshanguve',
    'Somerset East',
    'Siyabuswa',
    'Sinoville',
    'Silverton',
    'Sidwell',
    'Sibasa',
    'Sheffield Beach',
    'Sharpeville',
    'Shakaskraal',
    'Sezela',
    'Settlers',
    'Seshego',
    'Senekal',
    'Sedgefield',
    'Secunda',
    'Sebokeng',
    'Sea Park',
    'Scottburgh',
    'Schweizer-Reneke',
    'Sasolburg',
    'Sannieshof',
    'Sandton',
    'Sandown',
    'Sandhurst',
    'Salt Rock',
    'Saint Lucia',
    'Saint Francis',
    'Sabie',
    'Rustenburg',
    'Rosendal',
    'Rooihuiskraal',
    'Rooiberg',
    'Robertsham',
    'Rivonia',
    'Rietkuil',
    'Richmond',
    'Richards Bay',
    'Rensburgdorp',
    'Reitz',
    'Rayton',
    'Randfontein',
    'Randburg',
    'Qumbu',
    'Queenstown',
    'Queensburgh',
    'Protea',
    'Primrose',
    'Prieska',
    'Pretoria',
    'Mokopane',
    'Potchefstroom',
    'Postmasburg',
    'Port Shepstone',
    'Port Elizabeth',
    'Port Alfred',
    'Pongola',
    'Plettenberg Bay',
    'Pinetown',
    'Pimville',
    'Piet Retief',
    'Polokwane',
    'Pietermaritzburg',
    'Pienaarsrivier',
    'Phuthaditjhaba',
    'Phoenix',
    'Phalaborwa',
    'Petrus Steyn',
    'Peddie',
    'Paul Roux',
    'Paulpietersburg',
    'Patensie',
    'Parys',
    'Parkview',
    'Park Rynie',
    'Oudtshoorn',
    'Orkney',
    'Orania',
    'Orange Farm',
    'Onderstepoort',
    'Olifantsfontein',
    'Odendaalsrus',
    'eNyoni',
    'Modimolle',
    'Nottingham Road',
    'Northcliff',
    'Northam',
    'Noordheuwel',
    'Nigel',
    'New Germany',
    'Newcastle',
    'Nelspruit',
    'Mookgophong',
    'Musgrave',
    'Muldersdrift',
    'Mtunzini',
    'Mount Frere',
    'Mount Edgecombe',
    'Mount Ayliff',
    'Mossel Bay',
    'Mooi River',
    'Monument Park',
    'Montagu',
    'Mohlakeng',
    'Mogwase',
    'Modderfontein',
    'Mmabatho',
    'Mid-Ennerdale',
    'Middelburg',
    'Meyerton',
    'Merrivale',
    'Menlo Park',
    'Melmoth',
    'Meadowlands',
    'Matatiele',
    'Marquard',
    'Marina Beach',
    'Margate',
    'Marburg',
    'Mamelodi',
    'Malelane',
    'Makwassie',
    'Magaliesburg',
    'Mafikeng',
    'Mabopane',
    'Lyttelton',
    'Lynnwood',
    'Lydenburg',
    'Lusikisiki',
    'Louterwater',
    'Louis Trichardt',
    'Linden',
    'Lichtenburg',
    'Levubu',
    'Letsitele',
    'Lenasia',
    'Leeudoringstad',
    'Lebowakgomo',
    'Leandra',
    'Laudium',
    'La Lucia',
    'Ladysmith',
    'Ladybrand',
    'Ladismith',
    'KwaThema',
    'KwaNgendezi',
    'KwaNdlovu',
    'KwaMbonambi',
    'Kuruman',
    'Krugersdorp',
    'Kroonstad',
    'Kriel',
    'Komatipoort',
    'Kokstad',
    'Koffiefontein',
    'Knysna',
    'Kloof',
    'Klerksdorp',
    'Kirkwood',
    'Kinross',
    "King William's Town",
    'Kingsborough',
    'Kimberley',
    'Kew',
    'Kenton on Sea',
    'Kensington',
    'Kenilworth',
    'Kempton Park',
    'Keimoes',
    'Katlehong',
    'Kathu',
    'Kareedouw',
    'Kakamas',
    'Kagiso',
    'Jozini',
    'Joubertina',
    'Johannesburg',
    'Jeffreys Bay',
    'Jan Kempdorp',
    'Izotsha',
    'Ixopo',
    'Isipingo Beach',
    'Isipingo',
    'Isando',
    'Irene',
    'Humansdorp',
    'Howick',
    'Houghton Estate',
    'Hotazel',
    'Hopetown',
    'Honeydew',
    'Hogsback',
    'Hoedspruit',
    'Hluhluwe',
    'Hilton',
    'Hillcrest',
    'Highflats',
    'Hibberdene',
    'Hertzogville',
    'Hennenman',
    'Henley on Klip',
    'Hendrina',
    'Heilbron',
    'Heidelberg',
    'Hectorspruit',
    'Hazyview',
    'Hatfield',
    'Hartswater',
    'Hartenbos',
    'Hartebeespoort',
    'Hartbeespoort',
    'Harrismith',
    'Harding',
    'Hammanskraal',
    'Halfway House',
    'Haenertsburg',
    'Groot-Brakrivier',
    'Groblersdal',
    'Greyville',
    'Greytown',
    'Grahamstown',
    'Graaff Reinet',
    'Gonubie',
    'Glenwood',
    'Glencoe',
    'Giyani',
    'Gillitts',
    'Germiston',
    'George',
    'Frankfort',
    'Fourways',
    'Fouriesburg',
    'Fordsburg',
    'Fontainebleau',
    'Fochville',
    'Flagstaff',
    'Ficksburg',
    'Ferndale',
    'Fairland',
    'Evaton',
    'Estcourt',
    'eSikhaleni',
    'Eshowe',
    'Ermelo',
    'Erasmia',
    'Empangeni',
    'eMbalenhle',
    'Eldoradopark',
    'Elarduspark',
    'Edenvale',
    'Edendale',
    'East London',
    'Duxberry',
    'Durban',
    'Dunnottar',
    'Dundee',
    'Duiwelskloof',
    'Duduza',
    'Dube',
    'Douglas',
    'Dobsonville',
    'Despatch',
    'Derby',
    'Deneysville',
    'Delville',
    'Delmas',
    'Delareyville',
    'De Deur',
    'De Aar',
    'Darnall',
    'Danielskuil',
    'Dalview',
    'Dalton',
    'Cullinan',
    'Creighton',
    'Craighall Park',
    'Craighall',
    'Coligny',
    'Colesberg',
    'Clubview East',
    'Clayville',
    'Christiana',
    'Chatsworth',
    'Carnarvon',
    'Carletonville',
    'Cambridge',
    'Butterworth',
    'Burgersfort',
    'Bultfontein',
    'Bryanston',
    'Bronkhorstspruit',
    'Brixton',
    'Brits',
    'Bredasdorp',
    'Brandfort',
    'Brakpan',
    'Botshabelo',
    'Bothaville',
    'Boshoek',
    'Bosbokrand',
    'Bophelong',
    'Booysens',
    'Boksburg',
    'Boknes',
    'Boesmansriviermond',
    'Blythedale Beach',
    'Bloemhof',
    'Bloemfontein',
    'Black Rock',
    'Bhisho',
    'Bethlehem',
    'Bethal',
    'Bergville',
    'Berea',
    'Benoni',
    'Belfast',
    'Bedfordview',
    'Bedford',
    'Beaufort West',
    'Beacon Bay',
    'Barrydale',
    'Barkly East',
    'Barberton',
    'Baltimore',
    'Ballitoville',
    'Balgowan',
    'Aston Bay',
    'Ashton',
    'Ashley',
    'Anerley',
    'Amsterdam',
    'eManzimtoti',
    'Alldays',
    'Allandale',
    'Aliwal North',
    'Alice',
    'Alexandra',
    'Alberton',
    'L’Agulhas',
    'Adelaide',
    'Addo',
    'Elandsfontein',
    'Ekangala',
    'Kwamhlanga',
    'Akasia',
    'Garsfontein',
    'Midrand',
    'Centurion',
    'Tierpoort',
    'Yzerfontein',
    'Wynberg',
    'Worcester',
    'Woodstock',
    'Wolseley',
    'Wellington',
    'Welgemoed',
    'Vyeboom',
    'Vredendal',
    'Vredenburg',
    'Villiersdorp',
    'Vermont',
    'Velddrif',
    'Tulbagh',
    'Tokai',
    'Table View',
    'Strand',
    'Stompneusbaai',
    'Stellenbosch',
    'Stellenberg',
    'Stanford',
    'Springbok',
    'Salt River',
    'Sonstraal',
    'Sea Point',
    'Scarborough',
    'Saron',
    'Sandbaai',
    'Saldanha',
    'Saint James',
    'Saint Helena Bay',
    'Rosebank',
    'Robertson',
    'Riebeek West',
    'Riebeek-Kasteel',
    'Rawsonville',
    'Pringle Bay',
    'Prince Alfred Hamlet',
    'Porterville',
    'Plumstead',
    'Pinelands',
    'Piketberg',
    'Philadelphia',
    'Parow',
    'Paarl',
    'Onrus',
    'Ocean View',
    'Observatory',
    'Nyanga',
    'Napier',
    'Mouille Point',
    'Moorreesburg',
    'Mitchells Plain',
    'Milnerton',
    'Melkbosstrand',
    'McGregor',
    'Matroosfontein',
    'Mamre',
    'Malmesbury',
    'Macassar',
    'Lutzville',
    'Lansdowne',
    'Langebaanweg',
    'Langebaan',
    'Lamberts Bay',
    'Kuils River',
    'Kraaifontein',
    'Koringberg',
    'Kommetjie',
    'Kleinmond',
    'Klawer',
    'Khayelitsha',
    'Hout Bay',
    'Hopefield',
    'Hermanus',
    'Helderberg',
    'Heathfield',
    'Hawston',
    'Grabouw',
    "Gordon's Bay",
    'Gansbaai',
    'Franschhoek',
    'Eversdal',
    'Elandsbaai',
    'Eendekuil',
    'Edgemead',
    'Dwarskersbos',
    'Durbanville',
    'De Doorns',
    'Darling',
    'Claremont',
    'Clanwilliam',
    'Citrusdal',
    'Ceres',
    'Cape Town',
    'Calvinia',
    'Caledon',
    'Brackenfell',
    'Botrivier',
    'Bloubergstrand',
    "Betty's Bay",
    'Bellville',
    'Belhar',
    'Athlone',
    'Gordons Bay',
    'Umhlali',
    'Century City',
    'Somerset West',
    'Rossmore',
    'Fish Hoek',
    'Jukskei Park',
    'Kelvin',
    'Bonaero Park',
    'Krugersdorp North',
    'Paulshof',
    'Greenhills',
    'Douglasdale',
    'Mogale City',
    'Constantia Kloof',
    'Equestria',
    'Newlands',
    'Bela-Bela',
    'Lanseria',
    'Lephalale',
    'Auckland Park',
    'Melville',
    'Alberton North',
    'Cresta',
    'Boksburg North',
    'Bruma',
    'Florida Glen',
    'Helderkruin',
    'Lambton',
    'Meadowdale',
    'Menlyn',
    'Mondeor',
    'North Riding',
    'Randvaal',
    'Robertville',
    'Spartan',
    'Sunninghill',
    'Woodmead',
    'Ifafi',
    'Bramley',
    'Morningside Manor',
    'Moreleta Park',
    'Ruimsig',
    'Musina',
    'Zimbali',
    'Dainfern',
    'Olivedale',
    'Bromhof',
    'Midstream',
    'Birchleigh',
    'Weltevreedenpark',
    'Noordwyk',
    'Melrose',
    "Allen's Nek",
    'Bedford Gardens',
    'Benmore Gardens',
    'Broadacres',
    'Cowies Hill',
    'Erasmuskloof',
    'Kyalami Park',
    'Malanshof',
    'Mandeni',
    'South Hills',
    'St Francis Bay',
    'Sundowner',
    'Wadeville',
    'Mooikloof',
    'Rhodesfield',
    'Plattekloof',
    'Klapmuts',
    'Newlands East',
    'Broederstroom',
    'Parktown North',
    'Diepkloof Zone 5',
    'Marikana',
    'Vorna Valley',
    'Orlando East',
    'Bloubosrand',
    'Rabie Ridge',
    'Devland',
    'Eldorado Park',
    'Leondale',
    'Ntuzuma',
    'Mayfair West',
    'Croydon',
    'Wierdapark',
    'Kibler Park',
    'Bardene',
    'Chesterville',
    'Jet Park',
  ],
  Mozambique: [
    'Vilankulo',
    'Tete',
    'Ressano Garcia',
    'Quelimane',
    'Pemba',
    'Nampula',
    'Morrumbene',
    'Maxixe',
    'Matola',
    'Maputo',
    'Lichinga',
    'Chimoio',
    'Chibuto',
    'Beira',
    'Machava',
  ],
  Madagascar: [
    'Toamasina',
    'Toliara',
    'Sambava',
    'Mahajanga',
    'Fianarantsoa',
    'Antsiranana',
    'Antsirabe',
    'Antananarivo',
    'Ambondrona',
    'Ambodivona',
    'Ambodirano',
    'Ambodilazana',
    'Ambanja',
    'Ambanimaso',
    'Ambalamanga',
    'Alakamisy',
  ],
  Pakistan: [
    'Kheshgi Bāla',
    'Zhob',
    'Wazirabad',
    'Wan Bhachran',
    'Wana',
    'Wah',
    'Vehari',
    'Ubauro',
    'Tordher',
    'Toba Tek Singh',
    'Thatta',
    'Taunsa',
    'Tarlai Kalan',
    'Tando Jam',
    'Tando Allahyar',
    'Tando Adam',
    'Talagang',
    'Swabi',
    'Sukkur',
    'Sialkot',
    'Shujaabad',
    'Shekhupura',
    'Sharqpur Sharif',
    'Shahkot',
    'Shahdadpur',
    'Shabqadar',
    'Sargodha',
    'Sarai Naurang',
    'Sarai Alamgir',
    'Sangla Hill',
    'Sanāwān',
    'Sambrial',
    'Salim Khan',
    'Sahiwal',
    'Saddiqabad',
    'Sadda',
    'Rukan',
    'Renala Khurd',
    'Rawalpindi',
    'Raiwind',
    'Rahim Yar Khan',
    'Chenab Nagar',
    'Quetta',
    'Pir Mahal',
    'Pindi Gheb',
    'Phalia',
    'Peshawar',
    'Pattoki',
    'Pasrur',
    'Parachinar',
    'Pano Aqil',
    'Palandri',
    'Pakpattan',
    'Okara',
    'Nushki',
    'Nowshera',
    'New Mirpur',
    'Nawabshah',
    'Narowal',
    'Nankana Sahib',
    'Muzaffargarh',
    'Muzaffarabad',
    'Multan',
    'Mirpur Khas',
    'Mīrpur Batoro',
    'Mirpur',
    'Miran Shah',
    'Mingora',
    'Mianwali',
    'Mian Channu',
    'Matli',
    'Mastung',
    'Mari Khurd',
    'Mardan',
    'Mansehra',
    'Mangla',
    'Mandi Bahauddin',
    'Malakwal',
    'Malakand',
    'Mailsi',
    'Lodhran',
    'Liaquatpur',
    'Layyah',
    'Layari',
    'Larkana',
    'Landi Kotal',
    'Lalian',
    'Lalamusa',
    'Lakki',
    'Lahore',
    'Kotri',
    'Kotli',
    'Kotla Arab Ali Khan',
    'Kot Addu',
    'Kohat',
    'Khushab',
    'Khewra',
    'Karak',
    'Khanpur',
    'Khanewal',
    'Qazian',
    'Kasur',
    'Kassowal',
    'Kashmor',
    'Karachi',
    'Kamoke',
    'Kamalia',
    'Kala Gujran',
    'Kahror Pakka',
    'Jhelum',
    'Jauharabad',
    'Jamshoro',
    'Jampur',
    'Jalalpur Pirwala',
    'Jahangira',
    'Jacobabad',
    'Islamabad',
    'Hyderabad',
    'Hujra Shah Muqim',
    'Hazro City',
    'Havelian',
    'Haveli Lakha',
    'Hasilpur',
    'Hassan Abdal',
    'Harun',
    'Haripur',
    'Hangu',
    'Hafizabad',
    'Gujrat',
    'Gujranwala',
    'Gujar Khan',
    'Gojra',
    'Gilgit',
    'Ghotki',
    'Ghakhar',
    'Chak Two Hundred Forty-nine Thal Development Authority',
    'Faisalabad',
    'Dunyapur',
    'Dipalpur',
    'Dinga',
    'Dina',
    'Digri',
    'Dera Ismail Khan',
    'Dera Ghazi Khan',
    'Daur',
    'Daultala',
    'Daska Kalan',
    'Darya Khan',
    'Dandot',
    'Damorai',
    'Dadu',
    'Clifton',
    'Chishtian',
    'Chiniot',
    'Chichawatni',
    'Charsadda',
    'Chakwal',
    'Burewala',
    'Bhimbar',
    'Bhawana',
    'Bhalwal',
    'Bhakkar',
    'Basirpur',
    'Bannu',
    'Bahawalpur',
    'Badin',
    'Baddomalhi',
    'Arifwala',
    'Alipur',
    'Akora',
    'Aima Shahji',
    'Ahmadpur East',
    'Abbottabad',
    'Jhang City',
    'Muridke',
    'Aba Khel',
    'Nar Hafizabad',
    'Marghuz Kalle',
    'Dhok Parāli',
    'Cantt',
    'Rashang',
    'Azad',
    'Baffa',
    'Faisal Town',
    'Chak One Hundred Thirty-five',
    'Kharian',
    'Fateh',
    'Baghbanpura',
    'Risālpur',
    'Baldia Town',
    'Nasir Colony',
    'Model Colony',
    'Thorar',
    'Bihari',
    'Malakwala',
    'Ahmad Nagar',
    'Khaur',
    'Aliwala',
    'Qasimabad',
    'Pakki Pul',
    'Ghous Pur',
    'Sukheki',
    'Attock',
    'Sheikhupura',
    'Tarbela Dam',
    'Daharki',
    'Taxila',
  ],
  Thailand: [
    'Ban Wiang',
    'Ban Tha Pai',
    'Choeng Thale',
    'Sam Roi Yot',
    'Ban Pong',
    'Ban Lup',
    'Ban Klang',
    'Ban Khun Tak',
    'Ban Du',
    'Ban Dong Huai Yen',
    'Phetchaburi',
    'Wiang Sa',
    'Wiang Pa Pao',
    'Trang',
    'Thung Song',
    'Tha Maka',
    'Thalang',
    'Tha Chana',
    'Takua Thung',
    'Tak',
    'Surat Thani',
    'Sukhothai Thani',
    'Si Samrong',
    'Saraphi',
    'San Sai',
    'San Kamphaeng',
    'Sai Ngam',
    'Ratchaburi',
    'Ranong',
    'Pran Buri',
    'Prachuap Khiri Khan',
    'Phuket',
    'Photharam',
    'Phayao',
    'Phang Nga',
    'Phan',
    'Pa Sang',
    'Ngao',
    'Nakhon Si Thammarat',
    'Mae Sai',
    'Lamphun',
    'Lampang',
    'Krabi',
    'Ko Lanta Yai',
    'Khun Yuam',
    'Khanu Woralaksaburi',
    'Kathu',
    'Kanchanaburi',
    'Kamphaeng Phet',
    'Hua Hin',
    'Hang Dong',
    'Hang Chat',
    'Fang',
    'Chumphon',
    'Chiang Rai',
    'Chiang Mai',
    'Chiang Dao',
    'Chawang',
    'Chai Buri',
    'Cha-am',
    'Ban Tak',
    'Ko Samui',
    'Ban Song',
    'Ban San Sai',
    'Ban Sa',
    'Rawai',
    'Ban Pin',
    'Ban Pao',
    'Ban Na Klang',
    'Ban Muang',
    'Ban Krabi Noi',
    'Khuk Khak',
    'Ban Khuan',
    'Ban Kham',
    'Ban Kao',
    'Don Tum',
    'Ban Dong Chueak',
    'Ban Don Chedi',
    'Ban Bang Tanot',
    'Ao Nang',
    'Amphawa',
    'Ban Puai',
    'Muaklek',
    'Ko Pha Ngan',
    'Ban Ton',
    'Ban Suan',
    'Ban Phu Lon Noi',
    'Ban Phae',
    'Nong Hin',
    'Ban Lao Phatthana',
    'Ban Lao Yai',
    'Ban Lao',
    'Ban Kut Khon Kaen',
    'Ban Kho',
    'Ban Kha',
    'Fao Rai',
    'Ban Fang',
    'Ban Duea',
    'Waeng',
    'Tak Fa',
    'Bacho',
    'Yasothon',
    'Yan Nawa',
    'Yala',
    'Yaha',
    'Uttaradit',
    'Uthumphon Phisai',
    'Uthai Thani',
    'Udon Thani',
    'Ubon Ratchathani',
    'Trat',
    'Thon Buri',
    'Thoeng',
    'Tha Tako',
    'Thanyaburi',
    'Taling Chan',
    'Takhli',
    'Suwannaphum',
    'Surin',
    'Suphan Buri',
    'Songkhla',
    'Soeng Sang',
    'Si Sa Ket',
    'Si Racha',
    'Si Prachan',
    'Sing Buri',
    'Si Bun Rueang',
    'Sena',
    'Selaphum',
    'Seka',
    'Sawang Daen Din',
    'Satun',
    'Satuek',
    'Sattahip',
    'Sathing Phra',
    'Saraburi',
    'Sankhaburi',
    'Sangkha',
    'Samut Songkhram',
    'Samut Sakhon',
    'Samut Prakan',
    'Sakon Nakhon',
    'Sa Kaeo',
    'Sadao',
    'Roi Et',
    'Rayong',
    'Rattanaburi',
    'Raman',
    'Pua',
    'Prachin Buri',
    'Pong',
    'Amphoe Phra Pradaeng',
    'Ayutthaya',
    'Prakanong',
    'Phrae',
    'Pho Thale',
    'Phon Charoen',
    'Phon',
    'Phitsanulok',
    'Phichit',
    'Phetchabun',
    'Phatthalung',
    'Phasi Charoen',
    'Phan Thong',
    'Phanna Nikhom',
    'Phang Khon',
    'Phanat Nikhom',
    'Phana',
    'Phachi',
    'Pattani',
    'Pathum Thani',
    'Pa Mok',
    'Pak Thong Chai',
    'Pak Kret',
    'Pak Chong',
    'Pa Daet',
    'Nonthaburi',
    'Non Sung',
    'Nong Khai',
    'Nong Chok',
    'Nong Bua Lamphu',
    'Nong Bua',
    'Narathiwat',
    'Nan',
    'Nakhon Thai',
    'Nakhon Sawan',
    'Nakhon Ratchasima',
    'Nakhon Phanom',
    'Nakhon Pathom',
    'Nakhon Nayok',
    'Mukdahan',
    'Min Buri',
    'Manorom',
    'Maha Sarakham',
    'Lopburi',
    'Lom Sak',
    'Loei',
    'Lat Lum Kaeo',
    'Lam Luk Ka',
    'Kuchinarai',
    'Bangkok',
    'Krathum Baen',
    'Krasang',
    'Kranuan',
    'Kosum Phisai',
    'Klaeng',
    'Khon Kaen',
    'Khlung',
    'Khlong San',
    'Khlong Luang',
    'Khao Wong',
    'Khamcha-i',
    'Kantharawichai',
    'Kantharalak',
    'Kamphaeng Saen',
    'Kalasin',
    'Kaeng Khro',
    'In Buri',
    'Hua Sai',
    'Hat Yai',
    'Dusit',
    'Doembang Nangbuat',
    'Chum Phuang',
    'Chum Phae',
    'Chonnabot',
    'Chon Buri',
    'Chiang Khong',
    'Cha-uat',
    'Chanthaburi',
    'Chana',
    'Chaiyaphum',
    'Chainat',
    'Chai Badan',
    'Chachoengsao',
    'Buriram',
    'Bueng Kan',
    'Bua Yai',
    'Borabue',
    'Betong',
    'Ban Yang',
    'Ban Loei Wang Sai',
    'Ban Thin',
    'Ban Thaen',
    'Rangsit City Municipality',
    'Ban Sang',
    'Ban Sai',
    'Ban Khrua',
    'Ban Phran',
    'Pattaya',
    'Ban Phan Thong Luang',
    'Ban Phan Don',
    'Ban Pa Tai',
    'Pak Nam Pran',
    'Ban Pak Nam Krachae',
    'Ban Noen Sung',
    'Ban Na Phen',
    'Ban Na',
    'Ban Mung Nuea',
    'Ban Muang Charoen Phon',
    'Ban Mo',
    'Ban Mai',
    'Ban Laeng',
    'Ban Kut Ta Lat',
    'Ban Klap',
    'Ban Khok',
    'Ban Khoi Tai',
    'Ban Khlong Suan',
    'Ban Khlong Prawet',
    'Ban Khlong Ngae',
    'Ban Khai',
    'Ban Kat',
    'Ban Kaeng',
    'Bang Rakam',
    'Bangrak',
    'Bang Pa-in',
    'Bang Len',
    'Bang Lamung',
    'Bang Kruai',
    'Bang Krathum',
    'Bangkok Yai',
    'Bangkok Noi',
    'Bang Khun Thian',
    'Bang Khlo',
    'Bang Kapi',
    'Bang Bua Thong',
    'Bang Bo',
    'Ban Dong',
    'Ban Dan Noen Sung',
    'Ban Dan',
    'Ban Chiang Khian',
    'Ban Chiang',
    'Ban Chang',
    'Ban Chan',
    'Ban Chai',
    'Ban Bueng',
    'Ban Bung',
    'Ban Bo',
    'Ban Bang Yang',
    'Ban Laem Bang Yang',
    'Bang Na',
    'Bang Klam',
    'Ban Bang Khanak',
    'Ban Bang Chak',
    'Ban Ang Sila',
    'Lue Amnat',
    'Ban Ai',
    'At Samat',
    'Amphoe Aranyaprathet',
    'Ang Thong',
    'Amnat Charoen',
    'Akat Amnuai',
    'Bo Phut',
    'Karon',
    'Ban Choeng Doi',
    'Sukhumvit',
    'Koh Tao',
    'Bang Sue',
    'Don Mueang',
    'Salaya',
    'Ban Wat Bang Phli Yai Nai',
    'Ban Bang Phli Nakhon',
    'Khlong Toei',
    'Patong',
    'Lat Krabang',
    'Bang Khae',
    'Chatuchak',
    'Huai Khwang',
    'Khlong Sam Wa',
    'Ratchathewi',
    'Saphan Sung',
    'Sathon',
    'Suan Luang',
    'Thung Khru',
    'Watthana',
    'Chalong',
    'Ban Songkhla Nakharin',
    'Bang Sao Thong',
    'Lamai Beach',
    'Bang Bai Mai',
    'Ko Chan',
    'Si Mahosot',
    'Nathom',
    'Sichon',
    'Ban Hin Ngom',
    'Ban Chit',
    'Maret',
    'Khanom',
    'Ban Ko',
    'Ban Rim Nan',
    'Bua Lai',
    'Ban Mai Chumsaeng',
    'Mae Hi',
    'Bang Bon',
    'Ban Don',
    'Din Daeng',
    'Ban Phunphin',
    'Ban Thai Samakkhi',
    'Ban Nokkhao Plao',
    'Ban Nong Muang',
    'Sathorn',
    'Silom',
    'Latkrabang',
    'Kuraburi',
  ],
  Afghanistan: ['Kandahar', 'Kabul', 'Herat', 'Charikar', 'Ab Jalil'],
  Bangladesh: [
    'Thakurgaon',
    'Tejgaon',
    'Tongi',
    'Sylhet',
    'Noakhali',
    'Jamalpur',
    'Satkhira',
    'Sirajganj',
    'Narsingdi',
    'Rajshahi',
    'Nawabganj',
    'Narayanganj',
    'Nageswari',
    'Mymensingh',
    'Muktagacha',
    'Lalmonirhat',
    'Comilla',
    'Rangpur City',
    'Habiganj',
    'Gaurnadi',
    'Gafargaon',
    'Feni',
    'Dhaka',
    'Chunarughat',
    'Chhatak',
    'Kalia',
    'Wari',
    'Ukhiya',
    'Talma',
    'Sibpur',
    'Sachar',
    'Prasadpur',
    'Narundi',
    'Naogaon',
    'Nandigram',
    'Nagarbari',
    'Nabinagar',
    'Mirpur',
    'Matuail',
    'Madhabdi',
    'Lakshmipu',
    'Lakshmipur',
    'Kushtia',
    'Kumira',
    'Kumarkhali',
    'Khilgaon',
    'Jigatala',
    'Gunabati',
    'Ghazipur',
    'Gangni',
    'Faridpur',
    'Dinajpur',
    'Dhala',
    'Demra',
    'Chittagong',
    'Kasba',
    'Chandpur',
    'Ashuganj City',
    'Amjhupi',
    'Abdullahpur',
    'Khulna',
    'Bhola District',
    'Barisal',
    'Jessore',
    'Brahmanbaria',
    'Pabna',
    'Tangail',
    'Bogra',
    'Sherpur',
    'Kishorganj',
    'Mongla',
    'Manikganj',
    'Savar Upazila',
    'Hathazari',
    'Chandgaon',
    'Rajbari',
    'Mahadebpur',
    'Narail',
    'Gopalganj',
    'Mohammadpur',
    'Natore',
    'Damurhuda',
    'Kaligonj',
    'Gendaria',
    'Chuadanga',
    'Kurigram',
    'Nilphamari',
    'Magura District',
    'Shariatpur District',
    'Joypurhat',
    'Kalai',
    'Gaibandha',
    'Bhaluka',
    'Haragachh',
    'Uttara',
    'Meherpur',
    'Patuakhali',
    'Chatmohar',
    'Jhalakati',
    'Golapganj',
    'Pabna Sadar',
    'Shahjadpur',
  ],
  Indonesia: [
    'Tebingtinggi',
    'Tanjung Morawa',
    'Sigli',
    'Sidikalang',
    'Saribudolok',
    'Pematangsiantar',
    'Panton Labu',
    'Medan',
    'Lubuk Pakam',
    'Lhokseumawe',
    'Langsa',
    'Kisaran',
    'Kabanjahe',
    'Galang',
    'Bali',
    'Banda Aceh',
    'Yogyakarta',
    'Wonogiri',
    'Weleri',
    'Watansoppeng',
    'Watampone',
    'Ungaran',
    'Makassar',
    'Ubud',
    'Tulungagung',
    'Tuban',
    'Trenggalek',
    'Tondano',
    'Tomohon',
    'Timika',
    'Terusan',
    'Ternate',
    'Terbanggi Besar',
    'Tenggarong',
    'Tembilahan',
    'Tegal',
    'Tebet Barat',
    'Tasikmalaya',
    'Tarakan',
    'Tanjung Selor',
    'Tanjung Redeb',
    'Tanjung Pinang',
    'Tanjung Priok',
    'Bandar Lampung',
    'Tanjung Balai',
    'Tangerang',
    'Taliwang',
    'Tabanan',
    'Surakarta',
    'Surabaya',
    'Sungailiat',
    'Sumenep',
    'Sumedang',
    'Sumbawa Besar',
    'Sukabumi',
    'Subang',
    'Sragen',
    'Sorong',
    'Solok',
    'Sofifi',
    'Soe',
    'Sleman',
    'Slawi',
    'Singkawang',
    'Sengkang',
    'Singaraja',
    'Sidoarjo',
    'Siak Sri Indrapura',
    'Serpong',
    'Serang',
    'Sangatta',
    'Semarang',
    'Sawahlunto',
    'Sarau',
    'Sanggau',
    'Sampit',
    'Samarinda',
    'Salatiga',
    'Rengat',
    'Rembangan',
    'Rantepao',
    'Rangkasbitung',
    'Randudongkal',
    'Purwokerto',
    'Purwakarta',
    'Purbalingga',
    'Probolinggo',
    'Pringsewu',
    'Pontianak',
    'Ponorogo',
    'Polewali',
    'Penjaringan',
    'Pemalang',
    'Pelaihari',
    'Pekalongan',
    'Payakumbuh',
    'Pati',
    'Pasuruan',
    'Pariaman',
    'Parepare',
    'Pangkalpinang',
    'Pangkalanbuun',
    'Pangkalan Balai',
    'Panggul',
    'Pandeglang',
    'Pancoran',
    'Panasuan',
    'Pamulang',
    'Pamekasan',
    'Pamanukan',
    'Palu',
    'Palembang',
    'Palangkaraya',
    'Padang',
    'Ngawi',
    'Nganjuk',
    'Ngabang',
    'Nangah Pinoh',
    'Muara Enim',
    'Mojokerto',
    'Mogoi',
    'Mijen',
    'Metro',
    'Mempawah',
    'Maumere',
    'Mataram',
    'Martapura',
    'Maros',
    'Marisa',
    'Manokwari',
    'Manado',
    'Mamuju',
    'Malinau',
    'Malang',
    'Majalengka',
    'Magetan',
    'Magelang',
    'Madiun',
    'Luwuk',
    'Lumbuk',
    'Lumajang',
    'Lubuklinggau',
    'Labuan Lombok',
    'Lamongan',
    'Lahat',
    'Kurintji',
    'Kuningan',
    'Kudus',
    'Kuala Tungkal',
    'Kualakapuas',
    'Kotabumi',
    'Kotamobagu',
    'Klungkung',
    'Klaten',
    'Kijang',
    'Ketapang',
    'Kendari',
    'Kendal',
    'Kediri',
    'Kebon Jeruk',
    'Kebayoran Lama Selatan',
    'Kebayoran Baru',
    'Kawangkoan',
    'Karawang',
    'Amlapura',
    'Kepahiang',
    'Kandangan',
    'Kalideres',
    'Jombang',
    'Jepara',
    'Jember',
    'Jatiroto',
    'Jatinegara',
    'Jambi City',
    'Jakarta',
    'Jagakarsa',
    'Indramayu',
    'Uludanau',
    'Grobogan',
    'Gresik',
    'Gorontalo',
    'Gombong',
    'Godong',
    'Gianyar',
    'Garut',
    'Gandaria',
    'Gambir',
    'Fakfak',
    'Duri',
    'Dumai',
    'Denpasar',
    'Demak',
    'Curup',
    'Comal',
    'Cirebon',
    'Ciputat',
    'Cipondoh',
    'Cilincing',
    'Cileungsir',
    'Cilegon',
    'Cilangkap',
    'Cilandak',
    'Cilacap',
    'Cikarang',
    'Cikampek',
    'Cibinong',
    'Cianjur',
    'Ciamis',
    'Cemara',
    'Cakung',
    'Buntok',
    'Bulukumba',
    'Bukittinggi',
    'Brebes',
    'Boyolali',
    'Bontang',
    'Bondowoso',
    'Bojonegoro',
    'Bogor',
    'Blora',
    'Blitar',
    'Blimbing',
    'Bitung',
    'Bengkulu',
    'Bengkalis',
    'Belimbing',
    'Bekasi',
    'Bawang',
    'Batusangkar',
    'Baturaja',
    'Batulicin',
    'Batu',
    'Barabai',
    'Banyuwangi',
    'Banyumas',
    'Bantul',
    'Banjarmasin',
    'Banjarbaru',
    'Banjar',
    'Bangsri',
    'Bangli',
    'Bangkalan',
    'Bangilan',
    'Bangil',
    'Banggai',
    'Bandung',
    'Balikpapan',
    'Badegan',
    'Anggana',
    'Amuntai',
    'Ambon City',
    'Ambarawa',
    'Allakuang',
    'Airmadidi',
    'Sanur',
    'Tambaan',
    'Gonilan',
    'Bendungan Hilir',
    'Menteng',
    'Parung',
    'Cibubur',
    'Blukbuk',
    'Manggung',
    'Jambuwer',
    'Panakkukang',
    'Lambara',
    'Bangkok',
    'Gelang',
    'Cikupa',
    'Kalisari',
    'Kalibata',
    'Kemang Pratama',
    'Setiabudi',
    'Rawasari',
    'Senen',
    'Kartasura',
    'Sukoharjo',
    'Kupang',
    'Jayapura',
    'Banguntapan',
    'Depok',
    'Kalimantan',
    'Pandeyan',
    'Pondok',
    'Beji Lor',
    'Banjaran Kulon',
    'Banjarnegara',
    'Karanganyar',
    'Kebumen',
    'Ringinpitu',
    'Bajang',
    'Babakankaret',
    'Bojonglarang',
    'Buniwangi',
    'Regol',
    'Cimahi',
    'Banten',
    'Tangsel',
    'Batununggal',
    'Cantayan',
    'Ciawi',
    'Seminyak',
    'Bandar Kuala',
    'Binjai',
    'Katupadang',
    'Alur Cempedak',
    'Simalungun',
    'Martubung',
    'Blang Dalam',
    'Purworejo',
    'Bandungharjo',
    'Bener',
    'Krandon',
    'Betahwalang',
    'Soppeng',
    'Petukangan Utara',
    'Lebak',
    'Larangan',
    'Bandung Barat',
    'Sokaraja',
    'Kuta',
    'Karangpucung',
    'Babalan',
    'Babakan',
    'Paser',
    'Tamanan',
    'Banjaranyar',
    'Wonosobo',
    'Ketanggi',
    'Pare',
    'Pandaan',
    'Balenrejo',
    'Sedayu',
    'Pulorejo',
    'Raha Dua',
    'Balesono',
    'Gowa',
    'Bakalan',
    'Krian',
    'Badung',
    'Pacitan',
    'Kedungwaru',
    'Nusa Dua',
    'Kerobokan',
    'Sukamara',
    'Pasir Kidul',
    'Barusuda',
    'Nagarasari',
    'Dawagung',
    'Bagjasari',
    'Gunungkidul',
    'Bungasrejo',
    'Garung',
    'Bamban',
    'Pelem',
    'Banaran',
    'Brengkok',
    'Batam',
    'Banjar Agung',
    'Lampung',
    'Pekanbaru',
    'Kuala Simpang',
    'Bukit Tinggi',
    'Batang',
    'Jembrana Subdistrict',
  ],
  Tajikistan: [
    "Vose'",
    'Istaravshan',
    'Kurgan-Tyube',
    'Panjakent',
    'Farkhor',
    'Vahdat',
    'Norak',
    'Somoniyon',
    'Kulob',
    'Kolkhozobod',
    'Khorugh',
    'Hisor',
    'Dushanbe',
    'Danghara',
    'Tursunzoda',
    'Khujand',
    'Konibodom',
    'Isfara',
    'Chkalov',
  ],
  Malaysia: [
    'Kuah',
    'Maran',
    'Raub',
    'Bentung',
    'Tanjong Malim',
    'Kuala Kubu Baharu',
    'Batu Pahat',
    'Parit Raja',
    'Pekan Nenas',
    'Ayer Baloi',
    'Pontian Kechil',
    'Kampung Pasir Gudang Baru',
    'Pasir Gudang',
    'Kampung Larkin Lama',
    'Kota Tinggi',
    'Taman Senai',
    'Kulai',
    'Skudai',
    'Masai',
    'Johor Bahru',
    'Tampoi',
    'Gelang Patah',
    'Pengerang',
    'Kluang',
    'Yong Peng',
    'Segamat',
    'Tangkak',
    'Bandar Sungai Mati',
    'Muar town',
    'Labis',
    'Chaah',
    'Tanjong Karang',
    'Kuala Selangor',
    'Batang Berjuntai',
    'Kapar',
    'Shah Alam',
    'Klang',
    'Sungkai',
    'Slim River',
    'Sekincan',
    'Cukai',
    'Kuala Lipis',
    'Papar',
    'Kota Kinabalu',
    'Putatan',
    'Ranau',
    'Labuan',
    'Beaufort',
    'Membakut',
    'Sandakan',
    'Keningau',
    'Tawau',
    'Dungun',
    'Paka',
    'Kertih',
    'Gerik',
    'Lunas',
    'Kulim',
    'Selama',
    'Bandar Baharu',
    'Bagan Serai',
    'Padang Serai',
    'Bedong',
    'Baling',
    'Simpang Empat',
    'Kamunting',
    'Batu Kurau',
    'Taiping',
    'Kuala Kangsar',
    'Sungai Siput Utara',
    'Chemor',
    'Ipoh',
    'Menglembu',
    'Gua Musang',
    'Kuala Terengganu',
    'Marang',
    'Ajil',
    'Tampin',
    'Alor Gajah',
    'Jasin',
    'Durian Tunggal',
    'Kampung Ayer Keroh',
    'Sungai Udang',
    'Malacca',
    'Merlimau',
    'Teluk Panglima Garang',
    'Port Klang',
    'Bandar',
    'Banting',
    'Jenjarum',
    'Bangi',
    'Semenyih',
    'Kuala Klawang',
    'Mantin',
    'Seremban',
    'Rantau',
    'Port Dickson',
    'Sepang',
    'Taman Senawang Indah',
    'Simpang Pertang',
    'Titi',
    'Kampung Langkap',
    'Kampung Batu Kikir',
    'Bahau',
    'Kuala Pilah',
    'Rembau',
    'Johol',
    'Gemencheh',
    'Kota',
    'Rompin',
    'Gemas',
    'Kuala Rompin',
    'Daerah Pekan',
    'Mentekab',
    'Temerluh',
    'Teriang',
    'Batu Feringgi',
    'Butterworth',
    'Perai',
    'Bukit Mertajam',
    'Sungai Bakap',
    'Nibong Tebal',
    'Parit Buntar',
    'Kuala Kurau',
    'Tasek Glugor',
    'Balik Pulau',
    'Bayan Lepas',
    'Batu Maung',
    'George Town',
    'Genting Highlands',
    'Serendah',
    'Rawang',
    'Sungai Buloh',
    'Batu Tiga',
    'Petaling Jaya',
    'Serdang',
    'Kuala Lumpur',
    'Setapak',
    'Batu Caves',
    'Ampang',
    'Sungai Besi',
    'Hulu Langat',
    'Bagan Pasir Laut',
    'Hutan Melintang',
    'Sabak Bernam',
    'Sungai Besar',
    'Kuantan',
    'Kuala Pahang',
    'Gambang',
    'Tanah Rata',
    'Gopeng',
    'Batu Gajah',
    'Kampar',
    'Tapah',
    'Bidur',
    'Rumah Ambang',
    'Parit',
    'Pusing',
    'Teronoh',
    'Pangkor',
    'Lumut',
    'Sitiawan',
    'Teluk Intan',
    'Gurun',
    'Sungai Petani',
    'Kepala Batas',
    'Melor',
    'Ketereh',
    'Pasir Puteh',
    'Tanah Merah',
    'Machang',
    'Kuala Krai',
    'Kuching',
    'Jakar',
    'Belawai',
    'Sarikei',
    'Julau',
    'Sibu',
    'Beseri',
    'Padang Besar',
    'Kangar',
    'Changloon',
    'Arau',
    'Kuala Perlis',
    'Jitra',
    'Langgar',
    'Alor Star',
    'Sintok',
    'Kuala Nerang',
    'Pokok Sena',
    'Tumpat',
    'Pasir Mas',
    'Kota Bharu',
    'Bachok',
    'Kuala Besut',
    'Kota Belud',
    'Tamparuli',
    'Betong',
    'Dalat',
    'Kampong Lundu',
    'Bintulu',
    'Limbang',
    'Beluru',
    'Lutong',
    'Marabu',
    'Ulu Tiram',
    'Tanjung Tokong',
    'Tanjung Sepat',
    'Seri Kembangan',
    'Seputeh',
    'Sentul',
    'Relau',
    'Puchong Batu Dua Belas',
    'Permatang Pauh',
    'Penampang',
    'Pantai Remis',
    'Lukut',
    'Kubang Kerian',
    'Kepong',
    'Klebang Besar',
    'Kampung Tanjung Karang',
    'Kampong Sungai Rambai',
    'Kampung Sempa',
    'Pendang',
    'Kampung Pekan Kechil Beranang',
    'Kampung Paya Simpang Empat Genting',
    'Kampong Pasir Segambut',
    'Kampong Pangkal Kalong',
    'Kampung Kepayang',
    'Kampong Kemaman',
    'Kampung Batu Arang',
    'Kampung Baharu Ban Foo',
    'Kampung Baharu Nilai',
    'Kampung Ayer Salak',
    'Jelutong',
    'Damansara',
    'Cheras',
    'Bukit Rambai',
    'Bukit Payung',
    'Bukit Gambir',
    'Bemban',
    'Batu Berendam',
    'Batang Kali',
    'Ayer Tawar',
    'Ayer Itam',
    'Langat',
    'Tanjung Bungah',
    'Tanjong Bungah',
    'Putrajaya',
    'Alma',
    'Seberang Jaya',
    'Tanjung Bunga',
    'Glugor',
    'Kampung Raja',
    'Cyberjaya',
    'Bandar Tenggara',
    'Bandar Petri Jaya',
    'Dengkil',
    'Bandar Tun Razak',
    'Bandar Penawar',
    'Seri Manjung',
    'Taman Ungku Tun Aminah',
    'Kemayan',
    'Subang Jaya',
    'Taman Sri Ampang',
    'Bandar Baru Bangi',
    'Bandar Puchong Jaya',
    'Nusajaya',
    'Kajang',
    'Seri Iskandar',
    'Bandar Puncak Alam',
    'Peringgit',
  ],
  'Sri Lanka': [
    'Yatiyantota',
    'Yakkala',
    'Wellawa',
    'Welimada',
    'Weligama',
    'Wattala',
    'Wariyapola',
    'Warakapola',
    'Meda Wadduwa',
    'Veyangoda',
    'Vavuniya',
    'Valvedditturai',
    'Urapola',
    'Unawatuna',
    'Ukuwela',
    'Uhana',
    'Udugampola',
    'Thulhiriya',
    'Trincomalee',
    'Thihagoda',
    'Tangalle',
    'Talawatugoda',
    'Slave Island',
    'Sigiriya',
    'Seeduwa',
    'Siddamulla',
    'Ratnapura',
    'Ratmalana',
    'Rajagiriya',
    'Ragama',
    'Puttalam',
    'Polonnaruwa',
    'Polgahawela',
    'Point Pedro',
    'Pita Kotte',
    'Piliyandala',
    'Peradeniya',
    'Pannipitiya',
    'Pannala',
    'Panadura',
    'Pallewela',
    'Pallepola',
    'Padukka',
    'Nuwara Eliya',
    'Nugegoda',
    'Nochchiyagama',
    'Nittambuwa',
    'Negombo',
    'Nawana',
    'Nawalapitiya',
    'Nawala',
    'Naula',
    'Narammala',
    'Mulleriyawa',
    'Dehiwala-Mount Lavinia',
    'Moratuwa',
    'Molagoda',
    'Mirihana',
    'Mirigama',
    'Minuwangoda',
    'Medamulana',
    'Mawanella',
    'Matugama',
    'Matara',
    'Matale',
    'Maskeliya',
    'Marawila Town',
    'Maradana',
    'Malwana',
    'Malambe',
    'Maharagama',
    'Kuruwita',
    'Kurunegala',
    'Kuliyapitiya',
    'Kotugoda',
    'Sri Jayewardenepura Kotte',
    'Kottawa',
    'Kolonnawa',
    'Kochchikade',
    'Kiriwattuduwa',
    'Kesbewa',
    'Kelaniya',
    'Kegalle',
    'Katunayaka',
    'Katugastota',
    'Karambe',
    'Kandy',
    'Kandana',
    'Kalutara',
    'Kalubowila',
    'Kalpitiya',
    'Kalmunai',
    'Kalagedehena',
    'Kahathuduwa',
    'Kaduwela',
    'Kadawata',
    'Jaffna',
    'Ja Ela',
    'Ipalogama',
    'Horana South',
    'Homagama',
    'Hingurakgoda',
    'Hettipola',
    'Havelock Town',
    'Hatton',
    'Harnkahawa',
    'Hali-Ela',
    'Habarana',
    'Giriulla',
    'Ganemulla',
    'Gampola',
    'Gampaha',
    'Galle',
    'Galenbindunuwewa',
    'Galahitiyawa North',
    'Galaha',
    'Ethul Kotte',
    'Embilipitiya',
    'Elpitiya',
    'Eheliyagoda',
    'Dunagaha',
    'Dummalasuriya',
    'Diyatalawa',
    'Divulapitiya',
    'Deniyaya',
    'Dematagoda',
    'Demanhandiya',
    'Dehiwala',
    'Dehiowita',
    'Danowita',
    'Dankotuwa Town',
    'Dambulla',
    'Dambagalla',
    'Colombo',
    'Chilaw',
    'Boralesgamuwa South',
    'Biyagama',
    'Beruwala',
    'Berannawa',
    'Belihuloya',
    'Battaramulla South',
    'Bandarawela',
    'Bandaragama',
    'Balapitiya',
    'Badulla',
    'Avissawella',
    'Athurugiriya',
    'Anuradhapura',
    'Angoda',
    'Anamaduwa',
    'Ampara',
    'Aluthgama',
    'Alawwa',
    'Akuressa',
    'Akurana',
    'Pujapitiya',
    'Kotiyakumbura',
    'Mawaramandiya',
    'Wellampitiya',
    'Borella',
    'Kohuwala',
    'Lahugala2',
    'Kadawatha',
  ],
  Bhutan: [
    'Wangdue Phodrang',
    'Trongsa',
    'Thimphu',
    'Trashi Yangtse',
    'Trashigang',
    'Daga',
    'Shemgang',
    'Sarpang',
    'Punakha',
    'Paro',
    'Mongar',
    'Lhuentse',
    'Ha',
    'Gasa',
    'Tsirang',
    'Tsimasham',
    'Jakar',
    'Samtse',
    'Wamrong',
    'Pemagatshel',
    'Samdrup Jongkhar',
    'Babesa',
  ],
  India: [
    'Kilakarai',
    'Ziro',
    'Zamania',
    'Zaidpur',
    'Zahirabad',
    'Yeola',
    'Yellapur',
    'Yavatmal',
    'Yanam',
    'Yamunanagar',
    'Yadgir',
    'Worli',
    'Wellington',
    'Washim',
    'Warora',
    'Vadodra',
    'Wardha',
    'Warangal',
    'Wankaner',
    'Wangi',
    'Walajapet',
    'Wai',
    'Wada',
    'Vyara',
    'Vuyyuru',
    'Vrindavan',
    'Vriddhachalam',
    'Vizianagaram',
    'Vittal',
    'Visnagar',
    'Visakhapatnam',
    'Virar',
    'Viramgam',
    'Vinukonda',
    'Villupuram',
    'Villenour',
    'Vikarabad',
    'Vijayawada',
    'Vijapur',
    'Vidisha',
    'Verna',
    'Veraval',
    'Venkatagiri',
    'Vellore',
    'Vasind',
    'Vashi',
    'Vasco da Gama',
    'Vasai',
    'Vasad',
    'Varkala',
    'Varanasi',
    'Vaniyambadi',
    'Valsad',
    'Vallabh Vidyanagar',
    'Vaikam',
    'Vaijapur',
    'Vadodara',
    'Vadnagar',
    'Vadasinor',
    'Uttarpara',
    'Uttarkashi',
    'Uran',
    'Uppala',
    'Upleta',
    'Unnao',
    'Unjha',
    'Una',
    'Umreth',
    'Umred',
    'Umbraj',
    'Umargam',
    'Uluberiya',
    'Ulhasnagar',
    'Ulavapad',
    'Uklana',
    'Ukhrul',
    'Ujjain',
    'Udupi',
    'Udhampur',
    'Udgir',
    'Udayagiri',
    'Udalguri',
    'Udaipur',
    'Ooty',
    'Turbihal',
    'Turavur',
    'Tura',
    'Tupran',
    'Tuni',
    'Tumsar',
    'Tumkūr',
    'Tuensang',
    'Thiruvananthapuram',
    'Thrissur',
    'Tribeni',
    'Tonk',
    'Tohana',
    'Todabhim',
    'Cheyyar',
    'Tiruvannamalai',
    'Tiruvanmiyur',
    'Tiruvallur',
    'Tiruvalla',
    'Thiruthuraipoondi',
    'Thiruthani',
    'Tirur',
    'Tirupur',
    'Tirupattur',
    'Tirumala - Tirupati',
    'Tirunelveli',
    'Tiruchengode',
    'Tiruchi',
    'Tiruchchendur',
    'Tiptur',
    'Tinsukia',
    'Tindivanam',
    'Tilhar',
    'Tikamgarh',
    'Tijara',
    'Thoubal',
    'Thiruvarur',
    'Tharad',
    'Thanjavur',
    'Thanesar',
    'Thane',
    'Tezpur',
    'Theni',
    'Thenali',
    'Tena',
    'Tellicherry',
    'Tehri',
    'Tasgaon',
    'Tarn Taran',
    'Tharangambadi',
    'Tarakeswar',
    'Taoru',
    'Tanniyurnagaram',
    'Tanuku',
    'Tankara',
    'Tangmarg',
    'Tanguturu',
    'Tamulpur',
    'Tamluk',
    'Talwara',
    'Talwandi Sabo',
    'Taliparamba',
    'Talikota',
    'Talegaon Dabhade',
    'Talcher',
    'Talavadi',
    'Takli Dhokeshwar',
    'Taiyur',
    'Tadepallegudem',
    'Suti',
    'Surendranagar',
    'Suratgarh',
    'Surajpur',
    'Surajgarh',
    'Supaul',
    'Sundargarh',
    'Sunam',
    'Sullia',
    'Ganapathivattam',
    'Sultanpur',
    'Sukinda',
    'Sujangarh',
    'Suar',
    'Srivilliputhur',
    'Sriperumbudur',
    'Srinagar',
    'Srikakulam',
    'Soro',
    'Soreng',
    'Sorbhog',
    'Sonipat',
    'Songadh',
    'Sonepur',
    'Sonari',
    'Sonamura',
    'Sonamukhi',
    'Sonai',
    'Somvarpet',
    'Solim',
    'Solan',
    'Sojat',
    'Sohna',
    'Sogam',
    'Siwana',
    'Siwan',
    'Sivakasi',
    'Sivaganga',
    'Siuri',
    'Sitapur',
    'Sitamarhi',
    'Sirsilla',
    'Sirsi',
    'Sirsa',
    'Sironj',
    'Sirohi',
    'Sirkazhi',
    'Sirhind',
    'Sira',
    'Sinor',
    'Sinnar',
    'Singheswar',
    'Sindewahi',
    'Simrol',
    'Shimla',
    'Semiliguda',
    'Simhachalam',
    'Simga',
    'Simdega',
    'Simaluguri',
    'Silvassa',
    'Silchar',
    'Sikar',
    'Sidhi',
    'Siddipet',
    'Siddhapur',
    'Sibsagar',
    'Shujalpur',
    'Serampore',
    'Shrigonda',
    'Sravana Belgola',
    'Shorapur',
    'Shoranur',
    'Sholinghur',
    'Solapur',
    'Shirwal',
    'Shirdi',
    'Shimoga',
    'Shillong',
    'Siliguri',
    'Shikrapur',
    'Shevgaon',
    'Sheohar',
    'Sheoganj',
    'Shantipur',
    'Shanti Niketan',
    'Shamli',
    'Shamgarh',
    'Shajapur',
    'Shahpura',
    'Shahpur',
    'Shahkot',
    'Shahjahanpur',
    'Shahdol',
    'Shahapur',
    'Shahada',
    'Shahabad',
    'Sewri',
    'Serchhip',
    'Seoraphuli',
    'Seoni',
    'Sendhwa',
    'Sehore',
    'Secunderabad',
    'Sayalkudi',
    'Sawai Madhopur',
    'Savantvadi',
    'Sathyamangalam',
    'Satwas',
    'Sattur',
    'Satrikh',
    'Satnali',
    'Satna',
    'Sathiala',
    'Sathana',
    'Satara',
    'Sasni',
    'Sasaram',
    'Sarotra',
    'Sardarshahr',
    'Sarangi',
    'Sarangarh',
    'Saran',
    'Saraipali',
    'Sapotra',
    'Sanvordem',
    'Sanquelim',
    'Sankarankovil',
    'Sanguem',
    'Sangrur',
    'Sangola',
    'Sangli',
    'Sanghol',
    'Sangareddi',
    'Sangamner',
    'Sandwa',
    'Sancoale',
    'Sanand',
    'Sami',
    'Sambhal',
    'Sambalpur',
    'Samba',
    'Samastipur',
    'Samalkot',
    'Samalkha',
    'Salur',
    'Salt Lake City',
    'Saligrama',
    'Salem',
    'Salar',
    'Sakoli',
    'Sakleshpur',
    'Sainthia',
    'Sahibganj',
    'Sahibabad',
    'Saharsa',
    'Saharanpur',
    'Sagwara',
    'Saugor',
    'Sagar',
    'Safipur',
    'Safidon',
    'Sadhaura',
    'Sadabad',
    'Sachendi',
    'Sabrum',
    'Sabbavaram',
    'Sabang',
    'Rurka Kalan',
    'Ropar',
    'Rudraprayag',
    'Rowta',
    'Roorkee',
    'Rohtak',
    'Rohru',
    'Roha',
    'Robertsonpet',
    'Robertsganj',
    'Risod',
    'Rishikesh',
    'Rewari',
    'Rewa',
    'Repalle',
    'Renukoot',
    'Rehli',
    'Razole',
    'Rayagada',
    'Raxaul',
    'Rawatsar',
    'Rawatbhata',
    'Raurkela',
    'Ratnagiri',
    'Ratlam',
    'Ratia',
    'Rath',
    'Ratangarh',
    'Rasulpur Rohta',
    'Rasra',
    'Rasipuram',
    'Rapar',
    'Raniwara',
    'Ranipet',
    'Ranikhet',
    'Raniganj',
    'Ranibennur',
    'Rania',
    'Rangpo',
    'Rangia',
    'Rangapara',
    'Ranchi',
    'Ranaghat',
    'Ramtek',
    'Rampura Mandi',
    'Rampur',
    'Ramkola',
    'Ramgarh',
    'Ramanathapuram',
    'Rajula',
    'Rajsamand',
    'Rajpipla',
    'Rajkot',
    'Rajgurunagar',
    'Rajgarh',
    'Rajapur',
    'Rajapalaiyam',
    'Razampeta',
    'Rajahmundry',
    'Raisinghnagar',
    'Raisen',
    'Raipur Rani',
    'Raipur',
    'Raikot',
    'Raikia',
    'Raigarh',
    'Raiganj',
    'Raichur',
    'Raia',
    'Rahuri',
    'Rahon',
    'Rahimatpur',
    'Rahatgarh',
    'Rahata',
    'Raebareli',
    'Radhanpur',
    'Radhakishorepur',
    'Radaur',
    'Kollam',
    'Quepem',
    'Puttur',
    'Pushkar',
    'Pusad',
    'Purwa',
    'Puruliya',
    'Purnia',
    'Puri',
    'Punnapra',
    'Pune',
    'Punalur',
    'Pulwama',
    'Pukhrayan',
    'Pudukkottai',
    'Proddatur',
    'Pratapgarh',
    'Porumamilla',
    'Port Blair',
    'Poriem',
    'Porbandar',
    'Poonamalle',
    'Ponneri',
    'Ponnani',
    'Ponnampet',
    'Puducherry',
    'Ponda',
    'Pollachi',
    'Polasara',
    'Pithoragarh',
    'Pithapuram',
    'Piravam',
    'Pirangut',
    'Pipar Road',
    'Pilkhuwa',
    'Pilibhit',
    'Pilibangan',
    'Pilani',
    'Piduguralla',
    'Phillaur',
    'Phaltan',
    'Phalodi',
    'Phagwara',
    'Phagi',
    'Petlad',
    'Periyakulam',
    'Perintalmanna',
    'Peravurani',
    'Perambalur',
    'Penukonda',
    'Penugonda',
    'Pen',
    'Pehowa',
    'Peddapuram',
    'Pedana',
    'Payyoli',
    'Payyanur',
    'Pauri',
    'Patti',
    'Pattambi',
    'Patodi',
    'Patna City',
    'Patna',
    'Patiala',
    'Pathankot',
    'Pathanamthitta',
    'Pataudi',
    'Patancheru',
    'Patan',
    'Pasighat',
    'Partur',
    'Parsabad',
    'Parra',
    'Parli Vaijnath',
    'Pargi',
    'Parbhani',
    'Paravur Tekkumbhagam',
    'Parasia',
    'Parappanangadi',
    'Paradip',
    'Pappinissheri',
    'Papanasam',
    'Paonta Sahib',
    'Panvel',
    'Pantnagar',
    'Panskura',
    'Panruti',
    'Panna',
    'Panki',
    'Panipat',
    'Pandua',
    'Pandikkad',
    'Pandharpur',
    'Panchgani',
    'Panapakkam',
    'Panamaram',
    'Panjim',
    'Panagarh',
    'Palwal',
    'Palani',
    'Pallikondai',
    'Pallavaram',
    'Palladam',
    'Palitana',
    'Palia Kalan',
    'Pali',
    'Palakkad',
    'Palghar',
    'Palashi',
    'Palasa',
    'Palanpur',
    'Palampur',
    'Palakollu',
    'Pakyong',
    'Padrauna',
    'Padghe',
    'Paddhari',
    'Padampur',
    'Pachperwa',
    'Oxel',
    'Ottappalam',
    'Osmanabad',
    'Orai',
    'Ongole',
    'Onda',
    'Nuzvid',
    'Nurpur',
    'Nurmahal',
    'Nungambakkam',
    'Nuh',
    'Nowgong',
    'North Lakhimpur',
    'Nongpoh',
    'Nokha',
    'Nohar',
    'Nizamabad',
    'Nirmal',
    'Neem ka Thana',
    'Nimbahera',
    'Nimaparha',
    'Nileshwar',
    'Nilambur',
    'Nidadavole',
    'Nichlaul',
    'Neyveli',
    'New Delhi',
    'Nevasa',
    'Nereducherla',
    'Neral',
    'Nepanagar',
    'Nellore',
    'Nellayi',
    'Nelamangala',
    'Nazira',
    'Nayagarh',
    'Nayagaon',
    'Nawalgarh',
    'Nawada',
    'Navsari',
    'Nabadwip',
    'Nautanwa',
    'Naugarh',
    'Nathdwara',
    'Nasrapur',
    'Nasirabad',
    'Nashik',
    'Narwana',
    'Narsimhapur',
    'Narnaul',
    'Narela',
    'Narayangaon',
    'Narasaraopet',
    'Narainpur',
    'Napoklu',
    'Nanpara',
    'Nangloi Jat',
    'Nandyal',
    'Nandurbar',
    'Nandgaon',
    'Nanded',
    'Namli',
    'Namchi',
    'Nambiyur',
    'Namakkal',
    'Nallajerla',
    'Nalhati',
    'Nalgonda',
    'Nalbari',
    'Nalagarh',
    'Nakur',
    'Nakrekal',
    'Nakodar',
    'Najibabad',
    'Najafgarh',
    'Nainital',
    'Nahan',
    'Nagpur',
    'Nagercoil',
    'Nagda',
    'Nagbhir',
    'Nagaur',
    'Nagarbera',
    'Nagapattinam',
    'Naganahalli',
    'Nadiad',
    'Nadbai',
    'Nadaun',
    'Nachna',
    'Nabha',
    'Mysore',
    'Muzaffarpur',
    'Muzaffarnagar',
    'Mussoorie',
    'Murwara',
    'Murum',
    'Murthal',
    'Murshidabad',
    'Morinda',
    'Murarai',
    'Munnar',
    'Mungra Badshahpur',
    'Munger',
    'Mundra',
    'Mundgod',
    'Mummidivaram',
    'Multhan',
    'Multai',
    'Muktsar',
    'Mukerian',
    'Mudgere',
    'Mudhol',
    'Mothihari',
    'Morjim',
    'Morena',
    'Morvi',
    'Moradabad',
    'Mond',
    'Mokokchung',
    'Moirang',
    'Moga',
    'Modasa',
    'Miyani',
    'Mirzapur',
    'Mirik',
    'Miraj',
    'Mhow',
    'Mettuppalaiyam',
    'Memari',
    'Melattur',
    'Melaghar',
    'Meerut',
    'Medinipur',
    'Medchal',
    'Medarametla',
    'Medak',
    'Mayiladuthurai',
    'Mayna',
    'Mayakonda',
    'Mawana',
    'Mavelikara',
    'Mauganj',
    'Maudaha',
    'Mau',
    'Matunga',
    'Mattanur',
    'Matigara',
    'Mathura',
    'Marshaghai',
    'Maroli',
    'Mormugao',
    'Markapur',
    'Marikal',
    'Mariani',
    'Marakkanam',
    'Manteswar',
    'Mansa',
    'Mannargudi',
    'Manmad',
    'Manjhanpur',
    'Manjeri',
    'Manipal',
    'Mangalore',
    'Mangaldai',
    'Mangalagiri',
    'Mandya',
    'Mandsaur',
    'Mandla',
    'Mandi',
    'Mandawar',
    'Mandapeta',
    'Mandal',
    'Mandha',
    'Manda',
    'Mancherial',
    'Manchar',
    'Manawar',
    'Manantoddy',
    'Manamadurai',
    'Manali',
    'Malvan',
    'Malur',
    'Maler Kotla',
    'Malegaon',
    'Malebennur',
    'Malda',
    'Malavalli',
    'Malaudh',
    'Malappuram',
    'Makum',
    'Makrana',
    'Makhu',
    'Majuli',
    'Majitha',
    'Mainpuri',
    'Maihar',
    'Mahwah',
    'Mahuva',
    'Mahur',
    'Mahim',
    'Mahesana',
    'Mahendragarh',
    'Mahemdavad',
    'Mahe',
    'Mahbubnagar',
    'Mahasamund',
    'Maharaganj',
    'Mahalingpur',
    'Mahad',
    'Mahabaleshwar',
    'Magra',
    'Magam',
    'Madurantakam',
    'Madurai',
    'Chennai',
    'Madikeri',
    'Madhyamgram',
    'Madhubani',
    'Madhoganj',
    'Madgaon',
    'Maddur',
    'Madanpur',
    'Madakasira',
    'McLeod Ganj',
    'Machilipatnam',
    'Lunglei',
    'Ludhiana',
    'Lucknow',
    'Luckeesarai',
    'Lormi',
    'Loni Kalbhor',
    'Loni',
    'Lonavla',
    'Lohogaon',
    'Lohardaga',
    'Lohaghat',
    'Leh',
    'Lawngtlai',
    'Latur',
    'Latipur',
    'Lasalgaon',
    'Lalsot',
    'Lalitpur',
    'Lalgola',
    'Lalganj',
    'Lalbagh',
    'Lakhnadon',
    'Lakhimpur',
    'Laikera',
    'Ladwa',
    'Ladnun',
    'Labpur',
    'Kuzhithurai',
    'Kuttippuram',
    'Kutiatodu',
    'Kushalnagar',
    'Kurnool',
    'Kurkhera',
    'Kurali',
    'Kunnamkulam',
    'Kunigal',
    'Kundgol',
    'Kumta',
    'Kumharsain',
    'Kumbakonam',
    'Kumarkera',
    'Kumarakom',
    'Kulti',
    'Kulgam',
    'Kulasegaram',
    'Kukshi',
    'Kudligi',
    'Kudal',
    'Kuchaman',
    'Kistnapatam',
    'Krishnanagar',
    'Krishnai',
    'Krishnagiri',
    'Kozhikode',
    'Koyyalagudem',
    'Kovvur',
    'Kovilpatti',
    'Kottayam',
    'Kottarakara',
    'Kottakkal',
    'Kotputli',
    'Kotma',
    'Kotdwara',
    'Kotananduru',
    'Kotagiri',
    'Kota',
    'Kosamba',
    'Korea',
    'Korba',
    'Koraput',
    'Koppal',
    'Koppa',
    'Kopargaon',
    'Konnagar',
    'Kondotty',
    'Kommeri',
    'Kollengode',
    'Kollegal',
    'Kolhapur',
    'Kolar',
    'Kokrajhar',
    'Kohima',
    'Kodungallur',
    'Kodoli',
    'Kodaikanal',
    'Koch Bihar',
    'Kizhake Chalakudi',
    'Kishtwar',
    'Kishangarh',
    'Kishanganj',
    'Kichha',
    'Khurja',
    'Khurda',
    'Khurai',
    'Khowai',
    'Khopoli',
    'Khonsa',
    'Khirpai',
    'Khirkiyan',
    'Khimsar',
    'Khetri',
    'Kheralu',
    'Khedbrahma',
    'Kheda',
    'Khatima',
    'Khatauli',
    'Nagrota Bagwan',
    'Khargone',
    'Kharar',
    'Kharagpur',
    'Khanpur',
    'Khanna',
    'Khandwa',
    'Khandela',
    'Khandagiri',
    'Khanapara',
    'Khammam',
    'Khamgaon',
    'Khambhat',
    'Khamanon Kalan',
    'Khalra',
    'Khalilabad',
    'Khalapur',
    'Khairagarh',
    'Khagaria',
    'Khaga',
    'Khachrod',
    'Keskal',
    'Keshorai Patan',
    'Keonjhargarh',
    'Kelambakkam',
    'Kekri',
    'Kayamkulam',
    'Kawardha',
    'Kavaratti',
    'Kavali',
    'Kattanam',
    'Katras',
    'Katra',
    'Katoya',
    'Katol',
    'Katihar',
    'Kathua',
    'Kasia',
    'Kashipur',
    'Kasganj',
    'Kasaragod',
    'Karwar',
    'Karur',
    'Karunguli',
    'Kartarpur',
    'Karsiyang',
    'Karnal',
    'Karmala',
    'Karjat',
    'Karimpur',
    'Karimnagar',
    'Karimganj',
    'Kargani',
    'Karauli',
    'Karanja',
    'Karamsad',
    'Karambakkudi',
    'Karaikudi',
    'Karaikal',
    'Karad',
    'Kapurthala Town',
    'Kapasan',
    'Kanpur',
    'Kanniyakumari',
    'Kannavam',
    'Kannauj',
    'Kanhangad',
    'Kankon',
    'Kankipadu',
    'Kankinara',
    'Kanki',
    'Kanker',
    'Kankauli',
    'Kanigiri',
    'Kangar',
    'Kangayam',
    'Kandukur',
    'Kandi',
    'Kandhla',
    'Kanchipuram',
    'Kankanhalli',
    'Kamuthi',
    'Kamrej',
    'Kampli',
    'Kamargaon',
    'Kamalpur',
    'Kamakhya',
    'Kalyani',
    'Kalyan',
    'Kalpatta',
    'Kalol',
    'Kalna',
    'Kalmeshwar',
    'Kaliyaganj',
    'Kanina Khas',
    'Kalimpong',
    'Kalikavu',
    'Kalasa',
    'Kalanwali',
    'Kalanaur',
    'Kalamboli',
    'Kallam',
    'Kala Amb',
    'Kako',
    'Kakinada',
    'Kakdwip',
    'Kakatpur',
    'Kaithal',
    'Kairana',
    'Kailashahar',
    'Kaikalur',
    'Kaij',
    'Kagal',
    'Kadur',
    'Kadiri',
    'Kadikkad',
    'Kadi',
    'Kadana',
    'Kanchrapara',
    'Jundla',
    'Junagadh',
    'Jumri Tilaiya',
    'Jalandhar',
    'Jowai',
    'Jorhat',
    'Jogeshwari',
    'Jogbani',
    'Jodhpur',
    'Jind',
    'Jiaganj',
    'Jhunjhunun',
    'Jharsuguda',
    'Jhargram',
    'Jhansi',
    'Jhalrapatan',
    'Jhalawar',
    'Jhajjar',
    'Jhabua',
    'Jewar',
    'Jetpur',
    'Jeypore',
    'Jawali',
    'Jawala Mukhi',
    'Jaunpur',
    'Jatani',
    'Jasrana',
    'Jaspur',
    'Jasdan',
    'Jaora',
    'Janjgir',
    'Jangipur',
    'Jangipara',
    'Janghai',
    'Jamunamukh',
    'Jamui',
    'Jamshedpur',
    'Jamnagar',
    'Jammu',
    'Jambusar',
    'Jalpaiguri',
    'Jalna',
    'Jalgaon Jamod',
    'Jaleshwar',
    'Jalalpur',
    'Jalalabad',
    'Jajpur',
    'Jaito',
    'Jaitaran',
    'Jaisingpur',
    'Jaisalmer',
    'Jaipur',
    'Jagtial',
    'Jagraon',
    'Jaggampeta',
    'Jagdalpur',
    'Jagatsinghapur',
    'Jagadhri',
    'Jadcherla',
    'Jadan',
    'Jabalpur',
    'Itarsi',
    'Itanagar',
    'Ismailabad',
    'Islampur',
    'Iritty',
    'Irinjalakuda',
    'Indore',
    'Indapur',
    'Imphal',
    'Ilkal',
    'Ikauna',
    'Iglas',
    'Igatpuri',
    'Idukki',
    'Idar',
    'Idappadi',
    'Ichalkaranji',
    'Ibrahimpatan',
    'Hyderabad',
    'Husnabad',
    'Hunasagi',
    'Hukeri',
    'Hugli',
    'Hubli',
    'Hospet',
    'Hoskote',
    'Hoshangabad',
    'Hosanagara',
    'Honnali',
    'Honavar',
    'Hojai',
    'Hodal',
    'Hisar',
    'Hiriyur',
    'Hiranagar',
    'Hirakud',
    'Hingoli',
    'Hinganghat',
    'Hindupur',
    'Hindol',
    'Hindaun',
    'Himatnagar',
    'Hilsa',
    'Hazaribagh',
    'Haveri',
    'Hathras',
    'Hassan',
    'Hasnabad',
    'Hasimara',
    'Harur',
    'Harnaut',
    'Haripad',
    'Hariharganj',
    'Harihar',
    'Haridwar',
    'Harichandanpur',
    'Harda',
    'Harchowal',
    'Hapur',
    'Howrah',
    'Hanumangarh',
    'Hansot',
    'Hansi',
    'Hanamkonda',
    'Hamirpur',
    'Halvad',
    'Halol',
    'Haliyal',
    'Halisahar',
    'Haldwani',
    'Haldibari',
    'Hailakandi',
    'Haflong',
    'Habra',
    'Gyanpur',
    'Gwalior',
    'Guskhara',
    'Guruvayur',
    'Guru Har Sahai',
    'Gurmatkal',
    'Gurgaon',
    'Gurdaspur',
    'Guntur',
    'Guntakal',
    'Gundlupet',
    'Gunadala',
    'Guna',
    'Gumla',
    'Gulmarg',
    'Gulbarga',
    'Guindy',
    'Guhagar',
    'Guduvancheri',
    'Gudur',
    'Gudlavalleru',
    'Gudivada',
    'Gubbi',
    'Gua',
    'Gotan',
    'Gorakhpur',
    'Gobichettipalayam',
    'Gopeshwar',
    'Gopalpur',
    'Gopalganj',
    'Gooty',
    'Gondia',
    'Gondal',
    'Gonda City',
    'Gollapudi',
    'Golaghat',
    'Gola Bazar',
    'Gokarna',
    'Gokak',
    'Gohpur',
    'Gohana',
    'Goh',
    'Godhra',
    'Godda',
    'Gobardanga',
    'Goalpara',
    'Giridih',
    'Girgaum',
    'Gingee',
    'Ghazipur',
    'Ghaziabad',
    'Ghatal',
    'Ghat',
    'Gharaunda',
    'Ghumarwin',
    'Gaya',
    'Goribidnur',
    'Guwahati',
    'Garhshankar',
    'Garden Reach',
    'Gangtok',
    'Gangtha',
    'Gangarampur',
    'Gangapur',
    'Ganganagar',
    'Gandhinagar',
    'Gandhidham',
    'Gandevi',
    'Gandarbal',
    'Gandai',
    'Gampalagudem',
    'Gajwel',
    'Gajraula',
    'Gaighata',
    'Gagret',
    'Gadwal',
    'Gadhinglaj',
    'Gadarwara',
    'Gadag',
    'Forbesganj',
    'Firozpur',
    'Firozabad',
    'Ferokh',
    'Fazilka',
    'Fatehpur',
    'Fatehgarh',
    'Fatehabad',
    'Farrukhnagar',
    'Farrukhabad',
    'Faridkot',
    'Faridabad',
    'Falakata',
    'Faizabad',
    'Etawah',
    'Etah',
    'Erode',
    'Ernakulam',
    'Erattupetta',
    'Eluru',
    'Ellenabad',
    'Ekangar Sarai',
    'Egra',
    'Egmore',
    'Edayur',
    'Edappal',
    'Durgapur',
    'Durg',
    'Dungri',
    'Dungla',
    'Dungarpur',
    'Dunera',
    'Dundlod',
    'Dumka',
    'Dumaria',
    'Dubrajpur',
    'Draksharama',
    'Dona Paula',
    'Dombivali',
    'Doiwala',
    'Doda',
    'Diu',
    'Dispur',
    'Deesa',
    'Dirba',
    'Diphu',
    'Dinhata',
    'Dindigul',
    'Dinanagar',
    'Dimapur',
    'Dikchu',
    'Diglipur',
    'Didwana',
    'Dibrugarh',
    'Diamond Harbour',
    'Dhuri',
    'Dhupgari',
    'Dhule',
    'Dhubri',
    'Dhoraji',
    'Dholera',
    'Dhing',
    'Dhenkanal',
    'Dhemaji',
    'Dhekiajuli',
    'Dhaulpur',
    'Dharwad',
    'Dharur',
    'Dharuhera',
    'Dharamsala',
    'Dharmavaram',
    'Dharmapuri',
    'Dharmapuram',
    'Dharmanagar',
    'Dharmajigudem',
    'Dharmaj',
    'Dhari',
    'Dharavi',
    'Dharapuram',
    'Dharampur',
    'Dhar',
    'Dhansura',
    'Dhaniakhali',
    'Dhanera',
    'Dhandhuka',
    'Dhanbad',
    'Dhamtari',
    'Dhampur',
    'Dhamnod',
    'Dhaka',
    'Dewas',
    'Devanahalli',
    'Devakottai',
    'Desuri',
    'Dergaon',
    'Deoria',
    'Deolali',
    'Deoghar',
    'Deogarh',
    'Deoband',
    'Delmara',
    'Delhi',
    'Dehra Dun',
    'Davorlim',
    'Davangere',
    'Dausa',
    'Daurala',
    'Daund',
    'Dasuya',
    'Daspalla',
    'Dasave',
    'Daryapur',
    'Darsi',
    'Darjeeling',
    'Darbhanga',
    'Dapoli',
    'Dankaur',
    'Damoh',
    'Daman',
    'Medininagar',
    'Dalhousie',
    'Dalash',
    'Dalasanur',
    'Dohad',
    'Dahegam',
    'Dahanu',
    'Dadri',
    'Dadra',
    'Dabwali',
    'Dabhoi',
    'Cuttack',
    'Curchorem',
    'Cuncolim',
    'Cumbum',
    'Cumballa Hill',
    'Kadapa',
    'Cuddalore',
    'Cossipore',
    'Cortalim',
    'Coonoor',
    'Coondapoor',
    'Colva',
    'Calangute',
    'Coimbatore',
    'Kochi',
    'Cinnamara',
    'Churu',
    'Churachandpur',
    'Vikasnagar',
    'Chorhat',
    'Chopra',
    'Chittur',
    'Chittoor',
    'Chittaurgarh',
    'Chittaranjan',
    'Chitradurga',
    'Chidawa',
    'Chirala',
    'Chiplun',
    'Chinturu',
    'Chintamani',
    'Chikodi',
    'Chikmagalūr',
    'Chikhli',
    'Chidambaram',
    'Chicalim',
    'Chhota Udepur',
    'Chhindwara',
    'Chapra',
    'Chhapar',
    'Chetput',
    'Cherrapunjee',
    'Chennimalai',
    'Chengannur',
    'Chengalpattu',
    'Chavassheri',
    'Chavara',
    'Chetwayi',
    'Chattanahalli',
    'Chaksu',
    'Chatrapur',
    'Chatra',
    'Chas',
    'Charkhi Dadri',
    'Chapar',
    'Channarayapatna',
    'Channapatna',
    'Changanacheri',
    'Chanda',
    'Chandrakona',
    'Chandragiri',
    'Chandpur',
    'Chandpara',
    'Chandil',
    'Chandigarh',
    'Chandgad',
    'Chandbali',
    'Chanduasi',
    'Chandannagar',
    'Chamundi',
    'Chamrajnagar',
    'Champawat',
    'Champahati',
    'Champadanga',
    'Champa',
    'Chamba',
    'Challakere',
    'Chalisgaon',
    'Chakradharpur',
    'Chakan',
    'Canning',
    'Kannur',
    'Candolim',
    'Kolkata',
    'Buxar',
    'Burnpur',
    'Burla',
    'Burhanpur',
    'Bundi',
    'Buldana',
    'Bulandshahr',
    'Budhlada',
    'Budbud',
    'Budaun',
    'Bramhapuri',
    'Brahmapur',
    'Botad',
    'Borsad',
    'Borawar',
    'Bongaigaon',
    'Bomdila',
    'Mumbai',
    'Bolpur',
    'Bokaro Steel City',
    'Bokakhat',
    'Boisar',
    'Buhana',
    'Bodh Gaya',
    'Bodhan',
    'Bishnupur',
    'Beed',
    'Binnakandi',
    'Binnaguri',
    'Bilsi',
    'Bilimora',
    'Bilga',
    'Bilaspur',
    'Bilasipara',
    'Bikramganj',
    'Bikaner',
    'Bijapur',
    'Bijaynagar',
    'Bihar Sharif',
    'Bidar',
    'Bidadi',
    'Biaora',
    'Bhusaval',
    'Bhuj',
    'Bhubaneswar',
    'Bhose',
    'Bhopal',
    'Bhongir',
    'Bhulath Gharbi',
    'Bhogpur',
    'Bhiwani',
    'Bhiwandi',
    'Bhinmal',
    'Bhinai',
    'Bhimpur',
    'Bhimavaram',
    'Bhimadolu',
    'Bhim',
    'Bhilwara',
    'Bhiloda',
    'Bhayandar',
    'Bhawanipatna',
    'Bhawanigarh',
    'Bhavnagar',
    'Bathinda',
    'Bharuch',
    'Bharthana',
    'Bharatpur',
    'Bhanvad',
    'Bhanjanagar',
    'Bhangar',
    'Bhangala',
    'Bhandarej',
    'Bhandara',
    'Bhagalpur',
    'Bhagabanpur',
    'Bhadreswar',
    'Bhadravati',
    'Bhadrakh',
    'Bhadrachalam',
    'Bhadra',
    'Bhadohi',
    'Bhadaur',
    'Bhachau',
    'Beypore',
    'Betul',
    'Bettiah',
    'Betma',
    'Betim',
    'Beri Khas',
    'Benaulim',
    'Belonia',
    'Bellary',
    'Belgharia',
    'Belgaum',
    'Belgachia',
    'Beldanga',
    'Belda',
    'Behror',
    'Begusarai',
    'Begowal',
    'Bedradka',
    'Beawar',
    'Bazpur',
    'Bayad',
    'Bawani Khera',
    'Bawal',
    'Batala',
    'Basti',
    'Bastar',
    'Basirhat',
    'Basi',
    'Barwani',
    'Barwala',
    'Baruipur',
    'Barpeta',
    'Barotha',
    'Barnala',
    'Barnagar',
    'Barmer',
    'Barjora',
    'Baripada',
    'Barh',
    'Bargarh',
    'Bareilly',
    'Bardoli',
    'Barddhaman',
    'Baraut',
    'Barasat',
    'Barang',
    'Baran',
    'Baramati',
    'Barakpur',
    'Barakatha',
    'Baragaon',
    'Bapawar Kalan',
    'Bapatla',
    'Banswara',
    'Banswada',
    'Bansjora',
    'Bansi',
    'Bankura',
    'Banki',
    'Banka',
    'Banjar',
    'Banihal',
    'Bangarapet',
    'Bangaon',
    'Bengaluru',
    'Banga',
    'Banethi',
    'Bandikui',
    'Banda',
    'Banapur',
    'Balurghat',
    'Balu',
    'Balrampur',
    'Balotra',
    'Baloda Bazar',
    'Balod',
    'Ballia',
    'Ballarpur',
    'Ballabgarh',
    'Balicha',
    'Bali',
    'Balasore',
    'Balarampur',
    'Balangir',
    'Balanagar',
    'Balaghat',
    'Bakhli',
    'Bakewar',
    'Bakani',
    'Bajaura',
    'Baikunthpur',
    'Baijnath',
    'Bhuna',
    'Bahua',
    'Bahraich',
    'Baheri',
    'Baharampur',
    'Bahadurgarh',
    'Bagula',
    'Bagrian',
    'Bagodar',
    'Bagicha',
    'Bagpat',
    'Baghnagar',
    'Bagha Purana',
    'Bageshwar',
    'Bagepalli',
    'Bagalkot',
    'Baduria',
    'Badnawar',
    'Badlapur',
    'Badkulla',
    'Badarpur',
    'Badanganj',
    'Badami',
    'Bachai',
    'Babrala',
    'Babina',
    'Azimganj',
    'Azamgarh',
    'Ayodhya',
    'Avanigadda',
    'Avinashi',
    'Avadi',
    'Aurangabad',
    'Auraiya',
    'Aundh',
    'Attur',
    'Attingal',
    'Attili',
    'Attibele',
    'Athani',
    'Assonora',
    'Assagao',
    'Asind',
    'Asika',
    'Ashta',
    'Ashoknagar',
    'Asansol',
    'Arvi',
    'Arumuganeri',
    'Arsikere',
    'Arpora',
    'Armori',
    'Arki',
    'Arkar',
    'Ariyalur',
    'Ardi',
    'Araul',
    'Arni',
    'Arambol',
    'Arambagh',
    'Arakkonam',
    'Arrah',
    'Anuppur',
    'Anupgarh',
    'Anumanandal',
    'Ankleshwar',
    'Anjuna',
    'Anjar',
    'Angul',
    'Angamali',
    'Angadipram',
    'Anekal',
    'Anthiyur',
    'Andheri',
    'Andal',
    'Anchal',
    'Anavatti',
    'Anantnag',
    'Anantapur',
    'Anandpur',
    'Anand',
    'Anakapalle',
    'Amroha',
    'Amritsar',
    'Amreli',
    'Amravati',
    'Amod',
    'Ammapettai',
    'Amloh',
    'Amguri',
    'Amethi',
    'Ambur',
    'Amboli',
    'Ambikapur',
    'Ambheti',
    'Ambattur',
    'Ambasamudram',
    'Ambalapuzha',
    'Ambala',
    'Ambajogai',
    'Amb',
    'Ambarnath',
    'Amalner',
    'Amalapuram',
    'Alwar',
    'Alur',
    'Alod',
    'Almora',
    'Allinagaram',
    'Alappuzha',
    'Allahabad',
    'Alipur Duar',
    'Alipur',
    'Aligarh',
    'Aliganj',
    'Alibag',
    'Aldur',
    'Aldona',
    'Alandi',
    'Alanallur',
    'Akot',
    'Akola',
    'Akkihebbal',
    'Akividu',
    'Akbarpur',
    'Akaltara',
    'Ajnala',
    'Ajmer',
    'Ajjampur',
    'Ajas',
    'Aizawl',
    'Ahwa',
    'Ahmadpur',
    'Ahmednagar',
    'Ahmedabad',
    'Agra',
    'Agonda',
    'Agolai',
    'Agni',
    'Aghapur',
    'Agartala',
    'Agaram',
    'Agar',
    'Adyar',
    'Adur',
    'Adoni',
    'Adoli',
    'Adilabad',
    'Addanki',
    'Adavad',
    'Adalaj',
    'Achina',
    'Abu Road',
    'Mount Abu',
    'Abohar',
    'Abhayapuri',
    'Phogan',
    'Dalli Rajhara',
    'Bhanupratappur',
    'Contai',
    'Haldia',
    'Bagnan',
    'Haripal',
    'Ramnagar',
    'Baruipara',
    'Chanditala',
    'Bahira',
    'Andharmanik',
    'Bhasa',
    'Amtala',
    'Gazipur',
    'Gujrat',
    'Debipur',
    'Chakdaha',
    'Narayanpur',
    'Badu',
    'Amratala',
    'Anchar',
    'Haryana',
    'Talab Tillo',
    'Premnagar',
    'Landhaura',
    'Weir',
    'Alipura',
    'Kair',
    'Bajhol',
    'Bamoli',
    'Amsaur',
    'Chevella',
    'Shadnagar',
    'Sardarnagar',
    'Kanaiguda',
    'Kapra',
    'Hayatnagar',
    'Ghatkesar',
    'Pocharam',
    'Jangaon',
    'Vadigenhalli',
    'Beas',
    'Bokadvira',
    'Erukkancheri',
    'Kilpauk',
    'Mugalivakkam',
    'Vadapalani',
    'Raja Annamalaipuram',
    'Choolai',
    'Chepauk',
    'Royapettah',
    'Anna Nagar',
    'Ganesapuram',
    'Madipakkam',
    'Kotivakkam',
    'Nandambakkam',
    'Gunduperumbedu',
    'Kupwāra',
    'Gadu',
    'Mithoi',
    'Mira Road',
    'Auroville',
    'Puttaparthi',
    'Electronics City',
    'Panchkula',
    'Banaswadi',
    'VasanthaNagar',
    'Domlur',
    'Sholinganallur',
    'Kaudiar',
    'Bavdhan',
    'Dehradun',
    'Greater Noida',
    'Bishangarh',
    'Abusar',
    'Bharunda Kalan',
    'Gowala',
    'Bahadurpur',
    'Bagthala',
    'Jamrar',
    'Mohali',
    'Pelling',
    'Aluva',
    'Kotkapura',
    'Muvattupuzha',
    'Perumbavoor',
    'Vapi',
    'Baddi',
    'Mulund East',
    'Noida',
    'Bhiwadi',
    'Parwanoo',
    'Jorethang',
    'Mandideep',
    'Chembur',
    'Bani Park',
    'Majorda',
    'Hinjewadi',
    'Periyapattinam',
    'Kachiguda',
    'Vaishali Nagar',
    'Malkajgiri',
    'Mandamarri',
    'Gajuwaka',
    'Metpalle',
    'Manuguru',
    'Palwancha',
    'Barpeta Road',
    'Sathupalli',
    'Morigaon',
    'Naharlagun',
    'Silapathar',
    'Lumding Railway Colony',
    'Vazhuthacaud',
    'Banjara Hills',
    'Safdarjung Enclave',
    'Ambalavayal',
    'Alamnagar',
    'Pimpri-Chinchwad',
    'Dadar West',
    'Zira',
    'Adampur',
    'Vasant Kunj',
    'Cherai',
    'Vyttila',
    'Patran',
    'Jagatpura',
    'Jubilee Hills',
    'Goregaon East',
    'Civil Lines',
    'Kharghar',
    'Chendamangalam',
    'Nariman Point',
    'Omandur',
    'Thuraiyur',
    'Kattumannarkoil',
    'Mansarovar',
    'Ramagundam',
    'Malsian',
    'Birpara',
    'Artara',
    'Ravulapalem',
    'Jojawar',
    'Shiruru',
    'Akurdi',
    'Karjan',
    'Biswanath Chariali',
    'Prantij',
    'Kirandul',
    'Dargamitta',
    'Daxini Society',
    'Ganaur',
    'Thoothukudi',
    'Bardez',
    'Kattankulathur',
    'Jagiroad',
    'Haroa',
    'Keshpur',
    'Keshiary',
    'Naoboicha',
    'Chanchal',
    'Nagrota Surian',
    'Laitumkhrah',
    'Barshaini',
    'Kosagumuda',
    'Kheroni',
    'Bihpuria',
    'Dullavcherra',
    'Kurukshetra',
    'Sheragada',
    'Mal Bazar',
    'Banarhat',
    'Barbigha',
    'Jaykaypur',
    'Joda',
    'Khawzawl',
    'Bagodara',
    'Thodupuzha',
    'Purnea',
    'Srikalahasti',
    'Nedumkandam',
    'Vagamon',
    'Bhuntar',
    'Tarapith',
    'Nigdhu',
    'Bhawar',
    'Ahulana',
    'Guwalda',
    'Jhakri',
    'Pammal',
    'Maduravoyal',
    'Naraingarh',
    'Badhawar',
    'Bathuni',
    'Churag',
    'Badian',
    'Aduka',
    'Chomu',
    'Jagitial',
    'Ambuwala',
    'Aithal',
    'Haripur Kalan',
    'Anantmau',
    'Bareli',
    'Pipariya',
    'Anhai',
    'Morshi',
    'Moranwali',
    'Bargari',
    'Raman',
    'Raman Mandi',
    'Bhamipura',
    'Ghanda Bana',
    'Tapa',
    'Belra',
    'Burari',
    'Dad',
    'Mundka',
    'Kakrola',
    'Nangal Raya',
    'Anand Parbat',
    'East Of Kailash',
    'Bisrakh',
    'Boinda',
    'Tensa',
    'Dalkhola',
    'Hajipur',
    'Bhagowal',
    'Bassi',
    'Akhepura',
    'Dariba',
    'Adamwal',
    'Goa',
    'Andana',
    'Achhej',
    'Bhakli',
    'Araghar',
    'Ramannapet',
    'Singtam',
    'Muradnagar',
    'Ponkunnam',
    'Ettumanoor',
    'Perumpilavu',
    'Biraul',
    'Rosera',
    'Takhran',
    'Sopara',
    'Dalla',
    'Khant',
    'Rajpura',
    'Allowal',
    'Pisawa',
    'Hoshiarpur',
    'Tanda',
    'Sawantwadi',
    'Bati',
    'Achheja',
    'Bemetara',
    'Bhilai',
    'Mungeli',
    'Nehrunagar',
    'Karegaon',
    'Chincholi',
    'Suraram',
    'Jinnaram',
    'Ibrahimpatnam',
    'Bhatani',
    'Chandeni',
    'Chaumuhan',
    'Bhogalpura',
    'Balrai',
    'Ankilla',
    'Narsapur',
    'Gajulpet',
    'Shantinagar',
    'Sawaigudem',
    'Badli',
    'Ali Meo',
    'Alwal',
    'Appajipet',
    'Himmatnagar',
    'Addagudur',
    'Amberpet',
    'Kadipikonda',
    'Subedari',
    'Rangashaipet',
    'Kasibugga',
    'Agapet',
    'Ballepalli',
    'Madannapet',
    'Bojannapet',
    'Bangarigadda',
    'Singrauli',
    'Amaravaram',
    'Abdullapur',
    'Bhidur',
    'Gola',
    'Rawatpur',
    'Basupur',
    'Nadwa',
    'Salempur',
    'Daudpur',
    'Gopiganj',
    'Chandauli',
    'Babri',
    'Derhgawan',
    'Niwai',
    'Adsul',
    'Abbotganj',
    'Babhulgaon',
    'Nai Bazar',
    'Amauli',
    'Basantpur',
    'Abbaipalem',
    'Aptur',
    'Chandanagar',
    'Gaganpahad',
    'Kismatpur',
    'Attapur',
    'Hyderguda',
    'Barkas',
    'Uppuguda',
    'Aliabad',
    'Yakutpura',
    'Abids',
    'Mehdipatnam',
    'Tolichowki',
    'Erragadda',
    'Somajiguda',
    'Khairatabad',
    'Prakashamnagar',
    'Chandubatla',
    'Shamirpet',
    'Chitakodur',
    'Dubbak',
    'Kurella',
    'Taroda',
    'Bhadaiyan',
    'Udaipura',
    'Hanumanpura',
    'Shahganj',
    'Barsathi',
    'Adhanpur',
    'Khamaria',
    'Birbhanpur',
    'Amaura',
    'Babail',
    'Ghori',
    'Bahu Akbarpur',
    'Achhola',
    'Arjunamau',
    'Barhana',
    'Sikandrabad',
    'Anpara',
    'Arjunganj',
    'Dhatrara',
    'Milak',
    'Arsath',
    'Dehli',
    'Yellareddipet',
    'Maskanwa',
    'Nawabganj',
    'Bindrabazar',
    'Budhana',
    'Munderwa',
    'Barhalganj',
    'Bhujahi',
    'Karhan',
    'Aroor',
    'Ravangla',
    'Agai',
    'Domadih',
    'Barhni',
    'Umaria',
    'Rahimabad',
    'Itwa',
    'Arail',
    'Chhatnag',
    'Kalyanpur',
    'Pithampur',
    'Bina',
    'Baduhi',
    'Sangmeshwar',
    'Girad',
    'Chhapi',
    'Jambahar',
    'Rajpur',
    'Baharwal',
    'Dhotian',
    'Ahirka',
    'Agewa',
    'Shirur',
    'Dhariwal',
    'Dhandal',
    'Bara Sidhpur',
    'Dhundhla',
    'Jandaur',
    'Janephal',
    'Bahtarai',
    'Khamtarai',
    'Nasrala',
    'Goraya',
    'Dongargaon',
    'Alkanhar',
    'Ramsar',
    'Saket',
    'Bhatapara',
    'Allachaur',
    'Bains',
    'Nangal',
    'Bararta',
    'Balt',
    'Kumbh',
    'Badheri',
    'Deori',
    'Bari',
    'Amarwasi',
    'Arjuni',
    'Badheri Ghoghu',
    'Agrod',
    'Ainthapali',
    'Badmal',
    'Bahanpur',
    'Arambakkam',
    'Apparaopalem',
    'Gannavaram',
    'Peravaram',
    'Chintaluru',
    'Doddipatla',
    'Chilukuru',
    'Kanuru',
    'Chittavaram',
    'Aswapuram',
    'Sarapaka',
    'Rajam',
    'Anigandlapadu',
    'Chandapuram',
    'Ambarpet',
    'Nuapada',
    'Chandrala',
    'Baliguda',
    'Manikonda',
    'Bacheli',
    'Katekalyan',
    'Korukonda',
    'Akkayyapalem',
    'Arepalli',
    'Lankam',
    'Ambajipeta',
    'Nallakunta',
    'Ganapavaram',
    'Aladu',
    'Kopa',
    'Chinnayagudem',
    'Cherthala',
    'Dudhathal',
    'Karadiya',
    'Deoli',
    'Vav',
    'Falna',
    'Gadarpur',
    'Degana',
    'Airoli',
    'Ajanda',
    'Sherpur',
    'Bhainsmara',
    'Almas',
    'Ramgarhwa',
    'Rudarpur',
    'Bhagwanpur',
    'Brahmanpuri',
    'Nathnagar',
    'Masaurhi',
    'Puraini',
    'Dumraon',
    'Deo',
    'Bihta',
    'Arthua',
    'Tetariya',
    'Danapur',
    'Annavarappadu',
    'Baira',
    'Harkadi',
    'Bola',
    'Nitte',
    'Amblamogaru',
    'Kandavara',
    'Kulai',
    'Anekallu',
    'Tadas',
    'Hosakote',
    'Bondaladinne',
    'Nagarbhavi',
    'Gunjur',
    'Agaradahalli',
    'Ramanattukara',
    'Shamshi',
    'Pudubettu',
    'Gajanur',
    'Darbe',
    'Barebettu',
    'Kadabettu',
    'Karkala',
    'Dahegaon',
    'Anaji',
    'Rekya',
    'Pawapuri',
    'Bhojpur',
    'Akauna',
    'Harave',
    'Basavapatna',
    'Subhankarpur',
    'Putai',
    'Bettadapura',
    'Barhmotra',
    'Kallara',
    'Ajhaur',
    'Harapanahalli',
    'Harohalli',
    'Bori',
    'Tarikere',
    'Arakere',
    'Channahalli',
    'Aladahalli',
    'Bommanahalli',
    'Bannur',
    'Anugonda',
    'Chirakkal',
    'Puzhathi',
    'Kannadiparamba',
    'Kappad',
    'Kanayannur',
    'Bamroda',
    'Dela',
    'Chalil',
    'Purameri',
    'Azhiyur',
    'Kothamangalam',
    'Kannadi',
    'Mundakkal',
    'Malapuram',
    'Gramam',
    'Edakkara',
    'Mangaon',
    'Peddapalli',
    'Ponnur',
    'Bittangala',
    'Gonikoppal',
    'Badaga',
    'Anegundi',
    'Bhawanipur',
    'Sonpur',
    'Bagdogra',
    'Bakhri',
    'Nadghat',
    'Madhepura',
    'Birnagar',
    'Baidara',
    'Laskarpur',
    'Anandanagar',
    'Tapan',
    'Atla',
    'Bankipore',
    'Mari',
    'Tajnipur',
    'Malakpet',
    'Tarnaka',
    'Bodupal',
    'Almasguda',
    'Champapet',
    'Narsampet',
    'Araria',
    'Garhmukteshwar',
    'Rinchenpong',
    'Sangolda',
    'Moreh',
    'Kakkanad',
    'Abrama',
    'Aliparamba',
    'Alkapuri',
    'Ammavarikuppam',
    'Anaparthy',
    'Andipalayam',
    'Ukhra',
    'Thumpamon',
    'Savarkundla',
    'Rampura Phul',
    'Pothencode',
    'Poovathussery',
    'Pathsala',
    'Panoli',
    'Ochanthuruth',
    'Nirjuli',
    'Nanganallur',
    'Chevitikallu',
    'Chungathara',
    'Chottanikkara',
    'Mundamveli',
    'Edavilangu',
    'Hindmotor',
    'Hridaypur',
    'Kalayanthani',
    'Kalpeni',
    'Kannamangalam',
    'Karthikappally',
    'Katgun',
    'Kolaghat',
    'Kolenchery',
    'Maradu',
    'Marthandam',
    'Vastral',
    'Chayalode',
    'Erumely',
    'Indore G.P.O.',
    'Kavundampalayam',
    'Madhavaram Milk Colony',
    'Padinjarathara',
    'Hosapete',
    'Akkur',
    'Hulikere',
    'Agara',
    'Krishnapura',
    'Annavaram',
    'Ambakapalle',
    'Bommasandra',
    'Angallu',
    'Madanapalle',
    'Kunchanapalle',
    'Dommasandra',
    'Begepalli',
    'Hosur',
    'Ajjanahalli',
    'Natrampalli',
    'Madanapalli',
    'Anjur',
    'Narayanapuram',
    'Pudukottai',
    'Periyagaram',
    'Marripalem',
    'Chintalapudi',
    'Ramachandrapuram',
    'Ankireddipalli',
    'Vaddapalli',
    'Damaramadugu',
    'Bandarupalle',
    'Bommarajupeta',
    'Nellikuppam',
    'Adaiyur',
    'Thandalam',
    'Veppampattu',
    'Illodu',
    'Pudupakkam',
    'Pennalur',
    'Velacheri',
    'Arugavur',
    'Kalpakkam',
    'Akshipakkam',
    'Manapakkam',
    'Pathanapuram',
    'Morayur',
    'Arimbra',
    'Valluvambram',
    'Kittur',
    'Belladi',
    'Chappanangadi',
    'Anakkara',
    'Chembra',
    'Cherukara',
    'Vaduthala',
    'Chittattukara',
    'Toyakkavu',
    'Velappaya',
    'Malipuram',
    'Amminikkad',
    'Cherukattupulam',
    'Nattukal',
    'Ambalapara',
    'Amballur',
    'Kodakara',
    'Annallur',
    'Kalamasseri',
    'Okkal',
    'Chakkuvarakkal',
    'Sultanpet',
    'Mannarai',
    'Nasiyanur',
    'Khodiyarnagar',
    'Andiyur',
    'Erumanur',
    'Kiliyanur',
    'Karimangalam',
    'Anniyur',
    'Kuttalam',
    'Balanda',
    'Bara Ghaghra',
    'Gurgain',
    'Palamu',
    'Bermo',
    'Bhuli',
    'Khalari',
    'Jainagar',
    'Gagi',
    'Gidhour',
    'Mandar',
    'Mukkudal',
    'Alangulam',
    'Jambulingapuram',
    'Chinnamadankudiyiruppu',
    'Mudittanendal',
    'Kumaram',
    'Usilampatti',
    'Kavanur',
    'Chettipatti',
    'Kallankattuvalasu',
    'Alampatti',
    'Sundararajapuram',
    'Krishnapuram',
    'Ennai',
    'Servaikaranpatti',
    'Angarai',
    'Turaiyur',
    'Arasangudi',
    'Navalur',
    'Perambur',
    'Andipatti',
    'Perungudi',
    'Aliyanilai',
    'Rayapuram',
    'Alangudi',
    'Mandapasalai',
    'Kattikulam',
    'Kalaiyarkovil',
    'Ariyanendal',
    'Puduvayal',
    'Arumbakkam',
    'Tirumangalam',
    'Radhapuram',
    'Elleri',
    'Pambanar',
    'Kakkaveri',
    'Kilakadu',
    'Jambunathapuram',
    'Agarakottalam',
    'Sonua',
    'Baidmara',
    'Karanjia',
    'Bankual',
    'Biswanathpur',
    'Bhatasahi',
    'Kalahandi',
    'Nuagaon',
    'Ambapalas',
    'Kamarda',
    'Sihodih',
    'Manikdiha',
    'Madhupur',
    'Amarpali',
    'Batgaon',
    'Barbil',
    'Bhupalpally',
  ],
  China: [
    'Jiuquan',
    'Shengping',
    'Mangshi',
    'Lhasa',
    'Jiayuguan City',
    'Yining',
    'Ürümqi',
    'Shihezi',
    'Karamay',
    'Changji',
    'Taiwan',
    'Dailou',
    'Suzuo',
    'Jiulong',
    'Lintan Chengguanzhen',
    'Zunyi',
    'Yanjiang',
    'Zigong',
    'Jianning',
    'Zhuozhou',
    'Zhumadian',
    'Shangqiu',
    'Zhuji',
    'Zhuanghe',
    'Zhoukou',
    'Yuxi',
    'Zhifang',
    'Zhenjiang',
    'Zhaobaoshan',
    'Zhengzhou',
    'Yongji',
    'Zhaotong',
    'Zhaoqing',
    'Zhaoyuan',
    'Zhanjiang',
    'Zhangzhou',
    'Zhangye',
    'Zhangshu',
    'Zibo',
    'Anyang',
    'Zaozhuang',
    'Zaoyang',
    'Yuyao',
    'Yuzhou',
    'Yingchuan',
    'Kunshan',
    'Junyang',
    'Jinghong',
    'Pizhou',
    'Yunfu',
    'Yuncheng',
    'Yulin',
    'Yueyang',
    'Yongjiawan',
    'Yueqing',
    'Yuci',
    'Heyuan',
    'Yongzhou',
    'Yisa',
    'Yingxian',
    'Yingtan',
    'Yinchuan',
    'Qingzhou',
    'Yichun',
    'Yicheng',
    'Yichang',
    'Yibin',
    'Yaocheng',
    'Yantai',
    'Yanjiao',
    'Yangzhou',
    'Yangquan',
    'Yangpu',
    'Yancheng',
    'Xuzhou',
    'Shangrao',
    'Xujiahui',
    'Xuhui',
    'Xugezhuang',
    'Xuchang',
    'Xuanzhou',
    'Zijin',
    'Xiulin',
    'Xinzhou',
    'Xinhualu',
    'Xinyu',
    'Xinyang',
    'Xinxiang',
    'Xintai',
    'Xinpu',
    'Xining',
    'Xingtai',
    'Ankang',
    'Xinanzhuang',
    'Xichang',
    'Haining',
    'Zijinglu',
    'Xiaoshan',
    'Xiaogang',
    'Xianyang',
    'Xiantao',
    'Zhuhai',
    'Xiangxiang',
    'Xiangfan',
    'Xiangcheng Chengguanzhen',
    "Xi'an",
    'Xiamen',
    'Wuzhou',
    'Wuzhong',
    'Wuxue',
    'Wuxi',
    'Tongxiang',
    'Changde',
    'Wuhan',
    'Wuhai',
    'Wenzhou',
    'Wenling',
    'Tianfu',
    'Weinan',
    'Weihai',
    'Weifang',
    'Wanning',
    'Huangshan City',
    'Tongzhou',
    'Fuding',
    'Tongren',
    'Tongling',
    'Tongcheng',
    'Tiantai Chengguanzhen',
    'Tianshui',
    'Tianjin',
    'Tianchang',
    'Tengzhou',
    'Tangshan',
    'Taku',
    'Tangdukou',
    'Taizhou',
    'Taiyuan',
    'Taixing',
    'Taiping',
    'Taishan',
    'Chengxiang',
    "Tai'an",
    'Suzhou',
    'Suqian',
    'Suizhou',
    'Suining',
    'Songjiang',
    'Sishui',
    'Shuozhou',
    'Shuangliu',
    'Shouguang',
    'Shizuishan',
    'Shiyan',
    'Shiqiao',
    'Shijiazhuang',
    'Shenzhen',
    'Shanhu',
    'Shashi',
    'Shaoxing',
    'Shaoguan',
    'Shantou',
    'Shangzhen',
    'Shanghai',
    'Shancheng',
    'Sanming',
    'Sanmenxia',
    'Sanhe',
    'Changsha',
    'Rulong',
    'Fuqing',
    'Jieyang',
    'Rizhao',
    'Quzhou',
    'Qujing',
    'Qufu',
    'Quanzhou',
    'Qionghu',
    'Qinzhou',
    'Qinhuangdao',
    'Jinjiang',
    'Qingpu',
    "Huai'an",
    'Qingdao',
    'Qingyuan',
    'Qishan',
    'Puyang Chengguanzhen',
    'Putuo',
    'Pulandian',
    'Pudong',
    'Pingxiang',
    'Pingliang',
    'Pingdu',
    'Pingdingshan',
    'Dadukou',
    "Guang'an",
    'Ningbo',
    'Neijiang',
    'Nanyang',
    'Nantong',
    'Nanqiao',
    'Nanning',
    'Nanjing',
    'Nangong',
    'Nanchong',
    'Nanchang',
    'Gaoyao',
    'Mojiancun',
    'Mingshui',
    'Miluo Chengguanzhen',
    'Mianyang',
    'Mianchi Chengguanzhen',
    'Mentougou',
    'Wenlan',
    'Huichang',
    'Wuchuan',
    'Maoming',
    'Haimen',
    'Macheng',
    'Maba',
    'Luzhou',
    'Luoyang',
    'Luohe',
    'Luocheng',
    "Lu'an",
    'Loudi',
    'Longquan',
    'Longyan',
    'Longchuan',
    'Licheng',
    'Guankou',
    'Puning',
    'Lishui',
    'Lishi',
    'Liuzhou',
    'Linyi',
    'Linhai',
    'Cuifeng',
    'Lingcheng',
    'Linfen',
    'Lincang',
    'Xishan',
    'Lijiang',
    'Lichuan',
    'Liaocheng',
    'Lianshui',
    'Wuwei',
    'Lianjiang',
    'Leshan',
    'Leling',
    'Leiyang',
    'Lecheng',
    'Laohekou',
    'Lanzhou',
    'Langfang',
    'Lankao Chengguanzhen',
    'Laizhou',
    'Laiwu',
    'Laibin',
    'Kunyang',
    'Kunming',
    'Kaili',
    'Kaifeng',
    'Jurong',
    'Jiyuan',
    'Jiujiang',
    'Jiufo',
    'Jinxiangzhen',
    'Jintan',
    'Jining',
    'Jinhua',
    'Jingling',
    'Jingjiang',
    'Jinggangshan',
    'Jingdezhen',
    'Zhangping',
    'Jinchang',
    'Jinan',
    'Jimo',
    'Jimei',
    'Jiexiu',
    'Jiaxing',
    'Jiaozuo',
    'Jiaozhou',
    'Ningde',
    'Jianguang',
    'Jiangna',
    'Jiangmen',
    'Yangjiang',
    'Ji’an',
    'Guangyuan',
    'Qionghai',
    'Huzhou',
    'Xushan',
    'Huizhou',
    'Chengguan',
    'Huilong',
    'Xinhui District',
    'Yuhuan',
    'Huangshancun',
    'Daxing',
    'Huainan',
    'Huaihua',
    'Huaibei',
    'Hongkou',
    'Hongjiang',
    'Heze',
    'Jianshe',
    'Yiyang',
    'Hengyang',
    'Hengshui',
    'Henan',
    'Helixi',
    'Hejian',
    'Hefei',
    'Hebi',
    'Hanzhong',
    "Fu'an",
    'Hangzhou',
    'Changzhi',
    'Handan',
    'Hanchuan',
    'Jiaojiang',
    'Haikou',
    'Haidian',
    'Guyuan',
    'Guozhen',
    'Guli',
    'Guiyang',
    'Guilin',
    'Guigang',
    'Gucheng Chengguanzhen',
    'Guangzhou',
    'Guangshui',
    'Gejiu',
    'Gaozhou',
    'Gaoyou',
    'Gaomi',
    'Gaocheng',
    'Ganzhou',
    'Ganzhe',
    'Fuzhou',
    'Fuyang',
    'Fuling',
    'Foshan',
    'Fenyang',
    'Fengtai',
    'Fengcheng',
    'Feicheng',
    'Fangshan',
    'Ezhou',
    'Enshi',
    'Encheng',
    'Duyun',
    'Xinyi',
    'Dongying',
    'Dongtai',
    'Dongguan',
    'Donggang',
    'Dingxi',
    'Dingtao',
    'Dingcheng',
    'Dianbu',
    'Dezhou',
    'Deyang',
    'Deqing',
    'Dengzhou',
    'Huazhou',
    'Daye',
    'Dazhou',
    'Dawangli',
    'Fenghua',
    'Danyang',
    'Danjiangkou',
    'Tai Ping',
    'Pinghu',
    'Shunde',
    'Dalian',
    'Dali',
    'Dahuo',
    'Chuzhou',
    'Chuxiong',
    'Yangchun',
    'Yiwu',
    'Chongqing',
    'Chengqiao',
    'Chizhou',
    'Chishui',
    'Chishiqiao',
    'Chenzhou',
    'Jiangyin',
    'Chengdu',
    'Chenghua',
    'Chatingcun',
    'Chaozhou',
    'Chaohu',
    'Changzhou',
    'Kuiju',
    'Changning',
    'Changle',
    'Longxing',
    'Cangzhou',
    'Bozhou',
    'Baise City',
    'Binzhou',
    'Bijie',
    'Bengbu',
    'Beijing',
    'Beihai',
    'Beifang',
    'Beicai',
    'Beibei',
    'Bazhou',
    'Baoshan',
    'Baoding',
    'Baodi',
    'Baiyin',
    'Baisha',
    'Anshun',
    'Anqing',
    'Zhuqiao',
    'Lulou',
    'Datong',
    'Changshacun',
    'Zhongshancun',
    'Huangshi',
    'Baiyu',
    'Hubin',
    'Xianggang',
    'Sanya',
    'Shanweicun',
    'Zhongshan',
    'Wenchang',
    'Ledong',
    'Hainan',
    'Wuzhishan',
    'Shamen',
    'Wuyishan',
    'Xinjiang',
    'Taoyuan',
    'Hubei',
    'Xiangtan',
    'Yongxing',
    'Daban',
    'Changshan',
    'Nanping',
    'Zhoushan',
    'Jingmen',
    'Anbian',
    'Guanshan',
    'Baojingyuan',
    'Meizhou',
    'Shishi',
    'Putian',
    'Xianyou',
    'Yangzhong',
    'Zunhua',
    'Zhaodong',
    'Zhangjiakou',
    'Yanqing',
    'Xinmin',
    'Xicheng',
    'Xiabancheng',
    'Tongliao',
    'Tieling',
    'Suihua',
    'Siping',
    'Shunyi',
    'Shenyang',
    'Qiqihar',
    'Pinggu',
    'Mudanjiang',
    'Miyun',
    'Meihekou',
    'Longjing',
    'Linhe',
    'Liaoyang',
    'Kaiyuan',
    'Jixi',
    'Jiutai',
    'Jinzhou',
    'Jilin City',
    'Jiamusi',
    'Hohhot',
    'Heihe',
    'Harbin',
    'Haicheng',
    'Fuxin',
    'Fushun',
    'Diaobingshancun',
    'Dehui',
    'Dashiqiao',
    'Daqing',
    'Dandong',
    'Chifeng',
    'Chaoyang',
    'Changping',
    'Changchun',
    'Chaigoubu',
    'Benxi',
    'Beishicao',
    'Baotou',
    'Baishan',
    'Anshan',
    'Anda',
    'Acheng',
    'Baimiao',
    'Qianjin',
    'Dadong',
    'Wuchang',
    'Shangshan',
    'Xinzhai',
    'Chetou',
    'Chancheng',
    'Hedong',
    'Baoshucun',
    'Chanshan',
    'Beilin',
    'Tongchuan',
    'Wuhu',
    'Jincheng',
    'Shanghaicun',
    'Changshu City',
    'Jinhu',
    'Qingyangxia',
    'Shanwei',
    'Chaichang',
    'Beidang',
    'Changqiao',
    'Yangxing',
    'Jianxing',
    'Zaoshu',
    'Gangkou',
    'Chenqiao',
    'Nanjin',
    'Chengbei',
    'Jinhui',
    'Xinchenglu',
    'Daqiao',
    'Baihe',
    'Dayingzicun',
    'Qishi',
    'Ulanqab',
    'Ordos',
    'Baota',
    'Dajiang',
    'Huozhou',
    'Shangyu',
    'Dongpei',
    'Huayang',
    'Zhangjiagang',
    'Xingjiang',
    'Dayingmen',
    'Dongyang',
    'Baigou',
    'Guangdong',
    'Jianhua',
    'Wenshan City',
    'Huanggang',
    'Hongyuan',
    'Xinyuan',
    'Baiyan',
    'Chenjiaying',
    'Gaonan',
    'Zhou',
    'Chibi',
    'Dapang',
    'Baitang',
    'Hechi',
    'Sichuan',
    'Banhe',
    'Baixu',
    'Donghua',
    'Bandong',
    'Dongping',
    'Naliao',
    'Hezhou',
    'Nanling',
    'Fucheng',
    'Dacai',
    'Bailan',
    'Fujian',
    'Yizhou',
    'Qiaoxi',
    'Gouchang',
    'Mile',
    'Xinhe',
    'Xiaohekou',
    'Baike',
    'Shifang',
    'Fangyuan',
    'Meishan',
    'Bishanmiao',
    'Baita',
    'Bajiao',
    'Anloucun',
    'Qianjiang',
    'Ruijin',
    'Balitang',
    'Tongshan',
    'Panjin',
    'Anji',
    'Xinchang',
    'Jiashan',
    "Ji'an",
    'Yangling',
    'Xingping',
    "Yan'anshi",
    'Taiyuanshi',
    'Beiliyang',
    'Huludao',
    'Dongxing',
    'Bazhong',
  ],
  Maldives: [
    'Malé',
    'Hithadhoo',
    'Eydhafushi',
    'Kudahuvadhoo',
    'Viligili',
    'Thinadhoo',
    'Fuvahmulah',
    'Dhidhdhoo',
    'Kulhudhuffushi',
    'Naifaru',
    'Muli',
    'Ugoofaaru',
    'Funadhoo',
    'Veymandoo',
    'Hulhumale',
    'Fonadhoo',
    'Ukulhas',
    'Rasdhoo',
  ],
  Nepal: [
    'Tulsipur',
    'Surkhet',
    'Pokhara',
    'Patan',
    'Kirtipur',
    'Kathmandu',
    'Kaski',
    'Janakpur Dham',
    'Hetauda',
    'Dhankuta',
    'Dhangadhi',
    'Dailekh',
    'Butwal',
    'Biratnagar',
    'Bhaktapur',
    'Siddharthanagar',
    'Bardiya',
    'Achham',
    'Birendranagar',
    'Nepalgunj',
    'Damak',
    'Gauradaha',
    'Gauriganj',
    'Baliya',
    'Imadol',
    'Urlabari',
    'Chandranigahpur',
    'Lalbandi',
  ],
  Myanmar: [
    'Wagan',
    'Dawei',
    'Taunggyi',
    'Sittwe',
    'Sagaing',
    'Yangon',
    'Pyinmana',
    'Papun',
    'Mawlamyine',
    'Myeik',
    'Meiktila',
    'Mandalay',
    'Loikaw',
    'Lewe',
    'Hlaing',
    'Falam',
    'Naypyidaw',
    'Nay Pyi Taw',
  ],
  Mongolia: [
    'Uliastai',
    'Ulaangom',
    'Tamch',
    'Ovoot',
    'Nuga',
    'Ider',
    'Huyagt',
    'Hongo',
    'Dzel',
    'Khovd',
    'Chihertey',
    'Altai',
    'Ulaan-Uul',
    'Ulan Bator',
    'Tsonholong Suma',
    'Cecerleg',
    'Tsengel',
    'Toyrim',
    'Sühbaatar',
    'Shuluutayn Hiid',
    'Undurkhaan',
    'Mörön',
    'Mandalgovi',
    'Javhlant',
    'Hulstay',
    'Haylaastay',
    'Khatgal',
    'Erdene-Dzuu',
    'Eligen Deese',
    'Dund-Urt',
    'Dalandzadgad',
    'Choyr',
    'Saynshand',
    'Bayanterem',
    'Bayanhongor',
    'Baruun-Urt',
    'Arvayheer',
    'Chingeltei',
  ],
  Kyrgyzstan: [
    'Balykchy',
    'Tokmok',
    'Tash-Kumyr',
    'Talas',
    'Shoro',
    'Kyzyl-Suu',
    'Uzgen',
    'Osh',
    'Karasu',
    'Karakol',
    'Kaindy',
    'Jalal-Abad',
    'Ivanovka',
    'Cholpon-Ata',
    'Bishkek',
    'Batken',
  ],
  'French Southern Territories': ['Port-aux-Français'],
  'Cocos [Keeling] Islands': ['West Island'],
  Palau: [
    'Koror',
    'Ulimang',
    'Angaur',
    'Imeong Hamlet',
    'Ngchesar Hamlet',
    'Ngerkeai',
    'Ngereklmadel',
    'Mengellang',
    'Sonsorol Village',
    'Kayangel',
    'Kloulklubed',
    'Ngetkib',
    'Ngchemiangel',
    'Ngardmau',
  ],
  Vietnam: [
    'Yen Thanh',
    'Yen Bai',
    'Xuan Loc',
    'Trang Bom',
    'Vũng Tàu',
    'Vi Thanh',
    'Vinh Yen',
    'Vinh Phuc',
    'Vinh Long',
    'Vĩnh Long',
    'Vinh',
    'Viet Tri',
    'Van Lam',
    'Thanh Pho Uong Bi',
    'Tuy Hoa',
    'Tuyen Quang',
    'Tra Vinh',
    'Tien Phuoc',
    'Thu Duc',
    'Thu Dau Mot',
    'Thuan An',
    'Tho Xuan',
    'Thoi Hoa',
    'Thieu Hoa',
    'Than Thuoc',
    'Ho Chi Minh City',
    'Thanh Liem',
    'Thanh Hóa',
    'Thai Nguyen',
    'Thai Binh',
    'Tay Ninh',
    'Tan Phu',
    'Tan Binh',
    'Tan An',
    'San Thang',
    'Trai Ngau',
    'Sop Khine',
    'Soc Trang',
    'Sin Chai',
    'Rach Gia',
    'Qui Nhon',
    'Ba Don',
    'Quang Tri',
    'Quan Hau',
    'Quảng Ngãi',
    'Pleiku',
    'Phu Tho',
    'Phu Ly',
    'Phong Tho',
    'Phan Thiết',
    'Phan Rang',
    'Ninh Binh',
    'Nha Trang',
    'Nam Định',
    'Nam Cum',
    'Ban Na An',
    'Mo Cay',
    'Mai Lap',
    'Mai Dich',
    'Luc Ngan',
    'Long Xuyen',
    'Long An',
    'Kien Giang',
    'Lao Cai',
    'Lang Son',
    'Lam Dong',
    'Lai Chau',
    'Kon Tum',
    'Hung Yen',
    'Huế',
    'Ha Long',
    'Hoi An',
    'Hoa Vang',
    'Hoang Mai',
    'Hiep Hoa',
    'Ha Tinh',
    'Hanoi',
    'Ha Nam',
    'Haiphong',
    'Hai Hau',
    'Hai Duong',
    'Ha Giang',
    'Ha Dong',
    'Gia Nghia',
    'Duy Xuyen',
    'GJuc Trong',
    'Dong Xoai',
    'Dong Thap',
    'Dong Nai',
    'Dong Hoi',
    'Dong Ha',
    'Dinh Quan',
    'Dien Chau',
    'Điện Biên Phủ',
    'Quang Nam',
    'Di An',
    'Da Nang',
    'Da Lat',
    'Cu Chi',
    'Cho Gao',
    'Chi Linh',
    'Cho Dok',
    'Cao Lanh',
    'Can Tho',
    'Cam Ranh',
    'Ca Mau',
    'Cai Be',
    'Buon Ma Thuot',
    'Binh Thanh',
    'Binh Tan',
    'Bim Son',
    'Bien Hoa',
    'Bến Tre',
    'Ba Ria',
    'Bao Loc',
    'Ban Pheu',
    'Bac Ninh',
    'Ban Pac Ly',
    'Bac Lieu',
    'Bac Giang',
    'Bac Kan',
    'Ap Trung',
    'Ap Thap Muoi',
    'Quang Binh',
    'Ap Ha',
    'Binh Phuoc',
    'Ap Binh Dinh',
    'An Phu',
    'An Nhon',
    'An Giang',
    'A Me',
    'Quan Bay',
    'Khanh hoa',
    'Thanh Ba',
    'Khu Vuc Mot',
    'Long Thuan',
    'Cho Cau',
    'Khu Pho Mot',
    'Khu Pho Nam',
    'Phu Quoc',
    'Hung Ha',
    'Me Linh',
    'Thuong Tin',
    'Dong Anh',
    'Cau Giay',
    'Hoan Kiem',
    'Nui Thanh',
    'Cam Lam',
    'Gia Lam',
    'Tien Giang',
  ],
  'Democratic Republic of Timor-Leste': [
    'Viqueque',
    'Uatocarabau',
    'Suai',
    'Same',
    'Pante Makasar',
    'Manatutu',
    'Maliana',
    'Likisa',
    'Hatoudo',
    'Dili',
    'Baucau',
    'Lospalos',
    'Gleno',
    'Padiae',
    'Fatubossa',
  ],
  Laos: [
    'Vientiane',
    'Xiangkhoang',
    'Xam Nua',
    'Salavan',
    'Phongsali',
    'Pakse',
    'Muang Xay',
    'Sainyabuli',
    'Vang Vieng',
    'Pakxan',
    'Thakhek',
    'Luang Prabang',
    'Luang Namtha',
    'Ban Takon',
  ],
  Taiwan: [
    'Yongkang',
    'Yonghe',
    'Yuanshan',
    'Yuanlin',
    'Fengtian',
    'Yangmingshan',
    'Yangmei District',
    'Wufeng District',
    'Wujie',
    'Wan-hua',
    'Donggang',
    "Tong'anliao",
    'Tucheng',
    'Caotun',
    'Caoling',
    'Caogang',
    'Cailiao',
    'Caigong',
    'Dounan',
    'Douliu',
    'Toufen Township',
    'Duona',
    'Dexing',
    'Dade',
    'Dabu',
    'Taoyuan District',
    'Danei',
    'Dalin',
    'Dali',
    'Dakeng',
    'Dagang',
    'Taipei',
    'Tainan City',
    'Fengyuan',
    'Taichung',
    'Taichang',
    'Dahua',
    'Daxi',
    'Dazhu',
    'Daqiu',
    'Dajia',
    'Dacheng',
    'Shulin District',
    'Shilin',
    'Shenzhen',
    'Shanhua',
    'Banqiao',
    'Sanyi',
    'Beishi',
    'Sanxia District',
    'Sanchong District',
    'Budai',
    'Bunan',
    'Puli',
    'Buxin',
    'Buzhong',
    'Bitou',
    'Pingtung City',
    "Penghu'ercun",
    'Beitou',
    'Beidou',
    'Beimen',
    'Beigang',
    'Beihe',
    'Donghai',
    'Bade District',
    'Baoshi',
    'Baoshan',
    'Baiyu',
    'Baitang',
    'Bailu',
    'Baxian',
    'Bajian',
    'Bajia',
    'Emei',
    'Ningbu',
    'Neishuangxi',
    'Neihu',
    'Nanzih District',
    'Nantou City',
    'Nangang',
    'Milun',
    'Miaoli',
    'Mazucun',
    'Magong',
    "Da'an",
    'Longtan District',
    'Luzhu',
    'Linkou District',
    'Ligang',
    'Guoxing',
    'Guanglin',
    'Anpo',
    'Gaolang',
    'Gaoyi',
    'Fongshan District',
    'Kaohsiung City',
    'Gangzi',
    'Ruifang District',
    'Yilan',
    'Hualong',
    'Hualien City',
    'Xitun',
    'Xinzhuang',
    'Hsinchu',
    'Xiangshan',
    'Hengzhenzhuang',
    'Fuxingcun',
    'Fangyuan',
    'Zhuwei',
    'Chushui',
    'Zhubei',
    'Chongde',
    'Lehuacun',
    'Zhongli District',
    'Zhonghe',
    'Zhunan',
    'Zhulunli',
    'Chuxiang',
    'Zhouwei',
    'Jincheng',
    'Qingshui',
    'Qingquan',
    'Keelung',
    'Chidong',
    'Jiaosi',
    'Chiayi City',
    'Chashan',
    'Chaoyang',
    'Caoya',
    'Chaoliao',
    'Changtanwo',
    'Changlong',
    'Chang-hua',
    'Adan',
    'Aogu',
    'Andong',
    'Annei',
    'Ankeng',
    'Anzhao',
    'Alian',
    'Aikou',
    'Aquan',
    'Dake',
    'Anshan',
    'Ailiao',
    'Bulun',
    'Xinshequ',
    'Shuili',
    'East District',
    'Zhongxiqu',
    'Anping District',
    'Nanqu',
    'Baisha',
    'Beihua',
    'Bailing',
    'zuo ying qu',
    'Baishi',
    'Bitan',
    'Beimiao',
    'Beixing',
    'Beiying',
    'Dingfu',
    'Xinzhu',
    'Decheng',
    'Taoyuan City',
    'Tanmei',
    'Daling',
    'Baifu',
    'Taitung City',
    'Chenggong',
    'Anli',
    'Wenshan',
    "Tai'an",
  ],
  Philippines: [
    'Zaragoza',
    'Zamboanga City',
    'Villasis',
    'Vigan',
    'Victorias City',
    'Victoria',
    'Valencia',
    'NIA Valencia',
    'Urdaneta',
    'Tuguegarao City',
    'Trinidad',
    'Trece Martires City',
    'Tonsuya',
    'Tondo',
    'Toledo City',
    'Tiaong',
    'Tayug',
    'Taytay',
    'Tayabas',
    'Tarlac City',
    'Tanza',
    'Tanjay',
    'Tangos',
    'Tandag',
    'Tanay',
    'Tanauan',
    'Talon',
    'Talisay',
    'Talisay City',
    'Talavera',
    'Tagum Norte',
    'Magugpo Poblacion',
    'Taguig',
    'Tagbilaran',
    'Tagaytay',
    'Tacurong',
    'Tacloban City',
    'Tabuk',
    'Tabogon',
    'Tabaco',
    'Taal',
    'Surigao City',
    'Sucat',
    'Subic',
    'Sorsogon',
    'Solano',
    'Solana',
    'Socorro',
    'Siquijor',
    'Sinacaban',
    'Silay',
    'Silang',
    'Sibulan',
    'Sibalom',
    'Santo Tomas',
    'Santo Nino',
    'Santolan',
    'Santa Rosa',
    'Santa Rita',
    'Santa Mesa',
    'Santa Elena',
    'Santa Cruz',
    'San Pedro',
    'San Pablo City',
    'San Nicolas',
    'San Miguel',
    'San Mateo',
    'San Manuel',
    'San Juan',
    'San Jose del Monte',
    'San Jose',
    'San Jacinto',
    'San Isidro',
    'Clark',
    'San Francisco',
    'San Fernando',
    'San Fabian',
    'San Carlos City',
    'San Carlos',
    'San Buenaventura',
    'San Antonio',
    'San Andres',
    'San Agustin',
    'Sampaloc',
    'Samal',
    'Sagay',
    'Sacramento',
    'Roxas',
    'Rosario',
    'Rizal',
    'Quiapo District',
    'Quezon City',
    'Quezon',
    'Puerto Princesa City',
    'Puerto Galera',
    'Porac',
    'Polomolok',
    'Plaridel',
    'Pinamalayan',
    'Pinagbuhatan',
    'Pilar',
    'Pila',
    'Pikit',
    'Pateros',
    'Pasong Tamo',
    'Pasig',
    'Pasay',
    'Pasacao',
    'Paranaque City',
    'Paombong',
    'Paoay',
    'Paniqui',
    'Panglao',
    'Pandi',
    'Pandacan',
    'Panabo',
    'Pampanga',
    'Palo',
    'Palayan City',
    'Palanan',
    'Pagsanjan',
    'Pagbilao',
    'Pagadian',
    'Padre Garcia',
    'Paco',
    'Ozamiz',
    'Oroquieta',
    'Ormoc City',
    'Orion',
    'Orani',
    'Opol',
    'Olongapo City',
    'Obando',
    'Numancia',
    'Noveleta',
    'Novaliches Proper',
    'Navotas',
    'Nasipit',
    'Nangka',
    'Naic',
    'Naguilian',
    'Nagcarlan',
    'Naga',
    'Nabunturan',
    'Nabua',
    'Nabas',
    'Muntinlupa',
    'Munoz',
    'Rodriguez',
    'Molave',
    'Minglanilla',
    'Minalin',
    'Milaor',
    'Meycauayan',
    'Mexico',
    'Mercedes',
    'Mendez-Nunez',
    'Medina',
    'Medellin',
    'Mati',
    'Masbate',
    'Mariveles',
    'Marilao',
    'Marikina City',
    'Maria',
    'Marawi City',
    'Maramag',
    'Mapandan',
    'Manticao',
    'Manila',
    'Mangaldan',
    'Mandurriao',
    'Mandaue City',
    'Mandaluyong City',
    'Mandaluyong',
    'Manajao',
    'Mamburao',
    'Malvar',
    'Malolos',
    'Malilipot',
    'Malaybalay',
    'Malay',
    'Malate',
    'Malabon',
    'Makati',
    'Makati City',
    'Magalang',
    'Maco',
    'Macabebe',
    'Mabitac',
    'Mabalacat',
    'Lutayan',
    'Lumbang',
    'Luisiana',
    'Lugait',
    'Lucena City',
    'Lucban',
    'Lubao',
    'Los Banos',
    'Lipa City',
    'Lingayen',
    'Linamon',
    'Limay',
    'Liloan',
    'Ligao',
    'Libmanan',
    'Leyte',
    'Legazpi',
    'Laurel',
    'La Union',
    'La Trinidad',
    'Las Pinas',
    'Lapu-Lapu City',
    'Laoag',
    'La Libertad',
    'Laguna',
    'Lagindingan',
    'La Castellana',
    'La Carlota',
    'Labrador',
    'Labo',
    'Koronadal',
    'Kidapawan',
    'Kiblawan',
    'Kawit',
    'Kasilawan',
    'Caruhatan',
    'Kapatagan',
    'Calumpang',
    'Kalibo Town',
    'Jaen',
    'Isulan',
    'Isabela',
    'Iriga City',
    'Intramuros',
    'Indang',
    'Imus',
    'Iloilo City',
    'Iligan City',
    'Iligan',
    'Ibaan',
    'Hinigaran',
    'Hermosa',
    'Hamtic',
    'Hagonoy',
    'Gulod',
    'Guihulngan',
    'Guiguinto',
    'Guagua',
    'Guadalupe Viejo',
    'Goa',
    'General Trias',
    'General Santos',
    'Gapan',
    'Floridablanca',
    'Escalante',
    'Ermita',
    'El Salvador',
    'Dumaguete',
    'Dulag',
    'Dita',
    'Dipolog City',
    'Dipaculao',
    'Dinalupihan',
    'Digos',
    'Diffun',
    'Del Rosario',
    'Davao City',
    'Dasmarinas',
    'Danao',
    'Dagupan',
    'Daet',
    'Cubao',
    'Cotabato City',
    'Consolacion',
    'Concepcion',
    'Carmona',
    'Claver',
    'Cebu City',
    'Cavite',
    'Cavite City',
    'Cauayan',
    'Catbalogan',
    'Cardona',
    'Capihan',
    'Capas',
    'Canumay',
    'Caniogan',
    'Candelaria',
    'Camalig',
    'Calumpit',
    'Caloocan City',
    'Caloocan',
    'Calauan',
    'Calasiao',
    'Calape',
    'Calapan',
    'Calamba',
    'Calabanga',
    'Cainta',
    'Cagayan de Oro',
    'Cadiz',
    'Cabuyao',
    'Cabiao',
    'Cabangahan',
    'Cabanatuan City',
    'Cabadiangan',
    'Cabadbaran',
    'Butuan',
    'Bustos',
    'Burol',
    'Burgos',
    'Bunawan',
    'Bulacan',
    'Bugabus',
    'Budta',
    'Bubog',
    'Bretana',
    'Baybay',
    'Bool',
    'Bombongan',
    'Bolo',
    'Bogo',
    'Bocaue',
    'Boac',
    'Bitin',
    'Bislig',
    'Binuangan',
    'Binmaley',
    'Binangonan',
    'Binan',
    'Binakod',
    'Upper Bicutan',
    'Biasong',
    'Benguet',
    'Bel-Air One',
    'Becuran',
    'Bayugan',
    'Bayombong',
    'Bayanan',
    'Bay',
    'Bautista',
    'Bauang',
    'Bauan',
    'Batasan',
    'Batang First',
    'Batangas',
    'Bataan',
    'Basdio',
    'Baras',
    'Barangka',
    'Bansalan',
    'Bangued',
    'Bangkal',
    'Bangad',
    'Bancal',
    'Banca-Banca',
    'Banaybanay',
    'Banaban',
    'Bambang',
    'Balucuc',
    'Baluarte',
    'Balloc',
    'Baliuag',
    'Balite',
    'Balintawak',
    'Baliang',
    'Baler',
    'Balaybay',
    'Balatong A',
    'Balaquid',
    'Balaoan',
    'Balanoy',
    'Balangobong',
    'Balanga',
    'Balamban',
    'Balagtas',
    'Balagnan',
    'Baculin',
    'Bacalan',
    'Bais City',
    'Bahay Pare',
    'Bagumbong',
    'Bagumbayan',
    'Baguio City',
    'Bagua',
    'Bagong Silang',
    'Bagong Sikat',
    'Bagong Ilog',
    'Bagonbon',
    'Bagbaguin',
    'Bagbag',
    'Bagalnga',
    'Bagacay',
    'Bacoor',
    'Bacolod City',
    'Bacnotan',
    'Baclaran',
    'Bacani',
    'Bacacay',
    'Ayusan Norte',
    'Aya',
    'Awihao',
    'Atop-atop',
    'Atlu-bola',
    'Asuncion',
    'Astorga',
    'Arenas',
    'Arellano',
    'Aranguren',
    'Aplaya',
    'Apar',
    'Apalit',
    'Apacay',
    'Antipolo City',
    'Antipolo',
    'Anos',
    'Anon',
    'Anuling',
    'Anilauan',
    'Anilao',
    'Anibongan',
    'Anibung',
    'Angono',
    'Angeles City',
    'Angatel',
    'Angat',
    'Andres Bonifacio',
    'Andil',
    'Andangin',
    'Anahawan',
    'Amuyong',
    'Amungan',
    'Amti',
    'Ampongol',
    'Ampid Primero',
    'Americahay',
    'Ambulong',
    'Ambonao',
    'Ambalingit',
    'Amas',
    'Alula',
    'Alua',
    'Altavista',
    'Alos',
    'Almacen',
    'Allangigan Primero',
    'Alitaya',
    'Alitao',
    'Alipit',
    'Alipangpang South',
    'Alingaro',
    'Alimango',
    'Alima',
    'Alicia',
    'Alfonso',
    'Alawihaw',
    'Alapang',
    'Alangilan',
    'Alang-Alang',
    'Alaminos',
    'Alagao',
    'Alangan',
    'Alacan',
    'Alabel',
    'Aguso',
    'Agusipan',
    'Agusan Pequeno',
    'Agusan',
    'Agus',
    'Aguioas',
    'Aguinaldo',
    'Actangao',
    'Agsowao',
    'Agoncillo',
    'Agnaya',
    'Aggub',
    'Aggay',
    'Aggasian',
    'Agdeppa',
    'Agay-ayan',
    'Agape',
    'Aganan',
    'Aga',
    'Adya',
    'Aduas Centro',
    'Adlaon',
    'Adlawan',
    'Adela',
    'Acli',
    'Acedillo',
    'Acao',
    'Abuyog',
    'Abut',
    'Abong-Abong',
    'Abulalas',
    'Abucay',
    'Abuanan',
    'Abonagan',
    'Abo',
    'Abiawin',
    'Abian',
    'Abgao',
    'Abella',
    'Abanon',
    'Abangay',
    'Abangan Norte',
    'Abang',
    'Abaga',
    'Acacia',
    'Magallanes',
    'Marikina Heights',
    'Concepcion Dos',
    'Dolores',
    'Katipunan',
    'Alegria',
    'Balas',
    'Nueva',
    'Bagong Pagasa',
    'Kaligayahan',
    'Abuno',
    'Nagkaisang Nayon',
    'Capri',
    'Commonwealth',
    'North Fairview',
    'Bagong Silangan',
    'South Triangle',
    'Apolonio Samson',
    'Bagong Nayon',
    'Horseshoe',
    'Lourdes',
    'Amihan',
    'Loyola Heights',
    'Central',
    'Addition Hills',
    'Pio Del Pilar',
    'Valenzuela',
    'Vergara',
    'Kapitolyo',
    'Moonwalk I',
    'Pembo',
    'Cembo',
    'Santa Lucia',
    'Dos',
    'Altura Bata',
    'Bagong Kalsada',
    'Santiago',
    'Barcolan',
    'Asana',
    'Cabacaraan',
    'San Rafael',
    'Ilagan',
    'Balangkare Norte',
    'Alasas',
    'Adovis',
    'Bulac',
    'Binaritan',
    'Angeles',
    'Abogado',
    'Bagong Buhay',
    'Betes',
    'Baldios',
    'Asinan',
    'Cadaclan',
    'Alas-Asin',
    'Calabasa',
    'Bagong Bayan',
    'As-Is',
    'Fatima',
    'Pangasinan',
    'Anastacia',
    'Poblacion',
    'Cutcut',
    'Antipona',
    'Aguado',
    'Balantacan',
    'Apollo',
    'East Calaguiman',
    'Balante',
    'Ayala',
    'Ascomo',
    'Amamperez',
    'Bagong Pag-asa',
    'Alicaocao',
    'Subic Bay Freeport Zone',
    'Abra',
    'Bacarena',
    'Bagong Pook',
    'Moras De La Paz',
    'Manila Southwoods',
    'Don Bosco Executive Village',
    'Del Pilar Village',
    'Binondo',
    'Holy Spirit',
  ],
  'Hong Kong': [
    'Tsuen Wan',
    'Yuen Long',
    'Yau Ma Tei',
    'Wong Tai Sin',
    'Wong Chuk Hang',
    'Wang Tau Hom',
    'Wanchai',
    'Causeway Bay',
    'Tuen Mun San Hui',
    'Tuen Mun',
    'Tsz Wan Shan',
    'Repulse Bay',
    'Tsing Yi Town',
    'Tsing Lung Tau',
    'Tsimshatsui',
    'Tseung Kwan O',
    'To Kwa Wan',
    'Tiu Keng Leng',
    'Tin Shui Wai',
    'Ting Kau',
    'Tai Wo Hau Estate',
    'Tai Wo',
    'Tai Wai',
    'Tai Tam',
    'Tai O',
    'Tai Lin Pai',
    'Tai Kok Tsui',
    'Tai Hang',
    'So Kwun Wat',
    'Siu Lek Yuen',
    'Sheung Wan',
    'Sheung Shui',
    'Shek Tong Tsui',
    'Shek Lei',
    'Shek Kong',
    'Shek Kip Mei',
    'Shau Kei Wan',
    'Sha Tin Wai',
    'Shatin',
    'Sha Tau Kok',
    'Sham Tseng',
    'Sham Shui Po',
    'San Tin',
    'San Po Kong',
    'Sai Ying Pun',
    'Sai Wan Ho',
    'Sai Wan',
    'Sai Kung',
    'Quarry Bay',
    'Pui O',
    'Po Toi O',
    'Ping Shan',
    'Pak Ngan Heung',
    'Pak Kok San Tsuen',
    'North Point',
    'Ngau Tau Kok',
    'Ngau Chi Wan',
    'Nga Ying Chau',
    'Nam Wai',
    'Mong Kok',
    'Mid Levels',
    'Ma Yau Tong',
    'Ma Wan',
    'Mau Ping',
    'Ma Tin Tsuen',
    'Ma Tau Wai',
    'Ma Tau Kok',
    'Ma On Shan Tsuen',
    'Ma Liu Shui',
    'Lam Tin',
    'Lam Tei',
    'Lai Chi Kok',
    'Kwai Chung',
    'Kowloon',
    'Kennedy Town',
    'Jordan Valley',
    'Hung Hom',
    'Hong Lok Yuen',
    'Ho Man Tin',
    'Aberdeen',
    'Happy Valley',
    'Hang Hau',
    'Fo Tan',
    'Fanling',
    'Discovery Bay',
    'Chuk Yuen',
    'Choi Hung',
    'Cheung Sha Wan',
    'Cheung Chau',
    'Chek Chue',
    'Cha Kwo Ling',
    'Chai Wan',
    'Tai Po',
    'Admiralty',
    'Central',
    'Jordon',
    'Kowloon Bay',
    'Tsimshatsui East',
    'Deep Water Bay',
    'Tung Chung',
  ],
  Brunei: [
    'Tutong',
    'Seria',
    'Brooketon',
    'Kuala Belait',
    'Kampong Sungai Liang Kechil',
    'Kampong Sinaut',
    'Kampong Rimba',
    'Kampong Kuala Tutong',
    'Kampong Jerudong',
    'Kampong Danau',
    'Kampong Birau',
    'Bangar',
    'Bandar Seri Begawan',
  ],
  Macao: ['Taipa', 'Macao', 'Luhuan'],
  Cambodia: [
    'Phnom Penh',
    'Takeo',
    'Svay Rieng',
    'Stung Treng',
    'Sen Moronom',
    'Prey Veng',
    'Pursat',
    'Siem Reab',
    'Setbou',
    'Phluk',
    'Kraveak',
    'Phum Kbal Sa',
    'Kampraeus',
    'Phumi Chey Thum',
    'Bavet',
    'Phumi Banteay Rokar',
    'Poipet',
    'Pailin',
    'Krong Kep',
    'Koh Kong',
    'Kratie',
    'Kampot',
    'Kampong Thom',
    'Sihanoukville',
    'Kampong Chhnang',
    'Kampong Cham',
    'Banlung',
    'Battambang',
    'Anlong Veaeng',
  ],
  'South Korea': [
    'Yuseong',
    'Yeongwol-gun',
    'Yongsan-gu',
    'Yeongju',
    'Yongin-si',
    'Yeonggwang',
    'Yeongdeungpo-gu',
    'Yeongdeungpo-dong',
    'Yeongdong-gun',
    'Yeongdong',
    'Yeongdeok-gun',
    'Yeongdo-gu',
    'Yeongcheon-si',
    'Yeongam-gun',
    'Yeoncheon-gun',
    'Yeoncheon',
    'Yeoju',
    'Yesan-gun',
    'Yesan',
    'Yecheon',
    'Yangyang',
    'Yangsan',
    "Yangp'yong",
    'Yangju',
    'Yanggu',
    'Wonju-si',
    'Wonju',
    'Wanju',
    'Wando-gun',
    'Chilgok',
    'Eunpyeong-gu',
    'Eumseong-gun',
    'Eumseong',
    'Ulsan',
    'Ulju-gun',
    'Uljin County',
    'Ulchin',
    'Uiryeong-gun',
    'Uijeongbu-si',
    'Dongnae',
    'Dongjak-gu',
    'Donghae-si',
    'Dongducheon-si',
    'Dongdaemun-gu',
    'Danyang',
    'Tangjin',
    'Damyang-gun',
    'Damyang',
    'Dalseong-gun',
    'Daejeon',
    'Taegu',
    'Daegu',
    'Daedeok-gu',
    'Taian',
    'Suwongol',
    'Suwon',
    'Suncheon',
    'Sunchang-gun',
    'Suchon',
    'Seoul',
    'Seosan City',
    'Gangdong-gu',
    'Gangbuk-gu',
    'Sokcho',
    'Seo-gu',
    'Seodaemun-gu',
    'Seocho',
    'Siheung-si',
    'Sangju',
    'Sancheong-gun',
    'Samho',
    'Samcheok',
    'Pyeongtaek-si',
    'Pyeongchang',
    'Buyeo-gun',
    'Buyeo',
    'Busan',
    'Bucheon-si',
    'Buan-gun',
    'Puan',
    'Boeun-gun',
    'Boryeong-si',
    'Pohang-si',
    'Pohang',
    'Paju-si',
    'Osan',
    'Asan',
    'Okcheon',
    'Kosong',
    'Nonsan',
    'Nangen',
    'Namhae-gun',
    'Namhae',
    'Naju',
    'Mungyeong',
    'Paju',
    'Muju',
    'Muan-gun',
    'Muan',
    'Mokpo',
    'Miryang',
    'Mapo-gu',
    'Gyeongsan-si',
    'Gyeongju',
    'Gwangsan-gu',
    'Gwangju',
    'Gwanak-gu',
    'Gwacheon',
    'Gurye-gun',
    'Kurye',
    'Guro-gu',
    'Guri-si',
    'Gunwi-gun',
    'Kunwi',
    'Gunsan',
    'Gunpo',
    'Geumsan-gun',
    'Kinzan',
    'Gimje-si',
    'Gumi',
    'Goyang-si',
    'Goseong',
    'Gongju',
    'Gokseong-gun',
    'Kyosai',
    'Koyo',
    'Koesan',
    'Geochang-gun',
    "Koch'ang",
    'Gimpo-si',
    'Gimhae-si',
    'Gimhae',
    'Gimcheon',
    'Gijang',
    'Gapyeong County',
    'Gangseo-gu',
    'Gangneung',
    'Gangnam-gu',
    'Kangjin',
    'Ganghwa-gun',
    'Iksan',
    'Inje',
    'Incheon',
    'Icheon-si',
    'Hwasun-gun',
    'Hwasun',
    'Hwaseong-si',
    'Hwacheon',
    'Hongseong-gun',
    'Hongseong',
    'Hongcheon-gun',
    "Hongch'on",
    'Hoengseong-gun',
    'Hapcheon-gun',
    'Hamyang',
    'Haman',
    'Haeundae',
    'Haesan',
    'Haenam',
    'Jungpyong',
    'Jungnam',
    'Tongyeong-si',
    'Chungju',
    'Jung-gu',
    'Chuncheon',
    'Junam',
    'Cheorwon-gun',
    'Jeonju',
    'Cheongyang',
    'Jung-ang',
    'Jongno-gu',
    'Cheongju-si',
    'Cheongdo-gun',
    "Ch'ongdo",
    'Cheonan',
    'Jinju',
    'Jindo-gun',
    'Jincheon-gun',
    "Chinch'on",
    'Jinan-gun',
    'Jeju City',
    'Jecheon',
    'Changwon',
    'Changsu',
    'Changnyeong',
    'Jangheung',
    'Anyang-si',
    'Anseong',
    'Ansan-si',
    'Yeosu',
    'Gwangyang',
    'Tonghae',
    'Witunsan',
    'Seongnam-si',
    'Hanam',
    'Namyangju',
    'Pocheon-si',
    'Naeso',
    'Saha-gu',
    'Gyeonggi-do',
    'Unjeong',
    'Jayang-dong',
    'Songdong',
    'Songpa-dong',
    'Banpo-dong',
    'Uiwang',
    'Seogwipo',
    'Dajeon',
    'Sacheon',
    'Hwayang-dong',
    'Koyang-dong',
    'Jamsil',
    'Panggyo',
    'Soeul',
    'Yongin',
    'Jungbuk',
    'Dunsandong',
    'Unhaeng-dong',
    'Masan',
    'Andong',
    'Donggu',
    'Seongdong-gu',
    'Gwangjin-gu',
    'Jungnang-gu',
    'Nowon-gu',
    'Seongbuk-gu',
    'Yangcheon-gu',
    'Geumcheon-gu',
    'Seocho-gu',
    'Songpa-gu',
    'Taebaek-si',
    'Hanam-si',
    'Gunpo-si',
    'Gwangmyeong-si',
    'Uiwang-si',
    'Gwacheon-si',
    'Gyeyang-gu',
    'Bupyeong-gu',
    'Namdong-gu',
    'Yeonsu-gu',
    'Taean-gun',
    'Gyeryong-si',
    'Yuseong-gu',
    'Dalseo-gu',
    'Nam-gu',
    'Suseong-gu',
    'Buk-gu',
    'Yeonje-gu',
    'Haeundae-gu',
    'Geumjeong-gu',
    'Suyeong-gu',
    'Gimcheon-si',
    'Sacheon-si',
    'Busanjin-gu',
    'Junggu',
    'Cheoin',
    'Nowon',
    'Geomdan',
    'Ansan',
    'Gangdong',
    'Seongnam',
    'Geoje',
    'Buyeon',
    'Sejong',
    'Bupyeong',
    'Daedeok',
    'Boseong',
    'Jeongeup',
    'Songpa',
    'Ansu',
    'Hwaseong',
    'Gangjin',
    'Pusanjin-gu',
  ],
  Japan: [
    'Shingu',
    'Atsugi',
    'Akashi',
    'Zushi',
    'Zama',
    'Yuzawa',
    'Yuza',
    'Gero',
    'Yukuhashi',
    'Yuki',
    'Yugawara',
    'Yuasa',
    'Yoyogi-sanyacho,Tokyo',
    'Yoshikawa',
    'Yorii',
    'Yonakuni',
    'Yonago Shi',
    'Yonago',
    'Yonabaru',
    'Yokoyama',
    'Yokosuka',
    'Iinancho-yokono',
    'Yokohama',
    'Yokkaichi',
    'Youkaichi',
    'Yawatano',
    'Yawata',
    'Honmachi',
    'Yasugicho',
    'Yashiro',
    'Yashio',
    'Yaotsu',
    'Yao-shi',
    'Yao',
    'Yanai',
    'Yanagibashi',
    'Yanagawa',
    'Yamatotakada-shi',
    'Yamatokoriyama-shi',
    'Yamata',
    'Yamamoto',
    'Yamakita',
    'Yamaguchi',
    'Yamaga',
    'Yakage',
    'Yaizu',
    'Yaita',
    'Yagami',
    'Yabu',
    'Watarai',
    'Washika',
    'Waki',
    'Wakamatsucho',
    'Wajima Shi',
    'Wajima',
    'Wada',
    'Wabuka',
    'Utsunomiya',
    'Uto',
    'Utazu',
    'Usuki',
    'Ushibukamachi',
    'Ureshinomachi-shimojuku',
    'Urayasu-shi',
    'Urayasu',
    'Uozu',
    'Umi',
    'Ujiie',
    'Uji',
    'Uenohara',
    'Ueda',
    'Uebaru',
    'Ube',
    'Tsuzu',
    'Tsuyama',
    'Tsuwano',
    'Tsushima',
    'Tsuru-shi',
    'Tsuruoka',
    'Tsurumi',
    'Tsuruga',
    'Tsukumiura',
    'Tsukuda',
    'Tsukiji Fish Market',
    'Tsukawaki',
    'Tsubata',
    'Tsubame',
    'Tsu',
    'Toyota',
    'Toyoshina',
    'Toyooka-shi',
    'Toyooka',
    'Toyonomachi-toyono',
    'Toyonaka',
    'Toyokawa',
    'Toyohashi',
    'Toyoake-shi',
    'Toyama Shi',
    'Toyama',
    'Tottori-shi',
    'Totsuka cho',
    'Tosu',
    'Tosakicho',
    'Toranomon',
    'Tonosho',
    'Tondabayashi Shi',
    'Tondabayashi',
    'Tomioka',
    'Kawaguchi',
    'Tomigusuku',
    'Tokyo',
    'Tokushima',
    'Tsuchiyamacho-tokuhara',
    'Tokorozawa',
    'Tokoname',
    'Toki',
    'Tokamachi',
    'Tohaku-gun',
    'Togura',
    'Tochigi',
    'Toba',
    'Konancho-terasho',
    'Teradomari-koshimizu',
    'Tenri-shi',
    'Tenri',
    'Tawaramoto',
    'Tauracho',
    'Tatsuno',
    'Tatsunocho-tominaga',
    'Tateyama',
    'Tateda',
    'Tatebayashi',
    'Tarumizu',
    'Tarui',
    'Tara',
    'Nishi-Tokyo-shi',
    'Tanashicho',
    'Tanabe',
    'Tamano',
    'Tamana',
    'Tamamura',
    'Taketoyo',
    'Takeocho-takeo',
    'Hitotsubashi',
    'Takehara',
    'Takayama',
    'Takatsuki Shi',
    'Takatsuki',
    'Takashima',
    'Takasaki Shi',
    'Takasaki',
    'Takarazuka',
    'Takaoka-gun',
    'Takaoka',
    'Takanawa',
    'Takamatsu',
    'Takaishi',
    'Takahashi',
    'Takahama',
    'BungoTakada',
    'Taga',
    'Tajimi-shi',
    'Tajimi',
    'Tangocho-taiza',
    'Taiji',
    'Tahara',
    'Tagawa',
    'Tagata',
    'Tadotsu',
    'Tadaoka-higashi',
    'Tadami',
    'Tachiarai',
    'Suzuka',
    'Suzaki',
    'Suzaka',
    'Suwa',
    'Susaki',
    'Suntō',
    'Sumoto',
    'Sumida',
    'Sukumo',
    'Suita',
    'Suibara',
    'Sugito',
    'Furukawacho-sugisaki',
    'Suginami-ku',
    'Soka',
    'Soja',
    'Shobara',
    'Shizuoka',
    'Shitara',
    'Kamiagatacho-shishimi',
    'Shishihama',
    'Shirokanedai',
    'Shirokane',
    'Shiraoka',
    'Shirahama',
    'Shiozawa',
    'Shiojiri',
    'Shinshiro-shi',
    'Shinshiro',
    'Shinkawa',
    'Shinjuku',
    'Shinshuku',
    'Shinjo',
    'Shinagawa',
    'Shimoyoshida',
    'Shimotsuma-shi',
    'Shimotono',
    'Shimoseki',
    'Shimosato',
    'Shimonoseki',
    'Shimonita',
    'Shimomeguro',
    'Shimomaruko',
    'Shimokodanaka',
    'Shimoda',
    'Minato',
    'Shimizumachi',
    'Seisui',
    'Shimada',
    'Shimata',
    'Shimabara',
    'Shima',
    'Shiki',
    'Shido',
    'Shichika',
    'Shibuya',
    'Shibushi',
    'Shibukawa',
    'Shibaura',
    'Konan',
    'Shibata',
    'Shinbashi',
    'Kaigan',
    'Hamamatsucho',
    'Shibadaimon',
    'Shiba',
    'Settsu',
    'Seto',
    'Setagaya-ku',
    'Seta',
    'Sendai',
    'Sekimachi',
    'Satte',
    'Sato',
    'Sashima-gun',
    'Sasebo',
    'Sasayama',
    'Sasaguri',
    'Sarugakucho',
    'Sano',
    'Sanjo',
    'Sandacho',
    'Samata',
    'Sakurakabu',
    'Sakurai',
    'Sakuragicho',
    'Saku',
    'Sakata',
    'Sakaki',
    'Sakaiminato',
    'Sakaidecho',
    'Sakai-nakajima',
    'Sakai',
    'Sakado',
    'Saiki',
    'Yoshinocho-saijo',
    'Saga',
    'Sabae',
    'Ryotsu-minato',
    'Ryoke',
    'Rokujizo',
    'Ozato',
    'Oyama',
    'Owase',
    'Owariasahi-shi',
    'Otsuki',
    'Ōtsu',
    'Otemae',
    'Otemachi',
    'Ota-shi',
    'Otani',
    'Ōta-ku',
    'Otake',
    'Ota',
    'Oshima-gun',
    'Osawa',
    'Osaka',
    'Onomichi',
    'Ono',
    'Ono-hara',
    'Onna',
    'Onga-gun',
    'Omuta',
    'Omura',
    'Omorinaka',
    'Ome',
    'Omachi',
    'Okuchi-shinohara',
    'Okegawa',
    'Okazaki-shi',
    'Okazaki',
    'Okaya Shi',
    'Okayama',
    'Okaya',
    'Yamashirocho-okawamochi',
    'Okawa',
    'Ojiya',
    'Ōita City',
    'Ōita',
    'Oi-Suzugamoricho',
    'Oiso',
    'Higashioi',
    'Ogunimachi',
    'Nishiogu',
    'Ogose',
    'Ogikubo',
    'Ogawa',
    'Ogano',
    'Ogaki',
    'Odawara',
    'Oda Shi',
    'Oda-sakae',
    'Odacho-oda',
    'Ochiai',
    'Ochi',
    'Obuse',
    'Obu',
    'Obita',
    'Obama-machi',
    'Obama',
    'Nyuzen',
    'Numazu',
    'Numata',
    'Nukata-gun',
    'Nose',
    'Nonoichi',
    'Nogata',
    'Noda',
    'Nobeoka',
    'Nishiwaki',
    'Nishitsuruma',
    'Nishisonogi-gun',
    'Nishio',
    'Nishinoomote',
    'Nishinomiya',
    'Nishinippori',
    'Nishihama',
    'Nishihara',
    'Nishi-agenosho',
    'Nirasaki-shi',
    'Nirasaki',
    'Ninomiya',
    'Nikkō',
    'Niitsu',
    'Niimi',
    'Niihama',
    'Niigata',
    'Nihonmatsu',
    'Nichinan',
    'Neyagawa',
    'Naze',
    'Narutocho-mitsuishi',
    'Nara',
    'Nanao',
    'Namerikawa',
    'Nakijin',
    'Nakatsugawa',
    'Nakatsu',
    'Nakanoshima',
    'Nakanokami',
    'Nakanojomachi',
    'Nakano',
    'Nakamura',
    'Matsue-naka',
    'Nakama',
    'Naka-kochi',
    'Nakadachi',
    'Naha',
    'Nagoya',
    'Nago',
    'Nagaya',
    'Nagatsutacho',
    'Nagatacho',
    'Nagasu',
    'Nagasaki',
    'Nagareyama',
    'Nagaoka Shi',
    'Nagaoka',
    'Naganohara',
    'Nagano',
    'Shigarakicho-nagano',
    'Nagakute-shi',
    'Nagai',
    'Nagahama-shi',
    'Nagahama',
    'Nabegayato',
    'Nabari-shi',
    'Nabari',
    'Myozai Gun',
    'Musashino-shi',
    'Musashino',
    'Musashimurayama-shi',
    'Muroto-misakicho',
    'Murakami',
    'Munakata-shi',
    'Muko',
    'Motoyama',
    'Morohongo',
    'Moriyama',
    'Moriguchi',
    'Mizunami',
    'Mizukami',
    'Kamitakaracho-miza',
    'Miyoshi',
    'Miyazu',
    'Miyazaki',
    'Iitakacho-miyamae',
    'Miyakonojo',
    'Miyakojima',
    'Miyako',
    'Yonashiro-miyagi',
    'Miyada',
    'Mitsune',
    'Mitsuke',
    'Mitake',
    'Mita',
    'Mishuku',
    'Mishimacho',
    'Mishima',
    'Misato Shi',
    'Minoo Shi',
    'Minoh',
    'Mino',
    'Minamiura',
    'Tsunashimanishi',
    'Minamishinagawa',
    'Minamisenju',
    'Minamisaku-gun',
    'Minamiaoyama',
    'Minamata',
    'Minabe',
    'Miki',
    'Mihara',
    'Miemachi',
    'Mie-gun',
    'Nagiso',
    'Midaka',
    'Mibu',
    'Meguro-ku',
    'Matsuzaki',
    'Matsuyama',
    'Matsusaka',
    'Matsumoto Shi',
    'Matsumoto',
    'Matsue',
    'Matsudo Shi',
    'Matsudo',
    'Matsuda-soryo',
    'Matsubara',
    'Ashiomachi-mato',
    'Masuda',
    'Marugame',
    'Marifumachi',
    'Makurazaki',
    'Makinohara',
    'Maizuru',
    'Maibara',
    'Maebashi',
    'Maebaru-chuo',
    'Machida-shi',
    'Machida',
    'Kyoto',
    'Kuwana',
    'Kushimoto',
    'Kusatsu-shi',
    'Kusatsu',
    'Kurume',
    'Kurihama',
    'Kure',
    'Kurayoshi',
    'Kurashiki Shi',
    'Kurashiki',
    'Kuraji',
    'Kumamoto',
    'Kumagaya',
    'Kukichuo',
    'Kugayama',
    'Kudoyama',
    'Kudamatsu',
    'Kozu',
    'Koto',
    'Kotohira',
    'Koshigoe',
    'Koshigaya',
    'Koseda',
    'Ko-saitsuno',
    'Konosu',
    'Komoro',
    'Komono',
    'Komatsushimacho',
    'Komatsu',
    'Komaki',
    'Komagome',
    'Komae-shi',
    'Kokuryocho',
    'Kokubunji',
    'Kokubu-matsuki',
    'Kojimamachi',
    'Koizumicho',
    'Kohagura',
    'Koga',
    'Kofu',
    'Kodamacho-kodamaminami',
    'Kodaira',
    'Kochi',
    'Kobe Shi',
    'Kobe',
    'Kobayashi',
    'Ko',
    'Kizugawa-shi',
    'Kiyosu',
    'Kitsuki',
    'Kitasuna',
    'Kitakyushu',
    'Kita-ku',
    'Kitakatsushika-gun',
    'Kitakata',
    'Kitakase',
    'Kitagata',
    'Kishiwada Shi',
    'Kishiwada',
    'Kisarazu',
    'Kiryu',
    'Kinshi',
    'Kin',
    'Kikuchi',
    'Kida',
    'Keta',
    'Kazo',
    'Iinancho-kayumi',
    'Kawatana',
    'Kawasaki',
    'Kawasakicho',
    'Kawanishi',
    'Kawagoe',
    'Kawachi-nagano Shi',
    'Katsuyama',
    'Katsushika',
    'Kasuya-gun',
    'Kasumi',
    'Kasukabe',
    'Kasugai',
    'Kasuga',
    'Kashoji',
    'Kashiwazaki',
    'Kashiwara-shi',
    'Kashiwa',
    'Kashima',
    'Kashihara-shi',
    'Kashihara',
    'Kasaoka',
    'Kasamatsucho',
    'Kasai',
    'Karuizawa',
    'Kariya-shi',
    'Kariya',
    'Kariwa',
    'Karita',
    'Karatsu',
    'Kanzaki',
    'Kanzakimachi-kanzaki',
    'Kanuma',
    'Kanoya',
    "Kan'onjicho",
    'Kanie',
    'Kanazawa',
    'Kanazawacho',
    'Kanamachi',
    'Kanagawa',
    'Mitsu-kanagawa',
    'Kamo-mura',
    'Kamo-gun',
    'Kamo',
    'Kamitsuki',
    'Tatsuda',
    'Kamitakada',
    'Kaminokawa',
    'Kamimeguro',
    'Kamiina-gun',
    'Kamiichi',
    'Kameyama',
    'Kametsu',
    'Kameoka',
    'Kameido',
    'Kamakura',
    'Kako',
    'Kakogawa Shi',
    'Kakogawa',
    'Kakegawa',
    'Kakamigahara-shi',
    'Kakamigahara',
    'Kashima-shi',
    'Kainan',
    'Kagoshima',
    'Kadoma',
    'Kadena',
    'Katsushima',
    'Jōyō',
    'Izumo',
    'Izumisano',
    'Izumiotsu',
    'Izumi',
    'Iwayadomari',
    'Iwatsuki',
    'Iwata',
    'Iwama',
    'Iwakura',
    'Iwakuni',
    'Iwade',
    'Itsukaichi',
    'Itoman',
    'Itoigawa',
    'Itō',
    'Itano-gun',
    'Ikedacho-itano',
    'Itami',
    'Itabashi-ku',
    'Isogo',
    'Ishii',
    'Ishigaki',
    'Ishibashi',
    'Isesaki Shi',
    'Isesaki',
    'Isehara',
    'Ise',
    'Isahaya',
    'Iruma-gun',
    'Inuyama-shi',
    'Inuyama',
    'Ino',
    'Inazawa',
    'Ina-shi',
    'Inagi-shi',
    'Ina',
    'Imazu',
    'Imawatari',
    'Imaricho-ko',
    'Ikuta',
    'Ikoma',
    'Ikeda',
    'Ikedacho',
    'Iizuka',
    'Iiyama',
    'Iida',
    'Iha',
    'Iga',
    'Ichinomiya',
    'Ichikawa',
    'Ichiba',
    'Ichibacho-kagami',
    'Ibusuki',
    'Ibo-gun',
    'Ibaraki',
    'Ibara',
    'Hiyajo',
    'Honjo',
    'Hongo',
    'Hondomachi-hondo',
    'Honmura',
    'Hofu',
    'Hiyoshi',
    'Hiwacho-hiwa',
    'Hitoyoshi',
    'Hita',
    'Hiroshima',
    'Hiratsuka',
    'Hirakata',
    'Hirado',
    'Hioki',
    'Hino',
    'Himi Shi',
    'Himeji Shi',
    'Himeji',
    'Hikone',
    'Hiji',
    'Higashishinagawa',
    'Osaki',
    'Higashiosaka',
    'Okubo',
    'Higashinippori',
    'Higashimatsuyama Shi',
    'Higashikurume',
    'Higashikamata',
    'Hida',
    'Hekinan',
    'Heda',
    'Hayashino',
    'Hayashima',
    'Hayashi',
    'Hayama',
    'Hayakawa',
    'Hatsukaichi-shi',
    'Hatsukaichi',
    'Hatogaya-honcho',
    'Hashimoto',
    'Hashima-shi',
    'Hasama',
    'Harigaya',
    'Hara',
    'Haniu',
    'Hanyu',
    'Hanno',
    'Handa',
    'Hanahata',
    'Hamochi-hongo',
    'Hamamatsu',
    'Hamada',
    'Hakui',
    'Hakozaki',
    'Hakonegasaki',
    'Hakone',
    'Haguro',
    'Hagi',
    'Katsuren-haebaru',
    'Hadano',
    'Hachiōji',
    'Omihachiman',
    'Gyoda',
    'Sumiyocho-gusuku',
    'Gotsucho',
    'Gotenba Shi',
    'Gotenba',
    'Gosen',
    'Gose',
    'Gojo',
    'Godo',
    'Gobo',
    'Ginza',
    'Ginoza',
    'Ginowan',
    'Gifu City',
    'Gamagori',
    'Futtsu',
    'Futatsugi',
    'Zamami',
    'Funabashi-shi',
    'Funabashi',
    'Fukuyama',
    'Fukushima',
    'Fukuroi',
    'Fukura',
    'Fukuoka',
    'Fukui',
    'Fukui-shi',
    'Fukuecho',
    'Fukuchiyama',
    'Shirakawa',
    'Fuji-yoshida Shi',
    'Fujisawa',
    'Fujioka',
    'Fujinomiya',
    'Fujiidera-shi',
    'Fujii',
    'Fujieda',
    'Fuji',
    'Fuchucho',
    'Enzacho',
    'Ena-shi',
    'Egota',
    'Edogawa',
    'Echigawa',
    "Den'enchofu",
    'Daitocho',
    'Chofu-miyazakicho',
    'Chofu',
    'Chizu',
    'Chiyoda-ku',
    'Chiyagawa',
    'Chiryu',
    'Chino',
    'Chikushino-shi',
    'Chikugo Shi',
    'Chigasaki',
    'Chichibu',
    'Chatan',
    'Chabana',
    'Buzen-shi',
    'Beppu',
    'Befucho-minatomachi',
    'Ichibancho',
    'Azusawa',
    'Ayabe',
    'Awara',
    'Atami',
    'Asuka',
    'Ashiya',
    'Ashikaga',
    'Ashigarashimo-gun',
    'Ashigarakami-gun',
    'Asama',
    'Arida-gun',
    'Arakawa',
    'Haginaka',
    'Annaka',
    'Anjo',
    'Anan',
    'Anamizu',
    'Anabuki',
    'Amagasaki Shi',
    'Amagasaki',
    'Akune',
    'Akizuki',
    'Aki-gun',
    'Aki',
    'Akasakacho',
    'Akasaka',
    'Akaren',
    'Akadani',
    'Ajiro',
    'Aizu-wakamatsu Shi',
    'Aioi',
    'Aichi-gun',
    'Ageo',
    'Adachi',
    'Ie',
    'Okinawa',
    'Kushima',
    'Kogushigo',
    'Hikari',
    'Uwajima',
    'Seiro',
    'Nagato',
    'Hasuda',
    'Sayama',
    'Fussa',
    'Fukayanaka',
    'Gora',
    'Asaka',
    'Wako',
    'Shimotoda',
    'Kimitsu',
    'Miura',
    'Wakayama',
    'Iyo',
    'Yawatahama-shi',
    'Yuge-habu',
    'Saijo',
    'Ozu',
    'Ouchi',
    'Masaki',
    'Kawanoecho',
    'Kami-tado',
    'Imabari-shi',
    'Mishohirajo',
    'Habu',
    'Okushiri',
    'Fukaura',
    'Oirase',
    'Yotsukaido',
    'Yoshioka',
    'Yonezawa',
    'Yokote',
    'Yatabe',
    'Yanagawamachi-saiwaicho',
    'Yamagata',
    'Yamada',
    'Yachimata',
    'Yachi',
    'Yabuki',
    'Watari',
    'Wakuya',
    'Ushiku',
    'Tsukuba',
    'Tsukinoki',
    'Tsuchizakiminato-higashi',
    'Tsuchiara',
    'Toride-shi',
    'Toride',
    'Tono-shi',
    'Tono',
    'Tomiya',
    'Togane',
    'Tendo',
    'Tateishi',
    'Takizawa-shi',
    'Takahata',
    'Takahagi',
    'Takagi',
    'Tagajo-shi',
    'Daitocho-surisawa',
    'Sukagawa',
    'Sodegaura-shi',
    'Shisui',
    'Toyosatocho-shirotori',
    'Shiroishi',
    'Shiroi',
    'Shiogama',
    'Shinmachi',
    'Shikama',
    'Fujisawa-semine',
    'Sakura',
    'Sakashiuchi',
    'Sagae',
    'Ryugasaki-shi',
    'Ryugasaki',
    'Rokugo',
    'Rifu',
    'Otawara',
    'Otaki',
    'Tsukidate-ota',
    'Onagawa Cho',
    'Oishida',
    'Oharahama',
    'Ofunato',
    'Obanazawa',
    'Oarai',
    'Oami',
    'Nihommatsu',
    'Natori-shi',
    'Narita',
    'Narashino-shi',
    'Nanyo Shi',
    'Nakayama',
    'Nakajima',
    'Naka-gun',
    'Naka',
    'Murata',
    'Motomiya',
    'Motegi',
    'Moriya',
    'Morioka',
    'Mooka',
    'Mobara',
    'Mizusawa',
    'Miyauchi',
    'Mito',
    'Miharu',
    'Matsushima',
    'Mashiko',
    'Marumori',
    'Kuroiso',
    'Kuriyazawa',
    'Kozaki',
    'Koriyama Shi',
    'Koriyama',
    'Koma',
    'Kitakami',
    'Kitaibaraki',
    'Kinzan',
    'Kesennuma',
    'Kawaguchi-shinmachi',
    'Kamogawa',
    'Katsuura',
    'Katori-gun',
    'Katori-shi',
    'Kasama',
    'Kaneyama',
    'Kanegasaki',
    'Hanaizumicho-kazawa',
    'Kaminoyama',
    'Kamiasamigawa',
    'Kamaishi',
    'Kamagaya Shi',
    'Kakuda',
    'Kakioka',
    'Iwanuma-shi',
    'Iwanuma',
    'Iwakiri',
    'Iwaki',
    'Iwaizumi',
    'Itako',
    'Ishitsuka',
    'Ishioka',
    'Ishinomaki',
    'Inzai-shi',
    'Inawashiro',
    'Ichinoseki',
    'Ichihara Shi',
    'Ichihara',
    'Hokota',
    'Hizume',
    'Hitachi-shi',
    'Hitachi',
    'Hiraisomachi',
    'Higashine',
    'Higashikoya',
    'Higashi',
    'Hanamaki',
    'Goi',
    'Funaoka-chuo',
    'Funaishikawa',
    'Funagata',
    'Fukushima Shi',
    'Fujita',
    'Ena',
    'Date',
    'Daigo',
    'Choshi',
    'Chonan',
    'Chiba-shi',
    'Chiba',
    'Asakawa',
    'Asahi',
    'Arai',
    'Anesaki',
    'Ami',
    'Akita Shi',
    'Akita',
    'Akaishi',
    'Abiko',
    'Motoyoshicho-takaoka',
    'Ohira',
    'Motomachi',
    'Yudo',
    'Yubari',
    'Yamakoshi',
    'Wassamu',
    'Wakkanai',
    'Uryu',
    'Honcho',
    'Toyako-onsemmachi',
    'Hobetesu-tomiuchi',
    'Tomakomai',
    'Tofutsu',
    'Tobetsu',
    'Teshio',
    'Teshikaga',
    'Mutsu',
    'Takko',
    'Takikawa',
    'Takanosu',
    'Tatsuushi',
    'Sunagawa',
    'Shotoshibetsu',
    'Shoro',
    'Shizunai-furukawacho',
    'Shiraoi',
    'Shintoku',
    "Shin'ei",
    'Shimukappu',
    'Shimotoyoi',
    'Shimosetsuri',
    'Shimokawa',
    'Shimo-kanayama',
    'Shimo-furano',
    'Shikabe',
    'Shibinai',
    'Shibetsu',
    'Shibecha',
    "Onbetsucho-shakubetsugen'ya",
    'Sapporo',
    'Hobetsu-sakae',
    'Sai',
    'Rusutsu',
    'Rushin',
    'Rumoi',
    'Piuka',
    'Owani',
    'Otofuke',
    'Otaru',
    'Ōmu',
    'Onbetsu-motomachi',
    'Okidate',
    'Ogifushicho',
    'Ogawara',
    'Odate',
    'Ōdate',
    'Obirachō',
    'Obihiro',
    'Numanohata-chuo',
    'Noshiro',
    'Noboribetsu',
    'Nobeji',
    'Nishishunbetsu',
    'Niinai',
    'Nemuro',
    'Nayoro',
    'Namioka',
    'Nakashibetsu',
    'Nakaotofuke',
    'Nakaashoro',
    'Naie',
    'Naganuma',
    'Muroran',
    'Mukawa',
    'Mombetsu',
    'Monbetsu',
    'Monbetsu-honcho',
    'Misawa',
    'Yoichi',
    'Kutchan',
    'Kushiro Shi',
    'Kushiro',
    'Kuroishi',
    'Kuriyama',
    'Kumbetsu',
    'Kuji-shi',
    'Kuji',
    'Kosaka',
    'Hiranai',
    'Kiyokawa',
    'Kitami',
    'Mitsuishi-kerimai',
    'Kembuchi',
    'Taira',
    'Kamioboro',
    'Kamikita-gun',
    'Utanobori-ketobetsu',
    'Kawakami',
    'Kamikawa',
    'Kamibisei',
    'Kado',
    'Iwanai',
    'Iwamizawa',
    'Iwachishi',
    'Itayanagi',
    'Ishikari',
    'Ishigami',
    'Ichinohe',
    'Horokanai',
    'Kitahiroshima',
    'Hirosaki',
    'Higashioribe',
    'Hidaka Shinkokyoku',
    'Hattaushi',
    'Hanawa',
    "Hama'atsuma",
    'Hakodate',
    'Hachinohe Shi',
    'Hachinohe',
    'Furano',
    'Fukagawa',
    'Fujisaki',
    'Engaru',
    'Ebetsu-shi',
    'Ebetsu',
    'Chuo',
    'Chitose',
    'Chanai-motomachi',
    "Biruwa-gen'ya",
    'Bibai',
    'Betsukai',
    'Atsunai',
    'Ashibetsu',
    'Asahikawa',
    'Aomori Shi',
    'Aomori',
    'Akkeshi',
    'Akaigawa',
    'Akabira',
    'Ajigasawa',
    'Abashiri',
    'Goshogawara',
    'Kumago',
    'Heiwajima',
    'Tokai',
    'Masago',
    'Mihama',
    'Sodegaura',
    'Inage',
    'Takahamacho',
    'Narawa',
    'Maihama',
    'Chuoko',
    'Sakaecho',
    'Kihoku',
    'Kamiukena',
    'Nanto-shi',
    'Owariasahi',
    'Hitachi-Naka',
    'Chikusei',
    'Kasumigaura',
    'Tsuchiura',
    'Tsukubamirai',
    'Omitama',
    'Hitachiomiya',
    'Kamisu',
    'Namegata',
    'Sakuragawa',
    'Bando',
    'Inashiki',
    'Shintomi',
    'Higashi-Hiroshima',
    'Azumino',
    'Chikuma',
    'Murayama',
    'Minamiashigara',
    'Ritto',
    'Koka',
    'Katsuragi',
    'Kashiba',
    'Ouda-yamaguchi',
    'Nanto',
    'Oyabe',
    'Imizu',
    'Kurobe-shi',
    'Ayase',
    'Ebina',
    'Tama',
    'Musashimurayama',
    'Akikawa',
    'Akishima',
    'Hamura',
    'Higashiyamato',
    'Misato, Saitama',
    'Niiza',
    'Tonami',
    'Kahoku',
    'Kaga',
    'Nomimachi',
    'Matsuura',
    'Saikaicho-kobago',
    'Onojo',
    'Aizu-Wakamatsu',
    'Urasoe',
    'Uruma',
    'Joso',
    'Midori',
    'Kumejima',
    'Fujimino',
    'Fujimi',
    'Hidaka',
    'Higashi-Matsuyama',
    'Iruma',
    'Kitamoto',
    'Tsurugashima',
    'Sosa',
    'Sanmu',
    'Tamura',
    'Kinokawa',
    'Kumano',
    'Inabe',
    'Nasushiobara',
    'Shimotsuke',
    'Nasukarasuyama',
    'Tomi',
    'Komagane',
    'Inzai',
    'Isumi',
    'Yachiyo',
    'Tomisato',
    'Oshu',
    'Hachimantai',
    'Ninohe',
    'Higashimatsushima',
    'Kurihara',
    'Izunokuni',
    'Izu',
    'Kikugawa',
    'Kosai',
    'Susono',
    'Omaezaki',
    'Nikaho',
    'Daisen',
    'Senboku',
    'Oga',
    'Yurihonjo',
    'Katagami',
    'Kita-Akita',
    'Tsugaru',
    'Hirakawa',
    'Towada',
    'Kitanagoya',
    'Nisshin',
    'Toyoake',
    'Chita',
    'Aisai',
    'Hashima',
    'Kaizu',
    'Kani',
    'Minokamo',
    'Motosu',
    'Gujo',
    'Nakagusuku',
    'Joetsu',
    'Minamiuonuma',
    'Myoko',
    'Sado',
    'Tainai',
    'Uonuma',
    'Fujiidera',
    'Habikino',
    'Hannan',
    'Sennan',
    'Shijonawate',
    'Hokuto',
    'Kabukicho',
    'Saitama',
    'Niseko Town',
    'Higashimurayama',
    'Kunisaki-shi',
    'Fujikawaguchiko',
    'Futami',
    'Yawatahama',
    'Kirishima',
    'kumamoto',
    'Rishiri Town',
    'Iwakura-shi',
    'Kanzaki-gun',
    'Sanda Shi',
    'Tanba-shi',
    'Asago-shi',
    'Shiso-shi',
    'Kirishima Shi',
    'Ayauta-gun',
    'Izumisano Shi',
    'Unnan Shi',
    'Shunan Shi',
    'Asakuchi Shi',
    'Shimanto-shi',
    'Shikoku-chuo Shi',
    'Fukutsu Shi',
    'Kinokawa Shi',
    'Kyotango-shi',
    'Miyazaki Gun',
    'Minami Alps-shi',
    'Inabe-gun',
    'Unzen-shi',
    'Dazaifu',
    'Amakusa',
    'Abashiri District',
    'Sorachi-gun',
    'Daisen-shi',
    'Yurihonjo-shi',
    'Aizu-misato Machi',
    'Hitachiomiya-shi',
    'Soka Shi',
    'Oamishirasato-shi',
    'Hakusan Shi',
    'Aikō-gun',
    'Minamiuonuma Shi',
    'Bunkyo-ku',
    'Taito',
    'Toshima',
    'Ikebukuro',
    'Roppongi',
    'kooriyama',
    'Kamigyo-ku',
    'Sue',
    'Isozaki',
    'Buzen',
    'Asane',
    'Kakegawa Shi',
    'Takasu',
    'Midorigaoka',
    'Komatsugaoka',
    'Minamiruike',
    'Kyuden',
    'Wakamiya-kayaba',
    'Shimoshinjo-iwaki',
    'Nishitoyoda',
    'Bunkyocho',
    'Koguriyama',
    'Shigemorishincho',
    'Nishishigemori',
    'Toyotomicho',
    'Sayo',
    'Ishie',
    'Iwakicho',
    'Inakadate',
    'Koyanagi',
    'Utozaka',
    'Minakami',
    'Nakaibayashi',
    'Mihashi',
    'Hachioji',
    'Omagi',
    'Kitamachi',
    'Tsuji',
    'Nakahara',
    'Heiwadai',
    'Higashioizumi',
    'Shimura',
    'Maenocho',
    'Komone',
    'Kamiitabashi',
    'Narimasu',
    'Tokumaru',
    'Takashimadaira',
    'Hasune',
    'Nakajujo',
    'Senkawa',
    'Koishikawa',
    'Yotsuya',
    'Nakaochiai',
    'Hyakunincho',
    'Kitashinjuku',
    'Nishishinjuku',
    'Saginomiya',
    'Shirasagi',
    'Numabukuro',
    'Sasazuka',
    'Kamiigusa',
    'Amanuma',
    'Koenjiminami',
    'Shimotakaido',
    'Sengawacho',
    'Todoroki',
    'Wakabayashi',
    'Koyamadai',
    'Kitazawa',
    'Nakameguro',
    'Nishirokugo',
    'Yaguchi',
    'Ikegami',
    'Omorinishi',
    'Sanno',
    'Noborito',
    'Teraodai',
    'Hirao',
    'Kamiasao',
    'Utsukushigaoka',
    'Aobadai',
    'Misasa',
    'Katashina',
    'Hakuba',
    'Kamisato',
    'Hondocho',
    'Kita',
    'Takadanobaba',
    'Yamatomachi',
    'Oishi',
    'Nobi',
    'Iwato',
    'Koyabe',
    'Shiomidai',
    'Iriyamazucho',
    'Miharucho',
    'Hinodecho',
    'Otsucho',
    'Uwamachi',
    'Shioiricho',
    'Horiuchi',
    'Tebiro',
    'Tsunishi',
    'Yufu-shi',
    'Takashima-shi',
    'Izumizaki',
    'Matsukawamachi-asakawa',
    'Itamuro',
    'Itoshima-shi',
    'Kai-shi',
    'Kitasoma-gun',
    'Chiyoda',
    'Takuseicho',
    'Kowa',
    'Hirotomi',
    'Nina',
    'Kobira',
    'Soshubetsu',
    'Shiga',
    'Hyogo',
    'Kano',
    'Shiono',
    'Kusagoe',
    'Kuryugawa',
    'Ketto',
    'Okayamachi',
    'Morishita',
    'Tanakajimamachi',
    'Nagatoro',
    'Tsukinowa',
    'Sakitama',
    'Tarumicho',
    'Furuecho',
    'Yamabe',
    'Awaji',
    'Makamicho',
    'Tsukahara',
    'Fukumanjicho',
    'Ao',
    'Tadain',
    'Kitano',
    'Kizu',
    'Fushimidai',
    'Nishimaiko',
    'Koryocho',
    'Yokawacho-toyooka',
    'Nagose',
    'Yama',
    'Ama-Shi',
    'Kawanishicho',
    'Asago',
    'Ojicho',
    'Kanmaki',
    'Katsuragidai',
    'Jinnan',
    'Awa',
    'Higashiikoma',
    'Komyocho',
    'Tezukayama',
    'Nakayamacho',
    'Mozumecho',
    'Hironocho',
    'Kohata',
    'Minami',
    'Higashitera',
    'Takao',
    'Ureshino-kamiogawacho',
    'Matsugaoka',
    'Kusanomachi',
    'Shimoawazumachi',
    'Motobu',
    'Ozasa',
    'Taku',
    'Minobaru',
    'Kuramotogo',
    'Yasuda',
    'Oguchi-sato',
    'Nishinagaeura',
    'Suzaku',
    'Saifu',
    'Kamiusui',
    'Minamichita',
    'Yoshiki',
    'Togiri',
    'Shiraiwamachi',
    'Kibunemachi',
    'Amidaijicho',
    'Shimotomino',
    'Kanada',
    'Inokuramachi',
    'Takatsuo',
    'Aratsu',
    'Kobai',
    'Nakagawa',
    'Saigawa-osaka',
    'Obuchi',
    'Takizawa',
    'Shimizucho',
    'Yoshidajima',
    'Iidaoka',
    'Kayama',
    'Soga-yatsu',
    'Nishioi',
    'Tokawa',
    'Futatsuya',
    'Imaizumidai',
    'Oshima',
    'Eda',
    'Shinomiya',
    'Akashicho',
    'Okada',
    'Imazato',
    'Kamigo',
    'Tatekawa',
    'Fukamihigashi',
    'Kamiwada',
    'Iriya',
    'Toda',
    'Shimoseya',
    'Kameino',
    'Shonandai',
    'Kawana',
    'Kataseyama',
    'Fujiyoshida',
    'Yoshidacho',
    'Miyamaecho',
    'Surugadai',
    'Nishi',
    'Kamitogari',
    'Kawashimata',
    'Yomitan',
    'Seiyo-shi',
    'Kokandori',
    'Sakuramoto',
    'Showa',
    'Sakaimachi',
    'Kaizuka',
    'Tsukunocho',
    'Higashiterao',
    'Mamedocho',
    'Osonedai',
    'Minamikase',
    'Yako',
    'Nakamaruko',
    'Unane',
    'Kamisakunobe',
    'Shinsaku',
    'Shibokuchi',
    'Nogawa',
    'Nagao',
    'Higashimita',
    'Minamiikuta',
    'Kanahodo',
    'Hakusan',
    'Matsukazedai',
    'Tanacho',
    'Shiratoridai',
    'Saginuma',
    'Higashiarima',
    'Maginu',
    'Miyamaedaira',
    'Shirahatadai',
    'Edaminami',
    'Niiharucho',
    'Nishikojiya',
    'Minamikamata',
    'Minamirokugo',
    "Den'enchofu-minami",
    'Kugahara',
    'Kamiikedai',
    'Higashimagome',
    'Omorikita',
    'Hatanodai',
    'Togoshi',
    'Kitashinagawa',
    'Azabu-juban',
    'Motoazabu',
    'Nishiazabu',
    'Azabudai',
    'Higashiazabu',
    'Shibakoen',
    'Minamimotomachi',
    'Daikyocho',
    'Nishiwaseda',
    'Tomihisacho',
    'Yochomachi',
    'Yaraicho',
    'Yamabukicho',
    'Higashigokencho',
    'Agebacho',
    'Kagurazaka',
    'Aizumicho',
    'Waseda-tsurumakicho',
    'Nishiochiai',
    'Nishiikebukuro',
    'Kumanocho',
    'Nakamarucho',
    'Saiwaicho',
    'Sekimachikita',
    'Nakamurakita',
    'Eharacho',
    'Higashinakano',
    'Minamidai',
    'Kamisaginomiya',
    'Momoi',
    'Kamiogi',
    'Shimizu',
    'Omiya',
    'Tomigaya',
    'Dogenzaka',
    'Sakuragaokacho',
    'Ebisu',
    'Jingumae',
    'Komaba',
    'Higashiyama',
    'Gohongi',
    'Yutenji',
    'Chuocho',
    'Takaban',
    'Kakinokizaka',
    'Yakumo',
    'Higashigaoka',
    'Jiyugaoka',
    'Ookayama',
    'Kaminoge',
    'Kamiuma',
    'Chitosedai',
    'Kasuya',
    'Nishitsutsujigaoka',
    'Higashitsutsujigaoka',
    'Wakabacho',
    'Inokashira',
    'Kyonancho',
    'Midoricho',
    'Yayoi',
    'Shibakubocho',
    'Hibarigaoka',
    'Izumicho',
    'Fujimachi',
    'Iidabashi',
    'Kitazakae',
    'Takara',
    'Fukuei',
    'Minamiyawata',
    'Sugano',
    'Higashisugano',
    'Natsumi',
    'Magomenishi',
    'Kamiyamacho',
    'Yamate',
    'Higashinakayama',
    'Higashikasai',
    'Ichinoe',
    'Haruecho',
    'Yagochi',
    'Higashikoiwa',
    'Takasago',
    'Higashi-shinkoiwa',
    'Tachibana',
    'Oshiage',
    'Taihei',
    'Shinmatsudo',
    'Oyaguchi',
    'Kamishinshuku',
    'Higashihatsuishi',
    'Toyoshiki',
    'Toyogamicho',
    'Matsugasaki',
    'Toyofuta',
    'Daiba',
    'Higashisuna',
    'Ojima',
    'Kanda-sudacho',
    'Torigoe',
    'Nishiasakusa',
    'Higashiogu',
    'Machiya',
    'Tabata-shinmachi',
    'Nishikata',
    'Togasaki',
    'Hikonari',
    'Ho',
    'Matsubushi',
    'Katori',
    'Maeda',
    'Sunahara',
    'Rokkencho',
    'Ogi',
    'Miyagi',
    'Motoki-nishimachi',
    'Higashihokima',
    'Aoi',
    'Shiratori',
    'Kosuge',
    'Kubogaoka',
    'Minamiaizu',
    'Oji-honcho',
    'Nishigaoka',
    'Nishidai',
    'Nakadai',
    'Oharacho',
    'Inaridai',
    'Nakaitabashi',
    'Toshincho',
    'Tagara',
    'Nishiki',
    'Miharadai',
    'Sengencho',
    'Kanayamacho',
    'Takeoka',
    'Kitahara',
    'Negishidai',
    "Naka'arai",
    'Amanumacho',
    'Nishimachi',
    'Horisakicho',
    'Kamiochiai',
    'Shirakuwa',
    'Minamicho',
    'Kizawaminami',
    'Tsukagoshi',
    'Hachimangi',
    'Harayama',
    'Higashikawaguchi',
    'Wakatakecho',
    'Torigaoka',
    'Shimonagaya',
    'Chojamachi',
    'Yamato',
    'Bunkyo',
    'Azumagaoka',
    'Nishitobecho',
    'Minamisaiwai',
    'Bukkocho',
    'Imaicho',
    'Hazawaminami',
    'Sugetacho',
    'Kamishiranecho',
    'Tsurugamine',
    'Makigahara',
    'Futamatagawa',
    'Sasanodai',
    'Akuwahigashi',
    'Ryokuen',
    'Fukayacho',
    'Ishido',
    'Eniwa',
    'Higashigo',
    'Chubu',
    'Mukaihara',
    'Ito',
    'Shimadamachi',
    'Omuramachi',
    'Aichi',
    'Minami-Alps',
    'Mashiki',
    'Ogori',
    'Shinshigai',
    'Arao',
    'Goto',
    'Johara',
    'Ogura',
    'Otamachi',
    'Yanagida',
    'Munakata',
    'Miyota',
    'Ukiha',
    'Nagawa',
    'Fumizono',
    'Akancho-akanko-onsen',
    'Onbetsucho-nakaonbetsu',
    'Onbetsucho-nupukibetsu',
    'Minamiise',
    'Katamusari',
    'Kitami-danchi',
    'Takadai',
    "Kuchoro-gen'ya",
    'Hondori',
    'Kamitoyoni',
    'Sakaedori',
    'Kashiwagicho',
    'Higashinijo-minami',
    'Higashisanjo-kita',
    'Kyosei',
    'Pashikuru',
    'Kamishoro-chuo',
    'Onnenai',
    'Chuo-shinmachi',
    'Todai',
    'Himi',
    'Toon',
    'Yasu',
    'Yame',
    'Yamatokoriyama',
    'Yamanashi',
    'Tosashimizu',
    'Taketa',
    'Tachikawa',
    'Sagamihara',
    'Nagakute',
    'Mitoyo',
    'Kunitachi',
    'Ogasawara',
    'Kizugawa',
    'Katano',
    'Higashihiroshima',
    'Fuchu',
    'Chikugo',
    'Arida',
    'Aira',
    'Fukaya',
    'Nishitokyo',
    'Nanyo',
    'Kato',
    'Kiyose',
    'Joyo',
    'Yatomi',
    'Tome',
    'Kamagaya',
    'Ebino',
    'Bizen',
    'Higashiomi',
    'Etajima',
    'Shimanto',
    'Nankoku',
    'Seiyo',
    'Kai',
    'Warabi',
    'Itoshima',
    'Yufu',
    'Akiruno',
    'Shikokuchuo',
    'Narashino',
    'Minamiboso',
    'Oamishirasato',
    'Echizen',
    'Miyawaka',
    'Minamiawaji',
    'Shiso',
    'Tanba',
    'Nomi',
    'Higashikagawa',
    'Minamikyushu',
    'Minamisatsuma',
    'Soo',
    'Kyotanabe',
    'Kyotango',
    'Nantan',
    'Minamishimabara',
    'Saikai',
    'Unzen',
    'Bungo-ono',
    'Maniwa',
    'Setouchi',
    'Osaka-sayama',
    'Yoshinogawa',
    'Inagi',
    'Koganei',
    'Sanyoonoda',
    'Shunan',
    'Fuefuki',
    'Koshu',
    'Unnan',
    'Kunisaki',
    'Ichikikushikino',
    'Asakuchi',
    'Fukutsu',
    'Asakura',
    'Kamiamakusa',
    'Rikuzentakata',
    'Semboku',
    'Biei',
    'Kiyosato',
    'Kitoushi',
    'Tatsumi',
    'Rubeshibecho-fujimi',
    'Hokushincho',
    'Toryocho',
    'Sachioka',
    'Nitta-daiichi',
    'Utase',
    'Yunosawacho',
    'Mitsuwadai',
    'Bibaushi',
    'Higashirinkan',
    'Chibaminato',
    'Yokoze',
    'Nishikoizumi',
    'Shinozuka',
    'Sakakita',
    'Kusanagi',
    'Imaike',
    'Agui',
    'Myojo',
    'Higashimikuni',
    'Yakawa',
    'Kamiyasu',
    'Ikenobe',
    'Uchiko',
    'Suidocho',
    'Higashi-furuichiba',
    'Kamata-honcho',
    'Tamagawa',
    'Yayoicho',
    'Akatsuka-shinmachi',
    'Minami-Azabu',
    'Mitaka',
    'Chidori',
    'Nerima',
    'Kisohigashi',
    'Hagoromocho',
    'Miyashimohoncho',
    'Kitakishiwada',
    'Josuihoncho',
    'Kitaando',
    'Sakaihigashi',
    'Higashihagoromo',
    'Kandatsuchuo',
    'Oginosatohigashi',
    'Suzumenomiya',
    'Horinochi',
    'Higashimotomachi',
    'Hironodai',
    'Chuorinkan',
    'Uematsucho',
    'Higashikori',
    'Tatsumiminami',
    'Nakamiya',
    'Oimazatominami',
    'Nankokita',
    'Ikenomiya',
    'Motoyamaminamimachi',
    'Shinryodai',
    'Aratacho',
    'Nakayamatedori',
    'Nishitachibanacho',
    'Yamakubo',
    'Akamidai',
    'Mukaino',
    'Kamisawadori',
    'Kamitokorokami',
    'Touncho',
    'Nakazakinishi',
    'Umizuka',
    'Shinke',
    'Mikumari',
    'Misako',
    'Gokodori',
    'Uzumoridai',
    'Nishihommachi',
    'Nishimiyahara',
    'Shikitsunishi',
    'Fujishinden',
    'Baraki',
    'Minamimisaki',
    'Ekimaedori',
    'Shimmachi',
    'Jinzumachi',
    'Shogen',
    'Nishionuma',
    'Sapporo Shiritsu Kitanodaira Shogakko',
  ],
  'North Korea': ["Manp'o"],
  Singapore: [
    'Yew Tee',
    'Ulu Bedok',
    'Tuas',
    'Toa Payoh New Town',
    'Singapore',
    'Sembawang Estate',
    'Marsiling',
    'Kampong Ulu Jurong',
    'Kampong Sungai Jurong',
    'Kampong Punggol',
    'Kampong Pasir Ris',
    'Kampong Changi',
    'Kampong Bukit Panjang',
    'Buona Vista',
    'Boon Lay',
    'Ayer Raja New Town',
    'Bukit Batok New Town',
    'Jurong East New Town',
    'Jurong West New Town',
    'Simei New Town',
    'Tampines New Town',
    'Yishun New Town',
    'Woodlands New Town',
    'Ang Mo Kio New Town',
    'Clementi New Town',
    'Queenstown Estate',
    'Choa Chu Kang New Town',
  ],
  'Cook Islands': [
    'Omoka',
    'Teenui Village',
    'Tauhunu',
    'Tamarua Village',
    'Ngatiarua Village',
    'Avarua',
    'Amuri',
    'Palmerston',
    'Mangarei',
    'Motu Koe',
    'Nivano',
  ],
  Australia: [
    'Yorketown',
    'York',
    'Yankalilla',
    'Yanchep',
    'Wundowie',
    'Wudinna',
    'Woodville',
    'Woodside',
    'Wistow',
    'Willunga',
    'Willaston',
    'Whyalla',
    'Wembley',
    'Wellard',
    'Welland',
    'Wayville',
    'Waroona',
    'Wanneroo',
    'Walpole',
    'Walliston',
    'Waikerie',
    'Virginia',
    'Victor Harbor',
    'Vasse',
    'Unley',
    'Two Wells',
    'Trigg',
    'Toodyay',
    'Thebarton',
    'Tea Tree Gully',
    'Tanunda',
    'Tailem Bend',
    'Swan View',
    'Swanbourne',
    'Summertown',
    'Subiaco',
    'Sturt',
    'Strathalbyn',
    'Stoneville',
    'Stirling',
    'Spearwood',
    'Smithfield',
    'Serpentine',
    'Semaphore',
    'Seaton',
    'Seaford',
    'Scarborough',
    'Sawyers Valley',
    'Salisbury',
    'Saint Kilda',
    'Safety Bay',
    'Saddleworth',
    'Rudall',
    'Roxby Downs',
    'Roseworthy',
    'Roleystone',
    'Rockingham',
    'Riverton',
    'Maida Vale',
    'Reynella',
    'Prospect',
    'Port Pirie',
    'Port Noarlunga',
    'Moonta Bay',
    'Port Lincoln',
    'Port Hedland',
    'Port Germein',
    'Port Elliot',
    'Port Augusta West',
    'Port Augusta',
    'Pompoota',
    'Plympton',
    'Pinjarra',
    'Picton Junction',
    'Pickering Brook',
    'Peterborough',
    'Perth',
    'Penneshaw',
    'Penfield',
    'Pemberton',
    'Parkerville',
    'Parap',
    'One Tree Hill',
    "O'Halloran Hill",
    'Oaklands',
    'Oakbank',
    'Nuriootpa',
    'Nungarin',
    'North Fremantle',
    'Northfield',
    'North Dandalup',
    'North Beach',
    'Northampton',
    'Northam',
    'Normanville',
    'Old Noarlunga',
    'Nildottie',
    'Nhulunbuy',
    'Newman',
    'Nedlands',
    'Narrogin',
    'Nannup',
    'Nairne',
    'Murray Bridge',
    'Mundaring',
    'Mount Torrens',
    'Mount Pleasant',
    'Mount Magnet',
    'Mount Isa',
    'Mount Helena',
    'Mount Compass',
    'Mount Barker',
    'Mosman Park',
    'Morphett Vale',
    'Moonta',
    'Monarto South',
    'Modbury',
    'Moana',
    'Mitcham',
    'Mintaro',
    'Millendon',
    'Milang',
    'Midland',
    'Middleton',
    'Middle Swan',
    'Merredin',
    'Meningie',
    'Melrose',
    'Medina',
    'Meadows',
    'McLaren Vale',
    'Maylands',
    'Marion',
    'Margaret River',
    'Mardella',
    'Marbellup',
    'Mannum',
    'Manning',
    'Manjimup',
    'Mandurah',
    'Mallala',
    'Maitland',
    'Mahogany Creek',
    'Maddington',
    'Macclesfield',
    'Lyndoch',
    'Lower Chittering',
    'Longwood',
    'Lobethal',
    'Little Hampton',
    'Largs Bay',
    'Langhorne Creek',
    'Kwinana',
    'Kununurra',
    'Kojonup',
    'Kingscote',
    'Kimba',
    'Keswick',
    'Kenwick',
    'Kensington and Norwood',
    'Kelmscott',
    'Katherine',
    'Katanning',
    'Karnup',
    'Kapunda',
    'Kapinnie',
    'Kanmantoo',
    'Kalgoorlie',
    'Kalgan',
    'Kalbarri',
    'Kadina',
    'Jupiter Creek',
    'Ingle Farm',
    'Hovea',
    'Houghton',
    'Hope Valley',
    'Herne Hill',
    'Hendon',
    'Hawker',
    'Harvey',
    'Happy Valley',
    'Hamersley',
    'Halletts Cove',
    'Hahndorf',
    'Hackham',
    'Gumeracha',
    'Guildford',
    'Greenmount',
    'Greenbushes',
    'Grange',
    'Gosnells',
    'Goomalling',
    'Goolwa',
    'Golden Grove',
    'Glenelg',
    'Gingin',
    'Gilles Plains',
    'Geraldton',
    'Gepps Cross',
    'Gawler',
    'Fremantle',
    'Freeling',
    'Fitzroy Crossing',
    'Findon',
    'Eudunda',
    'Esperance',
    'Erskine',
    'Encounter Bay',
    'Elizabeth',
    'Dunsborough',
    'Donnybrook',
    'Chidlow',
    'Derby',
    'Darwin',
    'Darlington',
    'Dardanup',
    'Dalwallinu',
    'Cygnet River',
    'Cummins',
    'Cromer',
    'Craigburn Farm',
    'Crafers',
    'Cowaramup',
    'Cottesloe',
    'Coromandel Valley',
    'Copeville',
    'Coolgardie',
    'Cookernup',
    'Collie',
    'Clarendon',
    'Clare',
    'Chittering',
    'Cherry Gardens',
    'Cheltenham',
    'Charleston',
    'Caversham',
    'Carnarvon',
    'Carmel',
    'Carey Gully',
    'Cardup',
    'Capel',
    'Cannington',
    'Campbelltown',
    'Byford',
    'Butler',
    'Busselton',
    'Burra',
    'Burketown',
    'Bunbury',
    'Bullsbrook',
    'Bruce',
    'Broome',
    'Brighton',
    'Bridgewater',
    'Bradbury',
    'Boulder',
    'Blackwood',
    'Birkenhead',
    'Bickley',
    'Beverley',
    'Bentley',
    'Belair',
    'Bedford Park',
    'Bedfordale',
    'Beaumont',
    'Beaufort',
    'Bassendean',
    'Basket Range',
    'Balhannah',
    'Balaklava',
    'Bakers Hill',
    'Australind',
    'Augusta',
    'Armadale',
    'Ardrossan',
    'Applecross',
    'Angle Vale',
    'Angas Valley',
    'Angaston',
    'American River',
    'Altona',
    'Alice Springs',
    'Aldgate',
    'Albany',
    'Adelaide',
    'Eaton',
    'Seacliff',
    'Clapham',
    'Hindmarsh',
    'Two Rocks',
    'Quinns Rocks',
    'East Fremantle',
    'Peppermint Grove',
    'Claremont',
    'Bicton',
    'Attadale',
    'Shenton Park',
    'Dalkeith',
    'Drummond Cove',
    'Warnbro',
    'Mullaloo',
    'Exmouth',
    'Alyangula',
    'Stuart Park',
    'Larrakeyah',
    'Leanyer',
    'Fannie Bay',
    'Nightcliff',
    'Glen Iris',
    'Zeehan',
    'Yungaburra',
    'Young',
    'Yinnar',
    'Yerrinbool',
    'Yering',
    'Yeppoon',
    'Yenda',
    'Yelgun',
    'Yea',
    'Yass',
    'Yarrawonga',
    'Yarraville',
    'Yarra Junction',
    'Yarragon',
    'Yarra Glen',
    'Yan Yean',
    'Yandina',
    'Yamba',
    'Yaamba',
    'Wyreema',
    'Wyongah',
    'Wyong',
    'Wynyard',
    'Wynnum North',
    'Wynnum',
    'Wyndham',
    'Wye River',
    'Wyee',
    'Woy Woy',
    'Worrigee',
    'Worongary',
    'Woree',
    'Wooroolin',
    'Woonona',
    'Woongoolba',
    'Woombye',
    'Woolwich',
    'Woolloomooloo',
    'Woolgoolga',
    'Woodvale',
    'Woodstock',
    'Woodridge',
    'Woodhill',
    'Woodgate',
    'Woodford',
    'Woodend',
    'Woodenbong',
    'Wonthaggi',
    'Wonga Park',
    'Wondai',
    'Wombarra',
    'Wollongbar',
    'Wollert',
    'Wodonga',
    'Wivenhoe',
    'Witta',
    'Winton',
    'Winkie',
    'Wingham',
    'Windsor',
    'Windeyer',
    'Windaroo',
    'Windang',
    'Winchelsea',
    'Wilton',
    'Williamstown',
    'Williamsdale',
    'Wildes Meadow',
    'Wilberforce',
    'Whorouly',
    'Whittlesea',
    'Whittingham',
    'White Rock',
    'Whitebridge',
    'Wheelers Hill',
    'Whale Beach',
    'West Wyalong',
    'West Wallsend',
    'Weston',
    'Westmead',
    'West Hoxton',
    'Westbury',
    'West Burleigh',
    'Westbrook',
    'Werribee',
    'Werombi',
    'Wentworthville',
    'Wentworth Falls',
    'Wendouree',
    'Welshpool',
    'Wellington Point',
    'Weipa',
    'Wee Waa',
    'Weetangera',
    'Waverton',
    'Waurn Ponds',
    'Wauchope',
    'Waterloo',
    'Waterford',
    'Waterfall',
    'Warwick',
    'Warrnambool',
    'Warrimoo',
    'Warrenheip',
    'Warrawee',
    'Warrane',
    'Warrandyte',
    'Warragul',
    'Warnervale',
    'Warburton',
    'Wantirna South',
    'Wantirna',
    'Wanora',
    'Wangi Wangi',
    'Wangaratta',
    'Heathcote Junction',
    'Wandin North',
    'Wandin',
    'Wamuran',
    'Wamberal',
    'Wallsend',
    'Walloon',
    'Wallington',
    'Wallan',
    'Wallace',
    'Walkerston',
    'Walcha',
    'Waitara',
    'Wahroonga',
    'Wagga Wagga',
    'Wadeville',
    'Wacol',
    'Vineyard',
    'Villawood',
    'Victoria Point',
    'Vermont',
    'Ventnor',
    'Vaucluse',
    'Valley Heights',
    'Urraween',
    'Uralla',
    'Upwey',
    'Upper Rouchel',
    'Upper Lansdowne',
    'Upper Glastonbury',
    'Upper Coomera',
    'Upper Brookfield',
    'Unanderra',
    'Ulverstone',
    'Ultima',
    'Ulladulla',
    'Uki',
    'Tynong North',
    'Tynong',
    'Tylden',
    'Tyagarah',
    'Tyabb',
    'Tweed Heads',
    'Turramurra',
    'Tuncurry',
    'Tumut',
    'Tully',
    'Tullimbar',
    'Tullamarine',
    'Tugun',
    'Tuggeranong Administrative District',
    'Tuggerah',
    'Truganina',
    'Trentham',
    'Trebonne',
    'Traralgon South',
    'Traralgon',
    'Trafalgar',
    'Townsville',
    'Toukley',
    'Torquay',
    'Toronto',
    'Toowoomba',
    'Toowong',
    'Toorak',
    'Tooradin',
    'Toongabbie',
    'Toolamba',
    'Tomerong',
    'Tomakin',
    'Tomago',
    'Tolga',
    'Tocumwal',
    'Tintenbar',
    'Tintaldra',
    'Tin Can Bay',
    'Timbrebongie',
    'Timboon',
    'Thredbo',
    'Thornton',
    'Thornleigh',
    'Thornlands',
    'Thornbury',
    'Thomastown',
    'Thirroul',
    'Thirlmere',
    'The Summit',
    'The Rocks',
    'The Rock',
    'Theresa Park',
    'The Patch',
    'Theodore',
    'The Oaks',
    'The Basin',
    'Thagoona',
    'Texas, Goodiwindi',
    'Tewantin',
    'Terrigal',
    'Terrey Hills',
    'Terranora',
    'Termeil',
    'Teralba',
    'Tennyson',
    'Tenambit',
    'Templestowe Lower',
    'Templestowe',
    'Tempe',
    'Temora',
    'Telarah',
    'Tea Tree',
    'Tea Gardens',
    'Taylors Arm',
    'Tatura',
    'Tarro',
    'Tarragindi',
    'Taroona',
    'Tarneit',
    'Taree',
    'Tarana',
    'Tangambalanga',
    'Tamworth',
    'Tallebudgera',
    'Tallarook',
    'Tahmoor',
    'Syndal',
    'Sydney',
    'Sydenham',
    'Swansea',
    'Swan Hill',
    'Sutton',
    'Sutherland',
    'Surry Hills',
    'Surrey Hills',
    'Surfers Paradise',
    'Sunshine',
    'Sunnybank',
    'Sunbury',
    'Sulky Gully',
    'Stuarts Point',
    'Stroud',
    'Strathpine',
    'Strathmerton',
    'Strathfieldsaye',
    'Strathdownie',
    'Stratford',
    'Stokers Siding',
    'Stockton',
    'Stockleigh',
    'Stawell',
    'Stapylton',
    'Stanthorpe',
    'Stanmore',
    'Stanley',
    'Springwood',
    'Springvale South',
    'Springvale',
    'Spring Hill',
    'Spring Field',
    'Springdallah',
    'Springbrook',
    'Spreyton',
    'Spotswood',
    'Spencer',
    'South Yarra',
    'Werribee South',
    'Southport',
    'South Grafton',
    'Southbrook',
    'South Arm',
    'Sorrento',
    'Midway Point',
    'Somerville',
    'Somerton',
    'Somerset',
    'Somersby',
    'Soldiers Point',
    'Sofala',
    'Smythesdale',
    'Smithton',
    'Slacks Creek',
    'Singleton',
    'Silvan',
    'Shorncliffe',
    'Shepparton',
    'Shellharbour',
    'Seymour',
    'Seville',
    'Seven Hills',
    'Sedgwick',
    'Sebastopol',
    'Sebastian',
    'Scottsdale',
    'Scoresby',
    'Scone',
    'Schofields',
    'Scarsdale',
    'Sawtell',
    'Sassafras',
    'Sarina',
    'San Remo',
    'Sandringham',
    'Sandgate',
    'Sandford',
    'Sancrox',
    'Samford',
    'Sale',
    'Saint Peters',
    'Saint Marys',
    'Saint Lucia',
    'Saint Leonards',
    'St Kilda',
    'Saint Ives',
    'St Helens',
    'Saint George',
    'Saint Arnaud',
    'Rye',
    'Ryde',
    'Rydalmere',
    'Rutherglen',
    'Rutherford',
    'Ruffy',
    'Royalla',
    'Rowville',
    'Ross Creek',
    'Rosewood',
    'Roseville',
    'Rosehill',
    'Rosedale',
    'Rosebud',
    'Rosebery',
    'Rooty Hill',
    'Rookhurst',
    'Roma',
    'Rokewood',
    'Rokeby',
    'Rocklea',
    'Rockhampton',
    'Rockdale',
    'Rockbank',
    'Rochester',
    'Rochedale',
    'Robertson',
    'Riverview',
    'Riverstone',
    'Ripley',
    'Ringwood North',
    'Ringwood East',
    'Ringwood',
    'Riddell',
    'Richmond East',
    'Richmond',
    'Rhyll',
    'Rhodes',
    'Revesby',
    'Reservoir',
    'Research',
    'Renmark',
    'Regents Park',
    'Redlynch',
    'Redland Bay',
    'Red Hill South',
    'Redhead',
    'Redfern',
    'Red Cliffs',
    'Redcliffe',
    'Redbank',
    'Raworth',
    'Ravenshoe',
    'Rathmines',
    'Ranelagh',
    'Rand',
    'Raleigh',
    'Rainbow Flat',
    'Railton',
    'Quirindi',
    'Queenstown',
    'Queenscliff',
    'Queanbeyan',
    'Quakers Hill',
    'Pyrmont',
    'Pymble',
    'Pyalong',
    'Pullen Vale',
    'Proserpine',
    'Preston West',
    'Preston',
    'Prahran',
    'Potts Point',
    'Port Sorell',
    'Port Macquarie',
    'Portland',
    'Port Kembla',
    'Port Fairy',
    'Port Douglas',
    'Portarlington',
    'Pontville',
    'Pomona',
    'Pokolbin',
    'Point Lonsdale',
    'Point Cook',
    'Point Clare',
    'Plainland',
    'Pitt Town',
    'Pittsworth',
    'Pinnaroo',
    'Pinkenba',
    'Pine Mountain',
    'Pimpama',
    'Picton',
    'Pialba',
    'Phillip',
    'Petrie',
    'Pentland',
    'Penshurst',
    'Penrith',
    'Pennant Hills',
    'Penguin',
    'Pendle Hill',
    'Pechey',
    'Pearcedale',
    'Peak Crossing',
    'Peachester',
    'Pascoe Vale',
    'Parkville',
    'Park Ridge',
    'Parkes',
    'Parkdale',
    'Panton Hill',
    'Pambula',
    'Palmwoods',
    'Palm Beach',
    'Pakenham Upper',
    'Pakenham East',
    'Pakenham',
    'Oxley',
    'Oura',
    'Osborne',
    'Ormiston',
    'Ormeau',
    'Orange',
    'Oonoonba',
    'One Mile',
    'Lancefield',
    'Old Beach',
    'Old Bar',
    'Officer',
    'Barwon Heads',
    'Oberon',
    'Oatley',
    'Oatlands',
    'Oakleigh East',
    'Oakleigh',
    'Oakhurst',
    'Oakey',
    'Nunawading',
    'Numurkah',
    'Nugent',
    'Nubeena',
    'Nowra',
    'Notting Hill',
    'North Wonthaggi',
    'North Tamborine',
    'North Melbourne',
    'Northmead',
    'Northgate',
    'Fitzroy North',
    'Northcote',
    'North Arm',
    'Normanhurst',
    'Nords Wharf',
    'Noosaville',
    'Noosa',
    'Noble Park',
    'Newtown',
    'Newstead',
    'Newport',
    'New Norfolk',
    'Newnham',
    'Newmarket',
    'Newington',
    'Newhaven',
    'Newcastle',
    'Nerrina',
    'Nerang',
    'Nelson Bay',
    'Nelligen',
    'Neerim South',
    'Neerim East',
    'Neath',
    'Narromine',
    'Narre Warren North',
    'Narre Warren',
    'Narrawong',
    'Narrandera',
    'Narrabri',
    'Narrabeen',
    'Narooma',
    'Nar Nar Goon',
    'Narellan',
    'Narara',
    'Narangba',
    'Naracoorte',
    'Nanango',
    'Nana Glen',
    'Nambucca Heads',
    'Nambrok',
    'Nambour',
    'Nagambie',
    'Nabiac',
    'Myrniong',
    'Muswellbrook',
    'Murwillumbah',
    'Murtoa',
    'Murrurundi',
    'Harden',
    'Murrumbeena',
    'Murgon',
    'Murdunna',
    'Mungar',
    'Mundubbera',
    'Mullumbimby',
    'Mullengandra',
    'Mulgrave',
    'Mulgowie',
    'Mulgoa',
    'Mulbring',
    'Mudgeeraba',
    'Mudgee',
    'Moura',
    'Mount Waverley',
    'Mount Victoria',
    'Mount Seymour',
    'Mount Samson',
    'Mount Moriac',
    'Mount Morgan',
    'Mount Martha',
    'Mount Macedon',
    'Mount Gravatt',
    'Mount Gambier',
    'Mount Forbes',
    'Mount Evelyn',
    'Mount Eliza',
    'Mount Egerton',
    'Mount Duneed',
    'Mount Druitt',
    'Mount Direction',
    'Mount Dandenong',
    'Mt Crosby',
    'Mount Cotton',
    'Mount Clear',
    'Moss Vale',
    'Mosman',
    'Morwincha',
    'Morwell',
    'Moruya Heads',
    'Moruya',
    'Mortlake',
    'Mortdale',
    'Mornington',
    'Morisset East',
    'Morisset',
    'Moriac',
    'Moree',
    'Mordialloc',
    'Morayfield',
    'South Morang',
    'Mooroopna',
    'Mooroolbark',
    'Moorook',
    'Moorooduc',
    'Moorland',
    'Moore',
    'Moorabbin',
    'Moonee Ponds',
    'Moonee Beach',
    'Moonah',
    'Mooloolah Valley',
    'Mooloolabah',
    'Moolap',
    'Montville',
    'Montrose',
    'Monto',
    'Montmorency',
    'Mont Albert',
    'Monbulk',
    'Mona Vale',
    'Molong',
    'Moggill',
    'Yallourn',
    'Moama',
    'Mittagong',
    'Mitchelton',
    'Mitchells Flat',
    'Mirani',
    'Miranda',
    'Minto',
    'Minmi',
    'Mill Park',
    'Millicent',
    'Millaa Millaa',
    'Mildura',
    'Milawa',
    'Middle Camberwell',
    'Merrylands',
    'Merimbula',
    'Merricks North',
    'Mernda',
    'Meringandan',
    'Meredith',
    'Merbein',
    'Menzies Creek',
    'Mentone',
    'Menindee',
    'Menangle',
    'Menai',
    'Melton South',
    'Melton',
    'Melbourne',
    'Medowie',
    'Medlow Bath',
    'McLeans Ridges',
    'Maudsland',
    'Matraville',
    'Matcham',
    'Mascot',
    'Maryborough',
    'Martinsville',
    'Marshalltown',
    'Marrickville',
    'Maroubra',
    'Maribyrnong',
    'Marian',
    'Margate',
    'Mareeba',
    'Marburg',
    'Mapleton',
    'Mansfield',
    'Manor',
    'Mannerim',
    'Manly',
    'Manilla',
    'Mangrove Mountain',
    'Mangoplah',
    'Mangalore',
    'Malvern',
    'Malmsbury',
    'Maleny',
    'Maldon',
    'Malabar',
    'Majura',
    'Magpie',
    'Maffra',
    'Maddingley',
    'Macleod',
    'Maclean',
    'Macksville',
    'Mackay',
    'Macedon',
    'Lytton',
    'Lysterfield',
    'Lyndhurst South',
    'Luddenham',
    'Lucknow',
    'Lucaston',
    'Loxton',
    'Lowood',
    'Low Head',
    'Lovely Banks',
    'Lorne',
    'Lorn',
    'Longwarry',
    'Longreach',
    'Longlea',
    'Long Jetty',
    'Longford',
    'Logan Village',
    'Loganlea',
    'Loganholme',
    'Lockington',
    'Liverpool',
    'Little River',
    'Little Burleigh',
    'Lithgow',
    'Lismore',
    'Lisarow',
    'Linton',
    'Lindisfarne',
    'Lindfield',
    'Lindenow South',
    'Linden',
    'Lilydale',
    'Lidcombe',
    'Lewis Ponds',
    'Leura',
    'Leumeah',
    'Lethbridge',
    'Leopold',
    'Leongatha',
    'Leneva',
    'Leichhardt',
    'Legana',
    'Leeton',
    'Lawnton',
    'Lavington',
    'Camden Haven',
    'Laura',
    'Launceston',
    'Lauderdale',
    'Latrobe',
    'Largs',
    'Lara',
    'Langwarrin',
    'Lang Lang',
    'Lane Cove',
    'Landsborough',
    'Lalor',
    'Lal Lal',
    'Lakesland',
    'Lake Haven',
    'Lakes Entrance',
    'Lakemba',
    'Lake Illawarra',
    'Lake Clarendon',
    'Lake Albert',
    'Laidley',
    'Kyogle',
    'Kyneton',
    'Kybong',
    'Kyabram',
    'Kurri Kurri',
    'Kurrajong Heights',
    'Kurnell',
    'Kuranda',
    'Kumbia',
    'Kotara',
    'Korumburra',
    'Koroit',
    'Koo-Wee-Rup',
    'Kootingal',
    'Koornalla',
    'Koonya',
    'Koonwarra',
    'Kogarah',
    'Kirribilli',
    'Kin Kin',
    'Kingswood',
    'Kingston Beach',
    'Kingston',
    'Kingscliff',
    'Kinglake',
    'Kingaroy',
    'Kincumber',
    'Kilsyth',
    'Kilmore',
    'Killawarra',
    'Killarney',
    'Kilcoy',
    'Killcare',
    'Kiewa',
    'Kiama',
    'Kialla West',
    'Kialla East',
    'Keysborough',
    'Kew East',
    'Kew',
    'Kerang',
    'Kenthurst',
    'Kensington',
    'Kennington',
    'Kenmore',
    'Kenilworth',
    'Kempsey',
    'Kelso',
    'Keith Hall',
    'Keith',
    'Keiraville',
    'Keilor',
    'Kawungan',
    'Katoomba',
    'Karangi',
    'Kanwal',
    'Kangaroo Valley',
    'Kangaroo Flat',
    'Kandanga',
    'Kalorama',
    'Kallista',
    'Kalkallo',
    'Kalbar',
    'Kahibah',
    'Junee',
    'Julia Creek',
    'Jugiong',
    'Judbury',
    'Johns River',
    'Jindera',
    'Jindabyne',
    'Jimboomba',
    'Jilliby',
    'Jerilderie',
    'Jaspers Brush',
    'Jannali',
    'Jamberoo',
    'Ivanhoe East',
    'Irish Town',
    'Ipswich',
    'Inverloch',
    'Inverleigh',
    'Inverell',
    'Innisplain',
    'Innisfail',
    'Ingleburn',
    'Ingham',
    'Iluka',
    'Illewong',
    'Hurstville Grove',
    'Hurstbridge',
    'Huonville',
    'Huntly',
    'Hughesdale',
    'Howlong',
    'Howard',
    'Horsham',
    'Hornsby',
    'Hordern Vale',
    'Home Hill',
    'Homebush',
    'Holsworthy',
    'Holmesville',
    'Holgate',
    'Hobart',
    'Hillwood',
    'Hill Top',
    'Highett',
    'Highclere',
    'Hexham',
    'Hepburn Springs',
    'Helensburgh',
    'Heidelberg West',
    'Heidelberg',
    'Heddon Greta',
    'Heathmont',
    'Heathcote',
    'Healesville',
    'Hazelbrook',
    'Hay Point',
    'Haymarket',
    'Hawthorn',
    'Hatton Vale',
    'Hastings',
    'Hartley',
    'Harrisville',
    'Harristown',
    'Harlaxton',
    'Harkaway',
    'Hanwood',
    'Hampton Park',
    'Hampton',
    'Hamilton',
    'Hallam',
    'Hall',
    'Halifax',
    'Hagley',
    'Haddon',
    'Haberfield',
    'Habana',
    'Gympie',
    'Guyra',
    'Gunning',
    'Gungahlin',
    'Gundaroo',
    'Gundagai',
    'Gumly Gumly',
    'Gulgong',
    'Gruyere',
    'Grovedale',
    'Griffith',
    'Greta',
    'Grenfell',
    'Greenvale',
    'Greensborough',
    'Greenbank',
    'Great Western',
    'Gravelly Beach',
    'Granville',
    'Granton',
    'Grantham',
    'Grandchester',
    'Grafton',
    'Gracemere',
    'Gowrie Junction',
    'Gowrie',
    'Goulburn',
    'Gosford',
    'Gorokan',
    'Gordonvale',
    'Gordon',
    'Goornong',
    'Goonengerry',
    'Goonellabah',
    'Goondiwindi',
    'Goodna',
    'Gooburrum',
    'Gold Coast',
    'Gloucester',
    'Glen Waverley',
    'Glenview',
    'Glenroy',
    'Glenreagh',
    'Glenorie',
    'Glenore Grove',
    'Glenorchy',
    'Glen Innes',
    'Glen Huntly',
    'Glenhaven',
    'Glengarry',
    'Glenfield',
    'Glenella',
    'Gleneagle',
    'Glenbrook',
    'Glebe',
    'Glass House Mountains',
    'Glamorgan Vale',
    'Gladstone',
    'Gisborne',
    'Giru',
    'Gin Gin',
    'Gilston',
    'Gilgandra',
    'Gilderoy',
    'Gilberton',
    'Geurie',
    'Gerringong',
    'George Town',
    'Gembrook',
    'Geelong',
    'Gaythorne',
    'Gatton',
    'Garvoc',
    'Gardenvale',
    'Galston',
    'Fyshwick',
    'Freshwater Creek',
    'Freshwater',
    'Frederickton',
    'Frankston East',
    'Frankston',
    'Foster',
    'Forth',
    'Forster',
    'Forest Hill',
    'Fordsdale',
    'Forbes',
    'Footscray',
    'Flinders',
    'Fitzroy',
    'Ferny Creek',
    'Fernvale',
    'Fern Tree',
    'Fawkner',
    'Faulconbridge',
    'Faraday',
    'Fairfield',
    'Failford',
    'Exeter',
    'Ewingsdale',
    'Evans Head',
    'Evandale',
    'Euroa',
    'Eumundi',
    'Eudlo',
    'Ettalong',
    'Essendon',
    'Esk',
    'Erina',
    'Epsom',
    'Epping',
    'Engadine',
    'Enfield',
    'Emu Plains',
    'Emu Park',
    'Empire Bay',
    'Emmaville',
    'Emerald',
    'Eltham North',
    'Eltham',
    'Elsternwick',
    'Elsmore',
    'Elmore',
    'Elliminyt',
    'Elimbah',
    'Electrona',
    'Elderslie',
    'El Arish',
    'Eimeo',
    'Eildon',
    'Eight Mile Plains',
    'Edmonton',
    'Edithvale',
    'Edgecliff',
    'Eden',
    'Echuca',
    'Ebbw Vale',
    'Eastwood',
    'East Kangaloon',
    'East Hills',
    'East Gosford',
    'East Ballina',
    'Earlwood',
    'Eagle Heights',
    'Eaglehawk',
    'Eagle Farm',
    'Eagleby',
    'Duri',
    'Duranbah',
    'Dural',
    'Dunwich',
    'Dunoon',
    'Duns Creek',
    'Dungog',
    'Dunbible Creek',
    'Dunalley',
    'Dudley South',
    'Dubbo',
    'Duaringa',
    'Drysdale',
    'Drouin',
    'Dromana',
    'Drayton',
    'Dover',
    'Douglas Park',
    'Doreen',
    'Dora Creek',
    'Dooralong',
    'Doonside',
    'Donvale',
    'Doncaster East',
    'Doncaster',
    'Dirranbandi',
    'Dinmore',
    'Dingley',
    'Diggers Rest',
    'Diamond Creek',
    'Devonport',
    'Devon Meadows',
    'Derrimut',
    'Dennington',
    'Deniliquin',
    'Deloraine',
    'Delegate River',
    'Deer Park',
    'Deception Bay',
    'Dean',
    'Deakin',
    'Deagon',
    'Daylesford',
    'Dayboro',
    'Davistown',
    'Davidson',
    'Darra',
    'Darlington Point',
    'Darlinghurst',
    'Darling',
    'Darley',
    'Lower Plenty',
    'Dapto',
    'Dandenong West',
    'Dandenong',
    'Dalyston',
    'Dalby',
    'Dakabin',
    'Daceyville',
    'Cygnet',
    'Curra',
    'Curlewis',
    'Cundletown',
    'Cumbalum',
    'Cullen Bullen',
    'Croydon North',
    'Croydon',
    'Crows Nest',
    'Crookwell',
    'Cronulla',
    'Cringila',
    'Crib Point',
    'Creswick',
    'Cremorne',
    'Cranley',
    'Cranbourne',
    'Craigieburn',
    'Cowra',
    'Cowes',
    'Corryong',
    'Corrimal',
    'Corowa',
    'Cornubia',
    'Corio',
    'Corinella',
    'Corindi',
    'Corinda',
    'Cora Lynn',
    'Coraki',
    'Cootamundra',
    'Coorparoo',
    'Cooranbong',
    'Coopernook',
    'Coonabarabran',
    'Cooma',
    'Coolum Beach',
    'Coolangatta',
    'Cooktown',
    'Coogee',
    'Cooee',
    'Conondale',
    'Coniston',
    'Congo',
    'Condobolin',
    'Condamine',
    'Concord',
    'Como',
    'Colo Vale',
    'Collinsville',
    'Collinsvale',
    'Collingwood',
    'Collingullie',
    'Collaroy',
    'Coledale',
    'Coldstream',
    'Colbinabbin',
    'Colac',
    'Cohuna',
    'Coffs Harbour',
    'Cockatoo',
    'Coburg',
    'Cobram',
    'Cobden',
    'Cobbitty',
    'Cobar',
    'Clyde North',
    'Clyde',
    'Clunes',
    'Closeburn',
    'Cloncurry',
    'Clifton Springs',
    'Clifton',
    'Cleveland',
    'Clermont',
    'Clematis',
    'Clayton South',
    'Clayton',
    'Clareville',
    'Clarence Town',
    'Wollongong',
    'Chullora',
    'Chipping Norton',
    'Chippendale',
    'Chinderah',
    'Chinchilla',
    'Childers',
    'Chewton',
    'Chelsea Heights',
    'Chelsea',
    'Chatswood',
    'Charters Towers',
    'Charleville',
    'Charlestown',
    'Cessnock',
    'Ceres',
    'Central Tilba',
    'Cedar Grove',
    'Cecil Plains',
    'Caulfield',
    'Castlemaine',
    'Castle Hill',
    'Castlecrag',
    'Castella',
    'Casino',
    'Carrum Downs',
    'Carrum',
    'Carrington',
    'Carrick',
    'Carool',
    'Carnegie',
    'Carlton South',
    'Carlton North',
    'Carlton',
    'Carlingford',
    'Caringbah',
    'Cardiff',
    'Carbrook',
    'Capalaba',
    'Canungra',
    'Canowindra',
    'Cannonvale',
    'Canberra',
    'Canadian',
    'Camp Mountain',
    'Camperdown',
    'Campbells Creek',
    'Campbellfield',
    'Campbell',
    'Campania',
    'Camden',
    'Cambridge',
    'Cambooya',
    'Camberwell',
    'Cambewarra',
    'Caloundra',
    'Callignee',
    'Cairns',
    'Caboolture',
    'Bywong',
    'Byron Bay',
    'Buxton',
    'Burwood East',
    'Burwood',
    'Burringbar',
    'Burradoo',
    'Burpengary',
    'Burnley',
    'Burnie',
    'Burnett Heads',
    'Burleigh Heads',
    'Bunyip',
    'Buninyong',
    'Bungendore',
    'Bundanoon',
    'Bundamba',
    'Bundaberg',
    'Bulli',
    'Bullengarook',
    'Bulleen',
    'Bulla',
    'Bulahdelah',
    'Budgewoi',
    'Buccan',
    'Bucasia',
    'Bruthen',
    'Brunswick West',
    'Brunswick Heads',
    'Brunswick East',
    'Brunswick',
    'Brownsville',
    'Browns Plains',
    'Brown Hill',
    'Brooklyn',
    'Brookfield',
    'Broken Hill',
    'Broke',
    'Broadmeadows',
    'Broadford',
    'Broadbeach',
    'Brisbane',
    'Brightview',
    'Bridport',
    'Bridgenorth',
    'Briar Hill',
    'Branxton',
    'Brandy Creek',
    'Brandon',
    'Braeside',
    'Braemar',
    'Boyne Island',
    'Box Hill',
    'Bowral',
    'Bowenfels',
    'Bowen',
    'Botany',
    'Boronia',
    'Bordertown',
    'Boorowa',
    'Booral',
    'Boondall',
    'Boonah',
    'Boolaroo',
    'Booker Bay',
    'Bonville',
    'Bongaree',
    'Boneo',
    'Bombala',
    'Bomaderry',
    'Bolwarra',
    'Boambee',
    'Bloomsbury',
    'Bli Bli',
    'Blayney',
    'Blaxland',
    'Blackwater',
    'Blackwall',
    'Blacktown',
    'Blacks Beach',
    'Blackmans Bay',
    'Black Hill',
    'Blackheath',
    'Blackbutt',
    'Bittern',
    'Birkdale',
    'Birchip',
    'Bingara',
    'Binda',
    'Bilpin',
    'Biloela',
    'Bilinga',
    'Bicheno',
    'Bexley',
    'Bexhill',
    'Beveridge',
    'Bethania Junction',
    'Berwick',
    'Berry',
    'Berrigan',
    'Berridale',
    'Berri',
    'Berowra',
    'Bermagui',
    'Berkeley Vale',
    'Berkeley',
    'Beresfield',
    'Bentleigh East',
    'Bentleigh',
    'Bensville',
    'Benowa',
    'Bendigo',
    'Bendemeer',
    'Benalla',
    'Belrose',
    'Belmont',
    'Belltrees',
    'Bellingen',
    'Bellerive',
    'Bellbird',
    'Bellambi',
    'Belgrave South',
    'Belgrave',
    'Belgian Gardens',
    'Belconnen',
    'Bega',
    'Beerwah',
    'Beerburrum',
    'Beenleigh',
    'Beenak',
    'Beelbangera',
    'Beechworth',
    'Beechmont',
    'Beauty Point',
    'Beaumaris',
    'Beaudesert',
    'Beaconsfield Upper',
    'Beaconsfield',
    'Beachmere',
    'Bayswater',
    'Baxter',
    'Baulkham Hills',
    'Bathurst',
    'Batesford',
    'Batemans Bay',
    'Bass Hill',
    'Barwon Downs',
    'Barraba',
    'Barnsley',
    'Barmera',
    'Barmah',
    'Bargo',
    'Bargara',
    'Baranduda',
    'Banora Point',
    'Bannockburn',
    'Bankstown',
    'Bangalow',
    'Bambra',
    'Bamaga',
    'Balnarring',
    'Balmoral',
    'Balmain',
    'Ballina',
    'Ballarat',
    'Ballan',
    'Balgownie',
    'Balgowlah',
    'Balaclava',
    'Bairnsdale',
    'Bahrs Scrub',
    'Bagdad',
    'Bacchus Marsh',
    'Babinda',
    'Ayr',
    'Axedale',
    'Awaba',
    'Avoca Beach',
    'Avalon Beach',
    'Austins Ferry',
    'Austinmer',
    'Augathella',
    'Auburn Vale',
    'Auburn',
    'Atherton',
    'Asquith',
    'Aspley',
    'Aspendale',
    'Ashgrove',
    'Ashfield',
    'Ashby',
    'Ashburton',
    'Ascot Vale',
    'Ascot',
    'Arthurs Creek',
    'Artarmon',
    'Armidale',
    'Arcadia',
    'Ararat',
    'Apsley',
    'Appleby',
    'Appin',
    'Annandale',
    'Anna Bay',
    'Andergrove',
    'Alstonville',
    'Aloomba',
    'Almurta',
    'Allan',
    'Alfredton',
    'Alexandria',
    'Alexandra',
    'Albury',
    'Albion',
    'Albany Creek',
    'Aitkenvale',
    'Adaminaby',
    'Acacia Ridge',
    'Abermain',
    'Aberglasslyn',
    'Aberdeen',
    'Aberdare',
    'Abbotsford',
    'Morpeth',
    'New Lambton',
    'Raymond Terrace',
    'Windermere',
    'Mayfield',
    'Invermay',
    'Gymea',
    'Kirrawee',
    'Loftus',
    'Woolooware',
    'Lilli Pilli',
    'Burraneer',
    'Bundeena',
    'Yowie Bay',
    'Gymea Bay',
    'Yarrawarrah',
    'Bangor',
    'Kareela',
    'Sylvania',
    'Sylvania Waters',
    'Taren Point',
    'Bonnet Bay',
    'Oyster Bay',
    'Grays Point',
    'Banksmeadow',
    'Phillip Bay',
    'Little Bay',
    'Brighton-Le-Sands',
    'Monterey',
    'Ramsgate',
    'Sans Souci',
    'Blakehurst',
    'Connells Point',
    'Lugarno',
    'Peakhurst',
    'Padstow',
    'Riverwood',
    'Lutana',
    'New Town',
    'Lake Heights',
    'Warrawong',
    'Sandy Bay',
    'Tarrawanna',
    'Towradgi',
    'Fairy Meadow',
    'Mount Ousley',
    'Mount Keira',
    'Gwynneville',
    'Figtree',
    'Mangerton',
    'Palm Cove',
    'Sisters Beach',
    'Maianbar',
    'Norlane',
    'Bulimba',
    'New Farm',
    'Norman Park',
    'Hawthorne',
    'Maroochydore',
    'Slade Point',
    'Point Lookout',
    'Bellara',
    'Holloways Beach',
    'Bonbeach',
    'McCrae',
    'Laverton',
    'North Brighton',
    'Bellevue Hill',
    'Rose Bay',
    'Paddington',
    'Ultimo',
    'Lilyfield',
    'Drummoyne',
    'Greenwich',
    'Hunters Hill',
    'Longueville',
    'Northwood',
    'Cammeray',
    'Northbridge',
    'Seaforth',
    'Balgowlah Heights',
    'Clontarf',
    'Neutral Bay',
    'Point Piper',
    'Darling Point',
    'Dover Heights',
    'Randwick',
    'Clovelly',
    'Bronte',
    'Bondi',
    'Gladesville',
    'Forestville',
    'Brookvale',
    'Curl Curl',
    'Dee Why',
    'Umina Beach',
    'Blairgowrie',
    'Airport West',
    'Niddrie',
    'Gladstone Park',
    'Palmerston',
    'Tom Price',
    'Hayman Island',
    'Hervey Bay',
    'Yulara',
    'Moranbah',
    'Heyfield',
    'Blue Mountains',
    'Kaleen',
    'Karratha',
    'Balwyn',
    'Biggera Waters',
    'Hillarys',
    'Kingsford',
    'Klemzig',
    'Maidstone',
    'Osborne Park',
    'Seven Mile Beach',
    'Salamander Bay',
    'Magenta',
    'Wolli Creek',
    'Corlette',
    'Burswood',
    'Howrah',
    'Clifton Beach',
    'Montagu Bay',
    'Springfield Lakes',
    'Attwood',
    'Alexandra Headland',
    'North Narrabeen',
    'Toowoon Bay',
    'Joondalup',
    'Arncliffe',
    'Lovedale',
    'Middlemount',
    'East Lismore',
    'Clarkson',
    'Currambine',
    'Heathridge',
    'Paradise Point',
    'Girraween',
    'Lord Howe Island',
    'Mount Buller',
    'Herston',
    'Kelvin Grove',
    'Bowen Hills',
    'Taringa',
    'Chapel Hill',
    'Inala',
    'Mount Lawley',
    'Main Beach',
    'Athelstone',
    'Nundah',
    'Toombul',
    'Lutwyche',
    'Wooloowin',
    'Banyo',
    'Nudgee',
    'Zillmere',
    'Taigum',
    'Carseldine',
    'Bracken Ridge',
    'Brendale',
    'Kallangur',
    'Mango Hill',
    'North Lakes',
    'West End',
    'South Bank',
    'Highgate Hill',
    'Woolloongabba',
    'Dutton Park',
    'Milton',
    'Auchenflower',
    'Indooroopilly',
    'Mount Ommaney',
    'Macgregor',
    'Fortitude Valley',
    'Clayfield',
    'Chermside',
    'Ferny Grove',
    'Keperra',
    'The Gap',
    'Vincentia',
    'Fitzgibbon',
    'Zilzie',
    'Canada Bay',
    'Fairfield West',
    'Willoughby',
    'Forresters Beach',
    'Wongawallan',
    'Tamborine Mountain',
    'Peregian Beach',
    'Medindie',
    'Goolwa Beach',
    'Goolwa South',
    'North Ryde',
    'Huntingwood',
    'Caroline Springs',
    'Hoppers Crossing',
    'Rouse Hill',
    'Logan City',
    'Carindale',
    'Parramatta',
    'Macquarie',
    'Ferntree Gully',
    'Kewarra Beach',
    'Cooroy Mountain',
    'Narbethong',
    'Doonan',
    'Marcus Beach',
    'Fullarton',
    'Smiths Lake',
    'Mollymook',
    'South Durras',
    'Kurrimine Beach',
    'Shell Cove',
    'Burrill Lake',
    'Glenning Valley',
    'Stanwell Tops',
    'Dawesville',
    'Jabiru',
    'Adelaide Hills',
    'Fountain Gate',
    'Canning Vale',
    'Varsity Lakes',
    'Roselands',
    'Oxenford',
    'Glenmore Park',
    'Southbank',
    'St Kilda East',
    'Golden Beach',
    'Sanctuary Point',
    'Anglers Reach',
    'Docklands',
    'Hollywell',
    'Shoalhaven Heads',
    'Mermaid Beach',
    'Helensvale',
    'Daleys Point',
    'West Gosford',
    'Suffolk Park',
    'Ringtail Creek',
    'Mount Annan',
    'Watanobbi',
    'Miami',
    'Shoalwater',
    'Melton West',
    'Kurunjang',
    'Ashwood',
    'Elwood',
    'Box Hill South',
    'Blackburn',
    'Canterbury',
    'Hawthorn East',
    'Balwyn North',
    'Mont Albert North',
    'Box Hill North',
    'Blackburn North',
    'Blackburn South',
    'Chadstone',
    'East Malvern',
    'Oakleigh South',
    'Caulfield South',
    'Brighton East',
    'Hampton East',
    'Aspendale Gardens',
    'Booval',
    'North Booval',
    'Basin Pocket',
    'Brassall',
    'Silkstone',
    'Blackstone',
    'Eastern Heights',
    'Raceview',
    'Kholo',
    'Flinders View',
    'Deebing Heights',
    'Yamanto',
    'Redbank Plains',
    'Collingwood Park',
    'Bunjurgen',
    'Moogerah',
    'Woody Point',
    'Kippa-Ring',
    'Rothwell',
    'Kurwongbah',
    'Labrador',
    'Golden Point',
    'Delacombe',
    'Bonshaw',
    'Mount Helen',
    'Saint Albans',
    'Rozelle',
    'St Leonards',
    'Mt Coolum',
    'Saint Kilda West',
    'Eleebana',
    'Summer Hill',
    'Whites Valley',
    'The Entrance North Peninsula Natural Area',
    'Campwin Beach',
    'Churchill',
    'Alligator Creek',
    'Blue Mountain Heights',
    'Bogangar',
    'Bohle Plains',
    'Bonny Hills',
    'Brightwaters',
    'Burrum Heads',
    'Bushland Beach',
    'Cabarita',
    'Cooloola Cove',
    'Craiglie',
    'Craignish',
    'Hawkesbury Heights',
    'Highfields',
    'Deeragun',
    'Dodges Ferry',
    'Dundowran Beach',
    'Estella',
    'Fishermans Paradise',
    'Golden Bay-Singleton',
    'Grasmere',
    'Innes Park',
    'Jacobs Well',
    'Jensen',
    'Karalee',
    'Karana Downs',
    'Killara',
    'Launching Place',
    'Marcoola',
    'Maslin Beach',
    'McGraths Hill',
    'Mirrabooka',
    'Old Erowal Bay',
    'Tannum Sands',
    'Perisher Village',
    'River Heads',
    'Safety Beach',
    'Saunders Beach',
    'Seahampton',
    'Silverdale',
    'Silverwater',
    'South Golden Beach',
    'Saint Andrews Beach',
    'The Junction',
    'The Palms',
    'Tura Beach',
    'Turners Beach',
    'Windella Downs',
    'Windermere Park',
    'Withcott',
    'Wongaling Beach',
    'Murarrie',
    'North Altona',
    'Balcatta',
    'Central Coast',
    'Chain Valley Bay',
    'Coleambally',
    'Bateau Bay',
    'Bawley Point',
    'Agnes Banks',
    'Alice River',
    'Bonnells Bay',
    'Bowen Mountain',
    'Cabramurra',
    'Cowan',
    'Fingal Bay',
    'Flying Fish Point',
    'Freemans Reach',
    'Gelorup',
    'Gillieston Heights',
    'Glossodia',
    'Green Point',
    'Gulmarrad',
    'Gwandalan',
    'Hamilton Island',
    'Mount Low',
    'Hope Island',
    'Horseshoe Bay',
    'Howard Springs',
    'Indented Head',
    'Kurmond',
    'Lake Cathie',
    'Lake Munmorah',
    'Lemon Tree Passage',
    'Lennox Head',
    'Leschenault',
    'Londonderry',
    'Malua Bay',
    'Mannering Park',
    'Marsden Park',
    'McMasters Beach',
    'Mudjimba',
    'Nathalia',
    'Ningi',
    'North Arm Cove',
    'Ocean Shores',
    'Orchard Hills',
    'Orient Point',
    'Sussex Inlet',
    'Tapitallee',
    'Paraburdoo',
    'Robinvale',
    'Sandstone Point',
    'Sellicks Beach',
    'Shoal Point',
    'South West Rocks',
    'Wickham',
    'Wooloweyah',
    'Yellow Rock',
    'Yunderup',
    'Leederville',
    'Doveton',
    'Endeavour Hills',
    'Sefton',
    'Moorebank',
    'Baw Baw Village',
    'Turrella',
    'Montello',
    'Thurgoona',
    'Calala',
    'Acacia Gardens',
    'Glenfield Park',
    'Adamstown Heights',
    'Alfords Point',
    'Argenton',
    'Surrey Downs',
    'Ashbury',
    'Nailsworth',
    'Holt',
    'Barangaroo',
    'Hocking',
    'Bithramere',
    'Peterhead',
    'Tascott',
    'Shailer Park',
    'Ashcroft',
    'Ashmont',
    'Glenalta',
    'Blue Haven',
    'Bonnyrigg Heights',
    'Bossley Park',
    'White Gum Valley',
    'Clarendon Vale',
    'Woorree',
    'Kellyville',
    'Yalyalup',
    'Catherine Field',
    'Cook',
    'Stanford Merthyr',
    'Llandilo',
    'Tapping',
    'Seabrook',
    'Belimbla Park',
    'Casuarina',
    'Canley Vale',
    'Wadalba',
    'Clarenza',
    'Daw Park',
    'Willawong',
    'Denistone East',
    'Bourkelands',
    'St Helens Park',
    'St Ives Chase',
    'Winmalee',
    'Lamb Island',
    'Kiels Mountain',
    'Horsley',
    'East Killara',
    'Coochiemudlo Island',
    'Erskine Park',
    'Stratheden',
    'Craigmore',
    'Bow Bowing',
    'Berrimah',
    'Dolphin Heads',
    'Breakfast Point',
    'Frenchs Forest',
    'Girards Hill',
    'Wanguri',
    'Moulden',
    'Idalia',
    'Googong',
    'Torrens Park',
    'Stretton',
    'Grose Vale',
    'Harrington Park',
    'Tiwi',
    'Allenstown',
    'Cumberland Park',
    'Busby',
    'Abbey',
    'Marks Point',
    'Geebung',
    'Kentlyn',
    'Metford',
    'Maryland',
    'Taylors Lakes',
    'Banksia Park',
    'Rapid Creek',
    'Louth Park',
    'Gnangara',
    'Macquarie Park',
    'Maraylya',
    'Centenary Heights',
    'Woodroffe',
    'Calwell',
    'Pagewood',
    'Kuluin',
    'City',
    'Farrer',
    'Cherrybrook',
    'Evanston',
    'Forde',
    'Tatton',
    'Lyneham',
    'Kensington Park',
    'Woodcroft',
    'Menora',
    'Russell Lea',
    'Pooraka',
    'Fingal Head',
    'Bokarina',
    'Chifley',
    'Naremburn',
    'Plumpton',
    'Bellfield',
    'North Rocks',
    'Roxburgh Park',
    'Old Guildford',
    'Orangeville',
    'Ourimbah',
    'Underwood',
    'Mitchell Park',
    'Phegans Bay',
    'Clifton Hill',
    'Waratah West',
    'Jamboree Heights',
    'Goomboorian',
    'The Narrows',
    'Prestons',
    'Robin Hill',
    'Wamboin',
    'Voyager Point',
    'Warwick Farm',
    'Watsonia',
    'Warriewood',
    'Russell Vale',
    'Moil',
    'Warilla',
    'Scotland Island',
    'Shortland',
    'Beeliar',
    'Cowandilla',
    'Dharruk',
    'Tanilba Bay',
    'Werrington',
    'Winston Hills',
    'Wetherill Park',
    'Ardeer',
    'Southside',
    'Yarravel',
    'Westleigh',
    'Alphington',
    'Whalan',
    'Edensor Park',
    'Elanora Heights',
    'Avondale Heights',
    'Lynbrook',
    'Ellis Lane',
    'Welby',
    'Eveleigh',
    'Wentworth Point',
    'Fern Bay',
    'West Beach',
    'Payneham South',
    'Norman Gardens',
    'Ridgehaven',
    'Bayview Heights',
    'Kurralta Park',
    'Toorak Gardens',
    'Windradyne',
    'Ivanhoe',
    'Woodberry',
    'Woongarrah',
    'Hillcrest',
    'Fairview Park',
    'Kangaroo Ground',
    'Croydon Park',
    'Heatherbrae',
    'Drewvale',
    'Westlake',
    'Collinswood',
    'Manifold Heights',
    'Mickleham',
    'Greenacres',
    'Botanic Ridge',
    'Turner',
    'Oakden',
    'Colonel Light Gardens',
    'Nichols Point',
    'Jackass Flat',
    'Henley Beach South',
    'Davoren Park',
    'Redan',
    'Glenside',
    'Strathmore Heights',
    'Banksia Grove',
    'Orelia',
    'Macquarie Hills',
    'Waterways',
    'Golden Bay',
    'Gwelup',
    'Merewether',
    'Jolimont',
    'Magill',
    'Wyndham Vale',
    'Ballajura',
    'Maryville',
    'Wynn Vale',
    'Ardross',
    'Noranda',
    'Baskerville',
    'Cambridge Gardens',
    'Piara Waters',
    'Willetton',
    'Brigadoon',
    'Princes Hill',
    'Green Valley',
    'Prospect Vale',
    'Bungalow',
    'Youngtown',
    'East Side',
    'Belvedere',
    'Cashmere',
    'Winnellie',
    'Fadden',
    'Page',
    'Cotswold Hills',
    'Teneriffe',
    'East Deep Creek',
    'Glenwood',
    'Heathwood',
    'Highland Park',
    'Pemulwuy',
    'Petersham',
    'Kings Park',
    'Hyde Park',
    'Heritage Park',
    'Travancore',
    'Panorama',
    'Raby',
    'St Andrews',
    'Carramar',
    'Macleay Island',
    'Newborough',
    'Kalkie',
    'Punchbowl',
    'Lake Gardens',
    'Ascot Park',
    'Newcomb',
    'Para Hills',
    "O'Connor",
    'Rushcutters Bay',
    'Edwardstown',
    'Andrews Farm',
    'Railway Estate',
    "O'Sullivan Beach",
    'Bellevue Heights',
    'Broadview',
    'Petrie Terrace',
    'Clearview',
    'Redridge',
    'Rasmussen',
    'Rosemount',
    'Sheldon',
    'Rippleside',
    'Boronia Heights',
    'Glen Osmond',
    'Usher',
    'Seaholme',
    'Black Forest',
    'Tamarama',
    'Wagstaffe',
    'Athol Park',
    'Bowden',
    'Brahma Lodge',
    'Dover Gardens',
    'Bundall',
    'Hermit Park',
    'South Kingsville',
    'Yennora',
    'Aberfeldie',
    'Merrimac',
    'Seacombe Gardens',
    'Molendinar',
    'Stonyfell',
    'Currajong',
    'St Agnes',
    'Douglas',
    'Millbank',
    'Rostrevor',
    'Alexander Heights',
    'Carlisle',
    'Coodanup',
    'Ottoway',
    'Atwell',
    'Aveley',
    'Mount Warren Park',
    'Balga',
    'Helena Valley',
    'Cape Woolamai',
    'Karloo',
    'Clarinda',
    'Ridgewood',
    'Augustine Heights',
    'Lakelands',
    'Dallas',
    'Leeming',
    'Delahey',
    'Parkwood',
    'Downer',
    'Munno Para West',
    'Drumcondra',
    'Ridleyton',
    'North Lake',
    'Ormond',
    'Upper Kedron',
    'Wishart',
    'Pioneer Bay',
    'Stake Hill',
    'South Hedland',
    'Skye',
    'Sunset Strip',
    'Deep Bay',
    'Geilston Bay',
    'Havenview',
    'Trevallyn',
    'St Georges',
    'Quoiba',
    'Rosetta',
    'Tranmere',
    'Geographe',
    'Pasadena',
    'Parrearra',
    'Kambah',
    'Watson',
    'Yarralumla',
    'Queens Park',
    'Edens Landing',
    'Paechtown',
    'Redwood Park',
    'Banks',
    'Innaloo',
    'Cannon Hill',
    'Park Holme',
    'Carina Heights',
    'Pennington',
    'Dickson',
    'Madeley',
    'Booragoon',
    'Glynde',
    'Christie Downs',
    'Abbotsbury',
    'Rossmoyne',
    'Airds',
    'Doubleview',
    'Ambarvale',
    'Adamstown',
    'Austral',
    'Balcolyn',
    'Bonnyrigg',
    'Mundingburra',
    'Seville Grove',
    'Barden Ridge',
    'Birmingham Gardens',
    'Bardwell Park',
    'Beacon Hill',
    'Wondunna',
    'Belmore',
    'Woodpark',
    'College Park',
    'Beverly Hills',
    'Murrumba Downs',
    'Heatherton',
    'Glendalough',
    'Blakeview',
    'Birchgrove',
    'Blairmount',
    'Ellen Grove',
    'Carrara',
    'Hilton',
    'Booragul',
    'North Ward',
    'Lota',
    'Underdale',
    'Cabramatta',
    'Everton Hills',
    'Cameron Park',
    'Buttaba',
    'Elanora',
    'Meadow Springs',
    'Campsie',
    'Crestwood',
    'Canley Heights',
    'Carss Park',
    'Cartwright',
    'Chelmer',
    'Arundel',
    'Warradale',
    'Castlereagh',
    'Casula',
    'Stepney',
    'Cattai',
    'Bella Vista',
    'Carwoola',
    'Caves Beach',
    'Cecil Hills',
    'Beechboro',
    'Chester Hill',
    'Knoxfield',
    'Kent Town',
    'Chambers Flat',
    'Chiswick',
    'Claremont Meadows',
    'Ashmore',
    'Colyton',
    'Banksia Beach',
    'Barellan Point',
    'Copacabana',
    'Hawthorndene',
    'Cranebrook',
    'Hebersham',
    'Coes Creek',
    'Champion Lakes',
    'Aroona',
    'Double Bay',
    'Birrong',
    'Bishops Bridge',
    'Dudley',
    'Bligh Park',
    'Eagle Vale',
    'Brookdale',
    'Little Mountain',
    'Bardon',
    'Edgeworth',
    'Wakerley',
    'Elizabeth Bay',
    'Emerton',
    'New Beith',
    'Erskineville',
    'Eschol Park',
    'Pacific Paradise',
    'Ainslie',
    'Farmborough Heights',
    'Five Dock',
    'Fountaindale',
    'Eastlakes',
    'Rosslea',
    'Holland Park West',
    'Huntingdale',
    'Gaven',
    'Tanawha',
    'Glendenning',
    'Tanah Merah',
    'Westcourt',
    'Wulguru',
    'Greenacre',
    'Greenfield Park',
    'Beulah Park',
    'Eden Hills',
    'Regency Park',
    'Glandore',
    'Greystanes',
    'Halekulani',
    'Hammondville',
    'Morphettville',
    'Mariginiup',
    'Newton',
    'Haywards Bay',
    'Vista',
    'Horningsea Park',
    'Hoxton Park',
    'Ellenbrook',
    'Hurlstone Park',
    'Islington',
    'Connolly',
    'Lockridge',
    'Jerrabomberra',
    'Alderley',
    'Glen Forrest',
    'Kanahooka',
    'Greenfields',
    'Hamilton Hill',
    'Karabar',
    'Norville',
    'Kariong',
    'Alexandra Hills',
    'Kilaben Bay',
    'Jamisontown',
    'Nollamara',
    'Kingsgrove',
    'Hobartville',
    'Sinagra',
    'Lavender Bay',
    'Liberty Grove',
    'Jandakot',
    'Lalor Park',
    'Langford',
    'Bellbowrie',
    'Lathlain',
    'Leda',
    'Leonay',
    'Dernancourt',
    'Lethbridge Park',
    'Callaghan',
    'Fraser',
    'Mount Richon',
    'Leppington',
    'Millers Point',
    'Bakewell',
    'Padbury',
    'Macquarie Fields',
    'Pearsall',
    'Park Grove',
    'Primrose Sands',
    'Lynwood',
    'Marayong',
    'Alawa',
    'Mardi',
    'East Victoria Park',
    'McMahons Point',
    'Willagee',
    'Giralang',
    'Milperra',
    'Soldiers Hill',
    'Middle Cove',
    'Mawson',
    'Tecoma',
    'Minchinbury',
    'Eli Waters',
    'Mount Pritchard',
    'Palmyra',
    'Middle Park',
    'Niagara Park',
    'Stratton',
    'Nunderi',
    'Oxley Park',
    'Oxley Vale',
    'Bentley Park',
    'Mayfield East',
    'Belli Park',
    'Eastgardens',
    'Oakville',
    'Berserker',
    'Oak Flats',
    'Birtinya',
    'Narwee',
    'Panania',
    'Point Frederick',
    'Mile End',
    'Smeaton Grange',
    'Claymore',
    'Putney',
    'Tootgarook',
    'Condell Park',
    'Berrinba',
    'Sadleir',
    'Ropes Crossing',
    'Rosemeadow',
    'Ruse',
    'Saratoga',
    'Viveash',
    'Kawana',
    'Kedron',
    'Burns Beach',
    'Brinsmead',
    'South Hurstville',
    'Webberton',
    'Glengowrie',
    'Wyoming',
    'Branyan',
    'Speers Point',
    'Firle',
    'Summerland Point',
    'Hewett',
    'Calamvale',
    'Burdell',
    'Yagoona',
    'Zetland',
    'Shelly Beach',
    'Jubilee Pocket',
    'Bell Park',
    'Bell Post Hill',
    'Bells Beach',
    'Enoggera',
    'Aubin Grove',
    'Quarry Hill',
    'Albanvale',
    'Battery Hill',
    'Coombabah',
    'Coral Cove',
    'Gillen',
    'Park Avenue',
    'Cooriemungle',
    'Don Valley',
    'Gunn',
    'Park Orchards',
    'Hayborough',
    'Heckenberg',
    'Hove',
    'Tregear',
    'Flora Hill',
    'Earlville',
    'Golden Square',
    'Annerley',
    'Currans Hill',
    'Durack',
    'Highton',
    'Koongamia',
    'Kealba',
    'Daruka',
    'St Morris',
    'Jacana',
    'Jan Juc',
    'Warners Bay',
    'Junortoun',
    'Larapinta',
    'Dulwich Hill',
    'Yarrambat',
    'Sinnamon Park',
    'Wiley Park',
    'Hillvue',
    'Mount Sheridan',
    'Viewbank',
    'Aeroglen',
    'Vale Park',
    'Bellbird Park',
    'Gulliver',
    'Albert Park',
    'Garbutt',
    'Brookwater',
    'Buddina',
    'Nakara',
    'Crestmead',
    'Doolandella',
    'Fig Tree Pocket',
    'Munruben',
    'Frenchville',
    'Griffin',
    'Highvale',
    'Joyner',
    'Kepnock',
    'West Croydon',
    'Kuraby',
    'Lockrose',
    'Koongal',
    'Logan Central',
    'McDowall',
    'Landers Shoot',
    'Lake Macdonald',
    'Maroochy River',
    'Marsden',
    'Meadowbrook',
    'Minyama',
    'Mooroobool',
    'Manunda',
    'Forest Lodge',
    'Chirnside Park',
    'Moorooka',
    'Mundoolun',
    'Coolaroo',
    'New Auckland',
    'Karawara',
    'Georges Hall',
    'Risdon Park',
    'Eaglemont',
    'Oak Valley',
    'Pallara',
    'Ocean View',
    'Pacific Pines',
    'Rosewater',
    'Paget',
    'Pelican Waters',
    'Robina',
    'Runaway Bay',
    'Runcorn',
    'Rural View',
    'Mount Glorious',
    'Tinana',
    'Tingalpa',
    'Hamlyn Terrace',
    'Hillsdale',
    'Verrierdale',
    'Hampstead Gardens',
    'Warana',
    'Warner',
    'Wilsonton',
    'Wandi',
    'McKinnon',
    'Meadow Heights',
    'Cooee Bay',
    'Torrens',
    'Wurtulla',
    'Kearns',
    'Algester',
    'Kings Langley',
    'Fulham Gardens',
    'Flagstaff Hill',
    'Flinders Park',
    'Greenwith',
    'Gulfview Heights',
    'Huntfield Heights',
    'Whitfield',
    'Kidman Park',
    'Lockleys',
    'McCracken',
    'Westminster',
    'Paralowie',
    'Lansvale',
    'Nowergup',
    'Sheidow Park',
    'Urrbrae',
    'West Lakes',
    'Tallai',
    'Westbourne Park',
    'Strathdale',
    'Baldivis',
    'Bertram',
    'Banjup',
    'Bayonet Head',
    'Beckenham',
    'Bibra Lake',
    'Broadwood',
    'Cable Beach',
    'Driver',
    'Cloverdale',
    'Camillo',
    'Carey Park',
    'Parkinson',
    'Hackney',
    'Carine',
    'Cockburn Central',
    'Girrawheen',
    'Churchlands',
    'Coolbellup',
    'Cooloongup',
    'Lurnea',
    'Dalyellup',
    'Dianella',
    'Duncraig',
    'Djugun',
    'Edgewater',
    'Forrestfield',
    'Falcon',
    'Forrestdale',
    'Gidgegannup',
    'Hammond Park',
    'Halls Head',
    'Hannans',
    'Hazelmere',
    'High Wycombe',
    'Kingsley',
    'Yallambie',
    'Kardinya',
    'Karrinyup',
    'Adare',
    'Madora Bay',
    'Kewdale',
    'Martin',
    'Millars Well',
    'Melrose Park',
    'Morley',
    'Mount Hawthorn',
    'Mount Nasura',
    'Murdoch',
    'Ocean Reef',
    'Pegs Creek',
    'Samson',
    'Secret Harbour',
    'Salter Point',
    'Success',
    'Tuart Hill',
    'Thornlie',
    'Waikiki',
    'Wannanup',
    'Winthrop',
    'Mount Hutton',
    'Yangebup',
    'Yokine',
    'Battery Point',
    'Blackstone Heights',
    'Chigwell',
    'Mount Austin',
    'Mount Riverview',
    'Derwent Park',
    'Tolmans Hill',
    'Honeywood',
    'Kings Meadows',
    'Lenah Valley',
    'Kilburn',
    'Mount Nelson',
    'Rocherlea',
    'Rosny Park',
    'Rosny',
    'Coconut Grove',
    'Shearwater',
    'Kilkenny',
    'Humpty Doo',
    'Jingili',
    'Malak',
    'Millner',
    'Carters Ridge',
    'Casey',
    'Felixstow',
    'Barton',
    'Braddon',
    'Chapman',
    'Conder',
    'Charnwood',
    'Curtin',
    'Dunlop',
    'Florey',
    'Evatt',
    'Greenway',
    'Landsdale',
    'Harrison',
    'Hackett',
    'Higgins',
    'Isaacs',
    'Isabella Plains',
    'McKellar',
    'Nicholls',
    'Melba',
    'Ngunnawal',
    'Richardson',
    'Rivett',
    'Symonston',
    'Wanniassa',
    'Waramanga',
    'Centennial Park',
    'Bidwill',
    'Blair Athol',
    'Kearneys Spring',
    'Hinchinbrook',
    'Meadowbank',
    'Llanarth',
    'Penrose',
    'Valentine',
    'Bray Park',
    'Shalvey',
    'Parafield Gardens',
    'Camira',
    'Hillside',
    'Kangaroo Point',
    'Wavell Heights',
    'Red Hill',
    'Spring Mountain',
    'Heatley',
    'Davenport',
    'Hopeland',
    'Shelley',
    'Wakeley',
    'Mowbray',
    'The Vines',
    'Chisholm',
    'Fisher',
    'Flynn',
    'Holder',
    'Bonogin',
    'Spence',
    'Ooralea',
    'St Clair',
    'Ripponlea',
    'Mawson Lakes',
    'Sumner',
    'Scullin',
    'The Ponds',
    'Holden Hill',
    'Aranda',
    'Varroville',
    'Willmot',
    'Holmview',
    'Graceville',
    'Hemmant',
    'Woodrising',
    'Bunya',
    'Hendra',
    'Middle Ridge',
    'Crace',
    'Kirwan',
    'Mons',
    'Valley View',
    'Beldon',
    'Rosanna',
    'St James',
    'Floreat',
    'Braybrook',
    'Hillbank',
    'Currimundi',
    'Jane Brook',
    'Highgate',
    'Cairnlea',
    'California Gully',
    'Bundoora',
    'Melville',
    'Munster',
    'Burton',
    'Patterson Lakes',
    'Millbridge',
    'Marmion',
    'Lammermoor',
    'Duffy',
    'Southern River',
    'Joslin',
    'Lonsdale',
    'Mansfield Park',
    'Taperoo',
    'Narrabundah',
    "O'Malley",
    'St Peters',
    'Dudley Park',
    'Hughes',
    'Bayview',
    'Mission Beach',
    'Beaumont Hills',
    'Warabrook',
    'Milsons Point',
    'Malaga',
    'Kunda Park',
    'Cardigan Village',
    'Aldinga Beach',
    'North Rothbury',
    'Windsor Downs',
    'Wyee Point',
    'Yallourn North',
    'Bondi Junction',
    'Oxford Falls',
    'Nungurner',
    'Jesmond',
    'Katandra West',
    'Killarney Vale',
    'Risdon Vale',
    'Wangara',
    'Lockwood South',
    'Wemen',
    'Wyangala',
    'Allawah',
    'Callala Beach',
    'Blacksmiths',
    'Yarlana',
    'Brukunga',
    'Wedgefield',
    'Lesmurdie',
    'Yarramundi',
    'Girralong',
    'Beecroft',
    'Williams Landing',
    'Bindoon',
    'Kununoppin',
    'Nickol',
    'Eugenana',
    'Camdale',
    'Moncrieff',
    'North Willoughby',
    'Lane Cove North',
    'Bells Creek',
    'Largs North',
    'Christies Beach',
    'Main Ridge',
    'Seaford Meadows',
    'Seaford Rise',
    'Seaford Heights',
    'Yatte Yattah',
    'Leslie',
    'Rose Park',
    'Sanctuary Cove',
    'Nulkaba',
    'Clear Mountain',
    'Nathan',
    'Chermside West',
    'Everton Park',
    'Gordon Park',
    'Nudgee Beach',
    'Stafford',
    'Stafford Heights',
    'Wilston',
    'Archerfield',
    'Carole Park',
    'Coopers Plains',
    'Forest Lake',
    'Greenslopes',
    'Karawatha',
    'Mackenzie',
    'Mount Gravatt East',
    'Richlands',
    'Seventeen Mile Rocks',
    'Sunnybank Hills',
    'Upper Mount Gravatt',
    'Yeerongpilly',
    'Yeronga',
    'Camp Hill',
    'Chandler',
    'Manly West',
    'Morningside',
    'Ransome',
    'Wynnum West',
    'Kenmore Hills',
    'Riverhills',
    'Coalfalls',
    'Sadliers Crossing',
    'Springfield',
    'Springfield Central',
    'Tivoli',
    'South Ripley',
    'Cedar Vale',
    'Logan Reserve',
    'North Maclean',
    'Rochedale South',
    'South Maclean',
    'Waterford West',
    'Yarrabilba',
    'Russell Island',
    'Thorneside',
    'Arana Hills',
    'Caboolture South',
    'Eatons Hill',
    'Ferny Hills',
    'Samford Valley',
    'Wights Mountain',
    'South Wharf',
    'Heidelberg Heights',
    'Melbourne Airport',
    'Westmeadows',
    'Yuroke',
    'Essendon Fields',
    'Essendon North',
    'Keilor East',
    'Coburg North',
    'Gowanbrae',
    'Hadfield',
    'Pascoe Vale South',
    'North Warrandyte',
    'Bayswater North',
    'Croydon Hills',
    'Croydon South',
    'Kilsyth South',
    'Warranwood',
    'Vermont South',
    'Narre Warren East',
    'Cranbourne East',
    'Cranbourne North',
    'Cranbourne West',
    'Narre Warren South',
    'Dandenong North',
    'Noble Park North',
    'Bangholme',
    'Frankston North',
    'Frankston South',
    'Sandhurst',
    'Langwarrin South',
    'Rosebud West',
    'Keilor Downs',
    'Keilor Lodge',
    'Keilor Park',
    'Sunshine North',
    'Sunshine West',
    'Altona Meadows',
    'Williamstown North',
    'Kingsville',
    'Seddon',
    'West Footscray',
    'Burnside Heights',
    'Taylors Hill',
    'Ravenhall',
    'Eynesbury',
    'Arndell Park',
    'Allambie Heights',
    'Balmain East',
    'Banksia',
    'Bardwell Valley',
    'Belfield',
    'Berala',
    'Berowra Heights',
    'Beverley Park',
    'Bexley North',
    'Bilgola Plateau',
    'Blackett',
    'Bondi Beach',
    'Burwood Heights',
    'Cabramatta West',
    'Cambridge Park',
    'Camden South',
    'Caringbah South',
    'Carnes Hill',
    'Castle Cove',
    'Chatswood West',
    'Church Point',
    'Colebee',
    'Collaroy Plateau',
    'Concord West',
    'Constitution Hill',
    'Cremorne Point',
    'Dawes Point',
    'Denham Court',
    'Denistone',
    'Denistone West',
    'Dolls Point',
    'Duffys Forest',
    'Dundas Valley',
    'East Lindfield',
    'East Ryde',
    'Edmondson Park',
    'Emu Heights',
    'Ermington',
    'Fairfield East',
    'Fairfield Heights',
    'Fairlight',
    'Gledswood Hills',
    'Glen Alpine',
    'Gregory Hills',
    'Guildford West',
    'Harris Park',
    'Hassall Grove',
    'Henley',
    'Homebush West',
    'Horsley Park',
    'Hurstville',
    'Ingleside',
    'Kellyville Ridge',
    'Kemps Creek',
    'Killarney Heights',
    'Renown Park',
    'Kogarah Bay',
    'Brompton',
    'Kurraba Point',
    'Kyle Bay',
    'Lane Cove West',
    'Macquarie Links',
    'Marsfield',
    'Mays Hill',
    'Merrylands West',
    'Middle Dural',
    'Middleton Grange',
    'Miller',
    'Minto Heights',
    'Narellan Vale',
    'Narraweena',
    'North Balgowlah',
    'North Bondi',
    'North Curl Curl',
    'North Epping',
    'North Manly',
    'North Strathfield',
    'North Wahroonga',
    'Old Toongabbie',
    'Oran Park',
    'Padstow Heights',
    'Parklea',
    'Picnic Point',
    'Potts Hill',
    'Ramsgate Beach',
    'Regentville',
    'Revesby Heights',
    'Rodd Point',
    'Roseville Chase',
    'Shanes Park',
    'South Coogee',
    'South Maroota',
    'South Penrith',
    'South Wentworthville',
    'South Windsor',
    'Spring Farm',
    'Stanhope Gardens',
    'Strathfield South',
    'Sydney Olympic Park',
    'Telopea',
    'Wareemba',
    'Werrington County',
    'Werrington Downs',
    'West Pennant Hills',
    'West Ryde',
    'Wheeler Heights',
    'Wollstonecraft',
    'Woronora Heights',
    'The Gardens',
    'Muirhead',
    'Karama',
    'Bellamack',
    'Coolalinga',
    'Berry Springs',
    'Erindale',
    'Frewville',
    'Glenunga',
    'Hazelwood Park',
    'Kensington Gardens',
    'Leabrook',
    'Linden Park',
    'Tusmore',
    'Hectorville',
    'Allenby Gardens',
    'Devon Park',
    'Henley Beach',
    'Semaphore Park',
    'Woodville North',
    'Woodville Park',
    'Woodville South',
    'Woodville West',
    'Bibaringa',
    'Evanston Gardens',
    'Evanston Park',
    'Glenelg East',
    'Glenelg North',
    'Somerton Park',
    'Clovelly Park',
    'Hallett Cove',
    'Oaklands Park',
    'Plympton Park',
    'Seacombe Heights',
    'South Plympton',
    'Trott Park',
    'Clarence Gardens',
    'Marden',
    'Payneham',
    'Royston Park',
    'Aberfoyle Park',
    'Coromandel East',
    'Noarlunga Downs',
    'Port Noarlunga South',
    'Munno Para',
    'Smithfield Plains',
    'Angle Park',
    'Ethelton',
    'Ferryden Park',
    'Gillman',
    'Glanville',
    'Semaphore South',
    'Walkley Heights',
    'Windsor Gardens',
    'Wingfield',
    'Woodville Gardens',
    'Sefton Park',
    'Rosenthal Heights',
    'Cavan',
    'Direk',
    'Edinburgh',
    'Green Fields',
    'Parafield',
    'Para Hills West',
    'Para Vista',
    'Modbury Heights',
    'Modbury North',
    'Clarence Park',
    'Millswood',
    'Unley Park',
    'Brooklyn Park',
    'Camden Park',
    'Keswick Terminal',
    'Marleston',
    'North Plympton',
    'Novar Gardens',
    'Torrensville',
    'Acton',
    'Bonner',
    'Coombs',
    'Wright',
    'Garran',
    'Alfred Cove',
    'Alkimos',
    'Bedford',
    'Brabham',
    'City Beach',
    'Coolbinia',
    'Crawley',
    'Cullacabardee',
    'Daglish',
    'Dayton',
    'East Cannington',
    'Eglinton',
    'Embleton',
    'Harrisdale',
    'Haynes',
    'Henderson',
    'Henley Brook',
    'Hilbert',
    'Joondanna',
    'Kallaroo',
    'Midvale',
    'Myaree',
    'Oakford',
    'Parmelia',
    'Rivervale',
    'South Fremantle',
    'South Guildford',
    'South Lake',
    'Wembley Downs',
    'West Leederville',
    'Acton Park',
    'Dowsing Point',
    'West Moonah',
    'Mount Stuart',
    'Broadbeach Waters',
    'Burleigh Waters',
    'Clear Island Waters',
    'Luscombe',
    'Mermaid Waters',
    'Mount Nathan',
    'Ormeau Hills',
    'Tallebudgera Valley',
    'South Yunderup',
    'Buff Point',
    'Calga',
    'Charmhaven',
    'Chittaway Bay',
    'Erina Heights',
    'Mount Elliot',
    'Noraville',
    'Tacoma South',
    'Tuggerawong',
    'Bar Beach',
    'Broadmeadow',
    'Cooks Hill',
    'Elermore Vale',
    'Hamilton East',
    'Hamilton North',
    'Kooragang',
    'Mayfield West',
    'Merewether Heights',
    'North Lambton',
    'Rankin Park',
    'Waratah',
    'Belmont North',
    'Bolton Point',
    'Cardiff South',
    'Coal Point',
    'Fennell Bay',
    'Floraville',
    'Garden Suburb',
    'Gateshead',
    'Glendale',
    'Jewells',
    'Marmong Point',
    'Tingira Heights',
    'Windale',
    'Bellbird Heights',
    'Mallabula',
    'Ashtonfield',
    'Bolwarra Heights',
    'Dicky Beach',
    'Diddillibah',
    'Forest Glen',
    'Moffat Beach',
    'Peregian Springs',
    'Diamond Valley',
    'Kiamba',
    'Bohle',
    'Mount Louisa',
    'Mount St John',
    'Boorooma',
    'Gobbagombalin',
    'Albion Park Rail',
    'Barrack Heights',
    'Barrack Point',
    'Bombo',
    'Cordeaux Heights',
    'East Corrimal',
    'Koonawarra',
    'Yallah',
    'Toolijooa',
    'Mount Warrigal',
    'Lake Wendouree',
    'Lucas',
    'Armstrong Creek',
    'Charlemont',
    'Hamlyn Heights',
    'Wandana Heights',
    'Rudds Gully',
    'The Dawn',
    'Summerhill',
    'Rangeville',
    'Darling Heights',
    'Hodgson Vale',
    'Top Camp',
    'Vale View',
    'Wilsonton Heights',
    'Taminda',
    'Cooroibah',
    'East Innisfail',
    'Sunshine Coast',
    'Dingo Beach',
    'Marangaroo',
    'Upper Caboollure',
    'Bellmere',
    'Lake Barrine',
    'Katherine East',
    'Vale of Clwydd',
    'Littleton',
    'South Lismore',
    'Mount Colah',
    'Wellstead',
    'Albion Park',
    'Meridian Plains',
    'Buderim',
    'Sippy Downs',
    'Coomera',
    'West Wodonga',
    'Kalamunda',
    'Currumbin',
    'Yatala',
    'Strathmore',
    'West Busselton',
    'Bardia',
    'Burpengary East',
    'Pottsville',
    'Manly Vale',
    'Gladstone Central',
    'Jordan Springs',
    'Culburra Beach',
    'East Devonport',
    'West Kempsey',
    'North Gosford',
    'Singleton Heights',
    'Svensson Heights',
    'North Coogee',
    'Sapphire Beach',
    'Bennett Springs',
    'The Hill',
    'Glenelg South',
    'Twin Waters',
    'Korora',
    'Risdon Park South',
    'Treeby',
    'Elizabeth Hills',
    'Bungarribee',
    'Romaine',
    'Boomerang Beach',
    'Hazelwood North',
    'Manningham',
    'Skennars Head',
    'Pie Creek',
    'Nowra Hill',
    'Caddens',
    'Kambalda East',
    'Kotara South',
    'Nanum',
    'Kooyong',
    'Cliftleigh',
    'Taroomball',
    'Stony Rise',
    'Kealy',
    'Murrays Beach',
    'West Kalgoorlie',
    'Renwick',
    'Broken Head',
    'Mount Vincent',
    'Welcome Creek',
    'White Peak',
    'Golden Gully',
    'Tugrah',
    'East Arm',
    'Wonglepong',
    'Ocean Vista',
    'Axe Creek',
    'Tarleton',
    'Sunday Creek',
    'Redbournberry',
    'Uriarra Village',
    'Cedar Brush Creek',
    'Gowrie Mountain',
    'Sunshine Beach',
    'Mount Warning',
    'Mountain Lagoon',
    'Tonsley',
    'James Creek',
    'Bora Ridge',
    'Lower Norton',
    'Dairymans Plains',
    'Barjarg',
    'Wallu',
    'Undullah',
    'Vaughan',
    'Kithbrook',
    'Faulkland',
    'Upper Copmanhurst',
    'March',
    'Hazelvale',
    'Blanche Harbor',
    'Panuara',
    'Hacklins Corner',
    'Mount Bruno',
    'Pastoria East',
    'Moleville Creek',
    'Wolgan Valley',
    'Lower Hotham',
    'Tothill Belt',
    'Staghorn Flat',
    'Driffield',
    'Enmore',
    'South Granville',
    'Holland Park',
    'Edinburgh North',
    'Ocean Grove',
  ],
  'Christmas Island': ['Flying Fish Cove'],
  'Marshall Islands': ['Majuro', 'Ajeltake', 'Ebaye'],
  'Federated States of Micronesia': [
    'Weno',
    'Tofol',
    'Tepat',
    'Malem Village',
    'Walung',
    'Nema',
    'Lelu',
  ],
  'Papua New Guinea': [
    'Wewak',
    'Wabag',
    'Vanimo',
    'Tari',
    'Rabaul',
    'Port Moresby',
    'Popondetta',
    'Pongani',
    'Panguna',
    'Paivara',
    'Nambaga',
    'Namatanai',
    'Mt Hagen',
    'Minj',
    'Mendi',
    'Madang',
    'Lorengau',
    'Lae',
    'Kupano',
    'Kundiawa',
    'Koroba',
    'Kokopo',
    'Kimbe',
    'Kikori',
    'Kieta',
    'Kerema',
    'Kavieng',
    'Goroka',
    'Efogi',
    'Daru',
    'Arawa',
    'Ambunti',
    'Aitape',
    'Alotau',
  ],
  'Solomon Islands': [
    'Tulagi',
    'Lenggakiki',
    'Honiara',
    'Gizo',
    'Buala',
    'Auki',
    'Niupani',
    'Lata',
    'Kirakira',
  ],
  Kiribati: ['Umwa Village', 'Tabwakea Village', 'Kanton Village'],
  Tuvalu: [
    'Tanrake Village',
    'Funafuti',
    'Asau Village',
    'Nukualofa Village',
    'Lolua Village',
    'Tokelau Village',
    'Teava Village',
    'Savave Village',
  ],
  Nauru: [
    'Yangor',
    'Uaboe',
    'Ronave',
    'Baitsi District',
    'Ijuw',
    'Arubo',
    'Anabar',
    'Yaren District',
    'Arijejen',
    'Arenibek',
    'Boe',
    'Menen',
    'Nibok',
  ],
  Vanuatu: ['Sola', 'Port Vila', 'Norsup', 'Luganville', 'Loltong', 'Isangel'],
  'New Caledonia': [
    'We',
    'Vao',
    'Touho',
    'Pont des Francais',
    'Paita',
    'Noumea',
    'Mont-Dore',
    'Moindou',
    'La Foa',
    'Koumac',
    'Kouaoua',
    'Kone',
    'Houailou',
    'Ghio',
    'Dumbea',
    'Bourail',
    'Bouloupari',
  ],
  'Norfolk Island': ['Longridge Station', 'Kingston', 'Burnt Pine'],
  'New Zealand': [
    'Wyndham',
    'Woodend',
    'Winchester',
    'Whitianga',
    'Whatawhata',
    'Whangarei Heads',
    'Whangaparaoa',
    'Whangamata',
    'Whakarongo',
    'Weymouth',
    'West Melton',
    'Wellsford',
    'Wellington',
    'Waverley',
    'Warkworth',
    'Ward',
    'Wanganui',
    'Wakefield',
    'Waiuku',
    'Waitoa',
    'Waitakere City',
    'Wairakei',
    'Waipara',
    'Waipapa',
    'Waiouru',
    'Waiotira',
    'Wainuiomata',
    'Waimauku',
    'Waikuku Beach',
    'Waikawa',
    'Waikanae',
    'Waikaia',
    'Waihola',
    'Waihi Beach',
    'Waiau',
    'Waharoa',
    'Wadestown',
    'Urenui',
    'Turangi',
    'Tuakau',
    'Trentham',
    'Tokoroa',
    'Tokomaru',
    'Tokanui',
    'Titahi Bay',
    'Tinui',
    'Timaru',
    'Te Miro',
    'Te Kowhai',
    'Te Kauwhata',
    'Te Anau',
    'Taupo',
    'Taupiri',
    'Tangimoana',
    'Tamahere',
    'Takapau',
    'Takaka',
    'Taita',
    'Tairua',
    'Swanson',
    'Springston',
    'Silverdale',
    'Sheffield',
    'Seddon',
    'Sanson',
    'Saint Heliers',
    'Ruawai',
    'Ruatoria',
    'Ruatangata West',
    'Rotherham',
    'Rolleston',
    'Riverhead',
    'Reporoa',
    'Renwick',
    'Reefton',
    'Raupunga',
    'Raumati Beach',
    'Ranfurly',
    'Rakaia',
    'Rai Valley',
    'Raglan',
    'Putorino',
    'Pungarehu',
    'Puketapu',
    'Pukerua Bay',
    'Pukehina',
    'Motueka',
    'Porirua',
    'Ponsonby',
    'Pongaroa',
    'Pokeno',
    'Point Chevalier',
    'Pleasant Point',
    'Pirongia',
    'Piopio',
    'Penrose',
    'Wanaka',
    'Pauatahanui',
    'Pauanui',
    'Patoka',
    'Parnell',
    'Pareora',
    'Paraparaumu Beach',
    'Paraparaumu',
    'Papamoa',
    'Palmerston North',
    'Paihia',
    'Paengaroa',
    'Oxford',
    'Owhango',
    'Oturehua',
    'Otorohanga',
    'Otautau',
    'Otane',
    'Orewa',
    'Oreti Plains',
    'Onga Onga',
    'Onewhero',
    'Omarama',
    'Omanawa',
    'Omakau',
    'Okoroire',
    'Ohope Beach',
    'Ohaupo',
    'Ohangai',
    'Northland',
    'Norsewood',
    'Ngatea',
    'Newstead',
    'New Plymouth',
    'Nelson',
    'Napier City',
    'Murupara',
    'Murchison',
    'Mount Somers',
    'Mount Pisa',
    'Mossburn',
    'Moerewa',
    'Milburn',
    'Methven',
    'Maungaturoto',
    'Matakohe',
    'Maraetai',
    'Manukau',
    'Mangere',
    'Mangawhai',
    'Manawaru',
    'Manaia',
    'Makarau',
    'Lower Shotover',
    'Lower Hutt',
    'Loburn',
    'Lincoln',
    'Lepperton',
    'Leigh',
    'Leeston',
    'Lake Tekapo',
    'Kurow',
    'Kumeu',
    'Kerikeri',
    'Kawerau',
    'Kawakawa',
    'Kauri',
    'Kaukapakapa',
    'Katikati',
    'Karori',
    'Kapiti',
    'Kaniere',
    'Kakaramea',
    'Kaiwaka',
    'Kaipara Flats',
    'Kaikoura',
    'Johnsonville',
    'Invercargill',
    'Hunterville',
    'Hikurangi',
    'Hikuai',
    'Hawkes Bay',
    'Hawea Flat',
    'Hawarden',
    'Havelock North',
    'Havelock',
    'Hastings',
    'Hanmer Springs',
    'Hamurana',
    'Hamilton',
    'Oban',
    'Glenorchy',
    'Glenfield',
    'Glen Eden',
    'Glenavy',
    'Franz Josef',
    'Foxton Beach',
    'Fordell',
    'Fairlie',
    'Eureka',
    'Edievale',
    'Edgecumbe',
    'East Tamaki',
    'Dunedin',
    'Drury',
    'Dovedale',
    'Dargaville',
    'Darfield',
    'Culverden',
    'Coromandel',
    'Collingwood',
    'Coalgate',
    'Clyde',
    'Christchurch',
    'Cheviot',
    'Charing Cross',
    'Carters Beach',
    'Burnham',
    'Bulls',
    'Buckland',
    'Brooklyn',
    'Brightwater',
    'Beachlands',
    'Awakeri',
    'Avondale',
    'Auckland',
    'Atiamuri',
    'Ashwick Flat',
    'Ashley Clinton',
    'Ashhurst',
    'Arthurs Point',
    'Amberley',
    'Albert Town',
    'Albany',
    'Ahipara',
    'Opunake',
    'Eltham',
    'Hawera',
    'Foxton',
    'Levin',
    'Otaki',
    'Porangahau',
    'Wairoa',
    'Gisborne',
    'Waipawa',
    'Masterton',
    'Hokitika',
    'Greymouth',
    'Westport',
    'Bluff',
    'Riverton',
    'Winton',
    'Red Beach',
    'Stanmore Bay',
    'Algies Bay',
    'Takapuna',
    'Tauranga',
    'Mount Maunganui',
    'Waitara',
    'Port Chalmers',
    'Mosgiel',
    'Milton',
    'Balclutha',
    'Paeroa',
    'Waihi',
    'Papakura',
    'Whakatane',
    'Opotiki',
    'Thames',
    'Whakekauri',
    'Waitangi West',
    'Waikanai',
    'Rakautahi',
    'Gore',
    'Queenstown',
    'Arrowtown',
    'Mataura',
    'Cromwell',
    'Alexandra',
    'Roxburgh',
    'Tapanui',
    'Twizel',
    'Lawrence',
    'Belleknowes',
    'Geraldine',
    'Waimate',
    'Oamaru',
    'Ashburton',
    'Temuka',
    'Rangiora',
    'Kaiapoi',
    'Opawa',
    'Lyttelton',
    'Akaroa',
    'Inglewood',
    'Stratford',
    'Feilding',
    'Marton',
    'Waikanae Beach',
    'Raetihi',
    'Ohakune',
    'Shannon',
    'Greytown',
    'Carterton',
    'Martinborough',
    'Featherston',
    'Mangakino',
    'Taihape',
    'Pahiatua',
    'Woodville',
    'Eketahuna',
    'Kaitaia',
    'Kaikohe',
    'Whangarei',
    'Ruakaka',
    'Helensville',
    'Takanini',
    'Pukekohe',
    'MANUREWA',
    'Glendowie',
    'New Lynn',
    'ONEHUNGA',
    'Ellerslie',
    'Epsom',
    'Kingsland',
    'Titirangi',
    'Kohimarama',
    'Meadowbank',
    'Remuera',
    'Henderson',
    'Grafton',
    'Te Kuiti',
    'Taumarunui',
    'Te Aroha',
    'Morrinsville',
    'Matamata',
    'Putaruru',
    'Cambridge',
    'Te Awamutu',
    'Rotorua',
    'Picton',
    'Blenheim',
    'Mangawhai Heads',
    'Snells Beach',
    'Island Bay',
    'Woburn',
    'Petone',
    'Epuni',
    'Upper Hutt',
    'Whitby',
    'Crofton Downs',
    'Wilton',
    'Te Puke',
    'Judea',
    'Dannevirke',
    'Bluff Hill',
    'Pirimai',
    'Waipukurau',
    'Ngaruawahia',
    'Temple View',
    'Huntly',
    'Tuki Tuki',
  ],
  Fiji: [
    'Suva',
    'Rakiraki',
    'Navua',
    'Nausori',
    'Nasinu',
    'Nadi',
    'Lautoka',
    'Ahau',
    'Udu',
    'Pacific Harbour',
  ],
  Cameroon: [
    'Yokadouma',
    'Yaoundé',
    'Tibati',
    'Sangmelima',
    'Ngaoundéré',
    'Meiganga',
    'Lolodorf',
    'Limbe',
    'Kumba',
    'Kribi',
    'Fifinda',
    'Douala',
    'Buea',
    'Bamenda',
    'Bafoussam',
    'Bafia',
  ],
  Senegal: [
    'Ziguinchor',
    'Tivaouane',
    'Thiès',
    'Tanaf',
    'Subikouroto',
    'Sokone',
    'Seme',
    'Sedhiou',
    'Saint-Louis',
    'Richard-Toll',
    'Pikine',
    'Ouro Sogui',
    'Ouinndiou',
    'Ogo',
    'Nioro du Rip',
    'Ndioum',
    'Nangar',
    'Mbake',
    'Matam',
    'Marsassoum',
    'Louga',
    'Koussanar',
    'Koungheul',
    'Kolda',
    'Kedougou',
    'Kaolack',
    'Gambissara',
    'Gama',
    'Dodji',
    'Diourbel',
    'Diattakounda',
    'Dakar',
    'Boussinki',
    'Bignona',
    'Guediawaye',
  ],
  'Republic of the Congo': [
    'Sibiti',
    'Pointe-Noire',
    'Pointe Noire',
    'Owando',
    'Ouésso',
    'Dolisie',
    'Loandjili',
    'Impfondo',
    'Ewo',
    'Djambala',
    'Diosso',
    'Brazzaville',
  ],
  Portugal: [
    'Zibreira',
    'Zambujeira do Mar',
    'Vimeiro',
    'Vila Vicosa',
    'Vila Verde',
    'Vila Real de Santo António',
    'Vilar',
    'Vila Nova de Sao Pedro',
    'Vila Nova de Milfontes',
    'Vila Nova da Rainha',
    'Vila Nova da Barquinha',
    'Vila Nova da Baronia',
    'Vila Nogueira de Azeitao',
    'Vila Moreira',
    'Vila Fria',
    'Vila Franca de Xira',
    'Vila do Bispo',
    'Vila de Rei',
    'Vila de Frades',
    'Vila Cha de Ourique',
    'Vila Boim',
    'Vieira de Leiria',
    'Vidigueira',
    'Viana do Alentejo',
    'Vialonga',
    'Vermelha',
    'Ventosa',
    'Vendas Novas',
    'Vendas',
    'Venda do Pinheiro',
    'Valongo',
    'Vale de Santarem',
    'Vale de Lobos',
    'Vale de Figueira',
    'Vale da Pinta',
    'Valbom',
    'Valado de Frades',
    'Valadares',
    'Usseira',
    'Unhos',
    'Turquel',
    'Turcifal',
    'Trigaches',
    'Tremes',
    'Tramaga',
    'Trafaria',
    'Torres Vedras',
    'Torres Novas',
    'Torre da Marinha',
    'Torrao',
    'Tomar',
    'Toledo',
    'Tojal',
    'Tires',
    'Tesoureira',
    'Terrujem',
    'Terrugem',
    'Tercena',
    'Tavira',
    'Tancos',
    'Souzel',
    'Sobreiro Curvo',
    'Sobreda',
    'Sobralinho',
    'Sobral de Monte Agraco',
    'Sobral da Abelheira',
    'Sintra',
    'Sines Municipality',
    'Silves',
    'Silveira',
    'Sesimbra',
    'Setúbal',
    'Serta',
    'Serra de Agua',
    'Serpa',
    'Seixal',
    'Seda',
    'Sarzedas',
    'Sarilhos Pequenos',
    'Sarilhos Grandes',
    'Sardoal',
    'Sapataria',
    'Sao Vicente do Paul',
    'Sao Teotonio',
    'Sao Pedro do Corval',
    'Sao Pedro da Cadeira',
    'Sao Paulo',
    'Sao Martinho do Porto',
    'Sao Martinho das Amoreiras',
    'Sao Marcos',
    'Sao Mamede',
    'Sao Luis',
    'Sao Juliao do Tojal',
    'Sao Jorge',
    'Sao Joao dos Montes',
    'Sao Joao de Negrilhos',
    'Sao Joao da Talha',
    'Sao Joao das Lampas',
    'Sao Geraldo',
    'Sao Francisco',
    'Sao Domingos de Rana',
    'Sao Cristovao',
    'Sao Brissos',
    'Sao Bras de Alportel',
    'Sao Bartolomeu do Outeiro',
    'Sao Bartolomeu de Messines',
    'Santo Estevao',
    'Santo da Serra',
    'Santo Antonio das Areias',
    'Santo Antonio',
    'Santo Antao do Tojal',
    'Santo Andre',
    'Santo Amaro',
    'Santo Amador',
    'Santiago do Cacem',
    'Santarem',
    'Santarém',
    'Santana',
    'Santa Luzia',
    'Santa Iria da Azoia',
    'Santa Cruz',
    'Santa Catarina',
    'Samouco',
    'Samora Correia',
    'Salvaterra de Magos',
    'Salvador',
    'Salir',
    'Salemas',
    'Salema',
    'Sagres',
    'Sacavem',
    'Sabugueiro',
    'Sabacheira',
    'Rosario',
    'Rio Maior',
    'Rio de Mouro',
    'Rio de Moinhos',
    'Rinchoa',
    'Ribeira de Santarem',
    'Ribeira Brava',
    'Ribeira Branca',
    'Ribamar',
    'Ribafria',
    'Retaxo',
    'Reguengos de Monsaraz',
    'Reguengo do Fetal',
    'Redondo',
    'Raposeira',
    'Rana',
    'Ramada',
    'Quinta do Anjo',
    'Quinta',
    'Querenca',
    'Queluz',
    'Queijas',
    'Quarteira',
    'Pussos',
    'Proenca-a-Nova',
    'Praia das Macas',
    'Praganca',
    'Pragal',
    'Povoa de Santo Adriao',
    'Povoa de Santa Iria',
    'Povoa da Galega',
    'Povoa',
    'Porto Salvo',
    'Porto Moniz',
    'Porto de Mos',
    'Porto da Cruz',
    'Porto Covo',
    'Porto Alto',
    'Porto',
    'Portimão',
    'Portela',
    'Portel',
    'Portalegre',
    'Pontinha',
    'Pontevel',
    'Ponte de Sor',
    'Ponta do Sol',
    'Ponta Delgada',
    'Pombal',
    'Poceirao',
    'Pinheiro Grande',
    'Pinhal Novo',
    'Piedade',
    'Pias',
    'Pia Furada',
    'Pero Pinheiro',
    'Pereiro',
    'Peras Ruivas',
    'Peniche',
    'Pegoes',
    'Pego',
    'Pedrogao Grande',
    'Pederneira',
    'Pavia',
    'Pataias',
    'Parede',
    'Parchal',
    'Parceiros',
    'Palmela',
    'Paco de Arcos',
    'Outeiro',
    'Ourique',
    'Ourem',
    'Ota',
    'Olival',
    'Olivais',
    'Olho Marinho',
    'Olhao',
    'Olhalvo',
    'Oleiros',
    'Oledo',
    'Oeiras',
    'Odivelas',
    'Odemira',
    'Odeceixe',
    'Obidos',
    'Óbidos',
    'Nisa',
    'Negrais',
    'Nazaré',
    'Nafarros',
    'Murches',
    'Muge',
    'Mourisco',
    'Moura',
    'Moscavide e Portela',
    'Mora',
    'Montijo',
    'Monte Redondo',
    'Monte Real',
    'Montemor-o-Novo',
    'Montemor',
    'Monte Gordo',
    'Monte Estoril',
    'Montalvo',
    'Montachique',
    'Monsanto',
    'Monforte',
    'Monchique',
    'Moncarapacho',
    'Moitelas',
    'Moitas',
    'Moita',
    'Miragaia',
    'Mira',
    'Minde',
    'Milharado',
    'Milagres',
    'Mexilhoeira Grande',
    'Messejana',
    'Mertola',
    'Merces',
    'Merceana',
    'Mem Martins',
    'Mealhada',
    'Mata Mourisca',
    'Massama',
    'Marvao',
    'Martinganca',
    'Marteleira',
    'Marruas',
    'Marrazes',
    'Marinhais',
    'Marinha Grande',
    'Malveira',
    'Maiorga',
    'Magoito',
    'Mafra',
    'Madeira',
    'Madalena',
    'Machico',
    'Maceira',
    'Macas de Dona Maria',
    'Macao',
    'Luz',
    'Lousa',
    'Lourinhã',
    'Louriceira',
    'Lourical',
    'Loures',
    'Loule',
    'Lomba de Egua',
    'Lisbon',
    'Linho',
    'Linda a Velha',
    'Leiria',
    'Lavradio',
    'Laveiras',
    'Lapa',
    'Lameiro',
    'Lagos',
    'Lagoa',
    'Ladoeiro',
    'Junceira',
    'Juncal',
    'Igrejinha',
    'Idanha-a-Nova',
    'Idanha',
    'Guimaraes',
    'Guia',
    'Grandola',
    'Golega',
    'Gaviao',
    'Garvao',
    'Fuzeta',
    'Funchal',
    'Fronteira',
    'Frielas',
    'Freixianda',
    'Freiria',
    'Foz',
    'Fortios',
    'Fonte Longa',
    'Figueiro dos Vinhos',
    'Figueira',
    'Ferrel',
    'Ferreiras',
    'Ferreira do Zezere',
    'Ferreira do Alentejo',
    'Ferragudo',
    'Fátima',
    'Faro do Alentejo',
    'Faro',
    'Fanhoes',
    'Famoes',
    'Famalicao',
    'Evora',
    'Estremoz',
    'Estoril',
    'Ervideira',
    'Erra',
    'Ermidas',
    'Ericeira',
    'Envendos',
    'Entroncamento',
    'Entradas',
    'Encarnacao',
    'Elvas',
    'Dona Maria',
    'Dois Portos',
    'Damaia',
    'Dagorda',
    'Cuba',
    'Cruz Quebrada',
    'Cotovia',
    'Costa de Caparica',
    'Coruche',
    'Cortes',
    'Corroios',
    'Constancia',
    'Consolacao',
    'Conceicao',
    'Comporta',
    'Colares',
    'Coimbra',
    'Chicharo',
    'Charneca de Caparica',
    'Cercal',
    'Caxias',
    'Caxarias',
    'Cavaleiros',
    'Catefica',
    'Castro Verde Municipality',
    'Castro Marim',
    'Castelo de Vide',
    'Castelo Branco',
    'Castelo',
    'Castanheira do Ribatejo',
    'Caseirinhos',
    'Cascais',
    'Carvoeiro',
    'Carvoeira',
    'Carvide',
    'Carvalhal do Pombo',
    'Carvalhal',
    'Cartaxo',
    'Carrico',
    'Carregado',
    'Carne Assada',
    'Carnaxide',
    'Cardigos',
    'Carcavelos',
    'Caranguejeira',
    'Caparica',
    'Canico',
    'Canical',
    'Canha',
    'Caneiro',
    'Canecas',
    'Canal',
    'Canados',
    'Campo Maior',
    'Campelos',
    'Camaroes',
    'Camarate',
    'Câmara de Lobos',
    'Camacha',
    'Calvaria de Cima',
    'Calheta',
    'Caldas da Rainha',
    'Cafede',
    'Cadaval',
    'Cadafais',
    'Cacem',
    'Cabecao',
    'Cabeca Gorda',
    'Cabanas de Torres',
    'Burinhosa',
    'Burgau',
    'Bufarda',
    'Bucelas',
    'Borba',
    'Bombarral',
    'Boliqueime',
    'Bolembre',
    'Bobadela',
    'Boavista dos Pinheiros',
    'Biscainho',
    'Bicesse',
    'Benedita',
    'Bencatel',
    'Benavente',
    'Bemposta',
    'Belas',
    'Beja',
    'Batalha',
    'Barreiro',
    'Barcarena',
    'Barao de Sao Miguel',
    'Barao de Sao Joao',
    'Azueira',
    'Azoia de Baixo',
    'Azoia',
    'Azinhaga',
    'Azaruja',
    'Azambuja',
    'Avis',
    'Avelar',
    'Aveiras de Cima',
    'Aveiras de Baixo',
    'Atouguia da Baleia',
    'Atalaia',
    'Assentiz',
    'Arruda dos Vinhos',
    'Arronches',
    'Arrentela',
    'Arranho',
    'Arraiolos',
    'Arneiro',
    'Armacao de Pera',
    'Arco da Calheta',
    'Apelacao',
    'Andreus',
    'Ansiao',
    'Amoreira',
    'Amora',
    'Amor',
    'Amiaes de Cima',
    'Amareleja',
    'Amadora',
    'Alvorge',
    'Alvor',
    'Alvide',
    'Alverca do Ribatejo',
    'Alvalade',
    'Alvaiazere',
    'Altura',
    'Alto da Serra',
    'Alter do Chao',
    'Alqueva',
    'Alqueidao da Serra',
    'Alpiarca',
    'Almodovar',
    'Almeirim',
    'Almargem',
    'Almancil',
    'Almada',
    'Aljustrel',
    'Aljubarrota',
    'Aljezur',
    'Alhos Vedros',
    'Alhandra',
    'Algueirao',
    'Algoz',
    'Alges',
    'Alfragide',
    'Alfornelos',
    'Alfeizerao',
    'Alenquer',
    'Aldeias de Montoito',
    'Aldeia Gavinha',
    'Aldeia de Paio Pires',
    'Aldeia da Mata',
    'Alcoentre',
    'Alcochete',
    'Alcobaça',
    'Alcaravela',
    'Alcantarilha',
    'Alcanhoes',
    'Alcanena',
    'Alcanede',
    'Alcains',
    'Alcainca Grande',
    'Alcacovas',
    'Alcacer do Sal',
    'Alcabideche',
    'Albufeira',
    'Albergaria',
    'Alandroal',
    'Aguas de Moura',
    'Agualva',
    'Agua de Pena',
    'A dos Cunhados',
    'Assafora',
    'Abuxarda',
    'Abrunheira',
    'Abrantes',
    'Abobada',
    'Abela',
    'Sao Joao do Estoril',
    'Vouzela',
    'Vizela',
    'Viseu',
    'Vinhais',
    'Vimioso',
    'Vilarinho de Arcos',
    'Vilarinho das Cambas',
    'Vilarinho da Castanheira',
    'Vilarinho',
    'Vilar Formoso',
    'Vilarelho da Raia',
    'Vila Real',
    'Vilar do Pinheiro',
    'Vilar de Murteda',
    'Vilar de Mouros',
    'Vilar de Andorinho',
    'Vila Praia de Ancora',
    'Vila Pouca de Aguiar',
    'Vila Pouca',
    'Vila Nova de Tazem',
    'Vila Nova de Paiva',
    'Vila Nova de Gaia',
    'Vila Nova de Foz Coa',
    'Vila Nova de Famalicao',
    'Vila Nova de Ancos',
    'Vila Nova de Cerveira',
    'Vila Mea',
    'Vilamar',
    'Vila Maior',
    'Vila Franca',
    'Vila do Conde',
    'Vila da Lixa',
    'Vila Cha',
    'Vilaca',
    'Vila Boa de Quires',
    'Vila Boa',
    'Vieira do Minho',
    'Vidago',
    'Viatodos',
    'Viana do Castelo',
    'Vermoim',
    'Venade',
    'Veiros',
    'Varzea do Douro',
    'Varzea',
    'Valpacos',
    'Vales do Rio',
    'Valega',
    'Vale de Prazeres',
    'Vale de Cambra',
    'Vale da Madre',
    'Vale',
    'Valdigem',
    'Vagos',
    'Unhao',
    'Unhais da Serra',
    'Ul',
    'Ucha',
    'Turiz',
    'Troviscal',
    'Trofa',
    'Travanca',
    'Trapa',
    'Trancoso',
    'Tourais',
    'Torre de Moncorvo',
    'Torno',
    'Tondela',
    'Tocha',
    'Terroso',
    'Terras de Bouro',
    'Taveiro',
    'Tavarede',
    'Tarouca',
    'Tangil',
    'Talhadas',
    'Tadim',
    'Tabuaco',
    'Tabuacas',
    'Tabua',
    'Soutelo do Douro',
    'Souselas',
    'Souro Pires',
    'Soure Municipality',
    'Soito',
    'Sobrosa',
    'Sobreira',
    'Sobrado de Paiva',
    'Soalheira',
    'Cinfaes',
    'Silvares',
    'Sever do Vouga',
    'Serzedo',
    'Serzedelo',
    'Serpins',
    'Senhorim',
    'Senhora das Febres',
    'Senhora da Hora',
    'Sendim',
    'Seixo da Beira',
    'Seixinhos',
    'Seixezelo',
    'Seixas',
    'Seidoes',
    'Seia',
    'Sebolido',
    'Sazes da Beira',
    'Satao',
    'Sao Vicente de Pereira Jusa',
    'Sao Vicente da Beira',
    'Sao Tiago de Custoias',
    'Sao Roque',
    'Sao Romao do Coronado',
    'Sao Romao',
    'Sao Pedro do Sul',
    'Sao Pedro da Torre',
    'Sao Pedro da Cova',
    'Sao Miguel de Acha',
    'Sao Martinho do Bispo',
    'Sao Martinho de Antas',
    'Sao Martinho da Cortica',
    'Sao Martinho',
    'Sao Mamede do Coronado',
    'Sao Mamede de Infesta',
    'Sao Joao do Campo',
    'Sao Joao de Ver',
    'Sao Joao de Loure',
    'Sao Joao da Pesqueira',
    'Sao Joao da Madeira',
    'Sao Felix da Marinha',
    'Sao Felix',
    'Santo Tirso',
    'Santa Ovaia',
    'Santa Marta de Penaguiao',
    'Santa Marinha do Zezere',
    'Santa Cruz do Bispo',
    'Santa Cruz da Trapa',
    'Santa Comba Dao',
    'Sanguedo',
    'Sangalhos',
    'Sanfins',
    'Sandomil',
    'Sandim',
    'Sande',
    'Salto',
    'Salreu',
    'Salgueiro',
    'Sabugal',
    'Sabrosa',
    'Roriz',
    'Ronfe',
    'Romariz',
    'Rio Tinto',
    'Rio Meao',
    'Rio Mau',
    'Rio Caldo',
    'Ribeirao',
    'Ribeira de Pena',
    'Ribeira de Frades',
    'Ribeira da Mata',
    'Riba de Ave',
    'Resende',
    'Requiao',
    'Remelhe',
    'Regilde',
    'Regedoura',
    'Recarei',
    'Rebordosa',
    'Rebordoes',
    'Rebordelo',
    'Rebolosa',
    'Reboleiro',
    'Quintas',
    'Prazins',
    'Praia de Mira',
    'Praia da Granja',
    'Prado',
    'Póvoa de Varzim',
    'Povoa de Lanhoso',
    'Povoacao',
    'Portela das Cabras',
    'Ponte de Lima',
    'Ponte de Vagos',
    'Ponte da Barca',
    'Poiares',
    'Pocarica',
    'Pinhel',
    'Pinheiro de Azere',
    'Pinheiro da Bemposta',
    'Pinheiro',
    'Pinhao',
    'Pindo',
    'Pindelo',
    'Pessegueiro',
    'Peso da Régua Municipality',
    'Peso',
    'Perre',
    'Perozinho',
    'Pereiras',
    'Pereira',
    'Perafita',
    'Penela',
    'Penedono',
    'Penamaior',
    'Penamacor',
    'Penalva do Castelo',
    'Penafiel',
    'Penacova',
    'Pena',
    'Pedroucos',
    'Pedroso',
    'Pedras Salgadas',
    'Pedorido',
    'Pedome',
    'Paredes de Coura',
    'Paredes da Beira',
    'Paredes',
    'Pardilho',
    'Paranhos',
    'Parada do Monte',
    'Parada de Cima',
    'Pampilhosa do Botao',
    'Pampilhosa da Serra',
    'Palmeira',
    'Palheira',
    'Palhaca',
    'Paiao',
    'Padronelo',
    'Paços de Ferreira',
    'Pacos de Brandao',
    'Paco de Sousa',
    'Ovoa',
    'Ovar',
    'Ourondo',
    'Ourenta',
    'Oliveirinha',
    'Oliveira do Hospital',
    'Oliveira do Douro',
    'Oliveira do Bairro',
    'Oliveira de Frades',
    'Oliveira de Azeméis',
    'Olival Basto',
    'Oldroes',
    'Oia',
    'Noura',
    'Nogueira do Cravo',
    'Nogueira da Regedoura',
    'Nogueira',
    'Nine',
    'Nespereira',
    'Nelas',
    'Neiva',
    'Murtosa',
    'Murtede',
    'Murca',
    'Mundao',
    'Mujaes',
    'Mourisca do Vouga',
    'Mourelo',
    'Mosteiro',
    'Mozelos',
    'Mortagua',
    'Moreira do Lima',
    'Moreira de Conegos',
    'Moreira',
    'Montemor-o-Velho',
    'Montalegre',
    'Mondim de Basto',
    'Monção',
    'Moncao',
    'Moimenta da Beira',
    'Mogadouro',
    'Modivas',
    'Miuzela',
    'Mirandela',
    'Miranda do Douro',
    'Miranda do Corvo',
    'Mindelo',
    'Milheiros de Poiares',
    'Mesao Frio',
    'Melgaço',
    'Medas',
    'Meda',
    'Matosinhos Municipality',
    'Matos',
    'Marinha das Ondas',
    'Marecos',
    'Marco de Canaveses',
    'Mansores',
    'Mangualde',
    'Mancelos',
    'Mamarrosa',
    'Malpique',
    'Maia',
    'Macieira de Sarnes',
    'Macieira da Lixa',
    'Macieira',
    'Macedo de Cavaleiros',
    'Maceda',
    'Macal do Chao',
    'Luso',
    'Lousada',
    'Lousado',
    'Lousã',
    'Lourosa',
    'Louro',
    'Lourinha',
    'Louredo',
    'Loriga',
    'Lordemao',
    'Lordelo',
    'Longos',
    'Lomar',
    'Loivos',
    'Loivo',
    'Lobao',
    'Linhares',
    'Ligares',
    'Leca do Bailio',
    'Leca da Palmeira',
    'Lavra',
    'Laundos',
    'Larca',
    'Lanhoso',
    'Lanhelas',
    'Lanhas',
    'Lamego',
    'Lajeosa',
    'Labruge',
    'Junqueira',
    'Jugueiros',
    'Joane',
    'Janeiro de Cima',
    'Irivo',
    'Infias',
    'Infesta',
    'Ilhavo',
    'Gulpilhares',
    'Gueifaes',
    'Guimarães',
    'Guilhufe',
    'Guilhabreu',
    'Guifoes',
    'Guarda',
    'Grijo',
    'Granja do Ulmeiro',
    'Gouviaes',
    'Gouveia',
    'Gondomar',
    'Goncalo',
    'Góis',
    'Goaes',
    'Giao',
    'Gandra',
    'Gandarela',
    'Galegos',
    'Gafanha da Nazare',
    'Gafanha da Encarnacao',
    'Gafanha da Boa Hora',
    'Furadouro',
    'Fundao',
    'Freixo de Espada à Cinta Municipality',
    'Freixo de Cima',
    'Freixieiro de Soutelo',
    'Freixedas',
    'Freamunde',
    'Frazao',
    'Fragoso',
    'Frades',
    'Fradelos',
    'Foz do Sousa',
    'Fornos de Algodres',
    'Forjaes',
    'Fonte Boa',
    'Folgosa',
    'Fiscal',
    'Figueiro da Granja',
    'Figueiro',
    'Figueira de Castelo Rodrigo Municipality',
    'Figueira da Foz Municipality',
    'Fiaes',
    'Ferreiros',
    'Ferreira de Aves',
    'Fermentelos',
    'Felgueiras',
    'Feira',
    'Favoes',
    'Fatela',
    'Apulia e Fao',
    'Fanzeres',
    'Fajozes',
    'Fajoes',
    'Faia',
    'Fagilde',
    'Fafiao',
    'Fafe',
    'Estarreja',
    'Esposende',
    'Espinho',
    'Espariz',
    'Espargo',
    'Espadanedo',
    'Esmoriz',
    'Esgueira',
    'Escudeiros',
    'Escapaes',
    'Ervedosa do Douro',
    'Ermesinde',
    'Eiriz',
    'Eira Pedrinha',
    'Donelo',
    'Delaes',
    'Deimaos',
    'Dardavaz',
    'Cunha Baixa',
    'Cucujaes',
    'Cruzeiro',
    'Cristelo',
    'Crestuma',
    'Creixomil',
    'Covoes',
    'Covilha',
    'Covide',
    'Covelas',
    'Covas',
    'Costa Nova',
    'Cortegaca',
    'Correlha',
    'Cordinha',
    'Condeixa-a-Velha',
    'Condeixa-a-Nova',
    'Conde',
    'Coles',
    'Coja',
    'Chaves',
    'Cete',
    'Cesar',
    'Cervaes',
    'Cerva',
    'Cernache',
    'Cepoes',
    'Celorico de Basto',
    'Celorico da Beira',
    'Cazevel',
    'Castro Daire',
    'Castelo Viegas',
    'Castelo de Paiva',
    'Castanheiro do Sul',
    'Casa Telhada',
    'Casal Vasco',
    'Casal de Ermio',
    'Casal Comba',
    'Casa',
    'Carvalhosa',
    'Carregosa',
    'Carregal do Sal',
    'Carragosela',
    'Caramulo',
    'Cantanhede',
    'Canelas',
    'Canedo',
    'Canas de Senhorim',
    'Campos',
    'Campo de Viboras',
    'Campo de Besteiros',
    'Campo',
    'Campia',
    'Campea',
    'Caminha',
    'Cambra',
    'Camarneira',
    'Calendario',
    'Caldas de Vizela',
    'Caldas de Sao Jorge',
    'Caldas das Taipas',
    'Cacia',
    'Cabreira',
    'Cabecudos',
    'Cabeceiras de Basto',
    'Cabanas de Viriato',
    'Bustos',
    'Buarcos',
    'Brunhos',
    'Brito',
    'Branca',
    'Bragança',
    'Braga',
    'Boticas',
    'Bornes',
    'Borba de Godim',
    'Boim',
    'Bisalhaes',
    'Bitaraes',
    'Benagouro',
    'Belmonte',
    'Belide',
    'Beire',
    'Barroca',
    'Barqueiros',
    'Barco',
    'Barcelos',
    'Barbudo',
    'Baltar',
    'Bairro',
    'Baião',
    'Baguim do Monte',
    'Azurva',
    'Azenha',
    'Aves',
    'Avelas de Caminho',
    'Aveiro',
    'Avanca',
    'Atenor',
    'Assafarge',
    'Arvore',
    'Arrifana',
    'Arouca',
    'Arneiro Tecelao',
    'Armamar',
    'Argoncilhe',
    'Arganil',
    'Areia',
    'Arcozelo da Torre',
    'Arcozelo',
    'Arcos de Valdevez',
    'Arco de Baulhe',
    'Arazede',
    'Aradas',
    'Antanhol',
    'Anjos',
    'Anca',
    'Anadia',
    'Amorim',
    'Amoreira da Gandara',
    'Amares',
    'Amarante',
    'Alvite',
    'Alverca',
    'Alvarelhos',
    'Alvaraes',
    'Alquerubim',
    'Alpendurada',
    'Alijó',
    'Alheira',
    'Alfena',
    'Alfarelos',
    'Alfandega da Fe',
    'Aldeia Rica',
    'Aldeia de Santo Antonio',
    'Alcabideque',
    'Albergaria-a-Velha',
    'Aguiar da Beira Municipality',
    'Águeda Municipality',
    'Aguas Santas',
    'Agua Longa',
    'Aguada de Cima',
    'Aguada de Baixo',
    'Adaes',
    'Abambres',
    'Abacas',
    'Vila Nova',
    'Vila Franca do Campo',
    'Vila do Porto',
    'Velas',
    'Urzelina',
    'Sao Roque do Pico',
    'Sao Bras',
    'Santa Cruz das Flores',
    'Santa Cruz da Graciosa',
    'Ribeira Quente',
    'Ribeira Grande',
    'Ribeira do Meio',
    'Rabo de Peixe',
    'Praia da Vitoria',
    'Praia',
    'Porto Formoso',
    'Ponta Garca',
    'Nordeste',
    'Mosteiros',
    'Lajes',
    'Horta',
    'Ginetes',
    'Fenais da Luz',
    'Bandeiras',
    'Angra do Heroísmo',
    'Vilamoura',
    'Vila Nova De Cacela',
    'Quinta Do Conde',
    'Prior Velho',
    'Barroselas',
    'Santa Maria da Feira',
    'Valenza',
    'Verdizela',
    'Costa da Caparica',
    'Salir do Porto',
    'Luz de Tavira',
    'Valenca do Minho',
    'Praia Da Luz',
    'Chamusca',
    'Azeitao',
  ],
  Liberia: [
    'Weteken',
    'Voinjama',
    'Tubmanburg',
    'Fish Town',
    'Tawalata',
    'Sanniquellie',
    'Robertsport',
    'River Cess',
    'Paynesville',
    'Monrovia',
    'Kakata',
    'Greenville',
    'Gbarnga',
    'Congo Town',
    'Buchanan',
  ],
  'Ivory Coast': [
    'Zata',
    'Zambakro',
    'Yamoussoukro',
    'Sinfra',
    'Sassandra',
    'San-Pedro',
    'Oume',
    'Odienne-Sienso',
    'Odienné',
    'Mankono',
    'Man',
    'Ledi',
    'Lakota',
    'Koroumba',
    'Korhogo',
    'Grand-Bassam',
    'Gagnoa',
    'Duonfla',
    'Divo',
    'Dimbokro',
    'Daloa',
    'Dabou',
    'Dabakala',
    'Bouna',
    'Bondoukou',
    'Bingerville',
    'Beoumi',
    'Yeh',
    'Anyama',
    'Agnibilekrou',
    'Agboville',
    'Abokouamikro',
    'Aboisso',
    'Abidjan',
    'Abengourou',
    'Marcory',
    'Séguéla',
  ],
  Ghana: [
    'Yendi',
    'Wa',
    'Tema',
    'Techiman',
    'Tarkwa',
    'Tamale',
    'Takoradze',
    'Sunyani',
    'Oyibi',
    'Kasoa',
    'Nsawam',
    'Navrongo',
    'Mampong',
    'Legon',
    'Kwabeng',
    'Kumasi',
    'Koforidua',
    'Kintampo',
    'Cape Coast',
    'Bolgatanga',
    'Berekum',
    'Bawku',
    'Ashaiman',
    'Amasaman',
    'Accra',
    'Osu',
    'New Achimota',
  ],
  'Equatorial Guinea': [
    'Santiago de Baney',
    'San Antonio de Palé',
    'Oyala',
    'Mikomeseng',
    'Evinayong',
    'Malabo',
    'Luba',
    'Bata',
    'Aconibe',
  ],
  Nigeria: [
    'Zaria',
    'Yola',
    'Yenagoa',
    'Yaba',
    'Wuse',
    'Wukari',
    'Uyo',
    'Zazagawa',
    'Surulere',
    'Suleja',
    'Sokoto',
    'Somolu',
    'Sagamu',
    'Sapele',
    'Sango',
    'Port Harcourt',
    'Pindiga',
    'Owerri',
    'Osogbo',
    'Oshodi',
    'Oregun',
    'Onitsha',
    'Ojo',
    'Ogwa',
    'Ogba',
    'Obudu',
    'Obosi',
    'Nsukka',
    'Nnewi',
    'Nassarawa',
    'Mushin',
    'Mowe',
    'Minna',
    'Maryland',
    'Makurdi',
    'Lokoja',
    'Lekki',
    'Langtang',
    'Lagos',
    'Ketu',
    'Kebbe',
    'Katsina',
    'Kano',
    'Kamba',
    'Kaduna',
    'Jos',
    'Jalingo',
    'Jabi',
    'Isolo',
    'Iseri-Oke',
    'Isaga-Tedo',
    'Ipoti',
    'Ipaja',
    'Ilorin',
    'Ilasa',
    'Ikoyi',
    'Ikot Ekpene',
    'Ikorodu',
    'Ikeja',
    'Ikate',
    'Ifo',
    'Ile-Ife',
    'Ibeku',
    'Ibafo',
    'Ibadan',
    'Hadejia',
    'Gusau',
    'Gembu',
    'Funtua',
    'Enugu-Ukwu',
    'Enugu',
    'Eket',
    'Egbeda',
    'Effurun',
    'Dutse',
    'Damaturu',
    'Tsafe',
    'Burutu',
    'Bonny',
    'Birnin Kebbi',
    'Benin City',
    'Bariga',
    'Ayobo',
    'Awoyaya',
    'Awka',
    'Asokoro',
    'Asaba',
    'Apapa',
    'Anambra',
    'Amankwo',
    'Amalu',
    'Amagu',
    'Akure',
    'Akori',
    'Akko',
    'Ajah',
    'Agege',
    'Ado-Ekiti',
    'Adewole',
    'Abuja',
    'Abraka',
    'Abere',
    'Abeokuta',
    'Abalabi',
    'Abakaliki',
    'Aba',
    'Agboju',
    'Alimosho',
    'Ojota',
    'Akowonjo',
    'Lambe',
    'Jikoyi',
    'Ikotun',
    'Ilupeju',
    'Amoji',
    'Warri',
    'Vi',
    'Gbagada',
    'Ojodu',
    'Fagba',
    'Dopemu',
    'Calabar',
  ],
  'Burkina Faso': [
    'Ziniaré',
    'Tenkodogo',
    'Ouahigouya',
    'Ouagadougou',
    'Koudougou',
    'Kongoussi',
    'Dori',
    'Diébougou',
    'Diapaga',
    'Dédougou',
    'Bobo-Dioulasso',
    'Banfora',
  ],
  Togo: [
    'Tsiko',
    'Sokodé',
    'Segbe',
    'Sansanne-Mango',
    'Lomé',
    'Palimé',
    'Kara',
    'Badou',
  ],
  'Guinea-Bissau': [
    'Quebo',
    'Gabú',
    'Canchungo',
    'Gamamudo',
    'Cacheu',
    'Buba',
    'Bolama',
    'Bissora',
    'Bissau',
  ],
  Mauritania: [
    'Tidjikja',
    'Sélibaby',
    'Rosso',
    'Nouakchott',
    'Néma',
    'Kiffa',
    'Kaédi',
    'Fderîck',
    'Ayoun El Atrous',
    'Aleg',
    'Akjoujt',
    'Arafat',
    'Tevragh Zeina',
    'Dar Naim',
  ],
  Benin: [
    'Savé',
    'Savalou',
    'Porto-Novo',
    'Parakou',
    'Ouidah',
    'Natitingou',
    'Malanville',
    'Lokossa',
    'Kandi',
    'Godome',
    'Dogbo',
    'Djougou',
    'Cotonou',
    'Come',
    'Bohicon',
    'Bassila',
    'Banikoara',
    'Aplahoue',
    'Abomey-Calavi',
  ],
  Gabon: [
    'Oyem',
    'Oyam',
    'Ndinghi',
    'Moanda',
    'Mayumba',
    'Mamagnia',
    'Makokou',
    'Libreville',
    'Lambaréné',
    'Koulamoutou',
    'Franceville',
    'Dingui',
    'Anonebere',
  ],
  'Sierra Leone': ['Port Loko', 'Lunsar', 'Freetown', 'Bonthe', 'Boidu'],
  'São Tomé and Príncipe': [
    'São Tomé',
    'Sao Jose Picate',
    'Santo António',
    'Neves Ferreira',
    'Neves',
  ],
  'Saint Helena': ['Georgetown', 'Jamestown'],
  Gibraltar: ['Gibraltar', 'Catalan Bay'],
  Gambia: [
    'Serrekunda',
    'Sabi',
    'Mansa Konko',
    'Georgetown',
    'Farafenni',
    'Banjul',
    'Abuko',
  ],
  Guinea: [
    'Sangaredi',
    'Port-Kamsar',
    'Nzérékoré',
    'Mamou',
    'Lola',
    'Labé',
    'Kankan',
    'Kalia',
    'Forécariah',
    'Dabola',
    'Conakry',
    'Boké',
    'Marela',
  ],
  Niger: [
    'Birni N Konni',
    'Zinder',
    'Timia',
    'Tessaoua',
    'Say',
    'Niamey',
    'Nguigmi',
    'Mayahi',
    'Matamey',
    'Madaoua',
    'Dosso',
    'Dogondoutchi',
    'Diffa',
    'Ayorou',
    'Alaghsas',
  ],
  Mali: [
    'Timbuktu',
    'Tassiga',
    'Taoudenni',
    'Sikasso',
    'Markala',
    'Koulikoro',
    'Kolondieba',
    'Kolokani',
    'Kidal',
    'Kinmparana',
    'Kayes',
    'Kati',
    'Gao',
    'Djenné',
    'Bandiagara',
    'Bamako',
    'Bafoulabe',
    'Aguelhok',
    'Inekar',
  ],
  'Western Sahara': ['Laayoune Plage', 'Bir Anzarane'],
  Tunisia: [
    'Zaghouan',
    'Oued Meliz',
    'Oued Lill',
    'Wadhraf',
    'Tunis',
    'Tinja',
    'Tozeur',
    'Tataouine',
    'Thala',
    'Takelsa',
    'Tajerouine',
    'Tabarka',
    'Sousse',
    'Soliman',
    'Sukrah',
    'Siliana',
    'Sidi Bouzid',
    'Skanes',
    'Sfax',
    'La Sebala du Mornag',
    'Rades',
    'Grombalia',
    'Korba',
    'Kelibia',
    'Goubellat',
    'Kebili',
    'Ksar Hellal',
    'Carthage',
    'El Fahs',
    'Gafsa',
    'Gabès',
    'Nefta',
    'Nabeul',
    'Midoun',
    'Mateur',
    'Masakin',
    'Mareth',
    'Megrine',
    'Menzel Temime',
    'Menzel Jemil',
    'Menzel Heurr',
    'Menzel Bourguiba',
    'Menzel Abderhaman',
    'Manouba',
    'Medjez el Bab',
    'Jendouba',
    'Zarzis',
    'Djemmal',
    'Houmt Souk',
    'Hammam Sousse',
    'Hammam-Lif',
    'La Goulette',
    'Fouchana',
    'Degache',
    'Douar Tindja',
    'Dar Chabanne',
    'Ben Arous',
    'Bellevue',
    'Bardo',
    'Bizerte',
    'Beni Khiar',
    'Beja',
    'Slouguia',
    'Chebba',
    'Aryanah',
    'Ar Rudayyif',
    'Enfidha',
    'Ouardenine',
    'Kairouan',
    'Kasserine',
    'Gremda',
    'Monastir',
    'Moknine',
    'La Mohammedia',
    'Metlaoui',
    'La Marsa',
    'El Marsa',
    'La Manoubia',
    'Mahires',
    'Mahdia',
    'Kram',
    'El Kef',
    'El Jem',
    'Hammamet',
    'Fondouk Jedid',
    'Birine',
    'El Alia',
    'Akouda',
    'Jedaida',
    'Douane',
    'Gamart',
    'Borj Cedria',
    'El Mourouj',
    'El Hafsia',
    'Cite 18 Janvier',
    'Cite El Izdihar',
  ],
  Algeria: [
    'Boumerdas',
    'Zeribet el Oued',
    'Zeralda',
    'Zarzaitine',
    'Zahana',
    'Youb',
    'Toumella',
    'Touggourt',
    'Tolga',
    'Tlemcen',
    'Tizi Rached',
    'Tizi Ouzou',
    'Tizi-n-Tleta',
    'Tizi Gheniff',
    'Tissemsilt',
    'Tirmitine',
    'Tipasa',
    'Tindouf',
    'Timizart',
    'Timimoun',
    'Tighenif',
    'Tiaret',
    'Thenia',
    'Teniet el Abed',
    'Telerghma',
    'Telagh',
    'Tébessa',
    'Tebesbest',
    'Tazoult-Lambese',
    'Tazmalt',
    'Taoura',
    'Tamanghasset',
    'Tamalous',
    'Tamadjert',
    'Taher',
    'Tadmait',
    'Tadjenanet',
    'Tablat',
    'Stora',
    'Staoueli',
    'Sour el Ghozlane',
    'Sour',
    'Souma',
    'Lardjem',
    'Souk Ahras',
    'Sougueur',
    'Souahlia',
    'Skikda',
    'Sig',
    'Sidi Okba',
    'Sidi Moussa',
    'Sidi ech Chahmi',
    'Sidi Daoud',
    'Sidi Bel Abbes',
    'Sidi Amrane',
    'Sidi Ali',
    'Sidi Akkacha',
    'Sidi Aissa',
    'Sidi Aich',
    'Sfizef',
    'Sétif',
    'Sedrata',
    'Seddouk',
    'Sebdou',
    'Saoula',
    'Salah Bey',
    'Saida',
    'Sahel',
    'Sabra',
    'Rouissat',
    'Rouina',
    'Rouiba',
    'Rouached',
    'Remchi',
    'Relizane',
    'Reguiba',
    'Reghaia',
    'Reggane',
    'Redjas',
    'Ras el Oued',
    'Oum el Bouaghi',
    'Ouled Djellal',
    'Selmane',
    'Ouled Beni Messous',
    'Ouled Aiche',
    'Ouenza',
    'Oued Zenati',
    'Oued Smar',
    'Oued Rhiou',
    'Oued el Alleug',
    'Oued el Abtal',
    'Ouargla',
    'Ouakda',
    'Oran',
    'Ohanet',
    'Nedroma',
    'Naciria',
    'Naama',
    "M'Sila",
    'Mouzaia',
    'Mostaganem',
    'Monte-Christo',
    'Mohammadia',
    'Miliana',
    'Mila',
    'Messaad',
    'Mers el Kebir',
    'Merouana',
    'Menaâ',
    'Melouza',
    'Mekla',
    'Mehdia daira de meghila',
    'Megarine',
    'Meftah',
    'Medea',
    'Mecheria',
    "M'Daourouch",
    "M'Chedallah",
    'Mazouna',
    'Mascara',
    'Mansourah',
    'Makouda',
    'Maison Rouge',
    'Magra',
    'Maghnia',
    'Lichana',
    'Yellel',
    "L'Arbaa Nait Irathen",
    'Larbaâ',
    'Lakhdaria',
    'Laghouat',
    'Ksar el Boukhari',
    'Ksar Chellala',
    'Kouba',
    'Kolea',
    'Khenchela',
    'Khemisti',
    'Khemis Miliana',
    'Khemis el Khechna',
    'Kheiredine',
    'Kais',
    'Jijel',
    'Illizi',
    'Iherir',
    'Ighram',
    'Hussein Dey',
    'Hennaya',
    'Heliopolis',
    'Hassi Messaoud',
    'Hassi Khelifa',
    'Hassi Bel Guebbour',
    'Hassi Bahbah',
    'Hammoudi Hamrouch',
    'Hammam Melouane',
    'Hammamet',
    'Hammam Bou Hadjar',
    'Hadjout',
    'Guemar',
    'Guelma',
    'Grarem',
    'Ghardaïa',
    'Froha',
    'Frenda',
    'Freha',
    'Fouka',
    'Fermatou',
    'Feraoun',
    'Es Senia',
    'Emir Abdelkader',
    'El Tarf',
    'El Oued',
    'El Omaria',
    'Bir Mourad Rais',
    'El Mihan',
    'El Matmar',
    'El Malah',
    'El-Kseur',
    'El Karimia',
    'El Kala',
    'El Idrissia',
    'El Harrach',
    'El Hadjira',
    'El Felaye',
    'El Eulma',
    'El Biar',
    'El Bayadh',
    'ash-Shalif',
    'El Aouinet',
    'El Amria',
    'El Achour',
    'El Abiodh Sidi Cheikh',
    'El Abadia',
    'Edjeleh',
    'Eddis',
    'Ech Chettia',
    'Drea',
    'Draria',
    'Draa el Mizan',
    'Commune de Draa Ben Khedda',
    'Douéra',
    'Doucen',
    'Douar Rouached',
    'Djendel',
    'Djelfa',
    'Djanet',
    'Djamaa',
    'Didouche Mourad',
    'Dellys',
    'Deli Ibrahim',
    'Debila',
    'Dar el Beida',
    'Dar Chioukh',
    'Constantine',
    'Collo',
    'Chorfa',
    'Chiffa',
    'Chetouane',
    'Chetma',
    'Cheria',
    'Cheraga',
    'Chemini',
    'Chelghoum el Aid',
    'Chebli',
    'Charef',
    'Chabet el Ameur',
    'Brezina',
    'Bou Tlelis',
    'Bou Saada',
    'Boukhralfa',
    'Boukadir',
    'Bou Ismail',
    'Bouïra',
    'Bougara',
    'Bougaa',
    'Boufarik',
    'Boudoukha',
    'Boudouaou',
    'Boudjima',
    'Boudjellil',
    'Bou Arfa',
    'Bordj Zemoura',
    'Bordj Omar Driss',
    'Bordj Ghdir',
    'Bordj el Kiffan',
    'Bordj el Bahri',
    'Bordj de Chegga',
    'Bordj Bou Arreridj',
    'Boghni',
    'Blida',
    'Biskra',
    'Birkhadem',
    'Birine',
    'Bir el Ater',
    'Besbes',
    'Berrouaghia',
    'Berriane',
    'Berrahal',
    'Bensekrane',
    'Beni Saf',
    'Beni Mester',
    'Beni Mered',
    'Beni Douala',
    'Beni Amrane',
    'Belkheir',
    'Béjaïa',
    'Béchar',
    'Batna City',
    'Barika',
    'Barbacha',
    'Baraki',
    'Baghlia',
    'Bab Ezzouar',
    'Bāb al-Wādī',
    'Azzaba',
    'Azelouaz',
    'Azazga',
    'Arris',
    'Arbatache',
    'Aoulef',
    'Annaba',
    'Ammi Moussa',
    'Amizour',
    'Algiers',
    'Akbou',
    'Ait Slimane',
    'Ain Touta',
    'Ain Tinn',
    'Ain Temouchent',
    'Ain Taya',
    'Ain Soltane',
    'Ain Sefra',
    'Ain Oussera',
    'Ain Oulmene',
    'Ain Khiar',
    'Ain Kerma',
    'Ain Kercha',
    'Ain Fakroun',
    "'Ain el Melh",
    "'Ain el Hammam",
    'Ain el Bya',
    "'Ain el Berd",
    "'Ain el Bell",
    "'Ain el Ahdjar",
    'Ain Defla',
    'Ain Bessem',
    "'Ain Benian",
    'Ain Beida',
    'Aflou',
    'Adrar',
    'Adjahil',
    'Abou el Hassan',
    'El Milia',
    'Tin Alkoum',
    'Bourouba',
  ],
  Spain: [
    'Zurgena',
    'Zujar',
    'Zuheros',
    'Zubia',
    'Zorita',
    'Zarza la Mayor',
    'Zarza de Montanchez',
    'Zarza Capilla',
    'Zarra',
    'Zarcilla de Ramos',
    'Zalamea la Real',
    'Zalamea de la Serena',
    'Zahinos',
    'Zahara de los Atunes',
    'Zagra',
    'Zafra',
    'Zafarraya',
    'Yunquera',
    'Yeste',
    'Yepes',
    'Yecla',
    'Yatova',
    'Yaiza',
    'Vinuela',
    'Villena',
    'Villavieja',
    'Villaviciosa de Cordoba',
    'Villaverde del Rio',
    'Villatobas',
    'Villaseca de la Sagra',
    'Villarrubia de Santiago',
    'Villarrubia de los Ojos',
    'Villarrobledo',
    'Villarrasa',
    'Villargordo del Cabriel',
    'Villargordo',
    'Villarejo-Periesteban',
    'Vila-real',
    'Villardompardo',
    'Villar de Rena',
    'Villar del Rey',
    'Villar del Arzobispo',
    'Villar de Chinchilla',
    'Villaralto',
    'Villanueva de Tapia',
    'Villanueva de San Juan',
    'Villanueva del Trabuco',
    'Villanueva del Rosario',
    'Villanueva del Rio y Minas',
    'Villanueva del Rey',
    'Villanueva de los Castillejos',
    'Villanueva del Fresno',
    'Villanueva del Duque',
    'Villanueva de la Serena',
    'Villanueva de las Cruces',
    'Villanueva del Arzobispo',
    'Villanueva del Ariscal',
    'Villanueva de la Reina',
    'Villanueva de la Jara',
    'Villanueva de la Concepcion',
    'Villanueva de Cordoba',
    'Villanueva de Castellon',
    'Villanueva de Algaidas',
    'Villanueva de Alcardete',
    'Villamuelas',
    'Villamiel de Toledo',
    'Villamayor de Santiago',
    'Villamartin',
    'Vilamarxant',
    'Villamanrique de la Condesa',
    'Villamanrique',
    'Villamalea',
    'Villalpardo',
    'Villalonga',
    'Villalon',
    'Villalba del Alcor',
    'Villajoyosa',
    'Villahermosa',
    'Villaharta',
    'Villagarcia de la Torre',
    'Villafranqueza',
    'Villafranca de los Caballeros',
    'Villafranca de los Barros',
    'Villafranca de Cordoba',
    'Vilafranca de Bonany',
    'Villa del Rio',
    'Villa de Don Fadrique',
    'Villacarrillo',
    'Es Castell',
    'Villacanas',
    'Villablanca',
    'Vilches',
    'Vicar',
    'Viator',
    'Vergel',
    'Vera',
    'Ventas de Zafarraya',
    'Ventas del Carrizal',
    'Ventas con Pena Aguilera',
    'Velez-Rubio',
    'Vélez-Málaga',
    'Velada',
    'Vejer de la Frontera',
    'Valverde de Leganes',
    'Valverde del Camino',
    'Valverde de Jucar',
    'Valverde',
    'Valsequillo de Gran Canaria',
    'Valor',
    'Valleseco',
    'Vallehermoso',
    'Valle de la Serena',
    "la Vall d'Uixo",
    'Valldemossa',
    'Valladolises',
    'Vallada',
    'Valera de Abajo',
    'Valencina de la Concepcion',
    'Valencia del Ventoso',
    'Valencia de Alcantara',
    'Valencia',
    'Valderrubio',
    'Valdepenas de Jaen',
    'Valdepenas',
    'Valdeganga',
    'Valdefuentes',
    'Utrera',
    'Utiel',
    'Usagre',
    'Urda',
    'Umbrete',
    'Uleila de Campo',
    'Ugijar',
    'Ubrique',
    'Úbeda',
    'Turre',
    'Turleque',
    'Turis',
    'Tuejar',
    'Trujillo',
    'Triquivijate',
    'Trigueros',
    'Trevelez',
    'Trebujena',
    'Tous',
    'Totana',
    'Torrox',
    'Torrijos',
    'Torrevieja',
    'Torres',
    'Torreperogil',
    'Torre Pacheco',
    'Torreorgaz',
    'Torrenueva',
    'Torrent',
    'Torremolinos',
    'Torremocha',
    'Torremegia',
    'Torremanzanas',
    'Torrelamata',
    'Torrejoncillo',
    'Torredonjimeno',
    'Torre del Mar',
    'Torre del Campo',
    'Torre de la Horadada',
    'Torre de Juan Abad',
    'Torreaguera',
    'Tomelloso',
    'Tomares',
    'Toledo',
    'Tocina',
    'Tobarra',
    'Titaguas',
    'Tinajo',
    'Tijola',
    'Tibi',
    'Tias',
    'Tharsis',
    'Teulada',
    'Teror',
    'Teresa de Cofrentes',
    'Tembleque',
    'Telde',
    'Tejina',
    'Teguise',
    'Tegueste',
    'Teba',
    'Tazacorte',
    'Tarifa',
    'Tarazona de la Mancha',
    'Tamaraceite',
    'Tamaimo',
    'Tales',
    'Talayuela',
    'Talavera La Real',
    'Talavera de la Reina',
    'Talarrubias',
    'Tahiche',
    'Tacoronte',
    'Tavernes de la Valldigna',
    'Tavernes Blanques',
    'Tabernas',
    'Sufli',
    'Sueca',
    'Sucina',
    'Soo',
    'Son Servera',
    'Sonseca',
    'Son Ferriol',
    'Soneja',
    'Soller',
    'Sollana',
    'Socuellamos',
    'Socovos',
    'Sineu',
    'Sinarcas',
    'Simat de la Valldigna',
    'Silla',
    'Siles',
    'Siete Aguas',
    'Sierra de Yeguas',
    'Sierra de Fuentes',
    'Seville',
    'Setenil de las Bodegas',
    'Serra',
    'Selva',
    'Sella',
    'Segura de Leon',
    'Segura de la Sierra',
    'Segorbe',
    'Sedavi',
    'Sayalonga',
    'Sax',
    'El Sauzal',
    'Saucedilla',
    'San Vicent del Raspeig',
    'San Vicente de Alcantara',
    'Santo Tome',
    'Santomera',
    'Santo Angel',
    'Sant Jordi',
    'Santisteban del Puerto',
    'Santiponce',
    'Santiago del Teide',
    'Santiago de la Ribera',
    'Santiago de la Espada',
    'Santiago de Calatrava',
    'Sant Elm',
    'Es Migjorn Gran',
    'Santa Úrsula',
    'Santa Pola',
    'Santa Olalla del Cala',
    'Santanyí',
    'Santa Marta de Magasca',
    'Santa Marta',
    'Santa Maria de los Llanos',
    'Santa Maria del Mar',
    'Santa Margalida',
    'Santa Lucia',
    'Santa Gertrudis',
    'Santa Fe de Mondujar',
    'Santafe',
    'Santa Faz',
    'Santa Eulària des Riu',
    'Santaella',
    'Santa Elena',
    'Santa Cruz de Tenerife',
    'Santa Cruz de Mudela',
    'Santa Cruz de la Zarza',
    'Santa Cruz de La Palma',
    'Santa Cruz',
    'Santa Brigida',
    'Santa Barbara de Casa',
    'Santa Ana de Pusa',
    'Santa Ana',
    'Santa Amalia',
    'San Silvestre de Guzman',
    'San Sebastián de La Gomera',
    'San Roque',
    'San Pedro del Pinatar',
    'San Pedro',
    'San Pablo',
    'San Nicolas del Puerto',
    'San Nicolas',
    'San Miguel',
    'San Miguel De Abona',
    'San Martin de Pusa',
    'San Martin de Montalban',
    'San Luis de Sabinillas',
    'Sant Lluis',
    'Sanlucar la Mayor',
    'Sanlúcar de Barrameda',
    'San Lorenzo de la Parrilla',
    'Sant Llorenç des Cardassar',
    'San Juan del Puerto',
    'San Juan de la Rambla',
    'San Juan de Aznalfarache',
    'San Juan de Alicante',
    'Sant Joan de Labritja',
    'Sant Joan',
    'San Juan',
    'San Jose',
    'San Jose de la Rinconada',
    'San Javier',
    'San Isidro',
    'Sangonera la Verde',
    'Sant Francesc de Formentera',
    'San Fernando',
    'Sanet y Negrals',
    'San Cristóbal de La Laguna',
    'Sant Climent',
    'San Clemente',
    'Sencelles',
    'San Cayetano',
    'San Carlos del Valle',
    'San Bartolomé de Tirajana',
    'San Bartolome de la Torre',
    'San Bartolome',
    'San Bartolomé',
    'Sant Antoni de Portmany',
    'Salvaleon',
    'Salteras',
    'Salobreña',
    'Salinas',
    'Salar',
    'Sagunto',
    'Sabiote',
    'Rute',
    'Rus',
    'Ruidera',
    'Rubite',
    'Rotgla y Corbera',
    'Rota',
    'Rosalejo',
    'Rosal de la Frontera',
    'Roquetas de Mar',
    'Ronda',
    'Roldan',
    'Rojales',
    'Cabo Roig',
    'Rociana',
    'Rocafort',
    'Riola',
    'Riogordo',
    'Rincon de la Victoria',
    'Ricote',
    'Ribera del Fresno',
    'Ribarroja del Turia',
    'Requena',
    'Redovan',
    'Real de Montroi',
    'Real de Gandia',
    'Real',
    'Ragol',
    'Rafol de Almunia',
    'Rafelguaraf',
    'Rafelcofer',
    'Rafelbunyol',
    'Rafal',
    'Quintanar del Rey',
    'Quintanar de la Orden',
    'Quintana de la Serena',
    'Quesada',
    'Quero',
    'Pucol',
    'Purchil',
    'Purchena',
    'Puntallana',
    'Puntagorda',
    'Pulpí',
    'Pulianas',
    'Pulgar',
    'Puigpunyent',
    'Puig',
    'Puerto Serrano',
    'Puerto Real',
    'Puertollano',
    'Puerto de Santiago',
    'Puerto del Rosario',
    'Puerto de las Nieves',
    'Puerto de la Cruz',
    "Port d'Andratx",
    'Puente-Genil',
    'La Pobla Llarga',
    'La Pobla de Vallbona',
    'Puebla de Soto',
    'Puebla de Sancho Perez',
    'Puebla del Prior',
    'Puebla de la Calzada',
    'Puebla de Guzman',
    'Puebla de Alcocer',
    'Pruna',
    'Priego de Cordoba',
    'Prado del Rey',
    'Pozuelo de Calatrava',
    'Pozo Lorente',
    'Pozohondo',
    'Pozo del Camino',
    'Pozoblanco',
    'Pozo Alcon',
    'Povedilla',
    'Posadas',
    'Porzuna',
    'Portopetro',
    'Porto Cristo',
    'Portocolom',
    'Porreres',
    'Poris de Abona',
    'Pontones',
    'Polop',
    'Pollença',
    'Polinya de Xuquer',
    'Polan',
    'Pliego',
    'Playa Blanca',
    'Planes',
    'Pizarra',
    'Pitres',
    'Pinos Puente',
    'Pinoso',
    'Pinos del Valle',
    'Pinar',
    'Pilas',
    'Pilar de la Horadada',
    'Piedrabuena',
    'Picassent',
    'Picanya',
    'Petres',
    'Petrel',
    'Petra',
    'Periana',
    'Peraleda de la Mata',
    'Penarroya-Pueblonuevo',
    'Penaguila',
    'Penaflor',
    'Peligros',
    'Pegalajar',
    'Pedro Munoz',
    'Pedro Martinez',
    'Pedroche',
    'Pedro Abad',
    'Pedrera',
    'Pedreguer',
    'Pedralba',
    'Pechina',
    'Peal de Becerro',
    'Paymogo',
    'Paterna de Rivera',
    'Paterna del Campo',
    'Paterna',
    'Parcent',
    'Paradas',
    'Palos de la Frontera',
    'Palomares del Rio',
    'Palomares',
    'Palmera',
    'Palma',
    'Palma del Rio',
    'Palma de Gandia',
    'Palenciana',
    'Pajara',
    'Paiporta',
    'Padul',
    'Otura',
    'Otivar',
    'Osuna',
    'Ossa de Montiel',
    'Osa de la Vega',
    'Oropesa',
    'Órgiva',
    'Orihuela',
    'Oria',
    'Orgaz',
    'Orellana la Vieja',
    'Orcheta',
    'Orcera',
    'Orba',
    'Ontur',
    'Ontigola',
    'Ontinyent',
    'Onil',
    'Ondara',
    'Onda',
    'Olvera',
    'Olula del Rio',
    'Olocau',
    "L'Olleria",
    'Olivares',
    'Oliva de Merida',
    'Oliva de la Frontera',
    'Oliva',
    'Olias del Rey',
    'Olias',
    'Ojen',
    'Ohanes',
    'Ogijares',
    'Ochavillo del Rio',
    'Ocana',
    'Obejo',
    'Nules',
    'Novelda',
    'Nogales',
    'Noalejo',
    'Nivar',
    'Nijar',
    'Niebla',
    'Nerva',
    'Nerpio',
    'Nerja',
    'Navas de San Juan',
    'Navas del Selpillar',
    'Navas del Madrono',
    'Navarres',
    'Navalvillar de Pela',
    'Navalmoral de la Mata',
    'Navajas',
    'Navahermosa',
    'Nava de Abajo',
    'Naquera',
    'Nambroca',
    'Museros',
    'Muro del Alcoy',
    'Muro',
    'Mures',
    'Murcia',
    'Munera',
    'Mula',
    'Mutxamel',
    'Moya',
    'Motril',
    'Motilla del Palancar',
    'Mota del Cuervo',
    'Morro del Jable',
    'Morón de la Frontera',
    'el Morche',
    'Moraira',
    'Moratalla',
    'Moraleda de Zafayona',
    'Moral de Calatrava',
    'Mora',
    'Monturque',
    'Montroy',
    'Montoro',
    'Montillana',
    'Montilla',
    'Montijo',
    'Montiel',
    'Monterrubio de la Serena',
    'Montemayor',
    'Montellano',
    'Montejicar',
    'Montejaque',
    'Montefrio',
    'Montecorto',
    'Montealegre del Castillo',
    'Montalban de Cordoba',
    'Montaberner',
    'Montserrat',
    'Monòver',
    'Monforte del Cid',
    'Monesterio',
    'Mondujar',
    'Monda',
    'Moncofa',
    'Moncada',
    'Monachil',
    'Mollina',
    'Molina de Segura',
    'Mojacar Pueblo',
    'Moguer',
    'Mogente',
    'Mogán',
    'Mocejon',
    'Mislata',
    'Mirandilla',
    'Mirador',
    'Mira',
    'Minglanilla',
    'Minaya',
    'Minas de Riotinto',
    'Mijas',
    'Miguelturra',
    'Miguel Esteban',
    'Miajadas',
    'Mérida',
    'Mercadal',
    'Mengibar',
    'Menasalbas',
    'Membrilla',
    'Melilla',
    'Meliana',
    'Melejis',
    'Medina-Sidonia',
    'Medina de las Torres',
    'Medellin',
    'Mazo',
    'Mazarrón',
    'Mazagon',
    'Maspalomas',
    'Mascaraque',
    'Masarrochos',
    'Massanassa',
    'Massamagrell',
    'Massalfassar',
    'Martos',
    'Martin de la Jara',
    'Marratxí',
    'Marmolejo',
    'Marines el Viejo',
    'Marinaleda',
    'Maria de la Salut',
    'Maria',
    'Marchena',
    'Marbella',
    'Maracena',
    'Manzanares',
    'Manuel',
    'Manises',
    'Manilva',
    'Manchita',
    'Mancha Real',
    'Manacor',
    'Malpartida de Plasencia',
    'Malpartida de la Serena',
    'Malpartida de Caceres',
    'Malagon',
    'Málaga',
    'Mairena del Aljarafe',
    'Mairena del Alcor',
    'Mahora',
    'Mahon',
    'Magan',
    'Madronera',
    'Madrigueras',
    'Madrigalejo',
    'Madridejos',
    'Macael',
    'Luque',
    'Puerto Lumbreras',
    'Lucillos',
    'Luciana',
    'Llutxent',
    'Lucena del Puerto',
    'Lucena',
    'Los Yebenes',
    'Los Villares',
    'Los Silos',
    'Los Sauces',
    'Los Santos de Maimona',
    'Los Ramos',
    'Los Quemados',
    'Los Palacios y Villafranca',
    'Los Navalucillos',
    'Los Narejos',
    'Los Montesinos',
    'Los Molares',
    'Los Llanos de Aridane',
    'Los Garres',
    'Los Gallardos',
    'Los Cristianos',
    'Los Corrales',
    'Los Cantareros',
    'Los Belones',
    'Los Barrios',
    'Los Alcazares',
    'Lorqui',
    'Lorca',
    'Lora del Rio',
    'Lopera',
    'Lo Pagan',
    'Lomo de Arico',
    'Loja',
    'Logrosan',
    'Lo Ferro',
    'Lobres',
    'Lobosillo',
    'Lobon',
    'Llucmajor',
    'Llubí',
    'Lloseta',
    'Llosa de Ranes',
    'Lloret de Vistalegre',
    'Llombai',
    'San Fulgencio',
    'Lliber',
    'Llerena',
    'Llano de Brujas',
    'Lliria',
    'Linares',
    'Librilla',
    'Letur',
    'Lepe',
    'Ledana',
    'Lebrija',
    'Layos',
    'La Victoria de Acentejo',
    'La Victoria',
    'La Vera',
    'La Unión',
    'Laujar de Andarax',
    'Las Torres de Cotillas',
    'Las Pedroneras',
    'Las Palmas de Gran Canaria',
    'Las Palas',
    'La Solana',
    'Las Navas de la Concepcion',
    'Las Mesas',
    'Las Infantas',
    'Las Galletas',
    'Las Cabezas de San Juan',
    'La Savina',
    'La Romana',
    'La Roda de Andalucia',
    'La Roda',
    'La Roca de la Sierra',
    'La Rinconada',
    'La Redondela',
    'La Raya',
    'La Rambla',
    'La Rabita',
    'La Puerta de Segura',
    'La Puebla de Montalban',
    'La Puebla del Rio',
    'La Puebla de los Infantes',
    'La Puebla de Cazalla',
    'La Puebla de Almoradiel',
    'sa Pobla',
    'La Playa de Arguineguin',
    'La Pinilla',
    'La Palma del Condado',
    'La Palma',
    'Las Pajanosas',
    'La Orotava',
    'La Oliva',
    'La Nora',
    'Lanjaron',
    'Landete',
    'La Nava de Ricomalillo',
    'La Nava',
    'La Murada',
    'La Mojonera',
    'La Matanza de Acentejo',
    'La Mata',
    'La Luisiana',
    'La Línea de la Concepción',
    'La Lantejuela',
    'La Laguna',
    'Lajares',
    'La Iruela',
    'La Herradura',
    'La Haba',
    'La Guardia de Jaen',
    'La Guancha',
    'La Garrovilla',
    'La Fuente Grande',
    'La Esperanza',
    "L'Eliana",
    'La Cuesta',
    'La Codosera',
    'La Cisnera',
    'Lachar',
    'La Carolina',
    'La Carlota',
    'La Canada',
    'La Campana',
    'La Calzada de Oropesa',
    'La Calzada de Calatrava',
    'La Caleta-Guardia',
    'La Caleruela',
    'La Calahorra',
    'sa Cabaneta',
    'La Aparecida',
    'La Aljorra',
    'La Algaba',
    'La Alberca de Zancara',
    'Jumilla',
    'Jubrique',
    'Jodar',
    'Jimena de la Frontera',
    'Jijona',
    'Jerica',
    'Jerez de los Caballeros',
    'Jerez de la Frontera',
    'Jayena',
    'Javea',
    'Javali Viejo',
    'Jauja',
    'Xàtiva',
    'Jatar',
    'Jarafuel',
    'Jamilena',
    'Jalon',
    'Jalance',
    'Jaén',
    'Jacarilla',
    'Jabugo',
    'Jabalquinto',
    'Javali Nuevo',
    'Iznalloz',
    'Iznajar',
    'Istan',
    'Isso',
    'Iniesta',
    'Ingenio',
    'Infantes',
    'Inca',
    'Illora',
    'Igualeja',
    'Icod de los Vinos',
    'Ibros',
    'Ibiza Town',
    'Ibi',
    'Hurchillo',
    'Humilladero',
    'Huetor Vega',
    'Huetor-Tajar',
    'Huetor Santillan',
    'Huescar',
    'Huerta de Valdecarabanos',
    'Huercal Overa',
    'Huercal de Almeria',
    'Hueneja',
    'Huelva',
    'Huelma',
    'Huecija',
    'Hoya del Campo',
    'Hornos',
    'Hornachuelos',
    'Hornachos',
    'Horcajo de Santiago',
    'Honrubia',
    'Hondon de los Frailes',
    'Hondon de las Nieves',
    'Hinojosas de Calatrava',
    'Hinojosa del Valle',
    'Hinojosa del Duque',
    'Hinojos',
    'Hijar',
    'Higueruela',
    'Higuera de la Serena',
    'Higuera de Calatrava',
    'Herrera del Duque',
    'Herrera',
    'Hermigua',
    'Herguijuela',
    'Herencia',
    'Hellin',
    'Haría',
    'Guisguey',
    'Guime',
    'Guimar',
    'Guillena',
    'Guía de Isora',
    'Guevejar',
    'Guatiza',
    'Guarroman',
    'Guarena',
    'Guardamar del Segura',
    'Gualchos',
    'Guadix',
    'Guadassuar',
    'Guadamur',
    'Guadalupe',
    'Guadalcazar',
    'Guadalcanal',
    'Grazalema',
    'Gran Tarajal',
    'Granja de Torrehermosa',
    'Granja de Rocamora',
    'Granatula de Calatrava',
    'Granadilla de Abona',
    'Granada',
    'Graja de Iniesta',
    'Gorga',
    'Gojar',
    'Godelleta',
    'Godella',
    'Gines',
    'Gilet',
    'Gilena',
    'Gibraleón',
    'Gestalgar',
    'Gerena',
    'Genoves',
    'Gelves',
    'Gaucin',
    'Gata de Gorgos',
    'Garrucha',
    'Garlitos',
    'Garachico',
    'Gandia',
    'Galvez',
    'Galera',
    'Gáldar',
    'Galaroza',
    'Gador',
    'Gabia la Chica',
    'Gavarda',
    'Fuerte del Rey',
    'Fuente Vaqueros',
    'Fuentes de Leon',
    'Fuentes de Andalucia',
    'Fuentes',
    'Fuenterrobles',
    'Fuente Palmera',
    'Fuente Obejuna',
    'Fuente Librilla',
    'Fuentelespino de Haro',
    'La Font de la Figuera',
    'Fuente el Fresno',
    'Fuente de Piedra',
    'Fuente de Pedro Naharro',
    'Fuente del Maestre',
    'Fuente de Cantos',
    'Fuente Carreteros',
    'Fuente Amarga',
    'Fuentealbilla',
    'Fuente-Alamo de Murcia',
    'Fuente-Alamo',
    'Fuensanta de Martos',
    'Fuenlabrada de los Montes',
    'Fuengirola',
    'Fuencaliente de la Palma',
    'Frontera',
    'Frigiliana',
    'Fresnedoso de Ibor',
    'Fregenal de la Sierra',
    'Frailes',
    'Foios',
    'Fortuna',
    'Fornalutx',
    'Formentera de Segura',
    'Fondon',
    'Firgas',
    'Finestrat',
    'Fines',
    'Finana',
    'Ferreries',
    'Fernán Núñez',
    'Ferez',
    'Felanitx',
    'Favara',
    'Faura',
    'Fasnia',
    'Facinas',
    'Estepona',
    'Estepa',
    'Esporles',
    'Espinardo',
    'Espiel',
    'Espera',
    'Espejo',
    'Espartinas',
    'Eslida',
    'Esfiliana',
    'Escuzar',
    'Escanuela',
    'Escacena del Campo',
    'Enguera',
    'Encinas Reales',
    'Encinasola',
    'El Viso del Alcor',
    'El Viso',
    'El Tablero',
    'El Saucejo',
    'El Rubio',
    'El Rompido',
    'El Rocio',
    'El Robledo',
    'El Real de la Jara',
    'El Puntal',
    'El Puerto de Santa María',
    'Port de Sagunt',
    'El Puerto de Mazarron',
    'El Puente del Rio',
    'El Puente del Arzobispo',
    'El Provencio',
    'Port de Sóller',
    'El Porrosillo',
    'El Picazo',
    'El Perrunal',
    'El Perello',
    'El Pedroso',
    'El Pedernoso',
    'El Paso',
    'El Palmar de Troya',
    'El Palmar',
    'El Medano',
    'El Matorral',
    'El Jau',
    'El Hoyo',
    'El Herrumblar',
    'El Grao',
    'El Gastor',
    'El Ejido',
    'Elda',
    'El Cuervo',
    'El Cuchillo',
    'El Coronil',
    'Elche de la Sierra',
    'Elche',
    'El Cerro de Andevalo',
    'El Cerezo',
    'El Carpio de Tajo',
    'El Carpio',
    'El Campillo de Rodalquilar',
    'El Campillo de la Jara',
    'El Campillo',
    'El Bosque',
    'El Bonillo',
    'El Bonal',
    "S'Arenal",
    'El Arahal',
    'El Altet',
    'El Alquian',
    'El Abrigo',
    'Écija',
    'Durcal',
    'Dos Torres',
    'Dos Hermanas',
    'Don Benito',
    'Dona Mencia',
    'Don Alvaro',
    'Domingo Perez',
    'Domeno',
    'Dolores',
    'Denia',
    'Deleitosa',
    'Daya Nueva',
    'Dalias',
    'Daimiel',
    'Cumbres Mayores',
    'Cullera',
    'Cullar',
    'Cuevas de San Marcos',
    'Cuevas del Campo',
    'Cuevas del Becerro',
    'Cuevas del Almanzora',
    'Cuevas Bajas',
    'Cuerva',
    'Quatretonda',
    'Quartell',
    'Quart de Poblet',
    'Quart de les Valls',
    'Cristo del Espiritu Santo',
    'Cristina',
    'Crevillent',
    'Cozar',
    'Cox',
    'Costitx',
    'Corvera',
    'Cortes de Pallas',
    'Cortes de la Frontera',
    'Cortes de Baza',
    'Cortegana',
    'Corteconcepcion',
    'Corrales',
    'Corralejo',
    'Corral de Calatrava',
    'Corral de Almaguer',
    'Coripe',
    'Coria del Río',
    'Coria',
    'Cordobilla de Lacara',
    'Cordova',
    'Corbera',
    'Consuegra',
    'Constantina',
    'Consell',
    'Conil de la Frontera',
    'Conil',
    'Competa',
    'Comares',
    'Colònia de Sant Jordi',
    'Colomera',
    'Colmenar',
    'Coin',
    'Cogollos de Guadix',
    'Cocentaina',
    'Ciudad Real',
    'Cijuela',
    'Cieza',
    'Churriana de la Vega',
    'Churra',
    'Chulilla',
    'Chucena',
    'Chovar',
    'Chiva',
    'Xirivella',
    'Chirivel',
    'Chipiona',
    'Chinchilla de Monte Aragon',
    'Chilluevar',
    'Chillon',
    'Chilches',
    'Chiclana de Segura',
    'Chiclana de la Frontera',
    'Cheste',
    'Chella',
    'Chauchina',
    'Charilla',
    'Charco del Pino',
    'Ceuti',
    'Cerro Muriano',
    'Cenizate',
    'Cenes de la Vega',
    'Cehegín',
    'Cebolla',
    'Cazorla',
    'Cazalla de la Sierra',
    'Cazalilla',
    'Caudiel',
    'Caudete de las Fuentes',
    'Caudete',
    'Catral',
    'Catarroja',
    'Catadau',
    'Castuera',
    'Castro del Rio',
    'Castril',
    'Castillo del Romeral',
    'Castillo de Locubin',
    'Castillejar',
    'Castilleja de la Cuesta',
    'Castilleja de Guzman',
    'Castil de Campos',
    'Castilblanco de los Arroyos',
    'Castello de Rugat',
    'Castelló de la Plana',
    'Castell de Ferro',
    'Castellar de Santiago',
    'Castellar de la Frontera',
    'Castalla',
    'Casinos',
    'Casatejada',
    'Casasimarro',
    'Casas Ibanez',
    'Casas de Millan',
    'Casas de Juan Nunez',
    'Casas de Fernando Alonso',
    'Casas de Benitez',
    'Casariche',
    'Casares',
    'Casar de Caceres',
    'Casarabonela',
    'Casabermeja',
    'Cartaya',
    'Estacion de Cartama',
    'Cartama',
    'Cartajima',
    'Cartagena',
    'Carrizal',
    'Carrion de los Cespedes',
    'Carrion de Calatrava',
    'Carmona',
    'Carmena',
    'Carlet',
    'Cardena',
    'Carchelejo',
    'Carcer',
    'Carcaixent',
    'Carcabuey',
    'Carboneras de Guadazaon',
    'Carboneras',
    'Caravaca',
    'Capdepera',
    'es Capdellà',
    'Cantoria',
    'Cantillana',
    'Canteras',
    'Canillas de Albaida',
    'Canete de las Torres',
    "Canet d'En Berenguer",
    'Canena',
    'Candelaria',
    'Canamero',
    'Canals',
    'Canada Rosal',
    'Canada de Gallego',
    'Canada',
    'Camunas',
    'Campotejar',
    'Campos del Rio',
    'Campos',
    'el Camp de Mirra',
    'Campo de Criptana',
    'Campillos',
    'Campillo de Arenas',
    'El Campello',
    'Campanet',
    'Campanario',
    'Cambil',
    'Camas',
    'Calvià',
    'Calpe',
    'Calonge',
    'Callosa de Segura',
    "Callosa d'En Sarria",
    'Caleta de Sebo',
    'Calera y Chozas',
    'Calasparra',
    'Cala Rajada',
    'Calanas',
    'Calamonte',
    'Cala Millor',
    'Calahonda',
    'Cala Figuera',
    'Cala del Moral',
    'Calabardina',
    'Cala',
    'Cajar',
    'Cadiz',
    'Cadiar',
    'Cáceres',
    'Cabrillas',
    'Cabra',
    'Cabo de Palos',
    'Cabezo de Torres',
    'Cabeza la Vaca',
    'Cabeza del Buey',
    'Busot',
    'Burujon',
    'Burriana',
    'Burjassot',
    'Burguillos de Toledo',
    'Burguillos del Cerro',
    'Burguillos',
    'Bunyola',
    'Buñol',
    'Bullas',
    'Bujalance',
    'Búger',
    'Bugarra',
    'Brenes',
    'Brena Baja',
    'Brena Alta',
    'Bornos',
    'Bormujos',
    'Bonrepos i Mirambell',
    'Bonete',
    'Bonares',
    'Bolnuevo',
    'Bollullos par del Condado',
    'Bollullos de la Mitacion',
    'Bolbaite',
    'Bolanos de Calatrava',
    'Bodonal de la Sierra',
    'Bocairent',
    'Bobadilla',
    'Blanca',
    'Binissalem',
    'Bigastro',
    'Bienvenida',
    'Biar',
    'Betera',
    'Berlanga',
    'Berja',
    'Berchules',
    'Benitachell',
    'Benisano',
    'Benissa',
    'Benirredra',
    'Beniparrell',
    'Benimuslem',
    'Benimodo',
    'Benimeli',
    'Benimantell',
    'Benimamet',
    'Benijofar',
    'Beniganim',
    'Benifaio',
    'Benifairo de les Valls',
    'Beniel',
    'Benidorm',
    'Benidoleig',
    'Benicull de Xuquer',
    'Beniarres',
    'Beniarjo',
    'Beniarbeig',
    'Beniajan',
    'Benferri',
    'Benetusser',
    'Benejuzar',
    'Beneixama',
    'Benavites',
    'Benaojan',
    'Benamocarra',
    'Benameji',
    'Benamargosa',
    'Benalmádena',
    'Benahavis',
    'Benahadux',
    'Benaguasil',
    'Benagalbon',
    'Benacazon',
    'Belmonte',
    'Belmez de la Moraleda',
    'Belmez',
    'Belicena',
    'Belalcazar',
    'Begijar',
    'Bedar',
    'Betxi',
    'Beas de Segura',
    'Beas de Granada',
    'Beas',
    'Baza',
    'Baterno',
    'Barranda',
    'Barranco Hondo',
    'Barqueros',
    'Barx',
    'Bargas',
    'Barxeta',
    'Barcarrota',
    'Barbate',
    'Banos de la Encina',
    'Banyeres de Mariola',
    'Balsicas',
    'Balerma',
    'Balazote',
    'Bajamar',
    'Bailen',
    'Baeza',
    'Baena',
    'Badolatosa',
    'Badajoz',
    'Azutan',
    'Azucaica',
    'Azuaga',
    'Aznalcollar',
    'Aznalcazar',
    'Ayora',
    'Aielo de Malferit',
    'Ayamonte',
    'Avileses',
    'Atogo',
    'Atarfe',
    'Aspe',
    'Arucas',
    'Artà',
    'Arroyo de San Servan',
    'Arroyo del Ojanco',
    'Arroyo de la Miel',
    'Arroyo de la Luz',
    'Arrieta',
    'Arriate',
    'Arrecife',
    'Arona',
    'Aroche',
    'Armilla',
    'Arjonilla',
    'Arjona',
    'Cruce de Arinaga',
    'Arges',
    'Argamason',
    'Argamasilla de Calatrava',
    'Argamasilla de Alba',
    'Argallon',
    'Arenas de San Juan',
    'Ardales',
    'Arcos de la Frontera',
    'Archidona',
    'Archena',
    'Arcas',
    'Arbuniel',
    'Arboleas',
    'Arafo',
    'Aracena',
    'Antigua',
    'Antequera',
    'Antella',
    'Antas',
    'Anover de Tajo',
    'Anora',
    'Anna',
    'Andujar',
    'Andratx',
    'Anahuir',
    'Ambroz',
    'Alumbres',
    'Altura',
    'Altea',
    'Altarejos',
    'Alquerias',
    "s'Alqueria Blanca",
    'Alpuente',
    'Alpera',
    'Alosno',
    'Alora',
    'Almussafes',
    'Almuradiel',
    'Almuñécar',
    'Almoradi',
    'Almonte',
    'Almonaster la Real',
    'Almonacid de Toledo',
    'Almonacid del Marquesado',
    'Almoines',
    'Almoguera',
    'Almogia',
    'Almodovar del Rio',
    'Almodovar del Campo',
    'Almería',
    'Almensilla',
    'Almendricos',
    'Almendralejo',
    'Almendral',
    'Almenara',
    'Almedina',
    'Almedijar',
    'Almassora',
    'Almaraz',
    'Almansa',
    'Almagro',
    'Almadén',
    'Almachar',
    'Almassera',
    'Aljucer',
    'Aljucen',
    'Aljaraque',
    'Aliseda',
    'Alicante',
    'Alhendin',
    'Alhaurin el Grande',
    'Alhaurin de la Torre',
    'Alhambra',
    'Alhama de Murcia',
    'Alhama de Granada',
    'Alhama de Almeria',
    'Alguena',
    'Alguazas',
    'Algorfa',
    'Algodonales',
    'Alginet',
    'Algimia de Almonacid',
    'Algezares',
    'Algemesi',
    'Algeciras',
    'Algatocin',
    'Algarrobo',
    'Algarinejo',
    'Algar',
    'Algamitas',
    'Algaida',
    'Alfondeguilla',
    'Alfarrasi',
    'Alfarb',
    'Alfarnate',
    'Alfafar',
    'Alfacar',
    'Aldea Blanca',
    'Aldaia',
    'Alcudia de Guadix',
    "L'Alcudia de Crespins",
    "L'Alcudia",
    'Alcúdia',
    'Alcoy',
    'Alconera',
    'Alconchel',
    'Alcolea de Tajo',
    'Alcolea del Rio',
    'Alcolea de Calatrava',
    'Alcolea',
    'Alcoba',
    'Alzira',
    'Alcazar de San Juan',
    'Alcaudete',
    'Alcaucin',
    'Alcaraz',
    'Alcaracejos',
    'Alcantarilla',
    'Alcantera de Xuquer',
    'Alcantara',
    'Alcalali',
    'Alcala la Real',
    'Alcalá del Valle',
    'Alcalá de los Gazules',
    'Alcala del Jucar',
    'Alcalá de Guadaira',
    'Alcala',
    'Alcasser',
    'Alburquerque',
    'Albunol',
    'Albuixech',
    'Albudeite',
    'Albox',
    'Alborea',
    'Alboraya',
    'Alborache',
    'Albolote',
    'Alberic',
    'Albatera',
    'Albatana',
    'Albanchez',
    'Albalat dels Tarongers',
    'Albalat dels Sorells',
    'Albalat de la Ribera',
    'Albaladejo',
    'Albal',
    'Albaida del Aljarafe',
    'Albaida',
    'Albacete',
    'Alaior',
    'Alatoz',
    'Alaró',
    'Alarcon',
    'Alange',
    'Alameda',
    'Alajeró',
    'Alaquas',
    'Ajofrin',
    'Ahillones',
    'Agüimes',
    'Águilas',
    'Aguilar',
    'Agudo',
    'Aigues',
    'Aguadulce',
    'Agramon',
    'Agost',
    'Agaete',
    "Atzeneta d'Albaida",
    'Adsubia',
    'Adra',
    'Ador',
    'Adeje',
    'Adamuz',
    'Aceuchal',
    'Alcala del Rio',
    'Abla',
    'Abertura',
    'Abengibre',
    'Abaran',
    'Abanilla',
    'Punta Umbría',
    'Guadalema de los Quinteros',
    'Zurbano',
    'Zumaia',
    'Zumarraga',
    'Zuera',
    'Zuasti',
    'Zorraquin',
    'Zolina',
    'Zarzalejo',
    'Zarza de Granadilla',
    'Zarautz',
    'Zaratan',
    'Zaratamo',
    'Zaragoza',
    'Zamudio',
    'Zamora',
    'Zamarramala',
    'Zaldibar',
    'Zaidin',
    'Iurreta',
    'Igorre',
    'Yunquera de Henares',
    'Yuncos',
    'Yuncler',
    'Yequeda',
    'Yeles',
    'Yebra',
    'Yebes',
    'Viveiro',
    'Vivar del Cid',
    'Vitoria-Gasteiz',
    'Vistabella del Maestrazgo',
    'Viono',
    'Vinuesa',
    'Vincios',
    'Vinaros',
    'Vinaixa',
    'Vimianzo',
    "Vilobi d'Onyar",
    'el Vilosell',
    'Viloria',
    'Villavieja de Yeltes',
    'Villaviciosa de Odon',
    'Villaviciosa',
    'Villaverde de Pontones',
    'Villavaquerin',
    'Villaturiel',
    'Villatuerta',
    'Villaspesa',
    'Villaseca de Laciana',
    'Villasana de Mena',
    'Villarrin de Campos',
    'Urretxu',
    'Villarreal de Alava',
    'Villarramiel',
    'Areatza',
    'Villariezo',
    'Villares de la Reina',
    'Villarejo de Salvanes',
    'Villarejo del Valle',
    'Villardondiego',
    'Villar de Torre',
    'Villar del Olmo',
    'Villar de Gallimazo',
    'Villarcayo',
    'Villaralbo',
    'Vilar',
    'Villaquilambre',
    'Villapresente',
    'Villaornate',
    'Villaobispo de las Regueras',
    'Vilanova i la Geltrú',
    'Villanueva del Pardillo',
    'Villanueva de la Vera',
    'Villanueva de la Torre',
    'Villanueva de la Pena',
    'Villanueva de la Canada',
    'Villanueva de Gumiel',
    'Villanueva de Gallego',
    'Villanueva de Duero',
    'Villanueva de Carrizo',
    'Vilanova de Arousa',
    'Villamuriel de Cerrato',
    'Villamediana de Iregua',
    'Villamayor',
    'Villamartin de Campos',
    'Villamanta',
    'Villamanrique de Tajo',
    'Villamanan',
    'Villalpando',
    'Villalcazar de Sirga',
    'Villalbilla de Burgos',
    'Villalbilla',
    'Villalba del Rey',
    'Vilalba',
    'Villaherreros',
    'Vilagarcia de Arousa',
    'Villafranca de Ordizia',
    'Vilafranca del Penedès',
    'Villafranca del Bierzo',
    'Villafranca',
    'Villafames',
    'Villadiego de Cea',
    'Villadiego',
    'Villadepalos',
    'Villa del Prado',
    'Villa del Campo',
    'Villadecanes',
    'Villaconejos',
    'Villaconancio',
    'Villacastin',
    'Villacarriedo',
    'Villabona',
    'Villablino',
    'Villabanez',
    'Villabalter',
    'Atarrabia',
    'Vilecha',
    'Vilaverd',
    'Vila-seca',
    'Vilasantar',
    'Vila-sacra',
    'Vilaplana',
    'Vilanova de Segriá',
    'Vilanova del Camí',
    'Vilanova de la Roca',
    'Vilanova de la Muga',
    "Vilanova d'Escornalbou",
    'Vilamitjana',
    'Vilamalla',
    'Vilallonga del Camp',
    'Vilajuiga',
    'Vilafant',
    'Viladrau',
    'Viladamat',
    'Viladecavalls',
    'Vilaboa',
    'Vilablareix',
    'Vilabertran',
    'Vigo',
    'Viernoles',
    'Viella',
    'Vielha e Mijaran',
    'Vidreres',
    'Vidanes',
    'Vic',
    'Viana do Bolo',
    'Viana de Cega',
    'Viana',
    'Vespella de Gaia',
    'Verin',
    'Verges',
    'Bergara',
    'Bera',
    'Venturada',
    'Ventosilla',
    'Ventosa del Rio Almar',
    'Ventallo',
    'Venta de Banos',
    'El Vendrell',
    'Bemil',
    'Velilla de San Antonio',
    'Velilla de Jiloca',
    'Vilella de Cinca / Velilla de Cinca',
    'Veguellina de Orbigo',
    'Vegas de Matute',
    'Vegaquemada',
    'Veganzones',
    'Vega de Valcarce',
    'Vegadeo',
    'Vega de Espinareda',
    'Vegacerneja',
    'Vedra',
    'Bedia',
    'Vargas',
    'Baqueira',
    'Vandellos',
    'Valverde del Majano',
    'Valverde del Fresno',
    'Valverde de la Virgen',
    'Valtierra',
    'Valmojado',
    'Balmaseda',
    'Valls',
    'Vallromanes',
    'Vallmoll',
    'Vall-Llobrega',
    'Vallirana',
    'Vallgorguina',
    'Vallfogona de Balaguer',
    'Vallejo de Mena',
    'Valldoreix',
    'Vall de Bianya',
    "Vall d'Alba",
    'Vallbona de les Monges',
    'Valladolid',
    'Valjunquera',
    'Valga',
    'Els Valentins',
    'Valencia de Don Juan',
    'Valdunquillo',
    'Valdovino',
    'Valdilecha',
    'Valdetorres de Jarama',
    'Valdestillas',
    'Val de Santo Domingo',
    'Val de San Lorenzo',
    'Valderrobres',
    'Valderrey',
    'Valderas',
    'Valdeolmos',
    'Valdeobispo',
    'Valdemoro',
    'Valdemorillo',
    'Valdemanco',
    'Valdelosa',
    'Valdelafuente',
    'Valdecilla',
    'Valdeavero',
    'Valdearcos',
    'Luzaide',
    'Valbuena de Duero',
    'Rivas-Vaciamadrid',
    'Vacarisses',
    'Utrillas',
    'Utebo',
    'Usurbil',
    'Useras',
    'Usansolo',
    'Urunuela',
    'Uruena',
    'Urnieta',
    'Urarte',
    'Unquera',
    'Ullivarri de los Olleros',
    'Ulldemolins',
    'Ulldecona',
    'Ullastrell',
    'Ulla',
    'Ujo',
    'Ugena',
    'Uceda',
    'Tui',
    'Turiso',
    'Turegano',
    'Tudela de Duero',
    'Tudela',
    'Trubia',
    'Trobajo del Camino',
    'Trincherpe',
    'Trillo',
    'Trigueros del Valle',
    'Treto',
    'Trespaderne',
    'Trescasas',
    'Tremp',
    'Tremedal de Tormes',
    'Treboedo',
    'Trazo',
    'Tragacete',
    'Trabazos',
    'Trabada',
    'Touro',
    'Tossa de Mar',
    'Tortosa',
    'Torroella de Montgri',
    'Torres de Segre',
    'Torres de la Alameda',
    'Torres de Berrellen',
    'Torremenga',
    'Torrelodones',
    'Torrelles de Llobregat',
    'Torrelles de Foix',
    'Torrelavega',
    'Torrelaguna',
    'Torrejon de Velasco',
    'Torrejon del Rey',
    'Torrejon de la Calzada',
    'Torrejón de Ardoz',
    'Torrefarrera',
    'Torredembarra',
    'Torre del Compte',
    'Torre del Burgo',
    'Torrecilla de los Angeles',
    'Torrecaballeros',
    'Torreblanca',
    'Toro',
    'Tornavacas',
    'Torla',
    'Torija',
    'Toreno',
    'Torelló',
    'Tordillos',
    'Tordesillas',
    'Tordera',
    'Torce',
    'Toral de los Guzmanes',
    'Tora de Riubregos',
    'Tona',
    'Tomino',
    'Tolba / Tolva',
    'Tolosa',
    'Tivissa',
    'Tivenys',
    'Tineo',
    'Tierz',
    'Tielmes',
    'Tiana',
    'Teià',
    'Tetuan de las Victorias',
    'Teruel',
    'Terradillos',
    'Termens',
    'Teo',
    'O Temple',
    'Tejeda de Tietar',
    'Tebra',
    'Tauste',
    'Tarrega',
    'Terrassa',
    'Tarragona',
    'Tardienta',
    'Tarazona',
    'Tarancon',
    'Taradell',
    'Taracena',
    'Tapia de Casariego',
    'Tamon',
    'Tamarit de Llitera',
    'Talarn',
    'Talamanca de Jarama',
    'Tajonar',
    'Tagle',
    'Tafalla',
    'Taboadela',
    'Taboada',
    'Tabanera de Cerrato',
    'Súria',
    'Sunbilla',
    'Sudanell',
    'Subiza',
    'Subirats',
    'Suances',
    'Sotrondio',
    'Sotoserrano',
    'Sotosalbos',
    'Soutomaior',
    'Soto del Barco',
    'Soto de la Marina',
    'Sotillo de la Ribera',
    'Sotillo de la Adrada',
    'Sotalbo',
    'Soses',
    'Sos del Rey Catolico',
    'Sort',
    'Sorihuela',
    'Soria',
    'Sopela',
    'Porto do Son',
    'Somo',
    'Somballe',
    'Solsona',
    'Solares',
    'Sodupe',
    'Sobremazas',
    'Sobradiel',
    'Sobrada',
    'Sober',
    'Sobarzo',
    'Sitges',
    'Simancas',
    'Sils',
    'Silleda',
    'Siguenza',
    'Sigueiro',
    'Siete Iglesias de Trabancos',
    'Sietamo',
    'Sierra de Outes',
    'Sidamon',
    'Sevilla La Nueva',
    'Sevares',
    'Seva',
    'Setien',
    'Sesue',
    'Sestao',
    'Sesma',
    'Sesena Nuevo',
    'Sesena',
    'Sesamo',
    'Serranillos del Valle',
    'Serinya',
    'Sequeros',
    "La Seu d'Urgell",
    'Sentmenat',
    'Senet',
    'Selaya',
    'Segurilla',
    'Segura de Toro',
    'Segura',
    'Segovia',
    'Sebulcor',
    'Saians',
    'Sastago',
    'Sartaguda',
    'Sarroca de Lleida',
    'Sarrion',
    'Sarria de Ter',
    'Sarria',
    'Sarral',
    'Saron',
    'Sarinena',
    'Sariego',
    'Sardineiro',
    'Cerdanyola del Vallès',
    'Sanzoles',
    'San Vicenç de Torelló',
    'Sant Vicenc de Montalt',
    'Sant Vicenç dels Horts',
    'San Vicente de la Barquera',
    'Sant Vicenc de Castellet',
    'Barakaldo',
    'Santurtzi',
    'Santovenia de Pisuerga',
    'Santorcaz',
    'Santona',
    'Santo Domingo de la Calzada',
    'Sant Marti de Malda',
    'Sant Just Desvern',
    'Sant Joan les Fonts',
    'Sant Joan de Vilatorrada',
    'Santiuste de San Juan Bautista',
    'Santiuste',
    'Santiurde de Reinosa',
    'Santiago de Compostela',
    'Santiago de Cartes',
    'Doneztebe',
    'Santa Susanna',
    'Santa Perpetua de Mogoda',
    'Santa Oliva',
    'Santa Olalla',
    'Sant Antoli i Vilanova',
    'Santander',
    'Santa Marta de Tormes',
    'Santa Marta de Tera',
    'Santa Marta de Ortigueira',
    'Santa Marina del Rey',
    'Santa Maria de Palautordera',
    'Santa Maria del Paramo',
    'Lezama',
    'Santa Maria del Campo',
    'Barbera Del Valles',
    'Santa Margarida de Montbui',
    'Santa Magdalena de Pulpis',
    'Santa Fe del Penedes',
    'Santa Eulalia de Roncana',
    'Santa Eulàlia de Riuprimer',
    'Santa Eulalia de Gallego',
    'Santa Eulalia',
    'Santa Eugènia de Berga',
    'Santa Uxia de Ribeira',
    'Santa Cruz del Retamar',
    'Santa Cruz de la Seros',
    'Santa Cruz de Campezo',
    'Santa Cruz de Bezana',
    'Santa Cristina de Aro',
    'Santa Comba',
    'Santa Coloma de Queralt',
    'Santa Coloma de Gramenet',
    'Santa Coloma de Farners',
    'Santa Coloma de Cervelló',
    'Santa Cilia',
    'Santacara',
    'Santa Barbara',
    'San Sebastián de los Reyes',
    'San Sebastian',
    'San Sadurnino',
    "Sant Sadurni d'Anoia",
    'San Rafael',
    'Sant Quirze Safaja',
    'Sant Quirze del Valles',
    'Sant Quinti de Mediona',
    'Sant Pol de Mar',
    'Sant Pere Pescador',
    'Sant Pere de Vilamajor',
    'San Pedro de Trones',
    'Sant Pere de Ribes',
    'Premià de Dalt',
    'San Pedro del Arroyo',
    'Sant Pau de Seguries',
    'San Miguel de Meruelo',
    'San Miguel de las Duenas',
    'San Miguel de la Ribera',
    'San Mateo de Gallego',
    'San Mateo',
    'Sant Marti Sesgueioles',
    'Sant Marti Sarroca',
    'San Martin de Valdeiglesias',
    'Sant Marti de Tous',
    'San Martin del Pimpollar',
    'San Martin de la Vega',
    'Sant Martí de Centelles',
    'Sant Llorenc de Morunys',
    "Sant Llorenc d'Hortons",
    'El Escorial',
    'San Leonardo de Yagüe',
    'San Justo de la Vega',
    'Vilassar de Mar',
    'Sant Joan Despí',
    'San Juan de Nieva',
    'San Juan de Mozarrifar',
    'San Juan de Moro',
    'Sant Joan de Mollet',
    'Sant Joan de les Abadesses',
    'San Juan de la Mata',
    'Sant Jaume dels Domenys',
    'San Ildefonso',
    'Sant Hipòlit de Voltregà',
    'Sant Hilari Sacalm',
    'Sangüesa',
    'Sant Gregori',
    'Vilassar de Dalt',
    'Sanxenxo',
    'Sant Fruitos de Bages',
    'San Fiz do Seo',
    'San Fernando de Henares',
    'San Feliz de Torio',
    'Sant Feliu Sasserra',
    'Sant Feliu de Pallerols',
    'Sant Feliu de Llobregat',
    'Sant Feliu de Guíxols',
    'Sant Feliu de Codines',
    'Sant Feliu de Buixalleu',
    'San Felices de los Gallegos',
    'Sant Fost de Campsentelles',
    'Sant Esteve Sesrovires',
    'San Esteban de Pravia',
    'Sant Esteve de Palautordera',
    'San Esteban del Valle',
    'San Esteban de Gormaz',
    'San Emiliano',
    'Sant Cugat Sesgarrigues',
    'Sant Cugat del Vallès',
    'San Cristobal de la Polantera',
    'San Cristobal de la Cuesta',
    'Sant Climent de Llobregat',
    'Sant Cebria de Vallalta',
    'Sanchonuno',
    'Sant Celoni',
    'Sant Carles de la Rapita',
    'Sant Boi de Llobregat',
    'San Asensio',
    'Sant Antoni de Vilamajor',
    'Sant Antoni de Calonge',
    'San Andres de Soria',
    'San Andres del Rabanedo',
    'Sant Andreu de Llavaneres',
    'Sant Andreu de la Barca',
    'Erbera',
    'Sanaüja',
    'San Agustin del Guadalix',
    'Sant Adrià de Besòs',
    'San Adrian',
    'Samos',
    'Samano',
    'Langreo',
    'Salvatierra de Mino',
    'Salvatierra de Esca',
    'Salvatierra',
    'Salvacanete',
    'Salt',
    'Salou',
    'Sallent de Gallego',
    'Sallent',
    'Salinas de Pamplona',
    'Saldes',
    'Saldana',
    'Salceda',
    'Salas Altas',
    'Salas',
    'Salardu',
    'Salamanca',
    'Sajazarra',
    'Sahagun',
    'Sadaba',
    'Sada',
    'Sacramenia',
    'Sabinanigo',
    'Sabadell',
    'Rueda de la Sierra',
    'Rueda',
    'Rubielos de Mora',
    'Rubí',
    'Rossello',
    'Roses',
    'O Rosal',
    'Roquetes',
    'Roncal',
    'Rollan',
    'Rois',
    'Rodeiro',
    'Rodanillo',
    'Roda de Ter',
    'Roda de Berà',
    'Robles de la Valcueva',
    'Robledo de Chavela',
    'Robledillo de Mohernando',
    'Riudellots de la Selva',
    'Riudellots de la Creu',
    'Riudecols',
    'Riudarenes',
    'Ripollet',
    'Ripoll',
    'Rincon de Soto',
    'Riells i Viabrea',
    'Ricla',
    'Ribeira',
    'Ribes de Freser',
    'Ribas de Campos',
    "Riba-roja d'Ebre",
    'Ribaforada',
    'Ribadumia',
    'Ribadesella',
    'Ribadeo',
    'Ribadavia',
    'Rivabellosa',
    'Riaza',
    'Riano',
    'Rianxo',
    'Rialp',
    'Reus',
    'Respaldiza',
    'Requejo',
    'Errenteria',
    'Renedo',
    'Rendar',
    'Remondo',
    'Remolinos',
    'Reinosa',
    'Regencos',
    'Redondela',
    'Recas',
    'Readegos',
    'Raimat',
    'Rasueros',
    'Rascafria',
    'A Ramallosa',
    'Ramales de la Victoria',
    'Ramacastanas',
    'Rada',
    'Rabano',
    'Rabade',
    'Quismondo',
    'Quiroga',
    'Quinto',
    'Quintanilla de Urz',
    'Quintanaduenas',
    'Quijorna',
    'Quijas',
    'Quijano',
    'Queveda',
    'Quemada',
    'Quel',
    "Quart d'Onyar",
    'Puig-reig',
    'Puigpelat',
    'Puigcerdà',
    'Portomarin',
    'Puerto de Vega',
    'el Port de la Selva',
    'As Pontes de Garcia Rodriguez',
    'Puente San Miguel',
    'Puente La Reina – Gares',
    'Pontedeume',
    'Puente de Domingo Florez',
    'Puente de Alba',
    'Ponteceso',
    'Ponte Caldelas',
    'Ponteareas',
    'Pobra de Trives',
    'Puebla de Sanabria',
    'Puebla de Lillo',
    'A Pobra do Caraminal',
    'Puebla de Azaba',
    'Priaranza del Bierzo',
    'Pravia',
    'Prats de Llucanes',
    'Pratdip',
    'Prades',
    'Pradejon',
    'Pozuelo de Alarcón',
    'Pozo de Guadalajara',
    'Pozal de Gallinas',
    'Poio',
    'Potes',
    'Portugalete',
    'Portosin',
    'Portonovo',
    'Portomourisco',
    'Portillo de Toledo',
    'Portillo',
    'la Portella',
    'Porrua',
    'Porrino',
    'Porrera',
    'Porqueres',
    'Pontons',
    'Pontevedra',
    'Pontejos',
    'el Pont de Suert',
    'Ponts',
    'Ponferrada',
    'Pomar de Cinca',
    'Pomaluengo',
    'Polinya',
    'Pola de Siero',
    'Pola de Lena',
    'Pola de Laviana',
    'la Pobla de Segur',
    'la Pobla de Montornes',
    'la Pobla de Massaluca',
    'el Poal',
    'Plentzia',
    'Palau-solita i Plegamans',
    "Platja d'Aro",
    'Plasencia',
    'el Pla del Penedes',
    'El Pla de Santa Maria',
    'Placencia',
    'Pioz',
    'Piornal',
    'Pinto',
    'Pinseque',
    'Pineda de Mar',
    'Pina de Ebro',
    'Piera',
    'Piedras Blancas',
    'Piedralaves',
    'Pezuela de las Torres',
    'Perlora',
    'Perillo',
    'Pereruela',
    'Peratallada',
    'Peraltilla',
    'Peralta – Azkoien',
    'Perales de Tajuna',
    'Perales del Puerto',
    'Perales del Alfambra',
    'Perafita',
    'Peñíscola',
    'Penelles',
    'Penaranda de Duero',
    'Penaranda de Bracamonte',
    'Penagos',
    'Penafiel',
    'Pelayos de la Presa',
    'Pedrola',
    'Pedro Bernardo',
    'Pedrezuela',
    'Pedraza',
    'Pedrajas de San Esteban',
    'Samikola',
    'Pazos',
    'Pauels',
    'Pau',
    'Pasajes Ancho',
    'Parrillas',
    'Parres',
    'Parlava',
    'Parla',
    'Parets del Vallès',
    'Paredes de Nava',
    'Parbayon',
    'Paradela del Rio',
    'Paracuellos de Jarama',
    'El Papiol',
    'Papatrigo',
    'Panton',
    'Pantoja',
    'Panes',
    'Pamplona',
    'Pals',
    'Palomeque',
    'Palmeira',
    'Pallejà',
    'els Pallaresos',
    'Palencia',
    'Palazuelos de Eresma',
    'Palau-saverdera',
    'Palamós',
    'Palafrugell',
    'Palafolls',
    'Palacios de la Valduerna',
    'Palacios de la Sierra',
    'Padrón',
    'Padilla de Arriba',
    'Oyon',
    'Oiartzun',
    'Oia',
    'Oviedo',
    'Outes',
    'Ourol',
    'Oteruelo de la Valdoncina',
    'Outeiro de Rei',
    'Otanes',
    'Osso de Cinca',
    'Osma',
    'Oseiro',
    'Os de Balaguer',
    'Urtuella',
    'Òrrius',
    'Oroso',
    'Oropesa del Mar',
    'Oriz',
    'Orio',
    'Orihuela del Tremedal',
    'Organya',
    'Ourense',
    'Orendain',
    'Orejo',
    'Urduna',
    'Ordes',
    'Orcoyen',
    'Orbita',
    'Onzonilla',
    'Ontinena',
    'Ondarroa',
    'Onate',
    'Olvega',
    'Olot',
    'Olost',
    'Olmedo',
    'Olloniego',
    'Olleros de Sabero',
    'Olivella',
    'Olivares de Duero',
    'Olius',
    'Olite',
    'Oliana',
    'Olesa de Montserrat',
    'Oleiros',
    'Olvan',
    'Olba',
    'Olazagutia',
    'Olatz Txipia',
    'Olaberria',
    'Odena',
    'Numancia de la Sagra',
    'Nulles',
    'Nuevo Baztan',
    'Nubledo',
    'Noia',
    'Norena',
    'Nombela',
    'Noja',
    'Nois',
    'Noceda',
    'Noain',
    'Niveiro',
    'Nistal',
    'Nigran',
    'Nestares',
    'Negros',
    'Negreira',
    'Neda',
    'Navia',
    'Naves',
    'Navelgas',
    'Navatejera',
    'Navata',
    'Navas de San Antonio',
    'Navas de Oro',
    'Navas del Rey',
    'Navàs',
    'Navarrete',
    'Navarcles',
    'Navamorcuende',
    'Navaluenga',
    'Navalperal de Pinares',
    'Navalmanzano',
    'Navalcarnero',
    'Nava del Rey',
    'Nava de la Asuncion',
    'Navaconcejo',
    'Navacerrada',
    'Nava',
    'Narón',
    'Nanton',
    'Nanclares de la Oca',
    'Nájera',
    'Mutilva Baja',
    'Mutilva Alta',
    'Muruzabal',
    'Muros de Nalon',
    'Muros',
    'Murillo el Fruto',
    'Murillo de Rio Leza',
    'Muriedas',
    'Murchante',
    'Mura',
    'Munorrodero',
    'Munogalindo',
    'Mungia',
    'Mundaka',
    'Muimenta',
    'Mugia',
    'Mugardos',
    'Muel',
    'Mudrian',
    'Mozoncillo',
    'Mozarbez',
    'Moia',
    'Mourence',
    'Mutriku',
    'Mostoles',
    'Mos',
    'Mortera',
    'Morillas',
    'el Morell',
    'Moreda',
    'Morcillo',
    'Morata de Tajuna',
    'Morana',
    'Moralzarzal',
    'Morales de Toro',
    'Morales del Vino',
    'Moraleja del Vino',
    'Moraleja de Enmedio',
    'Moraleja',
    'Mora la Nova',
    "Móra d'Ebre",
    'Monzon',
    'Monzalbarba',
    'Montseny',
    'Mont-roig del Camp',
    'Mont-ral',
    'Mont-ras',
    'Montornès del Vallès',
    'Montmeló',
    'Montmaneu',
    'Montferri',
    'Montferrer',
    'Monterrubio',
    'Monterroso',
    'Montella',
    'Montejos del Camino',
    'Montehermoso',
    'Monteagudo',
    'Montbrio del Camp',
    'Montblanc',
    'Montanana',
    'Montalbán',
    'Montagut',
    'Monreal del Campo',
    'Monistrol de Calders',
    'Monistrol de Montserrat',
    'Montgat',
    'Monforte de Lemos',
    'Monfero',
    'Monfarracinos',
    'Moneo',
    'Mondragón',
    'Mondonedo',
    'Mondejar',
    'Montcada i Reixac',
    'Mombeltran',
    'Mollet de Peralada',
    'Mollet del Vallès',
    'Mollerussa',
    'Molins de Rei',
    'Mojados',
    'Moja',
    'Mogro',
    'Moana',
    'Miranda de Ebro',
    'Miranda de Arga',
    'Miralcamp',
    'Miraflores de la Sierra',
    'Mino',
    'Minao Goien/Minano Mayor',
    'Mieres',
    'Miengo',
    'Meres',
    'Mequinensa / Mequinenza',
    'Mentrida',
    'Elexalde Mendata',
    'Mendaro',
    'Melon',
    'Melide',
    'Mejorada del Campo',
    'Meis',
    'Mediona',
    'Medina de Rioseco',
    'Medina de Pomar',
    'Medina del Campo',
    'Medinya',
    'Medianas',
    'Meco',
    'Meanos',
    'Meano',
    'Mazaricos',
    'Mayorga',
    'Matute',
    'Maturana',
    'Matienzo',
    'Mataró',
    'Matamorosa',
    'Matalobos',
    'Matadepera',
    'Macanet de la Selva',
    'Macanet de Cabrenys',
    'Masquefa',
    'el Masnou',
    'Mas de las Matas',
    'Marugan',
    'Martorelles',
    'Martorell',
    'Martin Munoz de la Dehesa',
    'Martin',
    'Markina-Xemein',
    'Marin',
    'Mariana',
    'Marialba de la Ribera',
    'Maria de Huerva',
    'Marchamalo',
    'Maqueda',
    'Manzaneda',
    'Manzanares el Real',
    'Mansilla de las Mulas',
    'Manresa',
    'Manlleu',
    'Manjoya',
    'Manganeses de la Polvorosa',
    'Maluenda',
    'Malpica',
    'Les Mallorquines',
    'Mallen',
    'Mallavia',
    'Malla',
    'Maliano',
    'Malgrat de Mar',
    'Majadahonda',
    'Mahono',
    'Magazos',
    'Magallon',
    'Maello',
    'Maella',
    'Madrigal de la Vera',
    'Madridanos',
    'Madrid',
    'Madremanya',
    'Maceda',
    'Luzon',
    'Luiaondo',
    'Lupinen',
    'Lumpiaque',
    'Lumbrales',
    'Lumbier',
    'Lugones',
    'Lugo',
    'Luesia',
    'Luco de Jiloca',
    'Luceni',
    'Lucena del Cid',
    'Lubian',
    'Luarca',
    'Luanco',
    'Lobios',
    'Lousame',
    'Louredo',
    'Los Santos de la Humosa',
    'Los Molinos',
    'Los Hueros',
    'Los Corrales de Buelna',
    'Losar de la Vera',
    'Losana de Piron',
    'Lorio',
    'Lorenzana',
    'Lominchar',
    'Logroño',
    'Loeches',
    'Lodosa',
    'Lloret de Mar',
    'Llorenc del Penedes',
    'Llora',
    'Laudio-Llodio',
    'Lliçà de Vall',
    "Llica d'Amunt",
    'Llinars del Valles',
    'Llavorsi',
    'Llançà',
    'Llanes',
    'Llanera',
    'Llamas de la Ribera',
    'Llagostera',
    'Llafranc',
    'Llado',
    'Lizarza',
    'Linyola',
    'Linares de Riofrio',
    'Lillo del Bierzo',
    'Lierta',
    'Liencres',
    'Lezo',
    'Les Fonts',
    'Lesaka',
    'Les',
    'Lerma',
    'Lerin',
    'Lleida',
    'Lekeitio',
    'León',
    'Leon',
    'Leioa',
    'Leitza',
    'Leiro',
    'Legorreta',
    'Legazpia',
    'Legasa',
    'Legarda',
    'Leganés',
    'Ledesma',
    'Lekunberri',
    'Lecinena',
    'Lazcano',
    'La Virgen del Camino',
    'La Vecilla de Curueno',
    'La Vallencana',
    "la Tallada d'Emporda",
    'Las Ventas de Retamosa',
    'Las Vallinas',
    'Lastras de Cuellar',
    'Las Torres',
    'Las Rozas de Madrid',
    'Laspuna',
    'les Preses',
    "les Planes d'Hostoles",
    'Las Navas del Marques',
    'Las Matas',
    'La Selva del Camp',
    'la Cellera de Ter',
    'La Seca',
    "Les Cases d'Alcanar",
    'Lasarte',
    'A Rua',
    'Larrimbe',
    'Larrabetzu',
    'La Roca del Valles',
    'La Robla',
    'la Riera de Gaia',
    'Laredo',
    'Lardero',
    'La Rapita',
    'Laracha',
    'La Puebla de Hijar',
    'La Puebla de Arganzon',
    'La Pola de Gordon',
    'la Pobla de Mafumet',
    'La Pobla de Lillet',
    'La Pobla de Claramunt',
    'La Plaza',
    'La Pineda',
    'La Piedra',
    'La Pesga',
    'La Parrilla',
    'La Palma de Ebro',
    'Langa',
    'Lancara',
    'La Muela',
    'la Maso',
    'La Losa',
    'La Llagosta',
    'Lalin',
    'La Lastrilla',
    'la Jonquera',
    'Laxe',
    'La Iglesuela del Cid',
    'La Iglesuela',
    'Polanco',
    'Laguna de Duero',
    'Laguna Dalga',
    'A Gudina',
    'Laguardia',
    'A Guarda',
    'La Granada',
    'Lagata',
    'la Garriga',
    'La Fuente de San Esteban',
    'La Fresneda',
    'La Florida',
    'La Felguera',
    'A Estrada',
    'La Espina',
    "l'Escala",
    'Lakuntza',
    'A Costa',
    'A Coruña',
    'la Senia',
    'La Cavada',
    'La Caridad',
    'La Canonja',
    'A Caniza',
    'la Canya',
    'La Cabrera',
    'La Bisbal',
    'Labastida',
    'La Baneza',
    'San Juan de la Arena',
    'La Arena',
    'Zugaztieta / La Arboleda',
    "L'Ampolla",
    "l'Ametlla de Mar",
    "L'Ametlla del Valles",
    'La Almunia de Dona Godina',
    'La Munia',
    'La Almolda',
    'La Aldea del Puente',
    'La Alberca',
    'La Alameda de la Sagra',
    'La Adrada',
    'Juzbado',
    'Juneda',
    'Juarros de Voltoya',
    'Xove',
    'Jorba',
    'Jimenez de Jamuz',
    'Jerte',
    'Jarandilla de la Vera',
    'Jaraiz de la Vera',
    'Jadraque',
    'Jaca',
    'Izoria',
    'Illa de Arousa',
    'Isla',
    'Iscar',
    'Isasondo',
    'Isaba',
    'Irurtzun',
    'Irura',
    'Irun',
    'Iribas',
    'Insua',
    'Infiesto',
    'Imarcoain',
    'Illueca',
    'Illescas',
    'Iguena',
    'Igualada',
    'Igries',
    'Igea',
    'Idiazabal',
    'Icazteguieta',
    'Ibdes',
    "Ivars d'Urgell",
    'Ibarra',
    'Humanes de Madrid',
    'Huesca',
    'Huerta del Marquesado',
    'Huarte-Uharte',
    'Hoz de Barbastro',
    'Hoz de Anero',
    'Hoyo de Manzanares',
    'Hostalric',
    "L'Hospitalet de Llobregat",
    "l'Hospitalet de l'Infant",
    'Horta de Sant Joan',
    'Horche',
    'Hontoria de Valdearados',
    'Hontoria de Cerrato',
    'Hontanares de Eresma',
    'Hinojedo',
    'Hervás',
    'Herrera de Pisuerga',
    'Hernani',
    'Heras',
    'Echo',
    'Haro',
    'Gurrea de Gallego',
    'Gumiel de Izan',
    'Guitiriz',
    'Guissona',
    'Guijuelo',
    'Guijo de Granadilla',
    'Guijo de Galisteo',
    'Guijo de Coria',
    'els Guiamets',
    'Getaria',
    'Gernika-Lumo',
    'Guenes',
    'Getxo',
    'Gudar',
    'Guarnizo',
    'Guardo',
    'Guardiola de Bergueda',
    'Sant Salvador de Guardiola',
    'Gualta',
    'Guadarrama',
    'Guadalix de la Sierra',
    'Guadalajara',
    'O Grove',
    'Grinon',
    'Grijota',
    'Grijalba',
    'Gres',
    'Gravalos',
    'Graus',
    'Granollers',
    'Granyena de Segarra',
    'Granen',
    'Grandas de Salime',
    'la Granadella',
    'Grado',
    'Goian',
    'La Gornal',
    'Elexalde',
    'Gondomar',
    'Gomezserracin',
    'Gomecello',
    'Golmes',
    'Golmayo',
    'Goizueta',
    'Gironella',
    'Xinzo de Limia',
    'Ginestar',
    'Gijón',
    'Gibaja',
    'Getafe',
    'Girona',
    'Ger',
    'Gelsa',
    'Gelida',
    'Gabiria',
    'Gausac',
    'Garriguella',
    'Garray',
    'Garganta la Olla',
    'Garganta de los Montes',
    'Garay',
    'Gandesa',
    'Galve de Sorbe',
    'Gallur',
    'Gallarta',
    'Galdakao',
    'Galar',
    'Galapagos',
    'Galapagar',
    'Fustinana',
    'Funes',
    'Fuentiduena de Tajo',
    'Fuentes de Ropel',
    'Fuentes de Ebro',
    'Fuenterrabia',
    'Fuentepelayo',
    'Fuentelapena',
    'Fuente el Saz',
    'Fuensalida',
    'Fuensaldana',
    'Fuenmayor',
    'Fuenlabrada',
    'Frula',
    'Friol',
    'Fresno El Viejo',
    'Fresno del Camino',
    'Frama',
    'Fragen',
    'Fraga',
    'Frades',
    'Foz',
    'Fortanete',
    'Fornoles',
    'Fornells de la Selva',
    'Forcall',
    'Fonteta',
    'Fontcoberta',
    'Fontanar',
    'Fonollosa',
    'Folgueroles',
    'Foixa',
    'Flix',
    'Fitero',
    'Fiscal',
    'Finisterra',
    'Figueruelas',
    'Figueres',
    'Figaredo',
    'Fene',
    'Fayon',
    'Farlete',
    'Fanzara',
    'Falset',
    'Falces',
    'Fabero',
    'Fabara',
    'Ezquioga',
    'Ezkurra',
    'Ezcaray',
    "Esterri d'Aneu",
    'Estella-Lizarra',
    "l'Estartit",
    'Estanyol',
    'Estallo',
    'Estadilla',
    'Esquivias',
    'Espuendolas',
    'Esplugues de Llobregat',
    "L' Espluga de Francoli",
    'Espirdo',
    'Espinosa de los Monteros',
    'Espinelves',
    'Esparreguera',
    'Escucha',
    'Eskoriatza',
    'Esclanya',
    'Escatron',
    'Escaroz',
    'Escarabote',
    'Escarabajosa de Cabezas',
    'Escalona del Prado',
    'Escalona',
    'Escalante',
    'Erla',
    'Erandio',
    'Epila',
    'Entrimo',
    'Entrena',
    'Entrambasaguas',
    'Endrinal',
    'Embun',
    'Eltzaburu',
    'El Viso de San Juan',
    'El Villar de Arnedo',
    'Elvillar',
    'El Vellon',
    'El Tiemblo',
    'El Temple',
    'El Tejo',
    'Els Monjos',
    'Els Ibarsos',
    'els Hostalets de Pierola',
    'El Royo',
    'el Prat de Llobregat',
    'el Pont de Vilomara',
    'El Pobo de Duenas',
    'Santa Maria de Besora',
    'O Pino',
    'El Perdigon',
    'Elorrio',
    'Loiu',
    'El Molar',
    'Elizondo',
    'El Hoyo de Pinares',
    'El Hornillo',
    'Gurb',
    'Empuriabrava',
    'Elgoibar',
    'El Franco',
    'Figaro',
    'Ferrol',
    'El Espinar',
    'Elechas',
    'El Corrillo',
    'Elciego',
    'El Casar de Escalona',
    'El Burgo de Osma',
    'El Burgo de Ebro',
    'O Burgo',
    'el Bruc',
    'El Berrueco',
    'O Barco de Valdeorras',
    'El Barco de Avila',
    'El Astillero',
    'El Arenal',
    'Elantxobe',
    'El Alamo',
    'Ejea de los Caballeros',
    'Eibar',
    'Egiarreta',
    'Duruelo',
    'Durango',
    'Dumbria',
    'Duenas',
    'Dosrius',
    'Donramiro',
    'Doninos de Salamanca',
    'Dodro',
    'Distriz',
    'Devesa de Curueno',
    'Deba',
    'Desojo',
    'Derio',
    'Dehesas',
    'Daroca',
    'Darnius',
    'Daganzo de Arriba',
    'Dacon',
    'Cuntis',
    'Cunit',
    'Culleredo',
    'Cuevas de Vinroma',
    'Cuevas del Valle',
    'Cueto',
    'Cuerres',
    'Cuenca',
    'Cuellar',
    'Cudillero',
    'Cubla',
    'Cubillos del Sil',
    'Cubells',
    'Cubelles',
    'Cubas',
    'Cuarte de Huerva',
    'Cuarte',
    'Cuacos',
    'Creixell',
    'Covarrubias',
    'Covaleda',
    'Couso de Salas',
    'Cosuenda',
    'Cospeito',
    'Coslada',
    'Coscojuela de Sobrarbe',
    'Cortijo de San Isidro',
    'Cortiguera',
    'Cortes',
    'Corca',
    'Corrales de Duero',
    'Corpa',
    'Cornella del Terri',
    'Cornellà de Llobregat',
    'Coristanco',
    'Corella',
    'Cordovin',
    'Corcubión',
    'Corbins',
    'Corbera de Llobregat',
    'Copons',
    'Constanti',
    'Constante',
    'Conforcos',
    'Concabella',
    'Compostela',
    'Comillas',
    'Combarro',
    'Colombres',
    'Colmenar Viejo',
    'Colmenarejo',
    'Colmenar de Oreja',
    'Colmenar del Arroyo',
    'Collsuspina',
    'Coll de Nargo',
    'Collbato',
    'Collado Villalba',
    'Collado Mediano',
    'Colindres',
    'Coles',
    'Coiro',
    'Cogolludo',
    'Coca',
    'Cobos de Segovia',
    'Cobena',
    'Cobeja',
    'Clariana de Cardener',
    'Zizurkil',
    'Cizur Menor',
    'Cizur Mayor',
    'Ciudad Rodrigo',
    'Ciutadella',
    'Cistierna',
    'Cisterniga',
    'Cistella',
    'Cisneros',
    'Ciperez',
    'Cintruénigo',
    'Cinera',
    'Cilleros',
    'Cigunuela',
    'Cigales',
    'Cifuentes',
    'Zierbena',
    'Ciempozuelos',
    'Cicero',
    'Soto del Real',
    'Chozas de Canales',
    'Chinchón',
    'Chiloeches',
    'Xerta',
    'Checa',
    'Chatun',
    'Chapineria',
    'Chapela',
    'Chantada',
    'Cesuras',
    'Cestona',
    'Cervo',
    'Cervia de Ter',
    'Cervera de Pisuerga',
    'Cervera del Rio Alhama',
    'Cervera',
    'Cervello',
    'Cerdeda',
    'Cercedilla',
    'Cerceda',
    'Centenera',
    'Centelles',
    'Cenicientos',
    'Cenicero',
    'Cembranos',
    'Celra',
    'Cella',
    'Celanova',
    'Celada del Camino',
    'Cegama',
    'Cee',
    'Cedrillas',
    'Cedillo del Condado',
    'Cedeira',
    'Cebreros',
    'Zeanuri',
    'Cazalegas',
    'Catoira',
    'el Catllar',
    'Castroverde de Campos',
    'Castroverde',
    'Castro Urdiales',
    'Castroponce',
    'Castropol',
    'Castropodame',
    'Castrogonzalo',
    'Castro de Rei',
    'Castrillon',
    'Castrillo de la Vega',
    'Castillo de Bayuela',
    "Castell-Platja d'Aro",
    'Castillo',
    'Castillazuelo',
    'Castiliscar',
    'Castellvi de Rosanes',
    'Castellvell del Camp',
    'Castelltercol',
    'Castellserà',
    'Castelló de Farfanya',
    "Castelló d'Empúries",
    'Castellfollit de la Roca',
    'Castellfort',
    'Castelldefels',
    'Castelldans',
    'Castellciutat',
    'Castellcir',
    'Castellbisbal',
    'Castellar del Valles',
    'Castellanos de Moriscos',
    'Castejon de Valdejasa',
    'Castejon de Sos',
    'Castejon',
    'Castandiello',
    'Cassà de la Selva',
    'Caspe',
    'Casillas',
    'Casetas',
    'Casserres',
    'Cascante',
    'Casavieja',
    'Casas de Don Gomez',
    'Casarrubuelos',
    'Casarrubios del Monte',
    'Casar de Palomero',
    'Casalarreina',
    'Cartes',
    'Carrizo de la Ribera',
    'Carrion de los Condes',
    'Carrascosa del Campo',
    'Carranque',
    'Carral',
    'Carnota',
    'Carme',
    'Carino',
    'Cardona',
    'Cardenadijo',
    'Cardedeu',
    'Carcastillo',
    'Carcar',
    'Carbonero el Mayor',
    'Carballo',
    'O Carballino',
    'Carballedo',
    'Carbajosa de la Sagrada',
    'Carbajal de la Legua',
    'Carazo',
    'Carasa',
    'Carandia',
    'Carabana',
    'Capcanes',
    'Capellades',
    'Capdesaso',
    'Caparroso',
    'Canyelles',
    'Canyamars',
    'Cantavieja',
    'Cantalojas',
    'Cantallops',
    'Cantalejo',
    'Cantagallo',
    'Cànoves i Samalús',
    'Canovelles',
    'Cangas de Onis',
    'Cangas del Narcea',
    'Cangas do Morrazo',
    'Canet lo Roig',
    'Canete',
    'Canet de Mar',
    "Canet d'Adri",
    'Canencia',
    'Candeleda',
    'Candas',
    'Cancienes',
    'Canamares',
    'Camprodon',
    'Campo Real',
    'Camponaraya',
    'Campomanes',
    'Campo',
    'Campins',
    'Campdevànol',
    'Campaspero',
    'Campano',
    'Landa',
    'Caminreal',
    'Camelle',
    'Cambroncino',
    'Cambrils',
    'Cambre',
    'Cambados',
    'Camarzana de Tera',
    'Camarma de Esteruelas',
    'Camarinas',
    'Camargo',
    'Camarenilla',
    'Camarena',
    'Camallera',
    'Calzadilla de Tera',
    'Calzadilla',
    'Calzada de Valdunciel',
    'Calzada de Don Diego',
    'Calig',
    'Calella de Palafrugell',
    'Calella',
    'Calders',
    'Caldas de Reis',
    'Caldes de Montbui',
    'Caldes de Malavella',
    "Caldes d'Estrac",
    'Calatayud',
    'Calanda',
    'Calamocha',
    'Calahorra',
    'Calafell',
    'Calaf',
    'Cadrete',
    'Cadreita',
    'Cadaqués',
    'Cadalso de los Vidrios',
    'Cacabelos',
    'Cabrils',
    'Cabrianes',
    'Cabrerizos',
    'Cabrera de Mar',
    'Cabreira',
    'Caborana',
    'Caboalles de Abajo',
    'Cabezuela del Valle',
    'Cabezon de la Sal',
    'Cabezas de Alambre',
    'Cabanillas del Campo',
    'Cabanillas de la Sierra',
    'Cabanillas',
    'Cabanes',
    'Cabanas de la Sagra',
    'Cabanas',
    'Cabanaquinta',
    'Bustarviejo',
    'Burlata',
    'Burgos',
    'Burgo',
    'Burela de Cabo',
    'Bunuel',
    'Bujarrabal',
    'Bujaraloz',
    'Buitrago del Lozoya',
    'Bueu',
    'Buenaventura',
    'Brunete',
    'Broto',
    'Briviesca',
    'Brión',
    'Brime de Sog',
    'Bretona',
    'Breda',
    'Boya',
    'Botarell',
    'Borriol',
    'Borrassa',
    'Borox',
    'les Borges del Camp',
    'Les Borges Blanques',
    'Borja',
    'Bordils',
    'Boquineni',
    'Bonmatí',
    'Bonastre',
    'Bonar',
    'Boltana',
    'Bolmir',
    'Boizan',
    'Boiro',
    'Boecillo',
    'Boca de Huergano',
    'Bobadilla del Campo',
    'Boadilla del Monte',
    'Blanes',
    'Biure de Gaia',
    'la Bisbal del Penedes',
    'Binefar',
    'Binaced',
    'Bilbao',
    'Bigues i Riells',
    'Biescas',
    'Beuntza',
    'Betren',
    'Betanzos',
    'Bespen',
    'Bescano',
    'Besalú',
    'Berron',
    'Berriz',
    'Berriozar',
    'Berriogoiti',
    'Berriobeiti',
    'Berriatua',
    'Bermes',
    'Bermeo',
    'Beriain',
    'Bergondo',
    'Berga',
    'Berastegui',
    'Berango',
    'Beranga',
    'Benlloch',
    'Benissanet',
    'Benicassim',
    'Benicarló',
    'Bengoeche-Aperribay',
    'Benavides',
    'Benavente',
    'Benavent de Segria',
    'Benasque',
    'Benassal',
    'Benavarri / Benabarre',
    'Bembibre',
    'Belver de Cinca',
    'Belorado',
    'Belmonte de Tajo',
    'Bellvis',
    'Bellvei',
    'Bellver de Cerdanya',
    'Belltall',
    'Bellpuig',
    "Bellmunt d'Urgell",
    "Bell-lloc d'Urgell",
    'Bellavista',
    'Belianes',
    'Belchite',
    'Bejar',
    'Begonte',
    'Begues',
    'Becerril de la Sierra',
    'Becerrea',
    'Beasain',
    'Bearin',
    'Baiona',
    'Baio',
    'Batea',
    'Bascara',
    'Barzana',
    'Barruelo de Santullan',
    'Barros',
    'Barro',
    'Barraco',
    'Bareyo',
    'Barcelona',
    'Barbastro',
    'Barbadillo',
    'Barbadelo',
    'Baranain',
    'Baralla',
    'Barajas',
    'Banos de Montemayor',
    'Banos de Molgas',
    'Banos de Cerrato',
    'Banyoles',
    'Banyeres del Penedes',
    'Bandeira',
    'Baltanas',
    'Balsareny',
    'Balaguer',
    'Bailo',
    'Begur',
    'Baga',
    'Badaran',
    'Badames',
    'Badalona',
    'Babilafuente',
    'Baamonde',
    'Azuqueca de Henares',
    'Azpeitia',
    'Azkoitia',
    'Azanuy',
    'Azagra',
    'Aiguafreda',
    'Ayegui',
    'Aya',
    'Avion',
    'Avinyó',
    'Avilés',
    'Ávila',
    'Autol',
    'Aunon',
    'Aulesti',
    'Audicana',
    'Ateca',
    'Ataun',
    'Asua',
    'Astrain',
    'Astorga',
    'Astigarraga',
    'Asteasu',
    'Arzua',
    'Arzalde',
    'Artesa de Segre',
    'Artesa de Lleida',
    'Artes',
    'Arteixo',
    'Arteaga',
    'Artazu',
    'Artaza',
    'Artajona',
    'Arrubal',
    'Arroyomolinos',
    'Arroyo de la Encomienda',
    'Arriondas',
    'Arrigorriaga',
    'Arredondo',
    'Arre',
    'Arrabal de Portillo',
    'Arnedo',
    'Arnedillo',
    'Armunia',
    'Armintza',
    'Armenteira',
    'Arlanza',
    'Arizgoiti',
    'Arino',
    'Arguedas',
    'Argonos',
    'Argentona',
    'Arganda',
    'Argame',
    'Arevalo',
    'Ares',
    'Arenys de Munt',
    'Arenys de Mar',
    'Arenas de San Pedro',
    'Arenas de Iguna',
    'Aretxabaleta',
    'Arcos de Jalon',
    'Arciniega',
    'Arcenillas',
    'Arce',
    'Arcade',
    'Arbucies',
    "L'Arboc",
    'Arbo',
    'Arbizu',
    'Arbeca',
    'Aratzuri',
    'Aranjuez',
    'Aranga',
    'Aranda de Duero',
    'Arantza',
    'Arabayona',
    'Aoiz',
    'Anzuola',
    'Anucita',
    'Anoeta',
    'Anguciana',
    'Anglesola',
    'Anglès',
    'Anero',
    'Andosilla',
    'Andorra',
    'Andoain',
    'Ancin',
    'Anchuelo',
    'Amurrio',
    'Ampuero',
    'Amposta',
    'Amorebieta',
    'Amoedo',
    'Amezqueta',
    'Ames',
    'Amer',
    'Amavida',
    'Altafulla',
    'Alsasua – Altsasu',
    'Alquiza',
    'Alpicat',
    'Alpedrete',
    'Alovera',
    'Alonsotegi',
    'Almudevar',
    'Almonacid de Zorita',
    'Almenar',
    'Almazan',
    'Almandoz',
    'Almacelles',
    'Allo',
    'Allariz',
    'Alio',
    'Aliaga',
    'Alguaire',
    'Algorta',
    'Algete',
    'Alforja',
    'Alfes',
    'Alfarràs',
    'Alfaro',
    'Alfamen',
    'Alfajarin',
    'Alella',
    'Alegria',
    'Aldeatejada',
    'Aldea Real',
    'Aldeanueva del Camino',
    'Aldeanueva de la Vera',
    'Aldeamayor de San Martin',
    'Aldea del Fresno',
    'Aldeadavila de la Ribera',
    'Alcover',
    'Alcorisa',
    'Alcorcón',
    "l'Alcora",
    'Alcoletge',
    'Alcolea del Pinar',
    'Alcolea de Cinca',
    'Alcocer',
    'Alcossebre',
    'Alcobendas',
    'Alcarras',
    'Alcañiz',
    'Alcanices',
    'Alcanar',
    'el Campell / Alcampell',
    'Alcalá de Henares',
    'Alcala de Gurrea',
    'Alcala de Xivert',
    'Alborge',
    'Albons',
    'Albocasser',
    'Albinyana',
    'Albillos',
    'Albesa',
    'Albelda de Iregua',
    'Albelda',
    'Albatarrec',
    'Albarracin',
    'Albalate de Zorita',
    'Albalate del Arzobispo',
    'Alba de Yeltes',
    'Alba de Tormes',
    'Alba de Cerrato',
    'Alar del Rey',
    'Alaraz',
    'Alalpardo',
    'Alagon',
    'Alaejos',
    'Ajalvir',
    'Aizoain',
    'Ainzon',
    'Ainsa',
    'Aiguaviva',
    'Ahigal',
    'Aguinaliu',
    'Aguilar de Campoo',
    'Aguero',
    'Aguaron',
    'Agreda',
    'Agramunt',
    'Agoncillo',
    'Agarre',
    'Aduna',
    'Adahuesca',
    'Acera de la Vega',
    'Abrera',
    'Abiego',
    'Abegondo',
    'Abárzuza',
    'Abadino',
    'Abades',
    'Balea',
    'A Cabana',
    'Torrellano',
    'Los Angeles de San Rafael',
    'Santa Ponsa',
    'Valdelacalzada',
    'Vensillo / Vencillon',
    'el Torrico / Altorricon',
    'Escairon',
    'Akartegi',
    'Agerre',
    'Playa del Ingles',
    'Puerto Rico',
    'Cala Murada',
    "Cala d'Or",
    'Palmanova',
    'Puerto del Carmen',
    'Cala San Vicente',
    'Magaluf',
    'Can Pastilla',
    'Peguera',
    'Port de Pollença',
    'Miami Platja',
    'Costa Teguise',
    'Almerimar',
    'La Manga del Mar Menor',
    'Cales de Mallorca',
    'Ceuta',
    'Sopuerta',
    'Coma-ruga',
    'Majorca',
    'Beguda',
    'Alquerias del Nino Perdido',
    'Benalmadena Costa',
    'Sant Marti',
    'Torrox Costa',
    'Costa Calma',
    'Platja de Gandia',
    "Playa d'en Bossa",
    'Formigal',
    'Costa del Silencio',
    'Las Marinas',
    'San Carlos',
    'Cala Ferrera',
    'El Portil',
    'Vera Playa',
    'Jesus Pobre',
    'El Santiscal',
    'Benalup-Casas Viejas',
    'Vecindario',
    'Euba',
    'Guadiaro',
    'Lestrobe',
    'Cotobade',
    'Montrove',
    'Segur de Calafell',
    'Sol',
    'Poble Sec',
    "S'illot",
    "l'Alfas del Pi",
    'Deltebre',
    'Las Gabias',
    'San Jose del Valle',
    'Camarles',
    "L'Albir",
    'Golf del Sur',
    'Santutxu',
    'Tolleric',
    'Sondika',
    'Guargacho',
    'Los Realejos',
    'Sant Guim de Freixenet',
    'Sant Joan de Mediona',
    'Santa Agnes de Malanyanes',
    'Pasaia',
    'El entrego',
    'Ojedo',
    'Playa Honda',
    'Basauri',
    'La Restinga',
    'Campllong',
    'Hernan Cortes',
    'Cala Santanyi',
    'Lugo de Llanera',
    'Playa de Muro',
    'Bellaterra',
    'Alfoquia',
    'San Juan de los Terreros',
    'Corvera de Asturias',
    'Pinar de Campoverde',
    'Orihuela Costa',
    'Playa Flamenca',
    'Fenazar',
    'La Cala De Mijas',
    'Mojacar Playa',
    'Carchuna',
    'Gorraiz',
    'els Poblets',
    'El Rasillo de Cameros',
    'Tres Cantos',
    'El Pla de Manlleu',
    'Aravell',
    'Costa de la Calma',
    'Boca de los Frailes',
    'Sangonera la Seca',
    'Santa Gertrudis de Fruitera',
    'Caleta de Velez',
    'Bakio',
    'Rivero de Posadas',
    'Bentraces',
    'Pueblonuevo de Miramontes',
    'Les Franqueses del Valles',
    'La Isleta del Moro',
    'Sa Rapita',
    "L'Aldea",
    'Sant Marcal',
    'Moriles',
    'El Time',
    'As Ferreiras',
    'Palmanyola',
    'Valarena',
    'Benajarafe',
    'Vila-sana',
    'A Gandara',
    'Bertamirans',
    'Bonavista',
    'El Parador de las Hortichuelas',
    'O Milladoiro',
    'Piedrafita de Babia',
    'Puente Tocinos',
    'Les Roquetes',
    'San Isidro de Nijar',
    'Son Ferrer',
    'Torre de Benagalbon',
    'Sitio de Calahonda',
    'Cho',
    'Sol de Mallorca',
    'Liendo',
    'Gordexola',
    'Lierganes',
    'Sant Julia de Ramis',
    'Olivenza',
    'Villanueva de Arosa',
    'Valle Gran Rey',
    'Costacabana',
    'Castellnou de Bages',
    'Colinas de San Antonio',
    'La Pobla de Farnals',
    'Cartaojal',
    'Alagon del Rio',
    'Trandeiras',
    'Callus',
    'La Gangosa',
    'Santa Maria del Aguila',
    'Ascoy',
    'Font-Rubi',
    "La Font d'en Carros",
    'Alzuza',
    'O Vicedo',
    'Balanegra',
    'les Roquetes',
    'Aluche',
    'Sarriguren',
    'Arinaga',
    'La Garita',
    'Villamayor de Gallego',
    'La Fortuna',
    'Mansilla del Esla',
  ],
  Italy: [
    'Zeddiani',
    'Zafferana Etnea',
    'Zaccanopoli',
    'Vizzini',
    'Vittoria',
    'Vita',
    'Villasor',
    'Villasmundo',
    'Villasimius',
    'Villa San Giovanni',
    'Villasalto',
    'Villarosa',
    'Villaputzu',
    'Villapiana',
    'Villanova Truschedu',
    'Villanova Strisaili',
    'Villanovafranca',
    'Villanovaforru',
    'Villamassargia',
    'Villamar',
    'Villafrati',
    'Villafranca Tirrena',
    'Villafranca Sicula',
    'Villacidro',
    'Villabate',
    'Vibo Valentia',
    'Viagrande',
    'Verzino',
    'Verbicaro',
    'Ventimiglia di Sicilia',
    'Venetico',
    'Varapodio',
    'Vallelunga Pratameno',
    'Vallefiorita',
    'Valledolmo',
    'Valguarnera Caropepe',
    'Valdina',
    'Vaccarizzo Albanese',
    'Uta',
    'Ussassai',
    'Ussaramanna',
    'Ussana',
    'Uras',
    'Ugento',
    'Ucria',
    'Turri',
    'Tuili',
    'Tropea',
    'Troina',
    'Tripi',
    'Triparni',
    'Tricase',
    'Trenta',
    'Trecastagni',
    'Trebisacce',
    'Trapani',
    'Trabia',
    'Tortorici',
    'Tortora',
    'Tortolì',
    'Campora San Giovanni',
    'Torrenova',
    'Torregrotta',
    'Tiriolo',
    'Tiggiano',
    'Teulada',
    'Tertenia',
    'Terrasini',
    'Terranova da Sibari',
    'Terralba',
    'Termini Imerese',
    'Taviano',
    'Taverna',
    'Taurisano',
    'Taurianova',
    'Tarsia',
    'Taormina',
    'Sutera',
    'Strongoli',
    'Stignano',
    'Stalettì',
    'Staiti',
    'Squillace',
    'Spilinga',
    'Spezzano Piccolo',
    'Spezzano della Sila',
    'Spezzano Albanese',
    'Specchia',
    "Sparta'",
    'Spadafora',
    'Soveria Simeri',
    'Soveria Mannelli',
    'Soverato Marina',
    'Sortino',
    'Soriano Calabro',
    'Sommatino',
    'Soleminis',
    'Solarussa',
    'Solarino',
    'Syracuse',
    'Sinnai',
    'Sinagra',
    'Simaxis',
    'Siliqua',
    'Sili',
    'Siderno',
    'Siculiana',
    'Sibari',
    'Siamanna',
    'Seui',
    'Settimo San Pietro',
    'Sestu',
    'Serri',
    'Serrenti',
    'Serrastretta',
    'Serra San Bruno',
    'Serramanna',
    'Serradifalco',
    'Serdiana',
    'Senorbì',
    'Senis',
    'Seminara',
    'Selargius',
    'Scordia',
    'Scilla',
    'Scicli',
    'Sciacca',
    'Scandale',
    'Scalea',
    'Satriano',
    'Sarroch',
    'Sardara',
    'Saracena',
    'Saponara',
    'San Vito sullo Ionio',
    'San Vito Lo Capo',
    'Santo Stefano Quisquina',
    'Santo Stefano in Aspromonte',
    'Santo Stefano di Camastra',
    'San Sperate',
    "Sant'Onofrio",
    'Santa Eufemia Lamezia',
    "Sant'Eufemia d'Aspromonte",
    'San Teodoro',
    'Santa Venerina',
    'Santa Teresa di Riva',
    "Santa Sofia d'Epiro",
    'SantAntioco',
    'Santa Ninfa',
    "Sant'Angelo Muxaro",
    "Sant'Angelo di Brolo",
    'Santa Maria di Licodia',
    'Santa Margherita di Belice',
    'Santa Lucia del Mela',
    "Sant'Alessio Siculo",
    'Santa Giusta',
    "Sant'Agata di Militello",
    'Santa Flavia',
    'Santa Elisabetta',
    'Santa Domenica',
    'Santadi',
    'Santa Croce Camerina',
    'Santa Caterina Villarmosa',
    'Villa San Pietro',
    'San Pietro in Guarano',
    'San Pietro a Maida',
    'San Piero Patti',
    'San Pier Niceto',
    'San Nicolò Gerrei',
    "San Nicolò d'Arcidano",
    'San Nicola da Crissa',
    'San Michele di Ganzaria',
    'San Mauro Marchesato',
    'San Mauro Castelverde',
    'San Martino di Finita',
    'San Marco Argentano',
    'Sanluri',
    'San Lucido',
    'San Luca',
    'San Lorenzo del Vallo',
    'San Lorenzo',
    "San Gregorio d'Ippona",
    'San Gregorio di Catania',
    'San Giuseppe Jato',
    'San Giovanni Suergiu',
    'Samo',
    'San Giovanni la Punta',
    'San Giovanni in Fiore',
    'San Giovanni Gemini',
    'San Giorgio Morgeto',
    'Sangineto',
    'San Gavino Monreale',
    'San Filippo del Mela',
    'San Filippo',
    'San Ferdinando',
    'San Donato di Ninea',
    'San Demetrio Corone',
    'San Costantino Calabro',
    'San Cosmo Albanese',
    'San Cipirello',
    'San Cataldo',
    'San Calogero',
    'San Biagio Platani',
    'Samugheo',
    'Sambuca di Sicilia',
    'Samassi',
    'Salerni',
    'Salemi',
    'Sadali',
    'Ruinas',
    'Ruffano',
    'Rovito',
    'Rotonda',
    'Rota Greca',
    'Rossano',
    'Rosolini',
    'Roseto Capo Spulico',
    'Rosarno',
    'Rombiolo',
    'Rogliano',
    'Roggiano Gravina',
    'Roccella Valdemone',
    'Roccella Ionica',
    'Roccavaldina',
    'Roccapalumba',
    'Roccalumera',
    'Rocca di Neto',
    'Roccabernarda',
    'Rizziconi',
    'Riposto',
    'Riola Sardo',
    'Riesi',
    'Ricadi',
    'Ribera',
    'Resuttano',
    'Rende',
    'Reitano',
    'Reggio Calabria',
    'Regalbuto',
    'Realmonte',
    'Ravanusa',
    'Randazzo',
    'Ramacca',
    'Ragusa',
    'Raffadali',
    'Raddusa',
    'Raccuja',
    'Racalmuto',
    'Racale',
    'Quattromiglia',
    "Quartu Sant'Elena",
    'Quartucciu',
    'Pula',
    'Prizzi',
    'Priolo Gargallo',
    'Presicce',
    'Praia a Mare',
    'Pozzo di Gotto',
    'Pozzallo',
    'Portoscuso',
    'Portopalo di Capo Passero',
    'Porto Empedocle',
    'Porticello',
    'Pollina',
    'Polizzi Generosa',
    'Polistena',
    'Platì',
    'Platania',
    'Plataci',
    'Pizzoni',
    'Pizzo',
    'Piscinas',
    'Pirri',
    'Piraino',
    'Pietraperzia',
    'Pietrafitta',
    'Piedimonte Etneo',
    'Piazza Armerina',
    'Pianopoli',
    'Piana degli Albanesi',
    'Pettineo',
    'Petrosino',
    'Petronà',
    'Petrizzi',
    'Petralia Soprana',
    'Petilia Policastro',
    'Perdaxius',
    'Perdasdefogu',
    'Pedara',
    'Patù',
    'Patti',
    'Paterno Calabro',
    'Paternò',
    'Partinico',
    'Partanna',
    'Parenti',
    'Paravati',
    'Paola',
    'Pantelleria',
    'Panettieri',
    'Palmi',
    'Palmas Arborea',
    'Palma di Montechiaro',
    'Palizzi Marina',
    'Palermo',
    'Palazzolo Acreide',
    'Palazzo Adriano',
    'Palagonia',
    'Pachino',
    'Paceco',
    'Osini',
    'Oristano',
    'Albagiara',
    'Ollastra Simaxis',
    'Oliveri',
    'Olivarella',
    'Nuxis',
    'Nurri',
    'Nuraminis',
    'Nurallao',
    'Novara di Sicilia',
    'Noto',
    'Nocera Terinese',
    'Nizza di Sicilia',
    'Nissoria',
    'Niscemi',
    'Nicotera',
    'Nicosia',
    'Nicolosi',
    'Nicastro',
    'Naso',
    'Naro',
    'Narcao',
    'Mussomeli',
    'Musei',
    'Muravera',
    "Motta Sant'Anastasia",
    'Motta San Giovanni',
    'Mormanno',
    'Montevago',
    'Monterosso Calabro',
    'Montepaone',
    'Montemaggiore Belsito',
    'Montelepre',
    'Montalto Uffugo',
    'Montalbano Elicona',
    'Montagnareale',
    'Monserrato',
    'Monreale',
    'Monforte San Giorgio',
    'Monastir',
    'Monasterace',
    'Molochio',
    'Mojo Alcantara',
    'Mogoro',
    'Modica',
    'Mistretta',
    'Misterbianco',
    'Misilmeri',
    'Mirabella Imbaccari',
    'Mineo',
    'Militello Rosmarino',
    'Militello in Val di Catania',
    'Milici',
    'Mileto',
    'Milena',
    'Milazzo',
    'Milanesi',
    'Migliuso',
    'Mezzojuso',
    'Messina',
    'Mesoraca',
    'Merì',
    'Menfi',
    'Mendicino',
    'Melito di Porto Salvo',
    'Melissano',
    'Melissa',
    'Melilli',
    'Melicucco',
    'Meana Sardo',
    'Mazzarino',
    'Mazara del Vallo',
    'Maracalagonis',
    'Masullas',
    'Mascalucia',
    'Mascali',
    'Masainas',
    'Marzi',
    'Marsala',
    'Marrubiu',
    'Marineo',
    'Marina di Schiavonea',
    'Monasterace Marina',
    'Marina di Gioiosa Ionica',
    'Marina di Fuscaldo',
    'Marina di Camerota',
    'Marcellinara',
    'Marano Marchesato',
    'Mandatoriccio',
    'Mandanici',
    'Mammola',
    'Malito',
    'Malfa',
    'Maletto',
    'Maierato',
    'Maida',
    'Macchia',
    'Luzzi',
    'Lungro',
    'Lotzorai',
    'Longi',
    'Locri',
    'Loceri',
    'Lipari',
    'Linguaglossa',
    'Linera',
    'Licodia Eubea',
    'Licata',
    'Librizzi',
    'Letojanni',
    'Lercara Friddi',
    'Leonforte',
    'Lentini',
    'Lazzaro',
    'Laureana di Borrello',
    'Lattarico',
    'Lascari',
    'Rocca di Capri Leone',
    'Lanusei',
    'Laino Castello',
    'Laino Borgo',
    'Laconi',
    'Itala',
    'Ispica',
    'Isola delle Femmine',
    'Isola di Capo Rizzuto',
    'Isili',
    'Ionadi',
    'Ilbono',
    'Iglesias',
    'Guspini',
    'Aprigliano',
    'Guardia Piemontese',
    'Guardavalle',
    'Grotteria',
    'Grotte',
    'Grimaldi',
    'Grammichele',
    'Gonnosfanadiga',
    'Gonnesa',
    'Gizzeria',
    'Girifalco',
    'Gioiosa Marea',
    'Gioiosa Ionica',
    'Gioia Tauro',
    'Gimigliano',
    'Giffone',
    'Gibellina',
    'Giarre',
    'Giarratana',
    'Giardini',
    'Giardini-Naxos',
    'Gesico',
    'Geraci Siculo',
    'Gerace',
    'Genoni',
    'Gela',
    'Gasperina',
    'Gangi',
    'Galati Marina',
    'Gagliano del Capo',
    'Gagliano Castelferrato',
    'Gaggi',
    'Fuscaldo',
    'Furtei',
    'Furci Siculo',
    'Frascineto',
    'Francofonte',
    'Francavilla di Sicilia',
    'Fossato Serralta',
    'Fordongianus',
    'Fluminimaggiore',
    'Floridia',
    'Fiumefreddo di Sicilia',
    'Fiumedinisi',
    'Firmo',
    'Filadelfia',
    'Ficarra',
    'Ficarazzi',
    'Ferruzzano',
    'Favignana',
    'Favara',
    'Falerna',
    'Fagnano Castello',
    'Fabrizia',
    'Erice',
    'Enna',
    'Elmas',
    'Domusnovas',
    'Dolianova',
    'Dinami',
    'Diamante',
    'Delianuova',
    'Decollatura',
    'Decimomannu',
    'Davoli',
    'Dasà',
    'Cutro',
    'Custonaci',
    'Curinga',
    'Crucoli',
    'Crotone',
    'Crosia',
    'Cropani',
    'Cotronei',
    'Borgata Costiera',
    'Cosenza',
    'Corsano',
    'Corleone',
    'Corigliano Calabro',
    'Condrò',
    'Condofuri',
    'Comiso',
    'Collesano',
    'Civita',
    'Cittanova',
    'Cirò Marina',
    'Cirò',
    'Cinquefrondi',
    'Cinisi',
    'Ciminna',
    'Cianciana',
    'Chiaravalle Centrale',
    'Chiaramonte Gulfi',
    'Cetraro',
    'Cessaniti',
    'Cesarò',
    'Cerisano',
    'Cerda',
    'Cerchiara di Calabria',
    'Cerami',
    'Centuripe',
    'Cefalù',
    "Cava d'Aliga",
    'Cattolica Eraclea',
    'Catenanuova',
    'Catanzaro',
    'Catania',
    'Castrovillari',
    'Castronovo di Sicilia',
    'Castrolibero',
    'Castrofilippo',
    'Castrignano del Capo',
    'Castiglione di Sicilia',
    'Castiglione Cosentino',
    'Castiadas',
    'Castelvetrano',
    'Casteltermini',
    'Castelluzzo',
    'Castelluccio Inferiore',
    'Castellana Sicula',
    'Castellammare del Golfo',
    'Castel di Lucio',
    'Castel di Judica',
    'Casteldaccia',
    'Castelbuono',
    'Cassano Allo Ionio',
    'Casole Bruzio',
    'Casarano',
    'Casa Pace',
    'Casabona',
    'Caronia',
    'Caroni',
    'Carolei',
    'Carlopoli',
    'Carloforte',
    'Carlentini',
    'Carini',
    'Cariati',
    'Carfizzi',
    'Careri',
    'Cardinale',
    'Carbonia',
    'Caraffa di Catanzaro',
    'Capri Leone',
    'Capoterra',
    'Capo Rizzuto',
    "Capo d'Orlando",
    'Dipignano',
    'Capizzi',
    'Capaci',
    'Canicattini Bagni',
    'Canicattì',
    'Camporeale',
    'Campofranco',
    'Campofiorito',
    'Campofelice di Roccella',
    'Campofelice di Fitalia',
    'Campobello di Mazara',
    'Campobello di Licata',
    'Cammarata',
    'Camigliatello Silano',
    'Caltavuturo',
    'Caltanissetta',
    'Caltagirone',
    'Caltabellotta',
    'Calatafimi',
    'Calatabiano',
    'Calasetta',
    'Calascibetta',
    'Cagliari',
    'Caccuri',
    'Caccamo',
    'Cabras',
    'Butera',
    'Buscemi',
    'Burgio',
    'Burcei',
    'Buccheri',
    'Bruzzano Zeffirio',
    'Bronte',
    'Brolo',
    'Brancaleone',
    'Bova Marina',
    'Bovalino',
    'Botricello',
    'Borgo Partenope',
    'Borgia',
    'Borgetto',
    'Bonifati',
    'Bompietro',
    'Bolognetta',
    'Bivongi',
    'Bivona',
    'Bisignano',
    'Bisacquino',
    'Bianco',
    'Biancavilla',
    'Belvedere Marittimo',
    'Belvedere di Spinello',
    'Belpasso',
    'Belmonte Mezzagno',
    'Belmonte Calabro',
    'Baucina',
    'Barrali',
    'Barrafranca',
    'Bari Sardo',
    'Barcellona Pozzo di Gotto',
    'Baratili San Pietro',
    'Villa Verde',
    'Balestrate',
    'Balata di Baida',
    'Bagnara Calabra',
    'Bagheria',
    'Bagaladi',
    'Bacu Abis',
    'Avola',
    'Augusta',
    'Assoro',
    'Assemini',
    'Aritzo',
    'Argusto',
    'Arena',
    'Ardore Marina',
    'Arbus',
    'Arborea',
    'Arbatax',
    'Aragona',
    'Andrano',
    'Amendolara',
    'Amantea',
    'Altomonte',
    'Altofonte',
    'Altilia',
    'Altavilla Milicia',
    'Alliste',
    'Aliminusa',
    'Alimena',
    'Alia',
    'Alì',
    'Alessano',
    'Alessandria della Rocca',
    'Alessandria del Carretto',
    'Alcara li Fusi',
    'Alcamo',
    'Albi',
    'Aidone',
    'Agrigento',
    'Agira',
    'Adrano',
    'Acri',
    'Acquedolci',
    'Acquarica del Capo',
    'Acquafredda',
    'Acquaformosa',
    "Aci Sant'Antonio",
    'Acireale',
    'Aci Catena',
    'Aci Castello',
    'Aci Bonaccorsi',
    'Acate',
    'Santa Maria Navarrese',
    'Cardedu',
    'Volpago del Montello',
    "Granarolo dell'Emilia e Viadagola",
    'Zoppola',
    'Zollino',
    'Zola Predosa',
    'Zogno',
    'Zocca',
    'Zoagli',
    'Zimella',
    'Zibido San Giacomo',
    'Ziano Piacentino',
    'Ziano di Fiemme',
    'Panchià',
    'Zevio',
    'Zero Branco',
    'Zermeghedo',
    'Zerbolò',
    'Zenson di Piave',
    'Zeme',
    'Zelo Buon Persico',
    'Zelarino',
    'Zanica',
    'Zanè',
    'Zandobbio',
    'Zambana',
    'Azzago',
    'Zagarolo',
    'Vomero',
    'Volvera',
    'Volturino',
    'Volturara Irpina',
    'Voltido',
    'Volterra',
    'Volta Mantovana',
    'Voltago Agordino',
    'Voltaggio',
    'Volpiano',
    'Volpara',
    'Volongo',
    'Volargne',
    'Volano',
    'Voghera',
    'Voghenza',
    'Vodo di Cadore',
    'Vobarno',
    'Vizzola Ticino',
    'Vivaro',
    'Viù',
    'Vitulazio',
    'Vitulano',
    'Vittuone',
    'Vittorio Veneto',
    'Vitorchiano',
    'Viterbo',
    'Vistrorio',
    'Visso',
    'Viserba',
    'Visciano',
    'Visano',
    'Virle Piemonte',
    'Sterzing',
    'Vinci',
    'Vinchiaturo',
    'Vimodrone',
    'Vimercate',
    'Villotta',
    'Lancenigo-Villorba',
    'Villongo',
    'Villimpenta',
    'Villetta Barrea',
    'Villesse',
    'Villazzano',
    'Villa Guardia-Villa Viani',
    'Villavesco',
    'Villa Verucchio',
    'Villaverla',
    'Vergano-Villa',
    'Villastrada',
    'Villastellone',
    'Villa Santina',
    'Villa Santa Lucia degli Abruzzi',
    'Villa Santa Lucia',
    'Villasanta',
    'Villar Perosa',
    'Villar Pellice',
    'Villaricca',
    'Villar Focchiardo',
    'Villanuova sul Clisi',
    'Villanterio',
    'Villanova',
    'Villanova Monferrato',
    'Villanova Mondovì',
    "Villanova d'Asti",
    "Villanova d'Ardenghi",
    "Villanova d'Albenga",
    'Villa Minozzo',
    'Villamaina',
    'Villalvernia',
    'Villa Literno',
    'Villalfonsina',
    'Villa Latina',
    'Villa Lagarina',
    'Villa Guardia',
    'Villaga',
    'Villafranca Piemonte',
    'Villafranca in Lunigiana',
    'Villafranca di Verona',
    "Villafranca d'Asti",
    'Villafalletto',
    'Villa Estense',
    'Villadossola',
    'Villadose',
    'Villa di Tirano',
    'Villa di Serio',
    'Villa del Conte',
    "Villa d'Almè",
    'Villa Cortese',
    'Villa Celiera',
    'Villa Castelli',
    'Niederdorf',
    'Villa Basilica',
    'Villa Bartolomea',
    'Villa',
    'Villa Carcina',
    'Viguzzolo',
    'Vigonza',
    'Vigonovo',
    'Vigone',
    'Vigolzone',
    'Vigolo Vattaro',
    'Vigo di Fassa',
    'Vigo di Cadore',
    'Vigodarzere',
    'Vignolo',
    'Vignola',
    'Vignate',
    'Vignanello',
    'Vignale Monferrato',
    'Vigliano Biellese',
    'Viggiù',
    'Viggiano',
    'Vigevano',
    'Vigatto',
    'Vigasio',
    'Vigarano Pieve',
    'Vigarano Mainarda',
    'Vietri sul Mare',
    'Vietri di Potenza',
    'Vieste',
    'Vidor',
    'Vidigulfo',
    'Vicovaro',
    'Vicopisano',
    'Vico nel Lazio',
    'Vicomoscano',
    'Vicomero',
    'Vicolungo',
    'Vicoli',
    'Vico Equense',
    'Vico del Gargano',
    'Vico Canavese',
    'Viciomaggio',
    'Vicenza',
    'Vicchio',
    'Vicarello',
    'Vicalvi',
    'Vibonati',
    'Viareggio',
    'Viano',
    'Viadana',
    'Vezzano sul Crostolo',
    'Vezzano Ligure',
    'Vezzano',
    'Vetto',
    'Vetralla',
    'Vestone',
    'Vestenanova',
    'Vespolate',
    'Vescovato',
    'Vescovana',
    'Verzuolo',
    'Verucchio',
    'Vertova',
    'Vertemate con Minoprio',
    'Verrone',
    'Verrès',
    'Veronella',
    'Verona',
    'Veroli',
    'Verolengo',
    'Verolavecchia',
    'Verolanuova',
    'Vernole',
    'Vernio',
    'Vernazza',
    'Vernate',
    'Vernasca',
    'Vernante',
    'Vermiglio',
    'Vermezzo',
    'Vergiate',
    'Verghereto',
    'Vergato',
    'Verdello',
    'Vercurago',
    'Vercelli',
    'Verceia',
    'Venzone',
    'Venturina',
    'Ventotene',
    'Ventimiglia',
    'Venosa',
    'Venice',
    'Venegono Superiore',
    'Venegono Inferiore',
    'Venasca',
    'Venaria Reale',
    'Venafro',
    'Feldthurns',
    'Vellezzo Bellini',
    'Velletri',
    'Veleso',
    'Vejano',
    'Veglie',
    'Veggiano',
    'Vedelago',
    'Vedano Olona',
    'Vecchiano-Nodica',
    'Vazzola',
    'Vasto',
    'Vasia',
    'Vasanello',
    'Varzi',
    'Varsi',
    'Vahrn',
    'Varese Ligure',
    'Varese',
    'Varenna',
    'Varedo',
    'Varazze',
    "Varano de' Melegari",
    'Varallo Pombia',
    'Varallo Sesia',
    "Vaprio d'Agogna",
    "Vaprio d'Adda",
    'Vanzago',
    'Vanzaghello',
    'Vandoies di Sotto',
    'Valvasone',
    'Valva',
    'Valstagna',
    'Valpiana',
    'Valperga',
    'Valmontone',
    'Valmadrera',
    'Vallo della Lucania',
    'Valli del Pasubio',
    'Vallerotonda',
    'Vallerano',
    'Vallemaio',
    'Valle di Maddaloni',
    'Vallecrosia',
    'Vallecorsa',
    'Vallata',
    'Valgrana',
    'Valfenera',
    'Valfabbrica',
    'Valenzano',
    'Valenza',
    'Valentano',
    'Valeggio sul Mincio',
    'Valduggia',
    'Valdobbiadene',
    'Val di Mezzo',
    'Valdagno',
    'Vairano Patenora',
    'Vajont',
    'Vailate',
    'Vaiano Cremasco',
    'Vaiano',
    'Vaglio Basilicata',
    'Vaglia',
    'Vado Ligure',
    'Vacri',
    'Uzzano',
    'Usmate Velate',
    'Usini',
    'Uscio',
    'Ururi',
    'Uri',
    'Urgnano',
    'Urbisaglia',
    'Urbino',
    'Urbania',
    'Umbertide',
    'Uggiate Trevano',
    'Uggiano la Chiesa',
    'Udine',
    'Tuscania',
    'Tursi',
    'Turrivalignani',
    'Turriaco',
    'Turi',
    'Turbigo',
    'Tuoro sul Trasimeno',
    'Tuglie',
    'Tufino',
    'Tufillo',
    'Tuenno',
    'Truccazzano',
    'Tronzano Vercellese',
    'Tromello',
    'Troia',
    'Trofarello',
    'Truden',
    'Trivigno',
    'Trivento',
    'Triuggio',
    'Trissino',
    'Trisobbio',
    'Triora',
    'Trino',
    'Trinitapoli',
    'Trigolo',
    'Triggiano',
    'Trieste',
    'Tricesimo',
    'Tricerro',
    'Tricarico',
    'Tribiano',
    'Tribano',
    "Trezzo sull'Adda",
    'Trezzano sul Naviglio',
    'Trezzano Rosa',
    'Treviso',
    'Trevi nel Lazio',
    'Trevignano Romano',
    'Trevignano',
    'Treviglio',
    'Trevi',
    'Trevenzuolo',
    'Pian di San Bartolo-Trespiano',
    'Tresnuraghes',
    'Tresigallo',
    'Trescore Cremasco',
    'Trescore Balneario',
    'Trequanda',
    'Trepuzzi',
    'Treppo Grande',
    'Trentola-Ducenta',
    'Trento',
    'Trentinara',
    'Treia',
    'Tregnago',
    'Treglio',
    'Tredozio',
    'Trecenta',
    'Trecchina',
    'Trecate',
    'Trebaseleghe',
    'Travo',
    'Travesio',
    'Traversetolo',
    'Travedona Monate',
    'Travagliato',
    'Trasaghis',
    'Trasacco',
    'Traona',
    'Transacqua',
    'Trani',
    'Trana',
    'Tramutola',
    'Tramonti',
    'Tradate',
    'Tovena',
    'Toscolano-Maderno',
    'Torviscosa',
    'Tortoreto',
    'Tortona',
    'Torrita di Siena',
    'Torrile',
    'Torri di Quartesolo',
    'Torricella Sicura',
    'Torricella Peligna',
    'Torricella in Sabina',
    'Torricella',
    'Torrice',
    'Torriana',
    'Torrevecchia Teatina',
    'Torre Santa Susanna',
    'Torre San Severo',
    'Torre San Patrizio',
    'Torre Pellice',
    'Torre Orsaia',
    'Torrenieri',
    'Torremaggiore',
    'Torreglia',
    "Torre d'Isola",
    'Torre di Santa Maria',
    'Torre di Mosto',
    'Torre del Lago Puccini',
    'Torre del Greco',
    'Torrecuso',
    'Torre Boldone',
    'Torrebelvicino',
    'Torre Annunziata',
    'Torrazza Coste',
    'Torpè',
    'Toro',
    'Torno',
    'Torniella',
    'Tornareccio',
    'Torlino Vimercati',
    'Toritto',
    'Turin',
    'Torgiano',
    'Torchiarolo',
    'Torchiara',
    'Torbole sul Garda',
    'Torbole Casaglia',
    'Tonara',
    'Tonale',
    'Tombolo',
    'Tolve',
    'Tolmezzo',
    'Tollo',
    'Tollegno',
    'Tolfa',
    'Tolentino',
    'Toirano',
    'Toffia',
    'Todi',
    'Toano',
    'Tizzano Val Parma',
    'Tivoli',
    'Tito',
    'Tissi',
    'Tirrenia',
    'Tiers',
    'Tirano',
    'Tione di Trento',
    'Tione degli Abruzzi',
    'Tiglieto',
    'Ticengo',
    'Thiesi',
    'Thiene',
    'Tezze sul Brenta',
    'Teti',
    'Tessera',
    'Tisens',
    'Tesero',
    'Terzigno',
    'Terricciola',
    'Terrazzo',
    'Terranuova Bracciolini',
    'Terracina',
    'Terni',
    'Ternate',
    'Termoli',
    'Termeno',
    'Terlizzi',
    'Terlan',
    'Zugliano-Terenzano-Cargnacco',
    'Terenten',
    'Teramo',
    'Teolo',
    'Tenno',
    'Temu',
    'Tempio Pausania',
    'Telgate',
    'Telese',
    'Tegoleto',
    'Teglio Veneto',
    'Teglio',
    'Teggiano',
    'Teano',
    'Tavullia',
    'Tavoleto',
    'Tavernole sul Mella',
    'Tavernola Bergamasca',
    'Tavazzano',
    'Tavarnuzze',
    'Tavagnacco',
    'Taurasi',
    'Tassullo',
    'Tarzo',
    'Tarvisio',
    'Tarquinia',
    'Tarcento',
    'Taranto',
    'Tarantasca',
    'Tarano',
    'Tamai',
    'Talmassons',
    'Talana',
    'Talamone',
    'Taino',
    'Taibon Agordino',
    'Taglio di Po',
    'Tagliacozzo',
    'Taggia',
    'Suzzara',
    'Suvereto',
    'Sutri',
    'Sustinente',
    'Susegana',
    'Susa',
    'Surbo',
    'Surano',
    'Supino',
    'Suno',
    'Summonte',
    'Sumirago',
    'Sulzano',
    'Sulmona',
    'Subiaco',
    'Subbiano',
    'Sturno',
    'Strozza',
    'Stroppiana',
    'Stroncone',
    'Strigno',
    'Striano',
    'Strevi',
    'Strettoia',
    'Stresa',
    'Strangolagalli',
    'Strambino',
    'Stradella',
    'Strada in Chianti',
    'Stra',
    'Storo',
    'Stornarella',
    'Stornara',
    'Stigliano',
    'Stienta',
    'Stia',
    'Stezzano',
    'Stenico',
    'Stellanello',
    'Stella Cilento',
    'Stazzema',
    'Stazzano',
    'Statte',
    'Staranzano',
    'Stanghella',
    'Stagno Lombardo',
    'Staffolo',
    'Squinzano',
    'Spresiano',
    'Spotorno',
    'Sporminore',
    'Spormaggiore',
    'Spongano',
    'Spoltore',
    'Spoleto',
    'Spirano',
    'Spinoso',
    "Spino d'Adda",
    'Spinetta Marengo',
    'Spinetoli',
    'Le Casine-Perignano-Spinelli',
    'Spineda',
    'Spinazzola',
    'Spina',
    'Spilimbergo',
    'Spilamberto',
    'Spigno Saturnia',
    'Spigno Monferrato',
    'Spessa',
    'Sperlonga',
    'Spello',
    'Sparone',
    'Sparanise',
    'Sozzago',
    'Sovicille',
    'Sovere',
    'Sottomarina',
    'Sotto il Monte Giovanni XXIII',
    'Sossano',
    'Sospirolo',
    'Sospiro',
    'Sorso',
    'Sorrento',
    'Sorisole',
    'Soriano nel Cimino',
    'Sori',
    'Sorgono',
    'Soresina',
    'Sorbolo',
    'Sorano',
    'Soragna',
    'Sora',
    'Soprabolzano',
    'Sonnino',
    'Sondrio',
    'Sondalo',
    'Soncino',
    'Sona',
    'Somma Vesuviana',
    'Sommariva Perno',
    'Sommariva del Bosco',
    'Somma Lombardo',
    'Sommacampagna',
    'Solto Collina',
    'Solopaca',
    'Solofra',
    'Solignano Nuovo',
    'Soliera',
    'Solferino',
    'Soleto',
    'Solesino',
    'Solero',
    'Soldano',
    'Solbiate Olona',
    'Solbiate Arno',
    'Solarolo Rainerio',
    'Solarolo',
    'Solaro',
    'Solagna',
    'Sogliano Cavour',
    'Sogliano al Rubicone',
    'Soci',
    'Soccavo',
    'Soave',
    'Schluderns',
    'Siziano',
    'Siusi',
    'Sissa',
    'Sirolo',
    'Sirmione',
    'Sirignano',
    'Siniscola',
    'Sinalunga',
    'Silvi Paese',
    'Silvi',
    'Sillavengo',
    'Silea',
    'Silanus',
    'Schlanders',
    'Signa',
    'Sigillo',
    'Siena',
    'Sicignano degli Alburni',
    'Siano',
    'Sgurgola',
    'Sezze',
    'Seveso',
    'Settimo Vittone',
    'Settimo Torinese',
    'Settecamini',
    'Sestri Ponente',
    'Sestri Levante',
    'Sestriere',
    'Sesto San Giovanni',
    'Sestola',
    'Sesto Imolese',
    'Sesto Fiorentino',
    'Sesto ed Uniti',
    'Sesto Calende',
    'Sesto al Reghena',
    'Sexten',
    'Sestino',
    'Sesta Godano',
    'Sessa Aurunca',
    'Servigliano',
    'Serrone',
    'Serravalle Sesia',
    'Serravalle Scrivia',
    'Serravalle Pistoiese',
    'Serravalle di Chienti',
    'Serra San Quirico',
    'Serra Riccò',
    'Serrara Fontana',
    'Serramazzoni',
    'Serracapriola',
    'Sernaglia della Battaglia',
    'Sermoneta',
    'Sermide',
    'Serino',
    'Serina',
    'Seriate',
    'Sergnano',
    'Seren del Grappa',
    'Seregno',
    'Seravezza',
    'Seppio',
    'Sennori',
    'Senise',
    'Senigallia',
    'Seniga',
    'Seneghe',
    'Senale',
    'Senago',
    'Semiana',
    'Selvazzano Dentro',
    'Sëlva',
    'Sellero',
    'Segrate',
    'Segni',
    'Seggiano',
    'Sedrina',
    'Sedriano',
    'Sedini',
    'Sedilo',
    'Sedico',
    'Sedegliano',
    'Secugnago',
    'Secondigliano',
    'Secinaro',
    'Seano',
    'Scorzè',
    'Scortichino',
    'Scorrano',
    'Scoppito',
    'Natz-Schabs',
    'Schio',
    'Schilpario',
    'Schiavon',
    'Schiavi di Abruzzo',
    'Gazzada Schianno',
    'Scerni',
    'Scauri',
    'Scarperia',
    'Scarnafigi',
    'Scarmagno',
    'Scarlino',
    'Scapoli',
    'Scanzano Jonico',
    'Scansano',
    'Scano di Montiferro',
    'Scanno',
    'Scandriglia',
    'Scandolara Ravara',
    'Scandicci',
    'Scandiano',
    'Scalenghe',
    'Scaldasole',
    'Scala',
    'Scafati',
    'Scafa',
    'Savona',
    'Savignano sul Rubicone',
    'Savignano sul Panaro',
    'Savigliano',
    'Saviano',
    'Sava',
    'Sassuolo',
    'Sasso Marconi',
    'Sassofortino',
    'Sassoferrato',
    'Sassofeltrio',
    'Sasso di Castalda',
    'Sassocorvaro',
    'Sassinoro',
    'Sassari',
    'Sassano',
    'Sarzana',
    'Sartirana Lomellina',
    'Sarteano',
    'Sarsina',
    'Saronno',
    'Sarola',
    'Sarno',
    'Sarnico',
    'Sarnano',
    'Sarmato',
    'Sarezzo',
    'Sarezzano',
    'Sarnthein',
    'Sarconi',
    'Sarcedo',
    'Sapri',
    'Sappada',
    'San Zenone al Lambro',
    'San Zenone degli Ezzelini',
    'San Vittore del Lazio',
    'San Vito Romano',
    'San Vito di Leguzzano',
    'San Vito di Fagagna',
    'San Vito di Cadore',
    'San Vito dei Normanni',
    'San Vito Chietino',
    'San Vito al Tagliamento',
    'San Vincenzo',
    'San Vero Milis',
    'San Vendemiano',
    'San Venanzo',
    'San Valentino Torio',
    'Santu Lussurgiu',
    'San Stino di Livenza',
    'Santo Stefano di Magra',
    'Santo Stefano di Cadore',
    'Santo Stefano del Sole',
    'Santo Stefano Belbo',
    'Santo Stefano al Mare',
    'Santo Spirito',
    'Santorso',
    "Sant'Oreste",
    'Santopadre',
    "Sant'Omero",
    "Sant'Ilario d'Enza",
    'Santi Cosma e Damiano',
    'Santhià',
    "Sant'Eufemia a Maiella",
    'San Terenziano',
    'Santeramo in Colle',
    "Sant'Elpidio a Mare",
    "Sant'Elia Fiumerapido",
    "Sant'Elia a Pianisi",
    "Sant'Elena",
    "Sant'Egidio alla Vibrata",
    'Santa Vittoria in Matenano',
    "Santa Vittoria d'Alba",
    'Santa Teresa Gallura',
    'Santa Sofia',
    "Sant'Arsenio",
    "Sant'Arpino",
    'Santarcangelo di Romagna',
    "Sant'Arcangelo",
    "Sant'Apollinare",
    'Santa Palomba',
    "Sant'Antonino di Susa",
    "Sant'Antonio Abate",
    "Sant'Antimo",
    "Sant'Anna d'Alfaedo",
    "Sant'Angelo Romano",
    "Sant'Angelo Lomellina",
    "Sant'Angelo Lodigiano",
    "Sant'Angelo le Fratte",
    "Sant'Angelo in Vado",
    "Sant'Angelo in Pontano",
    "Sant'Angelo di Piove di Sacco",
    "Sant'Angelo dei Lombardi",
    "Sant'Andrea in Casale",
    "Sant'Andrea di Conza",
    "Sant'Anastasia",
    'San Tammaro',
    "Sant'Ambrogio di Valpolicella",
    "Sant'Ambrogio di Torino",
    'Santa Marinella',
    'Santa Maria Nuova',
    'Santa Maria Maddalena',
    'Santa Maria Imbaro',
    'Venezzano',
    'Santa Maria di Sala',
    'Santa Maria',
    'Santa Maria degli Angeli',
    'Santa Maria Codifiume',
    'Santa Maria Capua Vetere',
    'Santa Maria a Vico',
    'Santa Maria a Monte',
    'Santa Margherita Ligure',
    "Santa Margherita d'Adige",
    "Sant'Albano Stura",
    "Sant'Agostino",
    "Sant'Agnello",
    'Santa Giustina in Colle',
    'Santa Giustina',
    'Santa Giuletta',
    "Sant'Agata sul Santerno",
    "Sant'Agata Feltria",
    "Sant'Agata de' Goti",
    "Sant'Agata Bolognese",
    "Sant'Agapito",
    'Santa Fiora',
    "Santa Croce sull'Arno",
    'Santa Croce di Magliano',
    'Santa Cristina',
    'Santa Cristina Val Gardena',
    'San Sigismondo',
    'San Severo',
    'San Severino Marche',
    'Sansepolcro',
    'San Secondo Parmense',
    'San Secondo',
    'San Sebastiano Curone',
    'San Sebastiano al Vesuvio',
    'San Salvo',
    'San Salvatore Telesino',
    'San Salvatore Monferrato',
    'San Rufo',
    'Sanremo',
    'San Quirino',
    "San Quirico d'Orcia",
    'San Quirico',
    'San Prospero',
    'San Prisco',
    'San Potito Ultra',
    'San Potito Sannitico',
    'San Ponso',
    "San Polo d'Enza",
    'San Polo dei Cavalieri',
    'San Pio delle Camere',
    'San Pietro Viminario',
    'San Pietro Vernotico',
    'San Pietro Mosezzo',
    'San Pietro Polesine',
    'San Pietro in Lama',
    'San Pietro in Gu',
    'San Pietro Infine',
    'San Pietro in Casale',
    'San Pietro di Feletto',
    'San Pietro al Tanagro',
    'San Pietro al Natisone',
    'San Piero in Bagno',
    'San Piero a Sieve',
    "San Pier d'Isonzo",
    "San Pier d'Arena",
    'San Pellegrino Terme',
    'San Paolo di Civitate',
    'San Paolo Bel Sito',
    'San Paolo Albanese',
    'San Paolo',
    "San Panfilo d'Ocre",
    'San Pancrazio Salentino',
    'San Nicolo',
    'San Nicola',
    'Sannicola',
    'Sannicandro Garganico',
    'Sannicandro di Bari',
    'San Nazzaro Sesia',
    "Sannazzaro de' Burgondi",
    'San Nazario',
    'San Miniato',
    'San Miniato Basso',
    'San Michele Salentino',
    'San Michele di Serino',
    'San Michele al Tagliamento',
    "San Michele all'Adige",
    'San Mauro Torinese',
    'San Mauro Pascoli',
    'San Mauro di Saline',
    "San Maurizio d'Opaglio",
    'San Maurizio Canavese',
    'San Marzano sul Sarno',
    'San Marzano di San Giuseppe',
    'San Martino Valle Caudina',
    'San Martino Siccomario',
    'San Martino in Rio',
    'San Martino in Pensilis',
    'San Martino in Campo',
    'San Martino di Venezze',
    'San Martino di Lupari',
    'San Martino Canavese',
    'San Martino Buon Albergo',
    'San Martino in Passiria',
    'San Marino',
    'San Mariano',
    'San Marco in Lamis',
    'San Marco Evangelista',
    'San Marco dei Cavoti',
    'San Marco',
    'San Marcello Pistoiese',
    'San Marcello',
    'San Mango Piemonte',
    'San Lupo',
    'San Lorenzo Nuovo',
    'San Lorenzo in Campo',
    'St. Lorenzen',
    'San Lorenzo al Mare',
    'San Lorenzello',
    'San Leucio del Sannio',
    'St. Leonhard in Passeier',
    'San Lazzaro di Savena',
    'Sanguinetto',
    'San Gregorio Magno',
    'San Gregorio Matese',
    'San Godenzo',
    'San Giusto Canavese',
    'San Giustino',
    'San Giuseppe Vesuviano',
    'San Giuliano Vecchio',
    'San Giuliano Terme',
    'San Giuliano Milanese',
    'San Giuliano di Puglia',
    'San Giovanni Valdarno',
    'San Giovanni Rotondo',
    'San Giovanni Lupatoto',
    'San Giovanni in Persiceto',
    'San Giovanni in Marignano',
    'San Giovanni in Croce',
    'San Giovanni Incarico',
    'San Giovanni Ilarione',
    'San Giovanni del Dosso',
    'San Giovanni Bianco',
    'San Giovanni a Teduccio',
    'San Giovanni a Piro',
    'San Giovanni al Natisone',
    'San Giorgio Piacentino',
    'San Giorgio la Molara',
    'San Giorgio Ionico',
    'San Giorgio in Salici',
    'San Giorgio in Bosco',
    'San Giorgio di Piano',
    'San Giorgio di Nogaro',
    'San Giorgio del Sannio',
    'San Giorgio della Richinvelda',
    'San Giorgio Canavese',
    'San Giorgio a Liri',
    'San Giorgio a Cremano',
    'San Giorgio',
    'San Ginesio',
    'San Gimignano',
    'San Gillio',
    'San Giacomo degli Schiavoni',
    'San Gervasio Bresciano',
    'San Germano Vercellese',
    'San Genuario',
    'San Gennaro Vesuviano',
    'San Genesio ed Uniti',
    'Jenesien',
    'San Gemini',
    'Sangano',
    'Sanfront',
    'Sanfrè',
    'Aglientu',
    'San Francesco al Campo',
    'San Foca',
    'San Fior di Sotto',
    'San Fior',
    'San Fiorano',
    'San Ferdinando di Puglia',
    'San Felice sul Panaro',
    'San Felice del Benaco',
    'San Felice Circeo',
    'San Felice',
    'San Fele',
    'Sandrigo',
    'San Dorligo della Valle',
    'San Donato Milanese',
    'San Donato di Lecce',
    'San Donà di Piave',
    'San Donaci',
    'Sandon',
    'Sandigliano',
    "San Demetrio Ne' Vestini",
    'San Daniele del Friuli',
    "San Damiano d'Asti",
    'San Cristoforo',
    'San Costanzo',
    'San Colombano Certénoli',
    'San Colombano al Lambro',
    'San Clemente',
    'San Cipriano Picentino',
    'San Cesario sul Panaro',
    'San Cesario di Lecce',
    'San Cesareo',
    'San Cassiano',
    'San Casciano in Val di Pesa',
    'San Casciano dei Bagni',
    'San Carlo',
    'Innichen',
    'San Buono',
    'San Bonifacio',
    'San Biagio di Callalta',
    'San Biagio della Cima',
    'San Bernardino',
    'San Benigno Canavese',
    'San Benedetto Val di Sambro',
    'San Benedetto Po',
    'San Benedetto dei Marsi',
    'San Benedetto del Tronto',
    'San Bellino',
    'San Bartolomeo in Galdo',
    'San Baronto',
    'Sampeyre',
    'Samone',
    'Sammichele di Bari',
    'Samarate',
    'Salzano',
    'Salza Irpina',
    'Salvirola',
    'Salvaterra',
    'Saluzzo',
    'La Salute di Livenza',
    'Salussola',
    'Saluggia',
    'Saludecio',
    'Salsomaggiore Terme',
    'Salorno',
    'Salò',
    'Salizzole',
    'Saliceto',
    'Salice Salentino',
    'Salgareda',
    'Saletto',
    'Salerno',
    'Salento',
    'Sale Marasino',
    'Sale',
    'Salcedo',
    'Salassa',
    'Salandra',
    'Sala Consilina',
    'Sala Bolognese',
    'Sala Biellese',
    'Sala Baganza',
    'Saint-Vincent',
    'Sagrado',
    'Sacrofano',
    'Sacile',
    'Saccolongo',
    'Sacco',
    'Sabbioneta',
    'Sabbio Chiese',
    'Sabaudia',
    'Ruvo di Puglia',
    'Ruviano',
    'Rutigliano',
    'Rustico',
    'Russi',
    'Monrupino',
    'Rufina',
    'Rudiano',
    'Rubiera',
    'Rubiana',
    'Rubano',
    'Rozzano',
    'Rovolon',
    'Rovigo',
    'Roviano',
    'Rovetta',
    'Rovereto',
    'Roveredo in Piano',
    'Roverè della Luna',
    'Roverbella',
    'Rovello Porro',
    'Rovellasca',
    'Rovato',
    'Rovasenda',
    'Rovagnate',
    'Rottofreno',
    'Rotondi',
    'Rotella',
    'Rossiglione',
    'Rossano Veneto',
    'Rosora',
    'Rosolina',
    'Rosignano Solvay-Castiglioncello',
    'Rosignano Marittimo',
    'Roseto degli Abruzzi',
    'Rosello',
    'Roscigno',
    'Rosciano',
    'Rosate',
    'Rosasco',
    'Rosa',
    'Rosà',
    'Ronzone',
    'Rondissone',
    'Ronco Scrivia',
    'Ronco Pascolo',
    'Roncone',
    'Roncofreddo',
    'Roncoferraro',
    'Ronco Canavese',
    "Ronco all'Adige",
    'Ronciglione',
    'Ronchi dei Legionari',
    'Roncegno',
    'Roncade',
    'Roncà',
    'Romentino',
    "Romans d'Isonzo",
    'Romano di Lombardia',
    'Romano Canavese',
    'Romanengo',
    'Romagnano Sesia',
    'Rome',
    'Rolo',
    'Rognano',
    'Rofrano',
    'Rodigo',
    'Rodi Garganico',
    'Rodano',
    'Rocchetta Nervina',
    'Rocchetta di Vara',
    'Roccavione',
    'Roccastrada',
    'Rocca Sinibalda',
    'Roccasecca',
    'Rocca San Casciano',
    'Roccaraso',
    'Roccarainola',
    'Rocca Priora',
    'Roccapiemonte',
    'Rocca Pia',
    'Roccamontepiano',
    'Roccamonfina',
    'Roccamandolfi',
    'Roccafranca',
    'Roccaforte Mondovì',
    'Rocca di Papa',
    'Rocca di Botte',
    "Rocca d'Evandro",
    'Roccadaspide',
    'Roccacasale',
    'Roccabianca',
    'Rocca',
    'Robilante',
    'Robecco sul Naviglio',
    "Robecco d'Oglio",
    'Robecchetto con Induno',
    'Robbio',
    'Robbiate',
    'Robassomero',
    'Roasio',
    'Roana',
    'Ro',
    "Rivolta d'Adda",
    'Rivoli',
    'Rivodutri',
    'Rivignano',
    'Rivergaro',
    'Rivello',
    "Rive d'Arcano",
    'Rivarolo Mantovano',
    'Rivarolo del Re ed Uniti',
    'Rivarolo Canavese',
    'Rivara',
    'Riva presso Chieri',
    'Rivanazzano',
    'Rivamonte Agordino',
    'Rivalta di Torino',
    'Riva Ligure',
    'Rivalba',
    'Riva del Garda',
    'Ripi',
    'Ripe San Ginesio',
    'Ripatransone',
    'Ripa Teatina',
    'Riparbella',
    'Ripapersico',
    'Ripalta Cremasca',
    'Ripalimosani',
    'Ripacandida',
    'Rioveggio',
    'Rio Saliceto',
    'Rionero Sannitico',
    'Rionero in Vulture',
    'Rio Marina',
    'Riolunato',
    'Riolo Terme',
    'Mühlbach',
    'Rimini',
    'Rigolato',
    "Rignano sull'Arno",
    'Rignano Garganico',
    'Rignano Flaminio',
    'Schenna',
    'Rieti',
    'Riese Pio X',
    'Riccò del Golfo di Spezia',
    'Riccione',
    'Ribolla',
    'Riardo',
    'Riano',
    'Rho',
    'Rezzoaglio',
    'Rezzo',
    'Rezzato',
    'Revere',
    'Revello',
    'Retorbido',
    'Rescaldina',
    'Resana',
    'Renazzo',
    'Remedello di Sopra',
    'Remanzacco',
    'Regona',
    'Reggio Emilia',
    'Reggiolo',
    'Reggello',
    'Refrontolo',
    'Redagno di Sopra',
    'Recoaro Terme',
    'Recetto',
    'Recco',
    'Recanati',
    'Recale',
    'Reano',
    'Raviscanina',
    'Ravenna',
    'Ravello',
    'Ravarino',
    'Rassina',
    'Rapone',
    'Rapolla',
    'Rapolano Terme',
    'Rapallo',
    'Rapagnano',
    'Rancio Valcuvia',
    'Raiano',
    'Radicofani',
    'Radda in Chianti',
    'Ratschings',
    'Racconigi',
    'Quistello',
    "Quinzano d'Oglio",
    'Quinto Vicentino',
    'Quinto di Treviso',
    'Quincinetto',
    'Quiliano',
    'Quero',
    'Ripa-Pozzi-Querceta-Ponterosso',
    'Quattro Castella',
    'Quattordio',
    'Quarto Inferiore',
    "Quarto d'Altino",
    'Quarto',
    'Quartesana',
    'Quart',
    'Quarrata',
    'Quarona',
    'Quarna Sopra',
    'Quargnento',
    'Qualiano',
    'Quadri',
    'Putignano',
    'Pusiano',
    "Puos d'Alpago",
    'Punta Marina',
    'Pumenengo',
    'Pulsano',
    'Pulfero',
    'Puianello',
    'Prossedi',
    'Prosecco-Contovello',
    'Procida',
    'Priverno',
    'Priola',
    'Prignano sulla Secchia',
    'Prignano Cilento',
    'Pressana',
    'Presenzano',
    'Premosello-Chiovenda',
    'Premeno',
    'Premariacco',
    'Premana',
    'Preglia',
    'Preganziol',
    'Predosa',
    'Predore',
    'Prettau',
    'Predazzo',
    'Prazzo Superiore',
    'Pray',
    'Pravisdomini',
    'Pratovecchio',
    'Prato Sesia',
    'Pratola Serra',
    'Pratola Peligna',
    'Prato',
    'Prato Carnico',
    'Prad am Stilfser Joch',
    'Pratella',
    'Prata Sannita',
    'Prata di Principato Ultra',
    'Prata di Pordenone',
    'Prata Camportaccio',
    'Prarolo',
    'Pralungo',
    'Pralormo',
    'Pralboino',
    'Praiano',
    'Prado',
    'Pra',
    'Pozzuolo del Friuli',
    'Pozzuoli',
    'Pozzovetere',
    'Pozzonovo',
    'Pozzolo Formigaro',
    'Pozzoleone',
    'Pozzolengo',
    'Pozzilli',
    'Pozzaglio ed Uniti',
    'Pozza di Fassa',
    'Povoletto',
    'Poviglio',
    'Povegliano Veronese',
    'Potenza Picena',
    'Potenza',
    'Postioma',
    'Burgstall',
    'Posta Fibreno',
    'Positano',
    'Posada',
    'Portula',
    'Portovenere',
    'Porto Valtravaglia',
    'Porto Torres',
    'Porto Tolle',
    'Porto Santo Stefano',
    "Porto Sant'Elpidio",
    'Porto San Giorgio',
    'Porto Recanati',
    'Porto Potenza Picena',
    'Portomaggiore',
    'Portogruaro',
    'Porto Garibaldi',
    'Porto Fuori',
    'Portofino',
    'Portoferraio',
    "Porto d'Ascoli",
    'Civitanova Marche',
    'Porto Ceresio',
    'Portocannone',
    'Porto Badino',
    'Porto Azzurro',
    'Portico di Caserta',
    'Portici',
    'Porto Ercole',
    'Portegrandi I',
    'Portalbera',
    'Porretta Terme',
    'Porpetto',
    'Porotto-Cassama',
    'Porlezza',
    'Pordenone',
    'Porcia',
    'Porcari',
    'Poppi',
    'Ponzone',
    'Ponzano di Fermo',
    'Ponza',
    'Pont-Saint-Martin',
    'Pontremoli',
    'Pontinia',
    'Pontida',
    'Ponticelli',
    'Pontevico',
    'Ponte Valleceppi',
    'Lavena Ponte Tresa',
    'Pontestura',
    'Ponte San Pietro',
    'Ponte San Nicolo',
    'Pontenure',
    'Ponte Nossa',
    'Ponte nelle Alpi',
    'Pontelongo',
    'Pontelatone',
    'Pontelandolfo',
    'Ponte Lambro',
    'Ponte Galeria-La Pisana',
    'Ponte di Piave',
    'Ponte di Legno',
    'Pontedera',
    "Ponte dell'Olio",
    'Pontedassio',
    'Pontecurone',
    'Pontecorvo',
    'Pontecchio Polesine',
    'Pontecagnano',
    'Ponte Buggianese',
    'Ponte A Tressa',
    'Ponte a Evola',
    'Pontassieve',
    'Ponsacco',
    'Poncarale',
    'Pomponesco',
    'Pompiano',
    'Pompeiana',
    'Pompei',
    "Pomigliano d'Arco",
    'Pomezia',
    'Pombia',
    'Pomarico',
    'Pomaretto',
    'Pomarance',
    'Polverigi',
    'Polverara',
    'Pollone',
    'Pollica',
    'Pollenza',
    'Pollein',
    'Polla',
    'Polino',
    'Polinago',
    'Polignano a Mare',
    'Policoro',
    'Polesine',
    'Polesella',
    'Polcenigo',
    'Poirino',
    'Poiano',
    'Poiana Maggiore',
    'Pogno',
    'Pognana Lario',
    'Pogliano Milanese',
    'Poggiridenti',
    'Poggio San Vittorino',
    'Poggio Rusco',
    'Poggio Renatico',
    'Villa Opicina',
    'Poggio Picenze',
    'Poggio Nativo',
    'Poggio Moiano',
    'Poggio Mirteto',
    'Poggiomarino',
    'Poggio Imperiale',
    'Poggio Catino',
    'Poggio Bustone',
    'Poggio Berni',
    'Poggio a Caiano',
    'Poggibonsi',
    'Poggiardo',
    'Poggetto',
    'Pofi',
    'Podenzano',
    'Pocenia',
    'Pizzoli',
    'Pizzighettone',
    'Piuro',
    'Piubega',
    'Piteglio',
    'Pistoia',
    'Pisticci',
    'Pisogne',
    'Pisciotta',
    'Piscinola',
    'Pisa',
    'Piozzano',
    'Piovene Rocchette',
    'Piove di Sacco',
    'Piossasco',
    'Pioraco',
    'Piombino Dese',
    'Piombino',
    'Pioltello',
    'Piobesi Torinese',
    "Piobesi d'Alba",
    'Pinzolo',
    'Pineto',
    'Pinerolo',
    'Pincara',
    'Pinarella',
    'Pilcante',
    'Pigra',
    'Pignola',
    'Pignataro Maggiore',
    'Pignataro Interamna',
    'Piglio',
    'Pieve Vergonte',
    'Pieve Santo Stefano',
    'Pieve San Giacomo',
    'Pieve Porto Morone',
    'Pievepelago',
    'Pieve Ligure',
    'Pieve Fosciana',
    'Pieve di Teco',
    'Pieve di Soligo',
    'Pieve di Cento',
    'Pieve di Cadore',
    'Pieve Albignola',
    'Pietrelcina',
    'Pietrastornina',
    'Pietrasanta',
    'Pietramelara',
    'Pietralunga',
    'Pietra Ligure',
    'Pietragalla',
    "Pietra de' Giorgi",
    'Pietrabbondante',
    'Pietole',
    'Pieris',
    'Pienza',
    'Piegaro',
    'Piedimulera',
    'Piedimonte San Germano',
    'Piedimonte',
    'Picerno',
    'Piazzola sul Brenta',
    'Piazza Brembana',
    'Piazza al Serchio',
    'Piateda',
    'Piasco',
    'Pianura',
    'Piantonia',
    'Piantedo',
    'Pianoro',
    'Piano di Sorrento',
    'Follo',
    'Pianiga',
    'Piangipane',
    'Pianezze',
    'Pianezza',
    'Pianello Val Tidone',
    'Pianella',
    'Pian di Scò',
    'Piandimeleto',
    'Piano del Voglio',
    'Piancogno',
    'Piancastagnaio',
    'Pian Camuno',
    'Piana Crixia',
    'Piaggine',
    'Piadena',
    'Piacenza',
    'Lama Pezzoli',
    'Pezze di Greco',
    'Pezzana',
    'Peveragno',
    'Pettorazza Grimani',
    'Pettinengo',
    'Petritoli',
    'Petrignano',
    'Petriano',
    'Petina',
    'Petacciato',
    'Pessione',
    'Pessinetto',
    'Pessina Cremonese',
    'Pesco Sannita',
    'Pescorocchiano',
    'Pescocostanzo',
    'Pescina',
    'Pescia',
    'Peschiera del Garda',
    'Peschici',
    'Pesche',
    'Pescasseroli',
    'Pescara del Tronto',
    'Pescara',
    'Pescantina',
    'Pescaglia',
    'Pesaro',
    'Perugia',
    'Pertosa',
    'Persano',
    'Perosa Argentina',
    'Pero',
    'Pernumia',
    'Perito',
    'Pergola',
    'Pergine Valsugana',
    'Pergine Valdarno',
    'Perdifumo',
    'Perano',
    'Penne',
    'Pennapiedimonte',
    'Pennabilli',
    'Pellezzano',
    'Pelago',
    'Pegognaga',
    'Pederobba',
    'Pedavena',
    'Pedaso',
    'Pecorara',
    'Peccioli',
    'Pavullo nel Frignano',
    'Pavone del Mella',
    'Pavone Canavese',
    'Pavona',
    'Pavia',
    'Pavarolo',
    'Paupisi',
    'Paullo',
    'Paularo',
    'Patrica',
    'Paternopoli',
    'Paterno',
    'Pastrengo',
    'Pastena',
    'Passo di Treia',
    'Passo Corese',
    'Passirano',
    'Passignano sul Trasimeno',
    'Pasian di Prato',
    'Parzanica',
    'Paruzzaro',
    'Parre',
    'Parrano',
    'Parona',
    'Parma',
    'Partschins',
    'Paratico',
    'Parabita',
    'Parabiago',
    'Paolisi',
    'Panzano in Chianti',
    'Pantigliate',
    'Panni',
    'Pannarano',
    'Panicale',
    'Pandino',
    'Pancalieri',
    'Palù',
    'Palosco',
    'Palombaro',
    'Palombara Sabina',
    'Palo del Colle',
    'Palmanova',
    'Palma Campania',
    "Palazzolo sull'Oglio",
    'Pallare',
    'Pallanza',
    'Palinuro',
    'Paliano',
    'Palestrina',
    'Palena',
    'Palazzuolo sul Senio',
    'Palazzo San Gervasio',
    'Palau',
    'Palata',
    'Palanzano',
    'Palaia',
    'Palagiano',
    'Palagianello',
    'Palagano',
    'Pago Veiano',
    'Paglieta',
    'Paganico',
    'Pagani',
    'Paese',
    'Paesana',
    'Paduli',
    'Padula',
    'Padru',
    'Padriciano',
    'Padova',
    'Paderno Franciacorta',
    'Paderno Dugnano',
    "Paderno d'Adda",
    'Paderno Ponchielli',
    'Padenghe sul Garda',
    'Pacentro',
    'Ozzano Monferrato',
    'Ozieri',
    'Ovindoli',
    'Ovaro',
    'Ovada',
    'Oulx',
    'Ottaviano',
    'Ottati',
    'Otricoli',
    'Otranto',
    'Ostuni',
    'Ostra',
    'Ostiglia',
    'Ostiano',
    'Ostellato',
    'Ossona',
    'Ossi',
    'Ossago Lodigiano',
    'Ospitaletto',
    'Ospedalicchio',
    'Ospedaletto Euganeo',
    "Ospedaletto d'Alpinolo",
    'Ospedaletto',
    'Ospedaletti',
    'Osoppo',
    'Osnago',
    'Osmate',
    'Osimo',
    'Osilo',
    'Oschiri',
    'Orzivecchi',
    'Orzinuovi',
    'Orvieto',
    'Ortucchio',
    'Ortonovo',
    'Ortona',
    'Urtijëi',
    'Ortezzano',
    'Orte',
    'Orta San Giulio',
    'Orta Nova',
    'Orsogna',
    'Orsenigo',
    'Orsara di Puglia',
    'Orosei',
    'Ornavasso',
    'Ornago',
    'Oriolo Romano',
    'Oriolo',
    'Orio Litta',
    'Orio al Serio',
    'Origgio',
    'Oricola',
    'Oria',
    'Orgosolo',
    'Ordona',
    'Orciano di Pesaro',
    'Orbetello',
    'Orbassano',
    'Oratino',
    'Orani',
    'Auer',
    'Oppeano',
    'Opera',
    'Onifai',
    'Onano',
    'Omignano Scalo',
    'Omignano',
    'Omegna',
    'Olmeneta',
    'Olmedo',
    'Oliveto Lario',
    'Oliveto Citra',
    'Oliena',
    'Olginate',
    'Olgiate Olona',
    'Olgiate Comasco',
    'Olevano sul Tusciano',
    'Olevano Romano',
    'Oleggio',
    'Olcenengo',
    'Olbia',
    'Ogliastro Cilento',
    'Oglianico',
    'Oggiono',
    'Offida',
    'Offanengo',
    'Ofena',
    'Odolo',
    'Oderzo',
    'Occimiano',
    'Occhiobello',
    'Occhieppo Superiore',
    'Occhieppo Inferiore',
    'Nuvolento',
    'Nusco',
    'Nuoro',
    'Numana',
    'Nulvi',
    'Nule',
    'Novoli',
    'Novi Velia',
    'Novi Ligure',
    'Novi di Modena',
    'Noventa Vicentina',
    'Noventa di Piave',
    'Novellara',
    'Nove',
    'Novate Milanese',
    'Novate Mezzola',
    'Novara',
    'Deutschnofen',
    'Nova Milanese',
    'Welschnofen',
    'Novalesa',
    'Novafeltria',
    'Notaresco',
    'Norma',
    'Norcia',
    'Norbello',
    'Nonantola',
    'Noli',
    'Nole',
    'Nola',
    'Noicattaro',
    'Nogaredo di Prato',
    'Nogara',
    'Nociglia',
    'Noci',
    'Noceto',
    'Nocera Umbra',
    'Nocera Superiore',
    'Nocera Inferiore',
    'Nocelleto',
    'Nocara',
    'Noale',
    'Nizza Monferrato',
    'Nimis',
    'Nichelino',
    'Nibbiano',
    'Neviano degli Arduini',
    'Neviano',
    'Nettuno',
    'Nespolo',
    'Nerviano',
    'Nervesa della Battaglia',
    'Nerola',
    'Nereto',
    'Nepi',
    'Nemi',
    'Nembro',
    'Neive',
    'Negrar',
    'Nazzano',
    'Nave',
    'Navacchio',
    'Naturns',
    'Narzole',
    'Narni Scalo',
    'Narni',
    'Nardò',
    'Narbolia',
    'Naples',
    'Nago–Torbole',
    'Muzzana del Turgnano',
    'San Pietro Mussolino',
    'Musso',
    'Musile di Piave',
    'Muros',
    'Muro Lucano',
    'Muro Leccese',
    'Murlo',
    'Murialdo',
    'Murano',
    'Mura',
    'Mulazzo',
    'Mugnano di Napoli',
    'Mugnano del Cardinale',
    'Muggiò',
    'Muggia',
    'Mozzecane',
    'Mozzate',
    'Mozzanica',
    'Mottola',
    'Mottaziana',
    'Motta Visconti',
    'Motta Montecorvino',
    'Motta di Livenza',
    'Motta De Conti',
    'Motta Baluffi',
    'Mossa',
    'Moos in Passeier',
    'Moso',
    'Moscufo',
    "Mosciano Sant'Angelo",
    'Moscazzano',
    'Moruzzo',
    'Mortegliano',
    'Mortara',
    'Morrovalle',
    'Morrone del Sannio',
    "Morro d'Alba",
    'Morozzo',
    'Mornago',
    'Morlupo',
    'Morigerati',
    'Moriago della Battaglia',
    'Mori',
    'Morgex',
    'Moretta',
    'Mores',
    'Mordano',
    'Morcone',
    'Morciano di Romagna',
    'Morbegno',
    'Morano sul Po',
    'Monzuno',
    'Monzambano',
    'Monza',
    'Montorso Vicentino',
    'Montorio',
    'Montorio Romano',
    'Montorio al Vomano',
    "Montopoli in Val d'Arno",
    'Montone',
    'Montoggio',
    'Montodine',
    'Montjovet',
    'Montisi',
    'Montignoso',
    'Montieri',
    'Montichiari',
    'Monticello Conte Otto',
    'Monticchio',
    'Montiano',
    'Monti',
    'Monte Vidon Corrado',
    'Monteviale',
    'Monteverdi Marittimo',
    'Montevarchi',
    'Monte Urano',
    'Montespertoli',
    'Montesilvano Marina',
    'Montesegale',
    'Montese',
    'Montescudo',
    'Montescaglioso',
    'Montesarchio',
    'Monte San Vito',
    'Monte Santa Maria Tiberina',
    'Monte San Savino',
    'Monte San Pietro',
    'Monte San Pietrangeli',
    'Montesano sulla Marcellana',
    'Monte San Giusto',
    'Monte San Giovanni Campano',
    'Monte San Giacomo',
    'Monte San Biagio',
    'Monterotondo Marittimo',
    'Monterotondo',
    'Monterosso al Mare',
    'Monterosi',
    'Monteroni di Lecce',
    "Monteroni d'Arbia",
    'Monte Romano',
    'Monte Roberto',
    'Monte Rinaldo',
    'Monteriggioni',
    'Monterenzio',
    'Montereale Valcellina',
    'Monterchi',
    'Montepulciano',
    'Monteprandone',
    'Monte Porzio Catone',
    'Montepiano',
    'Montepescali',
    'Monteombraro',
    'Monteodorisio',
    'Montenero di Bisaccia',
    'Montemurlo',
    'Montemiletto',
    'Montemesola',
    'Montemerano',
    'Monte Marenzo',
    'Montemarciano',
    'Montemarano',
    'Montemagno',
    'Montelupone',
    'Montelupo Fiorentino',
    'Monteleone Sabino',
    'Monteleone di Spoleto',
    'Monteleone di Puglia',
    'Montelanico',
    'Montelabbate',
    'Monteiasi',
    'Montegrotto Terme',
    "Montegrosso d'Asti",
    'Montegranaro',
    'Montegiorgio',
    'Montegiordano',
    'Monte Giberto',
    'Montegaldella',
    'Montefredane',
    'Montefranco',
    'Monteforte Irpino',
    "Monteforte d'Alpone",
    'Monteflavio',
    "Montefiore dell'Aso",
    'Montefiore Conca',
    'Montefiascone',
    'Montefano',
    'Montefalcone di Val Fortore',
    'Montefalcone Appennino',
    'Montefalco',
    'Montefalcione',
    'Monte di Procida',
    'Montedinove',
    'Monte di Malo',
    'Montecosaro',
    'Montecorvino Rovella',
    'Montecorice',
    'Monte Compatri',
    'Montecilfone',
    'Monteciccardo',
    'Montechiarugolo',
    "Montechiaro d'Asti",
    'Monte Cerignone',
    'Montecchio Maggiore',
    'Montecchio Emilia',
    'Montecchio',
    'Montecchia di Crosara',
    'Montecavolo',
    'Montecatini Terme',
    'Montecastrilli',
    'Montecassiano',
    'Montecarotto',
    'Montecarlo',
    'Montecalvoli',
    'Montecalvo Irpino',
    'Montebruno',
    'Montebelluna',
    'Montebello Vicentino',
    'Montebello della Battaglia',
    'Montaquila',
    'Montappone',
    'Montanaso Lombardo',
    'Montanaro',
    'Montalto Dora',
    'Montalto di Castro',
    'Montalto delle Marche',
    'Montale',
    'Montalcino',
    'Montalbano Jonico',
    'Montaione',
    'Montagnana',
    'Montan',
    'Montà',
    'Monsummano Terme',
    'Monselice',
    'Monsano',
    'Monsampolo del Tronto',
    'Monsampietro Morico',
    'Monopoli',
    'Monno',
    'Moniga del Garda',
    'Welsberg-Taisten',
    'Mongrando',
    "Monforte d'Alba",
    'Monfalcone',
    'Monestirolo',
    'Monesiglio',
    'Moneglia',
    'Mondragone',
    'Mondovì',
    'Mondolfo',
    'Mondavio',
    'Monchio delle Corti',
    'Monchiero',
    'Moncalvo',
    'Moncalieri',
    'Monastier di Treviso',
    'Monasterolo del Castello',
    'Mompeo',
    'Momo',
    'Mommio Castello',
    'Mombaroccio',
    'Molveno',
    'Molteno',
    'Mollia',
    'Moliterno',
    'Muehlen in Taufers',
    'Molini di Triora',
    'Molinella',
    'Molinara',
    'Molfetta',
    'Molazzana',
    'Mola di Bari',
    'Moimacco',
    'Moie',
    'Moiano',
    'Mogliano Veneto',
    'Mogliano',
    'Moglia',
    'Moggio Udinese',
    'Moena',
    'Modugno',
    'Modigliana',
    'Modena',
    'Missaglia',
    'Misinto',
    "Misano di Gera d'Adda",
    'Misano Adriatico',
    'Mirano',
    'Mirandola',
    'Miradolo Terme',
    'Mirabello Monferrato',
    'Mirabello',
    'Mirabella Eclano',
    'Mirabella',
    'Mira',
    'Minucciano',
    'Minturno',
    'Minori',
    'Minervino Murge',
    'Minervino di Lecce',
    'Minerbio',
    'Minerbe',
    'Millesimo',
    'Milano Marittima',
    'Milan',
    'Mignano Monte Lungo',
    'Mignanego',
    'Miglionico',
    'Migliarino',
    'Miglianico',
    'Migiana',
    'Miazzina',
    'Miane',
    'Mezzomerico',
    'Mezzolombardo',
    'Mezzoldo',
    'Mezzolara',
    'Mezzogoro',
    'Mezzocorona',
    'Mezzano Inferiore',
    'Mezzano',
    'Mezzanino',
    'Mezzanego',
    'Mezzana Bigli',
    'Mezzana',
    'Meta',
    'Mestrino',
    'Mestre',
    'Mesola',
    'Mese',
    'Mesagne',
    'Merlara',
    'Merine',
    'Mergozzo',
    'Mergo',
    'Mereto di Tomba',
    'Mercogliano',
    'Mercenasco',
    'Mercato Saraceno',
    'Mercato San Severino',
    'Mercatello sul Metauro',
    'Mercallo',
    'Merate',
    'Merano',
    'Meolo',
    'Mentana',
    'Menaggio',
    'Melzo',
    'Mölten',
    'Melpignano',
    'Melizzano',
    'Melito Irpino',
    'Melito di Napoli',
    'Melfi',
    'Roccasecca Stazione',
    'Melendugno',
    'Melegnano',
    'Meldola',
    'Melara',
    'Meina',
    'Megliadino San Vitale',
    'Meduno',
    'Medolla',
    'Medole',
    'Medolago',
    'Medicina',
    'Medesano',
    'Medelana',
    'Mede',
    'Meda',
    'Mazzè',
    'Mazzano Romano',
    'Mattarello',
    'Matino',
    'Matigge',
    'Mathi',
    'Matera',
    'Matelica',
    'Massignano',
    'Masserano',
    'Massarosa',
    'Massa Martana',
    'Massa Marittima',
    'Massa Lubrense',
    'Massa Lombarda',
    'Massafra',
    'Massa Fiscaglia',
    'Massa Fermana',
    "Massa d'Albe",
    'Massa',
    'Massa e Cozzile',
    'Masone',
    'Maslianico',
    'Maserà di Padova',
    'Masera',
    'Maser',
    'Maschito',
    'Marzio',
    'Marzabotto',
    'Maruggio',
    'Martinsicuro',
    'Martiniana Po',
    'Martinengo',
    'Urbe',
    'Martina Franca',
    'Martignano',
    'Martignacco',
    'Marti',
    'Martellago',
    'Martano',
    'Marsicovetere',
    'Marsico Nuovo',
    'Marsciano',
    'Marradi',
    'Marostica',
    'Marone',
    'Marnate',
    'Marmirolo',
    'Marliana',
    'Marlia',
    'Marling',
    'Marino',
    'Marinella di Sarzana',
    'Marina Palmense',
    'Marina di Ravenna',
    'Marina di Pisa',
    'Marina di Pietrasanta',
    'Marina di Montemarciano',
    'Marina di Massa',
    'Marina di Grosseto',
    'Marina di Carrara',
    'Marina di Campo',
    'Marina di Andora',
    'Marigliano',
    'Mariglianella',
    'Mariano Comense',
    'Margherita di Savoia',
    'Marghera',
    'Maresca',
    'Marene',
    'Marcon',
    'Marco',
    'Marcianise',
    'Marciana Marina',
    'Marciana',
    'Marcheno',
    'Marcellina',
    'Marcaria',
    'Marcallo con Casone',
    'Marano Vicentino',
    'Marano Ticino',
    'Marano sul Panaro',
    'Marano Lagunare',
    'Marano di Valpolicella',
    'Marano di Napoli',
    'Maranello',
    'Mapello',
    'Manziana',
    'Manzano',
    'Mantova',
    'Manta',
    'Mansuè',
    'Manoppello',
    'Manocalzati',
    'Maniago',
    'Mango',
    'Manfredonia',
    'Manerbio',
    'Manduria',
    'Mandello del Lario',
    'Manciano',
    'Malonno',
    'Malo',
    'Malnate',
    'Malmantile',
    'Mals',
    'Malgrate',
    'Maleo',
    'Malé',
    'Malcesine',
    'Malalbergo',
    'Maissana',
    'Maiori',
    'Maiolati Spontini',
    'Maiero',
    'Magomadas',
    'Magnago',
    'Maglie',
    'Magliano Sabina',
    'Magliano Nuovo',
    'Magliano in Toscana',
    'Magliano di Tenna',
    "Magliano de' Marsi",
    'Magliano Alpi',
    'Magliano',
    'Magione',
    'Magenta',
    'Maerne',
    'Madonna di Campiglio',
    "Madonna dell'Arco",
    'Madignano',
    'Maddaloni',
    'Macugnaga',
    'Macomer',
    'Macerata Feltria',
    'Macerata Campania',
    'Macerata',
    'Maccarese',
    'Maccagno',
    'Maccacari',
    'Luzzana',
    'Lutago',
    'Lusiana',
    'Lusia',
    'Luserna San Giovanni',
    'Lurate Caccivio',
    'Luras',
    'Lurago Marinone',
    "Lurago d'Erba",
    'Lupara',
    'Luogosanto',
    'Luogosano',
    'Luni',
    'Lungavilla',
    'Lunano',
    'Luino',
    'Lugo',
    'Lugnano in Teverina',
    "Lugagnano Val d'Arda",
    'Lugagnano',
    'Lucrezia',
    'Luco dei Marsi',
    'Lucito',
    'Montano Lucino',
    'Lucignano',
    'Lucera',
    'Lucca',
    'Cannigione',
    'Lubriano',
    'Lu',
    'Lozzolo',
    'Lozzo di Cadore',
    'Lozzo Atestino',
    'Lovere',
    'Lorsica',
    'Loro Piceno',
    'Loro Ciuffenna',
    'Loria',
    'Loreto Aprutino',
    'Loreto',
    'Loreo',
    'Loreggia',
    'Lonigo',
    'Longiano',
    'Longarone',
    'Longare',
    'Londa',
    'Lonato',
    'Lonate Pozzolo',
    'Lonate Ceppino',
    'Lomello',
    'Lombardore',
    'Lomazzo',
    'Lomagna',
    'Lollove',
    'Loiri Porto San Paolo',
    'Loiano',
    'Lograto',
    'Lodi Vecchio',
    'Lodi',
    'Locorotondo',
    'Locate Varesino',
    'Locate di Triulzi',
    'Locana',
    'Loano',
    'Lizzano in Belvedere',
    'Lizzano',
    'Lizzanello',
    'Livorno Ferraris',
    'Livorno',
    'Livigno',
    'Liveri',
    'Lissone',
    'Lissaro',
    'Liscate',
    'Lioni',
    'Limone sul Garda',
    'Limone Piemonte',
    'Limestre',
    'Limena',
    'Limbiate',
    'Limatola',
    'Ligonchio',
    'Lignano Sabbiadoro',
    'Lignano Pineta',
    'Lignana',
    'Lierna',
    'Lido di Ostia',
    'Lido di Jesolo',
    'Lido di Camaiore',
    'Lido di Savio',
    'Lido',
    'Licenza',
    'Licciana Nardi',
    'Liberi',
    'Lezzeno',
    'Levigliani',
    'Levico Terme',
    'Leverano',
    'Levanto',
    'Levane',
    'Lettomanoppello',
    'Lessona',
    'Lessolo',
    'Lesina',
    "Lesignano de' Bagni",
    'Lesegno',
    'Lerma',
    'Lerici',
    'Lequile',
    'Leporano',
    'Leonessa',
    'Lentiai',
    'Lentella',
    'Lentate sul Seveso',
    'Lenta',
    'Lenola',
    'Leno',
    'Lenno',
    'Lendinara',
    'Leinì',
    'Le Grazie',
    'Legnaro',
    'Legnano',
    'Legnago',
    'Leffe',
    'Lecco',
    'Lecce',
    'Lazzeretto',
    'Lazise',
    'Lavis',
    'Lavino di Mezzo',
    'Lavezzola',
    'Lavenone',
    'Laveno-Mombello',
    'Lavello',
    'La Valle Agordina',
    'Lavaiano',
    'Lavagna',
    'Lauro',
    'Laurito',
    'Laurino',
    'Lauria',
    'Latronico',
    'Latisana',
    'Latina',
    'Latiano',
    'La Thuile',
    'Laterza',
    'Laterina',
    'Latera',
    'Lastra a Signa',
    'La Spezia',
    'Lasino',
    'La Salle',
    'Laas',
    'Larino',
    'Lariano',
    'Lari',
    'Lardirago',
    'Larderello',
    'L’Aquila',
    'Lapedona',
    'Lanzo Torinese',
    "Lanzo d'Intelvi",
    'Lanuvio',
    'Langosco',
    'Langhirano',
    'Landriano',
    'Landiona',
    'Lanciano',
    'Lana',
    'Lamporecchio',
    'La Morra',
    'Lamon',
    'Lammari',
    'Lama Mocogno',
    'La Maddalena',
    'La Loggia',
    'Lallio',
    'Laives',
    'Lainate',
    'Laigueglia',
    'Algund',
    'Lagosanto',
    'Lagonegro',
    'Laglio',
    'Ladispoli',
    'Latsch',
    'Lacedonia',
    'Lacco Ameno',
    'Lacchiarella',
    'Labico',
    'Ivrea',
    'Ittiri',
    'Itri',
    'Istrana',
    'Ispra',
    'Isorella',
    'Isola Vicentina',
    'Isola del Liri',
    'Isola della Scala',
    'Isola del Gran Sasso',
    'Isola del Cantone',
    'Isernia',
    'Iseo',
    'Ischitella',
    'Ischia Porto',
    'Ischia di Castro',
    'Ischia',
    'Irsina',
    'Irgoli',
    'Jolanda di Savoia',
    'Inzago',
    'Invorio',
    'Invillino',
    'Inveruno',
    'Inverigo',
    'Introdacqua',
    'Introbio',
    'Pallanza-Intra-Suna',
    'Induno Olona',
    "Incisa in Val d'Arno",
    'Impruneta',
    'Imperia',
    'Imola',
    'Illorai',
    'Illasi',
    'Jesolo',
    'Iesi',
    'Jelsi',
    'Idice',
    'Borgo Hermada',
    'Gussola',
    'Gussago',
    'Guiglia',
    'Guidonia',
    'Guidizzolo',
    'Guglionesi',
    'Gubbio',
    'Guastameroli',
    'Guastalla',
    'Guardia Sanframondi',
    'Guardiagrele',
    'Guardamiglio',
    'Guarcino',
    'Gualtieri',
    'Gualdo Tadino',
    'Gualdo Cattaneo',
    'Guagnano',
    'Grumo Nevano',
    'Grumolo Pedemonte',
    'Grumolo delle Abbadesse',
    'Grumo Appula',
    'Grumento Nova',
    'Grumello del Monte',
    'Grumello Cremonese',
    'Grugliasco',
    'Grozzana',
    'Grottolella',
    'Grotte di Castro',
    'Grottazzolina',
    'Grottammare',
    'Grottaminarda',
    'Grottaglie',
    'Grottaferrata',
    'Grosseto',
    'Grosotto',
    'Grosio',
    'Gropparello',
    'Gropello Cairoli',
    'Grontardo',
    'Grizzana Morandi',
    'Grisignano di Zocco',
    'Grignasco',
    'Gricignano di Aversa',
    'Grezzana',
    'Greve in Chianti',
    'Gressoney-Saint-Jean',
    'Gressan',
    'Greggio',
    'Grazzanise',
    'Grazie',
    'Gravina in Puglia',
    'Gravellona Toce',
    'Gravedona',
    'Grassina',
    'Grassano',
    'Granze',
    'Grantorto',
    'Granozzo con Monticello',
    'Grandate',
    'Gramolazzo',
    'Gragnano Trebbiense',
    'Gragnano',
    'Graffignano',
    'Grado',
    "Gradisca d'Isonzo",
    'Gradara',
    'Gozzano',
    'Governolo',
    'Gottolengo',
    'Gossolengo',
    'Goro',
    'Gorno',
    'Gorle',
    'Gorla Maggiore',
    'Gorizia',
    'Goriano Sicoli',
    'Gorgonzola',
    'Gordona',
    'Gonzaga',
    'Gonars',
    'Golfo Aranci',
    'Golasecca',
    'Goito',
    'Godiasco',
    "Godega di Sant'Urbano",
    'Glurns',
    'Giussano',
    'Giussago',
    'Giurdignano',
    'Giungano',
    'Giulianova',
    'Giuliano di Roma',
    'Giugliano in Campania',
    'Gissi',
    'Giovinazzo',
    'Giove',
    'Gioia Sannitica',
    'Gioia del Colle',
    'Marina di Ginosa',
    'Ginosa',
    'Ginestreto',
    'Ginestra Fiorentina',
    'Gildone',
    'Giffoni Valle Piana',
    'Giavera del Montello',
    'Giaveno',
    'Giano Vetusto',
    "Giano dell'Umbria",
    'Piano di Coreglia-Ghivizzano',
    'Ghisalba',
    'Ghilarza',
    'Ghiffa',
    'Ghemme',
    'Ghedi',
    'Gesualdo',
    'Gessopalena',
    'Gerenzago',
    'Gerano',
    'Gera Lario',
    'Genzano di Roma',
    'Genzano di Lucania',
    'Genoa',
    'Genola',
    'Genivolta',
    'Genga',
    'Genazzano',
    'Gemonio',
    'Gemona',
    'Gazzuolo',
    'Gazzo Veronese',
    'Gazzo',
    'Gazzaniga',
    'Gazzada',
    'Gazoldo degli Ippoliti',
    'Gavorrano',
    'Gavoi',
    'Gavirate',
    'Gavi',
    'Gavardo',
    'Gattinara',
    'Gattatico',
    'Gassino Torinese',
    'Garzeno',
    'Garlenda',
    'Garlasco',
    'Gargnano',
    'Gargazon',
    'Gardone Val Trompia',
    'Gardone Riviera',
    'Garda',
    'Garbagnate Milanese',
    'Garaguso',
    'Gandino',
    'Gambulaga',
    'Gambolò',
    'Gambettola',
    'Gambara',
    'Gamalero',
    'Galtellì',
    'Galluzzo',
    'Gallipoli',
    'Gallio',
    'Gallinaro',
    'Gallignano',
    'Galliera Veneta',
    'Galliera',
    'Gallicano nel Lazio',
    'Gallicano',
    'Galliate',
    'Gallese',
    'Gallarate',
    'Galeata',
    'Galbiate',
    'Galatone',
    'Galatina',
    'Gais',
    'Gaiole in Chianti',
    'Gaiarine',
    'Gaglianico',
    'Gaggio Montano',
    'Gaggiano',
    'Gaeta',
    'Gabicce Mare',
    'Gabiano',
    'Futani',
    'Fusina',
    'Fusignano',
    'Furci',
    'Fumane',
    'Fucecchio',
    'Fubine',
    'Frosolone',
    'Frosinone',
    'Front',
    'Frisa',
    'Frigento',
    'Fregona',
    'Fregene',
    'Frattocchie',
    'Fratta Todina',
    'Fratta Polesine',
    'Frattaminore',
    'Frattamaggiore',
    'Frasso Telesino',
    'Frasso Sabino',
    'Frassineto Po',
    'Frascati',
    'Frascaro',
    'Francolise',
    'Francavilla in Sinni',
    'Francavilla Fontana',
    "Francavilla d'Ete",
    'Francavilla al Mare',
    'Fragneto Monforte',
    'Fragagnano',
    'Frabosa Soprana',
    'Fossombrone',
    'Fossoli',
    'Fossato di Vico',
    'Fossano',
    'Fossalto',
    'Fossalta di Portogruaro',
    'Fossalta di Piave',
    'Fossacesia',
    'Fosdinovo',
    'Fortezza',
    'Forte dei Marmi',
    'Fornovo di Taro',
    'Forno di Zoldo',
    'Forno Canavese',
    'Forni Avoltri',
    'Fornaci di Barga',
    'Fornaci',
    'Fornacette',
    'Fornacelle',
    'Formignana',
    'Formigliana',
    'Formigine',
    'Formia',
    'Formello',
    'Forlimpopoli',
    'Forlì del Sannio',
    'Forlì',
    'Forio',
    'Forino',
    'Forchia',
    'Forano',
    'Fonzaso',
    'Fontevivo',
    'Fonteblanda',
    'Fontaniva',
    'Fontanile',
    'Fontanetto Po',
    "Fontaneto d'Agogna",
    'Fontanelle',
    'Fontanellato',
    'Fontanella',
    'Fontanelice',
    'Fontanarosa',
    'Fontana Liri',
    'Fontanafredda',
    'Fonni',
    'Fondo',
    'Fondi',
    'Fombio',
    'Follonica',
    'Follina',
    'Foligno',
    'Folignano',
    'Folgaria',
    'Foiano di Val Fortore',
    'Foiano della Chiana',
    'Foglizzo',
    'Fogliano Redipuglia',
    'Foglianise',
    'Foggia',
    'Florinas',
    'Flero',
    'Fivizzano',
    'Fiumicino',
    'Fiume Veneto',
    'Fiumalbo',
    'Fiuggi',
    'Fisciano',
    'Firenzuola',
    'Florence',
    "Fiorenzuola d'Arda",
    'Fiorano Modenese',
    'Fino Mornasco',
    'Finale Ligure',
    'Finale Emilia',
    'Filottrano',
    'Filighera',
    'Filiano',
    'Filetto',
    'Figline Valdarno',
    'Fiesso Umbertiano',
    'Fiesse',
    'Fiesole',
    'Fie Allo Sciliar',
    'Fidenza',
    'Ficarolo',
    'Fiavè',
    'Fiastra',
    'Fiano Romano',
    'Fiano',
    'Fezzano',
    'Fertilia',
    'Ferrera Erbognone',
    'Ferrara di Monte Baldo',
    'Ferrara',
    'Ferrandina',
    'Ferno',
    'Fermo',
    'Fermignano',
    'Ferentino',
    'Ferentillo',
    'Feltre',
    'Felizzano',
    'Felino',
    'Feletto Umberto',
    'Felegara',
    'Favria',
    'Favaro Veneto',
    'Fauglia',
    'Fasano',
    'Farra di Soligo',
    "Farra d'Alpago",
    'Farini',
    'Farindola',
    'Fara San Martino',
    'Fara Novarese',
    'Fara in Sabina',
    "Fara Gera d'Adda",
    'Fara Filiorum Petri',
    'Fanzolo',
    'Fano',
    'Fanna',
    'Fanano',
    'Pfalzen',
    'Falze di Piave',
    'Falerone',
    'Faleria',
    'Falconara Marittima',
    'Falcade',
    'Fai della Paganella',
    'Fagnano Olona',
    'Faggiano',
    'Fagagna',
    'Faenza',
    'Faedis',
    'Fae',
    'Fabro',
    'Fabrica di Roma',
    'Fabriano',
    'Fabbrico',
    'Etroubles',
    'Este',
    'Esperia',
    'Esine',
    'Esanatoglia',
    'Erula',
    'Ercolano',
    'Erchie',
    'Erbezzo',
    'Erbè',
    'Erba',
    'Eraclea',
    'Episcopia',
    'Envie',
    'Entracque',
    'Endine Gaiano',
    'Empoli',
    'Elice',
    'Neumarkt',
    'Edolo',
    'Eboli',
    'Durazzano',
    'Dumenza',
    'Duino',
    'Dugenta',
    'Dueville',
    'Dubino',
    'Dualchi',
    'Druento',
    'Dronero',
    'Dro',
    'Dozza',
    'Dovera',
    'Dovadola',
    'Dosson',
    'Dossobuono',
    'Dosolo',
    'Dosoledo',
    'Dosimo',
    'Dorno',
    'Dorgali',
    'Dongo',
    'Domodossola',
    'Domicella',
    'Domegliara',
    'Domaso',
    'Dolo',
    'Dolcedo',
    'Dolcè',
    'Doberdò del Lago',
    'Toblach',
    'Dizzasco',
    'Dimaro',
    'Diegaro',
    'Dicomano',
    'Diano Marina',
    "Diano d'Alba",
    'Diano Castello',
    'Desio',
    'Desenzano del Garda',
    'Desana',
    'Dervio',
    'Deruta',
    'Taio',
    'Denno',
    'Demonte',
    'Dello',
    'Deliceto',
    'Delebio',
    'Deiva Marina',
    'Dego',
    'Darfo',
    'Dalmine',
    'Dairago',
    'Cuzzago',
    'Cutrofiano',
    'Cutigliano',
    'Cusano Mutri',
    'Cusano Milanino',
    'Curtarolo',
    'Cursi',
    'Graun im Vinschgau',
    'Curno',
    'Cupramontana',
    'Cupra Marittima',
    'Cupello',
    'Cuorgnè',
    'Cuneo',
    'Cumignano sul Naviglio',
    'Cumiana',
    'Cuglieri',
    'Cuggiono',
    'Cuceglio',
    'Cuccaro Vetere',
    'Cuccana',
    'Crone',
    'Crognaleto',
    'Crodo',
    'Crocetta del Montello',
    'Crispiano',
    'Crevalcore',
    'Crevacuore',
    'Cressa',
    'Crespino',
    'Crespina',
    'Crespiatica',
    'Crespellano',
    'Crespano del Grappa',
    'Crespadoro',
    'Crescentino',
    'Cremona',
    'Crema',
    'Creazzo',
    'Covo',
    'Courmayeur',
    'Cottanello',
    'Cotignola',
    'Costigliole Saluzzo',
    "Costigliole d'Asti",
    'Costermano',
    'Costa Volpino',
    'Costa Valle Imagna',
    'Costano',
    'Costa di Rovigo',
    'Costa di Mezzate',
    'Costacciaro',
    'Costabissara',
    'Cossoine',
    'Cossato',
    'Corvara',
    'Corva',
    'Cortona',
    'Cortina di Giais',
    "Cortina d'Ampezzo",
    'Corteolona',
    'Cortemilia',
    'Cortemaggiore',
    'Corte Franca',
    'Cortazzone',
    'Cortaccia sulla Strada del Vino',
    'Corsico',
    'Corropoli',
    'Corridonia',
    'Correzzola',
    'Correggio',
    'Cornuda',
    'Corno di Rosazzo',
    'Cornedo Vicentino',
    "Cornate d'Adda",
    'Cornaredo',
    'Cormons',
    'Cormano',
    'Corleto Perticara',
    'Corio',
    'Corinaldo',
    "Corigliano d'Otranto",
    'Coriano',
    'Cori',
    'Coreglia Antelminelli',
    'Cordovado',
    'Cordignano',
    'Cordenons',
    'Corciano',
    'Corchiano',
    'Corbola',
    'Corbetta',
    'Corato',
    'Corana',
    'Copparo',
    'Copiano',
    'Copertino',
    'Copersito',
    'Conza della Campania',
    'Conversano',
    'Contursi Terme',
    'Controguerra',
    'Contrada',
    'Contigliano',
    'Conselve',
    'Conselice',
    'Consandolo',
    'Confienza',
    'Conegliano',
    'Condove',
    'Condino',
    'Concorezzo',
    'Concordia sulla Secchia',
    'Concordia Sagittaria',
    'Conco',
    'Concerviano',
    'Conca della Campania',
    'Conca dei Marini',
    'Cona',
    'Comun Nuovo',
    'Compiobbi',
    'Como',
    'Commessaggio',
    'Comerio',
    'Comazzo',
    'Comano',
    'Comacchio',
    'Comabbio',
    'Colturano',
    'Colorno',
    'Colonnella',
    'Colonna',
    'Cologno Monzese',
    'Cologno al Serio',
    'Cologne',
    'Cologna Veneta',
    'Colmurano',
    'Colloredo di Prato',
    'Collodi',
    'Colli sul Velino',
    'Colli del Tronto',
    'Colliano',
    'Colli a Volturno',
    'Colletorto',
    'Colle Sannita',
    'Collesalvetti',
    'Collepietra',
    'Collepepe',
    'Collepasso',
    'Collepardo',
    'Collegno',
    'Colleferro',
    "Colle di Val d'Elsa",
    'Colle di Tora',
    'Colle di Fuori',
    'Colledara',
    'Collecorvino',
    'Collecchio',
    'Collazzone',
    'Colico',
    'Colere',
    'Colbordolo',
    'Cogollo del Cengio',
    'Cogoleto',
    'Cogne',
    'Coggiola',
    'Codrongianos',
    'Codroipo',
    'Codogno',
    'Codognè',
    'Codigoro',
    'Codevigo',
    'Cocquio-Trevisago',
    'Cocconato',
    'Coccaglio',
    'Coazze',
    'Clusone',
    'Clusane',
    'Cles',
    'Classe',
    'Civitella San Paolo',
    'Civitella in Val di Chiana',
    'Civitella di Romagna',
    'Civitella del Tronto',
    "Civitella d'Agliano",
    'Civitella Casanova',
    'Civitavecchia',
    'Civitanova del Sannio',
    'Civitanova Alta',
    "Civita d'Antino",
    'Civita Castellana',
    'Cividate Camuno',
    'Cividate al Piano',
    'Cividale del Friuli',
    'Civezzano',
    'Civezza',
    'Cittiglio',
    "Città Sant'Angelo",
    'Cittaducale',
    'Città di Castello',
    'Città della Pieve',
    'Cittadella',
    'Cisternino',
    'Cisterna di Latina',
    'Cison di Valmarino',
    'Cisliano',
    'Cislago',
    'Ciserano',
    'Cisano sul Neva',
    'Cisano Bergamasco',
    'Cirimido',
    'Cirié',
    'Cireglio',
    'Circello',
    'Cipressa',
    'Cinto Caomaggiore',
    'Capanne-Prato-Cinquale',
    'Cinisello Balsamo',
    'Cinigiano',
    'Cingoli',
    "Cingia de' Botti",
    'Cinque Strade-Cimpello',
    'Cimitile',
    'Cimadolmo',
    'Cilavegna',
    'Cigole',
    'Cigliano',
    'Ciconicco',
    'Ciciliano',
    'Cicciano',
    'Cicagna',
    'Cibiana di Cadore',
    'Ciardes',
    'Ciampino',
    'Chivasso',
    'Chiusi della Verna',
    'Chiusi',
    'Chiusdino',
    'Chiusaforte',
    'Chiusa di Pesio',
    'Klausen',
    'Chiuppano',
    'Chions',
    'Chioggia',
    'Chieuti',
    'Chieti',
    'Chiesina Uzzanese',
    'Chiesanuova',
    'Chiesa in Valmalenco',
    'Chieri',
    'Chiavenna',
    'Chiavari',
    'Chiari',
    'Chiaravalle',
    'Chiarano',
    'Chiaramonti',
    'Chianni',
    'Chianciano Terme',
    'Chiampo',
    'Cherasco',
    'Châtillon',
    'Charvensod',
    'Champdepraz',
    'Chambave',
    'Ceva',
    'Cetona',
    'Cetica',
    'Cetara',
    'Cessalto',
    'Cesiomaggiore',
    'Cesenatico',
    'Cesena',
    'Cesarolo',
    'Porto Cesareo',
    'Cesara',
    'Cesano Maderno',
    'Cesana Torinese',
    'Cesa',
    'Cervo',
    'Cervino',
    'Cervignano del Friuli',
    'Cervia',
    'Cerveteri',
    'Cervesina',
    'Cervere',
    'Cerveno',
    'Cervaro',
    'Certosa di Pavia',
    'Certaldo',
    'Cerro Maggiore',
    'Cerro al Volturno',
    'Cerrione',
    'Cerreto Sannita',
    'Cerreto Guidi',
    'Cerreto di Spoleto',
    "Cerreto d'Esi",
    'Cerredolo',
    'Cerratina',
    'Cernusco sul Naviglio',
    'Cernobbio',
    'Cermignano',
    'Tscherms',
    'Cermenate',
    'Cerlongo',
    'Cerignola',
    'Ceriano Laghetto',
    'Ceriana',
    'Ceriale',
    'Virgilio',
    'Ceresara',
    'Ceregnano',
    'Cerea',
    'Cercola',
    'Cerasomma',
    'Ceraso',
    'Cerano',
    'Ceprano',
    'Ceppaloni',
    'Ceparana-Carpena',
    'Cepagatti',
    'Ceolini',
    'Centola',
    'Cento',
    'Centallo',
    'Cengio',
    'Ceneselli',
    'Cene',
    'Cencenighe Agordino',
    'Cenaia',
    'Cembra',
    'Cellole',
    'Cellio',
    'Cellino San Marco',
    'Cellino Attanasio',
    'Cellere',
    'Celleno',
    'Celle Ligure',
    'Celle di Bulgheria',
    'Cellamare',
    'Celenza Valfortore',
    'Celano',
    'Ceglie Messapica',
    'Ceglie di Campo',
    'Ceggia',
    'Cedrasco',
    'Cecina',
    'Cecchina',
    'Ceccano',
    'Cazzago San Martino',
    'Cavriglia',
    'Cavriana',
    'Cavriago',
    'Cavour',
    'Cavo',
    'Cavezzo',
    'Cavenago di Brianza',
    "Cavenago d'Adda",
    'Cavedago',
    'Cave',
    'Cavazzo Carnico',
    'Cavasso Nuovo',
    'Cavarzere',
    'Cavaria con Premezzo',
    'Cavareno',
    'Cava Manara',
    'Cavallirio',
    'Cavallino',
    'Cavallermaggiore',
    'Cavalicco',
    'Cavalese',
    'Cavaion Veronese',
    'Cavaglià',
    "Cava de' Tirreni",
    'Cattolica',
    'Catignano',
    "Ca' Tiepolo",
    'Castronno',
    'Castro dei Volsci',
    'Castrocielo',
    'Castrocaro Terme',
    'Castorano',
    'Castions di Strada',
    'Castions delle Mura',
    'Castione della Presolana',
    'Castione Marchesi',
    'Castilenti',
    'Castignano',
    'Castiglion Fiorentino',
    'Castiglion Fibocchi',
    'Castiglione Olona',
    'Castiglione Messer Raimondo',
    'Castiglione Messer Marino',
    'Castiglione in Teverina',
    "Castiglione d'Orcia",
    'Castiglione di Ravenna',
    'Castiglione di Garfagnana',
    'Castiglione delle Stiviere',
    'Castiglione della Pescaia',
    'Castiglione del Lago',
    'Castiglione dei Pepoli',
    'Castiglione del Genovesi',
    "Castiglione d'Adda",
    'Castiglione Chiavarese',
    'Castenedolo',
    'Castenaso',
    'Castel Volturno',
    'Castel Viscardo',
    'Castelvetro Piacentino',
    'Castelvetro di Modena',
    'Castelvetere sul Calore',
    'Castelverde',
    'Castelvenere',
    'Castelvecchio Subequo',
    'Castelvecchio Pascoli',
    'Casteltodino',
    'Castelseprio',
    'Castelsardo',
    'Castelsaraceno',
    'Castel San Vincenzo',
    "Castel Sant'Elia",
    "Castel Sant'Angelo",
    'Castel San Pietro Terme',
    'Castel San Pietro Romano',
    'Castel San Pietro',
    'Castel San Niccolò',
    'Castel San Lorenzo',
    'Castel San Giovanni',
    'Castel San Giorgio',
    'Kastelruth',
    'Castel Ritaldi',
    'Castelraimondo',
    'Castelplanio',
    'Castelnuovo Scrivia',
    'Castelnuovo Rangone',
    'Castelnuovo Nigra',
    'Castelnuovo Don Bosco',
    'Castelnuovo di Verona',
    'Castelnuovo di Val di Cecina',
    'Castelnuovo di Porto',
    'Castelnuovo Magra',
    'Castelnuovo di Garfagnana',
    'Castelnuovo di Farfa',
    'Castelnuovo della Daunia',
    'Castelnuovo Berardenga',
    'Castelnuovo Calcea',
    "Castelnovo ne' Monti",
    'Castelnovo di Sotto',
    'Castelnuovo',
    'Castel Mella',
    'Castelmauro',
    'Castelmassa',
    'Castel Maggiore',
    'Castel Madama',
    'Castelluccio Valmaggiore',
    'Castellucchio',
    'Castello Tesino',
    'Castello di Godego',
    'Castello Molina di Fiemme',
    'Castello di Annone',
    "Castello d'Argile",
    'Castelliri',
    'Castellino del Biferno',
    'Castellina in Chianti',
    'Castelli Calepio',
    'Castelli',
    'Castelletto sopra Ticino',
    'Castelleone',
    'Castellazzo Novarese',
    'Castellazzo Bormida',
    "Castell'Azzara",
    "Castell'Arquato",
    'Castellaro',
    'Castellarano',
    'Castellanza',
    'Castellaneta',
    'Castellana Grotte',
    'Castellamonte',
    'Castellammare di Stabia',
    'Castellalto',
    "Castell'Alfero",
    'Castellabate',
    'Castelguglielmo',
    'Castel Guelfo di Bologna',
    'Castelgomberto',
    'Castel Goffredo',
    'Castel Gandolfo',
    'Castel Gabbiano',
    'Castelfranco Veneto',
    'Castelfranco Emilia',
    'Castelfranco di Sotto',
    'Castelfranco di Sopra',
    'Castelfranci',
    'Castelforte',
    'Castel Focognano',
    'Castelfiorentino',
    'Castelfidardo',
    'Castelferretti',
    'Castel di Sangro',
    'Castel di Casio',
    'Castel del Rio',
    'Castel del Piano',
    "Castel d'Ario",
    "Castel d'Aiano",
    'Castel Colonna',
    'Castel Campagnano',
    'Castel Bolognese',
    'Castelbellino',
    'Castel Baronia',
    'Castegnato',
    'Casteggio',
    'Castano Primo',
    'Castagnole Monferrato',
    'Castagnole delle Lanze',
    'Castagnole',
    'Castagnito',
    'Castagneto Carducci',
    'Castagnaro',
    'Cassolnovo',
    'Cassio',
    'Cassino',
    'Cassine',
    "Cassina de' Pecchi",
    'Cassano delle Murge',
    "Cassano d'Adda",
    'Caspoggio',
    'Casperia',
    'Casoria',
    'Casorezzo',
    'Casorate Sempione',
    'Casorate Primo',
    'Casoli',
    "Casole d'Elsa",
    'Casola Valsenio',
    'Casola di Napoli',
    'Casnigo',
    'Casarile',
    "Casirate d'Adda",
    'Casinalbo',
    'Casina',
    'Caserta',
    'Pieve al Toppo',
    'Caselle Torinese',
    'Caselle Lurani',
    'Caselle in Pittari',
    'Casella',
    'Caselette',
    "Cascinette d'Ivrea",
    'Cascina',
    'Casciana Terme',
    'Cascia',
    'Casazza',
    'Casatisma',
    'Casatenovo',
    'Casasco',
    'Casarsa della Delizia',
    'Casargo',
    'Casapulla',
    'Casaprota',
    'Casandrino',
    'Casamassima',
    'Casamarciano',
    'Casalzuigno',
    'Casalvolone',
    'Casalvieri',
    'Casal Velino',
    'Casaluce',
    'Casalserugo',
    'Casalromano',
    'Casalpusterlengo',
    'Casaloldo',
    'Casalnuovo Monterotaro',
    'Casalnuovo di Napoli',
    'Casalnoceto',
    'Casalmoro',
    'Casalmaiocco',
    'Casalmaggiore',
    'Casaliggio',
    'Casalgrasso',
    'Casalgrande',
    'Casalfiumanese',
    'Casale sul Sile',
    'Casaleone',
    'Casale Monferrato',
    'Casale Marittimo',
    'Casale di Scodosia',
    'Casale di Pari',
    'Casale Corte Cerro',
    'Casalecchio di Reno',
    'Casal di Principe',
    'Casalbuttano ed Uniti',
    'Casalbuono',
    'Casalborgone',
    'Casalbordino',
    'Casalanguida',
    'Casacanditella',
    'Casacalenda',
    'Carvico',
    'Carunchio',
    'Carugate',
    'Cartura',
    'Cartoceto',
    'Carsoli',
    'Carrù',
    'Carrara',
    'Carpinone',
    'Carpino',
    'Carpineto Romano',
    'Carpineti',
    'Carpignano Sesia',
    'Carpignano Salentino',
    'Carpiano',
    'Carpi',
    'Carpesica',
    'Carpenedolo',
    'Carpegna',
    'Carpaneto Piacentino',
    'Carpacco',
    'Carovilli',
    'Carovigno',
    'Carosino',
    'Carona',
    'Carnate',
    'Carnago',
    'Carmignano di Brenta',
    'Carmignano',
    'Carmiano',
    'Carmagnola',
    'Carlino',
    'Carlazzo',
    'Carisio',
    'Carinola',
    'Carimate',
    'Carignano',
    'Carife',
    'Caresana',
    'Carenno',
    'Cardito',
    'Carceri',
    'Carcare',
    'Carbonera',
    'Carbonara di Bari',
    'Carbognano',
    'Caravino',
    'Caravate',
    'Caravaggio',
    'Carate Brianza',
    'Carassai',
    'Carasco',
    'Carapelle',
    'Carano',
    'Caramanico Terme',
    'Caraglio',
    'Capurso',
    'Capua',
    'Capriolo',
    'Caprino Veronese',
    'Caprino Bergamasco',
    'Capriglia Irpina',
    'Caprie',
    'Capriate San Gervasio',
    'Capriana',
    'Capri',
    'Caprese Michelangelo',
    'Caprarola',
    'Caprarica di Lecce',
    'Capranica',
    'Capralba',
    'Capraia Isola',
    'Capracotta',
    'Cappelle sul Tavo',
    'Cappella Maggiore',
    'Caposele',
    'Capoliveri',
    'Capodrise',
    'Capo di Ponte',
    'Capistrello',
    'Capestrano',
    'Capergnanica',
    'Capena',
    'Capannori',
    'Capannoli',
    'Capalbio',
    'Capaccio',
    'Caorso',
    'Caorle',
    'Canzo',
    'Cantu',
    'Cantiano',
    'Cantarana',
    'Cantalupo nel Sannio',
    'Cantalupo in Sabina',
    'Cantalice',
    'Cantagallo',
    'Olgiate Molgora',
    'Canossa',
    'Canosa Sannita',
    'Canosa di Puglia',
    'Cannole',
    'Cannobio',
    "Canneto sull'Oglio",
    'Cannero Riviera',
    'Cannara',
    'Cannalonga',
    'Canino',
    'Caneva',
    'Canepina',
    'Canelli',
    'Canegrate',
    'Candiolo',
    'Comelico Superiore',
    'Candiana',
    'Candia Lomellina',
    'Candelo',
    'Candela',
    'Cancellara',
    'Canazei',
    'Canaro',
    'Canale Monterano',
    'Canale',
    'Camugnano',
    'Camucia-Monsigliolo',
    'Sand in Taufers',
    'Campo Tizzoro',
    'Camposanto',
    'Camposano',
    'Campo San Martino',
    'Camposampiero',
    'Camporotondo di Fiastrone',
    'Camporosso',
    'Camporgiano',
    'Camponogara',
    'Campomorone',
    'Campomarino',
    'Campolongo al Torre',
    'Campo Ligure',
    'Campolieto',
    'Campoli del Monte Taburno',
    'Campoli Appennino',
    'Campogalliano',
    'Campoformido',
    'Campofilone',
    'Campodoro',
    'Campo di Giove',
    'Campo di Carne',
    'Campodenno',
    'Campodarsego',
    'Campobasso',
    'Campli',
    'Campitello di Fassa',
    'Campi Salentina',
    'Campiglia Marittima',
    "Campiglia d'Orcia",
    'Campiglia dei Berici',
    'Campi Bisenzio',
    'Campello sul Clitunno',
    'Campegine',
    'Camparada',
    'Campagnola Emilia',
    'Campagnatico',
    'Campagnano di Roma',
    'Campagna Lupia',
    'Campagna',
    'Camogli',
    'Camisano Vicentino',
    'Camisano',
    'Camigliano',
    'Camerota',
    'Camerino',
    'Cameri',
    'Camerata Picena',
    'Camerano',
    'Cambiano',
    'Cambiago',
    'Camalo',
    'Camaiore',
    'Calvizzano',
    'Calvisano',
    "Calvi dell'Umbria",
    'Calvenzano',
    'Calvene',
    'Calvatone',
    'Caluso',
    "Calusco d'Adda",
    'Caltignaga',
    'Caltana',
    'Calolziocorte',
    'Calliano',
    'Calizzano',
    'Calitri',
    'Calimera',
    'Calenzano',
    'Caldonazzo',
    'Caldogno',
    'Calderara di Reno',
    'Caldarola',
    'Caldaro sulla Strada del Vino',
    'Calcio',
    'Calcinato',
    'Calcinate',
    'Calcinaia',
    'Calci',
    'Calavino',
    'Calangianus',
    'Calamandrana',
    'Calalzo di Cadore',
    'Cala Gonone',
    'Calabritto',
    'Caivano',
    'Cairo Montenotte',
    'Cairate',
    'Caiatia',
    'Caianello',
    'Cagnano Varano',
    'Cagli',
    'Caggiano',
    'Caerano di San Marco',
    'Cadoneghe',
    'Caderzone',
    'Cadeo',
    'Cadelbosco di Sopra',
    'Cacciano',
    "Ca' Bianca",
    'Buttrio',
    'Buttigliera Alta',
    'Buttapietra',
    'Buti',
    'Busto Garolfo',
    'Busto Arsizio',
    'Bussoleno',
    'Bussolengo',
    'Busseto',
    'Bussana',
    'Busche',
    'Buscate',
    'Busca',
    'Busano',
    'Busalla',
    'Busachi',
    'Burolo',
    'Burgusio',
    'Burgos',
    'Burano',
    'Buonconvento',
    'Buglio in Monte',
    'Budrione',
    'Budrio',
    'Budoia',
    'Bucine',
    'Buccino',
    'Bucciano',
    'Bucchianico',
    'Bubbio',
    'Brusson',
    'Brusnengo',
    'Brusciano',
    'Brusasco',
    'Bruneck',
    'Brunate',
    'Bruna',
    'Bruino',
    'Brugnera',
    'Brugnato',
    'Brugine',
    'Brugherio',
    'Brufa',
    'Brossasco',
    'Bronzolo',
    'Broni',
    'Brivio',
    'Brittoli',
    'Brissogne',
    'Brissago-Valtravaglia',
    'Brisighella',
    'Brindisi',
    "Brignano Gera d'Adda",
    'Briga Novarese',
    'Bricherasio',
    'Bresso',
    'Bressanvido',
    'Brixen',
    'Bressana Bottarone',
    'Brescia',
    'Brescello',
    'Brentonico',
    'Breno',
    'Brenner',
    'Brendola',
    'Brembio',
    'Brembilla',
    'Brembate',
    'Breganze',
    'Breda di Piave',
    'Brebbia',
    'Brazzano',
    'Brandizzo',
    'Bracigliano',
    'Bracciano',
    'Bra',
    'Bozzolo',
    'Bovolone',
    'Bovolenta',
    'Bovisio-Masciago',
    'Bovino',
    'Boville Ernica',
    'Bovezzo',
    'Boves',
    'Bovegno',
    'Botticino',
    'Bottanuco',
    'Bosisio Parini',
    'Boscotrecase',
    'Boscoreale',
    'Bosconero',
    'Bosco Chiesanuova',
    'Boschetto',
    'Bosaro',
    'Bosa',
    'Bortigali',
    'Borso del Grappa',
    'Borore',
    'Bornasco',
    'Bormio',
    'Borgo Vercelli',
    'Borgo Val di Taro',
    'Borgo Tossignano',
    'Borgo Ticino',
    'Borgosesia',
    'Borgosatollo',
    'Borgo San Martino',
    'Borgo San Lorenzo',
    'Borgo San Giacomo',
    'Borgo San Dalmazzo',
    'Borgo Sabotino-Foce Verde',
    'Borgorose',
    'Borgoricco',
    'Borgoratto Mormorolo',
    'Borgo Priolo',
    'Borgo Podgora',
    'Borgonovo Val Tidone',
    'Borgone Susa',
    'Borgo Montenero',
    'Borgomaro',
    'Borgomanero',
    'Borgolavezzaro',
    "Borgofranco d'Ivrea",
    'Fosso Ghiaia',
    'Borgo di Terzo',
    "Borgo d'Ale",
    'Borgo Carso',
    'Borgo a Mozzano',
    'Buggiano',
    'Borgo Valsugana',
    'Borghetto Santo Spirito',
    'Borghetto Lodigiano',
    "Borghetto d'Arroscia",
    'Borgaro Torinese',
    'Borgagne',
    'Boretto',
    'Bordighera',
    'Borbona',
    'Bonorva',
    'Bono',
    'Bonefro',
    'Bonea',
    'Bondeno',
    'Bonavigo',
    'Bonate Sopra',
    'Bonassola',
    'Bonarcado',
    'Bomporto',
    'Bomba',
    'Bolzano Vicentino',
    'Bolzano Novarese',
    'Bolzano',
    'Bolzaneto',
    'Lake Bolsena',
    'Bolotana',
    'Bolognano',
    'Bologna',
    'Bollengo',
    'Bollate',
    'Bolano',
    'Bojano',
    'Bogliasco',
    'Boffalora sopra Ticino',
    "Boffalora d'Adda",
    'Bobbio',
    'Darfo Boario Terme',
    'Boara Polesine',
    'Blera',
    'Bitti',
    'Bitritto',
    'Bitonto',
    'Bitetto',
    'Bistagno',
    'Bisceglie',
    'Bisaccia',
    'Birori',
    'Bioglio',
    'Binasco',
    'Bientina',
    'Bienno',
    'Biella',
    'Biccari',
    'Bibione',
    'Bibbona',
    'Bibbiena',
    'Bibbiano',
    'Biassono',
    'Bianzone',
    'Bianzè',
    'Biandronno',
    'Biandrate',
    'Bevilacqua',
    'Bevazzana',
    'Bevagna',
    'Bettona',
    'Bettolle',
    'Bettola',
    'Besozzo',
    'Besnate',
    'Besenello',
    'Besano',
    "Sant'Andrea in Besanigo",
    'Besana in Brianza',
    'Berzo Inferiore',
    'Berzo Demo',
    'Bertiolo',
    'Bertinoro',
    'Berra',
    'Bernate Ticino',
    'Bernalda',
    'Berlingo',
    'Bergeggi',
    'Bergantino',
    'Bergamo',
    'Bereguardo',
    'Berchidda',
    'Berceto',
    'Berbenno di Valtellina',
    'Bentivoglio',
    'Benna',
    'Benevento',
    'Bene Vagienna',
    'Benetutti',
    'Belvedere Ostrense',
    'Belmonte Piceno',
    'Belmonte Castello',
    'Belluno',
    'Bellosguardo',
    'Bellona',
    'Bellocchi',
    'Bellinzago Novarese',
    'Bellegra',
    'Bellante',
    'Bellano',
    'Bellaguarda',
    'Bellagio',
    'Belgirate',
    'Belgioioso',
    'Belforte',
    'Belfiore',
    'Beinette',
    'Beinasco',
    'Bedonia',
    'Bedollo',
    'Bazzano',
    'Baveno',
    'Battipaglia',
    'Battaglia Terme',
    'Bastia umbra',
    'Bassiano',
    'Bassano Romano',
    'Bassano in Teverina',
    'Bassano del Grappa',
    'Bassano Bresciano',
    'Basilicanova',
    'Basiliano',
    'Baselice',
    'Baselga di Pinè',
    'Basciano',
    'Baschi',
    'Basagliapenta',
    'Barzanò',
    'Barzana',
    'Giacciano con Baruchella',
    'Barra',
    'Baronissi',
    'Barni',
    'Barletta',
    'Barlassina',
    'Barile',
    'Baricella',
    'Bari',
    'Bargi',
    'Barghe',
    'Barge',
    'Bargagli',
    'Barga',
    'Bareggio',
    'Bardonecchia',
    'Bardolino',
    'Bardineto',
    'Bardi',
    'Barco',
    'Barbiano',
    'Barbianello',
    "Barberino Val d'Elsa",
    'Barberino di Mugello',
    'Barbeano',
    'Barbarano Vicentino',
    'Barbarano Romano',
    'Baranzate',
    "Barano d'Ischia",
    'Baranello',
    'Baragiano',
    'Baone',
    'Banzi',
    'Banari',
    'Balzola',
    'Balvano',
    'Ballabio',
    'Baldissero Torinese',
    "Baldichieri d'Asti",
    'Balangero',
    'Baiso',
    'Baiano',
    'Bagolino',
    'Bagnoregio',
    'Bagnone',
    'Bagnolo San Vito',
    'Bagnolo Piemonte',
    'Bagnolo Mella',
    'Bagnolo in Piano',
    'Bagnolo del Salento',
    'Bagnolo Cremasco',
    'Bagnolo',
    'Bagnoli Irpino',
    'Bagnoli di Sopra',
    'Bagno di Romagna',
    'Bagno a Ripoli',
    'Bagni di Lucca',
    'Bagnarola',
    'Bagnara di Romagna',
    'Bagnara',
    'Bagnaia',
    'Bagnacavallo',
    'Badia Tedalda',
    'Badia Polesine',
    'Badia Pavese',
    'Badia Calavena',
    'Badia',
    'Badesse',
    'Badesi',
    'Bacoli',
    'Azzone',
    'Azzate',
    'Azzano Decimo',
    'Azzanello',
    'Azeglio',
    'Aymavilles',
    'Avio',
    'Avigna',
    'Avigliano Umbro',
    'Avigliano',
    'Avigliana',
    'Aviano',
    'Avezzano',
    'Avetrana',
    'Aversa',
    'Avellino',
    'Avella',
    'Ausonia',
    'Auronzo di Cadore',
    'Duino-Aurisina',
    'Aulla',
    'Auletta',
    'Auditore',
    'Attimis',
    'Attigliano',
    'Atripalda',
    'Atri',
    'Atessa',
    'Atena Lucana',
    'Atella',
    'Asti',
    'Asso',
    'Assisi',
    'Asolo',
    'Asola',
    'Asiago',
    'Ascoli Satriano',
    'Ascoli Piceno',
    'Asciano',
    'Ascea',
    'Arzignano',
    'Arzergrande',
    'Arzano',
    'Arzachena',
    'Artogne',
    'Artena',
    'Artegna',
    'Arta Terme',
    'Arsoli',
    'Arsita',
    'Arsiero',
    'Arsiè',
    'Arsago Seprio',
    'Arrone',
    'Arquata Scrivia',
    'Arquà Petrarca',
    'Arpino',
    'Arpaia',
    'Arosio',
    'Arona',
    'Arnesano',
    'Arnara',
    'Arma di Taggia',
    'Arluno',
    'Arlena di Castro',
    'Arischia',
    'Arignano',
    'Arienzo',
    'Arielli',
    'Ariccia',
    'Ariano nel Polesine',
    'Ariano Irpino',
    'Ari',
    'Argenta',
    'Argelato',
    'Argegno',
    'Arezzo',
    'Arese',
    'Arenzano',
    'Arena Po',
    'Ardesio',
    'Ardenno',
    'Ardea',
    'Arcugnano',
    'Arcore',
    'Arconate',
    'Arcole',
    'Arcola',
    'Arco',
    'Arcidosso',
    'Archi',
    'Arcevia',
    'Arceto',
    'Arcene',
    'Arce',
    'Arcade',
    'Aradeo',
    'Aquino',
    'Aquilonia',
    'Aquileia',
    'Aquara',
    'Aprilia',
    'Apricena',
    'Aprica',
    'Appignano del Tronto',
    'Appignano',
    'Appiano Gentile',
    'San Michele',
    'Apollosa',
    'Apiro',
    'Apice',
    'Apecchio',
    'Aosta',
    'Anzu',
    "Anzola dell'Emilia",
    'Anzio',
    'Anzano di Puglia',
    'Anzano del Parco',
    'Anversa degli Abruzzi',
    'Antrosano',
    'Antrodoco',
    'Antella',
    'Antegnate',
    'Annone Veneto',
    'Annone di Brianza',
    'Anitrella-Chiaiamari',
    'Anguillara Veneta',
    'Anguillara',
    'Angri',
    'Angolo Terme',
    'Anghiari',
    'Angera',
    'Anfo',
    'Anela',
    'Andria',
    'Andorno Micca',
    'Andezeno',
    'Andalo',
    'Ancona',
    'Anagni',
    'Anacapri',
    'Ampezzo',
    'Amorosi',
    'Amola di Piano',
    'Amelia',
    'Ameglia',
    'Ambra',
    'Amatrice',
    'Amaseno',
    'Amandola',
    'Amalfi',
    'Alzano Lombardo',
    'Alvito',
    'Alvignano',
    'Altopascio',
    'Altino',
    'Altidona',
    'Altedo',
    'Altavilla Vicentina',
    'Altavilla Silentina',
    'Altavilla Irpina',
    'Altare',
    'Altamura',
    'Alpignano',
    'Almese',
    'Almenno San Salvatore',
    'Almenno San Bartolomeo',
    'Almè',
    'Allumiere',
    'Alleghe',
    'Alife',
    'Alice Castello',
    'Aliano',
    'Alghero',
    'Alfonsine',
    'Alfiano Natta',
    'Alfedena',
    'Alfano',
    'Alezio',
    'Alessandria',
    'Aldeno',
    'Albuzzano',
    'Albizzate',
    'Albissola Marina',
    'Albino',
    'Albinea',
    'Albignasego',
    'Albiate',
    'Albettone',
    'Alberobello',
    'Alberese',
    'Albenga',
    "Albaredo d'Adige",
    "Albano Sant'Alessandro",
    'Albano Laziale',
    'Albanella',
    'Albacina-Borgo Tufico',
    'Alba',
    'Alatri',
    'Alassio',
    'Alanno',
    'Alagna',
    'Ala di Stura',
    'Ala',
    'Airuno',
    'Airola',
    'Airasca',
    'Aiello del Sabato',
    'Aiello del Friuli',
    'Aidomaggiore',
    'Agugliaro',
    'Agugliano',
    'Agropoli',
    'Agrate Brianza',
    'Agordo',
    'Agognate',
    'Agnone',
    'Agnadello',
    'Agna',
    'Agliana',
    'Aggius',
    'Agerola',
    'Agello',
    'Afragola',
    'Affile',
    'Affi',
    'Adro',
    'Adria',
    'Adelfia',
    'Acuto',
    'Acqui Terme',
    'Acquaviva Picena',
    'Acquaviva delle Fonti',
    'Acquaviva',
    'Acquasparta',
    'Acquasanta Terme',
    'Acquarica',
    'Acquapendente',
    'Acquanegra sul Chiese',
    'Acquanegra Cremonese',
    'Acqualagna',
    'Acquafondata',
    'Acilia-Castel Fusano-Ostia Antica',
    'Acerra',
    'Acerenza',
    'Acciaroli',
    'Acciano',
    'Accadia',
    'Abriola',
    'Abetone',
    'Abbiategrasso',
    'Abbazia Pisani',
    'Abbasanta',
    'Abbadia San Salvatore',
    'Abbadia Lariana',
    'Abano Terme',
    'Santa Maria la Palma',
    'Biancareddu',
    'Belvedere',
    'San Martino dei Mulini',
    'Gatteo',
    'Sala',
    'Bellariva',
    'Lido Adriano',
    'Lido di Spina',
    'Lido degli Estensi',
    'Gorino Veneto',
    'Capriva del Friuli',
    'San Lorenzo Isontino',
    'Saciletto',
    'San Vito al Torre',
    "San Canzian d'Isonzo",
    'Pavia di Udine',
    'Bagnaria Arsa',
    'Gorgo',
    'Meduna di Livenza',
    'Gorgo al Monticano',
    'Pramaggiore',
    'Ormelle',
    'San Polo di Piave',
    'Maserada sul Piave',
    'Covolo-Levada',
    'Santa Lucia di Piave',
    'Mareno di Piave',
    'Sorafurcia',
    'Colle Umberto',
    'Orsago',
    'Biadene',
    'Selva del Montello',
    'Povegliano',
    'Falze-Signoressa',
    'Falze',
    'Ponzano Veneto',
    'Lancenigo',
    'Fossò',
    "Fiesso d'Artico",
    'Marano',
    'Dese',
    'Peseggia-Gardigiano',
    'Rio San Martino',
    'Morgano',
    'Casier',
    'Biancade',
    'Veternigo',
    'Sezza',
    'Spinea',
    'Piovega',
    'Cercivento',
    'Trichiana',
    'Limana',
    'Valle di Cadore',
    'Col San Martino',
    'Orsaria',
    'Pradamano',
    'Basaldella',
    'Adegliacco',
    'Passons',
    'Colugna',
    'Pagnacco',
    'Zomeais',
    'Bordano',
    'Chiaicis',
    'San Martino al Tagliamento',
    'Ramuscello',
    'Roncadelle',
    'Veneto',
    'Leivi',
    'Caperana',
    'Cogorno',
    'Brusaporto',
    'San Bartolomeo al Mare',
    'Verbania',
    'Pieve Emanuele',
    'Osio Sotto',
    'Bedizzole',
    'Lumezzane',
    'Mazzano',
    'Porto Mantovano',
    'Noventa',
    'Castelnuovo del Garda',
    "Romano d'Ezzelino",
    'Saonara',
    'Mediglia',
    'Buccinasco',
    'Monte Argentario',
    'Guidonia Montecelio',
    'Cervinara',
    'Lamezia Terme',
    'Cardano al Campo',
    'Caronno Pertusella',
    'Cassano Magnago',
    'Gerenzano',
    'Uboldo',
    'Pessano Con Bornago',
    'Settimo Milanese',
    'Cassola',
    'Pieve a Nievole',
    'Albisola Superiore',
    'Arcisate',
    'Basiglio',
    'Bussero',
    'Verano Brianza',
    'Treviolo',
    'Concesio',
    'Pino Torinese',
    "San Cipriano d'Aversa",
    'San Felice A Cancello',
    'San Nicola la Strada',
    'Crispano',
    'Pollena Trocchia',
    'Tremestieri Etneo',
    'Santena',
    'Vinovo',
    'San Pietro in Cariano',
    "Ozzano dell'Emilia",
    'Casagiove',
    'Frignano',
    'Orta di Atella',
    'San Marcellino',
    'Teverola',
    'Casavatore',
    'Santa Maria La Carita',
    'Trecase',
    'Bellizzi',
    'Alba Adriatica',
    'Valderice',
    'Gravina di Catania',
    "Sant'Agata Li Battiati",
    'Lusciano',
    'Parete',
    'Piedimonte Matese',
    'Cesano Boscone',
    'Cesate',
    'Porto Viro',
    'Tor Lupara',
    'San Giovanni Teatino',
    'Bernareggio',
    'Coassolo Torinese',
    'Inverso Pinasca',
    'Roddi',
    'Roure',
    'San Raffaele Cimena',
    'Val della Torre',
    'Mombaruzzo',
    'Mombercelli',
    'Montabone',
    'Montafia',
    'Refrancore',
    "Rocca d'Arazzo",
    'Agrate Conturbia',
    'Castelspina',
    'Conzano',
    'Oviglio',
    'Camerana',
    'Verrayes',
    'Monastero di Vasco',
    'Diano Arentino',
    "Monticello d'Alba",
    'Roccabruna',
    'Mallare',
    'Torre Mondovi',
    'Plodio',
    'Casarza Ligure',
    'Davagna',
    'Orero',
    'Beverino',
    'Incisa Scapaccino',
    'Malgesso',
    'Mesenzana',
    'Montegrino Valtravaglia',
    'Saltrio',
    'Blevio',
    'Bulgarograsso',
    'Carbonate',
    "Caslino d'Erba",
    'Casnate Con Bernate',
    'Castelnuovo Bozzente',
    'Ayas',
    'Champorcher',
    'Introd',
    'Merone',
    'Moltrasio',
    'Montorfano',
    'Nesso',
    'Andora',
    'Arnasco',
    'Bodio Lomnago',
    'Brezzo di Bedero',
    'Castelveccana',
    'Novedrate',
    'Pare',
    'San Bartolomeo Val Cavargna',
    'San Fermo della Battaglia',
    'San Nazzaro Val Cavargna',
    'Solbiate',
    'Veniano',
    'Tresivio',
    'Albavilla',
    'Beregazzo con Figliaro',
    'Bregnano',
    'Assago',
    'Basiano',
    'Bellusco',
    'Cremia',
    'Eupilio',
    'Faggeto Lario',
    'Faloppio',
    "Pozzo d'Adda",
    'Pozzuolo Martesana',
    'Renate',
    'Roncello',
    "Canonica d'Adda",
    'Carobbio degli Angeli',
    'Cenate Sopra',
    "Moio de' Calvi",
    'Mozzo',
    'Palazzago',
    'Pedrengo',
    'Pianico',
    'Piario',
    'Pognano',
    'Ponteranica',
    'Foresto Sparso',
    "San Paolo d'Argon",
    "Sant'Omobono Terme",
    'Ubiale Clanezzo',
    'Valgoglio',
    'Vilminore di Scalve',
    'Bione',
    'Brione',
    'Verdellino',
    'Viadanica',
    'Vigano San Martino',
    'Costa di Serina',
    'Agnosine',
    'Alfianello',
    'Idro',
    'Manerba del Garda',
    'Inverno e Monteleone',
    'Collebeato',
    'Corteno Golgi',
    'Erbusco',
    'Malegno',
    'Ome',
    'Cappella Cantone',
    "Corte de' Cortesi con Cignone",
    'Polpenazze del Garda',
    'Persico Dosimo',
    "Urago d'Oglio",
    'Montescano',
    'Redavalle',
    'Roncaro',
    'Rovescala',
    'Santa Maria della Versa',
    'Vigo di Ton',
    'Trambileno',
    'Torricella Verzate',
    'Valfloriana',
    'Brentino Belluno',
    'Brenzone',
    'Lavagno',
    'Travaco Siccomario',
    'Verrua Po',
    'Zeccone',
    'Ripalta Arpina',
    'San Daniele Po',
    'San Pietro Valdastico',
    "Velo d'Astico",
    'Ponti sul Mincio',
    'Redondesco',
    'Serravalle a Po',
    'Vo',
    'Papozze',
    'Malosco',
    'Ossana',
    'Ronzo-Chienis',
    'Buja',
    'Segonzano',
    'Sfruz',
    'Reana del Rojale',
    'Tenna',
    'Angiari',
    'Colognola ai Colli',
    'Roveredo di Gua',
    'Rovere Veronese',
    'Molvena',
    'San Gregorio nelle Alpi',
    'Terrassa Padovana',
    'Castel di Lama',
    'Zeri',
    'San Romano in Garfagnana',
    'Cerignale',
    'Sambuca Pistoiese',
    'Capraia e Limite',
    'Tavarnelle Val di Pesa',
    'Gazzola',
    'Calvignasco',
    'Sardigliano',
    'Nibionno',
    'Lumarzo',
    'Cornegliano Laudense',
    'Castegnero',
    'Greccio',
    'Palazzo Pignano',
    'Terranova dei Passerini',
    "Capriata d'Orba",
    'Granaglione',
    'Fabbrica Curone',
    "Campo nell'Elba",
    'Isola del Giglio',
    'Tresana',
    'Pontey',
    'Larciano',
    'Orco Feglino',
    'Roccavignale',
    'Broccostella',
    'Cosio Valtellino',
    'Castel di Sasso',
    'Castel Morrone',
    'Samolaco',
    'Valdisotto',
    'Monte Isola',
    'Monticelli Brusati',
    'Muscoline',
    'Ossimo Superiore',
    "Provaglio d'Iseo",
    'Provaglio Val Sabbia',
    'Remedello',
    'Rodengo-Saiano',
    'Gardola',
    'Castelletto di Branduzzo',
    'Lettere',
    'Sovramonte',
    'Paderno del Grappa',
    'Saint Marcel',
    'Valtournenche',
    'Ceranesi',
    'Roe Volciano',
    'Venticano',
    'Cortina sulla Strada del Vino',
    'Senales',
    'Tirolo',
    'Ultimo - Ulten',
    'Prati',
    'Vallada Agordina',
    'Cavaso del Tomba',
    'Montecorvino Pugliano',
    'Balsorano',
    'Lucoli',
    'Conflenti',
    'Africo Nuovo',
    'Valledoria',
    'Castello di Brianza',
    'Monticello Brianza',
    'Castiraga Vidardo',
    'Antrona Schieranco',
    'Brovello-Carpugnino',
    'Oggebbio',
    'Monterosso Grana',
    'Murello',
    'Pianfei',
    'Pocapaglia',
    'Torre San Giorgio',
    "Vezza d'Alba",
    'Villar San Costanzo',
    'San Martino Alfieri',
    'San Paolo Solbrito',
    'Viarigi',
    'Avolasca',
    'Berzano di Tortona',
    'Borghetto di Borbera',
    'Molino dei Torti',
    'Mombello Monferrato',
    'Montemarzino',
    'Piovera',
    'Ponzano Monferrato',
    "Sant'Agata Fossili",
    'Arnad',
    'Agra',
    'Azzio',
    'Barasso',
    'Brenta',
    'Brusimpiano',
    'Caronno Varesino',
    'Cuasso al Monte',
    'Cugliate-Fabiasco',
    'Cunardo',
    'Daverio',
    'Gorla Minore',
    'Jerago Con Orago',
    'Leggiuno',
    'Varano Borghi',
    'Albese Con Cassano',
    'Alserio',
    'Cavallasca',
    'Corrido',
    'Fenegro',
    'Laino',
    'Briosco',
    'Cassinetta di Lugagnano',
    'Cogliate',
    'Correzzana',
    'Dresano',
    'Gessate',
    'Lazzate',
    'Lesmo',
    'Macherio',
    'Masate',
    'Mesero',
    'Mezzago',
    'San Vittore Olona',
    'Sulbiate',
    'Veduggio Con Colzano',
    'Vizzolo Predabissi',
    'Barbata',
    'Boltiere',
    'Bonate Sotto',
    'Bossico',
    "Chignolo d'Isola",
    'Colzate',
    'Entratico',
    'Filago',
    'Fiorano al Serio',
    'Fonteno',
    'Gorlago',
    'Madone',
    'Presezzo',
    'Castelcovati',
    'Borgo San Siro',
    'Chignolo Po',
    'Magherno',
    'Marcignago',
    'Casaletto Vaprio',
    'Malagnino',
    'Castelbelforte',
    'Motteggiana',
    'Daiano',
    'Rivoli Veronese',
    'Selva di Progno',
    'Alonte',
    'Altissimo',
    'Brogliano',
    'Campolongo sul Brenta',
    'Carre',
    'Mason Vicentino',
    'Montecchio Precalcino',
    'Montegalda',
    'Mussolente',
    'Pove del Grappa',
    'Alano di Piave',
    'Altivole',
    'Segusino',
    'Villafranca Padovana',
    'Arqua Polesine',
    'Frassinelle Polesine',
    'Capolona',
    'Montecreto',
    'Voghiera',
    'Montefelcino',
    'Maltignano',
    'Smerillo',
    'Montescudaio',
    'Jenne',
    'Montelibretti',
    'Roccagorga',
    'Coreno Ausonio',
    'Rivalta Bormida',
    'Rivarone',
    'Rocca Grimalda',
    'Tagliolo Monferrato',
    'Vignole Borbera',
    'Villadeati',
    'Villaromagnano',
    'Allein',
    'Donnas',
    'Gignod',
    'Nus',
    'Villeneuve',
    'Boissano',
    'Massimino',
    'Tovo San Giacomo',
    'Mele',
    'Savignone',
    'Bisuschio',
    'Brunello',
    'Buguggiate',
    'Cadrezzate',
    'Cantello',
    'Casale Litta',
    'Cremenaga',
    'Crosio della Valle',
    'Cuveglio',
    'Cuvio',
    'Galliate Lombardo',
    'Germignaga',
    'Grantola',
    'Inarzo',
    'Monvalle',
    'Morazzone',
    'Sangiano',
    'Albiolo',
    'Alzate Brianza',
    'Carugo',
    'Cassina Rizzardi',
    'Cucciago',
    'Figino Serenza',
    'Guanzate',
    'Limido Comasco',
    'Lipomo',
    'Longone al Segrino',
    'Luisago',
    'Mantello',
    'Montagna in Valtellina',
    'Ponte in Valtellina',
    'Talamona',
    'Aicurzio',
    'Albairate',
    'Bellinzago Lombardo',
    'Bubbiano',
    'Burago di Molgora',
    'Busnago',
    'Grezzago',
    'Ronco Briantino',
    'San Giorgio Su Legnano',
    'Settala',
    'Sovico',
    'Vedano al Lambro',
    'Zelo Surrigone',
    "Arzago d'Adda",
    'Azzano San Paolo',
    'Bagnatica',
    'Bariano',
    'Bolgare',
    'Cavernago',
    'Chiuduno',
    'Cortenuova',
    'Gandosso',
    'Grassobbio',
    'Levate',
    'Lurano',
    'Morengo',
    'Mornico al Serio',
    'Ranzanico',
    'Rogno',
    'Songavazzo',
    'Suisio',
    "Terno d'Isola",
    "Villa d'Adda",
    'Caino',
    'Calvagese della Riviera',
    'Capriano del Colle',
    'Castrezzato',
    'Cellatica',
    'Gianico',
    'Montirone',
    'Niardo',
    'Nuvolera',
    'Polaveno',
    'Pontoglio',
    'San Zeno Naviglio',
    'Trenzano',
    'Zone',
    'Borgarello',
    'Bosnasco',
    "Castello d'Agogna",
    'Pinarolo Po',
    "Torre d'Arese",
    'Torrevecchia Pia',
    'Trivolzio',
    'Valle Salimbene',
    'Bonemerse',
    'Casaletto di Sopra',
    'Chieve',
    "Corte de' Frati",
    'Cremosano',
    'Fiesco',
    'Pianengo',
    'Quintano',
    'Ricengo',
    'Borgofranco sul Po',
    'Felonica',
    'Mariana Mantovana',
    'Villa Poma',
    'Bieno',
    'Calceranica al Lago',
    'Cavedine',
    'Cis',
    'Faver',
    'Frassilongo',
    'Giustino',
    'Cantalupa',
    'Cercenasco',
    'Colleretto Giacosa',
    'Givoletto',
    'Grosso',
    'Salerano Canavese',
    'Bogogno',
    'Briona',
    'Colazza',
    'Veruno',
    'Succivo',
    "Sant'Angelo A Cupolo",
    'Casamicciola Terme',
    'Castello di Cisterna',
    'San Vitaliano',
    'Scisciano',
    'Cassano Irpino',
    'Cesinali',
    'Moschiano',
    'Castelluccio dei Sauri',
    'Binetto',
    'Brienza',
    'Figline Vegliaturo',
    'Santa Maria del Cedro',
    'Amato',
    'Sellia Marina',
    'Furnari',
    'Savoca',
    'Camporotondo Etneo',
    'San Pietro Clarenza',
    'Mazzarrone',
    'Ragalna',
    'Ronco Biellese',
    'Tavigliano',
    'Valdengo',
    'Bulciago',
    'Calco',
    'Cassago Brianza',
    'Civate',
    'Costa Masnaga',
    'Garlate',
    'Imbersago',
    'Pasturo',
    'Perego',
    'Perledo',
    'Pescate',
    'Rogeno',
    'Sirtori',
    'Suello',
    'Valgreghentino',
    'Verderio Inferiore',
    "Cervignano d'Adda",
    'Graffignana',
    'Ospedaletto Lodigiano',
    'Pieve Fissiraga',
    'Sordio',
    'Valera Fratta',
    'Montegridolfo',
    'Arizzano',
    'Vignone',
    'Bibiana',
    'Bruzolo',
    'Cafasse',
    'Castagnole Piemonte',
    'Castiglione Torinese',
    'Chianocco',
    'Chiaverano',
    'La Cassa',
    'Levone',
    'Lusiglie',
    'Piscina',
    'Piverone',
    'Porte',
    'Rivarossa',
    'Rosta',
    'San Carlo Canavese',
    'San Colombano Belmonte',
    'Usseaux',
    'Villanova Canavese',
    'Vische',
    'Caresanablot',
    'San Giacomo Vercellese',
    'Scopa',
    'Casalbeltrame',
    'Cavaglietto',
    "Cavaglio D'Agogna",
    'Dormelletto',
    'Gargallo',
    'Maggiora',
    'Mandello Vitta',
    'Miasino',
    'Alto',
    "Baldissero d'Alba",
    'Barolo',
    'Cavallerleone',
    'Cervasca',
    'Clavesana',
    'Farigliano',
    'Frabosa Sottana',
    'Guarene',
    'Lagnasco',
    'Lequio Berria',
    'Marmora',
    'Montaldo Roero',
    'Montanera',
    'Piozzo',
    'Priocca',
    'Prunetto',
    'Roccasparvera',
    'Somano',
    'Treiso',
    'Vicoforte',
    'Vottignasco',
    'Aramengo',
    'Calosso',
    'Cerro Tanaro',
    'Frinco',
    'Portacomaro',
    "Revigliasco d'Asti",
    'Rocchetta Tanaro',
    'Carbonara Scrivia',
    'Castelletto Monferrato',
    'Gremiasco',
    'Pasturana',
    'Crevoladossola',
    'Romallo',
    'Scurelle',
    'Strembo',
    'Caldiero',
    'Cazzano di Tramigna',
    'Isola Rizza',
    'Mezzane di Sotto',
    'Sorga',
    'Cartigliano',
    'Gambugliano',
    'Nogarole Vicentino',
    'San Germano dei Berici',
    'Sovizzo',
    'Zugliano',
    'Boara Pisani',
    'Cervarese Santa Croce',
    'San Giorgio delle Pertiche',
    'Bagnolo di Po',
    'Canda',
    'Majano',
    'Treppo Carnico',
    "Farra d'Isonzo",
    'San Pietro in Cerro',
    'San Possidonio',
    'Predappio',
    'Mercatino Conca',
    'Barbara',
    'Castelleone di Suasa',
    'Force',
    'Filattiera',
    'Villa Collemandina',
    'Montenero Sabino',
    'Trivigliano',
    'Carinaro',
    'Castello del Matese',
    'Curti',
    'Piana di Monte Verna',
    'Villa di Briano',
    'Falciano del Massico',
    'Casapesenna',
    'San Nicola Manfredi',
    'Carbonara di Nola',
    'Massa di Somma',
    'Montella',
    'Pago del Vallo di Lauro',
    'Vallesaccarda',
    'Felitto',
    'Morino',
    'Rivisondoli',
    'Roccamorice',
    'Rocca San Giovanni',
    'Gagliato',
    'Settingiano',
    'Campo Calabro',
    'Maropati',
    'Oppido Mamertina',
    'Riace',
    'Graniti',
    'Limina',
    'Pace del Mela',
    'Rometta',
    'Budoni',
    'Casapinta',
    'Castelletto Cervo',
    'Dorzano',
    'Miagliano',
    'Ponderano',
    'Quaregna',
    'Roppolo',
    'Zubiena',
    'Zumaglia',
    'Barzago',
    'Cesana Brianza',
    'Cremella',
    'Dolzago',
    'Montevecchia',
    'Sirone',
    'Vigano',
    'Borgo San Giovanni',
    'Livraga',
    'Marudo',
    'Merlino',
    'Zungri',
    'Bee',
    'Trontano',
    'Pastorano',
    'Casciago',
    'Cassano Valcuvia',
    'Bene Lario',
    'Binago',
    'Cabiate',
    'Cagno',
    'Ronago',
    'San Fedele Intelvi',
    'Senna Comasco',
    'Sorico',
    'Tavernerio',
    'Turate',
    'Valmorea',
    'Andalo Valtellino',
    'Civo',
    'Lanzada',
    'Noviglio',
    'Caponago',
    'Cerro al Lambro',
    'Cusago',
    'Pregnana Milanese',
    'Bracca',
    'Branzi',
    'Brembate di Sopra',
    'Castel Rozzone',
    'Torre Pallavicina',
    'Onore',
    'Osio Sopra',
    'Paladina',
    'Pontirolo Nuovo',
    'Pradalunga',
    'Ranica',
    'Azzano Mella',
    'Paitone',
    'Ceranova',
    'Cura Carpignano',
    'Pizzale',
    'Imer',
    'Nave San Rocco',
    'Pomarolo',
    'Banchette',
    'Lusernetta',
    'Maglione',
    'Marentino',
    'Mezzenile',
    'Osasco',
    'Osasio',
    'San Sebastiano da Po',
    'San Secondo di Pinerolo',
    'Vaie',
    'Varisella',
    'Vidracco',
    'Villarbasse',
    'Villar Dora',
    'Moncrivello',
    'Garbagna Novarese',
    'Gattico',
    'Massino Visconti',
    'Nebbiuno',
    'Oleggio Castello',
    'Pettenasco',
    'Bernezzo',
    'Brondello',
    'Caramagna Piemonte',
    'Castellinaldo',
    'Castiglione Falletto',
    'Castiglione Tinella',
    'Grinzane Cavour',
    "Corneliano d'Alba",
    'Faule',
    'Govone',
    'Lequio Tanaro',
    'Mosso',
    'Cavagnolo',
    'Mezzani',
    'Isola Albarella',
    'Albinia',
    'Lippiano',
    'Colle di Compito',
    'Capezzano Pianore',
    'Castellina Scalo',
    'Porto Cervo',
    'Porto Rotondo',
    'Guamo',
    'Segromigno in Monte',
    'Corsanico',
    'Pieve a Elici',
    'Stiava',
    'Tellaro',
    'Montuolo',
    'Orentano',
    'Aci Trezza',
    'Eraclea Mare',
    'Fontane Bianche',
    'Custoza',
    'Tortoreto Lido',
    'Ischia Ponte',
    'Povo',
    'Castellaneta Marina',
    'Torvaianica',
    'Torre Colimena',
    'Sieci',
    'Klobenstein',
    'Monticelli Terme',
    'Antria',
    'Gerno di Lesmo',
    'Panarea',
    'Aci San Filippo',
    'Gabicce Monte',
    'Stromboli',
    "Trinita d'Agultu",
    'Massaciuccoli',
    'Passoscuro',
    'Ara Nova',
    'Calerno',
    'San Sisto',
    'Vitinia',
    'Abbadia di Montepulciano',
    'Avane',
    'Oriago Di Mira',
    'Nova Siri Scalo',
    'Milano',
    'Pollenzo',
    'Goldrain',
    'Anconetta',
    'Santa Fosca',
    'Villa Adriana',
    'Molassana',
    'Tavernelle di Panicale',
    'Mezzolago',
    'Bornato',
    'Caulonia Marina',
    'Cavalcaselle',
    'Arbizzano-Santa Maria',
    'Gasponi',
    "Madonna Dell'Olmo",
    'Calderino',
    'Cancello ed Arnone',
    'Bellaria-Igea Marina',
    'Cavallino-Treporti',
    'Marconia',
    'Monte San Quirico',
    'Guardasone',
    'Montepaone Lido',
    'Catanzaro Lido',
    'Ostia Antica',
    'Arcavacata',
    'Basilicagoiano',
    'Margine Coperta-Traversagna',
    'Pozzalis',
    'Marco Simone',
    'Latina Scalo',
    'Tornimparte',
    'Torre di Palme',
    'Centobuchi',
    'Santa Maria delle Mole',
    'Lido dei Pini',
    'Carmignanello',
    'Carraia',
    'San Piero a Ponti',
    'Scarlino Scalo',
    'Sella di Corno',
    'Marina di Rocca Priora',
    'Monterusciello',
    'Casa Santa',
    'Progresso',
    'Andreotta',
    'Villatora',
    'Pontasserchio',
    'Gerbole',
    'Setteville',
    'Roveleto',
    'Tivolille Pasquali-Merenzata',
    'Villa Rosa',
    'Su Planu',
    'Capaccio Scalo',
    'Toscanella',
    'Lunetta-Frassino',
    'Vighignolo',
    'Osteria Grande',
    'San Brancato',
    'Grunuovo-Campomaggiore San Luca',
    'Bolladello-Peveranza',
    'Castelnuovo Vomano',
    'Manoppello Scalo',
    "Villa d'Agri",
    'Antessano',
    'Vigliano-Bettolino',
    'Colombella',
    'Roteglia',
    'Ravina',
    'Valleggia',
    'Monticello',
    'Limidi',
    'Stazione-Fornola',
    'Pascarola',
    'Ferriera',
    'San Damaso',
    'Coperchia',
    'Sinigo',
    'Rilievo',
    'Vidardo',
    'Guardavalle Marina',
    'Osteria Nuova',
    'Area Produttiva',
    'Saint Maurice',
    'Villa Musone',
    'Bivio Santa Cecilia',
    'Bodio',
    'Arino',
    'San Tommaso Tre Archi',
    'Onigo',
    'Villa Ceccolini',
    'Piana San Raffaele',
    'San Michele Tiorre',
    'Stazione',
    'Matinella',
    'Torrion Quartara',
    'Cuasso al Piano',
    "Ca' Rainati",
    'Asparetto',
    'Pratissolo',
    'Villaguattera',
    'Mandrogne',
    'Bellavista',
    'Folzano',
    'Zona Industriale',
    "Madonna Dell'Albero",
    'Gorzano',
    'Colleranesco',
    'Valmadonna',
    'Cerello-Battuello',
    'Bancali',
    'Sambughe',
    'Giovenzano',
    'Berzantina',
    'Guinzano',
    'Squillace Lido',
    'Spinimbecco',
    'Solara',
    'Cellore',
    'Gliaca',
    'Marocchi',
    'Osteria',
    'Moggio di Sotto',
    'San Pietro in Volta',
    'Ozzano Taro',
    'Stazione Masotti',
    'Badia al Pino',
    'Nosadello',
    'Bargano',
    'Canavaccio',
    'Bulgorello',
    'Borgo Lotti',
    'Fellegara',
    'Giavenale',
    'Gaione',
    'Trevozzo',
    'Correggioli',
    'Campigliano',
    'Boglietto',
    'Chiesa',
    'Casteldarne',
    'Roncola',
    'Santuario',
    'Cascina Nuova',
    'Cadriano',
    'Casalbellotto',
    'Ponte di Serravalle',
    'Sozzigalli',
    'Passo del Bidollo',
    'Albare Stazione',
    'Villanders',
    'Simeri Mare',
    'Ticchione',
    'Castione',
    'Teodone',
    'Marcellise',
    'Il Rosi',
    'Montepertuso',
    'Spregamore',
    'Antonelli',
    'Caluri',
    'Possidente',
    'Acquarola',
    'Casale della Madonna',
    'Cornei',
    'Cerretti',
    'Viotto',
    'Tanca Marchese',
    'Nubia',
    'Mamiano',
    'Campovico',
    'Castionetto',
    'Roncolo',
    'Benne',
    'Filecchio',
    'Martiri di Pizzocalvo',
    'Montesano Scalo',
    'Poscante',
    'Maccaretolo',
    'Mercatale',
    'Is Urigus',
    'Molinelle',
    'Camazzole',
    'Castello di Pratofontana',
    'Vigolo Marchese',
    'Bragno',
    'Contea',
    'Prun',
    'Casenuove',
    'Brusciana',
    'Bindua',
    'Barona',
    'Cafaggio',
    'Cascinetta',
    'Mosio',
    'Bailino-Fornace',
    'Cardazzo',
    'Mollaro',
    'Ferrazze',
    'Contrada Guidano',
    "San Nicolo d'Ega",
    'Apsella',
    'Chifenti',
    "Ca' Della Terra",
    'Alessia',
    'Ranchio',
    'Soranzen',
    'Caupo',
    'Brian',
    'Cervito-Canfora',
    'Ubersetto',
    'Aragno',
    'Santa Croce',
    'Bastremoli',
    'Montesoro',
    'Pietrabuona',
    'Canton Colombo-Gerbo',
    'San Pier Fedele',
    'Nuvolato',
    'Scrofiano',
    'Baldaria',
    'Madonnetta',
    'Vestea',
    'Castelcucco',
    'Santo Janni',
    'Ferrere',
    'Collalbrigo',
    'La Poza',
    'Casette di Funo',
    'Molin Nuovo',
    'Cugnoli',
    'Valdivilla',
    'Corone',
    'Fodico',
    'Case Rosse',
    'Pracando',
    'Feriolo',
    "Madonna dell'Ulivo",
    'Ama',
    'Bondo',
    'Bastiglia',
    'Borgo Petilia',
    'Vianino',
    'Zona Mercato Ortofrutticolo',
    'Antole',
    'Carpeneto',
    'Roletto',
    'Comunanza',
    'Guggal',
    'Localita Produttiva I',
    'Rimbocchi',
    'Merella',
    'Sassorosso',
    'Dolcecanto',
    'Pegoraro',
    'Rupecanina',
    'Gnocchetto',
    'Olza',
    'Monte',
    'Trinita',
    'San Basilio',
    'San Leo',
    'Bova',
    'Canevare',
    'Battistari',
    'Tabiano',
    'Castel Raniero',
    'Sarego',
    'Reggio',
    'Giovo',
    'Zona Industriale Ponte Rosso',
    'Castello',
    'Pietralba',
    'Turano',
    'Sasso Gattone',
    'Citta',
    'Montacchiello',
    'San Polo di Torrile',
    'Panaro',
    'Solfagnano',
    'Peschiera Borromeo',
    'Cassinone',
    'Cerete',
    'Trivero',
    'Telese Terme',
    'Baluello',
    'Casabasciana',
    'Ortignano',
    'Curtatone',
    'Velia',
    'Ne',
    'Nave San Felice',
    'Villa San Paterniano',
    'Loranze Alto',
    'Campolongo Maggiore',
    'Atina Inferiore',
    'San Lorenzo di Rovetta',
    'Canove di Roana',
    'Castelbello',
    'Badia A Elmi',
    'Marciano',
    'Itala Marina',
    'Fiumaretta di Ameglia',
    'Ponte di Castegnero',
    'Piano di Mommio',
    'Bisaccia Nuova',
    'Villanova del Ghebbo Canton',
    'Piane di Montegiorgio',
    'Molinetto',
    'Marotta',
    'Calvi Risorta',
    'Leporano Marina',
    'Chiusi Scalo',
    'Cetraro Marina',
    'Venetico Marina',
    'Orte Scalo',
    'Due Carrare',
    'Torvaianica Alta',
    'Vaccileddi',
    'Aspra',
    'Villaggio Mose',
    'Eppan',
    'Montoro Inferiore',
    'Bizzozero',
    'Scarperia e San Piero',
    "Sant'Urbano",
    'Zoldo Alto',
    'Mappano',
    'Nago',
    'Cagnano',
    'Washington',
    'Arenella',
  ],
  Morocco: [
    'Zaio',
    'Youssoufia',
    'Tiznit',
    'Tiflet',
    'Tétouan',
    'Temara',
    'Taza',
    'Taroudant',
    'Tarfaya',
    'Douar Tardane',
    'Taounate',
    'Taouima',
    'Tan-Tan',
    'Tangier',
    'Tahla',
    'Tafraout',
    'Souk et Tnine Jorf el Mellah',
    'Asmara',
    'Skhirat',
    'Sidi Yahia El Gharb',
    'Sidi Slimane',
    'Sidi Kacem',
    'Sidi Lmokhtar',
    'Bouknadel',
    'Sidi Bibi',
    'Sidi Bennour',
    'Sidi Allal el Bahraoui',
    'Settat',
    'Selouane',
    'Sefrou',
    'Salé',
    'Salah',
    'Safi',
    'Recani',
    'Douar Laarayech',
    'Rabat',
    'Ourtzagh',
    'Oulmes',
    'Douar Oulad Abbou',
    'Oulad Teima',
    'Oujda',
    'Oued Zem',
    'Oued Amlil',
    'Ouezzane',
    'Ouarzazate',
    'Zawyat an Nwacer',
    'Nkob',
    'Nador',
    'Mohammedia',
    'Missour',
    'Midelt',
    'Midar',
    'Marzouga',
    'Meknes',
    'Mehediyah',
    'Mdiq',
    'Martil',
    'Marrakesh',
    'Larache',
    'Ksar El Kebir',
    'Khouribga',
    'Khenifra',
    'Khenichet-sur Ouerrha',
    'Khaoui Naam',
    'Kenitra',
    'Douar Kalaa',
    'Kasba Tadla',
    'Karia Ba Mohamed',
    'Jerada',
    'Mrirt',
    'Inezgane',
    'Zawyat Ifrane',
    'Guercif',
    'Guelmim',
    'Ghafsai',
    'Fnidek',
    'Al Fqih Ben Calah',
    'Fes',
    'Souk Tnine Bouchane',
    'Es Sebt',
    'Essaouira',
    'Arfoud',
    'El Hajeb',
    'Laayoune',
    'Driouch',
    'Douar Jdida',
    'Ad Darwa',
    'Demnate',
    'Eddechira',
    'Dar Bouazza',
    'Chichaoua',
    'Echemmaia Est',
    'Casablanca',
    'Bouznika',
    'Bouskoura',
    'Boudenib',
    'Berkane',
    'Berguene',
    'Beni Mellal',
    'Ben Guerir',
    'Ben Ahmed',
    'Berrechid',
    'Azrou',
    'Azilal',
    'Azamor',
    'Asilah',
    'Ar Rommani',
    'Aourir',
    'Khemisset',
    'Al Hoceima',
    'Ait Ourir',
    'Ayt Mloul',
    'Ait Abdallah',
    'Ain Taoujdat',
    'Ain Harrouda',
    'Ahfir',
    'Agadir',
    'Afourer',
    'Skhirate',
    'Blougra',
    'Errachidia',
    'Tnine Ourika',
    'Imintanout',
    'Dakhla',
    'Salam',
    'Akhfennir',
    'Gueltat Zemmour',
    'Ahlaf',
    'Daroua',
    'Moulay Bouazza',
    'Imlili',
  ],
  Malta: [
    'Marsaxlokk',
    'Zurrieq',
    'Zejtun',
    'Haz-Zebbug',
    'Zabbar',
    'Xghajra',
    'Xewkija',
    'Xaghra',
    'Valletta',
    "Ta' Xbiex",
    'Tarxien',
    'Sliema',
    'Siggiewi',
    'Saint Venera',
    'Santa Lucija',
    'San Pawl il-Bahar',
    'Sannat',
    'Saint Lawrence',
    "St. Julian's",
    'Safi',
    'Paola',
    'Victoria',
    'Rabat',
    'Qrendi',
    'Qormi',
    'Qawra',
    'Qala',
    'Pietà',
    'Nigred',
    'Naxxar',
    'Nadur',
    'Munxar',
    'Imtarfa',
    'Imsida',
    'Mqabba',
    'Mosta',
    'Mgarr',
    'Mellieha',
    'Mdina',
    'Marsaskala',
    'Marsalforn',
    'Marsa',
    'Manikata',
    'Il-Madliena',
    'Luqa',
    'Senglea',
    'L-Iskorvit',
    'L-Iklin',
    'Lija',
    'Kirkop',
    'Kercem',
    'Kalkara',
    'Swieqi',
    'Gzira',
    'Floriana',
    'Birgu',
    'Hamrun',
    'Gwardamanga',
    'Gudja',
    'Ghaxaq',
    'Ghasri',
    'Hal Gharghur',
    'Gharb',
    'Ghajnsielem',
    'Dingli',
    'Bugibba',
    'Cospicua',
    'Birżebbuġa',
    'Birkirkara',
    'Bingemma',
    'Balzan',
    'Bahar ic-Caghaq',
    'Attard',
    "Ta' Giorni",
    'Saint John',
    'Fontana',
    'Pembroke',
    'Manresa',
    'Xemxija',
  ],
  Austria: [
    'Lengau',
    'Maierhof',
    'Kleinarl',
    'Pichl',
    'Millstatt',
    'Zwettl Stadt',
    'Zwettl an der Rodl',
    'Zwentendorf',
    'Zurndorf',
    'Zoebing',
    'Zistersdorf',
    'Zirl',
    'Zillingtal',
    'Zillingdorf',
    'Ziersdorf',
    'Zettling',
    'Zerlach',
    'Zemendorf',
    'Zeltweg',
    'Zellerndorf',
    'Zell an der Pram',
    'Zell am Ziller',
    'Zell am See',
    'Zell am Pettenfirst',
    'Zell am Moos',
    'Zeiselmauer',
    'Zeillern',
    'Zams',
    'Zagersdorf',
    'Ybbsitz',
    'Ybbs an der Donau',
    'Wuernitz',
    'Wuerflach',
    'Wundschuh',
    'Wullersdorf',
    'Wulkaprodersdorf',
    'Woerterberg',
    'Woerschach',
    'Wörgl',
    'Woerdern',
    'Woellersdorf',
    'Woell',
    'Wolkersdorf im Weinviertel',
    'Wolfurt',
    'Wolfsgraben',
    'Wolfsegg am Hausruck',
    'Wolfsberg',
    'Wolfsbach',
    'Wolfpassing',
    'Woelfnitz',
    'Wolfau',
    'Wipfing',
    'Winzendorf',
    'Winklarn',
    'Windischgarsten',
    'Windigsteig',
    'Windhaag bei Perg',
    'Windhaag bei Freistadt',
    'Wimpassing an der Pielach',
    'Wimpassing an der Leitha',
    'Willersdorf',
    'Willendorf am Steinfelde',
    'Wilhering',
    'Wilhelmsburg',
    'Wildungsmauer',
    'Wildon',
    'Wildenau',
    'Wieting',
    'Wiesmath',
    'Wiesing',
    'Wiesfleck',
    'Wieselburg',
    'Wies',
    'Wienersdorf',
    'Wiener Neustadt',
    'Wiener Neudorf',
    'Vienna',
    'Weyregg',
    'Weyer',
    'Wetzelsdorf',
    'Wettmannstaetten',
    'Westendorf',
    'Wesenufer',
    'Wernstein am Inn',
    'Werndorf',
    'Wernberg',
    'Werfen',
    'Wenns',
    'Weng im Innkreis',
    'Wendling',
    'Wels',
    'Weiz',
    'Weitra',
    'Weitersfelden',
    'Weitensfeld',
    'Weiten',
    'Weisskirchen in Steiermark',
    'Weisskirchen an der Traun',
    'Weissenbach an der Triesting',
    'Weissenbach am Lech',
    'Weissenbach',
    'Weissbriach',
    'Weinzierl am Riederberg',
    'Weingraben',
    'Weinburg',
    'Weiler',
    'Weikersdorf am Steinfelde',
    'Weikendorf',
    'Weigelsdorf',
    'Weiden am See',
    'Weibern',
    'Weer',
    'Waxenberg',
    'Wattens',
    'Warth',
    'Wartberg ob der Aist',
    'Wartberg an der Krems',
    'Wartberg',
    'Wankham',
    'Wampersdorf',
    'Walterskirchen',
    'Wals',
    'Wallsee',
    'Wallern im Burgenland',
    'Wallern an der Trattnach',
    'Waldzell',
    'Waldneukirchen',
    'Waldkirchen am Wesen',
    'Walding',
    'Wald im Pinzgau',
    'Waldburg',
    'Wald',
    'Wald am Schoberpass',
    'Walchsee',
    'Waizenkirchen',
    'Waidhofen an der Ybbs',
    'Waidhofen an der Thaya',
    'Waidendorf',
    'Wagrain',
    'Wagna',
    'Murau',
    'Vorderstoder',
    'Vordernberg',
    'Vorderhornbach',
    'Vorchdorf',
    'Vorau',
    'Vomp',
    'Voels',
    'Volders',
    'Voitsberg',
    'Voecklamarkt',
    'Voecklabruck',
    'Vitis',
    'Virgen',
    'Vils',
    'Villach',
    'Vill',
    'Vigaun',
    'Viehdorf',
    'Vichtenstein',
    'Vestenpoppen',
    'Vellach',
    'Velden am Wörther See',
    'Veitsch',
    'Utzenaich',
    'Uttendorf',
    'Unzmarkt',
    'Unterzoegersdorf',
    'Unterweitersdorf',
    'Unterweissenbach',
    'Unterwaltersdorf',
    'Untertauern',
    'Unterstinkenbrunn',
    'Untersiebenbrunn',
    'Unterschuetzen',
    'Rohrbach an der Goelsen',
    'Unterrohr',
    'Unterretzbach',
    'Unterrabnitz',
    'Unterpullendorf',
    'Unterpremstaetten',
    'Unterpetersdorf',
    'Unternalb',
    'Unterloisdorf',
    'Untergaimberg',
    'Unterfrauenhaid',
    'Unterbergen',
    'Unterauersbach',
    'Unken',
    'Ungenach',
    'Umhausen',
    'Ulrichskirchen',
    'Ulrichsberg',
    'Uderns',
    'UEbersbach',
    'Tumeltsham',
    'Tulln',
    'Tulfes',
    'Tschagguns',
    'Trumau',
    'Trofaiach',
    'Tristach',
    'Trins',
    'Trieben',
    'Tribuswinkel',
    'Treibach',
    'Trautmannsdorf an der Leitha',
    'Trausdorf an der Wulka',
    'Bad Traunstein',
    'Traunkirchen',
    'Traun',
    'Trasdorf',
    'Traismauer',
    'Traiskirchen',
    'Traisen',
    'Traboch',
    'Totzenbach',
    'Torren',
    'Tobaj',
    'Tirol',
    'Timelkam',
    'Tiffnerwinkl',
    'Thueringen',
    'Thoerl',
    'Thondorf',
    'Theresienfeld',
    'Thening',
    'Thaya',
    'Thaur',
    'Thalheim bei Wels',
    'Thalheim',
    'Thalgau',
    'Texing',
    'Ternitz',
    'Ternberg',
    'Terfens',
    'Telfs',
    'Telfes im Stubai',
    'Teesdorf',
    'Taxenbach',
    'Tauplitz',
    'Taufkirchen an der Trattnach',
    'Taufkirchen an der Pram',
    'Tattendorf',
    'Tarsdorf',
    'Tarrenz',
    'Tannheim',
    'Tamsweg',
    'Taiskirchen im Innkreis',
    'Tainach',
    'Tadten',
    'Sulz im Wienerwald',
    'Sulzberg',
    'Sulzau',
    'Sulz',
    'Suben',
    'Stuhlfelden',
    'Studenzen',
    'Stubenberg',
    'Stronsdorf',
    'Stroheim',
    'Strobl',
    'Strengen',
    'Streitdorf',
    'Strau',
    'Stratzing',
    'Strasswalchen',
    'Strass in Steiermark',
    'Strass im Attergau',
    'Strasshof an der Nordbahn',
    'Strassengel',
    'Strassburg-Stadt',
    'Strass im Zillertal',
    'Stotzing',
    'Stoessing',
    'Stoob',
    'Stollhof',
    'Stocking',
    'Stockerau',
    'Stiwoll',
    'Stinatz',
    'Stillfried',
    'Stiefern',
    'Steyrling',
    'Steyregg',
    'Steyr',
    'Stetten',
    'Stetteldorf am Wagram',
    'Steinhaus',
    'Steinfeld',
    'Steinerkirchen an der Traun',
    'Steinberg an der Rabnitz',
    'Steinbach an der Steyr',
    'Steinbach am Attersee',
    'Stein an der Enns',
    'Steinakirchen am Forst',
    'Steinach am Brenner',
    'Steinebrunn',
    'Steinabrueckl',
    'Stein',
    'Stegersbach',
    'Steeg',
    'Stattersdorf',
    'Stattegg',
    'Stanz bei Landeck',
    'Stanzach',
    'Stans',
    'Stams',
    'Stallhofen',
    'Stallehr',
    'Stainz bei Straden',
    'Stainz',
    'Stainach',
    'Stadtschlaining',
    'Stadl-Paura',
    'Rohrbach',
    'Spitz',
    'Spittal an der Drau',
    'Spital am Semmering',
    'Spital am Pyhrn',
    'Spillern',
    'Spielberg bei Knittelfeld',
    'Sonnberg',
    'Sommerein',
    'Sollenau',
    'Solden',
    'Hall in Tirol',
    'Soedingberg',
    'Soechau',
    'Soebriach',
    'Sitzenberg',
    'Sittersdorf',
    'Sittendorf',
    'Sipbachzell',
    'Silz',
    'Sillian',
    'Sigless',
    'Sigharting',
    'Siezenheim',
    'Sierning',
    'Sierndorf an der March',
    'Sierndorf',
    'Sieggraben',
    'Siegenfeld',
    'Siegendorf im Burgenland',
    'Sibratsgfaell',
    'Seyring',
    'Serfaus',
    'Senftenbach',
    'Semriach',
    'Semmering',
    'Selzthal',
    'Seitenstetten Markt',
    'Seiersberg',
    'Seewiesen',
    'Seewalchen',
    'Seekirchen am Wallersee',
    'Seeham',
    'Seefeld in Tirol',
    'Seeboden',
    'Seebenstein',
    'Seckau',
    'Sebersdorf',
    'Schwoich',
    'Schwertberg',
    'Schwendt',
    'Schwendau',
    'Schweiggers',
    'Schwechat',
    'Schwaz',
    'Schwarzenbach',
    'Schwarzenau',
    'Schwarzach im Pongau',
    'Schwarzach',
    'Schwanenstadt',
    'Schwanberg',
    'Schwadorf',
    'Schuetzen am Gebirge',
    'Schruns',
    'Schrems',
    'Schrattenthal',
    'Schrattenberg',
    'Schoerfling',
    'Schoppernau',
    'Schoenkirchen',
    'Schoengrabern',
    'Schoendorf',
    'Schoenbuehel an der Donau',
    'Schoenberg im Stubaital',
    'Schoenberg',
    'Schoenau im Muehlkreis',
    'Schnifis',
    'Schneegattern',
    'Schneeberg',
    'Schluesslberg',
    'Schlins',
    'Schlierbach',
    'Schleissheim',
    'Schleinbach',
    'Schleedorf',
    'Schlaegl',
    'Schladming',
    'Schildorn',
    'Schiefling am See',
    'Schiedlberg',
    'Schenkenfelden',
    'Scheiblingkirchen',
    'Scheibbs',
    'Scheffau am Wilden Kaiser',
    'Schattendorf',
    'Scharten',
    'Scharnstein',
    'Schaerding',
    'Schardenberg',
    'Schalchen',
    'Saxen',
    'Sautens',
    'Saeusenstein',
    'Sausal',
    'Bad Sauerbrunn',
    'Saubersdorf',
    'Sattledt',
    'Satteins',
    'Sarleinsbach',
    'Sankt Wolfgang im Salzkammergut',
    'Sankt Wolfgang',
    'Sankt Veit im Pongau',
    'Sankt Veit an der Glan',
    'Sankt Valentin-Landschach',
    'Sankt Valentin',
    'Sankt Urban',
    'Sankt Ulrich bei Steyr',
    'Sankt Stefan ob Stainz',
    'Sankt Stefan im Rosental',
    'Sankt Roman',
    'Sankt Radegund bei Graz',
    'Sankt Pölten',
    'Sankt Peter ob Judenburg',
    'Sankt Peter in der Au Markt',
    'Sankt Peter am Wimberg',
    'Sankt Peter am Hart',
    'Sankt Paul an der Gail',
    'Sankt Pantaleon',
    'Sankt Pankraz',
    'Sankt Oswald bei Freistadt',
    'Sankt Michael in Obersteiermark',
    'St Michael im Lungau',
    'Sankt Michael im Burgenland',
    'Sankt Martin im Muehlkreis',
    'Sankt Martin im Innkreis',
    'Sankt Martin bei Lofer',
    'Sankt Martin am Techelsberg',
    'Sankt Margareten im Rosental',
    'Sankt Margarethen im Burgenland',
    'Sankt Margarethen an der Raab',
    'Sankt Marein im Muerztal',
    'Sankt Lorenzen im Muerztal',
    'Sankt Lorenzen bei Knittelfeld',
    'Sankt Lorenzen am Wechsel',
    'Sankt Leonhard bei Freistadt',
    'Sankt Leonhard am Forst',
    'Sankt Konrad',
    'Sankt Kathrein am Offenegg',
    'Sankt Katharein an der Laming',
    'Sankt Kanzian',
    'Sankt Josef (Weststeiermark)',
    'Sankt Johann in Tirol',
    'Sankt Johann in der Haide',
    'St Johann im Pongau',
    'Sankt Johann bei Herberstein',
    'Sankt Jakob in Haus',
    'St. Gilgen',
    'Sankt Gertraud',
    'Sankt Georgen im Attergau',
    'Sankt Georgen bei Salzburg',
    'Sankt Georgen an der Leys',
    'Sankt Georgen an der Gusen',
    'Sankt Georgen am Ybbsfelde',
    'Sankt Gallen',
    'Sankt Florian am Inn',
    'Sankt Florian',
    'Sankt Daniel',
    'Saint Anton',
    'Sankt Aegyd am Neuwalde',
    'Sandl',
    'Sammersdorf',
    'Salzburg',
    'Sachsenburg',
    'Saalfelden am Steinernen Meer',
    'Saalbach',
    'Rutzenmoos',
    'Ruestorf',
    'Russbach am Pass Gschutt',
    'Ruprechtshofen',
    'Rum',
    'Rudersdorf',
    'Ruden',
    'Rueckersdorf',
    'Rottenmann',
    'Rothenthurn',
    'Rotheau',
    'Rotenturm an der Pinka',
    'Rossatz',
    'Rosenbach',
    'Rosenau am Sonntagberg',
    'Rosegg',
    'Roeschitz',
    'Roppen',
    'Roitham',
    'Rohr im Gebirge',
    'Rohrbrunn',
    'Rohrbach bei Mattersburg',
    'Rohrbach an der Teich',
    'Rohrbach an der Lafnitz',
    'Rodingersdorf',
    'Ritzing',
    'Rinn',
    'Rifenal',
    'Riezlern',
    'Rietz',
    'Riegersburg',
    'Riedlingsdorf',
    'Ried in der Riedmark',
    'Ried im Oberinntal',
    'Ried im Innkreis',
    'Riedau',
    'Reutte',
    'Reuthe',
    'Retz',
    'Rettenschoess',
    'Rekawinkel',
    'Reith bei Seefeld',
    'Reith',
    'Reisenberg',
    'Reinsberg',
    'Rein',
    'Reifnitz',
    'Reidling',
    'Reichraming',
    'Reichenthal',
    'Reichenfels',
    'Reichenau an der Rax',
    'Regelsbrunn',
    'Redlham',
    'Redleiten',
    'Rechnitz',
    'Raxendorf',
    'Ravelsbach',
    'Rauris',
    'Rauchwart im Burgenland',
    'Rauchenwarth',
    'Ratten',
    'Raschala',
    'Rappottenstein',
    'Ranten',
    'Rannersdorf',
    'Rankweil',
    'Rangersdorf',
    'Ramsau am Dachstein',
    'Ramsau im Zillertal',
    'Ramplach',
    'Rainbach im Muehlkreis',
    'Rainbach im Innkreis',
    'Rainbach',
    'Ragnitz',
    'Raggendorf',
    'Raggal',
    'Radstadt',
    'Bad Radkersburg',
    'Radfeld',
    'Radenthein',
    'Rabenstein an der Pielach',
    'Raasdorf',
    'Raaba',
    'Raab',
    'Pyhra',
    'Putzing',
    'Purkersdorf',
    'Purgstall',
    'Purbach am Neusiedler See',
    'Pulst',
    'Pulkau',
    'Pucking',
    'Puchenau',
    'Puchberg am Schneeberg',
    'Puch bei Weiz',
    'Puch bei Hallein',
    'Prutz',
    'Pruggern',
    'Prinzersdorf',
    'Prigglitz',
    'Hofamt Priel',
    'Pressbaum',
    'Prellenkirchen',
    'Prein an der Rax',
    'Pregarten',
    'Predlitz',
    'Preding',
    'Prambachkirchen',
    'Pram',
    'Poysdorf',
    'Potzneusiedl',
    'Pottenstein',
    'Pottendorf',
    'Pottenbrunn',
    'Poertschach am Woerthersee',
    'Poendorf',
    'Poelten',
    'Poels',
    'Polsenz',
    'Pollau',
    'Poellau',
    'Poelfing',
    'Poggersdorf',
    'Podersdorf am See',
    'Poechlarn',
    'Plank am Kamp',
    'Plainfeld',
    'Pixendorf',
    'Pischelsdorf in der Steiermark',
    'Pischelsdorf',
    'Pischeldorf',
    'Pirka',
    'Piringsdorf',
    'Pinsdorf',
    'Pinkafeld',
    'Pinggau',
    'Pillichsdorf',
    'Piesendorf',
    'Pierbach',
    'Pichling bei Stainz',
    'Pichl bei Wels',
    'Piberbach',
    'Pfunds',
    'Pfons',
    'Pflach',
    'Pfarrkirchen bei Bad Hall',
    'Pfaffing',
    'Peuerbach',
    'Petzenkirchen',
    'Pettneu',
    'Pettenbach',
    'Peterskirchen',
    'Pertisau',
    'Persenbeug',
    'Perschling',
    'Pernitz',
    'Pernegg an der Mur',
    'Pernegg',
    'Perg',
    'Perchtoldsdorf',
    'Penz',
    'Pennewang',
    'Pellendorf',
    'Peisching',
    'Peilstein im Muehlviertel',
    'Peggau',
    'Payerbach',
    'Paudorf',
    'Pattigham',
    'Patsch',
    'Paternion',
    'Passail',
    'Pasching',
    'Parndorf',
    'Pamhagen',
    'Pama',
    'Palterndorf',
    'Palt',
    'Pachfurth',
    'Oeynhausen',
    'Oetz',
    'Ottensheim',
    'Ottenschlag',
    'Ostermiething',
    'Oslip',
    'Ort im Innkreis',
    'Orth an der Donau',
    'Ollersdorf',
    'Olbendorf',
    'Ohlsdorf',
    'Oggau',
    'Oftering',
    'Offenhausen',
    'Oepping',
    'Oehling',
    'Oed',
    'Obritzberg',
    'Oberzoegersdorf',
    'Oberwoelz Stadt',
    'Oberwoelbling',
    'Oberweis',
    'Oberwart',
    'Oberwang',
    'Oberwaltersdorf',
    'Obervogau',
    'Obertrum am See',
    'Obertraun',
    'Obertilliach',
    'Obertauern',
    'Obersiebenbrunn',
    'Obersdorf',
    'Oberrabnitz',
    'Oberpullendorf',
    'Oberpinswang',
    'Oberpetersdorf',
    'Oberort',
    'Oberolberndorf',
    'Oberneukirchen',
    'Oberneudorf',
    'Oberndorf in Tirol',
    'Oberndorf bei Schwanenstadt',
    'Oberndorf bei Salzburg',
    'Oberndorf an der Melk',
    'Oberndorf',
    'Obernberg am Inn',
    'Obernalb',
    'Oberloisdorf',
    'Oberkappel',
    'Oberhofen im Inntal',
    'Oberhofen am Irrsee',
    'Oberhautzenthal',
    'Oberhausen',
    'Obergurgl',
    'Ober-Grafendorf',
    'Oberdrauburg',
    'Oberdorf im Burgenland',
    'Oberau',
    'Oberalm',
    'Obdach',
    'Nussbach',
    'Noetsch bei Bleiberg',
    'Nonndorf bei Gars',
    'Nikolsdorf',
    'Niklasdorf',
    'Nikitsch',
    'Niederwaldkirchen',
    'Niedernsill',
    'Niederneukirchen',
    'Niederndorf',
    'Niederkreuzstetten',
    'Niederfladnitz',
    'Niederbreitenbach',
    'Niederau',
    'Niederalm',
    'Niederabsdorf',
    'Nickelsdorf',
    'Neuzeug',
    'Neuwald',
    'Neutillmitsch',
    'Neutal',
    'Neustift',
    'Neustift an der Lafnitz',
    'Neustift am Alpenwalde',
    'Neustadtl-Markt',
    'Neusiedl am See',
    'Neusach',
    'Neurisshof',
    'Neupoella',
    'Neunkirchen',
    'Neumarkt in Steiermark',
    'Neumarkt im Muehlkreis',
    'Neumarkt im Hausruckkreis',
    'Neumarkt an der Raab',
    'Neumarkt am Wallersee',
    'Neulengbach',
    'Neukirchen bei Lambach',
    'Neukirchen an der Voeckla',
    'Neukirchen an der Enknach',
    'Neukirchen am Walde',
    'Neukirchen am Grossvenediger',
    'Neukirchen',
    'Neuhofen im Innkreis',
    'Neuhofen an der Ybbs',
    'Neuhofen an der Krems',
    'Neufelden',
    'Neufeld an der Leitha',
    'Neudorf bei Landsee',
    'Neudorf bei Staatz',
    'Neudau',
    'Neuberg',
    'Nenzing',
    'Neidling',
    'Nauders',
    'Natters',
    'Natternbach',
    'Nassereith',
    'Naas',
    'Naarn im Machlande',
    'Muthmannsdorf',
    'Musau',
    'Muerzzuschlag',
    'Muerzsteg',
    'Muerzhofen',
    'Murstetten',
    'Mureck',
    'Muenzkirchen',
    'Muenzbach',
    'Muenichreith am Ostrong',
    'Munderfing',
    'Muenchendorf',
    'Muehldorf',
    'Muehlbach am Hochkoenig',
    'Muehlbach',
    'Muckendorf an der Donau',
    'Moetz',
    'Moerstalling',
    'Moerbisch am See',
    'Mooskirchen',
    'Moosdorf',
    'Moosbrunn',
    'Moosbierbaum',
    'Moenichwald',
    'Mondsee',
    'Molln',
    'Mollersdorf',
    'Moellersdorf',
    'Moellbruecke',
    'Mittertrixen',
    'Mittersill',
    'Mitterndorf an der Fischa',
    'Mitterkirchen im Machland',
    'Mittergrabern',
    'Mitterdorf im Muerztal',
    'Mitterarnsdorf',
    'Mistelbach',
    'Mischendorf',
    'Mining',
    'Minihof Liebau',
    'Mils bei Solbad Hall',
    'Mieming',
    'Mieders',
    'Michelhausen',
    'Micheldorf in Oberoesterreich',
    'Micheldorf',
    'Michaelnbach',
    'Mettmach',
    'Melk',
    'Meiningen',
    'Mehrnbach',
    'Meggenhofen',
    'Medraz',
    'Mayrhofen',
    'Mauthausen',
    'Mauterndorf',
    'Mautern',
    'Mauerkirchen',
    'Mauer bei Melk',
    'Mauerbach',
    'Matzersdorf',
    'Matzendorf',
    'Matzen',
    'Mattsee',
    'Mattighofen',
    'Mattersburg',
    'Matrei in Osttirol',
    'Matrei am Brenner',
    'Mathon',
    'Martinsberg',
    'Bad Pirawarth',
    'Markersdorf an der Pielach',
    'Mariazell',
    'Maria Taferl',
    'Mariastein',
    'Mariasdorf',
    'Maria Schmolln',
    'Maria Saal',
    'Maria Rain',
    'Mariapfarr',
    'Maria Neustift',
    'Maria Lanzendorf',
    'Maria Lankowitz',
    'Mariahof',
    'Maria Enzersdorf',
    'Maria Ellend',
    'Maria Elend',
    'Maria-Anzbach',
    'Margarethen am Moos',
    'Marchtring',
    'Marchtrenk',
    'Marchegg',
    'Mannsdorf an der Donau',
    'Manning',
    'Mannersdorf an der March',
    'Mannersdorf am Leithagebirge',
    'Mank',
    'Mallnitz',
    'Maissau',
    'Maishofen',
    'Mainburg',
    'Mailberg',
    'Maeder',
    'Lustenau',
    'Lungotz',
    'Luftenberg an der Donau',
    'Ludesch',
    'Ludersdorf',
    'Losenstein',
    'Loruens',
    'Loosdorf',
    'Loipersdorf im Burgenland',
    'Loipersbach im Burgenland',
    'Loipersbach',
    'Loimersdorf',
    'Loidesthal',
    'Lohnsburg',
    'Lofer',
    'Lochen',
    'Lochau',
    'Litzlberg',
    'Litzelsdorf',
    'Litschau',
    'Linz',
    'Lindgraben',
    'Lind',
    'Limbach im Burgenland',
    'Lilienfeld',
    'Ligist',
    'Liezen',
    'Lienz',
    'Lieboch',
    'Lichtenwoerth',
    'Lichtenegg',
    'Lichtenberg',
    'Lichtenau',
    'Leutschach',
    'Lermoos',
    'Leopoldsdorf im Marchfelde',
    'Leopoldsdorf',
    'Leonstein',
    'Leonding',
    'Leogang',
    'Leobersdorf',
    'Leobendorf',
    'Leoben',
    'Lenzing',
    'Lengenfeld',
    'Lendorf',
    'Lend',
    'Lemsitz',
    'Lembach im Muehlkreis',
    'Leitzersdorf',
    'Leisach',
    'Leibsdorf',
    'Leibnitz, Styria',
    'Lech',
    'Lebring',
    'Laxenburg',
    'Lauterach',
    'Launsdorf',
    'Latschach',
    'Lassnitz',
    'Lassee',
    'Lasberg',
    'Lanzenkirchen',
    'Lans',
    'Lannach',
    'Langschlag',
    'Langmannersdorf',
    'Langenzersdorf',
    'Langenwang',
    'Langenstein',
    'Langenschoenbichl',
    'Langenrohr',
    'Langenlois',
    'Langen',
    'Landskron',
    'Landeck',
    'Lamprechtshausen',
    'Lambichl',
    'Lambach',
    'Laimbach am Ostrong',
    'Lafnitz',
    'Ladis',
    'Ladendorf',
    'Lackendorf',
    'Lackenbach',
    'Laakirchen',
    'Laab im Walde',
    'Laa an der Thaya',
    'Kuernberg',
    'Kundl',
    'Kumberg',
    'Kuehnsdorf',
    'Kuehnring',
    'Kufstein',
    'Kuchl',
    'Krumpendorf',
    'Krumegg',
    'Krumbach',
    'Krottendorf bei Ligist',
    'Krottendorf an der Lassnitz',
    'Kroessenbach',
    'Kronstorf',
    'Kronberg',
    'Kroisegg',
    'Kroatisch Minihof',
    'Kritzendorf',
    'Krieglach',
    'Krenglbach',
    'Kremsmuenster',
    'Kremsdorf',
    'Kremsbruecke',
    'Krems',
    'Kraubath an der Mur',
    'Kramsach',
    'Koettmannsdorf',
    'Kottingbrunn',
    'Koetschach',
    'Koestendorf',
    'Korneuburg',
    'Koppl',
    'Kopfing im Innkreis',
    'Koenigswiesen',
    'Koenigstetten',
    'Koenigsdorf',
    'Konigsbrunn',
    'Kolsassberg',
    'Kollerschlag',
    'Kogelsbach',
    'Koeflach',
    'Koblach',
    'Kobersdorf',
    'Kobenz',
    'Knittelfeld',
    'Klosterneuburg',
    'Kloech',
    'Klingenbach',
    'Kleinzell',
    'Kleinraming',
    'Kleinneusiedl',
    'Kleinkirchheim',
    'Kleinhoeflein',
    'Kleinhain',
    'Kleinhadersdorf',
    'Kleingaisfeld',
    'Kleedorf',
    'Klausen-Leopoldsdorf',
    'Klaus an der Pyhrnbahn',
    'Klaus',
    'Klagenfurt',
    'Klaffer am Hochficht',
    'Kitzladen',
    'Kittsee',
    'Kirchschlag bei Linz',
    'Kirchheim im Innkreis',
    'Kirchdorf in Tirol',
    'Kirchdorf an der Krems',
    'Kirchdorf am Inn',
    'Kirchberg in Tirol',
    'Kirchberggegend',
    'Kirchberg bei Mattighofen',
    'Kirchberg an der Wild',
    'Kirchberg an der Raab',
    'Kirchberg an der Pielach',
    'Kirchberg am Wechsel',
    'Kirchberg am Wagram',
    'Kirchberg',
    'Kirchbach in Steiermark',
    'Kindberg',
    'Kilb',
    'Kettlasbrunn',
    'Kematen in Tirol',
    'Kematen an der Krems',
    'Kematen an der Ybbs',
    'Kautzen',
    'Katzelsdorf',
    'Katsdorf',
    'Kasten bei Boeheimkirchen',
    'Karlstift',
    'Karlstetten',
    'Karlstein',
    'Kaprun',
    'Kapfenberg',
    'Kapellen',
    'Kamp',
    'Kammersberg',
    'Kammern im Liesingtal',
    'Kammern',
    'Kaltenleutgeben',
    'Kaltenbach',
    'Kalsdorf bei Graz',
    'Kallham',
    'Kaisersdorf',
    'Kaindorf an der Sulm',
    'Kaindorf',
    'Kainach bei Voitsberg',
    'Julbach',
    'Judenburg',
    'Judenau',
    'Jormannsdorf',
    'Jois',
    'Joching',
    'Jochberg',
    'Jettsdorf',
    'Jennersdorf',
    'Jenig',
    'Jenbach',
    'Japons',
    'Iselsberg',
    'Ischgl',
    'Irdning',
    'Inzing',
    'Inzersdorf im Kremstal',
    'Innsbruck',
    'Weerberg',
    'Innerschwand',
    'Innermanzing',
    'Innerbraz',
    'Imst',
    'Illmitz',
    'Huettendorf',
    'Huettenberg',
    'Huetten',
    'Huerm',
    'Huben',
    'Hoersching',
    'Hornstein',
    'Horn',
    'Horitschon',
    'Hoerersdorf',
    'Hoerbranz',
    'Hopfgarten im Brixental',
    'Hopfgarten in Defereggen',
    'Hoenigthal',
    'Hoenigsberg',
    'Hollersbach im Pinzgau',
    'Hollenthon',
    'Hollenstein an der Ybbs',
    'Hollabrunn',
    'Hoehnhart',
    'Hohenzell',
    'Hohenweiler',
    'Hohenthurn',
    'Hohenems',
    'Hohenau',
    'Hofstetten',
    'Hofstaetten bei Deutsch Goritz',
    'Hofstaetten an der Raab',
    'Hoeflein',
    'Hofkirchen im Traunkreis',
    'Hofkirchen im Muehlkreis',
    'Hofkirchen an der Trattnach',
    'Hof bei Salzburg',
    'Hoerfarth',
    'Hof am Leithaberge',
    'Hochwolkersdorf',
    'Hoechst',
    'Hochneukirchen',
    'Hochburg-Ach',
    'Hochburg',
    'Hobersdorf',
    'Hoebenbach',
    'Hitzendorf',
    'Hirtenberg',
    'Hirschbach',
    'Hirm',
    'Hipples',
    'Hippach',
    'Hinzenbach',
    'Hinterthal',
    'Hinterstoder',
    'Hintersee',
    'Hintersdorf',
    'Himmelberg',
    'Himberg',
    'Hieflau',
    'Heuberg',
    'Hetzmannsdorf',
    'Herzogsdorf',
    'Herzogenburg',
    'Herrnbaumgarten',
    'Hernstein',
    'Hermagor',
    'Herberstein',
    'Henndorf am Wallersee',
    'Hengsberg',
    'Hellmonsoedt',
    'Heiligenkreuz am Waasen',
    'Heiligenkreuz',
    'Heiligeneich',
    'Heiligenbrunn',
    'Heiligenblut am Großglockner',
    'Heiligenberg',
    'Heidenreichstein',
    'Hautzendorf',
    'Hausmening',
    'Hausmannstaetten',
    'Hausleiten',
    'Hauskirchen',
    'Hausbrunn',
    'Haus',
    'Hatting',
    'Haslach',
    'Haschendorf',
    'Hartmannsdorf',
    'Hartkirchen',
    'Harth',
    'Hartberg',
    'Hart bei Straden',
    'Hart',
    'Bad Haering',
    'Hargelsberg',
    'Hard',
    'Handenberg',
    'Hallwang',
    'Hallstatt',
    'Hallein',
    'Halbturn',
    'Halbenrain',
    'Haitzendorf',
    'Hainzenberg',
    'Hainfeld',
    'Hainersdorf',
    'Hainburg an der Donau',
    'Haiming',
    'Haigermoos',
    'Haidershofen',
    'Haid',
    'Haibach im Muehlkreis',
    'Haibach bei Schaerding',
    'Haegerau',
    'Hagenbrunn',
    'Hagenberg im Muehlkreis',
    'Hafnerbach',
    'Hadres',
    'Hadersdorf am Kamp',
    'Haag am Hausruck',
    'Haag',
    'Gutau',
    'Gusswerk',
    'Guessing',
    'Gurten',
    'Guntramsdorf',
    'Gunskirchen',
    'Guenselsdorf',
    'Gundersheim',
    'Gumpoldskirchen',
    'Gummern',
    'Grundlsee',
    'Grunddorf',
    'Grund',
    'Gruenbach',
    'Grub',
    'Grosswilfersdorf',
    'Grossweikersdorf',
    'Grosssteinbach',
    'Gross-Siegharts',
    'Grossschoenau',
    'Grossrust',
    'Grossraming',
    'Grosspetersdorf',
    'Grosspesendorf',
    'Grosspertholz',
    'Grossmeiseldorf',
    'Grosslobming',
    'Grosskrut',
    'Grossklein',
    'Heimschuh',
    'Grossgottfritz',
    'Grossgmain',
    'Gross-Gerungs',
    'Gross-Enzersdorf',
    'Grossebersdorf',
    'Grossau',
    'Grossarl',
    'Groedig',
    'Groebming',
    'Groeben',
    'Grimmenstein',
    'Grillenstein',
    'Grillenberg',
    'Griffen',
    'Grieskirchen',
    'Gries am Brenner',
    'Gries',
    'Gresten',
    'Greith',
    'Greinsfurth',
    'Grein',
    'Greifenburg',
    'Graz',
    'Gratwein',
    'Gratkorn',
    'Gramatneusiedl',
    'Gramastetten',
    'Gramais',
    'Grafenwoerth',
    'Grafenstein',
    'Grafenbach',
    'Goetzis',
    'Goetzendorf an der Leitha',
    'Gottsdorf',
    'Goettlesbrunn',
    'Goestling an der Ybbs',
    'Goesselsdorf',
    'Goesing',
    'Gosau',
    'Goepfritz an der Wild',
    'Kirchgoeming',
    'Gols',
    'Golling an der Salzach',
    'Golling',
    'Goldgeben',
    'Goldegg',
    'Going',
    'Goefis',
    'Goedersdorf',
    'Gnies',
    'Gnas',
    'Gmundnerberg',
    'Gmunden',
    'Gmuend',
    'Gloggnitz',
    'Gleisdorf',
    'Gleinstaetten',
    'Glaubendorf',
    'Glashuetten bei Langeck im Burgenland',
    'Glasenbach',
    'Ginzling',
    'Gilgenberg am Weilhart',
    'Getzersdorf',
    'Gersdorf an der Mur',
    'Geretsberg',
    'Gerersdorf bei Guessing',
    'Gerasdorf bei Wien',
    'Geras',
    'Geistthal',
    'Geinberg',
    'Gaweinstal',
    'Gastern',
    'Gasselsdorf',
    'Gaspoltshofen',
    'Gasen',
    'Gaschurn',
    'Gartenau',
    'Garsten',
    'Gars am Kamp',
    'Garrach',
    'Gansbach',
    'Gampern',
    'Gamlitz',
    'Gaming',
    'Galtuer',
    'Gallzein',
    'Gallspach',
    'Gallneukirchen',
    'Gaissau',
    'Gaflenz',
    'Gablitz',
    'Fussach',
    'Fuschl am See',
    'Furth an der Triesting',
    'Furth bei Goettweig',
    'Fuerstenfeld',
    'Fuernitz',
    'Fulpmes',
    'Fuegenberg',
    'Fuegen',
    'Fruehwaerts',
    'Frohnleiten',
    'Fritzens',
    'Friesach',
    'Friedberg',
    'Freundorf',
    'Fresing',
    'Freistadt',
    'Freinberg',
    'Freilassing',
    'Freienstein',
    'Freidorf an der Lassnitz',
    'Fraxern',
    'Frauenkirchen',
    'Franking',
    'Frankenmarkt',
    'Frankenfels',
    'Frankenburg',
    'Frankenau',
    'Fraham',
    'Foetschach',
    'Fornach',
    'Forchtenstein',
    'Fohnsdorf',
    'Floing',
    'Flinsdorf',
    'Fliess',
    'Flatz',
    'Flattach',
    'Fladnitz im Raabtal',
    'Fiss',
    'Fischlham',
    'Fischamend-Markt',
    'Fischamend Dorf',
    'Finkenberg',
    'Filzmoos',
    'Fieberbrunn',
    'Ferschnitz',
    'Fernitz',
    'Ferndorf',
    'Ferlach',
    'Fels am Wagram',
    'Felixdorf',
    'Feldkirchen in Kaernten',
    'Feldkirchen bei Mattighofen',
    'Feldkirchen bei Graz',
    'Feldkirchen an der Donau',
    'Feldkirch',
    'Feldbach',
    'Feistritz ob Bleiburg',
    'Feistritz bei Knittelfeld',
    'Feistritz an der Drau',
    'Fehring',
    'Falkenstein',
    'Falkendorf',
    'Faak am See',
    'Euratsfeld',
    'Eugendorf',
    'Etsdorf am Kamp',
    'Erpfendorf',
    'Erpersdorf',
    'Ernsthofen',
    'Ernstbrunn',
    'Ernsdorf bei Staatz',
    'Erlauf',
    'Bad Erlach',
    'Erla',
    'Erl',
    'Enzesfeld',
    'Enzersfeld',
    'Enzersdorf an der Fischa',
    'Enzenreith',
    'Enzenkirchen',
    'Ennsdorf',
    'Ennsbach',
    'Enns',
    'Engerwitzdorf',
    'Engersdorf',
    'Engelmannsbrunn',
    'Engelhartszell',
    'Engelhartstetten',
    'Emmersdorf an der Donau',
    'Elsbethen',
    'Ellmau',
    'Elixhausen',
    'Elbigenalp',
    'Eisgarn',
    'Eisenstadt',
    'Bad Eisenkappel',
    'Eisenerz',
    'Eisbach',
    'Eichgraben',
    'Eibiswald',
    'Eibesthal',
    'Ehrenhausen',
    'Eggersdorf bei Graz',
    'Eggenburg',
    'Eggelsberg',
    'Egg',
    'Egelsdorf',
    'Eferding',
    'Edt bei Lambach',
    'Edlitz',
    'Edelstal',
    'Edelschrott',
    'Edelsbach bei Feldbach',
    'Eckartsau',
    'Echsenbach',
    'Ebreichsdorf',
    'Eberstein',
    'Eberstalzell',
    'Eberschwang',
    'Ebersbrunn',
    'Eberndorf',
    'Ebergassing',
    'Ebenthal',
    'Ebensee',
    'Eben im Pongau',
    'Ebenfurth',
    'Ebenau',
    'Eben am Achensee',
    'Ebbs',
    'Duttendorf',
    'Duernstein',
    'Duel',
    'Drosendorf',
    'Drassmarkt',
    'Drassburg',
    'Drasenhofen',
    'Drasendorf',
    'Dornbirn',
    'Dorfgastein',
    'Fusch an der Glocknerstrasse',
    'Donnerskirchen',
    'Donawitz',
    'Doelsach',
    'Dobldorf',
    'Dobersdorf',
    'Dobersberg',
    'Diex',
    'Dietmanns',
    'Dietersdorf',
    'Dietach',
    'Deutsch-Wagram',
    'Deutschlandsberg',
    'Deutschkreutz',
    'Deutsch Kaltenbrunn',
    'Deutsch Jahrndorf',
    'Deutsch Goritz',
    'Deutschfeistritz',
    'Desselbrunn',
    'Dellach im Drautal',
    'Dellach',
    'Dechantskirchen',
    'Dalaas',
    'Buermoos',
    'Burgkirchen',
    'Buerg',
    'Burg',
    'Buchschachen',
    'Buchkirchen',
    'Brunnenthal',
    'Brunn an der Wild',
    'Brunn am Gebirge',
    'Bruggen',
    'Bruckneudorf',
    'Bruck an der Mur',
    'Bruck an der Leitha',
    'Bruck an der Grossglocknerstrasse',
    'Bruck am Ziller',
    'Bromberg',
    'Brixlegg',
    'Brixen im Thale',
    'Breitstetten',
    'Breitenwang',
    'Breitenstein am Semmering',
    'Breitenfurt bei Wien',
    'Breitenbrunn',
    'Breitenbach am Inn',
    'Breitenau',
    'Bregenz',
    'Braunau am Inn',
    'Brandenberg',
    'Brand',
    'Bramberg am Wildkogel',
    'Boeheimkirchen',
    'Bodensdorf',
    'Bockstein',
    'Bocksdorf',
    'Bockfliess',
    'Bad Blumau',
    'Blumau',
    'Bludesch',
    'Bludenz',
    'Blons',
    'Blindenmarkt',
    'Bleiburg',
    'Bischofstetten',
    'Bischofshofen',
    'Bisamberg',
    'Birkfeld',
    'Birgitz',
    'Bings',
    'Biedermannsdorf',
    'Biberbach',
    'Bezau',
    'Bernhardsthal',
    'Berndorf bei Salzburg',
    'Berndorf',
    'Bergheim',
    'Berg bei Rohrbach',
    'Behamberg',
    'Baumgartenberg',
    'Baumgarten an der March',
    'Bartholomaeberg',
    'Baldramsdorf',
    'Wimsbach',
    'Bad Tatzmannsdorf',
    'Bad Schallerbach',
    'Bad Sankt Leonhard im Lavanttal',
    'Bad Ischl',
    'Bad Hofgastein',
    'Bad Hall',
    'Bad Goisern am Hallstättersee',
    'Bad Gleichenberg',
    'Bad Gastein',
    'Bad Fischau',
    'Baden bei Wien',
    'Bad Aussee',
    'Bachmanning',
    'Axams',
    'Ausserbraz',
    'Aurolzmuenster',
    'Aurach am Hongar',
    'Auffach',
    'Auersthal',
    'Auersbach',
    'Au',
    'Atzelsdorf',
    'Atzbach',
    'Attersee',
    'Asten',
    'Asperhofen',
    'Aspang Markt',
    'Aschbach Markt',
    'Aschach an der Steyr',
    'Aschach an der Donau',
    'Arzl im Pitztal',
    'Arnoldstein',
    'Arnfels',
    'Ardning',
    'Ardagger Stift',
    'Arbing',
    'Arbesbach',
    'Apetlon',
    'Anthering',
    'Antau',
    'Ansfelden',
    'Anras',
    'Annaberg im Lammertal',
    'Annaberg',
    'Anif',
    'Angern an der March',
    'Anger',
    'Angath',
    'Andorf',
    'Andau',
    'Amstetten',
    'Ampflwang',
    'Taxerhof Lake',
    'Altnagelberg',
    'Altlengbach',
    'Althofen',
    'Altheim',
    'Altenmarkt im Pongau',
    'Altenmarkt an der Triesting',
    'Altenfelden',
    'Altenburg',
    'Altenberg bei Linz',
    'Altenberg',
    'Altaussee',
    'Altach',
    'Allhartsberg',
    'Allhaming',
    'Allerheiligen im Muerztal',
    'Allerheiligen bei Wildon',
    'Allentsteig',
    'Alland',
    'Alkoven',
    'Aldrans',
    'Albrechtsberg an der Pielach',
    'Alberndorf',
    'Aistersheim',
    'Aigen im Ennstal',
    'Aigen',
    'Ahorn',
    'Aggsbach',
    'Afritz',
    'Aflenz',
    'Adnet',
    'Admont',
    'Adlwang',
    'Achenkirch',
    'Ach',
    'Abwinden',
    'Abtenau',
    'Absam',
    'Semslach',
    'Finkenstein am Faaker See',
    'Attnang-Puchheim',
    'OEtztal-Bahnhof',
    'Leutasch',
    'Maurach',
    'Gschnitz',
    'Ochsengarten',
    'Oberperfuss',
    'Igls',
    'Obsteig',
    'Kolsass',
    'Gnadenwald',
    'Ehrwald',
    'Grossamberg',
    'Ganserndorf-Siedlung',
    'Lanzendorf',
    'Gunsering',
    'Messensee',
    'Nussdorf',
    'Kals am Grossglockner',
    'Laimach',
    'Gerlos',
    'Krimml',
    'Ried im Zillertal',
    'Hinterglemm',
    'Alpbach',
    'Esternberg',
    'Kleinedling',
    'Liebenfels',
    'Bach',
    'Wildschoenau',
    'Rohrmoos',
    'Langkampfen',
    'Hart bei Graz',
    'Berg im Drautal',
    'Lockenhaus',
    'Grosskirchheim',
    'Bad Zell',
    'Bad Leonfelden',
    'Pfarrwerfen',
    'Thal',
    'Schroecken',
    'Rossleithen',
    'Eitzing',
    'Aigen im Muehlkreis',
    'Soeding',
    'Ottnang am Hausruck',
    'Prottes',
    'Fontanella',
    'Alberschwende',
    'Mellau',
    'Frastanz',
    'Kirchbichl',
    'Kolbnitz',
    'Laengenfeld',
    'Haus im Ennstal',
    'Vandans',
    'Wildenduernbach',
    'Ertl',
    'Hittisau',
    'Neudauberg',
    'Schoenkirchen-Reyersdorf',
    'Eggendorf',
    'Aschau im Zillertal',
    'Stockach',
    'Thiersee',
    'Volderwald',
    'Grinzens',
    'Mitterlobming',
    'Stadlhof',
    'Muehlwang',
    'Tuffeltsham',
    'Am Landlberg',
    'Weifersdorf',
    'Puchlkirchen am Trattberg',
    'Linzerberg',
    'Bruck',
    'Langstrass',
    'Wolfern',
    'Regau',
    'Hauxmoos',
    'Enzendorf',
    'Neufurth',
    'Lerchenfeld',
    'Greising',
    'Hunnenbrunn',
    'Langenlebarn-Oberaigen',
    'Riederberg',
    'Firsching',
    'Tobelbad',
    'Traberg',
    'Braunau Neustadt',
    'Klessheim',
    'Vasoldsberg',
    'Absdorf',
    'Rohrendorf bei Krems',
    'Tux',
    'Bergland',
    'Lingenau',
    'Angerberg',
    'Vogau',
    'Thueringerberg',
    'Kaunertal',
    'Weisspriach',
    'Brunn bei Pitten',
  ],
  Denmark: [
    'Vra',
    'Vordingborg',
    'Vorbasse',
    'Vonge',
    'Vojens',
    'Vodskov',
    'Viuf',
    'Vissenbjerg',
    'Virum',
    'Vipperod',
    'Vinderup',
    'Vils',
    'Vildbjerg',
    'Vig',
    'Videbæk',
    'Viby',
    'Viborg',
    'Vaeggerlose',
    'Vestervig',
    'Vester-Skerninge',
    'Vestero Havn',
    'Vester Hassing',
    'Vestbjerg',
    'Veslos',
    'Vemmelev',
    'Vemb',
    'Vekso',
    'Vejstrup',
    'Vejle',
    'Vejen Municipality',
    'Vejby',
    'Veflinge',
    'Veddum',
    'Vedbaek',
    'Varde',
    'Vanlose',
    'Vandel',
    'Vamdrup',
    'Vallensbæk',
    'Valby',
    'Vadum',
    'Utterslev',
    'Ulstrup',
    'Ulsted',
    'Ullerslev',
    'Ulfborg',
    'Uldum',
    'Ugerlose',
    'Ugelbolle',
    'Ubby',
    'Tylstrup',
    'Tureby',
    'Tune',
    'Tullebolle',
    'Trustrup',
    'Trorod',
    'Trige',
    'Tranekaer',
    'Tranbjerg',
    'Torrig',
    'Toreby',
    'Torring',
    'Tønder',
    'Tollose',
    'Tommerup',
    'Toftlund',
    'Tjaereborg',
    'Tjele',
    'Tisvildeleje',
    'Tistrup',
    'Tiset',
    'Tinglev',
    'Tim',
    'Tilst',
    'Tikob',
    'Thyboron',
    'Thorso',
    'Thisted',
    'Them',
    'Terndrup',
    'Taastrup',
    'Tars',
    'Tårnby',
    'Tarm',
    'Tappernoje',
    'Svinninge',
    'Svenstrup',
    'Svendborg',
    'Svaneke',
    'Sunds',
    'Sulsted',
    'Suldrup',
    'Stubbekøbing',
    'Struer Municipality',
    'Stroby Egede',
    'Stroby',
    'Strandby',
    'Stouby',
    'Storvorde',
    'Store Merlose',
    'Store Heddinge',
    'Store Fuglede',
    'Stovring',
    'Stokkemarke',
    'Stoholm',
    'Stensved',
    'Stenstrup',
    'Stenlose',
    'Stenlille',
    'Stenderup',
    'Stege',
    'Stakroge',
    'Spottrup',
    'Spjald',
    'Spentrup',
    'Sorring',
    'Soro',
    'Sorvad',
    'Sonder Stenderup',
    'Sonderso',
    'Sonder Omme',
    'Sonder Felding',
    'Sønderborg',
    'Sonder Bjert',
    'Sollested',
    'Soby',
    'Soborg',
    'Sommersted',
    'Solrod',
    'Solbjerg',
    'Snoldelev',
    'Snertinge',
    'Snekkersten',
    'Snedsted',
    'Slangerup',
    'Slagelse',
    'Skævinge',
    'Skaerbaek',
    'Skovlunde',
    'Skørping',
    'Skodstrup',
    'Skodsborg',
    'Skodborg',
    'Skaelskor',
    'Skjern',
    'Skive',
    'Skibby',
    'Skarup',
    'Skanderborg',
    'Skals',
    'Skalbjerg',
    'Skagen',
    'Sjolund',
    'Sindal',
    'Silkeborg',
    'Sebbersund',
    'Saeby',
    'Sandved',
    'Sakskøbing',
    'Sabro',
    'Ryslinge',
    'Ryomgard',
    'Ry',
    'Rungsted',
    'Ruds-Vedby',
    'Rudkøbing',
    'Roslev',
    'Roskilde',
    'Rorvig',
    'Rønnede',
    'Rønne',
    'Ronde',
    'Rogen',
    'Rodvig',
    'Rødovre Municipality',
    'Rodkaersbro',
    'Rødekro',
    'Rodding',
    'Rodbyhavn',
    'Rødby',
    'Risskov',
    'Ringsted',
    'Ringkøbing',
    'Ringe',
    'Ribe',
    'Regstrup',
    'Refstrup',
    'Reerslev',
    'Redsted',
    'Ravsted',
    'Ravnshoj',
    'Rask Molle',
    'Ranum',
    'Randers',
    'Randbol',
    'Rakkeby',
    'Præstø',
    'Pandrup',
    'Padborg',
    'Outrup',
    'Overby',
    'Oure',
    'Otterup',
    'Ostofte',
    'Ostervra',
    'Oster Ulslev',
    'Ostermarie',
    'Osterild',
    'Oster Assels',
    'Ostbirk',
    'Orum',
    'Orsted',
    'Ornhoj',
    'Orbaek',
    'Olstykke',
    'Olsted',
    'Ølgod',
    'Olby',
    'Oksbol',
    'Odense',
    'Odder',
    'Nysted',
    'Nykobing Falster',
    'Nykobing Sjaelland',
    'Nykobing Mors',
    'Nyborg',
    'Naestved',
    'Naerum',
    'Nordby',
    'Nordborg',
    'Norresundby',
    'Norre Snede',
    'Norre Bindslev',
    'Norreballe',
    'Norre Asmindrup',
    'Nørre Alslev',
    'Norre Aby',
    'Norager',
    'Niva',
    'Nimtofte',
    'Nibe',
    'Nexo',
    'Nebel',
    'Nakskov',
    'Munkebo',
    'Munke Bjergby',
    'Moseby',
    'Morud',
    'Morkov',
    'Morke',
    'Monsted',
    'Moldrup',
    'Millinge',
    'Middelfart',
    'Mern',
    'Melby',
    'Martofte',
    'Marstal',
    'Marslev',
    'Marslet',
    'Maribo',
    'Mariager',
    'Manna',
    'Malov',
    'Malling',
    'Lystrup',
    'Lynge',
    'Lyngby',
    'Lunderskov',
    'Lundby Stationsby',
    'Losning',
    'Lokken',
    'Løgumkloster',
    'Logten',
    'Logstrup',
    'Løgstør',
    'Liseleje',
    'Lintrup',
    'Vaerlose',
    'Lille Skensved',
    'Lillerod',
    'Lemvig',
    'Lemming',
    'Lem',
    'Lejre',
    'Ledoje',
    'Laeborg',
    'Lasby',
    'Langeskov',
    'Langebæk',
    'Langa',
    'Kvaerndrup',
    'Kvong',
    'Kvistgard',
    'Kvanlose',
    'Kundby',
    'Krusa',
    'Korsør',
    'Korinth',
    'Køge',
    'Copenhagen',
    'Kongsted',
    'Kongerslev',
    'Kongens Lyngby',
    'Kolind',
    'Kolding',
    'Kokkedal',
    'Knebel',
    'Klovborg',
    'Klokkerholm',
    'Klippinge',
    'Klemensker',
    'Klejtrup',
    'Klarup',
    'Klampenborg',
    'Kjellerup',
    'Kirke Saby',
    'Kirke-Hyllinge',
    'Kirke Hvalso',
    'Kirke Eskilstrup',
    'Kibaek',
    'Kettinge',
    'Kerteminde',
    'Kastrup',
    'Karup',
    'Karrebaeksminde',
    'Karlslunde',
    'Karise',
    'Karby',
    'Kalvehave',
    'Kalundborg',
    'Jystrup',
    'Jyllinge',
    'Jyderup',
    'Junget',
    'Juelsminde',
    'Jordrup',
    'Jægerspris',
    'Jerup',
    'Jerslev',
    'Jelling',
    'Janderup',
    'Ishøj',
    'Ilskov',
    'Ikast',
    'Idestrup',
    'Hvidovre',
    'Hvide Sande',
    'Hurup',
    'Hundslund',
    'Hundested',
    'Humlebaek',
    'Humble',
    'Hovedgard',
    'Hovborg',
    'Hou',
    'Horslunde',
    'Horsens',
    'Hornsyld',
    'Hornslet',
    'Hornbaek',
    'Horbelev',
    'Horve',
    'Hørsholm',
    'Horning',
    'Horby',
    'Høng',
    'Hojslev',
    'Hojmark',
    'Hoje Tastrup',
    'Højer',
    'Hojby',
    'Hojbjerg',
    'Holtum',
    'Holte',
    'Holsted',
    'Holstebro',
    'Holme',
    'Holeby',
    'Holbol',
    'Holbaek',
    'Hobro',
    'Hjortshoj',
    'Hjørring',
    'Hjerm',
    'Hjallerup',
    'Hirtshals',
    'Hinnerup',
    'Hillerød',
    'Hevring',
    'Hesselager',
    'Herskind',
    'Herning',
    'Herlufmagle',
    'Herlev',
    'Herfolge',
    'Henne Strand',
    'Henne',
    'Hemmet',
    'Elsinore',
    'Helsinge',
    'Hellerup',
    'Hellebaek',
    'Hejnsvig',
    'Hejls',
    'Hedensted',
    'Hedehusene',
    'Havndal',
    'Haverslev',
    'Havdrup',
    'Hasseris',
    'Hasselager',
    'Haslev',
    'Hasle',
    'Harpelunde',
    'Harndrup',
    'Harlev',
    'Harby',
    'Harboore',
    'Hanstholm',
    'Hampen',
    'Hammershoj',
    'Hammer',
    'Hammel',
    'Hals',
    'Hadsund',
    'Hadsten',
    'Haderup',
    'Haderslev',
    'Guldborg',
    'Gudme',
    'Gudhjem',
    'Gudbjerg',
    'Graested',
    'Grindsted',
    'Grevinge',
    'Greve',
    'Grenå',
    'Gredstedbro',
    'Gråsten',
    'Gram',
    'Gorlose',
    'Gørlev',
    'Gording',
    'Glumso',
    'Glostrup',
    'Glostrup Municipality',
    'Glesborg',
    'Glejbjerg',
    'Glamsbjerg',
    'Gladsaxe',
    'Gjern',
    'Gjerlev',
    'Give',
    'Gistrup',
    'Gislinge',
    'Gislev',
    'Gilleleje',
    'Gesten',
    'Gentofte Municipality',
    'Gelsted',
    'Gedved',
    'Gedsted',
    'Gedser',
    'Ganlose',
    'Gandrup',
    'Gammel Svebolle',
    'Gammel Blahoj',
    'Galten',
    'Gadstrup',
    'Gadbjerg',
    'Fuglebjerg',
    'Frostrup',
    'Frorup',
    'Frederiksværk',
    'Frederikssund',
    'Frederikshavn',
    'Frederiksberg',
    'Fredericia',
    'Fredensborg',
    'Fovling',
    'Forslev',
    'Follenslev',
    'Flemming',
    'Fjerritslev',
    'Fjenneslev',
    'Ferritslev',
    'Fensmark',
    'Farvang',
    'Farup',
    'Farum',
    'Farsø',
    'Farevejle',
    'Fannerup',
    'Fangel',
    'Faxe Ladeplads',
    'Fakse',
    'Faaborg',
    'Espergaerde',
    'Eskilstrup',
    'Eskebjerg',
    'Esbjerg',
    'Erslev',
    'Errindlev',
    'Engesvang',
    'Ejstrup',
    'Ejby',
    'Egtved',
    'Egernsund',
    'Ega',
    'Ebeltoft',
    'Ebberup',
    'Dybvad',
    'Dronningmolle',
    'Dronninglund',
    'Dragør',
    'Dover',
    'Djeld',
    'Dianalund',
    'Daugard',
    'Dannemare',
    'Dalmose',
    'Dalby',
    'Christiansfeld',
    'Charlottenlund',
    'Byrum',
    'Bylderup-Bov',
    'Bryrup',
    'Brylle',
    'Brovst',
    'Brorup',
    'Bronshoj',
    'Brons',
    'Brønderslev',
    'Brøndby',
    'Brondby Strand',
    'Brondbyoster',
    'Broager',
    'Brenderup',
    'Bredsten',
    'Bredebro',
    'Braedstrup',
    'Brande',
    'Bramming',
    'Brabrand',
    'Borup',
    'Borre',
    'Bording Kirkeby',
    'Borkop',
    'Bogelunde',
    'Bolderslev',
    'Bogo By',
    'Bogense',
    'Boeslunde',
    'Baelum',
    'Blommenslyst',
    'Blokhus',
    'Blavand',
    'Baekke',
    'Bjaeverskov',
    'Bjaert',
    'Bjerringbro',
    'Bjergby',
    'Bisserup',
    'Birkerød',
    'Billund',
    'Bevtoft',
    'Bedsted Thy',
    'Beder',
    'Barrit',
    'Ballum',
    'Ballerup Municipality',
    'Balle',
    'Bagsvaerd',
    'Bagenkop',
    'Avlum',
    'Auning',
    'Augustenborg',
    'Assens',
    'Asperup',
    'Asnaes',
    'Askov',
    'Askeby',
    'Asa',
    'Arup',
    'Arslev',
    'Aars',
    'Arre',
    'Aarhus',
    'Arden',
    'Ansager',
    'Ans',
    'Alslev',
    'Alsgarde',
    'Almind',
    'Allinge',
    'Allingabro',
    'Allested',
    'Allerød Municipality',
    'Alestrup',
    'Aalborg',
    'Albaek',
    'Albertslund Municipality',
    'Akirkeby',
    'Agger',
    'Agerskov',
    'Agerbaek',
    'Agedrup',
    'Ærøskøbing',
    'Abyhoj',
    'Aabybro',
    'Aabenraa',
    'Norrebro',
    'Vesterbro',
    'Osterbro',
    'Amager',
    'Samso',
    'Vallensbaek Strand',
    'Dyssegard',
    'Orestad',
    'Nordhavn',
    'Sporup',
    'Kobenhavn S',
    'Aarhus C',
    'Kobenhavn O',
    'Aarhus N',
    'Aarhus V',
    'Kobenhavn NV',
    'Kobenhavn N',
    'Kobenhavn SV',
  ],
  'Faroe Islands': [
    'Vidareidi',
    'Vestmanna',
    'Vatnsoyrar',
    'Vagur',
    'Tvoroyri',
    'Tórshavn',
    'Toftir',
    'Sumba',
    'Stykkid',
    'Strendur',
    'Sørvágur',
    'Soldarfjordur',
    'Skopun',
    'Skáli',
    'Skalavik',
    'Signabour',
    'Sandur',
    'Sandavagur',
    'Saltangara',
    'Runavik',
    'Porkeri',
    'Oyndarfjordhur',
    'Nordragota',
    'Nes',
    'Midvagur',
    'Leirvik',
    'Kvívík',
    'Kollafjordhur',
    'Klaksvík',
    'Kirkjubøur',
    'Innan Glyvur',
    'Hvalvik',
    'Hvalba',
    'Hoyvík',
    'Hosvik',
    'Gota',
    'Glyvrar',
    'Fuglafjørður',
    'Eidi',
    'Argir',
  ],
  Iceland: [
    'Vopnafjordur',
    'Vik',
    'Varmahlid',
    'Siglufjordur',
    'Seyðisfjörður',
    'Sauðarkrokur',
    'Reykjahlíð',
    'Raufarhofn',
    'Olafsfjordur',
    'Husavik',
    'Hofn',
    'Eskifjordur',
    'Egilsstaðir',
    'Djupivogur',
    'Dalvik',
    'Faskrudsfjoerdur',
    'Reydarfjordur',
    'Akureyri',
    'Vogar',
    'Vestmannaeyjar',
    'Patreksfjordur',
    'Thorlakshofn',
    'Stykkishólmur',
    'Stokkseyri',
    'Selfoss',
    'Sangerdi',
    'Reykjavik',
    'Reykholt',
    'Olafsvik',
    'Njardvik',
    'Laugarvatn',
    'Seltjarnarnes',
    'Mosfellsbaer',
    'Kopavogur',
    'Keflavik',
    'Isafjordur',
    'Hvolsvollur',
    'Hveragerdi',
    'Hvanneyri',
    'Hvammstangi',
    'Skagastrond',
    'Hellissandur',
    'Hella',
    'Hafnarfjordur',
    'Grundarfjordur',
    'Grindavik',
    'Gardur',
    'Garðabaer',
    'Eyrarbakki',
    'Búðardalur',
    'Alftanes',
    'Borgarnes',
    'Blonduos',
    'Akranes',
    'Flúðir',
    'Talknafjordur',
    'Grimsey',
    'Laugar',
    'Reykjanesbaer',
  ],
  'United Kingdom': [
    'London',
    'Birmingham',
    'Manchester',
    'Leeds',
    'Sheffield',
    'Glasgow',
    'Newcastle upon Tyne',
    'Nottingham',
    'Liverpool',
    'Southend-on-Sea',
    'Bristol',
    'Edinburgh',
    'Brighton',
    'Bradford',
    'Leicester',
    'Sunderland',
    'Belfast',
    'Portsmouth',
    'Bournemouth',
    'Middlesbrough',
    'Coventry',
    'Southampton',
    'Reading',
    'Sutton on Hull',
    'Blackpool',
    'Plymouth',
    'Luton',
    'Oxford',
    'Norwich',
    'Old Aberdeen',
    'York',
    'Dundee',
    'Ipswich',
    'Peterborough',
    'Cambridge',
    'Exminster',
    'Bath',
    'Chester',
    'Greenock',
    'Carlisle',
    'Scarborough',
    'Ayr',
    'Inverness',
    'Perth',
    'River',
    'Dumfries',
    'Omagh',
    'Penzance',
    'Fort William',
    'Kirkwall',
    'Wick',
    'Lerwick',
    'Ilford',
    'Chelmsford',
    'Weston-super-Mare',
    'Llangefni',
    'Southport',
    'Armagh',
    'Rochester',
    'Rochdale',
    'Motherwell',
    'Clydach Vale',
    'Kirkintilloch',
    'Stirling',
    'Lambeth',
    'Dungannon',
    'Huddersfield',
    'Stretford',
    'Preston',
    'Newbury',
    'Rhuthun',
    'Blackburn',
    'Wood Green',
    'Stafford',
    'Gloucester',
    'Glenrothes',
    'South Bank',
    'Pont-y-pŵl',
    'Durham',
    'Bedford',
    'Taunton',
    'Widnes',
    'Uxbridge',
    'Elgin',
    'Hove',
    'Lewes',
    'Maidstone',
    'Llandrindod Wells',
    'Kingston upon Hull',
    'Kingston upon Thames',
    'Barri',
    'Giffnock',
    'Hounslow',
    'Derry',
    'Newtownabbey',
    'Forfar',
    'Torquay',
    'East Ham',
    'Solihull',
    'Poplar',
    'Mold',
    'Warwick',
    'Knowsley',
    'Hwlffordd',
    'Rotherham',
    'Caernarfon',
    'Morpeth',
    'Irvine',
    'Cwmbran',
    'Stornoway',
    'Darlington',
    'Hackney',
    'Trowbridge',
    'Merthyr Tudful',
    'Hertford',
    'Newry',
    'Belfast',
    'Camberwell',
    'Caerfyrddin',
    'Cookstown',
    'Dorchester',
    'Port Talbot',
    'Antrim',
    'Gateshead',
    'Wandsworth',
    'Exeter',
    'Camden Town',
    'Alloa',
    'Chicksands',
    'Banbridge',
    'Walthamstow',
    'Paisley',
    'Chichester',
    'Craigavon',
    'Woolwich',
    'Northallerton',
    'Romford',
    'Walsall',
    'Scunthorpe',
    'Islington',
    'Oldbury',
    'Downpatrick',
    'Livingston',
    'Ashton-under-Lyne',
    'Winchester',
    'Sandbach',
    'Derby',
    'Twickenham',
    'Saint Helens',
    'Bury',
    'Maidenhead',
    'Wembley',
    'Hartlepool',
    'Hugh Town',
    'Oldham',
    'Bexleyheath',
    'Ebbw Vale',
    'Magherafelt',
    'Wigan',
    'Ballymena',
    'Halifax',
    'Stockport',
    'Stockton-on-Tees',
    'Northampton',
    'Grays',
    'Ystrad Mynach',
    'Aberaeron',
    'Newtown Saint Boswells',
    'Enfield',
    'Stoke-on-Trent',
    'Sutton',
    'Dudley',
    'Wrecsam',
    'Aberdeen',
    'Bolton',
    'Warrington',
    'Salford',
    'Kensington',
    'Doncaster',
    'Swindon',
    'Pen-y-Bont ar Ogwr',
    'Catford',
    'Coleraine',
    'Barnsley',
    'Caerdydd',
    'Poole',
    'Bracknell',
    'Wakefield',
    'Thornbury',
    'Wokingham',
    'Falkirk',
    'Haddington',
    'Abertawe',
    'Beverley',
    'Croydon',
    'South Shields',
    'Morden',
    'Lochgilphead',
    'Worcester',
    'Matlock',
    'Shrewsbury',
    'Truro',
    'Hereford',
    'Newport',
    'Oakham',
    'Milton Keynes',
    'Dagenham',
    'Conwy',
    'Dalkeith',
    'Enniskillen',
    'Hamilton',
    'Hendon',
    'Hammersmith',
    'Bromley',
    'Telford',
    'Harrow',
    'Ealing',
    'Slough',
    'Wolverhampton',
    'Dumbarton',
    'Bangor',
    'Wallsend',
    'Strabane',
    'City of Westminster',
    'Lincoln',
    'Newport',
    'Lisburn',
    'Aylesbury',
    'Wallasey',
    'Grimsby',
    'Kilmarnock',
  ],
  Ireland: [
    'Buncrana',
    'Youghal',
    'Wicklow',
    'Paulstown',
    'Whitegate',
    'Wexford',
    'Westport',
    'Waterville',
    'Watergrasshill',
    'Waterford',
    'Walshtown',
    'Virginia',
    'Urlingford',
    'Tulsk',
    'Tullymore',
    'Tullyallen',
    'Tullow',
    'Tullamore',
    'Tulla',
    'Tubber',
    'Tuam',
    'Trim',
    'Tramore',
    'Tralee',
    'Tooreen',
    'Roundwood',
    'Togher',
    'Tobercurry',
    'Tipperary',
    'Tinahely',
    'Timahoe',
    'Thurles',
    'Thomastown',
    'Termonfeckin',
    'Terenure',
    'Templemore',
    'Tarbert',
    'Tallow',
    'Tallaght',
    'Taghmon',
    'Swords',
    'Swinford',
    'Swanlinbar',
    'Summerhill',
    'Strokestown',
    'Stranorlar',
    'Strand',
    'Straffan',
    'Stradbally',
    'Stillorgan',
    'Stepaside',
    'Stamullin',
    'Spiddal',
    'Sligo',
    'Slane',
    'Schull',
    'Screen',
    'Skibbereen',
    'Skerries',
    'Sixmilebridge',
    'Shrule',
    'Shinrone',
    'Shillelagh',
    'Shercock',
    'Shankill',
    'Shanagolden',
    'Scramoge',
    'Scotstown',
    'Scartaglin',
    'Scarriff',
    'Santry',
    'Sandymount',
    'Sallins',
    'Patrickswell',
    'Saint Mullins',
    'Saggart',
    'Rush',
    'Roundstone',
    'Rossnowlagh Lower',
    'Rosslare',
    'Roscrea',
    'Roscommon',
    'Rochfortbridge',
    'Riverstown',
    'Riverstick',
    'Rhode',
    'Redhills',
    'Redcross',
    'Ratoath',
    'Rathvilly',
    'Rathnew',
    'Rathmore',
    'Rathmolyon',
    'Rathmines',
    'Rathkeale',
    'Rathfarnham',
    'Rathdrum',
    'Rathdowney',
    'Rathdangan',
    'Rathcormack',
    'Rathcoole',
    'Rathangan',
    'Raheny',
    'Raharney',
    'Quin',
    'Prosperous',
    'Portumna',
    'Portroe',
    'Portnoo',
    'Portmarnock',
    'Portlaw',
    'Portlaoise',
    'Portarlington',
    'Piltown',
    'Daingean',
    'Passage West',
    'Palmerstown Bridge',
    'Pallaskenry',
    'Pallasgreen',
    'Pallas Grean',
    'Palatine',
    'Ovens',
    'Oughterard',
    'Oranmore',
    'Oola',
    'Omeath',
    'Old Kilcullen',
    'Oldcastle',
    'Nobber',
    'Newtownshandrum',
    'New Ross',
    'New Quay',
    'Newport',
    'Newmarket on Fergus',
    'Newmarket',
    'Newcastle',
    'Newcastle West',
    'Droichead Nua',
    'Newbridge',
    'Newbliss',
    'Newbawn',
    'Nenagh',
    'Navan',
    'Naas',
    'Myshall',
    'Mungret',
    'Mullingar',
    'Mullinavat',
    'Mullinahone',
    'Mullagh',
    'Muff',
    'Moylough',
    'Moycullen',
    'Moyard',
    'Moville',
    'Mountrath',
    'Mountmellick',
    'Mountcharles',
    'Mornington',
    'Mooncoin',
    'Monkstown',
    'Monasterevin',
    'Monaghan',
    'Mohill',
    'Mitchelstown',
    'Minane Bridge',
    'Milltown Malbay',
    'Milltown',
    'Millstreet',
    'Millford',
    'Milford',
    'Midleton',
    'Meelick',
    'Mayo',
    'Maynooth',
    'Manorhamilton',
    'Manorcunningham',
    'Mallow',
    'Malin',
    'Malahide',
    'Macroom',
    'Maam Cross',
    'Lusk',
    'Lucan',
    'Louth',
    'Louisburgh',
    'Loughrea',
    'Loughlinstown',
    'Longwood',
    'Longford',
    'Lixnaw',
    'Listowel',
    'Lismore',
    'Lisdoonvarna',
    'Liscannor',
    'Limerick',
    'Lifford',
    'Letterkenny',
    'Leixlip',
    'Leitrim',
    'Leighlinbridge',
    'Lehinch',
    'Leenane',
    'Lecarrow',
    'Leap',
    'Laytown',
    'Lanesborough',
    'Ladysbridge',
    'Knocktopher',
    'Knockraha',
    'Knocklong',
    'Knockbridge',
    'Knockaderry',
    'Knock',
    'Kishkeam',
    'Kinvarra',
    'Kinsale',
    'Kinnegad',
    'Kinlough',
    'Kingscourt',
    'Kincaslough',
    'Kilworth',
    'Kiltyclogher',
    'Kiltegan',
    'Kilteely',
    'Kiltamagh',
    'Kilrush',
    'Kilnaleck',
    'Kilmuckridge',
    'Kilmihil',
    'Kilmallock',
    'Kilmainham Wood',
    'Kilmaine',
    'Killybegs',
    'Killucan',
    'Killorglin',
    'Killiney',
    'Killeshin',
    'Killenaule',
    'Killeagh',
    'Killashandra',
    'Killarney',
    'Killaloe',
    'Killala',
    'Kilkishen',
    'Kilkenny',
    'Kilkelly',
    'Kilkee',
    'Kilgarvan',
    'Kilfinane',
    'Kilfenora',
    'Kildorrery',
    'Kildare',
    'Kildalkey',
    'Kilcullen',
    'Kilcoole',
    'Kilcolgan',
    'Kilcock',
    'Kilcar',
    'Kilbrittain',
    'Kilberry',
    'Kilbeggan',
    'Keshcarrigan',
    'Kenmare',
    'Kells',
    'Keenagh',
    'Kanturk',
    'Julianstown',
    'Johnstown',
    'Jenkinstown',
    'Inveran',
    'Inver',
    'Inishannon',
    'Inchicore',
    'Inch',
    'Inagh',
    'Howth',
    'Hospital',
    'Hollywood',
    'Hollymount',
    'Hollyford',
    'Headford',
    'Hacketstown',
    'Greystones',
    'Grenagh',
    'Greencastle',
    'Grangegeeth Cross Roads',
    'Grange',
    'Granard',
    'Graiguenamanagh',
    'Gowran',
    'Gort',
    'Gormanston',
    'Gorey',
    'Goresbridge',
    'Goleen',
    'Gneevgullia',
    'Glynn',
    'Glounthaune',
    'Glinsk',
    'Glenties',
    'Glennamaddy',
    'Glenealy',
    'Glenbeigh',
    'Glasnevin',
    'Glaslough',
    'Glanworth',
    'Glanmire',
    'Geashill',
    'Garristown',
    'Galway',
    'Galbally',
    'Freshford',
    'Frenchpark',
    'Foynes',
    'Foxford',
    'Foulkesmill',
    'Flagmount',
    'Finglas',
    'Fethard',
    'Ferrybank',
    'Ferns',
    'Fermoy',
    'Ferbane',
    'Fedamore',
    'Errill',
    'Ennistimon',
    'Enniskerry',
    'Enniskean',
    'Enniscorthy',
    'Ennis',
    'Enfield',
    'Emyvale',
    'Emo',
    'Elphin',
    'Edgeworthstown',
    'Edenderry',
    'Easky',
    'Durrow',
    'Dunshaughlin',
    'Dunmore East',
    'Dunmore',
    'Dunmanway',
    'Dunleer',
    'Dún Laoghaire',
    'Dunlavin',
    'Dungloe',
    'Dungarvan',
    'Dundrum',
    'Dunderry',
    'Dundalk',
    'Duncormick',
    'Dunboyne',
    'Duleek',
    'Dublin',
    'Drumshanbo',
    'Dromod',
    'Drumlish',
    'Drumcree',
    'Drumcliff',
    'Drumcar',
    'Dromahair',
    'Drogheda',
    'Dripsey',
    'Drinagh',
    'Drimoleague',
    'Douglas',
    'Doon',
    'Doolin',
    'Doocharry',
    'Donoughmore',
    'Donore',
    'Donohill',
    'Doneraile',
    'Donegal',
    'Donard',
    'Donadea',
    'Donacarney',
    'Donabate',
    'Dingle',
    'Delvin',
    'Delgany',
    'Dalkey',
    'Curry',
    'Crusheen',
    'Crumlin',
    'Falcarragh',
    'Crosshaven',
    'Cross',
    'Croom',
    'Croagh',
    'Cratloe',
    'Corrofin',
    'Cork',
    'Corbally',
    'Cootehill',
    'Cooraclare',
    'Convoy',
    'Conna',
    'Cong',
    'Collon',
    'Collinstown',
    'Cobh',
    'Coachford',
    'Cloyne',
    'Clontarf',
    'Clonsilla',
    'Clonroche',
    'Clonmel',
    'Clonmany',
    'Clones',
    'Clonee',
    'Clondalkin',
    'Clonaslee',
    'Clonakilty',
    'Cloghjordan',
    'Clogherhead',
    'Clogheen',
    'Cloghan',
    'Clifden',
    'Cleggan',
    'Clarinbridge',
    'Claremorris',
    'Claregalway',
    'Clarecastle',
    'Clara',
    'Clane',
    'Churchtown',
    'Cheekpoint',
    'Rath Luirc',
    'Charlestown',
    'Chapelizod',
    'Celbridge',
    'Cavan',
    'Causeway',
    'Castletownshend',
    'Castletownroche',
    'Castlerea',
    'Castlepollard',
    'Castlemartyr',
    'Castlemaine',
    'Castlelyons',
    'Castleknock',
    'Castlejordan',
    'Castleisland',
    'Castlegregory',
    'Castledermot',
    'Castlecomer',
    'Castlebridge',
    'Castleblayney',
    'Castlebellingham',
    'Castlebar',
    'Cashel',
    'Carrigtohill',
    'Carrignavar',
    'Carrigallen',
    'Carrigaline',
    'Carrigaholt',
    'Carrick-on-Suir',
    'Carrick on Shannon',
    'Carrickmines',
    'Carrickmacross',
    'Carrick',
    'Carraroe',
    'Carney',
    'Carnew',
    'Carndonagh',
    'Carna',
    'Carn',
    'Carlow',
    'Carlingford',
    'Carbury',
    'Cappoquin',
    'Cappamore',
    'Camross',
    'Calverstown',
    'Callan',
    'Cahersiveen',
    'Caherlistrane',
    'Caherdaniel',
    'Caherconlish',
    'Caher',
    'Cabinteely',
    'Buttevant',
    'Butlers Bridge',
    'Burtonport',
    'Burnfort',
    'Bunratty',
    'Bundoran',
    'Bunclody',
    'Bruree',
    'Bruff',
    'Broadford',
    'Bridgetown',
    'Bray',
    'Boyle',
    'Borrisoleigh',
    'Borrisokane',
    'Borris in Ossory',
    'Borris',
    'Bodyke',
    'Blessington',
    'Blarney',
    'Blanchardstown',
    'Blackrock',
    'Birr',
    'Birdhill',
    'Bettystown',
    'Belturbet',
    'Belmullet',
    'Bellananagh',
    'Belgooly',
    'Beaufort',
    'Bawnboy',
    'Barna',
    'Bantry',
    'Banteer',
    'Bansha',
    'Bandon',
    'Banagher',
    'Baltinglass',
    'Baltimore',
    'Ballyvaghan',
    'Ballyshannon',
    'Ballysadare',
    'Ballyragget',
    'Ballymurn',
    'Ballymote',
    'Ballymore Eustace',
    'Ballymahon',
    'Ballyjamesduff',
    'Ballyhooly',
    'Ballyhaunis',
    'Ballyhaise',
    'Ballyhahill',
    'Ballygarvan',
    'Ballygar',
    'Ballycumber',
    'Ballyconnell',
    'Ballycastle',
    'Ballybrittas',
    'Ballybofey',
    'Ballyboden',
    'Ballybay',
    'Ballyadams',
    'Ballsbridge',
    'Ballivor',
    'Ballitore',
    'Ballinspittle',
    'Ballinskelligs',
    'Ballinrobe',
    'Ballinlough',
    'Ballinhassig',
    'Ballingeary',
    'Ballingarry',
    'Ballineen',
    'Ballindine',
    'Ballinderreen',
    'Ballincollig',
    'Ballinasloe',
    'Ballinamuck',
    'Ballinamore Bridge',
    'Ballinamore',
    'Ballinalee',
    'Ballinafad',
    'Ballina',
    'Ballaghmore',
    'Ballaghaderreen',
    'Baldoyle',
    'Balbriggan',
    'Bailieborough',
    'Bagenalstown',
    'Aughrim',
    'Athy',
    'Athlone',
    'Athleague',
    'Athenry',
    'Athea',
    'Athboy',
    'Askeaton',
    'Ashford',
    'Ashbourne',
    'Arvagh',
    'Arklow',
    'Ardrahan',
    'Ardnacrusha',
    'Ardmore',
    'Ardfert',
    'Ardee',
    'Ardara',
    'Ardagh',
    'Annacarty',
    'Aherla',
    'Aglish',
    'Adrigole',
    'Adare',
    'Adamstown',
    'Achill',
    'Abbeyshrule',
    'Abbeyleix',
    'Abbeylara',
    'Abbeyfeale',
    'Ballyconneely',
    'Curragh',
    'Bweeng',
    'Whites Cross',
    'Rochestown',
    'Carrigeen',
    'Glenmore',
    'The Rower',
    'Rathgarvan',
    'Ardattin',
    'Castleconnell',
    'Ballyvaughan',
    'Garranmaconly',
    'Gortahork',
    'Lettermacaward',
    'Poles',
    'Drung',
    'Ballynahaia',
    'Ballysheedy',
    'Shannon',
    'Ballygawley',
    'Killeen',
    'Lettermore',
    'Ballybrit',
    'Corrandulla',
    'Craughwell',
    'Kilcormac',
    'Tara',
    'Moate',
    'Crookstown',
    'Kilbride',
    'Kildangan',
    'Kill',
    'Kilpedder',
    'Glencullen',
    'Brittas',
    'Woodtown',
    'Kingswood',
    'Ballymount',
    'Firhouse',
    'Sandyford',
    'Leopardstown',
    'Foxrock',
    'Sallynoggin',
    'Deansgrange',
    'Rathgar',
    'Ranelagh',
    'Irishtown',
    'Artane',
    'Drumcondra',
    'Ballymun',
    'Cabra',
    'Walkinstown',
    'Ashtown',
    'Phibsborough',
    'Hartstown',
    'Mulhuddart',
    'Ballyfermot',
    'Templeogue',
    "Saint Margaret's",
    'Bellewstown',
    'Clonskeagh',
    'Booterstown',
    'Ballinteer',
    'Perrystown',
    'Drimnagh',
    'Coolock',
    'Donaghmede',
    'Darndale',
    'Sandycove',
    'Enniscrone',
    'Tyrrelstown',
    'Rosscarbery',
    'Ballylinan',
    'Bishopstown',
    'Carrickaboy',
    'Castletroy',
    'Enniskeane',
    'Kilternan',
    'Knocklyon',
    'Renvyle',
    'Mountnugent',
    'Clonlara',
    'Kilsallaghan',
    'Kilkerrin',
    'Salthill',
    'Knocknagoshel',
    'The Curragh',
    'Stoneyford',
    'Dromcollogher',
    'Newtowncashel',
    'Newtownforbes',
    'The Neale',
    'Kentstown',
    'Tarmonbarry',
    'Cappawhite',
    'Ring',
    'Kilquade',
    'Newtownmountkennedy',
    'Glendalough',
    'Barndarrig',
    'Ballymontane',
    'Rosscahill',
    'Burt',
    'Silloge',
    'Templeglantine',
    'Cherrywood',
    'Newross',
  ],
  Switzerland: [
    'Zwingen',
    'Zweisimmen',
    'Zuzwil',
    'Zuzgen',
    'Bad Zurzach',
    'Zurich',
    'Zuoz',
    'Zunzgen',
    'Zumikon',
    'Zumholz',
    'Zug',
    'Zuckenriet',
    'Zuchwil',
    'Zollikon',
    'Zollikofen',
    'Zollbruck',
    'Zofingen',
    'Zizers',
    'Zinal',
    'Zimmerwald',
    'Zillis',
    'Zihlschlacht',
    'Ziegelbrucke',
    'Zermatt',
    'Zell',
    'Zeihen',
    'Zaziwil',
    'Yvorne',
    'Yvonand',
    'Yverdon-les-Bains',
    'Yens',
    'Wyssachen',
    'Wynigen',
    'Wynau',
    'Wurenlos',
    'Wurenlingen',
    'Wuppenau',
    'Wunnewil',
    'Worblaufen',
    'Worben',
    'Worb',
    'Wollerau',
    'Wolhusen',
    'Wolfenschiessen',
    'Wohlen',
    'Wittnau',
    'Wittenbach',
    'Winterthur',
    'Winikon',
    'Windlach',
    'Windisch',
    'Wimmis',
    'Willisau',
    'Wileroltigen',
    'Wiler',
    'Wildhaus',
    'Wilderswil',
    'Wildegg',
    'Wildberg',
    'Wilchingen',
    'Wila',
    'Wil',
    'Wikon',
    'Wigoltingen',
    'Wiesendangen / Wiesendangen (Dorf)',
    'Davos Wiesen',
    'Wiedlisbach',
    'Wetzikon',
    'Wettingen',
    'Wernetshausen',
    'Werdenberg',
    'Wengi',
    'Wengen',
    'Welschenrohr',
    'Weisslingen',
    'Weiningen',
    'Weinfelden',
    'Weggis',
    'Wegenstetten',
    'Weesen',
    'Wauwil',
    'Wattwil',
    'Wattenwil',
    'Wasterkingen',
    'Wasen',
    'Wangs',
    'Wangi',
    'Wangen an der Aare',
    'Wangen',
    'Walzenhausen',
    'Waltenschwil',
    'Waltensburg',
    'Wallisellen',
    'Walenstadt',
    'Wallenbuch',
    'Waldstatt',
    'Waldkirch',
    'Waldi',
    'Waldhaus',
    'Waldenburg',
    'Wald',
    'Walchwil',
    'Wahlen',
    'Wagenhausen',
    'Wachseldorn',
    'Wabern',
    'Vuisternens-en-Ogoz',
    'Vuadens',
    'Vouvry',
    'Vordemwald',
    'Volketswil / Volketswil (Dorf)',
    'Vitznau',
    'Vissoie',
    'Visperterminen',
    'Visp',
    'Vionnaz',
    'Vinzel',
    'Vilters',
    'Villnachern',
    'Villmergen',
    'Villigen',
    'Villeret',
    'Villeneuve',
    'Villaz-Saint-Pierre',
    'Villars-sur-Ollon',
    'Villars-sur-Glane',
    'Villars-le-Grand',
    'Viganello',
    'Vicques',
    'Veytaux',
    'Veyrier',
    'Vex',
    'Vevey',
    'Vetroz',
    'Vesenaz',
    'Versoix',
    'Verscio',
    'Vernier',
    'Vernayaz',
    'Vermes',
    'Vercorin',
    'Verbier',
    'Veltheim',
    'Vechigen',
    'Vaulruz',
    'Vaulion',
    'Vauffelin',
    'Vauderens',
    'Vandoeuvres',
    'Valzeina',
    'Vals Platz',
    'Vallorbe',
    'Valendas',
    "Val d'Illiez",
    'Valangin',
    'Vacallo',
    'Uzwil',
    'Uznach',
    'Utzenstorf',
    'Uttwil',
    'Uttigen',
    'Uster',
    'Urtenen',
    'Urnäsch',
    'Unterwasser',
    'Untervaz',
    'Unterterzen',
    'Unterstammheim',
    'Untersiggenthal',
    'Unterseen',
    'Unterlunkhofen',
    'Unterkulm',
    'Unteriberg',
    'Untererlinsbach',
    'Unterengstringen',
    'Ehrendingen',
    'Unterageri',
    'Uitikon',
    'Uhwiesen',
    'Ufhusen',
    'Uffikon',
    'Uettligen',
    'Uetikon',
    'Uetendorf',
    'Uesslingen',
    'Uerkheim',
    'Uerikon',
    'Ueberstorf',
    'Udligenswil',
    'Turtmann',
    'Turgi',
    'Turbenthal',
    'Tuggen',
    'Trun',
    'Trullikon',
    'Trubbach',
    'Trub',
    'Troistorrents',
    'Trogen',
    'Trin Mulin',
    'Trin',
    'Trimstein',
    'Trimmis',
    'Trimbach',
    'Triengen',
    'Treyvaux',
    'Trelex',
    'Trasadingen',
    'Tramelan',
    'Tomils',
    'Toffen',
    'Tobel',
    'Tiefencastel',
    'Thusis',
    'Thundorf',
    'Thun',
    'Thorishaus',
    'Thierrens',
    'Thierachern',
    'Thielle',
    'Therwil',
    'Thayngen',
    'Thalwil',
    'Thalheim',
    'Teufenthal',
    'Teufen AR',
    'Tesserete',
    'Territet',
    'Termen',
    'Tenero',
    'Tegna',
    'Tegerfelden',
    'Taverne',
    'Tavannes',
    'Tauffelen',
    'Tasch',
    'Tarasp',
    'Tann',
    'Tamins',
    'Tagerwilen',
    'Tafers',
    'Sues',
    'Sursee',
    'Sundlauenen',
    'Sumiswald',
    'Sulz',
    'Sulgen',
    'Suhr',
    'Sugiez',
    'Subingen',
    'Suberg',
    'Strengelbach',
    'Stilli',
    'Stettlen',
    'Sternenberg',
    'Steinhausen',
    'Steinerberg',
    'Steinen',
    'Stein am Rhein',
    'Stein',
    'Steffisburg',
    'Steckborn',
    'Staufen',
    'Stansstad',
    'Stans',
    'Stallikon',
    'Stalden',
    'Staffelbach',
    'Stafa',
    'Stadel',
    'Stabio',
    'Spreitenbach',
    'Spluegen',
    'Spiez',
    'Speicher',
    'Sottens',
    'Sorens',
    'Sorengo',
    'Soerenberg',
    'Soral',
    'Sonvilier',
    'Sonvico',
    'Sonceboz',
    'Solothurn',
    'Solduno',
    'Siviriez',
    'Sissach',
    'Sisikon',
    'Siselen',
    'Sirnach',
    'Sion',
    'Sins',
    'Silvaplana',
    'Sils im Domleschg',
    'Silenen',
    'Sigriswil',
    'Signau',
    'Siglistorf',
    'Sigirino',
    'Sierre',
    'Siebnen',
    'Siblingen',
    'Sevelen',
    'Sessa',
    'Seon',
    'Sent',
    'Sennwald',
    'Semsales',
    'Sempach',
    'Sementina',
    'Sembrancher',
    'Selzach',
    'Seltisberg',
    'Sils-Segl Maria',
    'Seftigen',
    'Seewis im Praetigau',
    'Seewen',
    'Seengen',
    'Seelisberg',
    'Seegraben',
    'Seedorf',
    'Sedrun',
    'Scuol',
    'Schwyz',
    'Schwellbrunn',
    'Schwarzenegg',
    'Schwarzenburg',
    'Schwarzenberg',
    'Schwandi',
    'Schwanden',
    'Schwaderloch',
    'Schuepfheim',
    'Schupfen',
    'Schupbach',
    'Schubelbach',
    'Schoetz',
    'Schonried',
    'Schongau',
    'Schonenwerd',
    'Schönengrund',
    'Schonenbuch',
    'Schonenberg an der Thur',
    'Schonbuhl',
    'Schoftland',
    'Schnottwil',
    'Schmitten',
    'Schmerikon',
    'Schlossrued',
    'Schlieren',
    'Schleuis',
    'Schleitheim',
    'Schlatt bei Diessenhofen',
    'Schinznach Dorf',
    'Schinznach Bad',
    'Schindellegi',
    'Schiers',
    'Scherzingen',
    'Schenkon',
    'Schattdorf',
    'Scharans',
    'Schanis',
    'Schafisheim',
    'Schaffhausen',
    'Schachen',
    'Scanfs',
    'Saxon',
    'Sax',
    'Savognin',
    'Savigny',
    'Saviese',
    'Sattel',
    'Satigny',
    'Sarnen',
    'Sarmenstorf',
    'Sargans',
    'San Vittore',
    'San Nazzaro',
    'Sankt Peterzell',
    'Sankt Niklaus',
    'St. Moritz',
    'Sankt Margrethen',
    'St. Gallen',
    'St. Antoni',
    'Samstagern',
    'Samnaun',
    'Samedan',
    'Salmsach',
    'Salgesch',
    'Salez',
    'Sales',
    'Saland',
    'Saint-Sulpice',
    'Saint-Prex',
    'Saint-Maurice',
    'Saint-Martin',
    'Saint-Livres',
    'Saint-Leonard',
    'Saint-Imier',
    'Saint-Gingolph',
    'Sainte-Croix',
    'Saint-Cergue',
    'Saint-Blaise',
    'Saint-Aubin',
    'Saint-Aubin-Sauges',
    'Saillon',
    'Saignelegier',
    'Sagogn',
    'Safenwil',
    'Sachseln',
    'Saas-Fee',
    'Saas-Balen',
    'Saas im Praettigau',
    'Saanen',
    'Ruthi',
    'Ruswil',
    'Russin',
    'Russikon',
    'Ruschlikon',
    'Ruschein',
    'Rupperswil',
    'Rumlang',
    'Rufi',
    'Rueun',
    'Rueggisberg',
    'Rue',
    'Rudolfstetten',
    'Rudlingen',
    'Rubigen',
    'Rovio',
    'Roveredo',
    'Rougemont',
    'Rotkreuz',
    'Rothrist',
    'Rothenburg',
    'Rothenthurm',
    'Rothenbach',
    'Rossiniere',
    'Rossens',
    'Roschenz',
    'Rorschach',
    'Rorbas',
    'Root',
    'Romont',
    'Roemerswil',
    'Romanshorn',
    'Romanel-sur-Lausanne',
    'Romainmotier',
    'Rolle',
    'Rohrbach',
    'Rohr',
    'Roggwil',
    'Rodi',
    'Rodersdorf',
    'Rodels',
    'Roche',
    'Robasacco',
    'Riva San Vitale',
    'Ringgenberg',
    'Riggisberg',
    'Riffenmatt',
    'Riedholz',
    'Riederalp',
    'Ried',
    'Riddes',
    'Rickenbach',
    'Rickenbach bei Wil',
    'Ricken',
    'Richterswil',
    'Richigen',
    'Richenthal',
    'Riaz',
    'Rheinfelden',
    'Rheineck',
    'Reutigen',
    'Renens',
    'Renan',
    'Rekingen',
    'Reinach',
    'Reigoldswil',
    'Reiden',
    'Reichenburg',
    'Reichenbach',
    'Rehetobel',
    'Regensdorf',
    'Regensberg',
    'Reconvilier',
    'Rebstein',
    'Raron',
    'Rapperswil',
    'Randogne',
    'Ramsen',
    'Ramismuhle',
    'Rafz',
    'Quartino',
    'Quarten',
    'Pura',
    'Pully',
    'Puidoux',
    'Prilly',
    'Presinge',
    'Preles',
    'Pregassona',
    'Praz-de-Fort',
    'Praz',
    'Pratteln',
    'Prangins',
    'Poschiavo',
    'Porto Ronco',
    'Portalban',
    'Porsel',
    'Porrentruy',
    'Ponthaux',
    'La Punt-Chamues-ch',
    'Pleigne',
    'Plasselb',
    'Plan-les-Ouates',
    'Plaffeien',
    'Piotta',
    'Pieterlen',
    'Piazzogna',
    'Pianezzo',
    'Pfyn',
    'Pfungen',
    'Pfaffhausen',
    'Pfeffingen',
    'Pfeffikon',
    'Pfaffnau',
    'Pfaeffikon',
    'Peseux',
    'Pery',
    'Perroy',
    'Perlen',
    'Penthalaz',
    'Peney-le-Jorat',
    'Pedrinate',
    'Payerne',
    'Paudex',
    'Paspels',
    'Parpan',
    'Panex',
    'Pampigny',
    'Palezieux',
    'Ovronnaz',
    'Ottenbach',
    'Othmarsingen',
    'Otelfingen',
    'Ostermundigen',
    'Wilchingen, Osterfingen',
    'Ossingen',
    'Osogna',
    'Orvin',
    'Orsieres',
    'Orpund',
    'Oron-la-Ville',
    'Ormalingen',
    'Orbe',
    'Oppens',
    'Opfikon',
    'Onnens',
    'Onex',
    'Olten',
    'Ollon',
    'Oleyres',
    'Oftringen',
    'Oetwil / Oetwil an der Limmat',
    'Oetwil am See',
    'Oensingen',
    'Obstalden',
    'Obfelden',
    'Oberwil',
    'Oberwil-Lieli',
    'Oberweningen',
    'Oberwangen',
    'Obervaz',
    'Oberuzwil',
    'Ober Urdorf',
    'Obersiggenthal',
    'Oberschlatt',
    'Oberruti',
    'Oberriet',
    'Oberrieden',
    'Oberonz',
    'Oberlunkhofen',
    'Oberkirch',
    'Oberhofen bei Etzgen',
    'Oberhofen',
    'Oberhallau',
    'Gurzelen',
    'Obergosgen',
    'Oberglatt',
    'Obergerlafingen',
    'Oberentfelden',
    'Oberengstringen',
    'Oberembrach',
    'Oberegg District',
    'Oberdorf',
    'Oberdiessbach',
    'Oberburg',
    'Oberbuchsiten',
    'Oberbipp',
    'Oberbalm',
    'Nyon',
    'Nussbaumen',
    'Nurensdorf',
    'Nunningen',
    'Novazzano',
    'Novalles',
    'Novaggio',
    'Nottwil',
    'Noiraigue',
    'Wichtrach',
    'Niederweningen / Niederweningen (Dorf)',
    'Niederuzwil',
    'Niederurnen',
    'Sommeri',
    'Niederscherli',
    'Niederrohrdorf',
    'Niederried',
    'Niederlenz',
    'Niederhelfenschwil',
    'Niedergosgen',
    'Erlinsbach',
    'Niederburen',
    'Niederbuchsiten',
    'Niederbipp',
    'Nidfurn',
    'Nidau',
    'Neyruz',
    'Neunkirch',
    'Neukirch',
    'Neuhausen',
    'Neuenkirch',
    'Neuenhof',
    'Neudorf',
    'Neuchatel',
    'Netstal',
    'Nesslau',
    'Neftenbach / Dorf Neftenbach',
    'Necker',
    'Nebikon',
    'Naters',
    'Nanikon',
    'Nafels',
    'Muttenz',
    'Muestair',
    'Morat',
    'Muri',
    'Muri bei Bern',
    'Murgenthal',
    'Murg',
    'Muotathal',
    'Muolen',
    'Munsingen',
    'Munchwilen',
    'Munchenstein',
    'Munchenbuchsee',
    'Mumpf',
    'Mumliswil',
    'Mullheim',
    'Muhlruti',
    'Mühlethurnen',
    'Mühlehorn',
    'Muhleberg',
    'Muhlau',
    'Muhen',
    'Mugena',
    'Moutier',
    'Moudon',
    'Motiers',
    'Mosnang',
    'Morschwil',
    'Morschach',
    'Morissen',
    'Moriken',
    'Morigen',
    'Morgins',
    'Morges',
    'Morgarten',
    'Morens',
    'Morel',
    'Morcote',
    'Morbio Inferiore',
    'Moosleerau',
    'Mont-sur-Rolle',
    'Montricher',
    'Montreux',
    'Monthey',
    'Monte Ceneri',
    'Monte Carasso',
    'Montcherand',
    'Montbovon',
    'Montana',
    'Montagny-la-Ville',
    'Monchaltorf',
    'Mols',
    'Mollis',
    'Mohlin',
    'Mogelsberg',
    'Minusio',
    'Miege',
    'Mezzovico',
    'Mezieres',
    'Meyrin',
    'Mex',
    'Metzerlen',
    'Mettmenstetten',
    'Mettlen',
    'Messen',
    'Mesocco',
    'Mervelier',
    'Merligen',
    'Merishausen',
    'Merenschwand',
    'Menznau',
    'Menzingen',
    'Menziken',
    'Mendrisio',
    'Mels',
    'Mellingen',
    'Melide',
    'Melchnau',
    'Melano',
    'Meisterschwanden',
    'Meiringen',
    'Meinisberg',
    'Meilen',
    'Meierskappel',
    'Meggen',
    'Medeglia',
    'Maur',
    'Mauborget',
    'Matzingen',
    'Matzendorf',
    'Matten',
    'Mastrils',
    'Massagno',
    'Marwil',
    'Martigny-Ville',
    'Martigny-Croix',
    'Martigny-Combe',
    'Marthalen',
    'Maroggia',
    'Marly',
    'Marin',
    'Marchissy',
    'Marbach',
    'Manno',
    'Malters',
    'Malleray',
    'Malix',
    'Malans',
    'Maladers',
    'Maisprach',
    'Maienfeld',
    'Magglingen',
    'Maggia',
    'Magden',
    'Magadino',
    'Madiswil',
    'Lyssach',
    'Lyss',
    'Lucerne',
    'Luven',
    'Lützelflüh',
    'Lutry',
    'Lutisburg',
    'Luthern',
    'Luterbach',
    'Lungern',
    'Lugano',
    'Ludiano',
    'Luchsingen',
    'Lucens',
    'Lourtier',
    'Lotzwil',
    'Lostorf',
    'Lostallo',
    'Losone',
    'Longirod',
    'Lommis',
    'Lodrino',
    'Locarno',
    'Littau',
    "L'Isle",
    'Linden',
    'Lindau',
    'Ligornetto',
    'Lignieres',
    'Ligerz',
    'Liestal',
    'Liesberg',
    'Lienz',
    'Liebistorf',
    'Liebefeld',
    'Lichtensteig',
    'Leytron',
    'Leysin',
    'Le Vaud',
    'Leuzigen',
    'Leutwil',
    'Leukerbad',
    'Leuk',
    'Les Thioleyres',
    'Lessoc',
    'Les Pommerats',
    'Les Marecottes',
    'Les Genevez',
    'Les Geneveys-sur-Coffrane',
    'Le Sepey',
    'Le Sentier',
    'Les Diablerets',
    'Les Charbonnieres',
    'Les Breuleux',
    'Les Brenets',
    'Les Bois',
    'Les Avants',
    'Les Acacias',
    'Le Prese',
    'Le Pont',
    'Le Paquier',
    'Lenzerheide',
    'Lenzburg',
    'Lens',
    'Le Noirmont',
    'Lenk',
    'Lengnau',
    'Le Mont-sur-Lausanne',
    'Le Locle',
    'Le Landeron',
    'Leissigen',
    'Leibstadt',
    'Leggia',
    'Le Cret-pres-Semsales',
    'Le Chenit',
    'Léchelles',
    'Le Chable',
    'Le Bry',
    'Le Brassus',
    'Lax',
    'Lavey',
    'Lausen',
    'Lausanne',
    'Laupersdorf',
    'Laupen',
    'Laufenburg',
    'Laufen',
    'Laufelfingen',
    'Lauerz',
    'Lauenen',
    'La Tour-de-Treme',
    'La Tour-de-Peilz',
    'La Sarraz',
    'La Sagne',
    'La Roche',
    'La Plaine',
    'Langrickenbach',
    'Langnau',
    'Langenthal',
    'Langenbruck',
    'La Neuveville',
    'Landquart',
    'Lamboing',
    'Lalden',
    'Lajoux',
    'La Heutte',
    'La Forclaz',
    'La Ferriere',
    'La Cure',
    'St-Legier-La Chiesaz',
    'Lachen',
    'La Chaux-de-Fonds',
    'La Brevine',
    'Laax',
    'Küttigen',
    'Küssnacht',
    'Künten',
    'Kueblis',
    'Krummenau',
    'Krinau',
    'Kriens',
    'Kriegstetten',
    'Kriechenwil',
    'Kreuzlingen',
    'Krauchthal',
    'Krattigen',
    'Kradolf',
    'Koppigen',
    'Konolfingen',
    'Köniz',
    'Kolliken',
    'Kollbrunn',
    'Koblenz',
    'Knutwil',
    'Knonau',
    'Kloten',
    'Klosters Dorf',
    'Klingnau',
    'Kleinlutzel',
    'Kirchlindach',
    'Kirchdorf',
    'Kirchberg',
    'Killwangen',
    'Kilchberg',
    'Kiesen',
    'Kiental',
    'Kesswil',
    'Kerzers',
    'Kerns',
    'Kempttal',
    'Kehrsatz',
    'Kefikon',
    'Kastanienbaum',
    'Kappelen',
    'Kappel',
    'Kandersteg',
    'Kandergrund',
    'Kaltbrunn',
    'Kallnach',
    'Kaisten',
    'Kaiserstuhl',
    'Kaiseraugst',
    'Jonschwil',
    'Jonen',
    'Jona',
    'Jeuss',
    'Jenins',
    'Jenaz',
    'Jegenstorf',
    'Ittenthal',
    'Islikon',
    'Iseo',
    'Iseltwald',
    'Iragna',
    'Inwil',
    'Intschi',
    'Intragna',
    'Interlaken',
    'Ins',
    'Innertkirchen',
    'Innerthal',
    'Ingenbohl',
    'Immensee',
    'Illnau',
    'Ilanz',
    'Igis',
    'Ibach',
    'Huttwilen',
    'Huttwil',
    'Huntwangen',
    'Hunibach',
    'Hunenberg',
    'Hundwil',
    'Huemoz',
    'Horw',
    'Hornussen',
    'Horn',
    'Horhausen',
    'Horgen',
    'Hondrich',
    'Hombrechtikon',
    'Holstein',
    'Holderbank',
    'Hohenrain',
    'Hofstetten',
    'Hochwald',
    'Hochdorf',
    'Hitzkirch',
    'Hittnau / Hittnau (Dorf)',
    'Hinwil',
    'Hinterkappelen',
    'Hindelbank',
    'Himmelried',
    'Hilterfingen',
    'Hildisrieden',
    'Herzogenbuchsee',
    'Herrliberg',
    'Hermetschwil-Staffeln',
    'Hermance',
    'Herisau',
    'Hergiswil',
    'Heremence',
    'Herbriggen',
    'Herbetswil',
    'Henggart',
    'Hendschiken',
    'Henau',
    'Hemishofen',
    'Hellbuehl',
    'Heitenried',
    'Heimiswil',
    'Heimberg',
    'Heiligenschwendi',
    'Heiden',
    'Heerbrugg',
    'Hausen am Albis / Hausen (Dorf)',
    'Hauptwil',
    'Les Hauderes',
    'Hatzingen',
    'Haslen',
    'Hasle',
    'Haemikon',
    'Hallau',
    'Hagglingen',
    'Haggenschwil',
    'Hagendorf',
    'Hagenbuch',
    'Habsburg',
    'Gwatt',
    'Guttingen',
    'Gunzwil',
    'Gunzgen',
    'Gummenen',
    'Gumligen',
    'Guarda',
    'Gstaad',
    'Grosswangen',
    'Gross Hochstetten',
    'Grossandelfingen',
    'Grossaffoltern',
    'Grono',
    'Grone',
    'Grolley',
    'Grindelwald',
    'Grimisuat',
    'Grimentz',
    'Greppen',
    'Grenchen',
    'Grellingen',
    'Greifensee',
    'Gravesano',
    'Graenichen',
    'Granges',
    'Grandvillard',
    'Grandval',
    'Grandson',
    'Le Grand-Saconnex',
    'Grandcour',
    'Grancy',
    'Grachen',
    'Grabs',
    'Gossau',
    'Goeslikon',
    'Gorgier',
    'Gorduno',
    'Gordola',
    'Gordevio',
    'Gontenschwil',
    'Gonten',
    'Gommiswald',
    'Gollion',
    'Goldswil',
    'Goldiwil',
    'Goldau',
    'Goldach',
    'Gnosca',
    'Glovelier',
    'Glis',
    'Glion',
    'Glattfelden',
    'Glattbrugg',
    'Glarus',
    'Glaris',
    'Gland',
    'Givrins',
    'Giubiasco',
    'Giswil',
    'Gipf-Oberfrick',
    'Castaneda',
    'Giornico',
    'Gimel',
    'Gilly',
    'Giffers',
    'Gibswil',
    'Geuensee',
    'Gettnau',
    'Gerzensee',
    'Gersau',
    'Gerlafingen',
    'Genolier',
    'Geneva',
    'Genestrerio',
    'Gempen',
    'Gelterkinden',
    'Gelfingen',
    'Gebenstorf',
    'Ganterschwil',
    'Gandria',
    'Gams',
    'Gampel',
    'Gals',
    'Galmiz',
    'Gais',
    'Gachnang',
    'Gachlingen',
    'Furna',
    'Fully',
    'Fullinsdorf',
    'Fulenbach',
    'Fuldera',
    'Melchsee-Frutt',
    'Frutigen',
    'Froideville',
    'Frieswil',
    'Frick',
    'Fribourg',
    'Frenkendorf',
    'Frenieres',
    'Freienbach',
    'Frauenfeld',
    'Fraubrunnen',
    'Fraschels',
    'Founex',
    'Forel',
    'Fontenais',
    'Fontainemelon',
    'Flurlingen',
    'Flums',
    'Fluehli',
    'Flüelen',
    'Flueli',
    'Flond',
    'Flims',
    'Fleurier',
    'Flawil',
    'Flamatt',
    'Flaach',
    'Fischingen',
    'Fiez',
    'Fiesso',
    'Fiesch',
    'Feusisberg',
    'Feuerthalen',
    'Felsberg',
    'Feldmeilen',
    'Feldbrunnen',
    'Feldbach',
    'Felben',
    'Fehraltorf',
    'Faulensee',
    'Farvagny-le-Petit',
    'Faoug',
    'Fanas',
    'Fallanden',
    'Falera',
    'Fahrwangen',
    'Fahrni',
    'Faedo',
    'Eysins',
    'Evolene',
    'Evionnaz',
    'Evilard',
    'Etzgen',
    'Ettiswil',
    'Ettingen',
    'Estavayer-le-Lac',
    'Essertines-sur-Rolle',
    'Eschlikon',
    'Eschenz',
    'Eschenbach',
    'Erstfeld',
    'Ersigen',
    'Erschwil',
    'Ernen',
    'Ermatingen',
    'Erlenbach',
    'Erlen',
    'Erlach',
    'Eriswil',
    'Erde',
    'Eptingen',
    'Épendes',
    'Epautheyres',
    'Épalinges',
    'Entlebuch',
    'Enney',
    'Ennetbürgen',
    'Ennenda',
    'Engi',
    'Engelberg',
    'Emmetten',
    'Emmenbruecke',
    'Emmen',
    'Embrach',
    'Elgg',
    'Einsiedeln',
    'Eiken',
    'Eigenthal',
    'Eichberg',
    'Eich',
    'Egnach',
    'Egliswil',
    'Eglisau',
    'Eggersriet',
    'Eggenwil',
    'Egg',
    'Egerkingen',
    'Effretikon',
    'Ecuvillens',
    'Écublens',
    'Echarlens',
    'Échallens',
    'Ebnat-Kappel',
    'Ebikon',
    'Dürrenroth',
    'Durrenasch',
    'Durnten',
    'Düdingen',
    'Dubendorf',
    'Dottikon',
    'Dornach',
    'Dorenaz',
    'Doppleschwand',
    'Dompierre',
    'Domdidier',
    'Dombresson',
    'Domat',
    'Disentis',
    'Dino',
    'Dietwil',
    'Dietikon',
    'Diessenhofen',
    'Diessbach',
    'Diepoldsau',
    'Dielsdorf',
    'Diegten',
    'Derendingen',
    'Densbueren',
    'Delémont',
    'Deitingen',
    'Degersheim',
    'Davos',
    'Davos Dorf',
    'Dattwil',
    'Därstetten',
    'Danis',
    'Daniken',
    'Dallenwil',
    'Daillens',
    'Dagmersellen',
    'Dachsen',
    'Cully',
    'Cugy',
    'Cugnasco',
    'Cudrefin',
    'Crissier',
    'Cressier',
    'Cresciano',
    'Crassier',
    'Crans-pres-Celigny',
    'Couvet',
    'Courtion',
    'Courtetelle',
    'Courtepin',
    'Courtelary',
    'Courtedoux',
    'Court',
    'Courroux',
    'Courrendlin',
    'Courgevaux',
    'Courgenay',
    'Courfaivre',
    'Cottens',
    'Cossonay',
    'Cortaillod',
    'Corsier',
    'Cornol',
    'Cornaux',
    'Corminboeuf',
    'Corgemont',
    'Cordast',
    'Corcelles',
    'Corcelles-pres-Payerne',
    'Coppet',
    'Contra',
    'Contone',
    'Conthey',
    'Cunter',
    'Confignon',
    'Concise',
    'Comologno',
    'Comano',
    'Colombier',
    'Collonges',
    'Collombey',
    'Cointrin',
    'Coeuve',
    'Claro',
    'Churwalden',
    'Chur',
    'Chironico',
    'Chippis',
    'Chiggiogna',
    'Chiasso',
    'Chez-le-Bart',
    'Chezard-Saint-Martin',
    'Cheyres',
    'Chexbres',
    'Chesieres',
    'Cheseaux-sur-Lausanne',
    'Chermignon',
    'Chene-Paquier',
    'Chene-Bourg',
    'Cheiry',
    'Chavornay',
    'Chavannes-le-Chene',
    'Chavannes',
    'Chatonnaye',
    'Chatel-Saint-Denis',
    'Chatelaine',
    "Chateau-d'Oex",
    'Charrat',
    'Charmoille',
    'Charmey',
    'Chardonne',
    'Chapelle-sur-Moudon',
    'Chancy',
    'Champvent',
    'Champsec',
    'Champex',
    'Champery',
    'Champagne',
    'Chamoson',
    'Chambesy',
    'Cham',
    'Chalais',
    'Cevio',
    'Cernier',
    'Cerniat',
    'Celigny',
    'Celerina',
    'Caux',
    'Castro',
    'Castione',
    'Castel San Pietro',
    'Caslano',
    'Carouge',
    'Canobbio',
    'Camorino',
    'Cama',
    'Cagiallo',
    'Cadro',
    'Cadenazzo',
    'Cadempino',
    'Butzberg',
    'Buttwil',
    'Buttisholz',
    'Busswil',
    'Bussigny',
    'Busserach',
    'Bursins',
    'Bueron',
    'Burglen',
    'Burgistein',
    'Burgdorf',
    'Buren an der Aare',
    'Bure',
    'Burchen',
    'Buonas',
    'Buochs',
    'Bullet',
    'Bulle',
    'Bulach',
    'Bühler',
    'Buchs / Buchs (Dorf)',
    'Buchs',
    'Buchrain',
    'Buchberg',
    'Buch',
    'Bubikon',
    'Bubendorf',
    'Bruttisellen',
    'Brutten',
    'Brusio',
    'Brusino Arsizio',
    'Brunnen',
    'Brunnadern',
    'Brugg',
    'Brügg',
    'Brot-Plamboz',
    'Bronschhofen',
    'Broc',
    'Brittnau',
    'Bristen',
    'Brissago',
    'Brislach',
    'Brigerbad',
    'Brig',
    'Brienz',
    'Brenzikofen',
    'Breno',
    'Bremgarten',
    'Breitenbach',
    'Breil',
    'Bre',
    'Braunwald',
    'Bramois',
    'Bowil',
    'Bovernier',
    'Le Bouveret',
    'Boudry',
    'Boudevilliers',
    'Bottmingen',
    'Bottighofen',
    'Bottenwil',
    'Bottens',
    'Bosingen',
    'Borex',
    'Bonstetten',
    'Boniswil',
    'Bonigen',
    'Bonfol',
    'Boncourt',
    'Bonaduz',
    'Boltigen',
    'Bolligen',
    'Boll',
    'Blumenstein',
    'Blonay',
    'Blauen',
    'Bitsch',
    'Bissone',
    'Bissegg',
    'Birsfelden',
    'Birrwil',
    'Birr',
    'Birmenstorf',
    'Birmensdorf',
    'Bioggio',
    'Binningen',
    'Bilten',
    'Bigorio',
    'Biglen',
    'Bigenthal',
    'Biere',
    'Biel/Bienne',
    'Biberist',
    'Biasca',
    'Bex',
    'Bever',
    'Bevaix',
    'Bettlach',
    'Bettingen',
    'Bettelried',
    'Berschis',
    'Bernex',
    'Berneck',
    'Bern',
    'Beringen',
    'Berikon',
    'Berg',
    'Bercher',
    'Benken',
    'Belp',
    'Belmont-sur-Lausanne',
    'Bellwald',
    'Bellmund',
    'Bellinzona',
    'Bellevue',
    'Belfaux',
    'Beinwil',
    'Begnins',
    'Beggingen',
    'Beckenried',
    'Bazenheid',
    'Bavois',
    'Bauma',
    'Batterkinden',
    'Bassersdorf',
    'Basse-Nendaz',
    'Bassecourt',
    'Basel',
    'Basadingen',
    'Bariswil',
    'Baretswil',
    'Barau',
    'Bannwil',
    'Banco',
    'Baltschieder',
    'Balsthal',
    'Ballwil',
    'Ballens',
    'Ballaigues',
    'Balgach',
    'Balerna',
    'Bagnes',
    'Bad Ragaz',
    'Baden',
    'Bachenbulach',
    'Baar',
    'Ayent',
    'Avry-sur-Matran',
    'Avenches',
    'Auw',
    'Auvernier',
    'Autigny',
    'Latterbach',
    'Aurigeno',
    'Aumont',
    'Augst',
    'Auenstein',
    'Aubonne',
    'Au',
    'Attiswil',
    'Attinghausen',
    'Attalens',
    'Asuel',
    'Assens',
    'Ascona',
    'Arzo',
    'Arzier-Le Muids',
    'Arth',
    'Arosa',
    'Arni',
    'Arlesheim',
    'Aristau',
    'Arisdorf',
    'Ardon',
    'Ardez',
    'Arconciel',
    'Arch',
    'Arcegno',
    'Arbon',
    'Arbedo',
    'Arbaz',
    'Aproz',
    'Apples',
    'Appenzell',
    'Anieres',
    'Andwil',
    'Andermatt',
    'Amriswil',
    'Amden',
    'Altstatten',
    'Altnau',
    'Altishofen',
    'Alterswil',
    'Altendorf',
    'Alten',
    'Altdorf',
    'Alpnach',
    'Allschwil',
    'Alle',
    'Albligen',
    'Albeuve',
    'Airolo',
    'Aigle',
    'Agra',
    'Agno',
    'Agiez',
    'Agarn',
    'Affoltern am Albis',
    'Aeschlen ob Gunten',
    'Aeschi b. Spiez',
    'Aeschau',
    'Aesch',
    'Aedermannsdorf',
    'Adliswil',
    'Adligenswil',
    'Adelboden',
    'Acquarossa',
    'Abtwil',
    'Aarwangen',
    'Aarburg',
    'Aarberg',
    'Aarau',
    'Aadorf',
    'Riehen',
    'Chene-Bougeries',
    'Meinier',
    'Puplinge',
    'Thonex',
    'Wettswil / Wettswil (Dorf)',
    'Itingen',
    'Arboldswil',
    'Montpreveyres',
    'Zwillikon',
    'Hedingen',
    'Allenwinden',
    'Adlikon',
    'Dorf',
    'Langwiesen',
    'Schollenberg',
    'Kleinandelfingen',
    'Oberstammheim',
    'Truttikon',
    'Waltalingen',
    'Freienstein',
    'Hochfelden',
    'Hoeri',
    'Lufingen',
    'Riet',
    'Alt-Bachs',
    'Boppelsen',
    'Neerach',
    'Niederhasli',
    'Oberhasli / Oberhasli (Dorf)',
    'Schleinikon',
    'Adetswil',
    'Brach',
    'Widenswil',
    'Bertschikon',
    'Unter-Ottikon',
    'Hirzel',
    'Gattikon',
    'Forch (suedl. Teil)',
    'Zollikerberg',
    'Rueti',
    'Winterberg',
    'Grafstal',
    'Tagelswangen',
    'Rumlikon',
    'Hinteregg',
    'Esslingen',
    'Benglen',
    'Binz',
    'Werrikon',
    'Wermatswil',
    'Gutenswil',
    'Gundetswil',
    'Daettlikon',
    'Dinhard',
    'Dorf Aesch',
    'Schmidrueti',
    'Rikon / Rikon (Dorfkern)',
    'Schwerzenbach',
    'Steinmaur',
    'Weiach',
    'Daenikon',
    'Niederglatt',
    'Ebmatingen',
    'Geroldswil',
    'Wolfhausen',
    'Seuzach Dorf',
    'Elsau-Raeterschen',
    'Sennhof',
    'Ittigen',
    'Anzere',
    'Crans-Montana',
    'Bettmeralp',
    'Gebertingen',
    'Cademario',
    'Agarone',
    'Breganzona',
    'Magliaso',
    'Rottenschwil',
    'Oulens-sous-Echallens',
    'Massongex',
    'Muriaux',
    'Chavannes de Bogis',
    'Les Paccots',
    'Origlio',
    'Vezia',
    'Vufflens-le-Chateau',
    'Ausserberg',
    'Wienacht-Tobel',
    'Étoy',
    'Montezillon',
    'Morlon',
    'Thal',
    'Preverenges',
    'Ipsach',
    'Langnau am Albis',
    'Safnern',
    'Frinvillier',
    'Langendorf',
    'Niederwil',
    'Hubersdorf',
    'Bellach',
    'Etziken',
    'Lommiswil',
    'Horriwil',
    'Halten',
    'Recherswil',
    'Ammansegg',
    'Buchegg',
    'Inkwil',
    'Niederoenz',
    'Nennigkofen',
    'Bolken',
    'Wanzwil',
    'Boningen',
    'Bettenhausen',
    'Bleienbach',
    'Obersteckholz',
    'Glashuetten',
    'Rumisberg',
    'Kestenholz',
    'Neuendorf',
    'Haerkingen',
    'Cologny',
    'Conches',
    'Troinex',
    'Lancy',
    'Perly-Certoux',
    'Le Lignon',
    'Carona',
    'Duggingen',
    'Haefelfingen',
    'Buckten',
    'Masein',
    'Grandvaux',
    'Tolochenaz',
    'Lussy-sur-Morges',
    'Champlan',
    'Grancia',
    'Paradiso',
    'Chernex',
    'Clarens',
    'Villa Luganese',
    'Orselina',
    'Brione sopra Minusio',
    'Arnegg',
    'Fuerigen',
    'Merlischachen',
    'Muralto',
    'Sant Antonino',
    'Muenchenwiler',
    'Staad',
    'Haute Nendaz',
    'Les Collons',
    'Valbella',
    'Stetten',
    'Bargen',
    'Loehningen',
    'Susten',
    'Grens',
    'Muntelier',
    'Jongny',
    'Flueh',
    'Baettwil',
    'Witterswil',
    'Biel-Benken',
    'Wallbach',
    'Zeiningen',
    'Sisseln',
    'Obermumpf',
    'Schupfart',
    'Hellikon',
    'Ottoberg',
    'Bergdietikon',
    'Jaberg',
    'Herbligen',
    'Wohlen bei Bern',
    'Herrenschwanden',
    'Glattpark',
    'Thunstetten',
    'Murist',
    'Givisiez',
    'Gletterens',
    'Kleindoettingen',
    'Dallikon',
    'Cresuz',
    'Hausen',
    'Rietheim',
    'Schneisingen',
    'Leuggern',
    'Fisibach',
    'Baldingen',
    'Clarmont',
    'Rorschacherberg',
    'Buetschwil',
    'Farvagny',
    'Golaten',
    'Veyras',
    'Montmollin',
    'Kaenerkinden',
    'Baldegg',
    'Oberurnen',
    'Fruthwilen',
    'Hasliberg-Reuti',
    'Oberarth',
    'Lupfig',
    'Birrhard',
    'Maegenwil',
    'Cuarnens',
    'Wilen b. Wil',
    'Montlingen',
    'Schliern',
    'Davos Platz',
    'Agriswil',
    'Algetshausen',
    'Anglikon',
    'Azmoos',
    'Bennau',
    'Berlens',
    'Bernhardzell',
    'Bichwil',
    'Billens',
    'Bonau',
    'Brent',
    'Buchackern',
    'Busslingen',
    'Buttikon',
    'Chailly',
    'Collex',
    'Corpataux',
    'Corzoneso',
    'Courtaman',
    'Cousset',
    'Daillon',
    'Davesco',
    'Detligen',
    'Dettighofen',
    'Dieterswil',
    'Donzhausen',
    'Drone',
    'Dussnang',
    'Engelburg',
    'Enggistein',
    'Engishofen',
    'Ennetbuehl',
    'Epagny',
    'Ermenswil',
    'Ettenhausen',
    'Flanthey',
    'Fruemsen',
    'Gaehwil',
    'Gerolfingen',
    'Golino',
    'Gross',
    'Gruenen',
    'Gruenenmatt',
    'Gumefens',
    'Guntershausen',
    'Gysenstein',
    'Hagendorn',
    'Happerswil',
    'Heldswil',
    'Hettenschwil',
    'Hettiswil',
    'Heubach',
    'Hosenruck',
    'Hugelshofen',
    'Illarsaz',
    'Innerberg',
    'Istighofen',
    'Kaegiswil',
    'Kaltbach',
    'Kleingurmels',
    'Kriessern',
    'Kulmerau',
    'Kuemmertshausen',
    'Landschlacht',
    'Lanzenneunforn',
    'Le Fuet',
    'Le Muids',
    'Lentigny',
    'Leontica',
    'Les Evouettes',
    'Levron',
    'Lobsigen',
    'Loemmenschwil',
    'Lossy',
    'Luechingen',
    'Lugnorre',
    'Lueterkofen',
    'Lueterswil',
    'Mattwil',
    'Middes',
    'Mission',
    'Montagny-les-Monts',
    'Montbrelloz',
    'Montet',
    'Muehlethal',
    'Murzelen',
    'Neuwilen',
    'Niederneunforn',
    'Niederteufen',
    'Niederwangen',
    'Nuglar',
    'Oberrindal',
    'Ortschwaben',
    'Pazzallo',
    'Plons',
    'Pont-de-la-Morge',
    'Posieux',
    'Pringy',
    'Promasens',
    'Rechy',
    'Reidermoos',
    'Riedtwil',
    'Riken',
    'Rose',
    'Rossrueti',
    'Rothenhausen',
    'Rueegsauschachen',
    'Rueegsbach',
    'Ruefenacht',
    'Salavaux',
    'Salorino',
    'Sarreyer',
    'Scharnachtal',
    'Schocherswil',
    'Schwarzenbach',
    'Signy',
    'Sonterswil',
    'Starrkirch',
    'Steinebrunn',
    'St. Margarethen',
    'Sutz',
    'Tennwil',
    'Trachslau',
    'Triboltingen',
    'Tscherlach',
    'Utzigen',
    'Vaglio',
    'Versegeres',
    'Villargiroud',
    'Villarimboud',
    'Wagen',
    'Walde',
    'Wallenwil',
    'Weier im Emmental',
    'Weite',
    'Wiezikon',
    'Wilihof',
    'Willerzell',
    'Zueberwangen',
    'Blessens',
    'Bouloz',
    'Choex',
    'Ebnet',
    'Edlibach',
    'Esmonts',
    'Estavayer-le-Gibloux',
    'Fiaugeres',
    'Figino',
    'Gontenbad',
    'Heinrichswil',
    'Helgisried',
    'Hinterfultigen',
    'Hofstatt',
    'Jouxtens',
    'Lanzenhaeusern',
    'Libingen',
    'Lovens',
    'Mittelhaeusern',
    'Mollie-Margot',
    'Nierlet-les-Bois',
    'Oberscherli',
    'Portels',
    'Progens',
    'Uetliburg',
    "Vers-l'Eglise",
    'Vesin',
    'Villaraboud',
    'Villarbeney',
    'Villarlod',
    'Villars',
    'Weingarten',
    'Wilen',
    'Winistorf',
    'Curio',
    'Lamone',
    'Les Avanchets',
    'La Rippe',
    'Oberaegeri',
    'Romoos',
    'Rivaz',
    'Villiers',
    'Venthone',
    'Hunzenschwil',
    'Grub',
    'Ftan',
    'Hersberg',
    'Dierikon',
    'Villarepos',
    'Bedano',
    'Biberstein',
    'Muzzano',
    'Lengwil',
    'Cavagnago',
    'Chevilly',
    'Pompaples',
    'Vuarrens',
    'Bischofszell',
    'Coldrerio',
    'Mathod',
    'Dittingen',
    'Genthod',
    'Dommartin',
    'Villars-Tiercelin',
    'Les Cullayes',
    'La Conversion',
    'Grand-Lancy',
    'Vessy',
    'Les Bioux',
    'Sassel',
    'Combremont-le-Grand',
    'Vallamand',
    'Les Monts-de-Corsier',
    'Ravoire',
    'St-Severin',
    'Areuse',
    'Marin-Epagnier',
    'Le Cret-du-Locle',
    'Reclere',
    'Heimisbach',
    'Oberfrittenbach',
    'Steg-Gampel',
    'Mollens',
    'Noes',
    'Bruderholz',
    'Liesberg Dorf',
    'Guensberg',
    'Erlinsbach AG',
    'Rombach',
    'Umiken',
    'Oberrohrdorf',
    'Benzenschwil',
    'Obernau',
    'Huenenberg See',
    'Riazzino',
    'Rivera TI',
    'Camignolo',
    'Torricella',
    'S. Pietro',
    'Rancate',
    'Tremona',
    'Corteglia',
    'Pambio-Noranco',
    'Barbengo',
    'Gentilino',
    'Ponte Capriasca',
    'Ruvigliana',
    'Purasca',
    "Cassina d'Agno",
    'Molinazzo di Monteggio',
    'Termine',
    'Tannay',
    'Salenstein',
    'St. Margrethenberg',
    'Balterswil',
    'Sitterdorf',
    'Mitloedi',
    'Horgenberg',
    'Walenstadtberg',
    'Flumserberg Bergheim',
    'Schwende District',
    'Lustmuehle',
    'Steinach',
    'Reute',
    'Wolfhalden',
    'Widnau',
    'Hinterforst',
    'Bussnang',
    'Dreien',
    'Ulisbach',
    'Kirchleerau',
    'Matran',
    'Sonnental',
    'Tuebach',
    'Olsberg',
    'Herznach',
    'Hirschthal',
    'Holziken',
    'Reitnau',
    'Oberhof',
    'Oberflachs',
    'Riniken',
    'Boezberg',
    'Ruefenach',
    'Remigen',
    'Moenthal',
    'Muelligen',
    'Scherz',
    'Ennetbaden',
    'Freienwil',
    'Fislisbach',
    'Bellikon',
    'Brunegg',
    'Taegerig',
    'Buettikon',
    'Zufikon',
    'Boswil',
    'Besenbueren',
    'Zetzwil',
    'Islisberg',
    'Denges',
    'Échandens',
    'Lonay',
    'Villars-Ste-Croix',
    'Boussens',
    'Sullens',
    'Étagnières',
    'Bettens',
    'Ogens',
    'Ferlens',
    'Essertes',
    'Carrouge',
    'Vulliens',
    'Chenaux',
    'Bremblens',
    'Chigny',
    'Denens',
    'Severy',
    'Buchillon',
    'Montherod',
    'Lavigny',
    'Luins',
    'St-Oyens',
    'Dully',
    'Choulex',
    'Bassins',
    'Laconnex',
    'Commugny',
    'Mies',
    'Vufflens-la-Ville',
    'Éclépens',
    'Premier',
    'Juriens',
    'Bretonnieres',
    'Sergey',
    'Lignerolle',
    'Eclagnens',
    'Cuarny',
    'Pomy',
    'Prevondavaux',
    'Molondin',
    'Grandevent',
    'Mutrux',
    'Chamblon',
    'Font',
    'Chatillon',
    'Chables',
    'Nuvilly',
    'Vucherens',
    'Henniez',
    'Seigneux',
    'Fetigny',
    'Rueyres-les-Pres',
    'Missy',
    'Montmagny',
    'Constantine',
    'Oron-le-Chatel',
    'Chatillens',
    'Bossonnens',
    'Remaufens',
    'Le Paquier-Montbarry',
    'Auboranges',
    'Vuarmarens',
    'Senedes',
    'St. Silvester',
    'Chenens',
    'Corserey',
    'Granges-Paccot',
    'Corseaux',
    'Chessel',
    'Rennaz',
    'Verossaz',
    'Les Verines (Chamoson)',
    'Uvrier',
    'Mayens-de-la-Zour (Saviese)',
    'Salins',
    'Pravidondaz (Salins)',
    'Bole',
    'Chambrelien',
    'Fresens',
    'Fontaines',
    'Les Enfers',
    'Schwadernau',
    'Studen',
    'Aegerten',
    'Port',
    'Jens',
    'Luescherz',
    'Finsterhennen',
    'Cortebert',
    'Cormoret',
    'Pontenet',
    'Develier',
    'Bourrignon',
    'Courchapoix',
    'Rossemaison',
    'Soulce',
    'Lugnez',
    'Schlosswil',
    'Kaufdorf',
    'Rizenbach',
    'Gempenach',
    'Tschugg',
    'Vinelz',
    'Ruppoldsried',
    'Bueetigen',
    'Epsach',
    'Hermrigen',
    'Meyriez',
    'Dotzigen',
    'Moosseedorf',
    'Iffwil',
    'Etzelkofen',
    'Grafenried',
    'Limpach',
    'Mattstetten',
    'Bollodingen',
    'Aefligen',
    'Niederhuenigen',
    'Haeutligen',
    'Oberthal',
    'Mirchel',
    'Pohlern',
    'Loc',
    'Nenzlingen',
    'Meltingen',
    'Grindel',
    'Giebenach',
    'Ziefen',
    'Lupsingen',
    'Bennwil',
    'Ramlinsburg',
    'Thuernen',
    'Diepflingen',
    'Nusshof',
    'Boeckten',
    'Rothenfluh',
    'Kienberg',
    'Anwil',
    'Farnern',
    'Winznau',
    'Ruetschelen',
    'Rohrbachgraben',
    'Gretzenbach',
    'Gisikon',
    'Fischbach',
    'Grossdietwil',
    'Altbueron',
    'Mehlsecken',
    'Roggliswil',
    'Ermensee',
    'Zugerberg',
    'Neuheim',
    'Obbuergen',
    'Cavigliano',
    'Pollegio',
    'Bruzella',
    'Porza',
    'Sala Capriasca',
    'Vernate',
    'Almens',
    'Dozwil',
    'Galgenen',
    'Schweizersholz',
    'Bettwiesen',
    'Tenniken',
    'Petit Lancy',
    'Gansingen',
    'Aetigkofen',
    'Niederstocken',
    'Misery',
    'Meikirch',
    'Duillier',
    'Vuisternens-devant-Romont',
    'Echichens',
    'Remetschwil',
    'Tentlingen',
    'Egolzwil',
    'Walperswil',
    'Cureglia',
    'Ursy',
    'Dintikon',
    'Rheinau',
    'Neuenegg',
    'Aire',
    'Unterentfelden',
    'Collonge-Bellerive',
    'Dulliken',
    'Delley',
    'Merzligen',
    'Noville',
    'Cronay',
    'Chavannes-des-Bois',
    'Aclens',
    'Bardonnex',
    'Corcelles-sur-Chavornay',
    'Aetingen',
    'Bibern SO',
    'Villars-sous-Yens',
    'Vaumarcus',
    'Ennetmoos',
    'Schlierbach',
    'Elfingen',
    'Penthaz',
    'Gudo',
    'Corban',
    'Boecourt',
    'Salvenach',
    'Corsier-sur-Vevey',
    'Chamby',
    'Ponte',
    'Ebersecken',
    'Vaux-sur-Morges',
    'Alpnach Dorf',
    'Wilen bei Wollerau',
    'Bussy-sur-Moudon',
    'Homberg',
    'Wettswil',
    'Watt',
    'Oberkulm',
    'Savosa',
    'Kallern',
    'Aeugst am Albis',
    'Cormondreche',
    'Fischbach-Goeslikon',
    'Volleges',
    'Le Mouret',
    'Servion',
    'Cheserex',
    'Bedigliora',
    'Uebeschi',
    'Kleinboesingen',
    'Cheseaux-Noreaz',
    'Rochefort',
    'Rueegsau',
    'Auswil',
    'Stels',
    'Bleiken',
    'Aeschlen',
  ],
  Netherlands: [
    'Zwolle',
    'Zwijndrecht',
    'Zweins',
    'Zweeloo',
    'Zwartsluis',
    'Zwartewaal',
    'Zwartemeer',
    'Zwartebroek',
    'Zwanenburg',
    'Zwammerdam',
    'Zwagerbosch',
    'Zwaanshoek',
    'De Westereen',
    'Zwaagdijk-Oost',
    'Zwaag',
    'Zutphen',
    'Zurich',
    'Zundert',
    'Zuilichem',
    'Zuidzande',
    'Zuidwolde',
    'Zuidvelde',
    'Zuidveen',
    'Zuid-Scharwoude',
    'Zuidoostbeemster',
    'Zuidlaren',
    'Zuidland',
    'Zuidlaarderveen',
    'Zuidhorn',
    'Zuiderwoude',
    'Zuidbroek',
    'Zuid-Beijerland',
    'Zoutkamp',
    'Zoutelande',
    'Zorgvlied',
    'Zonnemaire',
    'Zoeterwoude-Dorp',
    'Zoetermeer',
    'Zoelmond',
    'Zoelen',
    'Zijldijk',
    'Zijdewind',
    'Zijderveld',
    'Zieuwent',
    'Zierikzee',
    'Zevenhuizen',
    'Zevenhoven',
    'Zevenbergschen Hoek',
    'Zevenbergen',
    'Zevenaar',
    'Zetten',
    'Zennewijnen',
    'Zelhem',
    'Zeist',
    'Zeyen',
    'Zegveld',
    'Zegge',
    'Zeewolde',
    'Zeerijp',
    'Zeeland',
    'Zeddam',
    'Zandvoort',
    'Zandpol',
    'Zaltbommel',
    'Zalk',
    'Zaandijk',
    'Zaandam',
    'Zaamslag',
    'Yerseke',
    'Yde',
    'Wouw',
    'Walterswald',
    'Woudrichem',
    'Woudenberg',
    'Woubrugge',
    'Wormerveer',
    'Wormer',
    'Workum',
    'Woold',
    'Wommels',
    'Wolvega',
    'Woltersum',
    'Wolphaartsdijk',
    'Wolfheze',
    'Woldendorp',
    'Wognum',
    'Woerdense Verlaat',
    'Woerden',
    'Woensdrecht',
    'Witteveen',
    'Witte Paarden',
    'Wittem',
    'Wittelte',
    'Witmarsum',
    'Witharen',
    'Wissenkerke',
    'Wirdum',
    'Winterswijk',
    'Wintelre',
    'Winsum',
    'Winssen',
    'Winschoten',
    'Winkel',
    'Kiel-Windeweer',
    'Wilsum',
    'Wilp',
    'Wilnis',
    'Willemstad',
    'Wilhelminaoord',
    'Wildervank',
    'Wilbertoord',
    'Wijtgaard',
    'Wijster',
    'Wijnjewoude',
    'Wijnandsrade',
    'Wijnaldum',
    'Wijlre',
    'Wijk bij Duurstede',
    'Wijk aan Zee',
    'Wijhe',
    'Wijdewormer',
    'Wijdenes',
    'Wijckel',
    'Wijchen',
    'Wiesel',
    'Wierum',
    'Wieringerwerf',
    'Wieringerwaard',
    'Wierden',
    'Wichmond',
    'Wezuperbrug',
    'Wezup',
    'Wezep',
    'Weurt',
    'Weteringbrug',
    'Westzaan',
    'Westwoud',
    'West-Terschelling',
    'Westmaas',
    'West-Knollendam',
    'Westkapelle',
    'Westervoort',
    'Westervelde',
    'Westernieland',
    'Westerlee',
    'Westerland',
    'Westerhoven',
    'Westerhaar-Vriezenveensewijk',
    'Westergeest',
    'Westerbroek',
    'Westerbork',
    'Blokker',
    'Westerbeek',
    'Westendorp',
    'Westdorpe',
    'Westdorp',
    'Westbroek',
    'Westbeemster',
    'Wessem',
    'Wesepe',
    'Wervershoof',
    'Wernhout',
    'Werkhoven',
    'Werkendam',
    'Wenum',
    'Wemeldinge',
    'Welsum',
    'Wjelsryp',
    'Wellseind',
    'Wellerlooi',
    'Well',
    'Wekerom',
    'Weiteveen',
    'Wehl',
    'Wehe-Den Hoorn',
    'Weesp',
    'Weert',
    'Weerselo',
    'Waterlandkerkje',
    'Wateringen',
    'Watergang',
    'Wateren',
    'Wassenaar',
    'Waspik',
    'Waskemeer',
    'Warten',
    'Warnsveld',
    'Warns',
    'Warmond',
    'Warmenhuizen',
    'Wergea',
    'Warfstermolen',
    'Warfhuizen',
    'Warffum',
    'Warder',
    'Wapserveen',
    'Wapse',
    'Wapenveld',
    'Wanssum',
    'Wanroij',
    'Wanneperveen',
    'Wamel',
    'Walsoorden',
    'Wageningen',
    'Wagenborgen',
    'Wagenberg',
    'Wadenoijen',
    'Waddinxveen',
    'Waarland',
    'Waarder',
    'Waardenburg',
    'Waarde',
    'Waalwijk',
    'Waalre',
    'Vuren',
    'Vught',
    'Vrouwenpolder',
    'Vrouwenparochie',
    'Vrouwenakker',
    'Vroomshoop',
    'Vriezenveen',
    'Vries',
    'Vreeland',
    'Vredepeel',
    'Vragender',
    'Vortum-Mullem',
    'Vorstenbosch',
    'Vorden',
    'Vorchten',
    'Voorthuizen',
    'Voorst',
    'Voorschoten',
    'Voorhout',
    'Voorburg',
    'Vollenhove',
    'Volkel',
    'Volendam',
    'Volckerdorp',
    'Vogelwaarde',
    'Vogelenzang',
    'Voerendaal',
    'Vlodrop',
    'Flushing',
    'Vlijmen',
    'Vlierden',
    'Vlieland',
    'Vleuten',
    'Vledderveen',
    'Vledder',
    'Vlagtwedde',
    'Vlaardingen',
    'Vinkeveen',
    'Vinkel',
    'Vijlen',
    'Vijfhuizen',
    'Vierpolders',
    'Vierlingsbeek',
    'Vierhuizen',
    'Vierhouten',
    'Vierakker',
    'Vianen',
    'Veulen',
    'Vessem',
    'Ven-Zelderheide',
    'Venray',
    'Venlo',
    'Venhuizen',
    'Venhorst',
    'Velsen',
    'Velsen-Noord',
    'Velp',
    'Veldhoven',
    'Velden',
    'Velddriel',
    'Veghel',
    'Veessen',
    'Veere',
    'Feanwaldsterwal',
    'Feanwalden',
    'Veenoord',
    'Veeningen',
    'Veenhuizen',
    'Veenendaal',
    'Veendam',
    'Veen',
    'Veelerveen',
    'Vasse',
    'Varsseveld',
    'Varsselder',
    'Varik',
    'Valthermond',
    'Valkenswaard',
    'Valkenburg',
    'Valburg',
    'Vaassen',
    'Vaals',
    'Utrecht',
    'Usquert',
    'Ursem',
    'Urmond',
    'Urk',
    'Ureterp',
    'Ulvenhout',
    'Ulrum',
    'Ulicoten',
    'Ulft',
    'Ulestraten',
    'Uitwijk',
    'Uithuizermeeden',
    'Uithuizen',
    'Uithoorn',
    'Uitgeest',
    'Ugchelen',
    'Uffelte',
    'Udenhout',
    'Uden',
    'Uddel',
    'Ubbergen',
    'Ubbena',
    'Tzummarum',
    'Tzum',
    "'t Zandt",
    "'t Zand",
    'Tynaarlo',
    'Twijzelerheide',
    'Twijzel',
    'Twello',
    'Tweede Valthermond',
    'Tweede Exloermond',
    "Tull en 't Waal",
    "'t Veld",
    'Tuk',
    'Tuitjenhorn',
    'Tuil',
    'Tubbergen',
    'Triemen',
    'Tricht',
    'Tonden',
    'Tollebeek',
    'Tolkamer',
    'Toldijk',
    'Tolbert',
    'Tjuchem',
    'Tinte',
    'Tilligte',
    'Tilburg',
    'Tijnje',
    'Tytsjerk',
    'Tienray',
    'Tienhoven',
    'Tiel',
    'Thorn',
    'Tholen',
    "'t Harde",
    'Teuge',
    'Teteringen',
    'Tersoal',
    'Terwolde',
    'Terschuur',
    'Terneuzen',
    'Ternaard',
    'Terkaple',
    'Ter Idzard',
    'Terherne',
    'Terheijden',
    'Ter Heijde',
    'Terborg',
    'Terblijt',
    'Terband',
    'Ter Apelkanaal',
    'Ter Apel',
    'Ter Aar',
    'Ten Post',
    'Ten Boer',
    'Tegelen',
    'Swolgen',
    'Swifterbant',
    'Sweikhuizen',
    'Swalmen',
    'Susteren',
    'Surhuisterveen',
    'Sumar',
    'Stuifzand',
    'Stroobos',
    'Stroe',
    'Strijensas',
    'Strijen',
    'Streefkerk',
    'Stramproy',
    'Stoutenburg',
    'Stolwijk',
    'Stokkum',
    'Stiens',
    'Stevensweert',
    'Stevensbeek',
    'Sterksel',
    'Stellendam',
    'Stein',
    'Steijl',
    'Steggerda',
    'Stegeren',
    'Steenwijkerwold',
    'Steenwijk',
    'Steensel',
    'Steenenkamer',
    'Steenderen',
    'Steenbergen',
    'Stedum',
    'Stavoren',
    'Stavenisse',
    'Staphorst',
    'Standdaarbuiten',
    'Stampersgat',
    'Stadskanaal',
    "Stad aan 't Haringvliet",
    'Sprundel',
    'Sprang',
    'Spijkerboor',
    'Spijkenisse',
    'Spijk',
    'Spierdijk',
    'Spier',
    'Speuld',
    'Spaubeek',
    'Spankeren',
    'Spanga',
    'Spanbroek',
    'Spakenburg',
    'Spaarndam',
    'Son',
    'Sommelsdijk',
    'Someren-Eind',
    'Someren',
    'Soesterberg',
    'Soest',
    'Soerendonk',
    'Snelrewaard',
    'Sneek',
    'Smilde',
    'Smeulveen',
    'Smakt',
    'Sluiskil',
    'Sluis',
    'Sloten',
    'Slootdorp',
    'Slochteren',
    'Slijkenburg',
    'Sliedrech',
    'Sleeuwijk',
    'Sleen',
    'Slagharen',
    'Sittard',
    'Sirjansland',
    'Sint Willebrord',
    'Sint Philipsland',
    'Sint Pancras',
    'Sint-Oedenrode',
    'Sint Odilienberg',
    'Sint Nicolaasga',
    'Sint-Michielsgestel',
    'Sint Maartensvlotbrug',
    'Sint-Maartensdijk',
    'Sint Maartensbrug',
    'Sint Maarten',
    'Sint Kruis',
    'Sint Joost',
    'Sint Jansteen',
    'Sint Jansklooster',
    'Sint Jacobiparochie',
    'Sint Hubert',
    'Sint Anthonis',
    'Sint Annaparochie',
    'Sint Annaland',
    'Sint Agatha',
    'Sinderen',
    'Simpelveld',
    'Simonshaven',
    'Silvolde',
    'Sibrandabuorren',
    'Sijbekarspel',
    'Siegerswoude',
    'Siebengewald',
    'Siddeburen',
    'Sibculo',
    "'s-Hertogenbosch",
    "'s-Heerenhoek",
    "'s-Heerenberg",
    "'s-Gravenzande",
    "'s-Gravenpolder",
    "'s Gravenmoer",
    'The Hague',
    "'s-Gravendeel",
    "'s-Graveland",
    'Sexbierum',
    'Sevenum',
    'Serooskerke',
    'Sellingen',
    'Sebaldeburen',
    'Schrapveen',
    'Schraard',
    'Schouwerzijl',
    'Schoorl',
    'Schoonrewoerd',
    'Schoonoord',
    'Schoonloo',
    'Schoonhoven',
    'Schoonebeek',
    'Schoondijke',
    'Schipluiden',
    'Schiphol-Rijk',
    'Schinveld',
    'Schin op Geul',
    'Schinnen',
    'Schimmert',
    'Schildwolde',
    'Schijndel',
    'Schijf',
    'Schiermonnikoog',
    'Schiedam',
    'Scheveningen',
    'Scheulder',
    'Schettens',
    'Scherpenzeel',
    'Scherpenisse',
    'Schelluinen',
    'Schellinkhout',
    'Scheerwolde',
    'Scheemda',
    'Scharnegoutum',
    'Scharendijke',
    'Schalkwijk',
    'Schalkhaar',
    'Schaijk',
    'Schagerbrug',
    'Schagen',
    'Sauwerd',
    'Sas van Gent',
    'Sassenheim',
    'Sappemeer',
    'Santpoort-Zuid',
    'Santpoort-Noord',
    'Saaxumhuizen',
    'Saasveld',
    'Ruurlo',
    'Rutten',
    'Rumpt',
    'Ruinerwold',
    'Ruinen',
    'Rucphen',
    'Rozendaal',
    'Rozenburg',
    'Rouveen',
    'Rottum',
    'Rottevalle',
    'Rotterdam',
    'Rotsterhaule',
    'Roswinkel',
    'Rossum',
    'Rosmalen',
    'Roosteren',
    'Roosendaal',
    'Reduzum',
    'Readtsjerk',
    'Roodeschool',
    'Rome',
    'Rolde',
    'Rohel',
    'Roggel',
    'Rogat',
    'Roermond',
    'Roelofarendsveen',
    'Rodenrijs',
    'Roden',
    'Rockanje',
    'Rixtel',
    'De Rips',
    'Rinsumageast',
    'Rilland',
    'Rijswijk',
    'Rijssen',
    'Rijsenhout',
    'Rijsbergen',
    'Rijs',
    'Rijpwetering',
    'Ryptsjerk',
    'Rijnsburg',
    'Rijnsaterwoude',
    'Rijkevoort',
    'Rijen',
    'Rijckholt',
    'Rietmolen',
    'Riethoven',
    'Riel',
    'Ried',
    'Ridderkerk',
    'Rhoon',
    'Rhenoy',
    'Rhenen',
    'Rheeze',
    'Rheden',
    'Reuver',
    'Reutum',
    'Reusel',
    'Retranchement',
    'Renswoude',
    'Renkum',
    'Renesse',
    'Rekken',
    'Reitsum',
    'Reijmerstok',
    'Reeuwijk',
    'Reek',
    'Ravenstein',
    'Raerd',
    'Rasquert',
    'Ransdaal',
    'Randwijk',
    'Radewijk',
    'Raamsdonksveer',
    'Raamsdonk',
    'Raalte',
    'Puttershoek',
    'Putten',
    'Putte',
    'Puth',
    'Purmerend',
    'Puiflijk',
    'Prinsenbeek',
    'Posterholt',
    'Posterenk',
    'Poppenwier',
    'Poortvliet',
    'Poortugaal',
    'Polsbroek',
    'Poeldijk',
    'Poederoijen',
    'Plasmolen',
    'Pingjum',
    'Pijnacker',
    'Pietersbierum',
    'Pieterburen',
    'Piershil',
    'Philippine',
    'Petten',
    'Pesse',
    'Pernis',
    'Peize',
    'Peins',
    'Peest',
    'Paterswolde',
    'Parrega',
    'Papenvoort',
    'Papenhoven',
    'Papendrecht',
    'Papekop',
    'Panningen',
    'Pannerden',
    'Panheel',
    'Palemig',
    'Paasloo',
    'Ovezande',
    'Overveen',
    'Overslag',
    'Overloon',
    'Overlangel',
    'Overdinkel',
    'Overberg',
    'Overasselt',
    'Ouwsterhaule',
    'Ouwerkerk',
    'Oud-Zuilen',
    'Oudwoude',
    'Oud-Vossemeer',
    'Oudorp',
    'Oud Ootmarsum',
    'Aldtsjerk',
    'Oudkarspel',
    'Oudheusden',
    'Oud Gastel',
    'Oude Wetering',
    'Oudewater',
    'Oude-Tonge',
    'Oudesluis',
    'Oudeschild',
    'Ouderkerk aan den IJssel',
    'Ouderkerk aan de Amstel',
    'Oude Pekela',
    'Oudenhoorn',
    'Oudendijk',
    'Oudenbosch',
    'Oudemolen',
    'Oudemirdum',
    'Oude Meer',
    'Alde Leie',
    'Oudelande',
    'Oudehorne',
    'Oudehaske',
    'Oudega',
    'Oude Bildtzijl',
    'Ouddorp',
    'Oud-Beijerland',
    'Oud-Alblas',
    'Oud Ade',
    'Ottoland',
    'Ottersum',
    'Otterlo',
    'Ossenzijl',
    'Ossenisse',
    'Ossendrecht',
    'Oss',
    'Ospel',
    'Orvelte',
    'Oranjewoud',
    'Oranje',
    'Opperduit',
    'Opperdoes',
    'Opmeer',
    'Oploo',
    'Opijnen',
    'Opheusden',
    'Ophemert',
    'Opende',
    'Opeinde',
    'Ootmarsum',
    'Oostzaan',
    'Oostwoud',
    'Oostwold',
    'Oostvoorne',
    'Oost-Vlieland',
    'Oost-Souburg',
    'Oostrum',
    'Oostknollendam',
    'Oostkapelle',
    'Oosthuizen',
    'Oosterzee',
    'Oosterwolde',
    'Oosterwijk',
    'Oosterstreek',
    'Eastermar',
    'Oosterlittens',
    'Oosterleek',
    'Oosterland',
    'Oosterhout',
    'Oosterhesselen',
    'Oosterend',
    'Oosterblokker',
    'Oosterbierum',
    'Oosterbeek',
    'Oostelbeers',
    'Oosteind',
    'Oostdijk',
    'Oostburg',
    'Ooltgensplaat',
    'Ooij',
    'Onstwedde',
    'Ommeren',
    'Ommen',
    'Ommel',
    'Olterterp',
    'Olst',
    'Olland',
    'Oldenzaal',
    'Oldemarkt',
    'Oldekerk',
    'Oldehove',
    'Oldebroek',
    'Aldeboarn',
    'Oldeberkoop',
    'Olburgen',
    'Oisterwijk',
    'Oirschot',
    'Oirsbeek',
    'Oirlo',
    'Ohe en Laak',
    'Oentsjerk',
    'Oene',
    'Oegstgeest',
    'Oeffelt',
    'Odoornerveen',
    'Odoorn',
    'Odijk',
    'Ochten',
    'Obdam',
    'Obbicht',
    'Nutter',
    'Nuth',
    'Nunspeet',
    'Nunhem',
    'Numansdorp',
    'Nuland',
    'Nuis',
    'Nuenen',
    'Norg',
    'Nootdorp',
    'Noordwolde',
    'Noordwijkerhout',
    'Noordwijk-Binnen',
    'Noord-Sleen',
    'Noordscheschut',
    'Noord-Scharwoude',
    'Noordlaren',
    'Noordhorn',
    'Noordgouwe',
    'Noorden',
    'Noordeloos',
    'Noordbroek',
    'Noardburgum',
    'Noordbeemster',
    'Noorbeek',
    'Nistelrode',
    'Nisse',
    'Nispen',
    'Nijverdal',
    'Nijnsel',
    'Nijmegen',
    'Nijland',
    'Nijkerkerveen',
    'Nijkerk',
    'Nijeveen',
    'Nijensleek',
    'Nijenheim',
    'Nijemirdum',
    'Nijeholtwolde',
    'Nijeholtpade',
    'Nijehaske',
    'Nijbroek',
    'Nij Beets',
    'Nigtevecht',
    'Niezijl',
    'Nieuw-Weerdinge',
    'Nieuw-Vossemeer',
    'Nieuw-Vennep',
    'Nieuwveen',
    'Nieuwstadt',
    'Nieuw-Schoonebeek',
    'Nieuwpoort',
    'Nieuwolda',
    'Nieuw-Namen',
    'Nieuwleusen',
    'Nieuw-Lekkerland',
    'Nieuwlande',
    'Nieuwland',
    'Nieuw- en Sint Joosland',
    'Nieuwkuijk',
    'Nieuwkoop',
    'Nieuw-Heeten',
    'Nieuwe Wetering',
    'Nieuwe-Tonge',
    'Bad Nieuweschans',
    'Nieuweroord',
    'Nieuwerkerk aan den IJssel',
    'Nieuwerkerk',
    'Nieuwerbrug aan den Rijn',
    'Nieuwe Pekela',
    'Nieuwe-Niedorp',
    'Nieuwendijk',
    'Nieuwehorne',
    'Nieuwegein',
    'Nieuwebrug',
    'Nieuwdorp',
    'Nieuw-Dordrecht',
    'Nieuw-Buinen',
    'Nieuw-Bergen',
    'Nieuw-Beijerland',
    'Nieuw-Beerta',
    'Nieuw-Balinge',
    'Nieuw-Amsterdam',
    'Nieuwaal',
    'Nietap',
    'Nibbixwoud',
    'Netterden',
    'Nes',
    'Neerloon',
    'Neerkant',
    'Neeritter',
    'Neerijnen',
    'Andel',
    'Neer',
    'Neede',
    'Nederwetten',
    'Nederweert',
    'Nederlangbroek',
    'Nederhorst den Berg',
    'Nederhemert',
    'Nederasselt',
    'Nagele',
    'Naarden',
    'Naaldwijk',
    'Musselkanaal',
    'Mussel',
    'Muntendam',
    'Munstergeleen',
    'Munnekezijl',
    'Munnekeburen',
    'Muiderberg',
    'Muiden',
    'De Mortel',
    'Moordrecht',
    'Mookhoek',
    'Mook',
    'Montfort',
    'Montfoort',
    'Monster',
    'Monnickendam',
    'Molkwerum',
    'Molenschot',
    'Molenhoek',
    'Munein',
    'Molenaarsgraaf',
    'Moerstraten',
    'Moerkapelle',
    'Moergestel',
    'Moerdijk',
    'Mirns',
    'Minnertsga',
    'Milsbeek',
    'Millingen aan de Rijn',
    'Mill',
    'Milheeze',
    'Mildam',
    'Mijnsheerenland',
    'Mijdrecht',
    'Mierlo',
    'Midwoud',
    'Midsland',
    'Midlaren',
    'Middenmeer',
    'Middenbeemster',
    'Middelstum',
    'Middelie',
    'Middelharnis',
    'Middelburg',
    'Middelbeers',
    'Middelaar',
    'Mheer',
    'Meterik',
    'Meteren',
    'Merselo',
    'Merkelbeek',
    'Meppen',
    'Meppel',
    'Menaam',
    'Melissant',
    'Meliskerke',
    'Melick',
    'Melderslo',
    'Meijel',
    'Megen',
    'Megchelen',
    'Meeuwen',
    'Meerssen',
    'Meerlo',
    'Meerkerk',
    'Meedhuizen',
    'Meeden',
    'Medemblik',
    'Mechelen',
    'Maurik',
    'Marum',
    'Marsum',
    'Marrum',
    'Marknesse',
    'Markenbinnen',
    'Markelo',
    'Marienvelde',
    'Marienheem',
    'Mariaparochie',
    'Mariahout',
    'Mariaheide',
    'Margraten',
    'Mantgum',
    'Manderveen',
    'Malden',
    'Makkum',
    'Made',
    'Macharen',
    'Maastricht',
    'Maassluis',
    'Maasland',
    'Maashees',
    'Maasdijk',
    'Maasdam',
    'Maasbree',
    'Maasbracht',
    'Maasbommel',
    'Maartensdijk',
    'Maarssen',
    'Maarsbergen',
    'Maarn',
    'Maarheeze',
    'Luyksgestel',
    'Luttenberg',
    'Lutten',
    'Luttelgeest',
    'Lutjewinkel',
    'Lutjebroek',
    'Lunteren',
    'Luinjeberd',
    'Lucaswolde',
    'Lottum',
    'Losser',
    'Loppersum',
    'Lopikerkapel',
    'Lopik',
    'Loosbroek',
    'Loon op Zand',
    'Loo',
    'Lomm',
    'Loerbeek',
    'Loenga',
    'Loenersloot',
    'Loenen',
    'Lochem',
    'Lobith',
    'Lithoijen',
    'Lith',
    'Lisserbroek',
    'Lisse',
    'Lippenhuizen',
    'Linschoten',
    'Linne',
    'Linde',
    'Limmen',
    'Limbricht',
    'Lijnden',
    'Lieveren',
    'Lievelde',
    'Liessel',
    'Lieshout',
    'Lierop',
    'Lieren',
    'Lierderholthuis',
    'Lienden',
    'Liempde',
    'Lichtenvoorde',
    'Lexmond',
    'Lewedorp',
    'Leuvenheim',
    'Leutingewolde',
    'Leuth',
    'Leusden',
    'Leunen',
    'Lettele',
    'Lettelbert',
    'Lepelstraat',
    'Lent',
    'Lengel',
    'Lemmer',
    'Lemiers',
    'Lemelerveld',
    'Lemele',
    'Lelystad',
    'Lekkerkerk',
    'Leimuiden',
    'Leidschendam',
    'Leiderdorp',
    'Leiden',
    'Leeuwarden',
    'Leersum',
    'Leerdam',
    'Leerbroek',
    'Leens',
    'Leende',
    'Leek',
    'Ledeacker',
    'Lauwersoog',
    'Lattrop',
    'Lathum',
    'Laren',
    'Langweer',
    'Langezwaag',
    'Langeveen',
    'Langerak',
    'Langeraar',
    'Langenboom',
    'Langelille',
    'Langedijk',
    'Landsmeer',
    'Lambertschaag',
    'Lage Zwaluwe',
    'Lage-Vuursche',
    'Lage Mierde',
    'Laag Zuthem',
    'Laag-Soeren',
    'Kwintsheul',
    'Kwadijk',
    'Kwadendamme',
    'Kuinre',
    'Kudelstaart',
    'Kruisland',
    'Kruiningen',
    'Kronenberg',
    'Krommenie',
    'Krimpen aan den IJssel',
    'Krimpen aan de Lek',
    'Krewerd',
    'Kreileroord',
    'Kraggenburg',
    'Krabbendijke',
    'Koudum',
    'Koudekerke',
    'Koudekerk aan den Rijn',
    'Kortgene',
    'Kortenhoef',
    'Kortehemmen',
    'Kornhorn',
    'Kootwijkerbroek',
    'Kootwijk',
    'Kootstertille',
    'Koog aan de Zaan',
    'Koningslust',
    'Koningsbosch',
    'Kommerzijl',
    'Kollumerzwaag',
    'Kollum',
    'Kolhorn',
    'Kolham',
    'Koewacht',
    'Koekange',
    'Koedijk',
    'Kockengen',
    'Knegsel',
    'Klundert',
    'Kloosterzande',
    'Kloosterhaar',
    'Kloosterburen',
    'Kloetinge',
    'Klimmen',
    'Klijndijk',
    'Klazienaveen',
    'Klarenbeek',
    'Klaaswaal',
    'Kinderdijk',
    'Kilder',
    'Kesteren',
    'Kessel',
    'Kerkwijk',
    'Kerkrade',
    'Kerkdriel',
    'Marken',
    'Kerk-Avezaath',
    'Keldonk',
    'Keizersdijk',
    'Keijenborg',
    'Katwijk aan Zee',
    'Katwijk',
    'Kats',
    'Katlijk',
    'Kapellebrug',
    'Kapel-Avezaath',
    'Kantens',
    'Kamperveen',
    'Kamperland',
    'Kampen',
    'Kamerik',
    'Kaatsheuvel',
    'Kaag',
    'Julianadorp',
    'Joure',
    'Joppe',
    'Jonkersvaart',
    'Jonkersland',
    'Jellum',
    'Jabeek',
    'Jaarsveld',
    'Ittervoort',
    'Jirnsum',
    'Ingen',
    'Ilpendam',
    'IJzendoorn',
    'IJzendijke',
    'Ysselsteyn',
    'IJsselstein',
    'IJsselmuiden',
    'IJsselham',
    'Ysbrechtum',
    'IJmuiden',
    'IJlst',
    'IJhorst',
    'Hurwenen',
    'Hunsel',
    'Hummelo',
    'Hulten',
    'Hulst',
    'Hulshorst',
    'Hulsel',
    'Hulsberg',
    'Huizen',
    'Huis ter Heide',
    'Huissen',
    'Huijbergen',
    'Houtigehage',
    'Houten',
    'Horst',
    'Horssen',
    'Hoornsterzwaag',
    'Horn',
    'Hoornaar',
    'Hoorn',
    'Hoogwoud',
    'Hoogvliet',
    'Hoog Soeren',
    'Hoogmade',
    'Hooglanderveen',
    'Hoogland',
    'Hoog-Keppel',
    'Hoogkarspel',
    'Hooghalen',
    'Hooge Zwaluwe',
    'Hoogezand',
    'Hoogenweg',
    'Hoogeveen',
    'Hoogersmilde',
    'Hoogerheide',
    'Hooge Mierde',
    'Hoogeloon',
    'Hoge Hexel',
    'Hoogblokland',
    'Hoofdplaat',
    'Hoofddorp',
    'Honselersdijk',
    'Holwierde',
    'Holwerd',
    'Holtum',
    'Holtheme',
    'Holten',
    'Hollum',
    'Hollandscheveld',
    'Hollandsche Rading',
    'Holland',
    'Hoeven',
    'Hoevelaken',
    'Hoenzadriel',
    'Hoensbroek',
    'Hoenderloo',
    'Hoek van Holland',
    'Hoek',
    'Hoedekenskerke',
    'Hitzum',
    'Hippolytushoef',
    'Hilversumse Meent',
    'Hilversum',
    'Hilvarenbeek',
    'Hillegom',
    'Hijken',
    'Hierden',
    'Heythuysen',
    'Heusden',
    'Heumen',
    'Heukelum',
    'Heteren',
    'Herwijnen',
    'Herwen',
    'Herveld',
    'Hertme',
    'Herten',
    'Herpt',
    'Herpen',
    'Hernen',
    'Herkingen',
    'Herkenbosch',
    'Hensbroek',
    'Hennaard',
    'Hengstdijk',
    'Hengevelde',
    'Hengelo',
    'Hendrik-Ido-Ambacht',
    'Hemrik',
    'Hempens',
    'Hemelum',
    'Hem',
    'Helvoirt',
    'Helmond',
    'Hellum',
    'Hellouw',
    'Hellevoetsluis',
    'Hellendoorn',
    'Helenaveen',
    'Helden',
    'Hekendorp',
    'Hekelingen',
    'Heino',
    'Heinkensand',
    'Heinenoord',
    'Heiloo',
    'Heilig Landstichting',
    'Heikant',
    'Heijningen',
    'Heijenrade',
    'Heijen',
    'Heibloem',
    'Hegelsom',
    'Heeze',
    'Heeten',
    'Heeswijk-Dinther',
    'Heesch',
    'Heerlen',
    'Heerle',
    'Heerjansdam',
    'Heerhugowaard',
    'Heerewaarden',
    'Heerenveen',
    'Heerde',
    'Heenvliet',
    'Heemstede',
    'Heemskerk',
    'Heemserveen',
    'Heelweg',
    'Heelsum',
    'Heel',
    'Heeg',
    'Hedikhuizen',
    'Hedel',
    'Hazerswoude-Rijndijk',
    'Hazerswoude-Dorp',
    'Havelte',
    'Haulerwijk',
    'Haule',
    'Hattemerbroek',
    'Hattem',
    'Hasselt',
    'Haskerhorne',
    'Hartwerd',
    'Harskamp',
    'Harreveld',
    'Harmelen',
    'Harlingen',
    'Harkstede',
    'Harkema',
    'Harich',
    'Harfsen',
    'Haren',
    'Harderwijk',
    'Hardenberg',
    'Hurdegaryp',
    'Harbrinkhoek',
    'Haps',
    'Hapert',
    'Hantumeruitburen',
    'Hantum',
    'Hansweert',
    'Hank',
    'Handel',
    'Halsteren',
    'Hallum',
    'Halle',
    'Halfweg',
    'Haghorst',
    'Hagestein',
    'Haelen',
    'Haastrecht',
    'Haarsteeg',
    'Haarlo',
    'Haarlemmerliede',
    'Haarlem',
    'Haarle',
    'Haaren',
    'Haamstede',
    'Haalderen',
    'Haaksbergen',
    'Haaften',
    'Guttecoven',
    'Gulpen',
    'Grubbenvorst',
    'Grou',
    'Grootschermer',
    'Grootegast',
    'Grootebroek',
    'Groot-Ammers',
    'Groot-Agelo',
    'Gronsveld',
    'Groningen',
    'Grolloo',
    'Groessen',
    'Groesbeek',
    'Groenlo',
    'Groenekan',
    'Groede',
    'Grijpskerke',
    'Grijpskerk',
    'Griendtsveen',
    'Grevenbicht',
    'Grave',
    'Grathem',
    'Grashoek',
    'Gramsbergen',
    'Graft',
    'Grafhorst',
    'Graauw',
    'Goutum',
    'Goudswaard',
    'Gouderak',
    'Gouda',
    'Gortel',
    'Gorssel',
    'Gorredijk',
    'Gorinchem',
    'Goor',
    'Goirle',
    'Goes',
    'Goedereede',
    'De Glind',
    'Glimmen',
    'Glane',
    'Gilze',
    'Giethoorn',
    'Gieterveen',
    'Gieten',
    'Giessen-Oudekerk',
    'Giessenburg',
    'Giessen',
    'Giesbeek',
    'Gytsjerk',
    'Geulle',
    'Gerkesklooster',
    'Gennep',
    'Genemuiden',
    'Gendt',
    'Gendringen',
    'Genderen',
    'Gemonde',
    'Gemert',
    'Gelselaar',
    'Gellicum',
    'Geleen',
    'Geldrop',
    'Gelderswoude',
    'Geldermalsen',
    'Geffen',
    'Geesteren',
    'Geesbrug',
    'Geervliet',
    'Geertruidenberg',
    'Geersdijk',
    'Geerdijk',
    'Gastel',
    'Gasselternijveenschemond',
    'Gasselternijveen',
    'Gasselte',
    'Gassel',
    'Garminge',
    'Garyp',
    'Garderen',
    'Gameren',
    'Galder',
    'Gaanderen',
    'Froombosch',
    'Frieschepalen',
    'Franeker',
    'Foxhol',
    'Formerum',
    'Fochteloo',
    'Fluitenberg',
    'Fleringen',
    'Finsterwolde',
    'Feinsum',
    'Fijnaart',
    'Ferwert',
    'Feerwerd',
    'Eys',
    'Exloo',
    'Ewijk',
    'Everdingen',
    'Etten',
    'Est',
    'Essen',
    'Espel',
    'Escharen',
    'Esch',
    'Erp',
    'Ermelo',
    'Erlecom',
    'Erichem',
    'Erica',
    'Epse',
    'Epen',
    'Epe',
    'Enter',
    'Enspijk',
    'Enschot',
    'Enschede',
    'Ens',
    'Enkhuizen',
    'Engwierum',
    'Ingelum',
    'Engelen',
    'Emst',
    'Empel',
    'Empe',
    'Emmer-Compascuum',
    'Emmen',
    'Emmeloord',
    'Elst',
    'Elspeet',
    'Elsloo',
    'Elshout',
    'Elsendorp',
    'Elp',
    'Ellewoutsdijk',
    'Ellemeet',
    'Ellecom',
    'Ell',
    'Elim',
    'Eleveld',
    'Eldersloo',
    'Elburg',
    'Elahuizen',
    'Einighausen',
    'Eindhoven',
    'Eijsden',
    'Eygelshoven',
    'Eibergen',
    'Egmond-Binnen',
    'Egmond aan Zee',
    'Egmond aan den Hoef',
    'Egchel',
    'Eexterveen',
    'Eext',
    'Eethen',
    'Eesveen',
    'Jistrum',
    'Eesergroen',
    'Ees',
    'Eersel',
    'Eerbeek',
    'Eenum',
    'Een',
    'Eemnes',
    'Eelderwolde',
    'Eelde',
    'Eefde',
    'Eede',
    'Ee',
    'Ederveen',
    'Ede',
    'Edam',
    'Eck en Wiel',
    'Echtenerbrug',
    'Echteld',
    'Echt',
    'Dwingeloo',
    'Dussen',
    'Duizel',
    'Duivendrecht',
    'Duiven',
    'Druten',
    'Drunen',
    'Drouwenerveen',
    'Drouwenermond',
    'Drouwen',
    'Dronten',
    'Dronryp',
    'Drongelen',
    'Drogteropslagen',
    'Drogeham',
    'Driewegen',
    'Driezum',
    'Driemond',
    'Driel',
    'Driehuizen',
    'Driehuis',
    'Driebruggen',
    'Drieborg',
    'Driebergen-Rijsenburg',
    'Driebergen',
    'Dreumel',
    'Drempt',
    'Dreischor',
    'Drachtstercompagnie',
    'Drachten',
    'Dorst',
    'Dordrecht',
    'Doorwerth',
    'Doornspijk',
    'Doornenburg',
    'Doorn',
    'Donkerbroek',
    'Dongen',
    'Dommelen',
    'Domburg',
    'Dokkum',
    'Doetinchem',
    'Doesburg',
    'Doenrade',
    'Dodewaard',
    'Dirksland',
    'Dirkshorn',
    'Diphoorn',
    'Dinxperlo',
    'Dinteloord',
    'Dieverbrug',
    'Diever',
    'Diessen',
    'Dieren',
    'Diepenveen',
    'Diepenheim',
    'Diemen',
    'Dieden',
    'Didam',
    'De Zilk',
    'De Wilp',
    'De Wijk',
    'De Weere',
    'De Waal',
    'Deventer',
    'De Vecht',
    'Deurningen',
    'Deurne',
    'De Steeg',
    'De Rijp',
    'Den Velde',
    'Den Oever',
    'Den Ilp',
    'Den Hout',
    'Den Horn',
    'Den Hoorn',
    'Den Helder',
    'Den Ham',
    'Denekamp',
    'Den Dungen',
    'Den Dolder',
    'Den Burg',
    'Den Bommel',
    'De Moer',
    'Demmerik',
    'Demen',
    'De Meern',
    'De Lutte',
    'De Lier',
    'Delfzijl',
    'Delft',
    'Delfgauw',
    'Delden',
    'De Kwakel',
    'De Krim',
    'De Koog',
    'De Klomp',
    'De Kiel',
    'Deinum',
    'Deil',
    'De Hoeve',
    'De Hoef',
    'De Heurne',
    'De Goorn',
    'Deest',
    'Dedemsvaart',
    'De Cocksdorp',
    'De Bult',
    'De Bilt',
    'Darp',
    'Damwald',
    'Dalfsen',
    'Dalen',
    'Dalem',
    'Daarle',
    'Culemborg',
    'Cuijk',
    'Cruquius',
    'Cromvoirt',
    'Creil',
    'Cothen',
    'Koarnjum',
    'Colmschate',
    'Colijnsplaat',
    'Coevorden',
    'Clinge',
    'Chaam',
    'Castricum',
    'Casteren',
    'Capelle aan den IJssel',
    'Callantsoog',
    'Cadzand',
    'Cadier en Keer',
    'Buurmalsen',
    'Bussum',
    'Burum',
    'Burgwerd',
    'Burgerbrug',
    'Buren',
    'Bunschoten',
    'Bunnik',
    'Bunde',
    'Buitenpost',
    'Buitenkaag',
    'Buggenum',
    'Budel-Schoot',
    'Budel-Dorplein',
    'Budel',
    'Buchten',
    'Bruntinge',
    'Brunssum',
    'Brummen',
    'Bruinisse',
    'Bruchterveld',
    'Bruchem',
    'Brouwershaven',
    'Bronneger',
    'Broeksterwald',
    'Broek op Langedijk',
    'Broekland',
    'Broek in Waterland',
    'Broekhuizenvorst',
    'Broekhuizen',
    'Broekhoven',
    'Broek',
    'Britswerd',
    'Britsum',
    'Brielle',
    'Breukelen',
    'Breugel',
    'Breskens',
    'Breezand',
    'Breedenbroek',
    'Bredevoort',
    'Breda',
    'Brandwijk',
    'Brakel',
    'Braamt',
    'Boijl',
    'Boksum',
    'Boxtel',
    'Boxmeer',
    'Bovensmilde',
    'Boven-Leeuwen',
    'Bovenkarspel',
    'Boven-Hardinxveld',
    'Bourtange',
    'Bosschenhoofd',
    'Boskoop',
    'Bosch en Duin',
    'Borsele',
    'Bornerbroek',
    'Borne',
    'Born',
    'Borgercompagnie',
    'Borger',
    'Borculo',
    'Boornbergum',
    'Bontebok',
    'Bolsward',
    'Boesingheliede',
    'Boerdonk',
    'Boerakker',
    'Boekelo',
    'Boekel',
    'Boeicop',
    'Bodegraven',
    'Bocholtz',
    'Blokzijl',
    'Bloemendaal',
    'Blijham',
    'Blije',
    'Bleskensgraaf',
    'Blerick',
    'Bleiswijk',
    'Blaricum',
    'Blankenham',
    'Bladel',
    'Burdaard',
    'Bingelrade',
    'Bilthoven',
    'Biggekerke',
    'Biezenmortel',
    'Biest-Houtakker',
    'Biervliet',
    'Bierum',
    'Biddinghuizen',
    'Beverwijk',
    'Beusichem',
    'Beuningen',
    'Beugen',
    'Best',
    'Berltsum',
    'Berlicum',
    'Berkhout',
    'Berkenwoude',
    'Berkel',
    'Beringe',
    'Burgum',
    'Bergschenhoek',
    'Berghem',
    'Bergharen',
    'Bergeijk',
    'Bergentheim',
    'Bergen op Zoom',
    'Berg en Dal',
    'Bergen',
    'Bergambacht',
    'Benthuizen',
    'Bentelo',
    'Benschop',
    'Benningbroek',
    'Bennekom',
    'Bennebroek',
    'Beneden-Leeuwen',
    'Bemmel',
    'Belt-Schutsloot',
    'Beltrum',
    'Bellingwolde',
    'Belfeld',
    'Beinsdorp',
    'Beilen',
    'Beetsterzwaag',
    'Bitgummole',
    'Bitgum',
    'Beesel',
    'Beesd',
    'Beerzerveld',
    'Beerze',
    'Beerta',
    'Beers',
    'Beekbergen',
    'Beek gem Montferland',
    'Beek',
    'Beegden',
    'Bedum',
    'Bavel',
    'Bathmen',
    'Batenburg',
    'Basse',
    'Barsingerhorn',
    'Barneveld',
    'Barger-Compascuum',
    'Barendrecht',
    'Barchem',
    'Bantega',
    'Bant',
    'Banholt',
    'Ballum',
    'Balkbrug',
    'Balk',
    'Balinge',
    'Balgoij',
    'Bakkeveen',
    'Bakhuizen',
    'Bakel',
    'Baflo',
    'Baexem',
    'Badhoevedorp',
    'Babberich',
    'Baarn',
    'Baarlo',
    'Baarle-Nassau',
    'Baarland',
    'Baambrugge',
    'Baak',
    'Azewijn',
    'Axel',
    'Avenhorn',
    'Austerlitz',
    'Augustinusga',
    'Asten',
    'Assendelft',
    'Assen',
    'Asperen',
    'Asch',
    'Arum',
    'Arnhem',
    'Arnemuiden',
    'Arkel',
    'Arcen',
    'Appingedam',
    'Appeltern',
    'Appelscha',
    'Apeldoorn',
    'Annen',
    'Anna Paulowna',
    'Anloo',
    'Ankeveen',
    'Anjum',
    'Angerlo',
    'Angeren',
    'Anerveen',
    'Ane',
    'Andijk',
    'Andelst',
    'Amsterdam',
    'Amstenrade',
    'Amstelveen',
    'Amstelhoek',
    'Ammerzoden',
    'Amersfoort',
    'Amerongen',
    'America',
    'Ameide',
    'Altforst',
    'Alteveer',
    'Alphen aan den Rijn',
    'Alphen',
    'Almkerk',
    'Almere Stad',
    'Almere Haven',
    'Almere Buiten',
    'Almen',
    'Almelo',
    'Alkmaar',
    'Alem',
    'Alblasserdam',
    'Albergen',
    'Akkrum',
    'Akersloot',
    'Aijen',
    'Afferden',
    'Aerdenhout',
    'Aduard',
    'Adorp',
    'Acquoy',
    'Achtmaal',
    'Achthuizen',
    'Achterveld',
    'Achlum',
    'Abcoude',
    'Abbenes',
    'Abbenbroek',
    'Abbekerk',
    'Aartswoud',
    'Aarlanderveen',
    'Aardenburg',
    'Aalten',
    'Aalst',
    'Aalsmeerderbrug',
    'Aalsmeer',
    'Aalden',
    'Aalburg',
    'Aagtekerke',
    'Aadorp',
    'Radio Kootwijk',
    'Neerlangel',
    'Op den Bosch',
    'Landgraaf',
    'Jubbega',
    'De Knipe',
    'Aarle-Rixtel',
    'Kelpen-Oler',
    'Berkel en Rodenrijs',
    'IJburg',
    'Velserbroek',
    'Kapelle',
    'Burgh-Haamstede',
    'Beek en Donk',
    'Berg en Terblijt',
    'Loosdrecht',
    'Winterswijk Kotten',
    'Lattrop-Breklenkamp',
    'Ambt Delden',
    'Meerstad',
    'Maastricht-Airport',
    'Hardinxveld-Giessendam',
    'Son en Breugel',
  ],
  Belgium: [
    'Zwijndrecht',
    'Zwijnaarde',
    'Zwevezele',
    'Zwevegem',
    'Zutendaal',
    'Zulte',
    'Zuienkerke',
    'Zoutleeuw',
    'Zottegem',
    'Zonnegem',
    'Zonnebeke',
    'Zonhoven',
    'Zomergem',
    'Zolder',
    'Zoersel',
    'Zoerle-Parwijs',
    'Zingem',
    'Zillebeke',
    'Zichem',
    'Zeveneken',
    'Zevekote',
    'Zetrud-Lumay',
    'Zerkegem',
    'Zepperen',
    'Zemst',
    'Zelzate',
    'Zellik',
    'Zelem',
    'Zele',
    'Zeebrugge',
    'Zedelgem',
    'Zaventem',
    'Zarren',
    'Zandvliet',
    'Zandhoven',
    'Zandbergen',
    'Zaffelare',
    'Yvoir',
    'Yves-Gomezee',
    'Xhoris',
    'Xhoffraix',
    'Xhendremael',
    'Xhendelesse',
    'Wuustwezel',
    'Wulvergem',
    'Wulpen',
    'Woumen',
    'Wortel',
    'Wortegem',
    'Wondelgem',
    'Wonck',
    'Wommersom',
    'Wommelgem',
    'Wolvertem',
    'Woluwe-Saint-Pierre - Sint-Pieters-Woluwe',
    'Woluwe-Saint-Lambert',
    'Woesten',
    'Winksele',
    'Wingene',
    'Winenne',
    'Wimmertingen',
    'Wilsele',
    'Wilrijk',
    'Willerzie',
    'Willebroek',
    'Willebringen',
    'Wijtschate',
    'Wijnegem',
    'Wijgmaal',
    'Wijer',
    'Wijchmaal',
    'Wihogne',
    'Wieze',
    'Wiers',
    'Wierde',
    'Wielsbeke',
    'Wiekevorst',
    'Wichelen',
    'Wez-Velvain',
    'Wezembeek-Oppem',
    'Wezemaal',
    'Weverce',
    'Wevelgem',
    'Wetteren',
    'Westvleteren',
    'Westrem',
    'Westouter',
    'Westmeerbeek',
    'Westmalle',
    'Westkerke',
    'Westkapelle',
    'Westerlo',
    'Westende',
    'Wespelaar',
    'Wervik',
    'Werken',
    'Werchter',
    'Wepion',
    'Wenduine',
    'Wemmel',
    'Wellin',
    'Wellen',
    'Welle',
    'Welkenraedt',
    'Welden',
    'Weillen',
    'Wegnez',
    'Weerde',
    'Weelde',
    'Wechelderzande',
    'Webbekom',
    'Ways',
    'Wayaux',
    'Wavreille',
    'Wavre',
    'Wauthier-Braine',
    'Waulsort',
    'Waudrez',
    'Watou',
    'Watervliet',
    'Watermael-Boitsfort',
    'Waterloo',
    'Wasseiges',
    'Wasmuel',
    'Wasmes',
    'Warzee',
    'Warsage',
    'Warquignies',
    'Warneton',
    'Warnant',
    'Warmifontaine',
    'Waret-la-Chaussee',
    'Waremme',
    'Waregem',
    'Warcoing',
    'Warchin',
    'Wanzele',
    'Wanze',
    'Wannegem-Lede',
    'Wannebecq',
    'Wanfercee-Baulet',
    'Wandre',
    'Wambeek',
    "Houtain-l'Eveque",
    'Walsbets',
    'Walhorn',
    'Walem',
    'Walcourt',
    'Wakken',
    'Waimes',
    'Wagnelee',
    'Wachtebeke',
    'Waasmunster',
    'Waasmont',
    'Waarschoot',
    'Waarloos',
    'Waardamme',
    'Waanrode',
    'Vurste',
    'Vremde',
    'Vrasene',
    'Vottem',
    'Vosselare',
    'Vosselaar',
    'Vorselaar',
    'Voroux-lez-Liers',
    'Voroux-Goreux',
    'Voorde',
    'Vollezele',
    'Volaiville',
    'Vodelee',
    'Vodecee',
    'Vlissegem',
    'Vlimmeren',
    'Vlijtingen',
    'Vlierzele',
    'Vliermaalroot',
    'Vlezenbeek',
    'Vlekkem',
    'Vlamertinge',
    'Vladslo',
    'Vivegnis',
    'Vitrival',
    'Vissenaken',
    'Vise',
    'Virton',
    'Virelles',
    'Vinkt',
    'Vinderhoute',
    'Vilvoorde',
    'Ville-sur-Haine',
    'Villers-Poterie',
    'Villers-Perwin',
    'Villers-le-Gambon',
    'Villers-le-Bouillet',
    'Villers-la-Ville',
    'Villers-devant-Orval',
    'Villers-aux-Tours',
    'Villerot',
    'Vieuxville',
    'Vieux-Genappe',
    'Viesville',
    'Viemme',
    'Vielsalm',
    'Vichte',
    'Viane',
    'Vezin',
    'Veurne',
    'Verviers',
    'Vertrijk',
    'Verrebroek',
    'Verlaine',
    'Veltem-Beisem',
    'Velm',
    'Veldwezelt',
    'Veldhoven',
    'Veldegem',
    'Velaines',
    'Veerle',
    'Vedrin',
    'Vaux-sur-Sure',
    'Vaux-sous-Chevremont',
    'Vaucelles',
    'Varsenare',
    'Vance',
    'Valansart',
    'Ursel',
    'Ulbeek',
    'Uitbergen',
    'Uikhoven',
    'Uccle',
    'Turnhout',
    'Tubize',
    'Trooz',
    'Trois-Ponts',
    'Trivieres',
    'Tremelo',
    'Treignes',
    'Trazegnies',
    'Tourpes',
    'Tournai',
    'Tourinnes-la-Grosse',
    'Torhout',
    'Torgny',
    'Tongrinne',
    'Tongre-Notre-Dame',
    'Tongeren',
    'Tollembeek',
    'Tohogne',
    'Toernich',
    'Tisselt',
    'Tintigny',
    'Tinlot',
    'Tilly',
    'Tilleur',
    'Tilff',
    'Tildonk',
    'Tihange',
    'Tienen',
    'Tielt',
    'Tielrode',
    'Tielen',
    'Tiegem',
    'Thynes',
    'Thy-le-Chateau',
    'Thulin',
    'Thuin',
    'Thuillies',
    'Thoricourt',
    'Thorembais-Saint-Trond',
    'Thorembais-les-Beguines',
    'Thon',
    'Thirimont',
    'Thimister',
    'Thimeon',
    'Thieusies',
    'Thieu',
    'Thibesart',
    'Theux',
    'Testelt',
    'Tessenderlo',
    'Tervuren',
    'Tertre',
    'Ternat',
    'Terhagen',
    'Teralfene',
    'Tenneville',
    'Temse',
    'Temploux',
    'Templeuve',
    'Tellin',
    'Tavigny',
    'Taviers',
    'Tarcienne',
    'Tangissart',
    'Tamines',
    'Taintignies',
    'Suarlee',
    'Strombeek-Bever',
    'Strijpen',
    'Strepy-Bracquegnies',
    'Stree',
    'Stoumont',
    'Stokrooie',
    'Stokkem',
    'Stockay',
    'Stevoort',
    'Sterrebeek',
    'Sterpenich',
    'Stene',
    'Stembert',
    'Stekene',
    'Steenokkerzeel',
    'Steenkerque',
    'Steenhuffel',
    'Steendorp',
    'Stavelot',
    'Stambruges',
    'Stalhille',
    'Staden',
    'Stabroek',
    'Spy',
    'Sprimont',
    'Spontin',
    'Spiennes',
    'Spalbeek',
    'Spa',
    'Soye',
    'Soy',
    'Sovet',
    'Souvret',
    'Soumagne',
    'Sorinnes',
    'Soree',
    'Somzee',
    'Somme-Leuze',
    'Sombreffe',
    'Solre-sur-Sambre',
    'Soiron',
    'Soignies',
    'Snaaskerke',
    'Smetlede',
    'Smeerebbe-Vloerzegem',
    'Slins',
    'Slijpe',
    'Sleidinge',
    'Sivry',
    'Sirault',
    'Sint-Truiden',
    'Sint-Stevens-Woluwe',
    'Sint-Pieters-Rode',
    'Sint-Pieters-Leeuw',
    'Sint-Pauwels',
    'Sint-Niklaas',
    'Sint-Michiels',
    'Sint-Martens-Lierde',
    'Sint-Martens-Lennik',
    'Sint-Martens-Latem',
    'Sint-Martens-Bodegem',
    'Sint-Lievens-Houtem',
    'Sint-Laureins',
    'Sint-Lambrechts-Herk',
    'Sint-Kruis-Winkel',
    'Sint-Kruis',
    'Sint-Kornelis-Horebeke',
    'Sint-Katherina-Lombeek',
    'Sint-Katelijne-Waver',
    'Sint-Joris-Winge',
    'Sint-Joris-Weert',
    "Sint-Job-in-'t-Goor",
    'Sint-Jan-in-Eremo',
    'Sint-Goriks-Oudenhove',
    'Sint-Gillis-Waas',
    'Sint-Gillis-bij-Dendermonde',
    'Sint-Genesius-Rode',
    'Sint-Eloois-Winkel',
    'Sint-Eloois-Vijve',
    'Sint-Denijs-Westrem',
    'Sint-Denijs',
    'Sint-Andries',
    'Sint-Amandsberg',
    'Sint-Amands',
    'Sint-Agatha-Rode',
    'Sinsin',
    'Sinaai',
    'Silly',
    'Silenrieux',
    'Sijsele',
    'Signeulx',
    'Sibret',
    "'s-Gravenwezel",
    'Serskamp',
    'Seraing',
    'Septon',
    'Sensenruth',
    'Senenne',
    'Seneffe',
    'Semmerzake',
    'Selange',
    'Sclessin',
    'Sclayn',
    'Schulen',
    'Schriek',
    'Schoten',
    'Schorisse',
    'Schoonaarde',
    'Schilde',
    'Scherpenheuvel',
    'Schepdaal',
    'Schendelbeke',
    'Schellebelle',
    'Schelle',
    'Scheldewindeke',
    'Schaltin',
    'Schaffen',
    'Schaarbeek',
    'Sauveniere',
    'Sautour',
    'Sart Saint-Nicolas',
    'Sart-Saint-Laurent',
    'Sart-Eustache',
    'Sart-Dames-Avelines',
    'Sart-Bernard',
    'Sart-lez-Spa',
    'Sars-la-Buissiere',
    'Samree',
    'Samart',
    'Saives',
    'Saive',
    'Sankt Vith',
    'Saint-Vaast',
    'Saint-Symphorien',
    'Saint-Severin',
    'Saint-Servais',
    'Saint-Sauveur',
    'Saint-Remy-Geest',
    'Saint-Remy',
    'Saint-Nicolas',
    'Saint-Medard',
    'Saint-Martin',
    'Saint-Mard',
    'Saint-Marc',
    'Saint-Leger',
    'Saint-Josse-ten-Noode',
    'Saint-Hubert',
    'Saint-Gilles',
    'Saint-Ghislain',
    'Saint-Gerard',
    'Saint-Georges-sur-Meuse',
    'Saintes',
    'Sainte-Ode',
    'Saint-Denis-Bovesse',
    'Saint-Aubin',
    'Saint-Amand',
    'Russeignies',
    'Rupelmonde',
    'Runkelen',
    'Rumst',
    'Rummen',
    'Rumillies',
    'Rumes',
    'Rumbeke',
    'Rulles',
    'Ruiselede',
    'Ruisbroek',
    'Ruien',
    'Rugge',
    'Ruette',
    'Ruddervoorde',
    'Roux-Miroir',
    'Roux',
    'Roucourt',
    'Rotselaar',
    'Rosieres',
    'Roselies',
    'Rosee',
    'Ronse',
    'Ronquieres',
    'Rongy',
    'Romsee',
    'Romedenne',
    'Rollegem-Kapelle',
    'Rollegem',
    'Roksem',
    'Roisin',
    'Roeulx',
    'Roeselare',
    'Rocourt',
    'Roclenge-sur-Geer',
    'Rochefort',
    'Robechies',
    'Rixensart',
    'Rillaar',
    'Rijmenam',
    'Rijkhoven',
    'Rijkevorsel',
    'Rijkel',
    'Riezes',
    'Riemst',
    'Richelle',
    'Rhisnes',
    'Reves',
    'Burg-Reuland',
    'Retinne',
    'Retie',
    'Resteigne',
    'Ressegem',
    'Ressaix',
    'Reningelst',
    'Reninge',
    'Rendeux',
    'Remicourt',
    'Relegem',
    'Rekkem',
    'Rekem',
    'Reet',
    'Recogne',
    'Rebaix',
    'Ravels',
    'Ranst',
    'Ransart',
    'Rance',
    'Ramsel',
    'Ramsdonk',
    'Ramillies',
    'Ramegnies-Chin',
    'Raeren',
    'Racour',
    'Rachecourt',
    'Quievrain',
    'Quevy-le-Grand',
    'Quevaucamps',
    'Queue-du-Bois',
    'Quenast',
    'Quaregnon',
    'Puurs',
    'Putte',
    'Pulle',
    'Pulderbos',
    'Pry',
    'Profondeville',
    'Presles',
    'Presgaux',
    'Pousset',
    'Poupehan',
    'Poulseur',
    'Poucet',
    'Pottes',
    'Poppel',
    'Poperinge',
    'Pont-de-Loup',
    'Pont-a-Celles',
    'Pommeroeul',
    'Pollinkhove',
    'Pollare',
    'Poesele',
    'Poelkapelle',
    'Poederlee',
    'Plombières',
    'Ploegsteert',
    'Plainevaux',
    'Pittem',
    'Pironchamps',
    'Pipaix',
    'Pin',
    'Pietrebais',
    'Philippeville',
    'Peutie',
    'Petit-Rechain',
    'Petit-Hallet',
    'Lettelingen',
    'Petigny',
    'Pessoux',
    'Pesche',
    'Perwez',
    'Peruwelz',
    'Peronnes',
    'Perk',
    'Pepinster',
    'Pepingen',
    'Pellenberg',
    'Pellaines',
    'Peer',
    'Pecrot',
    'Pecq',
    'Paturages',
    'Patignies',
    'Passendale',
    'Parike',
    'Paliseul',
    'Paal',
    'Ovifat',
    'Overpelt',
    'Overmere',
    'Overijse',
    'Ouwegem',
    'Outrelouxhe',
    'Outgaarden',
    'Oupeye',
    'Ougree',
    'Ouffet',
    'Oud-Turnhout',
    'Oud-Heverlee',
    'Oudenburg',
    'Oudenaken',
    'Oudenaarde',
    'Oudegem',
    'Ottignies',
    'Ottenburg',
    'Othee',
    'Otegem',
    'Ostiches',
    'Ortho',
    'Orroir',
    'Orp-le-Grand',
    'Ormeignies',
    'Oreye',
    'Ordingen',
    'Orcq',
    'Orbais',
    'Opwijk',
    'Opvelp',
    'Oppuurs',
    'Opprebais',
    'Opont',
    'Opoeteren',
    'Ophain-Bois-Seigneur-Isaac',
    'Opgrimbie',
    'Opglabbeek',
    'Opdorp',
    'Oostwinkel',
    'Oostrozebeke',
    'Oostnieuwkerke',
    'Oostmalle',
    'Oostkamp',
    'Oostham',
    'Oosterzele',
    'Ostend',
    'Oosteeklo',
    'Oostduinkerke',
    'Oostakker',
    'Oordegem',
    'Ooigem',
    'Onze-Lieve-Vrouw-Waver',
    'Onkerzele',
    'Onhaye',
    'Olsene',
    'Olne',
    'Olmen',
    'Ollignies',
    'Olen',
    'Okegem',
    'Oizy',
    'Oisquercq',
    'Ohey',
    'Ohain',
    'Ogy',
    'Oevel',
    'Oeudeghien',
    'Oetingen',
    'Oeselgem',
    'Oelegem',
    'Oekene',
    'Oedelem',
    'Ochamps',
    'Obourg',
    'Obigies',
    'Obaix',
    'Nukerke',
    'Noville-les-Bois',
    'Nothomb',
    'Nossegem',
    'Noorderwijk',
    'Nonceveux',
    'Nollevaux',
    'Nokere',
    'Noiseux',
    'Noirefontaine',
    'Noduwez',
    'Niveze',
    'Nivelles',
    'Ninove',
    'Nimy',
    'Nil-Saint-Vincent-Saint-Martin',
    'Nijlen',
    'Nieuwrode',
    'Nieuwpoort',
    'Nieuwkerken-Waas',
    'Nieuwerkerken',
    'Niel-bij-Sint-Truiden',
    'Niel-bij-As',
    'Niel',
    'Nidrum',
    'Nevele',
    'Neuville-en-Condroz',
    'Neuville',
    'Neu-Moresnet',
    'Neufvilles',
    'Neufchateau',
    'Nethen',
    'Nessonvaux',
    'Neigem',
    'Neerpelt',
    'Neeroeteren',
    'Neerlinter',
    'Neerheylissem',
    'Nederename',
    'Brakel',
    'Nechin',
    'Nazareth',
    'Natoye',
    'Nassogne',
    'Naome',
    'Naninne',
    'Nandrin',
    'Namur',
    'Nameche',
    'Nalinnes',
    'Nafraiture',
    'Naast',
    'Musson',
    'Muno',
    'Muizen',
    'Moustier-sur-Sambre',
    'Mouscron',
    'Mourcourt',
    'Moulbaix',
    'Moelingen',
    'Morville',
    'Mortsel',
    'Mortier',
    'Mornimont',
    'Morlanwelz-Mariemont',
    'Morkhoven',
    'Morialme',
    'Moresnet',
    'Moorslede',
    'Moorsele',
    'Moorsel',
    'Montzen',
    'Mont-sur-Marchienne',
    'Mont-Saint-Guibert',
    'Mont-Sainte-Aldegonde',
    'Montroeul-sur-Haine',
    'Montignies-sur-Sambre',
    'Montignies-lez-Lens',
    'Montigny-le-Tilleul',
    'Montegnee',
    'Montbliart',
    'Monstreux',
    'Mons-lez-Liege',
    'Mons',
    'Monceau-sur-Sambre',
    'Monceau-Imbrechies',
    'Monceau',
    'Momignies',
    'Momalle',
    'Mollem',
    'Molenstede',
    'Molenbeersel',
    'Molenbeek-Wersbeek',
    'Molenbeek-Saint-Jean',
    'Mol',
    'Moerzeke',
    'Moerkerke',
    'Moere',
    'Moerbeke',
    'Moen',
    'Modave',
    'Minderhout',
    'Milmort',
    'Mignault',
    'Miecret',
    'Middelkerke',
    'Michelbeke',
    'Meux',
    'Meulebeke',
    'Mettet',
    'Mesvin',
    'Messelbroek',
    'Messancy',
    'Merksplas',
    'Merksem',
    'Merelbeke',
    'Mere',
    'Merdorp',
    'Merchtem',
    'Merbes-le-Chateau',
    'Menen',
    'Membach',
    'Melsen',
    'Melsele',
    'Melsbroek',
    'Mellier',
    'Mellet',
    'Mellery',
    'Melle',
    'Melin',
    'Melen',
    'Meldert',
    'Meix-le-Tige',
    'Meix-devant-Virton',
    'Meise',
    'Meeuwen',
    'Meetkerke',
    'Meeswijk',
    'Meerle',
    'Meerhout',
    'Meerdonk',
    'Meerbeke',
    'Meerbeek',
    'Meer',
    'Meensel-Kiezegem',
    'Mechelen-Bovelingen',
    'Mechelen-aan-de-Maas',
    'Mechelen',
    'Mean',
    'Mazy',
    'Mazenzele',
    'Maurage',
    'Maulde',
    'Maubray',
    'Matagne-la-Grande',
    'Massemen',
    'Masnuy-Saint-Jean',
    'Masbourg',
    'Martelange',
    'Marloie',
    'Marke',
    'Marilles',
    'Mariembourg',
    'Mariakerke',
    'Mark',
    'Marcinelle',
    'Marchovelette',
    'Marchin',
    'Marchienne-au-Pont',
    'Marche-lez-Ecaussinnes',
    'Marche-les-Dames',
    'Marche-en-Famenne',
    'Marbais',
    'Maransart',
    'Manhay',
    'Manage',
    'Malonne',
    'Malmedy',
    'Malderen',
    'Maldegem',
    'Maldingen',
    'Maissin',
    'Maisieres',
    'Mainvault',
    'Maillen',
    'Magnee',
    'Maffle',
    'Macquenoise',
    'Machelen-aan-de-Leie',
    'Machelen',
    'Maasmechelen',
    'Maaseik',
    'Maarkedal',
    'Luttre',
    'Lustin',
    'Lummen',
    'Luingne',
    'Lubbeek',
    'Loyers',
    'Loverval',
    'Lovenjoel',
    'Lovendegem',
    'Louveigne',
    'Louvain-la-Neuve',
    'Loupoigne',
    'Lottert',
    'Lot',
    'Loppem',
    'Lonzee',
    'Lontzen',
    'Longueville',
    'Londerzeel',
    'Loncin',
    'Lommel',
    'Lombardsijde',
    'Loksbergen',
    'Lokeren',
    'Loker',
    'Loenhout',
    'Lodelinsart',
    'Lochristi',
    'Lobbes',
    'Lives-sur-Meuse',
    'Lissewege',
    'Lint',
    'Linkebeek',
    'Linden',
    'Lincent',
    'Limont',
    'Limelette',
    'Limbourg',
    'Limal',
    'Lillois-Witterzee',
    'Lille',
    'Ligny',
    'Ligney',
    'Ligne',
    'Liezele',
    'Liers',
    'Lier',
    'Lierneux',
    'Liège',
    'Liedekerke',
    'Lichtervelde',
    'Lichtaart',
    'Libramont',
    'Libin',
    'Liberchies',
    'Leval-Trahegnies',
    'Leval-Chaudeville',
    'Leuze',
    'Leuven',
    'Letterhoutem',
    'Lesve',
    'Lessive',
    'Lessines',
    'Deux-Acren',
    'Les Bulles',
    'Les Binches',
    'Les Avins',
    'Le Roux',
    'Le Paturage',
    'Leopoldsburg',
    'Lens',
    'Lendelede',
    'Lembeek',
    'Leisele',
    'Leignon',
    'Leglise',
    'Leffinge',
    'Leest',
    'Leers-Nord',
    'Leers-et-Fosteau',
    'Leernes',
    'Leefdaal',
    'Ledegem',
    'Ledeberg',
    'Lede',
    'Le Bizet',
    'Lebbeke',
    'Lavoir',
    'Lavacherie',
    'Lauwe',
    'Latour',
    'Latinne',
    'Lathuy',
    'Lasne',
    'La Roche-en-Ardenne',
    'Laplaigne',
    'Lantin',
    'Lanklaar',
    'Langemark',
    'Langdorp',
    'Laneffe',
    'Landen',
    'Landegem',
    'Lanaye',
    'Lanaken',
    'Lambusart',
    'La Louvière',
    'La Hulpe',
    'La Hestre',
    'Lahamaide',
    'La Glanerie',
    'Laforet',
    'Laken',
    'Lacuisine',
    'La Calamine',
    'Laarne',
    'Kuurne',
    'Kuringen',
    'Kumtich',
    'Kruishoutem',
    'Kruibeke',
    'Krombeke',
    'Kraainem',
    'Kotem',
    'Kortrijk-Dutsel',
    'Kortrijk',
    'Kortessem',
    'Kortenberg',
    'Kortenaken',
    'Kortemark',
    'Korbeek-Dijle',
    'Koolskamp',
    'Koolkerke',
    'Kontich',
    'Koningshooikt',
    'Koksijde',
    'Koersel',
    'Koekelberg',
    'Koekelare',
    'Kobbegem',
    'Knokke-Heist',
    'Knokke',
    'Knesselare',
    'Kluizen',
    'Kluisbergen',
    'Klerken',
    'Kinrooi',
    'Kieldrecht',
    'Kettenis',
    'Kester',
    'Kessel-Lo',
    'Kessel',
    'Kersbeek-Miskom',
    'Kermt',
    'Kerksken',
    'Kerkhove',
    'Kemzeke',
    'Keiem',
    'Keerbergen',
    'Kaulille',
    'Kasterlee',
    'Kaprijke',
    'Kapelle-op-den-Bos',
    'Kapellen',
    'Kampenhout',
    'Kalmthout',
    'Kallo',
    'Kalken',
    'Kain',
    'Kaggevinne',
    'Kachtem',
    'Jurbise',
    'Juprelle',
    'Jupille-sur-Meuse',
    'Jupille',
    'Jumet',
    'Julemont',
    'Jodoigne-Souveraine',
    'Jodoigne',
    'Jeuk',
    'Jette',
    'Jemeppe-sur-Meuse',
    'Jemeppe-sur-Sambre',
    'Jemeppe',
    'Jemelle',
    'Jemappes',
    'Jehonville',
    'Jehay',
    'Jauche',
    'Jandrain-Jandrenouille',
    'Jamoigne',
    'Jamioulx',
    'Jambes',
    'Jalhay',
    'Jabbeke',
    'Izel',
    'Izegem',
    'Ixelles-Elsene',
    'Ivoz-Ramet',
    'Iveldingen',
    'Ittre',
    'Itterbeek',
    'Itegem',
    'Irchonwelz',
    'Ingelmunster',
    'Incourt',
    'Ypres',
    'Idegem',
    'Iddergem',
    'Ichtegem',
    'Hyon',
    'Huy',
    'Humbeek',
    'Hulste',
    'Hulshout',
    'Huldenberg',
    'Huizingen',
    'Huissignies',
    'Huise',
    'Hove',
    'Houyet',
    'Houwaart',
    'Houtvenne',
    'Houthulst',
    'Houthalen',
    'Houtave',
    'Houtain-Saint-Simeon',
    'Houtain-le-Val',
    'Housse',
    'Hour',
    'Houffalize',
    'Houdeng-Goegnies',
    'Houdeng-Aimeries',
    'Houdemont',
    'Hotton',
    'Horrues',
    'Hornu',
    'Horion-Hozemont',
    'Hoogstraten',
    'Hooglede',
    'Hondelange',
    'Hompre',
    'Hombourg',
    'Hombeek',
    'Holsbeek',
    'Hollogne-sur-Geer',
    'Hollange',
    'Hollain',
    'Hognoul',
    'Hogne',
    'Hofstade',
    'Hoevenen',
    'Hoeselt',
    'Hoeleden',
    'Hoelbeek',
    'Hoeilaart',
    'Hoegaarden',
    'Hody',
    'Hodister',
    'Hodeige',
    'Hoboken',
    'Hives',
    'Hillegem',
    'Hevillers',
    'Heverlee',
    'Hever',
    'Heuvelgem',
    'Heusy',
    'Heusden',
    'Heure-le-Romain',
    'Heure',
    'Heultje',
    'Heule',
    'Herzele',
    'Herve',
    'Hertsberge',
    'Herstal',
    'Herselt',
    'Herseaux',
    'Herquegies',
    'Heron',
    'Herne',
    'Hermeton-sur-Meuse',
    'Hermee',
    'Hermalle-sous-Huy',
    'Hermalle-sous-Argenteau',
    'Herk-de-Stad',
    'Herinnes',
    'Hergenrath',
    'Herfelingen',
    'Herenthout',
    'Herentals',
    'Herent',
    'Herdersem',
    'Herderen',
    'Herchies',
    'Herbeumont',
    'Heppignies',
    'Heppen',
    'Hensies',
    'Henripont',
    'Henri-Chapelle',
    'Hennuyeres',
    'Hemptinne-lez-Florennes',
    'Hemiksem',
    'Hellebecq',
    'Heldergem',
    'Helchteren',
    'Helkijn',
    'Hekelgem',
    'Heist-op-den-Berg',
    'Heist',
    'Heinstert',
    'Heffen',
    'Heestert',
    'Heers',
    'Hechtel',
    'Havre',
    'Havinnes',
    'Haversin',
    'Havelange',
    'Havay',
    'Hautrage',
    'Haut-Ittre',
    'Haut-Fays',
    'Hauset',
    'Hatrival',
    'Hastiere-par-dela',
    'Hasselt',
    'Harze',
    'Harsin',
    'Harmignies',
    'Haren',
    'Harelbeke',
    'Harchies',
    'Hanzinelle',
    'Hantes-Wiheries',
    'Han-sur-Lesse',
    'Hansbeke',
    'Hanret',
    'Hannut',
    'Haneffe',
    'Handzame',
    'Ham-sur-Sambre',
    'Ham-sur-Heure',
    'Hamont',
    'Hamois',
    'Hamoir',
    'Hamme-Mille',
    'Hamme',
    'Hamipre',
    'Ham',
    'Halma',
    'Halle (Kempen)',
    'Halle',
    'Hallaar',
    'Halen',
    'Halanzy',
    'Hakendover',
    'Haine-Saint-Pierre',
    'Haine-Saint-Paul',
    'Haillot',
    'Hacquegnies',
    'Hachy',
    'Haccourt',
    'Habay-la-Vieille',
    'Habay-la-Neuve',
    'Haasrode',
    'Haasdonk',
    'Haaltert',
    'Haacht',
    'Gullegem',
    'Guigoven',
    'Grune',
    'Gruitrode',
    'Groot-Bijgaarden',
    'Grobbendonk',
    'Grivegnee',
    'Grimminge',
    'Grimbergen',
    'Grez-Doiceau',
    'Grembergen',
    'Graty',
    'Grasheide',
    'Grandrieu',
    'Grand-Reng',
    'Grand-Rechain',
    'Grandmetz',
    'Grand-Leez',
    'Grand-Halleux',
    'Grammene',
    'Graide',
    'Grace-Berleur',
    'Gozee',
    'Gouy-lez-Pieton',
    'Gouvy',
    'Goutroux',
    'Gougnies',
    'Gottem',
    'Gosselies',
    'Gooik',
    'Gonrieux',
    'Goetsenhoven',
    'Goeferdinge',
    'Godinne',
    'Gochenee',
    'Glain',
    'Glabbeek',
    'Glabais',
    'Givry',
    'Gits',
    'Gistel',
    'Gingelom',
    'Gimnee',
    'Gilly',
    'Gijzegem',
    'Gijverinkhove',
    'Gierle',
    'Ghoy',
    'Ghlin',
    'Ghislenghien',
    'Gesves',
    'Gerpinnes',
    'Geraardsbergen',
    'Genval',
    'Gentinnes',
    'Gentbrugge',
    'Ghent',
    'Genly',
    'Genk',
    'Genendijk',
    'Genappe',
    'Gemmenich',
    'Gembloux',
    'Geluwe',
    'Gelrode',
    'Geetbets',
    'Geer',
    'Geel',
    'Gedinne',
    'Gavere',
    'Gaurain-Ramecroix',
    'Ganshoren',
    'Galmaarden',
    'Gallaix',
    'Furnaux',
    'Froyennes',
    'Fronville',
    'Froidmont',
    'Froidchapelle',
    'Freux',
    'Frasnes-lez-Gosselies',
    'Frasnes-lez-Buissenal',
    'Frasnes',
    'Franiere',
    'Francorchamps',
    'Frameries',
    'Fraiture',
    'Fraire',
    'Fraipont',
    'Foy-Notre-Dame',
    "'s-Gravenvoeren",
    'Fosses-la-Ville',
    'Forzee',
    'Forville',
    'Forrieres',
    'Forges',
    'Forest',
    'Forchies-la-Marche',
    'Fooz',
    'Fontenoille',
    'Fontaine-Valmont',
    "Fontaine-l'Eveque",
    'Fontaine',
    'Focant',
    'Floriffoux',
    'Florenville',
    'Florennes',
    'Floreffe',
    'Floree',
    'Flobecq',
    'Fleurus',
    'Fleron',
    'Flenu',
    'Flemalle-Haute',
    'Flemalle-Grande',
    'Flawinne',
    'Flavion',
    'Flamierge',
    'Fexhe-Slins',
    'Fexhe-le-Haut-Clocher',
    'Feschaux',
    'Ferrieres',
    'Feluy',
    'Fayt-lez-Manage',
    'Fayt-le-Franc',
    'Fays-les-Veneurs',
    'Fauvillers',
    'Fauroeulx',
    'Farciennes',
    'Familleureux',
    'Fallais',
    'Falisolle',
    'Faimes',
    'Eynatten',
    'Evrehailles',
    'Evregnies',
    'Evergem',
    'Evere',
    'Everberg',
    'Everbeek',
    'Evelette',
    'Eupen',
    'Eugies',
    'Etterbeek',
    'Etikhove',
    'Ethe',
    'Etalle',
    'Estinnes-au-Val',
    'Estaimpuis',
    'Estaimbourg',
    'Essene',
    'Essen',
    'Esplechin',
    'Espeler',
    'Esneux',
    'Escanaffles',
    'Erwetegem',
    'Ertvelde',
    'Erquennes',
    'Erquelinnes',
    'Erps-Kwerps',
    'Erpent',
    'Erpe',
    'Erondegem',
    'Ernage',
    'Ermeton-sur-Biert',
    'Erezee',
    'Erembodegem',
    'Ere',
    'Erbisoeul',
    'Erbaut',
    'Eppegem',
    'Epinois',
    'Ensival',
    'Enines',
    'Engis',
    'Enghien',
    'Emines',
    'Emelgem',
    'Embourg',
    'Emblem',
    'Elversele',
    'Elverdinge',
    'Elsenborn',
    'Elsegem',
    'Elouges',
    'Ellikom',
    'Ellezelles',
    'Ellemelle',
    'Elewijt',
    'Elen',
    'Eksel',
    'Eksaarde',
    'Ekeren',
    'Eke',
    'Eisden',
    'Einhoven',
    'Eine',
    'Eindhout',
    'Eigenbilzen',
    'Eghezee',
    'Egem',
    'Eernegem',
    'Eeklo',
    'Edegem',
    'Ecaussinnes-Lalaing',
    "Ecaussinnes-d'Enghien",
    'Eben-Emael',
    'Dworp',
    'Durnal',
    'Durbuy',
    'Duisburg',
    'Duinbergen',
    'Duffel',
    'Dudzele',
    'Drongen',
    'Drogenbos',
    'Drieslinter',
    'Drehance',
    'Dranouter',
    'Dour',
    'Dottignies',
    'Dorinne',
    'Roosdaal',
    'Dongelberg',
    'Donceel',
    'Dolhain',
    'Dolembreux',
    'Doische',
    'Dison',
    'Dion-le-Val',
    'Dion',
    'Dinant',
    'Dilsen',
    'Dilbeek',
    'Diksmuide',
    'Dikkebus',
    'Diest',
    'Diepenbeek',
    'Diegem',
    'Deurne',
    'Deurle',
    'Desteldonk',
    'Destelbergen',
    'Desselgem',
    'Dessel',
    'Dergneau',
    'De Planck',
    'De Pinte',
    'De Panne',
    'Dentergem',
    'Denee',
    'Denderwindeke',
    'Dendermonde',
    'Denderleeuw',
    'Denderhoutem',
    'De Klinge',
    'Deinze',
    'De Haan',
    'Deftinge',
    'Deerlijk',
    'Dave',
    'Daussois',
    'Dampremy',
    'Dampicourt',
    'Damme',
    'Dalhem',
    'Daknam',
    'Dadizele',
    'Cul-des-Sarts',
    'Cuesmes',
    'Crupet',
    'Crisnee',
    'Couvin',
    'Couture-Saint-Germain',
    'Coutisse',
    'Couthuin',
    'Court-Saint-Etienne',
    'Courriere',
    'Courcelles',
    'Couillet',
    'Cortil-Wodon',
    'Corswarem',
    'Corroy-le-Grand',
    'Corroy-le-Chateau',
    'Cornesse',
    'Corbais',
    'Comines',
    'Comblain-la-Tour',
    'Comblain-au-Pont',
    'Colfontaine',
    'Cointe',
    'Cognelee',
    'Clermont',
    'Clermont-sous-Huy',
    'Clavier',
    'Clabecq',
    'Ciplet',
    'Ciney',
    'Chiny',
    'Chimay',
    'Chievres',
    'Chevetogne',
    'Cheratte',
    'Chenee',
    'Chaumont-Gistoux',
    'Chaudfontaine',
    'Chatillon',
    'Chatelineau',
    'Chatelet',
    'Chassepierre',
    'Charneux',
    'Charleroi',
    'Chapon-Seraing',
    'Chapelle-lez-Herlaimont',
    'Chapelle-a-Wattines',
    'Chantemelle',
    'Chanly',
    'Chaineux',
    'Ceroux-Mousty',
    'Cerfontaine',
    'Celles',
    'Casteau',
    'Carnieres',
    'Carlsbourg',
    'Cambron-Saint-Vincent',
    'Callenelle',
    'Buvrinnes',
    'Butgenbach',
    'Bury',
    'Burst',
    'Bure',
    'Burdinne',
    'Burcht',
    'Bunsbeek',
    'Büllingen',
    'Buizingen',
    'Buissenal',
    'Buggenhout',
    'Budingen',
    'Brye',
    'Bruyelle',
    'Brussels',
    'Brustem',
    'Brussegem',
    'Bruges',
    'Brugelette',
    'Broechem',
    'Bressoux',
    'Breendonk',
    'Bree',
    'Bredene',
    'Brecht',
    'Bray',
    'Brasschaat',
    'Brasmenil',
    'Braives',
    'Braine-le-Comte',
    'Braine-le-Chateau',
    "Braine-l'Alleud",
    'Braibant',
    'Bracquegnies',
    'Bovesse',
    'Bouwel',
    'Bouvignes-sur-Meuse',
    'Boutersem',
    'Bousval',
    'Boussu-lez-Walcourt',
    'Boussu-en-Fagne',
    'Boussu',
    'Boussoit',
    'Bourlers',
    'Bourdon',
    'Bouillon',
    'Bougnies',
    'Bouge',
    'Bouffioulx',
    'Bost',
    'Bossiere',
    'Borsbeke',
    'Borsbeek',
    'Bornem',
    'Borgloon',
    'Borgerhout',
    'Borchtlombeek',
    'Boortmeerbeek',
    'Boorsem',
    'Boom',
    'Booischot',
    'Bon-Secours',
    'Bonneville',
    'Boninne',
    'Bonheiden',
    'Boncelles',
    'Bombaye',
    'Bomal',
    'Bolland',
    'Bolinne',
    'Bosvoorde',
    "Bois-d'Haine",
    'Bois-de-Villers',
    'Boirs',
    'Boignee',
    'Boezinge',
    'Boekhoute',
    'Boechout',
    'Bocholt',
    'Blicquy',
    'Bleharies',
    'Blegny',
    'Blaugies',
    'Blaton',
    'Blaregnies',
    'Blanmont',
    'Blankenberge',
    'Blanden',
    'Blandain',
    'Blaimont',
    'Blaasveld',
    'Bissegem',
    'Bioul',
    'Binche',
    'Bilzen',
    'Bilstain',
    'Biez',
    'Bièvre',
    'Biesmeree',
    'Biesme',
    'Bierwart',
    'Bierghes',
    'Bierges',
    'Biercee',
    'Bierbeek',
    'Bienne-lez-Happart',
    'Beyne-Heusay',
    'Beverst',
    'Beverlo',
    'Beveren',
    'Beveren-Leie',
    'Bevere',
    'Bever',
    'Bevel',
    'Beuzet',
    'Betekom',
    'Besonrieux',
    'Beselare',
    'Bertrix',
    'Bertree',
    'Bertogne',
    'Bertem',
    'Bernissart',
    'Bernimont',
    'Berneau',
    'Berloz',
    'Berlare',
    'Berlaar',
    'Berismenil',
    'Beringen',
    'Berg',
    'Berendrecht',
    'Sint-Agatha-Berchem',
    'Berchem',
    'Ben-Ahin',
    'Belsele',
    'Beloeil',
    'Bellingen',
    'Bellem',
    'Bellegem',
    'Bellaire',
    'Belgrade',
    'Bekkevoort',
    'Bekegem',
    'Beigem',
    'Begijnendijk',
    'Beez',
    'Beerzel',
    'Beervelde',
    'Beert',
    'Beersel',
    'Beerse',
    'Beernem',
    'Beclers',
    'Beauvechain',
    'Beauraing',
    'Beaumont',
    'Beaufays',
    'Bazel',
    'Bavikhove',
    'Baulers',
    'Bauffe',
    'Baudour',
    'Battice',
    'Batsheers',
    'Bas-Warneton',
    'Bastogne',
    'Bassilly',
    'Bassevelde',
    'Bassenge',
    'Bas-Oha',
    'Basecles',
    'Barvaux',
    'Barry',
    'Barchon',
    'Bande',
    'Bambrugge',
    'Balen',
    'Balegem',
    'Baisieux',
    'Baillonville',
    'Baileux',
    'Baelen',
    'Baasrode',
    'Baarle-Hertog',
    'Baardegem',
    'Baal',
    'Baaigem',
    'Aywaille',
    'Ayeneux',
    'Aye',
    'Awirs',
    'Awans',
    'Avin',
    'Averbode',
    'Avelgem',
    'Auvelais',
    'Autre-Eglise',
    'Aulnois',
    'Audregnies',
    'Auderghem',
    'Aubel',
    'Aubange',
    'Attre',
    'Attert',
    'Attenhoven',
    'Athus',
    'Ath',
    'Astenet',
    'Astene',
    'Assesse',
    'Assent',
    'Assenois',
    'Assenede',
    'Assebroek',
    'Asse',
    'Asquillies',
    'Asper',
    'Aspelare',
    'As',
    'Arsimont',
    'Arquennes',
    'Arlon',
    'Argenteau',
    'Arendonk',
    'Ardooie',
    'Archennes',
    'Arbre',
    'Appelterre-Eichem',
    'Anzegem',
    'Anvaing',
    'Antwerp',
    'Antoing',
    'Anthisnes',
    'Antheit',
    'Anseroeul',
    'Anseremme',
    'Ans',
    'Anloy',
    'Anhée',
    'Angreau',
    'Angre',
    'Angleur',
    'Andrimont',
    'Anderlues',
    'Anderlecht',
    'Andenne',
    'Ampsin',
    'Amougies',
    'Amonines',
    'Amel',
    'Amay',
    'Alveringem',
    'Alsemberg',
    'Alleur',
    'Alken',
    'Aisemont',
    'Aiseau',
    'Aische-en-Refail',
    'Agimont',
    'Adegem',
    'Acoz',
    'Achet',
    'Achene',
    'Achel',
    'Aartselaar',
    'Aarsele',
    'Aarschot',
    'Aalter',
    'Aalst',
    'Aalbeke',
    'Bredene-aan-Zee',
    'Grace-Hollogne',
    'Heuvelland',
    'Affligem',
    'Papignies',
    'Ottignies-Louvain-la-Neuve',
    'Orp-Jauche',
    'Rebecq',
    'Chastre',
    'Walhain',
    'Lennik',
    'Malle',
    'Laakdal',
    'Scherpenheuvel-Zichem',
    'Linter',
    'Tielt-Winge',
    'Houthalen-Helchteren',
    'Heusden-Zolder',
    'Dilsen-Stokkem',
    'Meeuwen-Gruitrode',
    'Voeren',
    'Hamont-Achel',
    'Hechtel-Eksel',
    'Neupre',
    'Flemalle',
    'Reuland',
    'Thimister-Clermont',
    'Sambreville',
    'Fernelmont',
    'Hastiere',
    'Ham-sur-Heure-Nalinnes',
    'Les Bons Villers',
    'Aiseau-Presles',
    'Sivry-Rance',
    'Aartrijke',
    'Zwalm',
  ],
  Germany: [
    'Zwoenitz',
    'Zwochau',
    'Zwingenberg',
    'Zwiesel',
    'Zwiefalten',
    'Zwickau',
    'Zwenkau',
    'Zweiflingen',
    'Zweifall',
    'Zweibrücken',
    'Zusmarshausen',
    'Zusch',
    'Zusamaltheim',
    'Zulpich',
    'Zuehlsdorf',
    'Zuggelrade',
    'Zschorna',
    'Zschorlau',
    'Zschopau',
    'Zschepplin',
    'Zschaitz',
    'Zotzenheim',
    'Zossen',
    'Zoeschingen',
    'Zornheim',
    'Zorneding',
    'Zoerbig',
    'Zolling',
    'Zoebingen',
    'Zitzschen',
    'Zittau',
    'Zirzow',
    'Zirndorf',
    'Ostseebad Zinnowitz',
    'Zingst',
    'Zimmern ob Rottweil',
    'Ziltendorf',
    'Zilly',
    'Ziethen',
    'Ziesendorf',
    'Ziesar',
    'Zierzow',
    'Ziertheim',
    'Zierow',
    'Zierenberg',
    'Ziepel',
    'Ziemitz',
    'Ziemetshausen',
    'Zielitz',
    'Zielheim',
    'Zichow',
    'Zeven',
    'Zeutsch',
    'Zeuthen',
    'Zeulenroda',
    'Zettlitz',
    'Zetel',
    'Zerrenthin',
    'Zernin',
    'Zernien',
    'Zerf',
    'Zerbst',
    'Zenting',
    'Zempin',
    'Zemmer',
    'Zell unter Aichelberg',
    'Zellingen',
    'Zell im Wiesental',
    'Mainhausen',
    'Zell am Main',
    'Zell am Harmersbach',
    'Zella-Mehlis',
    'Zella',
    'Zell',
    'Zeitz',
    'Zeitlofs',
    'Zeithain',
    'Zeiskam',
    'Zeilarn',
    'Zeil',
    'Zehna',
    'Zehdenick',
    'Zechlinerhuette',
    'Zechin',
    'Zarrentin',
    'Zarrendorf',
    'Zarpen',
    'Zarnewanz',
    'Zarnekau',
    'Zapfendorf',
    'Zapel',
    'Zangberg',
    'Zandt',
    'Zaisertshofen',
    'Zaisenhausen',
    'Zahna',
    'Zahlbach',
    'Zachenberg',
    'Zaberfeld',
    'Xanten',
    'Wyk auf Foehr',
    'Wyhl',
    'Wutoeschingen',
    'Wuthenow',
    'Wutha',
    'Wustrow',
    'Wustrau',
    'Wuestheuterode',
    'Wusterwitz',
    'Wustermark',
    'Wusterhusen',
    'Wusterhausen',
    'Wustenrot',
    'Wuschheim',
    'Wurzen',
    'Wurzburg',
    'Wurzbach',
    'Wurz',
    'Wuerselen',
    'Wurmsham',
    'Wurmlingen',
    'Wurmberg',
    'Wurmannsquick',
    'Wuppertal',
    'Wunstorf',
    'Wunsiedel',
    'Wulknitz',
    'Wulften',
    'Wulfsen',
    'Wuelfershausen',
    'Wrohm',
    'Wrixum',
    'Wrist',
    'Wriezen',
    'Wriedel',
    'Wrestedt',
    'Wredenhagen',
    'Wozinkel',
    'Wotenitz',
    'Woerthsee',
    'Worth an der Isar',
    'Worth an der Donau',
    'Woerth am Rhein',
    'Worth am Main',
    'Wort',
    'Worrstadt',
    'Worpswede',
    'Wormsdorf',
    'Worms',
    'Woermlitz',
    'Woringen',
    'Worfelden',
    'Woerbzig',
    'Worbis',
    'Woppenroth',
    'Wonsheim',
    'Wonsees',
    'Womrath',
    'Woelzow',
    'Woltersdorf',
    'Wolsfeld',
    'Wolsdorf',
    'Wolpinghausen',
    'Wolpertswende',
    'Wolpertshausen',
    'Wolnzach',
    'Wolmirstedt',
    'Wolmirsleben',
    'Wollstein',
    'Wollmerschied',
    'Wollin',
    'Wollbach',
    'Wolkramshausen',
    'Wolkenstein',
    'Wolken',
    'Wolgast',
    'Wolfstein',
    'Wolfschlugen',
    'Wolfsburg-Unkeroda',
    'Wolfsburg',
    'Wolfsbuch',
    'Wolfratshausen',
    'Wolframs-Eschenbach',
    'Wolfhagen',
    'Wolfertschwenden',
    'Wolferstedt',
    'Wolferstadt',
    'Wolfersheim',
    'Wolfersdorf',
    'Wolferode',
    'Wolferlingen',
    'Wolfenbüttel',
    'Wolfen',
    'Wolfegg',
    'Wolfartsweier',
    'Wolfach',
    'Woldert',
    'Woldegk',
    'Wohrden',
    'Wohnste',
    'Wohlde',
    'Woggersin',
    'Wixhausen',
    'Witzmannsberg',
    'Witzleben',
    'Witzlasreuth',
    'Witzin',
    'Witzhave',
    'Witzenhausen',
    'Witzeeze',
    'Wittstock',
    'Wittorf',
    'Wittnau',
    'Wittmund',
    'Wittlich',
    'Wittislingen',
    'Wittingen',
    'Wittichenau',
    'Wittibreut',
    'Wittgert',
    'Wittenhofen',
    'Wittenfoerden',
    'Wittenburg',
    'Wittenborn',
    'Wittenberge',
    'Witten',
    'Wittelshofen',
    'Wittchendorf',
    'Wittbek',
    'Wissmannsdorf',
    'Wissen',
    'Wismar',
    'Wischuer',
    'Wischhafen',
    'Wisch',
    'Wirscheid',
    'Wirsberg',
    'Wirges',
    'Wippingen',
    'Wippershain',
    'Wipperfürth',
    'Wipfeld',
    'Winzlar',
    'Winzer',
    'Winzeln',
    'Wintrich',
    'Winterwerb',
    'Winterspelt',
    'Wintersdorf',
    'Winterscheid',
    'Winterlingen',
    'Winterhausen',
    'Winterberg',
    'Winterbach',
    'Winsen',
    'Winnweiler',
    'Winningen',
    'Winnert',
    'Winnenden',
    'Winnen',
    'Winnemark',
    'Winklarn',
    'Winkelhaid',
    'Winhoring',
    'Wingst',
    'Wingerode',
    'Windsbach',
    'Windorf',
    'Windischleuba',
    'Windischeschenbach',
    'Windhagen',
    'Windesheim',
    'Winden',
    'Windehausen',
    'Windeck',
    'Windeby',
    'Windbergen',
    'Windberg',
    'Windach',
    'Wincheringen',
    'Wimsheim',
    'Wimmelburg',
    'Wimbach',
    'Wiltingen',
    'Wilthen',
    'Wilsum',
    'Wilster',
    'Wilstedt',
    'Wilsleben',
    'Wilsdruff',
    'Wilnsdorf',
    'Willstaett',
    'Willroth',
    'Willmenrod',
    'Willmars',
    'Willingshausen',
    'Willingen',
    'Willich',
    'Willebadessen',
    'Wilkau-Hasslau',
    'Wilhermsdorf',
    'Wilhelmsthal',
    'Wilhelmshaven',
    'Wilhelmsfeld',
    'Wilhelmsdorf',
    'Wilhelmsburg',
    'Wilhelminenhof',
    'Wilgartswiesen',
    'Wildsteig',
    'Wildpoldsried',
    'Wildflecken',
    'Wildeshausen',
    'Wildenfels',
    'Wildenberg',
    'Wildemann',
    'Wildberg',
    'Bad Wildbad',
    'Wildau',
    'Wiggensbach',
    'Wietzendorf',
    'Wietzen',
    'Wietze',
    'Wietmarschen',
    'Wiesweiler',
    'Wiesthal',
    'Wiesmoor',
    'Wiesloch',
    'Wieseth',
    'Wiesentheid',
    'Wiesenthau',
    'Wiesenthal',
    'Wiesent',
    'Wiesenstetten',
    'Wiesensteig',
    'Wiesenfelden',
    'Wiesenfeld',
    'Wiesenburg',
    'Wiesenbronn',
    'Wiesenbach',
    'Wiesenau',
    'Wiesemscheid',
    'Wiesbaum',
    'Wiesbaden',
    'Wiesbach',
    'Wiesau',
    'Wiesa',
    'Wierthe',
    'Wiersdorf',
    'Wiernsheim',
    'Wieren',
    'Wienrode',
    'Wienhausen',
    'Wiendorf',
    'Wiemersdorf',
    'Wielenbach',
    'Wiek',
    'Wiehl',
    'Wiehe',
    'Wiegendorf',
    'Wiefelstede',
    'Wiednitz',
    'Wiederstedt',
    'Wiedergeltingen',
    'Wiederau',
    'Wiedenzhausen',
    'Wiedensahl',
    'Wiedemar',
    'Wiebelskirchen',
    'Wiebelsheim',
    'Widdern',
    'Wickerstedt',
    'Wickede',
    'Weyher',
    'Weyhe',
    'Weyhausen',
    'Weyersfeld',
    'Weyerbusch',
    'Weyarn',
    'Wewelsfleth',
    'Wewelsburg',
    'Wetzlar',
    'Wettstetten',
    'Wettringen',
    'Wettin',
    'Wettesingen',
    'Wetter (Ruhr)',
    'Wetter',
    'Wetschen',
    'Westre',
    'Westhofen',
    'Westheim',
    'Westhausen',
    'Westgreussen',
    'Westerstetten',
    'Westerstede',
    'Westerronfeld',
    'Westerrade',
    'Westernohe',
    'Westerland',
    'Westerkappeln',
    'Westerhorn',
    'Westerholt',
    'Westerhof',
    'Westerheim',
    'Westergellersen',
    'Westeregeln',
    'Westerdeichstrich',
    'Westerburg',
    'Westensee',
    'Westenhausen',
    'Westendorf',
    'Westen',
    'Weste',
    'Wessling',
    'Wessin',
    'Wesseling',
    'Wesselburen',
    'Wesendorf',
    'Wesenberg',
    'Weselberg',
    'Wesel',
    'Wertlau',
    'Wertingen',
    'Werther',
    'Wertheim am Main',
    'Wertach',
    'Werpeloh',
    'Wernigerode',
    'Werneuchen',
    'Wernesgruen',
    'Wernersberg',
    'Werneck',
    'Werne',
    'Wernburg',
    'Wernberg-Koblitz',
    'Wernau',
    'Wermsdorf',
    'Wermerichshausen',
    'Wermelskirchen',
    'Werlte',
    'Werlaburgdorf',
    'Werl',
    'Werkhausen',
    'Werdohl',
    'Werder',
    'Werdau',
    'Werda',
    'Werben',
    'Werbach',
    'Wenzendorf',
    'Wenzenbach',
    'Wentorf bei Hamburg',
    'Wentorf',
    'Wensin',
    'Wenningstedt',
    'Wennigsen',
    'Weng',
    'Wendtorf',
    'Wendorf',
    'Wendlingen am Neckar',
    'Wendisch Rietz',
    'Wendisch Evern',
    'Wenden',
    'Wendelstein',
    'Wendelsheim',
    'Wendeburg',
    'Wemmetsweiler',
    'Wemding',
    'Wembach',
    'Welzow',
    'Welzheim',
    'Welver',
    'Welschbillig',
    'Welmbuttel',
    'Welling',
    'Wellheim',
    'Wellendingen',
    'Wellen',
    'Welle',
    'Welgesheim',
    'Welden',
    'Welcherath',
    'Weitramsdorf',
    'Weitnau',
    'Weiterstadt',
    'Weitersburg',
    'Weitefeld',
    'Weisweil',
    'Weisswasser',
    'Weissig',
    'Weissenthurm',
    'Weissenstein',
    'Weissenstadt',
    'Weissensee',
    'Weissensberg',
    'Weissenohe',
    'Weissenhorn',
    'Weissenfels',
    'Weissendorf',
    'Weissenburg in Bayern',
    'Weissenbrunn',
    'Weissenborn',
    'Weissenberg',
    'Weissdorf',
    'Weissbach',
    'Weissandt-Goelzau',
    'Weissach',
    'Weismain',
    'Weiskirchen',
    'Weisenheim am Sand',
    'Weisenheim am Berg',
    'Weisendorf',
    'Weisenbach',
    'Weisen',
    'Weisel',
    'Weisdin',
    'Weischlitz',
    'Weinstadt-Endersbach',
    'Weinsheim',
    'Weinsberg',
    'Weinolsheim',
    'Weinheim',
    'Weingarten',
    'Weinbohla',
    'Weinbach',
    'Weinaehr',
    'Weimar',
    'Weilmuenster',
    'Weil im Schonbuch',
    'Stuttgart-Weilimdorf',
    'Weilheim an der Teck',
    'Weilheim',
    'Weilerswist',
    'Weilerbach',
    'Weiler',
    'Weil der Stadt',
    'Weilburg',
    'Weilbach',
    'Weilar',
    'Weil am Rhein',
    'Weil',
    'Weikersheim',
    'Weihmichl',
    'Weiherhammer',
    'Weihenzell',
    'Weidorf',
    'Weiding',
    'Weidhausen',
    'Weidenthal',
    'Weidenstetten',
    'Weidenhahn',
    'Weidenberg',
    'Weidenbach',
    'Weiden',
    'Weida',
    'Weichering',
    'Weibersbrunn',
    'Weibern',
    'Wehringen',
    'Wehrheim',
    'Wehr',
    'Wehnde',
    'Wehlen',
    'Wehingen',
    'Wegscheid',
    'Wegenstedt',
    'Wegberg',
    'Weferlingen',
    'Wefensleben',
    'Weeze',
    'Weetzen',
    'Wees',
    'Weertzen',
    'Weener',
    'Wedringen',
    'Wedendorf',
    'Wedel',
    'Weddingstedt',
    'Weddersleben',
    'Weddelbrook',
    'Weddel',
    'Wechselburg',
    'Wechmar',
    'Wechingen',
    'Waxweiler',
    'Wattmannshagen',
    'Wattenheim',
    'Wattendorf',
    'Wattenbek',
    'Wathlingen',
    'Wasungen',
    'Wassertrudingen',
    'Wasserlosen',
    'Wasserliesch',
    'Wasserleben',
    'Wasserburg am Inn',
    'Wasserburg',
    'Wasseralfingen',
    'Wassenberg',
    'Wassenach',
    'Waschow',
    'Waschleithe',
    'Waschenbeuren',
    'Wasbuttel',
    'Wasbek',
    'Warwerort',
    'Wartmannsroth',
    'Warthausen',
    'Wartenberg',
    'Warstein',
    'Warpe',
    'Warnow',
    'Warngau',
    'Warnau',
    'Warmsroth',
    'Warmsen',
    'Warmensteinach',
    'Warmenau',
    'Warmbronn',
    'Warlitz',
    'Warle',
    'Warin',
    'Warendorf',
    'Waren',
    'Wardow',
    'Wardenburg',
    'Warburg',
    'Wanzleben',
    'Wansdorf',
    'Wannweil',
    'Wanna',
    'Wankendorf',
    'Wangerooge',
    'Wangen',
    'Wangels',
    'Wang',
    'Wandlitz',
    'Wanderup',
    'Walzbachtal',
    'Waltrop',
    'Walting',
    'Waltershausen',
    'Waltersdorf',
    'Walterschen',
    'Walternienburg',
    'Waltenhofen',
    'Walsrode',
    'Walsleben',
    'Walsheim',
    'Walshausen',
    'Walsdorf',
    'Walschleben',
    'Walpertskirchen',
    'Wallstawe',
    'Wallrath',
    'Wallmerod',
    'Wallhausen',
    'Wallgau',
    'Wallertheim',
    'Wallerstein',
    'Wallersheim',
    'Wallersdorf',
    'Wallerfing',
    'Wallerfangen',
    'Wallenhorst',
    'Wallenfels',
    'Walldurn',
    'Walldorf',
    'Wallbach',
    'Walksfelde',
    'Walkenried',
    'Walheim',
    'Waldweiler',
    'Waldthurn',
    'Waldstetten',
    'Waldsieversdorf',
    'Waldsiedlung',
    'Waldshut-Tiengen',
    'Waldshut',
    'Waldsee',
    'Waldsassen',
    'Waldrohrbach',
    'Waldrach',
    'Waldorf',
    'Waldmunchen',
    'Waldmuhlen',
    'Waldmohr',
    'Wald-Michelbach',
    'Waldlaubersheim',
    'Waldkraiburg',
    'Waldkirchen',
    'Waldkirch',
    'Waldkappel',
    'Waldhof-Falkenstein',
    'Waldheim',
    'Waldfischbach-Burgalben',
    'Waldfeucht',
    'Waldesch',
    'Waldershof',
    'Walderbach',
    'Waldenburg',
    'Waldenbuch',
    'Waldeck',
    'Walddorfhaeslach',
    'Waldbuttelbrunn',
    'Waldburg',
    'Waldbrunn',
    'Waldbroel',
    'Waldbreitbach',
    'Waldbockelheim',
    'Waldaschaff',
    'Wald an der Alz',
    'Wald-Amorbach',
    'Waldalgesheim',
    'Wald',
    'Walchum',
    'Walbeck',
    'Waischenfeld',
    'Wain',
    'Waigolshausen',
    'Waidhofen',
    'Waidhaus',
    'Waibstadt',
    'Waiblingen',
    'Wahrenholz',
    'Wahrenberg',
    'Wahns',
    'Wahlwies',
    'Wahlstedt',
    'Wahlrod',
    'Wahlitz',
    'Wahlbach',
    'Waging am See',
    'Waghausel',
    'Wagenfeld',
    'Waffenbrunn',
    'Wadgassen',
    'Wadersloh',
    'Wadern',
    'Waddeweitz',
    'Wackersleben',
    'Wackersdorf',
    'Wackersberg',
    'Wackernheim',
    'Wacken',
    'Wachtnitz',
    'Wachtendonk',
    'Wachtberg',
    'Wachenroth',
    'Wachenheim',
    'Wachau',
    'Wabern',
    'Waal',
    'Waakirchen',
    'Waake',
    'Waabs',
    'Vreschen-Bokel',
    'Vrees',
    'Vreden',
    'Vorstetten',
    'Vorra',
    'Vordorf',
    'Vorderweidenthal',
    'Volxheim',
    'Voltlage',
    'Vollmersbach',
    'Vollersode',
    'Volksdorf',
    'Volkmarsen',
    'Voelklingen',
    'Volkerzen',
    'Volkertshausen',
    'Volkenschwand',
    'Volkach',
    'Voigtstedt',
    'Voigtsgruen',
    'Voigtsdorf',
    'Vohwinkel',
    'Vohringen',
    'Vohrenbach',
    'Vohl',
    'Vohenstrauss',
    'Vohburg an der Donau',
    'Vogtareuth',
    'Vogt',
    'Vogelsdorf',
    'Vogelsberg',
    'Vogelsang',
    'Voerde',
    'Vockerode',
    'Vlotho',
    'Vitte',
    'Visselhoevede',
    'Visquard',
    'Visbek',
    'Vipperow',
    'Viol',
    'Vinningen',
    'Vilshofen',
    'Vilseck',
    'Vilsbiburg',
    'Villmar',
    'Villingen-Schwenningen',
    'Villingendorf',
    'Vilgertshofen',
    'Viezen',
    'Viersen',
    'Viernheim',
    'Vierkirchen',
    'Vierhofen',
    'Viereck',
    'Vienenburg',
    'Vielitz',
    'Vielist',
    'Vielbach',
    'Vielank',
    'Viechtach',
    'Viecht',
    'Vettweiss',
    'Vettelschoss',
    'Vetschau',
    'Vestenbergsgreuth',
    'Vesser',
    'Versmold',
    'Verl',
    'Veringenstadt',
    'Verden an der Aller',
    'Ventschow',
    'Veltheim',
    'Velten',
    'Velsdorf',
    'Velpke',
    'Vellberg',
    'Vellahn',
    'Velen',
    'Veldenz',
    'Velden',
    'Velburg',
    'Velbert',
    'Veitsteinbach',
    'Veitshochheim',
    'Veitsbronn',
    'Veilsdorf',
    'Vehlow',
    'Vehlitz',
    'Veelboeken',
    'Vechta',
    'Vechelde',
    'Vaterstetten',
    'Vastorf',
    'Varel',
    'Valluhn',
    'Valley',
    'Vallendar',
    'Vaihingen an der Enz',
    'Vahldorf',
    'Vachendorf',
    'Vachdorf',
    'Vacha',
    'Vaale',
    'Utzenfeld',
    'Utting am Ammersee',
    'Uttenweiler',
    'Uttenreuth',
    'Uthleben',
    'Uetersen',
    'Utendorf',
    'Utecht',
    'Utarp',
    'Ustersbach',
    'Usseln',
    'Uslar',
    'Usingen',
    'Userin',
    'Usedom',
    'Urzig',
    'Urspringen',
    'Ursensollen',
    'Ursberg',
    'Urmitz',
    'Urmersbach',
    'Urexweiler',
    'Urbar',
    'Bad Urach',
    'Uplengen',
    'Upgant-Schott',
    'Upahl',
    'Untrasried',
    'Unterwossen',
    'Unterwellenborn',
    'Unterweissbach',
    'Unterweikertshofen',
    'Unterthingau',
    'Unterstedt',
    'Unterstadion',
    'Untersiemau',
    'Untershausen',
    'Unterschneidheim',
    'Unterschleissheim',
    'Unterschleichach',
    'Unterschefflenz',
    'Unterroth',
    'Unterreit',
    'Unterreichenbach',
    'Unterpleichfeld',
    'Unterostendorf',
    'Unterneukirchen',
    'Untermunkheim',
    'Untermuhlhausen',
    'Untermettingen',
    'Untermerzbach',
    'Untermeitingen',
    'Untermassfeld',
    'Untermarchtal',
    'Unterleinleiter',
    'Unterlangenstadt',
    'Unterkochen',
    'Unterkirnach',
    'Unterhausen',
    'Unterhaching',
    'Untergruppenbach',
    'Untergriesbach',
    'Unterfoehring',
    'Untereppach',
    'Unterensingen',
    'Untereisesheim',
    'Unteregg',
    'Unterdietfurt',
    'Unterdiessen',
    'Unterdielbach',
    'Unterbronnen',
    'Unterbreizbach',
    'Unterammergau',
    'Unteraltertheim',
    'Unteraltenbernheim',
    'Unsleben',
    'Unnau',
    'Unna',
    'Unlingen',
    'Unkel',
    'Ungerhausen',
    'Unering',
    'Undenheim',
    'Umpfenbach',
    'Ummerstadt',
    'Ummern',
    'Ummendorf',
    'Ummanz',
    'Umkirch',
    'Ulsnis',
    'Ulrichstein',
    'Ulmen',
    'Ulm',
    'Uehlfeld',
    'Uhingen',
    'Uffing',
    'Uffenheim',
    'Uetze',
    'Uettingen',
    'Uelzen',
    'Uelversheim',
    'Uelsen',
    'Uedem',
    'Ueckermuende',
    'UEckeritz',
    'Udestedt',
    'Udersdorf',
    'Uder',
    'Udenheim',
    'Uchtelhausen',
    'Uchte',
    'Ubersee',
    'Uberlingen',
    'Uberherrn',
    'UEbach-Palenberg',
    'Tschernitz',
    'Tyrlaching',
    'Twistringen',
    'Twist',
    'Twieflingen',
    'Twedt',
    'Tuetzpatz',
    'Tutzing',
    'Tuttlingen',
    'Tuettleben',
    'Tuttendorf',
    'Tutschfelden',
    'Tuessling',
    'Tussenhausen',
    'Tuerkismuehle',
    'Turkheim',
    'Tunzenhausen',
    'Tuntenhausen',
    'Tuningen',
    'Tullau',
    'Tulau',
    'Tuchenbach',
    'Tucheim',
    'Trusetal',
    'Truenzig',
    'Trunkelsberg',
    'Truchtlaching',
    'Trostberg an der Alz',
    'Trostau',
    'Trossingen',
    'Trossin',
    'Troisdorf',
    'Trogen',
    'Trockenborn-Wolfersdorf',
    'Trochtelfingen',
    'Trobnitz',
    'Trittau',
    'Triptis',
    'Trippstadt',
    'Trinwillershagen',
    'Trimport',
    'Triftern',
    'Trierweiler',
    'Trier',
    'Triefenstein',
    'Tribsees',
    'Triberg',
    'Treysa',
    'Treuenbrietzen',
    'Treuen',
    'Treuchtlingen',
    'Treschklingen',
    'Trent',
    'Trennewurth',
    'Trendelburg',
    'Tremsbuttel',
    'Treis-Karden',
    'Treia',
    'Treffurt',
    'Treffelstein',
    'Trebur',
    'Trebsen',
    'Trebnitz',
    'Trebgast',
    'Trebendorf',
    'Treben',
    'Trebbin',
    'Trautskirchen',
    'Traustadt',
    'Traunwalchen',
    'Traunstein',
    'Traunreut',
    'Trassenheide',
    'Trassem',
    'Trassdorf',
    'Trappstadt',
    'Trappenkamp',
    'Tramm',
    'Traitsching',
    'Train',
    'Trabitz',
    'Traben-Trarbach',
    'Trabelsdorf',
    'Tottleben',
    'Tosterglope',
    'Tostedt',
    'Tornow',
    'Tornesch',
    'Torgelow',
    'Torgau',
    'Toppenstedt',
    'Toenning',
    'Toenisvorst',
    'Toging am Inn',
    'Todtnauberg',
    'Todtnau',
    'Au',
    'Todtmoos',
    'Todtenweis',
    'Todesfelde',
    'Todenbuttel',
    'Titz',
    'Tittmoning',
    'Tittling',
    'Titting',
    'Titisee-Neustadt',
    'Tiste',
    'Tirschenreuth',
    'Tirpersdorf',
    'Timmendorf Beach',
    'Timmel',
    'Timmdorf',
    'Tiefenthal',
    'Tiefenort',
    'Tiefenbronn',
    'Tiefenbach',
    'Tiefenau',
    'Thyrow',
    'Thyrnau',
    'Thurnau',
    'Thurmansbang',
    'Thuerkow',
    'Thuer',
    'Thungersheim',
    'Thumby',
    'Thum',
    'Thulendorf',
    'Thuine',
    'Thossfell',
    'Thomm',
    'Thomasburg',
    'Tholey',
    'Thierstein',
    'Thiersheim',
    'Thierhaupten',
    'Thiendorf',
    'Theuma',
    'Theuern',
    'Thermalbad Wiesenbad',
    'Thenried',
    'Themar',
    'Thelkow',
    'Theisseil',
    'Theilheim',
    'Theessen',
    'Thedinghausen',
    'Tharandt',
    'Thanstein',
    'Thannhausen',
    'Thamsbrueck',
    'Thalwenden',
    'Thalmassing',
    'Thalmannsfeld',
    'Thallwitz',
    'Thallichtenberg',
    'Thalheim',
    'Thalhausen',
    'Thalfingen',
    'Thaleischweiler-Froschen',
    'Thale',
    'Thaining',
    'Teutschenthal',
    'Teutleben',
    'Teuschnitz',
    'Teupitz',
    'Teunz',
    'Teugn',
    'Teuchern',
    'Teublitz',
    'Tettnang Castle',
    'Tettenweis',
    'Tettau',
    'Teterow',
    'Tetenhusen',
    'Tetenbull',
    'Testorf-Steinfort',
    'Tessin',
    'Tessenow',
    'Tespe',
    'Teschendorf',
    'Teplingen',
    'Teningen',
    'Tengen',
    'Templin',
    'Teltow',
    'Tellingstedt',
    'Tellig',
    'Telgte',
    'Teistungen',
    'Teisnach',
    'Teising',
    'Teisendorf',
    'Tegernsee',
    'Tegernheim',
    'Tecklenburg',
    'Tawern',
    'Tautenhain',
    'Tautenburg',
    'Taura',
    'Tauperlitz',
    'Taunusstein',
    'Taufkirchen',
    'Tauer',
    'Tauche',
    'Taucha',
    'Tauberbischofsheim',
    'Tastungen',
    'Tastrup',
    'Markt Taschendorf',
    'Tarthun',
    'Tarp',
    'Tarnow',
    'Tarmstedt',
    'Tarbek',
    'Tappendorf',
    'Tapfheim',
    'Tantow',
    'Tannheim',
    'Tannhausen',
    'Tannesberg',
    'Tanna',
    'Tann',
    'Tangstedt',
    'Tangermuende',
    'Tangerhuette',
    'Tamm',
    'Tambach-Dietharz',
    'Talkau',
    'Talheim',
    'Tagmersheim',
    'Taferrot',
    'Taching am See',
    'Tacherting',
    'Taben-Rodt',
    'Tabarz',
    'Syke',
    'Sustrum',
    'Sussen',
    'Suesel',
    'Surwold',
    'Surheim',
    'Surberg',
    'Supplingen',
    'Sueplingen',
    'Sunstedt',
    'Sundern',
    'Sulzheim',
    'Sulzfeld am Main',
    'Suelzfeld',
    'Sulzfeld',
    'Sulzemoos',
    'Sulzdorf',
    'Sulzburg',
    'Sulzberg',
    'Sulzbach-Rosenberg',
    'Sulzbach an der Murr',
    'Sulzbach am Main',
    'Sulzbach am Kocher',
    'Sulzbach',
    'Sulz am Neckar',
    'Sulingen',
    'Sulfeld',
    'Sukow',
    'Suhlendorf',
    'Suhl',
    'Sugenheim',
    'Sudwalde',
    'Sudlohn',
    'Suederwalsede',
    'Suederstapel',
    'Suederluegum',
    'Suderfahrenstedt',
    'Suderburg',
    'Suderbrarup',
    'Suckow',
    'Stuvenborn',
    'Stutzengrun',
    'Stuttgart',
    'Stutensee',
    'Stulln',
    'Stukenbrock',
    'Stuhr',
    'Stuhlingen',
    'Stubenberg',
    'Struxdorf',
    'Struth',
    'Struppen',
    'Strullendorf',
    'Strukdorf',
    'Struckum',
    'Stromberg',
    'Strohn',
    'Strohkirchen',
    'Stroebeck',
    'Stresow',
    'Streithausen',
    'Strehla',
    'Straussfurt',
    'Strausberg',
    'Straupitz',
    'Straubing',
    'Strasskirchen',
    'Strassenhaus',
    'Bessenbach',
    'Strassberg',
    'Strasburg',
    'Strande',
    'Stralsund',
    'Stralendorf',
    'Strahlungen',
    'Straelen',
    'Strackholt',
    'Stove',
    'Stotzheim',
    'Stottwang',
    'Stotten am Auerberg',
    'Stotten',
    'Stoernstein',
    'Storkow',
    'Stolzenau',
    'Stolpen',
    'Stolpe',
    'Stollberg',
    'Stolk',
    'Stolberg',
    'Stoeffin',
    'Stoetze',
    'Stodtlen',
    'Stockstadt am Main',
    'Stockstadt am Rhein',
    'Stocksee',
    'Stockheim',
    'Stockelsdorf',
    'Stockach',
    'Stimpfach',
    'Steyerberg',
    'Steutz',
    'Stettfeld',
    'Stettenhofen',
    'Stetten am Kalten Markt',
    'Stetten',
    'Sterup',
    'Sternenfels',
    'Sternberg',
    'Sterley',
    'Stephansposching',
    'Stephanskirchen',
    'Stennweiler',
    'Stendal',
    'Stemmen',
    'Stelzenberg',
    'Stelle',
    'Steisslingen',
    'Steinwiesen',
    'Steinwenden',
    'Steinweiler',
    'Steinsdorf',
    'Steinsberg',
    'Stein-Neukirch',
    'Steinmauern',
    'Steinmark',
    'Steinkirchen',
    'Steinitz',
    'Steinigtwolmsdorf',
    'Steinhude',
    'Steinhorst',
    'Steinhoring',
    'Steinhofel',
    'Steinheuterode',
    'Steinheim am der Murr',
    'Steinheim am Albuch',
    'Steinheim',
    'Steinheid',
    'Steinhausen an der Rottum',
    'Steinhagen',
    'Steingaden',
    'Steinfurth',
    'Steinfurt',
    'Steinfeld',
    'Steinenbronn',
    'Steinenberg',
    'Steinen',
    'Steinegg',
    'Steinefrenz',
    'Steinebach',
    'Steindorf',
    'Stein-Bockenheim',
    'Steinbergkirche',
    'Steinberg',
    'Stein',
    'Steinbach-Hallenberg',
    'Steinbach am Wald',
    'Steinbach am Taunus',
    'Steinbach',
    'Steinau an der Strasse',
    'Steinach',
    'Steina',
    'Steimel',
    'Steimbke',
    'Stiefenhofen',
    'Stegen',
    'Stegelitz',
    'Stegaurach',
    'Steffenshagen',
    'Steffeln',
    'Stebbach',
    'Staufen im Breisgau',
    'Staufenberg',
    'Staudt',
    'Staudernheim',
    'Stauchitz',
    'Stassfurt',
    'Starnberg',
    'Starkenberg',
    'Stapelfeld',
    'Stamsried',
    'Stammham',
    'Stallwang',
    'Staig',
    'Stahnsdorf',
    'Bad Staffelstein',
    'Staffelbach',
    'Stadum',
    'Stadt Wehlen',
    'Stadtsteinach',
    'Stadtroda',
    'Stadtoldendorf',
    'Stadtlohn',
    'Stadtlengsfeld',
    'Stadtlauringen',
    'Stadtkyll',
    'Stadtilm',
    'Stadthagen',
    'Stadtbergen',
    'Stadtallendorf',
    'Stadlern',
    'Stade',
    'Sproetau',
    'Sprockhoevel',
    'Springe',
    'Sprendlingen',
    'Spremberg',
    'Sprakensehl',
    'Spraitbach',
    'Spornitz',
    'Sponholz',
    'Sponheim',
    'Splietsdorf',
    'Spitzkunnersdorf',
    'Spiesen-Elversberg',
    'Spielberg',
    'Spiekeroog',
    'Spiegelberg',
    'Speyer',
    'Spenge',
    'Spelle',
    'Speinshart',
    'Speichersdorf',
    'Speicher',
    'Spechbach',
    'Spatzenhausen',
    'Sparneck',
    'Spardorf',
    'Spantekow',
    'Spangenberg',
    'Spangdahlem',
    'Spalt',
    'Spaichingen',
    'Spaden',
    'Spabrucken',
    'Soyen',
    'Sottrum',
    'Soetern',
    'Sorup',
    'Sorgenloch',
    'Sophienhamm',
    'Sontra',
    'Sonthofen',
    'Sontheim an der Brenz',
    'Sontheim',
    'Sonsbeck',
    'Sonnewalde',
    'Sonnenstein',
    'Sonnenberg-Winnenberg',
    'Sonnenberg',
    'Sonnefeld',
    'Sonnebull',
    'Sonneborn',
    'Sonneberg',
    'Sondershausen',
    'Sonderhofen',
    'Sommersdorf',
    'Sommerhausen',
    'Soemmerda',
    'Sommerach',
    'Soltendieck',
    'Soltau',
    'Sollwitt',
    'Sollstedt',
    'Soellingen',
    'Sollerup',
    'Solingen',
    'Sohrschied',
    'Sohren',
    'Sohnstedt',
    'Sohlde',
    'Sohland am Rotstein',
    'Sohland',
    'Sogel',
    'Soest',
    'Soderstorf',
    'Sochtenau',
    'Bad Sobernheim',
    'Slate',
    'Sitzendorf',
    'Sittensen',
    'Sittenbach',
    'Sinzing',
    'Sinzig',
    'Sinzheim',
    'Sinspelt',
    'Sinsheim',
    'Sinningen',
    'Sinn',
    'Singhofen',
    'Singenbach',
    'Singen',
    'Sindolsheim',
    'Sindelsdorf',
    'Sindelfingen',
    'Simonsberg',
    'Simmozheim',
    'Simmersfeld',
    'Simmern',
    'Simmertal',
    'Simmerath',
    'Simmelsdorf',
    'Simbach am Inn',
    'Simbach',
    'Silzen',
    'Silz',
    'Silbitz',
    'Sigmarszell',
    'Sigmaringendorf',
    'Sigmaringen',
    'Siglingen',
    'Sieverstedt',
    'Sievershuetten',
    'Sietow',
    'Sietow Dorf',
    'Siershahn',
    'Sierksdorf',
    'Sien',
    'Siemen',
    'Sielmingen',
    'Sielenbach',
    'Siek',
    'Siegsdorf',
    'Siegenburg',
    'Siegen',
    'Siegelsbach',
    'Siegburg',
    'Siefersheim',
    'Siedenburg',
    'Siedenbollentin',
    'Siebeldingen',
    'Sickerode',
    'Sichtigvor',
    'Sichelnstein',
    'Sibbesse',
    'Sexau',
    'Seukendorf',
    'Seubersdorf',
    'Setzingen',
    'Seth',
    'Sesslach',
    'Sessenhausen',
    'Sessenbach',
    'Sersheim',
    'Serrig',
    'Sereetz',
    'Sennfeld',
    'Sengenthal',
    'Senftenberg',
    'Sendenhorst',
    'Senden',
    'Semmenstedt',
    'Semlow',
    'Selzen',
    'Selters',
    'Selsingen',
    'Selmsdorf',
    'Selm',
    'Sellstedt',
    'Seligenthal',
    'Seligenstadt',
    'Selent',
    'Selchenbach',
    'Selbitz',
    'Selbach',
    'Selb',
    'Seitenroda',
    'Seinsheim',
    'Seifhennersdorf',
    'Seibersbach',
    'Sehnde',
    'Sehma',
    'Sehlen',
    'Sehlem',
    'Sehlde',
    'Sehestedt',
    'Seevetal',
    'Seestermuhe',
    'Seester',
    'Seeshaupt',
    'Seesen',
    'Seershausen',
    'Seerhausen',
    'Seelze',
    'Seelscheid',
    'Seelow',
    'Seelitz',
    'Seelbach',
    'Seehausen am Staffelsee',
    'Seehausen',
    'Seeg',
    'Seefeld',
    'Seedorf',
    'Seeburg',
    'Seebruck',
    'Seebergen',
    'Seebad Heringsdorf',
    'Seebad Bansin',
    'Seebad Ahlbeck',
    'Seebach',
    'Seddin',
    'Seckmauern',
    'Seckach',
    'Seck',
    'Sechtem',
    'Sebnitz',
    'Schworstadt',
    'Schwollen',
    'Schwissel',
    'Schwirzheim',
    'Schwindegg',
    'Schwifting',
    'Schwieberdingen',
    'Schwetzingen',
    'Schwerte',
    'Schweringen',
    'Schwerin',
    'Schwerbach',
    'Schwepnitz',
    'Schwenningen',
    'Schwendi',
    'Schwelm',
    'Schweitenkirchen',
    'Schweinsbuehl',
    'Schweinhausen',
    'Schweinfurt',
    'Schweindorf',
    'Schweina',
    'Schweighausen',
    'Schweich',
    'Schweiburg',
    'Schwei',
    'Schwegenheim',
    'Schwedt',
    'Schwedelbach',
    'Schwebheim',
    'Schwarzheide',
    'Schwarzenfeld',
    'Schwarzenbruck',
    'Schwarzenberg',
    'Schwarzenbek',
    'Schwarzenbach an der Saale',
    'Schwarzenbach am Wald',
    'Spiegelau',
    'Schwarzach bei Nabburg',
    'Schwarzach',
    'Schwarza',
    'Schwarz',
    'Schwarmstedt',
    'Schwarme',
    'Schwanheim',
    'Schwanheide',
    'Schwangau',
    'Schwanfeld',
    'Schwanewede',
    'Schwanebeck',
    'Schwandorf in Bayern',
    'Schwalmtal',
    'Schwalmstadt',
    'Schwallungen',
    'Schwall',
    'Schwalbach am Taunus',
    'Schwalbach',
    'Schwaikheim',
    'Schwaigern',
    'Schwaig',
    'Schwagstorf',
    'Schwabstedt',
    'Schwabsoien',
    'Schwabmunchen',
    'Schwabmuhlhausen',
    'Schwäbisch Gmünd',
    'Schwabhausen',
    'Schwabenheim',
    'Schwabbruck',
    'Schwabach',
    'Schwaan',
    'Schutzingen',
    'Schuettorf',
    'Schutterwald',
    'Schuttertal',
    'Bad Schussenried',
    'Schurensohlen',
    'Schurdt',
    'Schupf',
    'Schupbach',
    'Schulzendorf',
    'Schuelp',
    'Schuld',
    'Schuby',
    'Schrozberg',
    'Schrobenhausen',
    'Schriesheim',
    'Schrecksbach',
    'Schramberg',
    'Schotten',
    'Schortens',
    'Schornsheim',
    'Schorndorf',
    'Schopsdorf',
    'Schoppingen',
    'Schopp',
    'Schopfloch',
    'Schopfheim',
    'Schonwalde am Bungsberg',
    'Schoenwalde',
    'Schoenwald',
    'Schonwald',
    'Schonungen',
    'Schonthal',
    'Schonstett',
    'Schonsee',
    'Schonkirchen',
    'Schoeningen',
    'Schoenhausen',
    'Schönhagen',
    'Schongleina',
    'Schongeising',
    'Schongau',
    'Schonfeld',
    'Schoenfeld',
    'Schoenewalde',
    'Schoenermark',
    'Schoenenberg',
    'Schoeneiche',
    'Schonecken',
    'Schoeneck',
    'Schoneberg',
    'Schoenebeck',
    'Schondra',
    'Schoenburg',
    'Schoenbrunn',
    'Schonborn',
    'Schoenberg',
    'Schonberg',
    'Schonbek',
    'Schonbach',
    'Schoenau-Berzdorf',
    'Schonau an der Brend',
    'Schonau',
    'Schonau im Schwarzwald',
    'Schonaich',
    'Schonach im Schwarzwald',
    'Schomberg',
    'Schollnach',
    'Schollkrippen',
    'Schollbrunn',
    'Schnurpflingen',
    'Schnorbach',
    'Schneverdingen',
    'Schnelldorf',
    'Schneizlreuth',
    'Schnega',
    'Schneeberg',
    'Schneckenlohe',
    'Schneckenhausen',
    'Schneckengruen',
    'Schnaittenbach',
    'Schnaittach',
    'Schnaitsee',
    'Schnabelwaid',
    'Schmoelln',
    'Schmitten',
    'Schmitshausen',
    'Schmilau',
    'Schmiedehausen',
    'Schmiedefeld am Rennsteig',
    'Schmiedeberg',
    'Schmidt',
    'Schmidgaden',
    'Schmelz',
    'Schmedenstedt',
    'Schmeckwitz',
    'Schmallenberg',
    'Schmalkalden',
    'Schmalfeld',
    'Schmalensee',
    'Schlusselfeld',
    'Schluechtern',
    'Schluchsee',
    'Schlotheim',
    'Schlossvippach',
    'Schloen',
    'Schloben',
    'Schlitz',
    'Schlieven',
    'Schliersee',
    'Schlierbach',
    'Schlier',
    'Schliengen',
    'Schlieben',
    'Schleusingen',
    'Schleswig',
    'Schlesen',
    'Bad Schlema',
    'Schleiz',
    'Schleife',
    'Schleiden',
    'Schlehdorf',
    'Schlegel',
    'Schleching',
    'Schlat',
    'Schlangenbad',
    'Schlangen',
    'Schlammersdorf',
    'Schlaitdorf',
    'Schlagsdorf',
    'Schladen',
    'Schkopau',
    'Schkoelen',
    'Schkeuditz',
    'Schirnding',
    'Schirmitz',
    'Schirgiswalde',
    'Schipkau',
    'Schiphorst',
    'Schinkel ueber Kiel',
    'Schindhard',
    'Schiltberg',
    'Schiltach',
    'Schillsdorf',
    'Schillingsfurst',
    'Schillingen',
    'Schildow',
    'Schildau',
    'Schilda',
    'Schiffweiler',
    'Schifferstadt',
    'Schiffdorf',
    'Schierschwende',
    'Schierling',
    'Schierke',
    'Schierhorn',
    'Schierensee',
    'Schieren',
    'Scheyern',
    'Scheuring',
    'Scheuerfeld',
    'Schesslitz',
    'Schernfeld',
    'Schermen',
    'Schermbeck',
    'Schenklengsfeld',
    'Schenkenzell',
    'Schenkenberg',
    'Schenkelberg',
    'Schellhorn',
    'Schellerten',
    'Schellbronn',
    'Schelklingen',
    'Scheinfeld',
    'Scheidt',
    'Scheidegg',
    'Scheibenhardt',
    'Scheibenberg',
    'Scheessel',
    'Scheer',
    'Schechingen',
    'Schechen',
    'Schauren',
    'Schaufling',
    'Schashagen',
    'Scharnhorst',
    'Scharnebeck',
    'Scharenstetten',
    'Scharbeutz',
    'Schammelsdorf',
    'Schallstadt',
    'Schallodenbach',
    'Schallenburg',
    'Schallbach',
    'Schalksmuehle',
    'Schalkenmehren',
    'Schalkenbach',
    'Schalkau',
    'Schaidt',
    'Schaftlarn',
    'Schafstedt',
    'Schafflund',
    'Schackensleben',
    'Schacht-Audorf',
    'Schaalby',
    'Schaafheim',
    'Sayda',
    'Sauzin',
    'Saustrup',
    'Saupsdorf',
    'Saunstorf',
    'Saulgrub',
    'Sauldorf',
    'Sauerlach',
    'Sauensiek',
    'Satteldorf',
    'Satrup',
    'Sassnitz',
    'Sassenberg',
    'Sassen',
    'Sasbachwalden',
    'Sasbach',
    'Sarstedt',
    'Sarow',
    'Sarnow',
    'Sarmersbach',
    'Sargstedt',
    'Sargenroth',
    'Sanz',
    'Sankt Wolfgang',
    'Sankt Wendel',
    'Sankt Sebastian',
    'Sankt Peter-Ording',
    'Sankt Peter',
    'Sankt Oswald',
    'Saint Michaelisdonn',
    'Sankt Martin',
    'Sankt Margen',
    'Sankt Johann',
    'Sankt Ingbert',
    'Saint Goarshausen',
    'Sankt Goar',
    'Sankt Georgen im Schwarzwald',
    'Sankt Gangloff',
    'Saint Egidien',
    'Saint Blasien',
    'Sankt Augustin',
    'Sankt Andreasberg',
    'Sankt Aldegund',
    'Sankt Alban',
    'Sanitz',
    'Sangerhausen',
    'Sandstedt',
    'Sandhausen',
    'Sandesneben',
    'Sandersleben',
    'Sandersdorf',
    'Sande',
    'Sandbostel',
    'Sandberg',
    'Sand',
    'Samtens',
    'Samerberg',
    'Salzweg',
    'Salzwedel',
    'Salzkotten',
    'Salzhemmendorf',
    'Salzhausen',
    'Salzgitter',
    'Salzfurtkapelle',
    'Salzelmen',
    'Salzboeden',
    'Salzbergen',
    'Salz',
    'Sallgast',
    'Salgen',
    'Saldenburg',
    'Salching',
    'Salbitz',
    'Salach',
    'Sagard',
    'Saffig',
    'Saerbeck',
    'Sachsenkam',
    'Sachsenhagen',
    'Sachsenberg',
    'Sachsen',
    'Saarwellingen',
    'Saarlouis',
    'Saarburg',
    'Saarbrücken',
    'Saalfeld',
    'Saalburg',
    'Saal',
    'Rutsweiler an der Lauter',
    'Ruethen',
    'Rutesheim',
    'Rust',
    'Rüsselsheim am Main',
    'Ruschberg',
    'Ruppichteroth',
    'Ruppertsweiler',
    'Ruppertshofen',
    'Ruppertsberg',
    'Ruppendorf',
    'Runkel',
    'Runding',
    'Rumohr',
    'Rummelsheim',
    'Ruelzheim',
    'Rullstorf',
    'Ruhwinkel',
    'Ruhstorf',
    'Ruhstadt',
    'Ruhpolding',
    'Ruehn',
    'Ruhmannsfelden',
    'Ruhland',
    'Ruhla',
    'Ruhfelden',
    'Ruhen',
    'Rugland',
    'Rugheim',
    'Rugendorf',
    'Rudolstadt',
    'Rudnitz',
    'Ruedesheim am Rhein',
    'Rüdesheim an der Nahe',
    'Rudertshausen',
    'Ruderting',
    'Ruedersdorf',
    'Rudersberg',
    'Ruderatshofen',
    'Rudenhausen',
    'Rudelzhausen',
    'Rueckersdorf',
    'Ruckersdorf',
    'Ruber',
    'Rubenow',
    'Roxheim',
    'Roetz',
    'Rottweil',
    'Rottorf',
    'Rottmersleben',
    'Rotthalmunster',
    'Rottershausen',
    'Rottendorf',
    'Rottenburg an der Laaber',
    'Rottenburg',
    'Rottenbuch',
    'Rottenbach',
    'Rottenacker',
    'Rott am Inn',
    'Rottach-Egern',
    'Rott',
    'Rothselberg',
    'Rothlein',
    'Rothenburg upon Tauber',
    'Rothenburg',
    'Rothenbuch',
    'Rothenberg',
    'Rothenbach an der Pegnitz',
    'Rothenbach',
    'Rothen',
    'Roetha',
    'Roth',
    'Rotgesbuttel',
    'Roetgen',
    'Rotenhain',
    'Rotenburg an der Fulda',
    'Rotenburg',
    'Rot an der Rot',
    'Rot am See',
    'Rostock',
    'Rosswein',
    'Rosstal',
    'Rossow',
    'Rossleben',
    'Rosshaupten',
    'Rossdorf',
    'Rossbach',
    'Roesrath',
    'Roskow',
    'Rositz',
    'Rosenthal',
    'Rosenow',
    'Rosenheim',
    'Rosenhagen',
    'Rosengarten',
    'Rosenfeld',
    'Rosenberg',
    'Rosdorf',
    'Rosche',
    'Roschbach',
    'Rosbach vor der Hoehe',
    'Rosbach',
    'Roepersdorf',
    'Ronshausen',
    'Ronsberg',
    'Ronnenberg',
    'Ronneburg',
    'Rondeshagen',
    'Romstedt',
    'Romrod',
    'Rommerskirchen',
    'Roemhild',
    'Rollshausen',
    'Rollbach',
    'Roklum',
    'Roitzsch',
    'Roigheim',
    'Rohrmoos',
    'Rohrlack',
    'Rohrenfels',
    'Rohrdorf',
    'Rohrbrunn',
    'Rohrbach',
    'Rohr',
    'Rohl',
    'Rogling',
    'Roggentin',
    'Roggenburg',
    'Roggden',
    'Rogatz',
    'Rogaesen',
    'Rofingen',
    'Rodinghausen',
    'Roding',
    'Rodewisch',
    'Rodershausen',
    'Rodenkirchen',
    'Rodenberg',
    'Rodenbek',
    'Rodenbach',
    'Roden',
    'Rodelsee',
    'Rodau',
    'Rodalben',
    'Rockenhausen',
    'Rockenberg',
    'Rochlitz',
    'Roebel',
    'Riveris',
    'Rittershausen',
    'Rittersdorf',
    'Ritterhude',
    'Riol',
    'Rinzenberg',
    'Rinteln',
    'Rinnthal',
    'Rinkerode',
    'Ringenhain',
    'Ringelai',
    'Rinchnach',
    'Rimsting',
    'Rimpar',
    'Rimbach',
    'Rietz Neuendorf',
    'Rietschen',
    'Riethnordhausen',
    'Rietberg',
    'Riesweiler',
    'Rieste',
    'Rieseby',
    'Riesa',
    'Riepsdorf',
    'Rieps',
    'Rieneck',
    'Riemerling',
    'Rielasingen-Worblingen',
    'Riekofen',
    'Riegsee',
    'Riegelsberg',
    'Riegel',
    'Riedlingen',
    'Riedlhutte',
    'Riedhausen',
    'Riedering',
    'Riederich',
    'Rieder',
    'Riedenburg',
    'Rieden',
    'Riedelbach',
    'Riede',
    'Ried',
    'Rickenbach',
    'Richtenberg',
    'Ribnitz-Damgarten',
    'Ribbesbuttel',
    'Rhumspringe',
    'Rhinow',
    'Rheurdt',
    'Rhens',
    'Rheinzabern',
    'Rheinsberg',
    'Rheinfelden',
    'Rheine',
    'Rheinbrohl',
    'Rheinbreitbach',
    'Rheinbollen',
    'Rheinbischofsheim',
    'Rheinberg',
    'Rheinbach',
    'Rhede',
    'Rheda-Wiedenbrueck',
    'Rheda',
    'Rhade',
    'Reutlingen',
    'Reuterstadt Stavenhagen',
    'Reussenkoge',
    'Reudelsterz',
    'Retzstadt',
    'Retterath',
    'Rettenberg',
    'Rettenbach',
    'Rethwisch',
    'Rethen',
    'Reppenstedt',
    'Reppelin',
    'Rentweinsdorf',
    'Renthendorf',
    'Renshausen',
    'Renquishausen',
    'Renningen',
    'Rennertshofen',
    'Rennerod',
    'Rennau',
    'Rengsdorf',
    'Rendswuhren',
    'Rendsburg',
    'Renda',
    'Renchen',
    'Remshalden',
    'Remseck',
    'Remscheid',
    'Remptendorf',
    'Remplin',
    'Remmingsheim',
    'Remlingen',
    'Remagen',
    'Rellingen',
    'Reitwein',
    'Reitland',
    'Reit im Winkl',
    'Reiskirchen',
    'Reisen',
    'Reischach',
    'Reisbach',
    'Reipoltskirchen',
    'Reinstorf',
    'Reinstedt',
    'Reinstadt',
    'Reinsfeld',
    'Reinsdorf',
    'Reinsberg',
    'Reinsbek',
    'Reinholterode',
    'Reinheim',
    'Reinhardtsdorf',
    'Reinfeld',
    'Reinbek',
    'Reimershagen',
    'Reimerath',
    'Reilingen',
    'Reiffelbach',
    'Reiferscheid',
    'Reifenberg',
    'Reichmannsdorf',
    'Reichertshofen',
    'Reichertsheim',
    'Reichertshausen',
    'Reichersbeuern',
    'Reichenwalde',
    'Reichenschwand',
    'Reichenow',
    'Reichenberg',
    'Reichenbach-Steegen',
    'Reichenbach an der Fils',
    'Reichenbach',
    'Reichenbach/Vogtland',
    'Reichelsheim',
    'Reichartshausen',
    'Reich',
    'Rehna',
    'Rehm-Flehde-Bargen',
    'Rehlingen',
    'Rehling',
    'Rehfelde',
    'Reher',
    'Rehden',
    'Rehburg-Loccum',
    'Rehau',
    'Regnitzlosau',
    'Regis-Breitingen',
    'Regenstauf',
    'Regensburg',
    'Regen',
    'Reesdorf',
    'Rees',
    'Redwitz an der Rodach',
    'Rednitzhembach',
    'Redewisch',
    'Redekin',
    'Reddelich',
    'Recklinghausen',
    'Reckenthal',
    'Reckenroth',
    'Reckendorf',
    'Recke',
    'Rechtsupweg',
    'Rechtmehring',
    'Rechtenbach',
    'Rechlin',
    'Rechberghausen',
    'Rebenstorf',
    'Ravensburg',
    'Rausdorf',
    'Rauschenberg',
    'Raunheim',
    'Rauenberg',
    'Rauen',
    'Raubling',
    'Raubach',
    'Ratzeburg',
    'Rattiszell',
    'Rattenkirchen',
    'Rattelsdorf',
    'Ratshausen',
    'Ratingen',
    'Rathmannsdorf',
    'Rathenow',
    'Ratekau',
    'Rastow',
    'Rastorf',
    'Rastenberg',
    'Rastede',
    'Rastdorf',
    'Rastatt',
    'Rasdorf',
    'Raschau',
    'Rantrum',
    'Ransweiler',
    'Ranstadt',
    'Ranschbach',
    'Rannungen',
    'Rangsdorf',
    'Rangendingen',
    'Randersacker',
    'Ramsthal',
    'Ramstein-Miesenbach',
    'Ramstedt',
    'Ramsloh',
    'Ramsen',
    'Ramsdorf',
    'Ramsach',
    'Rammingen',
    'Rammenau',
    'Rammelsbach',
    'Ramelsloh',
    'Ramberg',
    'Ralswiek',
    'Ralingen',
    'Raitenbuch',
    'Raisting',
    'Rainrod',
    'Rainertshausen',
    'Rain',
    'Rahden',
    'Raguth',
    'Raguhn',
    'Raegelin',
    'Raesfeld',
    'Raduhn',
    'Radolfzell',
    'Radibor',
    'Radevormwald',
    'Radegast',
    'Radeburg',
    'Radebeul',
    'Radeberg',
    'Raddestorf',
    'Radbruch',
    'Rackwitz',
    'Raeckelwitz',
    'Raboldshausen',
    'Rabke',
    'Raben Steinfeld',
    'Rabenau',
    'Rabel',
    'Quitzow',
    'Quirnheim',
    'Quierschied',
    'Quickborn',
    'Querfurt',
    'Querenhorst',
    'Quellendorf',
    'Queidersbach',
    'Quedlinburg',
    'Quarnbek',
    'Quakenbrueck',
    'Quaal',
    'Pyrbaum',
    'Putzbrunn',
    'Puettelkow',
    'Putlitz',
    'Putbus',
    'Puschendorf',
    'Purgen',
    'Purfing',
    'Pulsnitz',
    'Pullenreuth',
    'Pullach im Isartal',
    'Pulheim',
    'Puderbach',
    'Puchheim',
    'Puchersreuth',
    'Puechau',
    'Prutting',
    'Prumzurlay',
    'Pruem',
    'Protzen',
    'Protzel',
    'Prosselsheim',
    'Proesen',
    'Proschim',
    'Pronstorf',
    'Pronsfeld',
    'Prohn',
    'Probstzella',
    'Probsteierhagen',
    'Pritzwalk',
    'Pritzier',
    'Prittriching',
    'Prisdorf',
    'Prisannewitz',
    'Prietitz',
    'Priestewitz',
    'Priesendorf',
    'Priepert',
    'Prien am Chiemsee',
    'Prichsenstadt',
    'Priborn',
    'Preussisch Oldendorf',
    'Pretzien',
    'Pretzfeld',
    'Pressig',
    'Presseck',
    'Pressath',
    'Prenzlauer Berg Bezirk',
    'Prenzlau',
    'Premnitz',
    'Premich',
    'Preist',
    'Preetz',
    'Prebitz',
    'Prath',
    'Pragsdorf',
    'Prackenbach',
    'Pracht',
    'Poyenberg',
    'Poxdorf',
    'Pottum',
    'Pottmes',
    'Pottiga',
    'Pottenstein',
    'Potsdam',
    'Postmunster',
    'Postlow',
    'Postfeld',
    'Postau',
    'Possendorf',
    'Poesneck',
    'Posing',
    'Poschendorf',
    'Porta Westfalica',
    'Pornbach',
    'Poppenricht',
    'Poppenhausen',
    'Poppengruen',
    'Ponitz',
    'Pondorf',
    'Pommersfelden',
    'Pommelsbrunn',
    'Polzow',
    'Polzig',
    'Polzen',
    'Polsingen',
    'Pollitz',
    'Polling',
    'Pollhagen',
    'Pollenfeld',
    'Polle',
    'Poelitz',
    'Poelchow',
    'Polch',
    'Poing',
    'Pohrsdorf',
    'Pohle',
    'Pohl',
    'Pogez',
    'Podelzig',
    'Podeldorf',
    'Pocking',
    'Pockau',
    'Pluwig',
    'Plutscheid',
    'Pluderhausen',
    'Plothen',
    'Plossig',
    'Plossberg',
    'Ploenjeshausen',
    'Ploen',
    'Plochingen',
    'Pliezhausen',
    'Plieningen',
    'Pliening',
    'Pleystein',
    'Plettenberg',
    'Plessa',
    'Pleisweiler-Oberhofen',
    'Pleiskirchen',
    'Pleinfeld',
    'Plein',
    'Pleidelsheim',
    'Pleckhausen',
    'Plech',
    'Plauen',
    'Plaue',
    'Plau am See',
    'Plattling',
    'Plattkow',
    'Plattenburg',
    'Platten',
    'Plate',
    'Plankstetten',
    'Plankstadt',
    'Plankenfels',
    'Planegg',
    'Plaidt',
    'Pittenhart',
    'Pirna',
    'Pirmasens',
    'Pirk',
    'Pinzberg',
    'Pinnow',
    'Pinneberg',
    'Pilsting',
    'Pilsach',
    'Pillig',
    'Pietenfeld',
    'Piesport',
    'Pielenhofen',
    'Piding',
    'Picher',
    'Philippsthal',
    'Philippsburg',
    'Philadelphia',
    'Pfungstadt',
    'Pfullingen',
    'Pfullendorf',
    'Pfronten',
    'Pfronstetten',
    'Pfreimd',
    'Pforzheim',
    'Pforzen',
    'Pforring',
    'Pfofeld',
    'Pfinztal',
    'Pferdingsleben',
    'Pfeffenhausen',
    'Pfeffelbach',
    'Pfedelbach',
    'Pfatter',
    'Pfarrweisach',
    'Pfarrkirchen',
    'Pfalzgrafenweiler',
    'Pfalzfeld',
    'Pfakofen',
    'Pfahlbronn',
    'Pfaffschwende',
    'Pfaffroda',
    'Pfaffing',
    'Pfaffenweiler',
    'Pfaffen-Schwabenheim',
    'Pfaffenhofen an der Roth',
    'Pfaffenhofen an der Ilm',
    'Pfaffenhofen an der Glonn',
    'Pfaffenhofen',
    'Pfaffenhausen',
    'Pettstadt',
    'Petting',
    'Pettendorf',
    'Petschow',
    'Peterslahr',
    'Petershausen',
    'Petershagen',
    'Petersdorf',
    'Petersberg',
    'Petersaurach',
    'Perlesreut',
    'Perleberg',
    'Perl',
    'Perkam',
    'Perach',
    'Penzlin',
    'Penzing',
    'Penzberg',
    'Pentling',
    'Pennigsehl',
    'Penkun',
    'Penig',
    'Pemfling',
    'Pellworm',
    'Pellingen',
    'Peitz',
    'Peiting',
    'Peissenberg',
    'Peissen',
    'Peine',
    'Peheim',
    'Pegnitz',
    'Pegau',
    'Pechbrunn',
    'Pausa',
    'Paunzhausen',
    'Paulinenaue',
    'Pattensen',
    'Patersdorf',
    'Pastow',
    'Pastin',
    'Pastetten',
    'Passow',
    'Passau',
    'Passade',
    'Pasewalk',
    'Partenstein',
    'Partenheim',
    'Parsdorf',
    'Parsberg',
    'Parsau',
    'Parkstetten',
    'Parkstein',
    'Parey',
    'Parchtitz',
    'Parchim',
    'Parchen',
    'Pappenheim',
    'Papenhagen',
    'Papendorf',
    'Papenburg',
    'Pantenburg',
    'Panten',
    'Pansfelde',
    'Pansdorf',
    'Panschwitz-Kuckau',
    'Panker',
    'Pampow',
    'Palzem',
    'Palling',
    'Palingen',
    'Painten',
    'Pahlen',
    'Pahl',
    'Paderborn',
    'Padenstedt',
    'Pabstorf',
    'Oyten',
    'Owschlag',
    'Owingen',
    'Owen',
    'Oeversee',
    'Overath',
    'Oevenum',
    'Ovendorf',
    'Ovelgonne',
    'Otzingen',
    'Otzing',
    'Otzdorf',
    'Ottweiler',
    'Ottrau',
    'Ottobrunn',
    'Ottobeuren',
    'Otterwisch',
    'Ottersweier',
    'Otterstadt',
    'Ottersberg',
    'Otterndorf',
    'Otterfing',
    'Otterberg',
    'Otterbach',
    'Otter',
    'Ottenstein',
    'Ottensoos',
    'Ottenhofen im Schwarzwald',
    'Ottenhofen',
    'Ottenheim',
    'Ottendorf-Okrilla',
    'Ottendorf',
    'Ottenbuttel',
    'Ottenbach',
    'Otisheim',
    'Otigheim',
    'Oststeinbek',
    'Ostseebad Sellin',
    'Ostseebad Rerik',
    'Ostseebad Prerow',
    'Ostseebad Kuehlungsborn',
    'Seeheilbad Graal-Mueritz',
    'Ostseebad Goehren',
    'Ostseebad Dierhagen',
    'Ostseebad Boltenhagen',
    'Ostseebad Binz',
    'Ostseebad Baabe',
    'Ostseebad Ahrenshoop',
    'Ostrohe',
    'Ostritz',
    'Ostringen',
    'Oestrich',
    'Ostrhauderfehn',
    'Ostrau',
    'Ostrach',
    'Osthofen',
    'Ostheim',
    'Ostgrossefehn',
    'Osterzell',
    'Osterwieck',
    'Osterspai',
    'Osterronfeld',
    'Oster-Ohrstedt',
    'Osterode am Harz',
    'Osterode',
    'Osternienburg',
    'Osterholz-Scharmbeck',
    'Osterhofen',
    'Osterhever',
    'Osterfeld',
    'Ostercappeln',
    'Osterby',
    'Osterburken',
    'Osterburg',
    'Osterberg',
    'Ostenfeld',
    'Osten',
    'Ostelsheim',
    'Ostbevern',
    'Ossling',
    'Osnabrück',
    'Osdorf',
    'Oschersleben',
    'Oschatz',
    'Osburg',
    'Ortrand',
    'Ortenburg',
    'Ortenberg',
    'Orsingen-Nenzingen',
    'Oersdorf',
    'Ornbau',
    'Oerlenbach',
    'OEring',
    'Orenhofen',
    'Oerel',
    'Orbis',
    'Oranienburg',
    'Oranienbaum',
    'Oppurg',
    'Oppershausen',
    'Oppenweiler',
    'Oppenheim',
    'Oppenau',
    'Oppach',
    'Opfingen',
    'Opfenbach',
    'Onstmettingen',
    'Olzreute',
    'Olzheim',
    'Olsbruecken',
    'Olsberg',
    'Olpe',
    'Olmscheid',
    'Ollingen',
    'Oelixdorf',
    'Olfen',
    'Oelerse',
    'Oldisleben',
    'Olderup',
    'Oldendorf',
    'Oldenbuttel',
    'Oldenburg in Holstein',
    'Oldenburg',
    'Oldenborstel',
    'Olching',
    'Olbronn',
    'Olbersleben',
    'Olbernhau',
    'Ohrum',
    'Ohringen',
    'Ohrdruf',
    'Ohorn',
    'Ohningen',
    'Ohne',
    'Ohmes',
    'Ohmden',
    'Ohlweiler',
    'Ohlstadt',
    'Ohlsbach',
    'Oftersheim',
    'Ofterschwang',
    'Ofterdingen',
    'Offstein',
    'Offingen',
    'Offenhausen',
    'Offenburg',
    'Offenberg',
    'Offenbach an der Queich',
    'Offenbach',
    'Oetzen',
    'Oettingen in Bayern',
    'Oettersdorf',
    'Oerzen',
    'Oerlinghausen',
    'Oer-Erkenschwick',
    'Oelsnitz',
    'Oelsberg',
    'Oelde',
    'Oeding',
    'Oedheim',
    'Oederan',
    'Oechsen',
    'Oebisfelde',
    'Odernheim',
    'Oderberg',
    'Odenthal',
    'Odenheim',
    'Odelzhausen',
    'Ockholm',
    'Ockenheim',
    'Ockenfels',
    'Ochtrup',
    'Ochtmersleben',
    'Ochtendung',
    'Ochsenhausen',
    'Ochsenfurt',
    'Obrigheim',
    'Obing',
    'Obhausen',
    'Oberzissen',
    'Oberwuerschnitz',
    'Oberwoerresbach',
    'Oberwolfach',
    'Oberwittstadt',
    'Dammbach',
    'Oberwiesen',
    'Oberwesel',
    'Oberwerrn',
    'Oberweissbach',
    'Oberweiler',
    'Oberweid',
    'Oberviechtach',
    'Oberursel',
    'Obertshausen',
    'Obertrubach',
    'Obertraubling',
    'Obertraubenbach',
    'Oberthulba',
    'Oberthingau',
    'Oberthal',
    'Oberteuringen',
    'Obertaufkirchen',
    'Obersussbach',
    'Obersulzen',
    'Oberstenfeld',
    'Oberstdorf',
    'Oberstaufen',
    'Oberstadion',
    'Obersontheim',
    'Obersochering',
    'Obershagen',
    'Oberseifersdorf',
    'Oberschweinbach',
    'Oberschwarzach',
    'Oberschonegg',
    'Oberschoena',
    'Oberschneiding',
    'Oberschleissheim',
    'Oberscheidweiler',
    'Obersanding',
    'Oberroth',
    'Oberrot',
    'Oberrossbach',
    'Ober-Roden',
    'Oberriexingen',
    'Oberried',
    'Oberreute',
    'Oberreichenbach',
    'Ober-Ramstadt',
    'Oberraden',
    'Oberpleichfeld',
    'Oberpframmern',
    'Oberowisheim',
    'Oberottmarshausen',
    'Oberotterbach',
    'Oberostendorf',
    'Ober-Olm',
    'Oberode',
    'Obernzell',
    'Obernkirchen',
    'Obernhof',
    'Obernheim-Kirchenarnbach',
    'Obernfeld',
    'Oberneuching',
    'Oberneisen',
    'Oberndorf',
    'Obernburg am Main',
    'Obernbreit',
    'Obermuhlbach',
    'Obermoschel',
    'Ober-Morlen',
    'Obermichelbach',
    'Obermeitingen',
    'Obermassfeld-Grimmenthal',
    'Obermarchtal',
    'Obermaiselstein',
    'Oberlungwitz',
    'Oberlibbach',
    'Oberleichtersbach',
    'Oberlehen',
    'Oberlauterbach',
    'Langfurth',
    'Oberlangen',
    'Oberlahr',
    'Oberkotzau',
    'Ober Kostenz',
    'Oberkochen',
    'Oberkirchen',
    'Oberkirch',
    'Oberkatz',
    'Oberkail',
    'Oberjoch',
    'Oberjettingen',
    'Oberirsen',
    'Oberickelsheim',
    'Oberhof',
    'Ober-Hilbersheim',
    'Oberhausen-Rheinhausen',
    'Oberhausen',
    'Oberharmersbach',
    'Oberhain',
    'Oberhaid',
    'Oberhaching',
    'Obergurig',
    'Obergunzburg',
    'Obergriesbach',
    'Oberg',
    'Obereschach',
    'Obererbach',
    'Oberelsbach',
    'Oberelbert',
    'Obereisenheim',
    'Oberdurenbach',
    'Oberdorla',
    'Simonswald',
    'Oberdolling',
    'Oberdischingen',
    'Oberding',
    'Oberderdingen',
    'Oberdachstetten',
    'Obercunnersdorf',
    'Obercrinitz',
    'Oberbrombach',
    'Oberboihingen',
    'Oberbillig',
    'Oberbettingen',
    'Oberbergkirchen',
    'Oberbechingen',
    'Oberaula',
    'Oberaudorf',
    'Oberau',
    'Oberasbach',
    'Oberammergau',
    'Oberaltertheim',
    'Oberaichbach',
    'Oberahr',
    'Ob',
    'Nutzen',
    'Nustrow',
    'Nussloch',
    'Nusse',
    'Nussdorf am Inn',
    'Nußdorf (Chiemgau)',
    'Nussbaum',
    'Nussbach',
    'Nusplingen',
    'Nurtingen',
    'Nuremberg',
    'Nurburg',
    'Nunchritz',
    'Nuembrecht',
    'Nufringen',
    'Nudlingen',
    'Nudersdorf',
    'Nuechel',
    'Nubel',
    'Nubbel',
    'Notzingen',
    'Nottuln',
    'Nottleben',
    'Nossentiner Huette',
    'Nossendorf',
    'Nossen',
    'Nörvenich',
    'Nortrup',
    'Nortorf',
    'Nortmoor',
    'Northeim',
    'Nortershausen',
    'Norken',
    'Norheim',
    'Nordwalde',
    'Nordstrand',
    'Nordstemmen',
    'Nordrach',
    'Nordleda',
    'Nordkirchen',
    'Nordhorn',
    'Nordholz',
    'Nordheim vor der Rhon',
    'Nordheim',
    'Nordhausen',
    'Nordhastedt',
    'Nordhalben',
    'Norderstedt',
    'Norderney',
    'Norderheistedt',
    'Nordenham',
    'Nordendorf',
    'Norden',
    'Nordahn',
    'Nonnweiler',
    'Nonnenhorn',
    'Nomborn',
    'Nohra',
    'Nohn',
    'Nohfelden',
    'Nohen',
    'Noer',
    'Noda',
    'Nochern',
    'Nobitz',
    'Nittendorf',
    'Nittenau',
    'Nittel',
    'Nisterberg',
    'Nindorf',
    'Niestetal',
    'Nieste',
    'Niesky',
    'Nieschuetz',
    'Nierstein',
    'Niepars',
    'Nienstadt',
    'Nienhagen, Lower Saxony',
    'Nienburg',
    'Nienburg/Saale',
    'Niemegk',
    'Nieheim',
    'Niederzissen',
    'Niederzimmern',
    'Niederzier',
    'Niederwuerzbach',
    'Niederworresbach',
    'Niederwinkling',
    'Niederwiesa',
    'Niederwerth',
    'Niederwerrn',
    'Niederweiler',
    'Niederwambach',
    'Niederwallmenach',
    'Niederwalgern',
    'Niederviehbach',
    'Niederurff',
    'Niedertiefenbach',
    'Niedertaufkirchen',
    'Niederstotzingen',
    'Niederstetten',
    'Niedersteinebach',
    'Niederstaufenbach',
    'Niedersohren',
    'Niedersfeld',
    'Niederselters',
    'Niederseelbach',
    'Niederschonenfeld',
    'Niederrossla',
    'Niederrossbach',
    'Niederrieden',
    'Niederorschel',
    'Nieder-Olm',
    'Niedernwohren',
    'Niedernhausen',
    'Niedernhall',
    'Niederneisen',
    'Niederndodeleben',
    'Niedernberg',
    'Niedermurach',
    'Niedermohr',
    'Niedermittlau',
    'Niedermehnen',
    'Niederlauer',
    'Niederlangen',
    'Niederkumbd',
    'Niederkruchten',
    'Niederkirchen bei Deidesheim',
    'Niederkirchen',
    'Niederkassel',
    'Niederhosenbach',
    'Niederhorbach',
    'Niederhofen',
    'Niederheimbach',
    'Niederhausen',
    'Niedergorsdorf',
    'Niedergeislbach',
    'Niedergebra',
    'Niederfuellbach',
    'Niederfrohna',
    'Niederfischbach',
    'Niederfinow',
    'Niederfell',
    'Niedereschach',
    'Niedererbach',
    'Niederems',
    'Niederelbert',
    'Niederdreisbach',
    'Niederdorfelden',
    'Niederdorf',
    'Niedercunnersdorf',
    'Niedercrinitz',
    'Niederburg',
    'Niederbreitbach',
    'Niederbosa',
    'Niederbergkirchen',
    'Niederaula',
    'Niederau',
    'Aschau im Chiemgau',
    'Niederalteich',
    'Niederalben',
    'Niederaichbach',
    'Niederaehren',
    'Niederahr',
    'Niedenstein',
    'Niebuell',
    'Nieblum',
    'Nideggen',
    'Nidderau',
    'Nidda',
    'Nickenich',
    'Newel',
    'Neversdorf',
    'Neuzelle',
    'Neu Zauche',
    'Neu Wulmstorf',
    'Neu Woserin',
    'Neuwittenbek',
    'Neuwied',
    'Neuweiler',
    'Neu-Ulm',
    'Neu Tucheband',
    'Neutrebbin',
    'Neutraubling',
    'Neustrelitz',
    'Neustadt in Holstein',
    'Neustadt-Glewe',
    'Neustadt bei Coburg',
    'Neustadt',
    'Neustadt an der Waldnaab',
    'Neustadt an der Donau',
    'Neustadt an der Aisch',
    'Neustadt am Ruebenberge',
    'Neustadt am Rennsteig',
    'Neustadt am Main',
    'Neustadt (Dosse)',
    'Neustadt in Sachsen',
    'Neustadt (Hessen)',
    'Neuss',
    'Neusorg',
    'Neusitz',
    'Neuseddin',
    'Neuschoo',
    'Neuschonau',
    'Neu Schloen',
    'Neuscharrel',
    'Neusass',
    'Neusalza-Spremberg',
    'Neuruppin',
    'Neu Roggentin',
    'Neuried',
    'Neurhede',
    'Neureichenau',
    'Neupotz',
    'Neupetershain',
    'Neuotting',
    'Neunkirchen am Sand',
    'Neunkirchen am Potzberg',
    'Neunkirchen',
    'Neunkhausen',
    'Neunheilingen',
    'Neundorf',
    'Neunburg vorm Wald',
    'Neumünster',
    'Neumuehl',
    'Neumarkt-Sankt Veit',
    'Neumarkt in der Oberpfalz',
    'Neumark',
    'Neulussheim',
    'Neulewin',
    'Neuler',
    'Neulehe',
    'Neu Kosenow',
    'Neukloster',
    'Neukirchen vorm Wald',
    'Neukirchen beim Heiligen Blut',
    'Neukirchen-Balbini',
    'Neukirchen am Teisenberg',
    'Neukirchen am Inn',
    'Neukirchen',
    'Neukirch/Lausitz',
    'Neukirch',
    'Neukieritzsch',
    'Neukenroth',
    'Neu Kaliss',
    'Neukalen',
    'Neu Isenburg',
    'Neuhutten',
    'Neuhuetten',
    'Neuhofen',
    'Neuhof an der Zenn',
    'Neuhof',
    'Neuhengstett',
    'Neuhemsbach',
    'Neuhausen ob Eck',
    'Neuhausen auf den Fildern',
    'Neuhausen',
    'Neuhaeusel',
    'Neuhaus an der Pegnitz',
    'Neuhaus am Rennweg',
    'Neuhaus am Inn',
    'Neuhaus',
    'Neuharlingersiel',
    'Neuhardenberg',
    'Neu Guelze',
    'Neugersdorf',
    'Neufraunhofen',
    'Neufra',
    'Neuffen',
    'Neufarn',
    'Neufahrn in Niederbayern',
    'Neufahrn bei Freising',
    'Neuershausen',
    'Neuental',
    'Neuenstein',
    'Neuenstadt am Kocher',
    'Neuensalz',
    'Neuenrade',
    'Neuenpleen',
    'Neuenmarkt',
    'Neuenkirchen',
    'Neuenhofe',
    'Neuenhaus',
    'Neuenhagen',
    'Neuengors',
    'Neuengonna',
    'Neuendorf',
    'Neuendettelsau',
    'Neuendeich',
    'Neuenburg',
    'Neuenburg am Rhein',
    'Neuenbrook',
    'Neudrossenfeld',
    'Neudorf-Bornstein',
    'Neudietendorf',
    'Neudenau',
    'Neudeck',
    'Neu Darchau',
    'Neuburg an der Kammel',
    'Neuburg an der Donau',
    'Neuburg',
    'Neubulach',
    'Neubukow',
    'Neubrunn',
    'Neu Brenz',
    'Neubrandenburg',
    'Neuborger',
    'Neu Boltenhagen',
    'Neubiberg',
    'Neubeuern',
    'Neubeckum',
    'Neubarental',
    'Neu-Anspach',
    'Neualbenreuth',
    'Netzschkau',
    'Netzow',
    'Netzaberg',
    'Nettetal',
    'Nettersheim',
    'Nettelsee',
    'Netphen',
    'Nesselwang',
    'Nerzweiler',
    'Nersingen',
    'Neresheim',
    'Nentershausen',
    'Nennslingen',
    'Nennhausen',
    'Nenndorf',
    'Nellingen',
    'Neitersen',
    'Neidlingen',
    'Neidhartshausen',
    'Neidenstein',
    'Neidenbach',
    'Nehren',
    'Negenborn',
    'Neetze',
    'Neef',
    'Nedlitz',
    'Neddemin',
    'Neckarzimmern',
    'Neckarwestheim',
    'Neckartenzlingen',
    'Neckartailfingen',
    'Neckarsulm',
    'Neckarsteinach',
    'Neckargerach',
    'Neckargemund',
    'Neckarbischofsheim',
    'Nebra',
    'Nebel',
    'Nazza',
    'Nauwalde',
    'Nauroth',
    'Nauort',
    'Naunhof',
    'Naunheim',
    'Naundorf',
    'Naumburg',
    'Nauheim',
    'Nauendorf',
    'Nauen',
    'Nattheim',
    'Nattenheim',
    'Natendorf',
    'Nassenfels',
    'Nassau',
    'Narsdorf',
    'Nanzdietschweiler',
    'Nannhausen',
    'Nandlstadt',
    'Namborn',
    'Nalbach',
    'Naila',
    'Nahrendorf',
    'Nahe',
    'Nagold',
    'Nagel',
    'Nadrensee',
    'Nackenheim',
    'Nack',
    'Nachtsheim',
    'Nachterstedt',
    'Nachrodt-Wiblingwerde',
    'Nabburg',
    'Mylau',
    'Mutterstadt',
    'Mutlangen',
    'Mustin',
    'Mussen',
    'Muschwitz',
    'Muschenbach',
    'Murrhardt',
    'Murr',
    'Murnau am Staffelsee',
    'Murg',
    'Murchin',
    'Muenzingen',
    'Muenster-Sarmsheim',
    'Muenstermaifeld',
    'Munsterhausen',
    'Munsterdorf',
    'Munsterappel',
    'Munster',
    'Münster',
    'Munsingen',
    'Munsing',
    'Munnerstadt',
    'Munkbrarup',
    'Munderkingen',
    'Hannoversch Münden',
    'Mundelsheim',
    'Muenchwies',
    'Muenchweiler an der Rodalbe',
    'Munchweiler an der Alsenz',
    'Munchweiler am Klingbach',
    'Munchsmunster',
    'Muenchengosserstaedt',
    'Munchen',
    'Munich',
    'Muencheberg',
    'Munchberg',
    'Mulsen',
    'Muellrose',
    'Müllheim',
    'Mülheim',
    'Muelheim',
    'Mulfingen',
    'Muldenhammer',
    'Muhlthal',
    'Muehl Rosin',
    'Muhlingen',
    'Muhlheim an der Donau',
    'Muehlheim am Main',
    'Mühlhausen',
    'Muhlhausen',
    'Muehlen Eichsen',
    'Muhlenbach',
    'Muehlen',
    'Muehldorf',
    'Muehlberg',
    'Muhlbach',
    'Muhlau',
    'Muhlacker',
    'Muehbrook',
    'Muggensturm',
    'Muegeln',
    'Mudershausen',
    'Mudersbach',
    'Mudenbach',
    'Muden',
    'Mudau',
    'Muckeln',
    'Muecke',
    'Mucka',
    'Mucheln',
    'Muecheln',
    'Much',
    'Motzingen',
    'Motzing',
    'Mottingen',
    'Motten',
    'Mossingen',
    'Mossautal',
    'Moser',
    'Mosbach',
    'Moertitz',
    'Morsum',
    'Morslingen',
    'Morsfeld',
    'Morscheid',
    'Morsbach',
    'Mornsheim',
    'Morlenbach',
    'Morlen',
    'Moritzburg',
    'Moritz',
    'Moringen',
    'Morel',
    'Morbach',
    'Moosthenning',
    'Moosmuhle',
    'Moosinning',
    'Moosburg',
    'Moosbrunn',
    'Moosbach',
    'Moosach',
    'Moos',
    'Moorrege',
    'Moorenweis',
    'Monzingen',
    'Monzelfeld',
    'Montabaur',
    'Monsheim',
    'Monschau',
    'Monkeberg',
    'Monheim am Rhein',
    'Monheim',
    'Monchsroth',
    'Monchsondheim',
    'Monchsdeggingen',
    'Moenchhagen',
    'Mönchengladbach',
    'Monchberg',
    'Mommenheim',
    'Momlingen',
    'Mombris',
    'Moltzow',
    'Molsdorf',
    'Moelschow',
    'Molschleben',
    'Molsberg',
    'Moelln',
    'Mollhagen',
    'Moellenbeck',
    'Molfsee',
    'Moldenit',
    'Molbergen',
    'Moisburg',
    'Mohsdorf',
    'Moehringen',
    'Mohrendorf',
    'Mohnsen',
    'Mohlsdorf',
    'Moglingen',
    'Mogglingen',
    'Mogendorf',
    'Moers',
    'Mockrehna',
    'Mockmuhl',
    'Moeckern',
    'Mixdorf',
    'Mitwitz',
    'Mittweida',
    'Mitterteich',
    'Mitterskirchen',
    'Mitterfels',
    'Mittenwalde',
    'Mittenwald',
    'Mittenaar',
    'Mittelstreu',
    'Mittelstenahe',
    'Mittelsinn',
    'Mittelschontal',
    'Mittelreidenbach',
    'Mittelherwigsdorf',
    'Mittelhausen',
    'Mitteleschenbach',
    'Mittelbuch',
    'Mittelbiberach',
    'Mittelbach',
    'Mittegrossefehn',
    'Mitlosheim',
    'Mistelgau',
    'Mistelbach',
    'Misselwarden',
    'Misburg',
    'Mirow',
    'Mintraching',
    'Minfeld',
    'Mindersdorf',
    'Minderlittgen',
    'Minden',
    'Mindelstetten',
    'Mindelheim',
    'Mindelaltheim',
    'Milzau',
    'Gross Miltzow',
    'Miltenberg',
    'Miltach',
    'Milkel',
    'Mildstedt',
    'Mildenau',
    'Mihla',
    'Mietingen',
    'Miesenheim',
    'Miesbach',
    'Miellen',
    'Mielkendorf',
    'Miehlen',
    'Midlum',
    'Mickhausen',
    'Michendorf',
    'Michelstadt',
    'Michelfeld',
    'Michelbach an der Bilz',
    'Michelau',
    'Meyenburg',
    'Meuselwitz',
    'Meuro',
    'Meudt',
    'Metzingen',
    'Metzels',
    'Mettmann',
    'Mettlach',
    'Mettingen',
    'Mettenheim',
    'Mettenhausen',
    'Mettendorf',
    'Metten',
    'Metelen',
    'Mestlin',
    'Messstetten',
    'Messkirch',
    'Messingen',
    'Messerich',
    'Messenkamp',
    'Messel',
    'Mesekenhagen',
    'Meseberg',
    'Mescherin',
    'Meschede',
    'Merzkirchen',
    'Merzig',
    'Merzhausen',
    'Merzenich',
    'Merzen',
    'Merzdorf',
    'Merzalben',
    'Merxheim',
    'Merxhausen',
    'Mertingen',
    'Mertendorf',
    'Merseburg',
    'Mermuth',
    'Merklingen',
    'Merkers',
    'Merkendorf',
    'Merken',
    'Merkelbach',
    'Mering',
    'Merenberg',
    'Merdingen',
    'Merchweiler',
    'Merching',
    'Merbelsrod',
    'Meppen',
    'Menteroda',
    'Menslage',
    'Mengkofen',
    'Mengerskirchen',
    'Mengerschied',
    'Mengen',
    'Mengelrode',
    'Mengede',
    'Mendig',
    'Menden',
    'Memmingerberg',
    'Memmingen',
    'Memmelsdorf',
    'Melz',
    'Melsungen',
    'Melsdorf',
    'Melsbach',
    'Mellrichstadt',
    'Mellinghausen',
    'Mellingen',
    'Am Mellensee',
    'Melle',
    'Meldorf',
    'Melchow',
    'Melchingen',
    'Melbeck',
    'Meitingen',
    'Meissenheim',
    'Meissen',
    'Meisenheim',
    'Meisdorf',
    'Meisburg',
    'Meiningen',
    'Meineweh',
    'Meinerzhagen',
    'Meinersen',
    'Meine',
    'Mehrstetten',
    'Mehring',
    'Mehren',
    'Mehltheuer',
    'Mehlmeisel',
    'Mehlingen',
    'Mehlbach',
    'Meggerdorf',
    'Megesheim',
    'Meezen',
    'Meetzen',
    'Meesiger',
    'Meersburg',
    'Meerfeld',
    'Meerbusch',
    'Meerbeck',
    'Meerane',
    'Meeder',
    'Medelby',
    'Medebach',
    'Medard',
    'Mecklenburg',
    'Meckesheim',
    'Meckenheim',
    'Meckenbeuren',
    'Meckel',
    'Mechterstaedt',
    'Mechtersen',
    'Mechernich',
    'Mechelgruen',
    'Mayschoss',
    'Mayen',
    'Maxsain',
    'Maxhutte-Haidhof',
    'Maxdorf',
    'Mauth',
    'Mauschbach',
    'Maulburg',
    'Maulbronn',
    'Maulbeerwalde',
    'Mauerstetten',
    'Mauern',
    'Mauer',
    'Mauchenheim',
    'Matersen',
    'Mastershausen',
    'Massow',
    'Masserberg',
    'Massenhausen',
    'Massenbachhausen',
    'Massbach',
    'Maselheim',
    'Maschen',
    'Marzling',
    'Marxzell',
    'Marxen am Berge',
    'Marxen',
    'Marx',
    'Marwitz',
    'Martinstein',
    'Martinsried',
    'Martinshohe',
    'Martinsheim',
    'Martinfeld',
    'Martfeld',
    'Martensrade',
    'Marsberg',
    'Marpingen',
    'Maroth',
    'Marolterode',
    'Maroldsweisach',
    'Marnheim',
    'Marnerdeich',
    'Marne',
    'Marlow',
    'Marloffstein',
    'Marlesreuth',
    'Marl',
    'Marktzeuln',
    'Markt Wald',
    'Markt Schwaben',
    'Marktschorgast',
    'Marktschellenberg',
    'Markt Rettenbach',
    'Marktredwitz',
    'Marktoffingen',
    'Marktoberdorf',
    'Markt Nordheim',
    'Marktleuthen',
    'Marktleugast',
    'Marktl',
    'Markt Indersdorf',
    'Marktheidenfeld',
    'Markt Erlbach',
    'Marktbreit',
    'Markt Bibart',
    'Markt Berolzheim',
    'Marktbergel',
    'Marksuhl',
    'Markneukirchen',
    'Marklohe',
    'Marklkofen',
    'Markkleeberg',
    'Maerkisch Buchholz',
    'Markgroningen',
    'Markersdorf',
    'Markersbach',
    'Marke',
    'Markdorf',
    'Maring-Noviand',
    'Marienwerder',
    'Mariental',
    'Marienrachdorf',
    'Marienmuenster',
    'Marienheide',
    'Marienhafe',
    'Marienfelde',
    'Marienfeld',
    'Marienborn',
    'Marienberg',
    'Marienau',
    'Mariaposching',
    'Margetshochheim',
    'Marburg',
    'Marbach am Neckar',
    'Marbach',
    'Mantel',
    'Mansfeld',
    'Mannstedt',
    'Mannheim',
    'Manndorf',
    'Manhagen',
    'Manderscheid',
    'Mandern',
    'Mandel',
    'Manching',
    'Mamming',
    'Mammendorf',
    'Mammelzen',
    'Malz',
    'Malterdingen',
    'Malsfeld',
    'Malschwitz',
    'Malsch',
    'Malliss',
    'Malgersdorf',
    'Malente',
    'Malchow',
    'Malching',
    'Malchin',
    'Maitzborn',
    'Maitenbeth',
    'Maisborn',
    'Maisach',
    'Mainzweiler',
    'Mainz',
    'Maintal',
    'Mainstockheim',
    'Mainleus',
    'Mainhardt',
    'Mainburg',
    'Mainbernheim',
    'Mainaschaff',
    'Maikammer',
    'Maihingen',
    'Maierhofen',
    'Mahring',
    'Mahlow',
    'Mahlberg',
    'Magstadt',
    'Magdeburgerforth',
    'Magdeburg',
    'Magdala',
    'Mackenrode',
    'Mackenbach',
    'Macken',
    'Machern',
    'Maasholm',
    'Maasen',
    'Maasdorf',
    'Lychen',
    'Luetzow',
    'Lutzingen',
    'Lutzhorn',
    'Lutzerath',
    'Luetzelbach',
    'Luettgenrode',
    'Lutterbek',
    'Lutter am Barenberge',
    'Lutter',
    'Luettenmark',
    'Luttenbach',
    'Luetow',
    'Lutjensee',
    'Luetjenburg',
    'Luetgenade',
    'Lutau',
    'Lustadt',
    'Luschendorf',
    'Lurschau',
    'Lupendorf',
    'Lupburg',
    'Lunzenau',
    'Luenow',
    'Lünen',
    'Lüneburg',
    'Luelsfeld',
    'Luhe-Wildenau',
    'Luhden',
    'Luegde',
    'Lugau',
    'Luga',
    'Lug',
    'Luerdissen',
    'Ludwigswinkel',
    'Ludwigsstadt',
    'Ludwigslust',
    'Ludwigshohe',
    'Ludwigshafen am Rhein',
    'Ludwigsfelde',
    'Ludwigsburg',
    'Luedinghausen',
    'Luedershagen',
    'Ludersfeld',
    'Ludersburg',
    'Luederitz',
    'Luder',
    'Lüdenscheid',
    'Ludenhausen',
    'Luckwitz',
    'Luckow',
    'Lucklum',
    'Luckenwalde',
    'Luckau',
    'Lucka',
    'Luchow',
    'Luechow',
    'Luebz',
    'Luebtheen',
    'Luebstorf',
    'Luebow',
    'Lubmin',
    'Lübeck',
    'Lubbow',
    'Lubberstedt',
    'Luebbenau',
    'Luebben',
    'Luebbecke',
    'Luebars',
    'Loxstedt',
    'Loewenstein',
    'Lotzbeuren',
    'Lottstetten',
    'Lotte',
    'Lostau',
    'Lossow',
    'Loessnitz',
    'Lossburg',
    'Losheim',
    'Lorzweiler',
    'Lorup',
    'Lorsch',
    'Loerrach',
    'Lorch',
    'Lonzenburg',
    'Lonsee',
    'Lonnig',
    'Lonnerstadt',
    'Loeningen',
    'Longuich',
    'Londorf',
    'Lommatzsch',
    'Lomitz',
    'Lollbach',
    'Lollar',
    'Loitzendorf',
    'Loitz',
    'Loitsche',
    'Loit',
    'Loissin',
    'Loiching',
    'Lohsa',
    'Lohra',
    'Lohr a. Main',
    'Lohnweiler',
    'Lohnsfeld',
    'Lohne',
    'Loehne',
    'Lohnberg',
    'Lohmen',
    'Lohmar',
    'Lohma',
    'Lohfelden',
    'Lohe-Fohrden',
    'Lohbruegge',
    'Lohberg',
    'Lohbarbek',
    'Loffingen',
    'Loffenau',
    'Lof',
    'Lodla',
    'Loddin',
    'Loecknitz',
    'Lochum',
    'Lochtum',
    'Lochhofen',
    'Lochgau',
    'Loburg',
    'Loebnitz',
    'Lobnitz',
    'Loebitz',
    'Lobichau',
    'Bad Lobenstein',
    'Lobenfeld',
    'Loebau',
    'Litzendorf',
    'Litterscheid',
    'List auf Sylt',
    'Lissendorf',
    'Lisberg',
    'Lippstadt',
    'Lipprechterode',
    'Linz am Rhein',
    'Linum',
    'Lintorf',
    'Lintig',
    'Linthe',
    'Lintach',
    'Linsenhofen',
    'Linsburg',
    'Linnich',
    'Linkenbach',
    'Lingerhahn',
    'Lingenfeld',
    'Lingen',
    'Lindwedel',
    'Lindow',
    'Lindlar',
    'Lindhorst',
    'Lindewitt',
    'Lindern',
    'Lindenfels',
    'Lindenberg im Allgau',
    'Lindenberg',
    'Linden',
    'Lindberg',
    'Lindau',
    'Lindach',
    'Linda',
    'Lind',
    'Linau',
    'Limburgerhof',
    'Limburg an der Lahn',
    'Limbach-Oberfrohna',
    'Limbach',
    'Lilienthal',
    'Lierschied',
    'Liepe',
    'Lienen',
    'Liekwegen',
    'Lieg',
    'Liederstaedt',
    'Liebstadt',
    'Lieblingshof',
    'Lieberose',
    'Liebenwalde',
    'Liebenstein',
    'Liebenburg',
    'Liebenau',
    'Lichterfelde',
    'Lichtenstein',
    'Lichtenfels',
    'Lichtenberg',
    'Lichtenau',
    'Lichte',
    'Lich',
    'Leverkusen',
    'Leutkirch',
    'Leutesdorf',
    'Leutershausen',
    'Leutersdorf',
    'Leutenberg',
    'Leutenbach',
    'Leupoldsgrun',
    'Leuna',
    'Leun',
    'Leuchtenberg',
    'Leubsdorf',
    'Leubingen',
    'Letschin',
    'Leopoldshohe',
    'Leonberg',
    'Lenzkirch',
    'Lenzen',
    'Lenting',
    'Lentfohrden',
    'Lensahn',
    'Lennestadt',
    'Lenne',
    'Lenggries',
    'Lengfeld',
    'Lengers',
    'Lengerich',
    'Lengenwang',
    'Lengenfeld',
    'Lengenbostel',
    'Lengefeld',
    'Lengede',
    'Lengdorf',
    'Lemwerder',
    'Lemnitz',
    'Lemgo',
    'Lemfoerde',
    'Lembruch',
    'Lemberg',
    'Leiwen',
    'Leitzkau',
    'Leisnig',
    'Leisel',
    'Leipzig',
    'Leipheim',
    'Leinzell',
    'Leiningen',
    'Leinfelden-Echterdingen',
    'Leinefelde-Worbis',
    'Leinburg',
    'Leimersheim',
    'Leimen',
    'Leiferde',
    'Leidersbach',
    'Leichlingen',
    'Leibolz',
    'Leiblfing',
    'Leibertingen',
    'Leiberg',
    'Lehsten',
    'Lehrte',
    'Lehrensteinsfeld',
    'Lehre',
    'Lehrberg',
    'Lehnin',
    'Lehmkuhlen',
    'Lehmen',
    'Lehesten',
    'Legden',
    'Legau',
    'Leezen',
    'Leezdorf',
    'Leese',
    'Leerstetten',
    'Leer',
    'Leegebruch',
    'Leck',
    'Lechenich',
    'Lechbruck',
    'Lebusa',
    'Lebus',
    'Lebach',
    'Lautzenbrucken',
    'Lautrach',
    'Lautertal',
    'Lautert',
    'Lautern',
    'Lauterhofen',
    'Lauterecken',
    'Lauterbach',
    'Lauterbach/Schwarzwald',
    'Lauterach',
    'Lauter',
    'Lautenbach',
    'Lauta',
    'Laussnitz',
    'Laussig',
    'Lausitz',
    'Lauschgruen',
    'Lauscha',
    'Laupheim',
    'Laumersheim',
    'Lauingen',
    'Laugna',
    'Lauffen am Neckar',
    'Laufersweiler',
    'Laufenburg',
    'Laufen',
    'Lauf an der Pegnitz',
    'Laufach',
    'Lauf',
    'Lauenstein',
    'Lauenhagen',
    'Lauenburg',
    'Lauenbruck',
    'Lauenau',
    'Laudert',
    'Laudenbach',
    'Lauchheim',
    'Lauchhammer',
    'Lauchdorf',
    'Laucha',
    'Laubenheim',
    'Lauben',
    'Laubach',
    'Lathen',
    'Lastrup',
    'Lassan',
    'Lasbek',
    'Laerz',
    'Lappersdorf',
    'Lanz',
    'Langweid',
    'Langwedel',
    'Langstedt',
    'Langquaid',
    'Langlingen',
    'Langgons',
    'Langewiesen',
    'Langewahl',
    'Langerwehe',
    'Langerringen',
    'Langeoog',
    'Langenzenn',
    'Langenwolschendorf',
    'Langenwetzendorf',
    'Langenthal',
    'Langenstein',
    'Langensendelbach',
    'Langenselbold',
    'Langenscheid',
    'Langenpreising',
    'Langenorla',
    'Langenneufnach',
    'Langenmosen',
    'Langenlonsheim',
    'Langenleuba-Niederhain',
    'Langenhorn',
    'Langenhennersdorf',
    'Langenhahn',
    'Langenhagen',
    'Langenfeld',
    'Langenenslingen',
    'Langendorf',
    'Langenburg',
    'Bad Schoenborn',
    'Langenbernsdorf',
    'Langenberg',
    'Langenbach bei Kirburg',
    'Langenbach',
    'Langenau',
    'Langenargen',
    'Langenaltheim',
    'Langen',
    'Langelsheim',
    'Langeln',
    'Langebrueck',
    'Langdorf',
    'Langballig',
    'Landsweiler-Reden',
    'Landstuhl',
    'Landshut',
    'Landscheid',
    'Landsberied',
    'Landsberg',
    'Landsberg am Lech',
    'Landrecht',
    'Landolfshausen',
    'Landkern',
    'Landesbergen',
    'Landenhausen',
    'Landau',
    'Landau an der Isar',
    'Lancken-Granitz',
    'Lamstedt',
    'Lamspringe',
    'Lampertheim',
    'Lamerdingen',
    'Lambsheim',
    'Lambrechtshagen',
    'Lambrecht',
    'Lam',
    'Lalendorf',
    'Laichingen',
    'Lahr',
    'Lahnstein',
    'Lahn',
    'Lagesbuettel',
    'Lagerdorf',
    'Lage',
    'Bad Laer',
    'Laer',
    'Ladenburg',
    'Ladelund',
    'Ladbergen',
    'Lachendorf',
    'Laboe',
    'Laberweinting',
    'Laatzen',
    'Bad Laasphe',
    'Laasdorf',
    'Laar',
    'Laage',
    'Laaberberg',
    'Laaber',
    'Kyritz',
    'Kyllburg',
    'Kutzhof',
    'Kutzenhausen',
    'Kutenholz',
    'Kusterdingen',
    'Kuessaberg',
    'Kusel',
    'Kurten',
    'Seiffen',
    'Rathen',
    'Oberwiesenthal',
    'Kurort Jonsdorf',
    'Kurort Gohrisch',
    'Kurnbach',
    'Kurnach',
    'Kueps Oberfranken',
    'Kuppingen',
    'Kuppenheim',
    'Kupferzell',
    'Kupferdreh',
    'Kunzing',
    'Kuenzelsau',
    'Kunzell',
    'Kunreuth',
    'Kummersbruck',
    'Kummerow',
    'Kummerfeld',
    'Kumhausen',
    'Kuembdchen',
    'Kulsheim',
    'Kulmbach',
    'Kulmain',
    'Kukels',
    'Kuhstorf',
    'Kuhlrade',
    'Kuhlenthal',
    'Kuhlen',
    'Kuhbach',
    'Kuhardt',
    'Kuden',
    'Kuddeworde',
    'Kuchen',
    'Kuchelmiss',
    'Kubschutz',
    'Krusenfelde',
    'Krunkel',
    'Kruen',
    'Krumstedt',
    'Krummwisch',
    'Krummesse',
    'Krummennaab',
    'Krummendeich',
    'Krummel',
    'Krummbek',
    'Krumhermersdorf',
    'Krumbek',
    'Krumbach',
    'Krugzell',
    'Krugsdorf',
    'Kruft',
    'Kruckow',
    'Kruchten',
    'Krov',
    'Krostitz',
    'Kroppenstedt',
    'Kroppen',
    'Kroeppelshagen-Fahrendorf',
    'Kroppach',
    'Kropp',
    'Kroepelin',
    'Kronshagen',
    'Kronprinzenkoog',
    'Kronburg',
    'Kronberg',
    'Kronau',
    'Kronach',
    'Krombach',
    'Krolpa',
    'Kroebeln',
    'Kritzow',
    'Kritzmow',
    'Kripp',
    'Kriftel',
    'Kriebitzsch',
    'Krickenbach',
    'Kreuzwertheim',
    'Kreuztal',
    'Kreuzau',
    'Kreuth',
    'Kretzschau',
    'Kressbronn am Bodensee',
    'Krempermoor',
    'Krempel',
    'Krempe',
    'Kremmen',
    'Krembz',
    'Kreischa',
    'Kreien',
    'Krefeld',
    'Krautheim',
    'Krauthausen',
    'Krausnick',
    'Krauschwitz',
    'Kraupa',
    'Krauchenwies',
    'Kranzberg',
    'Kranichfeld',
    'Kranenburg',
    'Kramerhof',
    'Krakow am See',
    'Krailling',
    'Kraiburg am Inn',
    'Kraftsdorf',
    'Kraftisried',
    'Koxhausen',
    'Kowerich',
    'Kotzenbull',
    'Kotzen',
    'Kottweiler-Schwanden',
    'Kotterichen',
    'Kottenheim',
    'Köthen',
    'Mainz Kostheim',
    'Chostlarn',
    'Kossau',
    'Kospoda',
    'Koserow',
    'Kosel',
    'Kosching',
    'Korswandt',
    'Korschenbroich',
    'Kornwestheim',
    'Korntal',
    'Korner',
    'Korle',
    'Kordel',
    'Korbussen',
    'Korborn',
    'Korbach',
    'Korb',
    'Konzenberg',
    'Konzell',
    'Konz',
    'Konstanz',
    'Konradsreuth',
    'Konradshofen',
    'Konow',
    'Konnersreuth',
    'Koennern',
    'Konken',
    'Koenigs Wusterhausen',
    'Koenigswinter',
    'Konigswartha',
    'Konigswalde',
    'Koenigstein im Taunus',
    'Koenigstein',
    'Konigssee',
    'Konigsmoor',
    'Koenigslutter am Elm',
    'Koenigshuette',
    'Bad Konigshofen im Grabfeld',
    'Konigshain',
    'Konigsfeld',
    'Konigsfeld im Schwarzwald',
    'Konigseggwald',
    'Koenigsee',
    'Konigsdorf',
    'Konigsbrunn',
    'Konigsbruck',
    'Königsbronn Abbey',
    'Koenigsborn',
    'Konigsberg in Bayern',
    'Konigheim',
    'Kongernheim',
    'Kongen',
    'Kommern',
    'Cologne',
    'Kollow',
    'Kolln-Reisiek',
    'Kollnburg',
    'Kollmar',
    'Kollig',
    'Koelleda',
    'Kolkwitz',
    'Kolitzheim',
    'Kolbingen',
    'Kolbermoor',
    'Kohlberg',
    'Kofering',
    'Koditz',
    'Kodersdorf',
    'Koeckern',
    'Kochel',
    'Kobrow',
    'Koblenz',
    'Knorrendorf',
    'Knoringen',
    'Knopp-Labach',
    'Knittlingen',
    'Knittelsheim',
    'Knetzgau',
    'Kluetz',
    'Kludenbach',
    'Kloetze',
    'Klotten',
    'Klostermoor',
    'Kloster Modingen',
    'Klostermansfeld',
    'Klosterlechfeld',
    'Klockries',
    'Klobikau',
    'Klixbuell',
    'Klipphausen',
    'Klinkrade',
    'Klink',
    'Klingsmoos',
    'Klingenthal',
    'Klingenmunster',
    'Klingenberg am Main',
    'Klingenberg',
    'Klingelbach',
    'Klinga',
    'Klietz',
    'Klieken',
    'Kleve',
    'Klettwitz',
    'Klettbach',
    'Klempau',
    'Klein-Winternheim',
    'Klein Wesenberg',
    'Kleinwallstadt',
    'Klein Vielen',
    'Kleinschwabhausen',
    'Klein Schneen',
    'Klein Roennau',
    'Kleinromstedt',
    'Klein Rogahn',
    'Kleinrinderfeld',
    'Klein Quenstedt',
    'Klein Pampau',
    'Kleinostheim',
    'Kleinopitz',
    'Kleinobringen',
    'Klein Nordende',
    'Kleinneuhausen',
    'Klein Meckelsen',
    'Kleinmaischeid',
    'Kleinmachnow',
    'Kleinlangheim',
    'Kleinlangenfeld',
    'Klein Krankow',
    'Kleinkmehlen',
    'Kleinkarlbach',
    'Kleinkahl',
    'Kleinich',
    'Kleinheubach',
    'Klein Gladebrugge',
    'Klein-Gerau',
    'Kleinfurra',
    'Kleinfahner',
    'Kleineutersdorf',
    'Kleinebersdorf',
    'Kleinbundenbach',
    'Kleinblittersdorf',
    'Klein Bennebek',
    'Klein Below',
    'Kleeth',
    'Rauhenebrach',
    'Klausen',
    'Klappholz',
    'Kladrum',
    'Kitzscher',
    'Kitzingen',
    'Kittendorf',
    'Kist',
    'Kisslegg',
    'Kissing',
    'Kissenbruck',
    'Kisselbach',
    'Kisdorf',
    'Kirtorf',
    'Kirschweiler',
    'Kirschkau',
    'Kirschau',
    'Kirrweiler',
    'Kirn',
    'Kirkel',
    'Kirf',
    'Kirchzell',
    'Kirchzarten',
    'Kirchworbis',
    'Kirchweiler',
    'Kirchweidach',
    'Kirchwalsede',
    'Kirchseeon',
    'Kirchroth',
    'Kirchmoeser',
    'Kirchlinteln',
    'Kirchlengern',
    'Kirchlauter',
    'Kirch Jesar',
    'Kirchhundem',
    'Kirchheim unter Teck',
    'Kirchheimbolanden',
    'Kirchheim bei Muenchen',
    'Kirchheim an der Weinstrasse',
    'Kirchheim am Neckar',
    'Kirchheim',
    'Kirchheilingen',
    'Kirchhausen',
    'Kirchhatten',
    'Kirchhaslach',
    'Kirchham',
    'Kirchhain',
    'Kirchgellersen',
    'Kirchgandern',
    'Kirchenthumbach',
    'Kirchentellinsfurt',
    'Kirchensittenbach',
    'Kirchenpingarten',
    'Kirchenlamitz',
    'Kirchendemenreuth',
    'Kirchen',
    'Eiselfing',
    'Kirchehrenbach',
    'Kirchdorf im Wald',
    'Kirchdorf am Inn',
    'Kirchdorf',
    'Kirchbrak',
    'Kirchberg an der Murr',
    'Kirchberg an der Jagst',
    'Kirchberg an der Iller',
    'Kirchberg',
    'Kirchbarkau',
    'Kirchasch',
    'Kirchardt',
    'Kirchanschoring',
    'Kirburg',
    'Kippenheim',
    'Kippenhausen',
    'Kipfenberg',
    'Kinsau',
    'Kindsbach',
    'Kinding',
    'Kindenheim',
    'Kindelbrueck',
    'Kilianstaedten',
    'Kieselbronn',
    'Kieselbach',
    'Kierspe',
    'Kiel',
    'Kiefersfelden',
    'Kiedrich',
    'Kiebitzreihe',
    'Kevelaer',
    'Keula',
    'Ketzin',
    'Kettig',
    'Kettenhausen',
    'Ketsch',
    'Kestrich',
    'Kesten',
    'Kesselsdorf',
    'Kesfeld',
    'Kerzenheim',
    'Kerschenbach',
    'Kerpen',
    'Kernen im Remstal',
    'Kerlingen',
    'Kenzingen',
    'Kenn',
    'Kempten (Allgaeu)',
    'Kempenich',
    'Kempen',
    'Kemnath',
    'Kemmern',
    'Kemberg',
    'Kelsterbach',
    'Kellmunz',
    'Kellinghusen',
    'Kellenhusen',
    'Kelkheim (Taunus)',
    'Kelheim',
    'Kelbra',
    'Kelberg',
    'Keidelheim',
    'Kehlbach',
    'Kehl',
    'Kefferhausen',
    'Kefenrod',
    'Kaulsdorf',
    'Kaufering',
    'Kaufbeuren',
    'Kaub',
    'Katzweiler',
    'Katzhuette',
    'Katzenelnbogen',
    'Katzenbach',
    'Kattendorf',
    'Kastorf',
    'Kastl',
    'Kasten',
    'Kastellaun',
    'Kastel',
    'Kassel',
    'Kasseedorf',
    'Kasendorf',
    'Kasel-Golzig',
    'Kasdorf',
    'Karstaedt',
    'Karsbach',
    'Karlum',
    'Karlstadt am Main',
    'Karlsruhe',
    'Karlskron',
    'Karlshuld',
    'Karlshausen',
    'Karlshagen',
    'Bad Karlshafen',
    'Karlsfeld',
    'Karlsburg',
    'Kargow',
    'Karben',
    'Karbach',
    'Kappelrodeck',
    'Kappeln',
    'Kappel',
    'Kapfenhardt',
    'Kanzem',
    'Kandern',
    'Kandel',
    'Kamp-Lintfort',
    'Kampen',
    'Kamp-Bornhofen',
    'Kammerstein',
    'Kammerforst',
    'Kammer',
    'Kamenz',
    'Kamen',
    'Kalubbe',
    'Kaltenwestheim',
    'Kaltental',
    'Kaltennordheim',
    'Kaltenlengsfeld',
    'Kaltenkirchen',
    'Kaltenengers',
    'Kallstadt',
    'Kallmunz',
    'Kall',
    'Kalkhorst',
    'Kalkar',
    'Kalenborn',
    'Kalefeld',
    'Kalchreuth',
    'Kalbsrieth',
    'Kalbe',
    'Kakoehl',
    'Kakenstorf',
    'Kaisheim',
    'Kaiserslautern',
    'Kaisersesch',
    'Kaisersbach',
    'Kaiseringen',
    'Kail',
    'Kahl am Main',
    'Kahla',
    'Kagel',
    'Kadenbach',
    'Kaden',
    'Kade',
    'Kaarst',
    'Kaaks',
    'Jueterbog',
    'Juergenstorf',
    'Juenkerath',
    'Jungingen',
    'Juelich',
    'Julbach',
    'Juist',
    'Jugenheim',
    'Judenbach',
    'Juchen',
    'Juebek',
    'Joessnitz',
    'Joernstorf',
    'Joerl',
    'Jork',
    'Joldelund',
    'Johanniskirchen',
    'Johanngeorgenstadt',
    'Johannesberg',
    'Jockgrim',
    'Joachimsthal',
    'Jever',
    'Jevenstedt',
    'Jetzendorf',
    'Jettenhausen',
    'Jettenbach',
    'Jestetten',
    'Jesteburg',
    'Jessnitz',
    'Jessen',
    'Jesewitz',
    'Jesenwang',
    'Jersbek',
    'Jerichow',
    'Jengen',
    'Jena',
    'Jemgum',
    'Jembke',
    'Jelmstorf',
    'Jatznick',
    'Jarmen',
    'Jardelund',
    'Jaenschwalde Ost',
    'Janschwalde',
    'Janneby',
    'Jandelsbrunn',
    'Jakobsweiler',
    'Jahnsdorf',
    'Jagstzell',
    'Jagsthausen',
    'Jagel',
    'Jaderberg',
    'Jachenhausen',
    'Jachenau',
    'Ivenack',
    'Itzstedt',
    'Itzgrund',
    'Itzehoe',
    'Ittlingen',
    'Itterbeck',
    'Issum',
    'Issing',
    'Issersheilingen',
    'Isseroda',
    'Isselhorst',
    'Isselburg',
    'Ispringen',
    'Isny',
    'Ismaning',
    'Isert',
    'Iserlohn',
    'Isenbuttel',
    'Isenburg',
    'Isen',
    'Irxleben',
    'Irsee',
    'Irschenberg',
    'Irsch',
    'Irndorf',
    'Irrel',
    'Irmenach',
    'Irlbach',
    'Irchenrieth',
    'Ipsheim',
    'Ippesheim',
    'Iphofen',
    'Inzlingen',
    'Inzigkofen',
    'Inzell',
    'Insingen',
    'Insheim',
    'Insel Poel',
    'Inning am Holz',
    'Inning am Ammersee',
    'Innernzell',
    'Ingolstadt',
    'Ingoldingen',
    'Ingenried',
    'Ingendorf',
    'Ingelheim am Rhein',
    'Ingelfingen',
    'Inden',
    'Inchenhofen',
    'Imsweiler',
    'Imsbach',
    'Impfingen',
    'Immert',
    'Immenstedt',
    'Immenstadt im Allgau',
    'Immenstaad am Bodensee',
    'Immenreuth',
    'Immenhausen',
    'Immendingen',
    'Ilvesheim',
    'Ilshofen',
    'Ilsfeld',
    'Ilsenburg',
    'Ilmmunster',
    'Ilmenau',
    'Illschwang',
    'Illmensee',
    'Illingen',
    'Illertissen',
    'Illerrieden',
    'Illerich',
    'Illerbeuren',
    'Ilfeld',
    'Ilbesheim',
    'Ilbeshausen',
    'Ihrlerstein',
    'Ihringen',
    'Ihlienworth',
    'Iggingen',
    'Iggensbach',
    'Iggelbach',
    'Igersheim',
    'Igensdorf',
    'Igenhausen',
    'Igel',
    'Iffezheim',
    'Idstein',
    'Idstedt',
    'Idesheim',
    'Iden',
    'Idar-Oberstein',
    'Icking',
    'Ichtershausen',
    'Ichenheim',
    'Ichenhausen',
    'Ibbenbueren',
    'Ibach',
    'Huttlingen',
    'Huttisheim',
    'Hutthurm',
    'Huettenrode',
    'Huttenheim',
    'Huttenberg',
    'Hutschenhausen',
    'Husum',
    'Hustedt',
    'Husby',
    'Hurup',
    'Hurtgenwald',
    'Hurlach',
    'Huepstedt',
    'Hupperath',
    'Hunteburg',
    'Huenstetten',
    'Hungenroth',
    'Hungen',
    'Huenfeld',
    'Hundsangen',
    'Hundisburg',
    'Hunding',
    'Hundeshagen',
    'Hundersingen',
    'Hunderdorf',
    'Hulsede',
    'Huelseburg',
    'Hulben',
    'Huje',
    'Huisheim',
    'Huglfing',
    'Hugelsheim',
    'Hufingen',
    'Huden',
    'Hude',
    'Huede',
    'Hueckeswagen',
    'Hueckelhoven',
    'Hoym',
    'Hoyerswerda',
    'Hoya',
    'Hovelhof',
    'Hottingen',
    'Hottenbach',
    'Hotensleben',
    'Hosskirch',
    'Hoslwang',
    'Hosenfeld',
    'Hosbach',
    'Hoerup',
    'Horstmar',
    'Hoerstel',
    'Horstedt',
    'Horst',
    'Hoerselgau',
    'Horscheid',
    'Horrweiler',
    'Horperath',
    'Hoernum',
    'Hornstorf',
    'Hornhausen',
    'Horneburg',
    'Hornburg',
    'Hornberg',
    'Horn-Bad Meinberg',
    'Hornbach',
    'Hoerlitz',
    'Hoeringhausen',
    'Hoeringen',
    'Horheim',
    'Horhausen',
    'Horgertshausen',
    'Horgenzell',
    'Horgau',
    'Hordt',
    'Horb am Neckar',
    'Horbach',
    'Hopsten',
    'Hoppenrade',
    'Hoppegarten',
    'Hopfingen',
    'Hopfigheim',
    'Hooksiel',
    'Hoogstede',
    'Hontheim',
    'Hoenow',
    'Honigsee',
    'Honerath',
    'Homburg',
    'Homberg (Efze)',
    'Homberg (Ohm)',
    'Holzwickede',
    'Holzminden',
    'Holzmaden',
    'Holzkirchen',
    'Holzkirch',
    'Holzheim',
    'Holzhausen bei Buchloe',
    'Holzhausen an der Haide',
    'Holzhausen',
    'Holzhau',
    'Holzgerlingen',
    'Holzen',
    'Holzappel',
    'Holweide',
    'Holungen',
    'Holtsee',
    'Holtland',
    'Hoeltinghausen',
    'Holthusen',
    'Holtgast',
    'Holtendorf',
    'Holt',
    'Holsthum',
    'Holm',
    'Hollstadt',
    'Hollnich',
    'Hollingstedt',
    'Hollfeld',
    'Hollern',
    'Hollenstedt',
    'Hollenbach',
    'Hollen',
    'Holle',
    'Holldorf',
    'Holdorf',
    'Hoisdorf',
    'Hoehr-Grenzhausen',
    'Hohnstein',
    'Hohnhorst',
    'Hohne',
    'Hohndorf',
    'Hohn',
    'Hohfroschen',
    'Hohenwestedt',
    'Hohenwarthe',
    'Hohenwarth',
    'Hohenwart',
    'Hohenwarsleben',
    'Hohen Wangelin',
    'Hohen Viecheln',
    'Hohenthann',
    'Hohentengen',
    'Hohentanne',
    'Hohen-Sulzen',
    'Hohenstein-Ernstthal',
    'Hohenstadt',
    'Hohenseefeld',
    'Hohenseeden',
    'Hohenschaftlarn',
    'Hohenroth',
    'Hohenpolding',
    'Hohenpeissenberg',
    'Hohen Neuendorf',
    'Hohenmoelsen',
    'Hohen Luckow',
    'Hohenlockstedt',
    'Hohenlinden',
    'Hohenleuben',
    'Hohenleipisch',
    'Hohenkirchen',
    'Hohenkammer',
    'Hohenhaslach',
    'Hohenhameln',
    'Hohenguestow',
    'Hohenfurch',
    'Hohenfels',
    'Hohenfelde',
    'Hohendorf',
    'Hohenbucko',
    'Hohenbrunn',
    'Hohenbruch',
    'Hohenbocka',
    'Hohenberg-Krusemark',
    'Hohenberg an der Eger',
    'Hohenaspe',
    'Hohenahr',
    'Hoheischweiler',
    'Hoeheinoed',
    'Hohebach',
    'Hohberg',
    'Hogsdorf',
    'Hofstetten',
    'Hofkirchen',
    'Hofheim in Unterfranken',
    'Hofheim am Taunus',
    'Hofheim',
    'Hofgeismar',
    'Hoffeld',
    'Hoefer',
    'Hofen an der Enz',
    'Hofeld',
    'Hofbieber',
    'Hof',
    'Hodorf',
    'Hodenhagen',
    'Hockenheim',
    'Hoeckendorf',
    'Hoechst im Odenwald',
    'Hochstenbach',
    'Hoechstberg',
    'Hochstatten',
    'Hochstadt an der Donau',
    'Hochstadt am Main',
    'Hochstadt an der Aisch',
    'Hochspeyer',
    'Hochkirch',
    'Hochheim am Main',
    'Hochheim',
    'Hochenschwand',
    'Hochdorf',
    'Hochberg',
    'Hobeck',
    'Hitzhusen',
    'Hitzhofen',
    'Hitzacker',
    'Hittbergen',
    'Hirz-Maulsbach',
    'Hirzenhain',
    'Hirtscheid',
    'Hirschfelde',
    'Hirschfeld',
    'Hirschberg',
    'Hirschbach',
    'Hirschau',
    'Hirschaid',
    'Hirrlingen',
    'Hipstedt',
    'Hinterzarten',
    'Hinterweidenthal',
    'Hinterschmiding',
    'Hinte',
    'Hinsdorf',
    'Bad Hindelang',
    'Himmelpforten',
    'Himmelkron',
    'Hilzingen',
    'Hiltpoltstein',
    'Hilter',
    'Hiltenfingen',
    'Hilpoltstein',
    'Hillscheid',
    'Hillesheim',
    'Hillerse',
    'Hille',
    'Hilkenbrook',
    'Hilgertshausen-Tandern',
    'Hilgermissen',
    'Hildrizhausen',
    'Hildesheim',
    'Hilders',
    'Hilden',
    'Hildburghausen',
    'Hilchenbach',
    'Hiddenhausen',
    'Heyerode',
    'Heuweiler',
    'Heusweiler',
    'Heustreu',
    'Heusenstamm',
    'Heufeld',
    'Heuerssen',
    'Heudeber',
    'Heuchlingen',
    'Heuchelheim',
    'Heubach',
    'Hetzles',
    'Hetzerath',
    'Hetzenhausen',
    'Hettstedt',
    'Hettstadt',
    'Hettingen',
    'Hettenshausen',
    'Hettenleidelheim',
    'Hetschburg',
    'Hetlingen',
    'Hessisch Oldendorf',
    'Hessisch Lichtenau',
    'Hessigheim',
    'Hessheim',
    'Hessen',
    'Hessdorf',
    'Hespe',
    'Hesel',
    'Herzogsweiler',
    'Herzogenrath',
    'Herzogenaurach',
    'Herzlake',
    'Herzebrock',
    'Herzberg am Harz',
    'Herzberg',
    'Herxheim',
    'Herten',
    'Herscheid',
    'Herschbroich',
    'Herschbach',
    'Hersbruck',
    'Herrstein',
    'Herrsching am Ammersee',
    'Herrnhut',
    'Herrngiersdorf',
    'Herrnburg',
    'Herrmannsacker',
    'Herrischried',
    'Herrieden',
    'Herrenhof',
    'Herrenberg',
    'Heroldsberg',
    'Heroldsbach',
    'Herold',
    'Herne',
    'Hermsdorf',
    'Hermeskeil',
    'Hermersberg',
    'Hermaringen',
    'Hermannsburg',
    'Herleshausen',
    'Herl',
    'Heringsdorf',
    'Heringen',
    'Hergolding',
    'Hergisdorf',
    'Hergensweiler',
    'Herforst',
    'Herford',
    'Herfatz',
    'Heretsried',
    'Herdorf',
    'Herdecke',
    'Herbstein',
    'Herbstadt',
    'Herbsleben',
    'Herbrechtingen',
    'Herborn',
    'Herbolzheim',
    'Herbertingen',
    'Hepstedt',
    'Heppenheim an der Bergstrasse',
    'Hepberg',
    'Hentern',
    'Hennweiler',
    'Hennstedt',
    'Hennigsdorf',
    'Hennickendorf',
    'Hennef',
    'Henneberg',
    'Hengersberg',
    'Hemslingen',
    'Hemsbuende',
    'Hemsbach',
    'Hemmoor',
    'Hemmingstedt',
    'Hemmingen',
    'Hemhofen',
    'Hemer',
    'Hemdingen',
    'Hemau',
    'Heltersberg',
    'Helse',
    'Helsa',
    'Helpsen',
    'Helmstedt',
    'Helmste',
    'Helmstadt',
    'Helmenzen',
    'Helmbrechts',
    'Hellingst',
    'Hellingen',
    'Hellenthal',
    'Hellenhahn-Schellenberg',
    'Helferskirchen',
    'Helfenbrunn',
    'Heldrungen',
    'Heldritt',
    'Heldburg',
    'Helbra',
    'Heitersheim',
    'Heistenbach',
    'Heist',
    'Heinzenhausen',
    'Heinzenbach',
    'Heinstetten',
    'Heinsen',
    'Heinsberg',
    'Heinrichswalde',
    'Heinrichsthal',
    'Heinrichshofen',
    'Heinrichsberg',
    'Heiningen',
    'Heinersreuth',
    'Heinersdorf',
    'Heinersbruck',
    'Heinboeckel',
    'Heinade',
    'Heimstetten',
    'Heimsheim',
    'Heimertingen',
    'Heimenkirch',
    'Heimbuchenthal',
    'Heimbach',
    'Heilsbronn',
    'Heiligkreuzsteinach',
    'Heiligenthal',
    'Heiligenstedtenerkamp',
    'Heiligenstedten',
    'Heilbad Heiligenstadt',
    'Heiligenstadt',
    'Heiligenroth',
    'Heiligenhaus',
    'Heiligenhagen',
    'Heiligenhafen',
    'Heiligengrabe',
    'Heiligenberg',
    'Heilenbach',
    'Heilbronn',
    'Heilberscheid',
    'Heikendorf',
    'Heidgraben',
    'Heidesheim',
    'Heidersdorf',
    'Heidenkam',
    'Heidenheim',
    'Heidenburg',
    'Heidenau',
    'Heiden',
    'Heidelberg',
    'Heidekrug',
    'Heideck',
    'Heide',
    'Hehlen',
    'Heessen',
    'Heeslingen',
    'Heemsen',
    'Heek',
    'Heede',
    'Hedersleben',
    'Hedeper',
    'Guldental',
    'Heddesheim',
    'Heddesbach',
    'Heddert',
    'Hecklingen',
    'Heckenmunster',
    'Hechthausen',
    'Hechingen',
    'Hebertshausen',
    'Hebertsfelden',
    'Heber',
    'Hebenshausen',
    'Hayna',
    'Hayingen',
    'Hawangen',
    'Havixbeck',
    'Havetoft',
    'Haverlah',
    'Havelberg',
    'Hauzenberg',
    'Hauteroda',
    'Hausweiler',
    'Hauswalde',
    'Haussommern',
    'Hausneindorf',
    'Hausham',
    'Hausern',
    'Hausen ob Verena',
    'Hausen',
    'Hausbay',
    'Hausach',
    'Haunwang',
    'Haunsheim',
    'Hauenstein',
    'Hatzfeld',
    'Hatzenbuhl',
    'Hattstedt',
    'Hattorf',
    'Hattingen',
    'Hattersheim',
    'Hattenhofen',
    'Hatten',
    'Haste',
    'Hassmersheim',
    'Hassloch',
    'Haßfurt',
    'Hassendorf',
    'Hasselfelde',
    'Hasselberg',
    'Hasselbach',
    'Hassel',
    'Hassbergen',
    'Hasloh',
    'Hasloch',
    'Haslach im Kinzigtal',
    'Haslach',
    'Haseluenne',
    'Haselund',
    'Haseloff',
    'Haselhorst',
    'Haselbach',
    'Haselau',
    'Hasel',
    'Haschbach am Remigiusberg',
    'Hasborn',
    'Hasbergen',
    'Harzgerode',
    'Harxheim',
    'Hartmannsgruen',
    'Hartmannsdorf',
    'Hartheim',
    'Harthausen auf der Scheer',
    'Harthausen',
    'Hartha',
    'Kurort Hartha',
    'Hartenstein',
    'Hartenholm',
    'Hartenfels',
    'Harsum',
    'Harsleben',
    'Harsewinkel',
    'Harsefeld',
    'Harschbach',
    'Harrislee',
    'Harpstedt',
    'Harmsdorf',
    'Harkerode',
    'Hargesheim',
    'Hargarten',
    'Haren',
    'Hardt',
    'Hardheim',
    'Hardegsen',
    'Harburg',
    'Harbach',
    'Happurg',
    'Hanstedt',
    'Hanshagen',
    'Hanroth',
    'Hanover',
    'Hankensbuttel',
    'Hanhofen',
    'Hangen-Weisheim',
    'Hanerau-Hademarschen',
    'Handorf',
    'Handewitt',
    'Handeloh',
    'Hanau',
    'Hamweddel',
    'Hamwarde',
    'Hamminkeln',
    'Hammersbach',
    'Hammelburg',
    'Hammelbach',
    'Hammah',
    'Hamm',
    'Hamfelde in Lauenburg',
    'Hamelin',
    'Hamdorf',
    'Altona',
    'Hamburg',
    'Hambuhren',
    'Hambrucken',
    'Hambergen',
    'Hamberge',
    'Halver',
    'Haltern am See',
    'Halstenbek',
    'Halsenbach',
    'Halsbrucke',
    'Hallstadt',
    'Hallschlag',
    'Hallgarten',
    'Hallerndorf',
    'Halle',
    'Hallbergmoos',
    'Halfing',
    'Haldenwang',
    'Haldensleben I',
    'Halblech',
    'Halberstadt',
    'Halbe',
    'Hakenstedt',
    'Haiterbach',
    'Hainstadt',
    'Hainspitz',
    'Hainsfarth',
    'Hainichen',
    'Hainfeld',
    'Hainewalde',
    'Haina',
    'Haiming',
    'Haimhausen',
    'Hailer',
    'Haigerloch',
    'Haiger',
    'Haidmuhle',
    'Haidlfing',
    'Haibach',
    'Hahnstatten',
    'Hahnichen',
    'Hahnheim',
    'Hahnenbach',
    'Hahn bei Wallmerod',
    'Hahnbach',
    'Hahausen',
    'Hagsdorf',
    'Hagermarsch',
    'Hagenow',
    'Hagen im Bremischen',
    'Hagenburg',
    'Hagenbach',
    'Hagen',
    'Hagelstadt',
    'Hage',
    'Hag',
    'Haftenkamp',
    'Hafenlohr',
    'Hafendorf',
    'Hadmersleben',
    'Hadersbach',
    'Hademstorf',
    'Hadamar',
    'Hackenheim',
    'Hachenburg',
    'Haby',
    'Habscheid',
    'Habach',
    'Haaren',
    'Haarbach',
    'Haar',
    'Haan',
    'Haale',
    'Fraunberg',
    'Haag an der Amper',
    'Haag',
    'Gyhum',
    'Guxhagen',
    'Guetzkow',
    'Gutweiler',
    'Gutow',
    'Guthmannshausen',
    'Gütersloh',
    'Gutenstetten',
    'Guetenbach',
    'Guteborn',
    'Gutach im Breisgau',
    'Gutach',
    'Gusterath',
    'Guesten',
    'Gusenburg',
    'Guesen',
    'Gueritz',
    'Gunzenhausen',
    'Guenzburg',
    'Guenzach',
    'Guenthersleben',
    'Guntersleben',
    'Guntersblum',
    'Guenstedt',
    'Gundremmingen',
    'Gundlkofen',
    'Gundheim',
    'Gundersweiler',
    'Gundersheim',
    'Gundelsheim',
    'Gundelfingen',
    'Gumtow',
    'Gumpelstadt',
    'Gummersbach',
    'Guelze',
    'Gueltz',
    'Guhrow',
    'Guglingen',
    'Gudow',
    'Gudensberg',
    'Gudendorf',
    'Guckingen',
    'Guckheim',
    'Guebs',
    'Guben',
    'Gschwend',
    'Grunwald',
    'Gruenstaedtel',
    'Gruenstadt',
    'Grunsfeld',
    'Gruenow',
    'Grunow',
    'Grunmettstetten',
    'Gruenkraut',
    'Gruenheide',
    'Gruenhainichen',
    'Gruenenplan',
    'Grunendeich',
    'Grundshagen',
    'Grundhof',
    'Gruenberg',
    'Gruenbach',
    'Gruibingen',
    'Grube',
    'Grub am Forst',
    'Grub',
    'Grove',
    'Aichtal',
    'Grotzingen',
    'Gross-Zimmern',
    'Gross Wokern',
    'Gross Wittensee',
    'Grosswilsdorf',
    'Grosswelzheim',
    'Grossweitzschen',
    'Grossweil',
    'Grosswallstadt',
    'Grossvillars',
    'Gross-Umstadt',
    'Gross Twulpstedt',
    'Trebel',
    'Grosssolt',
    'Gross Siemz',
    'Grossseifen',
    'Gross Schwulper',
    'Grossschweidnitz',
    'Gross Schwansee',
    'Grossschwabhausen',
    'Grossschonau',
    'Grossschirma',
    'Gross Schenkenberg',
    'Gross Santersleben',
    'Grossruckerswalde',
    'Grossrosseln',
    'Gross Rosenburg',
    'Grossroehrsdorf',
    'Gross-Rohrheim',
    'Grossrinderfeld',
    'Grossraeschen',
    'Gross Quenstedt',
    'Grosspostwitz',
    'Grossposna',
    'Gross Plasten',
    'Gross Pankow',
    'Gross Pampau',
    'Grossostheim',
    'Grossolbersdorf',
    'Gross Oesingen',
    'Gross Nordsee',
    'Gross Mohrdorf',
    'Grossmehring',
    'Grossmaischeid',
    'Grossloebichau',
    'Gross Lindow',
    'Grosslangheim',
    'Grosslangenfeld',
    'Gross Lafferde',
    'Gross Laasch',
    'Gross Kummerfeld',
    'Grosskrotzenburg',
    'Gross Kreutz',
    'Grosskotz',
    'Gross Koelzig',
    'Gross Kiesow',
    'Gross Kedingshagen',
    'Grosskayna',
    'Grosskarolinenfeld',
    'Gross Ippener',
    'Grossholbach',
    'Grossheubach',
    'Grossheringen',
    'Grosshennersdorf',
    'Grossheirath',
    'Grosshartmannsdorf',
    'Grossharthau',
    'Grosshansdorf',
    'Grosshabersdorf',
    'Gross Gronau',
    'Gross-Gerau',
    'Grosserlach',
    'Grossenwiehe',
    'Grossenseebach',
    'Grossensee',
    'Grossenrade',
    'Grossenluder',
    'Grossenkneten',
    'Grossenhof',
    'Grossenhain',
    'Grossengottern',
    'Grossenehrich',
    'Grossen Buseck',
    'Grossenbrode',
    'Grossenaspe',
    'Grosselfingen',
    'Grosseisenbach',
    'Grosseibstadt',
    'Grossdubrau',
    'Gross Duben',
    'Gross Dratow',
    'Gross Denkte',
    'Grossdeinbach',
    'Grossburgwedel',
    'Gross Buensdorf',
    'Grossbundenbach',
    'Grossbrembach',
    'Grossbreitenbach',
    'Grossbottwar',
    'Gross Boelkow',
    'Grossbodungen',
    'Gross-Bieberau',
    'Grossbettlingen',
    'Gross Berssen',
    'Grossberndten',
    'Grossbeeren',
    'Grossbartloff',
    'Grossbardorf',
    'Gross Ammensleben',
    'Grossalmerode',
    'Grossaitingen',
    'Groepelingen',
    'Groenwohld',
    'Groeningen',
    'Bad Groenenbach',
    'Gronau',
    'Gromitz',
    'Groembach',
    'Grolsheim',
    'Groitzsch',
    'Groeditz',
    'Groden',
    'Groebzig',
    'Grobenzell',
    'Grimoldsried',
    'Grimmen',
    'Grimma',
    'Griesstatt',
    'Griesingen',
    'Griesheim',
    'Griesbach',
    'Grieben',
    'Grevesmuehlen',
    'Grevenbroich',
    'Greven',
    'Greussenheim',
    'Greussen',
    'Grettstadt',
    'Grethem',
    'Gresse',
    'Gresgen',
    'Gresaubach',
    'Greppin',
    'Grenzach-Wyhlen',
    'Gremsdorf',
    'Gremersdorf',
    'Greiz',
    'Greimerath',
    'Greiling',
    'Greifswald',
    'Greifenstein',
    'Grefrath',
    'Greding',
    'Grebin',
    'Grebenstein',
    'Grebenhain',
    'Grebenau',
    'Gravenwiesbach',
    'Grauel',
    'Grattersdorf',
    'Grassel',
    'Grassau',
    'Grasleben',
    'Grasbrunn',
    'Grasberg',
    'Granzin',
    'Gransee',
    'Gransebieth',
    'Gransdorf',
    'Grammentin',
    'Grambow',
    'Grainet',
    'Grainbach',
    'Grainau',
    'Grafrath',
    'Grafling',
    'Grafing bei Munchen',
    'Grafenwohr',
    'Grafenwiesen',
    'Graefentonna',
    'Graefenthal',
    'Graefenroda',
    'Grafenrheinfeld',
    'Grafenhausen',
    'Graefenhainichen',
    'Graefenhain',
    'Grafengehaig',
    'Grafendorf',
    'Grafenberg',
    'Grafenau',
    'Grafenaschau',
    'Grafelfing',
    'Grabowhoefe',
    'Grabow',
    'Grabenstetten',
    'Grabenstatt',
    'Graben',
    'Grabau',
    'Gottmannshofen',
    'Gottmadingen',
    'Göttingen',
    'Gotthun',
    'Gottfrieding',
    'Gotteszell',
    'Gottenheim',
    'Gotha',
    'Goessweinstein',
    'Goessnitz',
    'Gossmannsrod',
    'Gossenheim',
    'Goslar',
    'Gosheim',
    'Goerzig',
    'Gorxheimertal',
    'Goerwihl',
    'Gorsleben',
    'Goerschlitz',
    'Gorsbach',
    'Gornsdorf',
    'Gornau',
    'Gorlosen',
    'Görlitz',
    'Gorleben',
    'Goergeshausen',
    'Göppingen',
    'Goppeln',
    'Goosefeld',
    'Gonzerath',
    'Gonnersdorf',
    'Goennebek',
    'Gondelsheim',
    'Gonbach',
    'Gommern',
    'Gomaringen',
    'Gomadingen',
    'Golzow',
    'Golssen',
    'Golenkamp',
    'Goldkronach',
    'Goldenstedt',
    'Goldenstaedt',
    'Goldelund',
    'Goldebek',
    'Goldberg',
    'Goldbeck',
    'Goldbach',
    'Gokels',
    'Goehren',
    'Goggingen',
    'Godenstorf',
    'Goeddeckenrode',
    'Gocklingen',
    'Gochsheim',
    'Goch',
    'Gnutz',
    'Gnoien',
    'Gneven',
    'Gnarrenburg',
    'Gmund am Tegernsee',
    'Glueckstadt',
    'Glücksburg',
    'Glott',
    'Glonn',
    'Glinstedt',
    'Glindenberg',
    'Glinde',
    'Glienicke',
    'Gleiszellen-Gleishorbach',
    'Gleissenberg',
    'Gleiritsch',
    'Glehn',
    'Glees',
    'Glauchau',
    'Glauburg',
    'Glaubitz',
    'Glatten',
    'Glattbach',
    'Glasin',
    'Glashutten',
    'Glashuette',
    'Glasehausen',
    'Glasau',
    'Glan-Munchweiler',
    'Glandorf',
    'Glaisin',
    'Gladenbach',
    'Gladbeck',
    'Gladbach',
    'Girod',
    'Girkenroth',
    'Ginsweiler',
    'Ginsheim-Gustavsburg',
    'Gingst',
    'Gingen an der Fils',
    'Gindorf',
    'Gimbsheim',
    'Gilzem',
    'Gilten',
    'Gilserberg',
    'Gillersdorf',
    'Gillenfeld',
    'Gilching',
    'Gifhorn',
    'Giessen',
    'Giersleben',
    'Giengen an der Brenz',
    'Gielow',
    'Gieleroth',
    'Giekau',
    'Gieboldehausen',
    'Giebelstadt',
    'Geyer',
    'Geversdorf',
    'Gevenich',
    'Gevelsberg',
    'Gettorf',
    'Gestratz',
    'Gessertshausen',
    'Geseke',
    'Geschwenda',
    'Gescher',
    'Gerzen',
    'Gerwisch',
    'Gertewitz',
    'Gerswalde',
    'Gerstungen',
    'Gersthofen',
    'Gerstetten',
    'Gersten',
    'Gersheim',
    'Gersfeld',
    'Gersdorf',
    'Düsseldorf-Gerresheim',
    'Gerolzhofen',
    'Gerolstein',
    'Gerolsheim',
    'Gerolsbach',
    'Geroldshausen',
    'Geroldsgrun',
    'Gernsheim',
    'Gernsdorf',
    'Gernsbach',
    'Gernrode',
    'Germerswang',
    'Germersheim',
    'Germering',
    'Gerlingen',
    'Geringswalde',
    'Gerichshain',
    'Gerhardshofen',
    'Gerhardsbrunn',
    'Geretsried',
    'Gerdshagen',
    'Gerdau',
    'Gerbstedt',
    'Gerbrunn',
    'Gerbershausen',
    'Gerbach',
    'Geratskirchen',
    'Gerach',
    'Gerabronn',
    'Geraberg',
    'Gera',
    'Georgsmarienhuette',
    'Georgsdorf',
    'Georgenthal',
    'Georgensgmund',
    'Georgenberg',
    'Genthin',
    'Gensingen',
    'Gengenbach',
    'Gemuenden an der Wohra',
    'Gemuenden',
    'Gemünden am Main',
    'Gemunden',
    'Gemmrigheim',
    'Gemmingen',
    'Gemmerich',
    'Geltorf',
    'Gelting',
    'Geltendorf',
    'Gelsenkirchen',
    'Gelnhausen',
    'Gelenau',
    'Geldersheim',
    'Geldern',
    'Geithain',
    'Geismar',
    'Geislingen an der Steige',
    'Geislingen',
    'Geisleden',
    'Geisingen',
    'Geising',
    'Geisfeld',
    'Geisenheim',
    'Geisenhausen',
    'Geisenfeld',
    'Geiselwind',
    'Geiselhoring',
    'Geiselbach',
    'Geisa',
    'Geilsdorf',
    'Geilenkirchen',
    'Geigant',
    'Geiersthal',
    'Gehrweiler',
    'Gehren',
    'Gehrden',
    'Gehrde',
    'Gehofen',
    'Gehlert',
    'Gefrees',
    'Gefell',
    'Geesthacht',
    'Geestgottberg',
    'Geeste',
    'Geesdorf',
    'Gedern',
    'Gechingen',
    'Gebsattel',
    'Gebhardshain',
    'Gebesee',
    'Gebelkofen',
    'Gauting',
    'Gaussig',
    'Gau-Odernheim',
    'Gaukonigshofen',
    'Gau-Bischofsheim',
    'Gau-Bickelheim',
    'Gau-Algesheim',
    'Gatterstaedt',
    'Gatersleben',
    'Garz',
    'Gartringen',
    'Gartow',
    'Garstedt',
    'Gars',
    'Garrel',
    'Garmisch-Partenkirchen',
    'Garlstorf',
    'Garlipp',
    'Garding',
    'Gardelegen',
    'Garching bei Munchen',
    'Garching an der Alz',
    'Garbsen',
    'Garbenteich',
    'Ganzlin',
    'Ganzkow',
    'Ganzig',
    'Ganspe',
    'Gansheim',
    'Gangkofen',
    'Gangelt',
    'Gandesbergen',
    'Ganderkesee',
    'Gamstaedt',
    'Gammertingen',
    'Gammelshausen',
    'Gammelsdorf',
    'Gammelin',
    'Gammelby',
    'Gamlen',
    'Gallun',
    'Gallin',
    'Gallentin',
    'Gallenbach',
    'Galenbeck',
    'Gaissach',
    'Gaimersheim',
    'Gailingen',
    'Gaildorf',
    'Gaiganz',
    'Gaienhofen',
    'Gaiberg',
    'Gahro',
    'Gaggenau',
    'Gaegelow',
    'Gadebusch',
    'Gackenbach',
    'Gachenbach',
    'Gablingen',
    'Bad Fussing',
    'Füssen',
    'Furtwangen im Schwarzwald',
    'Furth im Wald',
    'Furthen',
    'Furth',
    'Fürth',
    'Furstenzell',
    'Fuerstenwalde',
    'Furstenstein',
    'Fursteneck',
    'Fuerstenberg',
    'Furstenberg',
    'Fuerstenau',
    'Fuenfstetten',
    'Fultenbach',
    'Fuldatal',
    'Fuldabruck',
    'Fulda',
    'Fuhlendorf',
    'Fuchsstadt',
    'Fuchsmuhl',
    'Frucht',
    'Frontenhausen',
    'Fronhausen',
    'Froendenberg',
    'Froemmstedt',
    'Frohnstetten',
    'Frohnhofen',
    'Frohburg',
    'Fritzlar',
    'Frittlingen',
    'Friolzheim',
    'Friesoythe',
    'Friesenried',
    'Friesenheim',
    'Friesenhagen',
    'Friesack',
    'Friemar',
    'Frielendorf',
    'Friedrichswerth',
    'Friedrichswalde',
    'Friedrichsthal',
    'Friedrichstadt',
    'Friedrichsruhe',
    'Friedrichskoog',
    'Friedrichshafen',
    'Friedrichsgraben',
    'Friedrichsdorf',
    'Friedrichsaue',
    'Friedrichroda',
    'Friedland',
    'Friedewald',
    'Friedenweiler',
    'Friedenfels',
    'Friedeburg',
    'Friedberg',
    'Fridolfing',
    'Fridingen an der Donau',
    'Frickingen',
    'Frickhofen',
    'Frickenhausen',
    'Freyung',
    'Freystadt',
    'Freyburg',
    'Freudental',
    'Freudenstadt',
    'Freudenburg',
    'Freudenberg',
    'Fresenburg',
    'Freren',
    'Frensdorf',
    'Fremdingen',
    'Frellstedt',
    'Freital',
    'Freising',
    'Freisen',
    'Freisbach',
    'Freinsheim',
    'Freimersheim',
    'Freilingen',
    'Freilassing',
    'Freihung',
    'Freienwill',
    'Freiensteinau',
    'Freiburg',
    'Freiberg am Neckar',
    'Freiberg',
    'Freiamt',
    'Fredersdorf',
    'Freden',
    'Fredelsloh',
    'Freckenfeld',
    'Frechen',
    'Fraureuth',
    'Frauenzell',
    'Frauenwald',
    'Frauenstein',
    'Frauendorf',
    'Frauenau',
    'Frasdorf',
    'Frankweiler',
    'Frankisch-Crumbach',
    'Frankfurt am Main',
    'Frankfurt (Oder)',
    'Frankershausen',
    'Frankenthal',
    'Frankenstein',
    'Frankenheim',
    'Frankenhain',
    'Frankeneck',
    'Frankenberg',
    'Frankenau',
    'Frammersbach',
    'Framersheim',
    'Foertha',
    'Forstmehren',
    'Forstinning',
    'Forstern',
    'Forst',
    'Forchtenberg',
    'Forchheim',
    'Forbach',
    'Fockelberg',
    'Fockbek',
    'Fluterschen',
    'Flussbach',
    'Fluorn',
    'Flossenburg',
    'Floersheim',
    'Flonheim',
    'Flomborn',
    'Floeha',
    'Flitzing',
    'Flintsbach',
    'Flintbek',
    'Flieden',
    'Fleringen',
    'Flensburg',
    'Flemlingen',
    'Fleischwangen',
    'Flein',
    'Flecken Zechlin',
    'Fleckeby',
    'Flechtingen',
    'Flarchheim',
    'Flammersfeld',
    'Fladungen',
    'Flacht',
    'Flachslanden',
    'Fitzen',
    'Fitzbek',
    'Fischingen',
    'Fischerbach',
    'Fischen',
    'Fischelbach',
    'Fischbachtal',
    'Fischbachau',
    'Fischbach',
    'Fischach',
    'Fisch',
    'Fintel',
    'Finsterwalde',
    'Finsing',
    'Finnentrop',
    'Finkenthal',
    'Finkenbach-Gersweiler',
    'Filsum',
    'Fiersbach',
    'Fichtenwalde',
    'Fichtenberg',
    'Fichtelberg',
    'Feusdorf',
    'Feuerscheid',
    'Feuchtwangen',
    'Feucht',
    'Ferna',
    'Ferdinandshof',
    'Ferchland',
    'Felsberg',
    'Felm',
    'Fellheim',
    'Fellen',
    'Fellbach',
    'Fell',
    'Feldstetten',
    'Feldkirchen-Westerham',
    'Feldkirchen',
    'Felde',
    'Feldberg',
    'Feldafing',
    'Feld',
    'Feilitzsch',
    'Feilbingert',
    'Feichten',
    'Fehrbellin',
    'Fehl-Ritzhausen',
    'Fehlheim',
    'Fedderingen',
    'Faulenrost',
    'Faulbach',
    'Fassberg',
    'Farven',
    'Farschweiler',
    'Farnstadt',
    'Farchant',
    'Fambach',
    'Bad Fallingbostel',
    'Fallersleben',
    'Falkenstein',
    'Falkensee',
    'Falkenberg',
    'Falkenbach',
    'Fahrenzhausen',
    'Fahrenwalde',
    'Fahrenholz',
    'Fahrendorf',
    'Fahrenbach',
    'Fahrdorf',
    'Fahlhorst',
    'Fahlenbach',
    'Eystrup',
    'Eydelstedt',
    'Evessen',
    'Everswinkel',
    'Eversmeer',
    'Everode',
    'Eutingen',
    'Eutin',
    'Eusserthal',
    'Eussenheim',
    'Euskirchen',
    'Eurasburg',
    'Eulenbis',
    'Euerwang',
    'Euerdorf',
    'Euerbach',
    'Eudenbach',
    'Eubigheim',
    'Etzenricht',
    'Etzelwang',
    'Etzelsrode',
    'Etzbach',
    'Ettringen',
    'Ettlingen',
    'Ettinghausen',
    'Ettenstatt',
    'Ettenheim',
    'Etteldorf',
    'Ettal',
    'Etschberg',
    'Etingen',
    'Estorf',
    'Esthal',
    'Esterwegen',
    'Estenfeld',
    'Esslingen am Neckar',
    'Essingen',
    'Essing',
    'Essenheim',
    'Essenbach',
    'Essen',
    'Essel',
    'Espenhain',
    'Espenau',
    'Espelkamp',
    'Eslohe',
    'Eslarn',
    'Esens',
    'Eschweiler',
    'Eschwege',
    'Eschlkam',
    'Eschershausen',
    'Eschental',
    'Eschenrode',
    'Eschenlohe',
    'Eschenbach in der Oberpfalz',
    'Eschenbach',
    'Eschenau',
    'Eschelbronn',
    'Eschefeld',
    'Eschede',
    'Escheburg',
    'Eschborn',
    'Eschbach',
    'Eschau',
    'Esch',
    'Erzhausen',
    'Erxleben',
    'Erwitte',
    'Ertingen',
    'Erolzheim',
    'Ernsgaden',
    'Erndtebrueck',
    'Ermsleben',
    'Ermke',
    'Ermershausen',
    'Erligheim',
    'Erlensee',
    'Erlenmoos',
    'Erlenbach bei Marktheidenfeld',
    'Erlenbach am Main',
    'Erlenbach',
    'Erlau',
    'Erlangen',
    'Erlach',
    'Erkrath',
    'Erkner',
    'Erkheim',
    'Erkenbrechtsweiler',
    'Erkelenz',
    'Eriskirch',
    'Erisdorf',
    'Ering',
    'Erharting',
    'Ergoldsbach',
    'Ergolding',
    'Ergenzingen',
    'Erfweiler',
    'Erfurt',
    'Erftstadt',
    'Erfenbach',
    'Erfde',
    'Eresing',
    'Erdweg',
    'Erdmannsweiler',
    'Erdmannhausen',
    'Erding',
    'Erbshausen',
    'Erbes-Budesheim',
    'Erbenhausen',
    'Erbendorf',
    'Erbach',
    'Erbach im Odenwald',
    'Eppstein',
    'Eppishausen',
    'Eppingen',
    'Eppertshausen',
    'Eppenschlag',
    'Eppenrod',
    'Eppendorf',
    'Eppenbrunn',
    'Eppenberg',
    'Eppelsheim',
    'Eppelheim',
    'Eppelborn',
    'Epfendorf',
    'Epfenbach',
    'Enzkloesterle',
    'Ammerbuch',
    'Entraching',
    'Enslingen',
    'Ense',
    'Ensdorf',
    'Ensch',
    'Ennigerloh',
    'Ennepetal',
    'Enkirch',
    'Enkenbach-Alsenborn',
    'Eningen unter Achalm',
    'Sankt Englmar',
    'Enger',
    'Engen',
    'Engelthal',
    'Engelskirchen',
    'Engelsbrand',
    'Engelsberg',
    'Engden',
    'Bad Endorf',
    'Endorf',
    'Endingen',
    'Bad Endbach',
    'Emtmannsberg',
    'Emstek',
    'Emskirchen',
    'Emsdetten',
    'Emsburen',
    'Empfingen',
    'Emmerthal',
    'Emmering',
    'Emmerichenhain',
    'Emmerich',
    'Emmendorf',
    'Emmendingen',
    'Emmelshausen',
    'Emlichheim',
    'Emleben',
    'Emkendorf',
    'Emersacker',
    'Emden',
    'Embuhren',
    'Embsen',
    'Elztal',
    'Elze',
    'Elzach',
    'Elz',
    'Elxleben',
    'Eltville',
    'Eltmann',
    'Elterlein',
    'Elstra',
    'Elsterwerda',
    'Elsterberg',
    'Elsnig',
    'Elsfleth',
    'Elsenfeld',
    'Elsendorf',
    'Elsdorf-Westermuhlen',
    'Elsdorf',
    'Elpersheim',
    'Elmstein',
    'Elmshorn',
    'Elmenhorst',
    'Ellzee',
    'Ellwangen',
    'Ellrich',
    'Ellingstedt',
    'Ellingen',
    'Ellhofen',
    'Ellerstadt',
    'Ellern',
    'Ellerhoop',
    'Ellerbek',
    'Ellerau',
    'Ellenstedt',
    'Ellenhausen',
    'Ellenberg',
    'Ellefeld',
    'Elleben',
    'Elkenroth',
    'Elgershausen',
    'Elgersburg',
    'Elend',
    'Eldingen',
    'Eldena',
    'Elchesheim',
    'Elbstorf',
    'Eitzweiler',
    'Eitting',
    'Eitorf',
    'Eiterfeld',
    'Eitensheim',
    'Eitelborn',
    'Eislingen',
    'Eisingen',
    'Eisfeld',
    'Eisenschmitt',
    'Eisenberg',
    'Eisenach',
    'Einwinkel',
    'Einhausen',
    'Markt Einersheim',
    'Eineborn',
    'Einbeck',
    'Eimke',
    'Eimen',
    'Eimeldingen',
    'Eime',
    'Eilsleben',
    'Eilenstedt',
    'Eilenburg',
    'Eil',
    'Eigeltingen',
    'Eicklingen',
    'Eickendorf',
    'Eichwege',
    'Eichwalde',
    'Eichstetten',
    'Eichstaett',
    'Eichigt',
    'Eichenzell',
    'Eichenried',
    'Eichendorf',
    'Eichenbuhl',
    'Eichenberg',
    'Eichenbarleben',
    'Eichenau',
    'Eich',
    'Eibenstock',
    'Eibelstadt',
    'Ehringshausen',
    'Ehring',
    'Ehrenfriedersdorf',
    'Ehrenfeld',
    'Ehra',
    'Ehningen',
    'Ehndorf',
    'Ehlscheid',
    'Ehingen',
    'Ehekirchen',
    'Egweil',
    'Egmating',
    'Egloffstein',
    'Egling',
    'Eging on Sea',
    'Eggstatt',
    'Eggolsheim',
    'Egglkofen',
    'Egglham',
    'Eggesin',
    'Eggersdorf',
    'Eggenthal',
    'Eggenstein-Leopoldshafen',
    'Eggenfelden',
    'Eggebek',
    'Egg an der Gunz',
    'Egestorf',
    'Egenhausen',
    'Egelsbach',
    'Egeln',
    'Efringen-Kirchen',
    'Effeltrich',
    'Effelder',
    'Edling',
    'Edingen-Neckarhausen',
    'Edewecht',
    'Edesheim',
    'Ederheim',
    'Edenkoben',
    'Edemissen',
    'Edelweiler',
    'Edelsfeld',
    'Eddelak',
    'Eckstedt',
    'Ecklak',
    'Eckersdorf',
    'Eckernfoerde',
    'Eckartsberga',
    'Echzell',
    'Echterdingen',
    'Echte',
    'Eching',
    'Echem',
    'Ebstorf',
    'Ebringen',
    'Ebrach',
    'Ebnath',
    'Ebingen',
    'Ebhausen',
    'Ebertsheim',
    'Eberswalde',
    'Eberstedt',
    'Eberstadt',
    'Ebersdorf bei Coburg',
    'Ebersdorf',
    'Ebersberg',
    'Ebersbach an der Fils',
    'Ebersbach',
    'Ebernhahn',
    'Ebern',
    'Ebermannstadt',
    'Ebermannsdorf',
    'Eberhardzell',
    'Eberdingen',
    'Eberbach',
    'Ebenweiler',
    'Ebenshausen',
    'Ebensfeld',
    'Ebenhofen',
    'Ebenhausen',
    'Ebendorf',
    'Ebelsbach',
    'Ebeleben',
    'Dusslingen',
    'Duessin',
    'Düsseldorf',
    'Duschlberg',
    'Durrwangen',
    'Duerrroehrsdorf',
    'Durnhart',
    'Duerrholz',
    'Duernau',
    'Durmersheim',
    'Durmentingen',
    'Durlangen',
    'Düren',
    'Durchhausen',
    'Durbheim',
    'Durbach',
    'Durach',
    'Duppach',
    'Dunum',
    'Dunstelkingen',
    'Dunningen',
    'Duengenheim',
    'Dummerstorf',
    'Duemmer',
    'Duelmen',
    'Duelken',
    'Duisburg',
    'Duingen',
    'Duggendorf',
    'Dudweiler',
    'Duderstadt',
    'Dudenhofen',
    'Duedenbuettel',
    'Dudeldorf',
    'Duchroth',
    'Ducherow',
    'Dubrow',
    'Druebeck',
    'Droyssig',
    'Drossdorf',
    'Drolshagen',
    'Drognitz',
    'Drochtersen',
    'Droebischau',
    'Driftsethe',
    'Driedorf',
    'Drestedt',
    'Dreska',
    'Dresden',
    'Dreschvitz',
    'Drentwede',
    'Drensteinfurt',
    'Drelsdorf',
    'Dreitzsch',
    'Dreisen',
    'Dreisbach',
    'Dreis',
    'Dreiluetzow',
    'Dreilingen',
    'Dreihausen',
    'Dreieich',
    'Dreetz',
    'Drebkau',
    'Drebach',
    'Drantum',
    'Dranske',
    'Dransfeld',
    'Drakenburg',
    'Drahnsdorf',
    'Dragun',
    'Drage',
    'Drachselsried',
    'Drachhausen',
    'Dotlingen',
    'Dossenheim',
    'Dosingen',
    'Dorzbach',
    'Dorverden',
    'Dorum',
    'Dortmund',
    'Dortel',
    'Dorsten',
    'Dorst',
    'Dorrmoschel',
    'Doerpum',
    'Dorpstedt',
    'Doerpling',
    'Dorpen',
    'Dornum',
    'Dornstetten',
    'Dornstadt',
    'Dornheim',
    'Dornhan',
    'Dorndorf',
    'Dormagen',
    'Dorfprozelten',
    'Dorfen',
    'Dorfchemnitz',
    'Dorentrup',
    'Donzdorf',
    'Donsieders',
    'Donnstetten',
    'Donnersdorf',
    'Donndorf',
    'Donauwörth',
    'Donaustauf',
    'Donaueschingen',
    'Domsdorf',
    'Dommitzsch',
    'Dommershausen',
    'Dombuhl',
    'Doelzig',
    'Dollnstein',
    'Dollern',
    'Dollbergen',
    'Doehren',
    'Dohr',
    'Dohnsen',
    'Dohna',
    'Dohma',
    'Dohlau',
    'Dogern',
    'Doffingen',
    'Dodow',
    'Dodenau',
    'Dockweiler',
    'Doberschutz',
    'Doebern',
    'Doberlug-Kirchhain',
    'Doebeln',
    'Dobel',
    'Ditzingen',
    'Dittweiler',
    'Dittlingen',
    'Dittenheim',
    'Dittelsdorf',
    'Dittelbrunn',
    'Ditfurt',
    'Dissen',
    'Dischingen',
    'Dirmstein',
    'Dirlewang',
    'Dippoldiswalde',
    'Dipperz',
    'Dippach',
    'Dipbach',
    'Dinslaken',
    'Dinklage',
    'Dinkelscherben',
    'Dinkelsbühl',
    'Dingolshausen',
    'Dingolfing',
    'Dingelstaedt',
    'Dingelbe',
    'Dillstadt',
    'Dillingen an der Donau',
    'Dillingen',
    'Dillendorf',
    'Dillenburg',
    'Diez',
    'Dietzhausen',
    'Dietzenbach',
    'Dietramszell',
    'Dietmannsried',
    'Dietingen',
    'Dietfurt',
    'Dietersheim',
    'Dietersburg',
    'Dietenhofen',
    'Dietenheim',
    'Diessen am Ammersee',
    'Diespeck',
    'Diesdorf',
    'Diersburg',
    'Dierkshausen',
    'Dierdorf',
    'Diepoltskirchen',
    'Diepholz',
    'Diepenau',
    'Diensdorf-Radlow',
    'Dienheim',
    'Dienethal',
    'Dielmissen',
    'Dielkirchen',
    'Dielingen',
    'Dielheim',
    'Diekholzen',
    'Diekhof',
    'Diedorf',
    'Dieburg',
    'Dieblich',
    'Didderse',
    'Dickenschied',
    'Deyelsdorf',
    'Dexheim',
    'Deutzen',
    'Deutschneudorf',
    'Deutsch Evern',
    'Deuna',
    'Deuerling',
    'Deuben',
    'Dettum',
    'Dettmannsdorf',
    'Dettingen unter Teck',
    'Dettingen an der Iller',
    'Dettingen an der Erms',
    'Dettenhausen',
    'Dettelbach',
    'Detmold',
    'Detern',
    'Destedt',
    'Dessau',
    'Desloch',
    'Derschen',
    'Dernbach',
    'Dernau',
    'Dermsdorf',
    'Dermbach',
    'Dergenthin',
    'Derenburg',
    'Derben',
    'Denzlingen',
    'Dentlein am Forst',
    'Densborn',
    'Dennheritz',
    'Denklingen',
    'Denkingen',
    'Denkendorf',
    'Demmin',
    'Demitz-Thumitz',
    'Demerthin',
    'Demen',
    'Delmenhorst',
    'Dellmensingen',
    'Delligsen',
    'Dellfeld',
    'Delitzsch',
    'Delingsdorf',
    'Delbrueck',
    'Deizisau',
    'Deisslingen',
    'Deisenhofen',
    'Deisenhausen',
    'Deinstedt',
    'Deinste',
    'Deiningen',
    'Deining',
    'Deilinghofen',
    'Deilingen',
    'Deidesheim',
    'Deggingen',
    'Deggendorf',
    'Deesen',
    'Deensen',
    'Dedenbach',
    'Dedeleben',
    'Deckenpfronn',
    'Dautphe',
    'Dautmergen',
    'Daun',
    'Dauchingen',
    'Daubringen',
    'Dattenberg',
    'Datteln',
    'Datgen',
    'Dassow',
    'Dassendorf',
    'Dassel',
    'Dasing',
    'Darshofen',
    'Darscheid',
    'Darnstedt',
    'Darmstadt',
    'Darlingerode',
    'Dargun',
    'Danstedt',
    'Dannigkow',
    'Dannewerk',
    'Dannenfels',
    'Dannenberg',
    'Danndorf',
    'Dannau',
    'Dankmarshausen',
    'Daenischenhagen',
    'Damscheid',
    'Damme',
    'Damflos',
    'Damendorf',
    'Daemelow',
    'Dalldorf',
    'Daleiden',
    'Dalberg',
    'Daisendorf',
    'Dahn',
    'Dahme',
    'Dahlwitz-Hoppegarten',
    'Dahlheim',
    'Dahlewitz',
    'Dahlenburg',
    'Dahlen',
    'Dahlem',
    'Dageling',
    'Dachwig',
    'Dachsenhausen',
    'Dachsbach',
    'Dachau',
    'Dabendorf',
    'Dabel',
    'Daaden',
    'Cuxhaven',
    'Cunewalde',
    'Cumlosen',
    'Crussow',
    'Crottendorf',
    'Crostwitz',
    'Crostau',
    'Cronhutte',
    'Crivitz',
    'Crinitz',
    'Crimmitschau',
    'Crimla',
    'Creuzburg',
    'Creussen',
    'Cremlingen',
    'Creglingen',
    'Crawinkel',
    'Cramonshagen',
    'Crailsheim',
    'Cottbus',
    'Coswig',
    'Cornberg',
    'Coppenbruegge',
    'Contwig',
    'Connewitz',
    'Colnrade',
    'Colmberg',
    'Coelln',
    'Colditz',
    'Colbitz',
    'Coelbe',
    'Coesfeld',
    'Cochem',
    'Coburg',
    'Cloppenburg',
    'Climbach',
    'Clenze',
    'Cleebronn',
    'Clausthal-Zellerfeld',
    'Clausthal',
    'Claussnitz',
    'Clausen',
    'Christiansholm',
    'Christes',
    'Chorin',
    'Chieming',
    'Chemnitz',
    'Chamerau',
    'Cham',
    'Celle',
    'Ceesewitz',
    'Castrop-Rauxel',
    'Casekow',
    'Carpin',
    'Carlsberg',
    'Caputh',
    'Cappeln',
    'Campe',
    'Cammin',
    'Camburg',
    'Cambs',
    'Bad Camberg',
    'Calw',
    'Calvorde',
    'Calmbach',
    'Callenberg',
    'Caldern',
    'Calden',
    'Calberlah',
    'Calbe',
    'Calau',
    'Cadolzburg',
    'Cadenberge',
    'Caaschwitz',
    'Buxtehude',
    'Buxheim',
    'Buetzow',
    'Butzow',
    'Butzbach',
    'Buettstedt',
    'Buttstaedt',
    'Buttlar',
    'Butthard',
    'Buttenwiesen',
    'Buttenheim',
    'Buttelstedt',
    'Buttelborn',
    'Buetow',
    'Busum',
    'Bussmannshausen',
    'Busenberg',
    'Buschvitz',
    'Burtenbach',
    'Buerstadt',
    'Burscheid',
    'Burrweiler',
    'Burladingen',
    'Burkhardtsdorf',
    'Burkhardtroda',
    'Burkersdorf',
    'Burkau',
    'Burkardroth',
    'Burgwindheim',
    'Burgtonna',
    'Burgthann',
    'Burg Stargard',
    'Burgstall',
    'Burgstaedt',
    'Burgstadt',
    'Burgsolms',
    'Burgsinn',
    'Burgschwalbach',
    'Burgrieden',
    'Burgpreppach',
    'Burgoberbach',
    'Burgmannshofen',
    'Burglengenfeld',
    'Burglauer',
    'Burglahr',
    'Burgkunstadt',
    'Burgkirchen an der Alz',
    'Burgkemnitz',
    'Burgheim',
    'Burghausen',
    'Burghaun',
    'Burghaslach',
    'Burghagel',
    'Burggriesbach',
    'Burggen',
    'Buergel',
    'Burgebrach',
    'Burgdorf, Hanover',
    'Burgdorf',
    'Burgbrohl',
    'Burgbernheim',
    'Burgberg',
    'Burgau',
    'Burg',
    'Burg bei Magdeburg',
    'Bueren',
    'Burbach',
    'Bunsoh',
    'Bunsdorf',
    'Bundorf',
    'Bundenbach',
    'Bunde',
    'Buende',
    'Bulstringen',
    'Bullenkuhlen',
    'Bullay',
    'Buehnsdorf',
    'Buehne',
    'Buhlerzell',
    'Buhlertann',
    'Buhlertal',
    'Buhl',
    'Buggingen',
    'Buedesheim',
    'Budenheim',
    'Buedelsdorf',
    'Buckow',
    'Buckenhof',
    'Buecken',
    'Buckeburg',
    'Buchloe',
    'Buching',
    'Buchholz in der Nordheide',
    'Buchholz',
    'Buchhofen',
    'Buchfart',
    'Buchet',
    'Buchenbach',
    'Buchen',
    'Buchen in Odenwald',
    'Buchdorf',
    'Buchbrunn',
    'Buchbach',
    'Buch am Wald',
    'Buch am Buchrain',
    'Bucha',
    'Buch',
    'Bubsheim',
    'Bubesheim',
    'Bubenreuth',
    'Bruesewitz',
    'Bruschied',
    'Bruenzow',
    'Brunsmark',
    'Brunsbuettel',
    'Brunnthal',
    'Brunnen',
    'Brunn',
    'Bruenn',
    'Brunkau',
    'Bruhl',
    'Bruggen',
    'Bruegge',
    'Brueel',
    'Bruckmuhl',
    'Bruck in der Oberpfalz',
    'Brucken',
    'Bruckberg',
    'Brueck',
    'Bruck',
    'Bruchweiler-Barenbach',
    'Bruchweiler',
    'Bruchsal',
    'Bruchkoebel',
    'Bruchhausen-Vilsen',
    'Brothen',
    'Bronn',
    'Bromskirchen',
    'Brome',
    'Brombach',
    'Brokstedt',
    'Brokdorf',
    'Brohl',
    'Broderstorf',
    'Brodersby',
    'Brodenbach',
    'Brockum',
    'Brockscheid',
    'Broebberow',
    'Britz',
    'Brinkum',
    'Brilon',
    'Brieskow-Finkenheerd',
    'Brieske',
    'Brieselang',
    'Briedern',
    'Briedel',
    'Brickeln',
    'Brey',
    'Breuna',
    'Breuberg',
    'Bretzfeld',
    'Bretzenheim',
    'Bretten',
    'Brensbach',
    'Brennberg',
    'Bremthal',
    'Bremm',
    'Bremervoerde',
    'Bremerhaven',
    'Bremen',
    'Breklum',
    'Brekendorf',
    'Breitungen',
    'Breitscheid',
    'Breitnau',
    'Breitingen',
    'Breitenworbis',
    'Breitenthal',
    'Breitengussbach',
    'Breitenfelde',
    'Breitenburg',
    'Breitenbrunn',
    'Breitenbenden',
    'Breitenbach am Herzberg',
    'Breitenbach',
    'Breitenau',
    'Breitbrunn am Chiemsee',
    'Breit',
    'Breisach',
    'Breiholz',
    'Breidenbach',
    'Brehna',
    'Brehme',
    'Bregenstedt',
    'Breese',
    'Bredstedt',
    'Bredenbek',
    'Bredenbeck',
    'Bredehorn',
    'Breddorf',
    'Breddin',
    'Breckerfeld',
    'Brebel',
    'Braunshorn',
    'Braunschweig',
    'Braunsbedra',
    'Braunsbach',
    'Braunlingen',
    'Braunlage',
    'Braunichswalde',
    'Braunfels',
    'Brauneberg',
    'Braubach',
    'Brannenburg',
    'Brandshagen',
    'Brandscheid',
    'Brandis',
    'Brand-Erbisdorf',
    'Brandenburg',
    'Brande-Hornerkirchen',
    'Brand',
    'Bramsche',
    'Brammer',
    'Brakel',
    'Brake (Unterweser)',
    'Braderup',
    'Brackenheim',
    'Brackel',
    'Brachwitz',
    'Bracht',
    'Brachstedt',
    'Brachbach',
    'Braak',
    'Boxberg',
    'Bovenden',
    'Bous',
    'Botzingen',
    'Bottrop',
    'Bottingen',
    'Bottendorf',
    'Bottenbach',
    'Bothel',
    'Botersen',
    'Bosingen',
    'Bosenbrunn',
    'Bosenbach',
    'Bosel',
    'Bosbull',
    'Bosau',
    'Borxleben',
    'Bortfeld',
    'Borstorf',
    'Borstel',
    'Boerssum',
    'Borsfleth',
    'Borsdorf',
    'Boerrstadt',
    'Borrentin',
    'Borod',
    'Bornstedt',
    'Boernsen',
    'Bornhoved',
    'Bornheim',
    'Borna',
    'Born',
    'Borm',
    'Borkum',
    'Borkow',
    'Borkheide',
    'Borken',
    'Borgholzhausen',
    'Borger',
    'Borgentreich',
    'Bordesholm',
    'Boppard',
    'Bopfingen',
    'Boostedt',
    'Boos',
    'Bonsweiher',
    'Bonstetten',
    'Bonningstedt',
    'Bonnigheim',
    'Bonndorf',
    'Bonn',
    'Bongard',
    'Bonen',
    'Bonefeld',
    'Bonebuttel',
    'Bondorf',
    'Boms',
    'Bomlitz',
    'Bolsterlang',
    'Bolsberg',
    'Bollstedt',
    'Bollschweil Priory',
    'Bollingstedt',
    'Bollewick',
    'Bollendorf',
    'Bolanden',
    'Boklund',
    'Bokholt-Hanredder',
    'Bokensdorf',
    'Bokel',
    'Boizenburg',
    'Boitze',
    'Bohmte',
    'Bohmenkirch',
    'Bohme',
    'Boehlen',
    'Bogen',
    'Boffzen',
    'Boecke',
    'Bodolz',
    'Bodnegg',
    'Bodenwerder',
    'Bodenteich',
    'Bodenstein',
    'Bodensee',
    'Bodenrode',
    'Bodenmais',
    'Bodenkirchen',
    'Bodenheim',
    'Bodenfelde',
    'Boden',
    'Bodelwitz',
    'Bodelshausen',
    'Boddin',
    'Boeddensell',
    'Boeckweiler',
    'Bockhorst',
    'Bockhorn',
    'Bockenheim',
    'Bockenem',
    'Bockenau',
    'Bockau',
    'Bochum',
    'Bocholt',
    'Bobrach',
    'Böblingen',
    'Boblas',
    'Bobitz',
    'Bobingen',
    'Bobenheim-Roxheim',
    'Blumenthal',
    'Blumenholz',
    'Blumberg',
    'Blowatz',
    'Blomberg',
    'Blitzenreute',
    'Blindheim',
    'Bliestorf',
    'Bliesransbach',
    'Blieskastel',
    'Bliesdorf',
    'Bliedersdorf',
    'Blender',
    'Blekendorf',
    'Bleicherode',
    'Bleibach',
    'Bleialf',
    'Bleckhausen',
    'Bleckede',
    'Blaufelden',
    'Blaubeuren Abbey',
    'Blaubach',
    'Blankenstein',
    'Blankensee',
    'Blankenloch',
    'Blankenheim',
    'Blankenhain',
    'Blankenhagen',
    'Blankenfelde',
    'Blankenburg',
    'Blandikow',
    'Blaichach',
    'Blaibach',
    'Bitz',
    'Bitterfeld-Wolfen',
    'Bitburg',
    'Bissingen an der Teck',
    'Bissingen',
    'Bissersheim',
    'Bissendorf',
    'Bispingen',
    'Bismark',
    'Bisingen',
    'Bisdorf',
    'Bischweier',
    'Bischofswiesen',
    'Bischofswerda',
    'Bischofsmais',
    'Bischofsheim an der Rhon',
    'Bischofsheim',
    'Bischofferode',
    'Bischoffen',
    'Bischhausen',
    'Bischbrunn',
    'Bischberg',
    'Birtlingen',
    'Birstein',
    'Birresborn',
    'Birnfeld',
    'Birnbach',
    'Bad Birnbach',
    'Birlenbach',
    'Birkweiler',
    'Birkenwerder',
    'Birkenhuegel',
    'Birkenheide',
    'Birkenhain',
    'Birkenfeld',
    'Birkenau',
    'Birgel',
    'Birenbach',
    'Bippen',
    'Binzwangen',
    'Binzen',
    'Binswangen',
    'Binsfeld',
    'Binsdorf',
    'Binnen',
    'Bingen am Rhein',
    'Bingen',
    'Bindlach',
    'Binau',
    'Bilshausen',
    'Bilsen',
    'Billigheim',
    'Billerbeck',
    'Billafingen',
    'Bilfingen',
    'Bildstock',
    'Bietigheim-Bissingen',
    'Bietigheim',
    'Biessenhofen',
    'Biesenthal',
    'Bierstetten',
    'Biere',
    'Bienstadt',
    'Bienenbuttel',
    'Bielefeld',
    'Biederitz',
    'Biedenkopf',
    'Biebesheim',
    'Biebern',
    'Bieberehren',
    'Biebelried',
    'Bidingen',
    'Bickenriede',
    'Bickenbach',
    'Biburg',
    'Bibow',
    'Biblis',
    'Biberbach',
    'Biberach an der Riss',
    'Biberach',
    'Bexbach',
    'Beverungen',
    'Beverstedt',
    'Bevern',
    'Bad Bevensen',
    'Beutelsbach',
    'Beuron',
    'Beuren',
    'Beucha',
    'Betzigau',
    'Betzenstein',
    'Betzendorf',
    'Betzdorf',
    'Bettringen',
    'Bettingen',
    'Bettenhausen',
    'Bestwig',
    'Bestensee',
    'Besigheim',
    'Bescheid',
    'Besandten',
    'Berzhausen',
    'Berumbur',
    'Bersenbrueck',
    'Berschweiler',
    'Berod bei Wallmerod',
    'Bernstadt',
    'Bernsdorf',
    'Bernsbach',
    'Bernried',
    'Bernkastel-Kues',
    'Bernitt',
    'Bernhardswald',
    'Berngau',
    'Berndroth',
    'Berndorf',
    'Bernburg',
    'Bernbeuren',
    'Bernau am Chiemsee',
    'Bernau bei Berlin',
    'Bermersheim',
    'Bermersbach',
    'Bermatingen',
    'Berlstedt',
    'Berlingerode',
    'Berlingen',
    'Berlin',
    'Berlichingen',
    'Berkheim',
    'Berkenthin',
    'Berkenbruck',
    'Berka',
    'Beringstedt',
    'Bergtheim',
    'Bergrheinfeld',
    'Bergneustadt',
    'Berglern',
    'Berglangenbach',
    'Bergkirchen',
    'Bergkamen',
    'Bergisch Gladbach',
    'Berg im Gau',
    'Berghulen',
    'Bergholz',
    'Bergheim',
    'Berghausen',
    'Berghaupten',
    'Berggiesshuebel',
    'Bergfeld',
    'Bergen auf Ruegen',
    'Bergen an der Dumme',
    'Bergen',
    'Berge',
    'Bergatreute',
    'Berga',
    'Berg',
    'Berenbostel',
    'Berchtesgaden',
    'Berching',
    'Beratzhausen',
    'Bentwisch',
    'Bensheim',
    'Benshausen',
    'Benningen am Neckar',
    'Bennewitz',
    'Benneckenstein',
    'Benndorf',
    'Benediktbeuern',
    'Bendorf',
    'Bendestorf',
    'Bempflingen',
    'Bad Belzig',
    'Beltheim',
    'Belsch',
    'Belm',
    'Bellstedt',
    'Bellin',
    'Bellheim',
    'Bellenberg',
    'Bell',
    'Belgershain',
    'Belgern',
    'Beldorf',
    'Belau',
    'Bekond',
    'Beindersheim',
    'Beimerstetten',
    'Beilstein',
    'Beilrode',
    'Beilngries',
    'Beilingen',
    'Beiersdorf',
    'Beierfeld',
    'Beienheim',
    'Beidenfleth',
    'Behrensdorf',
    'Behrenhoff',
    'Behrendorf',
    'Behnsdorf',
    'Behlendorf',
    'Behla',
    'Beggerow',
    'Beetzendorf',
    'Beesten',
    'Beeskow',
    'Beerfelden',
    'Beelitz',
    'Beelen',
    'Beedenbostel',
    'Bedesbach',
    'Bederkesa',
    'Bedburg',
    'Beckum',
    'Beckstetten',
    'Beckingen',
    'Beckeln',
    'Beckedorf',
    'Beckdorf',
    'Bechtolsheim',
    'Bechtheim',
    'Bechstedtstrass',
    'Bechhofen',
    'Becherbach',
    'Bebra',
    'Beberstedt',
    'Bayrischzell',
    'Bayreuth',
    'Bayersoien',
    'Bayern',
    'Bayerisch Gmain',
    'Bayerisch Eisenstein',
    'Bayerbach',
    'Bawinkel',
    'Bautzen',
    'Baustert',
    'Bauschlott',
    'Bauschheim',
    'Baunatal',
    'Baunach',
    'Baumholder',
    'Baumfeld',
    'Bauler',
    'Baudenbach',
    'Battenberg',
    'Bastorf',
    'Basthorst',
    'Bastheim',
    'Bassum',
    'Bassenheim',
    'Bassen',
    'Basedow',
    'Basdahl',
    'Barweiler',
    'Barwedel',
    'Barver',
    'Baruth',
    'Barum',
    'Bartholoma',
    'Barth',
    'Bartensleben',
    'Barssel',
    'Barskamp',
    'Barsinghausen',
    'Barsbuettel',
    'Barntrup',
    'Barnstorf',
    'Barnstedt',
    'Barnstaedt',
    'Barnin',
    'Barnekow',
    'Barnau',
    'Barmstedt',
    'Barmissen',
    'Barleben',
    'Barkenholm',
    'Barkelsby',
    'Bargum',
    'Bargteheide',
    'Bargstedt',
    'Bargischow',
    'Bargfeld-Stegen',
    'Bargenstedt',
    'Barenstein',
    'Barendorf',
    'Barenburg',
    'Barenbach',
    'Bardowick',
    'Barchfeld',
    'Barbing',
    'Barbelroth',
    'Banzkow',
    'Bantikow',
    'Bannewitz',
    'Bannemin',
    'Bann',
    'Bangstede',
    'Bandelin',
    'Bammental',
    'Bamberg',
    'Balzhausen',
    'Balve',
    'Baltrum',
    'Baltmannsweiler',
    'Ballstedt',
    'Ballrechten',
    'Ballenstedt',
    'Balje',
    'Balingen',
    'Balgheim',
    'Balge',
    'Balduinstein',
    'Baldham',
    'Bakum',
    'Baindt',
    'Baiersdorf',
    'Baiersbronn',
    'Baiern',
    'Baierbrunn',
    'Baierbach',
    'Baienfurt',
    'Bahrenborstel',
    'Bahrdorf',
    'Bahlingen',
    'Bagband',
    'Baesweiler',
    'Bad Zwischenahn',
    'Bad Wurzach',
    'Bad Worishofen',
    'Bad Windsheim',
    'Bad Wimpfen',
    'Bad Wilsnack',
    'Bad Wildungen',
    'Bad Wiessee',
    'Bad Waldsee',
    'Bad Vilbel',
    'Bad Uberkingen',
    'Bad Toelz',
    'Bad Tennstedt',
    'Bad Suelze',
    'Bad Sulza',
    'Bad Steben',
    'Bad Sooden-Allendorf',
    'Bad Soden am Taunus',
    'Bad Segeberg',
    'Bad Schwartau',
    'Bad Schwalbach',
    'Bad Schmiedeberg',
    'Bad Schandau',
    'Bad Sassendorf',
    'Bad Salzungen',
    'Bad Salzuflen',
    'Bad Salzschlirf',
    'Bad Salzdetfurth',
    'Bad Sackingen',
    'Bad Sachsa',
    'Bad Saarow',
    'Bad Rothenfelde',
    'Bad Reichenhall',
    'Bad Rappenau',
    'Bad Pyrmont',
    'Bad Orb',
    'Bad Oldesloe',
    'Bad Oeynhausen',
    'Bad Neustadt an der Saale',
    'Bad Neuenahr-Ahrweiler',
    'Bad Nenndorf',
    'Bad Nauheim',
    'Bad Muskau',
    'Bad Muenstereifel',
    'Bad Muender am Deister',
    'Bad Mergentheim',
    'Bad Marienberg',
    'Bad Lippspringe',
    'Bad Liebenzell',
    'Bad Liebenwerda',
    'Bad Liebenstein',
    'Bad Lauterberg im Harz',
    'Bad Lausick',
    'Bad Lauchstaedt',
    'Bad Langensalza',
    'Bad Krozingen',
    'Bad Kreuznach',
    'Bad Koestritz',
    'Bad Koesen',
    'Bad Koenig',
    'Bad Kohlgrub',
    'Bad Klosterlausnitz',
    'Bad Kleinen',
    'Bad Kissingen',
    'Bad Iburg',
    'Bad Hoenningen',
    'Bad Honnef',
    'Bad Homburg',
    'Bad Hersfeld',
    'Bad Herrenalb',
    'Bad Heilbrunn',
    'Bad Harzburg',
    'Bad Grund',
    'Bad Gottleuba',
    'Bad Gandersheim',
    'Bad Friedrichshall',
    'Bad Freienwalde',
    'Bad Frankenhausen',
    'Bad Feilnbach',
    'Bad Essen',
    'Badersleben',
    'Badenweiler',
    'Badenheim',
    'Badenhausen',
    'Baden-Baden',
    'Bad Ems',
    'Bad Elster',
    'Bad Eilsen',
    'Bad Durrheim',
    'Bad Duerrenberg',
    'Bad Duerkheim',
    'Bad Dueben',
    'Bad Driburg',
    'Bad Doberan',
    'Bad Ditzenbach',
    'Baddeckenstedt',
    'Bad Cannstatt',
    'Bad Buchau',
    'Bad Bruckenau',
    'Bad Breisig',
    'Bad Bramstedt',
    'Bad Brambach',
    'Bad Boll',
    'Bad Bocklet',
    'Bad Blankenburg',
    'Bad Bibra',
    'Bad Bertrich',
    'Bad Berneck im Fichtelgebirge',
    'Bad Berleburg',
    'Bad Berka',
    'Bad Bergzaben',
    'Badbergen',
    'Bad Bentheim',
    'Bad Bellingen',
    'Bad Aibling',
    'Bad Abbach',
    'Backnang',
    'Bachingen an der Brenz',
    'Bachheim',
    'Bachhagel',
    'Bacharach',
    'Babensham',
    'Babenhausen',
    'Baasdorf',
    'Baalberge',
    'Aystetten',
    'Ayl',
    'Aying',
    'Axstedt',
    'Averlak',
    'Aventoft',
    'Authausen',
    'Aussernzell',
    'Ausleben',
    'Aurich',
    'Aura im Sinngrund',
    'Aurach',
    'Aura an der Saale',
    'Aumuehle',
    'Auma',
    'Aulendorf',
    'Auleben',
    'Au in der Hallertau',
    'Auhausen',
    'Auhagen',
    'Augustusburg',
    'Augustfehn',
    'Augustdorf',
    'Augsburg',
    'Auggen',
    'Aufhausen',
    'Auerbach',
    'Aue',
    'Auderath',
    'Aubstadt',
    'Aubing',
    'Au am Rhein',
    'Atzendorf',
    'Atzelgift',
    'Atting',
    'Attenweiler',
    'Attenkirchen',
    'Attenhausen',
    'Attendorn',
    'Astert',
    'Assling',
    'Asslar',
    'Assamstadt',
    'Asperg',
    'Asendorf',
    'Aschheim',
    'Aschersleben',
    'Aschendorf',
    'Ascheffel',
    'Ascheberg',
    'Aschbach',
    'Aschau am Inn',
    'Aschaffenburg',
    'Ascha',
    'Asbach',
    'Arzfeld',
    'Arzberg',
    'Arzbach',
    'Artlenburg',
    'Artern',
    'Arpshagen',
    'Arpsdorf',
    'Bad Arolsen',
    'Arnstorf',
    'Arnstein',
    'Arnstadt',
    'Arnsdorf',
    'Arnschwang',
    'Arnsberg',
    'Arnis',
    'Arneburg',
    'Arnbruck',
    'Arnbach',
    'Armsheim',
    'Arholzen',
    'Argenthal',
    'Aresing',
    'Arenrath',
    'Luftkurort Arendsee',
    'Arberg',
    'Appenweier',
    'Appen',
    'Appel',
    'Apolda',
    'Apensen',
    'Apenburg',
    'Apen',
    'Apelern',
    'Anzing',
    'Antweiler',
    'Antdorf',
    'Anschau',
    'Ansbach',
    'Anrode',
    'Anrath',
    'Annweiler am Trifels',
    'Annahuette',
    'Annaburg',
    'Annaberg-Buchholz',
    'Ankum',
    'Anklam',
    'Ankershagen',
    'Anhausen',
    'Angersbach',
    'Angern',
    'Angermuende',
    'Anger',
    'Angelroda',
    'Andisleben',
    'Andernach',
    'Anderlingen',
    'Andelfingen',
    'Andechs',
    'Amtzell',
    'Amstetten',
    'Amsdorf',
    'Ampfing',
    'Ampermoching',
    'Ammerndorf',
    'Ammern',
    'Ammelshain',
    'Amerdingen',
    'Amorbach',
    'Amerang',
    'Amelinghausen',
    'Amberg',
    'Alzey',
    'Alzenau in Unterfranken',
    'Alveslohe',
    'Alverdissen',
    'Altusried',
    'Alt Tucheband',
    'Altstrimmig',
    'Altstadten',
    'Altshausen',
    'Alt Schwerin',
    'Alt Ruppin',
    'Altrip',
    'Altrich',
    'Altoetting',
    'Altomunster',
    'Altmittweida',
    'Alt Meteln',
    'Altmannstein',
    'Altlussheim',
    'Altleiningen',
    'Altlandsberg',
    'Altkirchen',
    'Altkalen',
    'Althuttendorf',
    'Althutte',
    'Althornbach',
    'Althirschstein',
    'Althengstett',
    'Altheim',
    'Althegnenberg',
    'Altfraunhofen',
    'Alterkulz',
    'Altenwillershagen',
    'Altentreptow',
    'Altenthann',
    'Altensteig',
    'Altenstadt an der Waldnaab',
    'Altenstadt',
    'Altenriet',
    'Altenoythe',
    'Altenmunster',
    'Altenmedingen',
    'Altenmarkt an der Alz',
    'Altenkunstadt',
    'Altenkrempe',
    'Altenkirchen',
    'Altenholz',
    'Altenhof',
    'Altenheim',
    'Altenhausen',
    'Altengottern',
    'Altenglan',
    'Altengeseke',
    'Altenfeld',
    'Altendorf',
    'Altendiez',
    'Altencelle',
    'Altenburg',
    'Altenbrak',
    'Altenberge',
    'Altenberga',
    'Altenberg',
    'Altenbeken',
    'Altenbaindt',
    'Altenau',
    'Altenahr',
    'Altena',
    'Alteglofsheim',
    'Alt Duvenstedt',
    'Altdorf',
    'Altdobern',
    'Altbach',
    'Ammerthal',
    'Alsweiler',
    'Alsmoos',
    'Alsleben',
    'Alsheim',
    'Alsfeld',
    'Alsenz',
    'Alsdorf',
    'Alpirsbach',
    'Alperstedt',
    'Alpenrod',
    'Alpen',
    'Almersbach',
    'Almdorf',
    'Allstedt',
    'Allmersbach im Tal',
    'Allmendingen',
    'Allmannsweiler',
    'Alling',
    'Alleshausen',
    'Allershausen',
    'Allersberg',
    'Allensbach',
    'Allenfeld',
    'Allendorf an der Lumda',
    'Allendorf',
    'Alken',
    'Alheim',
    'Algesdorf',
    'Algermissen',
    'Alfter',
    'Alfstedt',
    'Alfhausen',
    'Alfeld',
    'Alfdorf',
    'Alf',
    'Alesheim',
    'Alerheim',
    'Aldingen',
    'Aldersbach',
    'Aldenhoven',
    'Albstadt',
    'Albig',
    'Albessen',
    'Albersweiler',
    'Albersroda',
    'Albersloh',
    'Albershausen',
    'Albersdorf',
    'Albbruck',
    'Albaching',
    'Aken',
    'Aixheim',
    'Aitrang',
    'Aitrach',
    'Aitern',
    'Alterhofen',
    'Aislingen',
    'Ainring',
    'Aindling',
    'Aiglsbach',
    'Aiging',
    'Aidlingen',
    'Aidhausen',
    'Aidenbach',
    'Aichstetten',
    'Aichhalden',
    'Aichelberg',
    'Aicha vorm Wald',
    'Aichach',
    'Ahstedt',
    'Ahrenviolfeld',
    'Ahrensfelde',
    'Ahrensburg',
    'Ahrensbok',
    'Ahorn',
    'Aholming',
    'Aholfing',
    'Ahnsen',
    'Ahnsbeck',
    'Ahlten',
    'Ahlsdorf',
    'Ahlerstedt',
    'Ahlersbach',
    'Ahlen',
    'Ahlden',
    'Ahlbershausen',
    'Ahausen',
    'Ahaus',
    'Agterhorn',
    'Aglasterhausen',
    'Agathenburg',
    'Aftholderberg',
    'Affinghausen',
    'Affing',
    'Affalterbach',
    'Aerzen',
    'Adorf',
    'Adlkofen',
    'Adendorf',
    'Adenau',
    'Adelzhausen',
    'Adelsried',
    'Adelshofen',
    'Adelsheim',
    'Adelsdorf',
    'Adelschlag',
    'Adelheidsdorf',
    'Adelebsen',
    'Achstetten',
    'Achslach',
    'Achim',
    'Achern',
    'Abtswind',
    'Abtsgmuend',
    'Abtsdorf',
    'Abtsbessingen',
    'Abstatt',
    'Absberg',
    'Ablach',
    'Abensberg',
    'Abenberg',
    'Abberode',
    'Aalen',
    'Aach',
    'Eggermuhlen',
    'Thalfang',
    'Traventhal',
    'Barby',
    'Hohenau',
    'Ehrenkirchen',
    'Apfeldorf',
    'Eglfing',
    'Germaringen',
    'Hohenkirchen-Siegertsbrunn',
    'Oy-Mittelberg',
    'Strasslach-Dingharting',
    'Achenmuhle',
    'Seeon-Seebruck',
    'Weiler-Simmerberg',
    'Bodman-Ludwigshafen',
    'Emmingen-Liptingen',
    'Herdwangen-Schonach',
    'Klettgau',
    'Lauchringen',
    'Muhlhausen-Ehingen',
    'Uhldingen-Muhlhofen',
    'Uhlingen-Birkendorf',
    'Wutach',
    'Katterbach',
    'Kerken',
    'Neukirchen-Vluyn',
    'Aspach',
    'Bad Peterstal-Griesbach',
    'Bad Rippoldsau-Schapbach',
    'Bad Teinach-Zavelstein',
    'Bobingen an der Rems',
    'Burgstetten',
    'Gutenzell-Hurbel',
    'Konigsbach-Stein',
    'Niefern-Oschelbronn',
    'Rietheim-Weilheim',
    'Seitingen-Oberflacht',
    'Urbach',
    'Giesen',
    'Hauslingen',
    'Isernhagen',
    'Buch am Erlbach',
    'Mallersdorf-Pfaffenberg',
    'Ahorntal',
    'Aurachtal',
    'Birgland',
    'Fensterbach',
    'Frankenhardt',
    'Hummeltal',
    'Kressberg',
    'Meedensdorf',
    'Muhr am See',
    'Postbauer-Heng',
    'Schwanstetten',
    'Schwarzach am Main',
    'Viereth-Trunstadt',
    'Weilersbach',
    'Wiesenttal',
    'Katlenburg-Lindau',
    'Neutz-Lettewitz',
    'Kromsdorf',
    'Topfstedt',
    'Urleben',
    'Ahnatal',
    'Diemelsee',
    'Edermuende',
    'Edertal',
    'Habichtswald',
    'Burgwald',
    'Meinhard',
    'Meissner',
    'Morschen',
    'Reinhardshagen',
    'Schauenburg',
    'Soehrewald',
    'Twistetal',
    'Vellmar',
    'Wehretal',
    'Gleichen',
    'Scheden',
    'Borchen',
    'Lippetal',
    'Mohnesee',
    'Schieder-Schwalenberg',
    'Schloss Holte',
    'Riedbach',
    'Rodental',
    'Roslau',
    'Bruchmuhlbach-Miesau',
    'Gossersweiler-Stein',
    'Hochstetten-Dhaun',
    'Hoppstadten-Weiersbach',
    'Kreimbach-Kaulbach',
    'Mannweiler-Colln',
    'Neumagen-Dhron',
    'Oberweiler-Tiefenbach',
    'Offenbach-Hundheim',
    'Osann-Monzel',
    'Salmtal',
    'Zeltingen-Rachtig',
    'Bosen-Eckelhausen',
    'Mandelbachtal',
    'Reichshof',
    'Swisttal',
    'Birken-Honigsessen',
    'Dreis-Bruck',
    'Gondershausen',
    'Grafschaft',
    'Hattert',
    'Heidenrod',
    'Holzweiler',
    'Ingelbach',
    'Kirchwald',
    'Kobern-Gondorf',
    'Malberg',
    'Moersbach',
    'Muelheim-Kaerlich',
    'Nistertal',
    'Oberehe-Stroheich',
    'Ruppach-Goldhausen',
    'Sankt Katharinen',
    'Spay',
    'Stockum-Puschen',
    'Wied',
    'Blankenbach',
    'Sailauf',
    'Aarbergen',
    'Angelburg',
    'Beselich',
    'Biebertal',
    'Bimbach',
    'Brechen',
    'Dautphetal',
    'Dietzhoelztal',
    'Eschenburg',
    'Fernwald',
    'Florstadt',
    'Gruendau',
    'Hainburg',
    'Hasselroth',
    'Hauneck',
    'Haunetal',
    'Huenfelden',
    'Huettenberg',
    'Jossgrund',
    'Lahnau',
    'Lahntal',
    'Liederbach',
    'Limeshain',
    'Linsengericht',
    'Ludwigsau',
    'Niddatal',
    'Nuesttal',
    'Pohlheim',
    'Waldems',
    'Waldsolms',
    'Walluf',
    'Weilrod',
    'Wildeck',
    'Wohratal',
    'Woellstadt',
    'Stadland',
    'Diekhusen-Fahrstedt',
    'Gross Offenseth-Aspern',
    'Klein Offenseth-Sparrieshoop',
    'Krempdorf',
    'Balzheim',
    'Rainau',
    'Riesbuerg',
    'Asbach-Baumenheim',
    'Baar-Ebenhausen',
    'Finningen',
    'Kammlach',
    'Kottgeisering',
    'Medlingen',
    'Schondorf am Ammersee',
    'Worth',
    'Isterberg',
    'Neukamperfehn',
    'Ahrenshoeft',
    'Aukrug',
    'Bordelum',
    'Emmelsbull-Horsbull',
    'Helgoland',
    'Lohe-Rickelshof',
    'Mohrkirch',
    'Oesterwurth',
    'Risum-Lindholm',
    'Schnarup-Thumby',
    'Sylt-Ost',
    'Tensbuttel-Rost',
    'Elbe',
    'Flothe',
    'Heere',
    'Ilsede',
    'Obernholz',
    'Sassenburg',
    'Sickte',
    'Bensdorf',
    'Damelang-Freienthal',
    'Marschacht',
    'Buechen-Dorf',
    'Henstedt-Ulzburg',
    'Travenbrueck',
    'Gaedebehn',
    'Moraas',
    'Strahwalde',
    'Fargau-Pratjau',
    'Admannshagen-Bargeshagen',
    'Bartenshagen-Parkentin',
    'Rossau',
    'Kappel-Grafenhausen',
    'Reute',
    'Rheinau',
    'Bruchhausen',
    'Sinntal',
    'Ebersbach-Musbach',
    'Vogtsburg',
    'Eschbronn',
    'Starzach',
    'Gaeufelden',
    'Waldachtal',
    'Rheinmuenster',
    'Seewald',
    'Waldbronn',
    'Karlsbad',
    'Straubenhardt',
    'Keltern',
    'Kaempfelbach',
    'Berglen',
    'Auenwald',
    'Aichwald',
    'Lauterstein',
    'Engstingen',
    'Sonnenbuehl',
    'Schemmerhofen',
    'Bibertal',
    'Kammeltal',
    'Schefflenz',
    'Brohl-Lutzing',
    'Marktrodach',
    'Triebel',
    'Kalletal',
    'Burgwedel',
    'Wedemark',
    'Stemwede',
    'Barnitz',
    'Ammersbek',
    'Steinburg',
    'Deggenhausertal',
    'Ransbach-Baumbach',
    'Freigericht',
    'Aachen',
    'Otzberg',
    'Ubstadt-Weiher',
    'Stadecken-Elsheim',
    'Dannstadt-Schauernheim',
    'Sankt Leon-Rot',
    'Rodersheim-Gronau',
    'Karlsdorf-Neuthard',
    'Graben-Neudorf',
    'Moerfelden-Walldorf',
    'Linkenheim-Hochstetten',
    'Billigheim-Ingenheim',
    'Bohl-Iggelheim',
    'Hochdorf-Assenheim',
    'Alsbach-Hahnlein',
    'Dittelsheim-Hessloch',
    'Brombachtal',
    'Riedstadt',
    'Angelbachtal',
    'Roemerberg',
    'Hochstadt',
    'Modautal',
    'Muehltal',
    'Hardthausen am Kocher',
    'Obersulm',
    'Leingarten',
    'Wittighausen',
    'Lauda-Konigshofen',
    'Sensbachtal',
    'Leinach',
    'Collenberg',
    'Altenbuch',
    'Mespelbrunn',
    'Lutzelbach',
    'Filderstadt',
    'Ostfildern',
    'Rodgau',
    'Butjadingen',
    'Kaufungen',
    'Jettingen-Scheppach',
    'Gropiusstadt',
    'Seeheim-Jugenheim',
    'Kalbach',
    'Ilmtal',
    'Gross Koeris',
    'Muenchehofe',
    'Unterspreewald',
    'Dallgow-Doeberitz',
    'Havelaue',
    'Seeblick',
    'Beetzsee',
    'Goerzke',
    'Schwielowsee',
    'Seddiner See',
    'Felixsee',
    'Boitzenburger Land',
    'Nordwestuckermark',
    'Oberuckersee',
    'Roevershagen',
    'Behren-Luebchin',
    'Feldberger Seenlandschaft',
    'Suederholz',
    'Luedersdorf',
    'Domsuehl',
    'Altefaehr',
    'Bobritzsch',
    'Amtsberg',
    'Striegistal',
    'Schoenheide',
    'Waldhufen',
    'Oderwitz',
    'Parthenstein',
    'Suelzetal',
    'Daehre',
    'Schimberg',
    'Moorgrund',
    'Rodeberg',
    'Tonna',
    'Straufhain',
    'Foeritz',
    'St. Ingbert',
    'Havelsee',
    'Heideblick',
    'Kuestriner Vorland',
    'Eisenhuettenstadt',
    'Schlaubetal',
    'Klanxbuell',
    'Adenbuettel',
    'Buehren',
    'Ebergoetzen',
    'Juehnde',
    'Drebber',
    'Unterluess',
    'Luenne',
    'Rhauderfehn',
    'Duensen',
    'Huenxe',
    'Rosendahl',
    'Huellhorst',
    'Anroechte',
    'Biebergemuend',
    'Floersbachtal',
    'Schoeffengrund',
    'Fuerfeld',
    'Moersdorf',
    'Puenderich',
    'Buechenbeuren',
    'Foehren',
    'Einoellen',
    'Schoenenberg-Kuebelberg',
    'Rieschweiler-Muehlbach',
    'Ingersheim',
    'OElbronn-Duerrn',
    'Moenchweiler',
    'Ruemmingen',
    'Tuerkenfeld',
    'Boebing',
    'Bodenwoehr',
    'Bischofsgruen',
    'Toepen',
    'Koednitz',
    'Muenchsteinach',
    'Heigenbruecken',
    'Theres',
    'Gruenenbach',
    'Schenefeld',
    'Schloss Holte-Stukenbrock',
    'Oestrich-Winkel',
    'Budingen',
    'Nastaetten',
    'Rheinstetten',
    'Auetal',
    'Kuesten',
    'Krummhoern',
    'Suedbrookmerland',
    'Langen (Hessen)',
    'Weimar (Lahn)',
    'Münstertal, Black Forest',
    'Panketal',
    'Schorfheide',
    'Milower Land',
    'Nuthetal',
    'Schenkendoebern',
    'Elsteraue',
    'Glottertal',
    'Neunkirchen am Brand',
    'Maxvorstadt',
    'Siegbach',
    'Schwanau',
    'Kell am See',
    'Eisleben Lutherstadt',
    'Bad Koetzting',
    'Haselbachtal',
    'Wittenberg',
    'Ebsdorfergrund',
    'Grabfeld',
    'Kabelsketal',
    'Schwentinental',
    'Heidesee',
    'Sundhagen',
    'Muldestausee',
    'Gutenborn',
    'Seegebiet Mansfelder Land',
    'Suedharz',
    'Salzatal',
    'Hoerselberg-Hainich',
    'Leinatal',
    'Westoverledingen',
    'Fehmarn',
    'Zeulenroda-Triebes',
    'Saterland',
    'Neustadt an der Orla',
    'Wangerland',
    'Grossheide',
    'Luebbenau-Neustadt',
    'Weiler bei Bingen',
    'Moormerland',
    'Kottmar',
    'Lossatal',
    'Berg bei Neumarkt in der Oberpfalz',
    'Karlstein am Main',
    'Osternienburger Land',
    'Heimweiler',
    'Extertal',
    'Bersteland',
    'Solms',
    'Rheinhausen',
    'Rehburg',
    'Oberweser',
    'Hohenstein',
    'Remchingen',
    'Heroldstatt',
    'Ebersburg',
    'Roedermark',
    'Satow',
    'Lauchhammer-Nord',
    'Elsterheide',
    'Spahnharrenstaette',
    'Mahlstetten',
    'Seustadt am Kulm',
    'Winden im Elztal',
    'Reitrain',
    'Neustadt (Wied)',
    'Syrgenstein',
    'Techau',
    'Baar (Schwaben)',
    'Gross Naundorf',
    'Finning',
    'Steinberg am See',
    'Buchholz (Westerwald)',
    'Emmerting',
    'Bad Soden',
    'Niederwuerschnitz',
    'Siersburg',
    'Diemelstadt',
    'Blaustein',
    'Helmstadt-Bargen',
    'Fronreute',
    'Hirschberg an der Bergstrasse',
    'Kraichtal',
    'Langenbrettach',
    'Lenningen',
    'Lobbach',
    'Hohendubrau',
    'Argenbuehl',
    'St. Peter',
  ],
  Luxembourg: [
    'Wormeldange',
    'Wolwelange',
    'Wolfsmuhle-les-Ellange',
    'Winseler',
    'Wincrange',
    'Wilwerwiltz',
    'Wiltz',
    'Weidingen',
    'Wecker',
    'Wasserbillig',
    'Walferdange',
    'Waldbredimus',
    'Wahlhausen',
    'Vichten',
    'Vianden',
    'Useldange',
    'Uebersyren',
    'Troisvierges',
    'Tetange',
    'Syren',
    'Strassen',
    'Steinsel',
    'Steinfort',
    'Stadtbredimus',
    'Soleuvre',
    'Schuttrange',
    'Schrondweiler',
    'Schrassig',
    'Schouweiler',
    'Schifflange',
    'Schengen',
    'Scheidgen',
    'Schandel',
    'Sanem',
    'Sandweiler',
    'Rumelange',
    'Roost',
    'Roodt-sur-Syre',
    'Roodt',
    'Rollingen',
    'Roedgen',
    'Rodange',
    'Reckange-sur-Mess',
    'Reckange',
    'Pratz',
    'Pontpierre',
    'Platen',
    'Pintsch',
    'Pettingen',
    'Pétange',
    'Osweiler',
    'Olm',
    'Oetrange',
    'Obercorn',
    'Nospelt',
    'Noertrange',
    'Noerdange',
    'Nocher',
    'Niedercorn',
    'Niederdonven',
    'Neihaischen',
    'Nachtmanderscheid',
    'Moutfort',
    'Mondorf-les-Bains',
    'Mondercange',
    'Mompach',
    'Moestroff',
    'Moesdorf',
    'Moersdorf',
    'Michelau',
    'Mertzig',
    'Merscheid',
    'Mersch',
    'Medernach',
    'Mamer',
    'Luxembourg',
    'Lullange',
    'Livange',
    'Lintgen',
    'Limpach',
    'Leudelange',
    'Lenningen',
    'Lellig',
    'Leithum',
    'Larochette',
    'Lannen',
    'Lamadelaine',
    'Kopstal',
    'Koerich',
    'Kockelscheuer',
    'Keispelt',
    'Kehlen',
    'Kayl',
    'Itzig',
    'Hunsdorf',
    'Huncherange',
    'Hovelange',
    'Hoscheid',
    'Holzem',
    'Hollenfels',
    'Hobscheid',
    'Hesperange',
    'Helmsange',
    'Hellange',
    'Heisdorf',
    'Heinerscheid',
    'Heffingen',
    'Hautcharage',
    'Hassel',
    'Hagen',
    'Hachiville',
    'Grosbous',
    'Grevenmacher',
    'Grevenknapp',
    'Gostingen',
    'Gonderange',
    'Goetzingen',
    'Godbrange',
    'Girst',
    'Garnich',
    'Frisange',
    'Foetz',
    'Flaxweiler',
    'Fischbach',
    'Findel',
    'Niederfeulen',
    'Fentange',
    'Ettelbruck',
    'Esch-sur-Alzette',
    'Erpeldange',
    'Elvange',
    'Eischen',
    'Ehnen',
    'Ehlerange',
    'Echternach',
    'Dudelange',
    'Differdange',
    'Diekirch',
    'Derenbach',
    'Dahlem',
    'Contern',
    'Consdorf',
    'Colmar',
    'Clervaux',
    'Clemency',
    'Christnach',
    'Capellen',
    'Canach',
    'Buschdorf',
    'Bridel',
    'Boxhorn',
    'Bous',
    'Boursdorf',
    'Bourscheid',
    'Bonnevoie',
    'Boevange',
    'Blumenthal',
    'Bissen',
    'Bettendorf',
    'Bettembourg',
    'Bertrange',
    'Bergem',
    'Bereldange',
    'Berdorf',
    'Berchem',
    'Belvaux',
    'Belval',
    'Beaufort',
    'Bastendorf',
    'Baschleiden',
    'Bascharage',
    'Aspelt',
    'Alzingen',
    'Bech-Kleinmacher',
    'Munshausen',
    'Senningerberg',
    'Howald',
    'Dondelange',
  ],
  France: [
    'Peyrat-le-Chateau',
    'Blaye',
    'Zuytpeene',
    'Zutkerque',
    'Zudausques',
    'Zoufftgen',
    'Zouafques',
    'Zoteux',
    'Zonza',
    'Zittersheim',
    'Zinswiller',
    'Zimming',
    'Zimmersheim',
    'Zimmerbach',
    'Zillisheim',
    'Zilia',
    'Zermezeele',
    'Zellwiller',
    'Zehnacker',
    'Zegerscappel',
    'Zalana',
    'Yzosse',
    'Yzeure',
    'Yzeron',
    'Yzernay',
    'Yvrencheux',
    "Yvre-l'Eveque",
    'Yvre-le-Polin',
    'Yvrac-et-Malleyrand',
    'Yvrac',
    'Yvoy-le-Marron',
    'Yvoire',
    'Yville-sur-Seine',
    'Yvignac-la-Tour',
    'Yviers',
    'Yvias',
    'Yvetot',
    'Yves',
    'Yversay',
    'Yvecrique',
    'Ytrac',
    'Yssingeaux',
    'Yronde',
    'Yquelon',
    'Yport',
    'Youx',
    'Yolet',
    'Ymonville',
    'Ymeray',
    'Ymare',
    'Ygrande',
    'Yffiniac',
    'Yevres',
    'Yevre-le-Chatel',
    'Yevre-la-Ville',
    'Yerville',
    'Yerres',
    'Yermenonville',
    'Yenne',
    'Yebles',
    'Yebleron',
    'Ydes',
    'Ychoux',
    'Yaucourt-Bussus',
    'Yainville',
    'Xonrupt-Longemer',
    'Xirocourt',
    'Xeuilley',
    'Xertigny',
    'Xermamenil',
    'Xaronval',
    'Xanton-Chassenon',
    'Xammes',
    'Xaintrailles',
    'Wulverdinghe',
    'Woustviller',
    'Wormhout',
    'Wolxheim',
    'Wolschwiller',
    'Wolschheim',
    'Wolfskirchen',
    'Wolfisheim',
    'Wolfgantzen',
    'Woippy',
    'Woinville',
    'Woincourt',
    'Woignarue',
    'Woelfling-les-Sarreguemines',
    'Wizernes',
    'Wiwersheim',
    'Wittisheim',
    'Wittes',
    'Wittersheim',
    'Witternheim',
    'Wittenheim',
    'Wittelsheim',
    'Witry-les-Reims',
    'Wissous',
    'Wissignicourt',
    'Wissembourg',
    'Wissant',
    'Wismes',
    'Wisches',
    'Wirwignes',
    'Wintzfelden',
    'Wintzenheim-Kochersberg',
    'Wintzenheim',
    'Wintzenbach',
    'Wintershouse',
    'Winnezeele',
    'Winkel',
    'Wingles',
    'Wingen-sur-Moder',
    'Windstein',
    'Wimy',
    'Wimmenau',
    'Wimille',
    'Wimereux',
    'Wilwisheim',
    'Willgottheim',
    'Willerwald',
    'Willerval',
    'Willer-sur-Thur',
    'Willeroncourt',
    'Willer',
    'Willems',
    'Wildenstein',
    'Wignehies',
    'Wiesviller',
    'Wicres',
    'Wicquinghem',
    'Wickerschwihr',
    'Weyersheim',
    'Weyer',
    'Westhouse-Marmoutier',
    'Westhouse',
    'Westhoffen',
    'Westhalten',
    'Wervicq-Sud',
    'Wentzwiller',
    'Weitbruch',
    'Weislingen',
    'Weinbourg',
    'Waziers',
    'Wavrin',
    'Waville',
    'Wattwiller',
    'Wattrelos',
    'Wattignies',
    'Watten',
    'Watigny',
    'Wassy',
    'Wassigny',
    'Wasselonne',
    'Wasquehal',
    'Wasnes-au-Bac',
    'Wasigny',
    'Warnecourt',
    'Warmeriville',
    'Warluzel',
    'Warlus',
    'Warluis',
    'Warloy-Baillon',
    'Warlincourt-les-Pas',
    'Warhem',
    'Warendin',
    'Wardrecques',
    'Wangenbourg-Engenthal',
    'Wanel',
    'Wancourt',
    'Wambrechies',
    'Wambez',
    'Wambercourt',
    'Wambaix',
    'Waltenheim',
    'Walscheid',
    'Walschbronn',
    'Wallon-Cappel',
    'Wallers',
    'Walincourt-Selvigny',
    'Walheim',
    'Waldwisse',
    'Waldighofen',
    'Waldhambach',
    'Walbach',
    'Wailly-Beaucamp',
    'Wailly',
    'Wahlenheim',
    'Wahagnies',
    'Wagnon',
    'Wacquemoulin',
    'Vy-les-Lure',
    'Vyans-le-Val',
    'Vulbens',
    'Vulaines-sur-Seine',
    'Vuillecin',
    'Vue',
    'Vry',
    'Vrizy',
    'Vritz',
    'Vrigny',
    'Vrigne-Meuse',
    'Vrigne-aux-Bois',
    'Vriange',
    'Vrely',
    'Vregille',
    'Vred',
    'Vraux',
    'Vraiville',
    'Vraignes-les-Hornoy',
    'Voyer',
    'Vovray-en-Bornes',
    'Voves',
    'Vouzon',
    'Vouziers',
    'Vouzan',
    'Vouvray-sur-Loir',
    'Vouvray',
    'Vouvant',
    'Voutre',
    'Vouthon',
    'Voutezac',
    'Vourles',
    'Vourey',
    'Vouneuil-sur-Vienne',
    'Vouneuil-sous-Biard',
    'Voulx',
    'Voultegon',
    'Voulpaix',
    'Voulon',
    'Vouleme',
    'Voulangis',
    'Voujeaucourt',
    'Vouillon',
    'Vouille-les-Marais',
    'Vouille',
    'Vouhenans',
    'Vouhe',
    'Vougy',
    'Vouel',
    'Vosnon',
    'Vorges',
    'Vorey',
    'Voreppe',
    'Vonnas',
    'Voncq',
    'Vomecourt-sur-Madon',
    'Volx',
    'Volvic',
    'Volstroff',
    'Volonne',
    'Volon',
    'Nurieux-Volognat',
    'Volnay',
    'Volmerange-les-Mines',
    'Vollore-Ville',
    'Volksberg',
    'Volgre',
    'Volesvres',
    'Voivres-les-le-Mans',
    'Voiteur',
    'Voisins-le-Bretonneux',
    'Voisines',
    'Voiron',
    'Voipreux',
    'Voillecomte',
    'Voillans',
    'Void-Vacon',
    'Vogue',
    'Voglans',
    'Volgelsheim',
    'Voellerdingen',
    'Voegtlinshoffen',
    'Vocance',
    'Vizille',
    'Vix',
    'Vivy',
    'Vivonne',
    'Vivoin',
    'Viviers-sur-Artaut',
    'Viviers-les-Lavaur',
    'Viviers-le-Gras',
    'Viviers',
    'Vivier-au-Court',
    'Vivario',
    'Vivaise',
    'Viuz-la-Chiesaz',
    'Viuz-en-Sallaz',
    'Vittel',
    'Vittefleur',
    'Vitteaux',
    'Vittarville',
    'Vitry-sur-Seine',
    'Vitry-sur-Orne',
    'Vitry-sur-Loire',
    'Vitry-le-François',
    'Vitry-en-Perthois',
    'Vitry-en-Artois',
    'Vitry-aux-Loges',
    'Vitrolles',
    'Vitreux',
    'Vitré',
    'Vitre',
    'Vitrac-sur-Montane',
    'Vitrac',
    'Viterbe',
    'Visseiche',
    'Vis-en-Artois',
    'Visan',
    'Viry-Châtillon',
    'Viry',
    'Virson',
    'Virsac',
    'Vironvay',
    'Vironchaux',
    'Virollet',
    'Viroflay',
    'Virlet',
    'Viriville',
    'Virignin',
    'Virigneux',
    'Virieu-le-Petit',
    'Virieu-le-Grand',
    'Virieu',
    'Viriat',
    'Virey',
    'Vireux-Wallerand',
    'Vireux-Molhain',
    'Virelade',
    'Vire',
    'Virazeil',
    'Virandeville',
    'Vion',
    'Viols-le-Fort',
    'Violes',
    'Violay',
    'Violaines',
    'Vinzelles',
    'Vinsobres',
    'Vinon-sur-Verdon',
    'Vinon',
    'Vinneuf',
    'Vinnemerville',
    'Vingt-Hanaps',
    'Vineuil-Saint-Firmin',
    'Vineuil',
    'Vindey',
    'Vindelle',
    'Vindefontaine',
    'Vincy-Manoeuvre',
    'Vincey',
    'Vincent',
    'Vincennes',
    'Vincelottes',
    'Vincelles',
    'Vinca',
    'Vinay',
    'Vinassan',
    'Vimy',
    'Vimoutiers',
    'Vimory',
    'Vimont',
    'Vimines',
    'Vimenil',
    'Vimarce',
    'Vilsberg',
    'Villy-le-Pelloux',
    'Villy-le-Bouveret',
    'Villotte-sur-Ource',
    'Villosanges',
    'Villorceau',
    'Villing',
    'Villieu-Loyes-Mollon',
    'Villiers-Vineux',
    'Villiers-sur-Yonne',
    'Villers-sur-Trie',
    'Villiers-sur-Tholon',
    'Villiers-sur-Suize',
    'Villiers-sur-Orge',
    'Villiers-sur-Morin',
    'Villiers-Saint-Denis',
    'Villiers-sur-Marne',
    'Villiers-sur-Loir',
    'Villiers-Saint-Georges',
    'Villiers-Louis',
    'Villiers-le-Morhier',
    'Villiers-le-Duc',
    'Villiers-le-Bel',
    'Villiers-le-Bacle',
    'Villiers-en-Plaine',
    'Villiers-en-Lieu',
    'Villiers-Couture',
    'Perceneige',
    'Villiers-au-Bouin',
    'Villiers-Adam',
    'Villiers',
    'Villie-Morgon',
    'Villey-sur-Tille',
    'Villey-Saint-Etienne',
    'Villexavier',
    'Villevocance',
    'Villevieux',
    'Villevieille',
    'Villeveyrac',
    'Villeveque',
    'Villevenard',
    'Villevaude',
    'Villevallier',
    'Villeurbanne',
    'Villette-sur-Aube',
    'Villette-les-Dole',
    'Villette-les-Arbois',
    'Villette-de-Vienne',
    "Villette-d'Anthon",
    'Villette',
    'Villethierry',
    'Villetelle',
    'Villetaneuse',
    'Ville-sur-Yron',
    'Ville-sur-Tourbe',
    'Ville-sur-Illon',
    'Ville-sur-Ancre',
    'Villes-sur-Auzon',
    'Villespy',
    'Villespassans',
    'Ville-sous-la-Ferte',
    'Villesequelande',
    'Villeseque',
    'Villeselve',
    'Villerville',
    'Villerupt',
    'Villers-sur-Saulnot',
    'Villers-sur-Meuse',
    'Villers-sur-Mer',
    'Villers-sur-Coudun',
    'Villers-Stoncourt',
    'Villers-sous-Preny',
    'Villers-sous-Chalamont',
    'Villers-Sire-Nicole',
    'Villers-Semeuse',
    'Villers-Saint-Sepulcre',
    'Villers-Saint-Paul',
    'Villers-Saint-Barthelemy',
    'Villers-Rotin',
    'Villers-Plouich',
    'Villers-Outreaux',
    'Villers-Marmery',
    'Villers-le-Tilleul',
    'Villers-les-Nancy',
    'Villers-les-Guise',
    'Villers-le-Sec',
    'Villers-les-Bois',
    'Villers-le-Lac',
    'Villers-la-Montagne',
    'Villers-la-Chevre',
    'Villers-Guislain',
    'Villers-Farlay',
    'Villersexel',
    'Villers-en-Haye',
    'Villers-en-Cauchies',
    'Villers-Ecalles',
    'Villers-Cotterêts',
    'Villers-Bretonneux',
    'Villers-Bocage',
    'Villers-au-Tertre',
    'Villers',
    'Villeroy',
    'Villeron',
    'Villermain',
    'Villereversure',
    'Villeret',
    'Villerest',
    'Villereau',
    'Villereal',
    'Villequier',
    'Villepreux',
    'Villepinte',
    'Villeperdue',
    'Villeparois',
    'Villeparisis',
    'Villentrois',
    'Villenoy',
    'Villenouvelle',
    'Villennes-sur-Seine',
    'Villeneuve-Tolosane',
    'Villeneuve-sur-Yonne',
    'Villeneuve-sur-Vere',
    'Villeneuve-sur-Verberie',
    'Villeneuve-sur-Lot',
    'Villeneuve-sur-Bellot',
    'Villeneuve-sur-Auvers',
    'Villeneuve-sous-Pymont',
    'Villeneuve-Saint-Germain',
    'Villeneuve-Saint-Georges',
    'Villeneuve-Minervois',
    'Villeneuve-Loubet',
    'Villeneuve-les-Sablons',
    'Villeneuve-les-Maguelone',
    'Villeneuve-les-Bouloc',
    'Villeneuve-les-Bordes',
    'Villeneuve les beziers',
    'Villeneuve-les-Avignon',
    'Villeneuve-le-Roi',
    'Villeneuve-Lecussan',
    'Villeneuve-le-Comte',
    'Villeneuve-la-Riviere',
    'Villeneuve-lArchevêque',
    'Villeneuve-la-Lionne',
    'Villeneuve-la-Guyard',
    'Villeneuve-la-Garenne',
    'Villeneuve-la-Comtesse',
    'Villeneuve-la-Comptal',
    "Villeneuve-d'Olmes",
    'Villeneuve-de-Riviere',
    'Villeneuve-de-Marsan',
    'Villeneuve-de-Marc',
    'Villeneuve-de-la-Raho',
    'Villeneuve-de-Berg',
    'Villeneuve',
    "Villenave-d'Ornon",
    'Villenauxe-la-Petite',
    'Villenauxe-la-Grande',
    'Villemus',
    'Villemur-sur-Tarn',
    'Villemurlin',
    'Villemoyenne',
    'Villemoutiers',
    'Villemoustaussou',
    'Villemotier',
    'Villemontry',
    'Villemontoire',
    'Villemomble',
    'Villemolaque',
    'Villemoisson-sur-Orge',
    'Villemoiron-en-Othe',
    'Villemoirieu',
    'Villemeux-sur-Eure',
    'Villemereuil',
    'Villemer',
    'Villembray',
    'Villembits',
    'Villematier',
    'Villemareuil',
    'Villemarechal',
    'Villemanoche',
    'Villemandeur',
    'Vilallonga dels Monts',
    'Villelongue-de-la-Salanque',
    "Villelongue-d'Aude",
    'Villeloin-Coulange',
    'Villelaure',
    'Ville-la-Grand',
    'Villejust',
    'Villejuif',
    'Villejesus',
    'Villegusien-le-Lac',
    'Villegouge',
    'Villegly',
    'Villegats',
    'Villegailhenc',
    'Villefranque',
    'Villefrancon',
    'Villefrancoeur',
    'Villefranche-sur-Saône',
    'Villefranche-sur-Mer',
    'Villefranche-du-Perigord',
    'Villefranche-de-Rouergue',
    'Villefranche-de-Panat',
    'Villefranche-de-Lauragais',
    'Villefranche-de-Conflent',
    "Villefranche-d'Allier",
    "Villefranche-d'Albigeois",
    'Villefranche-sur-Cher',
    'Villefontaine',
    'Villefollet',
    'Villefargeau',
    'Villefagnan',
    'Ville-en-Vermois',
    'Ville-en-Tardenois',
    'Ville-en-Selve',
    'Ville-en-Blaisois',
    'Villedoux',
    'Villedomer',
    'Villedieu-sur-Indre',
    'Villedieu-les-Poeles',
    'Villedieu',
    "Ville-d'Avray",
    'Villedaigne',
    'Villecroze',
    'Villecresnes',
    'Villecourt',
    'Villeconin',
    'Villecomtal-sur-Arros',
    'Villechetif',
    'Villecerf',
    'Villebrumier',
    'Villebret',
    'Villebon-sur-Yvette',
    'Villebois-Lavalette',
    'Villeblevin',
    'Villebernier',
    'Villebaudon',
    'Villebarou',
    'Ville-au-Val',
    'Ville',
    'Villaz',
    'Villaudric',
    'Villasavary',
    'Villars-sur-Var',
    'Villars-Saint-Georges',
    'Villars-les-Dombes',
    'Villars-les-Blamont',
    'Villars-en-Pons',
    'Villar-Saint-Pancrace',
    'Villars',
    'Villaries',
    "Villards-d'Heria",
    'Villard-de-Lans',
    'Villard-Bonnot',
    "Villar-d'Arene",
    'Villard',
    'Villapourcon',
    'Villanova',
    'Villandry',
    'Villandraut',
    'Villampuy',
    'Villamblard',
    'Villamblain',
    'Villaines-sous-Malicorne',
    'Villaines-la-Juhel',
    'Villaines-la-Gonais',
    'Village-Neuf',
    'Villacerf',
    'Villabon',
    'Villabe',
    'Vilde-Guingalan',
    'Vilbert',
    'Vihiers',
    'Vigy',
    'Vigoulet-Auzil',
    'Vigny',
    'Vignoux-sur-Barangeon',
    'Vignoux-sous-les-Aix',
    'Vignot',
    'Vignonet',
    'Vignolles',
    'Vignoles',
    'Vignoc',
    'Vignieu',
    'Vigneux-sur-Seine',
    'Vigneux-de-Bretagne',
    'Vignes',
    'Vignemont',
    'Vignacourt',
    'Viglain',
    'Viggianello',
    'Vigeois',
    'Viffort',
    'Vif',
    'Vievy',
    'Vieux-Vy-sur-Couesnon',
    'Vieux-Thann',
    'Vieux-Reng',
    'Vieux-Pont',
    'Vieux-Moulin',
    'Vieux-Mesnil',
    'Le Vieux-Marche',
    'Vieux-Manoir',
    'Vieux-Ferrette',
    'Vieux Conde',
    'Vieux-Conde',
    'Vieux-Charmont',
    'Vieux-Champagne',
    'Vieux-Boucau-les-Bains',
    'Vieux-Berquin',
    'Vieux',
    'Vieuvicq',
    'Vieussan',
    'Vieure',
    'Vieugy',
    'Viessoix',
    'Viesly',
    'Vierzy',
    'Vierzon',
    'Vierville-sur-Mer',
    'Viersat',
    'Viens',
    'Vienne-en-Val',
    'Vienne',
    'Viennay',
    'Viels-Maisons',
    'Vielmur-sur-Agout',
    'Viellesegure',
    'Vielle-Saint-Girons',
    'Viellenave-de-Navarrenx',
    "Viellenave-d'Arthez",
    'Vielle-Aure',
    'Vieilley',
    'Vieillevigne',
    'Vieille-Toulouse',
    'Vieilles-Maisons-sur-Joudry',
    'Vieille-Eglise-en-Yvelines',
    'Vieille-Eglise',
    'Vieille-Chapelle',
    'Vieille-Brioude',
    'Viefvillers',
    'Videlles',
    'Vidauban',
    'Vic-sur-Seille',
    'Vic-sur-Cere',
    'Vicq-sur-Gartempe',
    "Vicq-d'Auribat",
    'Vicq',
    'Vico',
    'Vic-le-Comte',
    'Vic-la-Gardiole',
    'Vichy',
    'Vicherey',
    'Vicheres',
    'Vic-Fezensac',
    'Vic-en-Bigorre',
    'Vicdessos',
    'Vibraye',
    'Vibrac',
    'Vibeuf',
    'Vias',
    'Viarmes',
    'Vianne',
    'Viane',
    'Viam',
    'Vialas',
    'Viala-du-Tarn',
    'Vezins-de-Levezou',
    'Vezins',
    'Vezin-le-Coquet',
    'Vezilly',
    'Vezezoux',
    'Vezenobres',
    'Vezelois',
    'Vezelise',
    'Vézelay',
    'Vezac',
    'Veyziat',
    'Veyssilieu',
    'Veyrins-Thuellin',
    'Veyrier-du-Lac',
    'Veyre-Monton',
    'Veyras',
    'Veyrac',
    'Veynes',
    'Vevy',
    'Veuxhaulles-sur-Aube',
    'Veuvey-sur-Ouche',
    'Veurey-Voroize',
    'Veules-les-Roses',
    'Veuil',
    'Vetraz-Monthoux',
    'Vetheuil',
    'Vestric-et-Candiac',
    'Vessey',
    'Vesseaux',
    'Vesoul',
    'Vesly',
    'Veslud',
    'Vesdun',
    'Vescovato',
    'Vesaignes-sous-Lafauche',
    'Verzy',
    'Verzenay',
    'Verzeille',
    'Vervins',
    'Vertus',
    'Vert-Saint-Denis',
    'Vertrieu',
    'Vertou',
    'Verton',
    'Vertolaye',
    'Vert-le-Petit',
    'Vert-le-Grand',
    'Vert-Toulon',
    'Vertheuil',
    'Verteuil-sur-Charente',
    'Vert-en-Drouais',
    'Verteillac',
    'Vertaizon',
    'Vertain',
    'Vert',
    'Ver-sur-Mer',
    'Ver-sur-Launette',
    'Versonnex',
    'Verson',
    'Verseilles-le-Bas',
    'Versailleux',
    'Versailles',
    'Vers',
    'Vers-Pont-du-Gard',
    'Verruyes',
    'Verrines-sous-Celles',
    'Verrieres-le-Buisson',
    'Verrieres-en-Forez',
    'Verrieres',
    'Verrens',
    'Verquin',
    'Verquigneul',
    'Verquieres',
    'Verosvres',
    'Veronne',
    'Veron',
    'Vero',
    'Verny',
    'Vern-sur-Seiche',
    'Vernoy',
    'Vernoux-sur-Boutonne',
    'Vernoux-en-Vivarais',
    'Vernoux-en-Gatine',
    'Vernou-la-Celle-sur-Seine',
    'Vernou-sur-Brenne',
    'Vernouillet',
    'Vernou-en-Sologne',
    'Vernosc-les-Annonay',
    'Vernon',
    'Vernoil-le-Fourrier',
    'Vernix',
    'Vernioz',
    'Verniolle',
    'Verneville',
    'Verneuil-sur-Vienne',
    'Verneuil-sur-Seine',
    'Verneuil-sur-Avre',
    'Verneuil-Moustiers',
    "Verneuil-l'Etang",
    'Verneuil-Grand',
    'Verneuil-en-Halatte',
    'Verneuil',
    'Verneugheol',
    'Vernet-les-Bains',
    'Vernet-la-Varenne',
    'Vernegues',
    "Vern-d'Anjou",
    'Vernaux',
    'Vernantois',
    'Vernantes',
    'Vernajoul',
    'Vernaison',
    'Vermenton',
    'Vermelles',
    'Vermandovillers',
    'Vermand',
    'Verlinghem',
    'Verlin',
    'Verlhac-Tescou',
    'Verjon',
    'Verines',
    'Verin',
    'Verigny',
    'Veria',
    'Vergt',
    'Vergonnes',
    'Vergongheon',
    'Vergigny',
    'Vergeze',
    'Vergezac',
    'Vergetot',
    'Vergeal',
    'Verfeuil',
    'Verfeil',
    'Veretz',
    'Vereaux',
    'Verdun',
    'Verdun-sur-le-Doubs',
    'Verdun-sur-Garonne',
    'Verdun-en-Lauragais',
    'Verdon',
    'Verdigny',
    'Verderel-les-Sauqueuse',
    'Verdelot',
    'Verdelais',
    'Verdalle',
    'Verchin',
    'Vercheny',
    'Verchaix',
    'Verchain-Maugre',
    'Vercel-Villedieu-le-Camp',
    'Verberie',
    'Verargues',
    'Veranne',
    'Verac',
    'Ver',
    'Venzolasca',
    'Ventron',
    'Ventiseri',
    'Venthon',
    'Venteuil',
    'Venteuges',
    'Venterol',
    'Ventenac-Cabardes',
    'Ventenac',
    'Ventelay',
    'Ventavon',
    'Ventabren',
    'Vensat',
    'Vensac',
    'Venoy',
    'Venouse',
    'Venosc',
    'Vennecy',
    'Vennans',
    'Venizy',
    'Venizel',
    'Vénissieux',
    'Vengeons',
    'Veney',
    'Veneux-les-Sablons',
    'Venette',
    'Venesmes',
    'Venerque',
    'Venerieu',
    'Venerand',
    'Venelles',
    'Venejan',
    'Vendresse',
    'Vendres',
    'Vendrennes',
    'Vendome',
    'Vendin-le-Vieil',
    'Vendin-les-Bethune',
    'Vendhuile',
    'Vendeville',
    'Vendeuvre-sur-Barse',
    'Vendeuvre-du-Poitou',
    'Vendeuvre',
    'Vendeuil-Caply',
    'Vendeuil',
    'Vendenheim',
    'Vendenesse-sur-Arroux',
    'Vendenesse-les-Charolles',
    'Vendemian',
    'Vendegies-au-Bois',
    'Vendays-Montalivet',
    'Vendat',
    'Vendargues',
    'Vence',
    'Venasque',
    'Venas',
    'Venarey-les-Laumes',
    'Venansault',
    'Venaco',
    'Vemars',
    'Velzic',
    'Velluire',
    'Vellexon-Queutrey-et-Vaudey',
    'Vellescot',
    'Velles',
    'Velleron',
    'Velleminfroy',
    'Vellefrey-et-Vellefrange',
    'Vellechevreux-et-Courbenans',
    'Vélizy-Villacoublay',
    'Velines',
    'Velesmes-Essarts',
    'Velennes',
    'Velaux',
    'Velars-sur-Ouche',
    'Velaines',
    'Velaine-en-Haye',
    'Veix',
    'Veigy',
    'Veigne',
    'Vedene',
    'Vecqueville',
    'Vecquemont',
    'Vecoux',
    'Veckring',
    'Vebron',
    'Veaugues',
    'Veauchette',
    'Veauche',
    'Vazerac',
    'Vazeilles-Limandre',
    'Vayres-sur-Essonne',
    'Vayres',
    'Vayrac',
    'Vay',
    'Vavray-le-Petit',
    'Vavray-le-Grand',
    'Vavincourt',
    'Vaux-sur-Seine',
    'Vaux-sur-Mer',
    'Vaux-sur-Eure',
    'Vaux-sur-Blaise',
    'Vauxrenard',
    'Vaux-Marquenneville',
    'Vaux-les-Saint-Claude',
    'Vaux-le-Penil',
    'Vaux-et-Chantegrue',
    'Vauxaillon',
    'Vaux',
    'Vauvillers',
    'Vauvert',
    'Vauvenargues',
    'Vautorte',
    'Vautebis',
    'Vaureilles',
    'Vaureal',
    'Vaupillon',
    'Vaunaveys-la-Rochette',
    'Vaumort',
    'Vaumoise',
    'Vaumeilh',
    'Vaumas',
    'Vaulx-Vraucourt',
    'Vaulx-en-Velin',
    'Vaulry',
    'Vaulnaveys-le-Haut',
    'Vaulnaveys-le-Bas',
    'Vaulandry',
    'Vaujours',
    'Vauhallan',
    'Vaugrigneuse',
    'Vaugneray',
    'Vaudry',
    'Vaudrimesnil',
    'Vaudricourt',
    'Vaudreching',
    'Vaudoy-en-Brie',
    'Vaudeville',
    'Vaudes',
    'Vaudelnay',
    'Vaudancourt',
    'Vaucresson',
    'Vaucouleurs',
    'Vauclaix',
    'Vauciennes',
    'Vauchretien',
    'Vauchassis',
    'Vaucelles-et-Beffecourt',
    'Vaubecourt',
    'Vaubadon',
    'Vatry',
    'Vatimont',
    'Vatilieu',
    'Vatan',
    'Vasteville',
    'Vassy',
    'Vassogne',
    'Vassieux-en-Vercors',
    'Vasselay',
    'Vassel',
    'Vasperviller',
    'Vasles',
    'Varzy',
    'Varzay',
    'Vars',
    'Varreddes',
    'Varois-et-Chaignot',
    'Varogne',
    'Varneville-Bretteville',
    'Varneville',
    'Varize',
    'Varilhes',
    'Varetz',
    'Varennes-sur-Usson',
    'Varennes-sur-Teche',
    'Varennes-sur-Morge',
    'Varennes-sur-Loire',
    'Varennes-sur-Allier',
    'Varennes-le-Grand',
    'Varennes-Jarcy',
    'Varennes-Changy',
    'Varennes-en-Argonne',
    'Varennes',
    'Varenguebec',
    'Varengeville-sur-Mer',
    'Vareilles',
    'Varces-Allieres-et-Risset',
    'Varaville',
    'Varangeville',
    'Varanges',
    'Varambon',
    'Varaize',
    'Varaignes',
    'Varages',
    'Varades',
    'Varacieux',
    'Vaour',
    'Vanzac',
    'Vanxains',
    'Vanvey',
    'Vanves',
    'Vantoux',
    'Vannes-le-Chatel',
    'Vannes',
    'Vannecrocq',
    'Vandrimare',
    'Vandre',
    'Vandoeuvre-les-Nancy',
    'Vandieres',
    'Vandelicourt',
    'Vandelans',
    'Vandeins',
    'Vance',
    'Valvigneres',
    'Valuejols',
    'Vals-pres-le-Puy',
    'Valsonne',
    'Vals-les-Bains',
    'Valros',
    'Valreas',
    'Valras-Plage',
    'Valpuiseaux',
    'Valprivas',
    'Valouse',
    'Valognes',
    'Valmont',
    'Valmondois',
    'Valmeinier',
    'Vallouise',
    'Vallon-sur-Gee',
    "Vallon-Pont-d'Arc",
    'Vallon-en-Sully',
    'Vallois',
    'Valloire',
    'Valliquerville',
    'Valliguieres',
    'Vallieres',
    'Valliere',
    'Valletot',
    'Vallet',
    'Vallery',
    'Valleroy-le-Sec',
    'Valleroy',
    'Valleret',
    'Valleres',
    'Valleraugue',
    'Vallenay',
    'Valleiry',
    'Valle-di-Mezzana',
    'Vallauris',
    'Vallant-Saint-Georges',
    'Vallans',
    'Vallangoujard',
    'Vallan',
    'Vallabrix',
    'Valiergues',
    'Valgorge',
    'Valfroicourt',
    'Valframbert',
    'Valfleury',
    'Valff',
    'Valeyrac',
    'Valergues',
    'Valenton',
    'Valentine',
    'Valentigney',
    'Valensole',
    'Valencogne',
    'Valencin',
    'Valenciennes',
    'Valence-sur-Baise',
    'Valence-en-Brie',
    'Valence',
    'Valencay',
    'Valeille',
    'Valdurenque',
    'Valdoie',
    'Val-dIzé',
    "Val d'Isère",
    'Val-de-la-Haye',
    'Valdampierre',
    'Valcourt',
    'Valcanville',
    'Valbonne',
    'Valbonnais',
    'Valberg',
    'Valay',
    'Valaurie',
    'Valailles',
    'Valady',
    'Vaivre-et-Montoille',
    'Vaite',
    'Vaison-la-Romaine',
    'Vaires-sur-Marne',
    'Vaire',
    'Vains',
    'Vailly-sur-Sauldre',
    'Vailly-sur-Aisne',
    'Vailly',
    'Vailhauques',
    'Vaiges',
    'Vagney',
    'Vadans',
    'Vacquiers',
    'Vacqueyras',
    'Vacheresse',
    'Vacherauville',
    "Vabres-l'Abbaye",
    'Vabre',
    'Vaas',
    'Uzeste',
    'Uzès',
    'Uzerche',
    'Uzer',
    'Uzemain',
    'Uzel',
    'Uzein',
    'Uzan',
    'Uza',
    'Uxem',
    'Uxegney',
    'Uvernet-Fours',
    'Uttenhoffen',
    'Ustou',
    'Ustaritz',
    'Ussy-sur-Marne',
    'Ussy',
    'Usson-en-Forez',
    'Usson-du-Poitou',
    'Ussel',
    'Usseau',
    'Ussat',
    'Ussac',
    'Usinens',
    'Us',
    'Urzy',
    'Ury',
    'Urville-Nacqueville',
    'Urville',
    'Uruffe',
    'Urt',
    'Urschenheim',
    'Urrugne',
    'Urou-et-Crennes',
    'Urmatt',
    'Urimenil',
    'Urgosse',
    'Urdes',
    'Urdens',
    'Urcy',
    'Urcuit',
    'Urcay',
    'Urbise',
    'Urbeis',
    'Urau',
    'Ur',
    'Upie',
    'Upaix',
    'Unverre',
    'Unieux',
    'Ungersheim',
    'Unac',
    'Umpeau',
    'Ully-Saint-Georges',
    'Uhrwiller',
    'Uhlwiller',
    'Uhart-Cize',
    'Ugine',
    'Uckange',
    'Uchizy',
    'Uchaux',
    'Uchaud',
    'Uberach',
    'Tuzaguet',
    'Tursac',
    'Turretot',
    'Turquant',
    'Turny',
    'Turgon',
    'Turckheim',
    'Tupin',
    'Tully',
    'Tullins',
    'Tulle',
    'Tulette',
    'Tugny-et-Pont',
    'Tudelle',
    'Tucquegnieux',
    'Tuchan',
    'Tubersent',
    'Truyes',
    'Trun',
    'Trucy-sur-Yonne',
    'Truchtersheim',
    'Troyes',
    'Trouy',
    'Trouville-sur-Mer',
    'Troussencourt',
    'Trouillas',
    'Trouhans',
    'Troubat',
    'Trosly-Loire',
    'Trosly-Breuil',
    'Troo',
    'Tronville-en-Barrois',
    'Tronsanges',
    'Tronget',
    'Trondes',
    'Tronchy',
    'Tromarey',
    'Trois-Villes',
    'Troissy',
    'Troissereux',
    'Troisgots',
    'Trocheres',
    'Troche',
    'Troarn',
    'Trizay-Coutretot-Saint-Serge',
    'Trizay',
    'Trizac',
    'Tritteling-Redlach',
    'Trith-Saint-Leger',
    'Tripleville',
    'Triors',
    'Trimbach',
    'Trilport',
    'Trilbardou',
    'Trigny',
    'Trignac',
    'Trigavou',
    'Trieux',
    'Triel-sur-Seine',
    'Trie-la-Ville',
    'Trie-Chateau',
    'Trie-sur-Baise',
    'Tricot',
    'Triaucourt-en-Argonne',
    'Treziers',
    'Trevoux',
    'Trevol',
    'Trevillers',
    'Trevignin',
    'Trevieres',
    'Treves',
    'Treverien',
    'Treveray',
    'Treveneuc',
    'Treve',
    'Trevenans',
    'Trets',
    'Tresson',
    'Tressin',
    'Tressignaux',
    'Tresses',
    'Tresserve',
    'Tresse',
    'Tressange',
    'Tresques',
    'Trespoux-Rassiels',
    'Treslon',
    'Tresilley',
    'Tresboeuf',
    'Trept',
    'Trepot',
    'Trepail',
    'Treon',
    'Trensacq',
    'Tremuson',
    'Tremouille-Saint-Loup',
    'Tremorel',
    'Tremont',
    'Treminis',
    'Tremeven',
    'Tremereuc',
    'Tremeoc',
    'Trementines',
    'Tremblois-les-Rocroi',
    'Tremblecourt',
    'Tremblay-en-France',
    'Tremblay',
    'Trelou-sur-Marne',
    'Trelon',
    'Trelly',
    'Trelivan',
    'Trelissac',
    'Trelins',
    'Trelevern',
    'Trelaze',
    'Trejouls',
    'Treize-Vents',
    'Treize-Septiers',
    'Treix',
    'Treillieres',
    'Treigny',
    'Treignat',
    'Tregunc',
    'Tréguier',
    'Tregueux',
    'Tregourez',
    'Tregomeur',
    'Treglonou',
    'Treglamus',
    'Tregastel',
    'Tregarantec',
    'Treflez',
    'Treflaouenan',
    'Treffrin',
    'Treffort-Cuisiat',
    'Treffort',
    'Trefflean',
    'Treffieux',
    'Treffendel',
    'Treduder',
    'Tredion',
    'Tredaniel',
    'Trebry',
    'Trebrivan',
    'Trebeurden',
    'Trebes',
    'Trebedan',
    'Trebas',
    'Treban',
    'Trebabu',
    'Treal',
    'Traves',
    'Traverseres',
    'Travaillan',
    'Trausse',
    'Trappes',
    'Trans-en-Provence',
    'Trannes',
    'Trange',
    'Tramoyes',
    'Tramole',
    'Tramayes',
    'Tramain',
    'Tralonca',
    'Trainou',
    'Traenheim',
    'Tracy-le-Val',
    'Tracy-le-Mont',
    'Touzac',
    'Touvre',
    'Touvois',
    'Touville',
    'Toutry',
    'Toutlemonde',
    'Toutens',
    'Toutencourt',
    'Toutainville',
    'Toussus-le-Noble',
    'Toussieux',
    'Toussieu',
    'Toury-Lurcy',
    'Toury',
    'Tourville-sur-Odon',
    'Tourville-sur-Arques',
    'Tourville-les-Ifs',
    'Tourville-la-Riviere',
    'Tourville-la-Campagne',
    'Tourves',
    'Tourtour',
    'Tourtoirac',
    'Tours-sur-Meymont',
    'Tours-sur-Marne',
    'Tours-en-Vimeu',
    'Tours-en-Savoie',
    'Tours',
    'Tourriers',
    'Tourrette-Levens',
    'Tourrenquets',
    'Tourouzelle',
    'Tourouvre',
    'Tourny',
    'Tournus',
    'Tournon-Saint-Martin',
    "Tournon-d'Agenais",
    'Tournon-sur-Rhône',
    'Tournes',
    'Tournemire',
    'Tournehem-sur-la-Hem',
    'Tournefeuille',
    'Tournecoupe',
    'Tournay',
    'Tournan-en-Brie',
    'Tournan',
    'Tourmont',
    'Tourmignies',
    'Tourly',
    'Tourlaville',
    'Tourgeville',
    'Tourrettes-sur-Loup',
    'Tour-en-Sologne',
    'Tourdun',
    'Tour-de-Faure',
    'Tourcoing',
    'Tourbes',
    'Tourailles',
    'Touquin',
    'Touques',
    'Toulouse',
    'Toulouges',
    'Toulon-sur-Arroux',
    'Toulon-sur-Allier',
    'Toulonjac',
    'Toulon',
    'Toulenne',
    'Toulaud',
    'Toul',
    'Touillon-et-Loutelet',
    'Touillon',
    'Toufflers',
    'Touet-sur-Var',
    'Toucy',
    'Totes',
    'Tossiat',
    'Tosse',
    'Tosny',
    'Torvilliers',
    'Torteron',
    'Tortequesne',
    'Torsac',
    'Torreilles',
    'Torpes',
    'Tornac',
    'Torigni-sur-Vire',
    'Torfou',
    'Torcy-le-Petit',
    'Torcy-le-Grand',
    'Torcy',
    'Torchefelon',
    'Torchamp',
    'Torce-en-Vallee',
    'Torce-Viviers-en-Charnie',
    'Torce',
    'Tonquedec',
    'Tonnoy',
    'Tonneville',
    'Tonnerre',
    'Tonneins',
    'Tonnay-Charente',
    'Tonnay-Boutonne',
    'Tomblaine',
    'Tombeboeuf',
    'Tollevast',
    'Tollent',
    'Toges',
    'Tocqueville-en-Caux',
    'Tocane-Saint-Apre',
    'Tivernon',
    'Figari',
    'Tirepied',
    'Tintury',
    'Tinteniac',
    'Tinqueux',
    'Tingry',
    'Tinchebray',
    'Tilques',
    'Tilly-sur-Seulles',
    'Tilly-sur-Meuse',
    'Tilly',
    'Tilloy-les-Mofflaines',
    'Tillieres-sur-Avre',
    'Tillieres',
    'Tille',
    'Tilh',
    'Til-Chatel',
    'Tigy',
    'Tignieu',
    'Tignes',
    'Tigne',
    'Tigery',
    'Tigeaux',
    'Tiffauges',
    'Tierce',
    'Tieffenbach',
    'Tibiran-Jaunac',
    'Thyez',
    'Thuy',
    'Thusy',
    'Thury-sous-Clermont',
    'Thury-Harcourt',
    'Thury',
    'Thurins',
    'Thure',
    'Thurageau',
    "Thun-l'Eveque",
    'Thumeries',
    'Thuit-Hebert',
    'Thuir',
    'Thuilley-aux-Groseilles',
    'Thueyts',
    'Thuellin',
    'Thoury-Ferottes',
    'Thourotte',
    'Thouron',
    'Thourie',
    'Thouarsais-Bouildroux',
    'Thouars',
    'Thouare-sur-Loire',
    'Thouarce',
    'Thors',
    'Thorrenc',
    'Thorigny-sur-Marne',
    'Thorigny',
    'Thorigne-Fouillard',
    'Thorigne-sur-Due',
    "Thorigne-d'Anjou",
    'Thorigne',
    'Thorenc',
    'Thore-la-Rochette',
    'Thorame-Basse',
    'Thoraise',
    'Thonon-les-Bains',
    'Thonnelle',
    'Thonnance-les-Joinville',
    'Thones',
    'Thomery',
    'Thollon-les-Memises',
    'Thoix',
    'Thoisy-la-Berchere',
    'Thoissey',
    'Thoiry',
    'Thoiria',
    'Thoirette',
    'Thoiras',
    'Thodure',
    'Thizy-les-Bourgs',
    'Thiville',
    'Thiviers',
    'Thivet',
    'Thiverval-Grignon',
    'Thiverny',
    'Thivencelle',
    'Thivars',
    'Thise',
    'Thire',
    'Thionville',
    'Thiolieres',
    'Thimory',
    'Thimert-Gatelles',
    'Thilouze',
    'Thil-Manneville',
    'Thillois',
    'Thilay',
    'Thil',
    'Thiezac',
    'Thieville',
    'Thieux',
    'Thieuloy-Saint-Antoine',
    "Thieulloy-l'Abbaye",
    'Thietreville',
    'Thiescourt',
    'Thierville-sur-Meuse',
    'Thierville',
    'Thiers',
    'Thiergeville',
    'Thiennes',
    'Thiefosse',
    'Thibouville',
    'Thibivillers',
    'Thibie',
    'Thiberville',
    'Thiaucourt-Regnieville',
    'Thiant',
    'Thianges',
    'Thiancourt',
    'Thiais',
    'Theze',
    'Theza',
    'Theys',
    'Thevray',
    'Theville',
    'Theuville-aux-Maillots',
    'Theuville',
    'Theuley',
    'Tethieu',
    'Thesee',
    'Therouanne',
    'Thermes-Magnoac',
    'Therdonne',
    'Theoule-sur-Mer',
    'Thenorgues',
    'Thenon',
    'Thennes',
    'Thenisy',
    'Thenioux',
    'Thenezay',
    'Theneuille',
    'Theneuil',
    'Thenay',
    'Thenac',
    'Thelonne',
    'Thelod',
    'Theligny',
    'Theize',
    'Theix',
    'Theillement',
    'Thehillac',
    'Thegra',
    'Theding',
    'Thebe',
    'Thaon-les-Vosges',
    'Thaon',
    'Thannenkirch',
    'Thann',
    'Thaire',
    'Teyran',
    'Teyjat',
    'Teuillac',
    'Teting-sur-Nied',
    'Teterchen',
    'Teteghem',
    'Tessy-sur-Vire',
    'Tesson',
    'Tesse-Froulay',
    'Terville',
    'Terves',
    'Terssac',
    'Tersanne',
    'Terrenoire',
    'Terraube',
    'Terrasson-Lavilledieu',
    'Ternay',
    'Ternat',
    'Ternant',
    'Ternand',
    'Terminiers',
    'Termignon',
    'Tergnier',
    'Terdeghem',
    'Tercis-les-Bains',
    'Tercillat',
    'Terce',
    'Tenteling',
    'Tennie',
    'Tendu',
    'Tendron',
    'Tende',
    'Tencin',
    'Tence',
    'Tenay',
    'Templeuve-en-Pevele',
    'Templemars',
    'Paris 03 Temple',
    'Teloche',
    'Tellancourt',
    'Telgruc-sur-Mer',
    'Teillet-Argenty',
    'Teille',
    'Teillay',
    'Tecou',
    'Teche',
    'Tayrac',
    'Tavers',
    'Taverny',
    'Tavernes',
    'Tavernay',
    'Tavaux',
    'Tauxigny',
    'Tautavel',
    'Taussac',
    'Taurinya',
    'Taurignan-Vieux',
    'Taurignan-Castet',
    'Tauriac',
    'Taupont',
    'Taulignan',
    'Taule',
    'Taugon',
    'Tatinghem',
    'Tasso',
    'Tassin-la-Demi-Lune',
    'Tart-le-Haut',
    "Tart-l'Abbaye",
    'Tartigny',
    'Tartas',
    'Tartaras',
    'Tarsul',
    'Taron-Sadirac-Viellenave',
    'Tarnos',
    'Targon',
    'Targassonne',
    'Tarentaise',
    'Tardets-Sorholus',
    'Tarcenay',
    'Tarbes',
    'Tarascon-sur-Ariege',
    'Tarascon',
    'Tarare',
    'Taradeau',
    'Tarabel',
    'Taponas',
    'Tantonville',
    'Tanques',
    'Tanneron',
    'Tanlay',
    'Taninges',
    'Tangry',
    'Tancrou',
    'Tancon',
    'Tancoigne',
    'Tancarville',
    'Taluyers',
    'Talmontiers',
    'Talmont-Saint-Hilaire',
    'Talmas',
    'Tallud-Sainte-Gemme',
    'Tallone',
    'Talloires',
    'Taller',
    'Tallende',
    'Tallard',
    'Talizat',
    'Talissieu',
    'Talensac',
    'Talencieux',
    'Talence',
    'Talcy',
    'Talasani',
    'Talant',
    'Talange',
    'Talais',
    'Taize-Aizie',
    'Taissy',
    'Taisnil',
    'Taisnieres-en-Thierache',
    'Taintrux',
    "Tain-l'Hermitage",
    'Taingy',
    'Tailly',
    'Taillis',
    'Tellet',
    'Taillecourt',
    'Taillebourg',
    'Taillades',
    'Tagsdorf',
    'Tagnon',
    'Taden',
    'Tacoignieres',
    'Tabre',
    'Tabanac',
    'Sylvanes',
    'Sy',
    'Suzoy',
    'Suze-la-Rousse',
    'Suzannecourt',
    'Sussargues',
    'Sus',
    'Surzur',
    'Sury-le-Comtal',
    'Sury-es-Bois',
    'Sury-aux-Bois',
    'Survilliers',
    'Surville',
    'Surtauville',
    'Surtainville',
    'Surques',
    'Surin',
    'Suriauville',
    'Surgy',
    'Surgeres',
    'Suresnes',
    'Sure',
    'Surbourg',
    'Surba',
    'Surat',
    'Supt',
    'Sundhouse',
    'Sundhoffen',
    'Sumene',
    'Sulniac',
    'Sully-sur-Loire',
    'Sully',
    'Sulignat',
    'Suippes',
    'Sucy-en-Brie',
    'Suce-sur-Erdre',
    'Succieu',
    'Subligny',
    'Subles',
    'Stutzheim-Offenheim',
    'Sturzelbronn',
    'Stuckange',
    'Struth',
    'Strenquels',
    'Strazeele',
    'Strasbourg',
    'Stotzheim',
    'Stosswihr',
    'Stiring-Wendel',
    'Still',
    'Stenay',
    'Stella-Plage',
    'Steinsoultz',
    'Steinseltz',
    'Steinbrunn-le-Bas',
    'Steinbourg',
    'Steinbach',
    'Steige',
    'Steenwerck',
    'Steenvoorde',
    'Steene',
    'Steenbecque',
    'Stattmatten',
    'Stainville',
    'Stains',
    'Staffelfelden',
    'Spycker',
    'Spincourt',
    'Spicheren',
    'Spezet',
    'Speracedes',
    'Speloncato',
    'Spechbach-le-Bas',
    'Spay',
    'Soyons',
    'Soyecourt',
    'Soyaux',
    'Souzy-la-Briche',
    'Souzy',
    'Souzay-Champigny',
    'Souyeaux',
    'Souvigny-de-Touraine',
    'Souvigny',
    'Souvigne',
    'Souvignargues',
    'Souvans',
    'Soutiers',
    'Soustons',
    'Soustelle',
    'Soussans',
    'Soussac',
    'Sousmoulins',
    'Sousceyrac',
    'Sourzac',
    'Soursac',
    'Sours',
    'Sourniac',
    'Sournia',
    'Sourdun',
    'Sourdeval',
    'Sourcieux-les-Mines',
    'Souraide',
    'Souppes-sur-Loing',
    'Soumoulou',
    'Soumeras',
    'Soumensac',
    'Soumans',
    'Soultz-sous-Forets',
    'Soultzmatt',
    'Soultz-Haut-Rhin',
    'Soultzeren',
    'Soulosse-sous-Saint-Elophe',
    'Soullans',
    'Souligny',
    'Soulignonne',
    'Souligne-sous-Ballon',
    'Soulieres',
    'Soulge-sur-Ouette',
    'Soulaures',
    'Soulangis',
    'Soulanges',
    'Soulan',
    'Soulaire-et-Bourg',
    'Soulac-sur-Mer',
    'Soula',
    'Souilly',
    'Souille',
    'Souillac',
    'Souilhanels',
    'Sougy-sur-Loire',
    'Sougy',
    'Souge',
    'Soufflenheim',
    'Souffelweyersheim',
    'Souesmes',
    'Soues',
    'Soueich',
    'Soudorgues',
    'Soudeilles',
    'Soudan',
    'Soudaine-Lavinadiere',
    'Soucy',
    'Souclin',
    'Soucirac',
    'Soucieu-en-Jarrest',
    'Soucht',
    'Souchez',
    'Soucelles',
    'Soublecause',
    'Soubise',
    'Soubes',
    'Soual',
    'Soturac',
    'Sotteville-sous-le-Val',
    'Sotteville-lès-Rouen',
    'Sotteville',
    'Sottevast',
    'Sotta',
    'Sossais',
    'Sospel',
    'Sos',
    'Sort-en-Chalosse',
    'Sorrus',
    'Sorquainville',
    'Sornay',
    'Sornac',
    'Sormery',
    'Sorigny',
    'Sorgues',
    'Sorges',
    'Soreze',
    'Sorel-Moussel',
    'Sorede',
    'Sore',
    'Sorbollano',
    'Sorbiers',
    'Sorbey',
    'Sorbets',
    'Sorbais',
    'Soppe-le-Bas',
    'Soorts-Hossegor',
    'Sonzay',
    'Sonneville',
    'Sonnaz',
    'Sonnay',
    'Songeons',
    'Sondersdorf',
    'Sondernach',
    'Sonchamp',
    'Sommieres-du-Clain',
    'Sommieres',
    'Sommevoire',
    'Sommeville',
    'Sommesous',
    'Sommery',
    'Sommerviller',
    'Sommervieu',
    'Sommereux',
    'Sommelonne',
    'Sommedieue',
    'Sommaing',
    'Somloire',
    'Sombernon',
    'Sombacour',
    'Somain',
    'Solterre',
    'Solre-le-Chateau',
    'Solomiac',
    'Sologny',
    'Sollies-Ville',
    'Sollies-Toucas',
    'Sollies-Pont',
    'Soligny-les-Etangs',
    'Soligny-la-Trappe',
    'Solignac-sur-Loire',
    'Solignac',
    'Soliers',
    'Solgne',
    'Soleymieux',
    'Soleymieu',
    'Solesmes',
    'Solers',
    'Solerieux',
    'Solenzara',
    'Solaro',
    'Solaize',
    'Soizy-aux-Bois',
    'Soisy-sur-Seine',
    'Soisy-sur-Ecole',
    'Soisy-sous-Montmorency',
    'Soisy-Bouy',
    'Soissons-sur-Nacey',
    'Soissons',
    'Soirans',
    'Soings-en-Sologne',
    'Soindres',
    'Soignolles-en-Brie',
    'Sognolles-en-Montois',
    'Socx',
    'Sochaux',
    'Smermesnil',
    'Smarves',
    'Sizun',
    'Sixt-sur-Aff',
    'Sixt-Fer-a-Cheval',
    'Six-Fours-les-Plages',
    'Sivry-sur-Meuse',
    'Sivry-Courtry',
    'Sivry',
    'Sivignon',
    'Sivergues',
    'Sisteron',
    'Sissy',
    'Sissonne',
    'Sisco',
    'Sireuil',
    'Siran',
    'Siouville-Hague',
    'Siorac-en-Perigord',
    'Siorac-de-Riberac',
    'Sion-les-Mines',
    'Sioniac',
    'Sin-le-Noble',
    'Singrist',
    'Singly',
    'Singles',
    'Sinceny',
    'Sinard',
    'Simple',
    'Simorre',
    'Simiane-Collongue',
    'Simard',
    'Simandre-sur-Suran',
    'Simandres',
    'Simandre',
    'Silly-Tillard',
    'Silly-sur-Nied',
    'Sillingy',
    'Silley-Amancey',
    'Sillery',
    'Sille-le-Philippe',
    'Sille-le-Guillaume',
    'Sillegny',
    'Sillans',
    'Silfiac',
    'Siguer',
    'Sigoyer',
    'Sigournais',
    'Sigoules',
    'Sigonce',
    'Sigolsheim',
    'Sigogne',
    'Signy-le-Petit',
    'Signes',
    'Sigloy',
    'Sigean',
    'Sierville',
    'Sierentz',
    'Sierck-les-Bains',
    'Sidiailles',
    'Sibiril',
    'Saint-Michel-sur-Ternoise',
    'Sezanne',
    'Seytroux',
    'Seythenex',
    'Seyssins',
    'Seyssinet-Pariset',
    'Seysses',
    'Seyssel',
    'Seyresse',
    'Seynod',
    'Seyne-les-Alpes',
    'Seychalles',
    'Sexfontaines',
    'Sexcles',
    'Sevry',
    'Sevrier',
    'Sevrey',
    'Sevres-Anxaumont',
    'Sèvres',
    'Sevran',
    'Sevis',
    'Severac-le-Chateau',
    'Severac',
    'Sevelinges',
    'Seurre',
    'Seuilly',
    'Seuillet',
    'Seugy',
    'Sète',
    'Sessenheim',
    'Serzy-et-Prin',
    'Sery-les-Mezieres',
    'Sery',
    'Servoz',
    'Servon-sur-Vilaine',
    'Servon-Melzicourt',
    'Servon',
    'Servins',
    'Servigney',
    'Servieres-le-Chateau',
    'Servian',
    'Serves-sur-Rhone',
    'Serverette',
    'Servaville-Salmonville',
    'Servas',
    'Servanches',
    'Servance',
    'Servais',
    'Sers',
    'Serruelles',
    'Serrouville',
    'Serris',
    'Serrigny-en-Bresse',
    'Ladoix-Serrigny',
    'Serrieres-sur-Ain',
    'Serrieres-en-Chautagne',
    'Serrieres-de-Briord',
    'Serrieres',
    'Serres-Sainte-Marie',
    'Serreslous-et-Arribans',
    'Serres-Castet',
    'Serres',
    'Serraval',
    'Serquigny',
    'Serqueux',
    'Serques',
    'Serpaize',
    'Sernhac',
    'Sermoise',
    'Sermoise-sur-Loire',
    'Sermizelles',
    'Sermiers',
    'Sermersheim',
    'Sermentizon',
    'Sermange',
    'Sermamagny',
    'Sermaize-les-Bains',
    'Sermaize',
    'Sermaises',
    'Sermaise',
    'Serley',
    'Seris',
    'Seringes-et-Nesles',
    'Serignan-du-Comtat',
    'Serignan',
    'Serignac-sur-Garonne',
    'Serignac',
    'Serifontaine',
    'Sergy',
    'Sergines',
    'Sergeac',
    'Serezin-du-Rhone',
    'Serevillers',
    'Serent',
    'Serempuy',
    'Seremange-Erzange',
    'Sereilhac',
    'Serecourt',
    'Sercy',
    'Sercoeur',
    'Serbonnes',
    'Serazereux',
    'Seranvillers-Forenville',
    'Serans',
    'Seranon',
    'Serandon',
    'Seraincourt',
    'Serain',
    'Sequehart',
    'Sequedin',
    'Sept-Saulx',
    'Septmonts',
    'Septmoncel',
    'Sept-Meules',
    'Septfonds',
    'Septeuil',
    'Septemes-les-Vallons',
    'Septeme',
    'Seppois-le-Haut',
    'Sepeaux',
    'Sentheim',
    'Sens-de-Bretagne',
    'Sens-Beaujeu',
    'Sens',
    'Sens-sur-Seille',
    'Senozan',
    'Senoville',
    'Senouillac',
    'Senots',
    'Senones',
    'Senoncourt',
    'Senonches',
    'Sennecey-les-Dijon',
    'Sennecey-le-Grand',
    'Sennece-les-Macon',
    'Sennecay',
    'Senlisse',
    'Senlis',
    'Seninghem',
    'Senestis',
    'Senesse-de-Senabugue',
    'Sene',
    'Senas',
    'Senargent-Mignafans',
    'Senantes',
    'Senan',
    'Senaillac-Lauzes',
    'Senaide',
    'Semur-en-Vallon',
    'Semur-en-Auxois',
    'Sempigny',
    'Sempesserre',
    'Semoy',
    'Semoussac',
    'Semons',
    'Semide',
    'Semeries',
    'Semens',
    'Semelay',
    'Semecourt',
    'Semeac',
    'Semblancay',
    'Sembadel',
    'Semarey',
    'Seltz',
    'Selongey',
    'Seloncourt',
    'Selommes',
    'Selles-sur-Cher',
    'Selles-Saint-Denis',
    'Selles',
    'Seligne',
    'Sélestat',
    'Seix',
    'Seissan',
    'Seingbouse',
    'Seine-Port',
    "Seillons-Source-d'Argens",
    'Seillans',
    'Seilhac',
    'Seilh',
    'Seigy',
    'Seigny',
    'Seignosse',
    'Seigneulles',
    'Seignelay',
    'Seiches-sur-le-Loir',
    'Seichamps',
    'Seguret',
    'Segrois',
    'Segrie',
    'Segre',
    'Segoufielle',
    'Segonzac',
    'Segny',
    'Seglien',
    'Seez',
    'Sees',
    'Sedan',
    'Secqueville-en-Bessin',
    'Secondigny',
    'Seclin',
    'Sechilienne',
    'Secheval',
    'Secenans',
    'Seby',
    'Sebourg',
    'Seboncourt',
    'Sebazac-Concoures',
    'Scy-Chazelles',
    'Scrignac',
    'Scorbe-Clairvaux',
    'Scolca',
    'Scionzier',
    'Sciez',
    'Sciecq',
    'Schwobsheim',
    'Schwindratzheim',
    'Schwenheim',
    'Schweighouse-Thann',
    'Schweighouse-sur-Moder',
    'Schorbach',
    'Schopperten',
    'Schoeneck',
    'Schoenau',
    'Schnersheim',
    'Schmittviller',
    'Schlierbach',
    'Schleithal',
    'Schirrhoffen',
    'Schirrhein',
    'Schirmeck',
    'Schiltigheim',
    'Schillersdorf',
    'Scherwiller',
    'Scharrachbergheim-Irmstett',
    'Scey-sur-Saone-et-Saint-Albin',
    "Sceaux-d'Anjou",
    'Sceaux',
    'Scaer',
    'Sazilly',
    'Saze',
    'Sayat',
    'Savy-Berlette',
    'Savy',
    'Savoyeux',
    'Savonnieres',
    'Savolles',
    'Savoisy',
    'Savins',
    'Savines-le-Lac',
    'Savigny-sur-Seille',
    'Savigny-sur-Orge',
    'Savigny-sur-Braye',
    'Savigny-Poil-Fol',
    'Savigny-Levescault',
    'Savigny-le-Temple',
    'Savigny-les-Beaune',
    'Savigny-en-Veron',
    'Savigny-en-Terre-Plaine',
    'Savigny-en-Septaine',
    'Savigny-en-Sancerre',
    'Savigny-en-Revermont',
    'Savigny',
    'Savignies',
    "Savigne-l'Eveque",
    'Savigne',
    'Savignac-de-lIsle',
    'Savignac-sur-Leyze',
    'Savignac-les-Eglises',
    'Savignac-Ledrier',
    'Savigna',
    'Savieres',
    'Savianges',
    'Saverne',
    'Saveres',
    'Saverdun',
    'Savennieres',
    'Savenay',
    'Savasse',
    'Savas',
    'Savarthes',
    'Sauze-Vaussais',
    'Sauzet',
    'Sauxillanges',
    'Sauville',
    'Sauvigny-les-Bois',
    'Sauvigny-le-Bois',
    'Sauvian',
    'Sauveterre-de-Guyenne',
    'Sauveterre',
    'Sauveterre-de-Bearn',
    'Sauverny',
    'Sauve',
    'Sauvagnon',
    'Sauvagney',
    'Sautron',
    'Sauteyrargues',
    'Sauternes',
    'Sautel',
    'Saussines',
    'Saussignac',
    'Saussey',
    'Sausseuzemare-en-Caux',
    'Sausset-les-Pins',
    'Saussenac',
    'Saussay-la-Campagne',
    'Saussay',
    'Saussan',
    'Sausheim',
    'Sauret-Besserve',
    'Saurat',
    'Sauqueville',
    'Saunieres',
    'Saunay',
    'Saumur',
    'Saumont',
    'Saulzoir',
    'Saulzet-le-Chaud',
    'Saulxures-sur-Moselotte',
    'Saulxures-les-Nancy',
    'Saulxures',
    'Saulx-Marchais',
    'Saulx-les-Chartreux',
    'Saulx-le-Duc',
    'Saulx',
    'Sault-de-Navailles',
    'Sault-Brenaz',
    'Saultain',
    'Saulon-la-Chapelle',
    'Saulny',
    'Saulnot',
    'Saulnieres',
    'Saulnes',
    'Saulnay',
    'Saulieu',
    'Sauliac-sur-Cele',
    'Saulgond',
    'Saulges',
    'Saulge',
    'Saules',
    'Saulcy-sur-Meurthe',
    'Saulchoy',
    'Saulchery',
    'Saulcet',
    'Saulce-sur-Rhone',
    'Saulces-Monclin',
    'Saujon',
    'Saugues',
    'Saugnacq-et-Muret',
    'Saugnac-et-Cambran',
    'Sauchay',
    'Saucats',
    'Saubusse',
    'Saubrigues',
    'Saubion',
    'Saubens',
    'Saturargues',
    'Satolas-et-Bonce',
    'Satillieu',
    'Sathonay-Village',
    'Sathonay-Camp',
    'Sassenay',
    'Sassenage',
    'Sassegnies',
    'Sassay',
    'Sasnieres',
    'Sarzeau',
    'Sartrouville',
    'Sartilly',
    'Sartène',
    'Sars-Poteries',
    'Sars-et-Rosieres',
    'Sarry',
    'Sarrola',
    'Sarrogna',
    'Sarrigne',
    'Sarrians',
    'Sarrewerden',
    'Sarre-Union',
    'Sarreinsming',
    'Sarreguemines',
    'Sarrebourg',
    'Sarras',
    'Sarrancolin',
    'Sarraltroff',
    'Sarralbe',
    'Sarp',
    'Saron-sur-Aube',
    'Sarnois',
    'Sarniguet',
    'Sarlat-la-Canéda',
    'Sarlande',
    'Sari-Solenzara',
    'Sarge-sur-Braye',
    'Sarge-les-le-Mans',
    'Sare',
    'Sardent',
    'Sarcus',
    'Sarcey',
    'Sarcelles',
    'Sarceaux',
    'Sarce',
    'Sarbazan',
    'Saran',
    'Saramon',
    'Saponay',
    'Sapois',
    'Sapogne-sur-Marche',
    'Sapogne-et-Feucheres',
    'Sapignicourt',
    'Saou',
    'Saorge',
    'Saonnet',
    'Saone',
    'Sanzey',
    'Sanvignes-les-Mines',
    'Sanvensa',
    'Santilly',
    'Santeuil',
    'Santes',
    'Santeny',
    'Santenay',
    'Santec',
    'Santa-Reparata-di-Balagna',
    "Sant'Antonino",
    'Santans',
    'Santa-Maria-di-Lota',
    'Sans-Vallois',
    'Sanssat',
    "Sanssac-l'Eglise",
    'Sansac-de-Marmiesse',
    'Sanry-les-Vigy',
    'Sannois',
    'San-Nicolao',
    'Sannerville',
    'Sannat',
    'San-Martino-di-Lota',
    'Sanilhac-Sagries',
    'Sanguinet',
    'Sanghen',
    'Sangatte',
    'Sandouville',
    'Sandillon',
    'Sand',
    'Sancy-les-Provins',
    'Sancoins',
    'Sanchey',
    'Sancheville',
    'Sancey-le-Long',
    'Sancey-le-Grand',
    'Sancergues',
    'Sance',
    'Sanary-sur-Mer',
    'Sampzon',
    'Sampigny',
    'Sampans',
    'Samoussy',
    'Samoreau',
    'Samonac',
    'Samois-sur-Seine',
    'Samognat',
    'Samoëns',
    'Sammeron',
    'Samerey',
    'Samer',
    'Sameon',
    'Sambin',
    'Samazan',
    'Samatan',
    'Samadet',
    'Salvagnac',
    'Salsigne',
    'Salperwick',
    'Salouel',
    'Salon-de-Provence',
    'Salome',
    'Salmiech',
    'Salmbach',
    'Salmagne',
    'Salles-sur-Mer',
    'Salles-sur-Garonne',
    'Sallespisse',
    'Salles-Lavalette',
    'Salles-la-Source',
    "Salles-d'Aude",
    "Salles-d'Angles",
    'Salles-Curan',
    'Salles-Adour',
    'Salles',
    'Sallertaine',
    'Sallen',
    "Salleles-d'Aude",
    'Salledes',
    'Salleboeuf',
    'Sallaumines',
    'Sallanches',
    'Sallagriffon',
    'Salins-les-Thermes',
    'Salins-les-Bains',
    'Salins',
    'Salinelles',
    'Salindres',
    'Salin-de-Giraud',
    'Saligny',
    'Salignac',
    'Salies-du-Salat',
    'Salies-de-Bearn',
    'Saleux',
    'Salettes',
    'Salesches',
    'Sales',
    'Salers',
    'Salernes',
    'Salency',
    'Saleilles',
    'Salses-le-Chateau',
    'Salbris',
    'Salbert',
    'Salavre',
    'Salaunes',
    'Salasc',
    'Salaise-sur-Sanne',
    'Salagnon',
    'Saizy',
    'Saizerais',
    'Saix',
    'Saivres',
    'Saissac',
    'Sainville',
    'Saint-Zacharie',
    'Saint-Yzan-de-Soudiac',
    'Saint-Yvi',
    'Saint-Ythaire',
    'Saint-Yrieix-sur-Charente',
    'Saint-Yrieix-le-Dejalat',
    'Saint-Yrieix-la-Perche',
    'Saint-Yorre',
    'Saint-Yon',
    'Saint-Ybard',
    'Saint-Yan',
    'Saint-Xandre',
    'Saint-Witz',
    'Saint-Wandrille-Rancon',
    'Saint-Vulbas',
    'Saint-Vran',
    'Saint-Vrain',
    'Saint-Vivien-de-Medoc',
    'Saint-Vivien',
    'Saint-Vitte-sur-Briance',
    'Saint-Vital',
    'Saint-Vit',
    'Saint-Vincent-sur-Oust',
    'Saint-Vincent-sur-Graon',
    'Saint-Vincent-la-Chatre',
    'Saint-Vincent-Jalmoutiers',
    'Saint-Vincent-du-Boulay',
    'Saint-Vincent-de-Tyrosse',
    'Saint-Vincent-des-Landes',
    'Saint-Vincent-de-Reins',
    'Saint-Vincent-de-Pertignas',
    'Saint-Vincent-de-Paul',
    'Saint-Vincent-de-Lamontjoie',
    'Saint-Vincent-de-Durfort',
    'Saint-Vincent-de-Barres',
    'Saint-Vincent-de-Barbeyrargues',
    'Saint-Vincent',
    "Saint-Vigor-d'Ymonville",
    'Saint-Vigor-des-Mezerets',
    'Saint-Victurnien',
    'Saint-Victour',
    'Saint-Victor-sur-Rhins',
    'Saint-Victor-sur-Loire',
    'Saint-Victor-la-Coste',
    'Saint-Victoret',
    'Saint-Victor-de-Cessieu',
    'Saint-Victor',
    'Saint-Viaud',
    'Saint-Verand',
    'Saint-Veran',
    'Saint-Venant',
    'Saint-Vaury',
    'Saint-Varent',
    'Saint-Vallier-de-Thiey',
    'Saint-Vallier',
    'Saint-Valerien',
    'Saint-Valentin',
    'Saint-Vaize',
    'Saint-Vaast-la-Hougue',
    'Saint-Vaast-Dieppedalle',
    'Saint-Uze',
    'Saint-Usage',
    'Saint-Urbain-Maconcourt',
    'Saint-Urbain',
    'Saint-Tropez',
    'Saint-Trojan-les-Bains',
    'Saint-Trivier-sur-Moignans',
    'Saint-Trivier-de-Courtes',
    'Saint-Trimoel',
    'Saint-Thurial',
    'Saint-Thual',
    'Saint-Thonan',
    'Saint-Thome',
    'Saint-Thois',
    'Saint-Thibery',
    'Saint-Thibault-des-Vignes',
    'Saint-Thibault',
    'Saint-Thegonnec',
    'Saint-Symphorien-sur-Couze',
    'Saint-Symphorien-sur-Coise',
    'Saint-Symphorien-le-Valois',
    'Saint-Symphorien-dOzon',
    'Saint-Symphorien-de-Lay',
    "Saint-Symphorien-d'Ancelles",
    'Saint-Symphorien',
    'Saint-Sylvestre-sur-Lot',
    'Pragoulin',
    'Saint-Sylvestre',
    "Saint-Sylvain-d'Anjou",
    'Saint-Sylvain',
    'Saint-Sulpice-sur-Leze',
    'Saint-Sulpice-le-Verdon',
    'Saint-Sulpice-les-Feuilles',
    'Saint-Sulpice-le-Gueretois',
    'Saint-Sulpice-la-Foret',
    'Saint-Sulpice-et-Cameyrac',
    'Saint-Sulpice-de-Royan',
    'Saint-Sulpice-de-Roumagnac',
    'Saint-Sulpice-de-Cognac',
    "Saint-Sulpice-d'Arnoult",
    'Saint-Sulpice-de-Pommeray',
    'Saint-Sulpice',
    'Saint-Sulpice-la-Pointe',
    'Saint-Suliac',
    'Saint-Soupplets',
    'Saint-Sornin-la-Marche',
    'Saint-Sornin',
    'Saint-Sorlin-en-Valloire',
    'Saint-Sorlin-en-Bugey',
    'Saint-Sorlin-dArves',
    'Saint-Sixt',
    'Saint-Simon-de-Pellouaille',
    'Saint-Simon',
    'Saint-Simeon-de-Bressieux',
    'Saint-Simeon',
    'Saint-Sigismond',
    'Saint-Siffret',
    'Saint-Severin',
    'Saint-Sever-du-Moustier',
    'Saint-Sever',
    'Saint-Servant',
    'Saint-Servais',
    'Saint-Sernin-sur-Rance',
    'Saint-Sernin-du-Plain',
    'Saint-Sernin-du-Bois',
    'Saint-Sernin',
    'Saint-Series',
    'Saint-Senoux',
    'Saint-Senoch',
    'Saint-Selve',
    'Saint-Segal',
    'Saint-Secondin',
    'Saint-Sébastien-sur-Loire',
    'Saint-Sebastien-de-Morsent',
    'Saint-Sebastien',
    'Saint-Savournin',
    'Saint-Saviol',
    'Saint-Savinien',
    'Saint-Savin',
    'Saint-Sauvier',
    'Saint-Sauveur-sur-Tinee',
    'Saint-Sauveur-sur-Ecole',
    'Saint-Sauveur-le-Vicomte',
    'Saint-Sauveur-les-Bray',
    'Saint-Sauveur-Lendelin',
    'Saint-Sauveur-Gouvernet',
    'Saint-Sauveur-en-Puisaye',
    'Saint-Sauveur-des-Landes',
    'Saint-Sauveur-de-Puynormand',
    'Saint-Sauveur-de-Montagut',
    'Saint-Sauveur-de-Cruzieres',
    'Saint-Sauveur-dAunis',
    'Saint-Sauveur',
    'Saint-Saulve',
    'Saint-Saulge',
    'Saint-Sauflieu',
    'Saint-Saud-Lacoussiere',
    'Saint-Saturnin-les-Avignon',
    'Saint-Saturnin-du-Bois',
    'Saint-Saturnin-de-Lenne',
    'Saint-Saturnin-les-Apt',
    'Saint-Satur',
    'Saint-Santin-de-Maurs',
    'Saint-Santin-Cantales',
    'Saint-Sandoux',
    'Saint-Samson-sur-Rance',
    'Saint-Samson-de-Bonfosse',
    'Saint-Saire',
    'Saint-Saens',
    'Saints',
    'Saints-en-Puisaye',
    'Saintry-sur-Seine',
    'Saint-Rustice',
    'Saint-Romphaire',
    'Saint-Rome-de-Tarn',
    'Saint-Romans-des-Champs',
    'Saint-Romans',
    'Saint-Romain-sur-Cher',
    'Saint-Romain-les-Atheux',
    'Saint-Romain-le-Puy',
    'Saint-Romain-la-Virvee',
    'Saint-Romain-la-Motte',
    'Saint-Romain-Lachalm',
    'Saint-Romain-en-Jarez',
    'Saint-Romain-en-Gal',
    'Saint-Romain-de-Surieu',
    'Saint-Romain-de-Popey',
    'Saint-Romain-de-Lerps',
    'Saint-Romain-de-Jalionas',
    'Saint-Romain-de-Colbosc',
    'Saint-Romain-de-Benet',
    "Saint-Romain-d'Ay",
    'Saint-Romain',
    'Saint-Rogatien',
    'Saint-Roch',
    'Saint-Riquier',
    'Saint-Rieul',
    'Saint-Reverend',
    'Saint-Restitut',
    'Saint-Renan',
    'Saint-Remy-sur-Durolle',
    'Saint-Remy-sur-Creuse',
    'Saint-Remy-sur-Avre',
    "Saint-Remy-l'Honore",
    'Saint-Remy-les-Chevreuse',
    'Saint-Remy-la-Varenne',
    'Saint-Remy-la-Vanne',
    'Saint-Remy-en-Rollat',
    'Saint-Remy-du-Plain',
    'Saint-Remy-de-Sille',
    'Saint-Rémy-de-Provence',
    'Saint-Remy-de-Blot',
    'Saint-Remy',
    'Saint-Remy-de-Maurienne',
    'Saint-Remeze',
    'Saint-Raphaël',
    'Saint-Rambert-en-Bugey',
    "Saint-Rambert-d'Albon",
    'Saint-Racho',
    'Saint-Rabier',
    'Saint-Quintin-sur-Sioule',
    'Saint-Quentin-le-Verger',
    'Saint-Quentin-la-Poterie',
    'Saint-Quentin-Fallavier',
    'Saint-Quentin-de-Chalais',
    'Saint-Quentin-de-Baron',
    'Saint-Quentin',
    'Saint-Quay-Portrieux',
    'Saint-Quay-Perros',
    'Saint-Pryve-Saint-Mesmin',
    'Saint-Prouant',
    'Saint-Prix',
    'Saint-Privat-la-Montagne',
    'Saint-Privat-de-Vallongue',
    'Saint-Privat-des-Vieux',
    'Saint-Priest-Taurion',
    'Saint-Priest-sous-Aixe',
    'Saint-Priest-la-Plaine',
    'Saint-Priest-la-Feuille',
    'Saint-Priest-en-Murat',
    'Saint-Priest-en-Jarez',
    'Saint-Priest',
    'Saint-Prest',
    'Saint-Pourcain-sur-Sioule',
    'Saint-Pourcain-sur-Besbre',
    'Saint-Pouange',
    'Saint-Pont',
    'Saint-Pons-de-Thomieres',
    'Saint-Pol-sur-Ternoise',
    'Saint-Pol-sur-Mer',
    'Saint-Polgues',
    'Saint-Pol-de-Léon',
    'Saint-Poix',
    'Saint-Point-Lac',
    'Saint-Planchers',
    'Saint-Pierre-sur-Dropt',
    'Saint-Pierre-sur-Dives',
    'Saint-Pierre-Roche',
    'Saint-Pierre-Quiberon',
    'Saint-Pierre-Montlimart',
    'Saint-Pierre-les-Nemours',
    'Saint-Pierre-les-Elbeuf',
    'Saint-Pierre-le-Moutier',
    'Saint-Pierre-Lavis',
    'Saint-Pierre-la-Vieille',
    'Saint-Pierre-la-Palud',
    'Saint-Pierre-Langers',
    'Saint-Pierre-la-Garenne',
    'Saint-Pierre-la-Bruyere',
    'Saint-Pierre-en-Port',
    'Saint-Pierre-Eglise',
    'Saint-Pierre-du-Vauvray',
    'Saint-Pierre-du-Regard',
    'Saint-Pierre-du-Perray',
    'Saint-Pierre-du-Palais',
    'Saint-Pierre-du-Mont',
    'Saint-Pierre-du-Chemin',
    'Saint-Pierre-du-Bu',
    'Saint-Pierre-du-Bosguerard',
    "Saint-Pierre-d'Oleron",
    "Saint-Pierre-d'Irube",
    "Saint-Pierre-d'Eyraud",
    'Saint-Pierre-dExideuil',
    'Saint-Pierre-de-Vassols',
    'Saint-Pierre-de-Varennes',
    'Saint-Pierre-de-Varengeville',
    'Saint-Pierre-des-Ormes',
    'Saint-Pierre-des-Nids',
    'Saint-Pierre-des-Fleurs',
    'Saint-Pierre-de-Semilly',
    'Saint-Pierre-des-Echaubrognes',
    'Saint-Pierre-des-Corps',
    'Saint-Pierre-en-Faucigny',
    'Saint-Pierre-de-Riviere',
    'Saint-Pierre-de-Plesguen',
    "Saint-Pierre-d'Entremont",
    'Saint-Pierre-de-Mons',
    'Saint-Pierre-de-Manneville',
    'Saint-Pierre-de-Maille',
    'Saint-Pierre-dels-Forcats',
    "Saint-Pierre-de-l'Isle",
    'Saint-Pierre-de-la-Fage',
    'Saint-Pierre-de-Fursac',
    'Saint-Pierre-de-Franqueville',
    'Saint-Pierre-de-Curtille',
    'Saint-Pierre-de-Cormeilles',
    'Saint-Pierre-de-Clairac',
    'Saint-Pierre-de-Chignac',
    'Saint-Pierre-de-Chartreuse',
    'Saint-Pierre-de-Chandieu',
    'Saint-Pierre-de-Buzet',
    'Saint-Pierre-de-Bressieux',
    'Saint-Pierre-de-Bailleul',
    "Saint-Pierre-d'Autils",
    "Saint-Pierre-d'Argencon",
    'Saint-Pierre-dAllevard',
    "Saint-Pierre-d'Albigny",
    'Saint-Pierre-Brouck',
    'Saint-Pierre-Aigle',
    'Saint-Pierre',
    'Saint-Piat',
    "Saint-Philippe-d'Aiguille",
    'Saint-Philbert-sur-Risle',
    'Saint-Philbert-du-Peuple',
    'Saint-Philbert-de-Grand-Lieu',
    'Saint-Philbert-de-Bouaine',
    'Saint-Pey-de-Castets',
    'Saint-Pever',
    'Saint-Perreux',
    'Saint-Pere-en-Retz',
    'Saint-Pere',
    'Saint-Perdon',
    'Saint-Peray',
    'Saint-Peravy-la-Colombe',
    'Saint-Pee-sur-Nivelle',
    'Saint-Pe-de-Bigorre',
    'Saint-Paul-Trois-Chateaux',
    'Saint-Paul-les-Durance',
    'Saint-Paul-les-Romans',
    'Saint-Paul-les-Fonts',
    'Saint-Paul-les-Dax',
    'Saint-Paul-le-Jeune',
    'Saint-Paul-le-Gaultier',
    'Saint-Paul-la-Roche',
    'Saint-Paulien',
    'Saint-Paul-et-Valmalle',
    'Saint-Paulet-de-Caisson',
    'Saint-Paul-en-Jarez',
    'Saint-Paul-en-Foret',
    'Saint-Paul',
    'Saint-Paul-en-Chablais',
    'Saint-Paul-en-Born',
    'Saint-Paul-du-Vernay',
    'Saint-Paul-de-Vezelin',
    'Saint-Paul-de-Varces',
    'Saint-Paul-de-Varax',
    'Saint-Paul-de-Serre',
    'Saint-Paul-de-Jarrat',
    'Saint-Paul-de-Fourques',
    'Saint-Paul-de-Fenouillet',
    'Saint-Paul-Cap-de-Joux',
    'Saint-Paul-de-Vence',
    'Saint-Paul-sur-Save',
    'Saint-Pathus',
    'Saint-Paterne-Racan',
    'Saint-Paterne',
    'Saint-Pastour',
    'Saint-Parthem',
    'Saint-Parres-les-Vaudes',
    'Saint-Parres-aux-Tertres',
    'Saint-Parize-le-Chatel',
    'Saint-Pargoire',
    'Saint-Pardoux-la-Riviere',
    'Saint-Pardoux-du-Breuil',
    'Saint-Pardoux',
    'Saint-Pardon-de-Conques',
    'Saint-Papoul',
    'Saint-Pantaleon-de-Larche',
    'Saint-Pandelon',
    'Saint-Pancrasse',
    'Saint-Pal-de-Mons',
    'Saint-Palais-sur-Mer',
    'Saint-Palais-du-Ne',
    'Saint-Palais-de-Negrignac',
    'Saint-Palais',
    'Saint-Pair-sur-Mer',
    'Saint-Pabu',
    'Saint-Oulph',
    'Saint-Ouen-les-Vignes',
    'Saint-Ouen-les-Parey',
    'Saint-Ouen-le-Pin',
    "Saint-Ouen-l'Aumone",
    'Saint-Ouen-en-Champagne',
    'Saint-Ouen-en-Brie',
    'Saint-Ouen-en-Belin',
    'Saint-Ouen-du-Tilleul',
    'Saint-Ouen-du-Breuil',
    'Saint-Ouen-des-Toits',
    'Saint-Ouen-des-Besaces',
    'Saint-Ouen-des-Alleux',
    "Saint-Ouen-d'Attez",
    'Saint-Ouen',
    'Saint-Orens-de-Gameville',
    'Saint-Ondras',
    'Saint-Omer-en-Chaussee',
    'Saint-Omer-Capelle',
    'Saint-Omer',
    'Saint-Offenge-dessus',
    'Saint-Nom-la-Breteche',
    'Saint-Nolff',
    'Saint-Nizier-sous-Charlieu',
    'Saint-Nizier-le-Desert',
    'Saint-Nizier-le-Bouchoux',
    'Saint-Nizier-du-Moucherotte',
    "Saint-Nizier-d'Azergues",
    'Saint-Nicolas-du-Pelem',
    'Saint-Nicolas-du-Bosc',
    'Saint-Nicolas-des-Bois',
    'Saint-Nicolas-de-Redon',
    'Saint-Nicolas-de-Port',
    'Saint-Nicolas-de-Pierrepont',
    'Saint-Nicolas-de-Macherin',
    'Saint-Nicolas-de-la-Taille',
    'Saint-Nicolas-de-la-Grave',
    'Saint-Nicolas-de-Bliquetuit',
    "Saint-Nicolas-d'Aliermont",
    'Saint-Nic',
    'Saint-Nexans',
    'Saint-Nectaire',
    'Saint-Nazaire-sur-Charente',
    'Saint-Nazaire-de-Ladarez',
    "Saint-Nazaire-d'Aude",
    'Saint-Nazaire',
    'Saint-Nazaire-les-Eymes',
    'Saint-Nauphary',
    'Saint-Nabord',
    'Saint-Nabor',
    'Saint-Morillon',
    'Saint-More',
    'Saint-Montan',
    'Saint-Molf',
    'Saint-Mitre-les-Remparts',
    'Saint-Mihiel',
    'Saint-Michel-sur-Orge',
    'Saint-Michel-sur-Meurthe',
    'Saint-Michel-sur-Loire',
    'Saint-Michel-Mont-Mercure',
    'Saint-Michel-lObservatoire',
    'Saint-Michel-le-Cloucq',
    'Saint-Michel-en-lHerm',
    'Saint-Michel-en-Greve',
    'Saint-Michel-en-Brenne',
    'Saint-Michel-de-Villadeix',
    "Saint-Michel-d'Euzet",
    'Saint-Michel-des-Andaines',
    'Saint-Michel-de-Montjoie',
    'Saint-Michel-de-Maurienne',
    'Saint-Michel-de-Llotes',
    'Saint-Michel-de-Lanes',
    'Saint-Michel-de-Fronsac',
    'Saint-Michel-de-Castelnau',
    'Saint-Michel-de-Bannieres',
    'Saint-Michel-Chef-Chef',
    'Saint-Michel',
    "Saint-M'Hervon",
    'Saint-Mexant',
    'Saint-Mesmin',
    'Saint-Mesmes',
    'Saint-Menoux',
    'Saint-Memmie',
    'Saint-Meme-les-Carrieres',
    'Saint-Meloir-des-Ondes',
    'Saint-Meloir-des-Bois',
    'Saint-Meen-le-Grand',
    'Saint-Médard-en-Jalles',
    'Saint-Medard-en-Forez',
    "Saint-Medard-d'Eyrans",
    'Saint-Medard-de-Guizieres',
    "Saint-Medard-d'Aunis",
    'Saint-Medard',
    'Saint-Medard-la-Rochette',
    'Saint-Mayeux',
    'Saint-Maximin-la-Sainte-Baume',
    'Saint-Maximin',
    'Saint-Maxent',
    'Saint-Max',
    'Saint-Maur',
    'Saint-Maurice-sur-Moselle',
    'Saint-Maurice-sur-Fessard',
    'Saint-Maurice-sur-Dargoire',
    'Saint-Maurice-sur-Aveyron',
    'Saint-Maurice-sur-Adour',
    'Saint-Maurice-Saint-Germain',
    'Saint-Maurice-Montcouronne',
    "Saint-Maurice-l'Exil",
    'Saint-Maurice-le-Vieil',
    'Saint-Maurice-les-Couches',
    'Saint-Maurice-les-Charencey',
    'Saint-Maurice-les-Brousses',
    'Saint-Maurice-la-Souterraine',
    'Saint-Maurice-la-Clouere',
    'Saint-Maurice-en-Valgodemard',
    'Saint-Maurice-en-Gourgois',
    'Saint-Maurice-Colombier',
    'Saint-Maurice-du-Desert',
    "Saint-Maurice-d'Etelan",
    'Saint-Maurice-des-Lions',
    'Saint-Maurice-de-Lignon',
    'Saint-Maurice-de-Gourdans',
    'Saint-Maurice-de-Beynost',
    'Saint-Maurice',
    'Saint-Maur-des-Fossés',
    'Saint-Mathurin-sur-Loire',
    'Saint-Mathurin',
    'Saint-Mathieu-de-Treviers',
    'Saint-Mathieu',
    'Saint-Masmes',
    'Saint-Mary',
    'Saint-Martory',
    'Saint-Martin-Vesubie',
    'Saint-Martin-Valmeroux',
    'Saint-Martin-sur-Oust',
    'Saint-Martin-sur-le-Pre',
    'Saint-Martin-sous-Montaigu',
    'Saint-Martin-Sainte-Catherine',
    'Saint-Martin-Osmonville',
    'Saint-Martin-Longueau',
    'Saint-Martin-le-Vinoux',
    'Saint-Martin-Lestra',
    'Saint-Martin-les-Melle',
    'Saint-Martin-le-Gaillard',
    'Saint-Martin-le-Chatel',
    'Saint-Martin-le-Beau',
    'Saint-Martin-la-Sauvete',
    'Valdivienne',
    'Saint-Martin-la-Plaine',
    'Saint-Martin-la-Patrouille',
    'Saint-Martin-Lalande',
    'Saint-Martin-Laguepie',
    'Saint-Martin-la-Garenne',
    'Saint-Martin-Lacaussade',
    'Saint-Martin-en-Haut',
    'Saint-Martin-en-Campagne',
    'Saint-Martin-en-Bresse',
    'Saint-Martin-en-Biere',
    'Saint-Martin-du-Vivier',
    'Saint-Martin-du-Vieux-Belleme',
    'Saint-Martin-du-Var',
    'Saint-Martin-du-Tertre',
    "Saint-Martin-d'Uriage",
    'Saint-Martin-du-Mont',
    'Saint-Martin-du-Manoir',
    'Saint-Martin-du-Limet',
    'Saint-Martin-du-Frene',
    'Saint-Martin-du-Fouilloux',
    'Saint-Martin-du-Bois',
    "Saint-Martin-d'Oydes",
    "Saint-Martin-d'Ordon",
    "Saint-Martin-d'Oney",
    "Saint-Martin-d'Hères",
    'Saint-Martin-de-Varreville',
    'Saint-Martin-de-Valgalgues',
    'Saint-Martin-des-Noyers',
    'Saint-Martin-des-Landes',
    'Saint-Martin-de-Sescas',
    'Saint-Martin-de-Seignanx',
    'Saint-Martin-des-Champs',
    'Saint-Martin-des-Besaces',
    'Saint-Martin-de-Sanzay',
    'Saint-Martin-de-Saint-Maixent',
    'Saint-Martin-de-Re',
    'Saint-Martin-de-Queyrieres',
    'Saint-Martin-de-Nigelles',
    'Saint-Martin-de-Mieux',
    'Saint-Martin-de-Macon',
    'Saint-Martin-de-Londres',
    'Saint-Martin-de-la-Porte',
    'Saint-Martin-de-la-Place',
    'Saint-Martin-de-Landelles',
    'Saint-Martin-de-la-Lieue',
    'Saint-Martin-de-la-Brasque',
    'Saint-Martin-de-Fraigneau',
    'Saint-Martin-de-Fontenay',
    'Saint-Martin-de-Crau',
    'Saint-Martin-de-Castillon',
    'Saint-Martin-de-Caralp',
    'Brem-sur-Mer',
    'Saint-Martin-de-Boscherville',
    'Saint-Martin-de-Bernegoue',
    'Saint-Martin-de-Belleville',
    'Saint-Martin-de-Bavel',
    "Saint-Martin-d'Auxigny",
    "Saint-Martin-d'Audouville",
    'Les Aspres',
    "Saint-Martin-d'Armagnac",
    "Saint-Martin-d'Ablois",
    "Saint-Martin-d'Abbat",
    'Saint-Martin-Boulogne',
    'Saint-Martin-Bellevue',
    'Saint-Martin-Belle-Roche',
    'Saint-Martin-au-Laert',
    'Saint-Martial-sur-Ne',
    'Saint-Martial-sur-Isop',
    'Saint-Martial-de-Valette',
    "Saint-Martial-d'Artenset",
    'Saint-Martial',
    'Saint-Mars-la-Reorthe',
    'Saint-Mars-la-Jaille',
    'Saint-Mars-la-Briere',
    'Saint-Mars-du-Desert',
    "Saint-Mars-d'Outille",
    'Saint-Mariens',
    'Saint-Mards-de-Blacarville',
    'Saint-Mard-les-Rouffy',
    'Saint-Mard-de-Vaux',
    'Saint-Mard',
    'Saint-Marc-Jaumegarde',
    'Saint-Marcet',
    'Saint-Marcellin-en-Forez',
    'Saint-Marcellin',
    'Saint-Marcel-les-Valence',
    'Saint-Marcel-les-Annonay',
    "Saint-Marcel-l'Eclaire",
    'Saint-Marcelin-de-Cray',
    'Saint-Marcel',
    "Saint-Marcel-d'Urfe",
    'Saint-Marcel-de-Felines',
    'Saint-Marcel-dArdèche',
    'Saint-Marcel-Bel-Accueil',
    'Saint-Marcan',
    'Saint-Mandrier-sur-Mer',
    'Saint-Mande-sur-Bredoire',
    'Saint-Mande',
    'Saint-Mammes',
    'Saint-Mamet-la-Salvetat',
    'Saint-Mamet',
    'Saint-Mamert-du-Gard',
    'Saint-Malo-du-Bois',
    'Saint-Malo-de-Phily',
    'Saint-Malo-de-Guersac',
    'St-Malo',
    'Saint-Maixme-Hauterive',
    "Saint-Maixent-l'Ecole",
    'Saint-Maixant',
    'Saint-Maigrin',
    'Saint-Magne-de-Castillon',
    'Saint-Maden',
    'Saint-Maclou-la-Briere',
    'Saint-Maclou',
    'Saint-Macaire-en-Mauges',
    'Saint-Macaire',
    'Saint-Lys',
    'Saint-Lyphard',
    'Saint-Lye-la-Foret',
    'Saint-Lye',
    'Saint-Lupicin',
    'Saint-Luperce',
    'Saint-Lunaire',
    'Saint-Lumine-de-Coutais',
    'Saint-Lumine-de-Clisson',
    'Saint-Lumier-en-Champagne',
    'Saint-Lubin-en-Vergonnois',
    'Saint-Lubin-des-Joncherets',
    'Saint-Loup-sur-Semouse',
    "Saint-Loup-d'Ordon",
    'Saint-Loup-de-Varennes',
    'Saint-Loup-Geanges',
    'Saint-Loup-de-Gonois',
    'Saint-Loup-Cammas',
    'Saint-Loup',
    'Saint-Louis-les-Bitche',
    'Saint-Louis-de-Montferrand',
    'Saint-Louis',
    'Saint-Loubes',
    'Saint-Lormel',
    'Saint-Lon-les-Mines',
    "Saint-Lo-d'Ourville",
    'Saint-Lô',
    'Saint-Lizier',
    'Saint-Lieux-les-Lavaur',
    'Saint-Leu-la-Foret',
    "Saint-Leu-d'Esserent",
    'Saint-Leon-sur-Vezere',
    "Saint-Leon-sur-l'Isle",
    'Saint-Leonard-en-Beauce',
    'Saint-Leonard-de-Noblat',
    'Saint-Leonard',
    'Saint-Leger-Triey',
    'Saint-Leger-sur-Vouzance',
    'Saint-Leger-sur-Roanne',
    'Saint-Leger-sur-Dheune',
    'Saint-Leger-sous-la-Bussiere',
    'Saint-Leger-sous-Cholet',
    'Saint-Leger-les-Vignes',
    'Saint-Leger-les-Domart',
    'Saint-Leger-le-Petit',
    'Saint-Leger-le-Gueretois',
    'Saint-Leger-en-Yvelines',
    'Saint-Leger-du-Bourg-Denis',
    'Saint-Leger-du-Bois',
    'Saint-Leger-des-Bois',
    'Saint-Leger-de-Montbrun',
    'Saint-Leger-de-Fougeret',
    'Saint-Leger-de-Balson',
    'Saint-Leger-aux-Bois',
    'Saint-Laurent-sur-Sevre',
    'Saint-Laurent-sur-Othain',
    'Saint-Laurent-sur-Mer',
    'Saint-Laurent-sur-Manoire',
    'Saint-Laurent-sur-Gorre',
    'Saint-Laurent-les-Tours',
    'Saint-Laurent-les-Eglises',
    'Saint-Laurent-la-Vernede',
    'Saint-Laurent-la-Gatine',
    'Saint-Laurent-Medoc',
    'Saint-Laurent-en-Royans',
    'Saint-Laurent-du-Var',
    'Saint-Laurent-du-Pont',
    'Saint-Laurent-du-Pape',
    'Saint-Laurent-en-Grandvaux',
    'Saint-Laurent-du-Bois',
    "Saint-Laurent-d'Olt",
    'Saint-Laurent-de-Vaux',
    'Saint-Laurent-de-Terregatte',
    'Saint-Laurent-des-Vignes',
    'Saint-Laurent-Nouan',
    'Saint-Laurent-des-Bois',
    'Saint-Laurent-des-Autels',
    'St Laurent des Arbres',
    'Saint-Laurent-de-Neste',
    'Saint-Laurent-de-Mure',
    'Saint-Laurent-de-Levezou',
    'Saint-Laurent-de-la-Salanque',
    'Saint-Laurent-de-la-Pree',
    'Saint-Laurent-de-Chamousset',
    'Saint-Laurent-de-Ceris',
    'Sant Llorenc de Cerdans',
    'Saint-Laurent-de-Carnols',
    'Saint-Laurent-de-Belzagot',
    "Saint-Laurent-d'Arce",
    "Saint-Laurent-d'Aigouze",
    'Saint-Laurent-Blangy',
    'Saint-Laurent-sur-Oust',
    'Saint-Laurent',
    'Saint-Laure',
    'Saint-Laon',
    'Saint-Lambert-la-Potherie',
    'Saint-Lambert-et-Mont-de-Jeux',
    'Saint-Lambert-du-Lattay',
    'Saint-Lager-Bressac',
    'Saint-Lager',
    'Saint-Juvat',
    'Saint-Just-Saint-Rambert',
    'Saint-Just-Malmont',
    'Saint-Just-le-Martel',
    'Saint-Just-la-Pendue',
    'Saint-Just-en-Chaussee',
    'Saint-Just-en-Bas',
    'Saint-Just-de-Claix',
    "Saint-Just-d'Avray",
    'Saint-Just-Chaleyssin',
    'Saint-Just',
    'Saint-Just-Luzac',
    'Saint-Junien',
    'Saint-Julien-sur-Reyssouze',
    'Saint-Julien-sur-Calonne',
    'Saint-Julien-Molin-Molette',
    'Saint-Julien-les-Villas',
    'Saint-Julien-les-Metz',
    'Saint-Julien-le-Petit',
    'Saint-Julien-le-Faucon',
    "Saint-Julien-l'Ars",
    'Saint-Julien-en-Saint-Alban',
    'Saint-Julien-en-Genevois',
    'Saint-Julien-en-Born',
    'Saint-Julien-du-Tournel',
    'Saint-Julien-du-Serre',
    'Saint-Julien-du-Sault',
    "Saint-Julien-d'Oddes",
    'Saint-Julien-de-Vouvantes',
    'Saint-Julien-des-Landes',
    'Saint-Julien-de-Raz',
    'Saint-Julien-de-Peyrolas',
    'Saint-Julien-Mont-Denis',
    'Saint-Julien-de-la-Nef',
    'Saint-Julien-de-Lampon',
    'Saint-Julien-de-Coppel',
    'Saint-Julien-de-Concelles',
    'Saint-Julien-de-Chedon',
    'Saint-Julien-de-Cassagnas',
    'Saint-Julien-Chapteuil',
    'Saint-Julien-aux-Bois',
    'Saint-Julien',
    'Saint-Julien-sur-Garonne',
    'Saint-Juery',
    'Saint-Jouvent',
    'Saint-Jouin-Bruneval',
    'Saint-Jouan-des-Guerets',
    'Saint-Joseph-de-Riviere',
    'Saint-Joseph',
    'Saint-Jory',
    'Saint-Jorioz',
    'Saint-Jodard',
    'Saint-Joachim',
    'Saint-Jeoire-Prieure',
    'Saint-Jeoire',
    'Saint-Jean-Trolimon',
    'Saint-Jean-sur-Vilaine',
    'Saint-Jean-sur-Veyle',
    'Saint-Jean-sur-Mayenne',
    'Saint-Jean-sur-Couesnon',
    'Saint-Jean-Saverne',
    'Saint-Jean-Saint-Germain',
    'Saint-Jean-Rohrbach',
    'Sant Joan de Pladecorts',
    'Saint-Jean-Pied-de-Port',
    'Saint-Jeannet',
    'Saint-Jean-le-Thomas',
    'Saint-Jean-les-Longuyon',
    'Saint-Jean-les-Buzy',
    'Saint-Jean-le-Centenier',
    'Saint-Jean-le-Blanc',
    'Saint-Jean-Lasseille',
    'Saint-Jean-la-Poterie',
    'Saint-Jean-Lachalm',
    'Saint-Jean-en-Val',
    'Saint-Jean-en-Royans',
    'Saint-Jean-du-Gard',
    'Saint-Jean-du-Doigt',
    "Saint-Jean-d'Illac",
    "Saint-Jean-d'Heurs",
    'Saint-Jean-de-Verges',
    'Saint-Jean-de-Vedas',
    'Saint-Jean-de-Trezy',
    'Saint-Jean-de-Touslas',
    'Saint-Jean-de-Thurigneux',
    'Saint-Jean-de-Thouars',
    'Saint-Jean-de-Tholome',
    'Saint-Jean-de-Soudain',
    'Saint-Jean-de-Linieres',
    'Saint-Jean-de-Sixt',
    'Saint-Jean-des-Champs',
    'Saint-Jean-de-Savigny',
    'Saint-Jean-de-Sauves',
    'Saint-Jean-de-Paracol',
    'Saint-Jean-de-Niost',
    'Saint-Jean-de-Muzols',
    'Saint-Jean-de-Monts',
    'Saint-Jean-de-Moirans',
    'Saint-Jean-de-Maurienne',
    'Saint-Jean-de-Marsacq',
    'Saint-Jean-de-Luz',
    'Saint-Jean-de-Losne',
    'Saint-Jean-de-Liversay',
    'Saint-Jean-de-la-Ruelle',
    'Saint-Jean-de-la-Motte',
    'Saint-Jean-de-Gonville',
    'Saint-Jean-de-Fos',
    'Saint-Jean-de-Folleville',
    'Saint-Jean-de-Daye',
    'Saint-Jean-de-Crieulon',
    'Saint-Jean-de-Braye',
    'Saint-Jean-de-Bournay',
    'Saint-Jean-de-Bonneval',
    'Saint-Jean-de-Boiseau',
    'Saint-Jean-de-Blaignac',
    'Saint-Jean-de-Beugne',
    'Saint-Jean-de-Belleville',
    "Saint-Jean-d'Avelanne",
    "Saint-Jean-d'Aulps",
    "Saint-Jean-d'Arves",
    "Saint-Jean-d'Ardieres",
    "Saint-Jean-d'Angely",
    'Saint-Jean-Chambre',
    'Saint-Jean-Brevelay',
    'Saint-Jean-Bonnefonds',
    'Saint-Jean-aux-Bois',
    'Saint-Jean',
    'Saint-Jans-Cappel',
    'Saint-Jammes',
    'Saint-James',
    'Saint-Jacut-les-Pins',
    'Saint-Jacut-de-la-Mer',
    'Saint-Jacques-sur-Darnetal',
    'Saint-Jacques-de-Thouars',
    'Saint-Jacques-des-Arrets',
    'Saint-Jacques-de-Nehou',
    'Saint-Jacques-de-la-Lande',
    'Saint-Izaire',
    'Saint-Ismier',
    'Saint-Inglevert',
    'Saintines',
    'Saint-Igeaux',
    'Saint-Hymer',
    'Saint-Honore-les-Bains',
    'Saint-Honore',
    'Saint-Hippolyte-du-Fort',
    'Saint-Hippolyte',
    'Saint-Hilaire-sur-Puiseaux',
    'Saint-Hilaire-de-Lusignan',
    'Saint-Hilaire-sur-Erre',
    'Saint-Hilaire-Saint-Mesmin',
    'Saint-Hilaire-Saint-Florent',
    'Saint-Hilaire-Peyroux',
    'Saint-Hilaire-lez-Cambrai',
    'Saint-Hilaire-le-Vouhis',
    'Saint-Hilaire-les-Places',
    'Saint-Hilaire-les-Monges',
    'Saint-Hilaire-les-Andresis',
    'Saint-Hilaire-le-Petit',
    'Saint-Hilaire-la-Palud',
    'Saint-Hilaire-Fontaine',
    'Saint-Hilaire-du-Rosier',
    'Saint-Hilaire-du-Maine',
    'Saint-Hilaire-du-Harcouet',
    "Saint-Hilaire-d'Ozilhan",
    'Saint-Hilaire-de-Voust',
    'Saint-Hilaire-de-Villefranche',
    'Saint-Hilaire-de-Talmont',
    'Saint-Hilaire-des-Loges',
    'Saint-Hilaire-de-Riez',
    'Saint-Hilaire-de-Loulay',
    'Saint-Hilaire-de-la-Cote',
    'Saint-Hilaire-de-Gondilly',
    'Saint-Hilaire-de-Court',
    'Saint-Hilaire-de-Clisson',
    'Saint-Hilaire-de-Chaleons',
    'Saint-Hilaire-de-Brethmas',
    'Saint-Hilaire-de-Brens',
    'Saint-Hilaire-de-Beauvoir',
    'Saint-Hilaire-Bonneval',
    'Saint-Hilaire',
    'Saint-Hernin',
    'Saint-Herblon',
    'Saint-Herblain',
    'Saint-Helen',
    'Saint-Heand',
    'Saint-Haon-le-Chatel',
    'Saint-Guinoux',
    'Saint-Guen',
    'Saint-Gregoire',
    'Saint-Gratien',
    'Saint-Gorgon',
    'Saint-Gonnery',
    'Saint-Gondon',
    'Saint-Gobain',
    'Saint-Goazec',
    "Saint-Girons-d'Aiguevives",
    'Saint-Girons',
    'Saint-Gingolph',
    'Saint-Gilles-Croix-de-Vie',
    'Saint-Gilles',
    'Saint-Gildas-des-Bois',
    'Saint-Gildas-de-Rhuys',
    'Saint-Gery',
    'Saint-Gervais-sur-Roubion',
    'Saint-Gervais-sur-Mare',
    'Saint-Gervais-les-Bains',
    'Saint-Gervais-la-Foret',
    'Saint-Gervais-en-Belin',
    "Saint-Gervais-d'Auvergne",
    'Saint-Gervais',
    'Saint-Gerons',
    'Saint-Germer-de-Fly',
    'Saint-Germain-Village',
    'Saint-Germain-sur-Sarthe',
    'Saint-Germain-sur-Rhone',
    'Saint-Germain-sur-Moine',
    'Saint-Germain-sur-Ille',
    'Saint-Germain-sur-Ay',
    'Saint-Germain-sur-Avre',
    'Saint-Germainmont',
    'Saint-Germain-lHerm',
    'Saint-Germain-le-Vasson',
    'Saint-Germain-les-Senailly',
    'Saint-Germain-Lespinasse',
    'Saint-Germain-les-Corbeil',
    'Saint-Germain-les-Belles',
    'Saint-Germain-les-Arpajon',
    'Saint-Germain-les-Arlay',
    'Saint-Germain-Lembron',
    'Saint-Germain-le-Guillaume',
    'Saint-Germain-le-Gaillard',
    'Saint-Germain-la-Ville',
    'Saint-Germain-Laval',
    'Saint-Germain-Laprade',
    "Saint-Germain-l'Aiguiller",
    'Saint-Germain-en-Laye',
    'Saint-Germain-en-Cogles',
    'Saint-Germain-du-Teil',
    'Saint-Germain-du-Puy',
    'Saint-Germain-du-Puch',
    'Saint-Germain-du-Plain',
    'Saint-Germain-du-Pinel',
    'Saint-Germain-du-Corbeis',
    'Saint-Germain-du-Bois',
    'Saint-Germain-de-Tournebut',
    "Saint-Germain-d'Esteuil",
    'Saint-Germain-des-Pres',
    'Saint-Germain-des-Fosses',
    'Saint-Germain-des-Angles',
    'Saint-Germain-de-Pasquier',
    'Saint-Germain-de-Lusignan',
    'Saint-Germain-de-la-Riviere',
    'Saint-Germain-de-la-Grange',
    'Saint-Germain-de-la-Coudre',
    'Saint-Germain-de-Calberte',
    "Saint-Germain-d'Arce",
    "Saint-Germain-au-Mont-d'Or",
    'Saint-Germain',
    'Saint-Gereon',
    'Saint-Gerand-le-Puy',
    'Saint-Geours-de-Maremne',
    "Saint-Geours-d'Auribat",
    'Saints-Geosmes',
    'Saint-Georges-sur-Moulon',
    'Saint-Georges-sur-Loire',
    'Saint-Georges-sur-la-Pree',
    "Saint-Georges-sur-l'Aa",
    'Saint-Georges-sur-Eure',
    'Saint-Georges-sur-Erve',
    'Saint-Georges-sur-Cher',
    'Saint-Georges-sur-Baulche',
    'Saint-Georges-sur-Allier',
    'Saint-Georges-Nigremont',
    'Saint-Georges-Motel',
    'Saint-Georges-Montcocq',
    'Saint-Georges-les-Bains',
    'Saint-Georges-les-Baillargeaux',
    'Saint-Georges-le-Gaultier',
    'Saint-Georges-le-Flechard',
    'Saint-Georges-Haute-Ville',
    'Saint-Georges-en-Couzan',
    'Saint-Georges-du-Vievre',
    'Saint-Georges-dOrques',
    "Saint-Georges-d'Oleron",
    "Saint-Georges-d'Esperanche",
    'Saint-Georges-des-Groseillers',
    'Saint-Georges-de-Rouelley',
    'Saint-Georges-de-Rex',
    'Saint-Georges-de-Reneins',
    'Saint-Georges-de-Reintembault',
    'Saint-Georges-de-Pointindoux',
    'Saint-Georges-de-Montaigu',
    'Saint-Georges-de-Mons',
    'Saint-Georges-de-Luzencon',
    "Saint-Georges-d'Elle",
    'Saint-Georges-de-Didonne',
    'Saint-Georges-Antignac',
    'Saint-Georges-de-Commiers',
    'Saint-Georges-de-Chesne',
    'Saint-Georges-de-Bohon',
    'Saint-Georges-Blancaneix',
    'Saint-Georges-Buttavent',
    'Saint-Georges',
    'Saint-Geoirs',
    'Saint-Geoire-en-Valdaine',
    'Saint-Genou',
    'Saint-Genix-sur-Guiers',
    'Saint-Genis-Pouilly',
    'Saint-Genis-les-Ollieres',
    'Saint-Genis-Laval',
    "Saint-Genis-d'Hiersac",
    'Saint-Genis-de-Saintonge',
    'Saint-Genis-des-Fontaines',
    "Saint-Geniez-d'Olt",
    'Saint-Genies-des-Mourgues',
    'Saint-Genies-de-Comolas',
    'Saint-Genies-Bellevue',
    'Saint-Genies',
    'Saint-Gengoux-le-National',
    'Saint-Genest-Malifaux',
    'Saint-Genest-Lerpt',
    'Saint-Genest-de-Beauzon',
    "Saint-Genest-d'Ambiere",
    'Saint-Genest',
    'Saint-Genes-de-Fronsac',
    'Saint-Genes-Champanelle',
    'Saint-Gence',
    'Saint-Genard',
    'Saint-Gely-du-Fesc',
    'Saint-Gelais',
    'Saint-Gein',
    'Saint-Gauzens',
    'Saint-Gaultier',
    'Saint-Gaudens',
    'Saint-Gatien-des-Bois',
    'Saint-Ganton',
    'Saint-Gal-sur-Sioule',
    'Saint-Galmier',
    'Saint-Fuscien',
    'Saint-Fulgent',
    'Saint-Front-sur-Lemance',
    'Saint-Front-la-Riviere',
    'Saint-Front-de-Pradoux',
    'Saint-Frejoux',
    'Saint-Fregant',
    'Saint-Francois',
    'Saint-Fraimbault-de-Prieres',
    'Saint-Fort-sur-Gironde',
    'Saint-Forgeux',
    'Saint-Forget',
    'Saint-Fons',
    'Saint-Folquin',
    'Saint-Flour',
    'Saint-Florent-sur-Cher',
    'Saint-Florent-sur-Auzonnet',
    'Saint-Florent-le-Vieil',
    'Saint-Florent-des-Bois',
    'Saint-Florent',
    'Saint-Firmin-sur-Loire',
    'Saint-Firmin',
    'Saint-Firmin-des-Pres',
    'Saint-Firmin-des-Bois',
    'Saint-Fiel',
    'Saint-Fiacre-sur-Maine',
    'Saint-Ferreol-Trente-Pas',
    'Saint-Ferreol-des-Cotes',
    "Saint-Ferreol-d'Auroure",
    'Saint-Ferme',
    'Saint-Felix-Lauragais',
    'Saint-Felix-de-Villadeix',
    'Saint-Felix-de-Sorgues',
    'Saint-Felix-de-Lodez',
    'Saint-Felix',
    "Saint-Feliu-d'Amont",
    'Saint-Felicien',
    'Saint-Faust',
    'Saint-Fargeol',
    'Saint-Fargeau-Ponthierry',
    'Saint-Fargeau',
    'Saint-Exupery',
    'Sainte-Verge',
    'Saint-Evarzec',
    'Sainte-Valiere',
    'Saint-Eutrope-de-Born',
    'Sainte-Tulle',
    'Saint-Etienne-sur-Suippe',
    'Saint-Etienne-sur-Reyssouze',
    'Saint-Etienne-sur-Chalaronne',
    'Saint-Etienne-les-Remiremont',
    'Saint-Etienne-le-Molard',
    "Saint-Etienne-l'Allier",
    'Saint-Etienne-la-Cigogne',
    'Le Devoluy',
    'Saint-Etienne-en-Cogles',
    'Saint-Etienne-en-Bresse',
    'Saint-Etienne-du-Vigan',
    'Saint-Étienne-du-Rouvray',
    "Saint-Etienne-du-Gue-de-l'Isle",
    'Saint-Etienne-du-Gres',
    'Saint-Etienne-du-Bois',
    'Saint-Etienne-de-Valoux',
    'Saint-Etienne-de-Tulmont',
    'Saint-Etienne-de-Tinee',
    'Saint-Etienne-des-Oullieres',
    'Saint-Etienne-de-Saint-Geoirs',
    'Saint-Etienne-de-Montluc',
    'Saint-Etienne-de-Fursac',
    'Saint-Etienne-de-Fontbellon',
    'Saint-Etienne-de-Crossey',
    'Corcoue-sur-Logne',
    'Saint-Etienne-de-Chigny',
    'Saint-Etienne-de-Brillouet',
    'Saint-Etienne-au-Mont',
    'Saint-Etienne',
    'Sainte-Terre',
    'Sainte-Suzanne',
    'Saint-Esteve',
    'Saint-Estephe',
    'Sainte-Soulle',
    'Sainte-Solange',
    'Saintes-Maries-de-la-Mer',
    'Sainte-Sigolene',
    'Sainte-Severe-sur-Indre',
    'Sainte-Seve',
    'Sainte-Savine',
    'Sainte-Sabine-sur-Longeve',
    'Saintes',
    'Saint-Erme-Outre-et-Ramecourt',
    'Sainte-Reine-de-Bretagne',
    'Sainte-Reine',
    'Saint-Erblon',
    'Sainte-Radegonde-des-Noyers',
    'Sainte-Pole',
    'Sainte-Pazanne',
    'Sainte-Paule',
    'Saint-Epain',
    'Sainte-Osmane',
    'Sainte-Opportune-du-Bosc',
    'Sainte-Olive',
    'Sainte-Neomaye',
    'Sainte-Nathalene',
    'Saint-Emilion',
    'Sainte-Mesme',
    'Sainte-Mere-Eglise',
    'Sainte-Menehould',
    'Sainte-Maxime',
    'Sainte-Maure-de-Touraine',
    'Sainte-Marthe',
    'Sainte-Marie',
    'Sainte-Marie-du-Mont',
    'Sainte-Marie-du-Bois',
    'Sainte-Marie-des-Champs',
    'Sainte-Marie-de-Re',
    'Sainte-Marie-de-Gosse',
    'Sainte-Marie-Cappel',
    'Sainte-Marie-aux-Mines',
    'Sainte-Marie-aux-Chenes',
    'Sainte-Marguerite-sur-Duclair',
    'Sainte-Marguerite-de-Carrouges',
    'Sainte-Marguerite-sur-Mer',
    'Sainte-Marguerite',
    'Sainte-Luce-sur-Loire',
    'Saint-Eloy-les-Mines',
    'Saint-Eloy-de-Gy',
    'Saint-Eloi',
    'Sainte-Lizaigne',
    'Sainte-Livrade-sur-Lot',
    'Saint-Eliph',
    'Sainte-Lheurine',
    'Sainte-Julie',
    'Sainte-Jamme-sur-Sarthe',
    'Sainte-Honorine-la-Chardonne',
    'Sainte-Honorine-du-Fay',
    'Sainte-Hermine',
    'Sainte-Helene-sur-Isere',
    'Sainte-Helene-du-Lac',
    'Sainte-Helene',
    'Sainte-Genevieve-sur-Argence',
    'Sainte-Genevieve-les-Gasny',
    'Sainte-Geneviève-des-Bois',
    'Sainte-Genevieve-des-Bois',
    'Sainte-Genevieve',
    'Sainte-Gemmes-sur-Loire',
    'Sainte-Gemme-la-Plaine',
    'Sainte-Gemme',
    'Sainte-Foy-les-Lyon',
    "Sainte-Foy-l'Argentiere",
    'Sainte-Foy-la-Grande',
    'Sainte-Foy-de-Peyrolieres',
    "Sainte-Foy-d'Aigrefeuille",
    'Sainte-Foy',
    'Sainte-Fortunade',
    'Sainte-Florine',
    'Sainte-Florence',
    'Sainte-Flaive-des-Loups',
    'Sainte-Feyre-la-Montagne',
    'Sainte-Euphemie',
    'Sainte-Eulalie-en-Born',
    "Sainte-Eulalie-d'Olt",
    'Sainte-Eulalie',
    'Saint Egreve',
    'Sainte-Eanne',
    'Sainte-Croix-sur-Buchy',
    'Sainte-Croix-Grand-Tonne',
    'Sainte-Croix-en-Plaine',
    'Sainte-Croix-Volvestre',
    'Sainte-Croix-de-Caderle',
    'Sainte-Croix-aux-Mines',
    'Sainte-Croix',
    'Sainte-Consorce',
    'Sainte-Colombe-sur-Gand',
    'Sainte-Colombe-pres-Vernon',
    'Sainte-Colombe-la-Commanderie',
    'Sainte-Colombe-de-Peyre',
    'Sainte-Colombe',
    'Sainte-Colombe-des-Bois',
    "Sainte-Christie-d'Armagnac",
    'Sainte-Christie',
    'Sainte-Cecile-les-Vignes',
    "Sainte-Cecile-d'Andorge",
    'Sainte-Cecile',
    'Sainte-Catherine',
    'Sainte-Catherine-de-Fierbois',
    'Sainte-Brigitte',
    'Saint-Ebremond-de-Bonfosse',
    'Sainte-Bazeille',
    'Sainte-Barbe',
    'Sainte-Aulde',
    'Sainte-Anne-sur-Vilaine',
    'Sainte-Anne-sur-Brivet',
    "Sainte-Anne-d'Auray",
    'Sainte-Anne',
    'Sainte-Anastasie-sur-Issole',
    'Sainte-Adresse',
    'Saint-Drezery',
    'Saint-Doulchard',
    "Saint-Donat-sur-l'Herbasse",
    'Saint-Donan',
    'Saint-Domineuc',
    'Saint-Dolay',
    'Saint-Dizier-Leyrenne',
    'Saint-Dizier-les-Domaines',
    'Saint-Dizier',
    'Saint-Dizant-du-Gua',
    'Saint-Divy',
    'Saint-Dionisy',
    'Saint-Die-des-Vosges',
    'Saint-Didier-sur-Rochefort',
    'Saint-Didier-sur-Chalaronne',
    'Saint-Didier-sous-Riverie',
    'Saint-Didier-sous-Aubenas',
    'Saint-Didier-en-Velay',
    'Saint-Didier-des-Bois',
    'Saint-Didier-de-la-Tour',
    'Saint-Didier-de-Formans',
    "Saint-Didier-d'Aussiat",
    "Saint-Didier-au-Mont-d'Or",
    'Saint-Didier',
    'Saint-Dezery',
    'Saint-Desirat',
    'Saint-Desir',
    'Saint-Desert',
    'Saint-Denis-sur-Scie',
    'Saint-Denis-sur-Sarthon',
    'Saint-Denis-le-Thiboult',
    'Saint-Denis-les-Rebais',
    'Saint-Denis-les-Ponts',
    'Saint-Denis-les-Martel',
    'Saint-Denis-les-Bourg',
    'Saint-Denis-la-Chevasse',
    'Saint-Denis-en-Val',
    'Saint-Denis-du-Payre',
    "Saint-Denis-d'Oleron",
    'Saint-Denis-de-Pile',
    'Saint-Denis-de-Palin',
    'Saint-Denis-de-Mere',
    'Saint-Denis-de-lHôtel',
    'Saint-Denis-de-Jouhet',
    'Saint-Denis-de-Gastines',
    'Saint-Denis-dAnjou',
    "Saint-Denis-d'Aclon",
    'Saint-Denis',
    'Saint-Dalmas-de-Tende',
    'Saint-Cyr-sur-Morin',
    'Saint-Cyr-sur-Mer',
    'Saint-Cyr-sur-Menthon',
    'Saint-Cyr-sur-Loire',
    'Saint-Cyr-sur-le-Rhone',
    'Saint-Cyr-sous-Dourdan',
    'Saint-Cyr-les-Vignes',
    "Saint-Cyr-l'École",
    'Saint-Cyr-la-Riviere',
    'Saint-Cyr-en-Val',
    'Saint-Cyr-en-Bourg',
    'Saint-Cyr-du-Bailleul',
    'Saint-Cyr-des-Gats',
    "Saint-Cyr-au-Mont-d'Or",
    'Saint-Cyr',
    'Saint-Cyprien-Plage',
    'Saint-Cyprien',
    'Saint-Cybardeaux',
    'Saint-Cricq-du-Gave',
    'Saint-Crespin-sur-Moine',
    'Saint-Crepin-Ibouvillers',
    'Saint-Crepin',
    'Saint-Coutant-le-Grand',
    "Saint-Couat-d'Aude",
    'Saint-Cosme-en-Vairais',
    'Saint-Cornier-des-Landes',
    'Saint-Corneille',
    'Saint-Contest',
    'Saint-Connec',
    'Saint-Congard',
    "Saint-Come-d'Olt",
    'Saint-Come-de-Fresne',
    'Saint-Cloud',
    'Saint-Clement-les-Places',
    'Voulmentin',
    'Saint-Clement-des-Levees',
    'Saint-Clement-des-Baleines',
    'Saint-Clement-de-la-Place',
    'Saint-Clement-Rancoudray',
    'Saint-Clement',
    'Saint-Clement-de-Riviere',
    'Saint-Claude-de-Diray',
    'Saint-Claude',
    'Saint-Claud',
    'Saint-Clar-de-Riviere',
    'Saint-Clar',
    "Saint-Clair-sur-l'Elle",
    'Saint-Clair-sur-Epte',
    'Saint-Clair-du-Rhone',
    'Saint-Clair-de-la-Tour',
    'Saint-Clair-de-Halouze',
    'Saint-Clair',
    'Saint-Cirq-Lapopie',
    'Saint-Cirgues-de-Malbert',
    'Saint-Cirgues-de-Jordanne',
    'Saint-Cirgue',
    'Saint-Ciers-sur-Gironde',
    'Saint-Ciers-de-Canesse',
    "Saint-Ciers-d'Abzac",
    'Saint-Christophe-Vallon',
    'Saint-Christophe-sur-le-Nais',
    'Saint-Christophe-sur-Guiers',
    'Saint-Christophe-les-Gorges',
    'Saint-Christophe-du-Ligneron',
    'Saint-Christophe-du-Bois',
    'Saint-Christophe-des-Bardes',
    'Saint-Christophe',
    'Saint-Christoly-Medoc',
    'Saint-Christol-les-Ales',
    'Saint-Christol',
    'Saint-Christo-en-Jarez',
    'Saint-Chinian',
    'Saint-Cheron-du-Chemin',
    'Saint-Cheron',
    "Saint-Chely-d'Aubrac",
    "Saint-Chely-d'Apcher",
    'Saint-Chef',
    'Saint-Chaptes',
    'Saint-Chamond',
    'Saint-Chamassy',
    'St Chamas',
    'Saint-Chaffrey',
    'Saint-Cezert',
    'Saint-Cezaire-sur-Siagne',
    'Saint-Cesaire-de-Gauzignan',
    'Saint-Cernin-de-Labarde',
    'Saint-Cernin',
    'Saint-Cergues',
    'Saint-Cere',
    'Saint-Cenere',
    'Saint-Celerin',
    'Saint-Castin',
    'Saint-Cast-le-Guildo',
    'Saint-Cassien',
    'Saint-Carreuc',
    'Saint-Carne',
    'Saint-Caprais-de-Bordeaux',
    'Saint-Cannat',
    'Saint-Calez-en-Saosnois',
    'Saint-Calais-du-Desert',
    'Saint-Calais',
    'Saint-Bueil',
    'Saint-Broingt-les-Fosses',
    'Saint-Brisson-sur-Loire',
    'Saint-Bris-le-Vineux',
    'Saint-Brieuc',
    'Saint-Brice-sur-Vienne',
    'Saint-Brice-sous-Foret',
    'Saint-Brice-Courcelles',
    'Saint-Brice-en-Cogles',
    'Saint-Briac-sur-Mer',
    'Saint-Bresson',
    'Saint-Bres',
    'Saint-Brandan',
    'Saint-Branchs',
    'Saint-Bouize',
    'Saint-Bon-Tarentaise',
    'Saint-Bonnet-Troncais',
    'Saint-Bonnet-pres-Riom',
    'Saint-Bonnet-les-Oules',
    'Saint-Bonnet-le-Courreau',
    'Saint-Bonnet-du-Gard',
    'Saint-Bonnet-de-Vieille-Vigne',
    'Saint-Bonnet-des-Bruyeres',
    'Saint-Bonnet-de-Mure',
    'Saint-Bonnet-de-Joux',
    'Saint-Bonnet-Avalouze',
    'Saint-Bonnet',
    'Saint-Bonnet-en-Champsaur',
    'Saint-Bomer-les-Forges',
    'Saint-Bohaire',
    'Saint-Boes',
    'Saint-Blimont',
    'Saint-Blaise-du-Buis',
    'Saint-Blaise',
    'Saint-Biez-en-Belin',
    'Saint-Bertrand-de-Comminges',
    'Saint-Berthevin',
    'Saint-Berthevin-la-Tanniere',
    'Saint-Beron',
    'Saint-Bernard',
    'Saint-Berain-sous-Sanvignes',
    'Saint-Berain',
    'Saint-Benoit-sur-Seine',
    'Saint-Benoit-sur-Loire',
    'Saint-Benoit-du-Sault',
    'Saint-Benoit-des-Ondes',
    'Saint-Benoit-de-Carmaux',
    'Saint-Benoit',
    'Saint-Benoist-sur-Vanne',
    'Saint-Benoist-sur-Mer',
    'Saint-Benin-dAzy',
    'Saint-Benezet',
    'Saint-Beauzire',
    'Saint-Beauzely',
    'Saint-Beat',
    'Saint-Bauzely',
    'Saint-Baudille-de-la-Tour',
    'Saint-Baudelle',
    'Saint-Barthelemy-le-Meil',
    'Saint-Barthelemy-de-Vals',
    "Saint-Barthelemy-d'Anjou",
    'Saint-Barthelemy',
    'Saint-Barnabe',
    'Saint-Barbant',
    'Saint-Baldoph',
    'Saint-Aygulf',
    'Saint-Ay',
    'Saint-Avre',
    'Saint-Avold',
    'Saint-Avit-Senieur',
    'Saint-Avit-Saint-Nazaire',
    'Saint-Avit',
    'Saint-Avertin',
    'Saint-Ave',
    'Saint-Aunix-Lengros',
    'Saint-Aunes',
    'Saint-Aulaire',
    'Saint-Augustin-des-Bois',
    'Saint-Augustin',
    'Saint-Aubin-sur-Yonne',
    'Saint-Aubin-sur-Quillebeuf',
    'Saint-Aubin-sur-Mer',
    'Saint-Aubin-sur-Gaillon',
    'Saint-Aubin-sur-Algot',
    'Saint-Aubin-le-Vertueux',
    'Saint-Aubin-les-Elbeuf',
    'Saint-Aubin-le-Monial',
    'Saint-Aubin-le-Cloud',
    'Saint-Aubin-en-Charollais',
    'Saint-Aubin-en-Bray',
    'Saint-Aubin-du-Plain',
    'Saint-Aubin-du-Perron',
    'Saint-Aubin-du-Cormier',
    'Saint-Aubin-de-Terregatte',
    'Saint-Aubin-des-Preaux',
    'Saint-Aubin-des-Landes',
    'Saint-Aubin-des-Chateaux',
    'Saint-Aubin-des-Bois',
    'Saint-Aubin-de-Medoc',
    'Saint-Aubin-de-Luigne',
    "Saint-Aubin-d'Ecrosville",
    'Saint-Aubin-de-Cretot',
    'Saint-Aubin-de-Blaye',
    "Saint-Aubin-d'Aubigne",
    "Saint-Aubin-d'Arquenay",
    'Saint-Aubin-Celloville',
    'Saint-Aubin',
    'Saint-Auban',
    'Saint-Astier',
    'Saint-Arnoult-en-Yvelines',
    'Saint-Arnoult-des-Bois',
    'Saint-Arnoult',
    'Saint-Armel',
    'Saint-Araille',
    'Saint-Aquilin',
    'Saint-Appolinaire',
    'Saint-Apollinaire-de-Rias',
    'Saint-Apollinaire',
    'Saint-Aout',
    'Saint-Antonin-Noble-Val',
    'Saint-Antoine-la-Foret',
    'Saint-Antoine-du-Rocher',
    'Saint-Antoine-de-Breuilh',
    'Saint-Antoine',
    'Saint-Antoine-lAbbaye',
    'Saint-Antheme',
    'Saint-Androny',
    'Val-de-Fier',
    'Saint-Andre-Treize-Voies',
    'Saint-Andre-sur-Vieux-Jonc',
    'Saint-Andre-sur-Cailly',
    'Saint-Andre-les-Vergers',
    'Saint-Andre-les-Alpes',
    'Saint-Andre-le-Gaz',
    'Saint-Andre-le-Desert',
    'Saint-Andre-le-Bouchoux',
    "Saint-Andre-Goule-d'Oie",
    'Saint-Andre-et-Appelles',
    "Saint-Andre-d'Olerargues",
    "Saint-Andre-d'Huiriat",
    "Saint-Andre-d'Hebertot",
    'Saint-Andre-de-Valborgne',
    'Saint-Andre-de-Seignanx',
    'Saint-Andre-des-Eaux',
    'Saint-Andre-de-Sangonis',
    'Saint-Andre-de-Rosans',
    'Saint-Andre-de-Roquepertuis',
    'Saint-Andre-de-Roquelongue',
    "Saint-Andre-de-l'Eure",
    'Saint-Andre-de-Lancize',
    'Saint-Andre-de-la-Marche',
    'Saint-Andre-de-Cubzac',
    'Saint-Andre-de-Corcy',
    "Saint-Andre-d'Apchon",
    'Saint-André-dAllas',
    'Saint-Andre-lez-Lille',
    'Saint-Andre',
    'Saint-Andre-de-la-Roche',
    'Sant Andreu de Sureda',
    'Saint-Andiol',
    'Saint-Andeol-le-Chateau',
    'Saint-Andeol-de-Berg',
    'Saint-Amour',
    'Saint-Ame',
    'Saint-Ambroix',
    'Saint-Amarin',
    'Saint-Amant-Tallende',
    'Saint-Amant-Roche-Savine',
    'Saint-Amant-de-Nouere',
    'Saint-Amant-de-Boixe',
    'Saint-Amant-de-Montmoreau',
    'Saint-Amans-Soult',
    'Saint-Amans-des-Cots',
    'Saint-Amand-sur-Sevre',
    'Saint-Amand-sur-Fion',
    'Saint-Amand-Montrond',
    'Saint-Amand-les-Eaux',
    'Saint-Amandin',
    'Saint-Amand-en-Puisaye',
    'Saint-Amand-Longpre',
    'Saint-Amand-des-Hautes-Terres',
    'Saint-Amand',
    'Saint-Amadou',
    'Saint-Allouestre',
    'Saint-Alban-sur-Limagnole',
    'Saint-Alban-Auriolles',
    'Saint-Alban-les-Eaux',
    'Saint-Alban-de-Roche',
    "Saint-Alban-d'Ay",
    'Saint-Alban',
    'Saint-Alban-Leysse',
    'Saint-Albain',
    'Saint-Aigulin',
    'Saint-Aignan-sur-Roe',
    'Saint-Aignan-le-Jaillard',
    'Saint-Aignan-Grandlieu',
    'Saint-Aignan-de-Cramesnil',
    'Saint-Aignan',
    'Saint-Agreve',
    'Saint-Agnant-de-Versillat',
    'Saint-Agnant',
    'Saint-Agnan-en-Vercors',
    'Saint-Agnan',
    'Saint-Agathon',
    'Saint-Affrique',
    'Saint-Affrique-les-Montagnes',
    'Sains-les-Marquion',
    'Sains-en-Gohelle',
    'Sains-en-Amienois',
    'Sains-du-Nord',
    'Sainpuits',
    'Sainghin-en-Weppes',
    'Sainghin-en-Melantois',
    'Sain-Bel',
    'Sail-sous-Couzan',
    'Sailly-sur-la-Lys',
    'Sailly-Saillisel',
    'Sailly-Labourse',
    'Sailly-Flibeaucourt',
    'Saillenard',
    'Saillat-sur-Vienne',
    'Saillans',
    'Saillagouse',
    'Sailhan',
    'Saignon',
    'Saigneville',
    'Saignes',
    'Sahurs',
    'Sagy',
    'Sagries',
    'Sagone',
    'Saffres',
    'Saffre',
    'Saessolsheim',
    'Sadroc',
    'Sadirac',
    'Sadillac',
    'Sacy-le-Grand',
    'Sacy',
    'Sacquenville',
    'Sacquenay',
    'Saconin-et-Breuil',
    'Saclay',
    'Saclas',
    'Sachin',
    'Sache',
    'Sacey',
    'Sabres',
    'Sabran',
    'Sabonneres',
    'Sablons',
    'Sablonnieres',
    'Sablonceaux',
    'Sablet',
    'Sable-sur-Sarthe',
    'Sabazan',
    'Sabarat',
    'Sabaillan',
    'Saales',
    'Saacy-sur-Marne',
    'Rye',
    'Ry',
    'Ruyaulcourt',
    'Ruy',
    'Ruvigny',
    'Rustroff',
    'Rustrel',
    'Russy',
    'Russ',
    'Rurange-les-Thionville',
    'Rupt-sur-Moselle',
    'Rupt',
    'Ruppes',
    'Ruoms',
    'Rungis',
    'Ruminghem',
    'Rumilly-en-Cambresis',
    'Rumilly',
    'Rumesnil',
    'Rumegies',
    'Rumaucourt',
    'Rully',
    'Ruitz',
    'Ruynes-en-Margeride',
    'Ruille-sur-Loir',
    'Rugles',
    'Ruffigne',
    'Ruffieux',
    'Ruffiac',
    'Ruffey-les-Beaune',
    'Ruffec',
    'Ruelle-sur-Touvre',
    'Ruelisheim',
    'Rueil-Malmaison',
    'Rue',
    'Rubempre',
    'Rubelles',
    'Ruaudin',
    'Roz-sur-Couesnon',
    'Rozoy-sur-Serre',
    'Roz-Landrieux',
    'Rozieres-en-Beauce',
    'Rozier-en-Donzy',
    "Rozier-Cotes-d'Aurec",
    'Rozerieulles',
    'Rozelieures',
    'Rozay-en-Brie',
    'Royville',
    'Royon',
    'Roynac',
    'Royere-de-Vassiviere',
    'Royer',
    'Roye',
    'Roybon',
    'Roy-Boissy',
    'Royaumeix',
    'Royat',
    'Royan',
    'Roville-devant-Bayon',
    'Rouziers-de-Touraine',
    'Rouy-le-Grand',
    'Rouy',
    'Rouxmesnil-Bouteilles',
    'Rouxeville',
    'Rouvroy-en-Santerre',
    'Rouvroy',
    'Rouvrois-sur-Meuse',
    'Rouvres-sous-Meilly',
    'Rouvres-en-Woevre',
    'Rouvres',
    'Rouvray-Saint-Florentin',
    'Rouvray-Saint-Denis',
    'Rouvray',
    'Rouville',
    'Rouvignies',
    'Routot',
    'Routes',
    'Routelle',
    'Rousson',
    'Roussines',
    'Roussillon-en-Morvan',
    'Roussillon',
    'Rousset',
    'Rousses',
    'Roussent',
    'Roussennac',
    'Roussay',
    'Roussas',
    'Roussac',
    'Rousies',
    'Roupeldange',
    'Rountzenheim',
    'Roumoules',
    'Roumazieres-Loubert',
    'Rou-Marson',
    'Roumare',
    'Roumagne',
    'Roullours',
    'Roullet-Saint-Estephe',
    'Roullens',
    'Roullee',
    'Roulans',
    'Roujan',
    'Rouilly-Saint-Loup',
    'Rouillon',
    'Rouille',
    'Rouillac',
    'Rouhling',
    'Rougon',
    'Rougnat',
    'Rougiers',
    'Rougeux',
    'Rougemont-le-Chateau',
    'Rougemont',
    'Rougegoutte',
    'Rouge',
    'Rouffigny',
    'Rouffignac-de-Sigoules',
    'Rouffignac',
    'Rouffignac-Saint-Cernin-de-Reilhac',
    'Rouffiac-Tolosan',
    'Rouffiac',
    'Rouffach',
    'Rouez',
    'Rouen',
    'Rouede',
    'Roudouallec',
    'Roucy',
    'Roucourt',
    'Roubia',
    'Roubaix',
    'Rouans',
    'Rouairoux',
    'Rottelsheim',
    'Rots',
    'Rothbach',
    'Rothau',
    'Rotangy',
    'Rosult',
    'Rostrenen',
    'Rosteig',
    'Rossfeld',
    'Rosporden',
    'Rospez',
    'Rosoy-en-Multien',
    'Rosoy',
    'Rosny-sur-Seine',
    'Rosny-sous-Bois',
    'Rosnoen',
    'Rosnay',
    'Rosis',
    'Rosiers-de-Juillac',
    "Rosiers-d'Egletons",
    'Rosieres-pres-Troyes',
    'Rosieres-en-Santerre',
    'Rosieres-aux-Salines',
    'Rosieres',
    'Rosheim',
    'Rosenwiller',
    'Rosendael',
    'Rosenau',
    'Roscoff',
    'Roscanvel',
    'Rosans',
    'Rorthais',
    'Rorschwihr',
    'Roquevaire',
    'Roquettes',
    'Roquetoire',
    'Roquesteron',
    'Roqueseriere',
    'Roques',
    'Roquemaure',
    'Roquelaure-Saint-Aubin',
    'Roquelaure',
    'Roquefort-sur-Garonne',
    'Roquefort-la-Bedoule',
    'Roquefort-des-Corbieres',
    'Roquefort',
    'Roquedur',
    'Roquecourbe',
    'Roquebrune-sur-Argens',
    'Roquebrune-Cap-Martin',
    'Roquebrun',
    'Roquebilliere',
    'Roppeviller',
    'Roppentzwiller',
    'Roppenheim',
    'Roppe',
    'Roost-Warendin',
    'Ronthon',
    'Ronssoy',
    'Ronsenac',
    'Ronquerolles',
    'Ronno',
    'Ronel',
    'Roncq',
    'Ronchin',
    'Ronchaux',
    'Ronchamp',
    'Roncey',
    'Ronce-les-Bains',
    'Ronai',
    'Rompon',
    'Romorantin-Lanthenay',
    'Romilly-sur-Seine',
    'Romilly-sur-Andelle',
    'Romilly-sur-Aigre',
    'Romille',
    'Romescamps',
    'Romeny-sur-Marne',
    'Romenay',
    'Romelfing',
    'Rombas',
    'Rombach-le-Franc',
    'Romazy',
    'Romans-sur-Isère',
    'Romaneche-Thorins',
    'Roman',
    'Romainville',
    'Romagny',
    'Romagnieu',
    'Romagne',
    'Romagnat',
    'Rom',
    'Rollot',
    'Rolleville',
    'Rolampont',
    'Roizy',
    'Roisey',
    'Roissy-en-France',
    'Roissy-en-Brie',
    'Roisel',
    'Roinville',
    'Roiffieux',
    'Roiffe',
    'Rohrwiller',
    'Rohrbach-les-Bitche',
    'Rohan',
    'Rogy',
    'Rognonas',
    'Rognes',
    'Rognaix',
    'Rognac',
    'Rogerville',
    'Roffiac',
    'Roeze-sur-Sarthe',
    'Roeux',
    'Roeulx',
    'Roeschwoog',
    'Roellecourt',
    'Rodilhan',
    'Rodez',
    'Rodes',
    'Rodern',
    'Roderen',
    'Rodemack',
    'Rodelle',
    'Rocroi',
    'Rocquigny',
    'Rocquencourt',
    'Rocquemont',
    'Rocquancourt',
    'Roclincourt',
    'Rocles',
    'Rochonvillers',
    'Rocheville',
    'Rochetoirin',
    'Rochetaillee',
    'Rochesson',
    'Rochessauve',
    'Roches-Premarie-Andille',
    'Roches-les-Blamont',
    'Rocheserviere',
    'Roche-Saint-Secret-Beconne',
    'Rochemaure',
    'Roche-lez-Beaupre',
    'Roche-la-Moliere',
    'Rochegude',
    'Rochefort-sur-Nenon',
    'Rochefort-sur-Loire',
    'Rochefort-en-Yvelines',
    'Rochefort-en-Terre',
    'Rochefort-du-Gard',
    'Rochefort',
    'Roche-en-Regnier',
    'Rochecorbon',
    'Rochecolombe',
    'Rochechouart',
    'Roche',
    'Roce',
    'Rocbaron',
    'Rocamadour',
    'Robion',
    'Robiac-Rochessadoule',
    'Roberval',
    'Robert-Espagne',
    'Robecq',
    'Robecourt',
    'Roannes-Saint-Mary',
    'Roanne',
    'Roaix',
    'Roaillan',
    'Rizaucourt-Buchey',
    'Rixheim',
    'Rivolet',
    'Riviere-sur-Tarn',
    'Riviere-Saas-et-Gourby',
    'Rivieres',
    'Riviere',
    'Rivesaltes',
    'Rivery',
    'Riventosa',
    'Rivel',
    'Rivedoux-Plage',
    'Rive-de-Gier',
    'Rivecourt',
    'Rivas',
    'Riupeyrous',
    'Ritzing',
    'Rittershoffen',
    'Risoul',
    'Ris-Orangis',
    'Riscle',
    'Riquewihr',
    'Rioz',
    'Rioux',
    'Riouperoux',
    'Riorges',
    'Rions',
    'Rion-des-Landes',
    'Riom-es-Montagnes',
    'Riom',
    'Riols',
    'Riocaud',
    'Rinxent',
    'Rimont',
    'Rimogne',
    'Rimling',
    'Rimeize',
    'Rillieux-la-Pape',
    'Rille',
    'Rilhac-Rancon',
    'Rigny-sur-Arroux',
    'Rigney',
    'Rignac',
    'Riez',
    'Rieux-Minervois',
    'Rieux-de-Pelleport',
    'Rieux',
    'Rieux-Volvestre',
    'Rieutort-de-Randon',
    'Rieupeyroux',
    'Rieumes',
    'Rieulay',
    'Riencourt',
    'Riedseltz',
    'Riedisheim',
    'Riec-sur-Belon',
    'Ricquebourg',
    'Richwiller',
    'Richemont',
    'Richelieu',
    'Richebourg',
    'Richardmenil',
    'Ricaud',
    'Ricarville',
    'Ribiers',
    'Riberac',
    'Ribennes',
    'Ribemont-sur-Ancre',
    'Ribemont',
    'Ribecourt-Dreslincourt',
    'Ribeauvillé',
    'Ribaute-les-Tavernes',
    'Ribaute',
    'Ribagnac',
    'Riaucourt',
    'Riantec',
    'Rians',
    'Riaille',
    'Rhuis',
    'Rhinau',
    'Rezé',
    'Reyssouze',
    'Reyrieux',
    'Reyrevignes',
    'Reynies',
    'Reynes',
    'Reygade',
    'Reyersviller',
    'Rexpoede',
    'Revonnas',
    'Revin',
    'Revigny-sur-Ornain',
    'Revigny',
    'Revercourt',
    'Reventin-Vaugris',
    'Revel',
    'Reveillon',
    'Reux',
    'Reuil',
    'Reuilly',
    'Reugny',
    'Rettel',
    'Retschwiller',
    'Retournac',
    'Retonfey',
    'Retjons',
    'Retiers',
    'Rethonvillers',
    'Rethondes',
    'Retheuil',
    'Rethel',
    'Retaud',
    'Restinclieres',
    'Restigne',
    'Ressons-sur-Matz',
    'Resson',
    'Requista',
    'Requeil',
    'Reppe',
    'Replonges',
    'Reparsac',
    'Reotier',
    'Renwez',
    'Renung',
    'Renty',
    'Rentieres',
    'Renneville',
    'Renneval',
    'Rennes-sur-Loue',
    'Rennes',
    'Rennepont',
    'Rening',
    'Renescure',
    'Renaze',
    'Renaison',
    'Renage',
    'Remy',
    'Remuzat',
    'Remoulins',
    'Remouille',
    'Remoncourt',
    'Remollon',
    'Remiremont',
    'Remilly-sur-Lozon',
    'Remilly-Aillicourt',
    'Remilly',
    'Remigny',
    'Remiencourt',
    'Remicourt',
    'Remering-les-Puttelange',
    'Remelfing',
    'Remelfang',
    'Rembercourt-Sommaisne',
    'Remaucourt',
    'Relevant',
    'Relans',
    'Rejet-de-Beaulieu',
    'Rejaumont',
    'Reithouse',
    'Reiningue',
    'Reinhardsmunster',
    'Reims',
    'Reillanne',
    'Reilhanette',
    'Reignier-Esery',
    'Reignac',
    'Reichstett',
    'Reichshoffen',
    'Rehon',
    'Rehainviller',
    'Rehaincourt',
    'Regusse',
    'Reguisheim',
    'Reguiny',
    'Regny',
    'Regnie-Durette',
    'Regneville-sur-Mer',
    'Redon',
    'Reding',
    'Redessan',
    'Redene',
    'Recy',
    'Recquignies',
    'Recologne',
    'Recloses',
    'Reclesne',
    'Recicourt',
    'Rechicourt-le-Chateau',
    'Rechesy',
    'Rebreuve-Ranchicourt',
    'Rebrechien',
    'Rebigue',
    'Rebeuville',
    'Rebenacq',
    'Rebais',
    'Reaumont',
    'Reau',
    'Realville',
    'Realmont',
    'Real',
    'Razines',
    'Razimet',
    'Razes',
    'Razac-sur-lIsle',
    "Razac-d'Eymet",
    'Raynans',
    'Ravieres',
    'Raves',
    'Ravennefontaines',
    'Ravenel',
    'Ravel',
    'Raveau',
    'Rauwiller',
    'Rauville-la-Bigot',
    'Rauret',
    'Raulhac',
    'Raucourt-et-Flaba',
    'Raucourt',
    'Raucoules',
    'Ratenelle',
    'Rasteau',
    'Raphele-les-Arles',
    'Rapaggio',
    "Raon-l'Etape",
    'Raon-aux-Bois',
    'Ranville',
    'Ranton',
    'Rantigny',
    'Ranspach-le-Haut',
    'Ransart',
    'Rans',
    'Rannee',
    'Rangen',
    'Rang-du-Fliers',
    'Ranes',
    'Randevillers',
    'Randens',
    'Randan',
    'Rancourt',
    'Ranconnieres',
    'Rancon',
    'Ranchot',
    'Ranchal',
    'Rance',
    'Rampillon',
    'Rampan',
    'Ramouzens',
    'Ramousies',
    'Ramous',
    'Ramonville-Saint-Agne',
    'Ramonchamp',
    'Ramicourt',
    'Ramecourt',
    'Ramburelles',
    'Rambucourt',
    'Rambouillet',
    'Rambervillers',
    'Rambaud',
    'Ramatuelle',
    'Ramasse',
    'Raizeux',
    'Raix',
    'Rai',
    'Raissac-sur-Lampy',
    'Raismes',
    'Rainvillers',
    'Rainneville',
    'Raincheval',
    'Raimbeaucourt',
    'Raillicourt',
    'Raillencourt-Sainte-Olle',
    'Rahon',
    'Rahay',
    'Rahart',
    'Raedersheim',
    'Radonvilliers',
    'Radon',
    'Radinghem-en-Weppes',
    'Radinghem',
    'Radepont',
    'Radenac',
    'Raddon-et-Chapendu',
    'Racrange',
    'Racquinghem',
    'Raches',
    'Rachecourt-sur-Marne',
    'Rablay-sur-Layon',
    'Rabastens-de-Bigorre',
    'Quivieres',
    'Quittebeuf',
    'Quistinic',
    'Quissac',
    'Quintin',
    'Quintenic',
    'Quintenas',
    'Quintal',
    'Quint-Fonsegrives',
    'Quinssaines',
    'Quinsac',
    'Quins',
    'Quingey',
    'Quineville',
    'Quincy-Voisins',
    'Quincy-sous-Senart',
    'Quincy-Landzecourt',
    'Quincieux',
    'Quincieu',
    'Quincie-en-Beaujolais',
    'Quincampoix',
    'Quimperlé',
    'Quimper',
    'Quillebeuf-sur-Seine',
    'Quillan',
    'Quievy',
    'Quievrechain',
    'Quievelon',
    'Quiestede',
    'Quierzy',
    'Quiers-sur-Bezonde',
    'Quiers',
    'Quibou',
    'Quiberville',
    'Quiberon',
    'Queyssac',
    'Queyrieres',
    'Queyrac',
    'Quevreville-la-Poterie',
    'Quevert',
    'Queven',
    'Quevauvillers',
    'Queudes',
    'Quettreville-sur-Sienne',
    'Quettetot',
    'Quettehou',
    'Quetigny',
    'Questrecques',
    'Questembert',
    'Quessy',
    'Quessoy',
    'Quesnoy-sur-Deule',
    'Quesnoy-sur-Airaines',
    'Quesnoy-le-Montant',
    'Quers',
    'Querrieu',
    'Querrien',
    'Querre',
    'Querqueville',
    'Quernes',
    'Querenaing',
    'Quenza',
    'Quend',
    'Quemperven',
    'Quemper-Guezennec',
    'Quemigny-sur-Seine',
    'Quéménéven',
    'Quelneuc',
    'Quelaines-Saint-Gault',
    'Queige',
    'Quedillac',
    'Quebriac',
    'Quatzenheim',
    'Quatremare',
    'Quatre-Champs',
    'Quasquara',
    'Quarre-les-Tombes',
    'Quarouble',
    'Quarante',
    'Quantilly',
    'Quaix-en-Chartreuse',
    'Quaedypre',
    'Puyvert',
    'Puy-Saint-Vincent',
    'Puy-Saint-Pierre',
    'Puy-Saint-Martin',
    'Puy-Saint-Eusebe',
    'Puyricard',
    'Puyravault',
    'Puyoo',
    'Puymoyen',
    'Puymirol',
    'Puymiclan',
    'Puymeras',
    'Puy-Malsignat',
    'Puyloubier',
    'Puy-lÉvêque',
    'Puylaurens',
    'Puylaroque',
    'Puihardy',
    'Puy-Guillaume',
    'Puygros',
    'Puygouzon',
    'Puy-du-Lac',
    'Puycornet',
    'Puycelsi',
    'Puybrun',
    'Puybegon',
    'Puttelange-les-Thionville',
    'Puttelange-aux-Lacs',
    'Puteaux',
    'Pussy',
    'Pussay',
    'Pusignan',
    'Pusey',
    'Pure',
    'Pulversheim',
    'Pulverieres',
    'Pulnoy',
    'Pulligny',
    'Puligny-Montrachet',
    'Pujols-sur-Ciron',
    'Pujols',
    'Pujo-le-Plan',
    'Pujaut',
    'Pujaudran',
    'Puivert',
    'Puisserguier',
    'Puisseguin',
    'Puissalicon',
    'Puisieulx',
    'Puiseaux',
    'Puimoisson',
    'Puimisson',
    'Puimichel',
    'Puilboreau',
    'Puilaurens',
    'Puicheric',
    'Pugny-Chatenod',
    'Pugnac',
    'Pugieu',
    'Puget-Ville',
    'Puget-Theniers',
    'Puget-sur-Argens',
    'Puessans',
    'Puechabon',
    'Puceul',
    'Publier',
    'Puberg',
    'Prusly-sur-Ource',
    'Prunoy',
    'Pruniers-en-Sologne',
    'Pruniers',
    'Prunieres',
    'Prunet',
    'Prunelli-di-Fiumorbo',
    'Prunay-en-Yvelines',
    'Prunay-le-Gillon',
    'Prunay-Belleville',
    'Pruines',
    'Pruille-le-Chetif',
    'Prudhomat',
    'Proyart',
    'Provins',
    'Provin',
    'Proville',
    'Proveysieux',
    'Provencheres-sur-Meuse',
    'Provencheres-sur-Fave',
    'Provenchere',
    'Prouzel',
    'Prouvy',
    'Prouvais',
    'Prouilly',
    'Prosnes',
    'Propriano',
    'Propiac',
    'Pronville',
    'Pronleroy',
    'Prompsat',
    'Projan',
    'Proisy',
    'Proissans',
    'Priziac',
    'Privezac',
    'Privas',
    'Prisse',
    'Prissac',
    'Prisches',
    'Prinquiau',
    'Pringy',
    'Prince',
    'Princay',
    'Primelin',
    'Primarette',
    'Prigonrieux',
    'Prignac-et-Marcamps',
    'Priay',
    'Prey',
    'Prevessin-Moens',
    'Preval',
    'Preux-au-Bois',
    'Preuschdorf',
    'Preures',
    'Prety',
    'Precieux',
    'Pressins',
    'Pressignac-Vicq',
    'Pressac',
    'Presnoy',
    'Presly',
    'Presles-en-Brie',
    'Presles',
    'Presle',
    'Presilly',
    'Presentevillers',
    'Preseau',
    'Presailles',
    'Prenois',
    'Premilhat',
    'Premian',
    'Premesques',
    'Premery',
    'Premanon',
    'Preixan',
    'Preignan',
    'Preignac',
    'Prefailles',
    'Pre-en-Pail',
    'Precy-sur-Oise',
    'Precy-sur-Marne',
    'Precy-sous-Thil',
    'Precigne',
    'Prechac',
    'Precey',
    'Preaux-Bocage',
    'Preaux',
    'Preaux-du-Perche',
    'Praz-sur-Arly',
    'Prayssas',
    'Prayssac',
    'Prayols',
    'Prasville',
    'Pranzac',
    'Pranles',
    'Pralong',
    'Pralognan-la-Vanoise',
    'Prailles',
    'Prahecq',
    'Pradons',
    'Pradines',
    'Pradinas',
    'Prades-sur-Vernazobre',
    'Prades-le-Lez',
    'Prades',
    'Pradere-les-Bourguets',
    'Poyartin',
    'Poyanne',
    'Pouzols-Minervois',
    'Pouzolles',
    'Pouzilhac',
    'Pouzauges',
    'Pouzac',
    'Pouylebon',
    'Pouy-de-Touges',
    'Pouydesseaux',
    'Pouyastruc',
    'Pouxeux',
    'Pousthomy',
    'Poussan',
    'Poursac',
    'Pourrieres',
    'Pourrain',
    'Pournoy-la-Chetive',
    'Pourcy',
    'Pourcieux',
    'Pourcharesses',
    'Poulx',
    'Poullaouen',
    'Poullan-sur-Mer',
    'Pouligny-Notre-Dame',
    'Pouldreuzic',
    'Poulangy',
    'Poulainville',
    'Pouilly-sur-Serre',
    'Pouilly-sur-Loire',
    'Pouilly-sous-Charlieu',
    'Pouilly-les-Nonains',
    'Pouilly-les-Feurs',
    'Pouilly-le-Monial',
    'Pouilly-en-Auxois',
    'Pouilly',
    'Pouilloux',
    'Pouillon',
    'Pouilley-les-Vignes',
    'Pouillenay',
    'Pouille-les-Coteaux',
    'Pouille',
    'Pougues-les-Eaux',
    'Pougny',
    'Pouffonds',
    'Poudenx',
    'Poudenas',
    'Poucharramet',
    'Pouan-les-Vallees',
    'Pouance',
    'Potigny',
    'Pothieres',
    'Poses',
    'Portvendres',
    'Port-sur-Saone',
    'Portsall',
    'Port-Saint-Pere',
    'Port-Saint-Louis-du-Rhone',
    'Port-Sainte-Marie',
    'Port-Sainte-Foy-et-Ponchapt',
    'Ports',
    'Porto-Vecchio',
    'Port-Mort',
    'Port-Louis',
    'Port-le-Grand',
    'Port-la-Nouvelle',
    'Portiragnes',
    'Portieux',
    'Porticcio',
    'Portet-sur-Garonne',
    'Portets',
    'Portes-les-Valence',
    'Portes',
    'Port-en-Bessin-Huppain',
    'Portel-des-Corbieres',
    "Port-d'Envaux",
    'Port-de-Bouc',
    'Port-Cros',
    'Portbail',
    'Port',
    'Porspoder',
    'Porquerolles',
    'Pornichet',
    'Pornic',
    'Pordic',
    'Porcieu',
    'Porcheville',
    'Porcheux',
    'Porcheres',
    'Porcelette',
    'Porcaro',
    'Paris 11 Popincourt',
    'Popian',
    'Pontvallain',
    'Pont-sur-Yonne',
    'Pont-sur-Seine',
    'Pont-sur-Sambre',
    'Pont-Scorff',
    'Pont-Salomon',
    'Pont-Saint-Pierre',
    'Pont-Saint-Martin',
    'Pont-Saint-Esprit',
    'Pont-Sainte-Maxence',
    'Pont-Sainte-Marie',
    'Ponts',
    'Pontru',
    'Pontrieux',
    'Pont-Remy',
    'Pontpoint',
    'Pontpierre',
    'Pontoux',
    'Pontorson',
    "Pontonx-sur-l'Adour",
    'Pontoise',
    'Pont-Noyelles',
    'Pont-Melvez',
    "Pont-l'Eveque",
    'Pont-les-Bonfays',
    "Pont-l'Abbe-d'Arnoult",
    "Pont-l'Abbé",
    'Pontivy',
    'Pontigny',
    'Pontigne',
    'Pontiacq-Viellepinte',
    'Ponthion',
    'Ponthierry',
    'Ponthevrard',
    'Pont-Hebert',
    'Pontgouin',
    'Pontfaverger-Moronvilliers',
    'Ponteves',
    'Pont-Eveque',
    'Pont-et-Massene',
    'Pontenx-les-Forges',
    'Ponte Leccia',
    'Ponteilla',
    'Pont-du-Navoy',
    'Pont-du-Chateau',
    'Pont-du-Casse',
    'Pont-de-Veyle',
    'Pont-de-Vaux',
    'Pont-de-Salars',
    'Pont-de-Roide',
    'Pont-de-Poitte',
    'Pont-de-Metz',
    "Pont-de-l'Isere",
    'Pont-de-Larn',
    "Pont-de-l'Arche",
    'Pont-de-Cheruy',
    'Pont-de-Buis-les-Quimerch',
    'Pont-de-Braye',
    "Pont-d'Ain",
    'Pont-Croix',
    'Pontchateau',
    'Pontchartrain',
    'Pontcharra-sur-Turdine',
    'Pontcharra',
    'Pontchardon',
    'Pontcarre',
    'Pontavert',
    'Pont-a-Vendin',
    'Pont-Aven',
    'Pont-Authou',
    'Pont-Audemer',
    'Pontaubault',
    'Pontarme',
    'Pontarlier',
    'Pontarion',
    'Pont-à-Mousson',
    'Pont-a-Marcq',
    'Pontamafrey-Montpascal',
    'Pontailler-sur-Saone',
    'Pontacq',
    'Ponsas',
    'Pons',
    'Pondaurat',
    'Poncins',
    'Poncin',
    'Ponchon',
    'Pomy',
    'Pomport',
    'Pomponne',
    'Pompogne',
    'Pompignan',
    'Pompignac',
    'Pompierre-sur-Doubs',
    'Pompiac',
    'Pompey',
    'Pompertuzat',
    'Pompejac',
    'Pompaire',
    'Pommiers',
    'Pommier-de-Beaurepaire',
    'Pommevic',
    'Pommeuse',
    'Pommerit-le-Vicomte',
    'Pommerit-Jaudy',
    'Pommereuil',
    'Pommeret',
    'Pommera',
    'Pomerols',
    'Pomerol',
    'Pomarez',
    'Polminhac',
    'Pollionnay',
    'Pollieu',
    'Polliat',
    'Pollestres',
    'Poligny',
    'Poligne',
    'Polignac',
    'Polienas',
    "Poleymieux-au-Mont-d'Or",
    'Polastron',
    'Poix-du-Nord',
    'Poitiers',
    'Poisy',
    'Poissy',
    'Poissons',
    'Poisson',
    'Poiseux',
    'Poisat',
    'Poiroux',
    'Pointre',
    'Pointis-Inard',
    'Pointis-de-Riviere',
    'Pointel',
    'Poincy',
    'Poilly-sur-Tholon',
    'Poilly-lez-Gien',
    'Poilley',
    'Poigny-la-Foret',
    'Poigny',
    'Pogny',
    'Poggio-Mezzana',
    'Poggio-dOletta',
    'Poey-de-Lescar',
    'Podensac',
    'Poce-sur-Cisse',
    'Poce-les-Bois',
    'Pluzunet',
    'Pluvigner',
    'Pluvet',
    'Plurien',
    'Pluneret',
    'Plumieux',
    'Plumetot',
    'Plumergat',
    'Plumelin',
    'Plumeliau',
    'Plumelec',
    'Plumaugat',
    'Plumaudan',
    'Pluherlin',
    'Pluguffan',
    'Plufur',
    'Pluduno',
    'Pludual',
    'Plozevet',
    'Plovan',
    'Plouzevede',
    'Plouzane',
    'Plouye',
    'Plouvorn',
    'Plouvien',
    'Plouvara',
    'Plouvain',
    'Plourivo',
    'Plourin-les-Morlaix',
    'Plourin',
    "Plourac'h",
    'Plounevez-Quintin',
    'Plounevez-Moedec',
    'Plounevez-Lochrist',
    'Plounevezel',
    'Plouneventer',
    'Plounerin',
    'Ploumoguer',
    'Ploumilliau',
    'Ploumagoar',
    'Plouisy',
    'Plouigneau',
    'Plouider',
    'Plouhinec',
    'Plouharnel',
    'Plourhan',
    'Plouha',
    'Plouguiel',
    'Plouguernevel',
    'Plouguerneau',
    'Plouguenast',
    'Plougrescant',
    'Plougourvest',
    'Plougoumelen',
    'Plougoulm',
    'Plougonven',
    'Plougonvelin',
    'Plougastel-Daoulas',
    'Plougasnou',
    'Ploufragan',
    "Plouezoc'h",
    'Plouezec',
    'Plouescat',
    'Plouer-sur-Rance',
    'Plouenan',
    'Plouegat-Guerand',
    'Plouedern',
    'Ploudiry',
    'Ploudaniel',
    'Ploudalmezeau',
    'Ploubezre',
    'Ploubazlanec',
    'Ploubalay',
    'Plouay',
    'Plouasne',
    'Plouarzel',
    'Plouaret',
    'Plouagat',
    'Plou',
    'Plottes',
    'Plonevez-Porzay',
    'Plonevez-du-Faou',
    'Plonéour-Lanvern',
    'Ploneis',
    'Plomodiern',
    'Plomeur',
    'Plomelin',
    'Plombieres-les-Dijon',
    'Plombieres-les-Bains',
    'Ploisy',
    'Plogonnec',
    'Plogastel-Saint-Germain',
    'Ploezal',
    'Ploeven',
    'Ploeuc-sur-Lie',
    'Ploermel',
    'Ploeren',
    'Ploerdut',
    'Ploemeur',
    'Ploemel',
    'Plobsheim',
    'Plobannalec-Lesconil',
    'Plivot',
    'Plichancourt',
    'Pleyber-Christ',
    'Pleyben',
    'Plevin',
    'Plevenon',
    'Pleven',
    'Pleuven',
    'Pleurtuit',
    'Pleurs',
    'Pleure',
    'Pleumeur-Bodou',
    'Pleumeleuc',
    'Pleumartin',
    'Pleugueneuc',
    'Pleudihen-sur-Rance',
    'Pleudaniel',
    'Pleucadeuc',
    'Pleubian',
    'Plestin-les-Greves',
    'Plestan',
    'Plessis-Saint-Benoist',
    'Plesse',
    'Plessala',
    'Plesnois',
    'Pleslin-Trigavou',
    'Plesidy',
    'Plescop',
    'Plerneuf',
    'Plerin',
    'Plerguer',
    'Plenise',
    'Pleneuf-Val-Andre',
    'Plenee-Jugon',
    'Plemy',
    'Plemet',
    'Plelo',
    'Plelauff',
    'Plelan-le-Petit',
    'Plelan-le-Grand',
    'Pleine-Selve',
    'Pleine-Fougeres',
    'Frehel',
    'Plehedel',
    'Pleguien',
    'Pledran',
    'Plechatel',
    'Pleaux',
    'Plazac',
    'Plauzat',
    'Plaudren',
    'Plats',
    'Plassac-Rouffiac',
    'Plassac',
    'Plasnes',
    'Plappeville',
    'Planrupt',
    'Planquery',
    'Planioles',
    'Planguenoual',
    'Planfoy',
    'Planes',
    "Plan-d'Orgon",
    'Le Plan-de-la-Tour',
    'Plan-de-Cuques',
    'Plan-dAups-Sainte-Baume',
    'Plancoet',
    'Plancher-Bas',
    'Planay',
    'Planaise',
    'Plaizac',
    'Plaissan',
    'Plaisir',
    'Plaisance-du-Touch',
    'Plainville',
    'Plaintel',
    'Plainoiseau',
    'Plainfaing',
    'Plaines-Saint-Lange',
    'Plaine-Haute',
    'Plaine',
    'Plaimpied-Givaudins',
    'Plailly',
    'Plagne',
    'Placy',
    'Place',
    'Plabennec',
    'Pizay',
    'Pitres',
    'Pithiviers',
    'Pissy-Poville',
    'Pissy',
    'Pissotte',
    'Pissos',
    'Pisseleu',
    'Pisieu',
    'Piseux',
    'Pisany',
    'Pirou',
    'Piriac-sur-Mer',
    'Pirey',
    'Pire-sur-Seiche',
    'Piquecos',
    'Pipriac',
    'Pioussay',
    'Pionsat',
    'Piolenc',
    'Pinterville',
    'Pinsaguel',
    'Pinsac',
    'Pinon',
    'Piney',
    'Pineuilh',
    'Pinet',
    'Pinel-Hauterive',
    'Pin-Balma',
    'Pinas',
    'Pimprez',
    'Pimorin',
    'Pillac',
    'Pihem',
    'Pignans',
    'Pignan',
    'Piffonds',
    'Pieve',
    'Pieusse',
    'Pietroso',
    'Pietrosella',
    'Pietralba',
    'Pierry',
    'Pierrevillers',
    'Pierreville',
    'Pierrevert',
    'Pierreval',
    'Pierres',
    'Pierrerue',
    'Pierre-Perthuis',
    'Pierre-Levee',
    'Pierrelaye',
    'Pierrelatte',
    'Pierrefontaine-les-Varans',
    'Pierrefontaine-les-Blamont',
    'Pierrefonds',
    'Pierrefitte-sur-Seine',
    'Pierrefitte-sur-Loire',
    'Pierrefitte-sur-Aire',
    'Pierrefitte',
    'Pierrefiques',
    'Pierrefeu-du-Var',
    'Pierrecourt',
    'Pierreclos',
    'Pierre-Chatel',
    'Pierre-Benite',
    'Pierre-de-Bresse',
    'Piennes',
    'Piegut-Pluviers',
    'Piegros-la-Clastre',
    'Picquigny',
    'Picherande',
    'Pichanges',
    'Picauville',
    'Pibrac',
    'Piblange',
    'Piana',
    'Piace',
    'Pia',
    'Phalsbourg',
    'Phalempin',
    'Phaffans',
    'Pfulgriesheim',
    'Pfetterhouse',
    'Pfastatt',
    'Pfalzweyer',
    'Pfaffenhoffen',
    'Pezy',
    'Pezuls',
    'Pezou',
    'Pezilla-la-Riviere',
    'Pezens',
    'Pezenas',
    'Pezarches',
    'Peyrusse',
    'Peyruis',
    'Peyrolles-en-Provence',
    'Peyrole',
    'Peyrins',
    'Peyrilhac',
    'Peyrignac',
    'Peyrieu',
    'Peyriat',
    'Peyriac-Minervois',
    'Peyriac-de-Mer',
    'Peyrestortes',
    'Peyrens',
    'Peyrelongue-Abos',
    'Peyrelevade',
    'Peyrehorade',
    'Peyregoux',
    'Peyrat-la-Noniere',
    'Peyrat-de-Bellac',
    'Peypin',
    'Peynier',
    'Peymeinade',
    'Peuplingues',
    'Peumerit',
    'Peujard',
    'Pettonville',
    'Petosse',
    'Petiville',
    'Petit-Verly',
    'Petit-Rederching',
    'Petit-Palais-et-Cornemps',
    'Petit-Noir',
    'Petit-Mars',
    'Petitmagny',
    'Petit-Landau',
    'Petite-Rosselle',
    'Petite-Foret',
    'Petitefontaine',
    'Pessoulens',
    'Pessines',
    'Pessan',
    'Pessac-sur-Dordogne',
    'Pessac',
    'Pesmes',
    'Peschadoires',
    'Pertuis',
    'Pertheville-Ners',
    'Perthes-les-Brienne',
    'Perthes',
    'Pertain',
    'Persquen',
    'Pers-Jussy',
    'Persan',
    'Persac',
    'Perrusson',
    'Perruel',
    'Perrou',
    'Perros-Guirec',
    'Perrigny-sur-Armancon',
    'Perrigny',
    'Perrignier',
    'Perriers-sur-Andelle',
    'Perriers-la-Campagne',
    'Perrieres',
    'Perrex',
    'Perreux',
    'Perret',
    'Perrecy-les-Forges',
    'Perrancey-les-Vieux-Moulins',
    'Perpignan',
    'Perouges',
    'Peronne',
    'Peronnas',
    'Peron',
    'Perols',
    'Pernois',
    'Pernes-les-Fontaines',
    'Pernes-les-Boulogne',
    'Pernes',
    'Pernay',
    'Pernant',
    'Pern',
    'Perles-et-Castelet',
    'Perissac',
    'Périgueux',
    'Perigny',
    'Perigneux',
    'Perigne',
    'Perignat-sur-Allier',
    'Perignat-les-Sarlieve',
    'Perignac',
    'Periers',
    'Peri',
    'Peret',
    'Perenchies',
    'Pere',
    'Percy-en-Auge',
    'Percy',
    'Percey',
    'Pepieux',
    'Penvenan',
    'Penta-di-Casinca',
    'Penol',
    'Penne-dAgenais',
    'Penne',
    'Pennautier',
    'Penmarch',
    'Penly',
    'Penguily',
    'Penestin',
    'Pende',
    'Penchard',
    'Pelves',
    'Pelussin',
    'Pellouailles-les-Vignes',
    'Pellevoisin',
    'Pellegrue',
    'Pelleautier',
    'Pelissanne',
    'Peipin',
    'Peillonnex',
    'Peillon',
    'Peille',
    'Peillac',
    'Pegomas',
    'Pedernec',
    'Pecy',
    'Pecqueuse',
    'Pecquencourt',
    'Pechbonnieu',
    'Pechabou',
    'Peault',
    'Peaule',
    'Peaugres',
    'Pazayac',
    'Payzac',
    'Payssous',
    'Payrin-Augmontel',
    'Payrignac',
    'Payre',
    "Payra-sur-l'Hers",
    'Payrac',
    'Payns',
    'Pavilly',
    'Pavie',
    'Pavezin',
    'Pavant',
    'Paussac-et-Saint-Vivien',
    'Paulx',
    'Pauligne',
    'Paulhiac',
    'Paulhe',
    'Paulhan',
    'Paulhaguet',
    'Paulhac',
    'Paule',
    'Pauillac Haut',
    'Pauillac',
    'Pauilhac',
    'Paudy',
    'Paucourt',
    'Pau',
    'Patay',
    'Passy',
    'Passonfontaine',
    'Passins',
    'Passavant',
    'Passais',
    'Passa',
    'Pasques',
    'Pasly',
    'Paslieres',
    'Pas-en-Artois',
    'Parux',
    'Parthenay',
    'Pars-les-Romilly',
    'Parsac',
    'Parpecay',
    'Paron',
    'Parne-sur-Roc',
    'Parnes',
    'Parnay',
    'Parnac',
    'Parmilieu',
    'Parmain',
    'Parly',
    'Parlan',
    'Parisot',
    'Paris',
    'Parigny',
    'Parigne-sur-Braye',
    "Parigne-l'Eveque",
    'Parigne-le-Polin',
    'Parigne',
    'Parignargues',
    'Pargny-sur-Saulx',
    'Pargny-les-Reims',
    'Pargny-la-Dhuys',
    'Pargny-Filain',
    'Parfondeval',
    'Parentis-en-Born',
    'Parentignat',
    'Parent',
    'Parennes',
    'Parempuyre',
    'Pardies',
    'Pardaillan',
    'Parcieux',
    'Parcey',
    'Parce-sur-Sarthe',
    "Parc-d'Anxtot",
    'Parcay-Meslay',
    'Parcay-les-Pins',
    'Paraza',
    'Paray-Vieille-Poste',
    'Paray-le-Monial',
    'Parassy',
    'Paradou',
    'Panzoult',
    'Pantin',
    'Panossas',
    'Panon',
    'Pannessieres',
    'Pannes',
    'Pannece',
    'Panjas',
    'Panissieres',
    'Panilleuse',
    'Pansey',
    'Panazol',
    'Pamproux',
    'Pamplie',
    'Pamiers',
    'Pamfou',
    'Palluel',
    'Pallud',
    'Palluau-sur-Indre',
    'Palluau',
    'Palleville',
    'Palleau',
    'Palisse',
    'Palinges',
    'Paley',
    'Palavas-les-Flots',
    'Palau-del-Vidre',
    'Palaminy',
    'Palalda',
    'Palaja',
    'Palaiseau',
    'Paladru',
    'Pajay',
    'Pair-et-Grandrupt',
    'Paimpont',
    'Paimpol',
    'Paimboeuf',
    'Pailloles',
    'Paillet',
    'Paillencourt',
    'Paille',
    'Pailhes',
    'Pagny-sur-Moselle',
    'Pagny-sur-Meuse',
    'Pagny-le-Chateau',
    'Pagny-la-Ville',
    'Pagnoz',
    'Padies',
    'Pacy-sur-Eure',
    'Pact',
    'Pace',
    'Pabu',
    'Paars',
    'Ozouer-le-Voulgis',
    'Ozon',
    'Ozolles',
    'Ozoir-le-Breuil',
    'Ozoir-la-Ferriere',
    'Ozillac',
    'Ozan',
    'Oytier-Saint-Oblas',
    'Oysonville',
    'Oyre',
    'Oyonnax',
    'Oyeu',
    'Oye-Plage',
    'Oye-et-Pallet',
    'Oxelaere',
    'Ovanches',
    'Ouzouer-sur-Loire',
    'Ouzouer-sous-Bellegarde',
    'Ouzouer-le-Marche',
    'Ouve-Wirquin',
    'Ouveillan',
    'Outtersteene',
    'Outrebois',
    'Outreau',
    'Outarville',
    'Oussoy-en-Gatinais',
    'Ousse-Suzan',
    'Ousse',
    'Ourville-en-Caux',
    'Ourton',
    'Oursbelille',
    'Ouroux-sur-Saone',
    'Ouroux-en-Morvan',
    'Ourouer',
    'Ourches-sur-Meuse',
    'Oulmes',
    'Oullins',
    'Oulins',
    'Oulchy-le-Chateau',
    'Oulches-la-Vallee-Foulon',
    'Ouistreham',
    'Ouilly-le-Vicomte',
    'Ouilly-le-Tesson',
    'Ouillon',
    'Ougney',
    'Ouges',
    'Ouerre',
    'Oueilloux',
    'Oudrenne',
    'Oudon',
    'Oudezeele',
    'Oucques',
    'Ouches',
    'Ouarville',
    'Ouainville',
    'Ottwiller',
    'Ottrott',
    'Ottmarsheim',
    'Otterswiller',
    'Ottange',
    'Othis',
    'Ota',
    'Ostwald',
    'Ostricourt',
    'Ostreville',
    'Osthouse',
    'Ostheim',
    'Ostabat-Asme',
    'Ossun',
    'Ossey-les-Trois-Maisons',
    'Osses',
    'Osserain-Rivareyte',
    'Osse',
    'Ossages',
    'Osny',
    'Osne-le-Val',
    'Osmery',
    'Os-Marsillon',
    'Osly-Courtil',
    'Oslon',
    'Osenbach',
    'Orx',
    'Orvilliers',
    'Orville',
    'Orveau',
    'Orvaux',
    'Orvault',
    'Orval',
    'Ortiporio',
    'Orthoux-Serignac-Quilhan',
    'Orthez',
    'Orthevielle',
    'Ortaffa',
    'Orsonnette',
    'Orschwihr',
    'Orschwiller',
    'Orsay',
    'Orsans',
    'Orsan',
    'Ors',
    'Orry-la-Ville',
    'Orrouy',
    'Orpierre',
    'Orphin',
    'Oroer',
    'Ornolac-Ussat-les-Bains',
    'Ornex',
    'Ornans',
    'Ornaisons',
    'Ormoy-la-Riviere',
    'Ormoy',
    'Ormesson-sur-Marne',
    'Ormes',
    'Ormersviller',
    'Ormeaux',
    'Orly-sur-Morin',
    'Orly',
    'Orlu',
    'Orlienas',
    'Orliaguet',
    'Orliac-de-Bar',
    'Orleix',
    'Orleat',
    'Orléans',
    'Orival',
    'Orist',
    'Oriol-en-Royans',
    'Oriocourt',
    'Orincles',
    'Orin',
    'Origny-le-Sec',
    'Origny-en-Thierache',
    'Orignolles',
    'Origne',
    'Orignac',
    'Orgueil',
    'Orgon',
    "Orgnac-l'Aven",
    'Orgeval',
    'Orges',
    'Orgerus',
    'Orgeres-en-Beauce',
    'Orgeres',
    'Orgelet',
    'Orgeix',
    'Oresmaux',
    'Ore',
    'Ordonnac',
    'Ordiarp',
    'Ordan-Larroque',
    'Orconte',
    'Orcines',
    'Orcieres',
    'Orcier',
    'Orchies',
    'Orchamps-Vennes',
    'Orchaise',
    'Orcet',
    'Orcemont',
    'Orcay',
    'Orbigny-au-Mont',
    'Orbey',
    'Orbessan',
    'Orbeil',
    'Orbec',
    'Orban',
    "Orbais-l'Abbaye",
    'Orbagna',
    'Orange',
    'Oraison',
    'Oradour-sur-Vayres',
    'Oradour-sur-Glane',
    'Oradour-Saint-Genest',
    'Optevoz',
    'Oppy',
    'Oppede le Vieux',
    'Opoul-Perillos',
    'Opio',
    'Paris 09 Opera',
    'Onzain',
    'Onville',
    'Ons-en-Bray',
    'Onoz',
    'Onnion',
    'Onnaing',
    'Onglieres',
    'Onet-le-Chateau',
    'Ondres',
    'Ondes',
    'Oncy-sur-Ecole',
    'Onans',
    'Oms',
    'Omonville-la-Rogue',
    'Ommoy',
    'Ommeel',
    'Omissy',
    'Omet',
    'Omessa',
    'Oltingue',
    'Oloron-Sainte-Marie',
    'Olonzac',
    'Olonne-sur-Mer',
    'Olmeto',
    'Olmeta-di-Tuda',
    'Olmet',
    'Ollioules',
    'Ollieres',
    'Ollezy',
    'Olle',
    'Ollainville',
    'Olivet',
    'Olivese',
    'Oletta',
    'Olendon',
    'Olemps',
    'Olby',
    'Olargues',
    'Oizon',
    'Oisy',
    'Oissery',
    'Oissel',
    'Oisseau',
    'Oison',
    'Oisly',
    'Oisemont',
    'Oiselay-et-Grachaux',
    'Oiry',
    'Oiron',
    'Oinville-sur-Montcient',
    'Oinville-sous-Auneau',
    'Oingt',
    'Oignies',
    'Ohnenheim',
    'Ohlungen',
    'Oherville',
    'Ohain',
    'Ogy',
    'Ognes',
    'Ogeu-les-Bains',
    'Oger',
    'Offwiller',
    'Offrethun',
    'Offranville',
    'Offoy',
    'Offendorf',
    'Offemont',
    'Offekerque',
    'Oeyreluy',
    'Oeuilly',
    'Oeuf-en-Ternois',
    'Oeting',
    'Oermingen',
    'Oelleville',
    'Odratzheim',
    'Odos',
    'Odomez',
    'Oderen',
    'Odenas',
    'Odars',
    'Octon',
    'Octeville-sur-Mer',
    'Ocquerre',
    'Occagnes',
    'Ocana',
    'Obterre',
    'Oblinghem',
    'Objat',
    'Obies',
    'Oberstinzel',
    'Obernai',
    'Obermodern-Zutzendorf',
    'Oberlarg',
    'Oberhoffen-sur-Moder',
    'Oberhergheim',
    'Oberhausbergen',
    'Oberhaslach',
    'Obergailbach',
    'Oberentzen',
    'Oberdorff',
    'Oberbruck',
    'Oberbronn',
    'Betschdorf',
    'Obenheim',
    'Nyons',
    'Nyoiseau',
    'Nurlu',
    'Nully',
    'Nuits-Saint-Georges',
    'Nuille-sur-Vicoin',
    'Nueil-sur-Layon',
    'Nueil-les-Aubiers',
    'Nucourt',
    "Nuaille-d'Aunis",
    'Nuaille',
    'Nozieres',
    'Nozeroy',
    'Nozay',
    'Noyon',
    'Noyers-sur-Cher',
    'Noyers-Saint-Martin',
    'Noyers-Pont-Maugis',
    'Noyers-Bocage',
    'Noyers',
    'Noyen-sur-Seine',
    'Noyen-sur-Sarthe',
    'Noyelles-sur-Selle',
    'Noyelles-sur-Mer',
    'Noyelles-sous-Lens',
    'Noyelles-sous-Bellonne',
    'Noyelles-les-Vermelles',
    'Noyelles-Godault',
    'Noyarey',
    'Noyant-la-Plaine',
    'Noyant-la-Gravoyere',
    "Noyant-d'Allier",
    'Noyant',
    'Noyal-sur-Vilaine',
    'Noyal-Chatillon-sur-Seiche',
    'Noyal-sur-Brutz',
    'Noyal-sous-Bazouges',
    'Noyal-Pontivy',
    'Noyal-Muzillac',
    'Novillars',
    'Novillard',
    'Noves',
    'Noveant-sur-Moselle',
    'Novalaise',
    'Nouzonville',
    'Nouzilly',
    'Nouzerines',
    'Nouvoitou',
    'Nouvion-sur-Meuse',
    'Nouvion-le-Comte',
    'Nousty',
    'Nouilly',
    'Nouillonpont',
    'Noueilles',
    'Nouart',
    'Nouans-les-Fontaines',
    'Nouan-le-Fuzelier',
    'Nouainville',
    'Nouaille-Maupertuis',
    'Notre-Dame-du-Pre',
    "Notre-Dame-d'Oe",
    'Notre-Dame-des-Millieres',
    'Notre-Dame-des-Landes',
    'Notre-Dame-de-Sanilhac',
    'Notre-Dame-de-Riez',
    'Notre-Dame-de-Monts',
    'Notre-Dame-de-Mesage',
    'Notre-Dame-de-Londres',
    "Notre-Dame-de-l'Isle",
    'Notre-Dame-de-Gravenchon',
    'Notre-Dame-de-Courson',
    'Notre-Dame-de-Commiers',
    'Notre-Dame-de-Bondeville',
    'Nothalten',
    'Nostang',
    'Nossoncourt',
    'Norville',
    'Nort-sur-Erdre',
    'Norroy-le-Veneur',
    'Norroy-les-Pont-a-Mousson',
    'Norroy',
    'Norolles',
    'Normanville',
    'Normandel',
    'Norges-la-Ville',
    'Nord-Pas-de-Calais',
    'Nordhouse',
    'Nordheim',
    'Nordausques',
    'Nonville',
    'Nontron',
    'Nonglard',
    'Nonant-le-Pin',
    'Nonant',
    'Nonancourt',
    'Nommay',
    'Nomexy',
    'Nomeny',
    'Nomdieu',
    'Nomain',
    'Nollieux',
    'Nolleval',
    'Nolay',
    'Noizay',
    'Noisy-sur-Oise',
    'Noisy-sur-Ecole',
    'Noisy-Rudignon',
    'Noisy-le-Sec',
    'Noisy-le-Roi',
    'Noisy-le-Grand',
    'Noisseville',
    'Noisiel',
    'Noirterre',
    'Noironte',
    'Noiron-sous-Gevrey',
    "Noirmoutier-en-l'Ile",
    'Noirlieu',
    'Noiretable',
    'Noiremont',
    'Nointot',
    'Nointel',
    'Noidans-les-Vesoul',
    'Nohic',
    'Nohant-Vic',
    'Nohanent',
    'Nogent-sur-Vernisson',
    'Nogent-sur-Seine',
    'Nogent-sur-Oise',
    'Nogent-sur-Marne',
    'Nogent-sur-Loir',
    'Nogent-le-Sec',
    'Nogent-le-Rotrou',
    'Nogent-le-Roi',
    'Nogent-le-Phaye',
    "Nogent-l'Artaud",
    'Nogent',
    'Nogentel',
    'Nogaro',
    'Noeux-les-Mines',
    'Noe',
    'Nods',
    'Noailly',
    'Noailles',
    'Noaillan',
    'Noaillac',
    'Noailhac',
    'Nizerolles',
    'Nivolas-Vermelle',
    'Nivillers',
    'Nivillac',
    'Nivelle',
    'Nitry',
    'Nissan-lez-Enserune',
    'Niort',
    'Nîmes',
    'Nilvange',
    'Niherne',
    'Niffer',
    'Nievroz',
    'Nieurlet',
    'Nieul-sur-Mer',
    "Nieul-sur-l'Autise",
    'Nieulle-sur-Seudre',
    'Nieul-le-Dolent',
    'Nieul',
    "Nieuil-l'Espoir",
    'Nieuil',
    'Nieudan',
    'Niergnies',
    'Nieppe',
    'Nielles-les-Ardres',
    'Niederschaeffolsheim',
    'Niedernai',
    'Niedermodern',
    'Niederhergheim',
    'Niederhausbergen',
    'Niederentzen',
    'Niederbruck',
    'Niederbronn-les-Bains',
    'Niderviller',
    'Nicorps',
    'Nicole',
    'Nice',
    'Nibelle',
    'Nibas',
    'Niafles',
    "Nezignan-l'Eveque",
    'Neydens',
    'Nexon',
    'Nevoy',
    'Neville-sur-Mer',
    'Neville',
    'Nevian',
    'Nevez',
    'Nevers',
    'Nevache',
    'Neuwiller-les-Saverne',
    'Neuwiller',
    'Neuvy-sur-Loire',
    'Neuvy-sur-Barangeon',
    'Neuvy-Sautour',
    'Neuvy-Saint-Sepulchre',
    'Neuvy-Pailloux',
    'Neuvy-le-Roi',
    'Neuvy-Grandchamp',
    'Neuvy-en-Sullias',
    'Neuvy-en-Mauges',
    'Neuvy-en-Champagne',
    'Neuvy',
    'Neuvireuil',
    'Neuvilly-en-Argonne',
    'Neuvilly',
    'Neuville-Vitasse',
    'Neuvillette-en-Charnie',
    'Neuville-sur-Vanne',
    'Neuville-sur-Saone',
    'Neuville-sur-Oise',
    'Neuville-sur-Escaut',
    'Neuville-sur-Ain',
    'Neuville-sur-Ailette',
    'Neuville-Saint-Remy',
    'Neuville-Saint-Amand',
    'Neuviller-la-Roche',
    'Neuville-les-Dieppe',
    'Neuville-les-Decize',
    'Neuville-les-Dames',
    'Neuville-Ferrieres',
    'Neuville-en-Ferrain',
    'Neuville-en-Avesnois',
    'Neuville-de-Poitou',
    'Neuville-aux-Bois',
    'Neuvillalais',
    'Neuvicq-le-Chateau',
    'Neuvic-Entier',
    'Neuvic',
    'Neuves-Maisons',
    'Neuvelle-les-Voisey',
    'Neuveglise',
    'Neuve-Eglise',
    'Neuve-Chapelle',
    'Neuvecelle',
    'Neure',
    'Neung-sur-Beuvron',
    'Neulliac',
    'Neulise',
    'Neuilly-sur-Suize',
    'Neuilly-sur-Seine',
    'Neuilly-sur-Marne',
    'Neuilly-sur-Eure',
    'Neuilly-sous-Clermont',
    'Neuilly-Saint-Front',
    'Neuilly-Plaisance',
    "Neuilly-l'Eveque",
    'Neuilly-les-Dijon',
    'Neuilly-le-Real',
    'Neuilly-en-Thelle',
    'Neuilly-en-Donjon',
    'Neuille-Pont-Pierre',
    'Neuille-le-Lierre',
    'Neuille',
    'Neuil',
    'Neugartheim-Ittlenheim',
    'Neufmoulin',
    'Neufmesnil',
    'Neufmanil',
    'Neufmaisons',
    'Neuflize',
    'Neufgrange',
    'Neufchelles',
    'Neufchef',
    'Neufchatel-Hardelot',
    'Neufchatel-en-Saosnois',
    'Neufchatel-en-Bray',
    'Neufchateau',
    'Neuf-Berquin',
    'Neublans-Abergement',
    'Nesploy',
    'Nesmy',
    'Nesles-la-Vallee',
    'Nesles-la-Montagne',
    'Nesles-la-Gilberde',
    'Nesle-Normandeuse',
    'Nesle-le-Repons',
    'Nesle',
    'Neschers',
    'Nery',
    'Nervieux',
    'Nersac',
    'Ners',
    'Nerondes',
    'Neronde',
    'Neron',
    'Nernier',
    'Neris-les-Bains',
    'Nerignac',
    'Nerigean',
    'Neret',
    'Nercillac',
    'Nérac',
    'Neoules',
    'Neons-sur-Creuse',
    'Nemours',
    'Nehwiller-pres-Woerth',
    'Nehou',
    'Negrondes',
    'Negrepelisse',
    'Nefiach',
    'Neffes',
    'Nedde',
    'Nebouzat',
    'Nebias',
    'Nebian',
    'Neaux',
    'Neauphle-le-Chateau',
    'Neaufles-Saint-Martin',
    'Neau',
    'Nazelles-Negron',
    'Nayemont-les-Fosses',
    'Nay',
    'Naves-Parmelan',
    'Naves',
    'Navenne',
    'Naveil',
    'Navarrenx',
    'Navailles-Angos',
    'Nauviale',
    'Naujac-sur-Mer',
    'Naucelles',
    'Naucelle',
    'Natzwiller',
    'Nassiet',
    'Nassandres',
    'Narrosse',
    'Nargis',
    'Narcy',
    'Narbonne',
    'Naours',
    'Nantua',
    'Nantouillet',
    'Grand Nantoin',
    'Nantille',
    'Nantiat',
    'Nantheuil',
    'Nanteuil-sur-Marne',
    'Nanteuil-les-Meaux',
    'Nanteuil-le-Haudouin',
    'Nanteuil-la-Fosse',
    'Nanteuil',
    'Nantes',
    'Nanterre',
    'Nanteau-sur-Lunain',
    'Nant',
    'Nan-sous-Thil',
    'Nans-les-Pins',
    'Nannay',
    'Nangy',
    'Nangis',
    'Nandy',
    'Nandax',
    'Nancy',
    'Nancray',
    'Nancras',
    'Nance',
    'Nancay',
    'Nampty',
    'Nampcel',
    'Nambsheim',
    'Nalliers',
    'Naizin',
    'Naisey-les-Granges',
    'Nainville-les-Roches',
    'Naintre',
    'Nailly',
    'Nailloux',
    'Nages-et-Solorgues',
    'Nadaillac',
    'Nabringhen',
    'Nabirat',
    'Nabinaud',
    'Myennes',
    'Myans',
    'Muzy',
    'Muzillac',
    'Mutzig',
    'Muttersholtz',
    'Mutrecy',
    'Mutigny',
    'Mussy-sur-Seine',
    'Mussig',
    'Mussidan',
    "Val-d'Ornain",
    'Mus',
    'Murviel-les-Montpellier',
    'Murviel-les-Beziers',
    'Murs',
    'Muron',
    'Murol',
    'Muro',
    'Murinais',
    'Muret-le-Chateau',
    'Muret',
    'Mures',
    'Mureils',
    'Mur-de-Sologne',
    'Mur-de-Bretagne',
    'Mur-de-Barrez',
    'Murbach',
    'Murat-le-Quaire',
    'Murat',
    'Muntzenheim',
    'Munster',
    'Muneville-le-Bingard',
    'Mundolsheim',
    'Muncq-Nieurlet',
    'Munchhouse',
    'Mulsanne',
    'Mulhouse',
    'Muizon',
    'Muirancourt',
    'Muides-sur-Loire',
    'Muhlbach-sur-Munster',
    'Mugron',
    'Muespach-le-Haut',
    'Muespach',
    'Muel',
    'Mudaison',
    'Moze-sur-Louet',
    'Mozac',
    'Moyvillers',
    'Moyrazes',
    'Moyon',
    'Moyeuvre-Grande',
    'Moyenneville',
    'Moyenmoutier',
    'Moyencourt-les-Poix',
    'Moyen',
    'Moye',
    "Moy-de-l'Aisne",
    'Moyaux',
    'Mouzon',
    'Mouzillon',
    'Mouzieys-Panens',
    'Mouzeuil-Saint-Martin',
    'Mouzeil',
    'Mouzay',
    'Mouy-sur-Seine',
    'Mouy',
    'Mouxy',
    'Moux-en-Morvan',
    'Moux',
    'Mouvaux',
    'Moutoux',
    'Mouton',
    'Moutiers-sur-le-Lay',
    'Moutiers-sous-Chantemerle',
    'Moutiers-au-Perche',
    'Moutiers',
    'Moutier-Malcard',
    'Mouthiers-sur-Boeme',
    'Mouthe',
    'Mouterre-sur-Blourde',
    'Mouterre-Silly',
    'Mouterhouse',
    'Moustoir-Ac',
    'Moustiers-Sainte-Marie',
    'Moustier',
    'Moustey',
    'Mousteru',
    'Moussy-le-Vieux',
    'Moussy-le-Neuf',
    'Moussy',
    'Moussoulens',
    'Moussey',
    'Mousseaux-sur-Seine',
    'Mousseaux-Neuville',
    'Moussan',
    'Moussac',
    'Mours-Saint-Eusebe',
    'Mouroux',
    'Mourmelon-le-Grand',
    'Mourioux-Vieilleville',
    'Mouries',
    'Mourenx',
    'Mounes-Prohencoux',
    'Moumour',
    'Moult',
    'Moulon',
    'Moulle',
    'Moulismes',
    'Moulis',
    'Moulins-sur-Yevre',
    'Moulins-les-Metz',
    'Moulins-le-Carbonnel',
    'Moulins-la-Marche',
    'Moulins-en-Tonnerrois',
    'Moulins',
    'Moulin-Neuf',
    'Moulin-Mage',
    'Moulineaux',
    'Mouliherne',
    'Mouliets-et-Villemartin',
    'Moulhard',
    'Mouleydier',
    'Moules',
    'Mouledous',
    'Moulay',
    'Moulares',
    'Mouilleron-le-Captif',
    'Mouilleron-en-Pareds',
    'Mouguerre',
    'Mougon',
    'Mougins',
    'Mouflieres',
    'Mouettes',
    'Mouen',
    'Mouchin',
    'Mouchard',
    'Mouchamps',
    'Mouaze',
    'Mouans-Sartoux',
    'Mouais',
    'Motz',
    'Mottier',
    'Motreff',
    'Mothern',
    'Mosnes',
    'Mosnay',
    'Mosnac',
    'Morzine',
    'Morvilliers',
    'Morville-sur-Andelle',
    'Morvillers',
    'Mortree',
    'Morthomiers',
    'Mortefontaine-en-Thelle',
    'Mortefontaine',
    'Morteaux-Couliboeuf',
    'Morteau',
    'Mortcerf',
    'Mortain',
    'Mortagne-sur-Sevre',
    'Mortagne-du-Nord',
    'Mortagne-au-Perche',
    'Morschwiller-le-Bas',
    'Morsbach',
    'Morsang-sur-Orge',
    'Morsan',
    'Morre',
    'Morosaglia',
    'Morogues',
    'Moroges',
    'Mornay-sur-Allier',
    'Mornas',
    'Mornant',
    'Mornac-sur-Seudre',
    'Mornac',
    'Mormoiron',
    'Mormant',
    'Morlet',
    'Morlancourt',
    'Morlaix',
    'Morlac',
    'Morlaas',
    'Moriville',
    'Morisel',
    'Morillon',
    'Morigny-Champigny',
    'Moriez',
    'Morieux',
    'Moriers',
    'Morieres-les-Avignon',
    'Morienval',
    'Morhange',
    'Morgny',
    'Morgemoulin',
    'Morfontaine',
    'Morez',
    'Morey-Saint-Denis',
    'Moreuil',
    'Morette',
    'Moret-sur-Loing',
    'Morestel',
    'Moreilles',
    'Moree',
    'Moreac',
    'Mordelles',
    'Morcourt',
    'Morchain',
    'Morcenx',
    'Morbier',
    'Morbecque',
    'Moras-en-Valloire',
    'Moranville',
    'Morannes',
    'Morangis',
    'Morancez',
    'Morance',
    'Morainvilliers',
    'Moragne',
    'Moosch',
    'Montville',
    'Montviette',
    'Montvicq',
    'Montvernier',
    'Montverdun',
    'Montvendre',
    'Montvalezan',
    'Montussan',
    'Montsurvent',
    'Montsurs',
    'Mont-sur-Monnet',
    'Monts-sur-Guesnes',
    'Mont-sous-Vaudrey',
    'Montsoult',
    'Montsoreau',
    'Montseveroux',
    'Montseret',
    'Montsegur-sur-Lauzon',
    'Montsecret',
    'Mont-Saxonnex',
    'Montsaugeon',
    'Montsauche-les-Settons',
    'Montsalvy',
    'Mont-Saint-Sulpice',
    'Mont-Saint-Pere',
    'Mont-Saint-Martin',
    'Mont-Saint-Aignan',
    'Monts',
    'Montry',
    'Montroy',
    'Montrouge',
    'Montrottier',
    'Montrond-les-Bains',
    'Montrond-le-Chateau',
    'Montrond',
    'Montromant',
    'Montrodat',
    'Montriond',
    'Montrieux-en-Sologne',
    'Montricoux',
    'Montricher-Albanne',
    'Montrichard',
    'Montrevel-en-Bresse',
    'Montrevault',
    'Montreux-Vieux',
    'Montreux-Chateau',
    'Montreuil-sur-Mer',
    'Montreuil-sur-Maine',
    'Montreuil-sur-Lozon',
    'Montreuil-sur-Ille',
    'Montreuil-sur-Epte',
    'Montreuil-sur-Blaise',
    'Montreuil-sur-Barse',
    'Montreuil-sous-Perouse',
    'Montreuil-le-Gast',
    'Montreuil-le-Chetif',
    "Montreuil-l'Argille",
    'Montreuil-Bonnin',
    'Montreuil-Bellay',
    'Montreuil-Juigne',
    'Montreuil-aux-Lions',
    'Montreuil',
    'Montret',
    'Montrequienne',
    'Montrem',
    'Montrelais',
    'Montrejeau',
    'Montredon-des-Corbieres',
    'Montrécourt',
    'Montreal-la-Cluse',
    'Montreal',
    'Montracol',
    'Montrabe',
    'Mont-pres-Chambord',
    'Montpothier',
    'Montpon-Menesterol',
    'Montplonne',
    'Montpitol',
    'Montpinchon',
    'Montpezat-sous-Bauzon',
    'Montpezat-de-Quercy',
    'Montpezat',
    'Montpeyroux',
    'Montperreux',
    'Montpellier',
    'Montours',
    'Montournais',
    'Montoulieu',
    'Montolieu',
    'Montoison',
    'Montois-la-Montagne',
    'Montoire-sur-le-Loir',
    'Montoir-de-Bretagne',
    'Mont-Notre-Dame',
    'Montner',
    'Montmorot',
    'Montmorin',
    'Montmorillon',
    'Montmorency',
    'Montmoreau-Saint-Cybard',
    'Montmirat',
    'Montmirail',
    'Montmin',
    'Montmeyran',
    'Montmerrei',
    'Montmerle-sur-Saone',
    'Montmelian',
    'Montmédy',
    'Montmaur',
    'Montmartin-sur-Mer',
    'Montmartin-en-Graignes',
    'Montmartin',
    'Montmarault',
    'Montmain',
    'Montmagny',
    'Montmacq',
    'Montluel',
    'Montluçon',
    'Montlouis-sur-Loire',
    'Montlivault',
    'Montlignon',
    'Montliard',
    'Montlhery',
    'Montlevon',
    'Mont-le-Vignoble',
    'Mont-le-Vernois',
    'Montlebon',
    'Montlaur',
    'Montlandon',
    'Montjoyer',
    'Montjoux',
    'Montjoire',
    'Montjoie-Saint-Martin',
    'Montjoie-en-Couserans',
    'Montjean-sur-Loire',
    'Montjean',
    'Montjay',
    'Montjavoult',
    'Montjaux',
    'Montivilliers',
    'Montirat',
    'Montils',
    'Montilly',
    'Montillot',
    'Montilliers',
    'Montigny-sur-Loing',
    "Montigny-sur-l'Ain",
    'Montigny-sur-Aube',
    'Montigny-sur-Armancon',
    'Montigny-Saint-Barthelemy',
    'Montigny-Montfort',
    'Montigny-lès-Metz',
    'Montigny-les-Cormeilles',
    'Montigny-Lengrain',
    'Montigny-Lencoup',
    'Montigny-le-Bretonneux',
    'Montigny-la-Resle',
    "Montigny-l'Allier",
    'Montigny-en-Ostrevent',
    'Montigny-en-Gohelle',
    'Montigny',
    'Montigne-sur-Moine',
    'Montigne-le-Brillant',
    'Montignargues',
    'Montignac-le-Coq',
    'Montignac-de-Lauzun',
    'Montignac-Charente',
    'Monties',
    'Montiers',
    "Montier-en-l'Isle",
    'Montier-en-Der',
    'Montierchaume',
    'Montieramey',
    'Monticello',
    'Monthyon',
    'Monthureux-sur-Saone',
    'Monthureux-le-Sec',
    'Monthou-sur-Bievre',
    'Monthois',
    'Monthodon',
    'Monthieux',
    'Monthiers',
    'Montherme',
    'Monthaut',
    'Montguyon',
    'Montgivray',
    'Montgiscard',
    'Montgirod',
    'Montgesty',
    'Montgesoye',
    'Montgeron',
    'Montgermont',
    'Montgerain',
    'Montgenost',
    'Montgenevre',
    'Montgeard',
    'Montge-en-Goele',
    'Montgardon',
    'Montgaillard',
    'Montfrin',
    'Montfort-sur-Risle',
    'Montfort-sur-Meu',
    'Montfort-le-Gesnois',
    "Montfort-l'Amaury",
    'Montfort-en-Chalosse',
    'Montfort',
    'Montflovin',
    'Montflours',
    'Montfleur',
    'Montfiquet',
    'Montferrier-sur-Lez',
    'Montferrier',
    'Montferrat',
    'Montferrand-le-Chateau',
    'Montferrand-du-Perigord',
    'Montferrand',
    'Montfermeil',
    'Montfavet',
    'Montfaucon',
    'Montfaucon-en-Velay',
    'Montfarville',
    'Monteynard',
    'Montevrain',
    'Monteux',
    'Montestruc-sur-Gers',
    'Montesson',
    'Montessaux',
    'Montesquiou',
    'Montesquieu-Volvestre',
    'Montesquieu',
    "Montesquiu d'Albera",
    'Montespan',
    'Montescourt-Lizerolles',
    'Montescot',
    'Monterrein',
    'Monterolier',
    'Monterfil',
    'Montereau-sur-le-Jard',
    'Montereau-Fault-Yonne',
    'Monterblanc',
    'Montenoy',
    'Montenoison',
    'Montenois',
    'Montenescourt',
    'Montendre',
    'Montenay',
    'Montenach',
    'Montemboeuf',
    'Montélimar',
    'Montelier',
    'Monteleger',
    'Monteils',
    'Montegut-Saves',
    'Montegut-Arros',
    'Montegut',
    'Montech',
    'Montebourg',
    'Monteaux',
    'Monte',
    'Montdragon',
    'Montdidier',
    'Mont-de-Marsan',
    'Montdauphin',
    'Montcuq',
    'Montcresson',
    'Montcoy',
    'Montcourt-Fromonville',
    'Montcornet',
    'Montcorbon',
    'Montclera',
    'Montclar',
    'Montchenu',
    'Montchanin',
    'Montchamp',
    'Montcey',
    'Montcenis',
    'Montcel',
    "Montceaux-l'Etoile",
    'Montceaux',
    'Montceau-les-Mines',
    'Montceau',
    'Mont-Cauvaire',
    'Montcaret',
    'Montcabrier',
    'Montbrun-Lauragais',
    'Montbronn',
    'Montbron',
    'Montbrison',
    'Montbrehain',
    'Montbray',
    'Montboyer',
    'Montbouy',
    'Montbouton',
    'Montboucher-sur-Jabron',
    'Montboucher',
    'Mont-Bonvillers',
    'Montbonnot-Saint-Martin',
    'Montboillon',
    'Montblanc',
    'Montbizot',
    'Montbeton',
    'Montbert',
    'Montberon',
    'Montbernard',
    'Montberaud',
    'Montbenoit',
    'Montbellet',
    'Montbéliard',
    'Montbazon',
    'Montbazin',
    'Montbazens',
    'Montbartier',
    'Montbarrey',
    'Montbard',
    'Montazels',
    'Montayral',
    'Montauville',
    'Montautour',
    'Montaut',
    'Montauroux',
    'Montauriol',
    'Montaure',
    'Montaulin',
    'Montaudin',
    'Montaud',
    'Montauban-de-Bretagne',
    'Montauban',
    'Montataire',
    'Montastruc-la-Conseillere',
    'Montarnaud',
    'Montargis',
    'Montaren-et-Saint-Mediers',
    'Montardon',
    'Montardit',
    'Montans',
    'Montanges',
    'Montaner',
    'Montanay',
    'Montamy',
    'Montamise',
    'Montalivet-les-Bains',
    'Montalieu',
    'Montainville',
    'Montailleur',
    'Montaigut-sur-Save',
    'Montaigut-le-Blanc',
    'Montaigu-de-Quercy',
    'Montaigu',
    'Montagudet',
    'Montagrier',
    'Montagny-sur-Grosne',
    'Montagny-pres-Louhans',
    'Montagny-les-Lanches',
    'Montagny-les-Buxy',
    'Montagny',
    'Montagnole',
    'Montagnieu',
    'Montagney',
    'Montagne',
    'Montagnat',
    'Montagnac-la-Crempse',
    'Montagnac',
    'Montady',
    'Mont',
    'Monswiller',
    'Monsteroux-Milieu',
    'Monsols',
    'Monsireigne',
    'Mons-en-Montois',
    'Mons-en-Chaussee',
    'Mons-en-Baroeul',
    'Monsempron-Libos',
    'Monsegur',
    'Monsac',
    'Mons',
    'Monprimblanc',
    'Monplaisant',
    'Monpazier',
    'Monoblet',
    'Monnieres',
    'Monneville',
    'Monnetier-Mornex',
    'Monnes',
    'Monnerville',
    'Monneren',
    'Monnaie',
    'Monleon-Magnoac',
    'Monlaur-Bernet',
    'Monistrol-sur-Loire',
    'Monieux',
    'Mongauzy',
    'Monfreville',
    'Monfort',
    'Monflanquin',
    'Monferran-Saves',
    'Monfaucon',
    'Moneteau',
    'Monesties',
    'Monestier-de-Clermont',
    'Monestier',
    'Monein',
    'Mondreville',
    'Mondrainville',
    'Mondragon',
    'Mondoubleau',
    'Mondonville',
    'Mondigny',
    'Mondeville',
    'Mondevert',
    'Mondelange',
    'Mondavezan',
    'Moncoutant',
    'Moncontour',
    'Moncley',
    'Monclar-de-Quercy',
    'Monchy-Saint-Eloi',
    'Monchy-le-Preux',
    'Monchy-Lagache',
    'Monchy-Humieres',
    'Monchy-Cayeux',
    'Monchy-Breton',
    'Monchecourt',
    'Moncey',
    'Moncel-sur-Seille',
    'Monce-en-Belin',
    'Monceau-Saint-Waast',
    'Monceau-le-Neuf-et-Faucouzy',
    'Monbrun',
    'Monbequi',
    'Monbazillac',
    'Monbalen',
    'Monay',
    "Monacia-d'Aullene",
    'Mommenheim',
    'Mombrier',
    'Momas',
    'Molsheim',
    'Molphey',
    'Mollkirch',
    'Molles',
    'Molleges',
    'Mollans-sur-Ouveze',
    'Molitg-les-Bains',
    'Molinons',
    'Molinges',
    'Molineuf',
    'Molinet',
    'Moliets-et-Maa',
    'Molieres-Cavaillac',
    'Molieres',
    'Moliens',
    'Molesmes',
    'Moleans',
    'Molay',
    'Molandier',
    'Molac',
    'Moitron-sur-Sarthe',
    'Moissy-Cramayel',
    'Moisson',
    'Moissey',
    'Moisselles',
    'Moissat Haut',
    'Moissannes',
    'Moissac',
    'Moislains',
    'Moisenay',
    'Moisdon-la-Riviere',
    'Moire',
    'Moirax',
    'Moirans-en-Montagne',
    'Moirans',
    'Moingt',
    'Moineville',
    'Moimay',
    'Moigny-sur-Ecole',
    'Moigne',
    'Moidieu',
    'Mohon',
    'Mogneville',
    'Mogneneins',
    'Mognard',
    'Moeze',
    'Moernach',
    'Moelan-sur-Mer',
    'Modane',
    'Mobecq',
    'Mitzach',
    'Mittelwihr',
    'Mittelhausbergen',
    'Mittelbergheim',
    'Mittainville',
    'Mitry-Mory',
    'Misy-sur-Yonne',
    'Missy-sur-Aisne',
    'Missy-aux-Bois',
    'Missillac',
    'Missecle',
    'Mison',
    'Misery',
    'Miserieux',
    'Miserey-Salines',
    'Miserey',
    'Miscon',
    'Mirville',
    'Mirmande',
    'Miribel-les-Echelles',
    'Miribel-Lanchatre',
    'Miribel',
    'Mirepoix',
    'Mirepeix',
    'Miremont',
    'Mirefleurs',
    'Mirecourt',
    'Mirebeau-sur-Beze',
    'Mirebeau',
    'Mire',
    'Miraumont',
    'Mirandol-Bourgnounac',
    'Mirande',
    'Miramont-de-Guyenne',
    'Mirambeau',
    'Miramas',
    'Mirabel-et-Blacons',
    'Mirabel',
    'Mirabeau',
    'Miossens-Lanusse',
    'Mios',
    'Mions',
    'Mionnay',
    'Minzier',
    'Minversheim',
    'Minorville',
    'Minihy-Treguier',
    'Miniac-Morvan',
    'Mimizan',
    'Mimeure',
    'Mimet',
    'Mimbaste',
    'Milly-sur-Therain',
    'Milly-la-Foret',
    'Milly',
    'Millery',
    'Millay',
    'Millau',
    'Millas',
    'Millancay',
    'Millam',
    'Millac',
    'Milizac',
    'Milhaud',
    'Milhas',
    'Milhac-de-Nontron',
    'Mijoux',
    'Migron',
    'Mignovillard',
    'Mignieres',
    'Migneville',
    'Migne-Auxances',
    'Mignavillers',
    'Mignaloux-Beauvoir',
    'Migennes',
    'Mieuxce',
    'Mieussy',
    'Mietesheim',
    'Mielan',
    'Michery',
    'Miannay',
    'Mialet',
    'Mezzavia',
    'Mezy-Moulins',
    'Mezy-sur-Seine',
    'Mezire',
    'Mezin',
    'Mezilles',
    'Mezieres-sur-Seine',
    'Mezieres-sur-Oise',
    'Mezieres-sur-Issoire',
    'Mezieres-sur-Couesnon',
    'Mezieres-lez-Clery',
    'Mezieres-en-Gatinais',
    'Mezieres-en-Drouais',
    'Mezieres-en-Brenne',
    'Mezidon-Canon',
    'Mezerville',
    'Mezerolles',
    'Mezeriat',
    'Mezeres',
    'Mezeray',
    'Mezel',
    'Meze',
    'Mezangers',
    'Meyzieu',
    'Meythet',
    'Meysse',
    'Meyssac',
    'Meys',
    'Meyrueis',
    'Meyronnes',
    'Meyronne',
    'Meyrieu-les-Etangs',
    'Meyrie',
    'Meyriat',
    'Meyreuil',
    'Meyras',
    'Meyrargues',
    'Meyrannes',
    'Meyrals',
    'Meynes',
    'Meymac',
    'Meylan',
    'Meyenheim',
    'Mexy',
    'Meximieux',
    'Mevoisins',
    'Meuzac',
    'Meuvaines',
    'Meusnes',
    'Meuse',
    'Meursault',
    'Meursanges',
    'Meursac',
    'Meures',
    'Meurchin',
    'Meurce',
    'Meung-sur-Loire',
    'Meunet-Planches',
    'Meulan-en-Yvelines',
    'Meudon',
    'Meucon',
    'Metz',
    'Metz-Robert',
    'Metzervisse',
    'Metzeresche',
    'Metzeral',
    'Metz-en-Couture',
    'Mettray',
    'Methamis',
    'Heille',
    'Metabief',
    'Mesvres',
    'Messy',
    'Messon',
    'Messincourt',
    'Messimy-sur-Saone',
    'Messimy',
    'Messigny-et-Vantoux',
    'Messia-sur-Sorne',
    'Messery',
    'Messeix',
    'Messei',
    'Messein',
    'Messe',
    'Messanges',
    'Messac',
    'Mesquer',
    'Mespaul',
    'Mesnil-Verclives',
    'Mesnil-Val-Plage',
    "Mesnil-sur-l'Estree",
    'Mesnil-Panneville',
    'Mesnil-Clinchamps',
    'Mesnieres-en-Bray',
    'Mesnay',
    'Mesnard-la-Barotiere',
    'Mesnac',
    'Mesmont',
    'Meslin',
    'Meslieres',
    'Meslay-du-Maine',
    'Meslay',
    'Mesland',
    'Meslan',
    'Mesigny',
    'Mesgrigny',
    'Meschers-sur-Gironde',
    'Mesangueville',
    'Mesanger',
    'Mery-sur-Seine',
    'Mery-sur-Oise',
    'Mery-sur-Marne',
    'Mery-sur-Cher',
    'Mery-la-Bataille',
    'Mery-Corbon',
    'Mery',
    'Merxheim',
    'Merviller',
    'Merville-Franceville-Plage',
    'Merville',
    'Mervent',
    'Mervans',
    'Meru',
    'Mertzwiller',
    'Mertrud',
    'Merten',
    'Mers-les-Bains',
    'Merris',
    'Merrey-sur-Arce',
    'Merpins',
    'Meroux',
    'Merouville',
    'Mernel',
    'Merlines',
    'Merlimont',
    'Merlevenez',
    'Merlas',
    'Merkwiller-Pechelbronn',
    'Meritein',
    'Merindol',
    'Merignies',
    'Merignas',
    'Merignac',
    'Mérignac',
    'Meriel',
    'Mericourt-sur-Somme',
    'Mericourt',
    'Méribel',
    'Merfy',
    'Merey-sous-Montrond',
    'Mereville',
    'Mereglise',
    'Mereau',
    'Mere',
    'Merdrignac',
    'Mercy-le-Bas',
    'Mercus-Garrabet',
    'Mercury',
    'Mercurol-Veaunes',
    'Mercues',
    'Mercoeur',
    'Merck-Saint-Lievin',
    'Merckeghem',
    'Mercin-et-Vaux',
    'Mercey',
    'Merceuil',
    'Mercatel',
    'Mer',
    'Meounes-les-Montrieux',
    'Menville',
    'Menucourt',
    'Menton',
    'Menthon-Saint-Bernard',
    'Menthonnex-en-Bornes',
    'Mensignac',
    'Mens',
    'Menonval',
    'Menoncourt',
    'Menomblet',
    'Mennevret',
    'Menneville',
    'Menneval',
    'Mennetou-sur-Cher',
    'Mennessis',
    'Mennecy',
    'Menilles',
    'Menil-Hubert-sur-Orne',
    'Menil-en-Xaintois',
    'Menigoute',
    'Menglon',
    'Menetrol',
    'Menetreols-sous-Vatan',
    'Menetou-Salon',
    'Menetou-Couture',
    'Menestreau-en-Villette',
    'Menesplet',
    'Menerbes',
    'Meneac',
    'Menditte',
    'Mendionde',
    'Mende',
    'Menchhoffen',
    'Menat',
    'Memenil',
    'Membrolles',
    'Melz-sur-Seine',
    'Melve',
    'Melun',
    'Melrand',
    'Meloisey',
    'Mello',
    'Melleville',
    'Melleray',
    'Mellecey',
    'Melle',
    'Mellac',
    'Melisey',
    'Melincourt',
    'Melicocq',
    'Melgven',
    'Melesse',
    'Melay',
    'Melamare',
    'Mejannes-les-Ales',
    'Mejannes-le-Clap',
    'Meistratzheim',
    'Meisenthal',
    'Meillonnas',
    'Meillers',
    'Meillerie',
    'Meillant',
    'Meillac',
    'Meilhaud',
    'Meilhan',
    'Meilhac',
    'Meigneux',
    'Mehun-sur-Yevre',
    'Mehoncourt',
    'Mehers',
    'Megrit',
    'Megevette',
    'Megève',
    'Mees',
    'Mee',
    'Medreac',
    'Medonville',
    'Medis',
    'Medavy',
    'Medan',
    'Mecringes',
    'Mecleuves',
    'Mechmont',
    'Mece',
    'Meauzac',
    'Meaux-la-Montagne',
    'Meaux',
    'Meautis',
    'Meaulte',
    'Meaudre',
    'Measnes',
    'Mazinghien',
    'Mazingarbe',
    'Mazieres-en-Mauges',
    'Mazieres-en-Gatine',
    'Mazieres-de-Touraine',
    'Mazeyrolles',
    'Mazet-Saint-Voy',
    'Mazerolles',
    'Mazerier',
    'Mazeres-Lezons',
    'Mazeres',
    'Mazeray',
    'Maze',
    'Mazaye',
    'Mazaugues',
    'Mazange',
    'Mazan',
    'Mazamet',
    'May-sur-Orne',
    'Mayrinhac-Lentour',
    'Mayran',
    'Mayrac',
    'Maynal',
    'Maylis',
    'Mayet',
    'Mayenne',
    'May-en-Multien',
    'Mayac',
    'Maxilly-sur-Leman',
    'Maxeville',
    'Maxent',
    'Maves',
    'Mauze-sur-le-Mignon',
    'Mauzens-et-Miremont',
    'Mauzac-et-Grand-Castang',
    'Mauzac',
    'Mauvieres',
    'Mauvezin-sur-Gupie',
    'Mauvezin',
    'Mauves-sur-Loire',
    'Mauves',
    'Maussane-les-Alpilles',
    'Maussac',
    'Maury',
    'Maurupt-le-Montois',
    'Maurs',
    'Mauron',
    'Mauressac',
    'Maurepas',
    'Maurens',
    'Mauremont',
    'Maureillas-las-Illas',
    'Maureilhan',
    'Maure-de-Bretagne',
    'Maurecourt',
    'Maure',
    'Mauquenchy',
    'Mauprevoir',
    'Mauny',
    'Maumusson',
    'Maulevrier',
    'Maulette',
    'Mauleon-Licharre',
    'Maule',
    'Maulde',
    'Maulaix',
    'Mauguio',
    'Maudetour-en-Vexin',
    'Maucourt-sur-Orne',
    'Maucor',
    'Maucomble',
    'Maubourguet',
    'Maubeuge',
    'Maubec',
    'Matzenheim',
    'Matton-et-Clemency',
    'Matour',
    'Matignon',
    'Mathieu',
    'Mathenay',
    'Mathay',
    'Mathaux',
    'Matha',
    'Matafelon-Granges',
    'Mas-Thibert',
    'Mastaing',
    'Massy',
    'Massugas',
    'Massoules',
    'Massongy',
    'Massoins',
    'Massognes',
    'Massingy',
    'Massignieu-de-Rives',
    'Massignac',
    'Massieux',
    'Massieu',
    'Massiac',
    'Masseube',
    'Masseret',
    'Massay',
    'Massat',
    'Massais',
    'Mas-Saintes-Puelles',
    'Massac-Seran',
    'Massac',
    'Masquieres',
    'Masparraute',
    'Masny',
    'Masnieres',
    'Maslives',
    'Masleon',
    'Maslacq',
    'Mas-Grenier',
    'Masevaux',
    'Mas-de-Londres',
    "Mas-d'Auvignon",
    'Masclat',
    'Mascaras',
    'Marzy',
    'Marzan',
    'Mary-sur-Marne',
    'Marville',
    'Marvejols',
    'Martres-Tolosane',
    'Les Martres-de-Veyre',
    'Les Martres-dArtière',
    'Martot',
    'Martinvast',
    'Martinet',
    'Martin-Eglise',
    'Martillac',
    'Martigues',
    'Martigny-le-Comte',
    'Martigny',
    'Martigne-Ferchaud',
    'Martigne-Briand',
    'Martigne-sur-Mayenne',
    'Martignat',
    'Martignas-sur-Jalle',
    'Martignargues',
    'Martiel',
    'Marthod',
    'Marthemont',
    'Martel',
    'Martaize',
    'Martainville-Epreville',
    'Martainville',
    'Marssac-sur-Tarn',
    'Marspich',
    'Marsonnas',
    'Mars-la-Tour',
    'Marsilly',
    'Marsillargues',
    'Marseillette',
    'Marseille-en-Beauvaisis',
    'Marseille',
    'Marseillan',
    'Marsat',
    'Marsas',
    'Marsanne',
    'Marsannay-le-Bois',
    'Marsannay-la-Côte',
    'Marsangy',
    'Marsangis',
    'Marsaneix',
    'Marsan',
    'Marsal',
    'Marsainvilliers',
    'Marsac-sur-Don',
    'Marsac-en-Livradois',
    'Marsac',
    'Marsac-sur-lIsle',
    'Mars',
    'Marray',
    'Marquixanes',
    'Marquivillers',
    'Marquise',
    'Marquion',
    'Marquillies',
    'Marquette-lez-Lille',
    'Marquette-en-Ostrevant',
    'Marques',
    'Marqueglise',
    'Marquefave',
    'Marquay',
    'Marpent',
    'Maroue',
    'Maron',
    'Maromme',
    'Marols',
    'Marolles-sur-Seine',
    'Marolles-les-Braults',
    'Marolles-en-Hurepoix',
    'Marolles-en-Brie',
    'Marolles',
    'Maroeuil',
    'Marnoz',
    'Marnes-la-Coquette',
    'Marnaz',
    'Marnay-sur-Marne',
    'Marnay',
    'Marmoutier',
    'Marmande',
    'Marmagne',
    'Marly',
    'Marly-le-Roi',
    'Marly-la-Ville',
    'Marly-Gomont',
    'Marlieux',
    'Marliens',
    'Marlhes',
    'Marles-sur-Canche',
    'Marles-les-Mines',
    'Marlens',
    'Marlenheim',
    'Marle',
    'Maringues',
    'Maringes',
    'Marines',
    'Farinole',
    'Marin',
    'Marimbault',
    'Marillac-le-Franc',
    'Marigny-les-Usages',
    'Marigny-le-Chatel',
    'Marigny-le-Cahouet',
    'Marigny-en-Orxois',
    'Marigny',
    'Marignier',
    'Marigne-Peuton',
    'Marigne',
    'Marignane',
    'Marignana',
    'Marignac-Lasclares',
    'Marignac',
    'Marieux',
    'Marieulles',
    'Mariac',
    'Margut',
    'Marguestau',
    'Marguerittes',
    'Margon',
    'Margny-les-Compiegne',
    'Margny',
    'Margival',
    'Marges',
    'Margerides',
    'Margencel',
    'Margaux',
    'Marfaux',
    'Mareuil-sur-Ourcq',
    'Mareuil-sur-Lay-Dissais',
    'Mareuil-sur-Cher',
    'Mareuil-sur-Ay',
    'Mareuil-la-Motte',
    'Mareuil-Caubert',
    'Marest-sur-Matz',
    'Maresquel-Ecquemicourt',
    'Maresches',
    'Maresche',
    'Marennes',
    'Mareil-sur-Mauldre',
    'Mareil-sur-Loir',
    'Mareil-Marly',
    'Mareilles',
    'Mareil-en-France',
    'Mareil-en-Champagne',
    'Mareau-aux-Pres',
    'Mardore',
    'Mardie',
    'Mardeuil',
    'Marcy-sous-Marle',
    "Marcy-l'Etoile",
    'Marcy',
    'Marcq-en-Baroeul',
    'Marcq',
    'Marcoussis',
    'Marcorignan',
    'Marconnelle',
    'Marconne',
    'Marcon',
    'Marcoles',
    'Marcoing',
    'Marckolsheim',
    'Marck',
    'Marcilly-sur-Tille',
    'Marcilly-sur-Eure',
    'Marcilly-le-Chatel',
    'Marcilly-le-Hayer',
    'Marcilly-en-Villette',
    "Marcilly-d'Azergues",
    'Marcilly',
    'Marcilloles',
    'Marcille-Robert',
    'Marcillat-en-Combraille',
    'Marcillac-Vallon',
    'Marcillac-la-Croisille',
    'Marcigny',
    'Marciac',
    'Marchiennes',
    'Marchezais',
    'Marchesieux',
    'Marcheprime',
    'Marchemoret',
    'Marchemaisons',
    'Marchelepot',
    'Marchaux',
    'Marchampt',
    'Marchamp',
    'Marcey-les-Greves',
    'Marcenod',
    'Marcenat',
    'Marcellus',
    'Marcellaz-Albanais',
    'Marce',
    'Marcay',
    'Marby',
    'Marboz',
    'Marboue',
    'Marbeuf',
    'Marbaix',
    'Marbache',
    'Maravat',
    'Maraussan',
    'Marault',
    'Marat',
    'Maransin',
    'Marans',
    'Marange-Zondrange',
    'Marange-Silvange',
    'Marainviller',
    'Manziat',
    'Manzat',
    'Many',
    'Manvieux',
    'Mantry',
    'Manthes',
    'Manthelan',
    'Manteyer',
    'Mantes-la-Ville',
    'Mantes-la-Jolie',
    'Manspach',
    'Mansle',
    'Mansigne',
    'Mansac',
    'Manou',
    'Manosque',
    'Manonville',
    'Manom',
    'Mannevillette',
    'Manneville-sur-Risle',
    'Manneville-la-Pipard',
    'Manneville-es-Plains',
    'Maniquerville',
    'Maninghem',
    'Manigod',
    'Manheulles',
    'Mangonville',
    'Manglieu',
    'Mangiennes',
    'Maneglise',
    'Mane',
    'Manduel',
    'Mandres-sur-Vair',
    'Mandres-les-Roses',
    'Mandres',
    'Mandeville-en-Bessin',
    'Mandeville',
    'Mandeure',
    'Mandelieu-la-Napoule',
    'Mandailles-Saint-Julien',
    'Mancieulles',
    'Manciet',
    'Manchecourt',
    'Mancenans',
    'Manaurie',
    'Manas',
    'Mamirolle',
    'Mamey',
    'Mametz',
    'Mamers',
    'Malzeville',
    'Malville',
    'Malvies',
    'Malves-en-Minervois',
    'Maltot',
    'Maltat',
    'Malroy',
    'Malpas',
    'Malmont',
    'Malmerspach',
    'Malleval',
    'Mallemort',
    'Mallemoisson',
    'Malleloy',
    'Malissard',
    'Malintrat',
    'Malijai',
    'Maligny',
    'Malicorne-sur-Sarthe',
    'Malicorne',
    'Malguenac',
    'Maleville',
    'Malestroit',
    'Malesherbes',
    'Malemort-sur-Correze',
    'Malemort-du-Comtat',
    'Malbuisson',
    'Malay-le-Grand',
    'Malavillers',
    'Malauzat',
    'Malause',
    'Malaunay',
    'Malaucourt-sur-Seille',
    'Malaucene',
    'Malataverne',
    'Malansac',
    'Malange',
    'Malakoff',
    'Malain',
    'Malafretaz',
    'Maizy',
    'Maizieres-les-Metz',
    'Maizieres-la-Grande-Paroisse',
    'Maizieres',
    'Maixe',
    'Maissemy',
    'Maisse',
    'Maisons-Laffitte',
    'Maisonsgoutte',
    'Maisons-du-Bois-Lievremont',
    'Maisons-Alfort',
    'Maisons',
    'Maison-Rouge',
    'Maisonnisses',
    'Maisonnais',
    'Maison-Maugis',
    'Maisoncelles-sur-Ajon',
    'Maisoncelles-en-Gatinais',
    'Maisnil',
    'Maisdon-sur-Sevre',
    'Mairieux',
    'Maire-Levescault',
    'Mainzac',
    'Mainxe',
    'Mainvilliers',
    'Maintenon',
    'Mainsat',
    'Mainfonds',
    'Maincy',
    'Mainbresson',
    'Mailly-Maillet',
    'Mailly-le-Camp',
    'Mailly-la-Ville',
    'Mailly-Champagne',
    'Mailly',
    'Maillot',
    'Maillezais',
    'Mailleroncourt-Charette',
    'Maillebois',
    'Maille',
    'Maillat',
    'Maillane',
    'Mailhac-sur-Benaize',
    'Mailhac',
    'Maignelay-Montigny',
    'Maidieres',
    'Maiche',
    'Maheru',
    'Magny-Vernois',
    'Magny-Saint-Medard',
    'Magny-les-Hameaux',
    'Magny-le-Hongre',
    'Magny-le-Freule',
    'Magny-en-Vexin',
    'Magny-Cours',
    'Magny',
    'Magnieu',
    'Magnieres',
    'Magnet',
    'Magne',
    "Magnat-l'Etrange",
    'Magnanville',
    'Magnac-sur-Touvre',
    'Magnac-Laval',
    'Magnac-Bourg',
    'Magland',
    'Magescq',
    'Magenta',
    'Magalas',
    'Magagnosc',
    'Maffliers',
    'Mael-Pestivien',
    'Mael-Carhaix',
    'Madranges',
    'Madiran',
    'Macquigny',
    'Macot-la-Plagne',
    'Macornay',
    'Mâcon',
    'Macogny',
    'Maclas',
    'Mackwiller',
    'Mackenheim',
    'Machy',
    'Machilly',
    'Macheren',
    'Machemont',
    'Machecoul',
    'Mache',
    'Machault',
    'Macey',
    'Macau',
    'Mably',
    'Lys-lez-Lannoy',
    'Lys',
    'Lyons-la-Forêt',
    'Lyon',
    'Lynde',
    'Lyaud',
    'Lyas',
    'Luzy',
    'Luz-Saint-Sauveur',
    'Luzinay',
    'Luzille',
    'Luzillat',
    'Luzenac',
    'Luzech',
    'Luze',
    'Luzarches',
    'Luzancy',
    'Luynes',
    'Luyeres',
    'Luxey',
    'Luxeuil-les-Bains',
    'Luxe-Sumberraute',
    'Lux',
    'Lutz-en-Dunois',
    'Lutzelhouse',
    'Lutzelbourg',
    'Lutterbach',
    'Lutter',
    'Luttange',
    'Lutilhous',
    'Luthenay-Uxeloup',
    'Lussat',
    'Lussas',
    'Lussan-Adeilhac',
    'Lussan',
    'Lussagnet-Lusson',
    'Lussac-les-Eglises',
    'Lussac-les-Chateaux',
    'Lus-la-Croix-Haute',
    'Lusigny-sur-Barse',
    'Lusigny',
    'Lusignan',
    'Lusanger',
    'Lurs',
    'Luriecq',
    'Lure',
    'Lurcy-Levis',
    'Lurcy-le-Bourg',
    'Lurbe-Saint-Christau',
    'Lupstein',
    'Luppy',
    'Luppe-Violles',
    'Luplante',
    'Lupe',
    "L'Union",
    'Lunéville',
    'Lunery',
    'Luneray',
    'Lunel-Viel',
    'Lunel',
    'Luneau',
    'Lunay',
    'Lunan',
    'Lunac',
    'Lumio',
    'Lumbres',
    'Lumbin',
    'Lully',
    'Luitre',
    'Luisetaines',
    'Luisant',
    'Lugrin',
    'Lugos',
    'Luglon',
    'Lugasson',
    'Lugan',
    'Lugaignac',
    'Luemschwiller',
    'Ludres',
    'Ludon-Medoc',
    'Ludes',
    'Lucy-le-Bocage',
    'Lucy',
    'Luc-sur-Orbieu',
    'Luc-sur-Mer',
    'Lucquy',
    'Lucon',
    'Lucmau',
    'Lucinges',
    'Luchy',
    'Luche-Thouarsais',
    'Luche-Pringe',
    'Luchat',
    'Lucey',
    'Luceram',
    'Lucenay-les-Aix',
    'Lucenay',
    'Luceau',
    'Luce',
    'Lucciana',
    'Lucbardez-et-Bargues',
    'Lucay-le-Male',
    'Luc',
    'Luc-la-Primaube',
    'Lubersac',
    'Luant',
    'Lozinghem',
    'Loze',
    'Lozanne',
    'Loyettes',
    'Loyes',
    'Loyat',
    'Loxeville',
    'Lovagny',
    'Louzy',
    'Louzignac',
    'Louvroil',
    'Louvres',
    'Louvigny',
    'Louvigne-du-Desert',
    'Louvigne-de-Bais',
    'Louvigne',
    'Louviers',
    'Louvetot',
    'Louversey',
    'Louverne',
    'Louvencourt',
    'Louvemont',
    'Louveciennes',
    'Loutzviller',
    'Loury',
    'Lourties-Monbrun',
    'Lournand',
    'Lourmarin',
    'Loures-Barousse',
    'Lourdes',
    'Lourde',
    'Lourches',
    'Louppy-sur-Loison',
    'Louplande',
    'Loupian',
    'Loupfougeres',
    'Loupershouse',
    'Loulay',
    'Louisfert',
    'Louhossoa',
    'Louhans',
    'Lougres',
    'Lougratte',
    'Louey',
    'Louestault',
    'Louerre',
    'Loue',
    'Loudun',
    'Loudrefing',
    'Loudes',
    'Loudeac',
    'Louchats',
    'Loucelles',
    'Loubressac',
    'Loublande',
    'Loubigne',
    'Loubieng',
    'Loubeyrat',
    'Loubes-Bernac',
    'Loubens-Lauragais',
    'Loubens',
    'Loubedat',
    'Louargat',
    'Louans',
    'Louannec',
    'Lothey',
    'Losne',
    'Los Masos',
    'Lorris',
    'Lorrez-le-Bocage-Preaux',
    'Lorquin',
    'Lormont',
    'Lormes',
    'Lormaye',
    'Lorlanges',
    'Loriol-sur-Drome',
    'Loriol-du-Comtat',
    'Lorigne',
    'Lorignac',
    'Lorient',
    'Lorgues',
    'Lorgies',
    'Lorges',
    'Lorette',
    'Lorcy',
    'Lorcieres',
    "L'Orbrie",
    'Loray',
    'Loperhet',
    'Loperec',
    'Loos-en-Gohelle',
    'Loos',
    'Loon-Plage',
    'Looberghe',
    'Lons-le-Saunier',
    'Lons',
    'Lonrai',
    'Lonny',
    "Lonlay-l'Abbaye",
    'Longwy',
    'Longvilliers',
    'Longvillers',
    'Longvic',
    'Longuyon',
    'Longueville-sur-Scie',
    'Longueville',
    'Longues-sur-Mer',
    'Longuerue',
    'Longuenesse',
    'Longueil-Sainte-Marie',
    'Longueil-Annel',
    'Longueil',
    'Longueau',
    'Longue-Jumelles',
    'Longraye',
    'Longpre-les-Corps-Saints',
    'Longpont-sur-Orge',
    'Longperrier',
    'Longny-au-Perche',
    'Longnes',
    'Longlaville',
    'Longjumeau',
    'Longfosse',
    "Longevilles-Mont-d'Or",
    'Longeville-les-Saint-Avold',
    'Longeville-les-Metz',
    'Longeville',
    'Longeville-sur-Mer',
    'Longeves',
    'Longessaigne',
    'Longes',
    'Longecourt-en-Plaine',
    'Longechenal',
    'Longeault',
    'Longeau-Percey',
    'Longchaumois',
    'Longchamps-sur-Aire',
    'Longchamp',
    'Longavesnes',
    'Longaulnay',
    'Longages',
    'Long',
    'Londinieres',
    'Lompret',
    'Lompnas',
    'Lommoye',
    'Lomme',
    'Lombron',
    'Lombreuil',
    'Lombez',
    'Lombers',
    'Loix',
    'Loivre',
    'Loisy-sur-Marne',
    'Loisy',
    'Loison-sous-Lens',
    'Loisin',
    'Loisieux',
    'Loiron',
    'Loire',
    'Loire-sur-Rhone',
    'Loigny-la-Bataille',
    'Loigne-sur-Mayenne',
    "L'Oie",
    'Loheac',
    'Loguivy-Plougras',
    'Logonna-Daoulas',
    'Logny-les-Aubenton',
    'Lognes',
    'Logelbach',
    'Lods',
    'Lodève',
    'Locunole',
    'Loctudy',
    'Locronan',
    'Locquirec',
    'Locquignol',
    'Locquenole',
    'Loconville',
    'Locon',
    'Locoal-Mendon',
    'Locmiquelic',
    'Locmine',
    'Locmariaquer',
    'Locmaria-Plouzane',
    'Locmaria-Grand-Champ',
    'Locmaria-Berrien',
    'Locmalo',
    'Lochieu',
    'Château de Loches',
    'Lobsann',
    'Llupia',
    'Lizy-sur-Ourcq',
    'Lizio',
    'Lixy',
    'Lixing-les-Saint-Avold',
    'Lixheim',
    'Livry-Louvercy',
    'Livry-sur-Seine',
    'Livry-Gargan',
    'Livry',
    'Livron-sur-Drome',
    'Livre-sur-Changeon',
    'Livre-la-Touche',
    'Livinhac-le-Haut',
    'Livet-et-Gavet',
    'Livet',
    'Livernon',
    'Liverdy-en-Brie',
    'Liverdun',
    'Livarot',
    'Litteau',
    'Lithaire',
    'Lit-et-Mixe',
    'Listrac-Médoc',
    'Lissieu',
    'Lisses',
    'Lissac',
    'Lisors',
    'Lison',
    'Lislet',
    'Lisle-sur-Tarn',
    'L’Isle-sur-le-Doubs',
    "L'Isle-sur-la-Sorgue",
    "L'Isle-Jourdain",
    "L'Isle-en-Dodon",
    'Lisle-en-Barrois',
    'LIsle-dEspagnac',
    "L'Isle-d'Abeau",
    "L'Isle-Adam",
    'Lisle',
    'Lisieux',
    'Lisbourg',
    'Lire',
    'Lipsheim',
    'Liposthey',
    'Liouc',
    'Lion-sur-Mer',
    'Lion-en-Beauce',
    'Liomer',
    'Linzeux',
    'Liny-devant-Dun',
    'Linxe',
    'Lintot',
    'Linthal',
    'Linselles',
    'Linsdorf',
    'Liniez',
    'Liniers',
    'Linguizzetta',
    'Lingreville',
    'Lingolsheim',
    'Lingevres',
    'Linge',
    'Lindry',
    'Lindebeuf',
    'Linazay',
    'Linas',
    'Linars',
    'Linards',
    'Limoux',
    'Limours',
    'Limony',
    'Limons',
    'Limonest',
    'Limon',
    'Limogne-en-Quercy',
    'Limoges-Fourches',
    'Limoges',
    'Limeyrat',
    'Limetz-Villez',
    'Limesy',
    'Limerzel',
    'Limeray',
    'Limendous',
    'Limeil-Brevannes',
    'Limbrassac',
    'Limay',
    'Limas',
    'Limans',
    'Limalonges',
    'Lillers',
    'Lillebonne',
    'Lille',
    "L'Ile-Saint-Denis",
    "L'Île-Rousse",
    "L'Ile-d'Olonne",
    "L'Ile-d'Elle",
    "L'Ile-Bouchard",
    'Lihus',
    'Liguge',
    'Ligueil',
    'Ligre',
    'Ligny-Saint-Flochel',
    'Ligny-les-Aire',
    'Ligny-le-Ribault',
    'Ligny-le-Chatel',
    'Ligny-en-Cambresis',
    'Ligny-en-Brionnais',
    'Ligny-en-Barrois',
    'Lignou',
    'Lignorelles',
    'Lignon',
    'Lignol',
    'Lignieres-de-Touraine',
    'Lignieres',
    'Ligneyrac',
    'Lignerolles',
    'Ligne',
    'Lignan-sur-Orb',
    'Lignan-de-Bazas',
    'Lignairolles',
    'Liglet',
    'Liginiac',
    'Ligescourt',
    'Ligardes',
    'Liffre',
    'Liffol-le-Grand',
    'Liez',
    'Liévin',
    'Lievans',
    'Lieuvillers',
    'Lieu-Saint-Amand',
    'Lieusaint',
    'Lieuron',
    'Lieurey',
    'Lieuran-Cabrieres',
    'Lieucourt',
    'Liessies',
    'Liesse-Notre-Dame',
    'Liernais',
    'Liergues',
    'Lieres',
    'Liepvre',
    'Liederschiedt',
    'Licques',
    'Licourt',
    'Lichtenberg',
    'Libourne',
    'Libermont',
    'Libercourt',
    'Lias',
    'Liart',
    'Liancourt',
    "L'Huisserie",
    'Lhuis',
    "L'Houmeau",
    "L'Hotellerie-de-Flee",
    "L'Hospitalet",
    "L'Hosmes",
    "L'Horme",
    "L'Hopital-le-Mercier",
    "L'Hopital-du-Grosbois",
    'Hopital-Camfrout',
    "L'Hopital",
    'Lhommaize',
    "L'Hermitage",
    "L'Hermenault",
    "L'Herbergement",
    "L'Haÿ-les-Roses",
    "L'Habit",
    'Lezoux',
    'Lezigneux',
    'Lezignan-Corbieres',
    'Lezennes',
    'Lezay',
    'Lezat-sur-Leze',
    'Lezardrieux',
    'Lezan',
    'Leyviller',
    'Leyrieu',
    'Leyr',
    'Leynhac',
    'Leyment',
    'Leymen',
    'Leyme',
    'Lexy',
    'Lewarde',
    'Levroux',
    'Levoncourt',
    'Le Vivier-sur-Mer',
    'Viviers-du-Lac',
    'Le Vilhain',
    'Le Vignau',
    'Levignac',
    'Le Vigen',
    'Le Vigeant',
    'Le Vigean',
    'Le Vigan',
    'Levier',
    'Le Vieil-Bauge',
    'Levie',
    'Le Veurdre',
    'Levet',
    'Le Vesinet',
    'Leves',
    'Le Versoud',
    'Levernois',
    'Le Vernois',
    'Vernet',
    'Le Vermont',
    'Le Verger',
    'Le Verdon-sur-Mer',
    'Levens',
    'Le Vaudreuil',
    'Le Vaudoue',
    'Le Vast',
    'Le Val-Saint-Germain',
    'Levallois-Perret',
    'Le Val-de-Gueblange',
    'Le Val-de-Gouhenans',
    'Le Valdecie',
    'Le Val-David',
    'Le Val-dAjol',
    'Valdahon',
    'Leval',
    'Le Val',
    'Levainville',
    'Le Leuy',
    'Leuvrigny',
    'Leuville-sur-Orge',
    'Leutenheim',
    'Leulinghem',
    'Leucate',
    'Leuc',
    'Le Truel',
    'Le Tronquay',
    'Le Tronchet',
    'Le Triadou',
    'Le Trevoux',
    'Le Treport',
    'Le Tremblay-sur-Mauldre',
    'Tremblay-les-Villages',
    'Le Tremblay',
    'Le Trehou',
    'Le Travet',
    "L'Etrat",
    'Le Transloy',
    'Le Translay',
    'Le Trait',
    'Letra',
    'Le Touvet',
    'Le Tourne',
    'Le Tour-du-Parc',
    'Le Touquet-Paris-Plage',
    'Le Torquesne',
    'Le Torpt',
    "L'Etoile",
    'Le Tignet',
    'Letia',
    'Le Thuit-Simer',
    'Le Thuit-Signol',
    'Le Thuit-Anger',
    'Le Thuit',
    'Le Thoureil',
    'Le Thour',
    'Le Thou',
    'Le Thoronet',
    'Le Thor',
    'Le Tholy',
    'Le Tholonet',
    'Le Thil-Riberpre',
    'Le Thillot',
    'Le Thillay',
    'Le Thieulin',
    'Le Theil',
    'Le Theil-de-Bretagne',
    'Le Temple-sur-Lot',
    'Le Temple-de-Bretagne',
    'Le Temple',
    'Le Teilleul',
    'Le Teil',
    'Le Teich',
    'Le Tallud',
    'Le Taillan-Medoc',
    'Le Syndicat',
    'Les Voivres',
    'Les Villettes',
    'Les Villards-sur-Thones',
    'Les Vigneaux',
    'Les Verchers-sur-Layon',
    'Les Ventes',
    'Les Vans',
    'Les Ulmes',
    'Le Subdray',
    'Les Trois-Pierres',
    'Lestrem',
    'Lestrade-et-Thouels',
    'Les Tourreilles',
    'Les Touches',
    'Lestiou',
    'Les Thuiles',
    'Les Ternes',
    "L'Estaque",
    'Lessy',
    'Les Sorinieres',
    'Lesseux',
    'Lessay',
    'Lessard-le-National',
    'Les Salles-sur-Verdon',
    'Les Salles-du-Gardon',
    "Les Sables-d'Olonne",
    'Les Rues-des-Vignes',
    'Les Rousses',
    'Les Rouges-Eaux',
    'Les Rosiers-sur-Loire',
    'Les Roches-de-Condrieu',
    'Les Rivieres-Henruel',
    'Les Riceys',
    'Les Ressuintes',
    'Les Rairies',
    'Lesquin',
    'Lesquielles-Saint-Germain',
    'Lesquerde',
    'Les Pujols',
    'Les Poulieres',
    'Les Portes-en-Re',
    'Les Ponts-de-Ce',
    'Les Plantiers',
    'Les Plans',
    'Les Plains-et-Grands-Essarts',
    'Les Pins',
    'Lespinasse',
    'Les Pilles',
    'Lespignan',
    'Les Pieux',
    'Lespielle',
    'Les Piards',
    'Les Petites-Loges',
    'Les Petites-Armoises',
    'Lesperon',
    'Les Pennes-Mirabeau',
    'Les Peintures',
    'Les Pavillons-sous-Bois',
    'Les Pas',
    'Lesparre-Médoc',
    'Les Paris',
    'Le Sourn',
    'Le Souich',
    'Les Orres',
    'Les Ormes-sur-Voulzie',
    'Les Ormes',
    'Les Olmes',
    'Les Ollieres-sur-Eyrieux',
    'Le Soler',
    'Les Neyrolles',
    'Lesneven',
    'Les Mureaux',
    'Moutiers-les-Mauxfaits',
    'Les Moutiers-en-Retz',
    'Les Montils',
    'Lesmont',
    'Les Monceaux',
    'Les Molieres',
    'Les Moitiers-en-Bauptois',
    'Les Milles',
    'Les Metairies',
    'Les Mesnuls',
    'Les Mesneux',
    'Les Mees',
    'Les Mazures',
    'Les Mayons',
    'Les Mathes',
    'Les Matelles',
    'Les Marches',
    'Les Maillys',
    'Les Magny',
    'Les Magnils-Reigniers',
    'Les Mages',
    'Les Lucs-sur-Boulogne',
    'Les Loges-sur-Brecey',
    'Les Loges-Margueron',
    'Les Loges-Marchis',
    'Les Loges',
    'Les Lilas',
    'Les Laumes',
    'Les Laubies',
    'Les Issards',
    'Lesigny',
    'Les Houches',
    'Les Hopitaux-Neufs',
    'Les Hogues',
    'Les Hermites',
    'Les Herbiers',
    'Les Hemmes de Marck',
    'Les Hautes-Rivieres',
    'Les Haies',
    'Les Guerreaux',
    'Les Granges-le-Roi',
    'Granges-les-Beaumont',
    'Les Grands-Chezeaux',
    'Les Gonds',
    'Les Gets',
    'Les Fourgs',
    'Les Fougerets',
    'Les Forges',
    'Les Fins',
    'Les Eyzies-de-Tayac-Sireuil',
    'Les Etilleux',
    'Les Estables',
    'Les Essarts-le-Roi',
    'Les Essarts',
    'Les Epesses',
    'Le Sen',
    'Le Sel-de-Bretagne',
    'Le Segur',
    'Les Eglisottes-et-Chalaures',
    "Les Eglises-d'Argenteuil",
    'Les Ecrennes',
    'Les Ecorces',
    'Les Echelles',
    'Les Deux-Fays',
    'Les Damps',
    'Lesdain',
    'Lescurry',
    "Lescure-d'Albigeois",
    'Crots',
    'Les Cresnays',
    'Lescout',
    'Les Costes',
    'Les Contamines-Montjoie',
    'Lesconil',
    'Les Combes',
    'Les Clouzeaux',
    'Les Clerimois',
    'Les Clayes-sous-Bois',
    'Lesches',
    'Lescheroux',
    'Lescherolles',
    'Les Cheres',
    'Les Chatelliers-Chateaumur',
    'Les Chapelles',
    'Les Chambres',
    'Les Cerqueux',
    "L'Escarene",
    'Lescar',
    'Les Cammazes',
    "L'Escale",
    'Les Cabannes',
    'Les Brulais',
    'Les Brouzils',
    'Les Breviaires',
    'Les Breseux',
    'Les Bordes',
    'Lesboeufs',
    'Les Bizots',
    'Les Billaux',
    'Les Biards',
    'Les Bessons',
    'Les Baux de Provence',
    'Les Barthes',
    'Les Ayvelles',
    'Les Aynans',
    'Les Avenieres',
    'Les Avanchers-Valmorel',
    'Les Authieux-du-Puits',
    'Les Autels',
    'Le Saulcy',
    'Les Aubiers',
    'Les Attaques',
    'Les Artigues-de-Lussac',
    'Les Ardillats',
    'Les Arcs',
    'Le Sappey-en-Chartreuse',
    'Le Sap',
    'Les Angles',
    'Les Andelys',
    'Les Ancizes',
    'Les Alluets-le-Roi',
    'Les Allues',
    'Les Alleuds',
    'Les Albres',
    "Les Aix-d'Angillon",
    'Les Aires',
    'Les Ageux',
    "Les Adrets-de-l'Esterel",
    'Les Adrets',
    'Les Abrets',
    'Lery',
    'Le Russey',
    'Lerrain',
    'Le Rozier',
    'Le Rove',
    'Le Rouret',
    'Le Roulier',
    'Le Rouget',
    'Le Ribay',
    'Le Rheu',
    'Leren',
    'Le Relecq-Kerhuon',
    'Le Reculey',
    'Lere',
    'Rayol-Canadel-sur-Mer',
    'Leran',
    'Le Raincy',
    'Le Quillio',
    'Le Quesnoy',
    'Le Quesnel',
    'Le Quesne',
    'Le Puy-Sainte-Reparade',
    'Le Puys',
    'Le Puy-en-Velay',
    'Lepuix-Neuf',
    'Le Puiset',
    'Le Pre-Saint-Gervais',
    'Le Pradet',
    'Le Pradal',
    'Le Pouzin',
    'Le Pout',
    'Le Pouliguen',
    'Le Poujol-sur-Orb',
    'Le Pouget',
    'Le Port-Marly',
    'Le Portel',
    'Port-des-Barques',
    'Le Porge',
    'Le Ponthou',
    'Le Pontet',
    'Le Pont-de-Planches',
    'Le Pont-de-Montvert',
    'Le Pont-de-Claix',
    'Le Pont-de-Beauvoisin',
    'Le Ponchel',
    'Le Poire-sur-Vie',
    'Le Poire-sur-Velluire',
    'Le Poinconnet',
    'Le Poet-Sigillat',
    'Le Poet-Laval',
    'Le Poet',
    'Le Plessis-Trevise',
    'Le Plessis-Robinson',
    'Le Plessis-Pate',
    'Le Plessis-Mace',
    'Le Plessis-Luzarches',
    'Le Plessis-Feu-Aussoux',
    'Le Plessis-Brion',
    'Le Plessis-Bouchard',
    'Le Plessis-Belleville',
    'Le Plessis',
    'Le Plessier-Rozainvillers',
    'Le Plantay',
    'Le Planois',
    'Le Plan-du-Var',
    'Le Pizou',
    'Saint-Denis-du-Pin',
    'Lepin-le-Lac',
    'Le Pin-en-Mauges',
    'Lepine',
    "L'Epine",
    'Le Pin',
    'Le Pian-sur-Garonne',
    'Le Pian-Medoc',
    'Le Petit-Quevilly',
    'Petit-Couronne',
    'Le Petit-Abergement',
    'Le Pertuis',
    'Le Perrier',
    'Le Perreux-sur-Marne',
    'Le Perreon',
    'Le Perray-en-Yvelines',
    'Le Perchay',
    'Le Pellerin',
    'Le Pegue',
    'Le Pecq',
    'Le Pechereau',
    'Le Peage-de-Roussillon',
    'Le Passage',
    'Le Pas',
    'Le Pallet',
    'Le Palais-sur-Vienne',
    'Le Palais',
    'Leon',
    'Léognan',
    'Leogeats',
    'Lentiol',
    'Lentilly',
    'Lentilleres',
    'Lentigny',
    'Lent',
    'Lens-Lestang',
    'Lens',
    'Le Nouvion-en-Thierache',
    'Lenoncourt',
    'Lennon',
    'Le Nizan',
    'Lening',
    'Le Neufbourg',
    'Le Neubourg',
    'Lencloitre',
    'Le Nayrac',
    'Le Muy',
    'Le Mung',
    'Lemud',
    'Lempdes',
    'Lempdes-sur-Allagnon',
    'Lempaut',
    'Le Mont-Saint-Adrien',
    'Le Montet',
    'Le Montellier',
    'Le Montel',
    'Le Montat',
    'Le Monetier-les-Bains',
    'Le Monestier',
    'Le Monastere',
    'Le Molay-Littry',
    'Le Miroir',
    'Le Minihic-sur-Rance',
    'Le Meux',
    'Le Mesnil-Saint-Denis',
    'Le Mesnil-Rainfray',
    'Le Mesnil-Patry',
    'Le Mesnil-le-Roi',
    'Le Mesnil-Gilbert',
    'Le Mesnil-Fuguet',
    'Le Mesnil-Esnard',
    'Le Mesnil-en-Vallee',
    'Le Mesnil-en-Thelle',
    'Le Mesnil-Caussois',
    'Le Mesnilbus',
    'Le Mesnil-Amelot',
    'Le Merzer',
    'Le Merlerault',
    'Le Meriot',
    'Lemere',
    'Le Menoux',
    'Le Menil-Ciboult',
    'Le Menil',
    'Le Meillard',
    'Le Mee-sur-Seine',
    'Leme',
    'Lembras',
    'Lembeye',
    'Lemberg',
    'Lembach',
    'Le May-sur-Evre',
    'Le Mayet-de-Montagne',
    'Le Mas Rillier',
    'Le Masnau-Massuguies',
    "Le Mas-d'Azil",
    "Le Mas-d'Agenais",
    'Le Mas',
    'Le Martinet',
    'Le Marillais',
    'Le Mans',
    'Le Manoir',
    'Le Malzieu-Ville',
    'Le Maisnil',
    'Le Luot',
    'Le Luhier',
    'Le Lude',
    'Le Luc',
    'Le Luart',
    'Le Louroux-Beconnais',
    'Le Loroux-Bottereau',
    'Le Lorey',
    'Le Locheur',
    'Le Lion-dAngers',
    'Le Lindois',
    'Lelex',
    'Le Lavandou',
    'Le Lardin-Saint-Lazare',
    'Le Langon',
    'Le Landreau',
    'Le Kremlin-Bicetre',
    'Leimbach',
    'Leigne-sur-Usseau',
    'Le Houlme',
    'Le Houga',
    'Lehon',
    "Le Hommet-d'Arthenay",
    'Le Hohwald',
    'Le Hingle',
    'Le Hezo',
    'Le Heron',
    'Le Havre',
    'Le Haut-Corlay',
    'Lehaucourt',
    'Le Haillan',
    'Léguillac-de-lAuche',
    'Le Guildo',
    'Leguevin',
    'Le Guerno',
    'Le Gue-de-Velluire',
    'Le Gue-de-Longroi',
    "Le Gue-d'Allere",
    'Le Gua',
    'Le Gros-Theil',
    'Le Grau-du-Roi',
    'Le Grand-Serre',
    'Le Grand-Quevilly',
    'Le Grand-Pressigny',
    'Le Grand-Luce',
    'Le Grand-Lemps',
    'Le Grand-Celland',
    'Le Grand-Bourg',
    'Le Grand-Bornand',
    'Le Gouray',
    'Gond-Pontouvre',
    'Le Givre',
    'Le Girouard',
    'Lege',
    'Lege-Cap-Ferret',
    'Le Gavre',
    'Le Gault-Soigny',
    'Le Garn',
    'Le Fuilet',
    'Le Fugeret',
    'Le Fresne-Poret',
    'Le Fresne-Camilly',
    "Le Freney-d'Oisans",
    'Le Fraysse',
    'Le Fousseret',
    'Le Fossat',
    'Leforest',
    'Le Folgoet',
    'Le Fleix',
    'Le Fieu',
    'Le Fidelaire',
    'Leffrinckoucke',
    'Le Fenouiller',
    'Le Fay-Saint-Quentin',
    'Le Fayet',
    'Le Fay',
    'Le Favril',
    'Le Fauga',
    'Le Faouet',
    'Le Faou',
    'Leers',
    'Lee',
    'Ledringhem',
    'Le Drennec',
    'Le Doulieu',
    'Le Douhet',
    'Le Dorat',
    'Le Donzeil',
    'Le Donjon',
    'Ledignan',
    'Le Dezert',
    'Ledeuix',
    'Le Deschaux',
    'Lederzeele',
    'Ledenon',
    'Ledat',
    'Le Cuing',
    'Lectoure',
    'Le Crozet',
    'Le Crotoy',
    'Le Croisty',
    'Le Croisic',
    'Le Creusot',
    'Le Crestet',
    'Le Crest',
    'Le Cres',
    'Lecques',
    "L'Ecouvotte",
    'Lecousse',
    'Le Cours',
    'Le Coudray-Saint-Germer',
    'Le Coudray-Montceaux',
    'Le Coudray-Macouard',
    'Le Coteau',
    'Le Conquet',
    'Le Collet-de-Deze',
    'Lecluse',
    'Le Cloitre-Saint-Thegonnec',
    'Le Cheylas',
    'Le Cheylard',
    'Le Chevain',
    'Le Chesne',
    'Le Chesnay',
    'La Lechere',
    'Le Chemin',
    'Le Chatellier',
    'Le Chatelet-en-Brie',
    'Le Chatelet',
    'Le Château-d’Oléron',
    'Le Chastang',
    'Le Change',
    'Champ-sur-Layon',
    'Le Champ-Saint-Pere',
    'Le Champ-pres-Froges',
    'Le Chambon-sur-Lignon',
    'Le Chambon-Feugerolles',
    'Le Chalard',
    'Le Chaffal',
    'Lecey',
    'Le Cendre',
    'Le Cellier',
    'Lecelles',
    'Le Caylar',
    'Le Cateau-Cambresis',
    'Le Castera',
    'Le Castellet',
    'Le Carlaret',
    "Le Cap D'Agde",
    'Le Cannet-des-Maures',
    'Le Cannet',
    'Le Cambout',
    'Le Caire',
    'Le Cailar',
    'Le Busseau',
    'Le Buret',
    'Le Buisson-de-Cadouin',
    'Le Bugue',
    'Le Brusquet',
    'Le Broc',
    'Le Brevedent',
    'Le Breuil-sur-Couze',
    'Le Breuil-sous-Argenton',
    'Le Breuil-en-Auge',
    'Le Breuil',
    'Le Brethon',
    'Le Breil-sur-Merize',
    'Le Bouyssou',
    "Le Bousquet-d'Orb",
    'Le Bouscat',
    'Le Bourget-du-Lac',
    'Le Bourget',
    'Le Bourg-Dun',
    "Le Bourg-d'Oisans",
    'Le Boupere',
    'Le Boulve',
    'el Volo',
    'Le Boulay-Morin',
    'Le Boulay',
    'Le Bouillon',
    'Le Bouchage',
    'Le Bosc-Roger-en-Roumois',
    'Le Bosc',
    'Le Born',
    'Le Bono',
    'Le Bonhomme',
    'Le Bois-Plage-en-Re',
    'Le Bodeo',
    'Le Bo',
    'Le Blanc-Mesnil',
    'Le Blanc',
    'Le Bizot',
    'Le Biot',
    'Le Bignon-du-Maine',
    'Le Bignon',
    'Le Bez',
    'Le Bernard',
    'Le Beny-Bocage',
    'Le Bec-Thomas',
    'Le Beausset',
    'Le Beaucet',
    'Le Bastit',
    'Le Bar-sur-Loup',
    'Le Barp',
    'Le Bardon',
    'Le Barcares',
    'Le Barboux',
    'Le Baizil',
    'Le Bailleul',
    'Leaz',
    'Lazer',
    'Lazenay',
    'Lay-Saint-Remy',
    'Lay-Saint-Christophe',
    'Layrac-sur-Tarn',
    'Layrac',
    'Laxou',
    'La Wantzenau',
    'La Walck',
    'La Vraie-Croix',
    'La Voulte-sur-Rhone',
    'Lavoncourt',
    'La Voivre',
    'Lavoine',
    'Lavit',
    'La Vineuse',
    'Lavilletertre',
    'La Villetelle',
    'La Villeneuve-au-Chene',
    'La Ville-es-Nonais',
    'La Ville-du-Bois',
    'La Villedieu-du-Clain',
    'Lavilledieu',
    'La Ville-Dieu-du-Temple',
    'La Ville-aux-Clercs',
    'Lavieu',
    'La Vicogne',
    'La Veze',
    'Laveyron',
    'La Veuve',
    'La Vespiere',
    'Laverune',
    'Laversines',
    'Laversine',
    'La Verriere',
    'La Verrie',
    'La Verpilliere',
    'Lavernose-Lacasse',
    'La Vernelle',
    'La Vernaz',
    'Lavernay',
    'Lavernat',
    'Lavergne',
    'La Verdiere',
    'Lavera',
    'Laventie',
    'La Vendelee',
    'Laveline-devant-Bruyeres',
    'Lavelanet-de-Comminges',
    'Lavelanet',
    'Laveissiere',
    'Lavazan',
    'Lavausseau',
    'Lavaur',
    'La Vaupaliere',
    'Lavault-Sainte-Anne',
    'Lavau',
    'Lavatoggio',
    'Lavasina',
    'La Varenne',
    'Lavare',
    'Lavardin',
    'Lavardac',
    'Lavans-Vuillafans',
    'Lavannes',
    'La Vancelle',
    'Laval-sur-Vologne',
    'Laval-Saint-Roman',
    'La Vallee de Dormelles',
    'La Valette-du-Var',
    'La Valette',
    'Lavalette',
    'Laval-en-Brie',
    'Laval',
    'La Vacherie',
    'La Vacheresse-et-la-Rouillie',
    'Lauzun',
    'Lauzerville',
    'Lauzerte',
    'Lauzach',
    'Lauwin-Planque',
    'Lauw',
    'Lautrec',
    'Lauterbourg',
    'Lautenbachzell',
    'Lautenbach',
    'Laussonne',
    'Lauris',
    'Lauret',
    'Laurens',
    'Laurenan',
    'Laure-Minervois',
    'Laurede',
    'Lauraguel',
    'Lauraet',
    'Laurabuc',
    'Launoy',
    'Launay-Villiers',
    'Launaguet',
    'Launac',
    'Laugnac',
    'Laugere',
    'Laudun-lArdoise',
    'Laucourt',
    'Laubressel',
    'Lau-Balagnas',
    'La Turbie',
    'La Turballe',
    'Lattes',
    'La Tronche',
    'La Trinite-Surzur',
    'La Trinité-sur-Mer',
    'La Trinite',
    'La Tretoire',
    'Latresne',
    'La Tremblade',
    'Latrecey-Ormoy-sur-Aube',
    'Latrape',
    'La Trancliere',
    'La Tranche-sur-Mer',
    'La Tour-sur-Orb',
    'La Tour-Saint-Gelin',
    'La Tourette',
    'La Tourlandry',
    'Latour-en-Woevre',
    'La Tour-en-Jarez',
    'La Tour-du-Pin',
    'La Tour-du-Crieu',
    'La Tour-de-Scay',
    'La Tour-de-Salvagny',
    'Latour-de-France',
    "La Tour-d'Aigues",
    'La Tour-Blanche',
    'Latour-Bas-Elne',
    'Latouille-Lentillac',
    'Latoue',
    'La Touche',
    'Latille',
    'Lathus-Saint-Remy',
    'Lathuile',
    'La Thuile',
    'La Teste-de-Buch',
    'La Tessoualle',
    'La Terrasse',
    'La Tardiere',
    'La Talaudiere',
    'La Suze-sur-Sarthe',
    'Lassy',
    'Lassur',
    'Lasson',
    'Lassigny',
    'Lasseube-Propre',
    'Lasseube',
    'Lasserre-de-Prouille',
    'Lasseran',
    'Lasserade',
    'Lassay-les-Chateaux',
    'Lassales',
    'La Souterraine',
    'La Sone',
    'La Seyne-sur-Mer',
    'La Sentinelle',
    'La Selve',
    'La Selle-sur-le-Bied',
    'La Selle-Guerchaise',
    'La Seguiniere',
    'La Seauve-sur-Semene',
    'Lasclaveries',
    'Lascelle',
    'Lascazeres',
    'Lascabanes',
    'Lasbordes',
    'La Sauziere-Saint-Jean',
    'La Sauve',
    'La Sauvagere',
    'La Saussaye',
    'La Sauniere',
    'La Saulce',
    'La Salvetat-Peyrales',
    'La Salvetat-Lauragais',
    'La Salvetat-Belmontet',
    'La Salvetat-sur-Agout',
    'La Salvetat-Saint-Gilles',
    'La Salle-de-Vihiers',
    'La Salle-les-Alpes',
    'Lasalle',
    'Larzicourt',
    'Laruscade',
    'Laruns',
    'La Rue-Saint-Pierre',
    'Larringes',
    'Larreule',
    'Larressore',
    'Larrazet',
    'La Rouxiere',
    'La Rouviere',
    'La Roussiere',
    'La Rouquette',
    'Larouillies',
    'La Rothiere',
    'La Roquette-sur-Siagne',
    'Laroque-Timbaut',
    'La Roque-sur-Ceze',
    'La Roque-Gageac',
    'La Roque-Esclapon',
    "Laroque-d'Olmes",
    "la Roca d'Albera",
    "La Roque-d'Antheron",
    'La Roquebrussanne',
    'Laroquebrou',
    'La Roque-Baignard',
    'Laronxe',
    'La Ronde-Haye',
    'La Ronde',
    'La Romieu',
    'La Romagne',
    'Laroin',
    'La Roe',
    'Larodde',
    'La Roche-Vineuse',
    'La Rochette',
    'La Roche-sur-Yon',
    'La Roche-sur-Foron',
    'La Rochepot',
    'La Roche-Posay',
    'La Rochenard',
    'Larochemillay',
    'La Roche-Maurice',
    'La Rochelle',
    "La Roche-l'Abeille",
    'La Rochefoucauld',
    'La Roche-en-Brenil',
    'La Roche-des-Arnauds',
    'La Roche-Derrien',
    'La Roche-de-Rame',
    'La Roche-de-Glun',
    'La Roche-Chalais',
    'La Roche-Blanche',
    'La Roche-Bernard',
    'La Rochebeaucourt-et-Argentine',
    'La Roche',
    'Larnaud',
    'Larnagol',
    'Larnage',
    'Larmor-Plage',
    'Larmor-Baden',
    'La Riviere-Saint-Sauveur',
    'La Riviere-Drugeon',
    'La Riviere-de-Corps',
    'La Riviere',
    'La Riche',
    'La Richardais',
    'La Ricamarie',
    "L'Argentiere-la-Bessee",
    'Largentière',
    'Largeasse',
    'La Reorthe',
    'La Reole',
    'La Renaudiere',
    'La Remaudiere',
    'La Regrippiere',
    'Laree',
    'La Redorte',
    'Lardy',
    'Larche',
    'Larchant',
    'Larchamp',
    'Larcay',
    'Larcan',
    'LArbresle',
    'La Ravoire',
    'Larajasse',
    'Laragne-Monteglin',
    'La Racineuse',
    'La Rabateliere',
    'La Quinte',
    'Laqueuille',
    'La Queue-les-Yvelines',
    'La Queue-en-Brie',
    'Laquenexy',
    'Lapugnoy',
    'Lapte',
    'Laps',
    'La Prenessaye',
    'Laprade',
    'Lappion',
    'Lapoutroie',
    'La Poueze',
    'La Poterie-Mathieu',
    'La Postolle',
    'La Possonniere',
    'La Pommeraye',
    'La Pommeraie-sur-Sevre',
    'La Poiteviniere',
    'La Planche',
    'La Plaine-sur-Mer',
    'La Plaine-Saint-Denis',
    'La Plaine',
    'La Pierre',
    'Lapeyrouse-Mornay',
    'Lapeyrouse-Fossat',
    'La Peyratte',
    'La Petite-Raon',
    'La Petite-Boissiere',
    'La Peruse',
    'Laperriere-sur-Saone',
    'La Perriere',
    'La Perouille',
    'La Pernelle',
    'Lapenty',
    'La Penne-sur-Huveaune',
    'Lapenne',
    'Lapenche',
    'Lapan',
    'Lapalud',
    'La Palud-sur-Verdon',
    'La Pallu',
    'Lapalisse',
    'La Pacaudiere',
    'Laons',
    'Laon',
    'Lanzac',
    'Lanvollon',
    'Lanveoc',
    'Lanvenegen',
    'Lanvellec',
    'Lanvaudan',
    'Lanvallay',
    'Lanuejouls',
    'Lanty',
    'Lantriac',
    'Lantosque',
    'Lanton',
    'Lantignie',
    'Lantic',
    'Lantheuil',
    'Lanthes',
    'Lanthenans',
    'Lantenne-Vertiere',
    'Lantenay',
    'Lantefontaine',
    'Lantages',
    'Lanta',
    'Lanslevillard',
    'Lanslebourg-Mont-Cenis',
    'Lans-en-Vercors',
    'Lansargues',
    'Lansac',
    'Lans',
    'Lanrivain',
    'Lanquetot',
    'Lanquais',
    'Lanouee',
    'Lanouaille',
    'La Norville',
    'La Noe-Poulain',
    'La Noe-Blanche',
    'Lanobre',
    'Lannion',
    'Lannilis',
    'Lanneuffret',
    'Lannes',
    'Lannepax',
    'Lannemezan',
    'Lanneanou',
    'Lanne',
    'Lanmeur',
    'Lanleff',
    'Laniscourt',
    'Laniscat',
    'Laning',
    'Lanildut',
    'Lanhouarneau',
    'Lanhelin',
    'Languidic',
    'Langueux',
    'Languenan',
    'Languedoc-Roussillon',
    'Languedias',
    'Langrune-sur-Mer',
    'Langres',
    'Langouet',
    'Langonnet',
    'Langon',
    'Langoiran',
    'Langogne',
    'Langoat',
    'Langlade',
    'Langensoultzbach',
    'Langeais',
    'Langeac',
    'Langast',
    'Langan',
    'La Neuville-Vault',
    'Laneuville-sur-Meuse',
    'La Neuville-sur-Essonne',
    'La Neuville-Roy',
    'La Neuville-les-Bray',
    'La Neuville-en-Tourne-a-Fuy',
    'La Neuville-en-Hez',
    'La Neuville-du-Bosc',
    "La Neuville-Chant-d'Oisel",
    'Laneuville-au-Rupt',
    'La Neuville-au-Pont',
    'Laneuveville-devant-Nancy',
    'La Neuve-Lyre',
    'La Neuvelle-les-Scey',
    'Lanester',
    'Lanespede',
    'Landunvez',
    'Landujan',
    'Landudec',
    'Landudal',
    'Landser',
    'Landroff',
    'Landricourt',
    'Landrevarzec',
    'Landres',
    'Landrecies',
    'Landrais',
    'Landouzy-la-Ville',
    'Landos',
    'Landorthe',
    'Landogne',
    'Landivy',
    'Landivisiau',
    'Landisacq',
    'Landiras',
    'Landifay-et-Bertaignemont',
    'Landevieille',
    'Landevant',
    'Landes-sur-Ajon',
    'Landes-le-Gaulois',
    'Landes',
    'Landeronde',
    'Landerneau',
    'Landepereuse',
    'Landemont',
    'Landelles-et-Coupigny',
    'Landelles',
    'Landehen',
    'Landeda',
    'Landean',
    'Landavran',
    'Landaul',
    'Landas',
    'Landange',
    'Lancrans',
    'Lancon-Provence',
    'Lancome',
    'Lancieux',
    'Lancie',
    'Lance',
    'La Murette',
    'Lamure-sur-Azergues',
    'La Mure',
    'La Muraz',
    'La Mulatiere',
    'Lampertheim',
    'Lampaul-Ploudalmezeau',
    'La Moutonne',
    'Lamoura',
    'La Mouille',
    'La Motte-Servolex',
    'La Motte-Saint-Martin',
    'La Motte-Saint-Jean',
    'Lamotte-Warfusee',
    'La Motte-de-Galaure',
    "La Motte-d'Aveillans",
    "La Motte-d'Aigues",
    'Lamotte-Buleux',
    'Lamotte-Brebiere',
    'Lamotte-Beuvron',
    'La Motte',
    'La Mothe-Saint-Heray',
    'Lamothe-Montravel',
    'Lamothe-Landerron',
    'Lamothe-Fenelon',
    'Lamothe-Capdeville',
    'La Mothe-Achard',
    'Lamothe',
    'Lamorlaye',
    'Lamonzie-Saint-Martin',
    'Lamontgie',
    'La Montagne',
    'La Monnerie-le-Montel',
    'La Mole',
    'Lamnay',
    'La Milesse',
    'La Meziere',
    'La Merlatiere',
    'Lamerac',
    'La Membrolle-sur-Longuenee',
    'La Membrolle-sur-Choisille',
    'Lamelouze',
    'La Meilleraye-de-Bretagne',
    'La Meilleraie-Tillay',
    'La Meignanne',
    'La Mede',
    'La Meaugon',
    'La Meauffe',
    'Lambres-lez-Douai',
    'Lambres',
    'Lambesc',
    'Lambersart',
    'Lamballe',
    'La Maxe',
    'Lamath',
    'Lamastre',
    'Lamasquere',
    'La Marre',
    'Lamarque',
    'Lamargelle',
    'Lamarche-sur-Saone',
    'La Marche',
    'Lamanon',
    'Lamancine',
    'La Mancelliere',
    'La Manceliere',
    'Lamalou-les-Bains',
    'La Malhoure',
    'La Malachere',
    'La Mailleraye-sur-Seine',
    'Lamagistere',
    'La Magdeleine',
    'La Magdelaine-sur-Tarn',
    'La Madrague',
    'La Madeleine-sur-Loing',
    'La Madeleine-de-Nonancourt',
    'La Madeleine',
    'La Madelaine-sous-Montreuil',
    'La Machine',
    'Lama',
    'La Luzerne',
    'Laluque',
    'La Lucerne-dOutremer',
    "Alpe d'Huez",
    'La Loyere',
    'La Loye',
    'Lalouvesc',
    'La Loupe',
    'La Loubiere',
    'Laloubere',
    'Lalonquette',
    'La Longueville',
    'Lalongue',
    'La Longeville',
    'La Londe-les-Maures',
    'La Londe',
    'Lalleu',
    'Lallaing',
    'La Liviniere',
    'Lalinde',
    'Laleu',
    'Lalbenque',
    "L'Albenc",
    'Lalaye',
    'Lalandelle',
    'Lalande-de-Pomerol',
    'La Lande-de-Goult',
    'La Lande-de-Fronsac',
    'La Laigne',
    'Lalacelle',
    'La Jumelliere',
    'La Jubaudiere',
    'La Javie',
    'La Jaudonniere',
    'La Jarrie',
    'La Jarne',
    'La Jard',
    'Laizy',
    'Laize-la-Ville',
    'Laize',
    'Laiz',
    'Laives',
    'Laitre-sous-Amance',
    'Laissey',
    'Laissac',
    'Lairoux',
    'Laire',
    'Lainville-en-Vexin',
    'Lains',
    'Laimont',
    'Lailly-en-Val',
    'Laille',
    'LAiguillon-sur-Mer',
    'Laigneville',
    'Laignelet',
    'Laigne-en-Belin',
    'Laigne',
    "L'Aigle",
    'La Houssoye',
    'La Houssaye-en-Brie',
    'La Houssaye',
    'Lahourcade',
    'Lahosse',
    'Lahontan',
    'Lahonce',
    'La Hoguette',
    'Lahitte',
    'Laheycourt',
    'La Heuniere',
    'Lahaymeix',
    'La Haye-Pesnel',
    'La Haye-Malherbe',
    'La Haye',
    'La Haye-du-Puits',
    'Descartes',
    'La Haye-de-Calleville',
    'La Haute-Chapelle',
    'La Harmoye',
    'Laharmand',
    'La Harengere',
    'La Haie-Traversaine',
    'La Haie-Fouassiere',
    'La Guyonniere',
    'Lagupie',
    'Laguiole',
    'La Guiche',
    'La Gueroulde',
    'La Gueriniere',
    "La Guerche-sur-l'Aubois",
    'La Guerche-de-Bretagne',
    'Laguepie',
    'Laguenne',
    'Lagruere',
    'La Groutte',
    'La Groise',
    'La Grigonnais',
    'La Gresle',
    'La Gree-Saint-Laurent',
    'La Graverie',
    'La Gravelle',
    'Lagrave',
    'Lagrauliere',
    'Lagrasse',
    'La Grandville',
    'La Grande-Paroisse',
    'La Grand-Croix',
    'GrandCombe-Châteleu',
    'La Gouesniere',
    'La Gorgue',
    'Lagord',
    'Lagorce',
    'Lagor',
    'La Gonfriere',
    'Lagny-le-Sec',
    'Lagny-sur-Marne',
    'Lagnieu',
    'Lagnes',
    'Laglorieuse',
    'La Glacerie',
    'La Giettaz',
    'Lagesse',
    'Lagery',
    'Lageon',
    'La Geneytouse',
    'La Genetouze',
    'La Genete',
    'La Gavotte',
    'La Gaude',
    'La Gaudaine',
    'La Gaubretiere',
    'La Garnache',
    'La Garenne-Colombes',
    'Lagardiolle',
    'Lagarde-sur-le-Ne',
    'Lagardelle-sur-Leze',
    'La Garde-Freinet',
    'Lagarde-Enval',
    'La Garde-Adhemar',
    'Lagarde',
    'La Garde',
    'La Gacilly',
    'La Frette-sur-Seine',
    'La Frette',
    'La Fresnais',
    'La Frenaye',
    'Lafraye',
    'La Franqui-Plage',
    'La Francheville',
    'Lafrancaise',
    'La Framboisiere',
    'La Foye-Monjault',
    'Lafox',
    'La Fouillouse',
    'La Fouillade',
    'La Forie',
    'La Forge',
    'La Foret-sur-Sevre',
    'La Foret-le-Roi',
    'La Foret-Fouesnant',
    'La Foret',
    'La Forest-Landerneau',
    'La Force',
    'La Fontenelle',
    'La Flotte',
    'La Flocelliere',
    'La Fleche',
    'La Flamengrie',
    'Lafitte-sur-Lot',
    'Lafitole',
    'Laffrey',
    'La Feuillie',
    'Lafeuillade-en-Vezie',
    'La Feuillade',
    'La Ferte-Vidame',
    'La Ferte-sous-Jouarre',
    'La Ferte-Saint-Cyr',
    'La Ferte-Saint-Aubin',
    'La Ferte-Milon',
    'La Ferte-Mace',
    'La Ferte-Imbault',
    'La Ferte-Gaucher',
    'La Ferte-Frenel',
    'La Ferte-Chevresis',
    'La Ferte-Bernard',
    'La Ferte-Alais',
    'La Ferte',
    'La Ferriere-sur-Risle',
    'La Ferriere-Bochard',
    'La Ferriere-Bechet',
    'La Ferriere-aux-Etangs',
    'La Ferriere',
    'La Fermete',
    'La Fere',
    'La Faute-sur-Mer',
    'La Farlede',
    'La Fare-les-Oliviers',
    'La Falaise',
    'Laduz',
    'La Douze',
    'La Doree',
    'Ladon',
    'La Dominelais',
    'Ladinhac',
    "La Digne-d'Amont",
    'Ladignac-le-Long',
    'La Destrousse',
    'Ladapeyre',
    'La Dagueniere',
    'Lacs',
    'Lacrouzette',
    'Lacrost',
    'La Cropte',
    'La Croix-Valmer',
    'La Croix-sur-Gartempe',
    'Lacroix-Saint-Ouen',
    'La Croixille',
    'La Croix-Hellean',
    'Lacroix-Falgarde',
    'La Croix-en-Touraine',
    'La Croix-du-Perche',
    'La Croisille-sur-Briance',
    'La Cresse',
    'La Crau',
    'Lacrabe',
    'Lacq',
    'La Couyere',
    'La Couture-Boussey',
    'La Couture',
    'Lacourt-Saint-Pierre',
    'La Courtine',
    'La Couronne',
    'La Courneuve',
    'La Coulonche',
    'La Coucourde',
    'La Couarde-sur-Mer',
    'La Cotiniere',
    'La Cote-Saint-Andre',
    "La Cote-d'Arbroz",
    'Lacoste',
    'La Cornuaille',
    'La Coquille',
    'La Copechagniere',
    'La Comte',
    'Lacommande',
    'La Comelle',
    'La Combe-de-Lancey',
    'La Colombe',
    'Lacollonge',
    'La Colle-sur-Loup',
    'La Collancelle',
    'La Cochere',
    'Saint-Martin-de-la-Cluze',
    'La Cluse-et-Mijoux',
    'La Cluse',
    'La Clusaz',
    'La Clotte',
    'La Clisse',
    'La Clayette',
    'La Ciotat',
    'Lachy',
    'La Chomette',
    'La Cheze',
    'La Chevroliere',
    'La Chenalotte',
    'La Chavatte',
    'La Chavanne',
    'La Chaux-du-Dombief',
    'La Chaux',
    'La Chaussee-sur-Marne',
    'La Chaussee-Saint-Victor',
    'La Chaussee',
    'La Chaussaire',
    'La Chaumusse',
    'La Chatre-Langlin',
    'La Châtre',
    'La Chataigneraie',
    'Lachassagne',
    'La Chartre-sur-le-Loir',
    'La Charmee',
    'La Charité-sur-Loire',
    'La Chapelle-Yvon',
    'La Chapelle-Viel',
    'La Chapelle-Vieille-Foret',
    'La Chapelle-Thouarault',
    'La Chapelle-Thireuil',
    'La Chapelle-Themer',
    'La Chapelle-Thecle',
    'La Chapelle-Taillefert',
    'La Chapelle-sur-Oreuse',
    'La Chapelle-sur-Loire',
    'La Chapelle-sur-Erdre',
    'La Chapelle-sur-Crecy',
    'La Chapelle-sur-Chezy',
    'Lachapelle-sous-Aubenas',
    'La Chapelle-Saint-Ursin',
    'La Chapelle-Saint-Sauveur',
    'La Chapelle-Saint-Mesmin',
    'La Chapelle-de-Brain',
    'La Chapelle-Saint-Luc',
    'La Chapelle-Saint-Laurian',
    'La Chapelle-Saint-Laurent',
    'La Chapelle-Saint-Laud',
    'La Chapelle-Saint-Geraud',
    'La Chapelle-Saint-Fray',
    'La Chapelle-Saint-Florent',
    'La Chapelle-Saint-Aubin',
    'La Chapelle-Saint-Andre',
    'La Chapelle-Reanville',
    'La Chapelle-Rablais',
    'La Chapelle-Pouilloux',
    'La Chapelle-Neuve',
    'La Chapelle-Montmoreau',
    'La Chapelle-Montligeon',
    'La Chapelle-dAndaine',
    'La Chapelle-les-Luxeuil',
    'La Chapelle-Launay',
    'La Chapelle-la-Reine',
    'La Chapelle-Janson',
    'La Chapelle-Huon',
    'La Chapelle-Heulin',
    'La Chapelle-Hermier',
    'La Chapelle-Gresignac',
    'La Chapelle-Glain',
    'La Chapelle-Gauthier',
    'La Chapelle-Gaceline',
    'La Chapelle-Faucher',
    'La Chapelle-en-Vercors',
    'La Chapelle-en-Serval',
    'La Chapelle-Encherie',
    'La Chapelle-du-Noyer',
    'La Chapelle-du-Mont-de-France',
    'La Chapelle-des-Marais',
    'La Chapelle-des-Fougeretz',
    'La Chapelle-de-la-Tour',
    'La Chapelle-de-Guinchay',
    "La Chapelle-d'Angillon",
    'La Chapelle-Craonnaise',
    'La Chapelle-Chaussee',
    'La Chapelle-Cecelin',
    'La Chapelle-Blanche',
    'La Chapelle-Basse-Mer',
    'Lachapelle-aux-Pots',
    'La Chapelle-aux-Filtzmeens',
    'La Chapelle-aux-Choux',
    'La Chapelle-aux-Brocs',
    'La Chapelle-au-Riboul',
    'La Chapelle-au-Moine',
    'La Chapelle-Aubareil',
    'La Chapelle-Anthenaise',
    'La Chapelle-Achard',
    'La Chapelle-Caro',
    'Lachapelle',
    'La Chapelaude',
    'La Champenoise',
    'Lachambre',
    'La Chambre',
    'La Chaize-le-Vicomte',
    'La Cerlangue',
    'Lacenas',
    'La Cellette',
    'La Celle-sur-Loire',
    'La Celle-Saint-Cyr',
    'La Celle-Saint-Cloud',
    'La Celle-Saint-Avant',
    'La Celle-les-Bordes',
    'La Celle-Dunoise',
    'La Celle-Conde',
    'La Celle',
    'Lacelle',
    'Lacave',
    'La Cavalerie',
    'La Caure',
    'Lacaune',
    'Lacassagne',
    'La Carneille',
    'Lacapelle-Segalar',
    'Lacapelle-Marival',
    'La Capelle-les-Boulogne',
    'La Capelle',
    'La Capelle-Bonance',
    'La Capelle-Bleys',
    'Lacapelle-Biron',
    'La Canourgue',
    'Lacanche',
    'Lacanau',
    'La Cambe',
    'La Calotterie',
    'La Calmette',
    'La Caine',
    'La Caillere-Saint-Hilaire',
    "La Cadiere-d'Azur",
    'Lacabarede',
    'La Cabanasse',
    'La Buxerette',
    'La Bussiere',
    'Laburgade',
    'Labuissiere',
    'La Buissiere',
    'La Buisse',
    "L'Absie",
    'Labry',
    'Labruyere',
    'La Brulatte',
    'Labruguiere',
    'La Bruffiere',
    'La Broque',
    'Labrit',
    'La Brillanne',
    'Labrihe',
    'La Brigue',
    'La Bridoire',
    'La Bretonniere-la-Claye',
    'La Bresse',
    'La Breole',
    'La Brede',
    'Laboutarie',
    'La Boussac',
    'Labourse',
    'La Bourgonce',
    'Labourgade',
    'La Bourdiniere-Saint-Loup',
    'La Bourdiniere',
    'La Bourboule',
    'Laboulbene',
    'La Bouillie',
    'La Bouille',
    'La Bouilladisse',
    'Labouheyre',
    'La Bosse-de-Bretagne',
    'Labosse',
    'La Bonneville-sur-Iton',
    'Laboissiere-en-Thelle',
    'La Boissiere-Ecole',
    'La Boissiere-de-Montaigu',
    'La Boissiere-du-Dore',
    'La Boissiere',
    'La Boisse',
    'La Bohalle',
    'La Bloutiere',
    'Lablachere',
    'La Biolle',
    'La Bigottiere',
    'Labeuvriere',
    'Labessiere-Candeil',
    'La Berthenoux',
    'La Bernerie-en-Retz',
    'La Bernardiere',
    'Labergement-Sainte-Marie',
    "L'Abergement-Sainte-Colombe",
    'Labergement-les-Auxonne',
    'Labenne',
    'La Benisson-Dieu',
    'La Benate',
    'La Belliole',
    'La Begude-de-Mazenc',
    'Labegude',
    'Labege',
    "L'Abbaye-sous-Plancy",
    'La Bazouge-des-Alleux',
    'La Bazoge-Montpincon',
    'La Bazoge',
    'La Bazoche-Gouet',
    'La Bazeuge',
    "La Baume-d'Hostun",
    'La Baume-de-Transit',
    'La Bauche',
    'La Batie-Rolland',
    'La Batie-Neuve',
    'La Batie-Montgascon',
    'La Batie-Divisin',
    'La Batie',
    'La Bathie',
    'La Bastidonne',
    'Labastide-Villefranche',
    'Labastidette',
    "La Bastide-sur-l'Hers",
    'La Bastide-Solages',
    'Labastide-Saint-Sernin',
    'Labastide-Saint-Pierre',
    'Labastide-Saint-Georges',
    'Labastide-Rouairoux',
    'Labastide-Murat',
    'Labastide-Marnhac',
    'Labastide-en-Val',
    'La Bastide-des-Jourdans',
    'La Bastide-de-Serou',
    'Labastide-Denat',
    'Labastide-de-Levis',
    'Labastide-sur-Besorgues',
    "Labastide-d'Anjou",
    'Labastide-Clermont',
    'La Bastide-Clairence',
    'Labastide-Beauvoir',
    'La Bassee',
    'Labarthe-sur-Leze',
    'Labarthe-Riviere',
    'Labarthe-Inard',
    'La Barthe-de-Neste',
    'Labarthe',
    'La Barre-en-Ouche',
    'La Barre-de-Semilly',
    'La Barre-de-Monts',
    'La Barre',
    'Labaroche',
    'La Barben',
    'La Balme-les-Grottes',
    'La Balme-de-Sillingy',
    'La Baffe',
    'La Baconniere',
    'La Bachellerie',
    'Kutzenhausen',
    'Kuttolsheim',
    'Kuntzig',
    'Kunheim',
    'Kruth',
    'Krautergersheim',
    'Kolbsheim',
    'Kogenheim',
    'Koenigsmacker',
    'Knutange',
    'Knoeringue',
    'Klingenthal',
    'Kirviller',
    'Kirschnaumen',
    'Kirrberg',
    'Kirchheim',
    'Kingersheim',
    'Kindwiller',
    'Kilstett',
    'Killem',
    'Kienheim',
    'Keskastel',
    'Kervignac',
    'Kersaint-Plabennec',
    'Kerprich-aux-Bois',
    'Kernoues',
    'Kernilis',
    'Kernascleden',
    'Kermaria-Sulard',
    'Kerlouan',
    'Kerling-les-Sierck',
    'Kerlaz',
    'Kergrist-Moelou',
    'Kergrist',
    'Kergloff',
    'Kerfourn',
    'Kerbach',
    'Kembs',
    'Kedange-sur-Canner',
    'Kaysersberg',
    'Kauffenheim',
    'Katzenthal',
    'Kanfen',
    'Kaltenhouse',
    'Juziers',
    'Juzes',
    'Juvisy-sur-Orge',
    'Juvigny-le-Tertre',
    'Juvigny',
    'Juvignies',
    'Juvigne',
    'Juvignac',
    'Juvardeil',
    'Juvaincourt',
    'Jutigny',
    'Jussy-le-Chaudrier',
    'Jussy',
    'Jussey',
    'Jussecourt-Minecourt',
    'Jussac',
    'Jury',
    'Juranville',
    'Jurancon',
    'Juniville',
    'Junhac',
    'Jungholtz',
    'Junas',
    'Jumilhac-le-Grand',
    'Jumièges',
    'Jumelles',
    'Jumeaux',
    'Jumeauville',
    'Jullouville',
    'Jullianges',
    'Julienne',
    'Julienas',
    'Jujurieux',
    'Juilly',
    'Juilley',
    'Juille',
    'Juillan',
    'Juillaguet',
    'Juillac',
    'Juigne-sur-Loire',
    'Juigne-des-Moutiers',
    'Juicq',
    'Jugon-les-Lacs',
    'Jugeals-Nazareth',
    'Jugazan',
    'Jublains',
    'Jubainville',
    'Juan-les-Pins',
    'Joze',
    'Joyeux',
    'Joyeuse',
    'Jouy-sur-Morin',
    'Jouy-sur-Eure',
    'Jouy-sous-Thelle',
    'Jouy-le-Potier',
    'Jouy-le-Moutier',
    'Jouy-le-Chatel',
    'Jouy-en-Josas',
    'Jouy-aux-Arches',
    'Jouy',
    'Joux-la-Ville',
    'Joux',
    'Jouvencon',
    'Jousse',
    'Journet',
    'Journans',
    'Jourgnac',
    'Jouques',
    'Jouillat',
    'Jouhet',
    'Jougne',
    'Jouet-sur-lAubois',
    'Bourg de Joue-sur-Erdre',
    "Joue-l'Abbe",
    'Joudreville',
    'Joudes',
    'Joucas',
    'Jouars-Pontchartrain',
    'Jouarre',
    'Jouac',
    'Jossigny',
    'Josselin',
    'Josse',
    'Josnes',
    'Jozerand',
    'Jonzieux',
    'Jonzier',
    'Jonzac',
    'Jonvelle',
    'Jons',
    'Jonquieres-Saint-Vincent',
    'Jonquieres',
    'Jonquery',
    'Jonquerettes',
    'Jongieux',
    'Joncreuil',
    'Jonchery-sur-Vesle',
    'Joncherey',
    'Joncels',
    'Jonage',
    'Jolivet',
    'Jolimetz',
    'Joinville-le-Pont',
    'Joinville',
    'Joigny-sur-Meuse',
    'Joigny',
    'Joeuf',
    'Jobourg',
    'Joannas',
    'Jezeau',
    'Jezainville',
    'Jeurre',
    'Jeumont',
    'Jeugny',
    'Jettingen',
    'Jenzat',
    'Jenlain',
    'Jegun',
    'Jebsheim',
    'Jeandelize',
    'Jeandelaincourt',
    'Jeancourt',
    'Jazennes',
    'Jazeneuil',
    'Jayat',
    'Javols',
    'Javerlhac-et-la-Chapelle-Saint-Robert',
    'Javerdat',
    'Javene',
    'Jaux',
    'Jaunay-Clan',
    'Jaulnes',
    'Jaulgonne',
    'Jaulges',
    'Jauldes',
    'Jaujac',
    'Jaudrais',
    'Jau-Dignac-et-Loirac',
    'Jatxou',
    'Jasseron',
    'Jasseines',
    'Jassans-Riottier',
    'Jasney',
    'Jarze',
    'Jarville-la-Malgrange',
    'Jars',
    'Javron-les-Chapelles',
    'Jarrie',
    'Jarny',
    'Jarnosse',
    'Jarnioux',
    'Jarnac-Champagne',
    'Jarnac',
    'Jarmenil',
    'Jargeau',
    'Jard-sur-Mer',
    'Jardres',
    'Jardin',
    'Jarcieu',
    'Janze',
    'Janvry',
    'Janville-sur-Juine',
    'Janville',
    'Jans',
    'Janneyrias',
    'Jandun',
    'Janailhac',
    'Jamericourt',
    'Jambville',
    'Jambles',
    'Jalons',
    'Jalogny',
    'Jallans',
    'Jallanges',
    'Jallais',
    'Jaligny-sur-Besbre',
    'Jailly-les-Moulins',
    'Jaillon',
    'Jaillans',
    'Jaignes',
    'Jacou',
    'Jacob-Bellecombette',
    'Jablines',
    'Izon',
    'Izier',
    'Izeure',
    'Izeron',
    'Izernore',
    'Izenave',
    'Izel-les-Hameau',
    'Izel-les-Equerchin',
    'Izeaux',
    'Ize',
    'Izaux',
    "Izaut-de-l'Hotel",
    'Iwuy',
    'Ivry-sur-Seine',
    'Ivry-le-Temple',
    'Ivry-la-Bataille',
    'Ivoy-le-Pre',
    'Iville',
    'Iverny',
    'Itteville',
    'Ittenheim',
    'Iteuil',
    'Itancourt',
    'Istres',
    'Issy-les-Moulineaux',
    'Issus',
    'Is-sur-Tille',
    'Issoudun',
    'Issou',
    'Issoncourt',
    'Issoire',
    'Issigeac',
    'Isserteaux',
    'Issenheim',
    'Isse',
    'Issans',
    'Issanlas',
    'Issancourt-et-Rumel',
    'Issamoulenc',
    'Isques',
    'Ispagnac',
    'Isomes',
    'Isola',
    'Isneauville',
    'Isles-sur-Suippe',
    'Isle',
    'Isigny-sur-Mer',
    'Isigny-le-Buat',
    'Is-en-Bassigny',
    'Isdes',
    'Isches',
    'Isbergues',
    'Irvillac',
    'Irreville',
    'Irouleguy',
    'Irodouer',
    'Irmstett',
    'Irles',
    'Irissarry',
    'Irigny',
    'Ire-le-Sec',
    'Irais',
    'Irai',
    'Ippling',
    'Inzinzac-Lochrist',
    'Inxent',
    'Inval-Boiron',
    'Insming',
    'Innenheim',
    'Injoux-Genissiat',
    'Ingwiller',
    'Inguiniel',
    'Ingre',
    'Ingrandes',
    'Ingolsheim',
    'Inghem',
    'Ingersheim',
    'Ingenheim',
    'Inchy-en-Artois',
    'Incheville',
    'Incarville',
    'Inaumont',
    'Imphy',
    'Imbsheim',
    'Illzach',
    'Illy',
    'Illois',
    'Illkirch-Graffenstaden',
    "Illiers-l'Eveque",
    'Illiers-Combray',
    'Illhaeusern',
    'Illfurth',
    'Ille-sur-Tet',
    'Illats',
    'Illange',
    'Ilharre',
    'Ile-Tudy',
    'Iholdy',
    'Iguerande',
    'Igoville',
    'Igon',
    'Igny',
    'Igney',
    'Ige',
    'Ifs',
    'Iffendic',
    'Idron',
    'Ichtratzheim',
    'Hymont',
    'Hyères',
    'Huttenheim',
    'Huttendorf',
    'Hussigny-Godbrange',
    'Husseren-Wesserling',
    'Hurtigheim',
    'Hurigny',
    'Huriel',
    'Huppy',
    'Huos',
    'Hunting',
    'Hunspach',
    'Huningue',
    'Hundsbach',
    'Hunawihr',
    'Humes-Jorquenay',
    'Humberville',
    'Humbercamps',
    'Humbecourt',
    'Hultehouse',
    'Hulluch',
    'Huisseau-sur-Mauves',
    'Huisseau-sur-Cosson',
    'Huismes',
    'Huiron',
    'Huilly-sur-Seille',
    'Huille',
    'Huez',
    'Huest',
    'Huelgoat',
    'Hudimesnil',
    'Huclier',
    'Hubersent',
    'Hoymille',
    'Houx',
    'Houville-la-Branche',
    'Houtteville',
    'Houtaud',
    'Housseville',
    'Housseras',
    'Houssen',
    'Hourtin',
    'Hourc',
    'Houppeville',
    'Houplines',
    'Houplin-Ancoisne',
    'Houlgate',
    'Houlette',
    'Houlbec-Cocherel',
    'Houilles',
    'Houeydets',
    'Houetteville',
    'Houecourt',
    'Houdilcourt',
    'Houdemont',
    'Houdelmont',
    'Houdelaincourt',
    'Houdancourt',
    'Houdan',
    'Houdain-lez-Bavay',
    'Houchin',
    'Hottviller',
    'Hotonnes',
    'Hostun',
    'Hostiaz',
    'Hostens',
    'Hossegor',
    'Hornaing',
    'Horgues',
    'Hordain',
    'Horbourg-Wihr',
    'Honnecourt-sur-Escaut',
    'Hon-Hergies',
    'Honfleur',
    'Hondschoote',
    'Hondouville',
    'Hondeghem',
    'Homps',
    'Hommes',
    'Homecourt',
    'Hombourg-Haut',
    'Hombourg',
    'Homblieres',
    'Hombleux',
    'Holving',
    'Holtzheim',
    'Holque',
    'Holnon',
    'Holling',
    'Hohfrankenheim',
    'Hoffen',
    'Hœrdt',
    'Hœnheim',
    'Hoedic',
    'Hochstatt',
    'Hochfelden',
    'Hitte',
    'His',
    'Hirtzfelden',
    'Hirtzbach',
    'Hirson',
    'Hirsingue',
    'Hirschland',
    'Hirel',
    'Hipsheim',
    'Hinx',
    'Hinges',
    'Hindlingen',
    'Hindisheim',
    'Hilsenheim',
    'Hillion',
    'Hilbesheim',
    'Hieville',
    'Hiesville',
    'Hiersac',
    'Hieres-sur-Amby',
    'Heyrieux',
    'Heurteauville',
    'Heuringhem',
    'Heuqueville',
    'Heugas',
    'Heudreville-sur-Eure',
    'Heudicourt',
    'Heudebouville',
    'Heuchin',
    'Heubecourt-Haricourt',
    'Hettange-Grande',
    'Hetomesnil',
    'Hestroff',
    'Hessenheim',
    'Hesse',
    'Hesingue',
    "Hesdin-l'Abbe",
    'Hesdin',
    'Hesdigneul-les-Boulogne',
    'Hesdigneul-les-Bethune',
    'Hescamps',
    'Herzeele',
    'Hery-sur-Alby',
    'Hery',
    'Hervilly',
    'Hervelinghen',
    'Hersin-Coupigny',
    'Herserange',
    'Hersbach',
    'Herrlisheim-pres-Colmar',
    'Herrlisheim',
    'Herrin',
    'Herpont',
    'Herouvillette',
    'Hérouville-Saint-Clair',
    'Hernicourt',
    'Hermonville',
    'Hermin',
    'Hermillon',
    'Hermies',
    'Hermeville',
    'Hermes',
    'Hermerswiller',
    'Hermeray',
    'Hermelinghen',
    'Herme',
    'Hermaville',
    'Hermanville-sur-Mer',
    'Herm',
    'Herly',
    'Herlies',
    'Herleville',
    'Herisson',
    'Herissart',
    'Herin',
    'Herimoncourt',
    'Herimenil',
    'Hericy',
    'Hericourt-en-Caux',
    'Hericourt',
    'Heric',
    'Hergnies',
    'Herepian',
    'Herchies',
    'Herblay',
    'Herbitzheim',
    'Herbinghen',
    'Herbignac',
    'Herbeys',
    'Herbeville',
    'Herbeuval',
    'Herbelles',
    'Herbecourt',
    'Herbault',
    'Henvic',
    'Henriville',
    'Henrichemont',
    'Henouville',
    'Henonville',
    'Henon',
    'Hennecourt',
    'Hennebont',
    'Heninel',
    'Henin-Beaumont',
    'Hengwiller',
    'Henflingen',
    'Henencourt',
    'Hendecourt-les-Ransart',
    'Hendecourt-les-Cagnicourt',
    'Hendaye',
    'Henanbihen',
    'Hemonstoir',
    'Hemilly',
    'Hemevez',
    'Hem',
    'Helstroff',
    'Heloup',
    'Helleville',
    'Hellemmes-Lille',
    'Hellean',
    'Helfrantzkirch',
    'Helfaut',
    'Helette',
    'Helesmes',
    'Heiteren',
    'Heining-les-Bouzonville',
    'Heimsbrunn',
    'Heimersdorf',
    'Heiltz-le-Hutier',
    'Heilly',
    'Heillecourt',
    'Heiligenstein',
    'Heiligenberg',
    'Heidwiller',
    'Hegenheim',
    'Hede-Bazouges',
    'Hecq',
    'Hecourt',
    'Hecken',
    'Hebecrevon',
    'Hebecourt',
    'Hazembourg',
    'Hazebrouck',
    'Haynecourt',
    'Haybes',
    'Hayange',
    'Haverskerque',
    'Haveluy',
    'Hauvine',
    'Hauville',
    'Hautvillers-Ouville',
    'Hautvillers',
    'Hautot-sur-Seine',
    'Hautot-sur-Mer',
    'Hautot-Saint-Sulpice',
    'Hautmont',
    'Haut-Mauco',
    'Yutz',
    'Hauteville-Lompnes',
    'Hauteville',
    'Hautevesnes',
    'Hautevelle',
    'Hautesvignes',
    'Hauterives',
    'Hauterive',
    'Haute-Normandie',
    'Hauteluce',
    'Haute-Kontz',
    'Haute-Isle',
    'Haute-Goulaine',
    'Hautefort',
    'Hautefontaine',
    'Hautefage-la-Tour',
    'Hautefage',
    'Hautecloque',
    'Haut-Clocher',
    'Haussy',
    'Hausgauen',
    'Haulme',
    'Haulchin',
    'Haudainville',
    'Haucourt',
    'Hauconcourt',
    'Haubourdin',
    'Hattmatt',
    'Hattenville',
    'Hatten',
    'Hatrize',
    'Hastingues',
    'Haspres',
    'Haspelschiedt',
    'Hasparren',
    'Hasnon',
    'Hary',
    'Hartmannswiller',
    'Hartennes-et-Taux',
    'Harskirchen',
    'Harsault',
    'Harreberg',
    'Harprich',
    'Harol',
    'Harnes',
    'Harmonville',
    'Harly',
    'Hargnies',
    'Harfleur',
    'Hareville',
    'Hardricourt',
    'Hardivillers',
    'Hardinvast',
    'Hardinghen',
    'Hardifort',
    'Hardelot-Plage',
    'Harcourt',
    'Harcigny',
    'Harcanville',
    'Harbouey',
    'Harbonnieres',
    'Haravilliers',
    'Haraucourt-sur-Seille',
    'Haraucourt',
    'Haramont',
    'Hanvoile',
    'Hanviller',
    'Hanvec',
    'Hantay',
    'Hangest-en-Santerre',
    'Hangenbieten',
    'Hangard',
    'Hanches',
    'Hanc',
    'Ham-sous-Varsberg',
    'Hampont',
    'Hampigny',
    'Hamel',
    'Hambye',
    'Hambers',
    'Hambach',
    'Hamars',
    'Ham',
    'Halstroff',
    'Halsou',
    'Halluin',
    'Halloy',
    'Hallines',
    'Hallignicourt',
    'Hallennes-lez-Haubourdin',
    'Hallencourt',
    'Halinghen',
    'Haisnes',
    'Haims',
    'Haimps',
    'Haillicourt',
    'Hailles',
    'Haigneville',
    'Haguenau',
    'Hagondange',
    'Hageville',
    'Hagetmau',
    'Hagetaubin',
    'Hagenthal-le-Haut',
    'Hagenthal-le-Bas',
    'Hagenbach',
    'Hagen',
    'Hadol',
    'Hadancourt-le-Haut-Clocher',
    'Hacqueville',
    'Habsheim',
    'Habloville',
    'Habere-Poche',
    'Habere-Lullin',
    'Habas',
    'Habarcq',
    "Gy-l'Eveque",
    'Gy-les-Nonains',
    'Gy-en-Sologne',
    'Gy',
    'Guyencourt',
    'Guyancourt',
    'Gussignies',
    'Gury',
    'Gurunhuel',
    'Gurs',
    'Gurmencon',
    'Gurgy',
    'Guntzviller',
    'Gunstett',
    'Gunsbach',
    'Gundolsheim',
    'Gundershoffen',
    'Gumond',
    'Gumieres',
    'Gumery',
    'Gujan-Mestras',
    'Guivry',
    'Guitte',
    'Guitry',
    'Guitres',
    'Guitinieres',
    'Guisseny',
    'Guise',
    'Guiscriff',
    'Guiscard',
    'Guiry-en-Vexin',
    'Guipry',
    'Guipronvel',
    'Guipel',
    'Guipavas',
    'Guingamp',
    'Guines',
    'Guimps',
    'Guimiliau',
    'Guimaec',
    'Guilvinec',
    'Guillos',
    'Guillonville',
    'Guillon',
    "Guilligomarc'h",
    'Guilliers',
    'Guillestre',
    'Guillerval',
    'Guillaumes',
    'Guillaucourt',
    'Guillac',
    'Guilherand-Granges',
    'Guilers',
    'Guignonville',
    'Guignicourt-sur-Vence',
    'Guignicourt',
    'Guigneville-sur-Essonne',
    'Guigneville',
    'Guignes',
    'Guignen',
    'Guignemicourt',
    'Guidel',
    'Guichen',
    'Guiche',
    'Guichainville',
    'Guibeville',
    'Guewenheim',
    'Gueux',
    'Gueutteville-les-Gres',
    'Gueugnon',
    'Guethary',
    'Guesnain',
    'Guerville',
    'Guerting',
    'Guerpont',
    'Guerny',
    'Guernes',
    'Guern',
    'Guermantes',
    'Guerlesquin',
    'Guerigny',
    'Gueret',
    'Guereins',
    'Guerchy',
    'Guerbigny',
    'Guerard',
    'Guerande',
    'Guer',
    'Guenrouet',
    'Guenin',
    'Guengat',
    'Guenange',
    'Guemps',
    'Guemar',
    'Guemappe',
    'Gueltas',
    'Guehenno',
    'Guegon',
    'Guecelard',
    'Guebwiller',
    'Guebenhouse',
    'Guarbecque',
    'Grussenheim',
    'Gruson',
    'Grundviller',
    'Gruissan',
    'Grugny',
    'Grugies',
    'Gruffy',
    'Grues',
    'Gruchet-le-Valasse',
    'Grozon',
    'Grougis',
    'Grouches-Luchuel',
    'Grosville',
    'Grostenquin',
    'Grosseto-Prugna',
    'Grosrouvre',
    'Gros-Rederching',
    'Grospierres',
    'Groslay',
    'Gros-Chastang',
    'Grosbreuil',
    'Grosbliederstroff',
    'Gron',
    'Grolejac',
    'Groix',
    'Groisy',
    'Groissiat',
    'Groffliers',
    'Grisy-Suisnes',
    'Grisolles',
    'Griselles',
    'Grimonviller',
    'Grimaud',
    'Grimaucourt-pres-Sampigny',
    'Grimaucourt-en-Woevre',
    'Grillon',
    'Grigny',
    'Grignon',
    'Grignols',
    'Grigneuseville',
    'Grignan',
    'Griesheim-sur-Souffel',
    'Griesheim-pres-Molsheim',
    'Gries',
    'Grieges',
    'Gricourt',
    'Grez-sur-Loing',
    'Grez-Neuville',
    'Grezillac',
    'Grezieu-la-Varenne',
    'Grez-en-Bouere',
    'Gretz-Armainvilliers',
    'Gresy-sur-Isere',
    'Gresy-sur-Aix',
    'Gressy',
    'Gresse-en-Vercors',
    'Grezolles',
    'Grezieu-le-Marche',
    'Greoux-les-Bains',
    'Greolieres',
    'Grentzingen',
    'Grentheville',
    'Grenoble',
    'Grening',
    'Grendelbruch',
    'Grenay',
    'Grenant',
    'Grenade-sur-l’Adour',
    'Grenade',
    'Gremonville',
    'Gremevillers',
    'Evry-Gregy-sur-Yerre',
    'Greges',
    'Gredisans',
    'Greasque',
    'Grazay',
    'Grazac',
    'Graye-sur-Mer',
    "Grayan-et-l'Hopital",
    'Gray',
    'Gravigny',
    'Gravieres',
    'Graveson',
    'Gravelotte',
    'Gravelines',
    'Graval',
    'Grauves',
    'Graulhet',
    'Graufthal',
    'Gratentour',
    'Gratens',
    'Grateloup-Saint-Gayrand',
    'Grasse',
    'Grassac',
    'Gras',
    'Granville',
    'Grans',
    'Granges-sur-Vologne',
    'Granges-sur-Lot',
    'Granges-sur-Aube',
    'Granges-Narboz',
    'Granges',
    'Grangermont',
    'Grane',
    'Grandvilliers',
    'Grandvillers-aux-Bois',
    'Grandvillers',
    'Grandvillars',
    'Le Grand-Village-Plage',
    'Grandvelle-et-le-Perrenot',
    'Grandru',
    'Grand-Rozoy',
    'Grandris',
    'Grandrieu',
    'Grand-Laviers',
    'Grandjean',
    'Gallargues-le-Montueux',
    'Grandfresnoy',
    'Grand-Fougeray',
    'Grand-Fort-Philippe',
    'Grandfontaine',
    'Grande-Synthe',
    'Grandcourt',
    'Grand-Couronne',
    'Grand-Charmont',
    'Grandchamps-des-Fontaines',
    'Grand-Champ',
    'Grandcamp-Maisy',
    'Grand-Camp',
    'Grand-Auverne',
    'Gramont',
    'Grambois',
    'Gramat',
    'Graix',
    'Grainville-Ymauville',
    'Grainville-sur-Ry',
    'Grainville-sur-Odon',
    'Grainville-Langannerie',
    'Grainville',
    'Graimbouville',
    'Graignes-Mesnil-Angot',
    'Gragnague',
    'Gradignan',
    'Graces',
    'Gracay',
    'Grabels',
    'Goyrans',
    'Goven',
    'Gouzon',
    'Gouzeaucourt',
    'Gouy-Saint-Andre',
    'Gouy-en-Ternois',
    'Gouy',
    'Goux-les-Usiers',
    'Goux-les-Dambelin',
    'Gouvix',
    'Gouville-sur-Mer',
    'Gouville',
    'Gouvieux',
    'Gouvernes',
    'Gouttieres',
    'Goutevernisse',
    'Goustranville',
    'Goussonville',
    'Gousse',
    'Goussainville',
    'Gours',
    'Gournay-sur-Marne',
    'Gournay-en-Bray',
    'Gournay-le-Guerin',
    'Gourin',
    'Gourgeon',
    'Gourge',
    'Gourgancon',
    'Gourdon',
    'Gourdan-Polignan',
    'Gourbesville',
    'Goupillieres',
    'Goulven',
    'Goult',
    'Gouloux',
    'Goulien',
    'Gouise',
    'Gouillons',
    'Gougenheim',
    'Gouezec',
    'Gouex',
    'Gouesnou',
    'Gouesnach',
    'Goudourville',
    'Goudargues',
    'Gouaix',
    'Gottenhouse',
    'Gotein-Libarrenx',
    'Gosné',
    'Gosnay',
    'Gorze',
    'Gorron',
    'Gorre',
    'Gornac',
    'Gorhey',
    'Gorges',
    'Gordes',
    'Gorcy',
    'Gorbio',
    'Goos',
    'Gontaud-de-Nogaret',
    'Gonsans',
    'Valanjou',
    'Gonneville-sur-Scie',
    'Gonneville-sur-Mer',
    'Gonneville-sur-Honfleur',
    'Gonneville-la-Mallet',
    'Gonnetot',
    'Gonnelieu',
    'Gonnehem',
    'Gonfreville-lOrcher',
    'Gonfaron',
    'Gonesse',
    'Gondrin',
    'Gondrexange',
    'Gondreville',
    'Gondrecourt-le-Chateau',
    'Gondeville',
    'Gondecourt',
    'Goncelin',
    'Gommerville',
    "Gommenec'h",
    'Gommegnies',
    'Gometz-le-Chatel',
    'Gometz-la-Ville',
    'Gomelange',
    'Gollainville',
    'Golinhac',
    'Golfe-Juan',
    'Golbey',
    'Goincourt',
    'Gognies-Chaussee',
    'Goetzenbruck',
    'Goes',
    'Gœrsdorf',
    'Godewaersvelde',
    'Goderville',
    'Glun',
    'Gluiras',
    'Glonville',
    'Glomel',
    'Glisy',
    'Glisolles',
    'Glere',
    'Glenic',
    'Gleize',
    'Glatigny',
    'Glatens',
    'Glanon',
    'Glanes',
    'Glandon',
    'Glandage',
    'Glamondans',
    'Glaire',
    'Glageon',
    'Gizy',
    'Gizia',
    'Givry-en-Argonne',
    'Givrycourt',
    'Givry',
    'Givrezac',
    'Givrauval',
    'Givrand',
    'Givraines',
    'Givors',
    'Givonne',
    'Givet',
    'Giverville',
    'Giverny',
    'Givenchy-en-Gohelle',
    'Givardon',
    'Gisy-les-Nobles',
    'Gissac',
    'Gisors',
    'Giroussens',
    'Gironville',
    'Gironde-sur-Dropt',
    'Gironcourt-sur-Vraine',
    'Giromagny',
    'Girolles',
    'Girecourt-sur-Durbion',
    'Gircourt-les-Vieville',
    'Giraumont',
    'Girancourt',
    'Girac',
    'Gipcy',
    'Gionges',
    'Ginoles',
    'Ginestet',
    'Ginestas',
    'Gindou',
    'Ginasservis',
    'Gimouille',
    'Gimont',
    'Gimeux',
    'Gimel-les-Cascades',
    'Gimeaux',
    'Gilocourt',
    'Gilly-sur-Loire',
    'Gilly-sur-Isere',
    'Gillonnay',
    'Gilley',
    'Gilette',
    'Gigouzac',
    'Gigondas',
    'Gigny-sur-Saone',
    'Gigny-Bussy',
    'Gigneville',
    'Gignac-la-Nerthe',
    'Gignac',
    'Gigean',
    'Gif-sur-Yvette',
    'Giez',
    'Gievres',
    'Gieville',
    'Gieres',
    'Giens',
    'Gien',
    'Gidy',
    'Gibles',
    'Giberville',
    'Giat',
    'Ghyvelde',
    'Ghissignies',
    'Ghisonaccia',
    'Gezoncourt',
    'Gezaincourt',
    'Gez',
    'Geyssans',
    'Gex',
    'Gevry',
    'Gevrey-Chambertin',
    'Gevigney-et-Mercey',
    'Geveze',
    'Geudertheim',
    'Geu',
    'Getigne',
    'Gestel',
    'Geste',
    'Gespunsart',
    'Gesnes',
    'Gerzat',
    'Gery',
    'Gerville',
    'Gervans',
    'Gerstheim',
    'Gerponville',
    'Geronce',
    'Gernelle',
    'Germond-Rouvre',
    'Germiny',
    "Germigny-l'Exempt",
    "Germigny-l'Eveque",
    'Germigny',
    'Germignonville',
    'Germignac',
    'Germainville',
    'Germaine',
    'Gerland',
    'Gergy',
    'Gerderest',
    'Gerbeviller',
    'Gerberoy',
    'Gerbecourt',
    'Gérardmer',
    'Ger',
    'Beard-Geovreissiat',
    'Georfans',
    'Gentioux-Pigerolles',
    'Gentilly',
    'Gentelles',
    'Gente',
    'Gensac-la-Pallue',
    'Genouilly',
    'Genouilleux',
    'Genolhac',
    'Gennevilliers',
    'Genneville',
    'Gennetines',
    'Gennes-sur-Seiche',
    'Gennes',
    'Genlis',
    'Genissieux',
    'Genissac',
    'Genis',
    'Geneuille',
    'Genets',
    'Genestelle',
    'Geneslay',
    'Generville',
    'Generest',
    'Generargues',
    'Generac',
    'Genelard',
    'Genech',
    'Gencay',
    'Genay',
    'Genas',
    'Genac',
    'Gemozac',
    'Gemonville',
    'Gemil',
    'Gemenos',
    'Gemeaux',
    'Gemaingoutte',
    'Gelucourt',
    'Geloux',
    'Gelles',
    'Geispolsheim',
    'Geispitzen',
    'Gedre',
    'Geay',
    'Geaune',
    'Gazeran',
    'Gaye',
    'Gavrus',
    'Gavrelle',
    'Gavray',
    'Gavisse',
    'Gavet',
    'Gavaudun',
    'Gauville-la-Campagne',
    'Gausson',
    'Gauriaguet',
    'Gauriac',
    'Gaujan',
    'Gaujacq',
    'Gaujac',
    'Gaudies',
    'Gauciel',
    'Gauchy',
    'Gaubertin',
    'Gattieres',
    'Gatteville-le-Phare',
    'Gatey',
    'Gasville-Oiseme',
    'Gastins',
    'Gastines',
    'Gastes',
    'Gassin',
    'Gasny',
    'Gas',
    'Garrigues',
    'Garrebourg',
    'Garons',
    'Garnay',
    'Garlin',
    'Garlan',
    'Garindein',
    'Garies',
    'Garidech',
    'Garges-lès-Gonesse',
    'Gargenville',
    'Gargas',
    'Garganvillar',
    'Gareoult',
    'Garennes-sur-Eure',
    'Garencieres',
    'Gardouch',
    'Gardonne',
    'Gardegan-et-Tourtirac',
    'Gardanne',
    'Gardais',
    'Garchizy',
    'Garches',
    'Garche',
    'Garcelles-Secqueville',
    'Garat',
    'Garancieres-en-Drouais',
    'Garancieres',
    'Garac',
    'Gap',
    'Ganzeville',
    'Ganties',
    'Gannes',
    'Gannat',
    'Ganges',
    'Gandrange',
    'Gandelu',
    'Gandelain',
    'Ganac',
    'Gan',
    'Gambsheim',
    'Gambais',
    'Gamarde-les-Bains',
    'Gamaches',
    'Galluis',
    'Gallician',
    'Gallardon',
    'Galie',
    'Galgon',
    'Galgan',
    'Galeria',
    'Galargues',
    'Galan',
    'Gajan',
    'Gainneville',
    'Gaillon',
    'Gailleres',
    'Gaillefontaine',
    'Gaillard',
    'Gaillan-en-Medoc',
    'Gaillac-Toulza',
    'Gaillac',
    'Gahard',
    'Gagny',
    'Gagnieres',
    'Gagnac-sur-Garonne',
    'Gael',
    'Gadencourt',
    'Gacogne',
    'Gace',
    'Gabrias',
    'Gabian',
    'Gabarret',
    'Gabarnac',
    'Gaas',
    'Fuveau',
    'Futeau',
    'Fussy',
    'Furmeyer',
    'Furiani',
    'Furdenheim',
    'Furchhausen',
    'Fumel',
    'Fumay',
    'Fuisse',
    'Fuilla',
    'Fublaines',
    'Frunce',
    'Fruges',
    'Frozes',
    'Froville',
    'Frouzins',
    'Frouard',
    'Frotey-les-Lure',
    'Frossay',
    'Frontonas',
    'Fronton',
    'Frontignan',
    'Frontenex',
    'Frontenay-Rohan-Rohan',
    'Frontenay',
    'Frontenas',
    'Frontenac',
    'Fronsac',
    'Froncles',
    'Fromy',
    'Fromentine',
    'Fromentieres',
    'Fromental',
    'Fromelles',
    'Fromelennes',
    'Frolois',
    'Froissy',
    'Froidmont-Cohartille',
    'Froidfond',
    'Froideterre',
    'Froideconche',
    'Froges',
    'Frocourt',
    'Froberville',
    'Friville-Escarbotin',
    'Frise',
    'Frignicourt',
    'Frichemesnil',
    'Fribourg',
    'Friaucourt',
    'Friardel',
    'Freyming-Merlebach',
    'Freybouse',
    'Frevent',
    'Freulleville',
    'Frettemeule',
    'Fretoy-le-Chateau',
    'Fretin',
    'Frethun',
    'Freteval',
    'Fresville',
    'Fressines',
    'Fresse-sur-Moselle',
    'Fressenneville',
    'Fressain',
    'Fresquiennes',
    'Fresnoy-les-Roye',
    'Fresnoy-le-Luat',
    'Fresnoy-le-Grand',
    'Fresnoy-la-Riviere',
    'Fresnoy-Andainville',
    'Fresnes-sur-Marne',
    'Fresnes-sur-Escaut',
    'Fresnes',
    'Fresnes-en-Woevre',
    'Fresnes-au-Mont',
    'Fresneaux-Montchevreuil',
    'Fresnay-sur-Sarthe',
    "Fresnay-l'Eveque",
    'Fresnay-le-Gilmert',
    'Fresnay-en-Retz',
    'Frepillon',
    'Freneuse-sur-Risle',
    'Freneuse',
    'Frencq',
    'Fremonville',
    'Fremicourt',
    'Fremestroff',
    'Fremainville',
    'Fréjus',
    'Frejeville',
    'Frejairolles',
    'Freistroff',
    'Freigne',
    'Fregouville',
    'Fregimont',
    'Frechou-Frechet',
    'Frebuans',
    'Frauenberg',
    'Frasnoy',
    'Frasne',
    'Franxault',
    'Franseches',
    'Frans',
    'Franqueville-Saint-Pierre',
    'Franqueville',
    'Franquevaux',
    'Franois',
    'Franleu',
    'Frangy',
    'Francueil',
    'Francourville',
    'Franconville',
    'Francon',
    'Francois',
    'Franclens',
    'Francin',
    'Francheville',
    'Franchevelle',
    'Francheval',
    'Franchesse',
    'Francheleins',
    'Francescas',
    'Francaltroff',
    'Frambouhans',
    'Fraize',
    'Fraisses',
    'Fraisans',
    'Frais',
    'Fraimbois',
    'Fragnes',
    'Fozzano',
    'Fozieres',
    'Foussignac',
    'Foussemagne',
    'Foussais-Payre',
    'Fours',
    'Fourquevaux',
    'Fourqueux',
    'Fourques-sur-Garonne',
    'Fourques',
    'Fournols',
    'Fournes-en-Weppes',
    'Fournes',
    'Fournels',
    'Fourneaux',
    'Fourmies',
    'Fourmetot',
    'Fourmagnac',
    'Fourges',
    'Fourg',
    'Fourdrinoy',
    'Fourdrain',
    'Val-Maravel',
    'Fourcigny',
    'Fourches',
    'Fourchambault',
    'Fouras',
    'Four',
    'Fouquieres-les-Lens',
    'Fouqueville',
    'Fouquerolles',
    'Fouquereuil',
    'Fouquenies',
    'Foulcrey',
    'Foulayronnes',
    'Foulain',
    'Fouju',
    'Fouilloy',
    'Fouillard',
    'Fougueyrolles',
    'Fougerolles-du-Plessis',
    'Fougerolles',
    'Fougeres-sur-Bievre',
    'Fougeres',
    'Fougere',
    'Fougax-et-Barrineuf',
    'Foug',
    'Fouesnant',
    'Fouchy',
    'Foucherolles',
    'Foucheres',
    'Foucherans',
    'Foucarville',
    'Foucarmont',
    'Fos-sur-Mer',
    'Fossoy',
    'Fosseuse',
    'Fosses',
    'Fossemagne',
    'Fortschwihr',
    'Fort-Mardyck',
    'Fort-Mahon-Plage',
    'Fortan',
    'Forstheim',
    'Forstfeld',
    'Fors',
    'Formiguères',
    'Formigny',
    'Formerie',
    'Forgues',
    'Forges-les-Eaux',
    'Forges-les-Bains',
    'Forges',
    'Forfry',
    'Forest-sur-Marque',
    'Forest-Saint-Julien',
    'Forcelles-sous-Gugney',
    'Forcelles-Saint-Gorgon',
    'Force',
    'Forcalquier',
    'Forcalqueiret',
    'Forbach',
    'Fontvieille',
    'Font-Romeu-Odeillo-Via',
    'Fontoy',
    "Fonties-d'Aude",
    'Fontgombault',
    'Fontet',
    'Fontes',
    'Fonters-du-Razes',
    'Fonteny',
    'Fontenoy-sur-Moselle',
    'Fontenoy',
    'Fontenouilles',
    'Fontenilles',
    'Fontenille',
    'Fontenelle',
    'Fontenay-Tresigny',
    'Fontenay-sur-Loing',
    'Fontenay-sur-Eure',
    'Fontenay-sous-Bois',
    'Fontenay-Saint-Pere',
    'Fontenay-pres-Vezelay',
    'Fontenay-pres-Chablis',
    'Fontenay-Mauvoisin',
    'Fontenay-le-Vicomte',
    'Fontenay-les-Briis',
    'Fontenay-le-Pesnel',
    'Fontenay-le-Fleury',
    'Fontenay-le-Comte',
    'Fontenay-en-Parisis',
    'Fontenay-aux-Roses',
    'Fontenai-sur-Orne',
    'Fontenailles',
    'Fontcouverte',
    'Fontclaireau',
    'Fontareches',
    'Fontannes',
    'Fontanil-Cornillon',
    'Fontangy',
    'Fontanges',
    'Fontains',
    'Fontaine-Uterte',
    'Fontaine-sur-Somme',
    'Fontaines-sur-Saone',
    'Fontaines-Saint-Martin',
    'Fontaine-Simon',
    "Fontaines-d'Ozillac",
    'Fontaines',
    'Fontaine-Notre-Dame',
    'Fontaine-Milon',
    'Fontaine-les-Luxeuil',
    'Fontaine-les-Gres',
    'Fontaine-le-Sec',
    'Fontaine-les-Dijon',
    'Fontaine-le-Port',
    'Fontaine-le-Dun',
    'Fontaine-le-Comte',
    'Fontaine-le-Bourg',
    'Fontaine-la-Mallet',
    'Fontaine-la-Guyon',
    "Fontaine-l'Abbe",
    'Fontaine-Heudebourg',
    'Fontaine-Guerin',
    'Fontaine-Francaise',
    'Fontaine-Etoupefour',
    'Fontaine-de-Vaucluse',
    'Fontainebleau',
    'Fontaine-Bellenger',
    'Fontaine-au-Pire',
    'Fontaine-au-Bois',
    'Fontaine',
    'Fontain',
    'Fons',
    'Fonsorbes',
    'Fonsomme',
    'Fonroque',
    'Fongueusemare',
    'Fondremand',
    'Fondettes',
    'Foncine-le-Haut',
    'Fonbeauzard',
    'Fomerey',
    'Folschviller',
    'Folleville',
    'Follainville-Dennemont',
    'Folkling',
    'Folgensbourg',
    'Folembray',
    'Folelli',
    'Folcarde',
    'Foix',
    'Foissiat',
    'Foecy',
    'Foameix-Ornel',
    'Fluquieres',
    'Flumet',
    'Flourens',
    'Flottemanville-Hague',
    'Floringhem',
    'Floressas',
    'Florentin',
    'Florensac',
    'Floremont',
    'Florange',
    'Florac',
    'Floirac',
    'Floing',
    'Flogny-la-Chapelle',
    'Flocques',
    'Flize',
    'Flixecourt',
    'Flipou',
    'Flines-lez-Raches',
    'Flines-les-Mortagne',
    'Flin',
    'Fligny',
    'Flez-Cuzy',
    'Fley',
    'Flexbourg',
    'Flexanville',
    'Fleury-sur-Orne',
    'Fleury-sur-Andelle',
    'Fleury-Merogis',
    'Fleury-les-Aubrais',
    'Fleury-la-Vallee',
    'Fleury-la-Montagne',
    'Fleury-en-Biere',
    'Fleury',
    'Fleurines',
    "Fleurieux-sur-l'Arbresle",
    'Fleurieu-sur-Saone',
    'Fleuriel',
    'Fleurie',
    'Fleurey-sur-Ouche',
    'Fleure',
    'Fleurbaix',
    'Fleurance',
    'Fleurac',
    'Fletre',
    'Flesselles',
    'Flers-en-Escrebieux',
    'Flers',
    'Flere-la-Riviere',
    'Fleisheim',
    'Flee',
    'Flechin',
    'Fleac-sur-Seugne',
    'Flayosc',
    'Flaxlanden',
    'Flavy-le-Martel',
    'Flavin',
    'Flavigny-sur-Moselle',
    'Flavignac',
    'Flaviac',
    'Flavacourt',
    'Flaux',
    'Flaujagues',
    'Flaucourt',
    'Flastroff',
    'Flassy',
    'Flassans-sur-Issole',
    'Flancourt-Catelon',
    'Flamanville',
    'Flagy',
    'Flagnac',
    'Flagey',
    'Flacheres',
    'Flacey-en-Bresse',
    'Fixin',
    'Fitz-James',
    'Fitilieu',
    'Fismes',
    'Fislis',
    'Firminy',
    'Firmi',
    'Fiquefleur-Equainville',
    'Finhan',
    'Filstroff',
    'Fillinges',
    'Fillievres',
    'Fillieres',
    'Fille',
    'Figeac',
    'Figarol',
    'Figanieres',
    'Fiennes',
    'Figarella',
    'Fiac',
    'Feyzin',
    'Feytiat',
    'Feves',
    'Feux',
    'Feusines',
    'Feurs',
    'Feuquieres-en-Vimeu',
    'Feuquieres',
    'Feuguerolles-Bully',
    'Feuchy',
    'Feucherolles',
    'Feternes',
    'Festigny',
    'Festieux',
    'Festalemps',
    'Fessy',
    'Fessenheim',
    'Fesches-le-Chatel',
    'Fertans',
    'Ferriere-sur-Beaulieu',
    'Ferrieres-la-Verrerie',
    'Ferrieres-Haut-Clocher',
    'Ferrieres-en-Brie',
    'Ferrieres-en-Gatinais',
    'Ferrieres',
    'Ferriere-la-Petite',
    'Ferriere-la-Grande',
    'Ferreux-Quincey',
    'Ferrette',
    'Ferrensac',
    'Ferques',
    'Feron',
    'Ferolles-Attilly',
    'Ferolles',
    'Ferney-Voltaire',
    'Fermanville',
    'Ferin',
    'Fericy',
    'Ferel',
    'Fere-en-Tardenois',
    'Fere-Champenoise',
    'Ferdrupt',
    'Fenouillet',
    'Feneu',
    'Fenetrange',
    'Fenery',
    'Fenay',
    'Fenain',
    'Felzins',
    'Felletin',
    'Fellering',
    'Felleries',
    'Felines-Termenes',
    'Felines',
    'Feliceto',
    'Feldbach',
    'Feissons-sur-Isere',
    'Feins',
    'Feings',
    'Feillens',
    'Feignies',
    'Feigeres',
    'Fegreac',
    'Fegersheim',
    'Fechain',
    'Fécamp',
    'Febvin-Palfart',
    'Feas',
    'Fay-sur-Lignon',
    'Fays',
    'Faymoreau',
    'Faymont',
    'Fay-les-Etangs',
    'Fayl-Billot',
    'Fayet-Ronaye',
    'Fayet',
    'Fayence',
    'Faye-dAnjou',
    'Fay-de-Bretagne',
    'Faycelles',
    'Fay-aux-Loges',
    'Favieres',
    'Faverolles-sur-Cher',
    'Faverolles-et-Coemy',
    'Faverolles',
    'Faverney',
    'Faverges',
    'Favars',
    'Faux-la-Montagne',
    'Fauverney',
    'Fauroux',
    'Fauquembergues',
    'Faumont',
    'Faulx',
    'Faulquemont',
    'Fauillet',
    'Fauguerolles',
    'Faugeres',
    'Faudoas',
    'Faucoucourt',
    'Faucon-de-Barcelonnette',
    'Faucogney-et-la-Mer',
    'Faucigny',
    'Fatines',
    'Farschviller',
    'Farnay',
    'Fargues-sur-Ourbise',
    'Fargues-Saint-Hilaire',
    'Fargues',
    'Fargniers',
    'Farges-les-Chalon',
    'Farges',
    'Faremoutiers',
    'Fareins',
    'Farebersviller',
    'Farbus',
    'Faramans',
    'Fanjeaux',
    'Fampoux',
    'Familly',
    'Fameck',
    'Famechon',
    'Famars',
    'Fals',
    'Falleron',
    'Falicon',
    'Faleyras',
    'Falck',
    'Falaise',
    'Fajolles',
    'Fajac-en-Val',
    'Fains-la-Folie',
    'Fains',
    'Fahy-les-Autrey',
    'Fagnieres',
    'Fabrezan',
    'Fabregues',
    'Fabras',
    'Fabas',
    'Ezy-sur-Eure',
    'Ezanville',
    'Eyzin-Pinet',
    'Eysus',
    'Eysines',
    'Eyres-Moncube',
    'Eyrein',
    'Eyragues',
    'Eynesse',
    'Eymoutiers',
    'Eymeux',
    'Eymet',
    'Eyjeaux',
    'Eygurande',
    'Eyguieres',
    'Eygliers',
    'Eygalieres',
    'Eycheil',
    'Eybens',
    'Exoudun',
    'Exireuil',
    'Exincourt',
    'Exideuil',
    'Excideuil',
    'Excenevex',
    'Évry',
    'Evron',
    'Evriguet',
    'Évreux',
    'Evrecy',
    'Evran',
    'Evires',
    'Evin-Malmaison',
    'Evillers',
    'Evigny',
    'Évian-les-Bains',
    'Eveux',
    'Evette-Salbert',
    'Everly',
    'Evenos',
    'Evecquemont',
    'Eve',
    'Evaux-les-Bains',
    'Evaille',
    'Euville',
    'Eus',
    'Eurville',
    'Eurville-Bienville',
    'Eurre',
    'Eugenie-les-Bains',
    'Euffigneix',
    'Eu',
    'Etzling',
    'Etuz',
    'Etupes',
    'Etsaut',
    'Etrun',
    'Etroussat',
    'Etroeungt',
    'Etrochey',
    'Etrigny',
    'Etricourt-Manancourt',
    'Etriche',
    'Etrez',
    'Etreville',
    'Etreval',
    'Etreux',
    'Étretat',
    'Etrepilly',
    'Etrepigny',
    'Etrepagny',
    'Etrembieres',
    'Etrelles',
    'Etreillers',
    'Etrechy',
    'Etrechet',
    'Etreaupont',
    'Etouy',
    'Etoutteville',
    'Etourvy',
    'Etoile-sur-Rhone',
    'Etoges',
    'Etobon',
    'Etival-les-le-Mans',
    'Etival-Clairefontaine',
    'Etival',
    'Etiolles',
    'Etinehem',
    'Etigny',
    'Etienville',
    'Eterville',
    'Etercy',
    'Etel',
    'Etcharry',
    'Etaux',
    'Etauliers',
    'Etaules',
    'Étaples',
    'Etang-sur-Arroux',
    'Etampes-sur-Marne',
    'Étampes',
    'Etalondes',
    'Etalleville',
    'Etalans',
    'Etais-la-Sauvin',
    'Etainhus',
    'Étain',
    'Etaimpuis',
    'Etagnac',
    'Etables-sur-Mer',
    'Etables',
    'Etable',
    'Esvres',
    'Estry',
    'Estrun',
    'Estreux',
    'Estrennes',
    'Estrees-sur-Noye',
    'Estrees-Saint-Denis',
    'Estrees-Mons',
    'Estrees',
    'Estreelles',
    'Estree',
    'Estrablin',
    'Estourmel',
    'Estoher',
    'Estivareilles',
    'Estissac',
    'Estillac',
    'Estibeaux',
    'Estezargues',
    'Esteville',
    'Estevelles',
    'Esternay',
    'Estang',
    'Estandeuil',
    'Estancarbon',
    'Estal',
    'Estaires',
    'Estaing',
    'Estagel',
    'Estadens',
    'Estables',
    'Essuiles',
    'Essoyes',
    'Esson',
    'Essomes-sur-Marne',
    'Essigny-le-Grand',
    'Essey-les-Nancy',
    'Esserts-Blay',
    'Essertines-en-Chatelneuf',
    'Essertenne-et-Cecey',
    'Essertaux',
    'Essert',
    'Esse',
    'Essay',
    'Essars',
    'Essarois',
    'Esquiule',
    'Esquibien',
    'Esquerdes',
    'Esquerchin',
    'Esquennoy',
    'Esquelbecq',
    'Esqueheries',
    'Esquay-sur-Seulles',
    'Esquay-Notre-Dame',
    'Esprels',
    'Espondeilhan',
    'Espoey',
    'Espirat',
    "Espira-de-l'Agly",
    'Espira-de-Conflent',
    'Espinasse-Vozelle',
    'Espinasses',
    'Espiet',
    'Espiens',
    'Espere',
    'Esperaza',
    'Espeluche',
    'Espelette',
    'Espedaillac',
    'Espechede',
    'Espas',
    'Esparsac',
    'Esparros',
    'Espanes',
    'Espalion',
    'Espalais',
    'Espagnac',
    'Esnouveaux',
    'Esnandes',
    'Esmoulins',
    'Esmery-Hallon',
    'Esmans',
    'Eslettes',
    'Esery',
    'Escrennes',
    'Escragnolles',
    'Escoville',
    'Escoutoux',
    'Escoussens',
    'Escoussans',
    'Escource',
    'Escouloubre',
    'La Baule-Escoublac',
    'Escou',
    'Escot',
    'Escos',
    'Escorneboeuf',
    'Esclottes',
    'Escles',
    'Esclavolles-Lurey',
    'Esclavelles',
    'Esclanedes',
    'Esclagne',
    'Esches',
    'Escherange',
    'Eschentzwiller',
    'Eschbach-au-Val',
    'Eschau',
    'Escautpont',
    'Escaudoeuvres',
    'Escaudes',
    'Escaudain',
    'Escatalens',
    'Escassefort',
    'Escamps',
    'Escalquens',
    'Escalles',
    'Escales',
    'Escala',
    'Esbly',
    'Esbarres',
    'Ervy-le-Chatel',
    'Erstroff',
    'Erstein',
    'Errouville',
    'Erre',
    'Er',
    'Erquy',
    'Erquinvillers',
    'Erquinghem-Lys',
    'Erquinghem-le-Sec',
    'Erquery',
    'Erondelle',
    'Erome',
    'Ernolsheim-les-Saverne',
    'Ernolsheim-Bruche',
    'Ernestviller',
    'Ernemont-sur-Buchy',
    'Ernemont-Boutavent',
    'Ernee',
    'Ermont',
    'Ermenouville',
    'Ermenonville',
    'Erlon',
    'Erize-la-Brulee',
    'Eringes',
    'Erin',
    'Erigne',
    'Ergue-Gaberic',
    'Ergny',
    'Ergnies',
    'Ereac',
    'Erdeven',
    'Ercuis',
    'Ercourt',
    'Erckartswiller',
    'Erchin',
    'Ercheu',
    'Erceville',
    'Erce-pres-Liffre',
    'Erce-en-Lamee',
    'Erce',
    'Erbree',
    'Erbray',
    'Erbalunga',
    'Eraville',
    'Eragny',
    'Equilly',
    'Equihen-Plage',
    'Equevillon',
    'Equeurdreville-Hainneville',
    'Equennes-Eramecourt',
    'Eps',
    'Epron',
    'Epreville-pres-le-Neubourg',
    'Epretot',
    'Epping',
    'Eppeville',
    'Epouville',
    'Epothemont',
    'Epone',
    'Epoisses',
    'Epinoy',
    'Epinouze',
    'Epineuse',
    'Epineuil-le-Fleuriel',
    'Épinay-sur-Seine',
    'Épinay-sur-Orge',
    'Epinay-sur-Odon',
    'Epinay-sur-Duclair',
    'Epinay-sous-Senart',
    'Épinal',
    'Epinac',
    'Epierre',
    'Epieds-en-Beauce',
    'Epieds',
    'Epiais-les-Louvres',
    'Epfig',
    'Epervans',
    'Epernon',
    'Epernay-sous-Gevrey',
    'Épernay',
    'Eperlecques',
    'Epercieux-Saint-Paul',
    'Epeigne-les-Bois',
    'Epegard',
    'Epaux-Bezu',
    'Epaumesnil',
    'Epargnes',
    'Epannes',
    'Epaney',
    'Epaignes',
    'Epagny',
    'Envermeu',
    'Enval',
    'Entzheim',
    'Entressen',
    'Entremont-le-Vieux',
    'Entre-Deux-Guiers',
    'Entrechaux',
    'Entrecasteaux',
    'Entraygues-sur-Truyere',
    'Entrange',
    'Entrammes',
    'Entrains-sur-Nohain',
    'Entraigues-sur-la-Sorgue',
    'Entraigues',
    'Ensues-la-Redonne',
    'Ensisheim',
    'Enquin-les-Mines',
    'Ennezat',
    'Ennevelin',
    'Ennery',
    'Engwiller',
    'Engomer',
    'Englos',
    'Englefontaine',
    'Englebelmer',
    'Enghien-les-Bains',
    'Engenville',
    'Engayrac',
    'Enfonvelle',
    'Enchenberg',
    'Enchastrayes',
    'Empurany',
    'Emondeville',
    'Emmerin',
    'Emlingen',
    'Emieville',
    'Emeville',
    'Emerchicourt',
    'Emerainville',
    'Embry',
    'Embrun',
    'Embreville',
    'Embres-et-Castelmaure',
    'Emanville',
    'Emalleville',
    'Elzange',
    'Elven',
    'Elsenheim',
    'Eloyes',
    'Eloise',
    'Eloie',
    'Elnes',
    'Elna',
    'Ellon',
    'Elliant',
    'Elincourt-Sainte-Marguerite',
    'Elincourt',
    'Elesmes',
    'Elbeuf-sur-Andelle',
    'Elbeuf',
    'Élancourt',
    'Einville-au-Jard',
    'Einvaux',
    'Eichhoffen',
    'Ehuns',
    'Eguzon-Chantome',
    'Eguisheim',
    'Eguilles',
    'Eguelshardt',
    'Egriselles-le-Bocage',
    'Egreville',
    'Egly',
    'Egliseneuve-pres-Billom',
    'Eglise-Neuve-de-Vergt',
    "Egliseneuve-d'Entraigues",
    'Egletons',
    'Egat',
    'Effry',
    'Effincourt',
    'Effiat',
    'Eecke',
    'Ecuvilly',
    'Ecury-sur-Coole',
    'Ecurat',
    'Ecully',
    'Ecuisses',
    'Ecuires',
    'Ecuille',
    'Ecuelles',
    'Ecuelle',
    'Ecueil',
    "Ectot-l'Auber",
    'Ecrouves',
    'Ecrosnes',
    'Ecromagny',
    'Ecretteville-les-Baons',
    'Ecrammeville',
    'Ecquevilly',
    'Ecquetot',
    'Ecques',
    'Ecoyeux',
    'Ecouviez',
    'Ecoust-Saint-Mein',
    'Ecourt-Saint-Quentin',
    'Ecouis',
    'Ecouflant',
    'Ecouen',
    'Ecotay',
    'Ecos',
    'Ecorcei',
    'Ecommoy',
    'Ecole-Valentin',
    'Ecoche',
    'Eclose',
    'Ecleux',
    'Eclassan',
    'Eclaron-Braucourt-Sainte-Liviere',
    'Eclaires',
    'Eckwersheim',
    'Eckbolsheim',
    'Echourgnac',
    'Échirolles',
    'Echire',
    'Echinghen',
    'Echilleuses',
    'Echillais',
    'Echevronne',
    'Echenoz-la-Meline',
    'Echenon',
    'Echenevex',
    'Echemire',
    'Echemines',
    'Echauffour',
    'Echassieres',
    'Echalou',
    'Echalas',
    'Eccica-Suarella',
    'Ecardenville-sur-Eure',
    'Ecaquelon',
    'Ecaillon',
    'Ebreuil',
    'Ebersviller',
    'Ebersheim',
    'Ebblinghem',
    'Eauze',
    'Eaux-Puiseaux',
    'Eaunes',
    'Eaubonne',
    'Dyo',
    'Dye',
    'Duvy',
    'Duttlenheim',
    'Dury',
    'Durtol',
    'Durtal',
    'Durstel',
    'Durrenentzen',
    'Durrenbach',
    'Durnes',
    'Durmenach',
    'Durenque',
    'Larequille',
    'Durban-Corbieres',
    'Duravel',
    'Duras',
    'Duranus',
    'Duppigheim',
    'Duntzenheim',
    'Dun-sur-Meuse',
    'Dun-sur-Auron',
    'Dun-le-Palestel',
    'Dunkirk',
    'Dunieres',
    'Duniere',
    'Dunes',
    'Duneau',
    'Dun',
    'Duisans',
    'Duingt',
    'Dugny-sur-Meuse',
    'Dugny',
    'Duerne',
    'Duclair',
    'Ducey',
    'Duault',
    'Dry',
    'Druye',
    'Drusenheim',
    'Drumettaz',
    'Drulingen',
    'Drulhe',
    'Druillat',
    'Drugeac',
    'Druelle',
    'Drucat',
    'Droyes',
    'Droux',
    'Drouville',
    'Droupt-Saint-Basle',
    'Drouilly',
    'Drouges',
    'Droue-sur-Drouette',
    'Droue',
    'Dromesnil',
    'Droisy',
    'Drocourt',
    'Driencourt',
    'Drevant',
    'Dreux',
    'Dreuil-les-Amiens',
    'Dreslincourt',
    'Dremil-Lafage',
    'Dreffeac',
    'Draveil',
    'Drap',
    'Drancy',
    'Drain',
    'Draillant',
    'Draguignan',
    'Dragey-Ronthon',
    'Dracy-Saint-Loup',
    'Drache',
    'Drace',
    'Dozule',
    'Doyet',
    'Douzy',
    'Douzillac',
    'Douzens',
    'Douy-la-Ramee',
    'Douy',
    'Doux',
    'Douvrin',
    'Douvres-la-Delivrande',
    'Douvres',
    'Douville-en-Auge',
    'Douville',
    'Douvaine',
    'Doussay',
    'Doussard',
    'Dournazac',
    'Douriez',
    'Dourgne',
    'Dourges',
    'Dourdan',
    'Dourdain',
    'Dounoux',
    'Doullens',
    'Doulezon',
    'Doulevant-le-Chateau',
    'Doulcon',
    'Doulaincourt-Saucourt',
    'Douilly',
    'Douillet',
    'Douelle',
    'Doue-la-Fontaine',
    'Doue',
    'Doudrac',
    'Doudeville',
    'Doudelainville',
    'Doudeauville',
    'Doucier',
    'Douchy-les-Mines',
    'Douchy',
    'Douchapt',
    'Doubs',
    'Douarnenez',
    'Douai',
    'Dossenheim-sur-Zinsel',
    'Dosches',
    'Dortan',
    'Dorres',
    'Dornes',
    'Dornecy',
    'Dornas',
    'Dormelles',
    'Dormans',
    'Dorlisheim',
    'Dorignies',
    'Dordives',
    'Dorceau',
    'Dorat',
    'Donzere',
    'Donzenac',
    'Donzac',
    'Donville-les-Bains',
    'Dontrien',
    'Dontreix',
    'Donnezac',
    'Donneville',
    'Donnery',
    'Donnemarie-Dontilly',
    'Donnemain-Saint-Mames',
    'Donnelay',
    'Donnay',
    'Donjeux',
    'Donges',
    'Doncourt-les-Longuyon',
    'Doncourt-les-Conflans',
    'Doncieres',
    'Donchery',
    'Don',
    'Domsure',
    'Domqueur',
    'Domptin',
    'Dompierre-sur-Yon',
    'Dompierre-sur-Veyle',
    'Dompierre-sur-Mer',
    'Dompierre-sur-Charente',
    'Dompierre-sur-Chalaronne',
    'Dompierre-sur-Besbre',
    'Dompierre-les-Eglises',
    'Dompierre-Becquincourt',
    'Dompierre-du-Chemin',
    'Dompierre',
    'Dompcevrin',
    'Dompaire',
    'Domont',
    'Domme',
    'Dommartin-les-Vallois',
    'Dommartin-les-Cuiseaux',
    'Dommartin',
    'Dommartemont',
    'Dom-le-Mesnil',
    'Domjean',
    'Dominois',
    'Domgermain',
    'Domfront-en-Champagne',
    'Domfront',
    'Domezain-Berraute',
    'Domeyrat',
    'Domevre-sur-Vezouze',
    'Domevre-sur-Durbion',
    'Domevre-sur-Aviere',
    'Domevre-en-Haye',
    'Domessin',
    'Domessargues',
    'Domerat',
    'Domene',
    'Domeliers',
    'Domblans',
    'Dombasle-sur-Meurthe',
    'Dombasle-en-Argonne',
    'Dombasle-devant-Darney',
    'Domazan',
    'Domats',
    'Domart-en-Ponthieu',
    'Domarin',
    'Domancy',
    'Domalain',
    'Domagne',
    'Dolving',
    'Dolus-le-Sec',
    "Dolus-d'Oleron",
    'Dolomieu',
    'Dolo',
    'Dolmayrac',
    'Dollon',
    'Dole',
    'Dol-de-Bretagne',
    'Dolcourt',
    'Doingt',
    'Dogneville',
    'Dognen',
    'Docelles',
    'Doazit',
    'Dizy-le-Gros',
    'Dizy',
    'Dizimieu',
    'Dixmont',
    'Divonne-les-Bains',
    'Divion',
    'Dives-sur-Mer',
    'Dival',
    'Diusse',
    'Distroff',
    'Distre',
    'Dissay',
    'Dissangis',
    'Dirinon',
    'Dirac',
    'Diou',
    'Diors',
    'Dions',
    'Dinsheim-sur-Bruche',
    'Dinsac',
    'Dingy-Saint-Clair',
    'Dingsheim',
    'Dinge',
    'Dinard',
    'Dinan',
    'Dijon',
    'Digosville',
    'Digoin',
    'Digny',
    'Digne-les-Bains',
    'Dignac',
    'Diges',
    'Dieval',
    'Dieuze',
    'Dieupentale',
    'Dieulouard',
    'Dieulefit',
    'Dieue-sur-Meuse',
    'Dieudonne',
    'Dietwiller',
    'Diesen',
    'Dierrey-Saint-Pierre',
    'Dierrey-Saint-Julien',
    'Dierre',
    'Dieppe-sous-Douaumont',
    'Dieppe',
    'Dienville',
    'Diemoz',
    'Diemeringen',
    'Diefmatten',
    'Diebling',
    'Die',
    'Didenheim',
    'Diconne',
    'Diarville',
    'Diane-Capelle',
    'Dhuizon',
    'DHuison-Longueville',
    'Deyvillers',
    'Deyme',
    'Devrouze',
    'Deville-les-Rouen',
    'Deville',
    'Devesset',
    'Devecey',
    'Devay',
    'Deux-Chaises',
    'Deulemont',
    'Deuil-la-Barre',
    'Dettwiller',
    'Detrier',
    'Desvres',
    'Dessenheim',
    'Desnes',
    'Desges',
    'Deservillers',
    'Desertines',
    'Desandans',
    'Desaignes',
    'Derval',
    'Dernacueillette',
    'Dercy',
    'Derchigny',
    'Déols',
    'Denonville',
    'Denney',
    'Denneville',
    'Dennebroeucq',
    'Denipaire',
    'Denice',
    'Denguin',
    'Denestanville',
    'Denee',
    'Denaze',
    'Denain',
    'Demuin',
    'Demu',
    'Demouville',
    'Demigny',
    'Demangevelle',
    'Demange-aux-Eaux',
    'Demandolx',
    'Delme',
    'Delle',
    'Delincourt',
    'Delettes',
    'Degre',
    'Degagnac',
    'Decize',
    'Decines-Charpieu',
    'Decazeville',
    'Deaux',
    'Deauville',
    'Dax',
    'Davezieux',
    'Davejean',
    'Davaye',
    'Davayat',
    'Daux',
    'Dauphin',
    'Daumeray',
    'Daumazan-sur-Arize',
    'Dauendorf',
    'Daubeuf-pres-Vatteville',
    'Daubensand',
    'Dasle',
    'Darvoy',
    'Darvault',
    'Darois',
    'Darnieulles',
    'Darney',
    'Darnetal',
    'Dargoire',
    'Dargnies',
    'Dargies',
    'Dareize',
    'Dardilly',
    'Daours',
    'Daoulas',
    'Daon',
    'Dannes',
    'Dannemois',
    'Dannemarie',
    'Dannelbourg',
    'Danjoutin',
    'Danizy',
    'Dangy',
    'Dangu',
    'Dangolsheim',
    'Dangeul',
    'Dangeau',
    'Dange-Saint-Romain',
    'Danestal',
    'Dancy',
    'Dancevoir',
    'Dance',
    'Damvix',
    'Damvillers',
    'Damville',
    'Damremont',
    'Damprichard',
    'Dampmart',
    'Dampierre-sur-Salon',
    'Dampierre-sur-Avre',
    'Dampierre-sous-Bouhy',
    'Dampierre-Saint-Nicolas',
    'Dampierre-les-Conflans',
    'Dampierre-et-Flee',
    'Dampierre-en-Yvelines',
    'Dampierre',
    'Damparis',
    'Damouzy',
    'Dammartin-sur-Tigeaux',
    'Dammartin-en-Serve',
    'Dammartin-en-Goele',
    'Dammartin-Marpain',
    'Dammarie-les-Lys',
    'Dammarie-en-Puisaye',
    'Dammarie',
    'Damigny',
    'Damiatte',
    'Damgan',
    'Damery',
    'Damerey',
    'Dame-Marie-les-Bois',
    'Dame-Marie',
    'Damelevieres',
    'Dambron',
    'Damblain',
    'Dambelin',
    'Dambach-la-Ville',
    'Damazan',
    'Damas-et-Bettegney',
    'Dallon',
    'Dallet',
    'Dalhunden',
    'Dalem',
    'Dainville',
    'Daillecourt',
    'Daillancourt',
    'Daigny',
    'Dahlenheim',
    'Dagneux',
    'Daglan',
    'Dadonville',
    'Dachstein',
    'Dabo',
    'Cysoing',
    'Cuzorn',
    'Cuzion',
    'Cuzieu',
    'Cuzance',
    'Cuy',
    "Cuxac-d'Aude",
    'Cuxac-Cabardes',
    'Cuvry',
    'Cuvilly',
    'Cuvillers',
    'Cuverville',
    'Cuvergnon',
    'Cuvat',
    'Cuttura',
    'Cuts',
    'Cutry',
    'Cuttoli-Corticchiato',
    'Cusy',
    'Custines',
    "Cussey-sur-l'Ognon",
    'Cusset',
    'Cussay',
    'Cussangy',
    'Cussac-Fort-Medoc',
    'Cussac-sur-Loire',
    'Cussac',
    'Curzon',
    'Curvalle',
    'Curtafond',
    'Cursan',
    'Curienne',
    'Curgies',
    'Cures',
    'Curcy-sur-Orne',
    'Curciat-Dongalon',
    'Curchy',
    'Curbigny',
    'Curbans',
    'Cuq-Toulza',
    'Cuq',
    'Cunlhat',
    'Cuneges',
    'Cunac',
    'Cumieres',
    'Cult',
    'Culoz',
    'Culmont',
    'Culles-les-Roches',
    'Culin',
    'Culhat',
    'Culey-le-Patry',
    'Culan',
    'Cuisy',
    'Cuisia',
    'Cuisery',
    'Cuise-la-Motte',
    'Cuiseaux',
    'Cuis',
    'Cuinzier',
    'Cuincy',
    'Cuinchy',
    'Cuigy-en-Bray',
    'Cuhon',
    'Cuguron',
    'Cugny',
    'Cugney',
    'Cugnaux',
    'Cuges-les-Pins',
    'Cugand',
    'Cuffy',
    'Cuffies',
    'Cuers',
    'Cudos',
    'Cucuron',
    'Cucugnan',
    'Cucq',
    'Cuchery',
    'Cubzac-les-Ponts',
    'Cublize',
    'Cublac',
    'Cubjac',
    'Cruviers-Lascours',
    'Crusnes',
    'Cruseilles',
    'Cruscades',
    'Crulai',
    'Crugny',
    'Cruet',
    'Cruas',
    'Crozon',
    'Crozet',
    'Crouy-sur-Ourcq',
    'Crouy-sur-Cosson',
    'Crouy-en-Thelle',
    'Croutelle',
    'Crottet',
    'Crotenay',
    'Crotelles',
    'Crossac',
    'Crosne',
    'Crosmieres',
    'Crolles',
    'Croixrault',
    'Croix-Moligneaux',
    'Croix-en-Ternois',
    'Croix-de-Vie',
    'Croixdalle',
    'Croix-Chapeau',
    'Croix',
    'Croisy',
    'Croissy-sur-Seine',
    'Croissy-Beaubourg',
    'Croismare',
    'Croisilles',
    'Croisette',
    'Croignon',
    'Crochte',
    'Cristot',
    'Crissey',
    'Crisse',
    'Crissay-sur-Manse',
    'Crisolles',
    'Crisenoy',
    'Criquetot-sur-Ouville',
    "Criquetot-l'Esneval",
    'Criquebeuf-sur-Seine',
    'Crimolois',
    'Criel-sur-Mer',
    'Cricqueville-en-Auge',
    'Criqueboeuf',
    'Crezancy',
    'Creysse',
    'Creyssac',
    'Creys-Mepieu',
    'Crevin',
    'Crevic',
    'Crevecoeur-le-Grand',
    'Crevant-Laveine',
    'Creuzier-le-Vieux',
    'Creutzwald',
    'Creuse',
    'Creully',
    'Creue',
    'Créteil',
    'Crest',
    'Cressy',
    'Cressonsacq',
    'Cresseveuille',
    'Cresserons',
    'Cressensac',
    'Cressat',
    'Cressanges',
    'Crespin',
    'Crespieres',
    'Crespian',
    'Cresantignes',
    'Crépy-en-Valois',
    'Crepol',
    'Crepieux-la-Pape',
    'Crepand',
    "Creon-d'Armagnac",
    'Creon',
    'Cremps',
    'Cremieu',
    'Cremeaux',
    'Cremarest',
    'Creissan',
    'Creil',
    'Crehen',
    'Crehange',
    'Cregy-les-Meaux',
    'Credin',
    'Crecy-sur-Serre',
    'Crecy-en-Ponthieu',
    'Crecy-la-Chapelle',
    'Crechy',
    'Crechets',
    'Creches-sur-Saone',
    'Creancey',
    'Creances',
    'Craywick',
    'Cravant',
    'Cravans',
    'Cravanche',
    'Cras-sur-Reyssouze',
    'Craponne-sur-Arzon',
    'Craponne',
    'Craonnelle',
    'Craon',
    'Cranves-Sales',
    'Crantenoy',
    'Cransac',
    'Crancot',
    'Crancey',
    'Crampagna',
    'Cramoisy',
    'Cramchaban',
    'Cramant',
    'Craintilleux',
    'Crachier',
    'Crach',
    'Cozzano',
    'Cozes',
    'Coyolles',
    'Coye-la-Foret',
    'Coyecques',
    'Cox',
    "Couzon-au-Mont-d'Or",
    'Couzon',
    'Couzeix',
    'Couy',
    'Coux-et-Bigaroque',
    'Coux',
    'Couville',
    'Couvertpuis',
    'Couture-sur-Loir',
    'Couture',
    'Coutras',
    'Coutouvre',
    'Coutiches',
    'Couthures-sur-Garonne',
    'Couthenans',
    'Coutevroult',
    'Couternon',
    'Couterne',
    'Coutens',
    'Coutances',
    'Coustellet',
    'Coussey',
    'Coussac-Bonneval',
    'Cousolre',
    'Cousances-les-Forges',
    'Cousance',
    'Courzieu',
    'Courville-sur-Eure',
    'Courville',
    'Courvieres',
    'Courtry',
    'Courtrizy-et-Fussigny',
    'Courtonne-la-Meurdrac',
    'Courtomer',
    'Courtois-sur-Yonne',
    'Courtisols',
    'Courtillers',
    'Courthezon',
    'Courtesoult-et-Gatey',
    'Courtes',
    'Courteranges',
    'Courtenot',
    'Courtenay',
    'Courtemanche',
    'Courtefontaine',
    'Courtaoult',
    'Courtalain',
    'Courson-les-Carrieres',
    'Cours-les-Barres',
    'Courseulles-sur-Mer',
    'Courset',
    'Coursegoules',
    'Cours-de-Pile',
    'Cours-de-Monsegur',
    'Coursan',
    'Coursac',
    'Cours',
    'Cours-la-Ville',
    'Courrières',
    'Courrensan',
    'Courpignac',
    'Courpiere',
    'Courpiac',
    'Courpalay',
    'Cournonterral',
    'Cournonsec',
    "Cournon-d'Auvergne",
    'Cournon',
    'Cournols',
    'Cournanel',
    'Courmemin',
    'Courmelles',
    'Courmangoux',
    'Courlon-sur-Yonne',
    'Courlay',
    'Courlaoux',
    'Courlans',
    'Courgis',
    'Courgenay',
    'Courgains',
    'Cour-et-Buis',
    'Courdemanche',
    'Courcy-aux-Loges',
    'Courcy',
    'Courcoury',
    'Courcouronnes',
    'Courcoue',
    'Courcon',
    'Courcite',
    'Cour-Cheverny',
    'Courchevel',
    'Courchelettes',
    'Courchaton',
    'Courchapon',
    'Courcerac',
    'Courcemont',
    "Courcelles-Val-d'Esnoms",
    'Courcelles-sur-Vesle',
    'Courcelles-sur-Seine',
    'Courcelles-sur-Nied',
    'Courcelles-sous-Moyencourt',
    'Courcelles-Sapicourt',
    'Courcelles-les-Montbeliard',
    'Courcelles-les-Lens',
    'Courcelles-les-Gisors',
    'Courcelles-la-Foret',
    'Courcelles-de-Touraine',
    'Courcelles-Chaussy',
    'Courcelles',
    'Courcelles-en-Bassee',
    'Courcelette',
    'Courceboeufs',
    'Courcay',
    'Courbouzon',
    'Courboin',
    'Courbevoie',
    'Courbesseaux',
    'Courbepine',
    'Courances',
    'Couqueques',
    'Coupru',
    'Coupiac',
    'Coupelle-Vieille',
    'Coume',
    'Coulvain',
    'Couloutre',
    'Coulounieix-Chamiers',
    'Coulonges-sur-Sarthe',
    'Coulonges-sur-lAutize',
    'Coulonges',
    'Coulonge',
    'Coulon',
    'Coulommiers',
    'Coulomby',
    'Coulombs',
    'Coulombiers',
    'Coulogne',
    'Coulobres',
    'Coulmier-le-Sec',
    'Coullons',
    'Coulimer',
    'Coulgens',
    'Coulans-sur-Gee',
    'Coulanges-sur-Yonne',
    'Coulanges-les-Nevers',
    'Coulanges-la-Vineuse',
    'Coulanges',
    'Coulangeron',
    'Coulandon',
    'Coulaines',
    'Couladere',
    'Couiza',
    'Couin',
    'Couilly-Pont-aux-Dames',
    'Couhe',
    'Coufouleux',
    'Couffy',
    'Couffe',
    'Coueron',
    'Coudures',
    'Coudun',
    'Coudres',
    'Coudreceau',
    'Coudray-Rabut',
    'Coudray',
    'Coudoux',
    'Coudeville-sur-Mer',
    'Coudes',
    'Coudekerque-Branche',
    'Coudekerque-Village',
    'Couddes',
    'Coucy-le-Chateau-Auffrique',
    'Couches',
    'Coubron',
    'Coubon',
    'Coublucq',
    'Coublevie',
    'Coubert',
    'Cottenchy',
    'Cottance',
    'Cotignac',
    'Coti-Chiavari',
    'Costaros',
    'Cosswiller',
    'Cossesseville',
    'Cosse-le-Vivien',
    'Cosne-Cours-sur-Loire',
    'Cosnes',
    "Cosne-d'Allier",
    'Cosnac',
    'Cosledaa-Lube-Boast',
    'Cos',
    'Corze',
    'Corveissiat',
    'Cortevaix',
    'Corte',
    'Corseul',
    'Corsept',
    'Corronsac',
    'Corrombles',
    'Corrobert',
    'Corribert',
    'Correze',
    'Correns',
    'Corquoy',
    'Corquilleroy',
    'Corps-Nuds',
    'Corpeau',
    'Corpe',
    'Coron',
    'Corny-sur-Moselle',
    'Corny',
    'Cornimont',
    'Cornillon-Confoux',
    'Cornille-les-Caves',
    'Cornille',
    'Cornier',
    'Corneilla-la-Riviere',
    'Corneilla-del-Vercol',
    'Corneilhan',
    'Cornebarrieu',
    'Corne',
    'Cornas',
    'Cornant',
    'Cornac',
    'Cormoyeux',
    'Cormontreuil',
    'Cormicy',
    'Cormes',
    'Cormery',
    'Corme-Royal',
    'Cormeray',
    'Cormenon',
    'Cormeilles-en-Vexin',
    'Cormeilles-en-Parisis',
    'Cormeilles',
    'Cormatin',
    'Cormaranche-en-Bugey',
    'Corlay',
    'Corignac',
    'Corgoloin',
    'Corgnac-sur-lIsle',
    'Corent',
    'Corenc',
    'Cordes-Tolosannes',
    'Cordesse',
    'Cordes-sur-Ciel',
    'Cordemais',
    'Cordelle',
    'Corcy',
    'Corcondray',
    'Corcieux',
    'Corcelles-les-Monts',
    'Corcelles-les-Citeaux',
    'Corcelles-en-Beaujolais',
    'Corbreuse',
    'Corbigny',
    'Corbieres',
    'Corbie',
    'Corberon',
    'Corbere-les-Cabanes',
    'Corbere',
    'Corbeny',
    'Corbenay',
    'Corbelin',
    'Corbeilles',
    'Corbeil-Essonnes',
    'Corbehem',
    'Corbas',
    'Corbarieu',
    'Coray',
    'Corancy',
    'Corancez',
    'Coquelles',
    'Copponex',
    'Conty',
    'Contrieres',
    'Contrexeville',
    'Contrevoz',
    'Contres-en-Vairais',
    'Contres',
    'Contremoulins',
    'Contreglise',
    'Contoire',
    'Contilly',
    'Contigne',
    'Conthil',
    'Conteville',
    'Contest',
    'Contes',
    'Contamine-sur-Arve',
    'Contamine-Sarzin',
    'Cons-Sainte-Colombe',
    'Cons-la-Grandville',
    'Consac',
    'Conques',
    'Conques-sur-Orbiel',
    'Conquereuil',
    'Connigis',
    'Connerre',
    'Connaux',
    'Connantre',
    'Connage',
    'Conlie',
    'Congy',
    'Congrier',
    'Congis-sur-Therouanne',
    'Conge-sur-Orne',
    'Confrancon',
    'Confolens',
    'Conflans-sur-Anille',
    'Conflans-Sainte-Honorine',
    'Conflans-sur-Lanterne',
    'Conflandey',
    'Condrieu',
    'Condren',
    'Condom',
    'Condillac',
    'Condezaygues',
    'Condette',
    'Conde-sur-Vire',
    'Conde-sur-Vesgre',
    'Conde-sur-Noireau',
    'Conde-sur-Marne',
    "Conde-sur-l'Escaut",
    'Conde-Sainte-Libiaire',
    'Conde-les-Autry',
    'Condeissiat',
    'Conde-Folie',
    'Condeau',
    'Condat-sur-Vienne',
    'Condat-sur-Vezere',
    'Condat-en-Combraille',
    'Condat',
    'Condal',
    'Condac',
    'Concriers',
    'Concressault',
    'Concremiers',
    'Concoules',
    'Concots',
    'Conchy-les-Pots',
    'Conchil-le-Temple',
    'Conches-en-Ouche',
    'Concarneau',
    'Conca',
    'Comps-sur-Artuby',
    'Comps',
    'Compreignac',
    'Compiègne',
    'Compeyre',
    'Compans',
    'Communay',
    'Commissey',
    'Commercy',
    'Commer',
    'Commequiers',
    'Commentry',
    'Commensacq',
    'Commelle',
    'Commarin',
    'Commana',
    'Comines',
    'Combs-la-Ville',
    'Combronde',
    'Combrit',
    'Combrimont',
    'Combres',
    'Combree',
    'Combre',
    'Combrand',
    'Combourtille',
    'Combourg',
    'Combon',
    'Combloux',
    'Comblot',
    'Combleux',
    'Comblessac',
    'Combles',
    'Comblanchien',
    'Combertault',
    'Comberouger',
    'Combefa',
    'Combeaufontaine',
    'Pontault-Combault',
    'Combas',
    'Combaillaux',
    'Coltines',
    'Coltainville',
    'Colroy-la-Roche',
    'Colpo',
    'Colonard-Corubert',
    'Colomieu',
    'Colomiers',
    'Colombies',
    'Colombier',
    'Colombiers',
    'Colombier-le-Vieux',
    'Colombier-Fontaine',
    'Colombier-en-Brionnais',
    'Colombey-les-Belles',
    'Colombes',
    'Colombelles',
    'Colombe-les-Vesoul',
    'Colombe',
    'Colomars',
    'Cologne',
    'Colmesnil-Manneville',
    'Colmery',
    'Colmen',
    'Colmars',
    'Colmar',
    'Collorgues',
    'Collonges-sous-Saleve',
    'Collonges-les-Premieres',
    "Collonges-au-Mont-d'Or",
    'Collonges-la-Rouge',
    'Collobrieres',
    'Collioure',
    'Collinee',
    'Colligny',
    'Colligis-Crandelain',
    'Colleville-Montgomery',
    'Colleville',
    'Colleret',
    'Collemiers',
    'Collegien',
    'Collandres',
    'Val-des-Marais',
    'Coligny',
    'Colembert',
    'Colayrac-Saint-Cirq',
    'Coisevaux',
    'Coise',
    'Coirac',
    'Coin-les-Cuvry',
    'Coings',
    'Coincy',
    'Coimeres',
    'Coigny',
    'Coignieres',
    'Cohiniac',
    'Cohade',
    'Cogolin',
    'Cognin-les-Gorges',
    'Cognieres',
    'Cognat-Lyonne',
    'Cognac',
    'Coex',
    'Coetmieux',
    'Codolet',
    'Codognan',
    'Cocumont',
    'Cocherel',
    'Coat-Meal',
    'Coarraze',
    'Coadout',
    'Cluses',
    'Cluny',
    'Cluis',
    'Cloyes-sur-le-Loir',
    'Cloue',
    'Clouange',
    'Clonas-sur-Vareze',
    'Clohars-Fouesnant',
    'Clohars-Carnoet',
    'Clitourps',
    'Clisson',
    'Clis',
    'Clion',
    'Clinchamps-sur-Orne',
    'Climbach',
    'Clichy-sous-Bois',
    'Clichy',
    'Clevilliers',
    'Cleville',
    'Clety',
    'Clesse',
    'Clesles',
    'Clery-sur-Somme',
    'Clery-Saint-Andre',
    'Clery',
    'Clerval',
    'Clermont-Saves',
    "Clermont-l'Herault",
    'Clermont-Ferrand',
    'Clermont-Creans',
    'Clermont',
    'Clerieux',
    'Clergoux',
    'Clerey',
    'Clères',
    'Clere-les-Pins',
    'Clerac',
    "Cleon-d'Andran",
    'Cleon',
    'Clemery',
    'Clelles',
    'Cleguerec',
    'Cleguer',
    "Clefs-Val d'Anjou",
    'Ban-sur-Meurthe-Clefcy',
    'Cleebourg',
    'Cleder',
    'Cleden-Poher',
    'Cleden-Cap-Sizun',
    'Clecy',
    'Clazay',
    'Claye-Souilly',
    'Clayes',
    'Claville',
    'Claveyson',
    'Clavette',
    'Claudon',
    'Clasville',
    'Clastres',
    'Clary',
    'Clarques',
    'Claret',
    'Clarensac',
    'Clarens',
    'Clarbec',
    'Clarafond-Arcine',
    'Clarafond',
    'Clarac',
    'Clapiers',
    'Clansayes',
    'Clans',
    'Clamerey',
    'Clamensane',
    'Clamecy',
    'Clamart',
    'Clam',
    'Claix',
    'Clairvaux-les-Lacs',
    'Clairoix',
    'Clairegoutte',
    'Clairac',
    'Claira',
    'Cizos',
    'Civrieux',
    'Civray-de-Touraine',
    'Civray',
    'Civrac-en-Medoc',
    'Civrac-de-Blaye',
    'Civens',
    'Civaux',
    'Citry',
    'Varennes-Vauzelles',
    'Cisse',
    'Cissac-Medoc',
    'Ciry-le-Noble',
    'Ciron',
    'Cirieres',
    'Cirey-sur-Vezouze',
    'Cirey',
    'Cires-les-Mello',
    "Cire-d'Aunis",
    'Ciran',
    'Cintre',
    'Cintray',
    'Cintegabelle',
    'Cinqueux',
    'Cinquetral',
    'Cinq-Mars-la-Pile',
    'Cindre',
    'Cilly',
    'Cigogne',
    'Cigne',
    'Ciez',
    'Cieux',
    'Cieutat',
    'Cierp-Gaud',
    'Ciel',
    'Ciboure',
    'Ciadoux',
    'Chuzelles',
    'Chusclan',
    'Chuisnes',
    'Chuffilly-Roche',
    'Chuelles',
    'Chozeau',
    'Choye',
    'Chouzy-sur-Cisse',
    'Chouze-sur-Loire',
    'Chouzelot',
    'Chouy',
    'Chourgnac',
    'Chouppes',
    'Chougny',
    'Chorges',
    'Chooz',
    "Chonas-l'Amballan",
    'Chomerac',
    'Cholet',
    'Choisy-le-Roi',
    'Choisy-en-Brie',
    'Choisy-au-Bac',
    'Choisy',
    'Choisey',
    'Choiseul',
    'Choignes',
    'Chocques',
    'Chivres-Val',
    'Chivres',
    'Chitenay',
    'Chissey-sur-Loue',
    'Chissay-en-Touraine',
    'Chis',
    'Chiry-Ourscamp',
    'Chirens',
    'Chire-en-Montreuil',
    'Chirassimont',
    'Chirac',
    'Chipilly',
    'Chinon',
    'Chimilin',
    'Chilly-Mazarin',
    'Chilly-le-Vignoble',
    'Chilly',
    'Chilleurs-aux-Bois',
    'Chigny-les-Roses',
    'Chignin',
    'Chidrac',
    'Chiddes',
    'Chiche',
    'Chiatra',
    'Chezy-sur-Marne',
    'Chezy-en-Orxois',
    'Chezeneuve',
    'Chezelles',
    'Cheyssieu',
    'Chey',
    'Chevry-en-Sereine',
    'Chevry-Cossigny',
    'Chevry',
    'Chevru',
    'Chevroches',
    'Chevrieres',
    'Chevrier',
    'Chevreville',
    'Chevreuse',
    'Chevremont',
    'Chevrainvilliers',
    'Chevincourt',
    'Chevinay',
    'Chevilly-Larue',
    'Chevilly',
    'Chevillon-sur-Huillard',
    'Chevillon',
    'Chevillelle',
    'Chevigny-Saint-Sauveur',
    'Cheverny',
    'Chevenon',
    'Chevannes-Changy',
    'Chevannes',
    'Chevanceaux',
    'Cheval-Blanc',
    'Chevaigne',
    'Chevagnes',
    'Cheux',
    'Chessy-les-Pres',
    'Chessy',
    'Chery-les-Pouilly',
    'Cherveux',
    'Cherves-Richemont',
    'Cherrueix',
    'Cherreau',
    'Cherre',
    'Cheroy',
    'Chermisey',
    'Cherier',
    'Chereng',
    'Cherence-le-Roussel',
    'Cherbourg-Octeville',
    'Cheraute',
    'Cherac',
    'Chepy',
    'Cheptainville',
    'Cheppes-la-Prairie',
    'Chepoix',
    'Chepniers',
    'Chenu',
    'Chens-sur-Leman',
    'Chenoves',
    'Chenove',
    'Chenou',
    'Chenonceaux',
    'Chenoise',
    'Chennevieres-sur-Marne',
    'Chennevieres',
    'Chennegy',
    'Chennebrun',
    'Chenimenil',
    'Cheniers',
    'Chenex',
    'Chenevieres',
    'Chenevelles',
    'Chenereilles',
    'Chenerailles',
    'Cheneraille',
    'Chenebier',
    'Chenay',
    'Chenaud',
    'Chemy',
    'Chemire-le-Gaudin',
    'Cheminot',
    'Cheminas',
    'Chemilly',
    'Chemille-Melay',
    'Chemery-les-Deux',
    'Chemery-sur-Bar',
    'Chemaze',
    'Chemaudin',
    'Chelun',
    'Chelles',
    'Chelle-Debat',
    'Chelieu',
    'Cheix-en-Retz',
    'Cheissoux',
    'Cheilly-les-Maranges',
    'Cheille',
    'Chehery',
    'Cheffois',
    'Cheffes',
    'Chef-Boutonne',
    'Chedigny',
    'Checy',
    'Chazey-sur-Ain',
    'Chazey-Bons',
    'Chazelles-sur-Lyon',
    'Chazelles-sur-Lavieu',
    'Chazelles',
    'Chazelet',
    'Chaze-Henry',
    "Chazay-d'Azergues",
    'Chavroches',
    'Chavot-Courcourt',
    'Chavonne',
    'Chavin',
    'Chaville',
    'Chavignon',
    'Chaveyriat',
    'Chavenon',
    'Chavenay',
    'Chavelot',
    'Chavaroux',
    'Chavanoz',
    'Chavanod',
    'Chavannes-sur-Suran',
    'Chavannes-sur-Reyssouze',
    "Chavannes-sur-l'Etang",
    'Chavannes',
    'Chavannaz',
    'Chavanay',
    'Chavagnes-les-Redoux',
    'Chavagnes-en-Paillers',
    'Chavagnes',
    'Chavagne',
    'Chaux-des-Crotenay',
    'Chaux',
    'Chauvry',
    'Chauvigny-du-Perche',
    'Chauvigny',
    'Chauvigne',
    'Chauvency-le-Chateau',
    'Chauve',
    'Chaussy',
    'Chaussin',
    'Chaussenac',
    'Chaussan',
    'Chauriat',
    'Chauray',
    'Chauny',
    'Chaunay',
    'Chaumuzy',
    'Chaumousey',
    'Chaumot',
    'Chaumont-sur-Tharonne',
    'Chaumont-sur-Loire',
    'Chaumont-Porcien',
    'Chaumont-en-Vexin',
    'Chaumontel',
    'Chaumont',
    'Chaumes-en-Brie',
    'Chaumergy',
    'Chaumercenne',
    'Chaumard',
    'Chaum',
    'Chaulnes',
    'Chaulgnes',
    'Chaufour-Notre-Dame',
    'Chaufour-les-Bonnieres',
    'Chauffry',
    'Chauffourt',
    'Chauffour-sur-Vell',
    'Chauffayer',
    'Chauffailles',
    'Chaudron-en-Mauges',
    'Chaudrey',
    'Chaudon',
    'Chaudeney-sur-Moselle',
    'Chaudenay-la-Ville',
    'Chaudenay',
    'Chaudefontaine',
    'Chaudefonds-sur-Layon',
    'Chauconin-Neufmontiers',
    'Chauchigny',
    'Chauche',
    'Chaucenne',
    'Chatuzange-le-Goubet',
    'Chatte',
    'Chattancourt',
    'Chatres-sur-Cher',
    'Chatou',
    'Chatonnay',
    'Chatillon-sur-Thouet',
    'Châtillon-sur-Seine',
    'Chatillon-sur-Seiche',
    'Chatillon-sur-Marne',
    'Chatillon-sur-Loire',
    'Chatillon-sur-Indre',
    'Chatillon-sur-Colmont',
    'Chatillon-sur-Cher',
    'Chatillon-sur-Chalaronne',
    'Chatillon-Saint-Jean',
    'Chatillon-le-Duc',
    'Chatillon-la-Palud',
    'Chatillon-en-Vendelais',
    'Chatillon-en-Diois',
    'Chatillon-en-Michaille',
    'Chatillon-Coligny',
    'Châtillon',
    'Chatillon',
    'Chatignonville',
    'Chatenoy-le-Royal',
    'Chatenoy-en-Bresse',
    'Chatenois-les-Forges',
    'Chatenois',
    'Chatenay-sur-Seine',
    'Châtenay-Malabry',
    'Chatelus-le-Marcheix',
    'Châtelperron',
    'Chatelneuf',
    'Chatel-Moron',
    'Chatel-Montagne',
    'Châtellerault',
    'Chatellenot',
    'Chatel-Guyon',
    'Chateldon',
    'Chatel-Censoir',
    'Chatelblanc',
    'Chatelaudren',
    'Chatelais',
    'Chatelain',
    'Chatelaillon-Plage',
    'Chatel',
    'Chateauvillain',
    'Chateauvilain',
    'Chateauvieux',
    'Château-Thierry',
    'Chateau-Thebaud',
    'Chateau-sur-Epte',
    'Chateau-Salins',
    'Chateauroux',
    'Châteauroux',
    'Chateauroux-les-Alpes',
    'Chateau-Renault',
    'Chateaurenaud',
    'Chateau-Renard',
    'Chateaurenard',
    'Chateau-Regnault',
    'Chateau-Porcien',
    'Châteauponsac',
    'Chateauneuf-Val-Saint-Donat',
    'Chateauneuf-Val-de-Bargis',
    'Chateauneuf-sur-Sarthe',
    'Chateauneuf-sur-Loire',
    'Châteauneuf-sur-Cher',
    'Chateauneuf-sur-Charente',
    'Chateauneuf-les-Martigues',
    'Chateauneuf-les-Bains',
    'Chateauneuf-la-Foret',
    'Chateauneuf-en-Thymerais',
    'Chateauneuf-du-Rhone',
    'Châteauneuf-du-Pape',
    'Chateauneuf-du-Faou',
    'Chateauneuf-sur-Isere',
    'Châteauneuf-dIlle-et-Vilaine',
    'Châteauneuf-de-Randon',
    'Chateauneuf-de-Galaure',
    'Chateauneuf-de-Gadagne',
    'Chateauneuf-Villevieille',
    'Chateauneuf',
    'Chateaumeillant',
    'Châteaulin',
    'Château-lÉvêque',
    'Chateau-la-Valliere',
    'Chateau-Larcher',
    'Chateau-Landon',
    'Chateau-Guibert',
    'Chateau-Gontier',
    'Chateaugiron',
    'Chateaugay',
    'Chateau-Gaillard',
    'Chateaufort',
    'Châteaudun',
    'Chateau-du-Loir',
    'Chateaudouble',
    "Chateau-d'Olonne",
    'Chateau-Chinon(Ville)',
    'Chateau-Chervix',
    'Chateaubriant',
    'Chateau-Brehain',
    'Chateaubourg',
    'Chateaubernard',
    'Chateau-Arnoux-Saint-Auban',
    'Chastreix',
    'Chastel-Nouvel',
    'Chasteaux',
    'Chastanier',
    'Chassy',
    'Chassors',
    'Chassille',
    'Chassigny-sous-Dun',
    'Chassigny',
    'Chassignolles',
    'Chassieu',
    'Chassiers',
    'Chassey-le-Camp',
    'Chasse-sur-Rhone',
    'Chassenon',
    'Chasseneuil-du-Poitou',
    'Chasseneuil',
    'Chasseneuil-sur-Bonnieure',
    'Chassenard',
    'Chassemy',
    'Chassant',
    'Chassal',
    'Chassagny',
    'Chassagnes',
    'Chassagne-Montrachet',
    'Chaspuzac',
    'Chaspinhac',
    'Chasnans',
    'Chasnais',
    'Charvonnex',
    'Charvieu-Chavagneux',
    'Chartronges',
    'Chartrettes',
    'Chartres-de-Bretagne',
    'Chartres',
    'Chartrene',
    'Charteves',
    'Chartainvilliers',
    'Charsonville',
    'Chars',
    'Charroux',
    'Charron',
    'Charrin',
    'Charrey-sur-Saone',
    'Charrey-sur-Seine',
    'Charras',
    'Charrais',
    'Charquemont',
    'Charpont',
    'Charpey',
    'Charost',
    'Charolles',
    'Charny',
    'Charnoz-sur-Ain',
    'Charnois',
    'Charnizay',
    'Charnecles',
    'Charnay-les-Macon',
    'Charnay',
    'Charmont-sous-Barbuise',
    'Charmont',
    'Charmois-devant-Bruyeres',
    'Charmoille',
    'Charmes-sur-Rhone',
    "Charmes-sur-l'Herbasse",
    'Charmes',
    'Charmeil',
    'Charme',
    'Charmauvillers',
    'Charly-sur-Marne',
    'Charly-Oradour',
    'Charly',
    'Charlieu',
    'Charleville-Mézières',
    'Charleville',
    'Charleval',
    'Charlas',
    'Charix',
    'Chariez',
    'Chargey-les-Port',
    'Charge',
    'Charezier',
    'Charette',
    'Charenton-le-Pont',
    'Charentilly',
    'Charentay',
    'Charency-Vezin',
    'Chareil-Cintrat',
    'Chardogne',
    'Charchilla',
    'Charce-Saint-Ellier-sur-Aubance',
    'Charbuy',
    'Charbonnieres-les-Varennes',
    'Charbonnieres-les-Bains',
    'Charavines',
    'Charantonnay',
    'Charancieu',
    'Chaptelat',
    'Chappes',
    'Chaponost',
    'Chaponnay',
    'Chapois',
    'Chapelon',
    'Chapelle-Voland',
    'La Chapelle-Montreuil',
    'Chapeau',
    'Chapdes-Beaufort',
    'Chapareillan',
    'Chaourse',
    'Chaource',
    'Chaon',
    'Chanzeaux',
    'Chanu',
    'Chantrigne',
    'Chantrans',
    'Chantraine',
    'Chantonnay',
    'Chantome',
    'Chantilly',
    'Chantillac',
    'Chanteuges',
    'Chantesse',
    'Chanterac',
    'Chantepie',
    'Chantenay-Saint-Imbert',
    'Chantemerle-sur-la-Soie',
    'Chanteloup-les-Vignes',
    'Chanteloup-en-Brie',
    'Chanteloup',
    'Chanteix',
    'Chanteheux',
    'Chantecoq',
    'Chanteau',
    'Chanousse',
    'Chanos-Curson',
    'Chanonat',
    'Channes',
    'Channay-sur-Lathan',
    'Chaniers',
    'Chaniat',
    'Changy',
    'Changis-sur-Marne',
    'Change',
    'Chaneins',
    'Chandolas',
    'Chandai',
    'Chancey',
    'Chancenay',
    'Chancelade',
    'Chanceaux-sur-Choisille',
    'Chanceaux',
    'Chancay',
    'Chanaz',
    'Chanay',
    'Chanas',
    'Chanac',
    'Chamvres',
    'Chamrousse',
    'Champvoisy',
    'Champvert',
    'Champvans',
    'Champvallon',
    'Champtonnay',
    'Champtoce-sur-Loire',
    'Champtoceaux',
    'Champteusse-sur-Baconne',
    'Champtercier',
    'Champ-sur-Drac',
    'Champs-sur-Marne',
    'Champs-Romain',
    'Champseru',
    'Champsecret',
    'Champsac',
    'Champrougier',
    'Champrond-en-Gatine',
    'Champougny',
    'Champoleon',
    'Champniers',
    'Champneuville',
    'Champmotteux',
    'Champlost',
    'Champlois',
    'Champlitte',
    'Champ-le-Duc',
    'Champlay',
    'Champlan',
    'Champillon',
    'Champigny',
    'Champigny-sur-Veude',
    'Champigny-sur-Marne',
    'Champigny-sous-Varennes',
    'Champigny-la-Futelaye',
    'Champigny-en-Beauce',
    'Champignol-lez-Mondeville',
    'Champigneulles',
    'Champignelles',
    'Champigne',
    'Champier',
    'Champien',
    'Champhol',
    'Champfromier',
    'Champfremont',
    'Champforgeuil',
    'Champfleur',
    'Champey',
    'Champeon',
    'Champenoux',
    'Champenard',
    'Champeix',
    'Champeaux',
    'Champeau-en-Morvan',
    'Champdotre',
    'Champdolent',
    'Champdivers',
    'Champdieu',
    'Champdeniers-Saint-Denis',
    'Champcueil',
    'Champcevrais',
    'Champcevinel',
    'Champcervon',
    'Champanges',
    'Champagny-en-Vanoise',
    'Champagnolles',
    'Champagnole',
    'Champagnier',
    'Champagney',
    'Champagneux',
    'Champagne-sur-Seine',
    'Champagne-sur-Oise',
    'Champagne-Saint-Hilaire',
    'Champagne-Mouton',
    'Champagne-les-Marais',
    'Champagne-Vigny',
    "Champagne-au-Mont-d'Or",
    'Champagne',
    'Champagnat-le-Jeune',
    'Champagnat',
    'Champagnac-le-Vieux',
    'Champagnac-la-Riviere',
    'Champagnac',
    'Chamoux-sur-Gelon',
    'Chamonix',
    'Chamigny',
    'Chameyrat',
    'Chamery',
    'Chamelet',
    'Chambry',
    'Chambroutet',
    'Chambrey',
    'Chambray-lès-Tours',
    'Chambray',
    'Chambourg-sur-Indre',
    'Chambourcy',
    'Chamboulive',
    'Chambost-Longessaigne',
    'Chambost-Allieres',
    'Chambors',
    'Chambornay-les-Pin',
    'Chambord',
    'Chambon-sur-Voueize',
    'Chambon-sur-Cisse',
    'Chambon-la-Foret',
    'Chambonas',
    'Chambon',
    'Chamboeuf',
    'Chambly',
    'Chamblet',
    'Chambles',
    'Chamblay',
    'Chamblac',
    'Chambéry',
    'Chamberaud',
    'Chambeire',
    'Chamaret',
    'Chamarande',
    'Chamant',
    'Chamalières',
    'Chamagnieu',
    'Chamagne',
    'Chamadelle',
    'Châlus',
    'Chalou-Moulineux',
    'Chalonvillars',
    'Chalon-sur-Saône',
    'Chalons-en-Champagne',
    'Chalons-du-Maine',
    'Chalon',
    'Chalonnes-sur-Loire',
    'Chalmaison',
    'Challuy',
    'Challonges',
    'Challex',
    'Challet',
    'Challes-les-Eaux',
    'Challement',
    'Challans',
    'Chalivoy-Milon',
    'Chalindrey',
    'Chaligny',
    'Chalifert',
    'Chaliers',
    'Chalezeule',
    'Chalette-sur-Voire',
    'Chalette-sur-Loing',
    'Chaleins',
    'Chalautre-la-Petite',
    'Chalautre-la-Grande',
    'Chalandrey',
    'Chalandray',
    'Chalancey',
    'Chalampe',
    'Chalamont',
    'Chalais',
    'Chalaines',
    'Chalabre',
    'Chaix',
    'Chaintrix-Bierges',
    'Chaintreaux',
    'Chaintre',
    'Chaingy',
    'Chainaz-les-Frasses',
    'Chailly-en-Gatinais',
    'Chailly-en-Brie',
    'Chailly-en-Biere',
    'Chaillevette',
    'Chailles',
    'Chaille-les-Marais',
    'Chailland',
    'Chaillac',
    'Chail',
    'Chahains',
    'Chahaignes',
    'Chagny',
    'Chaffois',
    'Chadurie',
    'Chadron',
    'Chadrat',
    'Chadrac',
    'Chadenac',
    'Chadeleuf',
    'Chacrise',
    'Chace',
    'Chabris',
    'Chabrillan',
    'Chabreloche',
    'Chabrac',
    'Chabournay',
    'Chabons',
    'Chablis',
    'Chabeuil',
    'Chabanais',
    'Cezy',
    'Cezens',
    'Cezay',
    'Cezac',
    'Ceyzerieu',
    'Ceyzeriat',
    'Ceyssat',
    'Ceyreste',
    'Ceyrat',
    'Cevins',
    'Ceton',
    'Cestas',
    'Cessy',
    'Cessoy-en-Montois',
    'Cesson-Sévigné',
    'Cesson',
    'Cessieu',
    'Cessey-sur-Tille',
    'Cessey',
    'Cesseville',
    'Cesseras',
    'Cessenon-sur-Orb',
    'Cesny-Bois-Halbout',
    'Cesny-aux-Vignes',
    'Cescau',
    'Cesarches',
    'Cervon',
    'Cervione',
    'Cerville',
    'Cervens',
    'Certines',
    'Cerseuil',
    'Cers',
    'Cerons',
    'Ceron',
    'Cerny',
    'Cernusson',
    'Cernoy-en-Berry',
    'Cerneux',
    'Cernay-les-Reims',
    'Cernay-la-Ville',
    'Cernay',
    'Cernans',
    'Cerizay',
    'Cerisy-la-Salle',
    'Cerisiers',
    'Cerilly',
    'Cergy',
    'Ceret',
    'Cereste',
    'Cerences',
    'Cere-la-Ronde',
    'Cerdon',
    'Cercy-la-Tour',
    'Cercottes',
    'Cercie',
    'Cervera de la Marenda',
    'Cerans-Foulletourte',
    'Cepoy',
    'Cepet',
    'Censeau',
    'Cenon',
    'Cendrieux',
    'Cendras',
    'Cenac',
    'Cempuis',
    'Cely',
    'Cellule',
    'Cellieu',
    'Cellettes',
    'Celles-sur-Durolle',
    'Celles-sur-Belle',
    'Celles-les-Conde',
    'Celles',
    'Celle-Levescault',
    'Cellefrouin',
    'Ceillac',
    'Ceignes',
    'Cebazat',
    'Ceaulmont',
    'Cazouls-les-Beziers',
    'Cazilhac',
    "Cazeres-sur-l'Adour",
    'Cazeres',
    'Cazavet',
    'Cazaux-Saves',
    'Cazaux',
    'Cazaugitat',
    'Cazaubon',
    'Cazals',
    'Cazalis',
    'Cayrac',
    'Caylus',
    'Cayeux-sur-Mer',
    'Cavillargues',
    'Cavignac',
    'Caves',
    'Caveirac',
    'Cavanac',
    'Cavan',
    'Cavalaire-sur-Mer',
    'Cavaillon',
    'Cauzac',
    'Caux',
    'Cauvigny',
    'Cauterets',
    'Causses-et-Veyran',
    'Caussens',
    'Causse-de-la-Selle',
    'Caussade',
    'Cauroir',
    'Cauro',
    'Caurel',
    'Caupenne',
    'Caunes-Minervois',
    'Cauneille',
    'Cauna',
    'Caumont-sur-Garonne',
    'Caumont-sur-Durance',
    "Caumont-l'Evente",
    'Caulnes',
    'Caulieres',
    'Caujac',
    'Cauge',
    'Cauffry',
    'Caudry',
    'Caudrot',
    'Caudeval',
    'Cauderan',
    'Caudecoste',
    'Caudebec-lès-Elbeuf',
    'Caudebec-en-Caux',
    'Caudan',
    'Caucalieres',
    'Caubios-Loos',
    'Caubiac',
    'Caubeyres',
    'Catus',
    'Catteville',
    'Cattenom',
    'Cattenieres',
    'Catheux',
    'Cateri',
    'Catenay',
    'Castries',
    'Castres-Gironde',
    'Castres',
    'Castilly',
    'Castillon-Saves',
    'Castillonnes',
    'Castillon-la-Bataille',
    'Castillon-en-Couserans',
    'Castillon',
    "Castex-d'Armagnac",
    'Castex',
    'Castets-en-Dorthe',
    'Castets',
    'Castetner',
    'Castetis',
    'Casterets',
    'Castera-Verduzan',
    'Castera-Lectourois',
    'Castel-Sarrazin',
    'Castelsarrasin',
    'Castelsagrat',
    'Castelner',
    "Castelnau-sur-l'Auvignon",
    'Castelnau-Riviere-Basse',
    'Castelnau-Pegayrols',
    'Castelnau-Magnoac',
    'Castelnau-le-Lez',
    'Castelnau-Durban',
    'Castelnau-d’Estrétefonds',
    'Castelnau-Montratier',
    'Castelnau-de-Medoc',
    'Castelnau-de-Brassac',
    "Castelnau-d'Auzan",
    'Castelnaudary',
    'Castelmoron-sur-Lot',
    'Castelmayran',
    'Castello-di-Rostino',
    'Castellare-di-Casinca',
    'Castellar',
    'Castellane',
    'Castella',
    'Casteljau',
    'Casteljaloux',
    'Castelginest',
    'Castelfranc',
    'Castelculier',
    'Casteide-Cami',
    'Castanet-Tolosan',
    'Castandet',
    'Castagniers',
    'Castagnac',
    'Cast',
    'Casson',
    'Cassis',
    'Casseuil',
    'Casseneuil',
    'Cassel',
    'Cassaniouze',
    'Cassagnoles',
    'Casalta',
    'Carvin',
    'Carville-Pot-de-Fer',
    'Carville-la-Folletiere',
    'Carville',
    'Cartigny',
    'Cartignies',
    'Carticasi',
    'Cartelegue',
    'Carspach',
    'Carsan',
    'Carsac-Aillac',
    'Cars',
    'Carry-le-Rouet',
    'Carros',
    'Carro',
    'Carrieres-sur-Seine',
    'Carrieres-sous-Poissy',
    'Carresse-Cassaber',
    'Carrere',
    'Carrepuis',
    'Carqueiranne',
    'Carquefou',
    'Carquebut',
    'Carpiquet',
    'Carpentras',
    'Caromb',
    'Carolles',
    'Caro',
    'Carnoules',
    'Carnon-Plage',
    'Carnoet',
    'Carnieres',
    'Carnet',
    'Carnas',
    'Carnac',
    'Carmaux',
    'Carly',
    'Carlux',
    'Carlus',
    'Carlipa',
    'Carling',
    'Carlepont',
    'Carlat',
    'Carla-Bayle',
    'Carignan',
    'Carhaix-Plouguer',
    'Cargèse',
    'Carentoir',
    'Carentan',
    'Carelles',
    'Cardroc',
    'Cardonville',
    'Cardonnette',
    'Cardet',
    'Cardaillac',
    'Carces',
    'Carcassonne',
    'Carcans',
    'Carcagny',
    'Carbonne',
    'Carbon-Blanc',
    'Carbay',
    'Carayac',
    'Carantec',
    'Caraman',
    'Capvern',
    'Captieux',
    'Cappy',
    'Cappelle-la-Grande',
    'Cappelle-en-Pevele',
    'Cappelle-Brouck',
    'Capoulet-et-Junac',
    'Caplong',
    'Capinghem',
    'Capian',
    'Capestang',
    'Capens',
    'Capendu',
    'Capelle-les-Hesdin',
    'Capelle-les-Grands',
    'Capdenac-Gare',
    "Cap-d'Ail",
    'Capbreton',
    'Caours',
    'Caouennec-Lanvezeac',
    'Cany-Barville',
    'Cantoin',
    'Cantin',
    'Cantenay-Epinard',
    'Cantenac',
    'Canteloup',
    'Canteleu',
    'Cantaing-sur-Escaut',
    'Canohes',
    'Cannes-et-Clairan',
    'Cannes-Ecluse',
    'Cannes',
    'Cannectancourt',
    'Canly',
    'Canisy',
    'Canihuel',
    'Cangey',
    'Canet-Plage',
    'Canet-en-Roussillon',
    'Canens',
    'Canejan',
    'Candresse',
    'Candillargues',
    'Cande-sur-Beuvron',
    'Cande',
    'Candas',
    'Cancon',
    'Cancale',
    'Canappeville',
    'Canale-di-Verde',
    'Campugnan',
    'Camps-la-Source',
    'Campsas',
    'Camps-Saint-Mathurin-Leobazel',
    'Camprond',
    'Campremy',
    'Campigny',
    'Campigneulles-les-Petites',
    'Campeneac',
    'Campel',
    'Campeaux',
    'Campbon',
    'Campan',
    'Campagne-sur-Aude',
    'Campagne-sur-Arize',
    'Campagne-les-Wardrecques',
    'Campagne-les-Hesdin',
    'Campagne-les-Boulonnais',
    'Campagne',
    'Campagnan',
    'Campagnac',
    'Camors',
    'Camon',
    'Camoel',
    'Camlez',
    'Camiers',
    'Cametours',
    'Came',
    'Cambronne-les-Ribecourt',
    'Cambronne-les-Clermont',
    'Cambron',
    'Cambremer',
    'Cambrai',
    'Camboulazet',
    'Cambon',
    'Cambo-les-Bains',
    'Camblanes-et-Meynac',
    'Camblain-lAbbé',
    'Cambes-en-Plaine',
    'Cambes',
    'Cambernon',
    'Camarsac',
    'Camaret-sur-Mer',
    'Camaret-sur-Aigues',
    'Camares',
    'Calvisson',
    'Calvinet',
    'Calvignac',
    'Calviac-en-Perigord',
    'Calvi',
    'Caluire-et-Cuire',
    'Calonne-sur-la-Lys',
    'Calonne-Ricouart',
    'Calonges',
    'Calmont',
    'Calmels-et-le-Viala',
    'Callian',
    'Calleville',
    'Callen',
    'Callas',
    'Callac',
    'Caligny',
    'Calignac',
    'Calenzana',
    'Calcatoggio',
    'Calan',
    'Calais',
    'Cajarc',
    'Caixas',
    'Caix',
    'Caissargues',
    'Caisnes',
    'Cairon',
    'Cairanne',
    'Cailly-sur-Eure',
    'Cailloux-sur-Fontaines',
    'Caillouet-Orgeville',
    'Caillouel-Crepigny',
    'Caille',
    'Caillac',
    'Cahus',
    'Cahors',
    'Cahaignes',
    'Cahagnes',
    'Cagny',
    'Cagnotte',
    'Cagnicourt',
    'Cagnes-sur-Mer',
    'Cagnac-les-Mines',
    'Caestre',
    'Caen',
    'Cadours',
    'Cadolive',
    'Cadillac-en-Fronsadais',
    'Cadillac',
    'Caderousse',
    'Cadenet',
    'Caden',
    'Cadaujac',
    'Cadalen',
    'Cachan',
    'Cabris',
    'Cabries',
    "Cabrieres-d'Avignon",
    "Cabrieres-d'Aigues",
    'Cabrieres',
    'Cabourg',
    'Cabidos',
    'Cabestany',
    'Cabasse',
    'Cabariot',
    'Cabara',
    'Cabannes',
    'Cabanac-Seguenville',
    'Cabanac-et-Villagrains',
    'Byans-sur-Doubs',
    'Buzy',
    'Buzet-sur-Tarn',
    'Buzancy',
    'Buzancais',
    'Buysscheure',
    'Buxy',
    'Buxieres-sous-Montaigut',
    'Buxieres-les-Mines',
    'Buxerolles',
    'Buvilly',
    'Paris 19 Buttes-Chaumont',
    'Butten',
    'Paris 18 Buttes-Montmartre',
    'Butteaux',
    'Butry-sur-Oise',
    'Butot-Venesville',
    'Buthiers',
    'Busy',
    'Bussy-Saint-Martin',
    'Bussy-Saint-Georges',
    'Bussy-les-Daours',
    'Bussy-le-Chateau',
    'Bussy-Albieux',
    'Bussy',
    'Bussus-Bussuel',
    'Bussiere-Saint-Georges',
    'Bussieres',
    'Bussiere-Poitevine',
    'Bussiere-Nouvelle',
    'Bussiere-Galant',
    'Busset',
    'Bussang',
    'Bussac-Foret',
    'Bussac-sur-Charente',
    'Busnes',
    'Busigny',
    'Buschwiller',
    'Burzet',
    'Bury',
    'Burret',
    'Buros',
    'Burnhaupt-le-Haut',
    'Burnhaupt-le-Bas',
    'Burlats',
    'Buriville',
    'Burgy',
    'Burgalays',
    'Bures-sur-Yvette',
    'Bures-en-Bray',
    'Burdignin',
    'Burdignes',
    'Burbure',
    'Burbach',
    'Bunzac',
    'Buno-Bonnevaux',
    'Bun',
    'Bult',
    'Bully-les-Mines',
    'Bully',
    'Bullion',
    'Bulles',
    'Bullecourt',
    'Bulle',
    'Bullainville',
    'Bulhon',
    'Bulgneville',
    'Bujaleuf',
    'Buissard',
    'Buis-les-Baronnies',
    'Buironfosse',
    'Buire-le-Sec',
    'Buire',
    'Buhy',
    'Buhl-Lorraine',
    'Buhl',
    'Bugnicourt',
    'Bugnein',
    'Bugeat',
    'Bugarach',
    'Buffon',
    'Buffieres',
    'Buffard',
    'Buethwiller',
    'Buellas',
    'Bueil',
    'Budos',
    'Bucy-le-Long',
    'Bucquoy',
    'Buchy',
    'Bucheres',
    'Buchelay',
    'Bucey-les-Traves',
    'Buceels',
    'Bucamps',
    'Buc',
    'Bubry',
    'Buais',
    'Bu',
    'Bry-sur-Marne',
    'Bry',
    'Bruz',
    'Bruyeres-sur-Oise',
    'Bruyeres-le-Chatel',
    'Bruyeres-et-Montberault',
    'Bruyères',
    'Brux',
    'Bruville',
    'Brusvily',
    'Brussieu',
    'Brusque',
    'Brunstatt',
    'Brunoy',
    'Bruniquel',
    'Brunet',
    'Brunemont',
    'Brunembert',
    'Brunelles',
    'Brunehamel',
    'Brumath',
    'Brullioles',
    'Brullemail',
    'Bruley',
    'Brulain',
    'Bruis',
    'Bruguieres',
    'Brugny-Vaudancourt',
    'Brugheas',
    'Bruges',
    'Bruebach',
    'Brue-Auriac',
    'Bruc-sur-Aff',
    'Brucheville',
    'Bruch',
    "Bruay-sur-l'Escaut",
    'Bruay-la-Buissiere',
    'Bru',
    'Broze',
    'Broyes',
    'Broye-Aubigney-Montseugny',
    'Brouzet-les-Ales',
    'Brouviller',
    'Brouvelieures',
    'Brout-Vernet',
    'Brou-sur-Chantereine',
    'Broussey-Raulecourt',
    'Brousseval',
    'Brousses-et-Villaret',
    'Brousse-le-Chateau',
    'Brouilla',
    'Broue',
    'Brouderdorff',
    'Brouckerque',
    'Brouay',
    'Brouage',
    'Brou',
    'Brosville',
    'Brosses',
    'Brossac',
    'Broquies',
    'Broquiers',
    'Broons',
    'Bronvaux',
    'Bron',
    'Bromont-Lamothe',
    'Brommat',
    'Bromeilles',
    'Brombos',
    'Brognon',
    'Broglie',
    'Brochon',
    'Brocas',
    'Broc',
    'Brizon',
    'Brizambourg',
    'Brix',
    'Brives-Charensac',
    'Brive-la-Gaillarde',
    'Brissay-Choigny',
    'Brissarthe',
    'Brissac-Quince',
    'Brison-Saint-Innocent',
    'Briscous',
    'Briouze',
    'Brioux-sur-Boutonne',
    'Brioude',
    'Briord',
    'Brionne',
    'Brion',
    'Briollay',
    'Brindas',
    'Brinay',
    'Brimont',
    'Brimeux',
    'Brillon-en-Barrois',
    'Brillon',
    'Brillecourt',
    'Briis-sous-Forges',
    'Brigueuil',
    'Brigueil-le-Chantre',
    'Brignoud',
    'Brignoles',
    'Brignogan-Plage',
    'Brignais',
    'Brignac-la-Plaine',
    'Briey',
    'Brieres-les-Scelles',
    'Brienon-sur-Armancon',
    'Briennon',
    'Brienne-le-Château',
    'Brienne',
    'Brielles',
    'Brie-et-Angonnes',
    'Brie-Comte-Robert',
    'Briec',
    'Brie',
    'Brides-les-Bains',
    'Bricy',
    'Bricqueville-sur-Mer',
    'Bricqueville-la-Blouette',
    'Bricquebec',
    'Briconville',
    'Bricon',
    'Briatexte',
    'Briare',
    'Briantes',
    'Briant',
    'Brianconnet',
    'Briançon',
    'Brezolles',
    'Brezins',
    'Brezilhac',
    'Breziers',
    'Breze',
    'Brevonnes',
    'Brevilly',
    'Brevilliers',
    'Breville-sur-Mer',
    'Brevillers',
    'Breville-les-Monts',
    'Breviandes',
    'Brevans',
    'Brevands',
    'Breval',
    'Breuville',
    'Breuschwickersheim',
    'Breurey-les-Faverney',
    'Breuilpont',
    'Breuil-Magne',
    'Breuil-le-Vert',
    'Breuillet',
    'Breuil-le-Sec',
    'Breuil-Bois-Robert',
    'Breuchotte',
    'Breuches',
    'Bretx',
    'Brettnach',
    'Bretteville-sur-Odon',
    'Bretteville-sur-Laize',
    "Bretteville-l'Orgueilleuse",
    'Bretten',
    'Brette-les-Pins',
    'Bretoncelles',
    'Brétigny-sur-Orge',
    'Bretignolles-sur-Mer',
    'Bretigney',
    'Brethenay',
    'Breteuil',
    'Bretenoux',
    'Breteniere',
    'Breteil',
    'Bretagne-de-Marsan',
    'Brestot',
    'Brest',
    'Bressuire',
    'Bresson',
    'Bressols',
    'Bressolles',
    'Bressieux',
    'Bressey-sur-Tille',
    'Bresnay',
    'Bresles',
    'Bresle',
    'Brenthonne',
    'Brens',
    'Brenouille',
    'Brennilis',
    'Brengues',
    'Brenat',
    'Bren',
    'Bremontier-Merval',
    'Bremondans',
    'Bremes',
    'La Creche',
    'Brelidy',
    'Breitenbach-Haut-Rhin',
    'Breil-sur-Roya',
    'Breilly',
    'Ile-de-Brehat',
    'Brehan',
    'Brehand',
    'Brehal',
    'Breel',
    'Bree',
    'Brecy',
    'Brechamps',
    'Brech',
    'Brecey',
    'Brece',
    'Breaute',
    'Breau-et-Salagosse',
    'Breancon',
    'Breal-sous-Montfort',
    'Brazey-en-Plaine',
    'Brazey-en-Morvan',
    'Bray-sur-Somme',
    'Bray-sur-Seine',
    'Bray-en-Val',
    'Bray-Dunes',
    'Brax',
    'Braud-et-Saint-Louis',
    'Braucourt',
    'Brassy',
    'Brassac-les-Mines',
    'Braslou',
    'Brasles',
    'Brasc',
    'Bras',
    'Braquis',
    'Branville',
    'Brantome',
    'Bransles',
    'Bransat',
    'Brans',
    'Brannens',
    'Branne',
    'Brannay',
    'Branges',
    'Brandonnet',
    'Brando',
    'Brandivy',
    'Branderion',
    'Brancourt-le-Grand',
    'Brancourt-en-Laonnois',
    'Bran',
    'Brametot',
    'Bram',
    'Brainville',
    'Brain-sur-lAuthion',
    'Brain-sur-Allonnes',
    'Brains-sur-les-Marches',
    'Brains-sur-Gee',
    'Brains',
    'Braine',
    'Brailly-Cornehotte',
    'Braillans',
    'Bragayrac',
    'Bradiancourt',
    'Bracquetuit',
    'Bracieux',
    'Brachy',
    'Braches',
    'Brach',
    'Bozouls',
    'Bozel',
    'Boz',
    'Boynes',
    'Boyer',
    'Boves',
    'Bovel',
    'Bovee-sur-Barboure',
    'Bouzy-la-Foret',
    'Bouzy',
    'Bouzonville-en-Beauce',
    'Bouzonville',
    'Bouzigues',
    'Bouyon',
    'Bouxwiller',
    'Bouxurulles',
    'Bouxieres-sous-Froidmont',
    'Bouxieres-aux-Dames',
    'Bouxieres-aux-Chenes',
    'Bouvron',
    'Bouville',
    'Bouvigny-Boyeffles',
    'Bouvignies',
    'Bouvieres',
    'Bouvesse-Quirieu',
    'Boutigny-Prouais',
    'Boutigny-sur-Essonne',
    'Boutigny',
    'Boutiers-Saint-Trojan',
    'Boutervilliers',
    'Boutencourt',
    'Boutenac-Touvent',
    'Bout-du-Pont-de-Larn',
    'Boutancourt',
    'Boustroff',
    'Boussy-Saint-Antoine',
    'Boussois',
    'Boussieres',
    'Boussens',
    'Boussenac',
    'Bousse',
    'Boussay',
    'Boussais',
    'Boussac',
    'Bousies',
    'Bousbecque',
    'Bousbach',
    'Bourthes',
    'Bourth',
    'Boursonne',
    'Boursin',
    'Bourseville',
    'Bourseul',
    'Bourscheid',
    'Boursay',
    'Boursault',
    'Bours',
    'Bourrouillan',
    'Bourron-Marlotte',
    'Bourret',
    'Bourreac',
    'Bourre',
    'Bourran',
    'Bourogne',
    'Bournos',
    'Bournonville',
    'Bournezeau',
    'Bourneville',
    'Bournel',
    'Bourneau',
    'Bournazel',
    'Bournand',
    'Bournan',
    'Bourmont',
    'Bourlon',
    'Bourisp',
    'Bourideys',
    'Bourgvilain',
    'Bourguignon-sous-Montbavin',
    'Bourguignons',
    'Bourguignon-les-la-Charite',
    'Bourguignon',
    'Bourguenolles',
    'Bourgueil',
    'Bourguebus',
    'Bourgtheroulde-Infreville',
    'Bourg',
    'Bourg-sous-Chatelet',
    'Bourg-Saint-Maurice',
    'Bourg-Saint-Christophe',
    'Bourg-Saint-Bernard',
    'Bourg-Saint-Andeol',
    'Bourgon',
    'Bourgogne',
    'Bourgneuf-en-Retz',
    'Bourgneuf-en-Mauges',
    'Bourgneuf',
    "la Guingueta d'Ix",
    'Bourg-les-Valence',
    'Bourg-le-Roi',
    'Bourg-le-Comte',
    'Bourg-Lastic',
    'Bourg-la-Reine',
    'Bourgheim',
    'Bourgfelden',
    'Bourg-et-Comin',
    'Bourges',
    'Bourg-en-Bresse',
    'Bourg-de-Thizy',
    'Bourg-des-Comptes',
    'Bourg-de-Péage',
    'Bourg-Blanc',
    'Bourg-Beaudouin',
    'Bourgbarre',
    'Bourg-Argental',
    'Bourg-Archambault',
    'Bourganeuf',
    'Bourg-Achard',
    'Bouresse',
    'Bourecq',
    'Bourdonnay',
    'Bourdic',
    'Bourdenay',
    'Bourdelles',
    'Bourdeaux',
    'Bourdeau',
    'Bourdalat',
    'Bourdainville',
    'Bourcefranc-le-Chapus',
    'Bourbriac',
    'Bourbourg',
    'Bourbonne-les-Bains',
    "Bourbon-l'Archambault",
    'Bourbon-Lancy',
    'Bourberain',
    'Bourbach-le-Bas',
    'Bouray-sur-Juine',
    'Bouranton',
    'Bouqueval',
    'Bouquetot',
    'Bouquet',
    'Bouquelon',
    'Bouquehault',
    'Boulzicourt',
    'Boult-sur-Suippe',
    'Boult-aux-Bois',
    'Boult',
    'Boulot',
    'Boulon',
    'Bouloire',
    'Boulogne-sur-Mer',
    'Boulogne-sur-Gesse',
    'Boulogne-Billancourt',
    'Boulogne',
    'Bouloc',
    'Boulleville',
    'Boulleret',
    'Boullay-les-Troux',
    'Bouligny',
    'Bouligney',
    'Bouliac',
    'Bouleuse',
    'Bouleurs',
    'Boulbon',
    'Boulazac',
    'Boulay-Moselle',
    'Boulay',
    'Boulange',
    'Boulancourt',
    'Boujan-sur-Libron',
    'Bouin-Plumoison',
    'Bouin',
    'Bouilly',
    'Bouille - sous - Varent',
    'Bouillargues',
    'Bouilland',
    'Bouillancy',
    'Bouillac',
    'Bouilhonnac',
    'Bouhet',
    'Bougy',
    'Bouguenais',
    'Bougue',
    'Bougon',
    'Bougnon',
    'Bouglon',
    'Bougligny',
    'Bouglainval',
    'Bougival',
    'Bouge-Chambalud',
    'Bougarber',
    'Bougainville',
    'Bouffere',
    'Bouffemont',
    'Bouex',
    'Bouesse',
    'Bouessay',
    'Bouere',
    'Bouer',
    'Bouelles',
    'Bouee',
    'Boue',
    'Boudou',
    'Boudeville',
    'Bouconvillers',
    'Boucoiran-et-Nozieres',
    'Bouclans',
    'Bouchy-Saint-Genest',
    'Bouchoir',
    'Bouchevilliers',
    'Boucheporn',
    'Bouchemaine',
    'Bouchain',
    'Boucey',
    'Bouce',
    'Bouc-Bel-Air',
    'Boucau',
    'Boubiers',
    'Boubers-sur-Canche',
    'Bouaye',
    'Bouafles',
    'Bouafle',
    'Bou',
    'Botz-en-Mauges',
    'Botans',
    'Bossieu',
    'Bossey',
    'Bosset',
    'Bossay-sur-Claise',
    'Bosrobert',
    'Bosnormand',
    "Bosmie-l'Aiguille",
    'Bosjean',
    'Bosguerard-de-Marcouville',
    'Bosdarros',
    'Bosc-Roger-sur-Buchy',
    'Bosc-Mesnil',
    'Bosc-le-Hard',
    'Bosc-Guerard-Saint-Adrien',
    'Callengeville',
    'Bosc-Edeline',
    'Bosc-Bordel',
    'Bosc-Berenger',
    'Boscamnant',
    'Bozas',
    "Bort-l'Etang",
    'Bort-les-Orgues',
    'Bornel',
    'Bornambusc',
    'Bormes-les-Mimosas',
    'Borgo',
    'Borest',
    'Bords',
    'Bordes',
    "Borderes-sur-l'Echez",
    'Bordeaux-en-Gatinais',
    'Bordeaux',
    'Boran-sur-Oise',
    'Boqueho',
    'Bootzheim',
    'Boos',
    'Boofzheim',
    'Bonzee',
    'Bonzac',
    'Bonvillers',
    'Bonvillaret',
    'Bonvillard',
    'Bons-Tassilly',
    'Bonson',
    'Bons-en-Chablais',
    'Bonrepos-sur-Aussonnelle',
    'Bonny-sur-Loire',
    'Bonnut',
    'Bonnœuvre',
    'Bonningues-les-Ardres',
    'Bonnieux',
    'Bonnieres-sur-Seine',
    'Bonnieres',
    'Bonneville-sur-Touques',
    'Bonneville-la-Louvet',
    'Bonneville-Aptot',
    'Bonneville',
    'Bonnevent-Velloreille',
    'Bonneveau',
    'Bonneval',
    'Bonneuil-sur-Marne',
    'Bonneuil-Matours',
    'Bonneuil-les-Eaux',
    'Bonneuil-en-Valois',
    'Bonneuil-en-France',
    'Bonnetan',
    'Bonnetage',
    'Bonnetable',
    'Bonne',
    'Bonnencontre',
    'Bonnemazon',
    'Bonnemaison',
    'Bonnemain',
    'Bonnelles',
    'Bonneil',
    'Bonnefont',
    'Bonnefamille',
    'Bonnee',
    'Bonnebosq',
    'Bonnay',
    'Bonnat',
    'Bonnard',
    'Bonnac-la-Cote',
    'Bonnac',
    'Bonloc',
    'Bonlieu-sur-Roubion',
    'Bonifacio',
    'Bongheat',
    'Bon-Encontre',
    'Bondy',
    'Bondues',
    'Bondoufle',
    'Boncourt',
    'Bonchamp-les-Laval',
    'Bonce',
    'Bonac-Irazein',
    'Bomy',
    'Bompas',
    'Bommiers',
    'Bombon',
    'Bolsenheim',
    'Bolquere',
    'Bologne',
    'Bollwiller',
    'Bollezeele',
    'Bolleville',
    'Bollene',
    'Bolbec',
    'Bolazec',
    'Boitron',
    'Boistrudan',
    'Buis-sur-Damville',
    'Boissy-sous-Saint-Yon',
    'Boissy-Saint-Léger',
    'Boissy-Mauvoisin',
    'Boissy-les-Perche',
    'Boissy-le-Sec',
    'Boissy-le-Cutte',
    'Boissy-le-Chatel',
    'Boissy-le-Bois',
    'Boissy-la-Riviere',
    "Boissy-l'Aillerie",
    'Boissy-Fresnoy',
    'Boissy-aux-Cailles',
    'Boissise-le-Roi',
    'Boissise-la-Bertrand',
    'Boissieres',
    'Boissezon',
    'Boissey-le-Chatel',
    'Boisseuilh',
    'Boisseuil',
    'Boisset-Saint-Priest',
    'Boisset-les-Prevanches',
    'Boisset-les-Montrond',
    'Boisset-et-Gaujac',
    'Boisset',
    'Boisseron',
    'Boisseaux',
    'Boissay',
    'Bois-Normand-pres-Lyre',
    'Boismorand',
    'Boismont',
    'Boisme',
    'Boisleux-au-Mont',
    'Bois-le-Roi',
    'Bois-Himont',
    'Bois-Guillaume',
    'Bois-Grenier',
    'Boisgervilly',
    'Boisemont',
    'Boisdinghem',
    'Bois-de-Gand',
    'Bois-de-Cene',
    "Bois-d'Arcy",
    "Bois-d'Amont",
    'Boiscommun',
    'Bois-Colombes',
    'Bois-Bernard',
    'Bois-Arnault',
    'Boiry-Sainte-Rictrude',
    'Boinvilliers',
    'Boinville-en-Woevre',
    'Boigny-sur-Bionne',
    'Bohas-Meyriat-Rignat',
    'Bohain-en-Vermandois',
    'Bogeve',
    'Boffres',
    'Boesses',
    'Boesse',
    'Boeschepe',
    'Boersch',
    'Boen-sur-Lignon',
    'Boeil-Bezing',
    'Boege',
    'Boe',
    'Bodilis',
    'Bocognano',
    'Boce',
    'Le Bocasse',
    'Bobital',
    'Bobigny',
    'Blyes',
    'Bluffy',
    'Bloye',
    'Blou',
    'Blotzheim',
    'Blosville',
    'Bonsecours',
    'Blonville-sur-Mer',
    'Blombay',
    'Blois-sur-Seille',
    'Blois',
    'Blodelsheim',
    'Blismes',
    'Bligny-les-Beaune',
    'Blienschwiller',
    'Blicourt',
    'Bletterans',
    'Blessy',
    'Blessac',
    'Blesmes',
    'Blesignac',
    'Blere',
    'Blérancourt',
    'Blenod-les-Toul',
    'Blenod-les-Pont-a-Mousson',
    'Blennes',
    'Bleneau',
    'Blendecques',
    'Blecourt',
    'Blaye-les-Mines',
    'Blavozy',
    'Blauzac',
    'Blausasc',
    'Blassac',
    'Blasimon',
    'Blaru',
    'Blaringhem',
    'Blargies',
    'Blanzy-les-Fismes',
    'Blanzy-la-Salonnaise',
    'Blanzy',
    'Blanzay-sur-Boutonne',
    'Blanzay',
    'Blanzat',
    'Blanzac',
    'Blanzac-Porcheresse',
    'Blanquefort',
    'Blangy-Tronville',
    'Blangy-sur-Ternoise',
    'Blangy-sur-Bresle',
    'Blangy-sous-Poix',
    'Blangy-le-Chateau',
    'Blan',
    'Blamont',
    'Blajan',
    'Blaisy-Bas',
    'Blaison-Gohier',
    'Blairville',
    'Blainville-sur-Orne',
    'Blainville-sur-Mer',
    "Blainville-sur-l'Eau",
    'Blainville-Crevon',
    'Blaincourt-les-Precy',
    'Blain',
    'Blagny',
    'Blagnac',
    'Blacy',
    'Blacqueville',
    'Blacourt',
    'Blace',
    'Bizous',
    'Bizonnes',
    'Biziat',
    'Bize-Minervois',
    'Bizanos',
    'Bizanet',
    'Biville-sur-Mer',
    'Biville-la-Baignarde',
    'Biville',
    'Biviers',
    'Bitschwiller-lès-Thann',
    'Bitschhoffen',
    'Bitry',
    'Bitche',
    'Bisten-en-Lorraine',
    'Bissy-sur-Fley',
    'Bissy-sous-Uxelles',
    'Bissezeele',
    'Bissey-sous-Cruchaud',
    'Bisseuil',
    'Bisel',
    'Bischwiller',
    'Bischwihr',
    'Bischoffsheim',
    'Bischholtz',
    'Bischheim',
    'Biscarrosse',
    'Biron',
    'Birieux',
    'Biriatou',
    'Birac',
    'Biozat',
    'Bioussac',
    'Bioule',
    'Biot',
    'Bioncourt',
    'Biollet',
    'Bio',
    'Bining',
    'Binic',
    'Binges',
    'Bindernheim',
    'Binas',
    'Bilwisheim',
    'Biltzheim',
    'Billy-sur-Ourcq',
    'Billy-sur-Oisy',
    'Billy-Montigny',
    'Billy-Berclau',
    'Billy',
    'Billom',
    'Billio',
    'Billiers',
    'Billieme',
    'Billiat',
    'Billezois',
    'Billere',
    'Billancourt',
    'Billancelles',
    'Bilieu',
    'Bihorel',
    'Biguglia',
    'Bigorno',
    'Bignoux',
    'Bignan',
    'Biganos',
    'Bievres',
    'Bieville-Beuville',
    'Bieville',
    'Bieuzy',
    'Bietlenheim',
    'Biesles',
    'Bierville',
    'Biert',
    'Bierne',
    'Bienvillers-au-Bois',
    'Bienville',
    'Bielle',
    'Biecourt',
    'Bidon',
    'Biding',
    'Bidart',
    'Bidarray',
    'Bidache',
    'Bicqueley',
    'Bichancourt',
    'Bibost',
    'Bibiche',
    'Biaudos',
    'Bias',
    'Biars-sur-Cere',
    'Biarritz',
    'Biarne',
    'Biard',
    'Bians-les-Usiers',
    'Biache-Saint-Vaast',
    'Bezu-Saint-Germain',
    'Bezu-Saint-Eloi',
    'Bezu-le-Guery',
    'Bezues-Bajon',
    'Bezouotte',
    'Bezouce',
    'Bezons',
    'Béziers',
    'Bezenet',
    'Beze',
    'Bezaudun-sur-Bine',
    'Bezannes',
    'Bezalles',
    'Bezac',
    'Beyssenac',
    'Beyries',
    'Beyrie-sur-Joyeuse',
    'Beynost',
    'Beynes',
    'Beynat',
    'Beynac',
    'Beylongue',
    'Beychac-et-Caillau',
    'Bey',
    'Bevillers',
    'Beville-le-Comte',
    'Beuzeville-la-Bastille',
    'Beuzeville',
    'Beuzec-Cap-Sizun',
    'Beuxes',
    'Beux',
    'Beuvry-la-Foret',
    'Beuvry',
    'Beuvrequen',
    'Beuvraignes',
    'Beuvrages',
    'Beuvillers',
    'Beuveille',
    'Beuvardes',
    'Beutin',
    'Beussent',
    'Beurlay',
    'Beure',
    'Beugny',
    'Beugnies',
    'Beugneux',
    'Betz-le-Chateau',
    'Betz',
    'Bettwiller',
    'Bettrechies',
    'Betton',
    'Betting',
    'Bettes',
    'Bettencourt-Saint-Ouen',
    'Bettborn',
    'Bettancourt-la-Longue',
    'Bettancourt-la-Ferree',
    'Betracq',
    'Beton-Bazoches',
    'Béthune',
    'Bethoncourt',
    'Bethon',
    'Bethisy-Saint-Pierre',
    'Bethisy-Saint-Martin',
    'Bethines',
    'Betheny',
    'Betheniville',
    'Bethencourt-sur-Mer',
    'Bethemont-la-Foret',
    'Bethancourt-en-Vaux',
    'Betete',
    'Betaucourt',
    'Betaille',
    'Bestiac',
    'Bessy-sur-Cure',
    'Bessuejouls',
    'Bessoncourt',
    'Besson',
    'Bessins',
    'Bessines-sur-Gartempe',
    'Bessines',
    'Bessieres',
    'Bessey-les-Citeaux',
    'Bessey',
    'Besset',
    'Besse-sur-Issole',
    'Besse-sur-Braye',
    'Bessens',
    'Bessenay',
    'Besseges',
    'Besse-et-Saint-Anastaise',
    'Besse',
    'Bessay-sur-Allier',
    'Bessancourt',
    'Bessan',
    'Besny-et-Loizy',
    'Besneville',
    'Besne',
    'Beslon',
    'Bescat',
    'Besayes',
    'Besançon',
    'Berzy-le-Sec',
    'Berzeme',
    'Berville-sur-Seine',
    'Berviller-en-Moselle',
    'Berville-en-Roumois',
    'Berville',
    'Berus',
    'Beruges',
    'Bertry',
    'Bertricourt',
    'Bertric-Buree',
    'Bertreville-Saint-Ouen',
    'Bertren',
    'Bertrange',
    'Bertrancourt',
    'Bertrambois',
    'Bertoncourt',
    'Bertincourt',
    'Berthouville',
    'Bertholene',
    'Berthenay',
    'Berthelming',
    'Berthecourt',
    'Berteaucourt-les-Dames',
    'Bertangles',
    'Berstett',
    'Berson',
    'Bersillies',
    'Bersee',
    'Bersac-sur-Rivalier',
    'Berry-Bouy',
    'Berrwiller',
    'Berrieux',
    'Berrien',
    'Berrie',
    'Berric',
    'Berriac',
    "Berre-l'Etang",
    'Berre-les-Alpes',
    'Berou-la-Mulotiere',
    'Berny-Riviere',
    'Bernwiller',
    'Bernouville',
    'Bernos-Beaulac',
    'Bernis',
    'Bernin',
    'Bernieres-sur-Mer',
    'Bernieres-le-Patry',
    'Bernex',
    'Berneval-le-Grand',
    'Berneuil-sur-Aisne',
    'Bernes-sur-Oise',
    'Bernecourt',
    'Berne',
    'Bernay-Vilbert',
    'Bernay',
    'Bernaville',
    'Bernardville',
    'Bernardswiller',
    'Bernadets-Dessus',
    'Bernac-Debat',
    'Bermont',
    'Bermeries',
    'Berling',
    'Berlancourt',
    'Berlaimont',
    'Berjou',
    'Berigny',
    'Bergues',
    'Berg-sur-Moselle',
    'Bergnicourt',
    'Bergholtz',
    'Bergheim',
    'Bergesserin',
    'Bergeres-les-Vertus',
    'Bergerac',
    'Bergbieten',
    'Berg',
    'Berfay',
    'Bereziat',
    'Berentzwiller',
    'Berengeville-la-Campagne',
    'Berdoues',
    "Berd'huis",
    'Berck',
    'Bercheres-sur-Vesgre',
    'Bercheres-les-Pierres',
    'Berche',
    'Bercenay-en-Othe',
    'Berat',
    'Beost',
    'Beon',
    'Beny',
    'Benquet',
    'Bénodet',
    'Bennwihr',
    'Benney',
    'Bennecourt',
    'Bengy-sur-Craon',
    'Benfeld',
    "Benevent-l'Abbaye",
    'Benet',
    'Benestroff',
    'Benest',
    'Benesse-Maremne',
    'Benay',
    'Benassay',
    'Benarville',
    'Benamenil',
    'Benagues',
    'Benac',
    'Bemecourt',
    'Belz',
    'Belveze-du-Razes',
    'Belveze',
    'Belves-de-Castillon',
    'Belvès',
    'Belverne',
    'Belvedere',
    'Belus',
    'Belpech',
    'Belonchamp',
    'Belmont-Tramonet',
    'Belmont-sur-Rance',
    'Belmont-sur-Buttant',
    'Belmont-de-la-Loire',
    'Belmont-Bretenoux',
    'Belmont',
    'Belloy-en-France',
    'Bellou-sur-Huisne',
    'Bellot',
    'Bellonne',
    'Bellon',
    'Bellocq',
    'Bellignies',
    'Belligne',
    'Bellignat',
    'Bellicourt',
    'Belley',
    'Bellevue-la-Montagne',
    'Belleville-sur-Vie',
    'Belleville',
    'Belleville-sur-Meuse',
    'Belleville-sur-Mer',
    'Belleville-en-Caux',
    'Bellevaux',
    'Belleuse',
    'Belleu',
    'Bellesserre',
    'Belleroche',
    'Bellerive-sur-Allier',
    'Bellentre',
    'Bellengreville',
    'Bellenglise',
    'Belleneuve',
    'Bellencombre',
    'Bellenaves',
    'Bellême',
    'Belle-Isle-en-Terre',
    'Bellegarde-sur-Valserine',
    'Bellegarde-en-Marche',
    'Bellegarde-en-Forez',
    'Bellegarde-en-Diois',
    'Bellegarde',
    'Bellefontaine',
    'Bellefond',
    'Belle-et-Houllefort',
    'Bellecombe-en-Bauges',
    'Bellechaume',
    'Bellebrune',
    'Bellebat',
    'Belleau',
    'Bellaing',
    'Bellac',
    'Belin-Beliet',
    'Beligneux',
    'Beliet',
    'Belhade',
    'Belgentier',
    'Belfort-du-Quercy',
    'Belfort',
    'Belesta',
    'Belcodene',
    'Belcastel',
    'Belcaire',
    'Belbeuf',
    'Belberaud',
    'Belan-sur-Ource',
    'Beinheim',
    'Beine-Nauroy',
    'Beine',
    'Beille',
    'Beignon',
    'Behuard',
    'Behren-les-Forbach',
    'Behoust',
    'Behonne',
    'Beguey',
    'Begnecourt',
    'Begles',
    'Begard',
    'Beganne',
    'Begadan',
    'Begaar',
    'Beffes',
    'Beduer',
    'Bedous',
    'Bedoues',
    'Bédoin',
    'Bedee',
    'Bedarrides',
    'Bédarieux',
    'Becquincourt',
    'Becquigny',
    'Becon-les-Granits',
    'Bechy',
    'Becherel',
    'Bec-de-Mortagne',
    'Beblenheim',
    'Beauzelle',
    'Beausite',
    'Beauzac',
    'Beaux',
    'Beauvoisin',
    'Beauvois-en-Cambresis',
    'Beauvoir-sur-Niort',
    'Beauvoir-sur-Mer',
    'Beauvoir-en-Lyons',
    'Beauvoir',
    'Beauvilliers',
    'Beauvezer',
    'Beauvallon',
    'Beauval',
    'Beauvais',
    'Beauvain',
    'Beautor',
    'Beautiran',
    'Beautheil',
    'Beausse',
    'Beaussais',
    'Beausoleil',
    'Beausemblant',
    'Beauronne',
    'Beaurieux',
    'Beaurevoir',
    'Beaurepaire',
    'Beauregard-Vendon',
    "Beauregard-l'Eveque",
    'Beauregard',
    'Beaurecueil',
    'Beaurainville',
    'Beaurains',
    'Beaurain',
    'Beauquesne',
    'Beaupuy',
    'Beaupreau',
    'Beaune-la-Rolande',
    "Beaune-d'Allier",
    'Beaune',
    'Beaumotte-Aubertans',
    'Beaumont-Village',
    'Beaumont-sur-Sarthe',
    'Beaumont-sur-Oise',
    'Beaumont-sur-Leze',
    'Beaumont-sur-Grosne',
    'Beaumont-Sardolles',
    'Beaumont-Monteux',
    'Beaumont-les-Valence',
    'Beaumont-les-Nonains',
    'Beaumont-le-Roger',
    'Beaumont-Hague',
    'Beaumont-en-Veron',
    'Beaumont-du-Gatinais',
    'Beaumont-en-Auge',
    'Beaumont-en-Argonne',
    'Beaumontel',
    'Beaumont-de-Lomagne',
    'Beaumont',
    'Beaumetz-les-Loges',
    'Beaumetz',
    'Beaumettes',
    'Beaumesnil',
    'Beaumes-de-Venise',
    'Beaumerie-Saint-Martin',
    'Beaumarches',
    'Beaulon',
    'Beaulieu-sur-Mer',
    'Beaulieu-sur-Loire',
    'Beaulieu-sur-Dordogne',
    'Beaulieu-sous-la-Roche',
    'Beaulieu-sous-Bressuire',
    'Beaulieu-lès-Loches',
    'Beaulieu-les-Fontaines',
    'Beaulieu',
    'Beaujeu',
    'Beaugency',
    'Beaugas',
    'Beaufort-sur-Gervanne',
    'Beaufort-en-Vallee',
    'Beaufort-en-Santerre',
    'Beaufort-Blavincourt',
    'Beaufort',
    'Beaudricourt',
    'Beaucroissant',
    'Beaucouze',
    "Beaucourt-sur-l'Hallue",
    'Beaucourt',
    'Beaucoudray',
    'Beauchastel',
    'Beauchamps-sur-Huillard',
    'Beauchamps',
    'Beauchamp',
    'Beauchalot',
    'Beauce',
    'Beaucaire',
    'Beaubec-la-Rosiere',
    'Bazus-Neste',
    'Bazus-Aure',
    'Bazuel',
    'Bazouges-sur-le-Loir',
    'Bazouges-la-Perouse',
    'Bazoilles-sur-Meuse',
    'Bazoges en Pareds',
    'Bazoges-en-Paillers',
    'Bazoches-sur-le-Betz',
    'Bazoches-sur-Guyonne',
    'Bazoches-les-Hautes',
    'Bazoches-les-Gallerandes',
    'Bazoches-les-Bray',
    'Bazoches',
    'Bazincourt-sur-Epte',
    'Baziege',
    'Bazicourt',
    'Bazet',
    'Bazens',
    'Bazemont',
    'Bazelat',
    'Bazeilles',
    'Bazas',
    'Bazancourt',
    'Bazaiges',
    'Bayonville-sur-Mad',
    'Bayonvillers',
    'Bayonne',
    'Bayon',
    'Bayon-sur-Gironde',
    'Bayeux',
    'Bayet',
    'Bayenghem-les-Eperlecques',
    'Bayel',
    'Bayecourt',
    'Baye',
    'Bayas',
    'Bayac',
    'Bavincourt',
    'Bavinchove',
    'Bavilliers',
    'Bavent',
    'Bavay',
    'Bavans',
    'Bauzy',
    'Bauvin',
    'Baurech',
    'Bauquay',
    'Baune',
    'Baume-les-Messieurs',
    'Baume-les-Dames',
    'Baulou',
    'Baulon',
    'Baulne-en-Brie',
    'Baulne',
    'Baule',
    'Baugy',
    'Bauge-en-Anjou',
    'Bauduen',
    'Baudrieres',
    'Baudricourt',
    'Baudres',
    'Baudreix',
    'Baudonvilliers',
    'Baudoncourt',
    'Baudignan',
    'Baudemont',
    'Baudement',
    'Baud',
    'Batz-sur-Mer',
    'Batzendorf',
    'Île de Batz',
    'Battenheim',
    'Batilly',
    'Bathernay',
    'Basville',
    'Bastia',
    'Bastelica',
    'Bassy',
    'Bassussarry',
    'Bassu',
    'Bassou',
    'Bassillac',
    'Bassevelle',
    'Bassens',
    'Bassemberg',
    'Indre',
    'Basse-Ham',
    'Basse-Goulaine',
    'Bassan',
    'Basly',
    'Baslieux',
    'Bas-en-Basset',
    'Bascous',
    'Bascons',
    'Basainville',
    'Barzan',
    'Barville-en-Gâtinais',
    'Barville',
    'Bartres',
    'Bartenheim',
    'Bart',
    'Bar-sur-Seine',
    'Bar-sur-Aube',
    'Barsac',
    'Bars',
    'Barry',
    'Barrou',
    'Barret',
    'Barran',
    'Barr',
    'Barquet',
    'Baronville',
    'Baron-sur-Odon',
    'Baron',
    'Baromesnil',
    'Barneville-Carteret',
    'Barnave',
    'Barly',
    'Barlin',
    'Barleux',
    'Barlest',
    'Bar-le-Duc',
    'Barjouville',
    'Barjols',
    'Barjac',
    'Barisis',
    'Barie',
    'Barges',
    'Bargemon',
    'Bargeme',
    'Barfleur',
    'Barenton-Bugny',
    'Barenton',
    'Barentin',
    'Barembach',
    'Bardouville',
    'Bardos',
    'Bardenac',
    'Barcus',
    'Barcelonnette',
    'Barcelonne-du-Gers',
    'Barcelonne',
    'Barc',
    'Barby',
    'Barbuise',
    'Barbonne-Fayel',
    'Barbizon',
    'Barbieres',
    'Barbezieux-Saint-Hilaire',
    'Barbezieres',
    'Barbey',
    'Barbery',
    'Barberier',
    'Barberaz',
    'Barbentane',
    'Barbechat',
    'Barbazan-Debat',
    'Barbazan',
    'Barbatre',
    'Barbaste',
    'Barbas',
    'Barbaira',
    'Barbachen',
    'Baratier',
    'Baraqueville',
    'Baraigne',
    'Barace',
    'Bapaume',
    'Banyuls de la Marenda',
    'Banyuls-dels-Aspres',
    'Bantzenheim',
    'Bantouzelle',
    'Bantigny',
    'Bantanges',
    'Banos',
    'Banon',
    'Bannoncourt',
    'Bannieres',
    'Banneville-sur-Ajon',
    'Bannes',
    'Banne',
    'Bannay',
    'Bannalec',
    'Bangor',
    'Baneuil',
    'Bandol AOC',
    'Ban-de-Laveline',
    'Bambiderstroff',
    'Bambecque',
    'Balzac',
    'Balschwiller',
    'Balsac',
    'Balogna',
    'Balmont',
    'Balma',
    'Ballots',
    'Ballon',
    'Ballersdorf',
    'Balleroy',
    'Ballee',
    'Ballay',
    'Ballans',
    'Ballan-Mire',
    'Ballancourt-sur-Essonne',
    'Ballaison',
    'Ballainvilliers',
    'Balizac',
    'Balesmes-sur-Marne',
    'Baldersheim',
    'Baldenheim',
    'Balbigny',
    'Balaze',
    'Balaruc-le-Vieux',
    'Balaruc-les-Bains',
    'Balanzac',
    'Balanod',
    'Balan',
    'Balaiseaux',
    'Balagny-sur-Therain',
    'Baladou',
    'Bajus',
    'Baixas',
    'Baix',
    'Baives',
    'Baissey',
    'Baisieux',
    'Bais',
    'Bairols',
    'Bains-sur-Oust',
    'Bain-de-Bretagne',
    'Baincthun',
    'Bailly-Romainvilliers',
    'Bailly',
    'Baillolet',
    'Bailleval',
    'Bailleul-sur-Therain',
    'Bailleul-Sir-Berthoult',
    'Bailleul',
    'Baillet-en-France',
    'Baillestavy',
    "Bailleau-l'Évêque",
    'Bailleau-le-Pin',
    'Baillargues',
    'Baigts-de-Bearn',
    'Baigts',
    'Baignes-Sainte-Radegonde',
    'Baho',
    'Bagnols-sur-Ceze',
    'Bagnols-les-Bains',
    'Bagnols-en-Foret',
    'Bagnols',
    'Bagnolet',
    "Bagnoles-de-l'Orne",
    'Bagneux',
    'Bagnères-de-Luchon',
    'Bagnères-de-Bigorre',
    'Bagneaux-sur-Loing',
    'Bagnac-sur-Cele',
    'Bages',
    'Bage-la-Ville',
    'Bagas',
    'Bagard',
    'Baffie',
    'Baerenthal',
    'Baerendorf',
    'Badonviller',
    'Badinieres',
    'Badevel',
    'Badens',
    'Baden',
    'Badaroux',
    'Bacqueville-en-Caux',
    'Bacqueville',
    'Bacquepuis',
    'Bacouel',
    'Bacilly',
    'Bachy',
    'Bachivillers',
    'Bachant',
    'Baccon',
    'Baccarat',
    'Azy-sur-Marne',
    'Azy',
    'Azur',
    'Azoudange',
    'Azincourt',
    'Azille',
    'Azillanet',
    'Azereix',
    'Azerat',
    'Azerailles',
    'Azelot',
    'Aze',
    'Azay-sur-Thouet',
    'Azay-sur-Cher',
    'Azay-le-Rideau',
    'Azay-le-Ferron',
    'Azay-le-Brule',
    'Azas',
    'Ayzac-Ost',
    'Aytre',
    'Ay-sur-Moselle',
    'Ayse',
    'Ayros-Arbouix',
    'Ayron',
    'Ayrens',
    'Ayherre',
    'Ayguesvives',
    'Ayguemorte-les-Graves',
    'Aydoilles',
    'Aydat',
    'Aÿ',
    'Ax-les-Thermes',
    'Axat',
    'Avy',
    'Avrilly',
    'Avrille-les-Ponceaux',
    'Avrille',
    'Avricourt',
    'Avressieux',
    'Avremesnil',
    'Avree',
    'Avrechy',
    'Avranches',
    'Avrainville',
    'Avoudrey',
    'Avosnes',
    'Avord',
    'Avon-les-Roches',
    'Avon-la-Peze',
    'Avon',
    'Avolsheim',
    'Avoine',
    'Avize',
    'Aviron',
    'Avion',
    'Avignonet-Lauragais',
    'Avignon',
    'Aveze',
    'Avesse',
    'Avessac',
    'Avesnes-sur-Helpe',
    'Avesnes-le-Sec',
    'Avesnes-le-Comte',
    'Avesnelles',
    'Averton',
    'Avermes',
    'Averdon',
    'Avensan',
    'Avenheim',
    "Avenay-Val-d'Or",
    'Aveluy',
    'Avelin',
    'Aveizieux',
    'Aveize',
    'Avaux',
    'Avanton',
    'Avant-les-Marcilly',
    'Avanne-Aveney',
    'Avallon',
    'Availles-Limouzine',
    "Auzouville-l'Esneval",
    'Auzouville-Auberbosc',
    'Auzouer-en-Touraine',
    'Auzon',
    'Auzielle',
    'Auzeville-Tolosane',
    'Auzeville-en-Argonne',
    'Auzelles',
    'Auzebosc',
    'Auzay',
    'Auzat-la-Combelle',
    'Auzat',
    'Auzances',
    'Auxy',
    'Auxonne',
    'Auxon-Dessous',
    'Auxon',
    'Aux Marais',
    'Auxi-le-Chateau',
    'Auxerre',
    'Auxais',
    'Auvillers-les-Forges',
    'Auvillars-sur-Saone',
    'Auvillar',
    'Auvers-sur-Oise',
    'Auvers-Saint-Georges',
    'Auverse',
    'Auve',
    'Autun',
    'Autry-le-Chatel',
    'Autruy-sur-Juine',
    'Autreville',
    'Autretot',
    'Autreches',
    'Autrans',
    'Autrac',
    'Autouillet',
    'Autingues',
    'Autigny-le-Petit',
    'Autignac',
    'Authumes',
    'Authume',
    'Authuille',
    'Authouillet',
    'Authon-du-Perche',
    'Authon',
    'Authon-Ebeon',
    'Authieux-Ratieville',
    'Authieule',
    'Authie',
    'Authezat',
    'Authevernes',
    'Autheuil-Authouillet',
    'Auteuil',
    'Auterrive',
    'Auterive',
    'Autechaux',
    'Aussurucq',
    'Aussos',
    'Aussonne',
    'Aussonce',
    'Ausson',
    'Aussois',
    'Aussillon',
    'Aussevielle',
    'Aussac-Vadalle',
    'Auros',
    'Auriol',
    'Aurin',
    'Aurimont',
    'Aurillac',
    'Aurignac',
    'Aurieres',
    'Auriebat',
    'Aurice',
    'Auribeau-sur-Siagne',
    'Auriac-sur-Vendinelle',
    'Auriac-du-Perigord',
    'Aureville',
    'Aurensan',
    'Aurel',
    'Aureille',
    'Aureilhan',
    'Aureil',
    'Aurec-sur-Loire',
    'Auray',
    'Auradou',
    'Aurade',
    'Auquemesnil',
    'Auquainville',
    'Aups',
    'Auppegard',
    'Auneuil',
    'Auneau',
    'Aunay-sur-Odon',
    'Aunay-sous-Auneau',
    'Aunat',
    'Aumur',
    'Aumont-Aubrac',
    'Aumont-en-Halatte',
    'Aumont',
    'Aumetz',
    'Aumessas',
    'Aumes',
    'Aumerval',
    'Aumenancourt',
    'Aumatre',
    'Aumale',
    'Aumagne',
    'Ault',
    'Aulon',
    'Aulnoye-Aymeries',
    'Aulnoy-lez-Valenciennes',
    'Aulnoy',
    'Aulnois-sur-Seille',
    'Aulnay-sur-Mauldre',
    'Aulnay-sous-Bois',
    'Aulnay',
    'Aulhat',
    'Aulas',
    'Aujols',
    'Aujargues',
    'Aujac',
    'Augy-sur-Aubois',
    'Augy',
    'Augny',
    'Augnax',
    'Augnat',
    'Augignac',
    'Augerville-la-Riviere',
    'Augers-en-Brie',
    'Augerans',
    'Augea',
    'Auge',
    'Auge-Saint-Medard',
    'Auflance',
    'Aufferville',
    'Auffay',
    'Auffargis',
    'Audun-le-Tiche',
    'Audun-le-Roman',
    'Audruicq',
    'Audrix',
    'Audrieu',
    'Audresselles',
    'Audrehem',
    'Audon',
    'Audinghen',
    'Audincthun',
    'Audincourt',
    'Audignon',
    'Audignies',
    'Audierne',
    'Audes',
    'Audenge',
    'Audembert',
    'Audelange',
    'Aucun',
    'Auchy-lez-Orchies',
    'Auchy-les-Mines',
    'Auchy-les-Hesdin',
    'Auchel',
    'Auch',
    'Aucamville',
    'Aucaleuc',
    'Auby',
    'Aubusson',
    'Aubry-le-Panthou',
    'Aubry-du-Hainaut',
    'Aubrives',
    'Auboue',
    'Aubord',
    'Aubin',
    'Aubigny-sur-Nère',
    'Aubigny-au-Bac',
    'Aubigny-en-Artois',
    'Aubigny',
    'Aubignosc',
    'Aubigney',
    'Aubigne-Racan',
    'Aubigne',
    'Aubignan',
    'Aubiet',
    'Aubiere',
    'Aubie-et-Espessas',
    'Aubiac',
    'Aubevoye',
    'Aubeterre-sur-Dronne',
    'Aube-sur-Rile',
    'Aubervilliers',
    'Auberville-la-Manuel',
    'Aubertin',
    'Aubers',
    'Aubermesnil-Beaumais',
    'Aubermesnil-aux-Erables',
    'Auberives-en-Royans',
    'Aubergenville',
    'Aubercourt',
    'Auberchicourt',
    'Aubepierre-Ozouer-le-Repos',
    'Aubenas',
    'Aube',
    'Aubazine',
    'Aubas',
    'Aubais',
    'Aubagne',
    'Atur',
    'Attricourt',
    'Attray',
    'Attin',
    'Attigny',
    'Attignat-Oncin',
    'Attignat',
    'Attichy',
    'Attiches',
    'Attenschwiller',
    'Attancourt',
    'Attainville',
    'Athis-Mons',
    'Athis',
    'Athis-de-lOrne',
    'Athies-sous-Laon',
    'Athies',
    'Athesans-Etroitefontaine',
    'Athee-sur-Cher',
    'Athee',
    'Astugue',
    'Aston',
    'Aste',
    'Astaffort',
    'Asswiller',
    'Asson',
    'Assis-sur-Serre',
    'Assignan',
    'Assieu',
    'Assier',
    'Assevillers',
    'Assevent',
    'Asserac',
    'Asse-le-Boisne',
    'Assay',
    'Assat',
    'Assas',
    'Assainvillers',
    'Asquins',
    'Asques',
    'Asque',
    'Aspres-sur-Buech',
    'Aspremont',
    'Aspiran',
    'Aspet',
    'Asperes',
    'Aspach-le-Haut',
    'Aspach-le-Bas',
    'Asnieres-sur-Vegre',
    'Asnieres-sur-Saone',
    'Asnieres-sur-Oise',
    'Asnieres-sur-Nouere',
    'Asnieres-la-Giraud',
    'Asnieres-sur-Seine',
    'Asnelles',
    'Aslonnes',
    'Asfeld',
    'Ascoux',
    'Ascheres-le-Marche',
    'Ascain',
    'Arzviller',
    'Arzon',
    'Arzens',
    'Arzay',
    'Arzano',
    'Arzal',
    'Arx',
    'Arvillers',
    'Arvillard',
    'Arvieux',
    'Arvieu',
    'Arveyres',
    'Arvert',
    'Arue',
    'Arudy',
    'Artzenheim',
    'Art-sur-Meurthe',
    'Artres',
    'Artonne',
    'Artolsheim',
    'Artix',
    'Artigues-pres-Bordeaux',
    'Artiguemy',
    'Artiguelouve',
    'Artigueloutan',
    'Arthonnay',
    'Arthon-en-Retz',
    'Artheze',
    'Arthez-de-Bearn',
    "Arthez-d'Asson",
    'Arthes',
    'Arthemonay',
    'Arthaz-Pont-Notre-Dame',
    'Artenay',
    'Artemps',
    'Artemare',
    'Artassenx',
    'Artas',
    'Artannes-sur-Thouet',
    'Artannes-sur-Indre',
    'Artagnan',
    'Arsy',
    'Ars-sur-Moselle',
    'Ars-sur-Formans',
    'Arsonval',
    'Ars-Laquenexy',
    'Ars-en-Re',
    'Arsac',
    'Ars',
    'Arry',
    'Arrou',
    'Arros-de-Nay',
    'Arronville',
    'Arronnes',
    'Arromanches-les-Bains',
    'Arrigas',
    'Arricau-Bordes',
    'Arreux',
    'Arrest',
    'Arrentieres',
    'Arrelles',
    'Arreau',
    'Arre',
    'Arraute-Charritte',
    'Arras-sur-Rhone',
    'Arras',
    'Arrancy-sur-Crusne',
    'Arradon',
    'Arques-la-Bataille',
    'Arques',
    'Arquenay',
    'Arpheuilles',
    'Arpenans',
    'Arpajon-sur-Cere',
    'Arpajon',
    'Arpaillargues-et-Aureillac',
    'Aron',
    'Arnouville',
    'Arnos',
    'Arnieres-sur-Iton',
    'Arneke',
    'Arnay-sous-Vitteaux',
    'Arnay-le-Duc',
    'Arnaville',
    'Arnave',
    'Arnaud-Guilhem',
    'Arnas',
    'Arnage',
    'Arnac-Pompadour',
    'Arnac-la-Poste',
    'Armoy',
    'Armissan',
    'Armillac',
    'Armes',
    'Armentieres-en-Brie',
    'Armentières',
    'Armendarits',
    'Armbouts-Cappel',
    'Armaille',
    'Arlos',
    'Arleux-en-Gohelle',
    'Arleux',
    'Arles',
    'Arlebosc',
    'Arlanc',
    'Arith',
    'Arinthod',
    'Arignac',
    'Arifat',
    'Arhansus',
    'Argy',
    'Arguel',
    'Argouges',
    'Argonay',
    'Argoeuves',
    'Argis',
    'Argilly',
    'Argilliers',
    'Argillieres',
    'Argiesans',
    'Argenvilliers',
    'Argent-sur-Sauldre',
    'Argentre-du-Plessis',
    'Argentre',
    'Argenton-sur-Creuse',
    'Argenton-les-Vallees',
    'Argentolles',
    'Argentine',
    'Argenteuil',
    'Argentat',
    'Argentan',
    'Argences',
    'Argelouse',
    'Argelos',
    'Argeliers',
    'Argelers',
    'Argeles-Gazost',
    'Argein',
    'Argancy',
    'Argagnon',
    'Arfons',
    'Arette',
    'Ares',
    'Arenthon',
    'Aren',
    'Areines',
    'Aregno',
    'Areches',
    'Ardres',
    'Ardouval',
    'Ardon',
    'Ardoix',
    'Ardin',
    'Ardilleux',
    'Ardiege',
    'Ardentes',
    'Ardenay-sur-Merize',
    'Arcy-sur-Cure',
    'Arcueil',
    'Arc-sur-Tille',
    'Arc-sous-Cicon',
    'Arconnay',
    'Arconcey',
    'Arcon',
    'Arcis-sur-Aube',
    'Arcis-le-Ponsart',
    'Arcins',
    'Archingeay',
    'Archigny',
    'Archignac',
    'Archiac',
    'Archettes',
    'Arches',
    'Archelange',
    'Archamps',
    'Arcey',
    'Arc-et-Senans',
    'Arces',
    'Arces-Dilo',
    'Arcens',
    'Arcenant',
    'Arceau',
    'Arcay',
    'Arcangues',
    'Arcambal',
    'Arcachon',
    'Arc-les-Gray',
    'Arbusigny',
    'Arbus',
    'Arbrissel',
    'Arbouet-Sussaute',
    'Arbouans',
    'Arbonne-la-Foret',
    'Arbonne',
    'Arbon',
    'Arbois',
    'Arbis',
    'Arbigny',
    'Arbent',
    'Arbas',
    'Arbanats',
    'Araules',
    'Arandon',
    'Arance',
    'Aramon',
    'Aramits',
    'Araches-la-Frasse',
    'Apt',
    'Apremont',
    'Apprieu',
    'Appoigny',
    'Appilly',
    'Appietto',
    'Appeville-Annebault',
    'Appenwihr',
    'Apinac',
    'Apach',
    'Aouze',
    'Aouste-sur-Sye',
    'Aouste',
    'Aoste',
    'Anzy-le-Duc',
    'Anzin-Saint-Aubin',
    'Anzin',
    'Anzeling',
    'Any-Martin-Rieux',
    'Anvin',
    'Anveville',
    'Antully',
    'Antrenas',
    'Antran',
    'Antrain',
    'Antony',
    'Antonne-et-Trigonant',
    'Antoingt',
    'Antoigne',
    'Antilly',
    'Antigny',
    'Antignac',
    'Antibes',
    'Anthy-sur-Leman',
    'Anthon',
    'Antheuil-Portes',
    'Antheny',
    'Anthelupt',
    'Anthe',
    'Antezant-la-Chapelle',
    'Anteuil',
    'Anstaing',
    'Ansouis',
    'Ansignan',
    'Anse',
    'Ansauvillers',
    'Ansac-sur-Vienne',
    'Ansacq',
    'Anould',
    'Anos',
    'Anor',
    'Annoville',
    'Annouville-Vilmesnil',
    'Annot',
    'Annonay',
    'Annoix',
    'Annoeullin',
    'Annezin',
    'Anneyron',
    'Anneville-Ambourville',
    'Anneville-sur-Scie',
    'Anneville-en-Saire',
    'Annet-sur-Marne',
    'Annesse-et-Beaulieu',
    'Annequin',
    'Annemasse',
    'Annecy-le-Vieux',
    'Annecy',
    'Annebault',
    'Annay-la-Cote',
    'Annay',
    'Anlhiac',
    'Anlezy',
    'Anjoutey',
    'Anizy-le-Chateau',
    'Anisy',
    'Aniche',
    'Aniane',
    'Anhiers',
    'Anhaux',
    'Angy',
    'Anguilcourt-le-Sart',
    'Anguerny',
    'Angresse',
    'Angres',
    'Angoulins',
    'Angoulême',
    'Angoisse',
    'Anglure',
    'Angliers',
    'Anglet',
    'Val-de-Saane',
    'Angles',
    'Anglemont',
    'Anglars-Saint-Felix',
    'Anglars-Nozac',
    'Anglars-Juillac',
    'Anglars',
    'Anglards-de-Salers',
    'Anglards-de-Saint-Flour',
    'Angivillers',
    'Angiens',
    'Angicourt',
    'Angevillers',
    'Angervilliers',
    "Angerville-l'Orcher",
    'Angerville-la-Martel',
    'Angerville',
    'Angers',
    'Angecourt',
    'Angais',
    'Anetz',
    'Anet',
    'Anduze',
    'Andryes',
    'Andrezieux-Boutheon',
    'Andrezel',
    'Andreze',
    'Andresy',
    'Andrest',
    'Andres',
    'Andouille-Neuville',
    'Andouille',
    'Andornay',
    'Andon',
    'Andolsheim',
    'Andoins',
    'Andlau',
    'Andilly',
    'Andigne',
    'Andeville',
    'Andernos-les-Bains',
    'Andernay',
    'Andelu',
    'Andelot-Blancheville',
    'Andelnans',
    'Andelarrot',
    'Andelain',
    'Andel',
    'Ande',
    'Andard',
    'Andancette',
    'Andance',
    'Ancy-le-Franc',
    'Ancy',
    'Anctoville-sur-Boscq',
    'Ancretieville-Saint-Victor',
    'Ancourt',
    'Ancone',
    'Ancinnes',
    'Anche',
    'Anchamps',
    'Ancerville',
    'Ancenis',
    'Ancemont',
    'Ancelle',
    'Ance',
    'Amy',
    'Ampuis',
    'Amponville',
    'Ampoigne',
    'Amplepuis',
    'Amou',
    'Amoncourt',
    'Amneville',
    'Ammertzwiller',
    'Ammerschwihr',
    'Amions',
    'Amilly',
    'Amillis',
    'Amifontaine',
    'Amiens',
    'Amfroipret',
    'Amfreville-sur-Iton',
    'Amfreville-la-Mi-Voie',
    'Amfreville-la-Campagne',
    'Amfreville',
    'Ameuvelle',
    'Amettes',
    'Amendeuix-Oneix',
    'Amélie-les-Bains',
    'Ambutrix',
    'Ambronay',
    'Ambrieres-les-Vallees',
    'Ambres',
    'Ambrault',
    'Ambourville',
    'Ambonnay',
    'Ambon',
    'Amboise',
    'Ambly-Fleury',
    'Ambleville',
    'Ambleteuse',
    'Ambleny',
    'Amblans-et-Velotte',
    'Amblainville',
    'Ambillou-Chateau',
    'Ambillou',
    'Ambierle',
    'Ambes',
    'Ambert',
    'Amberieux-en-Dombes',
    'Ambérieu-en-Bugey',
    'Amberac',
    'Ambenay',
    'Ambazac',
    'Ambares-et-Lagrave',
    'Ambacourt',
    'Amaye-sur-Orne',
    'Amanvillers',
    'Amanlis',
    'Amange',
    'Amancy',
    'Amancey',
    'Amance',
    'Amailloux',
    'Amagney',
    'Amagne',
    'Alzonne',
    'Alzing',
    'Alzen',
    'Alvimare',
    'Alvignac',
    'Aluze',
    'Altwiller',
    'Altviller',
    'Altorf',
    'Altkirch',
    'Altillac',
    'Althen-des-Paluds',
    'Altenstadt',
    'Altenach',
    'Alteckendorf',
    'Alsting',
    'Alrance',
    'Alquines',
    'Aloxe-Corton',
    'Alos-Sibas-Abense',
    'Alos',
    'Almont-les-Junies',
    'Almeneches',
    'Ally',
    'Alluyes',
    'Alluy',
    'Allouis',
    'Allouagne',
    'Allos',
    'Allonzier-la-Caille',
    'Allonnes',
    'Allonne',
    'Allondrelle',
    'Allogny',
    'Allinges',
    'Allineuc',
    'Alligny-Cosne',
    'Allier',
    'Allex',
    'Allevard',
    'Alles-sur-Dordogne',
    'Allery',
    'Alleriot',
    'Allerey-sur-Saone',
    'Allenwiller',
    'Allennes-les-Marais',
    'Allenjoie',
    'Allenc',
    'Allemond',
    'Allemant',
    'Allemans-du-Dropt',
    'Allemanche-Launay-et-Soyer',
    'Alleins',
    'Allegre',
    'Allegre-les-Fumades',
    'Allauch',
    'Allassac',
    'Allas-Champagne',
    'Allarmont',
    'Allanche',
    'Allan',
    'Allamps',
    'Allaire',
    'Allaines',
    'Alizay',
    'Alixan',
    'Alix',
    'Alissas',
    'Alignan-du-Vent',
    'Algrange',
    'Algolsheim',
    'Algajola',
    'Alfortville',
    'Alexain',
    'Alex',
    'Ailette',
    'Alet-les-Bains',
    'Alès',
    'Aléria',
    'Alenya',
    'Alençon',
    'Aldudes',
    'Alcay-Alcabehety-Sunharette',
    'Alby-sur-Cheran',
    'Albussac',
    'Alboussiere',
    "Albon-d'Ardeche",
    'Albon',
    'Albigny-sur-Saone',
    'Albieres',
    'Albias',
    'Albiac',
    'Albi',
    'Albertville',
    'Albert',
    'Albens',
    'Albefeuille-Lagarde',
    'Albe',
    'Albas',
    'Albaron',
    'Albaret-Sainte-Marie',
    'Alban',
    'Alba-la-Romaine',
    'Alata',
    'Alan',
    'Alairac',
    'Alaincourt',
    'Ajat',
    'Ajaccio',
    'Ajac',
    'Aizy-Jouy',
    'Aizenay',
    'Aix-Noulette',
    'Aix-les-Bains',
    'Aixe-sur-Vienne',
    'Aix-en-Provence',
    'Aix-en-Othe',
    'Aiton',
    'Aiserey',
    'Airvault',
    'Aire-sur-la-Lys',
    "Aire-sur-l'Adour",
    'Aire',
    'Airan',
    'Airaines',
    'Aingeray',
    'Aincreville',
    'Aincourt',
    'Ainay-le-Chateau',
    'Aime',
    'Aimargues',
    'Ailly-sur-Somme',
    'Ailly-sur-Noye',
    'Ailly-le-Haut-Clocher',
    'Aillianville',
    'Aillevillers-et-Lyaumont',
    'Ailleux',
    'Aillas',
    'Aillant-sur-Milleron',
    'Aiguillon',
    'Aigueze',
    'Ayguetinte',
    'Aigues-Vives',
    'Aigues-Mortes',
    'Aigueperse',
    'Aiguefonde',
    'Aigueblanche',
    'Aigremont',
    'Aigrefeuille-sur-Maine',
    "Aigrefeuille-d'Aunis",
    'Aigrefeuille',
    'Aigre',
    'Aigonnay',
    'Aigny',
    'Aigneville',
    'Aignerville',
    'Aigne',
    'Aignay-le-Duc',
    'Aiglun',
    'Aiglemont',
    'Aiffres',
    'Aicirits-Camou-Suhast',
    'Aibre',
    'Ahuy',
    'Ahun',
    'Ahuille',
    'Ahetze',
    'Agy',
    'Aguessac',
    'Agonges',
    'Agones',
    'Agon-Coutainville',
    'Agonac',
    'Agny',
    'Agnin',
    'Agnieres',
    'Agnetz',
    'Agneaux',
    'Agincourt',
    'Agenvillers',
    'Agen-dAveyron',
    'Agencourt',
    'Agen',
    'Agel',
    'Agde',
    'Agay',
    'Afa',
    'Adriers',
    'Adon',
    'Adissan',
    'Adamswiller',
    'Adainville',
    'Acy-en-Multien',
    'Acy',
    'Acquin-Westbecourt',
    'Acquigny',
    'Acq',
    'Acigne',
    'Achy',
    'Achiet-le-Grand',
    'Achicourt',
    'Achey',
    'Acheville',
    'Acheux-en-Vimeu',
    'Achery',
    'Acheres-la-Foret',
    'Acheres',
    'Achenheim',
    'Achen',
    'Accous',
    'Accons',
    'Abzac',
    'Abscon',
    'Abries',
    'Abrest',
    'Abreschviller',
    'Abos',
    'Abondant',
    'Abondance',
    'Aboen',
    'Ablon-sur-Seine',
    'Ablon',
    'Ablis',
    'Ableiges',
    'Abilly',
    'Abelcourt',
    'Abbeville-Saint-Lucien',
    'Abbevillers',
    'Abbeville',
    'Abbenans',
    'Abbecourt',
    'Abbaretz',
    'Abancourt',
    'Aast',
    "Lugon-et-l'Ile-du-Carnay",
    'Gonneville-en-Auge',
    'Locqueltas',
    'Bretagne',
    'La Chabossiere',
    'Les Deux Alpes',
    'Les Laures',
    'Dechy',
    'Saint-Valery-en-Caux',
    'Eze',
    'Berville-sur-Mer',
    'La Varenne-Saint-Hilaire',
    'Saint-Melaine-sur-Aubance',
    'La Menitre',
    "Villeneuve-d'Ascq",
    'Les Issambres',
    'Les Menuires',
    'La Palme',
    'Port Leucate',
    'Roquefort-les-Pins',
    'Port Camargue',
    'Cherisy',
    'Semussac',
    'Cerisy-Buleux',
    'Etrepigney',
    'Choue',
    'Saint-Jacques-des-Guerets',
    'Vievy-le-Raye',
    'Saint-Remy-du-Nord',
    'Cramenil',
    'Sainte-Pexine',
    'Saint-Valery-sur-Somme',
    'Trenal',
    'Saint-Benin-des-Bois',
    'Champagnac-de-Belair',
    'Cheneche',
    'Guemene-Penfao',
    'Saint-Georges-des-Coteaux',
    'Treffiagat',
    'Saint-Etienne-de-Cuines',
    'Menetreuil',
    'Chalo-Saint-Mars',
    'Saint-Brevin-les-Pins',
    'Ainhoa',
    'Avesnes-les-Aubert',
    'Balot',
    'Congenies',
    'Cran-Gevrier',
    'Faches-Thumesnil',
    'Flagey-Echezeaux',
    'Gelos',
    'Épiniac',
    'Frahier-et-Chatebier',
    "Anglesqueville-l'Esneval",
    'Frelinghien',
    "Geues-d'Oloron",
    'Saint-Sorlin-de-Conac',
    'Saint-Remy-sous-Barbuise',
    'Les Landes-Genusson',
    'Les Trois-Moutiers',
    'Ferrals-les-Corbieres',
    'La Grand-Combe',
    'La Grande-Motte',
    'La Bouexiere',
    'Castelmaurou',
    'Geneston',
    'Le Ban Saint-Martin',
    'Sace',
    'Le Sequestre',
    'Saint-Martin-de-Gurson',
    'Les Ulis',
    'Carnoux-en-Provence',
    'Saint-Manvieu-Norrey',
    'Bourgoin-Jallieu',
    'Marville-Moutiers-Brule',
    'Bendejun',
    'Chalandry-Elaire',
    'Brebieres',
    'Le Fresne-sur-Loire',
    'Aumelas',
    'Baalons',
    'Flavigny',
    'Frenouville',
    'Gerbamont',
    'La Chevillotte',
    'Labeaume',
    'Lac-des-Rouges-Truites',
    'Larbroye',
    'Larra',
    'Le Bois-dOingt',
    'Le Burgaud',
    'Le Fel',
    "Le Lac-d'Issarles",
    'Le Tablier',
    'Le Thuel',
    'Lecci',
    'Les Adjots',
    "Les Cotes-d'Arey",
    'Les Tourrettes',
    'Lorp-Sentaraille',
    'Mandres-aux-Quatre-Tours',
    'Marollette',
    'Mercurey',
    "L'Ile-d'Yeu",
    'Montonvillers',
    'Montoy-Flanville',
    'Montroty',
    'Mormaison',
    'Neufbosc',
    'Neyron',
    'Echallon',
    'Renedale',
    'Saint-Hilaire-la-Gerard',
    'Saint-Marc-la-Lande',
    'Vinezac',
    'Vitot',
    'Val-de-Reuil',
    'Seur',
    'Ile-Molene',
    'Crouttes-sur-Marne',
    'Mons-en-Laonnois',
    'Pelvoux',
    'Valdeblore',
    'Champis',
    'Ucel',
    'Bogny-sur-Meuse',
    'Encourtiech',
    'Crandelles',
    'Calorguen',
    'Marsaz',
    'Balines',
    'Menesqueville',
    'Sylvains-les-Moulins',
    'Bercheres-Saint-Germain',
    'Pins-Justaret',
    'Ceyras',
    'Domloup',
    'Pont-Pean',
    'Biol',
    'Boissia',
    'Genilac',
    'Aiguilhe',
    'Bessamorel',
    'Bajamont',
    'Madaillan',
    'Saint-Pierre-de-Coutances',
    'Bayard-sur-Marne',
    "Seuil-d'Argonne",
    'Ile-aux-Moines',
    'Burlioncourt',
    'Lorry-les-Metz',
    'Saulty',
    'Bidos',
    'Crastatt',
    'Ranrupt',
    'Gildwiller',
    'Mittlach',
    "Belmont-d'Azergues",
    'Marcilly-les-Buxy',
    'Saint-Leger-les-Paray',
    'Saint-Pavace',
    'Ambilly',
    'Demi-Quartier',
    'Belloy-sur-Somme',
    'Bermesnil',
    'Castelferrus',
    'Tourrettes',
    'Saint-Antonin-du-Var',
    'Beaufou',
    'Cezais',
    'Chaille-sous-les-Ormeaux',
    'Quincay',
    'Ban-de-Sapt',
    'Bois-de-Champ',
    'Cleurie',
    'Charmoy',
    'Bastelicaccia',
    'Luri',
    'Patrimonio',
    'Ville-di-Pietrabugno',
    'Sophia Antipolis',
    "L'Esperou",
    "Les Carroz d'Araches",
    'Ploumanach',
    'La Claquette',
    'Pyla sur Mer',
    'Les Echets',
    'Maquens',
    'Villalbe',
    'Nuces',
    'La Primaube',
    'Gages',
    'Roumazieres',
    'Blajoux',
    'La Source',
    'Chazoy',
    'Sengern',
    'Roissy-Charles de Gaulle',
    'Landrellec',
    'Roissy CDG',
    'Saint Denis',
    'La Plagne Tarentaise',
    'Mareuil en Perigord',
    'Lesponne',
    'Neunhoffen',
    'Gandren',
    'Querciolo',
    'Le Chable',
  ],
  Monaco: ['Monte Carlo', 'Monaco'],
  Andorra: [
    'Soldeu',
    'Sispony',
    'El Tarter',
    'Sant Julià de Lòria',
    'Santa Coloma',
    'Pal',
    'Ordino',
    'Meritxell',
    'Escaldes-Engordany',
    'La Massana',
    'Encamp',
    'Canillo',
    'Arinsal',
    'Andorra la Vella',
  ],
  Liechtenstein: [
    'Vaduz',
    'Triesenberg',
    'Triesen',
    'Schellenberg',
    'Schaanwald',
    'Schaan',
    'Ruggell',
    'Planken',
    'Nendeln',
    'Mauren',
    'Gamprin',
    'Eschen',
    'Bendern',
    'Balzers',
    'Mals',
    'Steg',
    'Oberplanken',
    'Bim Stall',
    'Welda Bongert',
  ],
  Jersey: ['Saint John', 'Saint Helier', 'Trinity', 'St Lawrence', 'Grouville'],
  'Isle of Man': [
    'Saint Johns',
    'Ramsey',
    'Port Saint Mary',
    'Port Erin',
    'Peel',
    'Onchan',
    'Maughold',
    'Lezayre',
    'Laxey',
    'Douglas',
    'Colby',
    'Bride',
    'Ballasalla',
    'Andreas',
  ],
  Guernsey: ['Saint Sampson', 'St Peter Port', 'St. Saviour', 'St Martin'],
  Czechia: [
    'Dvur Kralove nad Labem',
    'Zvoleneves',
    'Zvole',
    'Zverinek',
    'Zvanovice',
    'Zubri',
    'Zruc nad Sazavou',
    'Znojmo',
    'Zlutice',
    'Zlonice',
    'Zlobice',
    'Zliv',
    'Zlín',
    'Zlicin',
    'Zlechov',
    'Zleby',
    'Zlate Hory',
    'Zlata',
    'Zizelice',
    'Zitenice',
    'Zirovnice',
    'Zihle',
    'Zidneves',
    'Zidlochovice',
    'Zermanice',
    'Zeretice',
    'Zercice',
    'Zenklava',
    'Zemetice',
    'Zeliv',
    'Zelezny Brod',
    'Zeleznice',
    'Zelezna Ruda',
    'Zeletava',
    'Zelesice',
    'Zelenec',
    'Zelechovice nad Drevnici',
    'Zehun',
    'Zebrak',
    'Zdounky',
    'Zdirec',
    'Zdikovec',
    'Zdikov',
    'Zdice',
    'Zdiby',
    'Zdechovice',
    'Zdechov',
    'Zdanice',
    'Zbysov',
    'Zbyslavice',
    'Zbuzany',
    'Zbuch',
    'Zbraslavice',
    'Zbraslav',
    'Zbiroh',
    'Zator',
    'Žatec',
    'Zatec',
    'Zatcany',
    'Zastavka',
    'Zasova',
    'Zasmuky',
    'Zaryby',
    'Zarosice',
    'Zandov',
    'Zamberk',
    'Zalhostice',
    'Zákupy',
    'Zakrany',
    'Zajecov',
    'Zahradky',
    'Zahorovice',
    'Zadverice',
    'Zadni Treban',
    'Zacler',
    'Zabrodi',
    'Zabreh',
    'Zabcice',
    'Vyzlovka',
    'Vyssi Brod',
    'Vysoky Chlumec',
    'Vysoke nad Jizerou',
    'Vysoke Myto',
    'Vysoka nad Labem',
    'Vysocany',
    'Vyskovice',
    'Vyškov',
    'Vyrovice',
    'Vyprachtice',
    'Vykleky',
    'Vsetin',
    'Vsetaty',
    'Vsestary',
    'Vseruby',
    'Vseradice',
    'Vsenory',
    'Vsechovice',
    'Vroutek',
    'Vresova',
    'Vresina',
    'Vresce',
    'Vrchotovy Janovice',
    'Vrchoslavice',
    'Vrchlabi',
    'Vrbovec',
    'Vrbno pod Pradedem',
    'Wrasch',
    'Vratimov',
    'Vrany',
    'Vranovice',
    'Vrane nad Vltavou',
    'Vracov',
    'Vraclav',
    'Votice',
    'Volfartice',
    'Volenice',
    'Volduchy',
    'Volary',
    'Vojnuv Mestec',
    'Vojkovice',
    'Vodochody',
    'Vodnany',
    'Voderady',
    'Vnorovy',
    'Vlkanec',
    'Vlcnov',
    'Vlci Habrina',
    'Vlasim',
    'Vlasatice',
    'Vladislav',
    'Vlachovo Brezi',
    'Vlachovice',
    'Vizovice',
    'Vitkov',
    'Vitice',
    'Vitejeves',
    'Vir',
    'Vinicne Sumice',
    'Vinarice',
    'Vimperk',
    'Vigantice',
    'Vicov',
    'Vez',
    'Veverske Kninice',
    'Veverska Bityska',
    'Vetrny Jenikov',
    'Vetrni',
    'Vetrkovice',
    'Veseli nad Moravou',
    'Veseli nad Luznici',
    'Veselicko',
    'Verovice',
    'Verovany',
    'Vernerice',
    'Vermerovice',
    'Vendryne',
    'Velvary',
    'Veltrusy',
    'Velky Ujezd',
    'Velky Tynec',
    'Velky Senov',
    'Velky Osek',
    'Velky Orechov',
    'Velky Borek',
    'Velky Beranov',
    'Velke Prilepy',
    'Velke Popovice',
    'Velke Pavlovice',
    'Velke Opatovice',
    'Velke Nemcice',
    'Velke Mezirici',
    'Velke Losiny',
    'Velke Karlovice',
    'Velke Heraltice',
    'Velke Hamry',
    'Velke Brezno',
    'Velke Bilovice',
    'Velke Albrechtice',
    'Velka Polom',
    'Velka nad Velickou',
    'Velka Losenice',
    'Velka Jesenice',
    'Velka Hlegsebe',
    'Velka Dobra',
    'Velka Bystrice',
    'Velka Bites',
    'Veliny',
    'Velim',
    'Velichovky',
    'Velesin',
    'Velemin',
    'Velehrad',
    'Vejprty',
    'Vejprnice',
    'Vedrovice',
    'Varnsdorf',
    'Vanovice',
    'Vamberk',
    'Valtice',
    'Valasske Mezirici',
    'Valasske Klobouky',
    'Valasska Polanka',
    'Valasska Bystrice',
    'Vaclavovice',
    'Vacenovice',
    'Uzice',
    'Uvaly',
    'Uvalno',
    'Utery',
    'Usti nad Orlici',
    'Ústí nad Labem',
    'Ustek',
    'Usov',
    'Usobrno',
    'Usobi',
    'Urcice',
    'Urbanov',
    'Upice',
    'Unin',
    'Unicov',
    'Unhost',
    'Unesov',
    'Unanov',
    'Ujezd nad Lesy',
    'Ujezd',
    'Uhrineves',
    'Uhretice',
    'Uhonice',
    'Uholicky',
    'Uhlirske Janovice',
    'Uhersky Ostroh',
    'Uhersky Brod',
    'Uhersko',
    'Uherské Hradiště',
    'Uhercice',
    'Tyn nad Vltavou',
    'Tyniste nad Orlici',
    'Tynec nad Sazavou',
    'Tynec nad Labem',
    'Tymakov',
    'Tvrdonice',
    'Tvarožná',
    'Tutleky',
    'Tursko',
    'Turnov',
    'Tupesy',
    'Tuklaty',
    'Tucin',
    'Tuchomerice',
    'Tuchlovice',
    'Trutnov',
    'Trubin',
    'Trstenice',
    'Trsice',
    'Troubsko',
    'Troubky',
    'Troubelice',
    'Troskotovice',
    'Trnova',
    'Trnov',
    'Trnavka',
    'Trmice',
    'Trinec',
    'Trhovy Stepanov',
    'Trhove Sviny',
    'Trhova Kamenice',
    'Trhanov',
    'Trestina',
    'Trest',
    'Trenice',
    'Tremosnice',
    'Tremosna',
    'Trebovice',
    'Trebotov',
    'Trebon',
    'Trebom',
    'Trebivlice',
    'Trebichovice',
    'Třebíč',
    'Trebechovice pod Orebem',
    'Traplice',
    'Tovacov',
    'Touzim',
    'Tousen',
    'Topolna',
    'Tlumacov',
    'Tisnov',
    'Tisice',
    'Tisa',
    'Ticha',
    'Tetcice',
    'Tesany',
    'Terezín',
    'Teplice nad Metuji',
    'Teplice',
    'Tene',
    'Temice',
    'Telc',
    'Tatobity',
    'Tatenice',
    'Tasovice',
    'Tanvald',
    'Tachov',
    'Tábor',
    'Syrovice',
    'Sykorice',
    'Svratka',
    'Svojkov',
    'Svojetice',
    'Svitavy',
    'Svitavka',
    'Svinare',
    'Svijany',
    'Svidnice',
    'Sviadnov',
    'Svetla nad Sazavou',
    'Svaty Mikulas',
    'Svatoborice',
    'Svatava',
    'Svarov',
    'Susice',
    'Sumvald',
    'Sumperk',
    'Sumavske Hostice',
    'Sulejovice',
    'Sudomerice u Tabora',
    'Sudkov',
    'Suchy',
    'Suchdol nad Luznici',
    'Suchdol',
    'Sucha Loz',
    'Studenka',
    'Studenec',
    'Studena',
    'Strunkovice nad Blanici',
    'Struharov',
    'Strmilov',
    'Stritez nad Ludinou',
    'Strilky',
    'Stribro',
    'Stribrna Skalice',
    'Strelice',
    'Stredokluky',
    'Straz pod Ralskem',
    'Strazovice',
    'Strazov',
    'Straznice',
    'Straz nad Nisou',
    'Strazisko',
    'Straskov',
    'Strani',
    'Strancice',
    'Stramberk',
    'Strakonice',
    'Strahovice',
    'Stradonice',
    'Strachotice',
    'Stozec',
    'Stonava',
    'Stonarov',
    'Stod',
    'Stochov',
    'Stity',
    'Stitna nad Vlari',
    'Stezery',
    'Steti',
    'Sternberk',
    'Stepanov',
    'Stenovicky Borek',
    'Stenovice',
    'Stehelceves',
    'Stechovice',
    'Steborice',
    'Statenice',
    'Stary Sachov',
    'Stary Poddvorov',
    'Stary Plzenec',
    'Stary Kolin',
    'Stary Jicin',
    'Stary Hrozenkov',
    'Starovice',
    'Staric',
    'Stare Sedliste',
    'Stare Mesto',
    'Stare Krecany',
    'Stare Hradiste',
    'Stare Buky',
    'Stara Voda',
    'Stara Ves nad Ondrejnici',
    'Stara Paka',
    'Stara Lysa',
    'Stachy',
    'Stablovice',
    'Srubec',
    'Spytihnev',
    'Sporice',
    'Spomysl',
    'Špindlerův Mlýn',
    'Spalov',
    'Spalene Porici',
    'Solnice',
    'Sokolov',
    'Sokolnice',
    'Sobotka',
    'Sobotin',
    'Sobeslav',
    'Smrzovka',
    'Smirice',
    'Smidary',
    'Smecno',
    'Slusovice',
    'Sluknov',
    'Sloup',
    'Slezske Pavlovice',
    'Slavonice',
    'Slavkov u Brna',
    'Slavkov',
    'Slavicin',
    'Slatinice',
    'Slatinany',
    'Slapy',
    'Slapanov',
    'Šlapanice',
    'Slany',
    'Skvorec',
    'Skutec',
    'Skuhrov nad Belou',
    'Skuhrov',
    'Skoronice',
    'Skalsko',
    'Skalna',
    'Skalice nad Svitavou',
    'Skalice',
    'Sivice',
    'Sitborice',
    'Siroka Niva',
    'Sipy',
    'Silherovice',
    'Sibrina',
    'Sezimovo Usti',
    'Sezemice',
    'Sevetin',
    'Sestajovice',
    'Sepekov',
    'Senozaty',
    'Senov',
    'Senohraby',
    'Senice na Hane',
    'Senice',
    'Senec',
    'Semily',
    'Semice',
    'Semcice',
    'Sehradice',
    'Sedlnice',
    'Sedliste',
    'Sedlice',
    'Sedlec',
    'Sedlcany',
    'Sec',
    'Sebranice',
    'Sebetov',
    'Sazava',
    'Sardice',
    'Samotisky',
    'Sakvice',
    'Sadska',
    'Sadov',
    'Rynoltice',
    'Rynarec',
    'Rymarov',
    'Rychvald',
    'Rychnov nad Kneznou',
    'Rychnov',
    'Rybniste',
    'Rybi',
    'Rusava',
    'Rumburk',
    'Rudoltice',
    'Rudolfov',
    'Rudnik',
    'Rudna',
    'Rudikov',
    'Ruda nad Moravou',
    'Rtyne v Podkrkonosi',
    'Rozvadov',
    'Roztoky',
    'Rozsochy',
    'Rozsicka',
    'Rozsec nad Kunstatem',
    'Roznov pod Radhostem',
    'Rozna',
    'Rozmital pod Tremsinem',
    'Rozdrojovice',
    'Rovensko pod Troskami',
    'Rovecne',
    'Rousinov',
    'Roudnice nad Labem',
    'Roudne',
    'Rouchovany',
    'Rotava',
    'Rostin',
    'Rosice',
    'Ropice',
    'Rokytnice nad Jizerou',
    'Rokytnice',
    'Rokycany',
    'Rohle',
    'Rohatec',
    'Ritka',
    'Rimov',
    'Ricmanice',
    'Ricany',
    'Rez',
    'Revnicov',
    'Revnice',
    'Resice',
    'Repin',
    'Recany nad Labem',
    'Ratiskovice',
    'Ratibor',
    'Rataje nad Sazavou',
    'Rataje',
    'Raspenava',
    'Rasov',
    'Raskovice',
    'Rapsach',
    'Rapotin',
    'Rakvice',
    'Rakovnik',
    'Rakovice',
    'Rajnochovice',
    'Rajhradice',
    'Rajhrad',
    'Rajec-Jestrebi',
    'Radostin',
    'Radonice',
    'Radomysl',
    'Radimer',
    'Radim',
    'Radesinska Svratka',
    'Pysely',
    'Pustejov',
    'Pusta Polom',
    'Puchlovice',
    'Psary',
    'Pruhonice',
    'Prstice',
    'Provodovice',
    'Provodin',
    'Protivin',
    'Protivanov',
    'Prostredni Becva',
    'Prostějov',
    'Prosimerice',
    'Prosetin',
    'Prosec',
    'Probulov',
    'Probostov',
    'Probluz',
    'Prlov',
    'Pritluky',
    'Prisnotice',
    'Primda',
    'Prikazy',
    'Pribyslav',
    'Příbram',
    'Pribor',
    'Prezletice',
    'Prestice',
    'Prerov nad Labem',
    'Přerov',
    'Prepychy',
    'Premyslovice',
    'Prelouc',
    'Predmerice nad Labem',
    'Predmerice nad Jizerou',
    'Predklasteri',
    'Predhradi',
    'Predboj',
    'Prazmo',
    'Praksice',
    'Prague',
    'Prachovice',
    'Prachatice',
    'Pozorice',
    'Pozlovice',
    'Povrly',
    'Potstejn',
    'Potstat',
    'Potehy',
    'Postrelmov',
    'Postrekov',
    'Postoloprty',
    'Pornice',
    'Porici nad Sazavou',
    'Poricany',
    'Popuvky',
    'Popovice',
    'Polubny',
    'Polnicka',
    'Polna',
    'Policka',
    'Polichno',
    'Police nad Metuji',
    'Polevsko',
    'Polesovice',
    'Pohorelice',
    'Podomi',
    'Podoli',
    'Podivin',
    'Podebrady',
    'Podborany',
    'Pocatky',
    'Pobezovice',
    'Pnovice',
    'Pilsen',
    'Plumlov',
    'Plesna',
    'Plavy',
    'Plavec',
    'Platenice',
    'Plasy',
    'Planice',
    'Planany',
    'Plana nad Luznici',
    'Plana',
    'Pisty',
    'Pistovice',
    'Pisek',
    'Písek',
    'Pisecna',
    'Pilnikov',
    'Petrvald',
    'Petrovice u Karvine',
    'Petrovice',
    'Peruc',
    'Perstejn',
    'Perna',
    'Pelhrimov',
    'Pec pod Snezkou',
    'Pecky',
    'Pecka',
    'Pchery',
    'Pavlovice u Prerova',
    'Pavlovice',
    'Pavlikov',
    'Pasohlavky',
    'Paskov',
    'Pardubicky',
    'Pardubice',
    'Panensky Tynec',
    'Panenske Brezany',
    'Palkovice',
    'Pacov',
    'Oudolen',
    'Otrokovice',
    'Otovice',
    'Otnice',
    'Otice',
    'Otaslavice',
    'Osvracin',
    'Osvetimany',
    'Ostruzna',
    'Ostrozska Nova Ves',
    'Ostrozska Lhota',
    'Ostrov u Macochy',
    'Ostrov nad Oslavou',
    'Ostrovec',
    'Ostrov',
    'Ostromer',
    'Ostravice',
    'Ostrava',
    'Ostopovice',
    'Osova Bityska',
    'Osoblaha',
    'Oslavany',
    'Oskava',
    'Osik',
    'Osice',
    'Osek nad Becvou',
    'Osek',
    'Orlova',
    'Orechov',
    'Orech',
    'Oprechtice',
    'Opocno',
    'Opava',
    'Opatovice nad Labem',
    'Opatovice',
    'Opatov',
    'Oparany',
    'Ondrejov',
    'Olsany',
    'Olovnice',
    'Olovi',
    'Olomucany',
    'Olomouc',
    'Olesnice',
    'Olbramovice',
    'Olbramkostel',
    'Okrouhlo',
    'Okrisky',
    'Okna',
    'Ohrobec',
    'Odry',
    'Odolena Voda',
    'Ocmanice',
    'Ochoz',
    'Obristvi',
    'Nyrsko',
    'Nyrany',
    'Nymburk',
    'Nydek',
    'Nupaky',
    'Nucice',
    'Novy Malin',
    'Novy Liskovec',
    'Novy Knin',
    'Novy Jicin',
    'Novy Jachymov',
    'Novy Hrozenkov',
    'Novy Hradek',
    'Novy Bydzov',
    'Novy Bor',
    'Novosedly',
    'Novosedlice',
    'Nove Veseli',
    'Nove Syrovice',
    'Nove Straseci',
    'Nove Sedlo',
    'Nove Sady',
    'Nove Mesto pod Smrkem',
    'Nove Mesto na Morave',
    'Nove Mesto nad Metuji',
    'Nove Mesto',
    'Nove Hrady',
    'Nove Dvory',
    'Nova Ves pod Plesi',
    'Nova Ves',
    'Nova Vcelnice',
    'Nova Role',
    'Nova Paka',
    'Nova Cerekev',
    'Nova Bystrice',
    'Nosislav',
    'Nizkov',
    'Nizbor',
    'Nivnice',
    'Nikolcice',
    'Nezvestice',
    'Nezdenice',
    'Nezamyslice',
    'Neveklov',
    'Netvorice',
    'Netolice',
    'Nesovice',
    'Neratovice',
    'Nepomuk',
    'Neplachovice',
    'Nenkovice',
    'Nemojov',
    'Nemcicky',
    'Nemcice nad Hanou',
    'Nemcice',
    'Nemanice',
    'Nelahozeves',
    'Nekor',
    'Nejdek',
    'Nehvizdy',
    'Nedvedice',
    'Nedachlebice',
    'Necin',
    'Nechanice',
    'Nebuzely',
    'Navsi u Jablunkova',
    'Nasedlovice',
    'Napajedla',
    'Namest nad Oslavou',
    'Nalzovice',
    'Naklo',
    'Nachod',
    'Myto',
    'Myslocovice',
    'Myslkovice',
    'Mysliborice',
    'Mutenice',
    'Mukarov',
    'Mseno',
    'Msene',
    'Mratin',
    'Mrakov',
    'Mrakotin',
    'Moutnice',
    'Mosty u Jablunkova',
    'Mostkovice',
    'Most',
    'Mosnov',
    'Morkuvky',
    'Morkovice',
    'Morkov',
    'Morina',
    'Moravsky Zizkov',
    'Moravsky Pisek',
    'Moravsky Krumlov',
    'Moravsky Beroun',
    'Moravske Budejovice',
    'Moravska Trebova',
    'Moravska Nova Ves',
    'Moravka',
    'Moravicany',
    'Moravany',
    'Mohelno',
    'Mohelnice',
    'Modrice',
    'Modletice',
    'Mnisek pod Brdy',
    'Mnichovo Hradiště',
    'Mnichovice',
    'Mnichova',
    'Mnich',
    'Mladec',
    'Mlade Buky',
    'Mladcova',
    'Mlada Vozice',
    'Mladá Boleslav',
    'Mitrov',
    'Mirov',
    'Mirotice',
    'Mirosovice',
    'Mirosov',
    'Miroslav',
    'Mimon',
    'Milovice',
    'Milotice',
    'Milin',
    'Milicin',
    'Milevsko',
    'Miletin',
    'Mikulovice',
    'Mikulov',
    'Mikulcice',
    'Mikulasovice',
    'Mikolajice',
    'Mezoun',
    'Mezno',
    'Mezimesti',
    'Mezibori',
    'Mesto Touskov',
    'Mesto Libava',
    'Mesto Albrechtice',
    'Mesto',
    'Mestec Kralove',
    'Mesice',
    'Merklovice',
    'Merklin',
    'Merin',
    'Menin',
    'Mencice',
    'Mělník',
    'Melc',
    'Medlov',
    'Medenec',
    'Mecin',
    'Meceriz',
    'Mcely',
    'Martinov',
    'Marsov',
    'Markvartovice',
    'Mariánské Lázně',
    'Manova',
    'Manetin',
    'Maly Bor',
    'Malesovice',
    'Malenice',
    'Malecov',
    'Malá Strana',
    'Mala Skala',
    'Mala Moravka',
    'Mala Chuchle',
    'Majetin',
    'Mackovice',
    'Machov',
    'Lysice',
    'Lysa nad Labem',
    'Luzna',
    'Luzice',
    'Luzec nad Vltavou',
    'Luze',
    'Luzany',
    'Lutin',
    'Lustenice',
    'Luka nad Jihlavou',
    'Luhacovice',
    'Ludvikovice',
    'Ludvikov',
    'Ludgerovice',
    'Lucany nad Nisou',
    'Luby',
    'Lubna',
    'Lubenec',
    'Lovosice',
    'Lovcice',
    'Louny',
    'Loukov',
    'Louka',
    'Loucovice',
    'Loucna nad Desnou',
    'Loucna',
    'Loucen',
    'Lostice',
    'Lom u Mostu',
    'Lomnice nad Popelkou',
    'Lomnice nad Luznici',
    'Loket',
    'Lodenice',
    'Lochovice',
    'Locenice',
    'Litvinov',
    'Litultovice',
    'Litovel',
    'Litomyšl',
    'Litoměřice',
    'Litencice',
    'Liten',
    'Lisov',
    'Lisen',
    'Lipuvka',
    'Liptal',
    'Lipov',
    'Lipoltice',
    'Lipno nad Vltavou',
    'Lipnik nad Becvou',
    'Lipnice nad Sazavou',
    'Lipa',
    'Line',
    'Liderovice',
    'Lichnov (o. Novy Jicin)',
    'Lichkov',
    'Libusin',
    'Libstat',
    'Libouchec',
    'Libos',
    'Libochovicky',
    'Libochovice',
    'Libocany',
    'Libna',
    'Libis',
    'Libice nad Cidlinou',
    'Libhost',
    'Libeznice',
    'Liberec',
    'Libechov',
    'Libcice nad Vltavou',
    'Libcany',
    'Lhota pod Libcany',
    'Lhota',
    'Lhenice',
    'Lety',
    'Letovice',
    'Letonice',
    'Letohrad',
    'Lestkov',
    'Lesna',
    'Leskovec nad Moravici',
    'Lenora',
    'Lenesice',
    'Lelekovice',
    'Ledvice',
    'Lednice',
    'Ledenice',
    'Ledec nad Sazavou',
    'Ledcice',
    'Ledce',
    'Lazne Kynzvart',
    'Lazne Belohrad',
    'Lazany',
    'Lastany',
    'Lanzhot',
    'Lany',
    'Lanskroun',
    'Lahost',
    'Kytin',
    'Kysice',
    'Kyselka',
    'Kynsperk nad Ohri',
    'Kyjov',
    'Kvicovice',
    'Kvetnice',
    'Kvasiny',
    'Kvasice',
    'Kutná Hora',
    'Kurim',
    'Kunstat',
    'Kunratice',
    'Kunovice',
    'Kunin',
    'Kunice',
    'Kuncina',
    'Kuncice pod Ondrejnikem',
    'Kuncice',
    'Kudlovice',
    'Ktis',
    'Kryry',
    'Krusovice',
    'Krupka',
    'Krupa',
    'Krumvir',
    'Krtiny',
    'Krovi',
    'Krouna',
    'Kropacova Vrutice',
    'Krompach',
    'Kroměříž',
    'Krnov',
    'Krmelin',
    'Krizova',
    'Krizany',
    'Krizanov',
    'Krivoklat',
    'Krinec',
    'Kretin',
    'Kresetice',
    'Kremze',
    'Krelov',
    'Krchleby',
    'Kravsko',
    'Kravare',
    'Kratonohy',
    'Kratochvilka',
    'Krasny Dvur',
    'Krasne',
    'Krasna Lipa',
    'Kraslice',
    'Kraluv Dvur',
    'Kralupy nad Vltavou',
    'Kralovice',
    'Kraliky',
    'Kralice na Hane',
    'Krakovany',
    'Krabcice',
    'Kozlovice',
    'Kozarovice',
    'Kovarska',
    'Kout na Sumave',
    'Kourim',
    'Kounice',
    'Kostomlaty pod Milesovkou',
    'Kostomlatky',
    'Kostice',
    'Kostelni Hlavno',
    'Kostelec u Holesova',
    'Kostelec na Hane',
    'Kostelec nad Vltavou',
    'Kostelec nad Orlici',
    'Kostelec nad Labem',
    'Kostelec nad Cernymi Lesy',
    'Kostelany',
    'Kostany',
    'Kostalov',
    'Kosmonosy',
    'Kosice',
    'Korycany',
    'Korouhev',
    'Korenec',
    'Koprivnice',
    'Kopidlno',
    'Konstantinovy Lazne',
    'Konice',
    'Konarovice',
    'Komorni Lhotka',
    'Komarov',
    'Kolsov',
    'Kolovec',
    'Kolín',
    'Kokory',
    'Kokorin',
    'Kojetin',
    'Kojetice',
    'Kobylnice',
    'Kobyli',
    'Koberice',
    'Kninicky',
    'Knezmost',
    'Knezeves',
    'Kly',
    'Kluky',
    'Klopotovice',
    'Klokoty',
    'Klobuky',
    'Klobouky',
    'Klipec',
    'Klimkovice',
    'Klicany',
    'Klenovice na Hane',
    'Klecany',
    'Klatovy',
    'Klasterec nad Orlici',
    'Klasterec nad Ohri',
    'Klanovice',
    'Kladruby',
    'Kladno',
    'Kladky',
    'Kelc',
    'Kdyne',
    'Kaznejov',
    'Katerinice',
    'Kasperske Hory',
    'Kasejovice',
    'Kasava',
    'Karvina',
    'Karolinka',
    'Karlovy Vary',
    'Kardasova Recice',
    'Karany',
    'Karanice',
    'Kaplice',
    'Kamenny Ujezd',
    'Kamenne Zehrovice',
    'Kamenicky Senov',
    'Kamenice nad Lipou',
    'Kamenice',
    'Kamen',
    'Kajov',
    'Kadan',
    'Kacov',
    'Kacice',
    'Josefov',
    'Jistebnik',
    'Jirny',
    'Jirkov',
    'Jirikov',
    'Jiretin pod Jedlovou',
    'Jinonice',
    'Jinocany',
    'Jindrichuv Hradec',
    'Jindrichov',
    'Jince',
    'Jimramov',
    'Jiloviste',
    'Jilove u Prahy',
    'Jilove',
    'Jilesovice',
    'Jilemnice',
    'Jikev',
    'Jihlava',
    'Jičín',
    'Jezve',
    'Jevisovka',
    'Jevisovice',
    'Jevineves',
    'Jevicko',
    'Jevany',
    'Jestrebi',
    'Jesenik nad Odrou',
    'Jesenik',
    'Jesenice',
    'Jerisno',
    'Jenikovice',
    'Jenec',
    'Jemnice',
    'Jedovnice',
    'Jedlova',
    'Jazlovice',
    'Javornik',
    'Jarov',
    'Jaroslavice',
    'Jaromerice nad Rokytnou',
    'Jaromerice',
    'Jaromer',
    'Janov nad Nisou',
    'Janovice nad Uhlavou',
    'Janovice',
    'Janova',
    'Janov',
    'Jamne nad Orlici',
    'Jalubi',
    'Jakartovice',
    'Jachymov',
    'Jablunkov',
    'Jablunka',
    'Jablonne v Podjestedi',
    'Jablonne nad Orlici',
    'Jablonec nad Nisou',
    'Jablonec nad Jizerou',
    'Jablonce',
    'Jabkenice',
    'Ivanovice na Hane',
    'Ivancice',
    'Hyskov',
    'Hylov',
    'Hvozdna',
    'Hvozd',
    'Hutisko',
    'Hustopece Nad Becvou',
    'Hustopece',
    'Husinec',
    'Humpolec',
    'Hulin',
    'Hukvaldy',
    'Hudcov',
    'Hrusovany u Brna',
    'Hrusovany nad Jevisovkou',
    'Hrusova',
    'Hrotovice',
    'Hronov',
    'Hrochuv Tynec',
    'Hrobcice',
    'Hrobce',
    'Hrob',
    'Hredle',
    'Hrdejovice',
    'Hranice',
    'Hradek nad Nisou',
    'Hradek',
    'Hradec Králové',
    'Hradec',
    'Hradcany',
    'Hrabyne',
    'Hrabisin',
    'Hovorcovice',
    'Hovorany',
    'Hovezi',
    'Hostoun',
    'Hostomice',
    'Hostka',
    'Hostivice',
    'Hostinne',
    'Hosteradky-Resov',
    'Hospozin',
    'Horsovsky Tyn',
    'Horovice',
    'Horomerice',
    'Horni Terlicko',
    'Horni Sucha',
    'Horni Sloupnice',
    'Horni Slavkov',
    'Horni Redice',
    'Horni Poustevna',
    'Horni Pocaply',
    'Horni Plana',
    'Horni Pena',
    'Horni Mostenice',
    'Horni Mesto',
    'Horni Lidec',
    'Horni Libchava',
    'Horni Lhota',
    'Horni Jiretin',
    'Horni Jeleni',
    'Horni Hbity',
    'Horni Datyne',
    'Horni Cermna',
    'Horni Cerekev',
    'Horni Brusnice',
    'Horni Briza',
    'Horni Bludovice',
    'Horni Berkovice',
    'Horni Benesov',
    'Horni Becva',
    'Horicky',
    'Horice na Sumave',
    'Horice',
    'Horesovice',
    'Horepnik',
    'Horazgovice',
    'Hora Svate Kateriny',
    'Horakov',
    'Hora',
    'Holysov',
    'Holubice',
    'Holoubkov',
    'Holohlavy',
    'Holice',
    'Holešov',
    'Holedec',
    'Hole',
    'Holasice',
    'Hodslavice',
    'Hodov',
    'Hodonin',
    'Hodonín',
    'Hodonice',
    'Hodkovicky',
    'Hodkovice nad Mohelkou',
    'Hodice',
    'Hnojnik',
    'Hnevotin',
    'Hnatnice',
    'Hnanice',
    'Hluk',
    'Hlucin',
    'Hlubos',
    'Hluboka nad Vltavou',
    'Hlubocky',
    'Hlubocepy',
    'Hloubetin',
    'Hlohovec',
    'Hlinsko pod Hostynem',
    'Hlinsko',
    'Hlasna Treban',
    'Hladke Zivotice',
    'Hevlin',
    'Hermanuv Mestec',
    'Hermanovice',
    'Heralec',
    'Hejnice',
    'Havran',
    'Havlíčkův Brod',
    'Havlickova Borova',
    'Havířov',
    'Harrachov',
    'Haratice',
    'Hanusovice',
    'Hanovice',
    'Hamr',
    'Halže',
    'Halenkovice',
    'Halenkov',
    'Haj ve Slezsku',
    'Haj u Duchcova',
    'Hajnice',
    'Hajany',
    'Habry',
    'Habartov',
    'Grygov',
    'Golcuv Jenikov',
    'Fulnek',
    'Frystak',
    'Frysava',
    'Frydlant nad Ostravici',
    'Frydlant',
    'Frýdek-Místek',
    'Frycovice',
    'Frenstat pod Radhostem',
    'Frantiskovy Lazne',
    'Francova Lhota',
    'Ejpovice',
    'Dysina',
    'Dymokury',
    'Dyjakovice',
    'Dvorce',
    'Duchcov',
    'Dubne',
    'Dubnany',
    'Dub nad Moravou',
    'Dubicko',
    'Dubi',
    'Duba',
    'Drzkov',
    'Druzec',
    'Drnovice',
    'Drmoul',
    'Driten',
    'Drisy',
    'Drevohostice',
    'Drazov',
    'Drasov',
    'Drahotuse',
    'Drahany',
    'Drahanovice',
    'Doudleby nad Orlici',
    'Doubravnik',
    'Doubravice nad Svitavou',
    'Doubrava',
    'Domazlice',
    'Domasov u Sternberka',
    'Domasov nad Bystrici',
    'Domanin',
    'Dolni Zivotice',
    'Dolni Ujezd',
    'Dolni Trebonin',
    'Dolni Studenky',
    'Dolni Slivno',
    'Dolni Rozinka',
    'Dolni Roven',
    'Dolni Poustevna',
    'Dolni Nemci',
    'Dolni Mecholupy',
    'Dolni Lutyne',
    'Dolni Loucky',
    'Dolni Lhota',
    'Dolni Kounice',
    'Dolni Hermanice',
    'Dolni Hbity',
    'Dolni Dunajovice',
    'Dolni Domaslavice',
    'Dolni Dobrouc',
    'Dolni Cermna',
    'Dolni Cerekev',
    'Dolni Bukovsko',
    'Dolni Brezany',
    'Dolni Branna',
    'Dolni Bousov',
    'Dolni Bojanovice',
    'Dolni Berkovice',
    'Dolni Benesov',
    'Doksy',
    'Doksany',
    'Dohalice',
    'Dobruska',
    'Dobroviz',
    'Dobrovice',
    'Dobroslavice',
    'Dobronin',
    'Dobromerice',
    'Dobriv',
    'Dobris',
    'Dobrin',
    'Dobrichovice',
    'Dobrenice',
    'Dobrejovice',
    'Dobratice',
    'Dobrany',
    'Dobra',
    'Dnesice',
    'Dlouha Ves',
    'Dlouha Trebova',
    'Dlouha Loucka',
    'Dlouha Brtnice',
    'Divisov',
    'Divcice',
    'Divaky',
    'Detmarovice',
    'Detenice',
    'Destne',
    'Desna',
    'Dehylov',
    'Děčín',
    'Deblin',
    'Davle',
    'Dasice',
    'Dankovice',
    'Damnikov',
    'Damborice',
    'Dalovice',
    'Dalesice',
    'Dalecin',
    'Dacice',
    'Cvrcovice',
    'Cvikov',
    'Ctyrkoly',
    'Crhov',
    'Ckyne',
    'Cizov',
    'Cizkovice',
    'Citov',
    'Citonice',
    'Citoliby',
    'Cista',
    'Cisovice',
    'Cimelice',
    'Chynov',
    'Chvateruby',
    'Chvalsiny',
    'Chvalnov',
    'Chvalkovice',
    'Chvaletice',
    'Chvalcov',
    'Chuderov',
    'Chudenin',
    'Chrudim',
    'Chroustovice',
    'Chropyne',
    'Chromec',
    'Chrastava',
    'Chrastany',
    'Chrast',
    'Choustnikovo Hradiste',
    'Chotycany',
    'Chotoviny',
    'Chotikov',
    'Chotesov',
    'Chotenice',
    'Chotebor',
    'Chornice',
    'Chomutov',
    'Cholina',
    'Chodova Plana',
    'Chodov',
    'Chodouny',
    'Chocerady',
    'Chocen',
    'Chlum u Trebone',
    'Chlumin',
    'Chlumec nad Cidlinou',
    'Chlumec',
    'Chlumcany',
    'Chleny',
    'Cheb',
    'Chbany',
    'Chanovice',
    'Chabarovice',
    'Cetkovice',
    'Cestlice',
    'Český Těšín',
    'Český Krumlov',
    'Cesky Dub',
    'Cesky Brod',
    'Ceske Velenice',
    'Ceske Mezirici',
    'Ceske Libchavy',
    'České Budějovice',
    'Ceska Ves',
    'Ceska Trebova',
    'Ceska Skalice',
    'Česká Lípa',
    'Ceska Kamenice',
    'Ceska Bela',
    'Ceska',
    'Cerveny Ujezd',
    'Cerveny Kostelec',
    'Cervenka',
    'Cervene Pecky',
    'Cervena Voda',
    'Cervena Recice',
    'Cernuc',
    'Cernozice',
    'Cernovice',
    'Cernosice',
    'Cernilov',
    'Cerncice',
    'Cerna v Posumavi',
    'Cerna Hora',
    'Cermna',
    'Cerhovice',
    'Cerhenice',
    'Cerekvice nad Loucnou',
    'Cercany',
    'Celakovice',
    'Celadna',
    'Cejkovice',
    'Cejc',
    'Cechy pod Kosirem',
    'Cechtice',
    'Castrov',
    'Castolovice',
    'Caslavice',
    'Caslav',
    'Cachovice',
    'Cachnov',
    'Bzenec',
    'Bystricka',
    'Bystrice pod Hostynem',
    'Bystrice nad Pernstejnem',
    'Bystrice',
    'Bystrec',
    'Bystre',
    'Bystrany',
    'Byst',
    'Bysice',
    'Bylnice',
    'Bykev',
    'Butoves',
    'Bustehrad',
    'Bukovno',
    'Bukovka',
    'Bukovinka',
    'Bukovec',
    'Bukovany',
    'Bukov',
    'Bujanov',
    'Budyne nad Ohri',
    'Budisov nad Budisovkou',
    'Budisovice',
    'Budisov',
    'Budin',
    'Bucovice',
    'Buchlovice',
    'Brzice',
    'Brusperk',
    'Bruntal',
    'Brumovice',
    'Brumov',
    'Brozany',
    'Broumy',
    'Broumov',
    'Brodek u Prerova',
    'Brodek u Konice',
    'Brodek',
    'Brodce',
    'Brod',
    'Brno',
    'Brniste',
    'Brnenec',
    'Brna',
    'Bridlicna',
    'Brezuvky',
    'Brezova nad Svitavou',
    'Brezova',
    'Brezolupy',
    'Brezno',
    'Breznice',
    'Brezina',
    'Brezce',
    'Brezany',
    'Brestek',
    'Brest',
    'Břeclav',
    'Bratronice',
    'Brasy',
    'Braskov',
    'Brantice',
    'Brankovice',
    'Braniskov',
    'Brandys nad Orlici',
    'Brandys nad Labem-Stara Boleslav',
    'Brandysek',
    'Branany',
    'Boztesice',
    'Bozice',
    'Bozicany',
    'Bouzov',
    'Bosyne',
    'Boskovice',
    'Borsov',
    'Borsice',
    'Borovany',
    'Borova Lada',
    'Borova',
    'Borohradek',
    'Boritov',
    'Borek',
    'Bor',
    'Bolebor',
    'Bolatice',
    'Bojkovice',
    'Bohutin',
    'Bohusovice nad Ohri',
    'Bohuslavice',
    'Bohunovice',
    'Bohumin',
    'Bohosudov',
    'Bohdalov',
    'Bohdalice',
    'Bohdalec',
    'Bochov',
    'Bochor',
    'Bobnice',
    'Bludov',
    'Blucina',
    'Blovice',
    'Blizejov',
    'Blazovice',
    'Blatno',
    'Blatnice',
    'Blatna',
    'Blansko',
    'Bitovany',
    'Bily Potok',
    'Bilovice nad Svitavou',
    'Bilovec',
    'Bilkovice',
    'Bilka',
    'Bilina',
    'Bila Lhota',
    'Bila',
    'Bezno',
    'Bezdruzice',
    'Bestovice',
    'Besiny',
    'Berunice',
    'Beroun',
    'Benov',
    'Benesov nad Ploucnici',
    'Benešov',
    'Benecko',
    'Benatky nad Jizerou',
    'Belotin',
    'Belcice',
    'Bela pod Bezdezem',
    'Seibersdorf',
    'Bedrichov',
    'Becvary',
    'Becov nad Teplou',
    'Becov',
    'Bechyne',
    'Bechlin',
    'Bdeneves',
    'Bavory',
    'Bavorov',
    'Batelov',
    'Bast',
    'Baska',
    'Banov',
    'Bakov nad Jizerou',
    'Babice nad Svitavou',
    'Babice',
    'As',
    'Archlebov',
    'Albrechtice nad Vltavou',
    'Albrechtice nad Orlici',
    'Albrechtice',
    'Adamov',
    'Abertamy',
    'Lazne Bohdanec',
    'Cerny Most',
    'Suchdol nad Odrou',
    'Brumov-Bylnice',
    'Kostomlaty nad Labem',
    'Nova Ves nad Nisou',
  ],
  'San Marino': [
    'Serravalle',
    'San Marino',
    'Poggio Chiesanuova',
    'Montegiardino',
    'Fiorentino',
    'Falciano',
    'Faetano',
    'Domagnano',
    'Dogana',
    'Borgo Maggiore',
    'Acquaviva',
    'Gualdicciolo',
    'Cerbaiola',
  ],
  Croatia: [
    'Vranjic',
    'Županja',
    'Zrnovnica',
    'Zminj',
    'Zlobin',
    'Sveti Ivan Zelina',
    'Brodski Zdenci',
    'Zdenci Brdovecki',
    'Zaton',
    'Zaprešić',
    'Zagvozd',
    'Zagreb',
    'Zadar',
    'Zabok',
    'Sveti Ivan Zabno',
    'Vukovina',
    'Vukovar',
    'Vuka',
    'Vrsar',
    'Vrlika',
    'Vrgorac',
    'Gvozd',
    'Vrbovsko',
    'Vrbovec',
    'Vrbanja',
    'Vojnic',
    'Vodnjan',
    'Vodinci',
    'Vodice',
    'Vocin',
    'Visoko',
    'Visnjevac',
    'Viskovci',
    'Vis',
    'Virovitica',
    'Virje',
    'Vir',
    'Vinkovci',
    'Vinica',
    'Viljevo',
    'Vidrenjak',
    'Veli Losinj',
    'Veliko Vukovje',
    'Veliko Trgovisce',
    'Veliki Zdenci',
    'Veliki Grgevac',
    'Velika Pisanica',
    'Velika Ludina',
    'Velika Gorica',
    'Velika',
    'Vela Luka',
    'Varazdinske Toplice',
    'Varaždin',
    'Valpovo',
    'Umag',
    'Tuhelj',
    'Tucepi',
    'Trpinja',
    'Trpanj',
    'Trogir',
    'Trnovec Bartolovecki',
    'Trilj',
    'Tribunj',
    'Tribalj',
    'Trebovec',
    'Tovarnik',
    'Topusko',
    'Tisno',
    'Tenja',
    'Tar',
    'Svetvincenat',
    'Sveti Matej',
    'Sveti Martin na Muri',
    'Sveti Kriz',
    'Sveti Ilija',
    'Sveta Nedjelja',
    'Sveta Marija',
    'Suza',
    'Sutivan',
    'Supetarska Draga',
    'Supetar',
    'Sunja',
    'Sumartin',
    'Sukosan',
    'Suhopolje',
    'Sudovec',
    'Sucuraj',
    'Subotica Podravska',
    'Strmec Podravski',
    'Strmec Samoborski',
    'Strizivojna',
    'Strigova',
    'Strahoninec',
    'Ston',
    'Stobrec',
    'Stitar',
    'Staro Petrovo Selo',
    'Stari Slatnik',
    'Stari Mikanovci',
    'Stara Gradiska',
    'Stankovci',
    'Sracinec',
    'Split',
    'Solin',
    'Slunj',
    'Slavonski Kobas',
    'Slavonski Brod',
    'Požega',
    'Slano',
    'Skrljevo',
    'Skradin',
    'Sisak',
    'Siroko Polje',
    'Sirac',
    'Sinj',
    'Silo',
    'Sikirevci',
    'Sibinj',
    'Šibenik',
    'Sesvete',
    'Senj',
    'Semeljci',
    'Selnica',
    'Selina',
    'Selci Dakovacki',
    'Selce',
    'Selca kod Bogomolja',
    'Sela',
    'Savudrija',
    'Sarvas',
    'Sandrovac',
    'Samobor',
    'Slavonski Samac',
    'Sali',
    'Saborsko',
    'Runovic',
    'Rovisce',
    'Rovinj',
    'Rokovci',
    'Rogoznica',
    'Rijeka',
    'Repusnica',
    'Razanac',
    'Ravna Gora',
    'Rasinja',
    'Rasa',
    'Rakov Potok',
    'Rakovec',
    'Radoboj',
    'Radikovci',
    'Racisce',
    'Racinovci',
    'Rabac',
    'Rab',
    'Punitovci',
    'Punat',
    'Pula',
    'Pucisca',
    'Privlaka',
    'Primosten',
    'Prijeka',
    'Prigorje Brdovecko',
    'Pridraga',
    'Prelog',
    'Pregrada',
    'Poznanovec',
    'Povlja',
    'Postira',
    'Posedarje',
    'Poreč',
    'Popovaca',
    'Policnik',
    'Pokupsko',
    'Podturen',
    'Podstrana',
    'Slatina',
    'Potpican',
    'Podgajci Posavski',
    'Podgajci Podravski',
    'Podcrkavlje',
    'Podaca',
    'Plomin',
    'Ploce',
    'Ploče',
    'Plitvica',
    'Pleternica',
    'Plaski',
    'Pitomaca',
    'Piskorevci',
    'Pisarovina',
    'Pirovac',
    'Pican',
    'Petrinja',
    'Petrijanec',
    'Peteranec',
    'Pescenica',
    'Peklenica',
    'Pazin',
    'Parcic',
    'Pakrac',
    'Pag',
    'Ozalj',
    'Otok',
    'Otocac',
    'Ostrvica',
    'Osijek',
    'Osekovo',
    'Oroslavje',
    'Orle',
    'Oriovac',
    'Orehovica',
    'Orebic',
    'Orahovica',
    'Opuzen',
    'Opatija',
    'Omisalj',
    'Omiš',
    'Olib',
    'Okucani',
    'Ogulin',
    'Odra Sisacka',
    'Oborovo',
    'Nustar',
    'Novska',
    'Novoselec',
    'Novo Cice',
    'Novi Vinodolski',
    'Novi Marof',
    'Novigrad Podravski',
    'Novigrad',
    'Nova Raca',
    'Novalja',
    'Novakovec',
    'Novaki Kraljevecki',
    'Nova Gradiska',
    'Njivice',
    'Nerezisce',
    'Nerezine',
    'Nedescina',
    'Nedelisce',
    'Našice',
    'Mursko Sredisce',
    'Murter',
    'Mrzovic',
    'Mrkopalj',
    'Mravince',
    'Podravska Moslavina',
    'Moscenicka Draga',
    'Molve',
    'Mokosica',
    'Mlini',
    'Mirkovci',
    'Metković',
    'Medulin',
    'Matulji',
    'Marusevec',
    'Martinscica',
    'Martijanec',
    'Markusevecka Dubrava',
    'Marina',
    'Marija Bistrica',
    'Marcana',
    'Malinska',
    'Mali Lošinj',
    'Mali Erjavec',
    'Mali Bukovec',
    'Mala Subotica',
    'Makarska',
    'Mahicno',
    'Macinec',
    'Mace',
    'Lupoglav',
    'Lumbarda',
    'Lukac',
    'Ludbreg',
    'Lucko',
    'Lozovac',
    'Sveti Lovrec Pazenaticki',
    'Lovran',
    'Lovas',
    'Lopud',
    'Lopatinec',
    'Ljubescica',
    'Liznjan',
    'Lipik',
    'Martinska Ves',
    'Licki Osik',
    'Lic',
    'Lepoglava',
    'Lekenik',
    'Lastovo',
    'Ladimirevci',
    'Labin',
    'Kutjevo',
    'Kutina',
    'Krsan',
    'Krnica',
    'Krk',
    'Krizevci',
    'Kriz',
    'Kravarsko',
    'Krasica',
    'Krasic',
    'Krapinske Toplice',
    'Krapina',
    'Kraljev Vrh',
    'Kraljevica',
    'Sesvetski Kraljevec',
    'Kraljevec na Sutli',
    'Dicmo',
    'Kovacevac',
    'Kotoriba',
    'Kostrena',
    'Hrvatska Kostajnica',
    'Kosovo',
    'Kornic',
    'Korčula',
    'Koprivnica',
    'Koncanica',
    'Komletinci',
    'Komiža',
    'Knin',
    'Knezevo',
    'Knezevi Vinogradi',
    'Klostar Podravski',
    'Klostar Ivanic',
    'Klis',
    'Klanjec',
    'Klana',
    'Kistanje',
    'Kastel Sucurac',
    'Kastel Stari',
    'Kastel Stafilic',
    'Kastel Novi',
    'Kastel Luksic',
    'Kastel Kambelovac',
    'Kastelir',
    'Kastel Gomilica',
    'Kastav',
    'Kaptol',
    'Kapela',
    'Kamesnica',
    'Kamanje',
    'Kalnik',
    'Kali',
    'Jurdani',
    'Josipovac',
    'Jezerane',
    'Jesenice',
    'Jelsa',
    'Jelisavac',
    'Javorek',
    'Jastrebarsko',
    'Jasenovac',
    'Jarmina',
    'Jakovlje',
    'Jadranovo',
    'Ivankovo',
    'Ivanja Reka',
    'Ivanic-Grad',
    'Ivanec Bistranski',
    'Ivanec',
    'Imotski',
    'Ilok',
    'Icici',
    'Hvar',
    'Hum na Sutli',
    'Breznicki Hum',
    'Hrvace',
    'Hruscica',
    'Hreljin',
    'Hercegovac',
    'Harmica',
    'Grubisno Polje',
    'Groznjan',
    'Grohote',
    'Grizane',
    'Gradiste',
    'Gracec',
    'Grabarje',
    'Gospić',
    'Okrug Gornji',
    'Gornji Laduc',
    'Gornji Bogicevci',
    'Gornje Jesenje',
    'Gornja Vrba',
    'Gornja Stubica',
    'Gornja Rijeka',
    'Gornja Lomnica',
    'Gornja Bebrina',
    'Gorican',
    'Glina',
    'Gizdavac',
    'Generalski Stol',
    'Gata',
    'Gat',
    'Garesnica',
    'Gardinovec',
    'Garcin',
    'Galizana',
    'Fuzine',
    'Sv. Filipi i Jakov',
    'Fericanci',
    'Fazana',
    'Erdut',
    'Dvor',
    'Durmanec',
    'GJurgevac',
    'Durdenovac',
    'Dugo Selo',
    'Dugopolje',
    'Dugi Rat',
    'Duga Resa',
    'Dubrovnik',
    'Dubravica',
    'Dubosevica',
    'Drnje',
    'Drniš',
    'Drazice',
    'Draz',
    'Draganici',
    'Dragalic',
    'Zemunik Donji',
    'Donji Seget',
    'Donji Prolozac',
    'Donji Miholjac',
    'Donji Lapac',
    'Donji Kucan',
    'Donji Kraljevec',
    'Donji Kneginec',
    'Petrcane',
    'Donje Novo Selo',
    'Donje Makojisce',
    'Donja Zelina',
    'Donja Zdencina',
    'Donja Vrba',
    'Donja Voca',
    'Donja Stubica',
    'Donja Pusca',
    'Donja Moticina',
    'Donja Lomnica',
    'Donja Dubrava',
    'Brela',
    'Donja Bistra',
    'Domanjkus',
    'Dol',
    'Dobrinj',
    'Diminici',
    'Desinic',
    'Delnice',
    'Dekanovec',
    'Davor',
    'Dautan',
    'Daruvar',
    'Darda',
    'Dalya',
    'Đakovo',
    'Crivac',
    'Crikvenica',
    'Cres',
    'Crecan',
    'Cestica',
    'Cernik',
    'Cerna',
    'Cepin',
    'Ceminac',
    'Čazma',
    'Cavtat',
    'Cavle',
    'Čakovec',
    'Cacinci',
    'Cabuna',
    'Buzin',
    'Buzet',
    'Buje',
    'Budinscina',
    'Budancevica',
    'Brtonigla',
    'Brotnice',
    'Brodski Varos',
    'Brod Moravice',
    'Brnaze',
    'Bribir',
    'Brezje',
    'Bregana',
    'Brdovec',
    'Branjin Vrh',
    'Bosnjaci',
    'Bolc',
    'Bol',
    'Bogdanovci',
    'Bobota',
    'Blagorodovac',
    'Bjelovar',
    'Bjelkovac',
    'Bizovac',
    'Biskupija',
    'Bisko',
    'Biograd na Moru',
    'Bilje',
    'Bijelo Brdo',
    'Bibinje',
    'Bibici',
    'Betina',
    'Beter',
    'Beretinec',
    'Berek',
    'Benkovac',
    'Belišće',
    'Beli Manastir',
    'Belica',
    'Belec',
    'Bektez',
    'Bednja',
    'Bedekovcina',
    'Becic',
    'Bebrina',
    'Batrina',
    'Batina',
    'Bastajski Brdani',
    'Baska Voda',
    'Baska',
    'Barbarici Kravarski',
    'Barban',
    'Bale',
    'Bakovcica',
    'Bakic',
    'Bakar',
    'Baginec',
    'Babina Greda',
    'Apatija',
    'Antunovac',
    'Andrijasevci',
    'Adzamovci',
    'Bestovje',
    'Lipovljani',
    'Brezik Nasicki',
    'Lobor',
    'Kukljica',
    'Milna',
    'Preko',
    'Zlatar bistrica',
    'Gornji Draganec',
    'Nin',
    'Senkovec',
    'Alaginci',
    'Bartolovci',
    'Drakulica Rijeka',
    'Salopeki',
    'Basara',
    'Grad',
    'Kamen',
    'Kamenmost',
    'Blaca',
    'Posavski Bregi',
    'Cervar-Porat',
    'Karlovac',
    'Blatnica Pokupska',
    'Babindub',
    'Dramalj',
    'Sveti Kriz Zacretje',
    'Stubicke Toplice',
    'Viskovo',
    'Brezje Dravsko',
    'Turcin',
    'Palit',
    'Drvenik Gornja vala',
  ],
  'Bosnia and Herzegovina': [
    'Zvornik',
    'Zivinice',
    'Zepce',
    'Zenica',
    'Zeljezno Polje',
    'Zavidovici',
    'Vogosca',
    'Vitez',
    'Visoko',
    'Višegrad',
    'Veljaci',
    'Velika Kladuša',
    'Ugljevik',
    'Tuzla',
    'Trebinje',
    'Travnik',
    'Teslic',
    'Tesanj',
    'Stolac',
    'Srebrenik',
    'Srebrenica',
    'Srbac',
    'Sjenina',
    'Sarajevo',
    'Sanski Most',
    'Rogatica',
    'Novi Travnik',
    'Prozor',
    'Prnjavor',
    'Prijedor',
    'Priboj',
    'Posusje',
    'Pelagicevo',
    'Pazaric',
    'Pale',
    'Olovo',
    'Odzak',
    'Nevesinje',
    'Mrkonjic Grad',
    'Mramor',
    'Mostar',
    'Milici',
    'Međugorje',
    'Matuzici',
    'Maglaj',
    'Lukavac',
    'Ljubuski',
    'Livno',
    'Siroki Brijeg',
    'Laktasi',
    'Kresevo',
    'Krasulje',
    'Kocerin',
    'Kljuc',
    'Kladanj',
    'Kiseljak',
    'Kakanj',
    'Jajce',
    'Ilijas',
    'Ilidza',
    'Han Pijesak',
    'Hadzici',
    'Grude',
    'Gradacac',
    'Gracanica',
    'Gornji Rahic',
    'Goražde',
    'Fojnica',
    'Tomislavgrad',
    'Drvar',
    'Donji Vakuf',
    'Donja Mahala',
    'Doboj',
    'Derventa',
    'Citluk',
    'Cazin',
    'Capljina',
    'Buzim',
    'Busovaca',
    'Bunar',
    'Bugojno',
    'Brod',
    'Breza',
    'Brcko',
    'Bosanski Brod',
    'Bosanska Krupa',
    'Kostajnica',
    'Gradiska',
    'Bijeljina',
    'Bihać',
    'Banovici',
    'Banja Luka',
    'Jelah',
    'Srpska',
    'Celinac',
    'Usora',
    'Stijena',
    'Konjic',
    'Donja Slatina',
    'Jablanica',
  ],
  Slovenia: [
    'Žužemberk',
    'Zirovnica',
    'Ziri',
    'Zimarice',
    'Zgornji Leskovec',
    'Zgornji Duplek',
    'Zgornje Verjane',
    'Zgornje Jezersko',
    'Zgornje Hoce',
    'Zgornje Gorje',
    'Zgornja Senarska',
    'Zgornja Polskava',
    'Loznica',
    'Zgornja Kungota',
    'Zgornja Besnica',
    'Žetale',
    'Zelezniki',
    'Zdole',
    'Zavrstnik',
    'Zavrc',
    'Žalec',
    'Zagradec',
    'Zagorje ob Savi',
    'Zagorje',
    'Zabnica',
    'Vuzenica',
    'Vuhred',
    'Vrtojba',
    'Vrhnika',
    'Vremski Britof',
    'Vransko',
    'Volcja Draga',
    'Vojnik',
    'Vodice',
    'Vitomarci',
    'Vitanje',
    'Visnja Gora',
    'Vipava',
    'Verzej',
    'Velka',
    'Veliki Gaber',
    'Velike Lašče',
    'Velika Polana',
    'Velika Nedelja',
    'Vas',
    'Vace',
    'Ursna Sela',
    'Turnisce',
    'Turjak',
    'Trzin',
    'Trzic',
    'Trojane',
    'Trnovska Vas',
    'Trebnje',
    'Trebese',
    'Trebelno',
    'Trbovlje',
    'Trbonje',
    'Topolsica',
    'Sveti Tomaz',
    'Tolmin',
    'Velenje',
    'Tisina',
    'Teharje',
    'Tabor',
    'Sv. Ana v Slov. Goricah',
    'Studenec',
    'Štore',
    'Stopce',
    'Stojnci',
    'Starse',
    'Stari Trg pri Lozu',
    'Stari Trg ob Kolpi',
    'Stara Cerkev',
    'Stanjel',
    'Stahovica',
    'Sredisce ob Dravi',
    'Spodnji Ivanjci',
    'Spodnji Duplek',
    'Spodnja Idrija',
    'Hajdina',
    'Spodnja Besnica',
    'Sovodenj',
    'Sostanj',
    'Solcava',
    'Sodrazica',
    'Soca',
    'Smlednik',
    'Smartno v Rozni Dolini',
    'Smartno pri Slovenj Gradcu',
    'Smartno pri Litiji',
    'Smartno ob Paki',
    'Smartno na Pohorju',
    'Smarjeta pri Celju',
    'Smarje pri Jelsah',
    'Smarje-Sap',
    'Slovenske Konjice',
    'Slovenska Bistrica',
    'Slovenj Gradec',
    'Slap ob Idrijci',
    'Sladki Vrh',
    'Skofljica',
    'Skofja Vas',
    'Škofja Loka',
    'Zgornje Skofije',
    'Skocjan',
    'Sveta Trojica',
    'Sežana',
    'Sevnica',
    'Serdica',
    'Sentvid pri Sticni',
    'Sentrupert',
    'Šentjur pri Celju',
    'Šentjernej',
    'Sentjanz',
    'Šentilj',
    'Senozece',
    'Senovo',
    'Sencur',
    'Sempeter v Savinj. Dolini',
    'Sempeter pri Gorici',
    'Sempas',
    'Semic',
    'Selca',
    'Secovlje',
    'Sava',
    'Salovci',
    'Ruše',
    'Rucmanci',
    'Rovte',
    'Rogoza',
    'Rogatec nad Zelimljami',
    'Rogatec',
    'Rogasovci',
    'Rogaška Slatina',
    'Rocinj',
    'Rob',
    'Rimske Toplice',
    'Ribnica na Pohorju',
    'Ribnica',
    'Rence',
    'Recica ob Savinji',
    'Ravne na Koroskem',
    'Ratece',
    'Rakek',
    'Raka',
    'Raduha',
    'Radovljica',
    'Radovci',
    'Radomlje',
    'Radlje ob Dravi',
    'Radenci',
    'Radeče',
    'Race',
    'Puconci',
    'Ptujska Gora',
    'Ptuj',
    'Prosenjakovci',
    'Pristova',
    'Pristava pri Mestinju',
    'Prevalje',
    'Prestranek',
    'Prem',
    'Prelesje',
    'Prelasko',
    'Preddvor',
    'Prebold',
    'Prebacevo',
    'Pragersko',
    'Postojna',
    'Portorož',
    'Ponikva',
    'Polzela',
    'Polsnik',
    'Poljcane',
    'Poljane nad Skofjo Loko',
    'Polhov Gradec',
    'Polensak',
    'Podvelka',
    'Podpreska',
    'Podnart',
    'Podlehnik',
    'Podkum',
    'Podgrad',
    'Podgorci',
    'Podčetrtek',
    'Podbocje',
    'Planina pri Sevnici',
    'Planina',
    'Pivka',
    'Pisece',
    'Piran',
    'Petrovce',
    'Pesnica pri Mariboru',
    'Pernica',
    'Ozbalt',
    'Otocec',
    'Osilnica',
    'Ormoz',
    'Oplotnica',
    'Odranci',
    'Novo Mesto',
    'Nova Vas',
    'Nova Gorica',
    'Notranje Gorice',
    'Naklo',
    'Muta',
    'Murska Sobota',
    'Mozirje',
    'Most na Soci',
    'Moravske Toplice',
    'Moravce pri Gabrovki',
    'Moravce',
    'Mokronog',
    'Mojstrana',
    'Mislinja',
    'Mirna Peč',
    'Mirna',
    'Miren',
    'Miklavz pri Ormozu',
    'Miklavz na Dravskem Polju',
    'Mezica',
    'Metlika',
    'Menges',
    'Medvode',
    'Mavcice',
    'Materija',
    'Martjanci',
    'Markovci',
    'Marjeta na Dravskem Polju',
    'Maribor',
    'Marezige',
    'Makole',
    'Majšperk',
    'Lukovica',
    'Luce',
    'Lovrenc na Pohorju',
    'Lovrenc na Dravskem Polju',
    'Loski Potok',
    'Lokev',
    'Log pri Brezovici',
    'Loce',
    'Ljutomer',
    'Ljubno ob Savinji',
    'Ljubno',
    'Ljubljana',
    'Ljubecna',
    'Litija',
    'Limbus',
    'Libelice',
    'Leskovec pri Krskem',
    'Lesicno',
    'Lesce',
    'Lendava',
    'Lenart v Slov. Goricah',
    'Laze v Tuhinju',
    'Laško',
    'Laporje',
    'Kuzma',
    'Krtina',
    'Krško',
    'Krska Vas',
    'Kropa',
    'Krmelj',
    'Krizevci pri Ljutomeru',
    'Krizevci',
    'Krize',
    'Kresnice',
    'Kranjska Gora',
    'Kranj',
    'Kozje',
    'Kozina',
    'Kotlje',
    'Kostanjevica na Krki',
    'Koper',
    'Komen',
    'Kokarje',
    'Kojsko',
    'Kočevje',
    'Kobilje',
    'Kobarid',
    'Knezak',
    'Kidricevo',
    'Kapele',
    'Kanal',
    'Kamnik',
    'Kamnica',
    'Kamna Gorica',
    'Kal nad Kanalom',
    'Juršinci',
    'Jurovski Dol',
    'Sveti Jurij ob Scavnici',
    'Jezero',
    'Jesenice',
    'Jelsane',
    'Jastrebci',
    'Izola',
    'Izlake',
    'Ivanjkovci',
    'Ivančna Gorica',
    'Ilirska Bistrica',
    'Ig',
    'Idrija pri Baci',
    'Idrija',
    'Hrusica',
    'Hrusevje',
    'Hrib-Loski Potok',
    'Hrastnik',
    'Horjul',
    'Homec',
    'Hodoš',
    'Hlaponci',
    'Hinje',
    'Grosuplje',
    'Grobelno',
    'Grize',
    'Grgar',
    'Grahovo ob Baci',
    'Grahovo',
    'Gradac',
    'Grad',
    'Gozd Martuljek',
    'Gornji Petrovci',
    'Gornji Grad',
    'Gornja Radgona',
    'Gorisnica',
    'Gorenja Vas pri Smarjeti',
    'Gorenja Vas',
    'Straza',
    'Gomilsko',
    'Golnik',
    'Godovic',
    'Gocova',
    'Globoko',
    'Gabrovka',
    'Frankolovo',
    'Fram',
    'Dutovlje',
    'Duplje',
    'Dravograd',
    'Dramlje',
    'Dragotinci',
    'Dragatus',
    'Dornberk',
    'Dornava',
    'Domžale',
    'Domanjsevci',
    'Dol pri Ljubljani',
    'Dol pri Hrastniku',
    'Dole pri Litiji',
    'Dolenjske Toplice',
    'Dolenja Vas',
    'Dolenci',
    'Dobrunje',
    'Dobrovo',
    'Dobrovnik',
    'Dobrova',
    'Dobrnic',
    'Dobrna',
    'Dobravlje',
    'Dobova',
    'Dobje pri Planini',
    'Dob',
    'Divača',
    'Destrnik',
    'Deskle',
    'Dekani',
    'Crnuce',
    'Črnomelj',
    'Crni Vrh',
    'Crnice',
    'Crna na Koroskem',
    'Crensovci',
    'Col',
    'Cirkovce',
    'Cinzat',
    'Cersak',
    'Cerkvenjak',
    'Cerknica',
    'Cerklje ob Krki',
    'Cerklje na Gorenjskem',
    'Cepovan',
    'Cepinci',
    'Celje',
    'Cankova',
    'Budinci',
    'Bucka',
    'Brezovica pri Ljubljani',
    'Brezje',
    'Brežice',
    'Brezen',
    'Bresternica',
    'Brestanica',
    'Braslovče',
    'Branik',
    'Bovec',
    'Bostanj',
    'Borovnica',
    'Bohinjska Bistrica',
    'Bogojina',
    'Bodonci',
    'Bled',
    'Blanca',
    'Blagovica',
    'Bizeljsko',
    'Bistrica pri Rusah',
    'Bistrica ob Sotli',
    'Bilje',
    'Benedikt',
    'Beltinci',
    'Begunje pri Cerknici',
    'Begunje na Gorenjskem',
    'Artice',
    'Apace',
    'Ajdovščina',
    'Adrijanci',
    'Mackovci',
    'Mala Nedelja',
    'Razkrizje',
    'Solkan',
    'Nova Cerkev',
    'Cerkno',
    'Videm',
    'Logatec',
    'Ankaran',
    'Dekmanca',
    'Gorica pri Slivnici',
    'Podplat',
    'Kapla',
    'Videm pri Ptuju',
    'Zrece',
    'Selnica ob Dravi',
    'Malecnik',
    'Gracisce',
    'Lucija',
    'Pobegi',
    'Debeli Rtic',
    'Podnanos',
    'Preserje',
    'Sveti Stefan',
    'Smarjeske Toplice',
    'Orehova Vas',
    'Kisovec',
    'Cirkulane',
    'Krka',
    'Koprivnica',
    'Komenda',
    'Nazarje',
    'Municipality of Kostel',
    'Prelog',
    'Stara Fužina',
    'Bošamarin',
    'Dobriša Vas',
    'Grlice',
    'Pecine',
    'Pondor',
    'Dragovic',
    'Nadole',
    'Robanov Kot',
    'Pestike',
    'Korenjak',
    'Sv. Trojica',
    'Jesenje',
    'Brezovec pri Rogatcu',
    'Sitez',
    'Bistrica ob Dravi',
    'Suhor',
  ],
  Barbados: [
    'Worthing',
    'White Hill',
    'Oistins',
    'Holetown',
    'Hillaby',
    'Durants',
    'Clermont',
    'Cave Hill',
    'Bruce Vale',
    'Bridgetown',
  ],
  'Cabo Verde': [
    'Vinagre',
    'Nova Sintra',
    'Vila do Maio',
    'Ribeira Brava',
    'Toril',
    'Tarrafal de Sao Nicolau',
    'Sao Domingos',
    'Santa Maria',
    'Sal Rei',
    'Ribeira Grande',
    'Queimadas',
    'Praia',
    'Porto Novo',
    'Igreja',
    'Pombas',
    'Picos',
    'Montanha',
    'Mindelo',
    'Achada Monte',
    'Cova Figueira',
    'Cidade Velha',
    'Chacha',
    'Assomada',
    'Joao Teves',
  ],
  Guyana: [
    'Parika',
    'New Amsterdam',
    'Mahdia',
    'Mabaruma',
    'Linden',
    'Lethem',
    'Georgetown',
    'Diamond Place',
    'Charlestown',
    'Bush Lot',
    'Kamarang',
    'Kabakaburi',
  ],
  'French Guiana': [
    'Saint-Laurent-du-Maroni',
    'Saint-Georges',
    'Montsinery-Tonnegrande',
    'Remire-Montjoly',
    'Matoury',
    'Maripasoula',
    'Mana',
    'Macouria',
    'Kourou',
    'Grand-Santi',
    'Cayenne',
  ],
  Suriname: [
    'Wageningen',
    'Totness',
    'Sabakoe',
    'Paramaribo',
    'Onverwacht',
    'Nieuw Nickerie',
    'Nieuw Amsterdam',
    'Moengo',
    'Marienburg',
    'Lelydorp',
    'Kampong Baroe',
    'Groningen',
    'Friendship',
    'Domburg',
    'Brownsweg',
    'Brokopondo',
    'Botopasi',
    'Albina',
  ],
  Brazil: [
    'Xucuru',
    'Xexeu',
    'Xambioa',
    'Volta Redonda',
    'Vitorino Freire',
    'Vitoria do Mearim',
    'Vitoria de Santo Antao',
    'Viseu',
    'Conde',
    'Vigia',
    'Vicosa do Ceara',
    'Vicosa',
    'Vicencia',
    'Viana',
    'Vertentes',
    'Vera Cruz',
    'Venturosa',
    'Varzea Alegre',
    'Varzea',
    'Varjota',
    'Vargem Grande',
    'Valenca do Piaui',
    'Uruoca',
    'Urucui',
    'Uruburetama',
    'Urbano Santos',
    'Upanema',
    'Uniao dos Palmares',
    'Uniao',
    'Umirim',
    'Umbuzeiro',
    'Umarizal',
    'Uirauna',
    'Ubajara',
    'Uaua',
    'Tutoia',
    'Tururu',
    'Turiacu',
    'Tuparetama',
    'Tupanatinga',
    'Tuntum',
    'Tucurui',
    'Triunfo',
    'Trindade',
    'Trairi',
    'Tracunhaem',
    'Touros',
    'Toritama',
    'Tocantinopolis',
    'Tinguis',
    'Timon',
    'Timbiras',
    'Timbauba dos Batistas',
    'Timbauba',
    'Tibau do Sul',
    'Tibau',
    'Tiangua',
    'Terra Santa',
    'Terra Nova',
    'Teresina',
    'Tenorio',
    'Tenente Ananias',
    'Tejucuoca',
    'Teixeira',
    'Tavares',
    'Taua',
    'Taquaritinga do Norte',
    'Taquarana',
    'Taperoa',
    'Tanque dArca',
    'Tangara',
    'Tamboril',
    'Itambe',
    'Tamandare',
    'Taipu',
    'Tacima',
    'Tacaratu',
    'Tacaimbo',
    'Tabuleiro do Norte',
    'Tabira',
    'Surubim',
    'Sume',
    'Sucupira do Norte',
    'Sousa',
    'Soure',
    'Sossego',
    'Solonopole',
    'Soledade',
    'Solanea',
    'Sobral',
    'Sobradinho',
    'Sitio Novo',
    'Sirinhaem',
    'Simplicio Mendes',
    'Simoes',
    'Sertania',
    'Serrita',
    'Serrinha dos Pintos',
    'Serra Talhada',
    'Serraria',
    'Serra Redonda',
    'Serra Negra do Norte',
    'Serra de Sao Bento',
    'Serra da Raiz',
    'Serra Branca',
    'Serido',
    'Senador Pompeu',
    'Sape',
    'Sao Vicente Ferrer',
    'Sao Vicente',
    'Sao Sebastiao do Tocantins',
    'Sao Sebastiao',
    'Sao Raimundo Nonato',
    'Sao Raimundo das Mangabeiras',
    'Sao Rafael',
    'Sao Pedro do Piaui',
    'Sao Pedro',
    'Sao Paulo do Potengi',
    'Sao Paulo',
    'Sao Miguel dos Milagres',
    'Sao Miguel dos Campos',
    'Sao Miguel do Guama',
    'Sao Miguel de Taipu',
    'Sao Miguel do Gostoso',
    'Sao Mateus do Maranhao',
    'Sao Mamede',
    'Sao Luis do Quitunde',
    'Sao Luis do Curu',
    'São Luís',
    'Sao Lourenco da Mata',
    'Sao Jose do Serido',
    'Sao Jose dos Cordeiros',
    'Sao Jose do Peixe',
    'Sao Jose do Egito',
    'Sao Jose do Campestre',
    'Sao Jose do Bonfim',
    'Sao Jose do Belmonte',
    'Sao Jose de Ribamar',
    'Sao Jose de Piranhas',
    'Sao Jose de Mipibu',
    'Sao Jose de Caiana',
    'Sao Jose de Espinharas',
    'Sao Jose da Tapera',
    'Sao Jose da Laje',
    'Sao Jose da Coroa Grande',
    'Sao Jose',
    'Sao Joaquim do Monte',
    'Sao Joaquim',
    'Sao Joao dos Patos',
    'Sao Joao do Sabugi',
    'Sao Joao do Piaui',
    'Sao Joao do Jaguaribe',
    'Sao Joao do Araguaia',
    'Sao Joao de Pirabas',
    'Sao Joao',
    'Sao Goncalo do Piaui',
    'Sao Goncalo do Amarante',
    'Sao Geraldo do Araguaia',
    'Sao Francisco',
    'Sao Fernando',
    'Sao Domingos do Maranhao',
    'Sao Caitano',
    'Sao Caetano de Odivelas',
    'Sao Bento do Una',
    'Sao Bento do Trairi',
    'Sao Bento',
    'Sao Benedito',
    'Santo Antonio do Taua',
    'Santo Antonio',
    'Santo Agostinho',
    'Santa Teresinha',
    'Santa Rita',
    'Santarém',
    'Santa Quiteria do Maranhao',
    'Santa Quiteria',
    'Santana do Matos',
    'Santana do Ipanema',
    'Santana do Acarau',
    'Santa Maria do Para',
    'Santa Maria da Boa Vista',
    'Santa Maria',
    'Santa Luzia',
    'Santa Isabel do Para',
    'Santa Ines',
    'Santa Helena',
    'Santa Filomena do Maranhao',
    'Santa Cruz dos Milagres',
    'Santa Cruz do Piaui',
    'Santa Cruz do Capibaribe',
    'Santa Cruz',
    'Santa Brigida',
    'Sanharo',
    'Salvaterra',
    'Saloa',
    'Salitre',
    'Salinopolis',
    'Salgueiro',
    'Salgado de Sao Felix',
    'Salgadinho',
    'Saire',
    'Saboeiro',
    'Russas',
    'Ruy Barbosa',
    'Roteiro',
    'Rosario',
    'Rodolfo Fernandes',
    'Rio Tinto',
    'Rio Largo',
    'Rio Formoso',
    'Ribeirao',
    'Riacho de Santo Antonio',
    'Riacho de Santana',
    'Riacho das Almas',
    'Riachao do Bacamarte',
    'Riachao',
    'Reriutaba',
    'Remigio',
    'Regeneracao',
    'Redencao do Gurgueia',
    'Redencao',
    'Recife',
    'Rafael Fernandes',
    'Quixere',
    'Quixeramobim',
    'Quixelo',
    'Quixada',
    'Quixaba',
    'Quipapa',
    'Queimadas',
    'Queimada Nova',
    'Quebrangulo',
    'Princesa Isabel',
    'Primavera',
    'Presidente Dutra',
    'Potiretama',
    'Barra de Sao Miguel',
    'Potengi',
    'Santana',
    'Porto Franco',
    'Porto de Pedras',
    'Porto da Folha',
    'Porto Calvo',
    'Porteiras',
    'Portalegre',
    'Pombos',
    'Pombal',
    'Poco Redondo',
    'Poco das Trincheiras',
    'Poco Branco',
    'Pocinhos',
    'Pocao de Pedras',
    'Pocao',
    'Pitimbu',
    'Pirpirituba',
    'Piripiri',
    'Piranhas',
    'Piracuruca',
    'Piquet Carneiro',
    'Pipa',
    'Pio XII',
    'Pio IX',
    'Pinheiro',
    'Pindoretama',
    'Piloes',
    'Pilar',
    'Picui',
    'Picos',
    'Pianco',
    'Petrolina',
    'Jatoba',
    'Pesqueira',
    'Peritoro',
    'Pereiro',
    'Pequizeiro',
    'Pentecoste',
    'Pendencias',
    'Penalva',
    'Pedro Velho',
    'Pedro II',
    'Pedreiras',
    'Pedras de Fogo',
    'Pedra Lavrada',
    'Pedra Grande',
    'Pedra Branca',
    'Pedra',
    'Pecem',
    'Paulo Ramos',
    'Paulo Jacinto',
    'Paulo Afonso',
    'Paulistana',
    'Paulista',
    'Paulino Neves',
    'Pau dos Ferros',
    'Paudalho',
    'Patu',
    'Patos',
    'Pastos Bons',
    'Passo de Camaragibe',
    'Passira',
    'Passagem Franca',
    'Parnamirim',
    'Parnaiba',
    'Paripueira',
    'Parintins',
    'Pariconha',
    'Parelhas',
    'Parazinho',
    'Parau',
    'Paranatama',
    'Paramoti',
    'Parambu',
    'Paraipaba',
    'Paraibano',
    'Paragominas',
    'Paracuru',
    'Pao de Acucar',
    'Panelas',
    'Palmeira dos Indios',
    'Palmares',
    'Palmacia',
    'Palestina',
    'Padre Marcos',
    'Pacoti',
    'Pacatuba',
    'Pacajus',
    'Ouro Velho',
    'Ouro Branco',
    'Ouricuri',
    'Oros',
    'Oroco',
    'Orobo',
    'Olivenca',
    'Olivedos',
    'Olinda',
    "Olho d'Agua do Casado",
    "Olho d'Agua das Flores",
    'Oeiras',
    'Ocara',
    'Novo Oriente',
    'Novo Lino',
    'Nova Timboteua',
    'Nova Russas',
    'Nova Palmeira',
    'Nova Olinda (2)',
    'Nova Olinda',
    'Nova Floresta',
    'Nova Cruz',
    'Nisia Floresta',
    'Nazarezinho',
    'Nazare da Mata',
    'Nazare',
    'Natal',
    'Murici',
    'Mulungu',
    'Mucambo',
    'Morros',
    'Morrinhos',
    'Moreno',
    'Moraujo',
    'Morada Nova',
    'Monteiropolis',
    'Monteiro',
    'Monte Dourado',
    'Monte Alegre',
    'Montanhas',
    'Monsenhor Tabosa',
    'Mombaca',
    'Mogeiro',
    'Mossoro',
    'Mocajuba',
    'Missao Velha',
    'Mirandiba',
    'Miraima',
    'Mirador',
    'Miracema do Tocantins',
    'Minador do Negrao',
    'Milha',
    'Milagres',
    'Miguel Alves',
    'Meruoca',
    'Maxaranguape',
    'Pureza',
    'Mauriti',
    'Matriz de Camaragibe',
    'Matinha',
    'Matias Olimpio',
    'Mata Grande',
    'Massaranduba',
    'Massape',
    'Mar Vermelho',
    'Martins',
    'Martinopole',
    'Maribondo',
    'Mari',
    'Mares',
    'Marechal Deodoro',
    'Marcolandia',
    'Marco',
    'Marcelino Vieira',
    'Maranguape',
    'Maraial',
    'Maragogi',
    'Maracanau',
    'Maracana',
    'Maracacume',
    'Marabá',
    'Manaus',
    'Manaira',
    'Mamanguape',
    'Major Isidoro',
    'Magalhaes de Almeida',
    'Madalena',
    'Macurure',
    'Machados',
    'Maceió',
    'Macau',
    'Macaparana',
    'Macapá',
    'Macaiba',
    'Luzilandia',
    'Luis Gomes',
    'Luis Domingues',
    'Luis Correia',
    'Lucena',
    'Loreto',
    'Livramento',
    'Limoeiro do Norte',
    'Limoeiro de Anadia',
    'Limoeiro',
    'Lavras da Mangabeira',
    'Lajes',
    'Lajedo',
    'Lajeado',
    'Lagoa Seca',
    'Lagoa Salgada',
    'Lagoa Nova',
    'Lagoa Grande',
    'Lagoa de Velhos',
    'Lagoa do Sitio',
    'Lagoa dos Gatos',
    'Lagoa do Itaenga',
    'Lagoa do Carro',
    'Lagoa da Canoa',
    'Lagoa',
    'Juruti',
    'Juru',
    'Juripiranga',
    'Jurema',
    'Jupi',
    'Junqueiro',
    'Jundia',
    'Jucurutu',
    'Jucas',
    'Juazeiro do Norte',
    'Juazeiro',
    'Juazeiro do Piaui',
    'Juazeirinho',
    'Jose de Freitas',
    'Joaquim Nabuco',
    'Joaquim Gomes',
    'João Pessoa',
    'Joao Lisboa',
    'Joao Camara',
    'Joao Alfredo',
    'Girau do Ponciano',
    'Jerumenha',
    'Jericoacoara',
    'Jenipapo',
    'Jati',
    'Jatauba',
    'Jardim do Serido',
    'Jardim de Piranhas',
    'Jardim',
    'Jaramataia',
    'Jaqueira',
    'Japi',
    'Japaratinga',
    'Januario Cicco',
    'Janduis',
    'Jandaira',
    'Jaicos',
    'Jaguaruana',
    'Jaguaribe',
    'Jaguaribara',
    'Jaguaretama',
    'Jacuipe',
    'Jacarau',
    'Jacana',
    'Itaubal',
    'Itatuba',
    'Itarema',
    'Itaquitinga',
    'Itacoatiara',
    'Itapororoca',
    'Itaporanga',
    'Itapiuna',
    'Itapissuma',
    'Itapipoca',
    'Itapetim',
    'Itapecuru Mirim',
    'Itapage',
    'Itamaraca',
    'Itaituba',
    'Itaitinga',
    'Itainopolis',
    'Itaiba',
    'Itacuruba',
    'Itacaja',
    'Itabaiana',
    'Isaias Coelho',
    'Irituia',
    'Iraucuba',
    'Iracema',
    'Ipueiras',
    'Ipueira',
    'Ipubi',
    'Ipu',
    'Ipojuca',
    'Ipaumirim',
    'Ipaporanga',
    'Inhuma',
    'Ingazeira',
    'Inga',
    'Independencia',
    'Imperatriz',
    'Iguatu',
    'Iguaraci',
    'Igarape Miri',
    'Igarassu',
    'Igaci',
    'Ico',
    'Icapui',
    'Ibirajuba',
    'Ibimirim',
    'Ibicuitinga',
    'Ibiara',
    'Ibiapina',
    'Ibateguara',
    'Ibaretama',
    'Iati',
    'Horizonte',
    'Hidrolandia',
    'Gurjao',
    'Guassusse',
    'Guaraciaba do Norte',
    'Guarabira',
    'Guarai',
    'Guamare',
    'Guaiuba',
    'Grossos',
    'Groairas',
    'Gravatá',
    'Granjeiro',
    'Granja',
    'Grajau',
    'Graca Aranha',
    'Graca',
    'Governador Archer',
    'Goncalves Ferreira',
    'Goncalves Dias',
    'Goianorte',
    'Goianinha',
    'Goiana',
    'Gloria do Goita',
    'Garanhuns',
    'Gameleira',
    'Fronteiras',
    'Frecheirinha',
    'Francisco Santos',
    'Francisco Ayres',
    'Fortuna',
    'Fortaleza dos Nogueiras',
    'Fortaleza',
    'Forquilha',
    'Floriano',
    'Floresta',
    'Flores do Piaui',
    'Flores',
    'Florania',
    'Ferreiros',
    'Fernando Pedroza',
    'Feira Nova',
    'Feira Grande',
    'Farias Brito',
    'Fagundes',
    'Exu',
    'Eusebio',
    'Extremoz',
    'Espirito Santo',
    'Esperantinopolis',
    'Esperantina',
    'Esperanca',
    'Escada',
    'Erere',
    'Equador',
    'Emas',
    'Doutor Severiano',
    'Dona Ines',
    'Dom Pedro',
    'Dois Riachos',
    'Dix-sept Rosado',
    'Diamante',
    'Desterro',
    'Demerval Lobao',
    'Delmiro Gouveia',
    'Custodia',
    'Cururupu',
    'Curuca',
    'Curua',
    'Currais Novos',
    'Curaca',
    'Cupira',
    'Cumaru',
    'Cuitegi',
    'Cuite',
    'Cubati',
    'Cruzeta',
    'Cruz do Espirito Santo',
    'Cruz',
    'Croata',
    'Cristino Castro',
    'Crato',
    'Crateus',
    'Craibas',
    'Couto de Magalhaes',
    'Cortes',
    'Correntes',
    'Coronel Ezequiel',
    'Coroata',
    'Congo',
    'Condado',
    'Conceicao do Caninde',
    'Conceição do Araguaia',
    'Conceicao',
    'Colonia Leopoldina',
    'Colinas',
    'Colares',
    'Coite do Noia',
    'Coelho Neto',
    'Codó',
    'Cocal dos Alves',
    'Cocal',
    'Chorozinho',
    'Choro',
    'Cha Preta',
    'Chapadinha',
    'Cha Grande',
    'Cha de Alegria',
    'Centro',
    'Cedro',
    'Ceara Mirim',
    'Caxias',
    'Caucaia',
    'Catunda',
    'Catole do Rocha',
    'Campo Alegre de Lourdes',
    'Catende',
    'Catarina',
    'Castelo do Piaui',
    'Castanhal',
    'Casinhas',
    'Cascavel',
    'Casa Nova',
    'Carutapera',
    'Caruaru',
    'Carrapateira',
    'Carpina',
    'Carolina',
    'Carneiros',
    'Carnaubal',
    'Carnaubais',
    'Carnauba dos Dantas',
    'Carnaubeira da Penha',
    'Carnaiba',
    'Carius',
    'Caririacu',
    'Carire',
    'Caridade',
    'Caraubas',
    'Capoeiras',
    'Capitao Poco',
    'Capistrano',
    'Capela',
    'Capanema',
    'Canudos',
    'Canto do Buriti',
    'Caninde de Sao Francisco',
    'Caninde',
    'Canhotinho',
    'Canguaretama',
    'Candeias',
    'Canavieira',
    'Canapi',
    'Camutanga',
    'Campos Sales',
    'Campo Redondo',
    'Campo Maior',
    'Campo Grande',
    'Campo Alegre',
    'Campina Grande',
    'Campina',
    'Camocim',
    'Cameta',
    'Camela',
    'Camaragibe',
    'Camalau',
    'Caldeirao Grande',
    'Cajazeiras',
    'Cajapio',
    'Caico',
    'Caicara do Rio do Vento',
    'Caetes',
    'Cacimbinhas',
    'Cacimbas',
    'Cacimba de Dentro',
    'Cacimba de Areia',
    'Cachoeirinha',
    'Cachoeira dos andios',
    'Cachoeira do Arari',
    'Cabrobo',
    'Cabedelo',
    'Caapora',
    'Buriti dos Lopes',
    'Buriti Bravo',
    'Buriti',
    'Bujaru',
    'Buique',
    'Brejo Santo',
    'Brejo dos Santos',
    'Brejo do Cruz',
    'Brejo da Madre de Deus',
    'Brejo',
    'Brejinho',
    'Braganca',
    'Boqueirao',
    'Bonito de Santa Fe',
    'Bonito',
    'Bom Sucesso',
    'Bom Principio',
    'Bom Jesus',
    'Bom Jardim',
    'Bom Conselho',
    'Bodoco',
    'Bodo',
    'Boca da Mata',
    'Boa Viagem',
    'Bitupita',
    'Bezerros',
    'Bertolinia',
    'Bertioga',
    'Benevides',
    'Beneditinos',
    'Belo Jardim',
    'Belem de Sao Francisco',
    'Belem de Maria',
    'Belem',
    'Belém',
    'Bela Cruz',
    'Beberibe',
    'Bayeux',
    'Baturite',
    'Batalha',
    'Barroquinha',
    'Barro Duro',
    'Barro',
    'Barrento',
    'Barreiros',
    'Barreirinhas',
    'Barreira',
    'Barras',
    'Barra do Corda',
    'Barra de Santo Antonio',
    'Barra de Santa Rosa',
    'Barcelona',
    'Barcarena',
    'Barbalha',
    'Barauna',
    'Barao de Grajau',
    'Bananeiras',
    'Baixio',
    'Baia Formosa',
    'Baia da Traicao',
    'Bacurituba',
    'Bacabal',
    'Babaculandia',
    'Axixa do Tocantins',
    'Axixa',
    'Aurora',
    'Atalaia',
    'Assare',
    'Aroeiras',
    'Ares',
    'Areia de Baraunas',
    'Areia Branca',
    'Areia',
    'Arcoverde',
    'Araruna',
    'Araripina',
    'Araripe',
    'Arari',
    'Ararenda',
    'Arara',
    'Arapoema',
    'Arapiraca',
    'Arapari',
    'Araioses',
    'Araguatins',
    'Araguaína',
    'Aracoiaba',
    'Aracati',
    'Aracagi',
    'Aquiraz',
    'Apuiares',
    'Apodi',
    'Aparecida',
    'Antonio Martins',
    'Sao Joao do Rio do Peixe',
    'Angicos',
    'Angical do Piaui',
    'Angelim',
    'Ananindeua',
    'Anajatuba',
    'Anajas',
    'Anadia',
    'Amontada',
    'Amarante do Maranhao',
    'Amarante',
    'Amaraji',
    'Amapari',
    'Amapa',
    'Alto Santo',
    'Altos',
    'Alto do Rodrigues',
    'Altinho',
    'Altaneira',
    'Altamira',
    'Almino Afonso',
    'Alianca',
    'Alhandra',
    'Alexandria',
    'Alegrete',
    'Alcantil',
    'Alcantaras',
    'Alcântara',
    'Alagoinha',
    'Alagoa Nova',
    'Alagoa Grande',
    'Sao Sebastiao de Lagoa de Roca',
    'Aiuaba',
    'Aguiar',
    'Aguas Belas',
    'Agua Preta',
    'Agua Branca',
    'Agrestina',
    'Afua',
    'Afranio',
    'Afonso Bezerra',
    'Afogados da Ingazeira',
    'Acu',
    'Acopiara',
    'Acari',
    'Acarau',
    'Acarape',
    'Abreu e Lima',
    'Abare',
    'Abaetetuba',
    'Canto do Murici',
    'Mata Roma',
    'Mosquito',
    'Aparecida do Rio Negro',
    'Brasilandia do Tocantins',
    'Buriti do Tocantins',
    'Campos Lindos',
    'Carmolandia',
    'Colmeia',
    'Divinopolis do Tocantins',
    'Itapiratins',
    'Sao Miguel do Tocantins',
    'Wanderlandia',
    'Colinas do Tocantins',
    'Miranorte',
    'Zacarias',
    'Xaxim',
    'Xavantina',
    'Chavantes',
    'Xanxere',
    'Xambre',
    'Witmarsum',
    'Wagner',
    'Votuporanga',
    'Votorantim',
    'Volta Grande',
    'Vitorino',
    'Vitória da Conquista',
    'Vitória',
    'Vista Alegre do Alto',
    'Visconde do Rio Branco',
    'Virmond',
    'Virginopolis',
    'Virginia',
    'Virgem da Lapa',
    'Viradouro',
    'Vinhedo',
    'Vila Velha',
    'Vila Flores',
    'Videira',
    'Vidal Ramos',
    'Vicente Dutra',
    'Vianopolis',
    'Viamão',
    'Viadutos',
    'Vespasiano Correa',
    'Vespasiano',
    'Vermelho Novo',
    'Veredinha',
    'Vere',
    'Veranopolis',
    'Ventania',
    'Venda Nova',
    'Wenceslau Braz',
    'Venancio Aires',
    'Vazante',
    'Vassouras',
    'Varzelandia',
    'Varzedo',
    'Varzea Paulista',
    'Várzea Grande',
    'Varzea do Poco',
    'Varzea da Palma',
    'Varjao',
    'Varginha',
    'Vargem Grande do Sul',
    'Vargem Bonita',
    'Vargem Alta',
    'Vargem Alegre',
    'Vargem',
    'Valparaiso',
    'Valinhos',
    'Valentim Gentil',
    'Valente',
    'Valenca',
    'Valença',
    'Vacaria',
    'Utinga',
    'Urutai',
    'Urussanga',
    'Urupes',
    'Uruguaiana',
    'Urucuia',
    'Urucuca',
    'Urucania',
    'Urubici',
    'Uruana',
    'Uruacu',
    'Uru',
    'Urania',
    'Urandi',
    'Urai',
    'Uniflor',
    'Uniao da Vitoria',
    'Unai',
    'Una',
    'Umuarama',
    'Umburatiba',
    'Umbauba',
    'Uibai',
    'Uchoa',
    'Ubiretama',
    'Ubirajara',
    'Uberlândia',
    'Uberaba',
    'Ubatuba',
    'Ubata',
    'Ubarana',
    'Ubaporanga',
    'Ubaira',
    'Ubai',
    'Uba',
    'Turvo',
    'Turvania',
    'Turmalina',
    'Turiuba',
    'Tupi Paulista',
    'Boa Vista do Tupim',
    'Tuparendi',
    'Tupandi',
    'Tupancireta',
    'Tupaciguara',
    'Tupa',
    'Tuneiras do Oeste',
    'Tuiuti',
    'Tucunduva',
    'Tucano',
    'Tubarao',
    'Trombudo Central',
    'Treze Tilias',
    'Treze de Maio',
    'Treviso',
    'Tres Rios',
    'Tres Pontas',
    'Tres Passos',
    'Três Lagoas',
    'Tres Fronteiras',
    'Tres de Maio',
    'Tres Coroas',
    'Três Corações',
    'Tres Cachoeiras',
    'Tres Barras',
    'Tremembe',
    'Tremedal',
    'Tramandai',
    'Trajano de Morais',
    'Trabiju',
    'Torrinha',
    'Torres',
    'Toropi',
    'Tombos',
    'Tomazina',
    'Tomar do Geru',
    'Toledo',
    'Tocos do Moji',
    'Tocantins',
    'Tobias Barreto',
    'Tiros',
    'Tiradentes',
    'Timoteo',
    'Timburi',
    'Timbo',
    'Tijucas do Sul',
    'Tijucas',
    'Tiete',
    'Tibagi',
    'Teutonia',
    'Terra Roxa',
    'Terra Rica',
    'Terra de Areia',
    'Terra Boa',
    'Teresopolis',
    'Santa Terezinha de Goias',
    'Terenos',
    'Teófilo Otoni',
    'Teodoro Sampaio',
    'Tenente Portela',
    'Telemaco Borba',
    'Teixeira Soares',
    'Teixeiras',
    'Taubate',
    'Tatuí',
    'Tarumirim',
    'Taruma',
    'Tarabai',
    'Taquarivai',
    'Taquarituba',
    'Taquaritinga',
    'Taquari',
    'Taquaral',
    'Taquara',
    'Tapiratiba',
    'Tapiramuta',
    'Tapirai',
    'Tapira',
    'Tapes',
    'Tapera',
    'Tapejara',
    'Tanquinho',
    'Tanque Novo',
    'Tanhacu',
    'Tangua',
    'Tanabi',
    'Tamboara',
    'Tambau',
    'Tamarana',
    'Taiuva',
    'Taiobeiras',
    'Taio',
    'Taguai',
    'Taciba',
    'Tabuleiro',
    'Taboao da Serra',
    'Tabatinga',
    'Tabapua',
    'Suzano',
    'Sumaré',
    'Sulina',
    'Sorocaba',
    'Sombrio',
    'Soledade de Minas',
    'Socorro',
    'Sitio do Quinto',
    'Sitio do Mato',
    'Siqueira Campos',
    'Sinimbu',
    'Simonesia',
    'Simao Pereira',
    'Simao Dias',
    'Silveiras',
    'Silveirania',
    'Silveira Martins',
    'Silvania',
    'Silva Jardim',
    'Sidrolandia',
    'Sideropolis',
    'Severinia',
    'Setubinha',
    'Sete Lagoas',
    'Sete de Setembro',
    'Sete Barras',
    'Sertaozinho',
    'Sertao Santana',
    'Sertao',
    'Sertanopolis',
    'Sertaneja',
    'Serrolandia',
    'Serro',
    'Serrinha',
    'Serra Preta',
    'Serranos',
    'Serrania',
    'Serra Negra',
    'Serrana',
    'Serra dos Dourados',
    'Serra do Salitre',
    'Serra dos Aimores',
    'Serra Azul',
    'Serra',
    'Seropedica',
    'Sericita',
    'Sereno',
    'Serafina Correa',
    'Senhor do Bonfim',
    'Senhora dos Remedios',
    'Senhora do Porto',
    'Senhora de Oliveira',
    'Senges',
    'Senador Jose Bento',
    'Senador Firmino',
    'Senador Canedo',
    'Senador Amaral',
    'Sem Peixe',
    'Selbach',
    'Sede Nova',
    'Seberi',
    'Sebastianopolis do Sul',
    'Seara',
    'Seabra',
    'Schroeder',
    'Saude',
    'Saudades',
    'Satiro Dias',
    'Sarzedo',
    'Sarutaia',
    'Sardoa',
    'Sarapui',
    'Sarandi',
    'Saquarema',
    'Sapucaia',
    'Sapopema',
    'Sapiranga',
    'Sapeacu',
    'Sao Vicente de Minas',
    'Sao Valentim',
    'Sao Thome das Letras',
    'Sao Tomas de Aquino',
    'Sao Tiago',
    'Sao Simao',
    'Sao Sepe',
    'Sao Sebastiao do Passe',
    'Sao Sebastiao do Paraiso',
    'Sao Sebastiao do Paraiba',
    'Sao Sebastiao do Cai',
    'Sao Sebastiao de Campos',
    'Sao Sebastiao da Grama',
    'Sao Sebastiao da Bela Vista',
    'Sao Sebastiao da Amoreira',
    'Sao Roque',
    'Sao Pedro do Turvo',
    'Sao Pedro do Sul',
    'Sao Pedro do Ivai',
    'Dom Pedro de Alcantara',
    'Sao Pedro da Aldeia',
    'São Paulo',
    'Sao Miguel do Iguacu',
    'São Miguel do Oeste',
    'Sao Miguel do Anta',
    'São Miguel das Missões',
    'Sao Miguel das Matas',
    'Sao Miguel Arcanjo',
    'Sao Mateus do Sul',
    'Sao Mateus',
    'São Mateus',
    'Sao Martinho',
    'Sao Marcos',
    'Sao Manuel',
    'Sao Luiz Gonzaga',
    'Sao Luis do Paraitinga',
    'Sao Luis de Montes Belos',
    'Sao Luiz',
    'Sao Lourenco do Turvo',
    'Sao Lourenco do Sul',
    'Sao Lourenco do Oeste',
    'Sao Lourenco da Serra',
    'São Lourenço',
    'São Leopoldo',
    'São José dos Pinhais',
    'São José dos Campos',
    'Sao Jose do Rio Preto',
    'Sao Jose do Rio Pardo',
    'Sao Jose do Ouro',
    'Sao Jose do Mantimento',
    'Sao Jose do Jacuri',
    'Sao Jose do Jacuipe',
    'Sao Jose do Inhacora',
    'Sao Jose do Hortencio',
    'Sao Jose do Goiabal',
    'Sao Jose do Cerrito',
    'Sao Jose do Cedro',
    'Sao Jose do Calcado',
    'Sao Jose do Barreiro',
    'Sao Jose do Alegre',
    'Sao Jose de Uba',
    'Sao Jose da Varginha',
    'Sao Jose da Safira',
    'Sao Jose da Boa Vista',
    'Sao Jose da Bela Vista',
    'Sao Jose da Barra',
    'Sao Jorge do Ivai',
    "Sao Jorge d'Oeste",
    'Sao Jorge',
    'Sao Joaquim de Bicas',
    'Sao Joaquim da Barra',
    'Sao Joao Nepomuceno',
    'Sao Joao Evangelista',
    'Sao Joao do Triunfo',
    'Sao Joao do Paraiso',
    'Sao Joao do Oriente',
    'Sao Joao do Manhuacu',
    'Sao Joao do Caiua',
    'Sao Joao de Meriti',
    'Sao Joao do Manteninha',
    'São João del Rei',
    'Sao Joao de Iracema',
    'Sao Joao da Urtiga',
    'Sao Joao das Duas Pontes',
    'Sao Joao da Ponte',
    'Sao Joao da Ocalina',
    'Sao Joao da Mata',
    'Sao Joao da Boa Vista',
    'Sao Joao da Barra',
    'Sao Joao Batista do Gloria',
    'Sao Joao Batista',
    'Sao Jeronimo da Serra',
    'Sao Jeronimo',
    'Sao Gotardo',
    'Sao Goncalo dos Campos',
    'Sao Goncalo do Sapucai',
    'Sao Goncalo do Rio Abaixo',
    'Sao Goncalo do Para',
    'Sao Goncalo',
    'Sao Geraldo',
    'Sao Gabriel de Goias',
    'Sao Gabriel',
    'Sao Francisco Xavier',
    'Sao Francisco do Sul',
    'Sao Francisco do Conde',
    'Sao Francisco de Sales',
    'Sao Francisco de Paula',
    'Sao Francisco de Goias',
    'Sao Francisco de Assis',
    'Sao Felipe',
    'Sao Fidelis',
    'Sao Felix',
    'Sao Domingos do Sul',
    'Sao Domingos do Prata',
    'Sao Domingos',
    'Sao Cristovao',
    'Sao Carlos do Ivai',
    'Sao Carlos',
    'São Carlos',
    'São Caetano do Sul',
    'Sao Bras do Suacui',
    'Sao Bras',
    'São Borja',
    'Sao Bonifacio',
    'São Bernardo do Campo',
    'São Bento do Sul',
    'Sao Bento do Sapucai',
    'Santos Dumont',
    'Santos',
    'Santo Expedito',
    'Santo Estevao',
    'Santo Cristo',
    'Santo Augusto',
    'Santo Antonio do Sudoeste',
    'Santo Antonio do Rio Verde',
    'Santo Antonio do Pinhal',
    'Santo Antonio do Paraiso',
    'Santo Antonio do Monte',
    'Santo Antonio do Jardim',
    'Santo Antonio do Jacinto',
    'Santo Antonio do Grama',
    'Santo Antonio do Caiua',
    'Santo Antonio do Amparo',
    'Santo Antonio de Posse',
    'Santo Antonio de Padua',
    'Santo Antonio de Jesus',
    'Santo Antonio da Platina',
    'Santo Antonio da Alegria',
    'Santo Antonio da Patrulha',
    'Santo Ângelo',
    'Santo André',
    'Santo Anastacio',
    'Santo Amaro das Brotas',
    'Santo Amaro da Imperatriz',
    'Santo Amaro',
    'Santiago',
    'Santa Vitoria do Palmar',
    'Santa Vitoria',
    'Santa Teresa',
    'Santa Tereza de Goias',
    'Santa Rosa de Viterbo',
    'Santa Rosa',
    'Santa Rita do Sapucai',
    'Santa Rita do Passa Quatro',
    'Santa Rita do Itueto',
    'Santa Rita de Caldas',
    'Santanopolis',
    'Santana dos Montes',
    'Santana do Paraiso',
    'Santana do Manhuacu',
    'Santana do Livramento',
    'Santana do Jacare',
    'Santana do Itarare',
    'Santana do Garambeu',
    'Santana de Parnaiba',
    'Santana de Cataguases',
    'Santana da Vargem',
    'Santana da Ponte Pensa',
    'Santa Mariana',
    'Santa Maria Madalena',
    'Santa Maria do Suacui',
    'Santa Maria do Salto',
    'Santa Maria do Herval',
    'Santa Maria de Itabira',
    'Santa Maria da Vitoria',
    'Santa Maria da Serra',
    'Santa Margarida',
    'Santaluz',
    'Santa Lucia',
    'Santa Leopoldina',
    'Santa Juliana',
    'Santa Isabel do Ivai',
    'Santa Isabel',
    'Santa Helena de Goias',
    'Santa Gertrudes',
    'Santa Fe do Sul',
    'Santa Fe',
    'Santa Eudoxia',
    'Santa Ernestina',
    'Santa Cruz do Sul',
    'Santa Cruz do Rio Pardo',
    'Santa Cruz de Monte Castelo',
    'Santa Cruz do Escalvado',
    'Santa Cruz de Goias',
    'Santa Cruz da Vitoria',
    'Santa Cruz das Palmeiras',
    'Santa Cruz da Conceicao',
    'Santa Cruz Cabralia',
    'Santa Clara do Sul',
    'Santa Cecilia do Pavao',
    'Santa Cecilia',
    'Santa Branca',
    'Santa Barbara do Sul',
    "Santa Barbara d'Oeste",
    'Santa Barbara',
    'Santa Amelia',
    'Santa Albertina',
    'Santa Adelia',
    'Sangao',
    'Sandovalina',
    'Sananduva',
    'Salvador',
    'Salto Grande',
    'Salto do Itarare',
    'Salto de Pirapora',
    'Salto da Divisa',
    'Salto',
    'Saltinho',
    'Salmourao',
    'Salinas da Margarida',
    'Salinas',
    'Salgado Filho',
    'Salgado',
    'Salesopolis',
    'Sales Oliveira',
    'Sales',
    'Saldanha Marinho',
    'Sacramento',
    'Sacra Familia do Tingua',
    'Sabinopolis',
    'Sabino',
    'Sabaudia',
    'Sabara',
    'Rubineia',
    'Rubim',
    'Rubiataba',
    'Roseira',
    'Rosario do Sul',
    'Rosario do Catete',
    'Rosario da Limeira',
    'Roque Gonzales',
    'Rondonópolis',
    'Rondon',
    'Rondinha',
    'Ronda Alta',
    'Roncador',
    'Romelandia',
    'Rolante',
    'Rolandia',
    'Rodeiro',
    'Rodeio Bonito',
    'Rodeio',
    'Roda Velha',
    'Rochedo de Minas',
    'Rochedo',
    'Roca Sales',
    'Riozinho',
    'Rio Vermelho',
    'Rio Verde de Mato Grosso',
    'Rio Verde',
    'Rio Rufino',
    'Rio Real',
    'Rio Preto do Sul',
    'Rio Preto',
    'Rio Pomba',
    'Rio Piracicaba',
    'Rio Pardo de Minas',
    'Rio Pardo',
    'Rio Paranaiba',
    'Rio Novo do Sul',
    'Rio Novo',
    'Rio Negro',
    'Rio Negrinho',
    'Rio Manso',
    'Riolandia',
    'Rio Grande da Serra',
    'Rio Grande',
    'Rio do Sul',
    'Rio dos Cedros',
    'Rio do Pires',
    'Rio Doce',
    'Rio do Campo',
    'Rio do Antonio',
    'Rio de Janeiro',
    'Rio de Contas',
    'Rio das Pedras',
    'Rio das Ostras',
    'Rio das Mortes',
    'Rio das Flores',
    'Rio das Antas',
    'Rio Claro',
    'Rio Casca',
    'Rio Brilhante',
    'Rio Branco do Sul',
    'Rio Bonito',
    'Rio Bananal',
    'Rio Acima',
    'Rinopolis',
    'Rincao',
    'Rifaina',
    'Ribeiropolis',
    'Ribeira do Amparo',
    'Ribeirão Preto',
    'Ribeirao Pires',
    'Ribeirao do Pinhal',
    'Ribeirão das Neves',
    'Ribeirao Corrente',
    'Ribeirao Claro',
    'Ribeirao Branco',
    'Ribeirao Bonito',
    'Ribeira do Pombal',
    'Ribas do Rio Pardo',
    'Rialma',
    'Riachuelo',
    'Riacho dos Machados',
    'Riachao do Jacuipe',
    'Riachao do Dantas',
    'Riachao das Neves',
    'Retirolandia',
    'Santo Antonio do Retiro',
    'Restinga Seca',
    'Restinga',
    'Ressaquinha',
    'Resplendor',
    'Reserva',
    'Resende Costa',
    'Resende',
    'Renascenca',
    'Relvado',
    'Registro',
    'Reginopolis',
    'Regente Feijo',
    'Reduto',
    'Redentora',
    'Recreio',
    'Reboucas',
    'Realeza',
    'Raul Soares',
    'Raposos',
    'Rancho Alegre',
    'Rancharia',
    'Ramalho',
    'Rafard',
    'Quissama',
    'Quirinopolis',
    'Quintana',
    'Quinta do Sol',
    'Quilombo',
    'Quijingue',
    'Querencia do Norte',
    'Queluz',
    'Queimados',
    'Quatro Pontes',
    'Quatro Barras',
    'Quatis',
    'Quatigua',
    'Quata',
    'Quartel Geral',
    'Aldeia Velha',
    'Quarai',
    'Quadra',
    'Putinga',
    'Prudentopolis',
    'Prudente de Morais',
    'Protasio Alves',
    'Propria',
    'Promissao',
    'Progresso',
    'Primeiro de Maio',
    'Presidente Venceslau',
    'Itaoca',
    'Presidente Prudente',
    'Presidente Olegario',
    'Presidente Getulio',
    'Presidente Epitacio',
    'Presidente Bernardes',
    'Presidente Alves',
    'Pratinha',
    'Pratapolis',
    'Pratania',
    'Prata',
    'Praia Grande',
    'Prados',
    'Pradopolis',
    'Prado',
    'Poxoreo',
    'Pouso Redondo',
    'Pouso Novo',
    'Pouso Alto',
    'Pouso Alegre',
    'Potirendaba',
    'Potiragua',
    'Pote',
    'Posse',
    'Porto Xavier',
    'Porto Uniao',
    'Porto Seguro',
    'Porto Rico',
    'Porto Real do Colegio',
    'Porto Real',
    'Porto Nacional',
    'Porto Marrecas',
    'Porto Firme',
    'Porto Ferreira',
    'Porto Feliz',
    'Porto Belo',
    'Porto Alegre',
    'Porteirinha',
    'Portao',
    'Porecatu',
    'Porciuncula',
    'Porangatu',
    'Porangaba',
    'Populina',
    'Ponto Novo',
    'Pontes Gestal',
    'Ponte Serrada',
    'Pontes e Lacerda',
    'Ponte Nova',
    'Ponte Alta do Norte',
    'Ponte Alta',
    'Ponta Porã',
    'Pontalinda',
    'Pontalina',
    'Pontal',
    'Ponta Grossa',
    'Pompeu',
    'Pompeia',
    'Pomerode',
    'Poloni',
    'Pojuca',
    'Pocrane',
    'Poco Verde',
    'Poços de Caldas',
    'Poco Fundo',
    'Pocoes',
    'Poa',
    'Platina',
    'Planura',
    'Planalto',
    'Planaltina do Parana',
    'Piuma',
    'Pitangui',
    'Pitangueiras',
    'Pitanga',
    'Piritiba',
    'Pires do Rio',
    'Pirenopolis',
    'Pirauba',
    'Piratuba',
    'Piratininga',
    'Piratini',
    'Piraquara',
    'Pirapozinho',
    'Pirapora do Bom Jesus',
    'Pirapora',
    'Pirapo',
    'Piranguinho',
    'Pirangucu',
    'Pirangi',
    'Piranga',
    'Pirajui',
    'Pirajuba',
    'Piraju',
    'Pirai do Sul',
    'Pirai do Norte',
    'Pirai',
    'Pirassununga',
    'Piracicaba',
    'Piracema',
    'Piracanjuba',
    'Piracaia',
    'Piquete',
    'Piquerobi',
    'Pioneiros',
    'Pintadas',
    'Pinheiro Preto',
    'Pinheiro Machado',
    'Pinheiral',
    'Pinhao',
    'Pinhalzinho',
    'Pinhalao',
    'Espirito Santo do Pinhal',
    'Pindorama',
    'Pindobacu',
    'Pindamonhangaba',
    'Pindai',
    'Pimenta',
    'Pilar do Sul',
    'Pien',
    'Piedade dos Gerais',
    'Piedade de Ponte Nova',
    'Piedade',
    'Picarras',
    'Piau',
    'Piata',
    'Piacabucu',
    'Piabeta',
    'Petrópolis',
    'Petrolina de Goias',
    'Pescaria Brava',
    'Perus',
    'Peruibe',
    'Perola do Oeste',
    'Perobal',
    'Perola',
    'Pereiras',
    'Pereira Barreto',
    'Perdoes',
    'Perdigao',
    'Pequi',
    'Pequeri',
    'Penha',
    'Penedo',
    'Penapolis',
    'Pelotas',
    'Pejucara',
    'Peixe',
    'Pedro Leopoldo',
    'Pedro de Toledo',
    'Pedrinopolis',
    'Pedreira',
    'Pedregulho',
    'Pedras Grandes',
    'Pedras de Maria da Cruz',
    'Pedrao',
    'Pedra Mole',
    'Pedralva',
    'Pedra do Indaia',
    'Pedra do Anta',
    'Pedra Bela',
    'Pedra Azul',
    'Pe de Serra',
    'Pederneiras',
    'Pecanha',
    'Peabiru',
    'Paverama',
    'Pavao',
    'Paulo Lopes',
    'Paulo Frontin',
    'Paulo de Faria',
    'Paulo Bento',
    'Paulistas',
    'Paulistania',
    'Paulinia',
    'Pauliceia',
    'Paula Freitas',
    'Paula Candido',
    'Pau Brasil',
    'Patrocinio Paulista',
    'Patrocinio do Muriae',
    'Patrocinio',
    'Patos de Minas',
    'Pato Branco',
    'Pato Bragado',
    'Paty do Alferes',
    'Posto da Mata',
    'Passos',
    'Passo Fundo',
    'Passo do Sobrado',
    'Passa Tempo',
    'Passa Quatro',
    'Passabem',
    'Parobe',
    'Paripiranga',
    'Pareci Novo',
    'Pardinho',
    'Parauna',
    'Paratinga',
    'Paraty',
    'Parapua',
    'Parapeuna',
    'Paraopeba',
    'Paranoa',
    'Paranhos',
    'Paranavai',
    'Paranapoema',
    'Paranapanema',
    'Paranaiba',
    'Paranaguá',
    'Paranacity',
    'Paramirim',
    'Paraisopolis',
    'Paraiso do Norte',
    'Paraiso',
    'Paraíso das Águas',
    'Paraibuna',
    'Paraiba do Sul',
    'Parai',
    'Paraguacu Paulista',
    'Paraguacu',
    'Para de Minas',
    'Paracatu',
    'Paracambi',
    'Papanduva',
    'Papagaios',
    'Pantano Grande',
    'Panorama',
    'Pancas',
    'Panambi',
    'Panama',
    'Palotina',
    'Palmopolis',
    'Palmitos',
    'Palmitinho',
    'Palmital',
    'Palminopolis',
    'Palmeiras de Goias',
    'Palmeiras',
    'Palmeiral',
    "Palmeira d'Oeste",
    'Palmeira das Missoes',
    'Palmeira',
    'Palmas de Monte Alto',
    'Palmas',
    'Palmares Paulista',
    'Palmares do Sul',
    'Palma',
    'Palhoca',
    'Pains',
    'Paineiras',
    'Paicandu',
    'Padre Paraiso',
    'Padre Bernardo',
    'Pacaembu',
    'Ouvidor',
    'Ouro Verde',
    'Ouro Preto',
    'Ouro Fino',
    'Ouro',
    'Ourizona',
    'Ourinhos',
    'Ouricangas',
    'Otacilio Costa',
    'Osvaldo Cruz',
    'Osorio',
    'Osasco',
    'Orleans',
    'Orlandia',
    'Orizona',
    'Orindiuva',
    'Oriente',
    'Onda Verde',
    'Oliveira dos Brejinhos',
    'Oliveira',
    'Olindina',
    'Olimpio Noronha',
    'Olimpia',
    "Olhos d'Agua",
    'Ocaucu',
    'Nuporanga',
    'Nucleo Bandeirante',
    'Novo Horizonte',
    'Novo Hamburgo',
    'Novo Cruzeiro',
    'Novo Brasil',
    'Nova Vicosa',
    'Nova Veneza',
    'Nova Venecia',
    'Nova Trento',
    'Nova Soure',
    'Nova Serrana',
    'Nova Roma',
    'Nova Resende',
    'Nova Prata',
    'Nova Ponte',
    'Nova Petropolis',
    'Nova Palma',
    'Nova Padua',
    'Nova Olimpia',
    'Nova Odessa',
    'Maripa',
    'Nova Luzitania',
    'Nova Londrina',
    'Nova Lima',
    'Nova Laranjeiras',
    'Nova Itarana',
    'Nova Independencia',
    'Nova Iguaçu',
    'Nova Granada',
    'Nova Friburgo',
    'Nova Fatima',
    'Nova Europa',
    'Nova Esperanca',
    'Nova Era',
    'Nova Canaa',
    'Nova Brescia',
    'Nova Bassano',
    'Nova Aurora',
    'Nova Araca',
    'Nova America da Colina',
    'Nova America',
    'Nova Alianca do Ivai',
    'Nova Alianca',
    'Nossa Senhora do Socorro',
    'Nossa Senhora do Livramento',
    'Nossa Senhora de Lourdes',
    'Nossa Senhora das Gracas',
    'Nossa Senhora das Dores',
    'Nossa Senhora da Gloria',
    'Nortelandia',
    'Nordestina',
    'Nonoai',
    'Niterói',
    'Niquelandia',
    'Nipoa',
    'Nioaque',
    'Nilopolis',
    'Nilo Pecanha',
    'Nhecolandia',
    'Nhandeara',
    'Neves Paulista',
    'Neropolis',
    'Nepomuceno',
    'Neopolis',
    'Nazario',
    'Nazare Paulista',
    'Nazareno',
    'Navirai',
    'Navegantes',
    'Natividade',
    'Natividade da Serra',
    'Natercia',
    'Narandiba',
    'Naque',
    'Nanuque',
    'Nantes',
    'Muzambinho',
    'Mutunopolis',
    'Mutum',
    'Mutuipe',
    'Muritiba',
    'Muriaé',
    'Muqui',
    'Muniz Freire',
    'Muniz Ferreira',
    'Munhoz de Melo',
    'Munhoz',
    'Mundo Novo',
    'Muliterno',
    'Mucurici',
    'Mucuri',
    'Mucum',
    'Mucuge',
    'Motuca',
    'Mostardas',
    'Mossamedes',
    'Mozarlandia',
    'Morungaba',
    'Mortugaba',
    'Morro do Chapeu',
    'Morro da Fumaca',
    'Morro Agudo',
    'Morretes',
    'Mormaco',
    'Moreira Sales',
    'Morangaba',
    'Morada Nova de Minas',
    'Montividiu',
    'Montezuma',
    'Monte Verde Paulista',
    'Monte Siao',
    'Montes Claros',
    'Monte Santo de Minas',
    'Monte Santo',
    'Montenegro',
    'Monte Mor',
    'Monteiro Lobato',
    'Monte Gordo',
    'Monte do Carmo',
    'Monte Castelo',
    'Monte Carmelo',
    'Monte Belo',
    'Monte Azul Paulista',
    'Monte Azul',
    'Monte Aprazivel',
    'Monte Alto',
    'Monte Alegre do Sul',
    'Monte Alegre de Sergipe',
    'Monte Alegre de Minas',
    'Montanha',
    'Monsenhor Paulo',
    'Mongagua',
    'Mondai',
    'Moncoes',
    'Mombuca',
    'Moita Bonita',
    'Mogi Mirim',
    'Mogi Guacu',
    'Mogi das Cruzes',
    'Moema',
    'Moeda',
    'Mococa',
    'Messianopolis',
    'Mirim Doce',
    'Mirassolandia',
    'Mirassol',
    'Mirante do Paranapanema',
    'Mirangaba',
    'Mirandopolis',
    'Miranda',
    'Mirai',
    'Mira Estrela',
    'Miradouro',
    'Miracema',
    'Miracatu',
    'Mirabela',
    'Mineiros do Tiete',
    'Mineiros',
    'Minas Novas',
    'Mimoso do Sul',
    'Mimoso',
    'Milho Verde',
    'Miguel Pereira',
    'Miguelopolis',
    'Miguel Calmon',
    'Mesquita',
    'Merces',
    'Mercedes',
    'Mendonca',
    'Mendes Pimentel',
    'Mendes',
    'Meleiro',
    'Medina',
    'Medianeira',
    'Medeiros Neto',
    'Medeiros',
    'Maximiliano de Almeida',
    'Maua',
    'Matozinhos',
    'Mato Verde',
    'Matos Costa',
    'Mato Grosso',
    'Matipo',
    'Matinhos',
    'Matina',
    'Matias Cardoso',
    'Matias Barbosa',
    'Mateus Leme',
    'Matelandia',
    'Mata Verde',
    'Matao',
    'Mata de Sao Joao',
    'Mata',
    'Mascote',
    'Marumbi',
    'Maruim',
    'Martins Soares',
    'Martinopolis',
    'Martinho Campos',
    'Marquinho',
    'Marques de Souza',
    'Marmeleiro',
    'Marlieria',
    'Mariopolis',
    'Marinopolis',
    'Maringá',
    'Mariluz',
    'Marília',
    'Marilena',
    'Marilandia do Sul',
    'Marilandia',
    'Marilac',
    'Marica',
    'Maria Quiteria',
    'Mariapolis',
    'Mariana',
    'Marialva',
    'Maria Helena',
    'Maria da Fe',
    'Vila Maria',
    'Marechal Floriano',
    'Marechal Candido Rondon',
    'Mar de Espanha',
    'Marcelino Ramos',
    'Maravilhas',
    'Maravilha',
    'Marau',
    'Marataizes',
    'Marata',
    'Marapoama',
    'Marape',
    'Maragogipe',
    'Maracas',
    'Maracaju',
    'Maracaja',
    'Maracai',
    'Manoel Viana',
    'Manoel Ribas',
    'Mantenopolis',
    'Mantena',
    'Manhumirim',
    'Manhuacu',
    'Mangueirinha',
    'Mangaratiba',
    'Manga',
    'Manduri',
    'Mandirituba',
    'Mandaguari',
    'Mandaguacu',
    'Mambore',
    'Mambai',
    'Mallet',
    'Malhador',
    'Malhada de Pedras',
    'Malacacheta',
    'Major Vieira',
    'Major Gercino',
    'Mairipotaba',
    'Mairiporã',
    'Mairinque',
    'Mairi',
    'Mage',
    'Magalhaes',
    'Mafra',
    'Maetinga',
    'Madre de Deus de Minas',
    'Madre de Deus',
    'Macuco',
    'Macieira',
    'Machado',
    'Machadinho',
    'Machacalis',
    'Macedonia',
    'Macaubas',
    'Macaubal',
    'Macatuba',
    'Macarani',
    'Macajuba',
    'Macaia',
    'Macaé',
    'Luziania',
    'Luzerna',
    'Luz',
    'Lupionopolis',
    'Lupercio',
    'Luminarias',
    'Luiziania',
    'Luiziana',
    'Luisburgo',
    'Luis Antonio',
    'Luiz Alves',
    'Lucianopolis',
    'Lucelia',
    'Louveira',
    'Lorena',
    'Lontras',
    'Lontra',
    'Londrina',
    'Lobato',
    'Loanda',
    'Lins',
    'Linhares',
    'Lindoia',
    'Limeira',
    'Lima Duarte',
    'Licinio de Almeida',
    'Liberdade',
    'Leopolis',
    'Leopoldo de Bulhoes',
    'Leopoldina',
    'Lencois Paulista',
    'Lencois',
    'Leme do Prado',
    'Leme',
    'Lebon Regis',
    'Leandro Ferreira',
    'Lavrinhas',
    'Lavras do Sul',
    'Lavras',
    'Lavinia',
    'Lauro Muller',
    'Laurentino',
    'Lassance',
    'Laranjeiras do Sul',
    'Laranjeiras',
    'Laranjal Paulista',
    'Laranjal',
    'Laranja da Terra',
    'Lapao',
    'Lapa',
    'Lambari',
    'Lajinha',
    'Lages',
    'Lajedo do Tabocal',
    'Lajedinho',
    'Laje do Muriae',
    'Laje',
    'Laguna Carapa',
    'Laguna',
    'Lagoa Vermelha',
    'Lagoa Santa',
    'Lagoa Real',
    'Lagoao',
    'Lagoa Formosa',
    'Lagoa Dourada',
    'Lagoa dos Patos',
    'Lagoa da Prata',
    'Lagarto',
    'Lagamar',
    'Ladario',
    'Ladainha',
    'Lacerdopolis',
    'Kalore',
    'Juti',
    'Jussiape',
    'Jussari',
    'Jussara',
    'Juruaia',
    'Juritis',
    'Juranda',
    'Juramento',
    'Juquitiba',
    'Juquia',
    'Junqueiropolis',
    'Jundiai do Sul',
    'Jundiaí',
    'Jumirim',
    'Julio de Castilhos',
    'Juiz de Fora',
    'Juatuba',
    'Joviania',
    'Jose Raydan',
    'Jose Bonifacio',
    'Jose Boiteux',
    'Jordania',
    'Joinville',
    'Joia',
    'Joaquim Tavora',
    'Joao Ramalho',
    'Joao Pinheiro',
    'Joao Neiva',
    'Joao Monlevade',
    'Joanopolis',
    'Joaima',
    'Joaçaba',
    'Jitauna',
    'Jiquirica',
    'Jesuania',
    'Jeronimo Monteiro',
    'Jeriquara',
    'Jeremoabo',
    'Jequitinhonha',
    'Jequitai',
    'Jequié',
    'Jequeri',
    'Jeceaba',
    'Jaua',
    'Jaú',
    'Jataizinho',
    'Jatai',
    'Jarinu',
    'Jardinopolis',
    'Jardim Alegre',
    'Jaraguá do Sul',
    'Jaragua',
    'Jaquirana',
    'Japura',
    'Japoata',
    'Japira',
    'Japeri',
    'Japaratuba',
    'Jangada',
    'Jandira',
    'Jandaia do Sul',
    'Jandaia',
    'Janauba',
    'Jambeiro',
    'Jales',
    'Jaguaruna',
    'Jaguariuna',
    'Jaguaripe',
    'Jaguariaiva',
    'Jaguari',
    'Jaguarari',
    'Jaguarao',
    'Jaguaracu',
    'Jaguaquara',
    'Jaguapita',
    'Jacutinga',
    'Jacupiranga',
    'Jacui',
    'Jacobina',
    'Jacinto Machado',
    'Jacinto',
    'Jaciara',
    'Jaci',
    'Jacarezinho',
    'Jacarepagua',
    'Jacareí',
    'Jaguare',
    'Jacaraci',
    'Jaboticabal',
    'Jaboticatubas',
    'Jaboti',
    'Jaborandi',
    'Jabora',
    'Ivoti',
    'Ivolandia',
    'Ivatuba',
    'Ivaipora',
    'Ivai',
    'Iuna',
    'Ituverava',
    'Itutinga',
    'Iturama',
    'Ituporanga',
    'Itupeva',
    'Itumirim',
    'Itumbiara',
    'Ituiutaba',
    'Itueta',
    'Ituberá',
    'Ituacu',
    'Itu',
    'Itobi',
    'Itiuba',
    'Itirucu',
    'Itirapua',
    'Itirapina',
    'Ibitira',
    'Itaverava',
    'Itauna',
    'Itau de Minas',
    'Itaucu',
    'Itatinga',
    'Itatiba',
    'Itatiaiucu',
    'Itatiaia',
    'Itati',
    'Itaruma',
    'Itariri',
    'Itarare',
    'Itarantim',
    'Itarana',
    'Itaqui',
    'Itaquara',
    'Itaquaquecetuba',
    'Itapuranga',
    'Itapura',
    'Itapui',
    "Itaporanga d'Ajuda",
    'Itapora',
    'Itapolis',
    'Itapitanga',
    'Itapirapua',
    'Itapiranga',
    'Itapira',
    'Itapicuru',
    'Itapevi',
    'Itapeva',
    'Itapetininga',
    'Itapetinga',
    'Itaperuna',
    'Itaperucu',
    'Itapemirim',
    'Itapema',
    'Itapoa',
    'Itapejara',
    'Itapecerica da Serra',
    'Itapecerica',
    'Itapebi',
    'Itape',
    'Itaparica',
    'Itapagipe',
    'Itapaci',
    'Itaocara',
    'Itanhomi',
    'Itanhem',
    'Itanhandu',
    'Itanhaem',
    'Itanagra',
    'Itamonte',
    'Itamogi',
    'Itambe do Mato Dentro',
    'Itambaraca',
    'Itambacuri',
    'Itamari',
    'Itamarandiba',
    'Itamaraju',
    'Italva',
    'Itajuipe',
    'Itajuba',
    'Itaju',
    'Itajobi',
    'Itagiba',
    'Itajaí',
    'Itaipe',
    'Itaiopolis',
    'Itaici',
    'Itai',
    'Itaguaru',
    'Itaguara',
    'Itaguaje',
    'Itaguai',
    'Itaguacu',
    'Itagi',
    'Itaete',
    'Itacare',
    'Itacarambi',
    'Itacambira',
    'Itabuna',
    'Itaborai',
    'Itabirito',
    'Itabirinha de Mantena',
    'Itabira',
    'Itaberai',
    'Itaberaba',
    'Itabera',
    'Itaara',
    'Ita',
    'Irupi',
    'Iretama',
    'Irece',
    'Irati',
    'Irara',
    'Iraquara',
    'Irapuru',
    'Miraguai',
    'Irapua',
    'Irani',
    'Irajuba',
    'Iraja',
    'Irai de Minas',
    'Irai',
    'Iracemapolis',
    'Ipupiara',
    'Ipumirim',
    'Ipuiuna',
    'Ipua',
    'Ipora',
    'Ipoema',
    'Ipiranga',
    'Ipira',
    'Ipigua',
    'Ipiau',
    'Ipiacu',
    'Ipeuna',
    'Ipero',
    'Ipe',
    'Ipaucu',
    'Ipatinga',
    'Ipanema',
    'Ipameri',
    'Ipaba',
    'Iomere',
    'Inocencia',
    'Inimutaba',
    'Inhumas',
    'Inhauma',
    'Inhapim',
    'Inhambupe',
    'Inhacora',
    'Indiaroba',
    'Indiapora',
    'Indianopolis',
    'Indiana',
    'Indaiatuba',
    'Indaial',
    'Indaiabira',
    'Inconfidentes',
    'Inacio Martins',
    'Imbituva',
    'Imbituba',
    'Imbe',
    'Imarui',
    'Ilopolis',
    'Ilicinea',
    'Ilhota',
    'Ilhéus',
    'Ilha Solteira',
    'Ilhabela',
    'Ijui',
    'Ijaci',
    'Iguatemi',
    'Iguatama',
    'Iguape',
    'Iguai',
    'Iguaba Grande',
    'Igrejinha',
    'Igreja Nova',
    'Igrapiuna',
    'Igarata',
    'Igarape',
    'Igarapava',
    'Igaracu do Tiete',
    'Igapora',
    'Iepe',
    'Idiolandia',
    'Iconha',
    'Ichu',
    'Icem',
    'Icaraima',
    'Icara',
    'Ibotirama',
    'Ibiuna',
    'Ibitiuva',
    'Ibitita',
    'Ibitinga',
    'Belmiro Braga',
    'Ibiruba',
    'Ibirite',
    'Ibirataia',
    'Ibirarema',
    'Ibirapuita',
    'Ibirapua',
    'Ibirapitanga',
    'Ibirama',
    'Ibiraiaras',
    'Ibiracu',
    'Ibiraci',
    'Ibiracatu',
    'Ibira',
    'Ibipora',
    'Ibipitanga',
    'Santa Rita de Cassia',
    'Ibicui',
    'Ibicoara',
    'Ibicare',
    'Ibicarai',
    'Ibiassuce',
    'Ibiam',
    'Ibiai',
    'Ibiaca',
    'Ibia',
    'Ibatiba',
    'Ibate',
    'Ibarama',
    'Ibaiti',
    'Iaras',
    'Iapu',
    'Iacu',
    'Iacri',
    'Iacanga',
    'Humaita',
    'Hulha Negra',
    'Hortolândia',
    'Horizontina',
    'Herval',
    'Herculandia',
    'Hematita',
    'Heliopolis',
    'Heliodora',
    'Harmonia',
    'Guzolandia',
    'Gurupi',
    'Gurinhata',
    'Guiricema',
    'Guiratinga',
    'Guimarania',
    'Guidoval',
    'Guaxupe',
    'Guatapara',
    'Guatambu',
    'Guarulhos',
    'Guaruja',
    'Guarinos',
    'Guariba',
    'Guarei',
    'Guarda Mor',
    'Guaratuba',
    'Guaratingueta',
    'Guaratinga',
    'Guararema',
    'Guararapes',
    'Guarara',
    'Guaraquecaba',
    'Guarapuava',
    'Guarapari',
    'Guaranta',
    'Guarani dOeste',
    'Guarani das Missoes',
    'Guaraniacu',
    'Guarani',
    'Guaranesia',
    'Guaramirim',
    'Guaraciama',
    'Guaraciaba',
    'Guaraci',
    'Guaracai',
    'Guara',
    'Guaporema',
    'Guapore',
    'Guapo',
    'Guapirama',
    'Guapimirim',
    'Guapiara',
    'Guapiacu',
    'Guape',
    'Guanhaes',
    'Guanambi',
    'Guajeru',
    'Guairaca',
    'Guaira',
    'Guaimbe',
    'Guaicui',
    'Guaicara',
    'Guaianases',
    'Guacui',
    'Gravatal',
    'Gravataí',
    'Grao Para',
    'Grao Mogol',
    'Grandes Rios',
    'Gramado',
    'Gracho Cardoso',
    'Governador Valadares',
    'Governador Lindenberg',
    'Gouvea',
    'Gonzaga',
    'Gongogi',
    'Goncalves',
    'Goitacazes',
    'Goioere',
    'Goiatuba',
    'Goias',
    'Goianira',
    'Goiânia',
    'Goianesia',
    'Goiandira',
    'Goianapolis',
    'Goiabeira',
    'Glorinha',
    'Glicerio',
    'Girua',
    'Getulio Vargas',
    'Getulina',
    'Gentio do Ouro',
    'Sao Vicente do Sul',
    'General Salgado',
    'General Carneiro',
    'General Camara',
    'Gaviao Peixoto',
    'Gaviao',
    'Gaurama',
    'Gastao Vidigal',
    'Gaspar',
    'Garuva',
    'Garopaba',
    'Garibaldi',
    'Garca',
    'Gandu',
    'Galileia',
    'Galia',
    'Gabriel Monteiro',
    'Fundao',
    'Frutal',
    'Fruta de Leite',
    'Frei Paulo',
    'Frei Gaspar',
    'Freguesia',
    'Frederico Westphalen',
    'Franco da Rocha',
    'Francisco Sa',
    'Francisco Morato',
    'Lucas do Rio Verde',
    'Francisco Beltrão',
    'Francisco Alves',
    'Franca',
    'Foz do Iguaçu',
    'Fortaleza dos Valos',
    'Forquilhinha',
    'Formosa',
    'Formigueiro',
    'Formiga',
    'Fontoura Xavier',
    'Florinia',
    'Florida',
    'Florianópolis',
    'Florestopolis',
    'Florestal',
    'Floresta Azul',
    'Flores da Cunha',
    'Floreal',
    'Flora Rica',
    'Florai',
    'Firminopolis',
    'Filadelfia',
    'Figueira',
    'Ferros',
    'Ferraz de Vasconcelos',
    'Fernando Prestes',
    'Fernandopolis',
    'Fernandes Pinheiro',
    'Fenix',
    'Felisburgo',
    'Feliz',
    'Felixlandia',
    'Feira de Santana',
    'Fazenda Nova',
    'Faxinal do Soturno',
    'Faxinal dos Guedes',
    'Faxinal',
    'Fatima',
    'Fartura',
    'Farroupilha',
    'Farol',
    'Fama',
    'Fagundes Varela',
    'Extrema',
    'Eunapolis',
    'Eugenopolis',
    'Euclides da Cunha',
    'Estulandia',
    'Estrela do Sul',
    'Estrela do Norte',
    'Estrela do Indaia',
    'Estrela dOeste',
    'Estrela Dalva',
    'Estrela',
    'Estiva',
    'Esteio',
    'Estancia Velha',
    'Estancia',
    'Espumoso',
    'Esplanada',
    'Espirito Santo do Turvo',
    'Espirito Santo do Dourado',
    'Espinosa',
    'Espera Feliz',
    'Esmeraldas',
    'Herveiras',
    'Erval Velho',
    'Erval Seco',
    'Ervalia',
    'Erval Grande',
    "Erval d'Oeste",
    'Ernestina',
    'Erial',
    'Erechim',
    'Erebango',
    'Entre Rios de Minas',
    'Entre Rios',
    'Entre Ijuis',
    'Entre Folhas',
    'Engenheiro Schmidt',
    'Engenheiro Paulo de Frontin',
    'Engenheiro Beltrao',
    'Engenheiro Balduino',
    'Encruzilhada do Sul',
    'Encruzilhada',
    'Encantado',
    'Embu Guacu',
    'Embu',
    'Eloi Mendes',
    'Elisiario',
    'Elias Fausto',
    'Eldorado',
    'Edeia',
    'Ecoporanga',
    'Echapora',
    'Durande',
    'Duque de Caxias',
    'Duplo Ceu',
    'Dumont',
    'Duas Barras',
    'Duartina',
    'Dracena',
    'Doutor Pedrinho',
    'Doutor Mauricio Cardoso',
    'Doutor Bozano',
    'Dourados',
    'Dourado',
    'Dores do Turvo',
    'Dores do Indaia',
    'Dores de Campos',
    'Dorandia',
    'Dona Francisca',
    'Dona Euzebia',
    'Dom Silverio',
    'Dom Pedrito',
    'Dom Joaquim',
    'Domingos Martins',
    'Dom Feliciano',
    'Dom Cavati',
    'Dom Basilio',
    'Dois Vizinhos',
    'Dois Lajeados',
    'Dois Irmaos',
    'Dois Corregos',
    'Dobrada',
    'Divisa Nova',
    'Dores do Rio Preto',
    'Divisa Alegre',
    'Divinópolis',
    'Divinolandia',
    'Divino das Laranjeiras',
    'Divino',
    'Diorama',
    'Dionisio Cerqueira',
    'Dionisio',
    'Dias davila',
    'Dianópolis',
    'Diamantina',
    'Diadema',
    'Desterro de Entre Rios',
    'Descoberto',
    'Descanso',
    'Descalvado',
    'Delfinopolis',
    'Delfim Moreira',
    'Datas',
    'Damianopolis',
    'Curvelo',
    'Pirambu',
    'Curral de Dentro',
    'Curiuva',
    'Curitibanos',
    'Curitiba',
    'Curimata',
    'Cunha Pora',
    'Cunha',
    'Cumbica',
    'Cumbe',
    'Cuiabá',
    'Cubatao',
    'Cruz Machado',
    'Cruzilia',
    'Cruzeiro do Sul',
    'Cruzeiro dos Peixotos',
    'Cruzeiro do Oeste',
    'Cruzeiro da Fortaleza',
    'Cruzeiro',
    'Cruz das Almas',
    'Santa Cruz da Esperanca',
    'Cruz Alta',
    'Crominia',
    'Crixas',
    'Cristina',
    'Cristianopolis',
    'Cristiano Otoni',
    'Cristalina',
    'Cristalia',
    'Cristalandia',
    'Cristais',
    'Crissiumal',
    'Crisopolis',
    'Criciúma',
    'Cravinhos',
    'Coxim',
    'Cotipora',
    'Cotia',
    'Cosmorama',
    'Cosmopolis',
    'Cosmos',
    'Corvo',
    'Coruripe',
    'Corupa',
    'Corumbatai',
    'Corumbaiba',
    'Corumba de Goias',
    'Corumbá',
    'Correia Pinto',
    'Corrego Fundo',
    'Corrego do Ouro',
    'Corrego Danta',
    'Coronel Vivida',
    'Coronel Pacheco',
    'Coronel Murta',
    'Coronel Martins',
    'Coronel Macedo',
    'Coronel Freitas',
    'Coronel Fabriciano',
    'Coronel Bicaco',
    'Coronel Barros',
    'Coromandel',
    'Coroados',
    'Coroaci',
    'Cornelio Procopio',
    'Corinto',
    'Coribe',
    'Corguinho',
    'Cordisburgo',
    'Cordeiropolis',
    'Cordeiro',
    'Corbelia',
    'Coracao de Maria',
    'Coracao de Jesus',
    'Coqueiral',
    'Contendas do Sincora',
    'Contendas',
    'Contenda',
    'Contagem',
    'Constantina',
    'Conservatoria',
    'Conselheiro Pena',
    'Conselheiro Mairinck',
    'Conselheiro Lafaiete',
    'Congonhinhas',
    'Congonhas',
    'Condor',
    'Condeuba',
    'Concordia',
    'Conchas',
    'Conchal',
    'Conceicao dos Ouros',
    'Conceicao do Rio Verde',
    'Conceicao do Para',
    'Conceicao do Tocantins',
    'Rio da Conceicao',
    'Conceicao do Mato Dentro',
    'Conceicao do Coite',
    'Conceicao do Castelo',
    'Conceicao do Almeida',
    'Conceicao de Macabu',
    'Conceicao do Jacuipe',
    'Conceicao de Jacarei',
    'Conceicao de Ipanema',
    'Conceicao das Alagoas',
    'Conceicao da Feira',
    'Conceicao da Barra',
    'Conceicao da Aparecida',
    'Comercinho',
    'Comendador Gomes',
    'Coluna',
    'Colorado',
    'Colombo',
    'Colombia',
    'Colina',
    'Colatina',
    'Coimbra',
    'Cocalinho',
    'Coaraci',
    'Clevelandia',
    'Clementina',
    'Claudio',
    'Claro dos Pocoes',
    'Ciriaco',
    'Cipotanea',
    'Cipo',
    'Cidreira',
    'Cidade Gaucha',
    'Cicero Dantas',
    'Cianorte',
    'Chui',
    'Chopinzinho',
    'Chonim',
    'Nova Xavantina',
    'Charqueadas',
    'Charqueada',
    'Doutor Camargo',
    'Chapecó',
    'Chapada dos Guimaraes',
    'Chapada',
    'Chacara',
    'Ceu Azul',
    'Cesario Lange',
    'Cerro Negro',
    'Cerro Largo',
    'Cerro Grande',
    'Cerro Azul',
    'Cerrito',
    'Cerquilho',
    'Cerqueira Cesar',
    'Ceres',
    'Centralina',
    'Central',
    'Centenario do Sul',
    'Celso Ramos',
    'Celina',
    'Ceilandia',
    'Cedro do Abaete',
    'Cedro de Sao Joao',
    'Cedral',
    'Caxias do Sul',
    'Caxambu',
    'Caturama',
    'Caturai',
    'Catuji',
    'Catu',
    'Catigua',
    'Catas Altas',
    'Catanduvas',
    'Catanduva',
    'Catalao',
    'Cataguases',
    'Castro Alves',
    'Castro',
    'Castilho',
    'Castelo',
    'Cassilandia',
    'Cassia dos Coqueiros',
    'Cassia',
    'Casimiro de Abreu',
    'Casca',
    'Casa Branca',
    'Carvalhos',
    'Carrancas',
    'Carmopolis de Minas',
    'Carmopolis',
    'Carmo do Rio Verde',
    'Carmo do Rio Claro',
    'Carmo do Paranaiba',
    'Carmo do Cajuru',
    'Carmo de Minas',
    'Carmo da Mata',
    'Carmo da Cachoeira',
    'Carmo',
    'Carlos Gomes',
    'Carlos Chagas',
    'Carlos Barbosa',
    'Carlopolis',
    'Cariri do Tocantins',
    'Carira',
    'Carinhanha',
    'Cariacica',
    'Careacu',
    'Cardoso Moreira',
    'Cardoso',
    'Cardeal da Silva',
    'Carbonita',
    'Carazinho',
    'Caratinga',
    'Caarapo',
    'Carapicuiba',
    'Carapebus',
    'Carangola',
    'Carandai',
    'Carambei',
    'Caraibas',
    'Carai',
    'Caraguatatuba',
    'Caputira',
    'Capivari',
    'Capitolio',
    'Capinzal',
    'Capinopolis',
    'Capim Grosso',
    'Capim Branco',
    'Capetinga',
    'Capelinha',
    'Capela do Alto Alegre',
    'Capela do Alto',
    'Capao do Leao',
    'Capao da Canoa',
    'Capao Bonito',
    'Capao Alto',
    'Cantagalo',
    'Cansancao',
    'Canoinhas',
    'Canoas',
    'Canitar',
    'Canhoba',
    'Cangucu',
    'Cangonhal',
    'Cangas',
    'Canelinha',
    'Canela',
    'Candiota',
    'Candido Rodrigues',
    'Candido Mota',
    'Candido Godoi',
    'Candido de Abreu',
    'Candelaria',
    'Candeal',
    'Canavieiras',
    'Canas',
    'Canarana',
    'Canapolis',
    'Cananeia',
    'Canaa',
    'Campos Novos Paulista',
    'Campos Novos',
    'Campos Gerais',
    'Campos do Jordao',
    'Campos Borges',
    'Campos Belos',
    'Campos Altos',
    'Campos dos Goytacazes',
    'Campo Novo',
    'Campo Mourao',
    'Campo Magro',
    'Campo Largo',
    'Campo Florido',
    'Campo Ere',
    'Campo do Tenente',
    'Campo do Meio',
    'Campo do Brito',
    'Campo Bom',
    'Campo Belo',
    'Campo Azul',
    'Campo Alegre de Goias',
    'Campinho',
    'Campina Verde',
    'Campinas do Sul',
    'Campinas',
    'Campina Grande do Sul',
    'Campina do Monte Alegre',
    'Campina do Amoral',
    'Campina da Lagoa',
    'Campestre',
    'Campanha',
    'Cambuquira',
    'Cambui',
    'Cambuci',
    'Cambira',
    'Cambe',
    'Cambara',
    'Camargo',
    'Camaqua',
    'Camanducaia',
    'Camamu',
    'Camacho',
    'Camaçari',
    'Camacari',
    'Camacan',
    'Calmon',
    'California',
    'Caldas Novas',
    'Caldas',
    'Cajuru',
    'Cajobi',
    'Cajati',
    'Cajamar',
    'Caiua',
    'Cairu',
    'Caieiras',
    'Caicara',
    'Caibate',
    'Caiaponia',
    'Cafelandia',
    'Cafeara',
    'Cafarnaum',
    'Caetite',
    'Caete',
    'Caetanopolis',
    'Caem',
    'Cacule',
    'Cacu',
    'Caconde',
    'Cachoeiro de Itapemirim',
    'Cachoeiras de Macacu',
    'Cachoeira Paulista',
    'Cachoeira Dourada',
    'Cachoeira do Sul',
    'Cachoeira do Campo',
    'Cachoeira de Minas',
    'Cachoeira',
    'Cacequi',
    'Cacapava do Sul',
    'Cacapava',
    'Caçador',
    'Cabreuva',
    'Cabralia Paulista',
    'Cabo Verde',
    'Cabo Frio',
    'Cabeceiras',
    'Cabaceiras do Paraguacu',
    'Butia',
    'Buritizeiro',
    'Buritizal',
    'Buritis',
    'Buritirama',
    'Buriti Alegre',
    'Buritama',
    'Buri',
    'Boquim',
    'Buerarema',
    'Buenopolis',
    'Bueno Brandao',
    'Brusque',
    'Brumado',
    'Brumadinho',
    'Brotas de Macaubas',
    'Brotas',
    'Brodosqui',
    'Brochier',
    'Britania',
    'Brejoes',
    'Brejo Alegre',
    'Brejetuba',
    'Brasopolis',
    'Braunas',
    'Brauna',
    'Bras Pires',
    'Brasilandia',
    'Brasilia de Minas',
    'Brasília',
    'Brasil',
    'Braganca Paulista',
    'Braga',
    'Braco do Trombudo',
    'Braco do Norte',
    'Botuvera',
    'Botupora',
    'Botumirim',
    'Botucatu',
    'Botelhos',
    'Bossoroca',
    'Borrazopolis',
    'Holambra',
    'Borda da Mata',
    'Borborema',
    'Boraceia',
    'Boquira',
    'Boqueirao do Leao',
    'Bonfinopolis',
    'Bom Retiro do Sul',
    'Bom Retiro',
    'Bom Repouso',
    'Bom Jesus dos Perdoes',
    'Bom Jesus do Norte',
    'Bom Jesus do Itabapoana',
    'Bom Jesus do Galho',
    'Bom Jesus da Penha',
    'Bom Jesus da Lapa',
    'Bom Jardim de Minas',
    'Bom Jardim de Goias',
    'Bom Jardim da Serra',
    'Bom Despacho',
    'Boituva',
    'Bofete',
    'Bocaiuva do Sul',
    'Bocaiuva',
    'Bocaina do Sul',
    'Bocaina de Minas',
    'Bocaina',
    'Boa Vista do Burica',
    'Boa Vista',
    'Boa Ventura',
    'Boa Nova',
    'Boa Esperanca do Sul',
    'Boa Esperanca',
    'Blumenau',
    'Bituruna',
    'Bismarque',
    'Biritinga',
    'Biritiba Mirim',
    'Birigui',
    'Biquinhas',
    'Bilac',
    'Biguacu',
    'Bicas',
    'Betim',
    'Bernardino de Campos',
    'Berilo',
    'Bento Gonçalves',
    'Bento de Abreu',
    'Benedito Novo',
    'Belo Vale',
    'Belo Oriente',
    'Belo Horizonte',
    'Belmonte',
    'Belisario',
    'Belford Roxo',
    'Belem Velho',
    'Bela Vista do Toldo',
    'Bela Vista do Paraiso',
    'Bela Vista de Goias',
    'Bela Vista',
    'Bebedouro',
    'Baus',
    'Bauru',
    'Batatais',
    'Bataipora',
    'Bataguassu',
    'Bastos',
    'Barueri',
    'Barroso',
    'Barros Cassal',
    'Barro Preto',
    'Barrocas',
    'Barro Alto',
    'Barrinha',
    'Barretos',
    'Barreiro da Raiz',
    'Barreiras',
    'Barra Velha',
    'Barra Mansa',
    'Barra Longa',
    'Barra dos Coqueiros',
    'Barra do Ribeiro',
    'Barra do Piraí',
    'Barra do Jacare',
    'Barra do Cuiete',
    'Barra do Bugres',
    'Barra de Sao Joao',
    'Barra de Sao Francisco',
    'Barra da Estiva',
    'Barra do Choca',
    'Barracao',
    'Barra Bonita',
    'Barra',
    'Bariri',
    'Barbosa Ferraz',
    'Barbacena',
    'Barao do Triunfo',
    'Barao de Cotegipe',
    'Barao de Cocais',
    'Barao de Antonina',
    'Barao',
    'Bangu',
    'Bandeirantes',
    'Bandeira do Sul',
    'Bandeira',
    'Bananal',
    'Bambui',
    'Balsa Nova',
    'Balsamo',
    'Balneário Camboriú',
    'Baixo Guandu',
    'Baixa Grande',
    'Bagé',
    'Baependi',
    'Bady Bassitt',
    'Avare',
    'Avanhandava',
    'Aurilandia',
    'Auriflama',
    'aurea',
    'Augusto de Lima',
    'Atibaia',
    'Ataleia',
    'Astorga',
    'Astolfo Dutra',
    'Assis',
    'Assai',
    'Ascurra',
    'Arvorezinha',
    'Arujá',
    'Aruana',
    'Artur Nogueira',
    'Arroio Trinta',
    'Arroio Grande',
    'Arroio do Tigre',
    'Arroio dos Ratos',
    'Arroio do Silva',
    'Arroio do Meio',
    'Arraias',
    'Arraial do Cabo',
    'Armazem',
    'Armacao de Buzios',
    'Ariranha',
    'Arinos',
    'Argirita',
    'Arembepe',
    'Areiopolis',
    'Arealva',
    'Areal',
    'Areado',
    'Arcos',
    'Arceburgo',
    'Araxá',
    'Araujos',
    'Araucária',
    'Aratuipe',
    'Aratiba',
    'Araruama',
    'Ararica',
    'Araras',
    'Araraquara',
    'Ararangua',
    'Araquari',
    'Arapua',
    'Arapoti',
    'Arapora',
    'Arapongas',
    'Aramina',
    'Arambare',
    'Aramari',
    'Araguari',
    'Araguacu',
    'Aragoiania',
    'Aragarcas',
    'Aracui',
    'Aracuai',
    'Aracu',
    'Aracruz',
    'Aracoiaba da Serra',
    'Aracitaba',
    'Araci',
    'Araçatuba',
    'Aracatu',
    'Aracas',
    'Aracariguama',
    'Aracaju',
    'Aracai',
    'Arabuta',
    'Aquidaba',
    'Apucarana',
    'Apora',
    'Apiuna',
    'Apiai',
    'Apiaca',
    'Aperibe',
    'Aparecida do Taboado',
    "Aparecida d'Oeste",
    'Antonio Prado',
    'Antonio Olinto',
    'Antonio Dias',
    'Antonio Carlos',
    'Antonina',
    'Anitapolis',
    'Anita Garibaldi',
    'Anicuns',
    'Anhumas',
    'Anhembi',
    'Anhanguera',
    'Angulo',
    'Anguera',
    'Angra dos Reis',
    'Angical',
    'Angelina',
    'Angatuba',
    'Andrelandia',
    'Andre da Rocha',
    'Andradina',
    'Andradas',
    'Andorinha',
    'Andira',
    'Andarai',
    'Anchieta',
    'Anaurilandia',
    'Anastacio',
    'Anápolis',
    'Analandia',
    'Anage',
    'Ampere',
    'Amparo de Sao Francisco',
    'Amparo do Serra',
    'Amparo',
    'Americo de Campos',
    'Americo Brasiliense',
    'Americana',
    'Amargosa',
    'Amarantina',
    'Amaral Ferrador',
    'Amapora',
    'Amambai',
    'Alvorada do Sul',
    'Alvorada',
    'Alvinopolis',
    'Alvinlandia',
    'Alvaro de Carvalho',
    'Alvares Machado',
    'Alvares Florence',
    'Alto Rio Novo',
    'Alto Rio Doce',
    'Alto Piquiri',
    'Alto Parana',
    'Alto Paraiso de Goias',
    'Alto Mutum Preto',
    'Alto Garcas',
    'Alto Araguaia',
    'Alto Alegre',
    'Altinopolis',
    'Alterosa',
    'Altair',
    'Alpinopolis',
    'Alpercata',
    'Almirante Tamandare',
    'Almenara',
    'Almas',
    'Almadina',
    'Alfredo Wagner',
    'Alfredo Marcondes',
    'Alfredo Chaves',
    'Alfenas',
    'Alexandrita',
    'Alem Paraiba',
    'Alegria',
    'Alegre',
    'Alecrim',
    'Alcobaca',
    'Albertina',
    'Alambari',
    'Alagoinhas',
    'Alagoa',
    'Ajuricaba',
    'Aiuruoca',
    'Aimores',
    'Aguia Branca',
    'Agudos do Sul',
    'Agudos',
    'Agudo',
    'Agua Vermelha',
    'Aguas Vermelhas',
    'Aguas Formosas',
    'Aguas de Sao Pedro',
    'Aguas de Lindoia',
    'Aguas de Chapeco',
    'Aguas da Prata',
    'Aguanil',
    'Agua Limpa',
    'Aguai',
    'Agua Fria',
    'Agua Doce',
    'Agua Boa',
    'Afonso Claudio',
    'Adustina',
    'Adolfo',
    'Adamantina',
    'Acucena',
    'Acegua',
    'Acajutiba',
    'Acaiaca',
    'Abre Campo',
    'Abrantes',
    'Abelardo Luz',
    'Abdon Batista',
    'Abatia',
    'Abaete',
    'Abadiania',
    'Abadia dos Dourados',
    'Leblon',
    'Flamengo',
    'Guaíba',
    'Comodoro',
    'Vilar dos Teles',
    'Agostinho Porto',
    'Honorio Gurgel',
    'Deodoro',
    'Padre Miguel',
    'Bombinhas',
    'Pranchita',
    'Pontal do Parana',
    'Nova Rosalandia',
    'Palmeiropolis',
    'Porto Alegre do Tocantins',
    'Sao Felix do Tocantins',
    'Silvanopolis',
    'Sucupira',
    'Alianca do Tocantins',
    'Paraiso do Tocantins',
    'Maceio',
    'Comendador Levy Gasparian',
    'Governador Portela',
    'Engenheiro Pedreira',
    'Tupari',
    'Tefé',
    'Rio Branco',
    'Porto Velho',
    'Novo Aripuana',
    'Minas Gerais',
    'Mancio Lima',
    'Manacapuru',
    'Coari',
    'Caapiranga',
    'Ariquemes',
    'Iranduba',
    'Xapuri',
    'Vilhena',
    'Rondonia',
    'Presidente Medici',
    'Posto Fiscal Rolim de Moura',
    'Placido de Castro',
    'Pimenteiras',
    'Pimenta Bueno',
    'Ouro Preto do Oeste',
    'Mamore',
    'Jaru',
    'Guajará-Mirim',
    'Espigao dOeste',
    'Costa Marques',
    'Cacoal',
    'Brasileia',
    "Nova Brasilandia d'Oeste",
    'Cacaulandia',
    'Theobroma',
    'Teixeiropolis',
    'Alto Alegre dos Parecis',
    'Abadia de Goias',
    'Abel Figueiredo',
    'Acrelandia',
    'Acreuna',
    'Adelandia',
    'Agrolandia',
    'Agronomica',
    'Agua Doce do Norte',
    'Aguas de Santa Barbara',
    'Aguas Frias',
    'Aguas Lindas de Goias',
    'Aguas Mornas',
    'Aguiarnopolis',
    'Alcinopolis',
    'Alexania',
    'Alfredo Vasconcelos',
    'Alta Floresta',
    'Alto Alegre do Maranhao',
    'Alto Boa Vista',
    'Alto Caparao',
    'Alto Horizonte',
    'Alto Jequitiba',
    'Alto Taquari',
    'Aluminio',
    'Alvorada do Norte',
    "Alvorada d'Oeste",
    'Amelia Rodrigues',
    'Ametista do Sul',
    'Angelandia',
    'Antonio Goncalves',
    'Antonio Joao',
    'Antonio Prado de Minas',
    'Aparecida de Goiania',
    'Aparecida do Rio Doce',
    'Aripuana',
    'Ariranha do Ivai',
    'Arroio do Padre',
    'Arroio do Sal',
    'Assis Chateaubriand',
    'Assuncao do Piaui',
    'Atalanta',
    'Atilio Vivacqua',
    'Augustinopolis',
    'Augusto Pestana',
    'Aurelino Leal',
    'Avelino Lopes',
    'Bacabeira',
    'Baldim',
    'Balneario Arroio do Silva',
    'Balneario Barra do Sul',
    'Balneario Gaivota',
    'Balneario Pinhal',
    'Banabuiu',
    'Banzae',
    'Barra do Guarita',
    'Barra do Ouro',
    'Bela Vista de Minas',
    'Bela Vista do Maranhao',
    'Belem do Piaui',
    'Berizal',
    'Boa Vista da Aparecida',
    'Boa Vista do Gurupi',
    'Boa Vista do Sul',
    'Bodoquena',
    'Bom Jesus de Goias',
    'Bom Jesus do Oeste',
    'Bom Jesus do Tocantins',
    'Bom Sucesso do Sul',
    'Bonfinopolis de Minas',
    'Boqueirao do Piaui',
    'Braco do Rio',
    'Braganey',
    'Brasilandia de Minas',
    'Brasnorte',
    'Breu Branco',
    'Brunopolis',
    'Buriticupu',
    'Cabeceira Grande',
    'Cabixi',
    'Cabo de Santo Agostinho',
    'Cachoeira de Pajeu',
    'Cafezal do Sul',
    'Caibi',
    'Caldas Brandao',
    'Campestre da Serra',
    'Campestre de Goias',
    'Campestre do Maranhao',
    'Campina das Missoes',
    'Campinacu',
    'Campo Grande do Piaui',
    'Campo Limpo Paulista',
    'Campo Novo de Rondonia',
    'Campo Novo do Parecis',
    'Campos Verdes',
    'Cana Brava do Norte',
    'Candeias do Jamari',
    'Candido Sales',
    'Candoi',
    'Capinzal do Norte',
    'Capitao',
    'Capitao Andrade',
    'Capitao Eneas',
    'Capitao Leonidas Marques',
    'Capivari de Baixo',
    'Capivari do Sul',
    'Cardeal',
    'Carneirinho',
    'Castelandia',
    'Catuti',
    'Caxambu do Sul',
    'Cezarina',
    'Chapada do Norte',
    'Chapadao do Ceu',
    'Chapadao do Lageado',
    'Chupinguaia',
    'Chuvisca',
    'Cidade Ocidental',
    'Cidelandia',
    'Claudia',
    'Cocal de Telha',
    'Cocal do Sul',
    'Cocalzinho de Goias',
    'Colider',
    'Colniza',
    'Colonia do Gurgueia',
    'Colorado do Oeste',
    'Combinado',
    'Concordia do Para',
    'Cordilheira Alta',
    'Cordislandia',
    'Coronel Domingos Soares',
    'Coronel Joao Sa',
    'Coronel Sapucaia',
    'Coronel Xavier Chaves',
    'Corumbatai do Sul',
    'Costa Rica',
    'Cravolandia',
    'Cristais Paulista',
    'Cristal',
    'Cristopolis',
    'Cruzaltense',
    'Cruzeiro do Iguacu',
    'Cruzmaltina',
    'Cuite de Mamanguape',
    'Curionopolis',
    'Curvelandia',
    'Damiao',
    'Darcinopolis',
    'David Canabarro',
    'Delta',
    'Deodapolis',
    'Deputado Irapuan Pinheiro',
    'Diamante do Norte',
    'Dilermano de Aguiar',
    'Divinesia',
    'Divinolandia de Minas',
    'Dois Irmaos das Missoes',
    'Dois Irmaos do Buriti',
    'Dom Aquino',
    'Dom Bosco',
    'Dona Emma',
    'Dormentes',
    'Douradina',
    'Doutor Ricardo',
    'Edealina',
    'Eldorado do Sul',
    'Eldorado dos Carajas',
    'Engenheiro Caldas',
    'Engenheiro Coelho',
    'Entre Rios do Oeste',
    'Entre Rios do Sul',
    'Espigao Alto do Iguacu',
    'Estiva Gerbi',
    'Estrela de Alagoas',
    'Estrela Velha',
    'Euclides da Cunha Paulista',
    'Ewbank da Camara',
    'Fatima do Sul',
    'Fazenda Rio Grande',
    'Fazenda Vilanova',
    'Fernandes Tourinho',
    'Flor da Serra do Sul',
    'Floresta do Araguaia',
    'Florida Paulista',
    'Formosa do Oeste',
    'Formosa do Sul',
    'Fortaleza de Minas',
    'Fortuna de Minas',
    'Fraiburgo',
    'Francisco Dumont',
    'Franciscopolis',
    'Fronteira',
    'Gaucha do Norte',
    'Geminiano',
    'Gloria de Dourados',
    'Godofredo Viana',
    'Governador Celso Ramos',
    'Governador Edison Lobao',
    'Governador Jorge Teixeira',
    'Governador Luiz Rocha',
    'Governador Mangabeira',
    'Guabiruba',
    'Guaipora',
    'Guaraita',
    'Guarani de Goias',
    'Guaranta do Norte',
    'Guaruja do Sul',
    'Heitorai',
    'Honorio Serpa',
    'Ibema',
    'Ibitiura de Minas',
    'Icarai de Minas',
    'Igaracy',
    'Igarape do Meio',
    'Igarape Grande',
    'Imbuia',
    'Imigrante',
    'Inaciolandia',
    'Indiara',
    'Ipora do Oeste',
    'Itabela',
    'Itagimirim',
    'Itaguari',
    'Itaipava do Grajau',
    'Itaipulandia',
    'Itaja',
    'Itamarati de Minas',
    'Itaobim',
    'Itapirapua Paulista',
    'Itaquirai',
    'Itatim',
    'Itinga do Maranhao',
    'Ivate',
    'Ivinhema',
    'Jaboatao dos Guararapes',
    'Jacunda',
    'Jaiba',
    'Janiopolis',
    'Japora',
    'Jauru',
    'Jenipapo dos Vieiras',
    'Jesuitas',
    'Jesupolis',
    'Jijoca de Jericoacoara',
    'Joca Marques',
    'Jose Goncalves de Minas',
    'Joselandia',
    'Juara',
    'Juscimeira',
    'Juvenilia',
    'Lafaiete Coutinho',
    'Lago da Pedra',
    'Lagoa de Dentro',
    'Lagoa do Mato',
    'Lago dos Rodrigues',
    'Lagoa Grande do Maranhao',
    'Lajeado do Bugre',
    'Lauro de Freitas',
    'Leoberto Leal',
    'Lidianopolis',
    'Lima Campos',
    'Limeira do Oeste',
    'Lindoeste',
    'Lindoia do Sul',
    'Lindolfo Collor',
    'Logradouro',
    'Lunardelli',
    'Luzinopolis',
    'Mae do Rio',
    'Mara Rosa',
    'Maranhaozinho',
    'Marcionilio Souza',
    'Mario Campos',
    'Maripa de Minas',
    'Marituba',
    'Marmelopolis',
    'Mateiros',
    'Mathias Lobato',
    'Matrincha',
    'Maua da Serra',
    'Maurilandia',
    'Mesopolis',
    'Messias Targino',
    'Milton Brandao',
    'Minacu',
    'Minas do Leao',
    'Ministro Andreazza',
    'Miranda do Norte',
    'Mirante da Serra',
    'Miravania',
    'Missal',
    'Montadas',
    'Montalvania',
    'Monte Alegre dos Campos',
    'Monte Carlo',
    'Montes Claros de Goias',
    'Montividiu do Norte',
    'Moreilandia',
    'Morrinhos do Sul',
    'Morro Grande',
    'Natalandia',
    'Nicolau Vergueiro',
    'Nina Rodrigues',
    'Nova Alexandria',
    'Nova Alvorada do Sul',
    'Nova Andradina',
    'Nova Boa Vista',
    'Nova Canaa Paulista',
    'Nova Candelaria',
    'Nova Cantu',
    'Nova Erechim',
    'Nova Esperanca do Piria',
    'Nova Esperanca do Sudoeste',
    'Nova Gloria',
    'Nova Hartz',
    'Nova Iguacu de Goias',
    'Nova Ipixuna',
    'Nova Itaberaba',
    'Nova Mamore',
    'Nova Maringa',
    'Nova Olinda do Maranhao',
    'Nova Prata do Iguacu',
    'Nova Redencao',
    'Nova Roma do Sul',
    'Nova Santa Rita',
    'Nova Santa Rosa',
    'Nova Tebas',
    'Novo Alegre',
    'Novo Barreiro',
    'Novo Cabrais',
    'Novo Gama',
    'Novo Horizonte do Norte',
    'Novo Horizonte do Sul',
    'Novo Machado',
    'Novo Mundo',
    'Novo Oriente de Minas',
    'Novo Tiradentes',
    'Novo Triunfo',
    'Novorizonte',
    'Ourilandia do Norte',
    'Ouro Verde de Goias',
    'Ouro Verde do Oeste',
    'Ouroeste',
    'Ourolandia',
    'Pacaja',
    'Pacaraima',
    'Paco do Lumiar',
    'Padre Carvalho',
    'Paial',
    'Palestina de Goias',
    'Palma Sola',
    'Palmeirandia',
    'Paranaiguara',
    'Passa e Fica',
    'Patos do Piaui',
    'Pedra Branca do Amapari',
    'Pedra Preta',
    'Pedrinhas Paulista',
    'Pedro Alexandre',
    'Pedro Canario',
    'Pedro Osorio',
    'Penaforte',
    'Peritiba',
    'Petrolandia',
    'Picada Cafe',
    'Piedade de Caratinga',
    'Piloezinhos',
    'Pimenteiras do Oeste',
    'Pinhais',
    'Pinhal de Sao Bento',
    'Pinheirinho do Vale',
    'Pinheiros',
    'Planaltino',
    'Planalto Alegre',
    'Pontao',
    'Ponte Branca',
    'Ponto Belo',
    'Ponto dos Volantes',
    'Porteirao',
    'Portelandia',
    'Porto Alegre do Norte',
    'Porto Vilma',
    'Potim',
    'Prado Ferreira',
    'Presidente Janio Quadros',
    'Presidente Kennedy',
    'Presidente Tancredo Neves',
    'Primavera de Rondonia',
    'Professor Jamil',
    'Quarto Centenario',
    'Quedas do Iguacu',
    'Quitandinha',
    'Quiterianopolis',
    'Quixabeira',
    'Rafael Jambeiro',
    'Ramilandia',
    'Rancho Alegre dOeste',
    'Raposa',
    'Riachao do Poco',
    'Ribeirao Cascalheira',
    'Rio Bonito do Iguacu',
    'Rio Branco do Ivai',
    'Rio Quente',
    'Riqueza',
    'Ritapolis',
    'Riversul',
    'Rondon do Para',
    'Rosario do Ivai',
    'Rosario do Pontal',
    'Salete',
    'Salto do Jacui',
    'Salto do Lontra',
    'Salto Veloso',
    'Sanclerlandia',
    'Santa Barbara de Goias',
    'Santa Barbara do Leste',
    'Santa Cruz de Minas',
    'Santa Cruz do Banabuiu',
    'Santa Cruz Do Xingu',
    'Santa Efigenia de Minas',
    'Santa Fe de Goias',
    'Santa Fe de Minas',
    'Santa Izabel do Oeste',
    'Santa Luzia do Para',
    'Santa Maria de Jetiba',
    'Santa Maria do Cambuca',
    'Santa Rita do Pardo',
    'Santa Rosa da Serra',
    'Santa Rosa de Goias',
    'Santa Rosa de Lima',
    'Santa Rosa do Sul',
    'Santa Tereza do Oeste',
    'Santa Terezinha',
    'Santa Terezinha de Itaipu',
    'Santana do Piaui',
    'Santana do Riacho',
    'Joca Claudino',
    'Santo Antonio da Barra',
    'Santo Antonio das Missoes',
    'Santo Antonio de Goias',
    'Santo Antonio do Descoberto',
    'Santo Expedito do Sul',
    'Santopolis do Aguapei',
    'Sao Bento Abade',
    'Sao Bernardino',
    'Sao Cristovao do Sul',
    'Sao Domingos das Dores',
    'Sao Domingos do Araguaia',
    'Sao Domingos do Norte',
    "Sao Felipe d'Oeste",
    'Sao Felix de Minas',
    'Sao Francisco de Itabapoana',
    'Sao Francisco do Brejao',
    'Sao Francisco do Guapore',
    'Sao Francisco do Para',
    'Sao Gabriel da Palha',
    'Sao Joao da Canabrava',
    'Sao Joao da Fronteira',
    'Sao Joao das Missoes',
    'Sao Joao do Ivai',
    'Sao Joao do Polesine',
    'Sao Joao do Soter',
    'Sao Jorge do Patrocinio',
    'Sao Jose das Missoes',
    'Sao Jose das Palmeiras',
    'Sao Jose do Rio Claro',
    'Sao Jose do Vale do Rio Preto',
    'Sao Jose do Xingu',
    'Sao Jose dos Ausentes',
    'Sao Jose dos Quatro Marcos',
    'Sao Jose dos Ramos',
    'Sao Ludgero',
    'Sao Luis Gonzaga do Maranhao',
    'Sao Luiz do Norte',
    'Sao Miguel da Baixa Grande',
    'Sao Miguel do Aleixo',
    'Sao Miguel do Guapore',
    'Sao Miguel do Passa Quatro',
    'Sao Paulo das Missoes',
    'Sao Pedro da agua Branca',
    'Sao Pedro do Butia',
    'Sao Pedro do Iguacu',
    'Sao Pedro dos Crentes',
    'Sao Roque do Canaa',
    'Sao Sebastiao da Vargem Alegre',
    'Sao Sebastiao do Anta',
    'Sao Sebastiao do Oeste',
    'Sapezal',
    'Saudade do Iguacu',
    'Sede Alvorada',
    'Senador Alexandre Costa',
    'Senador Eloi de Souza',
    'Senador La Rocque',
    'Senador Rui Palmeira',
    'Sentinela do Sul',
    'Serra Dourada',
    'Serranopolis do Iguacu',
    'Sete Quedas',
    'Simoes Filho',
    'Simolandia',
    'Sinop',
    'Sitio Novo do Tocantins',
    'Sooretama',
    'Sorriso',
    'Sul Brasil',
    'Tailandia',
    'Tanque do Piaui',
    'Tapurah',
    'Taquarucu do Sul',
    'Teixeira de Freitas',
    'Tenente Laurentino Cruz',
    'Teofilandia',
    'Teolandia',
    'Teotonio Vilela',
    'Terezopolis de Goias',
    'Timbo Grande',
    'Travesseiro',
    'Tres Barras do Parana',
    'Trindade do Sul',
    'Tucuma',
    'Tunapolis',
    'Tunas do Parana',
    'Tupanci do Sul',
    'Tupassi',
    'Turilandia',
    'Turvelandia',
    'Turvolandia',
    'Ubirata',
    'Ulianopolis',
    'Uniao do Oeste',
    'Uruana de Minas',
    'Uruara',
    'Urupa',
    'Vale do Anari',
    'Vale dos Vinhedos',
    'Vale Real',
    'Vale Verde',
    'Valparaiso de Goias',
    'Vargeao',
    'Vargem Grande Paulista',
    'Varjao de MInas',
    'Venda Nova do Imigrante',
    'Venha Ver',
    'Vera',
    'Vera Cruz do Oeste',
    'Vereda',
    'Vertente do Lerio',
    'Vicentina',
    'Vicentinopolis',
    'Vila Boa',
    'Vila Nova do Piaui',
    'Vila Nova do Sul',
    'Vila Nova dos Martirios',
    'Vila Pavao',
    'Vila Propicio',
    'Vila Valerio',
    'Vista Serrana',
    'Vitor Meireles',
    'Vitoria das Missoes',
    'Wenceslau Guimaraes',
    'Xangri la',
    'Zortea',
    'Monte Belo do Sul',
    'Salvador do Sul',
    'Sapucaia do Sul',
    'Barra de Guabiraba',
    'Caicara do Norte',
    'Cambara do Sul',
    'Ipiranga do Sul',
    'Recanto das Emas',
    'Taguatinga',
    'Barra da Tijuca',
    'Jardim Botanico',
    'Praca da Bandeira',
    'Cerejeiras',
    'Nossa Senhora Das Gracas',
    'Jacare',
    'Jardim Paulista',
    'Morro de Sao Paulo',
    'Barros Filho',
    'Itanhanga',
    "Olho d'Agua das Cunhas",
    'Itabata',
    "Mirassol d'Oeste",
    'Gouvelandia',
    'Crucilandia',
    "Alta Floresta D'Oeste",
    'Pontal do Araguaia',
    'Porto Trombetas',
    'Brasil Novo',
    'Aguas Lindas',
    'Jardim Jordao',
    'Mustardinha',
    'Alto Paraiso',
    'Zumbi dos Palmares',
    'Vicente Pires',
    'Carlos Prates',
    'Dona Clara',
    'Lago Sul',
    'Luis Eduardo Magalhaes',
    'Divino Espirito Santo',
    'Se',
    'Cidade Tiradentes',
  ],
  Greenland: [
    'Upernavik',
    'Sisimiut',
    'Qeqertarsuaq',
    'Qasigiannguit',
    'Qaqortoq',
    'Paamiut',
    'Nuuk',
    'Narsarsuaq',
    'Narsaq',
    'Nanortalik',
    'Maniitsoq',
    'Nuussuaq',
    'Ilulissat',
    'Tasiilaq',
    'Aasiaat',
    'Nunat',
    'Pituffik',
  ],
  'Saint Pierre and Miquelon': ['Saint-Pierre', 'Miquelon'],
  'South Georgia and the South Sandwich Islands': ['Grytviken'],
  'Falkland Islands': ['Stanley'],
  Argentina: [
    'Zárate',
    'Wilde',
    'Wanda',
    'Virreyes',
    'Villa Soldati',
    'Villa Sarmiento',
    'Villars',
    'Villa Rosa',
    'Villa Real',
    'Villa Paranacito',
    'Villa Ocampo',
    'Villa Numancia',
    'Villa Maria Grande',
    'Villa Mantero',
    'Villa Madero',
    'Villa Luzuriaga',
    'Villa Luro',
    'Villa Lugano',
    'Villa Insuperable',
    'Villa Hernandarias',
    'Villaguay',
    'Villa Gesell',
    'Villa Elisa',
    'Villa El Cacique',
    'Villa Dominico',
    'Villa Devoto',
    'Villa de Mayo',
    'Villa del Rosario',
    'Villa del Parque',
    'Villa Crespo',
    'Villa Bosch',
    'Villa Ballester',
    'Villa Alsina',
    'Victoria',
    'Vicente Lopez',
    'Veronica',
    'Veinticinco de Mayo',
    'Veinte de Setiembre',
    'Urdinarrain',
    'Turdera',
    'Tristan Suarez',
    'Tortuguitas',
    'Tolosa',
    'Tigre',
    'Temperley',
    'Tapiales',
    'Tandil',
    'Tabossi',
    'Tablada',
    'Suipacha',
    'Solis',
    'Sierra de los Padres',
    'Sauce de Luna',
    'Sauce',
    'Sarandi',
    'San Vicente',
    'Santo Tome',
    'Santos Lugares',
    'San Telmo',
    'Santa Teresita',
    'Santa Rosa',
    'Santa Lucia',
    'Santa Elena',
    'Santa Clara del Mar',
    'San Salvador',
    'San Pedro',
    'San Miguel del Monte',
    'San Justo',
    'San Jose de Feliciano',
    'San Javier',
    'San Isidro',
    'San Ignacio',
    'San Francisco Solano',
    'San Fernando',
    'San Cristobal',
    'San Clemente del Tuyu',
    'San Cayetano',
    'San Bernardo del Tuyu',
    'San Antonio de Padua',
    'San Antonio de Areco',
    'San Andres de Giles',
    'San Andres',
    'Saladillo',
    'Saladas',
    'Saenz Pena',
    'Rosario del Tala',
    'Roque Perez',
    'Romang',
    'Ringuelet',
    'Retiro',
    'Resistencia',
    'Remedios de Escalada',
    'Reconquista',
    'Recoleta',
    'Rauch',
    'Ranelagh',
    'Ranchos',
    'Ramos Mejia',
    'Rafael Castillo',
    'Rafael Calzada',
    'Quilmes',
    'Quequen',
    'Punta Lara',
    'Villa Pueyrredon',
    'Puerto Tirol',
    'Puerto Rico',
    'Puerto Piray',
    'Puerto Madero',
    'Puerto Iguazú',
    'Puerto Esperanza',
    'Puerto Eldorado',
    'Pronunciamiento',
    'Presidente Derqui',
    'Presidencia de la Plaza',
    'Posadas',
    'Pontevedra',
    'Platanos',
    'Pirane',
    'Pinamar',
    'Pilar',
    'Pila',
    'Pereyra',
    'Pedernales',
    'Pavon',
    'Paternal',
    'Pastor Britos',
    'Paso del Rey',
    'Paso de los Libres',
    'Parque Patricios',
    'Parque Chacabuco',
    'Palermo',
    'Orense',
    'Open Door',
    'Once',
    'Olivos',
    'Olivera',
    'Obera',
    'Nunez',
    'Nueva Pompeya',
    'Norberto de la Riestra',
    'Nogoya',
    'Necochea',
    'Navarro',
    'Munro',
    'Muniz',
    'Moron',
    'Moquehua',
    'Montserrat',
    'Monte Grande',
    'Monte Chingolo',
    'Monte Castro',
    'Monte Caseros',
    'Montecarlo',
    'Mocoreta',
    'Miramar',
    'Merlo',
    'Mercedes',
    'Melchor Romero',
    'Mburucuya',
    'Matheu',
    'Mataderos',
    'Martinez',
    'Mariano Moreno',
    'Haedo',
    'Mariano Acosta',
    'Mar del Tuyu',
    'Mar del Plata',
    'Mar de Ajo',
    'Marcos Paz',
    'Manzanares',
    'Manuel J. Cobo',
    'Manuel B. Gonnet',
    'Malabrigo',
    'Maipu',
    'Magdalena',
    'Macia',
    'Lynch',
    'Luján',
    'Luis Guillon',
    'Lucas Gonzalez',
    'Los Polvorines',
    'Los Cardales',
    'Longchamps',
    'Lomas de Zamora',
    'Lomas del Mirador',
    'Lobos',
    'Loberia',
    'Llavallol',
    'Liniers',
    'Lima',
    'Libertad',
    'Leandro N. Alem',
    'Las Toscas',
    'Las Toninas',
    'Las Pipinas',
    'Las Garzas',
    'Las Flores',
    'Larroque',
    'La Plata',
    'La Paz',
    'Lanus',
    'Lucila del Mar',
    'La Lucila',
    'La Leonesa',
    'Laguna Naick-Neck',
    'Laguna Blanca',
    'Laferrere',
    'La Dulce',
    'La Capilla',
    'Juan N. Fernandez',
    'Juan Maria Gutierrez',
    'Jose Marmol',
    'Jose Leon Suarez',
    'Jeppener',
    'Villa Flandria',
    'Jardin America',
    'Ituzaingo',
    'Ita Ibate',
    'Isidro Casanova',
    'Ingeniero Maschwitz',
    'Ingeniero Budge',
    'Ibicuy',
    'Hurlingham',
    'Hasenkamp',
    'Guillermo E. Hudson',
    'Gualeguaychú',
    'Gualeguay',
    'Grand Bourg',
    'Goya',
    'Gonzalez Catan',
    'Gobernador Mansilla',
    'Glew',
    'Gerli',
    'Villa Urquiza',
    'General San Martin',
    'General Rodriguez',
    'General Piran',
    'General Pacheco',
    'General Lavalle',
    'General Las Heras',
    'General Juan Madariaga',
    'General Jose de San Martin',
    'General Galarza',
    'General Belgrano',
    'Garupa',
    'Garin',
    'Francisco Alvarez',
    'Fracran',
    'Formosa',
    'Fontana',
    'Florida',
    'Floresta',
    'Flores',
    'Florencio Varela',
    'Federal',
    'Federacion',
    'Fatima',
    'Ezpeleta',
    'Jose Maria Ezeiza',
    'Esquina',
    'Ensenada',
    'Enrique Finn',
    'Empedrado',
    'El Talar',
    'El Palomar',
    'El Lenguaraz',
    'El Colorado',
    'Dos de Mayo',
    'Don Torcuato',
    'Domselaar',
    'Dolores',
    'Dock Sud',
    'Deseado',
    'Del Viso',
    'Curuzu Cuatia',
    'Cortines',
    'Corrientes',
    'Coronel Vidal',
    'Constitucion',
    'Congreso',
    'Concordia',
    'Concepción del Uruguay',
    'Concepcion de la Sierra',
    'Concepcion',
    'Comandante Nicanor Otamendi',
    'Comandante Fontana',
    'Colonia Pucheta',
    'Colonia Popular',
    'Colonia Benitez',
    'Colon',
    'Colegiales',
    'Coghlan',
    'Clorinda',
    'Claypole',
    'Clara',
    'Ciudad Evita',
    'Ciudadela',
    'City Bell',
    'Chillar',
    'Chascomus',
    'Chajari',
    'Chacarita',
    'Cerro Azul',
    'Cerrito',
    'Castelli',
    'Castelar',
    'Caseros',
    'Carmen de Areco',
    'Carlos Keen',
    'Capitan Sarmiento',
    'Capiovi',
    'Capilla del Senor',
    'Canuelas',
    'Cantera Aguirre',
    'Canning',
    'Candelaria',
    'Campo Grande',
    'Campana',
    'Cachari',
    'Caballito',
    'Burzaco',
    'Buenos Aires',
    'Brandsen',
    'Bovril',
    'Boulogne',
    'Bosques',
    'Boedo',
    'Betbeder',
    'Bernardo de Irigoyen',
    'Bernal',
    'Berisso',
    'Berazategui',
    'Benito Juarez',
    'Benavidez',
    'Bella Vista',
    'Belgrano',
    'Belen de Escobar',
    'Beccar',
    'Batan',
    'Basavilbaso',
    'Basail',
    'Barranqueras',
    'Barracas',
    'Baradero',
    'Banfield',
    'Balcarce',
    'Azul',
    'Ayacucho',
    'Avellaneda',
    'Atucha',
    'Atalaya',
    'Arturo Segui',
    'Arroyo Urquiza',
    'Apostoles',
    'Altamirano',
    'Alsina',
    'Almirante Brown',
    'Almagro',
    'Alejandro Korn',
    'Alegre',
    'Aldo Bonzi',
    'Aldea San Antonio',
    'Alcaraz',
    'Aguapey',
    'Agronomia',
    'Acassuso',
    'Abbott',
    'Abasto',
    'Maquinista Savio',
    'El Jagueel',
    'Guernica',
    'Alejandro Petion',
    'Zenon Pereyra',
    'Zavalla',
    'Zavalia',
    'Zapala',
    'Yerba Buena',
    'Wheelwright',
    'Villa Yacanto',
    'Villa Valeria',
    'Villa Union',
    'Villa Trinidad',
    'Villa Rumipal',
    'Villa Regina',
    'Villa Ojo de Agua',
    'Villa Nueva',
    'Villa Mirasol',
    'Villa Minetti',
    'Villa Media Agua',
    'Villa María',
    'Villalonga',
    'Villa Las Rosas',
    'Villa La Angostura',
    'Villa Krause',
    'Villa Huidobro',
    'Villa Giardino',
    'Villa General Mitre',
    'Villa General Belgrano',
    'Villa Eloisa',
    'Villa El Chocon',
    'Villa Dolores',
    'Villa de Soto',
    'Villa de Maria',
    'Villa del Totoral',
    'Villa del Dique',
    'Villa Cura Brochero',
    'Villa Constitucion',
    'Villa Concepcion del Tio',
    'Villa Carlos Paz',
    'Villa Canas',
    'Villa Berthet',
    'Villa Atuel',
    'Villa Ascasubi',
    'Villa Anizacate',
    'Villa Angela',
    'Villa Amelia',
    'Villa Allende',
    'Viedma',
    'Vicuna Mackenna',
    'Victorica',
    'Viamonte',
    'Viale',
    'Vera',
    'Venado Tuerto',
    '28 de Noviembre',
    'Vedia',
    'Valle Hermoso',
    'Valcheta',
    'Uspallata',
    'Ushuaia',
    'Uriburu',
    'Unquillo',
    'Ucacha',
    'Tupungato',
    'Tunuyan',
    'Trevelin',
    'Tres Lomas',
    'Tres Isletas',
    'Tres Arroyos',
    'Tres Algarrobos',
    'Trenque Lauquen',
    'Trenel',
    'Treinta de Agosto',
    'Transito',
    'Totoras',
    'Tostado',
    'Tornquist',
    'Toledo',
    'Toay',
    'Tio Pujio',
    'Tinogasta',
    'Ticino',
    'Termas de Rio Hondo',
    'Teodelina',
    'Tartagal',
    'Tapalque',
    'Tanti',
    'Tancacha',
    'Tafi Viejo',
    'Tafi del Valle',
    'Sunchales',
    'Suardi',
    'Stroeder',
    'Strobel',
    'Sierras Bayas',
    'Sierra Grande',
    'Sierra de la Ventana',
    'Sierra Colorada',
    'Serrezuela',
    'Serrano',
    'Senillosa',
    'Segui',
    'Seeber',
    'Sebastian Elcano',
    'Sauce Viejo',
    'Sastre',
    'Sarmiento',
    'Sa Pereyra',
    'Santiago del Estero',
    'Santa Sylvina',
    'Santa Rosa de Rio Primero',
    'Santa Rosa de Calamuchita',
    'Santa Maria',
    'Santa Margarita',
    'Santa Magdalena',
    'Santa Fe',
    'Santa Clara de Saguier',
    'Santa Clara de Buena Vista',
    'San Salvador de Jujuy',
    'Orán',
    'San Rafael',
    'San Pedro de Jujuy',
    'San Nicolás de los Arroyos',
    'San Miguel de Tucumán',
    'San Miguel',
    'San Martín de los Andes',
    'San Martin de las Escobas',
    'San Martin',
    'San Marcos Sierra',
    'San Luis',
    'San Lorenzo',
    'San Juan',
    'San Jose de la Esquina',
    'San Jose de Jachal',
    'San Jose',
    'San Jorge',
    'San Jeronimo Sur',
    'San Jeronimo Norte',
    'San Guillermo',
    'San Gregorio',
    'San Genaro',
    'San Francisco de Santa Fe',
    'San Francisco',
    'Sanford',
    'San Fernando del Valle de Catamarca',
    'San Carlos Sur',
    'San Carlos de Bolivar',
    'San Carlos Centro',
    'San Bernardo',
    'San Benito',
    'San Antonio Oeste',
    'San Antonio de Arredondo',
    'San Antonio',
    'Sampacho',
    'Salto Grande',
    'Salto',
    'Salta',
    'Salsipuedes',
    'Salsacate',
    'Salliquelo',
    'Saldungaray',
    'Salazar',
    'Saira',
    'Sacanta',
    'Saavedra',
    'Rufino',
    'Rosario de Lerma',
    'Rosario de la Frontera',
    'Rosario',
    'Roldan',
    'Rojas',
    'Rodeo del Medio',
    'Rodeo de la Cruz',
    'Roberts',
    'Rivera',
    'Rivadavia',
    'Rio Tercero',
    'Rio Segundo',
    'Rio Primero',
    'Rio Mayo',
    'Rio Grande',
    'Río Gallegos',
    'Río Cuarto',
    'Rio Colorado',
    'Rio Ceballos',
    'Recreo',
    'Realico',
    'Real del Padre',
    'Rawson',
    'Rancul',
    'Ramblon',
    'Ramallo',
    'Rafaela',
    'Rada Tilly',
    'Quimili',
    'Quemu Quemu',
    'Punta Alta',
    'Pujato',
    'Puerto Santa Cruz',
    'Puerto San Martin',
    'San Julian',
    'Puerto Madryn',
    'Puerto Deseado',
    'Puerto Belgrano',
    'Pueblo Nuevo',
    'Puan',
    'Salvador Mazza',
    'Presidencia Roque Sáenz Peña',
    'Pozo de Piedra',
    'Pozo del Tigre',
    'Pozo del Molle',
    'Portena',
    'Poman',
    'Pocito',
    'Plottier',
    'Plaza Huincul',
    'Playa Union',
    'Piquillin',
    'Piguee',
    'Piedritas',
    'Piedra del Aguila',
    'Pico Truncado',
    'Piamonte',
    'Perito Moreno',
    'Perico',
    'Pergamino',
    'Perez Millan',
    'Perez',
    'Pellegrini',
    'Pehuajó',
    'Pedro Luro',
    'Pascanas',
    'Parera',
    'Paraná',
    'Pampa del Infierno',
    'Palpala',
    'Palmira',
    'Pacara',
    'Oriente',
    'Ordonez',
    'Oncativo',
    'Olta',
    'Oliveros',
    'Oliva',
    'Olavarría',
    'Nueve de Julio',
    'Nono',
    'Noetinger',
    'Neuquén',
    'Morteros',
    'Morrison',
    'Montes de Oca',
    'Monteros',
    'Monte Maiz',
    'Monte Hermoso',
    'Monte Cristo',
    'Monte Buey',
    'Monje',
    'Moises Ville',
    'Mina Clavero',
    'Micaela Cascallares',
    'Metan',
    'Mendoza',
    'Mendiolaza',
    'Melo',
    'Melincue',
    'Mayor Buratovich',
    'Maximo Paz',
    'Marull',
    'Maria Teresa',
    'Maria Susana',
    'Mariano H. Alfonso',
    'Maria Juana',
    'Margarita',
    'Marcos Juarez',
    'Malvinas Argentinas',
    'Malargüe',
    'Malagueno',
    'Maggiolo',
    'Maciel',
    'Machagai',
    'Macachin',
    'Luzuriaga',
    'Luque',
    'San Isidro de Lules',
    'Lujan de Cuyo',
    'Los Surgentes',
    'Los Sembrados',
    'Los Reartes',
    'Los Molinos',
    'Los Menucos',
    'Los Condores',
    'Los Cardos',
    'Los Antiguos',
    'Lonquimay',
    'Loncopue',
    'Lincoln',
    'Lin Calel',
    'Libertador General San Martin',
    'Leones',
    'Leleque',
    'Las Varillas',
    'Las Talitas',
    'Las Rosas',
    'Las Perdices',
    'Las Parejas',
    'Las Palmeras',
    'Las Lajitas',
    'Las Lajas',
    'Las Isletillas',
    'Las Heras',
    'Las Catitas',
    'Las Brenas',
    'Las Acequias',
    'La Rioja',
    'La Quiaca',
    'La Punta',
    'Laprida',
    'La Playosa',
    'La Para',
    'Landeta',
    'La Merced',
    'Lamarque',
    'La Laguna',
    'Laguna Paiva',
    'Laguna Larga',
    'Laguna Alsina',
    'La Granja',
    'Lago Puelo',
    'La Francia',
    'La Emilia',
    'La Dormida',
    'La Cumbre',
    'La Cruz',
    'La Consulta',
    'La Cautiva',
    'La Carrodilla',
    'La Carlota',
    'La California',
    'La Calera',
    'Laboulaye',
    'Laborde',
    'La Banda',
    'Justo Daract',
    'Justiniano Posse',
    'Junin de los Andes',
    'Junín',
    'Junin',
    'Juan Pujol',
    'Juan Jose Paso',
    'Juan Bernabe Molina',
    'Juan Bautista Alberdi',
    'Juan Anchorena',
    'Joaquin V. Gonzalez',
    'Timbues',
    'Jesus Maria',
    'James Craik',
    'Jacinto Arauz',
    'Iturraspe',
    'Isla Verde',
    'Irigoyen',
    'Intendente Alvear',
    'Inriville',
    'Ingeniero White',
    'Ingeniero Luis A. Huergo',
    'Ingeniero Luiggi',
    'Ingeniero Jacobacci',
    'Ingeniero Guillermo N. Juarez',
    'Ingeniero Beaugey',
    'Indio Rico',
    'Idiazabal',
    'Ibarlucea',
    'Humboldt',
    'Humahuaca',
    'Huinca Renanco',
    'Hughes',
    'Huerta Grande',
    'Huanguelen',
    'Hipatia',
    'Hilario Ascasubi',
    'Hernando',
    'Henderson',
    'Helvecia',
    'Guatrache',
    'Guatimozin',
    'Guamini',
    'Gruetly Sur',
    'Granadero Baigorria',
    'Goyena',
    'Godoy Cruz',
    'Godoy',
    'Gobernador Gregores',
    'Gobernador Galvez',
    'Gobernador Crespo',
    'Gobernador Costa',
    'Gobernador Candioti',
    'General Villegas',
    'General Viamonte',
    'General Roca',
    'Villa General Ramírez',
    'General Racedo',
    'General Pinto',
    'General Pinedo',
    'General Pico',
    'General Martin Miguel de Gueemes',
    'General Levalle',
    'General La Madrid',
    'General Lagos',
    'General Gutierrez',
    'General Gelly',
    'General Fernandez Oro',
    'General Enrique Mosconi',
    'General Deheza',
    'General Daniel Cerri',
    'General Conesa',
    'General Cabrera',
    'General Baldissera',
    'General Alvear',
    'General Acha',
    'Galvez',
    'Gaiman',
    'Funes',
    'Frontera',
    'Frias',
    'Freyre',
    'Fray Luis Beltran',
    'Fray Luis A. Beltran',
    'Franck',
    'Fraile Pintado',
    'Firmat',
    'Fernandez',
    'Felipe Sola',
    'Felicia',
    'Famailla',
    'Etruria',
    'Esquel',
    'Esperanza',
    'Escalante',
    'Empalme Villa Constitucion',
    'Emilia',
    'Embarcación',
    'Embalse',
    'Embajador Martini',
    'El Trebol',
    'El Tio',
    'Villa Angelica',
    'Elortondo',
    'Elisa',
    'El Galpon',
    'El Fuertecito',
    'El Fortin',
    'Elena',
    'El Challao',
    'El Carril',
    'El Carmen',
    'El Calafate',
    'El Brete',
    'El Bolsón',
    'El Barco',
    'El Aranado',
    'Egusquiza',
    'Eduardo Castex',
    'Dos Pozos',
    'Dorila',
    'Dolavon',
    'Diego de Alvear',
    'Diecisiete de Agosto',
    'Diaz',
    'Diamante',
    'Devoto',
    'Del Campillo',
    'De la Garma',
    'Dean Funes',
    'Darregueira',
    'Daireaux',
    'Cutral-Co',
    'Curaru',
    'Cruz del Eje',
    'Cruz Alta',
    'Crespo',
    'Costa Sacate',
    'Cosquin',
    'Corzuela',
    'Corti',
    'Correa',
    'Corralito',
    'Corral de Bustos',
    'Coronel Vidt',
    'Coronel Suárez',
    'Coronel Pringles',
    'Coronel Olmedo',
    'Coronel Mom',
    'Coronel Moldes',
    'Coronel Martinez de Hoz',
    'Coronel Du Graty',
    'Coronel Dorrego',
    'Coronel Charlone',
    'Coronel Bogado',
    'Coronel Belisle',
    'Coronel Baigorria',
    'Coronel Arnold',
    'Coronda',
    'Córdoba',
    'Coraceros',
    'Contraalmirante Cordero',
    'Concepcion del Bermejo',
    'Comodoro Rivadavia',
    'Comandante Luis Piedra Buena',
    'Colonia Tirolesa',
    'Colonia Tacurales',
    'Colonia Santa Rosa',
    'Colonia Matilde',
    'Colonia Holandesa',
    'Colonia Caroya',
    'Colonia Belgrano',
    'Colonia Baron',
    'Colonia Aldao',
    'Colazo',
    'Colastine',
    'Colalao del Valle',
    'Plaza Clucellas',
    'Cipolletti',
    'Cinco Saltos',
    'Chos Malal',
    'Choele Choel',
    'Chivilcoy',
    'Chimpay',
    'Chimbas',
    'Chilibroste',
    'Chilecito',
    'Chicoana',
    'Chepes Viejo',
    'Chazon',
    'Chateaubriand',
    'Charata',
    'Chanaritos',
    'Chamical',
    'Chacabuco',
    'Chabas',
    'Cervantes',
    'Cerrillos',
    'Ceres',
    'Cereales',
    'Centeno',
    'Centenario',
    'Caucete',
    'Catrilo',
    'Catriel',
    'Casilda',
    'Casbas',
    'Carrizal',
    'Carnerillo',
    'Carmen de Patagones',
    'Carmen del Sauce',
    'Carlos Tejedor',
    'Carlos Pellegrini',
    'Carlos Casares',
    'Carhue',
    'Carcarana',
    'Capitan Bermudez',
    'Capilla del Monte',
    'Canals',
    'Canadon Seco',
    'Canadon Lagarto',
    'Canada Seca',
    'Canada Rosquin',
    'Canada Rica',
    'Canada Honda',
    'Canada de Gomez',
    'Campo Quijano',
    'Campo Largo',
    'Camilo Aldao',
    'Camarones',
    'Calilegua',
    'Caleufu',
    'Caleta Olivia',
    'Calchin',
    'Calchaqui',
    'Cafferata',
    'Cafayate',
    'Cachirulo',
    'Cabana',
    'Buta Ranquil',
    'Burruyacu',
    'Bulnes',
    'Buena Vista',
    'Brinkmann',
    'Bragado',
    'Bowen',
    'Bordenave',
    'Boeuf',
    'Boca de la Zanja',
    'Blas de Rosales',
    'Bigand',
    'Bialet Masse',
    'Berutti',
    'Berrotaran',
    'Bernasconi',
    'Bernardo Larroude',
    'Bermejo',
    'Berabevu',
    'Bengolea',
    'Bell Ville',
    'Belen',
    'Barrio La Fortuna',
    'Barrancas',
    'Bandera',
    'Banda del Rio Sali',
    'Balsa',
    'Balnearia',
    'Ballesteros',
    'Bajo Grande',
    'Bahía Blanca',
    'Ausonia',
    'Atahona',
    'Ascension',
    'Arteaga',
    'Arroyo Seco',
    'Arroyo Dulce',
    'Arroyo Algodon',
    'Arroyito Challaco',
    'Arroyito',
    'Arribenos',
    'Arrecifes',
    'Arocena',
    'Armstrong',
    'Arias',
    'Argueello',
    'Arequito',
    'Arenaza',
    'Arata',
    'Antonio Pini',
    'Anguil',
    'Angostura',
    'Anelo',
    'Pueblo Andino',
    'Andalgala',
    'Andacollo',
    'Anatuya',
    'Amenabar',
    'Florentino Ameghino',
    'Alvear',
    'Alvarez',
    'Alumine',
    'Alto Verde',
    'Altos de Chipion',
    'Alto San Pedro',
    'Alto de Medina',
    'Alta Italia',
    'Alta Gracia',
    'Alpachiri',
    'Almafuerte',
    'Allen',
    'Alijilan',
    'Alicia',
    'Algarrobo Verde',
    'Algarrobo',
    'Alejo Ledesma',
    'Alejandro Roca',
    'Alderetes',
    'Alcorta',
    'Alcira Gigena',
    'Alberti',
    'Albardon',
    'Aimogasta',
    'Agustina',
    'Aguilares',
    'Aguas Blancas',
    'Aguaray',
    'Agua de Oro',
    'Adolfo Gonzales Chaves',
    'Adelia Maria',
    'Achiras',
    'Acevedo',
    'Acebal',
    'Acambuco',
    'Abra Pampa',
    'Abel',
    'Medanos',
    'El Chalten',
    'Balvanera',
    'Costa del Este',
    'Bella Italia',
    'Carilo',
    'Villa Mercedes',
    'Nordelta',
    'Carapachay',
    'Villa Martelli',
    'Florida Oeste',
    'Villa Adelina',
    'Andresito',
    'Las Grutas',
    'Bariloche',
    'Pueblo General Belgrano',
    'Parque Avellaneda',
    'Río Turbio',
    'Villa Parque Siquiman',
    'Adrogue',
    'Costa Azul',
    'Tolhuin',
    'Carlos Spegazzini',
    'Parada Robles',
    'Manuel Alberti',
    'Villa Maipu',
    'Martin Coronado',
    'Villa Celina',
    'Rincon de Milberg',
    'Libertador San Martin',
    'Rincon de Los Sauces',
    'Ingeniero Adolfo Sourdeaux',
    'Dina Huapi',
    'America',
    '9 de Abril',
    'Barrio Esteban Echeverria',
  ],
  Paraguay: [
    'Ypane',
    'Ypacarai',
    'Villa Villeta',
    'Villarrica',
    'Villa Oliva',
    'Villa Hayes',
    'Villa Elisa',
    'Torin',
    'San Ignacio',
    'Santa Rosa Misiones',
    'Santa Rita',
    'San Lorenzo',
    'San Juan Bautista',
    'San Antonio',
    'Quiindy',
    'Piribebuy',
    'Villa Pilar',
    'Pedro Juan Caballero',
    'Panadero',
    'Colonia Nueva Germania',
    'Nemby',
    'Naranjal',
    'Nanawua',
    'Luque',
    'Loma Plata',
    'Limpio',
    'Lima',
    'La Paloma',
    'Lambaré',
    'Hohenau',
    'Hernandarias',
    'Guarambare',
    'Fuerte Olimpo',
    'Fernando de la Mora',
    'Eusebio Ayala',
    'Estancia Nueva Esperanza',
    'Encarnación',
    'Coronel Oviedo',
    'Concepción',
    'Mariano Roque Alonso',
    'Ciudad del Este',
    'Carapegua',
    'Capiatá',
    'Caaguazu',
    'Boqueron',
    'Benjamin Aceval',
    'Bella Vista',
    'Belen',
    'Juan de Ayolas',
    'Asunción',
    'Aregua',
    'Horqueta',
    'Filadelfia',
    'Loma Pyta',
    'Central',
    'Katuete',
    'Barrio San Roque',
  ],
  Uruguay: [
    'Villa del Carmen',
    'Vichadero',
    'Vergara',
    'Colonia Valdense',
    'Uruguay',
    'Union',
    'Trinidad',
    'Treinta y Tres',
    'Toledo',
    'Tacuarembó',
    'Sarandi Grande',
    'Sarandi del Yi',
    'Santiago Vazquez',
    'Santa Rosa',
    'Santa Lucia',
    'Santa Clara de Olimar',
    'Santa Bernardina',
    'Santa Ana',
    'San Ramon',
    'San Jose de Mayo',
    'San Jacinto',
    'San Carlos',
    'Salto',
    'Salinas',
    'Rosario',
    'Rodriguez',
    'Rocha',
    'Rivera',
    'Rio Branco',
    'Punta del Este',
    'Progreso',
    'Pocitos',
    'Piriapolis',
    'Paysandú',
    'Paso Pereira',
    'Paso de los Toros',
    'Pando',
    'Pan de Azucar',
    'Ombues de Lavalle',
    'Nuevo Berlin',
    'Nueva Palmira',
    'Nueva Helvecia',
    'Montevideo',
    'Minas',
    'Mercedes',
    'Melo',
    'Manga',
    'Maldonado',
    'Los Cerrillos',
    'Libertad',
    'Las Piedras',
    'La Paz',
    'La Paloma',
    'La Barra',
    'Juan L. Lacaze',
    'Joaquin Suarez',
    'Ituzaingo',
    'Fray Marcos',
    'Fray Bentos',
    'Florida',
    'Ecilda Paullier',
    'Durazno',
    'Dieciocho de Julio',
    'Diecinueve de Abril',
    'Curtina',
    'Cordon',
    'Colonia Suiza',
    'Colonia del Sacramento',
    'Chui',
    'Centro',
    'Castillos',
    'Carmelo',
    'Cardal',
    'Canelones',
    'Buceo',
    'Bella Vista',
    'Belen',
    'Barker',
    'Baltasar Brum',
    'Solis',
    'Atlantida',
    'Artilleros',
    'Artigas',
    'Arroyo Malo',
    'Arroyo de la Virgen',
    'Andresito',
    'Aigua',
    'Acosta',
    'Acegua',
    'Ciudad Vieja',
    'Colonia Nicolich',
    'Punta del Diablo',
    'Ciudad de la Costa',
    'Playa Hermosa',
    'Solymar',
    'Lagomar',
    'Shangrila',
    'Parque del Plata',
    'Las Canteras',
    'Aguada',
    'Aires Puros',
    'Barrio Sur',
    'La Teja',
    'Melilla',
    'Palermo',
    'Prado',
    'Barros Blancos',
    'Arenas de Jose Ignacio',
    'Abra de Perdomo',
    'Ciudad del Plata',
    'Verde Alto',
    'Bello Horizonte',
    'Fortin de Santa Rosa',
    'Pinamar',
    'El Pinar',
    'Berrondo',
    'Los Arrayanes',
    'Arenal Chico',
    'Barrancas Coloradas',
  ],
  Venezuela: [
    'La Asunción',
    'Agua Linda',
    'El Corozo',
    'Altamira',
    'El Chaparro',
    'Anaco',
    'Agua Fria',
    'Zaraza',
    'Yaritagua',
    'Villa de Cura',
    'Villa Bruzual',
    'Valle de La Pascua',
    'Valera',
    'Valencia',
    'Upata',
    'Turmero',
    'Tucupita',
    'Tucacas',
    'Trujillo',
    'Tinaquillo',
    'Tinaco',
    'Tia Juana',
    'Temblador',
    'Tariba',
    'Araguita',
    'Tacarigua',
    'Santa Teresa del Tuy',
    'Santa Rita',
    'Santa Fe',
    'Santa Elena de Uairén',
    'Santa Cruz',
    'Santa Clara',
    'San Mateo',
    'San Juan de los Morros',
    'San Juan de Colón',
    'San Jose de Guanipa',
    'San Joaquin',
    'San Felix',
    'San Felipe',
    'San Diego',
    'San Cristóbal',
    'San Carlos',
    'San Antonio del Tachira',
    'San Antonio de Los Altos',
    'Quíbor',
    'Punto Fijo',
    'Punta de Mata',
    'Punta Arenas',
    'Puerto Ordaz and San Felix',
    'Puerto La Cruz',
    'Puerto Cabello',
    'Puerto Ayacucho',
    'Pregonero',
    'Porlamar',
    'Paraiso de Chabasquen',
    'Pampatar',
    'Ocumare',
    'Naguanagua',
    'Montalban',
    'Araguaimujo',
    'Miranda',
    'Mérida',
    'Mene Grande',
    'Mariara',
    'Maracay',
    'Maracaibo',
    'Maiquetia',
    'Machiques',
    'Los Teques',
    'Los Guayos',
    'La Victoria',
    'La Uva',
    'La Toma',
    'Las Tejerias',
    'La Mocha',
    'Lagunillas',
    'La Guayana',
    'La Guaira',
    'La Fuente',
    'Higuerote',
    'Gueiria',
    'Guatire',
    'Guarenas',
    'Guarabao',
    'Guapachana',
    'Guanta',
    'Guanare',
    'Guacara',
    'Punta de Garambeo',
    'Gamelotal',
    'El Vigía',
    'El Tocuyo',
    'El Tigre',
    'Los Roques',
    'El Pilar',
    'Lecherias',
    'El Limon',
    'El Consejo',
    'El Conde',
    'El Callao',
    'Ejido',
    'Cumaná',
    'Cua',
    'Coro',
    'Cordoba',
    'Coloncito',
    'Cojedes',
    'Clarines',
    'Ciudad Ojeda',
    'Ciudad Bolívar',
    'Chivacoa',
    'Chimpire',
    'Chichiriviche',
    'Charallave',
    'Chacao',
    'Caucagua',
    'Catia La Mar',
    'Casanay',
    'Carúpano',
    'Carrizal',
    'Carrasquero',
    'Carora',
    'Caripito',
    'Caracas',
    'Caraballeda',
    'Cantaura',
    'Caño Mánamo',
    'Campo Elias',
    'Campo de Carabobo',
    'Campamento Rio Grande',
    'Cambural de Cataure',
    'Calle Larga',
    'Calabozo',
    'Caja Seca',
    'Caicara del Orinoco',
    'Cagua',
    'Cabudare',
    'Cabimas',
    'Buena Vista',
    'Boscan',
    'Bolivia',
    'Bolivar',
    'Boconó',
    'Blanquillo',
    'Betijoque',
    'Bergantin',
    'Belen',
    'Bejuma',
    'Baruta',
    'Barquisimeto',
    'Barinas',
    'Barcelona',
    'Barbacoas',
    'Bachaquero',
    'Arichuna',
    'Arenosa',
    'Arenita',
    'Arenas',
    'Arenales',
    'Araya',
    'Araure',
    'Aponte',
    'Apartaderos',
    'Aparicion',
    'Antunez',
    'Alto de Tomon',
    'Altagracia de Orituco',
    'Altagracia',
    'La Alquitrana',
    'Alpargaton',
    'Aguirre',
    'Aguas Calientes',
    'Agua Salada',
    'Agua Negra',
    'Agua Mansa',
    'Agua Dulce',
    'Agua Colorada',
    'Agua Clara',
    'Agua Caliente',
    'Agua Blanca',
    'Adaure',
    'Acurigua',
    'Achaguas',
    'Acequias',
    'Acarigua',
    'Acapralito',
    'Barrialito',
    'San Antonio',
    'Cachipe',
    'Aroa',
    'Arenilla',
    'Barrio Ajuro',
    'Cambural',
    'Bijagual',
    'Capiantar',
    'El Alto',
    'Barrio Obrero',
    'Botabanero',
    'Alto de Los Godos',
    'Ambrosio',
    'Agua',
    'Agua Viva',
    'La Catalinera',
    'Aparicio',
    'Maturín',
    'El Silencio',
    'Amparo',
    'Andarreal',
    'La Cienaga',
    'Barrio Colombia',
    'Los Angeles',
    'Campo Grande',
    'El Dieciocho',
    'La Grita',
    'Botijon',
    'Brisas de Londres',
    'El Diamante',
    'Cano Raya',
    'Campo Alegre',
    'Aricagua',
    'Buron',
    'Guasdualito',
    'San Fernando de Apure',
    'Aliviadero',
    'Vargas',
    'Playa El Agua',
    'Barrio La Vega',
    'Ciudad Universitaria',
    'Barrio El Carmen',
    'Barrio Campo Alegre',
  ],
  Mexico: [
    'Graciano Sanchez',
    'Atizapan de Zaragoza',
    'Las Flores',
    'Xicotencatl',
    'San Fernando',
    'Ciudad Gustavo Diaz Ordaz',
    'Laredo',
    'Nueva Primavera',
    'Ciudad Valles',
    'Zumpango',
    'Zumpango del Rio',
    'Zongolica',
    'Zimapan',
    'Zempoala',
    'Zaragoza',
    'Zapotlan de Juarez',
    'Zacualtipan',
    'Zacatlan',
    'Zacatepec',
    'Zacapoaxtla',
    'Villa de Zaachila',
    'Yecapixtla',
    'Yautepec',
    'Yanga',
    'Yajalon',
    'Xoxocotla',
    'Xonacatlan',
    'Xochitepec',
    'Xochimilco',
    'Xilitla',
    'Xicotepec de Juarez',
    'Xico',
    'Xaloztoc',
    'Villa Victoria',
    'Alvaro Obregon',
    'Villahermosa',
    'Gustavo Adolfo Madero',
    'Villa Guerrero',
    'Villaflores',
    'Villa del Carbon',
    'Villa Cuauhtemoc',
    'Villa Comaltitlan',
    'Veracruz',
    'Vega de Alatorre',
    'Valle Hermoso',
    'Valladolid',
    'Union Hidalgo',
    'Unidad del Seguro Social en Santa Fe',
    'Uman',
    'Ucu',
    'Tzompantepec',
    'Tuzamapan',
    'Tuxtla Gutiérrez',
    'Tuxpam de Rodriguez Cano',
    'Tulum',
    'Tultitlan de Mariano Escobedo',
    'Tultepec',
    'Tulancingo',
    'Tula de Allende',
    'Ciudad Tula',
    'Santa Rosa Treinta',
    'San Miguel Topilejo',
    'Toluca',
    'Toliman',
    'Tolcayuca',
    'Tochtepec',
    'Tlayacapan',
    'Tlaxco',
    'Tlaxcoapan',
    'San Bernardino Tlaxcalancingo',
    'Tlatlauquitepec',
    'Tlaquiltenango',
    'Tlapa de Comonfort',
    'Tlapacoyan',
    'Tlanchinol',
    'Tlanalapa',
    'Tlaltizapan',
    'Tlalpan',
    'Tlalnepantla',
    'Tlalmanalco',
    'Tlahuelilpan',
    'Tlahuac',
    'Tlacotepec',
    'Tlacoquemeca',
    'Tlacolula de Matamoros',
    'Tlachichuca',
    'Tizimín',
    'Tizayuca',
    'Tixtla de Guerrero',
    'Tinuncah',
    'Tihuatlan',
    'Ticul',
    'Santiago Tianguistenco de Galeana',
    'Tezontepec de Aldama',
    'Teziutlán',
    'Texcoco',
    'Tetelilla',
    'Teteles de Avila Castillo',
    'Tequixquiac',
    'Tequisquiapan',
    'Cuautitlán Izcalli',
    'Tepoztlan',
    'Tepotzotlan',
    'Tepeyahualco',
    'Tepexi de Rodriguez',
    'Tepetlaoxtoc de Hidalgo',
    'Tepepan',
    'Tepeji del Rio de Ocampo',
    'Tepecoacuilco de Trujano',
    'Tepeapulco',
    'Tepeaca',
    'Tepatepec',
    'Tepalcingo',
    'Teopisca',
    'Teoloyucan',
    'Teocelo',
    'Tenosique',
    'Tenango del Aire',
    'Tenango de Arista',
    'Tenancingo de Degollado',
    'Tempoal de Sanchez',
    'Temoaya',
    'Temoac',
    'Temixco',
    'Temax',
    'Temascalcingo',
    'Temascalapa',
    'Temapache',
    'Temamatla',
    'Teloloapan',
    'Tekik de Regil',
    'Tekanto',
    'Tehuacán',
    'Tecozautla',
    'Tecolutla',
    'Tecamachalco',
    'Teapa',
    'Taxco',
    'Tasquillo',
    'Tapayula',
    'Tapachula',
    'Tantoyuca',
    'Tanquian de Escobedo',
    'Tamuin',
    'Tampico Alto',
    'Tampico',
    'Tamiahua',
    'Tamazunchale',
    'Tamasopo',
    'Suchiapa',
    'Soto la Marina',
    'Soltepec',
    'Soledad de Doblado',
    'Soconusco',
    'Singuilucan',
    'Sayula de Aleman',
    'San Vicente Tancuayalab',
    'Santo Domingo Tehuantepec',
    'Texcaltitlan',
    'Tetla',
    'Pinotepa Nacional',
    'Miahuatlan',
    'Santiago de Anaya',
    'Santiago Tulantepec',
    'Santa Rita Tlahuapan',
    'Santa Maria Totolapilla',
    'Santa Maria Tonameca',
    'Nopaltepec',
    'Huatulco',
    'Santa Maria del Tule',
    'Coronango',
    'Santa Maria Chimalhuacan',
    'Santa Maria Aztahuacan',
    'Santa Maria Atzompa',
    'Heroica Ciudad de Tlaxiaco',
    'Santa Lucia',
    'Santa Cruz Xoxocotlán',
    'Tecamac',
    'Santa Cruz Huatulco',
    'Santa Cruz Tlaxcala',
    'Santa Anita',
    'Santa Ana Nextlalpan',
    'Santa Ana Necoxtla',
    'Santa Ana Jilotzingo',
    'Santa Ana Chiautempan',
    'San Sebastian Etla',
    'Huixcolotla',
    'San Salvador el Verde',
    'San Salvador El Seco',
    'San Salvador Atenco',
    'San Rafael',
    'San Pedro Tapanatepec',
    'San Pedro Pochutla',
    'San Pedro Ixtlahuaca',
    'San Pablo Etla',
    'San Pablo de las Salinas',
    'Apetatitlan Antonio Carbajal',
    'Zinacantepec',
    'San Miguel Panixtlahuaca',
    'Chapultepec',
    'San Miguel Canoa',
    'San Miguel Ajusco',
    'Mexicaltzingo',
    'San Mateo del Mar',
    'San Mateo Atenco',
    'Xaltocan',
    'San Martin de las Piramides',
    'San Martin Chalchicuautla',
    'San Marcos de Leon',
    'Teolocholco',
    'San Lorenzo Tezonco',
    'Almecatla',
    'Tianguismanalco',
    'Teotihuacan',
    'San Juan Mitepec',
    'San Juan Ixcaquixtla',
    'San Juan Evangelista',
    'San Juan del Río',
    'San Juan de Aragon',
    'San Juan Cotzocon',
    'San Juan Cacahuatepec',
    'San Juan Bautista Tuxtla',
    'San Jose Tlacuitlapan',
    'San Jeronimo Lidice',
    'San Gaspar Tonatico',
    'San Francisco Telixtlahuaca',
    'Mixtla',
    'San Francisco Huehuetlan',
    'San Francisco',
    'Teotlaltzingo',
    'San Felipe del Progreso',
    'San Felipe de Jesus',
    'Yauhquemehcan',
    'San Dionisio Ocotlan',
    'San Dionisio Ocotepec',
    'San Cristóbal de las Casas',
    'San Cristobal',
    'San Ciro de Acosta',
    'Amaxac de Guerrero',
    'San Bartolome Loxicha',
    'San Bartolo Ameyalco',
    'San Antonio la Isla',
    'San Angel Inn',
    'San Andres Tuxtla',
    'San Andres Timilpan',
    'San Andres Tepetitlan',
    'San Andres Huayapam',
    'San Agustin Tlaxiaca',
    'San Agustin Etla',
    'San Agustin Amatengo',
    'Salina Cruz',
    'Sabana Grande',
    'Juan Rodriguez Clara',
    'Rioverde',
    'Rio Frio de Juarez',
    'Ciudad Rio Bravo',
    'Rinconada',
    'Reynosa',
    'Rayon',
    'Grajales',
    'Quecholac',
    'Putla Villa de Guerrero',
    'Puerto Escondido',
    'Puente Nacional',
    'Puente de Ixtla',
    'Puebla City',
    'Pro-Hogar',
    'Progreso Nacional',
    'Progreso de Alvaro Obregon',
    'Progreso',
    'Poza Rica de Hidalgo',
    'Pomuch',
    'Polotitlan de la Ilustracion',
    'Polanco',
    'Playa del Carmen',
    'Platon Sanchez',
    'Pie de la Cuesta',
    'Piedad Narvarte',
    'Pichucalco',
    'Peto',
    'Perote',
    'Santa Ursula',
    'Paso del Macho',
    'Paraiso',
    'Papantla de Olarte',
    'Papalotla',
    'Panuco',
    'Panotla',
    'Paa Mul',
    'Palmas de Abajo',
    'Palizada',
    'Palenque/Temp',
    'Pachuca',
    'Ozumba de Alzate',
    'Oxkutzkab',
    'Otumba',
    'Oteapan',
    'San Felipe Orizatlan',
    'Orizaba',
    'Oriental',
    'Omitlan de Juarez',
    'Oluta',
    'Olinala',
    'Ocuituco',
    'Villa Union',
    'Ocuilan de Arteaga',
    'Ocozocoautla de Espinosa',
    'Ocoyoacac',
    'Ocotlan de Morelos',
    'San Francisco Ocotlan',
    'Ocosingo',
    'Ocampo',
    'Oaxtepec',
    'Oaxaca City',
    'Nuevo Progreso',
    'Nueva Villa de Padilla',
    'Nuevo Laredo',
    'Nueva Santa Maria',
    'Noxtepec',
    'Nopala de Villagran',
    'Nogales',
    'Ciudad Nicolás Romero',
    'Necaxa',
    'Nautla',
    'Naucalpan',
    'Nativitas',
    'Naranjos',
    'Naolinco de Victoria',
    'Nanchital de Lazaro Cardenas del Rio',
    'Ciudad de Nanacamilpa',
    'Nacajuca',
    'Muna',
    'Motul',
    'Motozintla',
    'Mazatlan',
    'Mochitlan',
    'Mixquiahuala de Juarez',
    'Misantla',
    'Mineral del Monte',
    'Mineral de la Reforma',
    'Minatitlán',
    'Milpa Alta',
    'Mier',
    'Miahuatlan de Porfirio Diaz',
    'Miacatlan',
    'Colonia Mexico',
    'Metepec',
    'Mérida',
    'Mendoza',
    'Medellin de Bravo',
    'Mecayapan',
    'Mecacalco',
    'Mazatepec',
    'Mazatan',
    'Maxcanu',
    'Matias Romero',
    'Heroica Matamoros',
    'Martinez de la Torre',
    'Mariano Escobedo',
    'Ursulo Galvan',
    'Mani',
    'Maltrata',
    'Malinaltepec',
    'Malinalco',
    'Magdalena Mixhuca',
    'Magdalena Contreras',
    'Macuspana',
    'Los Reyes Acatlixhuayan',
    'Los Reyes Acaquilpan',
    'Los Reyes de Juarez',
    'Los Herreras',
    'Los Comales',
    'Lomas de Reforma',
    'Lomas de Chapultepec',
    'Linares',
    'Libres',
    'Lerma de Villada',
    'Lerdo de Tejada',
    'Las Vigas de Ramirez',
    'Las Rosas',
    'Las Choapas',
    'Congregacion Reforma',
    'La Pezma',
    'La Joya',
    'La Isla',
    'San Antonio de la Cal',
    'La Antigua',
    'Kanasin',
    'Juventino Rosas',
    'Juchitepec',
    'Juchitán de Zaragoza',
    'Juarez',
    'Juan Escutia',
    'Jose Cardel',
    'Joquicingo',
    'Jonacatepec',
    'Jojutla',
    'Jocotitlan',
    'Jiutepec',
    'Jiquipilas',
    'Jilotepec de Molina Enriquez',
    'Jesus Carranza',
    'Jaumave',
    'Jantetelco',
    'Jaltipan de Morelos',
    'Jaltenco',
    'Jalpan',
    'Jalpa de Mendez',
    'Xalatlaco',
    'Xalapa',
    'Jalacingo',
    'Izúcar de Matamoros',
    'Izamal',
    'Ixtepec',
    'Ixtapan de la Sal',
    'Ixtapaluca',
    'Iztapalapa',
    'Ixtaczoquitlan',
    'Iztacalco',
    'Ixmiquilpan',
    'Ixhuatlancillo',
    'San Francisco Ixhuatan',
    'Isla Mujeres',
    'Igualapa',
    'Iguala de la Independencia',
    'Ignacio de la Llave',
    'Hunucma',
    'Huixtla',
    'Huixquilucan de Degollado',
    'Ciudad de Huitzuco',
    'Huitzilac',
    'Huimanguillo',
    'Huiloapan de Cuauhtemoc',
    'Huichihuayan',
    'Huichapan',
    'Hueytamalco',
    'Hueypoxtla',
    'Huejutla de Reyes',
    'Huejotzingo',
    'Huehuetoca',
    'Huehuetan',
    'Huayacocotla',
    'Huautla de Jimenez',
    'Huauchinango',
    'Huatusco',
    'Hualahuises',
    'Ciudad de Huajuapan de Leon',
    'Huajoyuca',
    'Huahuaxtla',
    'Guadalupe Insurgentes',
    'Guadalupe Tlachco',
    'Genova',
    'Ciudad General Teran',
    'General Bravo',
    'Gabriel Hernandez',
    'Frontera Hidalgo',
    'Comalapa',
    'Fortin de las Flores',
    'Felipe Carrillo Puerto',
    'Ezequiel Montes',
    'Escuintla',
    'Escarcega',
    'Emiliano Zapata',
    'Villa Emiliano Zapata',
    'El Carmen',
    'Ciudad Mante',
    'El Higo',
    'Coapeche',
    'El Castillo',
    'El Carmen Aztama',
    'El Carmen de Castano',
    'Arenal',
    'El Arenal',
    'Ejutla de Crespo',
    'Ecatepec de Morelos',
    'Ebano',
    'Dzununcan',
    'Dzoncauich',
    'Dzitbalche',
    'Dzilam de Bravo',
    'Dzidzantun',
    'Dos Rios',
    'Doctor Gonzalez',
    'Cuyoaco',
    'Cunduacan',
    'Culhuacan',
    'Cuitlahuac',
    'Cuetzalan',
    'Cuernavaca',
    'Cuautlancingo',
    'Cuautla',
    'Cuautitlan',
    'Cuautepec de Hinojosa',
    'Cuautepec',
    'Cuapiaxtla',
    'Cuanala',
    'Cuajinicuilapa',
    'Delegacion Cuajimalpa de Morelos',
    'Cruz Grande',
    'San Miguel de Cozumel',
    'Cozoyopan',
    'Coyutla',
    'Coyotepec',
    'Coyoacán',
    'Cosoleacaque',
    'Cosolapa',
    'Coscomatepec de Bravo',
    'Cosamaloapan',
    'Córdoba',
    'Copainala',
    'Constitucion de la Republica',
    'Comitán',
    'Comalcalco',
    'Colombia',
    'Cajiapan',
    'Cocula',
    'Cocotitlan',
    'Coatzintla',
    'Coatzacoalcos',
    'Coatepec Harinas',
    'Coatepec',
    'Coacoyula de Alvarez',
    'Coacoatzintla',
    'Coacalco',
    'Ciudad Victoria',
    'Ciudad Serdan',
    'Ciudad Satelite',
    'Ciudad Sahagun',
    'Ciudad Pemex',
    'Ciudad Nezahualcoyotl',
    'Ciudad Miguel Aleman',
    'Ciudad Mendoza',
    'Ciudad Madero',
    'Ciudad Fernandez',
    'Mexico City',
    'Ciudad del Maiz',
    'Ciudad del Carmen',
    'Ciudad Camargo',
    'Citlaltepec',
    'Cintalapa de Figueroa',
    'Churubusco',
    'Chunyaxche',
    'Cholula',
    'Chocaman',
    'Chinameca',
    'Chilpancingo',
    'Chilapa de Alvarez',
    'Chignahuapan',
    'Chietla',
    'Chicontepec',
    'Chiconcuac',
    'Chicomuselo',
    'Chicoloapan',
    'Chiautla de Tapia',
    'Chiautla',
    'Chiapa de Corzo',
    'Chetumal',
    'Chapultenango',
    'Champoton',
    'Chalco',
    'Chahuites',
    'Chachapa',
    'Chablekal',
    'Cerro Azul',
    'Cerrillos de Diaz',
    'Celestun',
    'Catemaco',
    'Catazaja',
    'Cardenas',
    'Capulhuac de Mirafuentes',
    'Cancún',
    'Campeche',
    'Calpulalpan',
    'Calkini',
    'Calimaya',
    'Cadereyta Jimenez',
    'Cadereyta',
    'Cacalchen',
    'Cacahoatan',
    'Buenavista de Cuellar',
    'Buenavista Gallardo',
    'Boye',
    'Bondojito',
    'Bochil',
    'Boca del Rio',
    'Bibiano Hernandez',
    'Berriozabal',
    'Benito Juarez',
    'Becal',
    'Barranca Honda',
    'Banderilla',
    'Balancan',
    'Bajos del Ejido',
    'Bacalar',
    'Baca',
    'Azcapotzalco',
    'Ayutla de los Libres',
    'Ayutla',
    'Ayotoxco de Guerrero',
    'Ciudad Ayala',
    'Axochiapan',
    'Axapusco',
    'Atoyac',
    'Atotonilco de Tula',
    'Atotonilco el Grande',
    'Atlixco',
    'Atlequizayan',
    'Atlautla',
    'Atlatlahucan',
    'Atlacomulco de Fabela',
    'Ciudad Lopez Mateos',
    'Atizapan',
    'Atitalaquia',
    'Atempan',
    'Atalpan',
    'Asuncion Nochixtlan',
    'Asuncion Ixtaltepec',
    'Arroyo Grande',
    'Apizaco',
    'Apaxtla de Castrejon',
    'Apan',
    'Angel R. Cabada',
    'Amozoc de Mota',
    'Ameluca',
    'Amecameca',
    'Amayuca',
    'Amatlan de los Reyes',
    'Amacuzac',
    'Alvarado',
    'Altotonga',
    'Altamira',
    'Villa de Almoloya de Juarez',
    'Almoloya de Alquisiras',
    'Almoloya',
    'Axtla de Terrazas',
    'Alfajayucan',
    'Aldana',
    'San Miguel Albarradas',
    'Alamo',
    'Akumal',
    'Aguilera',
    'Agua Dulce',
    'Agricola Oriental Sur',
    'Aculco de Espinoza',
    'Actopan',
    'Altzayanca',
    'Acolman',
    'Achupil',
    'Acayucan',
    'Acaxochitlan',
    'Acatzingo',
    'Acatlan de Perez Figueroa',
    'Acatlan de Osorio',
    'Acatempan',
    'Acapulco de Juárez',
    'Acapetahua',
    'Colonia de Acapantzingo',
    'Acambay',
    'Puerto Morelos',
    'Ajacuba',
    'Concepcion Jolalpan',
    'Galeana',
    'Higueron',
    'Caja Pinta',
    'Escobedo',
    'Hidalgo',
    'El Barrio de la Soledad',
    'Guivicia',
    'Lachinilla',
    'Santos Reyes Nopala',
    'Mazunte',
    'Teotongo',
    'Tezonapa',
    'Almolonga',
    'Aserradero',
    'Isla Soyaltepec',
    'Vicente Camalote',
    'Tierra Blanca',
    'Santa Catarina',
    'Arroyo Encino',
    'San Sebastian Tutla',
    'El Arrogante Justo Benitez',
    'Frontera',
    'Amado Gomez',
    'Chichonal 3ra. Seccion (La Capilla)',
    'Pueblo Hidalgo',
    'San Miguel Allende',
    'Francisco Villa',
    'Amantla',
    'Munoz',
    'San Matias Tepetomatitlan',
    'Huamantla',
    'Ignacio Zaragoza',
    'Xicohtzinco',
    'Ocatlan',
    'Zacatelco',
    'San Damian Texoloc',
    'Tlaxcala City',
    'San Hipolito Chimalpa',
    'Totolac',
    'Acatitla',
    'San Rafael Tenanyecac',
    'Xocoyucan',
    'Tenancingo',
    'Villa Vicente Guerrero',
    'Chilon',
    'Francisco I. Madero',
    'Chihuahua',
    'Revolucion Mexicana',
    'Tonala',
    'Arriaga',
    'Queretaro',
    'Santa Cruz Atizapan',
    'Tepetlixpa',
    'Villa San Agustin Atlapulco',
    'San Luis Huexotla',
    'San Jeronimo Amanalco',
    'La Magdalena Panoaya',
    'Riva Palacio',
    'Tezoyuca',
    'Tepexpan',
    'San Cristobal Colhuacan',
    'Cuacuila',
    'Hueyapan',
    'San Andres Cholula',
    'Miravalles',
    'El Veladero',
    'Cuesta Blanca',
    'Emilio Portes Gil',
    'Huehuetlan el Chico',
    'Huachinantla',
    'El Calabozo',
    'Zacacoyuca',
    'Ahuixtla',
    'Achigca',
    'Tototepec',
    'Guadalupe',
    'Jose Maria Morelos',
    'Isla Holbox',
    'Los Pescados',
    'Playa de Chachalacas',
    'Corral Falso',
    'El Pochote',
    'Cecilio Teran',
    'San Angel',
    'Colonia Lealtad',
    'Gutierrez Zamora',
    'Gabino Barreda',
    'Loma Bonita',
    'Juan Diaz Covarrubias',
    'Emilio Carranza',
    'El Zapote',
    'Ejido Miguel Hidalgo',
    'San Francisco Gatos',
    'El Aguaje',
    'Tlalpujahuilla',
    'San Lorenzo Tlacoyucan',
    'San Salvador Cuauhtenco',
    'Santa Maria Nativitas',
    'San Luis Tlaxialtemalco',
    'Ejidos de San Andres Totoltepec',
    'San Bernabe Ocotepec',
    'San Mateo Tlaltenango',
    'Venustiano Carranza',
    'Miguel Hidalgo',
    'Cuauhtemoc',
    'Ignacio Allende',
    'Huixquilucan',
    'Lomas de Las Palmas',
    'El Refugio',
    'Melchor Ocampo',
    'Tonanitla',
    'Ojo de Agua',
    'Villa de Las Flores',
    'Francisco Javier Mina',
    'Trinidad Garcia de la Cadena',
    'Santa Maria de la Paz',
    'Villa Hidalgo',
    'San Blas',
    'Tuxtuac',
    'Carrillo',
    'Jose Maria Morelos y Pavon',
    'Santo Domingo',
    'Calabacillas',
    'Campo Sesenta y Cuatro B',
    'Elota',
    'Eldorado',
    'Villa Benito Juarez',
    'Resolana',
    'Cihuatlan',
    'Dieciocho de Marzo',
    'Bajio de San Jose',
    'General Domingo Arrieta',
    'Ejido Queretaro',
    'Cuervos',
    'Zinapecuaro',
    'Ixtapa-Zihuatanejo',
    'Zapotlanejo',
    'Zapotlan del Rey',
    'Zapotiltic',
    'Zapopan',
    'Zamora',
    'Zacoalco de Torres',
    'Zacatecas City',
    'Zacapu',
    'Yuriria',
    'Yurecuaro',
    'Yahualica de Gonzalez Gallo',
    'Vista Hermosa de Negrete',
    'Vista Hermosa',
    'Villanueva',
    'Villamar',
    'Villa Madero',
    'Lopez',
    'Ciudad de Villaldama',
    'Villa Juarez',
    'Villagran',
    'Villa Garcia',
    'Santa Clara del Cobre',
    'El Pueblito',
    'Garcia',
    'Villa de Cos',
    'Ciudad de Villa de Alvarez',
    'Villa Corona',
    'El Vegil',
    'Viesca',
    'Vicam Pueblo',
    'Ejido Venustiano Carranza',
    'Valparaiso',
    'Valle de Santiago',
    'Valle de Juarez',
    'Valle de Bravo',
    'Valle de Banderas',
    'Usmajac',
    'Uruapan',
    'Uriangato',
    'Union de Tula',
    'Union de San Antonio',
    'Tzintzuntzan',
    'Tuxpan',
    'Tototlan',
    'Totatiche',
    'Torreón',
    'Torreon',
    'Topia',
    'Tonila',
    'Tonaya',
    'Tomatlan',
    'Todos Santos',
    'Tocumbo',
    'Tlaquepaque',
    'Tlapehuala',
    'Tlalpujahua de Rayon',
    'Tlajomulco de Zuniga',
    'Tizapan el Alto',
    'Tingueindin',
    'Tijuana',
    'Tequila',
    'Tepic',
    'Tepetongo',
    'Tepechitlan',
    'Tepatitlán de Morelos',
    'Teocuitatlan de Corona',
    'Teocaltiche',
    'Tenamaxtlan',
    'Temascaltepec de Gonzalez',
    'Tejupilco de Hidalgo',
    'Tecuala',
    'Tecpan de Galeana',
    'Tecoman',
    'Tecolotlan',
    'Tecate',
    'Tecalitlan',
    'Tarimoro',
    'Tarandacuao',
    'Tapalpa',
    'Tanhuato de Guerrero',
    'Tancitaro',
    'Tamazula de Gordiano',
    'Talpa de Allende',
    'Tala',
    'Tacambaro de Codallos',
    'Tabasco',
    'Sombrerete',
    'Soledad de Graciano Sanchez',
    'Sinaloa de Leyva',
    'Silao',
    'Sayulita',
    'Sayula',
    'Saucillo',
    'San Vicente',
    'Valle Santo Domingo',
    'Santiago Papasquiaro',
    'Santiago Ixcuintla',
    'Santiago',
    'Santa Teresa',
    'Santa Rosa Jauregui',
    'Santa Maria del Rio',
    'Santa Ines',
    'Santa Cruz del Astillero',
    'Santa Barbara',
    'Santa Ana',
    'San Pedro de Nolasco',
    'San Pedro Lagunillas',
    'San Nicolás de los Garza',
    'San Miguel el Alto',
    'San Miguel de Allende',
    'San Miguel',
    'San Martin Hidalgo',
    'San Luis Río Colorado',
    'San Luis Potosí City',
    'San Luis de la Paz',
    'Cabo San Lucas',
    'San Julian',
    'San Juan de los Lagos',
    'San Juan Cosala',
    'San Jose Iturbide',
    'San Jose de Arenales',
    'San José del Cabo',
    'San Jose de Colima',
    'San Jose de Avila',
    'San Jose de Bacum',
    'San Jose de Lourdes',
    'San Joaquin',
    'San Jeronimo de Juarez',
    'San Ignacio Cerro Gordo',
    'San Francisco del Rincón',
    'San Francisco de los Romo',
    'San Felipe',
    'San Dimas',
    'San Diego de la Union',
    'San Diego de Alejandria',
    'San Diego de Alcala',
    'Tlaltenango de Sanchez Roman',
    'San Antonio de los Vazquez',
    'San Antonio Tlayacapan',
    'Salvatierra',
    'Saltillo',
    'Salinas Victoria',
    'Salinas de Hidalgo',
    'Salamanca',
    'Sain Alto',
    'Sahuayo de Morelos',
    'Ciudad Sabinas Hidalgo',
    'Sabinas',
    'Ruiz',
    'Rosarito',
    'El Rosario',
    'Romita',
    'San Andres',
    'Rio Grande',
    'Rincon de Tamayo',
    'Rincon de Romos',
    'Rincon de Guayabitos',
    'Ramos Arizpe',
    'Quiroga',
    'Quila el Grande',
    'Querétaro City',
    'Puruandiro',
    'Purisima de Bustos',
    'Purepero de Echaiz',
    'Punta de Mita',
    'Puerto Vallarta',
    'Puerto Peñasco',
    'Pueblo Yaqui',
    'Pueblo Nuevo',
    'Pozuelos de Abajo',
    'Poncitlan',
    'Pinos',
    'Piedras Negras',
    'Petatlan',
    'Pesqueria',
    'Periban de Ramos',
    'Penon Blanco',
    'Penjamo',
    'Penjamillo de Degollado',
    'La Penita de Jaltomba',
    'Pegueros',
    'Pedro Escobedo',
    'Patzcuaro',
    'Patamban',
    'Parras de la Fuente',
    'Paracho de Verduzco',
    'Pantanal',
    'Palmito del Verde',
    'Palau',
    'Pabellon de Arteaga',
    'San Jose de Gracia',
    'Ojuelos de Jalisco',
    'Ojinaga',
    'Ocotlan',
    'Numaran',
    'Nuevo Ideal',
    'Nuevo Casas Grandes',
    'Nueva Rosita',
    'Nueva Italia de Ruiz',
    'Nocupetaro',
    'Nochistlan de Mejia',
    'Nextipac',
    'Navolato',
    'Navojoa',
    'Nava',
    'Naica',
    'Nahuatzen',
    'Nacozari de Garcia',
    'Naco',
    'Mulege',
    'Moyahua de Estrada',
    'Moroleon',
    'Morelos',
    'Morelia',
    'Monterrey',
    'Monte Escobedo',
    'Monclova',
    'Moctezuma',
    'Mocorito',
    'Miraflores',
    'Mineral de Angangueo',
    'Mina',
    'Miguel Auza',
    'Mechoacanejo',
    'Mexicali',
    'Mesillas',
    'Meoqui',
    'Ciudad Melchor Muzquiz',
    'Mazatlán',
    'Mazamitla',
    'Matehuala',
    'Mascota',
    'Marin',
    'Marfil',
    'Maravatio de Ocampo',
    'Mapimi',
    'Manzanillo',
    'Santa Maria del Oro',
    'Magdalena de Kino',
    'La Paletena',
    'Magdalena',
    'Madera',
    'Villa Luvianos',
    'Los Reyes de Salgado',
    'Los Reyes',
    'Los Mochis',
    'Los Gavilanes',
    'Loreto',
    'Gabriel Zamora',
    'León',
    'La Venta del Astillero',
    'Las Varas',
    'Las Canoas',
    'Las Bayas',
    'Queseria',
    'La Presa',
    'La Piedad',
    'La Paz',
    'La Moncada',
    'La Leonera',
    'La Laja',
    'La Huerta',
    'La Huacana',
    'Lagos de Moreno',
    'La Estancia de Amezcua',
    'La Cruz',
    'La Canada',
    'Barra de Navidad',
    'La Barca',
    'Puente Grande',
    'Juchitlan',
    'Juchipila',
    'Juarez y Reforma',
    'Ciudad Benito Juarez',
    'Juan Aldama',
    'Juanacatlan',
    'Jocotepec',
    'Jiquilpan de Juarez',
    'Jimenez',
    'Jesus Maria',
    'Jerez de Garcia Salinas',
    'Jerecuaro',
    'Jaral del Progreso',
    'Jamay',
    'Jalpa',
    'Jalostotitlan',
    'Xalisco',
    'Jahuara Primero',
    'Jacona de Plancarte',
    'Ixtlahuacan de Santiago',
    'Ixtlan del Rio',
    'Ixtlahuacan del Rio',
    'Ixtlahuacan de los Membrillos',
    'Ixtlahuacan',
    'Ixtapa',
    'Irapuato',
    'Indaparapeo',
    'Huetamo de Nunez',
    'Huejuquilla el Alto',
    'Huejucar',
    'Huatabampo',
    'Huanimaro',
    'Huandacareo',
    'Parral',
    'Heroica Zitacuaro',
    'Heroica Caborca',
    'Hermosillo',
    'Guaymas',
    'Guasave',
    'Guanajuato City',
    'Guamuchil',
    'Ciudad Guadalupe Victoria',
    'Guadalajara',
    'Guachochi',
    'Guachinango',
    'Gomez Palacio',
    'Gomez Farias',
    'General Zuazua',
    'Vicente Guerrero',
    'Juan Jose Rios',
    'General Escobedo',
    'General Cepeda',
    'San Pedro Garza Garcia',
    'Fresnillo',
    'Etzatlan',
    'Etchojoa',
    'Cuitzeo',
    'Escuinapa',
    'Ensenada',
    'Encarnacion de Diaz',
    'Empalme Escobedo',
    'Empalme',
    'El Rodeo',
    'El Quince',
    'El Puerto',
    'El Nacimiento',
    'El Limon',
    'El Grullo',
    'El Fuerte',
    'El Cuis',
    'El Cercado',
    'Vencedores',
    'Ciudad Insurgentes',
    'Ejido Guadalajara',
    'Durango',
    'Ejido Distrito Federal',
    'Chiapas Numero Uno',
    'Ecuandureo',
    'Dolores Hidalgo',
    'Doctor Arroyo',
    'Degollado',
    'Cutzamala de Pinzon',
    'Cuquio',
    'Culiacán',
    'Cuitzeo del Porvenir',
    'Cueramaro',
    'Cuatro Cienegas de Carranza',
    'Creel',
    'Coyuca de Catalan',
    'Coyuca de Benitez',
    'Cotija de la Paz',
    'Cosala',
    'Cortazar',
    'Carona',
    'Concordia',
    'Concepcion del Oro',
    'Concepcion de Buenos Aires',
    'Compostela',
    'Comonfort',
    'Comala',
    'Colotlan',
    'Rio Colorado',
    'Colonia Lazaro Cardenas',
    'Aldama',
    'Colon',
    'Colima',
    'Cojumatlan de Regules',
    'Cofradia de Suchitlan',
    'Coeneo de la Libertad',
    'Coalcoman de Vazquez Pallares',
    'Ciudad Obregón',
    'Ciudad Manuel Doblado',
    'Lerdo',
    'Ciudad Juárez',
    'Ciudad Hidalgo',
    'Ciudad Guzmán',
    'Ciudad Delicias',
    'Ciudad Constitución',
    'Camargo',
    'Anahuac',
    'Ciudad Altamirano',
    'Ciudad Acuña',
    'Citala',
    'Cienega de Flores',
    'Churintzio',
    'Chulavista',
    'Chihuahua City',
    'Cheran',
    'Chavinda',
    'Charo',
    'Charcas',
    'Chapalilla',
    'Chapala',
    'Chalchihuites',
    'Cerro de Ortega',
    'Cerritos',
    'Cerano',
    'Celaya',
    'Castanos',
    'La Resolana',
    'Casas Blancas',
    'Carmen',
    'Capilla de Guadalupe',
    'Canitas de Felipe Pescador',
    'Condiro',
    'Cananea',
    'Canada de Ramirez',
    'Camino Real',
    'Calvillo',
    'Victor Rosales',
    'Buenavista Tomatlan',
    'Buenavista',
    'Bucerias',
    'Brisenas de Matamoros',
    'Bermejillo',
    'Bellavista',
    'Badiraguato',
    'Ayotlan',
    'Avandaro',
    'Autlan de Navarro',
    'Atoyac de Alvarez',
    'Atotonilco el Alto',
    'Atotonilco',
    'Atequiza',
    'Atemajac de Brizuela',
    'Asientos',
    'Arteaga',
    'Ciudad de Armeria',
    'Ario de Rosales',
    'Arcelia',
    'Arandas',
    'Apozol',
    'Aporo',
    'Apodaca',
    'Apatzingán',
    'Apaseo el Grande',
    'Apaseo el Alto',
    'San Juanito de Escobedo',
    'Angostura',
    'Angamacutiro de la Union',
    'Ancon de Carros',
    'Ancon',
    'Ameca',
    'Amealco',
    'Amatitan',
    'Allende',
    'Ciudad de Allende',
    'Alamos',
    'Ajuchitlan',
    'Ajijic',
    'Ahualulco de Mercado',
    'Ahualulco del Sonido Trece',
    'Ahuacatlan',
    'Ahome',
    'Agua Zarca',
    'Agua Verde',
    'Aguascalientes',
    'Agua Prieta',
    'Agua de Obispo',
    'Agua Caliente',
    'Adolfo Ruiz Cortines',
    'Acuitzio del Canje',
    'Acatlan de Juarez',
    'Acatic',
    'Acaponeta',
    'Acambaro',
    'Abasolo',
    'El Ojito del Indio',
    'El Durazno',
    'Barrio de Jalisco (Jalisco)',
    'Victoria',
    'Doctor Mora',
    'Dolores Hidalgo Cuna de la Independencia Nacional',
    'Maravatio del Encinal',
    'El Cerro',
    'Pastor Ortiz',
    'Carrizal Grande',
    'Cerrito de Santiago',
    'San Antonio Emenguaro',
    'Victoria de Cortazar',
    'Bella Vista de Santa Maria',
    'El Acebuche',
    'San Jose Huipana',
    'Tecario',
    'Guacamayas',
    'Lázaro Cárdenas',
    'San Pedro Limon',
    'San Gabriel Pantoja',
    'Zacazonapan',
    'Ixtapilla',
    'Coyuquilla Norte',
    'San Luis de La Loma',
    'Canada de la Remonta (La Canada)',
    'Cahuatitan',
    'Aquiles Serdan (Tamala)',
    'Cerro Bola',
    'El Mirador',
    'El Filo',
    'Acequia Blanca',
    'Palomas',
    'Nuevo Vallarta',
    'Puerto Aventuras',
    'Colonia Napoles',
    'Col. Bosques de las Lomas',
    'Colonia Condesa',
    'Interlomas',
    'Punta Abreojos',
    'Rancho de los Perez (Ejido Durango)',
    'Ejido Coahuila',
    'Baja California',
    'Santa Fe',
    'Mesa de San Jacinto',
    'Mexico',
    'Miguel Aleman',
    'Nuevo Leon',
    'Agua Nacida',
    'Los Olivos',
    'Hermosillo Sahuaripa',
    'Fuentes del Valle',
    'Las Pintitas',
    'San Rafael Tlanalapan',
    'Tecamac de Felipe Villanueva',
    'Crucecita',
    'Veintidos de Febrero',
    'San Lucas el Grande',
    'Tenango de las Flores',
    'Kilometro 30',
    'San Lorenzo Axocomanitla',
    'Colinas de Plata',
    'Santa Justina Ecatepec',
    'La Trinidad Chautenco',
    'Santa Maria Texmelucan',
    'Santiago Chimalpa (Chimalpa)',
    'Unidad Habitacional Jose Maria Morelos y Pavon',
    'Zacualpan de Amilpas',
    'Ocopulco',
    'Chicahuaxtla',
    'La Aurora',
    'Ninguno [Centro de Readaptacion Social de Atlacholoaya]',
    'Tinajitas',
    'Cinco de Mayo',
    'Emiliano Zapata (El Ranchito)',
    'Colonia Adolfo Lopez Mateos',
    'Canada del Tabaco',
    'Lomas de Tepemecatl',
    'Catishtic',
    'Quintana Roo',
    'El Pozo',
    'Concepcion Caro',
    'Huajojutla (El Azul)',
    'Los Alamos II',
    'Guadalupe Victoria',
    'Buenavista (San Miguel)',
    'Leona Vicario',
    'Malpica',
    'Cerro Lloron',
    'San Isidro Cuatepec',
    'Ejido Culiacan (Culiacancito)',
    'El Capulin',
    'San Blas Otzacatipan',
    'Durango Daboxtha',
    'Cruz Blanca',
    'Concepcion de Ruiz',
    'Ajolotla',
    'Las Golondrinas',
    'Puerto las Cruces (Monte las Cruces)',
    'El Huizachal (San Isidro)',
    'Lagunas',
    'Zapoapa',
    'Buena Vista',
    'Campo Crucero (Campo Diaz)',
    'Alvaradito',
    'Tripuente',
    'Colonia San Martin',
    'El Pato',
    'Bella Vista',
    'La Labor',
    'Arroyo Zarco',
    'Colonia Nuevo Mexico',
    'Alto Lucero',
    'Regocijo',
    'Cruz Verde (Tercera Seccion Cruz Verde)',
    'Ampliacion San Miguel',
    'Barrio de San Francisco (San Agustin)',
    'Cuajimalpa (Los Cuervos)',
    'Emiliano Zapata (Anexo Ninos Heroes)',
    'San Jose',
    'San Marcos',
    'Colinas de Mexicali',
    'Alfonso Medina',
    'Poza Rica',
    'Chimalpa (Kilometro 34 Carretera Mexico-Tulancingo)',
    'Altar de Pimas',
    'Haciendas la Herradura',
    'Prolongacion Narciso Mendoza',
    'Colonia Chacalco',
    'Cotzio',
    'El Portal',
    'Cipres',
    'Alfredo V. Bonfil (Pompeya)',
    'El Tamarindo',
    'Constituyentes del Setenta y Cuatro',
    'La Cienega',
    'Ixtlahuaca',
    'Cacahuate',
    'El Colorado (Cerro Colorado)',
    'Los Limones',
    'El Nanche',
    'La Guadalupana',
    'Ayacapa',
    'Atlantida',
    'Rio Blanco',
    'Trinitaria',
    'Barrio el Calvario',
    'El Sifon de la Cuera',
    'Andres Rangel',
    'Hacienda de Rueda',
    'Union de Credito Agricola de Hermosillo',
    'Bonaterra',
    'Campo Caborca',
    'Cama de Piedra',
    'El Capulin (Rio Escondido)',
    'Sacramento (Campo la Once)',
    'La Nopalera',
    'Caja del Agua',
    'El Guayabo',
    'El Llano de Puquitzimo',
    'Joya de Montoros',
    'El Charco Largo',
    'Agua Buena',
    'El Palo Dusal',
    'El Medellin',
    'Cerro Prieto 2',
    'Tukurubin (Agua Zarca)',
    'El Palmar',
    'Granjas Familiares la Paz',
    'El Ranchito',
    'San Ignacio',
    'Nuevo Necaxa',
    'Paso Colorado',
    'Jalapa',
    'La Estacion de Ahuazotepec',
    'Saltillito',
    'La Cablotera',
    'El Tuzal',
    'La Colorada',
    'Piedras Grandes',
    'Mecatan',
    'La Gloria',
    'El Salto',
    'Jalisco',
    'Los Tigres',
    'El Espinal',
    'Yocnajab el Rosario',
    'El Colorado',
    'Proyeto Rio Sonora Hermosillo XXI',
    'San Juan Tepenahuac',
    'Geovillas de La Asuncion',
    'Altavista',
    'Michoacan',
    'Barrio San Jose',
    'Guerrero',
    'Santa Maria La Ribera',
    'Instituto del Fondo Nacional de la Vivienda para los Trabajadores Xalapa',
    'San Diego',
    'Leonardo Bravo',
    'Santa Gertrudis',
    'El Areno',
    'El Fresnito',
    'Alvarez del Castillo',
    'Buenavista de Coloradas',
    'Adolfo Lopez Mateos',
    'Centro',
    'Ciudad Chapultepec',
    'Lomas Verdes',
    'Estrella de Campeche',
    'Ampliacion San Antonio',
    'Sonora',
    'Corderena',
    'Salvador Alvarado',
    'Solidaridad',
    'Zona Centro',
    'Ermita Zaragoza',
    'Ampliacion Emiliano Zapata',
    'La Habana',
    'La Asuncion',
    'La Conchita',
    'San Jacinto',
    'El Cedral',
    'Jardines del Llano',
    'San Martin Centro',
    'Bernal',
    'Las Lomas',
    'Calyeca',
    'El Cerrito',
    'Nuevo Mexico',
    'Palma Sola',
    'Los Fresnos',
    'Alatriste',
    'Cuarto',
    'Finca Guadalupe',
    'Pueblo de Chiluca',
    'Mexico Nuevo',
    'Valle de Mexico',
    'San Martin Xochinahuac',
    'Residencial Zacatenco',
    'Jardines de La Hacienda',
    'Acolco',
    'La Condesa',
    'Las Arboledas',
    'Santiago Atepetlac',
    'Solidaridad Nacional',
    'Palmatitla',
    'La Pastora',
    'Jorge Negrete',
    'Chalma de Guadalupe',
    'Compositores Mexicanos',
    'Tlalpexco',
    'Ahuehuetes',
    'Lindavista Norte',
    'San Rafael Ticoman',
    'Real del Bosque',
    'Zona Escolar Oriente',
    'Villas de La Hacienda',
    'Instituto de Seguridad Social del Estado de Mexico y Municipios',
    'Club de Golf Bellavista',
    'Caltongo',
    'San Pablo Chimalpa',
    'Tlatelolco',
    'Carmen Serdan',
    'San Miguel Chapultepec',
    'El Jaguey',
    'Tepeyac Insurgentes',
    'El Coyol',
    'Campestre Aragon',
    'Providencia',
    'Confederacion de Trabajadores de Mexico Aragon',
    'La Pradera',
    'Claveria',
    'Libertad',
    'San Francisco Tetecala',
    'Pedregal de Echegaray',
    'Isidro Fabela',
    'Barros Sierra',
    'Progreso Tizapan',
    'Heroes de Padierna',
    'Jardines del Pedregal',
    'El Rosal',
    'Tlacoyaque',
    'Lomas de La Era',
    'Lomas Quebradas',
    'Nueva Argentina',
    'Torre Blanca',
    'Penon de Los Banos',
    'Felipe Angeles',
    'Ex Hipodromo de Peralvillo',
    'San Simon Tolnahuac',
    'Atlampa',
    'Irrigacion',
    'Granada',
    'Legaria',
    'Anzures',
    'Veronica Anzures',
    'Ampliacion Caracol',
    'Caracol',
    'Cuchilla Pantitlan',
    'Daniel Garza',
    'Roma Sur',
    'Roma Norte',
    'Jardin Balbuena',
    'Puebla',
    'Aviacion Civil',
    'Santa Martha Acatitla',
    'Santiago Acahualtepec',
    'Nueva Santa Anita',
    'Esperanza',
    'Algarin',
    'Obrera',
    'Doctores',
    'Independencia',
    'Reforma Iztaccihuatl Norte',
    'Santiago Sur',
    'Sideral',
    'San Simon Ticumac',
    'Letran Valle',
    'San Juan',
    'Alfonso XIII',
    'Arvide',
    'San Pedro de Los Pinos',
    'Bonanza',
    'Campo de Tiro Los Gamitos',
    'Ampliacion La Cebada',
    'Lomas de Bezares',
    'Lomas del Chamizal',
    'Hacienda de Las Palmas',
    'Lomas de Vista Hermosa',
    'San Jose de Los Cedros',
    'Ampliacion Jalalpa',
    'Colina del Sur',
    'Lomas de Plateros',
    'Credito Constructor',
    'Portales Oriente',
    'La Regadera',
    'Granjas Esmeralda',
    'Xoco',
    'Axotla',
    'Los Alpes',
    'Cumbres de Tepetongo',
    'Lomas de Tarango',
    'Lomas de Santa Fe',
    'Las Maromas',
    'Lomas de Las Aguilas',
    'Atlamaya',
    'Monte de Piedad',
    'La Concepcion',
    'San Juan Xalpa',
    'Puente Blanco',
    'Consejo Agrarista Mexicano',
    'Lomas de La Estancia',
    'Avante',
    'Vistas del Maurel',
    'Olimpica',
    'Popular Santa Teresa',
    'Fuentes del Pedregal',
    'Toriello Guerra',
    'Nueva Oriental Coapa',
    'Lazaro Cardenas',
    'Rincon de San Juan',
    'Granjas Coapa',
    'Magisterial',
    'Jose Lopez Portillo',
    'Jardines de San Lorenzo Tezonco',
    'Agricola Metropolitana',
    'El Triangulo',
    'Xochipilli',
    'Santiago Tepalcatlalpan',
    'San Juan Tepeximilpa',
    'Lomas de Padierna',
    'Ex Hacienda Coapa',
    'La Cabana',
    'Atenor Salas',
    'Santa Cruz Atoyac',
    'Club de Golf Mexico',
    'San Sebastian',
    'El Hujal',
    'Candelaria',
    'Santa Cruz Quilehtla',
    'Tres Valles',
    'Ixhuatlan del Sureste',
    'Tarimbaro',
  ],
  Jamaica: [
    'Yallahs',
    'Whitehouse',
    'Wakefield',
    'Vineyard Town',
    'Ulster Spring',
    'Stony Hill',
    'Spanish Town',
    'Sheffield',
    'Seaforth',
    'Savanna-la-Mar',
    'Santa Cruz',
    "St Ann's Bay",
    'Runaway Bay',
    'Red Hills',
    'Portmore',
    'Port Maria',
    'Portland Cottage',
    'Port Antonio',
    'Pembroke Hall',
    'Oracabessa',
    'Old Harbour Bay',
    'Old Harbour',
    'Ocho Rios',
    'Newport East',
    'Newark',
    'Negril',
    'Nain',
    'Morant Bay',
    'Montego Bay',
    'Moneague',
    'May Pen',
    'Mandeville',
    'Lucea',
    'Long Bay',
    'Linstead',
    'Lacovia',
    'Kingston',
    'Kensington',
    'Junction',
    'Hopewell',
    'Highgate',
    'Harbour View',
    'Hanover',
    'Grange Hill',
    'Gordon Town',
    'Friendship',
    'Freetown',
    'Florida',
    'Falmouth',
    'Fair Prospect',
    'Duncans',
    'Discovery Bay',
    'Dias',
    'Crofts Hill',
    'Claremont',
    'Christiana',
    'Buff Bay',
    'Browns Town',
    'Brooklyn',
    'Bog Walk',
    'Black River',
    'Bethel Town',
    'Belfield',
    'Balaclava',
    'Alexandria',
  ],
  'Dominican Republic': [
    'Yayas de Viajama',
    'Yaguate',
    'Villa Tapia',
    'Villa Mella',
    'Villa Jaragua',
    'Villa Francisca',
    'Villa Bisono',
    'Veragua Arriba',
    'Tamayo',
    'Tabique',
    'Sosua, Cabarete',
    'Simon Bolivar',
    'Santo Domingo',
    'Santiago de los Caballeros',
    'Santa Cruz de El Seibo',
    'Santa Cruz de Barahona',
    'Samaná',
    'San Pedro de Macorís',
    'San Miguel',
    'San Juan',
    'San Jose de Ocoa',
    'San Jose de Mendoza',
    'San Jose de Las Matas',
    'San Jeronimo',
    'San Francisco de Macorís',
    'San Francisco',
    'San Fernando de Monte Cristi',
    'Puerto Plata',
    'San Felipe',
    'San Carlos',
    'Salvaleón de Higüey',
    'Salcedo',
    'Sabaneta de Yasica',
    'Sabaneta',
    'Sabana Perdida',
    'Romana',
    'Rio San Juan',
    'Rincon Largo',
    'Punta Cana',
    'Pueblo Nuevo',
    'Polo',
    'Pinzon',
    'Pimentel',
    'Pedernales',
    'Pantoja',
    'Oviedo',
    'Ocoa',
    'Neiba',
    'Nagua',
    'Monte Plata',
    'Moncion',
    'Moca',
    'Mendoza',
    'Mata Hambre',
    'Mao',
    'Manoguayabo',
    'Maimon',
    'Madrid',
    'Los Tres Ojos de Agua',
    'Los Molinos',
    'Los Amaceyes',
    'Los Alcarrizos',
    'Licey al Medio',
    'Licey',
    'Las Terrenas',
    'La Romana',
    'La Rejolla',
    'La Milagrosa',
    'La Julia',
    'La Horma',
    'La Guama',
    'La Francia',
    'La Esperilla',
    'La Esperanza',
    'La Cienaga',
    'La Altagracia',
    'La Abadesa',
    'Juan Dolio',
    'Juancho',
    'Jimani',
    'Jarabacoa',
    'Jamao Adentro',
    'Hondo Valle',
    'Herrera',
    'Hainamosa',
    '12 de Haina',
    'Gurabo',
    'Guaymate',
    'Guananico',
    'Galvan',
    'Fantino',
    'Esperanza',
    'Ensanche Villa Faro',
    'Ensanche Ozama',
    'El Salado',
    'El Puerto',
    'El Pino',
    'El Mamey Arriba',
    'El Mamey',
    'Duverge',
    'Dajabon',
    'Cristo Rey',
    'Cristobal',
    'Cotui',
    'Constanza',
    'Concepción de la Vega',
    'Comendador',
    'Ciudad Nueva',
    'Castellar',
    'Castanuelas',
    'Canongo',
    'Cancino Adentro',
    'Cancino',
    'Cabrera',
    'Cabral',
    'Cabarete',
    'Borojol',
    'Bonao',
    'Boca de Mao',
    'Boca Chica',
    'San Cristobal',
    'Bella Vista',
    'Bayona',
    'Bayahibe',
    'Bavaro',
    'Barrio Norte',
    'Baní',
    'Bajos de Haina',
    'Azua',
    'Arroyo Salado',
    'Arroyo Hondo',
    'Arroyo Cano',
    'Arenoso',
    'Arenitas',
    'Arenazo',
    'Anton Sanchez',
    'Anama',
    'Altamira',
    'Alejandro Bass',
    'Zona Colonial',
    'Evaristo Morales',
    'Santo Domingo Oeste',
    'Santo Domingo Este',
    'Manuel Diaz',
  ],
  Curaçao: ['Willemstad', 'Otrabanda', 'Jan Thiel', 'Brievengat', 'Grote Berg'],
  'Bonaire, Sint Eustatius, and Saba': [
    'The Bottom',
    'Oranjestad',
    'Kralendijk',
  ],
  'Sint Maarten': ['Retreat Estate', 'Philipsburg', 'Cul de Sac', 'Koolbaai'],
  Cuba: [
    'Varadero',
    'Santa Clara',
    'San Luis',
    'Sabana de la Trinidad',
    'Regla',
    'Ranchuelo',
    'Matanzas',
    'La Lisa',
    'Havana',
    'Holguín',
    'Guantánamo',
    'Cocodrilo',
    'Cardenas',
    'Boqueron',
    'Arroyo Naranjo',
    'Centro Habana',
    'Habana',
    'Plaza de la Revolucion',
  ],
  Martinique: [
    'Schoelcher',
    'Saint-Pierre',
    'Saint-Joseph',
    'Sainte-Marie',
    'Sainte-Luce',
    'Riviere Salee',
    'Riviere-Pilote',
    'Macouba',
    'Le Vauclin',
    'Les Trois-Ilets',
    'Saint-Esprit',
    'Le Robert',
    'Le Morne-Rouge',
    'Le Marin',
    'Le Lorrain',
    'Le Lamentin',
    'Le Francois',
    'Le Diamant',
    'Le Carbet',
    'La Trinite',
    "L'Ajoupa-Bouillon",
    'Le Gros-Morne',
    'Fort-de-France',
    'Ducos',
    'Case-Pilote',
    'Bellefontaine',
    'Basse-Pointe',
    'La Plaine des Trois-Ilets',
    'Marigot',
    'Saint-Ange',
    'Francois',
    'Mont Vert',
  ],
  Bahamas: [
    'West End',
    'The Bight',
    'Sweeting Cay',
    'Spanish Wells',
    'Sandy Point',
    'North Palmetto Point',
    'Nassau',
    'Marsh Harbour',
    'Gregory Town',
    'Green Turtle Cay',
    'Freeport',
    'Dunmore Town',
    'Dundas Town',
    'Crown Haven',
    'Chub Cay',
    'Andros Town',
    'Alice Town',
    'Eleuthera',
    'Grand Cay',
  ],
  Bermuda: [
    'Sunnyside',
    'Somerset',
    'Saint George',
    'Hamilton',
    'Cross Bay',
    'Stovel Bay',
  ],
  Anguilla: ['The Valley'],
  'Trinidad and Tobago': [
    'Woodland',
    'Williamsville',
    'Vistabella',
    'Valsayn',
    'Valencia',
    'Upper Carapichaima',
    'Tunapuna',
    'Tabaquite',
    'South Oropouche',
    'Siparia',
    'Scarborough',
    'Santa Cruz',
    'San Juan',
    'Sangre Grande',
    'San Fernando',
    'Saint Joseph',
    'Saint James',
    'Saint Augustine',
    'Roxborough',
    'Rio Claro',
    'Princes Town',
    'Preysal',
    'Port of Spain',
    'Poole',
    'Point Fortin',
    'Plymouth',
    'Piparo',
    'Piarco',
    'Petit Valley',
    'Penal',
    'Palo Seco',
    'New Grant',
    'Morvant',
    'Mc Bean',
    'Mason Hall',
    'Maraval',
    'Marabella',
    'Lower Quarter',
    'Los Bajos',
    'Laventille',
    'La Brea',
    'Kelly Village',
    'Indian Walk',
    'Icacos',
    'Gran Couva',
    'Gasparillo',
    'Fyzabad',
    'Freeport',
    'Florida',
    'El Dorado',
    'Dowe',
    'Diego Martin',
    'Debe',
    "D'Abadie",
    'Curepe',
    'Cunupia',
    'Cumuto',
    'Couva',
    'Claxton Bay',
    'Chase Village',
    'Chaguanas',
    'Caroni',
    'Carenage',
    'Carapichaima',
    'Caparo',
    'California',
    'Barrackpore',
    'Barataria',
    'Avocat',
    'Arouca',
    'Arima',
    'Glencoe',
    'Plaisance Park',
    'Palmiste',
    'La Romain',
    'Trincity',
    'Aranguez',
  ],
  'St Kitts and Nevis': [
    'Trinity',
    'Tabernacle',
    'Sandy Point Town',
    'Old Road Town',
    'Nicola Town',
    'Newton Ground',
    'Monkey Hill',
    'Molineux',
    'Middle Island',
    'Jessup',
    'Dieppe Bay Town',
    'Cotton Ground',
    'Charlestown',
    'Cayon',
    'Beaumont',
    'Basseterre',
  ],
  Dominica: [
    'Soufriere',
    'Saint Joseph',
    'Roseau',
    'Rosalie',
    'Portsmouth',
    'Pointe Michel',
    'Massacre',
    'Marigot',
    'Mahaut',
    'Colihaut',
    'Berekua',
    'Wotten Waven',
    'Belfast',
  ],
  'Antigua and Barbuda': [
    "St John's",
    'Piggotts',
    'Old Road',
    'Liberta',
    'Freetown',
    'Falmouth',
    'Codrington',
    'Bolands',
    'All Saints',
  ],
  'Saint Lucia': [
    'Vieux Fort',
    'Soufriere',
    'Praslin',
    'Monchy',
    'Micoud',
    "L'Hermitage",
    'Laborie',
    'Gros Islet',
    'Grand Anse',
    'Dennery',
    'Dauphin',
    'Choiseul',
    'Chassin',
    'Castries',
    'Beausejour',
    'Anse La Verdure',
    'Anse La Raye',
  ],
  'Turks and Caicos Islands': [
    'Cockburn Town',
    'Blue Hills Settlements',
    'Balfour Town',
    'Providenciales',
  ],
  Aruba: [
    'San Nicolas',
    'Santa Cruz',
    'Savaneta',
    'Paradera',
    'Palm Beach',
    'Oranjestad',
    'Noord',
    'Alto Vista',
  ],
  'British Virgin Islands': ['Road Town', 'East End'],
  'Saint Vincent and the Grenadines': [
    'Rutland Vale',
    'Questelles',
    'Port Elizabeth',
    'Kingstown',
    'Georgetown',
    'Chateaubelair',
  ],
  Montserrat: ['Plymouth', 'Brades'],
  Guadeloupe: [
    'Vieux-Habitants',
    'Vieux-Fort',
    'Versailles',
    'Trois-Rivieres',
    'Terre-de-Haut',
    'Petites Anses',
    'Saint-Francois',
    'Sainte-Rose',
    'Sainte-Anne',
    'Saint-Claude',
    'Port-Louis',
    'Port Louis',
    'Pointe-Noire',
    'Pointe-à-Pitre',
    'Petit-Canal',
    'Petit-Bourg',
    'Les Mangles',
    'Les Abymes',
    'Le Moule',
    'Le Gosier',
    'Lamentin',
    'Grand-Bourg',
    'Gourbeyre',
    'Deshaies',
    'Capesterre-Belle-Eau',
    'Capesterre-de-Marie-Galante',
    'Bouillante',
    'Basse-Terre',
    'Baillif',
    'Baie Mahault',
    'Baie-Mahault',
    'Anse-Bertrand',
    "Morne-a-l'Eau",
    'La Desirade',
    'Jarry',
    'Dorville',
    'Saint-Louis',
    'Goyave',
  ],
  'Saint Martin': ['Marigot', 'Concordia', 'Agrement', 'Anse Marcel'],
  'Saint Barthélemy': ['Gustavia', 'Grand Cul-de-Sac'],
  Grenada: [
    'Victoria',
    'Sauteurs',
    "St. George's",
    'Morne Tranquille',
    'Hillsborough',
    'Grenville',
    'Gouyave',
  ],
  'Cayman Islands': [
    'West Bay',
    'Spot Bay',
    'Savannah',
    'George Town',
    'Bodden Town',
  ],
  Belize: [
    'Spanish Lookout',
    'San Pedro Town',
    'San Ignacio',
    'Placencia',
    'Independence',
    'Dangriga',
    'Corozal',
    'Benque Viejo',
    'Belmopan',
    'Belize City',
    'Stann Creek District',
  ],
  'El Salvador': [
    'Zaragoza',
    'Usulutan',
    'Tonacatepeque',
    'Tejutla',
    'Soyapango',
    'Sonsonate',
    'San Vicente',
    'Santo Tomas',
    'Santo Domingo',
    'Santa Cruz Michapa',
    'Santa Ana',
    'San Salvador',
    'San Miguel',
    'San Martin',
    'San Marcos',
    'San Luis Talpa',
    'San Juan Talpa',
    'San Juan Opico',
    'San Jacinto',
    'San Ignacio',
    'Palmira',
    'Osicala',
    'Nuevo Cuscatlan',
    'Santa Tecla',
    'Nueva Guadalupe',
    'Metapan',
    'Mejicanos',
    'Lourdes',
    'La Reina',
    'La Libertad',
    'Izalco',
    'Intipuca',
    'Ilopango',
    'Ilobasco',
    'El Congo',
    'Delgado',
    'Cuscatancingo',
    'Conchagua',
    'Cojutepeque',
    'Ciudad Arce',
    'Cinquera',
    'Chalatenango',
    'Buenos Aires',
    'Berlin',
    'Apopa',
    'Apaneca',
    'Antiguo Cuscatlan',
    'Anamoros',
    'Ahuachapan',
    'Acajutla',
  ],
  Guatemala: [
    'Zacapa',
    'Villa Nueva',
    'Villa Canales',
    'Totonicapán',
    'Sumpango',
    'Solola',
    'Santa Rosa',
    'Santa Lucia Milpas Altas',
    'Santa Catarina Pinula',
    'San Raimundo',
    'San Pedro Sacatepequez',
    'San Pedro La Laguna',
    'San Pedro Carcha',
    'San Pedro Ayampuc',
    'San Pablo',
    'San Lucas Sacatepequez',
    'San Juan del Obispo',
    'San Jose Pinula',
    'San Cristobal Totonicapan',
    'San Benito',
    'Salama',
    'Sacapulas',
    'Rio Hondo',
    'Retalhuleu',
    'Rabinal',
    'Quetzaltenango',
    'Poptun',
    'Peten',
    'Petapa',
    'Panzos',
    'Panajachel',
    'Palin',
    'Monjas',
    'Momostenango',
    'Mixco',
    'Mazatenango',
    'Los Angeles',
    'La Esperanza',
    'Jutiapa',
    'Jalapa',
    'Huehuetenango',
    'Fraijanes',
    'Flores',
    'Estanzuela',
    'Esquipulas',
    'Escuintla',
    'Guastatoya',
    'Cuyotenango',
    'Coban',
    'Coatepeque',
    'Ciudad Vieja',
    'Melchor de Mencos',
    'Guatemala City',
    'Chiquimula',
    'Chinautla',
    'Chimaltenango',
    'Chichicastenango',
    'Antigua Guatemala',
    'Amatitlán',
    'Agua Blanca',
    'Ciudad Real',
  ],
  Honduras: [
    'Puerto Cortez',
    'Cortes',
    'Yoro',
    'Villanueva',
    'Victoria',
    'Valle de Angeles',
    'Trujillo',
    'Tocoa',
    'Tela',
    'Tegucigalpa',
    'Talanga',
    'Sula',
    'Siguatepeque',
    'Santa Rosa de Copán',
    'Santa Lucia',
    'Santa Barbara',
    'San Pedro Sula',
    'San Manuel',
    'San Lorenzo',
    'San Juan de Flores',
    'Coxen Hole',
    'Puerto Lempira',
    'Piraera',
    'Pinalejo',
    'Omoa',
    'Nacaome',
    'Los Angeles',
    'La Lima',
    'La Entrada',
    'La Ceiba',
    'Juticalpa',
    'Gracias',
    'El Progreso',
    'Danli',
    'Cucuyagua',
    'Copán',
    'Comayaguela',
    'Comayagua',
    'Cofradia',
    'Choloma',
    'Catacamas',
    'Cane',
    'Copan Ruinas',
  ],
  Nicaragua: [
    'Tola',
    'Sebaco',
    'Santo Tomas',
    'San Rafael del Sur',
    'San Juan de Oriente',
    'Rivas',
    'Ocotal',
    'Nueva Guinea',
    'Nindiri',
    'Nandaime',
    'Matiguas',
    'Mateare',
    'Matagalpa',
    'Masaya',
    'Managua',
    'León',
    'Jinotepe',
    'Jinotega',
    'Jalapa',
    'Granada',
    'Esteli',
    'El Sauce',
    'Dolores',
    'Diriomo',
    'Diriamba',
    'Condega',
    'Chinandega',
    'Chichigalpa',
    'Boaco',
    'Bluefields',
    'Amayito',
    'Alsacia',
    'Aguas Calientes',
    'Agua Fria',
    'Ciudad Sandino',
  ],
  'Costa Rica': [
    'Zent',
    'Zarcero',
    'Zapote',
    'Vuelta de Jorco',
    'Venecia',
    'Uvita',
    'Uruca',
    'Upala',
    'Union',
    'Turrucares',
    'Turrialba',
    'Turin',
    'Tres Rios',
    'Tilaran',
    'Tigra',
    'Tarcoles',
    'Taras',
    'Hacienda Tamarindo',
    'Talamanca',
    'Tablon',
    'Siquirres',
    'Sarchi Sur',
    'Sarchi',
    'Sarapiqui',
    'San Vito',
    'Santo Domingo',
    'Santiago',
    'Santa Cruz',
    'Santa Clara',
    'Santa Barbara',
    'Santa Ana',
    'San Sebastian',
    'San Ramon',
    'San Rafael',
    'San Pedro',
    'San Pablo',
    'San Mateo',
    'San Jose de la Montana',
    'San Jose',
    'San José',
    'San Isidro',
    'San Ignacio',
    'San Francisco',
    'San Diego',
    'San Carlos',
    'San Antonio',
    'Sámara',
    'Sabanilla',
    'Rosario',
    'Rivas',
    'Quesada',
    'Quepos',
    'Quebrada Grande',
    'Puntarenas',
    'Limón',
    'Puerto Jimenez',
    'Pozos',
    'Pocosol',
    'Playa Hermosa',
    'Pital',
    'Piedades',
    'Perez',
    'Penjamo',
    'Pejibaye',
    'Pavas',
    'Parrita',
    'Paraiso',
    'Paquera',
    'Palmar Norte',
    'Palmares',
    'Pacuarito',
    'Orotina',
    'Nosara',
    'Nicoya',
    'Naranjo',
    'Nandayure',
    'Moravia',
    'Mora',
    'Monte Verde',
    'Montelimar',
    'Miramar',
    'Mercedes',
    'Mata de Platano',
    'Los Chiles',
    'Liberia',
    'La Suiza',
    'Las Juntas',
    'La Garita',
    'La Cruz',
    'Juan Vinas',
    'Jicaral',
    'Jaco',
    'Isla de Tibas',
    'Huacas',
    'Hojancha',
    'Heredia',
    'Hacienda Monteverde',
    'Hacienda Coronado',
    'Guatuso',
    'Guatemala',
    'Guapiles',
    'Guadalupe',
    'Guacimo',
    'Guacima',
    'Griega',
    'Grecia',
    'Golfito',
    'Garabito',
    'Fortuna',
    'La Fortuna',
    'Florida',
    'Filadelfia',
    'Fatima',
    'Esparza',
    'Escazu',
    'El Coco',
    'Desamparados',
    'Curridabat',
    'Coto Brus',
    'Corralillo',
    'Coronado',
    'Concepcion',
    'Colon',
    'Cobano',
    'Ciudad Cortes',
    'Cartago',
    'Cartagena',
    'Carrillo',
    'Carmona',
    'Carana',
    'Capellades',
    'Canas',
    'Canafistula',
    'Cahuita',
    'Cachi',
    'Buenos Aires',
    'Brasilito',
    'Brasil',
    'Bellavista',
    'Belen',
    'Batan',
    'Barva',
    'Barreal',
    'Bagaces',
    'Atenas',
    'Atalanta',
    'Aserri',
    'Animas',
    'Angeles',
    'Altamira',
    'Alajuelita',
    'Alajuela',
    'Aguas Zarcas',
    'Aguas Buenas',
    'Aguacate',
    'Aeropuerto',
    'Abangaritos',
    'Guanacaste',
    'Manuel Antonio',
    'Cariari',
    'Paso Canoas',
    'Puerto Viejo de Talamanca',
    'Playa Flamingo',
  ],
  Ecuador: [
    'Zaruma',
    'Zamora',
    'Yantzaza',
    'Yaguachi Nuevo',
    'Vuelta Larga',
    'Vinces',
    'Ventanas',
    'Valdivia',
    'Tumbaco',
    'Tulcán',
    'Tena',
    'Tarqui',
    'Tanicuchi',
    'Sucua',
    'Sozoranga',
    'Saquisili',
    'Santo Domingo de los Colorados',
    'Santo Domingo',
    'Santa Rosa',
    'Santa Elena',
    'Santa Ana',
    'San Rafael',
    'San Miguel de Salcedo',
    'San Juan Bosco',
    'Sangolqui',
    'San Francisco',
    'San Antonio de Ibarra',
    'San Antonio',
    'Samborondon',
    'Salitre',
    'Salinas',
    'Rosa Zarate',
    'Rocafuerte',
    'Riobamba',
    'Quito',
    'Quevedo',
    'Puyo',
    'Puyango',
    'Pujili',
    'Puerto Villamil',
    'Puerto Quito',
    'Puerto Lopez',
    'Puerto Baquerizo Moreno',
    'Puerto Ayora',
    'Portoviejo',
    'Portovelo',
    'Playas',
    'Pinas',
    'Pillaro',
    'Picoaza',
    'Pichincha',
    'Pelileo',
    'Pedro Vicente Maldonado',
    'Pedro Carbo',
    'Pedernales',
    'Pasaje',
    'Otavalo',
    'Nobol',
    'Nayon',
    'Naranjal',
    'Mulalo',
    'Montecristi',
    'Montanita',
    'Milagro',
    'Mariscal Sucre',
    'Manta',
    'Machala',
    'Machachi',
    'Macas',
    'Macara',
    'Loja',
    'La Union',
    'La Troncal',
    'Latacunga',
    'La Puntilla',
    'La Mana',
    'La Magdalena',
    'La Libertad',
    'Nueva Loja',
    'Juan Montalvo',
    'Jipijapa',
    'Ibarra',
    'Huaquillas',
    'Huachi Chico',
    'Hacienda Santo Domingo',
    'Hacienda Santa Isabel',
    'Hacienda San Pablo',
    'Carcelen',
    'Guayas',
    'Guayaquil',
    'Guaranda',
    'Gualaquiza',
    'Gualaceo',
    'Galapagos',
    'Febres Cordero',
    'Esmeraldas',
    'El Triunfo',
    'Durán',
    'El Angel',
    'Daule',
    'Data de Villamil',
    'Cumbaya',
    'Cuenca',
    'Cotocollao',
    'Chone',
    'Chillogallo',
    'Chillanes',
    'Cayambe',
    'Catarama',
    'Catamayo',
    'Canar',
    'Calceta',
    'Biblian',
    'Bellavista',
    'Banos',
    'Balzar',
    'Balao Chico',
    'Bahia de Caraquez',
    'Babahoyo',
    'Baba',
    'Azogues',
    'Ayacucho',
    'Atuntaqui',
    'Atacames',
    'Archidona',
    'Ambato',
    'Amaguana',
    'Alausi',
    'El Inca',
    'John F. Kennedy',
  ],
  Colombia: [
    'Zipaquirá',
    'Zarzal',
    'Zaragoza',
    'Yumbo',
    'Yotoco',
    'Yopal',
    'Yondo',
    'Yavarate',
    'Yaguara',
    'Yacopi',
    'Viota',
    'Villeta',
    'Villavicencio',
    'Villa del Rosario',
    'Villapinzon',
    'Villanueva',
    'Villamaria',
    'Villa de Leyva',
    'Victoria',
    'Viani',
    'Venecia',
    'Velez',
    'Valledupar',
    'Valle de San Jose',
    'Valencia',
    'Usme',
    'Usaquen',
    'Urrao',
    'Villa de San Diego de Ubate',
    'Turbo',
    'Turbana',
    'Turbaco',
    'Tuquerres',
    'Tunja',
    'Tumaco',
    'Tuluá',
    'Trujillo',
    'Trinidad',
    'Topaga',
    'Tolú',
    'Tocancipa',
    'Tocaima',
    'Titiribi',
    'Timbio',
    'Timana',
    'Tibu',
    'Tesalia',
    'Tenjo',
    'Tauramena',
    'Tarapaca',
    'Tamesis',
    'Tame',
    'Tado',
    'Tabio',
    'Supia',
    'Suesca',
    'Sucre',
    'Subachoque',
    'Suba',
    'Suaza',
    'Sopo',
    'Sonson',
    'Soledad',
    'Sogamoso',
    'Socorro',
    'Soacha',
    'Sincelejo',
    'Simijaca',
    'Silvania',
    'Sibate',
    'Sevilla',
    'Sesquile',
    'Segovia',
    'Saravena',
    'San Vicente del Caguan',
    'Santuario',
    'El Santuario',
    'Santo Tomas',
    'Santo Domingo',
    'Santa Rosa de Viterbo',
    'Santa Rosa de Osos',
    'Santa Rosa de Cabal',
    'Santander de Quilichao',
    'Santa Marta',
    'Santa Barbara',
    'San Roque',
    'San Pablo',
    'San Martin',
    'San Luis',
    'San Juan del Cesar',
    'San Juan de Betulia',
    'San Juan',
    'San Jose de la Montana',
    'San Jeronimo',
    'San Gil',
    'San Cristobal',
    'San Carlos',
    'San Bernardo',
    'San Antero',
    'San Andrés',
    'San Andres',
    'San Alberto',
    'San Agustin',
    'Sampues',
    'Saldana',
    'Salamina',
    'Sahagun',
    'Sabaneta',
    'Sabanalarga',
    'Sabana de Torres',
    'Rozo',
    'Rosas',
    'Roldanillo',
    'Risaralda',
    'Riosucio',
    'Rionegro',
    'Riohacha',
    'Riofrio',
    'Rio de Oro',
    'Retiro',
    'Restrepo',
    'Raquira',
    'Apulo',
    'Quito',
    'Quimbaya',
    'Quibdó',
    'Puerto Triunfo',
    'Puerto Salgar',
    'Puerto Narino',
    'Puerto Lopez',
    'Inirida',
    'Puerto Colombia',
    'Puerto Carreño',
    'Puerto Boyacá',
    'Puerto Berrio',
    'Puerto Asís',
    'Puente Aranda',
    'Pradera',
    'Pore',
    'Popayan',
    'Planeta Rica',
    'Pivijay',
    'Pitalito',
    'Piedecuesta',
    'Pereira',
    'Pelaya',
    'Pasto',
    'Pamplona',
    'Palmira',
    'Palmar de Varela',
    'Paipa',
    'Pailitas',
    'Paicol',
    'Pacora',
    'Pacho',
    'Oporapa',
    'Ocaña',
    'Nocaima',
    'Nobsa',
    'Nemocon',
    'Neiva',
    'Natagaima',
    'Narino',
    'Muzo',
    'Mosquera',
    'Montería',
    'Montenegro',
    'Montelíbano',
    'Montebello',
    'Moniquira',
    'Mompos',
    'Mogotes',
    'Mocoa',
    'Mitú',
    'Miranda',
    'Miraflores',
    'Mendoza',
    'Melgar',
    'Medellin',
    'Medellín',
    'Mariquita',
    'Marinilla',
    'Manzanares',
    'Manizales',
    'Mani',
    'Manaciare',
    'Malambo',
    'Malaga',
    'Majagual',
    'Maicao',
    'Magangué',
    'Madrid',
    'Macheta',
    'Los Patios',
    'Los Andes',
    'Lorica',
    'Libano',
    'Leticia',
    'Lerida',
    'Leiva',
    'Lebrija',
    'La Virginia',
    'La Vega',
    'La Union',
    'La Tebaida',
    'La Primavera',
    'La Plata',
    'La Paz',
    'La Magdalena',
    'La Jagua de Ibirico',
    'La Florida',
    'La Estrella',
    'La Dorada',
    'La Ceja',
    'La Calera',
    'La Argentina',
    'Juan de Acosta',
    'Juanchito',
    'Jerico',
    'Jardin',
    'Jamundi',
    'Ituango',
    'Itaguei',
    'Istmina',
    'Isabel Lopez',
    'Ipiales',
    'Iles',
    'Ibague',
    'Honda',
    'Hojas Anchas',
    'Guayabetal',
    'Guayabal de Siquima',
    'Guayabal',
    'Guatape',
    'Guarne',
    'Guamal',
    'Guaduas',
    'Guacari',
    'Gomez Plata',
    'Giron',
    'Girardota',
    'Girardot City',
    'Ginebra',
    'Gigante',
    'Garzon',
    'Garzón',
    'Garagoa',
    'Galapa',
    'Gachancipa',
    'Fusagasuga',
    'Fuquene',
    'Funza',
    'Fundacion',
    'Fresno',
    'Fortul',
    'Fontibon',
    'Fonseca',
    'Fomeque',
    'Floridablanca',
    'Florida',
    'Florencia',
    'Filandia',
    'Facatativá',
    'Espinal',
    'Envigado',
    'Entrerrios',
    'Engativa',
    'El Tambo',
    'El Rosal',
    'El Paso',
    'El Dovio',
    'El Charquito',
    'El Cerrito',
    'El Bagre',
    'Duitama',
    'Dosquebradas',
    'Donmatias',
    'Dagua',
    'Dabeiba',
    'Curumani',
    'Curiti',
    'Cumaribo',
    'Cujacal',
    'Cúcuta',
    'Cubara',
    'Coyaima',
    'Cota',
    'Corozal',
    'Coronado',
    'Corinto',
    'Copacabana',
    'Concordia',
    'Colombia',
    'Cogua',
    'Clemencia',
    'Cisneros',
    'Circasia',
    'Cienega',
    'Cienaga de Oro',
    'Cienaga',
    'Choconta',
    'Chiquinquira',
    'Chipaque',
    'Chinu',
    'Chinchina',
    'Chinacota',
    'Chigorodo',
    'Chia',
    'Charala',
    'Chaparral',
    'Cerete',
    'Caucasia',
    'Casuarito',
    'Cartago',
    'Cartagena',
    'El Carmen de Viboral',
    'Caqueza',
    'Candelaria',
    'Canaveral',
    'Canasgordas',
    'Campo de la Cruz',
    'Campoalegre',
    'Santiago de Cali',
    'Caldas',
    'Calarcá',
    'Cajica',
    'Cajamarca',
    'Caicedonia',
    'Caicedo',
    'Buritaca',
    'Bugalagrande',
    'Guadalajara de Buga',
    'Buga',
    'Buenos Aires',
    'Buenavista',
    'Buenaventura',
    'Bucaramanga',
    'Briceno',
    'Boyaca',
    'Bosconia',
    'Bosa',
    'Bonda',
    'Bogota',
    'Bogotá',
    'Bochalema',
    'Bocas del Palo',
    'Bello',
    'Belen de Umbria',
    'Belen',
    'Barranquilla',
    'Barrancabermeja',
    'Barragan',
    'Barichara',
    'Barcelona',
    'Barbosa',
    'Baranoa',
    'Ayapel',
    'Atlantico',
    'Armenia',
    'Arjona',
    'Arbela',
    'Arauquita',
    'Arauca',
    'Aracataca',
    'Aquitania',
    'Apia',
    'Apartado',
    'Santa Fe de Antioquia',
    'Antioquia',
    'Anserma',
    'Anori',
    'Anganoy',
    'Andes',
    'Andalucia',
    'Anapoima',
    'Amalfi',
    'Alvarado',
    'Alfonso Lopez',
    'Alcaparral',
    'Alcala',
    'Albania',
    'Alban',
    'Aipe',
    'Aguazul',
    'Aguadas',
    'Aguaclara',
    'Aguachica',
    'Acaricuará',
    'Acacias',
    'Abejorral',
    'Yarumal',
    'Las Vegas',
    'Montevideo',
    'Agua Bonita',
    'La Serena',
    'Palestina',
    'La Hormiga',
    'Monterrey',
    'Puerto Gaitan',
    'El Pital',
    'La Esperanza',
    'Cali',
    'Cundinamarca',
    'La Dolores',
    'La Palma',
    'Santa Helena',
    'Los Martires',
    'Fredonia',
    'La Cruz',
    'Rafael Uribe Uribe',
    'Delicias',
    'Chapinero',
    'La Avanzada',
    'Tolima',
    'Carepa',
    'Ciudad Bolivar',
    'Vegachi',
    'Agustin Codazzi',
    'El Retorno',
    'Plato',
    'Santa Helena del Opon',
    'La Guajira',
    'El Rocio',
    'San Jose',
    'San Juan de Soco',
    'San José del Guaviare',
    'Orito',
    'Teusaquillo',
    'Kennedy',
    'Samana',
    'Ricaurte',
    'Barrios Unidos',
    'Paris',
    'La Pradera',
    'El Corazon',
    'Barrios de Jesus',
    'Los Naranjos',
    'Rio Blanco',
    'Piendamo',
    'Sopetran',
    'La Merced',
    'Aguas Calientes',
  ],
  Peru: [
    'Victor Larco Herrera',
    'Tumbes',
    'Trujillo',
    'Talara',
    'Sisa',
    'Pucallpa',
    'Piura',
    'Pimentel',
    'Paita',
    'Moyobamba',
    'Morrope',
    'La Peca',
    'Lambayeque',
    'La Libertad',
    'La Esperanza',
    'Jaén',
    'Iquitos',
    'Illimo',
    'Huaraz',
    'Huánuco',
    'Huanchaco',
    'Ferrenafe',
    'El Conde',
    'Chota',
    'Chimbote',
    'Chiclayo',
    'Chapaira',
    'Chachapoyas',
    'Carhuaz',
    'Caras',
    'Cajamarca',
    'Bellavista',
    'Barranca',
    'Alto Peru',
    'San Isidro',
    'La Victoria',
    'Puerto de Belen',
    'Vitarte',
    'Villa',
    'Ventanilla',
    'Tinyahuarco',
    'Tambopata',
    'Tacna',
    'Surquillo',
    'Santiago de Surco',
    'Surco',
    'Sunampe',
    'Santa Anita',
    'San Martin de Porras',
    'San Martin',
    'San Luis',
    'San Jose de los Chorrillos',
    'San Jeronimo',
    'San Borja',
    'Rimac',
    'Punta Hermosa',
    'Puno',
    'Puerto Maldonado',
    'Puerto Cashpajali',
    'Puente de Piedra',
    'Pueblo Libre',
    'Pisco',
    'Pasco',
    'Paramonga',
    'Oxapampa',
    'Nazca',
    'Moquegua',
    'Miraflores',
    'Magdalena del Mar',
    'Macusani',
    'Lurin',
    'San Juan de Lurigancho',
    'Loreto',
    'Lince',
    'Lima',
    'Boca Piedras',
    'La Perla',
    'La Molina',
    'Bella Union',
    'Junin',
    'Juliaca',
    'Jesus Maria',
    'Jauja',
    'Independencia',
    'Ilo',
    'Ica',
    'Huaytara',
    'Huaral',
    'Huancayo',
    'Huancavelica',
    'Huacho',
    'El Jato',
    'El Agustino',
    'Cusco',
    'La Condeza',
    'Comas',
    'Ciudad de Dios',
    'Cieneguilla',
    'Chosica',
    'Chorrillos',
    'Chincha Alta',
    'Chincha',
    'Charcani Grande',
    'Characato',
    'Chancay',
    'Chaclacayo',
    'Cerro de Pasco',
    'Ccullco',
    'Carabayllo',
    'Cangallo',
    'Callao',
    'California',
    'Barranco',
    'Barcelona',
    'Ayacucho',
    'Ate',
    'Arequipa',
    'Andahuaylas',
    'Ancon',
    'Abancay',
    'San Martin de Porres',
    'Tambo',
    'Las Palmeras',
    'Los Olivos',
    'San Miguel',
    'Calzada',
    'Magdalena',
    'Alata',
    'Allgahuanca',
    'Achaque',
    'Carmen de La Legua',
    'Barrio Obrero Industrial',
  ],
  Panama: [
    'Volcan',
    'Veraguas',
    'Vacamonte',
    'Tocumen',
    'Sona',
    'Santiago',
    'San Miguelito',
    'San Carlos',
    'Sabalo',
    'Playón Chico',
    'Playa Blanca',
    'Penonomé',
    'Pedasí',
    'Panama City',
    'Narganá',
    'Mulatupo',
    'Buabidi',
    'Las Tablas',
    'Las Cumbres',
    'La Pintada',
    'La Concepcion',
    'La Chorrera',
    'Garachiné',
    'Farallon',
    'David',
    'Coloncito',
    'Colón',
    'Chitré',
    'Chame',
    'Bugaba',
    'Boquete',
    'Boca Chica',
    'Bajo Boquete',
    'Bagala',
    'Arraijan',
    'Anton',
    'Alanje',
    'Aguadulce',
    'Curundame',
    'Sajalices de Capira',
  ],
  Haiti: [
    'Tabarre',
    'Port-de-Paix',
    'Port-au-Prince',
    'Petionville',
    'Miragoane',
    'Leogane',
    'Jérémie',
    'Hinche',
    'Gonaïves',
    'Delmas 73',
    'Delmas',
    'Delmar',
    'Les Cayes',
    'Cap-Haïtien',
    'Canape Vert',
    'Bourdon',
    'Bon Repos',
    'Carrefour',
  ],
  Chile: [
    'Yumbel',
    'Vitacura',
    'Viña del Mar',
    'Villarrica',
    'Villa Alemana',
    'Vicuna',
    'Victoria',
    'Valparaíso',
    'Vallenar',
    'Valdivia',
    'Tucapel',
    'Traiguen',
    'Tome',
    'Tocopilla',
    'Tierra Amarilla',
    'Temuco',
    'Taltal',
    'Talcahuano',
    'Talca',
    'Talagante',
    'San Vicente de Tagua Tagua',
    'San Vicente',
    'Santiago',
    'Santa Maria',
    'Santa Cruz',
    'Santa Barbara',
    'San Ramon',
    'San Pedro de Atacama',
    'San Pedro',
    'San Miguel',
    'San Jose de Maipo',
    'San Jose de la Mariquina',
    'San Joaquin',
    'San Javier',
    'San Fernando',
    'San Felipe',
    'San Esteban',
    'San Clemente',
    'San Carlos',
    'San Bernardo',
    'San Antonio',
    'Salamanca',
    'Sagrada Familia',
    'Rio Negro',
    'Rio Bueno',
    'Rinconada de los Andes',
    'Retiro',
    'Requinoa',
    'Rengo',
    'Renca',
    'Recreo',
    'Recoleta',
    'Rancagua',
    'Quirihue',
    'Quintero',
    'Quinta Normal',
    'Quinta de Tilcoco',
    'Quilpué',
    'Quillota',
    'Quilicura',
    'Quemchi',
    'Putu',
    'Putaendo',
    'Purranque',
    'Punta Arenas',
    'Puerto Varas',
    'Quellon',
    'Puerto Octay',
    'Puerto Natales',
    'Port Montt',
    'Puerto Cisnes',
    'Puerto Aisén',
    'Puente Alto',
    'Pucon',
    'Puchuncavi',
    'Providencia',
    'Pozo Almonte',
    'Porvenir',
    'Pitrufquen',
    'Pisco Elqui',
    'Pirque',
    'Pichilemu',
    'Pichidegua',
    'Pichidangui',
    'Pica',
    'Peumo',
    'Petorca',
    'Penco',
    'Penalolen',
    'Penaflor',
    'Parral',
    'Panguipulli',
    'Palmilla',
    'Paine',
    'Paillaco',
    'Padre Las Casas',
    'Padre Hurtado',
    'Ovalle',
    'Osorno',
    'Olmue',
    'Olivar',
    'Nunoa',
    'Nueva Imperial',
    'Nogales',
    'Nancagua',
    'Nacimiento',
    'Mulchen',
    'Monte Patria',
    'Molina',
    'Melipilla',
    'Mejillones',
    'Maullin',
    'Maule',
    'Maipu',
    'Macul',
    'Machali',
    'Lota',
    'Los Vilos',
    'Los Lagos',
    'Los Ángeles',
    'Los Andes',
    'Los Alamos',
    'Lonquimay',
    'Longavi',
    'Loncoche',
    'Lo Barnechea',
    'Llanquihue',
    'Llaillay',
    'Linares',
    'Limache',
    'Lican Ray',
    'Lebu',
    'Lautaro',
    'La Union',
    'La Serena',
    'Las Condes',
    'Las Cabras',
    'La Reina',
    'Lanco',
    'Lampa',
    'La Ligua',
    'Laja',
    'La Granja',
    'Florida',
    'La Florida',
    'La Cruz',
    'La Cisterna',
    'La Calera',
    'Isla de Maipo',
    'Iquique',
    'Illapel',
    'Huepil',
    'Hualpen',
    'Hualane',
    'Hijuelas',
    'Hacienda La Calera',
    'Graneros',
    'Gorbea',
    'Galvarino',
    'Futaleufu',
    'Frutillar Alto',
    'Frutillar',
    'Espejo',
    'Empedrado',
    'El Quisco',
    'El Monte',
    'El Bosque',
    'Duao',
    'Donihue',
    'Dalcahue',
    'Curicó',
    'Curanilahue',
    'Curacavi',
    'Curacautin',
    'Coronel',
    'Coquimbo',
    'Copiapó',
    'Constitucion',
    'Concon',
    'Conchali',
    'Concepción',
    'Collipulli',
    'Colina',
    'Coinco',
    'Coyhaique',
    'Coelemu',
    'Codegua',
    'Cochrane',
    'Chonchi',
    'Chimbarongo',
    'Chillan Viejo',
    'Chillan',
    'Chiguayante',
    'Chicureo Abajo',
    'Chepica',
    'Chanco',
    'Chanaral',
    'Chaiten',
    'Cerrillos',
    'Central',
    'Cauquenes',
    'Catemu',
    'Castro',
    'Casablanca',
    'Cartagena',
    'Canete',
    'Calera de Tango',
    'Caldera',
    'Calbuco',
    'Calama',
    'Cajon',
    'Cabrero',
    'Cabildo',
    'Bulnes',
    'Buin',
    'Arica',
    'Arauco',
    'Antofagasta',
    'Angol',
    'Ancud',
    'Alto Hospicio',
    'Algarrobo',
    'San Pedro de la Paz',
    'Curauma',
    'La Pintana',
    'Lo Prado',
    'Chiloe',
    'El Carmen',
  ],
  Bolivia: [
    'Vallegrande',
    'Trinidad',
    'Tarija',
    'Sucre',
    'Santa Cruz',
    'Reyes',
    'Quillacollo',
    'Potosí',
    'Oruro',
    'La Paz',
    'Cochabamba',
    'Cobija',
  ],
  'French Polynesia': [
    'Rikitea',
    'Vaitape',
    'Uturoa',
    'Tohautu',
    'Tiputa',
    'Temae',
    'Taunoa',
    'Taravao',
    'Rautini',
    'Punaauia',
    'Pirae',
    'Papetoai',
    'Papeete',
    'Papao',
    'Paopao',
    'Paea',
    'Otutara',
    'Mataura',
    'Marepa',
    'Mahina',
    'Fare',
    'Faanui',
    'Faaa',
    'Arue',
    'Afaahiti',
    'Taiohae',
  ],
  'Pitcairn Islands': ['Adamstown'],
  Tokelau: ['Fale old settlement', 'Nukunonu', 'Atafu Village'],
  Tonga: ['Tufurai', 'Nukunuku', "Nuku'alofa", 'Neiafu', 'Hihifo'],
  'Wallis and Futuna': ['Vailala', 'Leava', 'Mata-Utu', 'Gahi', 'Alo'],
  Samoa: [
    'Uafato',
    'Saipipi',
    'Sagone',
    'Safotu',
    'Patamea',
    'Mulifanua',
    'Moamoa',
    'Matatufu',
    'Lufilufi',
    'Leulumoega',
    'Gautavai',
    'Apia',
    'Asau',
  ],
  Niue: ['Alofi'],
  Guam: [
    'Santa Rita',
    'Umatac Village',
    'Yigo Village',
    'Merizo Village',
    'Inarajan Village',
    'Barrigada Village',
    'Agat Village',
    'Dededo Village',
    'Hagåtña',
    'Mangilao Village',
    'Tamuning',
  ],
  'Northern Mariana Islands': [
    'Songsong',
    'San Jose',
    'Alamagan Village',
    'Saipan',
  ],
  'United States': [
    'Cherokee',
    'Cost',
    'Bay Minette',
    'Edna',
    'Henderson',
    'Natalia',
    'Crockett',
    'Hume',
    'Sperryville',
    'Beaverton',
    'Yorktown',
    'Akron',
    'Beech Grove',
    'Gepp',
    'Guy',
    'Ocean View',
    'Berry',
    'Parrish',
    'Bessemer',
    'Lenox',
    'Cross Plains',
    'Aurora',
    'New Pekin',
    'Solsberry',
    'Belcamp',
    'Prairie View',
    'Stockton',
    'Horse Branch',
    'Kimper',
    'Paducah',
    'Donner',
    'Jessup',
    'Marion',
    'Woodbine',
    'Amoret',
    'Cowgill',
    'Pinola',
    'Billingsley',
    'Vass',
    'Woodleaf',
    'Birmingham',
    'Racine',
    'Black',
    'Watts',
    'Lugoff',
    'Cordova',
    'Blountsville',
    'Silver Point',
    'Buda',
    'Boaz',
    'Boligee',
    'Brantley',
    'Brewton',
    'Bridgeport',
    'Brierfield',
    'Brilliant',
    'Brookwood',
    'Brownsboro',
    'Brundidge',
    'Bryant',
    'Buhl',
    'Butler',
    'Calera',
    'Camden',
    'Camp Hill',
    'Carbon Hill',
    'Castleberry',
    'Cedar Bluff',
    'Centre',
    'Centreville',
    'Chapman',
    'Chatom',
    'Chelsea',
    'Childersburg',
    'Chunchula',
    'Citronelle',
    'Clanton',
    'Clayton',
    'Cleveland',
    'Clopton',
    'Coaling',
    'Coden',
    'Coffee Springs',
    'Coffeeville',
    'Coker',
    'Collinsville',
    'Columbiana',
    'Cottondale',
    'Cottonwood',
    'Cragford',
    'Crane Hill',
    'Creola',
    'Cropwell',
    'Crossville',
    'Cuba',
    'Cullman',
    'Cusseta',
    'Dadeville',
    'Daleville',
    'Daphne',
    'Dauphin Island',
    'Dawson',
    'Deatsville',
    'Decatur',
    'Delta',
    'Demopolis',
    'Dixons Mills',
    'Dora',
    'Dothan',
    'Double Springs',
    'Dozier',
    'Duncanville',
    'Dutton',
    'Eastaboga',
    'Eclectic',
    'Eight Mile',
    'Elberta',
    'Elkmont',
    'Empire',
    'Enterprise',
    'Equality',
    'Eufaula',
    'Eutaw',
    'Eva',
    'Evergreen',
    'Fackler',
    'Fairfield',
    'Fairhope',
    'Falkville',
    'Fayette',
    'Fitzpatrick',
    'Five Points',
    'Flat Rock',
    'Florence',
    'Flomaton',
    'Foley',
    'Forkland',
    'Fort Deposit',
    'Fort Mitchell',
    'Fort Payne',
    'Fosters',
    'Frisco City',
    'Fruithurst',
    'Fultondale',
    'Fyffe',
    'Gadsden',
    'Gainestown',
    'Gainesville',
    'Gallant',
    'Gallion',
    'Gardendale',
    'Gaylesville',
    'Geneva',
    'Georgiana',
    'Geraldine',
    'Gilbertown',
    'Goodwater',
    'Gordo',
    'Goshen',
    'Grady',
    'Grand Bay',
    'Grant',
    'Graysville',
    'Greensboro',
    'Greenville',
    'Grove Hill',
    'Grove Oak',
    'Guin',
    'Gulf Shores',
    'Guntersville',
    'Gurley',
    'Hackleburg',
    'Haleyville',
    'Hamilton',
    'Hanceville',
    'Harpersville',
    'Hartford',
    'Hartselle',
    'Harvest',
    'Hayden',
    'Hayneville',
    'Hazel Green',
    'Headland',
    'Heflin',
    'Helena',
    'Henagar',
    'Higdon',
    'Highland Home',
    'Holly Pond',
    'Hollywood',
    'Honoraville',
    'Hoover',
    'Hope Hull',
    'Horton',
    'Huntsville',
    'Hurtsboro',
    'Ider',
    'Irvington',
    'Jack',
    'Jackson',
    'Jacksons Gap',
    'Jacksonville',
    'Jasper',
    'Jemison',
    'Kansas',
    'Kennedy',
    'Killen',
    'Kimberly',
    'Kinston',
    'Laceys Spring',
    'Lafayette',
    'Lanett',
    'Langston',
    'Lapine',
    'Leeds',
    'Leesburg',
    'Leighton',
    'Leroy',
    'Lexington',
    'Lillian',
    'Linden',
    'Lincoln',
    'Lineville',
    'Livingston',
    'Locust Fork',
    'Logan',
    'Louisville',
    'Lowndesboro',
    'Loxley',
    'Luverne',
    'Lynn',
    'Madison',
    'Malcolm',
    'Maplesville',
    'Marbury',
    'Margaret',
    'Maylene',
    'McCalla',
    'McKenzie',
    'Meridianville',
    'Mentone',
    'Midland City',
    'Midway',
    'Millbrook',
    'Millport',
    'Millry',
    'Mobile',
    'Monroeville',
    'Montevallo',
    'Montgomery',
    'Montrose',
    'Moody',
    'Morris',
    'Moulton',
    'Moundville',
    'Mount Olive',
    'Mount Vernon',
    'Mulga',
    'Munford',
    'Muscadine',
    'Muscle Shoals',
    'Nauvoo',
    'New Brockton',
    'New Hope',
    'New Market',
    'Newbern',
    'Newton',
    'Newville',
    'Normal',
    'Northport',
    'Notasulga',
    'Oakman',
    'Odenville',
    'Ohatchee',
    'Opelika',
    'Oneonta',
    'Opp',
    'Orange Beach',
    'Owens Cross Roads',
    'Oxford',
    'Ozark',
    'Paint Rock',
    'Pelham',
    'Pell City',
    'Perdido',
    'Phenix City',
    'Phil Campbell',
    'Piedmont',
    'Pike Road',
    'Pinckard',
    'Pine Hill',
    'Pinson',
    'Pisgah',
    'Plantersville',
    'Pleasant Grove',
    'Point Clear',
    'Prattville',
    'Quinton',
    'Ragland',
    'Rainbow City',
    'Rainsville',
    'Ramer',
    'Ranburne',
    'Red Bay',
    'Red Level',
    'Reform',
    'Remlap',
    'Repton',
    'Riverside',
    'Roanoke',
    'Robertsdale',
    'Rogersville',
    'Russellville',
    'Safford',
    'Saginaw',
    'Saint Stephens',
    'Salem',
    'Samson',
    'Saraland',
    'Satsuma',
    'Sawyerville',
    'Scottsboro',
    'Seale',
    'Section',
    'Selma',
    'Semmes',
    'Sheffield',
    'Shelby',
    'Silas',
    'Silverhill',
    'Slocomb',
    'Smiths',
    'Somerville',
    'Spanish Fort',
    'Springville',
    'Spruce Pine',
    'Stapleton',
    'Steele',
    'Sterrett',
    'Stevenson',
    'Sulligent',
    'Sumiton',
    'Summerdale',
    'Sweet Water',
    'Sycamore',
    'Sylacauga',
    'Talladega',
    'Tallassee',
    'Tanner',
    'Theodore',
    'Thomasville',
    'Thorsby',
    'Toney',
    'Town Creek',
    'Trinity',
    'Troy',
    'Trussville',
    'Tuscaloosa',
    'Tuscumbia',
    'Tuskegee',
    'Tyler',
    'Union Grove',
    'Union Springs',
    'Uniontown',
    'Uriah',
    'Valley',
    'Valley Head',
    'Vance',
    'Verbena',
    'Vincent',
    'Vinegar Bend',
    'Vinemont',
    'Wadley',
    'Warrior',
    'Waverly',
    'Weaver',
    'Webb',
    'Wedowee',
    'Wellington',
    'Weogufka',
    'West Blocton',
    'Westover',
    'Wetumpka',
    'Wilmer',
    'Wilsonville',
    'Winfield',
    'Woodstock',
    'Woodville',
    'York',
    'Alexander',
    'Alicia',
    'Alix',
    'Alma',
    'Almyra',
    'Alpena',
    'Altus',
    'Amagon',
    'Amity',
    'Antoine',
    'Arkadelphia',
    'Ash Flat',
    'Ashdown',
    'Atkins',
    'Augusta',
    'Austin',
    'Bald Knob',
    'Banks',
    'Barling',
    'Batesville',
    'Bauxite',
    'Bay',
    'Bearden',
    'Bee Branch',
    'Beebe',
    'Bella Vista',
    'Belleville',
    'Benton',
    'Bentonville',
    'Berryville',
    'Bigelow',
    'Bismarck',
    'Black Rock',
    'Blevins',
    'Bluffton',
    'Blytheville',
    'Bonnerdale',
    'Bono',
    'Booneville',
    'Bradford',
    'Bradley',
    'Brinkley',
    'Brockwell',
    'Brookland',
    'Bull Shoals',
    'Cabot',
    'Caddo Gap',
    'Calico Rock',
    'Calion',
    'Camp',
    'Canehill',
    'Caraway',
    'Carlisle',
    'Casa',
    'Casscoe',
    'Cave City',
    'Cave Springs',
    'Cedarville',
    'Center Ridge',
    'Centerton',
    'Charleston',
    'Cherokee Village',
    'Cherry Valley',
    'Clarkedale',
    'Clarkridge',
    'Clarksville',
    'Clinton',
    'Coal Hill',
    'College Station',
    'Colt',
    'Conway',
    'Corning',
    'Cotter',
    'Crawfordsville',
    'Crossett',
    'Damascus',
    'Danville',
    'Dardanelle',
    'De Queen',
    'De Valls Bluff',
    'De Witt',
    'Delight',
    'Dermott',
    'Des Arc',
    'Dierks',
    'Doddridge',
    'Dolph',
    'Donaldson',
    'Dover',
    'Drasco',
    'Dumas',
    'Earle',
    'Edgemont',
    'El Dorado',
    'Elizabeth',
    'Elkins',
    'Emerson',
    'England',
    'Eudora',
    'Eureka Springs',
    'Evening Shade',
    'Everton',
    'Fairfield Bay',
    'Farmington',
    'Fayetteville',
    'Flippin',
    'Floral',
    'Foreman',
    'Fordyce',
    'Forrest City',
    'Fort Smith',
    'Fouke',
    'Franklin',
    'Fulton',
    'Gamaliel',
    'Garfield',
    'Gassville',
    'Gentry',
    'Gillett',
    'Gillham',
    'Glenwood',
    'Gould',
    'Gravette',
    'Green Forest',
    'Greenbrier',
    'Greenwood',
    'Griffithville',
    'Guion',
    'Gurdon',
    'Hackett',
    'Hamburg',
    'Hampton',
    'Hardy',
    'Harrell',
    'Harrisburg',
    'Harrison',
    'Hatfield',
    'Hattieville',
    'Havana',
    'Hazen',
    'Heber Springs',
    'Hector',
    'Hensley',
    'Hermitage',
    'Hindsville',
    'Hope',
    'Horatio',
    'Horseshoe Bend',
    'Hot Springs',
    'Hot Springs Village',
    'Houston',
    'Hoxie',
    'Hughes',
    'Huttig',
    'Ida',
    'Imboden',
    'Jefferson',
    'Jessieville',
    'Johnson',
    'Jonesboro',
    'Judsonia',
    'Junction City',
    'Keiser',
    'Keo',
    'Kingston',
    'Knoxville',
    'Lafe',
    'Lake City',
    'Lake Village',
    'Lamar',
    'Lavaca',
    'Leachville',
    'Lead Hill',
    'Leola',
    'Lepanto',
    'Leslie',
    'Lewisville',
    'Little Rock',
    'Lockesburg',
    'Lonoke',
    'Lonsdale',
    'Louann',
    'Lowell',
    'Luxora',
    'Mabelvale',
    'Magazine',
    'Magnolia',
    'Malvern',
    'Mammoth Spring',
    'Manila',
    'Marianna',
    'Marked Tree',
    'Marmaduke',
    'Marshall',
    'Marvell',
    'Maumelle',
    'Mayflower',
    'Maynard',
    'McCrory',
    'McGehee',
    'McRae',
    'Melbourne',
    'Mena',
    'Mineral Springs',
    'Monette',
    'Monticello',
    'Moro',
    'Morrilton',
    'Mount Ida',
    'Mountain Pine',
    'Mountain View',
    'Mountainburg',
    'Mountain Home',
    'Mulberry',
    'Murfreesboro',
    'Nashville',
    'Natural Dam',
    'New Blaine',
    'Newark',
    'Newport',
    'Norfork',
    'Norman',
    'Norphlet',
    'North Little Rock',
    'Oden',
    'Oil Trough',
    'Ola',
    'Omaha',
    'Osceola',
    'Ozan',
    'Palestine',
    'Pangburn',
    'Paragould',
    'Paris',
    'Parthenon',
    'Pea Ridge',
    'Pearcy',
    'Peel',
    'Perry',
    'Perryville',
    'Piggott',
    'Pine Bluff',
    'Plainview',
    'Pleasant Plains',
    'Plumerville',
    'Pocahontas',
    'Portia',
    'Pottsville',
    'Powhatan',
    'Prairie Grove',
    'Prattsville',
    'Prescott',
    'Quitman',
    'Ratcliff',
    'Rector',
    'Redfield',
    'Rison',
    'Rogers',
    'Roland',
    'Rosston',
    'Royal',
    'Saint Paul',
    'Saratoga',
    'Scotland',
    'Scott',
    'Scranton',
    'Searcy',
    'Sheridan',
    'Shirley',
    'Sidney',
    'Siloam Springs',
    'Smackover',
    'Solgohachia',
    'Springdale',
    'Stamps',
    'Star City',
    'Stephens',
    'Story',
    'Strawberry',
    'Strong',
    'Stuttgart',
    'Subiaco',
    'Sulphur Rock',
    'Sulphur Springs',
    'Summers',
    'Taylor',
    'Texarkana',
    'Tillar',
    'Timbo',
    'Trumann',
    'Tuckerman',
    'Tyronza',
    'Van Buren',
    'Vilonia',
    'Viola',
    'Waldron',
    'Walnut Ridge',
    'Ward',
    'Warren',
    'Washington',
    'Weiner',
    'Wesley',
    'West Fork',
    'West Helena',
    'West Memphis',
    'Western Grove',
    'White Hall',
    'Wickes',
    'Wilburn',
    'Wilson',
    'Winslow',
    'Wooster',
    'Wynne',
    'Yellville',
    'Columbia Heights',
    'Bear',
    'Bethany Beach',
    'Bridgeville',
    'Claymont',
    'Dagsboro',
    'Delaware City',
    'Delmar',
    'Felton',
    'Frankford',
    'Frederica',
    'Georgetown',
    'Harbeson',
    'Harrington',
    'Hartly',
    'Hockessin',
    'Laurel',
    'Lewes',
    'Marydel',
    'Middletown',
    'Milford',
    'Millsboro',
    'Millville',
    'Milton',
    'New Castle',
    'Rehoboth Beach',
    'Seaford',
    'Selbyville',
    'Smyrna',
    'Townsend',
    'Washington Heights',
    'Wilmington',
    'Alachua',
    'Alford',
    'Altamonte Springs',
    'Altha',
    'Altoona',
    'Alva',
    'Anthony',
    'Apalachicola',
    'Apollo Beach',
    'Apopka',
    'Arcadia',
    'Archer',
    'Astatula',
    'Astor',
    'Atlantic Beach',
    'Auburndale',
    'Aventura',
    'Avon Park',
    'Babson Park',
    'Baker',
    'Bartow',
    'Bascom',
    'Basinger',
    'Bell',
    'Belle Glade',
    'Belleview',
    'Beverly Hills',
    'Big Pine Key',
    'Blountstown',
    'Boca Grande',
    'Boca Raton',
    'Bokeelia',
    'Bonifay',
    'Bonita Springs',
    'Bowling Green',
    'Boynton Beach',
    'Bradenton',
    'Bradenton Beach',
    'Bradley Junction',
    'Brandon',
    'Branford',
    'Bristol',
    'Bronson',
    'Brooker',
    'Brooksville',
    'Bryceville',
    'Bunnell',
    'Bushnell',
    'Callahan',
    'Cantonment',
    'Cape Canaveral',
    'Cape Coral',
    'Captiva',
    'Carrabelle',
    'Caryville',
    'Casselberry',
    'Cedar Key',
    'Celebration',
    'Center Hill',
    'Century',
    'Chattahoochee',
    'Chiefland',
    'Chipley',
    'Chokoloskee',
    'Christmas',
    'Citra',
    'Citrus Springs',
    'Clearwater',
    'Clearwater Beach',
    'Clermont',
    'Clewiston',
    'Cocoa',
    'Cocoa Beach',
    'Coconut Creek',
    'Cooper City',
    'Copeland',
    'Coral Gables',
    'Coral Springs',
    'Crawfordville',
    'Crescent City',
    'Crestview',
    'Cross City',
    'Crystal Beach',
    'Crystal River',
    'Dade City',
    'Dania Beach',
    'Davenport',
    'Davie',
    'Daytona Beach',
    'DeFuniak Springs',
    'DeLand',
    'DeBary',
    'Deerfield Beach',
    'Delray Beach',
    'Deltona',
    'Destin',
    'Doral',
    'Dundee',
    'Dunedin',
    'Dunnellon',
    'Earleton',
    'East Palatka',
    'East Lake Weir',
    'Eastpoint',
    'Eaton Park',
    'Ebro',
    'Edgewater',
    'Elkton',
    'Ellenton',
    'Englewood',
    'Estero',
    'Eustis',
    'Evinston',
    'Felda',
    'Fellsmere',
    'Fernandina Beach',
    'Flagler Beach',
    'Fleming Island',
    'Florahome',
    'Floral City',
    'Florida City',
    'Florida Gardens',
    'Fort Lauderdale',
    'Fort McCoy',
    'Fort Meade',
    'Fort Myers',
    'Fort Myers Beach',
    'Ft. Pierce',
    'Fort Walton Beach',
    'Fort White',
    'Fountain',
    'Freeport',
    'Frostproof',
    'Fruitland Park',
    'Gibsonton',
    'Glen Saint Mary',
    'Gotha',
    'Graceville',
    'Grand Island',
    'Grand Ridge',
    'Green Cove Springs',
    'Gretna',
    'Groveland',
    'Gulf Breeze',
    'Gulfport',
    'Haines City',
    'Hallandale',
    'Hastings',
    'Hawthorne',
    'Hernando',
    'Hialeah',
    'High Springs',
    'Highland City',
    'Hilliard',
    'Hobe Sound',
    'Holiday',
    'Hollister',
    'Holt',
    'Homestead',
    'Homosassa',
    'Horseshoe Beach',
    'Hosford',
    'Howey-in-the-Hills',
    'Hudson',
    'Immokalee',
    'Indialantic',
    'Indian Lake Estates',
    'Indian Rocks Beach',
    'Indiantown',
    'Inglis',
    'Intercession City',
    'Interlachen',
    'Inverness',
    'Islamorada',
    'Jacksonville Beach',
    'Jay',
    'Jennings',
    'Jensen Beach',
    'Juno Beach',
    'Jupiter',
    'Key Biscayne',
    'Key Colony Beach',
    'Key Largo',
    'Key West',
    'Keystone Heights',
    'Kissimmee',
    'LaBelle',
    'Lacoochee',
    'Lady Lake',
    'Lake Alfred',
    'Lake Butler',
    'Lake Como',
    'Lake Helen',
    'Lake Panasoffkee',
    'Lake Placid',
    'Lake Mary',
    'Lake Wales',
    'Lake Worth',
    'Lakeland',
    "Land O' Lakes",
    'Largo',
    'Laurel Hill',
    'Lawtey',
    'Lecanto',
    'Lee',
    'Lehigh Acres',
    'Lithia',
    'Live Oak',
    'Longboat Key',
    'Longwood',
    'Lorida',
    'Loxahatchee Groves',
    'Lutz',
    'Lynn Haven',
    'Macclenny',
    'Maitland',
    'Malabar',
    'Malone',
    'Manalapan',
    'Marathon',
    'Marathon Shores',
    'Marco Island',
    'Mary Esther',
    'Mascotte',
    'Mayo',
    'McAlpin',
    'McDavid',
    'McIntosh',
    'Medley',
    'Melbourne Beach',
    'Melrose',
    'Merritt Island',
    'Miami',
    'Miami Beach',
    'Miami Gardens',
    'Micanopy',
    'Middleburg',
    'Mims',
    'Miramar',
    'Miramar Beach',
    'Molino',
    'Montverde',
    'Moore Haven',
    'Morriston',
    'Mt. Dora',
    'Myakka City',
    'Naples',
    'Navarre',
    'Neptune Beach',
    'New Port Richey',
    'New Smyrna Beach',
    'Newberry',
    'Niceville',
    'Nokomis',
    'North Fort Myers',
    'North Miami',
    'North Miami Beach',
    'North Palm Beach',
    'North Port',
    "O'Brien",
    'Oak Hill',
    'Ocala',
    'Ochopee',
    'Ocklawaha',
    'Ocoee',
    'Odessa',
    'Okahumpka',
    'Okeechobee',
    'Old Town',
    'Oldsmar',
    'Ona',
    'Opa-locka',
    'Orange City',
    'Orange Park',
    'Oriole Beach',
    'Orlando',
    'Ormond Beach',
    'Osprey',
    'Osteen',
    'Oviedo',
    'Pahokee',
    'Paisley',
    'Palatka',
    'Palm Bay',
    'Palm Beach',
    'Palm Beach Gardens',
    'Palm City',
    'Palm Coast',
    'Palm Harbor',
    'Palmetto',
    'Panacea',
    'Panama City',
    'Panama City Beach',
    'Pembroke Pines',
    'Penney Farms',
    'Pensacola',
    'Pierson',
    'Pine Castle',
    'Pine Hills',
    'Pineland',
    'Pinellas Park',
    'Pinetta',
    'Placida',
    'Plant City',
    'Plantation',
    'Polk City',
    'Pompano Beach',
    'Ponce de Leon',
    'Ponte Vedra Beach',
    'Ponte Vedra',
    'Port Charlotte',
    'Port Orange',
    'Port Richey',
    'Port Saint Joe',
    'Port Saint John',
    'Port Saint Lucie',
    'Punta Gorda',
    'Quincy',
    'Raiford',
    'Reddick',
    'Riverview',
    'Rockledge',
    'Rosemary Beach',
    'Rotonda West',
    'Ruskin',
    'Safety Harbor',
    'Saint Augustine',
    'Saint Cloud',
    'Saint James City',
    'Saint Leo',
    'St. Petersburg',
    'San Antonio',
    'San Mateo',
    'Sanderson',
    'Sanibel',
    'Sanford',
    'Santa Rosa Beach',
    'Sarasota',
    'Satellite Beach',
    'Sebastian',
    'Sebring',
    'Seffner',
    'Seminole',
    'Shalimar',
    'Silver Springs',
    'Sneads',
    'Sopchoppy',
    'Sorrento',
    'South Bay',
    'South Daytona',
    'Spring Hill',
    'Starke',
    'Steinhatchee',
    'Stuart',
    'Summerfield',
    'Summerland Key',
    'Sumterville',
    'Sun City Center',
    'Sunrise',
    'Suwannee',
    'Tallahassee',
    'Tampa',
    'Tarpon Springs',
    'Tavares',
    'Tavernier',
    'The Villages',
    'Thonotosassa',
    'Titusville',
    'Trenton',
    'Umatilla',
    'Valparaiso',
    'Valrico',
    'Venice',
    'Vernon',
    'Vero Beach',
    'Viera',
    'Wauchula',
    'Wausau',
    'Webster',
    'Weirsdale',
    'Welaka',
    'Wellborn',
    'Wesley Chapel',
    'West Palm Beach',
    'Weston',
    'Westville',
    'Wewahitchka',
    'White Springs',
    'Wildwood',
    'Williston',
    'Wimauma',
    'Windermere',
    'Winter Garden',
    'Winter Haven',
    'Winter Park',
    'Winter Springs',
    'Yalaha',
    'Yankeetown',
    'Youngstown',
    'Yulee',
    'Zellwood',
    'Zephyrhills',
    'Zolfo Springs',
    'Abbeville',
    'Acworth',
    'Adairsville',
    'Adel',
    'Adrian',
    'Ailey',
    'Alamo',
    'Alapaha',
    'Albany',
    'Allenhurst',
    'Alpharetta',
    'Alto',
    'Ambrose',
    'Americus',
    'Andersonville',
    'Appling',
    'Aragon',
    'Arlington',
    'Armuchee',
    'Arnoldsville',
    'Ashburn',
    'Athens',
    'Atlanta',
    'Attapulgus',
    'Auburn',
    'Austell',
    'Avondale Estates',
    'Axson',
    'Baconton',
    'Bainbridge',
    'Ball Ground',
    'Barnesville',
    'Barney',
    'Barwick',
    'Baxley',
    'Bethlehem',
    'Bishop',
    'Blackshear',
    'Blairsville',
    'Blakely',
    'Bloomingdale',
    'Blue Ridge',
    'Bogart',
    'Bonaire',
    'Bowdon',
    'Bowersville',
    'Bowman',
    'Box Springs',
    'Braselton',
    'Bremen',
    'Brinson',
    'Bronwood',
    'Brooklet',
    'Brooks',
    'Broxton',
    'Brunswick',
    'Buchanan',
    'Buckhead',
    'Buena Vista',
    'Buford',
    'Byromville',
    'Byron',
    'Cadwell',
    'Cairo',
    'Calhoun',
    'Camilla',
    'Canon',
    'Canton',
    'Carlton',
    'Carnesville',
    'Carrollton',
    'Cartersville',
    'Cataula',
    'Cave Spring',
    'Cecil',
    'Cedar Springs',
    'Cedartown',
    'Centerville',
    'Chatsworth',
    'Cherry Log',
    'Chickamauga',
    'Cisco',
    'Clarkdale',
    'Clarkesville',
    'Clarkston',
    'Claxton',
    'Clyo',
    'Cobb',
    'Cochran',
    'Cohutta',
    'Colbert',
    'Coleman',
    'College Park',
    'Collins',
    'Colquitt',
    'Columbus',
    'Comer',
    'Commerce',
    'Concord',
    'Conley',
    'Conyers',
    'Coolidge',
    'Cordele',
    'Cornelia',
    'Cotton',
    'Covington',
    'Crandall',
    'Crawford',
    'Cumming',
    'Cuthbert',
    'Dacula',
    'Dahlonega',
    'Dalton',
    'Dallas',
    'Danielsville',
    'Darien',
    'Davisboro',
    'Dawsonville',
    'Dearing',
    'Demorest',
    'Denton',
    'Dewy Rose',
    'Dexter',
    'Dillard',
    'Doerun',
    'Donalsonville',
    'Douglas',
    'Douglasville',
    'Dry Branch',
    'Dublin',
    'Duluth',
    'Dunwoody',
    'East Dublin',
    'Eastanollee',
    'Eastman',
    'Eatonton',
    'Edison',
    'Elberton',
    'Elko',
    'Ellabell',
    'Ellaville',
    'Ellenwood',
    'Ellerslie',
    'Ellijay',
    'Enigma',
    'Evans',
    'Fairburn',
    'Fairmount',
    'Fitzgerald',
    'Fleming',
    'Flintstone',
    'Flovilla',
    'Flowery Branch',
    'Folkston',
    'Forest Park',
    'Forsyth',
    'Fort Benning',
    'Fort Gaines',
    'Fort Oglethorpe',
    'Fort Valley',
    'Fortson',
    'Franklin Springs',
    'Gay',
    'Gibson',
    'Gillsville',
    'Glennville',
    'Good Hope',
    'Gordon',
    'Grantville',
    'Gray',
    'Grayson',
    'Griffin',
    'Grovetown',
    'Guyton',
    'Hahira',
    'Hardwick',
    'Harlem',
    'Hartsfield',
    'Hartwell',
    'Hawkinsville',
    'Hazlehurst',
    'Helen',
    'Hephzibah',
    'Hiawassee',
    'Hinesville',
    'Hiram',
    'Hoboken',
    'Hogansville',
    'Holly Springs',
    'Homer',
    'Homerville',
    'Hortense',
    'Hoschton',
    'Hull',
    'Irwinton',
    'Jeffersonville',
    'Jekyll Island',
    'Jenkinsburg',
    'Jesup',
    'Juliette',
    'Kathleen',
    'Kennesaw',
    'Kingsland',
    'Kite',
    'La Grange',
    'LaFayette',
    'Lake Park',
    'Lakemont',
    'Lavonia',
    'Lawrenceville',
    'Leary',
    'Lilburn',
    'Lincolnton',
    'Lindale',
    'Lithia Springs',
    'Lithonia',
    'Lizella',
    'Locust Grove',
    'Loganville',
    'Lookout Mountain',
    'Ludowici',
    'Lula',
    'Lumber City',
    'Luthersville',
    'Lyons',
    'Mableton',
    'Macon',
    'Manchester',
    'Mansfield',
    'Marblehill',
    'Marietta',
    'Marshallville',
    'Martin',
    'Mauk',
    'Maysville',
    'McCaysville',
    'McDonough',
    'McIntyre',
    'Meansville',
    'Meigs',
    'Menlo',
    'Metter',
    'Midland',
    'Midville',
    'Milan',
    'Milledgeville',
    'Millen',
    'Millwood',
    'Milner',
    'Mineral Bluff',
    'Molena',
    'Monroe',
    'Montezuma',
    'Moreland',
    'Morgan',
    'Morganton',
    'Morrow',
    'Morven',
    'Mount Airy',
    'Moultrie',
    'Mount Berry',
    'Mountain City',
    'Murrayville',
    'Musella',
    'Nahunta',
    'Naylor',
    'Newington',
    'Newnan',
    'Nicholls',
    'Nicholson',
    'Norcross',
    'Norman Park',
    'Norwood',
    'Oakfield',
    'Oakwood',
    'Ochlocknee',
    'Ocilla',
    'Odum',
    'Oglethorpe',
    'Omega',
    'Orchard Hill',
    'Pavo',
    'Peachtree City',
    'Peachtree Corners',
    'Pearson',
    'Pembroke',
    'Pendergrass',
    'Pine Lake',
    'Pine Mountain',
    'Pine Mountain Valley',
    'Pinehurst',
    'Pitts',
    'Plains',
    'Pooler',
    'Port Wentworth',
    'Portal',
    'Poulan',
    'Powder Springs',
    'Preston',
    'Ranger',
    'Ray City',
    'Register',
    'Reidsville',
    'Rentz',
    'Resaca',
    'Rex',
    'Reynolds',
    'Rhine',
    'Riceboro',
    'Richland',
    'Richmond Hill',
    'Rincon',
    'Ringgold',
    'Rising Fawn',
    'Riverdale',
    'Roberta',
    'Rock Spring',
    'Rockmart',
    'Rocky Face',
    'Rome',
    'Roopville',
    'Roswell',
    'Rossville',
    'Royston',
    'Rutledge',
    'Rydal',
    'Saint George',
    'St. Marys',
    'Saint Simons Island',
    'Sale City',
    'Sandersville',
    'Sandy Springs',
    'Sardis',
    'Sautee',
    'Savannah',
    'Scottdale',
    'Screven',
    'Senoia',
    'Sharpsburg',
    'Shellman',
    'Silver Creek',
    'Sky Valley',
    'Smithville',
    'Snellville',
    'Social Circle',
    'Soperton',
    'Sparta',
    'Springfield',
    'Statesboro',
    'Statham',
    'Sterling',
    'Stillmore',
    'Stockbridge',
    'Stone Mountain',
    'Sugar Hill',
    'Sugar Valley',
    'Summerville',
    'Sumner',
    'Sunny Side',
    'Surrency',
    'Suwanee',
    'Swainsboro',
    'Sylvania',
    'Sylvester',
    'Talbotton',
    'Talking Rock',
    'Tallapoosa',
    'Taylorsville',
    'Temple',
    'Tennga',
    'Tennille',
    'Thomaston',
    'Thomson',
    'Tifton',
    'Tiger',
    'Tignall',
    'Toccoa',
    'Toomsboro',
    'Trion',
    'Tucker',
    'Tunnel Hill',
    'Twin City',
    'Ty Ty',
    'Tybee Island',
    'Tyrone',
    'Unadilla',
    'Union City',
    'Union Point',
    'Upatoi',
    'Uvalda',
    'Valdosta',
    'Vidalia',
    'Vienna',
    'Villa Rica',
    'Waco',
    'Waleska',
    'Warm Springs',
    'Warner Robins',
    'Warrenton',
    'Watkinsville',
    'Waycross',
    'Waynesboro',
    'Waynesville',
    'Waverly Hall',
    'West Point',
    'Whigham',
    'White',
    'White Plains',
    'Whitesburg',
    'Willacoochee',
    'Williamson',
    'Winder',
    'Winston',
    'Winterville',
    'Woodbury',
    'Woodland',
    'Wrens',
    'Wray',
    'Wrightsville',
    'Yatesville',
    'Young Harris',
    'Zebulon',
    'Addieville',
    'Albion',
    'Alhambra',
    'Altamont',
    'Alton',
    'Anna',
    'Arcola',
    'Arenzville',
    'Argenta',
    'Arthur',
    'Ashland',
    'Ashley',
    'Ashmore',
    'Assumption',
    'Atwood',
    'Ava',
    'Aviston',
    'Baldwin',
    'Barry',
    'Bartelso',
    'Batchtown',
    'Baylis',
    'Beecher City',
    'Belknap',
    'Belle Rive',
    'Bement',
    'Benld',
    'Bethalto',
    'Bethany',
    'Blue Mound',
    'Bluffs',
    'Bluford',
    'Bonnie',
    'Boody',
    'Breese',
    'Brighton',
    'Broadlands',
    'Brookport',
    'Broughton',
    'Brownstown',
    'Brussels',
    'Bunker Hill',
    'Cambria',
    'Campbell Hill',
    'Cantrall',
    'Carbondale',
    'Carlinville',
    'Carlyle',
    'Carmi',
    'Carrier Mills',
    'Carterville',
    'Casey',
    'Caseyville',
    'Cave-in-Rock',
    'Centralia',
    'Cerro Gordo',
    'Chambersburg',
    'Chatham',
    'Chester',
    'Chesterfield',
    'Chrisman',
    'Christopher',
    'Cisne',
    'Clay City',
    'Cobden',
    'Coffeen',
    'Columbia',
    'Cottage Hills',
    'Coulterville',
    'Creal Springs',
    'Cypress',
    'Dahlgren',
    'Dalton City',
    'De Soto',
    'Dieterich',
    'Divernon',
    'Dix',
    'Du Quoin',
    'Dundas',
    'Dupo',
    'East Alton',
    'East Saint Louis',
    'Edinburg',
    'Edwardsville',
    'Effingham',
    'Eldorado',
    'Elizabethtown',
    'Elkville',
    'Ellery',
    'Ellis Grove',
    'Elsah',
    'Energy',
    'Enfield',
    'Evansville',
    'Fairview Heights',
    'Farina',
    'Fieldon',
    'Flora',
    'Freeburg',
    'Galatia',
    'Geff',
    'Germantown',
    'Gillespie',
    'Glen Carbon',
    'Glenarm',
    'Girard',
    'Godfrey',
    'Golconda',
    'Golden Eagle',
    'Goreville',
    'Gorham',
    'Grand Chain',
    'Granite City',
    'Grantsburg',
    'Grayville',
    'Greenfield',
    'Greenup',
    'Griggsville',
    'Hamel',
    'Hardin',
    'Harvel',
    'Hecker',
    'Herrin',
    'Hettick',
    'Hidalgo',
    'Highland',
    'Hindsboro',
    'Hillsboro',
    'Hoffman',
    'Humboldt',
    'Hurst',
    'Hutsonville',
    'Illiopolis',
    'Ina',
    'Irving',
    'Iuka',
    'Ivesdale',
    'Jacob',
    'Jerseyville',
    'Jewett',
    'Johnston City',
    'Junction',
    'Kampsville',
    'Kane',
    'Karnak',
    'Kell',
    'Keyesport',
    'Kincaid',
    'Kinmundy',
    'Latham',
    'Lebanon',
    'Lenzburg',
    'Liberty',
    'Litchfield',
    'Loami',
    'Long Creek',
    'Lovington',
    'Macedonia',
    'Makanda',
    'Marine',
    'Marissa',
    'Martinsville',
    'Maryville',
    'Mascoutah',
    'Mason',
    'Mattoon',
    'McLeansboro',
    'Mechanicsburg',
    'Meredosia',
    'Metropolis',
    'Michael',
    'Mill Shoals',
    'Millstadt',
    'Morrisonville',
    'Mound City',
    'Mount Carmel',
    'Mount Sterling',
    'Mt. Vernon',
    'Moweaqua',
    'Mulberry Grove',
    'Mulkeytown',
    'Murphysboro',
    'Neoga',
    'New Athens',
    'New Baden',
    'New Berlin',
    'New Canton',
    'New Salem',
    'Niantic',
    'Noble',
    'Norris City',
    "O'Fallon",
    'Oakdale',
    'Oblong',
    'Oconee',
    'Odin',
    'Okawville',
    'Olive Branch',
    'Olney',
    'Opdyke',
    'Oreana',
    'Owaneco',
    'Palmyra',
    'Pana',
    'Pawnee',
    'Payson',
    'Pearl',
    'Percy',
    'Pesotum',
    'Pinckneyville',
    'Pittsfield',
    'Plainville',
    'Pleasant Hill',
    'Pomona',
    'Prairie du Rocher',
    'Ramsey',
    'Raymond',
    'Red Bud',
    'Richview',
    'Ridge Farm',
    'Riverton',
    'Robinson',
    'Rochester',
    'Rockbridge',
    'Rockport',
    'Rockwood',
    'Roodhouse',
    'Rosamond',
    'Rosiclare',
    'Roxana',
    'Sadorus',
    'Saint Francisville',
    'Saint Jacob',
    'Saint Libory',
    'Sainte Marie',
    'Sesser',
    'Shawneetown',
    'Shelbyville',
    'Sherman',
    'Shipman',
    'Shobonier',
    'Shumway',
    'Sidell',
    'Sigel',
    'Simpson',
    'Smithton',
    'Springerton',
    'Staunton',
    'Steeleville',
    'Stonefort',
    'Strasburg',
    'Sullivan',
    'Swansea',
    'Tamaroa',
    'Tamms',
    'Taylorville',
    'Teutopolis',
    'Thayer',
    'Thompsonville',
    'Tilden',
    'Toledo',
    'Tolono',
    'Tower Hill',
    'Tuscola',
    'Ullin',
    'Valier',
    'Valmeyer',
    'Vandalia',
    'Villa Ridge',
    'Virden',
    'Virginia',
    'Waggoner',
    'Walsh',
    'Waltonville',
    'Warrensburg',
    'Waterloo',
    'Wayne City',
    'West Frankfort',
    'West Salem',
    'West Union',
    'Westfield',
    'Williamsville',
    'Winchester',
    'Windsor',
    'Wood River',
    'Woodlawn',
    'Woodson',
    'Worden',
    'Xenia',
    'Yale',
    'Zeigler',
    'Avoca',
    'Avon',
    'Bargersville',
    'Bedford',
    'Bennington',
    'Bicknell',
    'Birdseye',
    'Blanford',
    'Bloomfield',
    'Bloomington',
    'Boonville',
    'Borden',
    'Brazil',
    'Brookville',
    'Brownsburg',
    'Brownsville',
    'Bruceville',
    'Butlerville',
    'Cambridge City',
    'Camby',
    'Campbellsburg',
    'Cannelton',
    'Carmel',
    'Carthage',
    'Cayuga',
    'Center Point',
    'Chandler',
    'Charlestown',
    'Chrisney',
    'Clear Creek',
    'Cloverdale',
    'Coal City',
    'Coatesville',
    'Commiskey',
    'Connersville',
    'Cory',
    'Corydon',
    'Crane',
    'Cynthiana',
    'Dale',
    'Dana',
    'Depauw',
    'Deputy',
    'Dillsboro',
    'Dubois',
    'Dugger',
    'Edinburgh',
    'Edwardsport',
    'Elberfeld',
    'Ellettsville',
    'Elnora',
    'English',
    'Evanston',
    'Fairland',
    'Falmouth',
    'Farmersburg',
    'Ferdinand',
    'Fishers',
    'Floyds Knobs',
    'Fort Branch',
    'Fortville',
    'Fountain City',
    'Francisco',
    'Fredericksburg',
    'Freedom',
    'Freelandville',
    'Freetown',
    'French Lick',
    'Gentryville',
    'Gosport',
    'Grandview',
    'Greencastle',
    'Greensburg',
    'Greens Fork',
    'Guilford',
    'Hagerstown',
    'Hanover',
    'Hardinsburg',
    'Harmony',
    'Hartsville',
    'Haubstadt',
    'Heltonville',
    'Henryville',
    'Hillsdale',
    'Holland',
    'Holton',
    'Huntingburg',
    'Hymera',
    'Indianapolis',
    'Jamestown',
    'Jasonville',
    'Kennard',
    'Kingman',
    'Knightstown',
    'Knightsville',
    'Laconia',
    'Ladoga',
    'Lanesville',
    'Lawrence',
    'Lawrenceburg',
    'Lewis',
    'Linton',
    'Lizton',
    'Loogootee',
    'Lynnville',
    'Manilla',
    'Marengo',
    'Markleville',
    'Mauckport',
    'Mays',
    'McCordsville',
    'Medora',
    'Memphis',
    'Merom',
    'Milltown',
    'Milroy',
    'Mitchell',
    'Monroe City',
    'Monrovia',
    'Mooresville',
    'Moores Hill',
    'Morgantown',
    'Morristown',
    'Nabb',
    'New Albany',
    'New Harmony',
    'New Palestine',
    'New Point',
    'New Ross',
    'New Salisbury',
    'Newburgh',
    'Nineveh',
    'North Salem',
    'North Vernon',
    'Oakland City',
    'Oaktown',
    'Odon',
    'Oldenburg',
    'Orleans',
    'Osgood',
    'Otwell',
    'Owensburg',
    'Owensville',
    'Paoli',
    'Paragon',
    'Paris Crossing',
    'Patoka',
    'Patriot',
    'Pendleton',
    'Petersburg',
    'Pittsboro',
    'Plainfield',
    'Poland',
    'Poseyville',
    'Princeton',
    'Reelsville',
    'Richland City',
    'Richmond',
    'Rising Sun',
    'Roachdale',
    'Rockville',
    'Rosedale',
    'Rushville',
    'Saint Anthony',
    'Saint Bernice',
    'Saint Meinrad',
    'Sandborn',
    'Santa Claus',
    'Scipio',
    'Scottsburg',
    'Sellersburg',
    'Seymour',
    'Shelburn',
    'Shoals',
    'Spencer',
    'Stilesville',
    'Sunman',
    'Switz City',
    'Taswell',
    'Tell City',
    'Tennyson',
    'Terre Haute',
    'Trafalgar',
    'Underwood',
    'Unionville',
    'Vallonia',
    'Versailles',
    'Vevay',
    'Vincennes',
    'Wadesville',
    'Waveland',
    'West Baden Springs',
    'West Harrison',
    'West Newton',
    'West Terre Haute',
    'Westport',
    'Whiteland',
    'Whitestown',
    'Wilkinson',
    'Williams',
    'Williamsburg',
    'Worthington',
    'Zionsville',
    'Abbyville',
    'Abilene',
    'Agra',
    'Albert',
    'Allen',
    'Almena',
    'Alta Vista',
    'Andale',
    'Andover',
    'Argonia',
    'Arkansas City',
    'Arma',
    'Assaria',
    'Atchison',
    'Athol',
    'Attica',
    'Axtell',
    'Baileyville',
    'Baldwin City',
    'Barnes',
    'Basehor',
    'Baxter Springs',
    'Bazine',
    'Beattie',
    'Belle Plaine',
    'Beloit',
    'Belvue',
    'Bendena',
    'Bentley',
    'Berryton',
    'Beverly',
    'Bison',
    'Bluff City',
    'Bogue',
    'Bonner Springs',
    'Bucklin',
    'Bucyrus',
    'Buhler',
    'Burden',
    'Burdett',
    'Burlingame',
    'Burlington',
    'Burns',
    'Burr Oak',
    'Burrton',
    'Bushton',
    'Caldwell',
    'Caney',
    'Cassoday',
    'Catharine',
    'Cedar',
    'Cedar Point',
    'Cedar Vale',
    'Chanute',
    'Chase',
    'Cheney',
    'Cherryvale',
    'Chetopa',
    'Circleville',
    'Claflin',
    'Clay Center',
    'Clifton',
    'Clyde',
    'Coffeyville',
    'Coldwater',
    'Colony',
    'Colwich',
    'Concordia',
    'Conway Springs',
    'Cottonwood Falls',
    'Council Grove',
    'Courtland',
    'Cummings',
    'Cunningham',
    'Dartmouth',
    'Delia',
    'Delphos',
    'Derby',
    'Dorrance',
    'Douglass',
    'Downs',
    'Dwight',
    'Easton',
    'Edgerton',
    'Elk City',
    'Elk Falls',
    'Ellinwood',
    'Ellis',
    'Ellsworth',
    'Elsmore',
    'Emmett',
    'Emporia',
    'Erie',
    'Eskridge',
    'Eureka',
    'Everest',
    'Fairview',
    'Fall River',
    'Falun',
    'Fontana',
    'Ford',
    'Fort Scott',
    'Frankfort',
    'Fredonia',
    'Galena',
    'Galesburg',
    'Galva',
    'Frontenac',
    'Gardner',
    'Garden Plain',
    'Garnett',
    'Gaylord',
    'Geuda Springs',
    'Geneseo',
    'Glasco',
    'Glen Elder',
    'Goddard',
    'Goessel',
    'Great Bend',
    'Green',
    'Greeley',
    'Greenleaf',
    'Grenola',
    'Gypsum',
    'Halstead',
    'Hanston',
    'Hardtner',
    'Harper',
    'Harveyville',
    'Haven',
    'Haviland',
    'Hays',
    'Haysville',
    'Hazelton',
    'Herington',
    'Hesston',
    'Hiawatha',
    'Hill City',
    'Hoisington',
    'Holyrood',
    'Howard',
    'Hoyt',
    'Hunter',
    'Hutchinson',
    'Independence',
    'Inman',
    'Iola',
    'Isabel',
    'Jewell',
    'Jetmore',
    'Kansas City',
    'Kechi',
    'Kinsley',
    'Kiowa',
    'Kirwin',
    'La Crosse',
    'Lancaster',
    'Lansing',
    'Larned',
    'LeRoy',
    'Leavenworth',
    'Leawood',
    'Lebo',
    'Lecompton',
    'Lenexa',
    'Leon',
    'Leonardville',
    'Liebenthal',
    'Lincolnville',
    'Lindsborg',
    'Linn',
    'Linwood',
    'Little River',
    'Longton',
    'Lorraine',
    'Lost Springs',
    'Louisburg',
    'Lucas',
    'Luray',
    'Lyndon',
    'Maize',
    'Manhattan',
    'Mankato',
    'Marquette',
    'Marysville',
    'Matfield Green',
    'Mayetta',
    'McCracken',
    'McCune',
    'McFarland',
    'McLouth',
    'McPherson',
    'Medicine Lodge',
    'Melvern',
    'Meriden',
    'Miltonvale',
    'Minneapolis',
    'Mission',
    'Morganville',
    'Morrill',
    'Mound Valley',
    'Moundridge',
    'Mount Hope',
    'Mullinville',
    'Mulvane',
    'Muscotah',
    'Neodesha',
    'Neosho Falls',
    'Nekoma',
    'Ness City',
    'Nickerson',
    'North Newton',
    'Norton',
    'Nortonville',
    'Norwich',
    'Offerle',
    'Ogden',
    'Oketo',
    'Olathe',
    'Olmitz',
    'Olpe',
    'Olsburg',
    'Onaga',
    'Osage City',
    'Osawatomie',
    'Osborne',
    'Oskaloosa',
    'Oswego',
    'Ottawa',
    'Overbrook',
    'Overland Park',
    'Ozawkie',
    'Palco',
    'Palmer',
    'Paola',
    'Parker',
    'Parsons',
    'Paxico',
    'Peabody',
    'Pawnee Rock',
    'Peck',
    'Peru',
    'Phillipsburg',
    'Pittsburg',
    'Pleasanton',
    'Potwin',
    'Prairie Village',
    'Pratt',
    'Pretty Prairie',
    'Protection',
    'Ramona',
    'Randall',
    'Randolph',
    'Ransom',
    'Rantoul',
    'Reading',
    'Republic',
    'Riley',
    'Rock',
    'Rosalia',
    'Rose Hill',
    'Roxbury',
    'Rush Center',
    'Russell',
    'Sabetha',
    'Saint John',
    'Saint Marys',
    'Salina',
    'Savonburg',
    'Sawyer',
    'Scammon',
    'Sedan',
    'Sedgwick',
    'Seneca',
    'Sharon',
    'Shawnee',
    'Silver Lake',
    'Smith Center',
    'Soldier',
    'Solomon',
    'South Hutchinson',
    'South Haven',
    'Spearville',
    'Stafford',
    'Stark',
    'Stilwell',
    'Strong City',
    'Sylvan Grove',
    'Tecumseh',
    'Tescott',
    'Tipton',
    'Tonganoxie',
    'Toronto',
    'Towanda',
    'Topeka',
    'Turon',
    'Udall',
    'Valley Center',
    'Valley Falls',
    'Vassar',
    'Victoria',
    'WaKeeney',
    'Wakarusa',
    'Wakefield',
    'Waldo',
    'Walnut',
    'Wamego',
    'Waterville',
    'Wathena',
    'Weir',
    'Wellsville',
    'West Mineral',
    'Westmoreland',
    'Westphalia',
    'Wetmore',
    'White City',
    'White Cloud',
    'Whitewater',
    'Whiting',
    'Wichita',
    'Wilsey',
    'Wright',
    'Zenda',
    'Adairville',
    'Adolphus',
    'Alexandria',
    'Allen City',
    'Almo',
    'Alvaton',
    'Annville',
    'Argillite',
    'Ary',
    'Auxier',
    'Bagdad',
    'Banner',
    'Barbourville',
    'Bardstown',
    'Bardwell',
    'Barlow',
    'Battletown',
    'Baxter',
    'Beaumont',
    'Beaver',
    'Beattyville',
    'Beaver Dam',
    'Bee Spring',
    'Beech Creek',
    'Beechmont',
    'Belfry',
    'Bellevue',
    'Belton',
    'Berea',
    'Big Clifty',
    'Big Creek',
    'Bimble',
    'Bonnyman',
    'Bonnieville',
    'Boston',
    'Brandenburg',
    'Brodhead',
    'Bronston',
    'Buckner',
    'Buffalo',
    'Bulan',
    'Burkesville',
    'Burna',
    'Burnside',
    'Cadiz',
    'California',
    'Calvert City',
    'Calvin',
    'Campbellsville',
    'Campton',
    'Caneyville',
    'Cannel City',
    'Carrie',
    'Catlettsburg',
    'Cawood',
    'Cecilia',
    'Centertown',
    'Central City',
    'Clarkson',
    'Clay',
    'Clayhole',
    'Clearfield',
    'Cloverport',
    'Combs',
    'Corbin',
    'Corinth',
    'Coxs Creek',
    'Crab Orchard',
    'Crestwood',
    'Crittenden',
    'Cub Run',
    'Cumberland',
    'Dawson Springs',
    'Dayton',
    'DeMossville',
    'Dema',
    'Dixon',
    'Drakesboro',
    'Dry Ridge',
    'Dunmor',
    'Earlington',
    'East Bernstadt',
    'East Point',
    'Eastern',
    'Eastview',
    'Eastwood',
    'Eddyville',
    'Edgewood',
    'Edmonton',
    'Eighty Eight',
    'Ekron',
    'Elkhorn City',
    'Ellington',
    'Emlyn',
    'Emmalena',
    'Eolia',
    'Erlanger',
    'Eubank',
    'Evarts',
    'Ewing',
    'Ewington',
    'Fairdale',
    'Falls of Rough',
    'Fancy Farm',
    'Finchville',
    'Fisherville',
    'Flat Lick',
    'Flatwoods',
    'Flemingsburg',
    'Fordsville',
    'Fort Thomas',
    'Foster',
    'Fountain Run',
    'Fourmile',
    'Freeburn',
    'Frenchburg',
    'Garrett',
    'Garrison',
    'Ghent',
    'Gilbertsville',
    'Glasgow',
    'Glencoe',
    'Glendale',
    'Grand Rivers',
    'Guston',
    'Guthrie',
    'Hagerhill',
    'Hallie',
    'Hanson',
    'Happy',
    'Hardyville',
    'Harlan',
    'Harned',
    'Harold',
    'Harrodsburg',
    'Hawesville',
    'Hazard',
    'Hazel',
    'Hebron',
    'Herdon',
    'Herndon',
    'Hestand',
    'Hi Hat',
    'Hickman',
    'Hickory',
    'Hindman',
    'Hodgenville',
    'Holmes Mill',
    'Hopkinsville',
    'Horse Cave',
    'Huddy',
    'Hustonville',
    'Hyden',
    'Inez',
    'Irvine',
    'Jenkins',
    'Keavy',
    'Kettle Island',
    'Kevil',
    'Kirksey',
    'Knottsville',
    'La Center',
    'Kuttawa',
    'Langley',
    'Latonia',
    'Lebanon Junction',
    'Leburn',
    'Ledbetter',
    'Leitchfield',
    'Lewisburg',
    'Lewisport',
    'Lexington-Fayette',
    'Littcarr',
    'Livermore',
    'London',
    'Loretto',
    'Lost Creek',
    'Louisa',
    'Loyall',
    'Maceo',
    'Madisonville',
    'Mammoth Cave',
    'Marrowbone',
    'Mayfield',
    'Mayking',
    'Mays Lick',
    'McDaniels',
    'McDowell',
    'McKee',
    'Meally',
    'Means',
    'Melber',
    'Melvin',
    'Middlesboro',
    'Morehead',
    'Morganfield',
    'Morning View',
    'Mortons Gap',
    'Mount Eden',
    'Mount Hermon',
    'Mount Sherman',
    'Mount Washington',
    'Mouthcard',
    'Mousie',
    'Munfordville',
    'Murray',
    'Nancy',
    'Nazareth',
    'Nerinx',
    'Neon',
    'New Concord',
    'New Haven',
    'Nicholasville',
    'Oak Grove',
    'Oakland',
    'Old Washington',
    'Olive Hill',
    'Olmstead',
    'Olympia',
    'Owensboro',
    'Owenton',
    'Owingsville',
    'Paintsville',
    'Park City',
    'Parkers Lake',
    'Parksville',
    'Partridge',
    'Pathfork',
    'Payneville',
    'Pewee Valley',
    'Phelps',
    'Philpot',
    'Phyllis',
    'Pikeville',
    'Pine Knot',
    'Pine Top',
    'Pineville',
    'Pinsonfork',
    'Pleasureville',
    'Powderly',
    'Prestonsburg',
    'Printer',
    'Prospect',
    'Providence',
    'Putney',
    'Raccoon',
    'Radcliff',
    'Ravenna',
    'Rineyville',
    'Robards',
    'Roundhill',
    'Rush',
    'Russell Springs',
    'Sacramento',
    'Sadieville',
    'Salt Lick',
    'Salvisa',
    'Salyersville',
    'Sanders',
    'Sandgap',
    'Sandy Hook',
    'Science Hill',
    'Scottsville',
    'Sebree',
    'Sedalia',
    'Sextons Creek',
    'Sharon Grove',
    'Shelbiana',
    'Shepherdsville',
    'Siler',
    'Silver Grove',
    'Simpsonville',
    'Slemp',
    'Smithfield',
    'Smiths Grove',
    'Somerset',
    'Sonora',
    'South Shore',
    'Staffordsville',
    'Stamping Ground',
    'Stanford',
    'Stanton',
    'Stearns',
    'Stinnett',
    'Stopover',
    'Sturgis',
    'Summer Shade',
    'Summersville',
    'Symsonia',
    'Taylor Mill',
    'Thelma',
    'Thousandsticks',
    'Tollesboro',
    'Tompkinsville',
    'Tomahawk',
    'Tram',
    'Turners Station',
    'Tyner',
    'Union',
    'Upton',
    'Utica',
    'Van Lear',
    'Vanceburg',
    'Vancleve',
    'Verona',
    'Vicco',
    'Vine Grove',
    'Viper',
    'Virgie',
    'Waddy',
    'Wallingford',
    'Wallins Creek',
    'Walton',
    'Warfield',
    'Warsaw',
    'Wayland',
    'Waynesburg',
    'Webbville',
    'Weeksbury',
    'West Liberty',
    'West Paducah',
    'Wheelwright',
    'Whitesville',
    'Whitley City',
    'Wickliffe',
    'Williamstown',
    'Willisburg',
    'Wilmore',
    'Wingo',
    'Woodburn',
    'Wooton',
    'Worthville',
    'Wrigley',
    'Yeaddiss',
    'Abita Springs',
    'Addis',
    'Amite',
    'Anacoco',
    'Angie',
    'Angola',
    'Arabi',
    'Arnaudville',
    'Ball',
    'Barataria',
    'Basile',
    'Bastrop',
    'Baton Rouge',
    'Bell City',
    'Belle Chasse',
    'Belle Rose',
    'Bernice',
    'Berwick',
    'Bienville',
    'Blanchard',
    'Bogalusa',
    'Bossier City',
    'Bourg',
    'Boyce',
    'Branch',
    'Breaux Bridge',
    'Broussard',
    'Brusly',
    'Bunkie',
    'Buras',
    'Bush',
    'Cameron',
    'Campti',
    'Carencro',
    'Castor',
    'Chalmette',
    'Charenton',
    'Chauvin',
    'Choudrant',
    'Church Point',
    'Clarks',
    'Colfax',
    'Convent',
    'Converse',
    'Cotton Valley',
    'Cottonport',
    'Coushatta',
    'Creole',
    'Crowley',
    'Cut Off',
    'Darrow',
    'DeQuincy',
    'DeRidder',
    'Delcambre',
    'Delhi',
    'Denham Springs',
    'Des Allemands',
    'Destrehan',
    'Deville',
    'Dodson',
    'Donaldsonville',
    'Downsville',
    'Doyline',
    'Dry Creek',
    'Dry Prong',
    'Dubach',
    'Duson',
    'Echo',
    'Effie',
    'Egan',
    'Elm Grove',
    'Elmer',
    'Epps',
    'Erath',
    'Eros',
    'Estherwood',
    'Ethel',
    'Eunice',
    'Farmerville',
    'Ferriday',
    'Florien',
    'Fluker',
    'Folsom',
    'Fordoche',
    'Forest Hill',
    'Fort Polk',
    'Franklinton',
    'French Settlement',
    'Galliano',
    'Geismar',
    'Gheens',
    'Gibsland',
    'Gilbert',
    'Glenmora',
    'Gloster',
    'Golden Meadow',
    'Goldonna',
    'Gonzales',
    'Grambling',
    'Gramercy',
    'Grand Coteau',
    'Grand Isle',
    'Greenwell Springs',
    'Grosse Tete',
    'Gueydan',
    'Hackberry',
    'Hahnville',
    'Hammond',
    'Harvey',
    'Haughton',
    'Haynesville',
    'Hessmer',
    'Hineston',
    'Holden',
    'Hornbeck',
    'Hosston',
    'Houma',
    'Iota',
    'Iowa',
    'Jarreau',
    'Jeanerette',
    'Jena',
    'Jonesville',
    'Kaplan',
    'Keachi',
    'Keithville',
    'Kenner',
    'Kentwood',
    'Kinder',
    'Krotz Springs',
    'Labadieville',
    'Lacombe',
    'Lafitte',
    'Lake Arthur',
    'Lake Charles',
    'Lake Providence',
    'Laplace',
    'Larose',
    'Lawtell',
    'Lecompte',
    'Leesville',
    'Leonville',
    'Lettsworth',
    'Livonia',
    'Lockport',
    'Logansport',
    'Longville',
    'Loranger',
    'Loreauville',
    'Lottie',
    'Luling',
    'Lutcher',
    'Mamou',
    'Mandeville',
    'Mangham',
    'Mansura',
    'Many',
    'Maringouin',
    'Marksville',
    'Marrero',
    'Maurice',
    'Maurepas',
    'Melville',
    'Mer Rouge',
    'Meraux',
    'Mermentau',
    'Merryville',
    'Metairie',
    'Minden',
    'Montegut',
    'Monterey',
    'Mooringsport',
    'Moreauville',
    'Morgan City',
    'Morganza',
    'Morse',
    'Napoleonville',
    'Natchitoches',
    'New Iberia',
    'New Llano',
    'New Orleans',
    'New Roads',
    'Newellton',
    'Norco',
    'Oak Ridge',
    'Oberlin',
    'Oil City',
    'Olla',
    'Opelousas',
    'Paincourtville',
    'Patterson',
    'Paulina',
    'Pearl River',
    'Pierre Part',
    'Pine Prairie',
    'Pitkin',
    'Plain Dealing',
    'Plaquemine',
    'Pollock',
    'Ponchatoula',
    'Port Allen',
    'Port Barre',
    'Port Sulphur',
    'Prairieville',
    'Pride',
    'Raceland',
    'Ragley',
    'Rayne',
    'Rayville',
    'Reeves',
    'Reserve',
    'Robeline',
    'Robert',
    'Roseland',
    'Ruston',
    'Saint Amant',
    'Saint Benedict',
    'Saint Bernard',
    'Saint Gabriel',
    'Saint James',
    'Saint Joseph',
    'Saint Landry',
    'Saint Martinville',
    'Saint Rose',
    'Saline',
    'Sarepta',
    'Schriever',
    'Shongaloo',
    'Shreveport',
    'Sibley',
    'Sicily Island',
    'Simmesport',
    'Slagle',
    'Slaughter',
    'Slidell',
    'Springhill',
    'Sterlington',
    'Stonewall',
    'Sulphur',
    'Sunset',
    'Tallulah',
    'Thibodaux',
    'Tickfaw',
    'Trout',
    'Vacherie',
    'Ventress',
    'Ville Platte',
    'Vinton',
    'Violet',
    'Vivian',
    'Walker',
    'Waterproof',
    'Welsh',
    'West Monroe',
    'Westlake',
    'Westwego',
    'White Castle',
    'Winnfield',
    'Winnsboro',
    'Wisner',
    'Woodworth',
    'Zachary',
    'Youngsville',
    'Zwolle',
    'Aberdeen',
    'Abingdon',
    'Accident',
    'Accokeek',
    'Adamstown',
    'Annapolis',
    'Arbutus',
    'Arnold',
    'Ashton',
    'Baltimore',
    'Barclay',
    'Bel Air',
    'Bel Alton',
    'Beltsville',
    'Berlin',
    'Bethesda',
    'Big Pool',
    'Bishopville',
    'Bladensburg',
    'Boonsboro',
    'Bowie',
    'Boyds',
    'Brandywine',
    'Brentwood',
    'Brinklow',
    'Brookeville',
    'Brooklandville',
    'Brooklyn',
    'Bryans Road',
    'Bryantown',
    'Burtonsville',
    'Cabin John',
    'Cambridge',
    'Capitol Heights',
    'Cascade',
    'Catonsville',
    'Cecilton',
    'Charlotte Hall',
    'Cheltenham',
    'Chesapeake Beach',
    'Chesapeake City',
    'Chestertown',
    'Chevy Chase',
    'Childs',
    'Church Hill',
    'Churchton',
    'Churchville',
    'Clarksburg',
    'Clear Spring',
    'Clements',
    'Cockeysville',
    'Colora',
    'Conowingo',
    'Crofton',
    'Crisfield',
    'Crownsville',
    'Curtis Bay',
    'Darlington',
    'Davidsonville',
    'Deal Island',
    'Deale',
    'Derwood',
    'Dickerson',
    'District Heights',
    'Dowell',
    'Drayden',
    'Dundalk',
    'Dunkirk',
    'Earleville',
    'East New Market',
    'Elkridge',
    'Ellicott City',
    'Emmitsburg',
    'Essex',
    'Fallston',
    'Faulkner',
    'Federalsburg',
    'Finksburg',
    'Fork',
    'Ft. Washington',
    'Frederick',
    'Freeland',
    'Friendship',
    'Friendsville',
    'Frostburg',
    'Fruitland',
    'Gaithersburg',
    'Gambrills',
    'Glen Arm',
    'Glen Burnie',
    'Glenelg',
    'Glenn Dale',
    'Grantsville',
    'Grasonville',
    'Great Mills',
    'Greenbelt',
    'Gunpowder',
    'Gwynn Oak',
    'Halethorpe',
    'Hampstead',
    'Hancock',
    'Harwood',
    'Havre de Grace',
    'Hughesville',
    'Hunt Valley',
    'Huntingtown',
    'Hurlock',
    'Hyattsville',
    'Ijamsville',
    'Indian Head',
    'Jarrettsville',
    'Joppa',
    'Keedysville',
    'Kensington',
    'Kennedyville',
    'Keymar',
    'Kingsville',
    'La Plata',
    'Lanham',
    'Leonardtown',
    'Lexington Park',
    'Linthicum Heights',
    'Lisbon',
    'Lothian',
    'Lusby',
    'Lutherville',
    'Mardela Springs',
    'Marriottsville',
    'Maugansville',
    'Mechanicsville',
    'Middle River',
    'Millersville',
    'Millington',
    'Monkton',
    'Montgomery Village',
    'Mount Rainier',
    'Myersville',
    'Nanjemoy',
    'New Windsor',
    'Newburg',
    'North Beach',
    'North East',
    'Nottingham',
    'Ocean City',
    'Odenton',
    'Owings',
    'Owings Mills',
    'Oxon Hill',
    'Parkton',
    'Parkville',
    'Parsonsburg',
    'Pasadena',
    'Perry Hall',
    'Phoenix',
    'Pikesville',
    'Piney Point',
    'Pittsville',
    'Pocomoke City',
    'Point of Rocks',
    'Pomfret',
    'Poolesville',
    'Port Deposit',
    'Port Republic',
    'Port Tobacco',
    'Potomac',
    'Prince Frederick',
    'Princess Anne',
    'Pylesville',
    'Quantico',
    'Queenstown',
    'Randallstown',
    'Rawlings',
    'Reisterstown',
    'Ridgely',
    'Riverdale Park',
    'Rock Hall',
    'Rocky Ridge',
    'Saint Leonard',
    'Saint Michaels',
    'Salisbury',
    'Sandy Spring',
    'Savage',
    'Secretary',
    'Severn',
    'Severna Park',
    'Shady Side',
    'Silver Spring',
    'Smithsburg',
    'Snow Hill',
    'Solomons',
    'Sparks',
    'Sparrows Point',
    'Stevensville',
    'Street',
    'Sudlersville',
    'Suitland',
    'Sunderland',
    'Swanton',
    'Sykesville',
    'Takoma Park',
    'Taneytown',
    'Temple Hills',
    'Thurmont',
    'Towson',
    'Tracys Landing',
    'Trappe',
    'Union Bridge',
    'Upper Falls',
    'Upper Marlboro',
    'Waldorf',
    'Walkersville',
    'Washington Grove',
    'West Friendship',
    'West River',
    'Westernport',
    'Westminster',
    'White Marsh',
    'Whiteford',
    'Willards',
    'Williamsport',
    'Windsor Mill',
    'Woodsboro',
    'Wye Mills',
    'Advance',
    'Agency',
    'Alba',
    'Aldrich',
    'Amazonia',
    'Altenburg',
    'Anderson',
    'Amsterdam',
    'Arbyrd',
    'Archie',
    'Argyle',
    'Armstrong',
    'Asbury',
    'Ash Grove',
    'Auxvasse',
    'Ballwin',
    'Barnett',
    'Barnhart',
    'Bates City',
    'Beaufort',
    'Belgrade',
    'Belle',
    'Benton City',
    'Berger',
    'Bernie',
    'Bethel',
    'Bevier',
    'Billings',
    'Birch Tree',
    'Blackburn',
    'Blackwater',
    'Bland',
    'Bloomsdale',
    'Blue Eye',
    'Blue Springs',
    'Bogard',
    'Bolivar',
    'Bonnots Mill',
    'Bonne Terre',
    'Bosworth',
    'Bourbon',
    'Bragg City',
    'Branson',
    'Breckenridge',
    'Bridgeton',
    'Brinktown',
    'Bronaugh',
    'Brookfield',
    'Brookline',
    'Broseley',
    'Bunceton',
    'Bunker',
    'Bynumville',
    'Cabool',
    'Cadet',
    'Callao',
    'Camdenton',
    'Camden Point',
    'Campbell',
    'Cape Fair',
    'Cape Girardeau',
    'Cardwell',
    'Carl Junction',
    'Caruthersville',
    'Cassville',
    'Catawissa',
    'Caulfield',
    'Cedar Hill',
    'Cedarcreek',
    'Center',
    'Centerview',
    'Chaffee',
    'Chamois',
    'Chilhowee',
    'Chillicothe',
    'Chula',
    'Clarence',
    'Clark',
    'Clarksdale',
    'Clarkton',
    'Clever',
    'Clifton Hill',
    'Climax Springs',
    'Cole Camp',
    'Cooter',
    'Cosby',
    'Creighton',
    'Crocker',
    'Cross Timbers',
    'Crystal City',
    'Curryville',
    'Dardenne',
    'Davisville',
    'Dawn',
    'Dearborn',
    'Deerfield',
    'Defiance',
    'Des Peres',
    'Diamond',
    'Dittmer',
    'Doniphan',
    'Drexel',
    'Drury',
    'Dudley',
    'Duenweg',
    'Durham',
    'Eagle Rock',
    'Earth City',
    'East Lynne',
    'East Prairie',
    'Edwards',
    'El Dorado Springs',
    'Eldon',
    'Ellsinore',
    'Elsberry',
    'Eminence',
    'Eugene',
    'Excello',
    'Excelsior Springs',
    'Exeter',
    'Fair Grove',
    'Fairdealing',
    'Falcon',
    'Farber',
    'Farrar',
    'Faucett',
    'Fenton',
    'Festus',
    'Fisk',
    'Flemington',
    'Florissant',
    'Fordland',
    'Forest City',
    'Forest Green',
    'Foristell',
    'Fredericktown',
    'Freeman',
    'French Village',
    'Frohna',
    'Gallatin',
    'Garden City',
    'Gerald',
    'Gideon',
    'Gladstone',
    'Glenallen',
    'Gobler',
    'Golden City',
    'Goodman',
    'Gower',
    'Grain Valley',
    'Granby',
    'Gravois Mills',
    'Green Ridge',
    'Grover',
    'Grovespring',
    'Hale',
    'Halfway',
    'Hallsville',
    'Hannibal',
    'Harrisonville',
    'Hartsburg',
    'Hartville',
    'Hawk Point',
    'Hayti',
    'Hazelwood',
    'Herculaneum',
    'Hermann',
    'Higbee',
    'Higginsville',
    'High Ridge',
    'Highlandville',
    'Holcomb',
    'Holliday',
    'Holts Summit',
    'Hornersville',
    'House Springs',
    'Humansville',
    'Hunnewell',
    'Hurley',
    'Iberia',
    'Imperial',
    'Ionia',
    'Ironton',
    'Isabella',
    'Jamesport',
    'Jefferson City',
    'Jonesburg',
    'Joplin',
    'Kaiser',
    'Kearney',
    'Kelso',
    'Kennett',
    'Keytesville',
    'Kimberling City',
    'Kingdom City',
    'Kirbyville',
    'Knob Noster',
    'Koshkonong',
    'La Monte',
    'La Russell',
    'Labadie',
    'Lake Ozark',
    'Lake Saint Louis',
    'Lampe',
    'Laquey',
    'Lathrop',
    'Lawson',
    "Lee's Summit",
    'Lentner',
    'Leonard',
    'Leopold',
    'Liberal',
    'Licking',
    'Lilbourn',
    'Linn Creek',
    'Linneus',
    'Lockwood',
    'Lohman',
    'Lone Jack',
    'Lonedell',
    'Long Lane',
    'Louisiana',
    'Lowry City',
    'Macks Creek',
    'Malden',
    'Marble Hill',
    'Marceline',
    'Marionville',
    'Marquand',
    'Marshfield',
    'Marthasville',
    'Martinsburg',
    'Maryland Heights',
    'Maywood',
    'Meadville',
    'Mexico',
    'Mill Spring',
    'Miller',
    'Missouri City',
    'Moberly',
    'Mokane',
    'Monett',
    'Montgomery City',
    'Montier',
    'Moscow Mills',
    'Mountain Grove',
    'Myrtle',
    'Nelson',
    'Neosho',
    'Nevada',
    'New Bloomfield',
    'New Boston',
    'New Cambria',
    'New Florence',
    'New Franklin',
    'New London',
    'New Madrid',
    'New Melle',
    'Niangua',
    'Nixa',
    'Noel',
    'Norborne',
    'Oakville',
    'Old Monroe',
    'Olivette',
    'Oran',
    'Oregon',
    'Oronogo',
    'Orrick',
    'Osage Beach',
    'Otterville',
    'Overland',
    'Pacific',
    'Park Hills',
    'Parma',
    'Patton',
    'Peculiar',
    'Pevely',
    'Philadelphia',
    'Pilot Grove',
    'Pilot Knob',
    'Plato',
    'Platte City',
    'Plattsburg',
    'Pleasant Hope',
    'Point Lookout',
    'Polo',
    'Poplar Bluff',
    'Portageville',
    'Portland',
    'Potosi',
    'Pottersville',
    'Powell',
    'Poynor',
    'Prairie Hill',
    'Prairie Home',
    'Purdy',
    'Puxico',
    'Purdin',
    'Qulin',
    'Raymore',
    'Reeds Spring',
    'Rhineland',
    'Rich Hill',
    'Richards',
    'Ridgedale',
    'Roach',
    'Robertsville',
    'Rocheport',
    'Rockaway Beach',
    'Rocky Mount',
    'Rolla',
    'Rosebud',
    'Saint Ann',
    'Saint Charles',
    'Saint Clair',
    'St Louis',
    'Saint Mary',
    'City of Saint Peters',
    'Saint Robert',
    'Saint Thomas',
    'Sainte Genevieve',
    'Schell City',
    'Scott City',
    'Seligman',
    'Senath',
    'Shelbina',
    'Shell Knob',
    'Sikeston',
    'Silva',
    'Slater',
    'South West City',
    'Spokane',
    'Stark City',
    'Steelville',
    'Stella',
    'Stotts City',
    'Stoutland',
    'Stover',
    'Stoutsville',
    'Strafford',
    'Sturgeon',
    'Sunrise Beach',
    'Sweet Springs',
    'Syracuse',
    'Taneyville',
    'Tebbetts',
    'Theodosia',
    'Tina',
    'Town and Country',
    'Trimble',
    'Union Star',
    'Urbana',
    'Urich',
    'Valles Mines',
    'Valley Park',
    'Vanzant',
    'Viburnum',
    'Vichy',
    'Walnut Shade',
    'Wappapello',
    'Washburn',
    'Weatherby',
    'Webb City',
    'Wentworth',
    'Wentzville',
    'West Plains',
    'Wheatland',
    'Wheeling',
    'Willard',
    'Willow Springs',
    'Winona',
    'Wooldridge',
    'Wright City',
    'Wyatt',
    'Yukon',
    'Ackerman',
    'Alligator',
    'Amory',
    'Bailey',
    'Baldwyn',
    'Bassfield',
    'Bay Saint Louis',
    'Bay Springs',
    'Belden',
    'Belmont',
    'Belzoni',
    'Benoit',
    'Bentonia',
    'Biloxi',
    'Blue Mountain',
    'Bogue Chitto',
    'Bolton',
    'Boyle',
    'Braxton',
    'Brookhaven',
    'Bruce',
    'Bude',
    'Byhalia',
    'Byram',
    'Caledonia',
    'Calhoun City',
    'Carriere',
    'Cary',
    'Cascilla',
    'Chunky',
    'Como',
    'Conehatta',
    'Crosby',
    'Crowder',
    'Crystal Springs',
    "D'Iberville",
    'De Kalb',
    'Dennis',
    'Diamondhead',
    'Drew',
    'Durant',
    'Ecru',
    'Ellisville',
    'Eupora',
    'Flowood',
    'Forest',
    'Foxworth',
    'French Camp',
    'Friars Point',
    'Gautier',
    'Glen Allan',
    'Glens',
    'Golden',
    'Grace',
    'Greenwood Springs',
    'Grenada',
    'Guntown',
    'Harrisville',
    'Hattiesburg',
    'Heidelberg',
    'Hermanville',
    'Hollandale',
    'Horn Lake',
    'Houlka',
    'Indianola',
    'Itta Bena',
    'Jayess',
    'Kilmichael',
    'Kiln',
    'Kosciusko',
    'Lake',
    'Lauderdale',
    'Leakesville',
    'Lena',
    'Leland',
    'Long Beach',
    'Louin',
    'Lucedale',
    'Lumberton',
    'Maben',
    'Magee',
    'Mantachie',
    'Mantee',
    'Marks',
    'Mathiston',
    'McCall Creek',
    'McCarley',
    'McComb',
    'McHenry',
    'McLain',
    'Mendenhall',
    'Meridian',
    'Merigold',
    'Mize',
    'Mooreville',
    'Moorhead',
    'Morton',
    'Moselle',
    'Moss Point',
    'Mound Bayou',
    'Natchez',
    'Nesbit',
    'Nettleton',
    'New Augusta',
    'New Hebron',
    'Noxapater',
    'Oak Vale',
    'Ocean Springs',
    'Okolona',
    'Osyka',
    'Ovett',
    'Pace',
    'Pachuta',
    'Pascagoula',
    'Pass Christian',
    'Pattison',
    'Paulding',
    'Pearlington',
    'Pelahatchie',
    'Perkinston',
    'Petal',
    'Picayune',
    'Pickens',
    'Pontotoc',
    'Pope',
    'Poplarville',
    'Port Gibson',
    'Potts Camp',
    'Prentiss',
    'Pulaski',
    'Purvis',
    'Raleigh',
    'Richton',
    'Ridgeland',
    'Rienzi',
    'Ripley',
    'Robinsonville',
    'Rolling Fork',
    'Roxie',
    'Ruth',
    'Saltillo',
    'Sand Hill',
    'Saucier',
    'Scooba',
    'Seminary',
    'Senatobia',
    'Shannon',
    'Shubuta',
    'Sledge',
    'Soso',
    'Southaven',
    'Southhaven',
    'State Line',
    'Starkville',
    'Steens',
    'Stewart',
    'Stringer',
    'Summit',
    'Sumrall',
    'Sunflower',
    'Tchula',
    'Terry',
    'Tiplersville',
    'Tishomingo',
    'Toomsuba',
    'Tougaloo',
    'Tremont',
    'Tunica',
    'Tupelo',
    'Tutwiler',
    'Tylertown',
    'University',
    'Vaiden',
    'Vancleave',
    'Vardaman',
    'Vicksburg',
    'Walls',
    'Walnut Grove',
    'Water Valley',
    'Wesson',
    'West',
    'Wiggins',
    'Yazoo City',
    'Ahoskie',
    'Alamance',
    'Albemarle',
    'Albertson',
    'Alexis',
    'Andrews',
    'Angier',
    'Ansonville',
    'Apex',
    'Arapahoe',
    'Ararat',
    'Arden',
    'Ash',
    'Asheboro',
    'Asheville',
    'Atlantic',
    'Aulander',
    'Autryville',
    'Ayden',
    'Aydlett',
    'Badin',
    'Bahama',
    'Bakersville',
    'Balsam Grove',
    'Banner Elk',
    'Barnardsville',
    'Bath',
    'Battleboro',
    'Bayboro',
    'Bear Creek',
    'Belews Creek',
    'Belhaven',
    'Belvidere',
    'Bennett',
    'Benson',
    'Bessemer City',
    'Beulaville',
    'Biscoe',
    'Black Mountain',
    'Bladenboro',
    'Blanch',
    'Blounts Creek',
    'Blowing Rock',
    'Boiling Springs',
    'Bolivia',
    'Boomer',
    'Boone',
    'Bostic',
    'Brevard',
    'Broadway',
    'Browns Summit',
    'Bryson City',
    'Buies Creek',
    'Bullock',
    'Bunn',
    'Bunnlevel',
    'Burgaw',
    'Burnsville',
    'Butner',
    'Buxton',
    'Calabash',
    'Candler',
    'Candor',
    'Caroleen',
    'Carolina',
    'Carolina Beach',
    'Carolina Pines',
    'Carrboro',
    'Casar',
    'Cashiers',
    'Castalia',
    'Castle Hayne',
    'Catawba',
    'Cedar Grove',
    'Cedar Island',
    'Chadbourn',
    'Chapel Hill',
    'Charlotte',
    'Cherryville',
    'China Grove',
    'Chinquapin',
    'Chocowinity',
    'Claremont',
    'Clarendon',
    'Clemmons',
    'Cliffside',
    'Climax',
    'Coats',
    'Coinjock',
    'Colerain',
    'Collettsville',
    'Conetoe',
    'Connelly Springs',
    'Conover',
    'Cooleemee',
    'Corapeake',
    'Cornelius',
    'Council',
    'Cove City',
    'Cramerton',
    'Creedmoor',
    'Creston',
    'Creswell',
    'Crouse',
    'Crumpler',
    'Cullowhee',
    'Currie',
    'Currituck',
    'Danbury',
    'Davidson',
    'Davis',
    'Deep Gap',
    'Deep Run',
    'Denver',
    'Dobson',
    'Dunn',
    'Eagle Springs',
    'East Bend',
    'East Flat Rock',
    'Eden',
    'Edenton',
    'Edneyville',
    'Efland',
    'Elizabeth City',
    'Elk Park',
    'Elkin',
    'Ellenboro',
    'Ellerbe',
    'Elm City',
    'Elon',
    'Emerald Isle',
    'Engelhard',
    'Ennice',
    'Ernul',
    'Erwin',
    'Etowah',
    'Everetts',
    'Fairmont',
    'Faison',
    'Farmville',
    'Ferguson',
    'Fleetwood',
    'Fletcher',
    'Four Oaks',
    'Franklinville',
    'Fremont',
    'Frisco',
    'Fuquay-Varina',
    'Garland',
    'Garner',
    'Gastonia',
    'Gates',
    'Gatesville',
    'Germanton',
    'Gibsonville',
    'Glenville',
    'Gloucester',
    'Godwin',
    'Gold Hill',
    'Goldsboro',
    'Graham',
    'Grandy',
    'Granite Falls',
    'Grantsboro',
    'Grassy Creek',
    'Green Mountain',
    'Grifton',
    'Grimesland',
    'Halifax',
    'Hallsboro',
    'Hamlet',
    'Hamptonville',
    'Harrells',
    'Harrellsville',
    'Harris',
    'Hatteras',
    'Havelock',
    'Haw River',
    'Hayesville',
    'Hendersonville',
    'Henrico',
    'Hertford',
    'Hiddenite',
    'High Point',
    'Highlands',
    'Hildebran',
    'Hillsborough',
    'Hobbsville',
    'Holly Ridge',
    'Hookerton',
    'Hope Mills',
    'Horse Shoe',
    'Hubert',
    'Huntersville',
    'Hurdle Mills',
    'Indian Trail',
    'Iron Station',
    'Ivanhoe',
    'Jackson Springs',
    'Jamesville',
    'Jarvisburg',
    'Kannapolis',
    'Kelford',
    'Kelly',
    'Kenansville',
    'Kenly',
    'Kernersville',
    'Kill Devil Hills',
    'King',
    'Kings Mountain',
    'Kittrell',
    'Kitty Hawk',
    'Knightdale',
    'Lake Lure',
    'Lake Toxaway',
    'Lake Waccamaw',
    'Landis',
    'Laurel Springs',
    'Laurinburg',
    'Lawndale',
    'Leicester',
    'Lenoir',
    'Lewiston Woodville',
    'Lilesville',
    'Lillington',
    'Little Switzerland',
    'Littleton',
    'Locust',
    'Lowgap',
    'Lucama',
    'Macclesfield',
    'Maggie Valley',
    'Maiden',
    'Manns Harbor',
    'Manteo',
    'Maple Hill',
    'Marble',
    'Mars Hill',
    'Marshville',
    'Marston',
    'Matthews',
    'Maury',
    'Maxton',
    'Mayodan',
    'McLeansville',
    'Mebane',
    'Merritt',
    'Merry Hill',
    'Micro',
    'Middlesex',
    'Midway Park',
    'Millers Creek',
    'Mills River',
    'Mocksville',
    'Moncure',
    'Montreat',
    'Mooresboro',
    'Moravian Falls',
    'Morehead City',
    'Morrisville',
    'Mount Gilead',
    'Mount Holly',
    'Mount Mourne',
    'Mt Pleasant',
    'Mount Ulla',
    'Moyock',
    'Murphy',
    'Nags Head',
    'Nakina',
    'Nebo',
    'New Bern',
    'New Hill',
    'Newland',
    'Newton Grove',
    'Norlina',
    'North Wilkesboro',
    'Oak City',
    'Oak Island',
    'Oakboro',
    'Ocean Isle Beach',
    'Ocracoke',
    'Old Fort',
    'Olin',
    'Oriental',
    'Otto',
    'Pantego',
    'Paw Creek',
    'Peachland',
    'Pfafftown',
    'Pilot Mountain',
    'Pinebluff',
    'Pinetops',
    'Pinetown',
    'Pinnacle',
    'Pink Hill',
    'Pisgah Forest',
    'Pleasant Garden',
    'Plymouth',
    'Point Harbor',
    'Polkton',
    'Pollocksville',
    'Powells Point',
    'Powellsville',
    'Purlear',
    'Raeford',
    'Ramseur',
    'Randleman',
    'Red Springs',
    'Rich Square',
    'Richfield',
    'Richlands',
    'Riegelwood',
    'Roanoke Rapids',
    'Roaring Gap',
    'Roaring River',
    'Robbins',
    'Robbinsville',
    'Robersonville',
    'Rockingham',
    'Rockwell',
    'Rocky Point',
    'Rolesville',
    'Ronda',
    'Roper',
    'Roseboro',
    'Rosman',
    'Rougemont',
    'Rowland',
    'Roxboro',
    'Roxobel',
    'Ruffin',
    'Rural Hall',
    'Rutherfordton',
    'Saint Pauls',
    'Salemburg',
    'Saluda',
    'Sandy Ridge',
    'Sapphire',
    'Scaly Mountain',
    'Scotland Neck',
    'Seagrove',
    'Semora',
    'Shallotte',
    'Shawboro',
    'Sherrills Ford',
    'Shiloh',
    'Siler City',
    'Sims',
    'Sneads Ferry',
    'Snow Camp',
    'Sophia',
    'South Mills',
    'Southern Pines',
    'Southport',
    'Spindale',
    'Spring Hope',
    'Spring Lake',
    'Staley',
    'Stanfield',
    'Stanley',
    'Stantonsburg',
    'Star',
    'State Road',
    'Statesville',
    'Stedman',
    'Stem',
    'Stokesdale',
    'Stoneville',
    'Stony Point',
    'Stovall',
    'Sugar Grove',
    'Sunbury',
    'Sunset Beach',
    'Swannanoa',
    'Swanquarter',
    'Swansboro',
    'Sylva',
    'Tabor City',
    'Tar Heel',
    'Tarawa Terrace II',
    'Tarboro',
    'Teachey',
    'Terrell',
    'Timberlake',
    'Tobaccoville',
    'Todd',
    'Topton',
    'Traphill',
    'Troutman',
    'Tryon',
    'Tuckasegee',
    'Turkey',
    'Union Mills',
    'Valdese',
    'Vale',
    'Vanceboro',
    'Vilas',
    'Wade',
    'Wadesboro',
    'Wake Forest',
    'Walkertown',
    'Wallace',
    'Walnut Cove',
    'Walstonburg',
    'Wanchese',
    'Waxhaw',
    'Weaverville',
    'Wendell',
    'West End',
    'West Jefferson',
    'Whispering Pines',
    'Whitakers',
    'White Oak',
    'Whiteville',
    'Whitsett',
    'Whittier',
    'Wilkesboro',
    'Williamston',
    'Wingate',
    'Winnabow',
    'Winston-Salem',
    'Winton',
    'Wrightsville Beach',
    'Yadkinville',
    'Yanceyville',
    'Zionville',
    'Absecon',
    'Atco',
    'Atlantic City',
    'Audubon',
    'Avalon',
    'Barnegat',
    'Barnegat Light',
    'Barrington',
    'Bayville',
    'Beach Haven',
    'Beachwood',
    'Bellmawr',
    'Blackwood',
    'Brigantine',
    'Browns Mills',
    'Buena',
    'Cape May Court House',
    'Cape May',
    'Cherry Hill',
    'Clarksboro',
    'Clementon',
    'Collingswood',
    'Dennisville',
    'Egg Harbor City',
    'Elwood',
    'Estell Manor',
    'Forked River',
    'Gibbsboro',
    'Gibbstown',
    'Glassboro',
    'Glendora',
    'Gloucester City',
    'Greenwich',
    'Haddon Heights',
    'Haddonfield',
    'Hainesport',
    'Hammonton',
    'Lanoka Harbor',
    'Lavallette',
    'Lawnside',
    'Longport',
    'Manahawkin',
    'Mantua',
    'Maple Shade Township',
    'Margate City',
    'Marlton',
    'Marmora',
    'Mays Landing',
    'Medford',
    'Mickleton',
    'Moorestown',
    'Mount Ephraim',
    'Mount Laurel',
    'Mount Royal',
    'Mullica Hill',
    'National Park',
    'Newfield',
    'Northfield',
    'Oaklyn',
    'Paulsboro',
    'Pedricktown',
    'Pemberton',
    'Penns Grove',
    'Pennsauken',
    'Pennsville',
    'Pitman',
    'Pleasantville',
    'Port Norris',
    'Rio Grande',
    'Runnemede',
    'Sea Isle City',
    'Seaside Heights',
    'Seaside Park',
    'Sewell',
    'Sicklerville',
    'Somerdale',
    'Somers Point',
    'Stone Harbor',
    'Stratford',
    'Swedesboro',
    'Thorofare',
    'Toms River',
    'Tuckerton',
    'Turnersville',
    'Ventnor City',
    'Villas',
    'Vincentown',
    'Vineland',
    'Waretown',
    'Waterford Works',
    'Wenonah',
    'West Berlin',
    'West Creek',
    'Winslow Township',
    'Woodbury Heights',
    'Woodstown',
    'Alpha',
    'Amanda',
    'Amelia',
    'Amesville',
    'Arcanum',
    'Ashville',
    'Batavia',
    'Beallsville',
    'Beavercreek',
    'Belle Valley',
    'Bellbrook',
    'Belpre',
    'Bidwell',
    'Blacklick',
    'Blanchester',
    'Blue Ash',
    'Blue Creek',
    'Blue Rock',
    'Buchtel',
    'Byesville',
    'Canal Winchester',
    'Carroll',
    'Chauncey',
    'Chesapeake',
    'Chesterhill',
    'Cincinnati',
    'Cleves',
    'Coalton',
    'College Corner',
    'Commercial Point',
    'Coolville',
    'Crooksville',
    'Crown City',
    'Cutler',
    'Eaton',
    'Enon',
    'Etna',
    'Fairborn',
    'Farmersville',
    'Franklin Furnace',
    'Gallipolis',
    'Galloway',
    'Glenford',
    'Glouster',
    'Gratiot',
    'Grove City',
    'Groveport',
    'Guysville',
    'Hamden',
    'Hamersville',
    'Harveysburg',
    'Hopewell',
    'Huber Heights',
    'Jacobsburg',
    'Jerusalem',
    'Kettering',
    'Kings Mills',
    'Kitts Hill',
    'Langsville',
    'Laura',
    'Laurelville',
    'Little Hocking',
    'Lockbourne',
    'Londonderry',
    'Lore City',
    'Loveland',
    'Lucasville',
    'Lynchburg',
    'Maineville',
    'Malta',
    'McArthur',
    'McConnelsville',
    'McDermott',
    'Medway',
    'Miamisburg',
    'Miamitown',
    'Miamiville',
    'Middleport',
    'Millersport',
    'Millfield',
    'Minford',
    'Moscow',
    'Mount Orab',
    'Mount Perry',
    'Mount Saint Joseph',
    'Nelsonville',
    'New Carlisle',
    'New Holland',
    'New Lebanon',
    'New Lexington',
    'New Madison',
    'New Marshfield',
    'New Matamoras',
    'New Paris',
    'New Richmond',
    'New Vienna',
    'North Bend',
    'Okeana',
    'West Chester',
    'Oregonia',
    'Orient',
    'Otway',
    'Pataskala',
    'Pedro',
    'Peebles',
    'Philo',
    'Pickerington',
    'Piketon',
    'Pleasant City',
    'Pleasant Plain',
    'Pomeroy',
    'Portsmouth',
    'Powhatan Point',
    'Proctorville',
    'Quaker City',
    'Ray',
    'Reedsville',
    'Reno',
    'Reynoldsburg',
    'Richmond Dale',
    'Roseville',
    'Rutland',
    'Sabina',
    'Sardinia',
    'Seaman',
    'Shadyside',
    'Sharonville',
    'South Bloomingville',
    'South Charleston',
    'South Lebanon',
    'South Point',
    'South Salem',
    'South Vienna',
    'South Webster',
    'Spring Valley',
    'Springboro',
    'Stockport',
    'Terrace Park',
    'The Plains',
    'Thornville',
    'Thurston',
    'Tipp City',
    'Trotwood',
    'Washington Court House',
    'Waterford',
    'Wellston',
    'West Alexandria',
    'West Milton',
    'West Portsmouth',
    'Wheelersburg',
    'Wilberforce',
    'Willow Wood',
    'Woodsfield',
    'Yellow Springs',
    'Zanesville',
    'Achille',
    'Ada',
    'Adair',
    'Afton',
    'Alex',
    'Aline',
    'Anadarko',
    'Antlers',
    'Apache',
    'Arapaho',
    'Ardmore',
    'Arkoma',
    'Arnett',
    'Asher',
    'Atoka',
    'Barnsdall',
    'Bartlesville',
    'Beggs',
    'Bessie',
    'Big Cabin',
    'Binger',
    'Bixby',
    'Blackwell',
    'Blair',
    'Bluejacket',
    'Bokchito',
    'Bokoshe',
    'Boswell',
    'Bowlegs',
    'Boynton',
    'Braman',
    'Bristow',
    'Broken Arrow',
    'Broken Bow',
    'Bromide',
    'Burneyville',
    'Byars',
    'Cache',
    'Caddo',
    'Canadian',
    'Carmen',
    'Carnegie',
    'Carney',
    'Carter',
    'Cashion',
    'Castle',
    'Catoosa',
    'Cement',
    'Centrahoma',
    'Chattanooga',
    'Checotah',
    'Cheyenne',
    'Chickasha',
    'Choctaw',
    'Chouteau',
    'Claremore',
    'Cleo Springs',
    'Colcord',
    'Comanche',
    'Cookson',
    'Copan',
    'Cordell',
    'Council Hill',
    'Coweta',
    'Coyle',
    'Crescent',
    'Cushing',
    'Cyril',
    'Dacoma',
    'Daisy',
    'Delaware',
    'Depew',
    'Devol',
    'Dewar',
    'Dewey',
    'Dill City',
    'Disney',
    'Drumright',
    'Duke',
    'Duncan',
    'Dustin',
    'Earlsboro',
    'Edmond',
    'El Reno',
    'Elgin',
    'Elmore City',
    'Enid',
    'Erick',
    'Eucha',
    'Fargo',
    'Faxon',
    'Finley',
    'Fort Cobb',
    'Fort Gibson',
    'Fort Towson',
    'Fox',
    'Foyil',
    'Gage',
    'Gans',
    'Garber',
    'Geronimo',
    'Glenpool',
    'Goltry',
    'Gore',
    'Gracemont',
    'Grove',
    'Haileyville',
    'Hallett',
    'Harrah',
    'Hartshorne',
    'Haskell',
    'Haworth',
    'Healdton',
    'Heavener',
    'Hendrix',
    'Hennessey',
    'Henryetta',
    'Hinton',
    'Hitchcock',
    'Hobart',
    'Holdenville',
    'Hollis',
    'Hominy',
    'Howe',
    'Hugo',
    'Hulbert',
    'Hydro',
    'Idabel',
    'Indiahoma',
    'Inola',
    'Jenks',
    'Jet',
    'Jones',
    'Kellyville',
    'Kenefic',
    'Keota',
    'Ketchum',
    'Kiefer',
    'Kingfisher',
    'Kinta',
    'Konawa',
    'Lahoma',
    'Laverne',
    'Lawton',
    'Leedey',
    'Lenapah',
    'Lindsay',
    'Lone Grove',
    'Lone Wolf',
    'Longdale',
    'Loyal',
    'Luther',
    'Macomb',
    'Madill',
    'Mangum',
    'Mannford',
    'Maramec',
    'Marlow',
    'Maud',
    'May',
    'McAlester',
    'McCurtain',
    'McLoud',
    'Mead',
    'Meeker',
    'Milburn',
    'Mill Creek',
    'Millerton',
    'Minco',
    'Mooreland',
    'Morrison',
    'Mounds',
    'Muldrow',
    'Muse',
    'Muskogee',
    'Mustang',
    'Nash',
    'Newalla',
    'Newcastle',
    'Newkirk',
    'Nowata',
    'Ochelata',
    'Okarche',
    'Okay',
    'Okeene',
    'Okemah',
    'Oklahoma City',
    'Okmulgee',
    'Oktaha',
    'Oologah',
    'Osage',
    'Owasso',
    'Paden',
    'Panama',
    'Park Hill',
    'Pawhuska',
    'Pauls Valley',
    'Perkins',
    'Pocasset',
    'Pocola',
    'Ponca City',
    'Pond Creek',
    'Porter',
    'Porum',
    'Poteau',
    'Prague',
    'Pryor',
    'Purcell',
    'Quapaw',
    'Randlett',
    'Ratliff City',
    'Ravia',
    'Red Oak',
    'Red Rock',
    'Reydon',
    'Ringling',
    'Ringwood',
    'Rocky',
    'Roff',
    'Rose',
    'Rush Springs',
    'Sallisaw',
    'Sand Springs',
    'Sapulpa',
    'Sasakwa',
    'Savanna',
    'Sayre',
    'Seiling',
    'Sentinel',
    'Shady Point',
    'Shattuck',
    'Shidler',
    'Skiatook',
    'Snow',
    'Soper',
    'Spavinaw',
    'Spencerville',
    'Sperry',
    'Spiro',
    'Springer',
    'Stigler',
    'Stillwater',
    'Stroud',
    'Sweetwater',
    'Swink',
    'Tahlequah',
    'Talala',
    'Talihina',
    'Taloga',
    'Terral',
    'Thackerville',
    'Thomas',
    'Tonkawa',
    'Tulsa',
    'Tuskahoma',
    'Tuttle',
    'Valliant',
    'Verden',
    'Vian',
    'Vici',
    'Vinita',
    'Wagoner',
    'Wakita',
    'Walters',
    'Wanette',
    'Wann',
    'Wapanucka',
    'Wardville',
    'Warner',
    'Watonga',
    'Waukomis',
    'Waurika',
    'Wayne',
    'Waynoka',
    'Weatherford',
    'Webbers Falls',
    'Welch',
    'Weleetka',
    'Welling',
    'Wetumka',
    'Wewoka',
    'Whitefield',
    'Whitesboro',
    'Wilburton',
    'Wister',
    'Woodward',
    'Wyandotte',
    'Wynnewood',
    'Wynona',
    'Abbottstown',
    'Addison',
    'Airville',
    'Allison',
    'Arendtsville',
    'Artemas',
    'Aspers',
    'Aston Mills',
    'Atglen',
    'Avondale',
    'Bala',
    'Big Cove Tannery',
    'Biglerville',
    'Blue Ridge Summit',
    'Bobtown',
    'Breezewood',
    'Broomall',
    'Carmichaels',
    'Chadds Ford',
    'Christiana',
    'Clearville',
    'Clifton Heights',
    'Cochranville',
    'Concordville',
    'Conestoga',
    'Confluence',
    'Crystal Spring',
    'Dallastown',
    'Darby',
    'Drexel Hill',
    'Dunbar',
    'East Berlin',
    'Essington',
    'Fawn Grove',
    'Folcroft',
    'Fort Loudon',
    'Gap',
    'Gettysburg',
    'Glen Mills',
    'Glen Rock',
    'Glenolden',
    'Havertown',
    'Holbrook',
    'Holmes',
    'Holtwood',
    'Hopwood',
    'Hyndman',
    'Kennett Square',
    'Kirkwood',
    'Landenberg',
    'Lansdowne',
    'Lemont Furnace',
    'Littlestown',
    'Maple Grove',
    'Marcus Hook',
    'Markleton',
    'Markleysburg',
    'Masontown',
    'McConnellsburg',
    'McSherrystown',
    'Media',
    'Mercersburg',
    'Meyersdale',
    'Modena',
    'Mont Alto',
    'Mount Morris',
    'Needmore',
    'New Freedom',
    'New Freeport',
    'New Oxford',
    'New Park',
    'New Providence',
    'Newtown Square',
    'Normalville',
    'Ohiopyle',
    'Orrtanna',
    'Parkesburg',
    'Peach Bottom',
    'Pequea',
    'Point Marion',
    'Prospect Park',
    'Quarryville',
    'Red Lion',
    'Rices Landing',
    'Ridley Park',
    'Seven Valleys',
    'Shady Grove',
    'Sharon Hill',
    'Shrewsbury',
    'Spring Grove',
    'Springs',
    'Stewartstown',
    'Swarthmore',
    'Thorndale',
    'Toughkenamon',
    'Upper Darby',
    'Warfordsburg',
    'Washington Boro',
    'West Finley',
    'West Grove',
    'Whitehall',
    'Willow Street',
    'Wind Ridge',
    'Woodlyn',
    'Aiken',
    'Alcolu',
    'Allendale',
    'Awendaw',
    'Aynor',
    'Bamberg',
    'Barnwell',
    'Batesburg',
    'Beech Island',
    'Bennettsville',
    'Bethune',
    'Blackstock',
    'Blackville',
    'Blacksburg',
    'Blythewood',
    'Bonneau',
    'Branchville',
    'Brunson',
    'Cades',
    'Calhoun Falls',
    'Campobello',
    'Cassatt',
    'Cayce',
    'Central',
    'Chapin',
    'Chappells',
    'Cheraw',
    'Chesnee',
    'Clarks Hill',
    'Clemson',
    'Clio',
    'Clover',
    'Cope',
    'Cottageville',
    'Coward',
    'Cowpens',
    'Cross',
    'Cross Anchor',
    'Cross Hill',
    'Dalzell',
    'Daufuskie Landing',
    'Denmark',
    'Dillon',
    'Donalds',
    'Dorchester',
    'Drayton',
    'Due West',
    'Early Branch',
    'Easley',
    'Eastover',
    'Edgefield',
    'Edgemoor',
    'Edisto Island',
    'Ehrhardt',
    'Elloree',
    'Enoree',
    'Estill',
    'Eutawville',
    'Fair Play',
    'Fairfax',
    'Folly Beach',
    'Fort Lawn',
    'Fort Mill',
    'Fountain Inn',
    'Gaffney',
    'Galivants Ferry',
    'Gaston',
    'Goose Creek',
    'Graniteville',
    'Gray Court',
    'Great Falls',
    'Greeleyville',
    'Green Pond',
    'Green Sea',
    'Greer',
    'Hanahan',
    'Hardeeville',
    'Harleyville',
    'Heath Springs',
    'Hemingway',
    'Hickory Grove',
    'Hilton Head Island',
    'Hodges',
    'Holly Hill',
    'Honea Path',
    'Hopkins',
    'Huger',
    'Irmo',
    'Islandton',
    'Isle of Palms',
    'Iva',
    'Johns Island',
    'Johnsonville',
    'Johnston',
    'Kershaw',
    'Kingstree',
    'La France',
    'Ladson',
    'Landrum',
    'Latta',
    'Laurens',
    'Little Mountain',
    'Longs',
    'Loris',
    'Lyman',
    'Manning',
    'Mauldin',
    'Mayesville',
    'McBee',
    'McClellanville',
    'McCormick',
    'Modoc',
    'Moncks Corner',
    'Monetta',
    'Montmorenci',
    'Moore',
    'Mount Croghan',
    'Mt. Pleasant',
    'Mountville',
    'Mullins',
    'Murrells Inlet',
    'Myrtle Beach',
    'Neeses',
    'New Ellenton',
    'New Zion',
    'Nichols',
    'Ninety Six',
    'North',
    'North Augusta',
    'North Charleston',
    'North Myrtle Beach',
    'Norway',
    'Olanta',
    'Okatie',
    'Orangeburg',
    'Pacolet',
    'Pageland',
    'Pamplico',
    'Patrick',
    'Pauline',
    'Pawleys Island',
    'Pelion',
    'Pelzer',
    'Pinewood',
    'Plum Branch',
    'Pomaria',
    'Port Royal',
    'Prosperity',
    'Ravenel',
    'Reevesville',
    'Reidville',
    'Rembert',
    'Richburg',
    'Ridge Spring',
    'Ridgeville',
    'Ridgeway',
    'Rock Hill',
    'Roebuck',
    'Round O',
    'Rowesville',
    'Ruby',
    'Saint Matthews',
    'Saint Stephen',
    'Salley',
    'Salters',
    'Santee',
    'Seabrook',
    'Sheldon',
    'Six Mile',
    'Smoaks',
    'Spartanburg',
    'Startex',
    'Starr',
    'Sullivans Island',
    'Summerton',
    'Sumter',
    'Taylors',
    'Tigerville',
    'Tillman',
    'Timmonsville',
    'Townville',
    'Travelers Rest',
    'Turbeville',
    'Varnville',
    'Wadmalaw Island',
    'Wagener',
    'Walhalla',
    'Walterboro',
    'Ware Shoals',
    'Warrenville',
    'Wedgefield',
    'Wellford',
    'West Columbia',
    'White Rock',
    'Whitmire',
    'Woodruff',
    'Yemassee',
    'Adams',
    'Adamsville',
    'Alcoa',
    'Algood',
    'Allons',
    'Antioch',
    'Apison',
    'Arrington',
    'Ashland City',
    'Auburntown',
    'Bean Station',
    'Beech Bluff',
    'Beechgrove',
    'Belfast',
    'Bell Buckle',
    'Bells',
    'Bethel Springs',
    'Bethpage',
    'Big Rock',
    'Big Sandy',
    'Birchwood',
    'Blaine',
    'Bloomington Springs',
    'Blountville',
    'Bon Aqua',
    'Bradyville',
    'Bruceton',
    'Brush Creek',
    'Buffalo Valley',
    'Bulls Gap',
    'Bumpus Mills',
    'Burlison',
    'Bybee',
    'Byrdstown',
    'Castalian Springs',
    'Celina',
    'Chapmansboro',
    'Chuckey',
    'Clarkrange',
    'Coalfield',
    'College Grove',
    'Collegedale',
    'Collierville',
    'Collinwood',
    'Cookeville',
    'Copperhill',
    'Cornersville',
    'Corryton',
    'Cottage Grove',
    'Cottontown',
    'Counce',
    'Cowan',
    'Culleoka',
    'Cumberland City',
    'Cumberland Furnace',
    'Cumberland Gap',
    'Dandridge',
    'Darden',
    'Decaturville',
    'Decherd',
    'Deer Lodge',
    'Del Rio',
    'Dellrose',
    'Dickson',
    'Dowelltown',
    'Dresden',
    'Drummonds',
    'Duff',
    'Dunlap',
    'Dyer',
    'Dyersburg',
    'Eads',
    'Eagleville',
    'Elizabethton',
    'Elmwood',
    'Elora',
    'Enville',
    'Erin',
    'Ethridge',
    'Estill Springs',
    'Evensville',
    'Fall Branch',
    'Finger',
    'Flag Pond',
    'Flintville',
    'Gainesboro',
    'Gatlinburg',
    'Gleason',
    'Goodlettsville',
    'Goodspring',
    'Gordonsville',
    'Granville',
    'Greenback',
    'Greeneville',
    'Grimsley',
    'Gruetli-Laager',
    'Halls',
    'Hampshire',
    'Harriman',
    'Harrogate',
    'Helenwood',
    'Henning',
    'Henry',
    'Hilham',
    'Hixson',
    'Hohenwald',
    'Hollow Rock',
    'Hornbeak',
    'Howley',
    'Huntingdon',
    'Huntland',
    'Huron',
    'Hurricane Mills',
    'Indian Mound',
    'Iron City',
    'Jacksboro',
    'Jellico',
    'Joelton',
    'Johnson City',
    'Jonesborough',
    'Kenton',
    'Kingsport',
    'Kingston Springs',
    'Kodak',
    'La Vergne',
    'Rocky Top',
    'Lakewood',
    'Lancing',
    'Lascassas',
    'Laurel Bloomery',
    'Lenoir City',
    'Leoma',
    'Limestone',
    'Lobelville',
    'Loudon',
    'Lyles',
    'Mascot',
    'Maynardville',
    'McDonald',
    'McEwen',
    'McMinnville',
    'Medina',
    'Medon',
    'Michie',
    'Middleton',
    'Milligan',
    'Minor Hill',
    'Mohawk',
    'Monteagle',
    'Moores Camp',
    'Mooresburg',
    'Morris Chapel',
    'Mosheim',
    'Moss',
    'Mount Juliet',
    'Mount Pleasant',
    'New Johnsonville',
    'New Tazewell',
    'Niota',
    'Nolensville',
    'Norma',
    'Nunnelly',
    'Obion',
    'Old Farm',
    'Oliver Springs',
    'Oneida',
    'Only',
    'Ooltewah',
    'Parrottsville',
    'Pegram',
    'Petros',
    'Pigeon Forge',
    'Piney Flats',
    'Pioneer',
    'Pleasant Shade',
    'Pleasant View',
    'Primm Springs',
    'Puryear',
    'Quebeck',
    'Readyville',
    'Red Boiling Springs',
    'Reliance',
    'Rickman',
    'Riddleton',
    'Roan Mountain',
    'Rock Island',
    'Rockford',
    'Rockvale',
    'Rutherford',
    'Sale Creek',
    'Santa Fe',
    'Saulsbury',
    'Selmer',
    'Sevierville',
    'Sewanee',
    'Sharps Chapel',
    'Signal Mountain',
    'Sneedville',
    'Soddy',
    'South Fulton',
    'South Pittsburg',
    'Speedwell',
    'Spring City',
    'Strawberry Plains',
    'Summertown',
    'Sunbright',
    'Surgoinsville',
    'Taft',
    'Talbott',
    'Tazewell',
    'Telford',
    'Tellico Plains',
    'Ten Mile',
    'Tennessee Ridge',
    "Thompson's Station",
    'Thorn Hill',
    'Tiptonville',
    'Toone',
    'Tracy City',
    'Tullahoma',
    'Turtletown',
    'Unicoi',
    'Vanleer',
    'Vonore',
    'Wartrace',
    'Wartburg',
    'Watauga',
    'Watertown',
    'White Bluff',
    'White House',
    'White Pine',
    'Whites Creek',
    'Whitwell',
    'Wildersville',
    'Yuma',
    'Abbott',
    'Adkins',
    'Alamo Heights',
    'Aledo',
    'Alice',
    'Alief',
    'Alleyton',
    'Altair',
    'Alvarado',
    'Alvin',
    'Alvord',
    'Anahuac',
    'Angleton',
    'Anson',
    'Aquilla',
    'Aransas Pass',
    'Archer City',
    'Arp',
    'Arthur City',
    'Atascosa',
    'Aubrey',
    'Avery',
    'Avinger',
    'Azle',
    'Bacliff',
    'Baird',
    'Balch Springs',
    'Ballinger',
    'Bandera',
    'Bangs',
    'Barker',
    'Bartlett',
    'Batson',
    'Baytown',
    'Beckville',
    'Bedias',
    'Beeville',
    'Bellaire',
    'Bellmead',
    'Bellville',
    'Ben Wheeler',
    'Benavides',
    'Bertram',
    'Big Wells',
    'Bigfoot',
    'Blanco',
    'Bloomburg',
    'Blooming Grove',
    'Blossom',
    'Bluff Dale',
    'Blum',
    'Boerne',
    'Bogata',
    'Boling',
    'Bonham',
    'Boyd',
    'Brady',
    'Brazoria',
    'Bremond',
    'Brenham',
    'Bridge City',
    'Broaddus',
    'Brookeland',
    'Brookshire',
    'Bruceville-Eddy',
    'Brownwood',
    'Bruni',
    'Bryan',
    'Bryson',
    'Buchanan Dam',
    'Buckholts',
    'Buffalo Gap',
    'Bullard',
    'Bulverde',
    'Buna',
    'Burkburnett',
    'Burkeville',
    'Burleson',
    'Burnet',
    'Burton',
    'Byers',
    'Caddo Mills',
    'Calliham',
    'Canyon Lake',
    'Carbon',
    'Carmine',
    'Carrizo Springs',
    'Castroville',
    'Cat Spring',
    'Cedar Creek',
    'Cedar Park',
    'Celeste',
    'Channelview',
    'Chappell Hill',
    'Chico',
    'Chilton',
    'China',
    'Chireno',
    'Cibolo',
    'Cleburne',
    'Clute',
    'Coldspring',
    'Colleyville',
    'Colmesneil',
    'Combes',
    'Comfort',
    'Concepcion',
    'Conroe',
    'Cookville',
    'Cooper',
    'Coppell',
    'Copperas Cove',
    'Corpus Christi',
    'Corrigan',
    'Corsicana',
    'Cotulla',
    'Coupland',
    'Cranfills Gap',
    'Crowell',
    'Cuero',
    'Cumby',
    "D'Hanis",
    'Daingerfield',
    'Daisetta',
    'Damon',
    'De Berry',
    'De Leon',
    'DeSoto',
    'Deer Park',
    'Del Valle',
    'Delmita',
    'Denison',
    'Deport',
    'Desdemona',
    'Detroit',
    'Devers',
    'Diana',
    'Diboll',
    'Dickinson',
    'Dime Box',
    'Dodd City',
    'Dodge',
    'Donie',
    'Donna',
    'Doss',
    'Douglassville',
    'Driftwood',
    'Dripping Springs',
    'Eagle Lake',
    'Early',
    'East Bernard',
    'Eastland',
    'Edcouch',
    'El Campo',
    'Electra',
    'Elkhart',
    'Ellinger',
    'Elm Mott',
    'Elmendorf',
    'Elsa',
    'Emory',
    'Encinal',
    'Encino',
    'Ennis',
    'Etoile',
    'Euless',
    'Eustace',
    'Evadale',
    'Evant',
    'Falfurrias',
    'Falls City',
    'Farmers Branch',
    'Fentress',
    'Ferris',
    'Flatonia',
    'Flint',
    'Floresville',
    'Flower Mound',
    'Forney',
    'Fort Worth',
    'Frankston',
    'Fred',
    'Freer',
    'Fresno',
    'Friendswood',
    'Frost',
    'Fruitvale',
    'Fulshear',
    'Galena Park',
    'Galveston',
    'Ganado',
    'Garwood',
    'Gause',
    'George West',
    'Giddings',
    'Gilmer',
    'Gladewater',
    'Glen Rose',
    'Godley',
    'Goldthwaite',
    'Goliad',
    'Goodrich',
    'Gordonville',
    'Goree',
    'Gorman',
    'Gouldbusk',
    'Graford',
    'Granbury',
    'Grand Prairie',
    'Grand Saline',
    'Granger',
    'Grapeland',
    'Grapevine',
    'Groesbeck',
    'Groves',
    'Groveton',
    'Gunter',
    'Gustine',
    'Hallettsville',
    'Haltom City',
    'Hamshire',
    'Hankamer',
    'Hargill',
    'Harker Heights',
    'Harleton',
    'Harlingen',
    'Harrold',
    'Haslet',
    'Hawkins',
    'Hawley',
    'Hearne',
    'Hebbronville',
    'Helotes',
    'Hemphill',
    'Hempstead',
    'Henrietta',
    'Hewitt',
    'Hico',
    'Hockley',
    'Hondo',
    'Honey Grove',
    'Hooks',
    'Horseshoe Bay',
    'Hubbard',
    'Huffman',
    'Hughes Springs',
    'Humble',
    'Hungerford',
    'Huntington',
    'Hutchins',
    'Hutto',
    'Industry',
    'Ingleside',
    'Ingram',
    'Iowa Park',
    'Italy',
    'Itasca',
    'Jarrell',
    'Jermyn',
    'Joaquin',
    'Joshua',
    'Jourdanton',
    'Justin',
    'Karnack',
    'Karnes City',
    'Kaufman',
    'Katy',
    'Keene',
    'Keller',
    'Kemah',
    'Kemp',
    'Kenedy',
    'Kennedale',
    'Kempner',
    'Kerens',
    'Kerrville',
    'Kilgore',
    'Killeen',
    'Kingsbury',
    'Knippa',
    'Knox City',
    'Kopperl',
    'Kosse',
    'Kountze',
    'Krum',
    'Kyle',
    'La Coste',
    'La Feria',
    'La Joya',
    'La Marque',
    'La Porte',
    'La Vernia',
    'La Ward',
    'LaRue',
    'Ladonia',
    'Lago Vista',
    'Laguna Vista',
    'Lake Creek',
    'Lake Dallas',
    'Lake Jackson',
    'Lakeway',
    'Lampasas',
    'Laneville',
    'Laredo',
    'Lavon',
    'League City',
    'Leakey',
    'Leander',
    'Leon Valley',
    'Leona',
    'Liberty Hill',
    'Lingleville',
    'Lipan',
    'Little Elm',
    'Liverpool',
    'Llano',
    'Lockhart',
    'Lolita',
    'Lometa',
    'Lone Oak',
    'Longview',
    'Lorena',
    'Los Fresnos',
    'Lott',
    'Lovelady',
    'Loving',
    'Lufkin',
    'Lyford',
    'Lytle',
    'Mabank',
    'Malakoff',
    'Manchaca',
    'Manor',
    'Manvel',
    'Marble Falls',
    'Markham',
    'Marlin',
    'Marquez',
    'Mart',
    'Mathis',
    'Mauriceville',
    'Maxwell',
    'Maypearl',
    'McAllen',
    'McDade',
    'McGregor',
    'McKinney',
    'McQueeney',
    'Melissa',
    'Menard',
    'Mercedes',
    'Merit',
    'Mesquite',
    'Mexia',
    'Meyersville',
    'Mico',
    'Midlothian',
    'Milam',
    'Milano',
    'Millersview',
    'Millsap',
    'Mineola',
    'Mineral Wells',
    'Mont Belvieu',
    'Montague',
    'Moran',
    'Mount Calm',
    'Mount Enterprise',
    'Muenster',
    'Muldoon',
    'Mullin',
    'Munday',
    'Murchison',
    'Nacogdoches',
    'Navasota',
    'Neches',
    'Nederland',
    'Needville',
    'Nemo',
    'New Braunfels',
    'New Caney',
    'New Ulm',
    'New Waverly',
    'Nixon',
    'Nocona',
    'Nolanville',
    'Nome',
    'Nordheim',
    'Normangee',
    'North Houston',
    'North Richland Hills',
    'Novice',
    'Oakhurst',
    'Odem',
    'Oglesby',
    'Olmito',
    'Onalaska',
    'Orange',
    'Orange Grove',
    'Ore City',
    'Overton',
    'Paige',
    'Palacios',
    'Panola',
    'Paradise',
    'Pearland',
    'Pearsall',
    'Peaster',
    'Pecan Gap',
    'Penitas',
    'Perrin',
    'Petrolia',
    'Pettus',
    'Pflugerville',
    'Pharr',
    'Pickton',
    'Pilot Point',
    'Pipe Creek',
    'Plano',
    'Point Comfort',
    'Pointblank',
    'Point',
    'Ponder',
    'Poolville',
    'Port Aransas',
    'Port Arthur',
    'Port Bolivar',
    'Port Isabel',
    'Port Neches',
    'Poteet',
    'Poth',
    'Pottsboro',
    'Prairie Lea',
    'Premont',
    'Progreso',
    'Prosper',
    'Quanah',
    'Queen City',
    'Quinlan',
    'Rainbow',
    'Raymondville',
    'Realitos',
    'Redwater',
    'Refugio',
    'Rhome',
    'Rice',
    'Richardson',
    'Richland Hills',
    'Richland Springs',
    'Riesel',
    'Rio Grande City',
    'Rio Hondo',
    'Rio Vista',
    'Rising Star',
    'Riviera',
    'Robstown',
    'Rochelle',
    'Rockdale',
    'Rockwall',
    'Romayor',
    'Rosenberg',
    'Rosharon',
    'Round Mountain',
    'Round Rock',
    'Round Top',
    'Rowlett',
    'Roxton',
    'Royse City',
    'Rule',
    'Runaway Bay',
    'Runge',
    'Rusk',
    'Rye',
    'Sabinal',
    'Sachse',
    'Sadler',
    'Saint Hedwig',
    'Saint Jo',
    'Salado',
    'San Augustine',
    'San Benito',
    'San Diego',
    'San Isidro',
    'San Juan',
    'San Marcos',
    'San Saba',
    'Sandia',
    'Sanger',
    'Santa Anna',
    'Santo',
    'Savoy',
    'Schertz',
    'Schulenburg',
    'Scroggins',
    'Scurry',
    'Seadrift',
    'Seagoville',
    'Sealy',
    'Seguin',
    'Shepherd',
    'Shiner',
    'Silsbee',
    'Simms',
    'Simonton',
    'Sinton',
    'Sour Lake',
    'South Houston',
    'South Padre Island',
    'Southlake',
    'Spicewood',
    'Splendora',
    'Spring',
    'Spring Branch',
    'Springtown',
    'Spurger',
    'Stamford',
    'Stephenville',
    'Stockdale',
    'Stowell',
    'Strawn',
    'Streetman',
    'Sugar Land',
    'Sullivan City',
    'Sulphur Bluff',
    'Sunnyvale',
    'Sweeny',
    'Talco',
    'Talpa',
    'Tarpley',
    'Tatum',
    'Teague',
    'Telephone',
    'Tenaha',
    'Tennessee Colony',
    'Texas City',
    'The Colony',
    'The Woodlands',
    'Thicket',
    'Thornton',
    'Thrall',
    'Three Rivers',
    'Throckmorton',
    'Timpson',
    'Tioga',
    'Tivoli',
    'Tolar',
    'Tom Bean',
    'Tomball',
    'Tow',
    'Trinidad',
    'Troup',
    'Tuleta',
    'Tye',
    'Universal City',
    'Utopia',
    'Uvalde',
    'Valera',
    'Valley Mills',
    'Valley View',
    'Van',
    'Van Alstyne',
    'Van Vleck',
    'Venus',
    'Vidor',
    'Village Mills',
    'Von Ormy',
    'Voss',
    'Votaw',
    'Waelder',
    'Waller',
    'Wallisville',
    'Walnut Springs',
    'Waskom',
    'Waxahachie',
    'Weimar',
    'Weinert',
    'Weslaco',
    'Wharton',
    'Wheelock',
    'Whitehouse',
    'Whitewright',
    'Whitney',
    'Wichita Falls',
    'Willis',
    'Wills Point',
    'Wimberley',
    'Windom',
    'Windthorst',
    'Winnie',
    'Winters',
    'Wolfe City',
    'Woodlake',
    'Woodway',
    'Wortham',
    'Wylie',
    'Yantis',
    'Yoakum',
    'Zapata',
    'Zavalla',
    'Zephyr',
    'Accomac',
    'Advance Mills',
    'Alberta',
    'Aldie',
    'Altavista',
    'Amelia Court House',
    'Amherst',
    'Amissville',
    'Annandale',
    'Appalachia',
    'Appomattox',
    'Ark',
    'Aroda',
    'Austinville',
    'Axton',
    'Aylett',
    'Bandy',
    'Barboursville',
    'Barhamsville',
    'Barren Springs',
    'Bassett',
    'Basye',
    'Bealeton',
    'Beaverdam',
    'Big Island',
    'Big Stone Gap',
    'Birchleaf',
    'Blackstone',
    'Blairs',
    'Blue Grass',
    'Bluefield',
    'Bluemont',
    'Boones Mill',
    'Boydton',
    'Bracey',
    'Breaks',
    'Bridgewater',
    'Brightwood',
    'Brodnax',
    'Brookneal',
    'Buckingham',
    'Buffalo Junction',
    'Bumpass',
    'Burgess',
    'Burke',
    'Callands',
    'Callaway',
    'Cana',
    'Cape Charles',
    'Carrsville',
    'Castleton',
    'Castlewood',
    'Catharpin',
    'Catlett',
    'Champlain',
    'Chantilly',
    'Charles City',
    'Charlotte Court House',
    'Charlottesville',
    'Chase City',
    'Chilhowie',
    'Chincoteague',
    'Christiansburg',
    'Clear Brook',
    'Clifton Forge',
    'Clinchco',
    'Clintwood',
    'Cobbs Creek',
    'Coeburn',
    'Coleman Falls',
    'Colonial Beach',
    'Colonial Heights',
    'Copper Hill',
    'Covesville',
    'Craddockville',
    'Crewe',
    'Crimora',
    'Cross Junction',
    'Crozier',
    'Crozet',
    'Crystal Hill',
    'Cullen',
    'Culpeper',
    'DeWitt',
    'Delaplane',
    'Deltaville',
    'Dendron',
    'Dillwyn',
    'Dinwiddie',
    'Disputanta',
    'Doswell',
    'Drakes Branch',
    'Dry Fork',
    'Duffield',
    'Dulles',
    'Dumfries',
    'Dunn Loring',
    'Dunnsville',
    'Dyke',
    'Earlysville',
    'Elk Creek',
    'Elkwood',
    'Elliston',
    'Esmont',
    'Evington',
    'Exmore',
    'Faber',
    'Fairfax Station',
    'Falls Church',
    'Fancy Gap',
    'Farnham',
    'Ferrum',
    'Fincastle',
    'Fishersville',
    'Flint Hill',
    'Floyd',
    'Fork Union',
    'Fort Blackmore',
    'Fort Defiance',
    'Franktown',
    'Free Union',
    'Fries',
    'Front Royal',
    'Fulks Run',
    'Galax',
    'Gate City',
    'Glade Spring',
    'Gladehill',
    'Gladys',
    'Glen Allen',
    'Gloucester Point',
    'Goldvein',
    'Goochland',
    'Goode',
    'Green Bay',
    'Greenbackville',
    'Greenbush',
    'Grimstead',
    'Grottoes',
    'Grundy',
    'Gwynn',
    'Hallieford',
    'Hampden Sydney',
    'Harrisonburg',
    'Hartfield',
    'Hayes',
    'Haymarket',
    'Haysi',
    'Heathsville',
    'Hillsville',
    'Hiwassee',
    'Honaker',
    'Howardsville',
    'Huddleston',
    'Hudgins',
    'Hurt',
    'Hustle',
    'Iron Gate',
    'Ivy',
    'Jarratt',
    'Jeffersonton',
    'Jetersville',
    'Jewell Ridge',
    'Kenbridge',
    'Kents Store',
    'Keswick',
    'Keysville',
    'Kilmarnock',
    'King George',
    'King William',
    'King and Queen Court House',
    'Kinsale',
    'Lanexa',
    'Laurel Fork',
    'Lignum',
    'Linville',
    'Little Washington',
    'Locust Dale',
    'Locust Hill',
    'Long Island',
    'Lorton',
    'Lovettsville',
    'Lovingston',
    'Lynch Station',
    'Lyndhurst',
    'Machipongo',
    'Madison Heights',
    'Maidens',
    'Manakin',
    'Manassas',
    'Manassas Park',
    'Mathews',
    'Maurertown',
    'Max Meadows',
    'McKenney',
    'McLean',
    'Meadows of Dan',
    'Meadowview',
    'Meherrin',
    'Melfa',
    'Merrifield',
    'Mineral',
    'Mitchells',
    'Modest Town',
    'Mollusk',
    'Moneta',
    'Montpelier',
    'Montross',
    'Montvale',
    'Moseley',
    'Mount Crawford',
    'Mount Jackson',
    'Mount Sidney',
    'Mouth of Wilson',
    'Narrows',
    'Nathalie',
    'Natural Bridge',
    'Natural Bridge Station',
    'Nellysford',
    'Newport News',
    'Nickelsville',
    'Nokesville',
    'Norfolk',
    'North Garden',
    'North Tazewell',
    'Nottoway Court House',
    'Oak Hall',
    'Oakpark',
    'Oakton',
    'Occoquan',
    'Onancock',
    'Onley',
    'Onemo',
    'Painter',
    'Pamplin',
    'Parksley',
    'Partlow',
    'Patrick Springs',
    'Pearisburg',
    'Penhook',
    'Penn Laird',
    'Pennington Gap',
    'Pilot',
    'Poquoson',
    'Potomac Farms',
    'Pound',
    'Pounding Mill',
    'Prince George',
    'Providence Forge',
    'Purcellville',
    'Quinby',
    'Radford',
    'Raphine',
    'Rapidan',
    'Rappahannock Academy',
    'Raven',
    'Reedville',
    'Remington',
    'Reston',
    'Reva',
    'Rhoadesville',
    'Rich Creek',
    'Riner',
    'Ripplemead',
    'Rixeyville',
    'Rocky Gap',
    'Round Hill',
    'Ruckersville',
    'Rural Retreat',
    'Rustburg',
    'Ruther Glen',
    'Saltville',
    'Sandston',
    'Saxe',
    'Schuyler',
    'Sedley',
    'Shacklefords',
    'Shenandoah',
    'Short Pump',
    'Skippers',
    'South Boston',
    'South Hill',
    'Spotsylvania',
    'Stanardsville',
    'Stanleytown',
    'Stephens City',
    'Stephenson',
    'Stevensburg',
    'Stuarts Draft',
    'Suffolk',
    'Sumerduck',
    'Surry',
    'Susan',
    'Sutherland',
    'Sweet Briar Station',
    'Tangier',
    'Tappahannock',
    'Temperanceville',
    'Thaxton',
    'Timberville',
    'Toano',
    'Toms Brook',
    'Triangle',
    'Trout Dale',
    'Troutville',
    'Tysons Corner',
    'Union Hall',
    'Upperville',
    'Urbanna',
    'Vansant',
    'Vesuvius',
    'Virgilina',
    'Virginia Beach',
    'Wachapreague',
    'Washington Corner',
    'Weber City',
    'Weems',
    'West Augusta',
    'Weyers Cave',
    'White Post',
    'White Stone',
    'Wirtz',
    'Wolftown',
    'Woodbridge',
    'Woodford',
    'Woolwine',
    'Wytheville',
    'Accoville',
    'Albright',
    'Alkol',
    'Alum Bridge',
    'Alum Creek',
    'Amherstdale',
    'Apple Grove',
    'Arbovale',
    'Arnoldsburg',
    'Ashford',
    'Baisden',
    'Bancroft',
    'Barrackville',
    'Beckley',
    'Belington',
    'Berkeley Springs',
    'Bickmore',
    'Big Springs',
    'Bigbend',
    'Birch River',
    'Bolt',
    'Bowden',
    'Branchland',
    'Brenton',
    'Brohard',
    'Bruceton Mills',
    'Buckhannon',
    'Cabins',
    'Capon Bridge',
    'Cass',
    'Chapmanville',
    'Charles Town',
    'Charlton Heights',
    'Clendenin',
    'Cowen',
    'Coxs Mills',
    'Craigsville',
    'Crawley',
    'Crum',
    'Culloden',
    'Cyclone',
    'Dailey',
    'Daniels',
    'Davin',
    'Delbarton',
    'Dingess',
    'Duck',
    'Dunmore',
    'Durbin',
    'East Bank',
    'Eccles',
    'Eleanor',
    'Elk Garden',
    'Elkview',
    'Ellamore',
    'Falling Spring',
    'Falling Waters',
    'Fort Ashby',
    'Fort Gay',
    'Fraziers Bottom',
    'French Creek',
    'Friendly',
    'Gallipolis Ferry',
    'Gassaway',
    'Gerrardstown',
    'Glen Daniel',
    'Glen Easton',
    'Grafton',
    'Grant Town',
    'Grassy Meadows',
    'Great Cacapon',
    'Green Bank',
    'Griffithsville',
    'Halltown',
    'Hamlin',
    'Harman',
    'Harpers Ferry',
    'Harts',
    'Heaters',
    'Hedgesville',
    'Hendricks',
    'Hewett',
    'Hugheston',
    'Hurricane',
    'Huttonsville',
    'Iaeger',
    'Institute',
    'Inwood',
    'Ireland',
    'Indore',
    'Ivydale',
    'Jacksonburg',
    'Jane Lew',
    'Jeffrey',
    'Jumping Branch',
    'Kanawha Head',
    'Kearneysville',
    'Kenna',
    'Kenova',
    'Kermit',
    'Keyser',
    'Kingwood',
    'Lavalette',
    'Layland',
    'Lenore',
    'Lesage',
    'Lerona',
    'Letart',
    'Lindside',
    'Lizemores',
    'Lynco',
    'Maidsville',
    'Man',
    'Mannington',
    'Marlinton',
    'Matewan',
    'Mathias',
    'Matoaka',
    'Meadow Bridge',
    'Middlebourne',
    'Moatsville',
    'Moorefield',
    'Moundsville',
    'Mount Gay',
    'Mount Lookout',
    'Mount Nebo',
    'Mount Zion',
    'Mullens',
    'Naoma',
    'Naugatuck',
    'Nellis',
    'Nettie',
    'New Martinsville',
    'Nimitz',
    'Nitro',
    'Normantown',
    'Orma',
    'Osbornes Mills',
    'Paden City',
    'Panther',
    'Parkersburg',
    'Paw Paw',
    'Pecks Mill',
    'Pennsboro',
    'Peterstown',
    'Petroleum',
    'Peytona',
    'Philippi',
    'Poca',
    'Point Pleasant',
    'Points',
    'Powellton',
    'Prichard',
    'Proctor',
    'Procious',
    'Rainelle',
    'Ranson',
    'Ravenswood',
    'Raysal',
    'Red House',
    'Reedy',
    'Reynoldsville',
    'Rhodell',
    'Ridgeley',
    'Ridgeview',
    'Rio',
    'Rivesville',
    'Roderfield',
    'Romney',
    'Ronceverte',
    'Rowlesburg',
    'Rupert',
    'Saint Albans',
    'Salt Rock',
    'Saulsville',
    'Scarbro',
    'Scott Depot',
    'Seneca Rocks',
    'Shady Spring',
    'Shanks',
    'Sharples',
    'Shepherdstown',
    'Shinnston',
    'Sinks Grove',
    'Sistersville',
    'Slaty Fork',
    'Smoot',
    'Sod',
    'Spanishburg',
    'Spelter',
    'Spurlockville',
    'Stollings',
    'Summit Point',
    'Surveyor',
    'Sutton',
    'Terra Alta',
    'Tunnelton',
    'Uneeda',
    'Tornado',
    'Upper Tract',
    'Verdunville',
    'Wallback',
    'Wana',
    'War',
    'Wardensville',
    'Webster Springs',
    'Wellsley',
    'West Hamlin',
    'White Sulphur Springs',
    'Yawkey',
    'Ariton',
    'Elba',
    'Elmore',
    'Sherwood',
    'Rosie',
    'Libuse',
    'Little Orleans',
    'Warwick',
    'Chadwick',
    'Prairie',
    'Shaw',
    'Merchantville',
    'Coalgate',
    'Nashoba',
    'Adger',
    'Shady Valley',
    'Sugar Tree',
    'Dilley',
    'Devine',
    'Hunt',
    'Wallis',
    'Bruno',
    'New Kent',
    'Millboro',
    'New Church',
    'Cresson',
    'Bay City',
    'Wise',
    'Peach Creek',
    'Alabaster',
    'Albertville',
    'Alexander City',
    'Aliceville',
    'Alpine',
    'Andalusia',
    'Anniston',
    'Arab',
    'Supply',
    'Arley',
    'Atmore',
    'Attalla',
    'Autaugaville',
    'Axis',
    'Baileyton',
    'Haddam',
    'Fort Atkinson',
    'Gladbrook',
    'Cornwall Bridge',
    'Montour',
    'New Liberty',
    'Victor',
    'Cos Cob',
    'Coventry',
    'Glendale Heights',
    'Neponset',
    'Cromwell',
    'East Falmouth',
    'North Andover',
    'North Quincy',
    'Revere',
    'West Barnstable',
    'Wareham',
    'Alfred',
    'Danielson',
    'Rockland',
    'Champion',
    'Inkster',
    'Dayville',
    'Deep River',
    'Edina',
    'Soudan',
    'Saint Edward',
    'Camillus',
    'Pennellville',
    'Remsen',
    'Slate Hill',
    'Watervliet',
    'East Canaan',
    'Cleveland Heights',
    'East Granby',
    'Glenmont',
    'Genoa',
    'East Haddam',
    'East Hampton',
    'East Hartford',
    'East Hartland',
    'East Haven',
    'Eastford',
    'Irma',
    'Falls Village',
    'Flanders',
    'Gales Ferry',
    'Gilman',
    'Glastonbury',
    'Groton',
    'Harwinton',
    'Higganum',
    'Ivoryton',
    'Jewett City',
    'Kent',
    'Killingworth',
    'Lakeville',
    'Ledyard Center',
    'Mansfield Center',
    'Marlborough',
    'Middle Haddam',
    'Middlebury',
    'Middlefield',
    'Milldale',
    'Moodus',
    'Moosup',
    'Mystic',
    'New Britain',
    'New Canaan',
    'New Fairfield',
    'New Hartford',
    'New Milford',
    'New Preston',
    'North Branford',
    'North Franklin',
    'North Granby',
    'North Grosvenor Dale',
    'North Haven',
    'North Stonington',
    'North Windham',
    'Northford',
    'Norwalk',
    'Old Greenwich',
    'Old Lyme',
    'Old Saybrook',
    'Oneco',
    'Pawcatuck',
    'Plantsville',
    'Pomfret Center',
    'Preston City',
    'Putnam',
    'Quaker Hill',
    'Redding',
    'Ridgefield',
    'Rocky Hill',
    'Shelton',
    'Simsbury',
    'Somers',
    'South Glastonbury',
    'South Windham',
    'South Windsor',
    'Southbury',
    'Southington',
    'Stonington',
    'Storrs',
    'Suffield',
    'Taftville',
    'Terryville',
    'Thompson',
    'Tolland',
    'Torrington',
    'Trumbull',
    'Uncasville',
    'Voluntown',
    'Waterbury',
    'Weatogue',
    'West Hartford',
    'West Haven',
    'West Simsbury',
    'West Suffield',
    'Westbrook',
    'Wethersfield',
    'Willimantic',
    'Willington Hill',
    'Wilton',
    'Windham',
    'Windsor Locks',
    'Winsted',
    'Wolcott',
    'Ackley',
    'Ainsworth',
    'Albert City',
    'Albia',
    'Alburnett',
    'Alden',
    'Algona',
    'Allerton',
    'Alta',
    'Amana',
    'Ames',
    'Anamosa',
    'Ankeny',
    'Anthon',
    'Aplington',
    'Aredale',
    'Arnolds Park',
    'Atalissa',
    'Aurelia',
    'Badger',
    'Bagley',
    'Barnum',
    'Battle Creek',
    'Bayard',
    'Belmond',
    'Bernard',
    'Bettendorf',
    'Blairsburg',
    'Blairstown',
    'Blakesburg',
    'Blencoe',
    'Blockton',
    'Bode',
    'Bonaparte',
    'Bondurant',
    'Boxholm',
    'Boyden',
    'Braddyville',
    'Bradgate',
    'Brayton',
    'Breda',
    'Britt',
    'Brunsville',
    'Buffalo Center',
    'Burt',
    'Calamus',
    'Calmar',
    'Camanche',
    'Carpenter',
    'Carson',
    'Carter Lake',
    'Castana',
    'Cedar Falls',
    'Cedar Rapids',
    'Center Junction',
    'Chariton',
    'Charter Oak',
    'Churdan',
    'Clare',
    'Clarinda',
    'Clarion',
    'Clear Lake',
    'Cleghorn',
    'Clive',
    'Coggon',
    'Coin',
    'College Springs',
    'Colo',
    'Columbus City',
    'Columbus Junction',
    'Conesville',
    'Conrad',
    'Coon Rapids',
    'Coralville',
    'Correctionville',
    'Corwith',
    'Council Bluffs',
    'Cresco',
    'Crystal Lake',
    'Cylinder',
    'Dallas Center',
    'Davis City',
    'Decatur City',
    'Decorah',
    'Dedham',
    'Des Moines',
    'Diagonal',
    'Dickens',
    'Dike',
    'Donnellson',
    'Doon',
    'Dows',
    'Drakesville',
    'Dubuque',
    'Dumont',
    'Duncombe',
    'Dunkerton',
    'Dyersville',
    'Dysart',
    'Eagle Grove',
    'Earlham',
    'Earling',
    'Earlville',
    'Elberon',
    'Eldora',
    'Eldridge',
    'Elk Horn',
    'Elkader',
    'Elliott',
    'Ellston',
    'Elma',
    'Ely',
    'Emmetsburg',
    'Epworth',
    'Estherville',
    'Evansdale',
    'Everly',
    'Exira',
    'Fairbank',
    'Farley',
    'Farnhamville',
    'Farragut',
    'Fertile',
    'Floris',
    'Fonda',
    'Fort Dodge',
    'Fort Madison',
    'Garden Grove',
    'Garnavillo',
    'George',
    'Glidden',
    'Goldfield',
    'Gowrie',
    'Graettinger',
    'Grand Mound',
    'Grand River',
    'Greene',
    'Grimes',
    'Grinnell',
    'Griswold',
    'Grundy Center',
    'Guthrie Center',
    'Guttenberg',
    'Hanlontown',
    'Harcourt',
    'Hartley',
    'Hawarden',
    'Hawkeye',
    'Hazleton',
    'Hedrick',
    'Hills',
    'Holstein',
    'Holy Cross',
    'Hopkinton',
    'Hornick',
    'Hospers',
    'Houghton',
    'Humeston',
    'Huxley',
    'Ida Grove',
    'Imogene',
    'Iowa City',
    'Iowa Falls',
    'Ireton',
    'Janesville',
    'Joice',
    'Jolley',
    'Kalona',
    'Kamrar',
    'Kanawha',
    'Kellerton',
    'Kellogg',
    'Kensett',
    'Keokuk',
    'Keosauqua',
    'Keystone',
    'Kimballton',
    'Kingsley',
    'La Motte',
    'La Porte City',
    'Lacona',
    'Lake Mills',
    'Lake View',
    'Lakota',
    'Lamoni',
    'Lamont',
    'Larchwood',
    'Latimer',
    'Lawler',
    'Le Claire',
    'Le Mars',
    'Ledyard',
    'Libertyville',
    'Lime Springs',
    'Linn Grove',
    'Liscomb',
    'Lockridge',
    'Lohrville',
    'Lone Rock',
    'Lone Tree',
    'Long Grove',
    'Lorimor',
    'Lost Nation',
    'Lowden',
    'Lu Verne',
    'Luana',
    'Luzerne',
    'Lytton',
    'Madrid',
    'Malcom',
    'Mallard',
    'Manly',
    'Manson',
    'Mapleton',
    'Maquoketa',
    'Marble Rock',
    'Marcus',
    'Marne',
    'Marshalltown',
    'Martelle',
    'Martensdale',
    'Mason City',
    'Masonville',
    'Massena',
    'McCallsburg',
    'McIntire',
    'Mediapolis',
    'Melcher-Dallas',
    'Merrill',
    'Meservey',
    'Miles',
    'Milo',
    'Minburn',
    'Missouri Valley',
    'Mitchellville',
    'Modale',
    'Monmouth',
    'Monona',
    'Moorland',
    'Moravia',
    'Morning Sun',
    'Mount Auburn',
    'Mount Ayr',
    'Moville',
    'Muscatine',
    'Nashua',
    'Nemaha',
    'Neola',
    'New Albin',
    'New Hampton',
    'New Sharon',
    'New Virginia',
    'Newell',
    'Nora Springs',
    'North English',
    'North Liberty',
    'Northwood',
    'Northboro',
    'Ocheyedan',
    'Odebolt',
    'Oelwein',
    'Okoboji',
    'Onawa',
    'Onslow',
    'Ossian',
    'Otho',
    'Otley',
    'Ottumwa',
    'Oxford Junction',
    'Pacific Junction',
    'Packwood',
    'Palo',
    'Panora',
    'Parnell',
    'Paton',
    'Paullina',
    'Pella',
    'Peosta',
    'Persia',
    'Peterson',
    'Pilot Mound',
    'Postville',
    'Prairie City',
    'Prairieburg',
    'Primghar',
    'Prole',
    'Promise City',
    'Quasqueton',
    'Radcliffe',
    'Randalia',
    'Readlyn',
    'Reasnor',
    'Reinbeck',
    'Rhodes',
    'Riceville',
    'Ringsted',
    'Robins',
    'Rock Rapids',
    'Rock Valley',
    'Rockwell City',
    'Rolfe',
    'Rowley',
    'Rudd',
    'Runnells',
    'Ruthven',
    'Ryan',
    'Sabula',
    'Sac City',
    'Saint Ansgar',
    'Saint Lucas',
    'Saint Olaf',
    'Salix',
    'Sanborn',
    'Scarville',
    'Schaller',
    'Schleswig',
    'Searsboro',
    'Sergeant Bluff',
    'Shambaugh',
    'Shell Rock',
    'Shellsburg',
    'Sherrill',
    'Sigourney',
    'Sioux Center',
    'Sioux City',
    'Sioux Rapids',
    'Sloan',
    'Smithland',
    'Solon',
    'South English',
    'Spirit Lake',
    'Springbrook',
    'Stanhope',
    'Stanwood',
    'State Center',
    'Steamboat Rock',
    'Storm Lake',
    'Story City',
    'Stout',
    'Strawberry Point',
    'Sully',
    'Superior',
    'Swan',
    'Swea City',
    'Swisher',
    'Tabor',
    'Tama',
    'Templeton',
    'Terril',
    'Thor',
    'Tiffin',
    'Titonka',
    'Toddville',
    'Traer',
    'Treynor',
    'Tripoli',
    'Troy Mills',
    'Urbandale',
    'Ute',
    'Vail',
    'Van Horne',
    'Van Wert',
    'Villisca',
    'Walcott',
    'Walford',
    'Wall Lake',
    'Wapello',
    'Waucoma',
    'Waukee',
    'Waukon',
    'Webster City',
    'Weldon',
    'Wellman',
    'Wellsburg',
    'West Branch',
    'West Bend',
    'West Burlington',
    'West Des Moines',
    'Westgate',
    'Westside',
    'Wever',
    'What Cheer',
    'Whittemore',
    'Windsor Heights',
    'Winterset',
    'Winthrop',
    'Wiota',
    'Woden',
    'Wyoming',
    'Yarmouth',
    'Zearing',
    'Algonquin',
    'Alsip',
    'Amboy',
    'Annawan',
    'Apple River',
    'Arlington Heights',
    'Armington',
    'Aroma Park',
    'Ashkum',
    'Astoria',
    'Atkinson',
    'Bardolph',
    'Barrington Hills',
    'Beardstown',
    'Beason',
    'Beecher',
    'Bellflower',
    'Bellwood',
    'Bensenville',
    'Berkeley',
    'Berwyn',
    'Biggsville',
    'Blandinsville',
    'Blue Island',
    'Bolingbrook',
    'Bonfield',
    'Bourbonnais',
    'Bowen',
    'Braceville',
    'Braidwood',
    'Bridgeview',
    'Brimfield',
    'Broadview',
    'Browning',
    'Buckley',
    'Buffalo Grove',
    'Burbank',
    'Cabery',
    'Calumet City',
    'Camp Point',
    'Capron',
    'Carbon Cliff',
    'Carlock',
    'Carol Stream',
    'Carpentersville',
    'Catlin',
    'Champaign',
    'Chana',
    'Chandlerville',
    'Channahon',
    'Chebanse',
    'Chenoa',
    'Cherry',
    'Chestnut',
    'Chicago',
    'Chicago Heights',
    'Chicago Ridge',
    'Cicero',
    'Cissna Park',
    'Clarendon Hills',
    'Coal Valley',
    'Colchester',
    'Colona',
    'Compton',
    'Congerville',
    'Cornell',
    'Cortland',
    'Country Club Hills',
    'Crest Hill',
    'Crete',
    'Creve Coeur',
    'Cullom',
    'Dahinda',
    'Dakota',
    'Dallas City',
    'Danforth',
    'Danvers',
    'Davis Junction',
    'DeKalb',
    'Deer Creek',
    'Delavan',
    'Depue',
    'Des Plaines',
    'Dolton',
    'Donovan',
    'Downers Grove',
    'Dunfermline',
    'Durand',
    'East Dubuque',
    'East Moline',
    'East Peoria',
    'Edelstein',
    'El Paso',
    'Elburn',
    'Elk Grove Village',
    'Elmhurst',
    'Elmwood Park',
    'Emden',
    'Evergreen Park',
    'Fairbury',
    'Farmer City',
    'Fisher',
    'Fithian',
    'Flanagan',
    'Flossmoor',
    'Forrest',
    'Forreston',
    'Fowler',
    'Fox Lake',
    'Fox River Grove',
    'Franklin Grove',
    'Franklin Park',
    'Garden Prairie',
    'German Valley',
    'Gibson City',
    'Gifford',
    'Gilberts',
    'Glasford',
    'Glen Ellyn',
    'Glenview',
    'Golf',
    'Goodfield',
    'Grant Park',
    'Grayslake',
    'Great Lakes',
    'Green Valley',
    'Greenview',
    'Gridley',
    'Gurnee',
    'Hainesville',
    'Hanna City',
    'Hanover Park',
    'Harvard',
    'Harwood Heights',
    'Hazel Crest',
    'Hennepin',
    'Herscher',
    'Heyworth',
    'Hickory Hills',
    'Highland Park',
    'Highwood',
    'Hillside',
    'Hinckley',
    'Hines',
    'Hinsdale',
    'Hoffman Estates',
    'Homer Glen',
    'Homewood',
    'Hoopeston',
    'Hopedale',
    'Huntley',
    'Illinois City',
    'Ipava',
    'Island Lake',
    'Joliet',
    'Justice',
    'Kankakee',
    'Kenilworth',
    'Kenney',
    'Kewanee',
    'Kilbourne',
    'Kirkland',
    'La Fox',
    'La Grange Park',
    'La Harpe',
    'La Moille',
    'La Rose',
    'La Salle',
    'Lacon',
    'Ladd',
    'Lake Bluff',
    'Lake Forest',
    'Lake Villa',
    'Lake Zurich',
    'Lake in the Hills',
    'Lanark',
    'Le Roy',
    'Leaf River',
    'Lemont',
    'Leonore',
    'Lewistown',
    'Lincolnshire',
    'Lincolnwood',
    'Lindenwood',
    'Lisle',
    'Little York',
    'Loda',
    'Lombard',
    'Long Point',
    'Loraine',
    'Lostant',
    'Loves Park',
    'Ludlow',
    'Lynn Center',
    'Machesney Park',
    'Mackinaw',
    'Mahomet',
    'Manito',
    'Manteno',
    'Maple Park',
    'Maquon',
    'Mark',
    'Maroa',
    'Marseilles',
    'Martinton',
    'Matherville',
    'Matteson',
    'Mazon',
    'McNabb',
    'Medinah',
    'Melrose Park',
    'Mendon',
    'Mendota',
    'Metamora',
    'Minier',
    'Minonk',
    'Minooka',
    'Mokena',
    'Moline',
    'Momence',
    'Monee',
    'Monroe Center',
    'Mooseheart',
    'Morton Grove',
    'Mount Carroll',
    'Mount Prospect',
    'Mount Pulaski',
    'Mundelein',
    'Naperville',
    'New Lenox',
    'Niles',
    'Norridge',
    'North Aurora',
    'North Chicago',
    'Northbrook',
    'Oak Brook',
    'Oak Forest',
    'Oak Lawn',
    'Oak Park',
    'Oakbrook Terrace',
    'Oakford',
    'Odell',
    'Ohio',
    'Olympia Fields',
    'Onarga',
    'Ophiem',
    'Orangeville',
    'Orion',
    'Orland Park',
    'Palatine',
    'Palos Heights',
    'Palos Hills',
    'Palos Park',
    'Park Forest',
    'Park Ridge',
    'Paxton',
    'Pearl City',
    'Pecatonica',
    'Pekin',
    'Penfield',
    'Peoria',
    'Peoria Heights',
    'Peotone',
    'Piper City',
    'Pontiac',
    'Poplar Grove',
    'Port Byron',
    'Posen',
    'Princeville',
    'Prophetstown',
    'Prospect Heights',
    'Rankin',
    'Richton Park',
    'Ridott',
    'River Forest',
    'River Grove',
    'Roberts',
    'Rock City',
    'Rock Falls',
    'Rockton',
    'Rolling Meadows',
    'Romeoville',
    'Roscoe',
    'Roselle',
    'Rosemont',
    'Round Lake',
    'Saint Anne',
    'San Jose',
    'Sandwich',
    'Saybrook',
    'Scales Mound',
    'Schaumburg',
    'Schiller Park',
    'Seaton',
    'Seatonville',
    'Seward',
    'Shabbona',
    'Sherrard',
    'Shorewood',
    'Silvis',
    'Skokie',
    'Smithshire',
    'Somonauk',
    'South Beloit',
    'South Elgin',
    'South Holland',
    'South Pekin',
    'South Wilmington',
    'Sparland',
    'Standard',
    'Steger',
    'Steward',
    'Stillman Valley',
    'Stone Park',
    'Streamwood',
    'Streator',
    'Stronghurst',
    'Sublette',
    'Table Grove',
    'Tampico',
    'Taylor Ridge',
    'Tennessee',
    'Thomasboro',
    'Tilton',
    'Timewell',
    'Tinley Park',
    'Tiskilwa',
    'Toluca',
    'Tonica',
    'Toulon',
    'Trivoli',
    'Ursa',
    'Varna',
    'Vermont',
    'Vernon Hills',
    'Villa Park',
    'Wadsworth',
    'Wapella',
    'Wataga',
    'Waterman',
    'Watseka',
    'Wauconda',
    'Waukegan',
    'Wenona',
    'West Brooklyn',
    'West Chicago',
    'West Dundee',
    'Westchester',
    'Western Springs',
    'Westmont',
    'Wheaton',
    'White Heath',
    'Williamsfield',
    'Willowbrook',
    'Wilmette',
    'Winnebago',
    'Winnetka',
    'Winthrop Harbor',
    'Wonder Lake',
    'Wood Dale',
    'Woodridge',
    'Worth',
    'Wyanet',
    'Yates City',
    'Yorkville',
    'Zion',
    'Ambia',
    'Argos',
    'Avilla',
    'Battle Ground',
    'Berne',
    'Beverly Shores',
    'Bringhurst',
    'Brook',
    'Brookston',
    'Buck Creek',
    'Burnettsville',
    'Cedar Lake',
    'Chalmers',
    'Chesterton',
    'Churubusco',
    'Claypool',
    'Columbia City',
    'Corunna',
    'Craigville',
    'Crown Point',
    'Culver',
    'DeMotte',
    'Delphi',
    'Earl Park',
    'East Chicago',
    'Etna Green',
    'Farmland',
    'Fort Wayne',
    'Francesville',
    'Frankton',
    'Gary',
    'Gas City',
    'Goodland',
    'Goldsmith',
    'Grabill',
    'Greentown',
    'Griffith',
    'Grovertown',
    'Hanna',
    'Hartford City',
    'Hoagland',
    'Huntertown',
    'Kendallville',
    'Kentland',
    'Kewanna',
    'Kimmell',
    'Kingsford Heights',
    'Kirklin',
    'Kokomo',
    'Kouts',
    'Knox',
    'La Fontaine',
    'Lagrange',
    'Lagro',
    'Lake Station',
    'Laotto',
    'Lapel',
    'Larwill',
    'Leo-Cedarville',
    'Liberty Center',
    'Liberty Mills',
    'Ligonier',
    'Macy',
    'Markle',
    'Medaryville',
    'Merrillville',
    'Michigan City',
    'Michigantown',
    'Millersburg',
    'Mishawaka',
    'Mongo',
    'Monon',
    'Morocco',
    'Muncie',
    'Munster',
    'Nappanee',
    'Noblesville',
    'North Judson',
    'North Manchester',
    'North Webster',
    'Orland',
    'Otterbein',
    'Parker City',
    'Pennville',
    'Perrysville',
    'Pierceton',
    'Pine Village',
    'Pleasant Lake',
    'Poneto',
    'Portage',
    'Redkey',
    'Rensselaer',
    'Roann',
    'Rolling Prairie',
    'Rome City',
    'Royal Center',
    'Russiaville',
    'San Pierre',
    'Schererville',
    'Sharpsville',
    'Shipshewana',
    'South Bend',
    'South Whitley',
    'Springport',
    'Sumava Resorts',
    'Summitville',
    'Swayzee',
    'Sweetser',
    'Thorntown',
    'Tippecanoe',
    'Uniondale',
    'Upland',
    'Veedersburg',
    'Wabash',
    'Walkerton',
    'Wanatah',
    'Waynetown',
    'West Lebanon',
    'West Lafayette',
    'Westpoint',
    'Wheatfield',
    'Winamac',
    'Winona Lake',
    'Wolcottville',
    'Yoder',
    'Abington',
    'Acton',
    'Acushnet',
    'Agawam',
    'Allston',
    'Amesbury',
    'Ashburnham',
    'Ashby',
    'Ashfield',
    'Assonet',
    'Attleboro',
    'Attleboro Falls',
    'Ayer',
    'Baldwinville',
    'Barnstable',
    'Barre',
    'Becket',
    'Belchertown',
    'Bellingham',
    'Berkley',
    'Bernardston',
    'Billerica',
    'Blandford',
    'Bondsville',
    'Boxborough',
    'Boxford',
    'Boylston',
    'Brewster',
    'Brockton',
    'Buckland',
    'Buzzards Bay',
    'Byfield',
    'Carver',
    'Charlemont',
    'Charlton',
    'Chelmsford',
    'Cheshire',
    'Chestnut Hill',
    'Chicopee',
    'Chilmark',
    'Cohasset',
    'Colrain',
    'Cotuit',
    'Cummington',
    'Dennis Port',
    'Dighton',
    'Dracut',
    'Dunstable',
    'Duxbury',
    'East Bridgewater',
    'East Brookfield',
    'East Freetown',
    'East Longmeadow',
    'East Otis',
    'East Sandwich',
    'East Taunton',
    'East Walpole',
    'East Weymouth',
    'Eastham',
    'Easthampton',
    'Edgartown',
    'Erving',
    'Everett',
    'Fairhaven',
    'Feeding Hills',
    'Fitchburg',
    'Forestdale',
    'Foxborough',
    'Framingham',
    'Gilbertville',
    'Gill',
    'Great Barrington',
    'Hadley',
    'Hampden',
    'Harwich',
    'Harwich Port',
    'Haverhill',
    'Haydenville',
    'Head of Westport',
    'Hingham',
    'Holliston',
    'Holyoke',
    'Housatonic',
    'Hubbardston',
    'Hyannis',
    'Hyde Park',
    'Indian Orchard',
    'Ipswich',
    'Jamaica Plain',
    'Lake Pleasant',
    'Leominster',
    'Leverett',
    'Longmeadow',
    'Lunenburg',
    'Lynnfield',
    'Manchester-by-the-Sea',
    'Marblehead',
    'Marlboro',
    'Marstons Mills',
    'Mashpee',
    'Mattapan',
    'Mattapoisett',
    'Medfield',
    'Menemsha',
    'Merrimac',
    'Methuen',
    'Middleboro',
    'Millbury',
    'Millers Falls',
    'Millis',
    'Monson',
    'Monument Beach',
    'Nahant',
    'Nantucket',
    'Natick',
    'Needham',
    'Needham Heights',
    'New Bedford',
    'New Braintree',
    'Newbury Old Town',
    'Newburyport',
    'Newton Center',
    'Newton Highlands',
    'Newton Lower Falls',
    'Newton Upper Falls',
    'Newtonville',
    'North Adams',
    'North Attleboro',
    'North Billerica',
    'North Brookfield',
    'North Carver',
    'North Chatham',
    'North Chelmsford',
    'North Dartmouth',
    'North Dighton',
    'North Eastham',
    'North Easton',
    'North Falmouth',
    'North Grafton',
    'North Hatfield',
    'North Oxford',
    'North Reading',
    'North Truro',
    'North Weymouth',
    'Northampton',
    'Northborough',
    'Northbridge',
    'Norwell',
    'Nutting Lake',
    'Oak Bluffs',
    'Osterville',
    'Pepperell',
    'Petersham',
    'Plympton',
    'Provincetown',
    'Raynham',
    'Rehoboth',
    'Rochdale',
    'Roslindale',
    'Rowe',
    'Roxbury Crossing',
    'Royalston',
    'Russells Mills',
    'Sagamore',
    'Sagamore Beach',
    'Sandisfield',
    'Saugus',
    'Scituate',
    'Seekonk',
    'Shelburne Falls',
    'Sherborn',
    'Shutesbury',
    'South Carver',
    'South Chatham',
    'South Dartmouth',
    'South Deerfield',
    'South Dennis',
    'South Easton',
    'South Egremont',
    'South Hadley',
    'South Hamilton',
    'South Harwich',
    'South Lancaster',
    'South Orleans',
    'South Weymouth',
    'South Yarmouth',
    'Southborough',
    'Southbridge',
    'Southfield',
    'Southwick',
    'Stoneham',
    'Stoughton',
    'Stow',
    'Sturbridge',
    'Sudbury',
    'Swampscott',
    'Taunton',
    'Tewksbury',
    'Topsfield',
    'Truro',
    'Turners Falls',
    'Tyngsboro',
    'Tyringham',
    'Uxbridge',
    'Vineyard Haven',
    'Waban',
    'Wales',
    'Walpole',
    'Waltham',
    'Ware',
    'Wellesley',
    'Wellesley Hills',
    'Wellfleet',
    'Wenham',
    'West Boylston',
    'West Bridgewater',
    'West Brookfield',
    'West Dennis',
    'West Falmouth',
    'West Harwich',
    'West Hatfield',
    'West Newbury',
    'West Springfield',
    'West Stockbridge',
    'West Tisbury',
    'West Townsend',
    'West Roxbury',
    'West Wareham',
    'West Warren',
    'West Yarmouth',
    'Westborough',
    'Westford',
    'Westwood',
    'Weymouth',
    'Whitinsville',
    'Whitman',
    'Wilbraham',
    'Winchendon',
    'Woburn',
    'Woods Hole',
    'Worcester',
    'Wrentham',
    'Yarmouth Port',
    'Abbot Village',
    'Alna',
    'Arundel',
    'Atkinson Mills',
    'Bailey Island',
    'Bangor',
    'Bar Harbor',
    'Bass Harbor',
    'Benedicta',
    'Biddeford',
    'Bingham',
    'Blue Hill',
    'Boothbay',
    'Boothbay Harbor',
    'Bowdoin Center',
    'Bowdoinham',
    'Brewer',
    'Bridgton',
    'Brooklin',
    'Brookton',
    'Brownfield',
    'Brownville',
    'Bryant Pond',
    'Buckfield',
    'Bucksport',
    'Burnham',
    'Calais',
    'Canaan',
    'Cape Elizabeth',
    'Cape Neddick',
    'Caribou',
    'Casco',
    'Castine',
    'Center Lovell',
    'Chebeague Island',
    'Cherryfield',
    'Columbia Falls',
    'Corinna',
    'Cornish',
    'Cumberland Center',
    'Cumberland Foreside',
    'Damariscotta',
    'Deer Isle',
    'Dixfield',
    'Dixmont',
    'Dover-Foxcroft',
    'Dresden Mills',
    'East Boothbay',
    'East Machias',
    'East Millinocket',
    'East Waterboro',
    'Eastport',
    'Eddington',
    'Edgecomb',
    'Eliot',
    'Embden',
    'Exeter Corners',
    'Farmingdale',
    'Fort Fairfield',
    'Fort Kent',
    'Fryeburg',
    'Gardiner',
    'Gouldsboro',
    'Greenville Junction',
    'Hallowell',
    'Harborside',
    'Hollis Center',
    'Houlton',
    'Howland',
    'Hulls Cove',
    'Island Falls',
    'Islesboro',
    'Jackman',
    'Jonesport',
    'Kenduskeag',
    'Kennebunk',
    'Kennebunkport',
    'Kents Hill',
    'Kingfield',
    'Kittery Point',
    'Kittery',
    'Levant',
    'Lewiston',
    'Limerick',
    'Limington',
    'Lisbon Falls',
    'Little Deer Isle',
    'Livermore Falls',
    'Lovell',
    'Lubec',
    'Machias',
    'Machiasport',
    'Madawaska',
    'Mattawamkeag',
    'Mechanic Falls',
    'Milbridge',
    'Millinocket',
    'Minot',
    'New Gloucester',
    'New Harbor',
    'New Sweden',
    'New Vineyard',
    'Newry',
    'Nobleboro',
    'Norridgewock',
    'North Anson',
    'North Berwick',
    'North Bridgton',
    'North Monmouth',
    'Harpswell',
    'North Waterboro',
    'Northeast Harbor',
    'Ogunquit',
    'Old Orchard Beach',
    'Orono',
    'Orrington',
    'Orrs Island',
    'Owls Head',
    'Palermo',
    'Patten',
    'Peaks Island',
    'Pemaquid',
    'Penobscot',
    'Phippsburg',
    'Port Clyde',
    'Pownal Center',
    'Presque Isle',
    'Purgatory',
    'Rangeley',
    'Readfield',
    'Round Pond',
    'Rumford',
    'Sabattus',
    'Saco',
    'Saint Francis',
    'Sangerville',
    'Scarborough',
    'Seal Harbor',
    'Searsmont',
    'Searsport',
    'Sebago Center',
    'Sebasco Estates',
    'Shapleigh',
    'Skowhegan',
    'Somesville',
    'South Berwick',
    'South Casco',
    'South China',
    'South Paris',
    'South Portland',
    'South Thomaston',
    'South West Bend',
    'Southwest Harbor',
    'Springvale',
    'Stacyville',
    'Standish',
    'Steep Falls',
    'Stetson',
    'Steuben',
    'Stockton Springs',
    'Stratton',
    'Swans Island',
    'Tenants Harbor',
    'Thorndike',
    'Topsham',
    'Trevett',
    'Turner',
    'Unity',
    'Vassalboro',
    'Vinalhaven',
    'Waldoboro',
    'Wallagrass',
    'Walnut Hill',
    'Waterboro',
    'Waterford Flat',
    'Weld',
    'Wells',
    'West Corinth',
    'West Enfield',
    'West Forks',
    'West Newfield',
    'West Paris',
    'West Poland',
    'Windham Center',
    'Winterport',
    'Wiscasset',
    'Winter Harbor',
    'Woolwich',
    'Wytopitlock',
    'York Beach',
    'York Harbor',
    'York Village',
    'Acme',
    'Ahmeek',
    'Alanson',
    'Alger',
    'Algonac',
    'Allegan',
    'Allen Park',
    'Allenton',
    'Almont',
    'Amasa',
    'Ann Arbor',
    'Applegate',
    'Armada',
    'Au Gres',
    'Auburn Hills',
    'Bad Axe',
    'Bannister',
    'Baraga',
    'Barbeau',
    'Bark River',
    'Baroda',
    'Barryton',
    'Barton City',
    'Bear Lake',
    'Belding',
    'Benton Harbor',
    'Benzonia',
    'Berrien Springs',
    'Berrien Center',
    'Beulah',
    'Big Bay',
    'Big Rapids',
    'Birch Run',
    'Black River',
    'Blissfield',
    'Bloomfield Hills',
    'Boyne City',
    'Boyne Falls',
    'Brethren',
    'Bridgman',
    'Brimley',
    'Britton',
    'Brown City',
    'Bruce Crossing',
    'Byron Center',
    'Cadillac',
    'Calumet',
    'Capac',
    'Carleton',
    'Caro',
    'Carson City',
    'Carsonville',
    'Caseville',
    'Casnovia',
    'Cass City',
    'Cassopolis',
    'Cement City',
    'Center Line',
    'Central Lake',
    'Ceresco',
    'Charlevoix',
    'Chassell',
    'Cheboygan',
    'Chesaning',
    'Clarklake',
    'Clawson',
    'Clifford',
    'Clinton Township',
    'Coloma',
    'Colon',
    'Columbiaville',
    'Comstock Park',
    'Conklin',
    'Constantine',
    'Coopersville',
    'Copemish',
    'Copper Harbor',
    'Covert',
    'Croswell',
    'Crystal',
    'Crystal Falls',
    'Curran',
    'Curtis',
    'Custer',
    'Dafter',
    'Daggett',
    'Dansville',
    'Davison',
    'Davisburg',
    'Dearborn Heights',
    'Decker',
    'Deckerville',
    'Deford',
    'Delton',
    'Dimondale',
    'Dorr',
    'Dowagiac',
    'Dowling',
    'Drummond',
    'Dryden',
    'East Jordan',
    'East Lansing',
    'East Leroy',
    'East Tawas',
    'Eastpointe',
    'Eaton Rapids',
    'Eau Claire',
    'Eckerman',
    'Ecorse',
    'Edmore',
    'Edwardsburg',
    'Elmira',
    'Elsie',
    'Elwell',
    'Engadine',
    'Escanaba',
    'Essexville',
    'Evart',
    'Fair Haven',
    'Fairgrove',
    'Farmington Hills',
    'Farwell',
    'Felch',
    'Fennville',
    'Fenwick',
    'Ferndale',
    'Fife Lake',
    'Filion',
    'Flushing',
    'Foster City',
    'Fowlerville',
    'Frankenmuth',
    'Fraser',
    'Frederic',
    'Free Soil',
    'Fruitport',
    'Gagetown',
    'Gaines',
    'Galien',
    'Garden',
    'Germfask',
    'Gladwin',
    'Glen Arbor',
    'Gobles',
    'Goodells',
    'Gowen',
    'Grand Blanc',
    'Grand Haven',
    'Grand Junction',
    'Grand Ledge',
    'Grand Marais',
    'Grand Rapids',
    'Grandville',
    'Grass Lake',
    'Grawn',
    'Grayling',
    'Gregory',
    'Grosse Pointe',
    'Grosse Pointe Woods',
    'Gulliver',
    'Gwinn',
    'Hamtramck',
    'Harbor Beach',
    'Harbor Springs',
    'Harper Woods',
    'Harrietta',
    'Hart',
    'Hartland',
    'Haslett',
    'Hazel Park',
    'Hemlock',
    'Herron',
    'Hersey',
    'Hesperia',
    'Hickory Corners',
    'Hillman',
    'Holly',
    'Honor',
    'Houghton Lake',
    'Howard City',
    'Howell',
    'Hubbard Lake',
    'Hudsonville',
    'Huntington Woods',
    'Imlay City',
    'Indian River',
    'Interlochen',
    'Iron Mountain',
    'Iron River',
    'Irons',
    'Ironwood',
    'Ishpeming',
    'Ithaca',
    'Jeddo',
    'Jenison',
    'Jerome',
    'Johannesburg',
    'Kalamazoo',
    'Kaleva',
    'Kalkaska',
    'Kawkawlin',
    'Keego Harbor',
    'Kent City',
    'Kewadin',
    'Kinde',
    'Kingsford',
    'Kinross',
    "L'Anse",
    'Lachine',
    'Laingsburg',
    'Lake Ann',
    'Lake Leelanau',
    'Lake Odessa',
    'Lake Orion',
    'Lakeview',
    'Lambertville',
    'Lapeer',
    'Laurium',
    'Lennon',
    'Lincoln Park',
    'Ludington',
    'Luna Pier',
    'Lupton',
    'Mackinac Island',
    'Mackinaw City',
    'Manistee',
    'Mancelona',
    'Manistique',
    'Manitou Beach',
    'Manton',
    'Maple City',
    'Marcellus',
    'Marenisco',
    'Marine City',
    'Marlette',
    'Mattawan',
    'Maybee',
    'Mayville',
    'McBain',
    'McMillan',
    'Mears',
    'Mecosta',
    'Melvindale',
    'Menominee',
    'Mesick',
    'Michigamme',
    'Michigan Center',
    'Middleville',
    'Mikado',
    'Minden City',
    'Mio',
    'Morenci',
    'Morley',
    'Mount Clemens',
    'Mt. Morris',
    'Muir',
    'Mulliken',
    'Munger',
    'Munising',
    'Munith',
    'Muskegon',
    'National City',
    'Naubinway',
    'Negaunee',
    'New Baltimore',
    'New Buffalo',
    'New Era',
    'New Hudson',
    'New Lothrop',
    'Newaygo',
    'North Branch',
    'Northville',
    'Norton Shores',
    'Nottawa',
    'Novi',
    'Nunica',
    'Oakley',
    'Okemos',
    'Old Mission',
    'Olivet',
    'Onaway',
    'Onekama',
    'Onondaga',
    'Onsted',
    'Ontonagon',
    'Ortonville',
    'Oscoda',
    'Osseo',
    'Ossineke',
    'Otisville',
    'Otsego',
    'Otter Lake',
    'Ovid',
    'Owendale',
    'Owosso',
    'Pelkie',
    'Pellston',
    'Perrinton',
    'Peters',
    'Petoskey',
    'Pewamo',
    'Pickford',
    'Pigeon',
    'Pinckney',
    'Pinconning',
    'Pittsford',
    'Plainwell',
    'Pleasant Ridge',
    'Pompeii',
    'Port Austin',
    'Port Hope',
    'Port Huron',
    'Port Sanilac',
    'Potterville',
    'Prudenville',
    'Pullman',
    'Quinnesec',
    'Rapid City',
    'Rapid River',
    'Ray Center',
    'Redford',
    'Reed City',
    'Reese',
    'Remus',
    'Riga',
    'River Rouge',
    'Rives Junction',
    'Rochester Hills',
    'Rodney',
    'Rogers City',
    'Romeo',
    'Romulus',
    'Roscommon',
    'Rose City',
    'Rosebush',
    'Rothbury',
    'Royal Oak',
    'Rudyard',
    'Saint Helen',
    'Saint Ignace',
    'Saint Johns',
    'Saint Louis',
    'Sand Lake',
    'Sandusky',
    'Saranac',
    'Saugatuck',
    'Sault Ste. Marie',
    'Schoolcraft',
    'Scotts',
    'Scottville',
    'Sears',
    'Sebewaing',
    'Six Lakes',
    'Skandia',
    'Smiths Creek',
    'Snover',
    'Sodus',
    'South Boardman',
    'South Lyon',
    'South Rockwood',
    'Southgate',
    'Spring Arbor',
    'Saint Clair Shores',
    'Sterling Heights',
    'Suttons Bay',
    'Swartz Creek',
    'Tawas City',
    'Tekonsha',
    'Temperance',
    'Three Oaks',
    'Toivola',
    'Topinabee',
    'Traverse City',
    'Trenary',
    'Trout Creek',
    'Trufant',
    'Tustin',
    'Twin Lake',
    'Twining',
    'Ubly',
    'Vanderbilt',
    'Vermontville',
    'Vestaburg',
    'Vulcan',
    'Walkerville',
    'Walled Lake',
    'Walloon Lake',
    'Watersmeet',
    'Watton',
    'Webberville',
    'Weidman',
    'West Olive',
    'Westland',
    'Wheeler',
    'White Lake',
    'White Pigeon',
    'Whitmore Lake',
    'Wixom',
    'Ypsilanti',
    'Zeeland',
    'Aitkin',
    'Akeley',
    'Albert Lea',
    'Alborn',
    'Altura',
    'Angle Inlet',
    'Anoka',
    'Appleton',
    'Askov',
    'Atwater',
    'Babbitt',
    'Backus',
    'Balaton',
    'Barrett',
    'Battle Lake',
    'Baudette',
    'Bayport',
    'Beardsley',
    'Becker',
    'Bejou',
    'Belview',
    'Bemidji',
    'Bertha',
    'Big Lake',
    'Bigfork',
    'Bingham Lake',
    'Bird Island',
    'Biwabik',
    'Blackduck',
    'Blomkest',
    'Blooming Prairie',
    'Blue Earth',
    'Bovey',
    'Bowlus',
    'Braham',
    'Brainerd',
    'Breezy Point',
    'Bricelyn',
    'Brook Park',
    'Brooklyn Park',
    'Brooten',
    'Browerville',
    'Browns Valley',
    'Brownsdale',
    'Brownton',
    'Buffalo Lake',
    'Burtrum',
    'Canby',
    'Cannon Falls',
    'Canyon',
    'Carlos',
    'Cass Lake',
    'Center City',
    'Champlin',
    'Chanhassen',
    'Chaska',
    'Chatfield',
    'Chisago City',
    'Chisholm',
    'Circle Pines',
    'Clara City',
    'Clarissa',
    'Clarkfield',
    'Clearbrook',
    'Clitherall',
    'Clontarf',
    'Cloquet',
    'Cokato',
    'Coleraine',
    'Collegeville',
    'Cold Spring',
    'Cologne',
    'Comfrey',
    'Conger',
    'Cook',
    'Cosmos',
    'Crane Lake',
    'Crookston',
    'Cross Lake',
    'Cyrus',
    'Danube',
    'Darwin',
    'Dassel',
    'Deer River',
    'Delano',
    'Deerwood',
    'Dennison',
    'Dent',
    'Detroit Lakes',
    'Dilworth',
    'Dodge Center',
    'Dunnell',
    'Eagan',
    'Eagle Bend',
    'East Grand Forks',
    'Eden Prairie',
    'Eden Valley',
    'Eitzen',
    'Elbow Lake',
    'Elk River',
    'Ellendale',
    'Elysian',
    'Emily',
    'Emmons',
    'Erhard',
    'Erskine',
    'Esko',
    'Eveleth',
    'Excelsior',
    'Eyota',
    'Faribault',
    'Federal Dam',
    'Fergus Falls',
    'Fifty Lakes',
    'Finland',
    'Finlayson',
    'Forbes',
    'Forest Lake',
    'Foreston',
    'Fort Ripley',
    'Fosston',
    'Foxhome',
    'Frazee',
    'Freeborn',
    'Fulda',
    'Garvin',
    'Gibbon',
    'Glyndon',
    'Golden Valley',
    'Gonvick',
    'Good Thunder',
    'Goodhue',
    'Goodridge',
    'Granada',
    'Grand Meadow',
    'Grand Portage',
    'Green Isle',
    'Grey Eagle',
    'Grygla',
    'Gully',
    'Hackensack',
    'Hallock',
    'Halstad',
    'Hanley Falls',
    'Hayfield',
    'Herman',
    'Heron Lake',
    'Hibbing',
    'Hitterdal',
    'Hokah',
    'Holdingford',
    'Hovland',
    'Howard Lake',
    'Hoyt Lakes',
    'International Falls',
    'Inver Grove Heights',
    'Iona',
    'Isanti',
    'Isle',
    'Jacobson',
    'Jeffers',
    'Jordan',
    'Kandiyohi',
    'Karlstad',
    'Kasota',
    'Kasson',
    'Keewatin',
    'Kelliher',
    'Kenyon',
    'Kerkhoven',
    'Kerrick',
    'Kiester',
    'Kilkenny',
    'Kimball',
    'La Crescent',
    'Lake Benton',
    'Lake Bronson',
    'Lake Crystal',
    'Lake Elmo',
    'Zimmerman',
    'Lake George',
    'Lake Itasca',
    'Lake Lillian',
    'Lake Wilson',
    'Lakefield',
    'Lamberton',
    'Lanesboro',
    'Laporte',
    'Le Center',
    'Le Sueur',
    'Lengby',
    'Lester Prairie',
    'Lindstrom',
    'Lino Lakes',
    'Lismore',
    'Little Falls',
    'Long Lake',
    'Long Prairie',
    'Lowry',
    'Lucan',
    'Lutsen',
    'Lyle',
    'Mabel',
    'Madelia',
    'Madison Lake',
    'Mahnomen',
    'Mantorville',
    'Maple Lake',
    'Maple Plain',
    'Marcell',
    'Marine on Saint Croix',
    'Mayer',
    'Mazeppa',
    'Meadowlands',
    'Menahga',
    'Mentor',
    'Milaca',
    'Miltona',
    'Minneota',
    'Minnesota City',
    'Minnesota Lake',
    'Minnetonka',
    'Mizpah',
    'Montevideo',
    'Moose Lake',
    'Mora',
    'Motley',
    'Mound',
    'Mountain Iron',
    'Mountain Lake',
    'Murdock',
    'Nashwauk',
    'Nerstrand',
    'Nevis',
    'New Auburn',
    'New Germany',
    'New Munich',
    'New Prague',
    'New Richland',
    'New York Mills',
    'Newfolden',
    'Nicollet',
    'Nielsville',
    'Nisswa',
    'North Mankato',
    'Northome',
    'Norwood Young America',
    'Ogema',
    'Ogilvie',
    'Okabena',
    'Oklee',
    'Olivia',
    'Onamia',
    'Ormsby',
    'Oronoco',
    'Orr',
    'Osakis',
    'Oslo',
    'Ostrander',
    'Ottertail',
    'Outing',
    'Owatonna',
    'Palisade',
    'Park Rapids',
    'Parkers Prairie',
    'Paynesville',
    'Pelican Rapids',
    'Pengilly',
    'Pennock',
    'Pequot Lakes',
    'Perham',
    'Perley',
    'Pierz',
    'Pillager',
    'Pine City',
    'Pine Island',
    'Pine River',
    'Pipestone',
    'Plummer',
    'Ponemah',
    'Ponsford',
    'Prinsburg',
    'Prior Lake',
    'Reads Landing',
    'Red Lake',
    'Red Lake Falls',
    'Red Wing',
    'Redwood Falls',
    'Remer',
    'Renville',
    'Richville',
    'Robbinsdale',
    'Rochert',
    'Rollingstone',
    'Roosevelt',
    'Rose Creek',
    'Roseau',
    'Rosemount',
    'Rothsay',
    'Royalton',
    'Rush City',
    'Rushford',
    'Rushmore',
    'Ruthton',
    'Sacred Heart',
    'Saint Bonifacius',
    'Saint Louis Park',
    'Saint Michael',
    'Saint Paul Park',
    'Saint Peter',
    'Salol',
    'Sandstone',
    'Sartell',
    'Sauk Centre',
    'Sauk Rapids',
    'Scandia',
    'Schroeder',
    'Sebeka',
    'Shakopee',
    'Shelly',
    'Shevlin',
    'Silver Bay',
    'Slayton',
    'Sleepy Eye',
    'Solway',
    'South Saint Paul',
    'Spicer',
    'Spring Park',
    'Stacy',
    'Stanchfield',
    'Staples',
    'Starbuck',
    'Steen',
    'Stephen',
    'Stewartville',
    'Storden',
    'Strandquist',
    'Sturgeon Lake',
    'Taconite',
    'Talmoon',
    'Taylors Falls',
    'Thief River Falls',
    'Tofte',
    'Tower',
    'Tracy',
    'Trail',
    'Trimont',
    'Twin Lakes',
    'Twin Valley',
    'Two Harbors',
    'Ulen',
    'Upsala',
    'Vergas',
    'Vesta',
    'Verndale',
    'Viking',
    'Vining',
    'Wabasha',
    'Wabasso',
    'Waconia',
    'Wadena',
    'Wahkon',
    'Waite Park',
    'Wanamingo',
    'Wannaska',
    'Warba',
    'Warroad',
    'Waseca',
    'Waskish',
    'Watkins',
    'Waubun',
    'Wayzata',
    'Welcome',
    'West Concord',
    'White Bear Lake',
    'Willmar',
    'Willow River',
    'Wilmont',
    'Winger',
    'Wolverton',
    'Wood Lake',
    'Wrenshall',
    'Zumbro Falls',
    'Zumbrota',
    'Arbela',
    'Baring',
    'Barnard',
    'Blythedale',
    'Brashear',
    'Burlington Junction',
    'Cainsville',
    'Clearmont',
    'Conception',
    'Conception Junction',
    'Craig',
    'Downing',
    'Fillmore',
    'Galt',
    'Gilman City',
    'Grant City',
    'Green Castle',
    'Green City',
    'Greentop',
    'Hurdland',
    'Kahoka',
    'King City',
    'Kirksville',
    'La Belle',
    'Mercer',
    'Newtown',
    'Novinger',
    'Novelty',
    'Pattonsburg',
    'Powersville',
    'Ravenwood',
    'Rock Port',
    'Rosendale',
    'Skidmore',
    'Stanberry',
    'Tarkio',
    'Westboro',
    'Wyaconda',
    'Aneta',
    'Argusville',
    'Arvilla',
    'Bathgate',
    'Belcourt',
    'Binford',
    'Bisbee',
    'Cando',
    'Carrington',
    'Casselton',
    'Cavalier',
    'Christine',
    'Cogswell',
    'Cooperstown',
    'Courtenay',
    'Crary',
    'Dazey',
    'Devils Lake',
    'Dickey',
    'Edgeley',
    'Egeland',
    'Emerado',
    'Enderlin',
    'Esmond',
    'Fessenden',
    'Fingal',
    'Fordville',
    'Forest River',
    'Forman',
    'Fort Ransom',
    'Fort Totten',
    'Fullerton',
    'Gackle',
    'Glenfield',
    'Grace City',
    'Grand Forks',
    'Gwinner',
    'Hankinson',
    'Hannaford',
    'Hannah',
    'Hatton',
    'Hensel',
    'Hoople',
    'Horace',
    'Kathryn',
    'Kindred',
    'Kulm',
    'LaMoure',
    'Langdon',
    'Larimore',
    'Lidgerwood',
    'Litchville',
    'Maddock',
    'McVille',
    'Michigan',
    'Milnor',
    'Minto',
    'Mooreton',
    'Munich',
    'Napoleon',
    'Neche',
    'Minnewaukan',
    'New Rockford',
    'Niagara',
    'Oakes',
    'Oriska',
    'Osnabrock',
    'Page',
    'Park River',
    'Pembina',
    'Penn',
    'Pingree',
    'Rock Lake',
    'Rolette',
    'Rugby',
    'Sarles',
    'Sheyenne',
    'Spiritwood',
    'Starkweather',
    'Stirum',
    'Streeter',
    'Sykeston',
    'Tappen',
    'Tolna',
    'Tower City',
    'Valley City',
    'Wahpeton',
    'West Fargo',
    'Wishek',
    'Wyndmere',
    'Anselmo',
    'Ansley',
    'Beatrice',
    'Beaver City',
    'Beaver Crossing',
    'Bee',
    'Beemer',
    'Benedict',
    'Bennet',
    'Bladen',
    'Boelus',
    'Bradshaw',
    'Brainard',
    'Brock',
    'Bruning',
    'Burr',
    'Burwell',
    'Butte',
    'Cedar Bluffs',
    'Chambers',
    'Clatonia',
    'Coleridge',
    'Cozad',
    'Dakota City',
    'David City',
    'Deshler',
    'Diller',
    'Eagle',
    'Edgar',
    'Elkhorn',
    'Elm Creek',
    'Emmet',
    'Filley',
    'Firth',
    'Fort Calhoun',
    'Friend',
    'Funk',
    'Gilead',
    'Glenvil',
    'Guide Rock',
    'Hallam',
    'Hartington',
    'Hildreth',
    'Holdrege',
    'Hooper',
    'Howells',
    'Humphrey',
    'Inland',
    'Jansen',
    'Juniata',
    'Kenesaw',
    'La Vista',
    'Leigh',
    'Long Pine',
    'Loomis',
    'Loup City',
    'Lynch',
    'Malmo',
    'Martell',
    'Meadow Grove',
    'Merna',
    'Morse Bluff',
    'Naper',
    'Nebraska City',
    'Nehawka',
    'Neligh',
    'Newman Grove',
    'Niobrara',
    'North Loup',
    "O'Neill",
    'Oconto',
    'Orchard',
    'Ord',
    'Osmond',
    'Otoe',
    'Papillion',
    'Pawnee City',
    'Pender',
    'Phillips',
    'Pickrell',
    'Pierce',
    'Platte Center',
    'Plattsmouth',
    'Ponca',
    'Primrose',
    'Red Cloud',
    'Republican City',
    'Rising City',
    'Roca',
    'Rulo',
    'Sargent',
    'Scotia',
    'Scribner',
    'Shickley',
    'Shubert',
    'Snyder',
    'South Sioux City',
    'Spalding',
    'Steele City',
    'Springview',
    'Steinauer',
    'Stromsburg',
    'Tekamah',
    'Ulysses',
    'Verdigre',
    'Wahoo',
    'Walthill',
    'Wausa',
    'Weeping Water',
    'Western',
    'Wilcox',
    'Wilber',
    'Winside',
    'Wolbach',
    'Wynot',
    'Wymore',
    'Yutan',
    'Alstead',
    'Alton Bay',
    'Antrim',
    'Barnstead',
    'Bow',
    'Bretton Woods',
    'Campton Lower Village',
    'Candia',
    'Canterbury',
    'Center Barnstead',
    'Center Conway',
    'Center Harbor',
    'Center Ossipee',
    'Center Tuftonboro',
    'Chichester',
    'Chocorua',
    'Colebrook',
    'Contoocook',
    'Cornish Center',
    'Derry',
    'Dunbarton Center',
    'East Hampstead',
    'East Kingston',
    'East Wakefield',
    'Eaton Center',
    'Epping',
    'Epsom',
    'Errol',
    'Francestown',
    'Franconia',
    'Georges Mills',
    'Gilford',
    'Gilmanton',
    'Gilmanton Ironworks',
    'Gilsum',
    'Glencliff',
    'Glen',
    'Goffstown',
    'Grantham',
    "Hale's Location",
    'Hampton Falls',
    'Henniker',
    'Hill',
    'Holderness',
    'Hooksett',
    'Intervale',
    'Jaffrey',
    'Lempster',
    'Lochmere',
    'Lyme',
    'Lyndeborough',
    'Meredith',
    'Melvin Village',
    'Merrimack',
    'Mirror Lake',
    'Mont Vernon',
    'Moultonborough',
    'New Durham',
    'New Ipswich',
    'Newbury',
    'Newmarket',
    'North Conway',
    'North Haverhill',
    'North Hampton',
    'North Sandwich',
    'North Stratford',
    'North Sutton',
    'North Walpole',
    'North Woodstock',
    'Orford',
    'Ossipee',
    'Peterborough',
    'Piermont',
    'Plaistow',
    'Rindge',
    'Rollinsford',
    'Rumney',
    'Sanbornton',
    'Sanbornville',
    'Sandown',
    'Somersworth',
    'South Sutton',
    'Stoddard',
    'Stratham',
    'Sunapee',
    'Swanzey',
    'Tamworth',
    'Waterville Valley',
    'Weare',
    'West Chesterfield',
    'West Stewartstown',
    'Wilmot',
    'Winnisquam',
    'Wolfeboro',
    'Wolfeboro Falls',
    'Woodsville',
    'Allentown',
    'Asbury Park',
    'Atlantic Highlands',
    'Avenel',
    'Basking Ridge',
    'Bayonne',
    'Bedminster',
    'Belle Mead',
    'Belford',
    'Belmar',
    'Bergenfield',
    'Berkeley Heights',
    'Bernardsville',
    'Bloomsbury',
    'Bogota',
    'Boonton',
    'Bordentown',
    'Bound Brook',
    'Bradley Beach',
    'Branchburg Township',
    'Brielle',
    'Brookside',
    'Budd Lake',
    'Califon',
    'Carlstadt',
    'Carteret',
    'Cedar Knolls',
    'Cliffside Park',
    'Cliffwood',
    'Closter',
    'Colonia',
    'Colts Neck',
    'Cookstown',
    'Cranbury',
    'Cranford',
    'Cream Ridge',
    'Cresskill',
    'Deal',
    'Demarest',
    'Denville',
    'Dunellen',
    'East Brunswick',
    'East Hanover',
    'East Orange',
    'East Rutherford',
    'Eatontown',
    'Elizabethport',
    'Englewood Cliffs',
    'Englishtown',
    'Essex Fells',
    'Fair Lawn',
    'Far Hills',
    'Florham Park',
    'Fords',
    'Fort Lee',
    'Franklin Lakes',
    'Freehold',
    'Frenchtown',
    'Gillette',
    'Glen Gardner',
    'Glen Ridge',
    'Hackettstown',
    'Hamilton Square',
    'Haledon',
    'Harrington Park',
    'Hasbrouck Heights',
    'Hazlet',
    'High Bridge',
    'Highland Lakes',
    'Hightstown',
    'Holmdel',
    'Hopatcong',
    'Iselin',
    'Jersey City',
    'Keansburg',
    'Kearny',
    'Keasbey',
    'Kendall Park',
    'Kenvil',
    'Keyport',
    'Kinnelon',
    'Lake Hiawatha',
    'Landing',
    'Laurelton',
    'Layton',
    'Ledgewood',
    'Leonardo',
    'Leonia',
    'Lincroft',
    'Little Ferry',
    'Little Silver',
    'Lodi',
    'Long Branch',
    'Long Valley',
    'Mahwah',
    'Manasquan',
    'Mantoloking',
    'Manville',
    'Maplewood',
    'Matawan',
    'Mendham',
    'Metuchen',
    'Midland Park',
    'Millstone',
    'Mine Hill',
    'Monmouth Beach',
    'Monmouth Junction',
    'Montclair',
    'Montville',
    'Moonachie',
    'Morris Plains',
    'Mount Arlington',
    'Mountain Lakes',
    'Mountainside',
    'Neptune City',
    'Neshanic Station',
    'Netcong',
    'New Brunswick',
    'New Egypt',
    'New Vernon',
    'North Arlington',
    'North Bergen',
    'North Caldwell',
    'Northvale',
    'Nutley',
    'Ocean Grove',
    'Oceanport',
    'Ogdensburg',
    'Old Bridge',
    'Oldwick',
    'Oradell',
    'Palisades Park',
    'Paramus',
    'Parlin',
    'Parsippany',
    'Passaic',
    'Paterson',
    'Peapack',
    'Pennington',
    'Pequannock',
    'Perth Amboy',
    'Pine Brook',
    'Piscataway',
    'Pittstown',
    'Plainsboro',
    'Point Pleasant Beach',
    'Pompton Lakes',
    'Pompton Plains',
    'Port Monmouth',
    'Port Murray',
    'Princeton Junction',
    'Rahway',
    'Rancocas',
    'Raritan',
    'Readington',
    'Red Bank',
    'Ridgefield Park',
    'Ridgewood',
    'Ringoes',
    'River Edge',
    'Rochelle Park',
    'Rockaway',
    'Roebling',
    'Roselle Park',
    'Rumson',
    'Saddle River',
    'Saddle Brook',
    'Sayreville',
    'Scotch Plains',
    'Sea Girt',
    'Secaucus',
    'Sewaren',
    'Short Hills',
    'Skillman',
    'South Amboy',
    'South Bound Brook',
    'South Orange',
    'South Plainfield',
    'South River',
    'South Hackensack',
    'Spotswood',
    'Stewartsville',
    'Stirling',
    'Stockholm',
    'Succasunna',
    'Sussex',
    'Teaneck',
    'Tenafly',
    'Teterboro',
    'Three Bridges',
    'Tinton Falls',
    'Totowa',
    'Towaco',
    'Tranquility',
    'Waldwick',
    'Wallington',
    'Wanaque',
    'Watchung',
    'Weehawken',
    'West Long Branch',
    'West Milford',
    'West New York',
    'West Orange',
    'Whippany',
    'Whitehouse Station',
    'Willingboro',
    'Wood-Ridge',
    'Woodcliff Lake',
    'Wrightstown',
    'Wyckoff',
    'Accord',
    'Alder Creek',
    'Alexandria Bay',
    'Allegany',
    'Almond',
    'Altmar',
    'Altona',
    'Amagansett',
    'Amawalk',
    'Amenia',
    'Amityville',
    'Ancram',
    'Andes',
    'Angelica',
    'Apalachin',
    'Arcade',
    'Ardsley',
    'Arkport',
    'Arkville',
    'Armonk',
    'Arverne',
    'Au Sable Forks',
    'Austerlitz',
    'Averill Park',
    'Babylon',
    'Baldwin Place',
    'Baldwinsville',
    'Ballston Lake',
    'Ballston Spa',
    'Barton',
    'Basom',
    'Bay Shore',
    'Bayside',
    'Beacon',
    'Bearsville',
    'Beaver Dams',
    'Beaver Falls',
    'Bedford Hills',
    'Bellerose',
    'Bellmore',
    'Bellport',
    'Bemus Point',
    'Bergen',
    'Berkshire',
    'Bernhards Bay',
    'Big Flats',
    'Binghamton',
    'Blauvelt',
    'Bloomingburg',
    'Blossvale',
    'Blue Point',
    'Bohemia',
    'Boiceville',
    'Bolton Landing',
    'Bovina Center',
    'Branchport',
    'Brant Lake',
    'Brantingham',
    'Brasher Falls',
    'Brewerton',
    'Briarcliff Manor',
    'Bridgehampton',
    'Brightwaters',
    'Broadalbin',
    'Brockport',
    'Brocton',
    'The Bronx',
    'Bronxville',
    'Brooktondale',
    'Brushton',
    'Buffalo Corners',
    'Burlingham',
    'Burnt Hills',
    'Cadyville',
    'Callicoon',
    'Calverton',
    'Cambria Heights',
    'Cameron Mills',
    'Campbell Hall',
    'Canajoharie',
    'Canandaigua',
    'Canaseraga',
    'Canastota',
    'Canisteo',
    'Cape Vincent',
    'Carle Place',
    'Cassadaga',
    'Castile',
    'Castorland',
    'Cato',
    'Catskill',
    'Cattaraugus',
    'Cayuta',
    'Cazenovia',
    'Cedarhurst',
    'Center Moriches',
    'Centereach',
    'Centerport',
    'Central Bridge',
    'Central Islip',
    'Central Square',
    'Central Valley',
    'Chappaqua',
    'Charlotteville',
    'Chase Mills',
    'Chateaugay',
    'Chaumont',
    'Chautauqua',
    'Chazy',
    'Cheektowaga',
    'Chenango Forks',
    'Chittenango',
    'Cincinnatus',
    'Clarence Center',
    'Claryville',
    'Clayville',
    'Clemons',
    'Clifton Park',
    'Clifton Springs',
    'Clinton Corners',
    'Clintondale',
    'Clymer',
    'Cobleskill',
    'Cochecton',
    'Cohocton',
    'Cohoes',
    'Cold Brook',
    'Cold Spring Harbor',
    'College Point',
    'Colton',
    'Commack',
    'Conesus',
    'Conewango Valley',
    'Congers',
    'Constable',
    'Constableville',
    'Constantia',
    'Copenhagen',
    'Copiague',
    'Copake',
    'Coram',
    'Corfu',
    'Cornwall-on-Hudson',
    'Corona',
    'Cowlesville',
    'Coxsackie',
    'Cranberry Lake',
    'Craryville',
    'Cuddebackville',
    'Cutchogue',
    'Dannemora',
    'De Kalb Junction',
    'De Peyster',
    'DeRuyter',
    'Deansboro',
    'Deferiet',
    'Delanson',
    'Delevan',
    'Deposit',
    'Diamond Point',
    'Dickinson Center',
    'Dobbs Ferry',
    'Dolgeville',
    'Dover Plains',
    'Duanesburg',
    'Durhamville',
    'Eagle Bay',
    'Eagle Bridge',
    'Earlton',
    'East Amherst',
    'East Aurora',
    'East Berne',
    'East Bethany',
    'East Branch',
    'East Chatham',
    'East Elmhurst',
    'East Greenbush',
    'East Islip',
    'East Marion',
    'East Meadow',
    'East Meredith',
    'East Moriches',
    'East Nassau',
    'East New York',
    'East Northport',
    'East Norwich',
    'East Quogue',
    'East Rochester',
    'East Rockaway',
    'East Setauket',
    'East Syracuse',
    'East White Plains',
    'Eastchester',
    'Elbridge',
    'Elizaville',
    'Ellenville',
    'Ellicottville',
    'Elmont',
    'Elmsford',
    'Endicott',
    'Erieville',
    'Evans Mills',
    'Fabius',
    'Fairport',
    'Falconer',
    'Fallsburg',
    'Far Rockaway',
    'Farmingville',
    'Feura Bush',
    'Fishers Island',
    'Fishkill',
    'Fleischmanns',
    'Floral Park',
    'Florida',
    'Forest Hills',
    'Forestburg',
    'Forestport',
    'Forestville',
    'Fort Ann',
    'Fort Covington',
    'Fort Edward',
    'Fort Johnson',
    'Fort Montgomery',
    'Fort Plain',
    'Franklin Square',
    'Freeville',
    'Fresh Meadows',
    'Frewsburg',
    'Fultonville',
    'Galway',
    'Gansevoort',
    'Garnerville',
    'Garrattsville',
    'Gasport',
    'Gerry',
    'Getzville',
    'Gilboa',
    'Glen Aubrey',
    'Glen Cove',
    'Glen Head',
    'Glen Oaks',
    'Glen Spey',
    'Glens Falls',
    'Gloversville',
    'Goldens Bridge',
    'Gouverneur',
    'Gowanda',
    'Grahamsville',
    'Grand Gorge',
    'Granite Springs',
    'Great Kills',
    'Great Neck',
    'Great River',
    'Greenfield Center',
    'Greenfield Park',
    'Greenlawn',
    'Greenport',
    'Greenvale',
    'Greenwood Lake',
    'Greig',
    'Guilderland',
    'Guilderland Center',
    'Hagaman',
    'Halcottsville',
    'Hammondsport',
    'Hampton Bays',
    'Hannacroix',
    'Harpersfield',
    'Harpursville',
    'Hartsdale',
    'Hartwick',
    'Hastings-on-Hudson',
    'Hauppauge',
    'Haverstraw',
    'Henderson Harbor',
    'Hensonville',
    'Herkimer',
    'Hermon',
    'Heuvelton',
    'Hewlett',
    'Hicksville',
    'High Falls',
    'Highland Falls',
    'Highland Mills',
    'Hillburn',
    'Hilton',
    'Hogansburg',
    'Holland Patent',
    'Holley',
    'Holtsville',
    'Honeoye Falls',
    'Hoosick Falls',
    'Hopewell Junction',
    'Hornell',
    'Horseheads',
    'Howard Beach',
    'Hubbardsville',
    'Hudson Falls',
    'Huletts Landing',
    'Huntington Station',
    'Hurleyville',
    'Ilion',
    'Indian Lake',
    'Interlaken',
    'Irondequoit',
    'Island Park',
    'Islandia',
    'Islip',
    'Islip Terrace',
    'Jackson Heights',
    'Jamaica',
    'Jefferson Valley',
    'Jericho',
    'Johnsburg',
    'Johnstown',
    'Jordanville',
    'Katonah',
    'Keeseville',
    'Kendall',
    'Kerhonkson',
    'Kew Gardens',
    'Keuka Park',
    'Kinderhook',
    'King Ferry',
    'Kings Park',
    'Kirkville',
    'Lackawanna',
    'Lagrangeville',
    'Lake Grove',
    'Lake Katrine',
    'Lake Luzerne',
    'Lake Peekskill',
    'Larchmont',
    'Lee Center',
    'Levittown',
    'Lily Dale',
    'Lima',
    'Lindenhurst',
    'Little Neck',
    'Little Valley',
    'Livingston Manor',
    'Loch Sheldrake',
    'Locke',
    'Locust Valley',
    'Long Island City',
    'Loudonville',
    'Lowville',
    'Lynbrook',
    'Lyndonville',
    'Lyon Mountain',
    'Macedon',
    'Mahopac',
    'Mallory',
    'Malverne',
    'Mamaroneck',
    'Manhasset',
    'Manlius',
    'Manorville',
    'Maple Springs',
    'Margaretville',
    'Maryland',
    'Maspeth',
    'Massapequa',
    'Massapequa Park',
    'Mastic',
    'Mastic Beach',
    'Mattituck',
    'Maybrook',
    'Mechanicville',
    'Medusa',
    'Menands',
    'Merrick',
    'Middle Grove',
    'Middle Island',
    'Middle Village',
    'Middleburgh',
    'Mill Neck',
    'Miller Place',
    'Minerva',
    'Mineville',
    'Minoa',
    'Mohegan Lake',
    'Moira',
    'Monsey',
    'Montauk',
    'Montour Falls',
    'Mooers',
    'Mooers Forks',
    'Moriches',
    'Mount Kisco',
    'Mount Sinai',
    'Mount Upton',
    'Mount Vision',
    'Mountain Dale',
    'Mountainville',
    'Mumford',
    'Munnsville',
    'Nanuet',
    'Napanoch',
    'Narrowsburg',
    'Nassau',
    'Nesconset',
    'Neversink',
    'New City',
    'New Hyde Park',
    'New Lisbon',
    'New Paltz',
    'New Rochelle',
    'New Suffolk',
    'New York',
    'Newark Valley',
    'Newcomb',
    'Newfane',
    'Newton Falls',
    'Niagara Falls',
    'Nicholville',
    'North Babylon',
    'North Bangor',
    'North Chili',
    'North Collins',
    'North Creek',
    'North Greece',
    'North Hudson',
    'North Lawrence',
    'North Rose',
    'North Tonawanda',
    'Nunda',
    'Nyack',
    'Oakland Gardens',
    'Oceanside',
    'Old Bethpage',
    'Old Chatham',
    'Old Forge',
    'Old Westbury',
    'Olean',
    'Olivebridge',
    'Olmstedville',
    'Ontario',
    'Orchard Park',
    'Oriskany',
    'Oriskany Falls',
    'Ossining',
    'Oswegatchie',
    'Ouaquaga',
    'Owego',
    'Oyster Bay',
    'Ozone Park',
    'Painted Post',
    'Palatine Bridge',
    'Palisades',
    'Parish',
    'Patchogue',
    'Pattersonville',
    'Pavilion',
    'Pawling',
    'Peekskill',
    'Penn Yan',
    'Perrysburg',
    'Philmont',
    'Piercefield',
    'Piffard',
    'Pine Bush',
    'Pine Plains',
    'Plattsburgh',
    'Pleasant Valley',
    'Poestenkill',
    'Port Chester',
    'Port Ewen',
    'Port Henry',
    'Port Jefferson',
    'Port Jefferson Station',
    'Port Jervis',
    'Port Washington',
    'Porter Corners',
    'Portville',
    'Potsdam',
    'Poughkeepsie',
    'Poughquag',
    'Pound Ridge',
    'Prattsburgh',
    'Preston Hollow',
    'Purchase',
    'Purdys',
    'Purling',
    'Putnam Station',
    'Putnam Valley',
    'Queens Village',
    'Queens',
    'Queensbury',
    'Quogue',
    'Ransomville',
    'Raquette Lake',
    'Ravena',
    'Red Hook',
    'Rego Park',
    'Rensselaer Falls',
    'Rensselaerville',
    'Rexford',
    'Rhinebeck',
    'Richfield Springs',
    'Richford',
    'Ridge',
    'Rifton',
    'Riverhead',
    'Rock City Falls',
    'Rock Tavern',
    'Rockaway Park',
    'Rockville Centre',
    'Ronkonkoma',
    'Roslyn',
    'Roslyn Heights',
    'Rotterdam Junction',
    'Rouses Point',
    'Sackets Harbor',
    'Sag Harbor',
    'Sagaponack',
    'Saint Johnsville',
    'Saint Regis Falls',
    'Salamanca',
    'Salisbury Center',
    'Salisbury Mills',
    'Salt Point',
    'Sandy Creek',
    'Saranac Lake',
    'Saratoga Springs',
    'Saugerties',
    'Sauquoit',
    'Savona',
    'Sayville',
    'Scarsdale',
    'Schaghticoke',
    'Schenectady',
    'Schoharie',
    'Schroon Lake',
    'Schuylerville',
    'Scipio Center',
    'Scotchtown',
    'Sea Cliff',
    'Selden',
    'Selkirk',
    'Seneca Falls',
    'Sharon Springs',
    'Shelter Island',
    'Shelter Island Heights',
    'Shenorock',
    'Sherburne',
    'Shoreham',
    'Shortsville',
    'Shrub Oak',
    'Shushan',
    'Sinclairville',
    'Skaneateles',
    'Slingerlands',
    'Sloansville',
    'Sloatsburg',
    'Smallwood',
    'Smithtown',
    'Sound Beach',
    'South Dayton',
    'South Fallsburg',
    'South Glens Falls',
    'South Jamesport',
    'South Kortright',
    'South New Berlin',
    'South Otselic',
    'South Ozone Park',
    'South Plymouth',
    'Southampton',
    'Southold',
    'Sparrow Bush',
    'Speculator',
    'Spencerport',
    'Spencertown',
    'Speonk',
    'Sprakers',
    'Springfield Gardens',
    'Springwater',
    'Saint Bonaventure',
    'Staatsburg',
    'Stanfordville',
    'Star Lake',
    'Staten Island',
    'Stephentown',
    'Stone Ridge',
    'Stony Brook',
    'Stormville',
    'Stuyvesant',
    'Suffern',
    'Sugar Loaf',
    'Sunnyside',
    'Swan Lake',
    'Syosset',
    'Taberg',
    'Tannersville',
    'Tappan',
    'Tarrytown',
    'Thendara',
    'Theresa',
    'Thiells',
    'Thornwood',
    'Ticonderoga',
    'Tillson',
    'Tomkins Cove',
    'Tonawanda',
    'Troupsburg',
    'Trumansburg',
    'Truxton',
    'Tuckahoe',
    'Tully',
    'Tupper Lake',
    'Tuxedo Park',
    'Ulster Park',
    'Valatie',
    'Valhalla',
    'Valley Cottage',
    'Valley Stream',
    'Van Etten',
    'Vernon Center',
    'Verplanck',
    'Vestal',
    'Voorheesville',
    'Waddington',
    'Wading River',
    'Wainscott',
    'Walden',
    'Wallkill',
    'Walworth',
    'Wantagh',
    'Wappingers Falls',
    'Warners',
    'Washingtonville',
    'Water Mill',
    'Watkins Glen',
    'Wawarsing',
    'Weedsport',
    'West Babylon',
    'West Chazy',
    'West Coxsackie',
    'West Edmeston',
    'West Falls',
    'West Fulton',
    'West Haverstraw',
    'West Hempstead',
    'West Henrietta',
    'West Islip',
    'West Nyack',
    'West Sayville',
    'West Seneca',
    'West Shokan',
    'West Valley',
    'West Winfield',
    'Westbrookville',
    'Westbury',
    'Westerlo',
    'Westhampton',
    'Westhampton Beach',
    'Westtown',
    'Wevertown',
    'Whitestone',
    'Whitney Point',
    'Williston Park',
    'Willsboro',
    'Willseyville',
    'Wingdale',
    'Woodbourne',
    'Woodhaven',
    'Woodhull',
    'Woodmere',
    'Woodside',
    'Wurtsboro',
    'Wyandanch',
    'Wynantskill',
    'Yaphank',
    'Yonkers',
    'Yorktown Heights',
    'Adena',
    'Alliance',
    'Alvada',
    'Alvordton',
    'Antwerp',
    'Apple Creek',
    'Archbold',
    'Ashtabula',
    'Austinburg',
    'Avon Lake',
    'Barberton',
    'Bay Village',
    'Beach City',
    'Bedford Heights',
    'Belle Center',
    'Bellefontaine',
    'Benton Ridge',
    'Berkey',
    'Berlin Heights',
    'Bloomville',
    'Boardman',
    'Botkins',
    'Bradner',
    'Brecksville',
    'Broadview Heights',
    'Burgoon',
    'Cable',
    'Canal Fulton',
    'Canfield',
    'Cardington',
    'Carey',
    'Casstown',
    'Centerburg',
    'Chagrin Falls',
    'Chardon',
    'Chesterland',
    'Chickasaw',
    'Chippewa Lake',
    'Columbia Station',
    'Columbus Grove',
    'Conneaut',
    'Continental',
    'Convoy',
    'Coshocton',
    'Crestline',
    'Curtice',
    'Custar',
    'Cuyahoga Falls',
    'Cygnet',
    'De Graff',
    'Dellroy',
    'Dillonvale',
    'Dola',
    'Dorset',
    'Doylestown',
    'Dunbridge',
    'East Canton',
    'East Liberty',
    'East Liverpool',
    'East Sparta',
    'East Palestine',
    'Eastlake',
    'Edon',
    'Elyria',
    'Euclid',
    'Fairlawn',
    'Farmdale',
    'Findlay',
    'Fort Jennings',
    'Fort Loramie',
    'Fort Recovery',
    'Fostoria',
    'Frazeysburg',
    'Gahanna',
    'Galion',
    'Gambier',
    'Garfield Heights',
    'Garrettsville',
    'Gates Mills',
    'Gibsonburg',
    'Glandorf',
    'Gnadenhutten',
    'Graytown',
    'Green Camp',
    'Green Springs',
    'Grover Hill',
    'Hamler',
    'Hammondsville',
    'Hanoverton',
    'Harpster',
    'Harrod',
    'Haskins',
    'Heath',
    'Holgate',
    'Holmesville',
    'Homeworth',
    'Hoytville',
    'Huntsburg',
    'Jackson Center',
    'Jeromesville',
    'Kalida',
    'Kelleys Island',
    'Killbuck',
    'Kinsman',
    'Kirby',
    'La Rue',
    'Lake Milton',
    'Leavittsburg',
    'Leetonia',
    'Leipsic',
    'Lewis Center',
    'Lindsey',
    'Lorain',
    'Luckey',
    'Magnetic Springs',
    'Malinta',
    'Maple Heights',
    'Maria Stein',
    'Mark Center',
    'Martins Ferry',
    'Massillon',
    'Masury',
    'Maumee',
    'McCutchenville',
    'Mechanicstown',
    'Middle Point',
    'Milford Center',
    'Mineral City',
    'Mineral Ridge',
    'Mingo Junction',
    'Minster',
    'Mogadore',
    'Monclova',
    'Morral',
    'Mount Blanchard',
    'Mount Victory',
    'Munroe Falls',
    'Nashport',
    'New Bavaria',
    'New Bloomington',
    'New Bremen',
    'New Knoxville',
    'New Middletown',
    'New Philadelphia',
    'New Riegel',
    'New Springfield',
    'New Washington',
    'New Waterford',
    'Newbury Center',
    'Newcomerstown',
    'Ney',
    'North Baltimore',
    'North Canton',
    'North Jackson',
    'North Kingsville',
    'North Lima',
    'North Olmsted',
    'North Ridgeville',
    'North Royalton',
    'Oak Harbor',
    'Ohio City',
    'Olmsted Falls',
    'Orrville',
    'Orwell',
    'Ottoville',
    'Painesville',
    'Pandora',
    'Parkman',
    'Payne',
    'Pemberville',
    'Peninsula',
    'Pierpont',
    'Piqua',
    'Plain City',
    'Polk',
    'Port Clinton',
    'Put-in-Bay',
    'Radnor',
    'Rayland',
    'Richwood',
    'Ridgeville Corners',
    'Risingsun',
    'Rittman',
    'Rock Creek',
    'Rocky River',
    'Rootstown',
    'Rossburg',
    'Rossford',
    'Rushsylvania',
    'Russia',
    'Saint Clairsville',
    'Saint Henry',
    'Saint Paris',
    'Scio',
    'Seville',
    'Sharon Center',
    'Sheffield Lake',
    'Sherrodsville',
    'Shreve',
    'Steubenville',
    'Stony Ridge',
    'Streetsboro',
    'Strongsville',
    'Struthers',
    'Stryker',
    'Sugarcreek',
    'Tallmadge',
    'Tiltonsville',
    'Twinsburg',
    'Uhrichsville',
    'Upper Sandusky',
    'Vanlue',
    'Venedocia',
    'Vermilion',
    'Wakeman',
    'Walbridge',
    'Walhonding',
    'Wapakoneta',
    'Warrensville Heights',
    'Wauseon',
    'Waynesfield',
    'West Farmington',
    'West Mansfield',
    'West Unity',
    'Westerville',
    'Westfield Center',
    'Willoughby',
    'Yorkshire',
    'Zanesfield',
    'Aaronsburg',
    'Albrightsville',
    'Alburtis',
    'Aliquippa',
    'Allenport',
    'Allenwood',
    'Allport',
    'Allison Park',
    'Alum Bank',
    'Amberson',
    'Ambler',
    'Ambridge',
    'Apollo',
    'Archbald',
    'Aristes',
    'Armagh',
    'Avella',
    'Avis',
    'Avonmore',
    'Baden',
    'Bally',
    'Barto',
    'Bartonsville',
    'Beaver Meadows',
    'Beavertown',
    'Belle Vernon',
    'Bellefonte',
    'Bensalem',
    'Bentleyville',
    'Bernville',
    'Bethel Park',
    'Birchrunville',
    'Birdsboro',
    'Black Lick',
    'Blairs Mills',
    'Blakeslee',
    'Blandon',
    'Bloomsburg',
    'Blue Bell',
    'Boalsburg',
    'Boyers',
    'Boyertown',
    'Brackenridge',
    'Braddock',
    'Brackney',
    'Breinigsville',
    'Brisbin',
    'Brockway',
    'Bryn Athyn',
    'Bryn Mawr',
    'Bulger',
    'Bunola',
    'Burgettstown',
    'Bushkill',
    'Cairnbrook',
    'Cambridge Springs',
    'Canadensis',
    'Canonsburg',
    'Carrolltown',
    'Catasauqua',
    'Cementon',
    'Center Valley',
    'Centre Hall',
    'Chalfont',
    'Charleroi',
    'Cherry Tree',
    'Chester Springs',
    'Chesterbrook',
    'Cheswick',
    'Chicora',
    'Clairton',
    'Clarks Summit',
    'Claysburg',
    'Claysville',
    'Coal Center',
    'Cochranton',
    'Cocolamus',
    'Colmar',
    'Columbia Cross Roads',
    'Colver',
    'Commodore',
    'Conneaut Lake',
    'Conneautville',
    'Connellsville',
    'Conshohocken',
    'Coopersburg',
    'Coplay',
    'Coraopolis',
    'Corry',
    'Coudersport',
    'Cowansville',
    'Creekside',
    'Cressona',
    'Croydon',
    'Curwensville',
    'Daisytown',
    'Dauphin',
    'Davidsville',
    'Delmont',
    'Devon',
    'Dillsburg',
    'Distant',
    'Donora',
    'Dornsife',
    'Downingtown',
    'Dravosburg',
    'Dresher',
    'Drums',
    'DuBois',
    'Duke Center',
    'Duncannon',
    'Duncansville',
    'Duquesne',
    'Duryea',
    'Dushore',
    'East Brady',
    'East Butler',
    'East Earl',
    'East Greenville',
    'East Hickory',
    'East McKeesport',
    'East Petersburg',
    'East Stroudsburg',
    'East Waterford',
    'Ebensburg',
    'Edinboro',
    'Effort',
    'Eighty Four',
    'Elco',
    'Elizabethville',
    'Elkins Park',
    'Elkland',
    'Elliottsburg',
    'Ellwood City',
    'Elverson',
    'Elysburg',
    'Emlenton',
    'Emmaus',
    'Emporium',
    'Enola',
    'Enon Valley',
    'Ephrata',
    'Equinunk',
    'Ernest',
    'Evans City',
    'Export',
    'Exton',
    'Factoryville',
    'Fairless Hills',
    'Fairmont City',
    'Fallentimber',
    'Falls Creek',
    'Fannettsburg',
    'Farrell',
    'Fayette City',
    'Feasterville',
    'Finleyville',
    'Flourtown',
    'Fogelsville',
    'Ford City',
    'Forksville',
    'Fort Washington',
    'Fountain Hill',
    'Foxburg',
    'Frackville',
    'Frenchville',
    'Friedens',
    'Furlong',
    'Galeton',
    'Gallitzin',
    'Gardners',
    'Genesee',
    'Gibsonia',
    'Girardville',
    'Gladwyne',
    'Glassport',
    'Glen Campbell',
    'Glen Lyon',
    'Glenmoore',
    'Glenside',
    'Glenshaw',
    'Grampian',
    'Granville Summit',
    'Grassflat',
    'Green Lane',
    'Green Tree',
    'Guys Mills',
    'Gwynedd Valley',
    'Hallstead',
    'Harborcreek',
    'Harford',
    'Harleysville',
    'Harrison City',
    'Harveys Lake',
    'Hatboro',
    'Haverford',
    'Hegins',
    'Hellertown',
    'Hereford',
    'Herrick Center',
    'Hershey',
    'Highspire',
    'Hollidaysburg',
    'Home',
    'Homer City',
    'Honesdale',
    'Honey Brook',
    'Hookstown',
    'Hop Bottom',
    'Horsham',
    'Hostetter',
    'Houtzdale',
    'Hummelstown',
    'Hunker',
    'Hunlock Creek',
    'Huntingdon Valley',
    'Hustontown',
    'Ickesburg',
    'Imler',
    'Indiana',
    'Intercourse',
    'Irvona',
    'Irwin',
    'Ivyland',
    'Jamison',
    'Jeannette',
    'Jenkintown',
    'Jennerstown',
    'Jersey Mills',
    'Jersey Shore',
    'Jim Thorpe',
    'Johnsonburg',
    'Jonestown',
    'Julian',
    'Karns City',
    'Karthaus',
    'Kempton',
    'Kennerdell',
    'Kersey',
    'Kimberton',
    'King of Prussia',
    'Kintnersville',
    'Kittanning',
    'Klingerstown',
    'Kulpmont',
    'Kulpsville',
    'Kunkletown',
    'Kutztown',
    'La Plume',
    'Laceyville',
    'Lackawaxen',
    'Lafayette Hill',
    'Lake Ariel',
    'Landisburg',
    'Landisville',
    'Langeloth',
    'Langhorne',
    'Lansdale',
    'Lansford',
    'Latrobe',
    'Laughlintown',
    'Lecontes Mills',
    'Leechburg',
    'Leeper',
    'Leesport',
    'Leetsdale',
    'Lehighton',
    'Lemoyne',
    'Lenhartsville',
    'Lewis Run',
    'Lewisberry',
    'Linesville',
    'Lititz',
    'Little Meadows',
    'Lock Haven',
    'Locustdale',
    'Loganton',
    'Long Pond',
    'Loysville',
    'Lucerne Mines',
    'Lucinda',
    'Lykens',
    'Macungie',
    'Mahanoy City',
    'Mainesburg',
    'Manheim',
    'Manns Choice',
    'Marion Center',
    'Mars',
    'Matamoras',
    'Mattawana',
    'Mayport',
    'Maytown',
    'McAdoo',
    'McAlisterville',
    'McClure',
    'McKeesport',
    'McVeytown',
    'Mehoopany',
    'Mertztown',
    'Meshoppen',
    'Mifflin',
    'Mifflinburg',
    'Mifflintown',
    'Milesburg',
    'Mill Hall',
    'Millerstown',
    'Millheim',
    'Millmont',
    'Minersville',
    'Mohnton',
    'Mohrsville',
    'Monaca',
    'Monessen',
    'Monongahela',
    'Monroeton',
    'Mont Clare',
    'Montgomeryville',
    'Montoursville',
    'Moosic',
    'Morrisdale',
    'Moshannon',
    'Mount Aetna',
    'Mount Bethel',
    'Mount Holly Springs',
    'Mount Jewett',
    'Mount Joy',
    'Mount Pleasant Mills',
    'Mount Pocono',
    'Mount Union',
    'Mount Wolf',
    'Mountain Top',
    'Muncy',
    'Muncy Valley',
    'Murrysville',
    'Myerstown',
    'Nanticoke',
    'Nanty Glo',
    'Narberth',
    'Narvon',
    'Natrona Heights',
    'Nescopeck',
    'Nesquehoning',
    'New Alexandria',
    'New Berlinville',
    'New Bethlehem',
    'New Brighton',
    'New Columbia',
    'New Eagle',
    'New Cumberland',
    'New Enterprise',
    'New Kensington',
    'New Ringgold',
    'New Stanton',
    'New Tripoli',
    'New Wilmington',
    'Newfoundland',
    'Newmanstown',
    'Norristown',
    'North Versailles',
    'North Wales',
    'Northern Cambria',
    'Northumberland',
    'NuMine',
    'Nuremberg',
    'Oakmont',
    'Oley',
    'Olyphant',
    'Orbisonia',
    'Orefield',
    'Oreland',
    'Orrstown',
    'Orwigsburg',
    'Osceola Mills',
    'Osterburg',
    'Ottsville',
    'Palmerton',
    'Paupack',
    'Peckville',
    'Pen Argyl',
    'Penn Run',
    'Pennsburg',
    'Pennsylvania Furnace',
    'Perkasie',
    'Perkiomenville',
    'Perryopolis',
    'Philipsburg',
    'Phoenixville',
    'Pine Grove',
    'Pipersville',
    'Pitcairn',
    'Pittsburgh',
    'Pittston',
    'Pleasant Mount',
    'Pleasant Unity',
    'Plymouth Meeting',
    'Pocono Summit',
    'Port Carbon',
    'Port Matilda',
    'Port Allegany',
    'Port Trevorton',
    'Portersville',
    'Pottstown',
    'Presto',
    'Punxsutawney',
    'Quakertown',
    'Ralston',
    'Rebersburg',
    'Red Hill',
    'Reinholds',
    'Renfrew',
    'Richboro',
    'Ridgway',
    'Riegelsville',
    'Rimersburg',
    'Ringtown',
    'Rixford',
    'Roaring Branch',
    'Roaring Spring',
    'Robesonia',
    'Rochester Mills',
    'Ronks',
    'Rossiter',
    'Roulette',
    'Royersford',
    'Ruffs Dale',
    'Russellton',
    'Saegertown',
    'Saltsburg',
    'Sandy Lake',
    'Sarver',
    'Saxonburg',
    'Saxton',
    'Saylorsburg',
    'Scenery Hill',
    'Schaefferstown',
    'Schnecksville',
    'Schuylkill Haven',
    'Schwenksville',
    'Scotrun',
    'Selinsgrove',
    'Sellersville',
    'Sewickley',
    'Shade Gap',
    'Shamokin',
    'Shavertown',
    'Shawnee on Delaware',
    'Shelocta',
    'Shermans Dale',
    'Shickshinny',
    'Shinglehouse',
    'Shippenville',
    'Shippensburg',
    'Shirleysburg',
    'Shoemakersville',
    'Shohola',
    'Sipesville',
    'Skippack',
    'Skytop',
    'Slatington',
    'Sligo',
    'Slippery Rock',
    'Smethport',
    'Snow Shoe',
    'Souderton',
    'South Fork',
    'Spartansburg',
    'Spring House',
    'Spring Mills',
    'Spring Run',
    'Spruce Creek',
    'Stahlstown',
    'Star Junction',
    'State College',
    'Stevens',
    'Stoneboro',
    'Stoystown',
    'Stroudsburg',
    'Summerhill',
    'Susquehanna',
    'Sutersville',
    'Sweet Valley',
    'Tamaqua',
    'Tarentum',
    'Terre Hill',
    'Thompsontown',
    'Three Springs',
    'Tionesta',
    'Tobyhanna',
    'Trafford',
    'Transfer',
    'Trout Run',
    'Tunkhannock',
    'Turbotville',
    'Turtle Creek',
    'Twin Rocks',
    'Ulster',
    'Union Dale',
    'Unityville',
    'University Park',
    'Upper Black Eddy',
    'Valencia',
    'Valley Forge',
    'Vandergrift',
    'Venetia',
    'Villanova',
    'Vintondale',
    'Volant',
    'Wallaceton',
    'Walnut Bottom',
    'Walnutport',
    'Wampum',
    'Wapwallopen',
    'Warminster',
    'Warrendale',
    'Warrington',
    'Warriors Mark',
    'Washington Crossing',
    'Watsontown',
    'Wattsburg',
    'Waymart',
    'Weatherly',
    'Weedville',
    'Wellsboro',
    'Wernersville',
    'West Alexander',
    'West Decatur',
    'West Elizabeth',
    'West Middlesex',
    'West Mifflin',
    'West Pikeland',
    'West Sunbury',
    'Wexford',
    'White Deer',
    'White Haven',
    'Wilkes-Barre',
    'Willow Grove',
    'Willow Hill',
    'Wilmerding',
    'Winburne',
    'Wind Gap',
    'Windber',
    'Womelsdorf',
    'Wyalusing',
    'Wyncote',
    'Wyomissing',
    'York Haven',
    'York Springs',
    'Youngwood',
    'Zelienople',
    'Zion Grove',
    'Ashaway',
    'Central Falls',
    'Chepachet',
    'Cranston',
    'East Greenwich',
    'East Providence',
    'Hope Valley',
    'Little Compton',
    'Mapleville',
    'Narragansett',
    'North Kingstown',
    'North Providence',
    'North Scituate',
    'North Smithfield',
    'Pascoag',
    'Pawtucket',
    'Peace Dale',
    'Saunderstown',
    'Slatersville',
    'Tiverton',
    'West Kingston',
    'West Warwick',
    'Westerly',
    'Woonsocket',
    'Alcester',
    'Armour',
    'Baltic',
    'Beresford',
    'Big Stone City',
    'Blunt',
    'Bonesteel',
    'Bowdle',
    'Brandt',
    'Brentford',
    'Brookings',
    'Canistota',
    'Cavour',
    'Chamberlain',
    'Chancellor',
    'Claire City',
    'Colman',
    'Colome',
    'Conde',
    'Corsica',
    'Cresbard',
    'Crooks',
    'Dante',
    'De Smet',
    'Dell Rapids',
    'Dimock',
    'Doland',
    'Elk Point',
    'Emery',
    'Estelline',
    'Ethan',
    'Faulkton',
    'Flandreau',
    'Fort Thompson',
    'Garretson',
    'Geddes',
    'Goodwin',
    'Grenville',
    'Highmore',
    'Hosmer',
    'Hoven',
    'Irene',
    'Iroquois',
    'Java',
    'Kennebec',
    'Lake Andes',
    'Lake Norden',
    'Langford',
    'Lennox',
    'Lesterville',
    'Letcher',
    'Lower Brule',
    'Menno',
    'Milbank',
    'New Effington',
    'North Sioux City',
    'Oacoma',
    'Parkston',
    'Peever',
    'Pickstown',
    'Plankinton',
    'Platte',
    'Ree Heights',
    'Renner',
    'Rosholt',
    'Sinai',
    'Sioux Falls',
    'Sioux Falls Junction',
    'Sisseton',
    'Tea',
    'Tolstoy',
    'Tripp',
    'Tyndall',
    'Valley Springs',
    'Veblen',
    'Vermillion',
    'Viborg',
    'Volga',
    'Volin',
    'Wagner',
    'Wakonda',
    'Waubay',
    'Wessington Springs',
    'Willow Lake',
    'Winfred',
    'Winner',
    'Wolsey',
    'Worthing',
    'Yankton',
    'Adamant',
    'Alburg',
    'Ascutney',
    'Bakersfield',
    'Bellows Falls',
    'Bridport',
    'Brattleboro',
    'Bridgewater Corners',
    'Cavendish',
    'Chittenden',
    'Craftsbury',
    'Cuttingsville',
    'Danby',
    'Derby Center',
    'Derby Line',
    'East Burke',
    'East Corinth',
    'East Dorset',
    'East Dover',
    'East Fairfield',
    'East Hardwick',
    'East Montpelier',
    'East Randolph',
    'East Ryegate',
    'East Thetford',
    'East Wallingford',
    'Enosburg Falls',
    'Essex Junction',
    'Fairlee',
    'Felchville',
    'Glover',
    'Guildhall',
    'Highgate Springs',
    'Hinesburg',
    'Irasburg',
    'Killington',
    'Lyndon Center',
    'Manchester Center',
    'McIndoe Falls',
    'Middlebury (village)',
    'Middletown Springs',
    'Monkton Boro',
    'Montgomery Center',
    'Moretown',
    'Newport Center',
    'North Bennington',
    'North Ferrisburgh',
    'North Calais',
    'North Hartland',
    'North Hero',
    'North Montpelier',
    'North Springfield',
    'North Troy',
    'Northfield Falls',
    'Pawlet',
    'Perkinsville',
    'Post Mills',
    'Poultney',
    'Pownal',
    'Proctorsville',
    'Randolph Center',
    'Readsboro',
    'Ripton',
    'Saint Johnsbury',
    'Saxtons River',
    'Shelburne',
    'South Burlington',
    'South Hero',
    'South Londonderry',
    'South Royalton',
    'South Ryegate',
    'South Shaftsbury',
    'South Strafford',
    'Starksboro',
    'Stowe',
    'Thetford Center',
    'Townshend',
    'Tunbridge',
    'Underhill',
    'Vergennes',
    'Waitsfield',
    'Waterbury Center',
    'Websterville',
    'Wells River',
    'West Burke',
    'West Charleston',
    'West Danville',
    'West Dover',
    'West Rutland',
    'West Topsham',
    'West Townshend',
    'West Wardsboro',
    'White River Junction',
    'Whitingham',
    'Winooski',
    'Abbotsford',
    'Abrams',
    'Adell',
    'Algoma',
    'Amberg',
    'Amery',
    'Amherst Junction',
    'Antigo',
    'Arena',
    'Argonne',
    'Arkansaw',
    'Arpin',
    'Baileys Harbor',
    'Balsam Lake',
    'Baraboo',
    'Barneveld',
    'Barron',
    'Bayfield',
    'Beetown',
    'Belgium',
    'Bell Center',
    'Big Bend',
    'Birnamwood',
    'Black Creek',
    'Black Earth',
    'Black River Falls',
    'Blanchardville',
    'Bloomer',
    'Blue Mounds',
    'Blue River',
    'Bonduel',
    'Boscobel',
    'Boulder Junction',
    'Bowler',
    'Boyceville',
    'Briggsville',
    'Brillion',
    'Brule',
    'Burnett',
    'Butternut',
    'Cadott',
    'Camp Douglas',
    'Campbellsport',
    'Caroline',
    'Cashton',
    'Cedarburg',
    'Centuria',
    'Chaseburg',
    'Chetek',
    'Chili',
    'Chippewa Falls',
    'Clam Lake',
    'Clintonville',
    'Cochrane',
    'Colby',
    'Colgate',
    'Combined Locks',
    'Coon Valley',
    'Cornucopia',
    'Crandon',
    'Crivitz',
    'Cuba City',
    'Cudahy',
    'De Forest',
    'De Pere',
    'Deerbrook',
    'Delafield',
    'Detroit Harbor',
    'Dickeyville',
    'Dodgeville',
    'Dousman',
    'Dresser',
    'Eagle River',
    'East Troy',
    'Eau Galle',
    'Egg Harbor',
    'Elcho',
    'Eleva',
    'Elk Mound',
    'Elkhart Lake',
    'Ellison Bay',
    'Elroy',
    'Endeavor',
    'Ettrick',
    'Exeland',
    'Fairchild',
    'Fall Creek',
    'Fennimore',
    'Fifield',
    'Fish Creek',
    'Fond du Lac',
    'Footville',
    'Fox Point',
    'Franksville',
    'Friesland',
    'Galesville',
    'Gays Mills',
    'Genoa City',
    'Glen Flora',
    'Glenbeulah',
    'Glenwood City',
    'Grand Marsh',
    'Grand View',
    'Granton',
    'Green Lake',
    'Greendale',
    'Gresham',
    'Hales Corners',
    'Hager City',
    'Harshaw',
    'Hatley',
    'Hayward',
    'Hazelhurst',
    'Herbster',
    'Hilbert',
    'Hixton',
    'Holcombe',
    'Holmen',
    'Horicon',
    'Hortonville',
    'Hubertus',
    'Iron Ridge',
    'Ixonia',
    'Johnson Creek',
    'Juda',
    'Juneau',
    'Kaukauna',
    'Kenosha',
    'Keshena',
    'Kewaskum',
    'Kewaunee',
    'Kiel',
    'Kieler',
    'Knapp',
    'Krakow',
    'La Farge',
    'La Pointe',
    'La Valle',
    'Lac du Flambeau',
    'Ladysmith',
    'Lake Delton',
    'Lake Geneva',
    'Lake Nebagamon',
    'Lake Tomahawk',
    'Lannon',
    'Laona',
    'Larsen',
    'Little Chute',
    'Little Suamico',
    'Lomira',
    'Luck',
    'Luxemburg',
    'Lyndon Station',
    'Maiden Rock',
    'Manawa',
    'Manitowoc',
    'Manitowish Waters',
    'Maple',
    'Marinette',
    'Markesan',
    'Mauston',
    'Mazomanie',
    'Menasha',
    'Menomonee Falls',
    'Menomonie',
    'Mequon',
    'Merrillan',
    'Milwaukee',
    'Mindoro',
    'Mineral Point',
    'Minocqua',
    'Minong',
    'Mishicot',
    'Mondovi',
    'Montello',
    'Mosinee',
    'Mount Calvary',
    'Mount Horeb',
    'Mountain',
    'Mukwonago',
    'Muscoda',
    'Muskego',
    'Nashotah',
    'Necedah',
    'Neenah',
    'Neillsville',
    'Nekoosa',
    'Neshkoro',
    'New Franken',
    'New Glarus',
    'New Holstein',
    'North Freedom',
    'North Prairie',
    'Oak Creek',
    'Oconomowoc',
    'Oconto Falls',
    'Odanah',
    'Okauchee',
    'Omro',
    'Oostburg',
    'Orfordville',
    'Oshkosh',
    'Owen',
    'Pardeeville',
    'Park Falls',
    'Pelican Lake',
    'Pell Lake',
    'Pepin',
    'Peshtigo',
    'Pewaukee',
    'Pickett',
    'Plain',
    'Platteville',
    'Pleasant Prairie',
    'Plover',
    'Poplar',
    'Port Edwards',
    'Port Wing',
    'Porterfield',
    'Potter',
    'Poynette',
    'Prairie Farm',
    'Prairie du Chien',
    'Prairie du Sac',
    'Radisson',
    'Random Lake',
    'Readstown',
    'Redgranite',
    'Reedsburg',
    'Reeseville',
    'Rhinelander',
    'Rib Lake',
    'Rice Lake',
    'Richland Center',
    'Ringle',
    'Ripon',
    'River Falls',
    'Rock Springs',
    'Rothschild',
    'Rubicon',
    'Rudolph',
    'Saint Croix Falls',
    'Saint Germain',
    'Saint Nazianz',
    'Sand Creek',
    'Sarona',
    'Sauk City',
    'Saukville',
    'Sayner',
    'Saxon',
    'Schofield',
    'Shawano',
    'Sheboygan',
    'Sheboygan Falls',
    'Shell Lake',
    'Shiocton',
    'Siren',
    'Sister Bay',
    'Slinger',
    'Sobieski',
    'Soldiers Grove',
    'Solon Springs',
    'South Milwaukee',
    'South Range',
    'South Wayne',
    'Spooner',
    'Spring Green',
    'Star Prairie',
    'Stetsonville',
    'Stevens Point',
    'Stitzer',
    'Stone Lake',
    'Strum',
    'Sturgeon Bay',
    'Sturtevant',
    'Suamico',
    'Sun Prairie',
    'Suring',
    'Thorp',
    'Three Lakes',
    'Tigerton',
    'Tomah',
    'Trego',
    'Trempealeau',
    'Trevor',
    'Turtle Lake',
    'Two Rivers',
    'Valders',
    'Van Dyne',
    'Vesper',
    'Viroqua',
    'Wabeno',
    'Warrens',
    'Waukesha',
    'Waunakee',
    'Waupaca',
    'Waupun',
    'Wausaukee',
    'Wautoma',
    'Wauwatosa',
    'West Allis',
    'Westby',
    'Weyauwega',
    'Weyerhaeuser',
    'Whitelaw',
    'Wild Rose',
    'Williams Bay',
    'Winneconne',
    'Winter',
    'Wisconsin Dells',
    'Wisconsin Rapids',
    'Withee',
    'Wittenberg',
    'Wonewoc',
    'Woodman',
    'Wyocena',
    'Beech Bottom',
    'Colliers',
    'Follansbee',
    'New Manchester',
    'Triadelphia',
    'Valley Grove',
    'Weirton',
    'Spring Bay',
    'Comstock',
    'Honeoye',
    'Nova',
    'Beccaria',
    'Ephraim',
    'Kohler',
    'Amston',
    'Ansonia',
    'Ballouville',
    'Bantam',
    'Barkhamsted',
    'Beacon Falls',
    'Broad Brook',
    'Centerbrook',
    'Chaplin',
    'Pearce',
    'Tonopah',
    'Cool',
    'San Ysidro',
    'Black Canyon City',
    'Loop',
    'Waka',
    'Mona',
    'Buckeye',
    'Bullhead City',
    'Bylas',
    'Camp Verde',
    'Carefree',
    'Casa Grande',
    'Cave Creek',
    'Chinle',
    'Chino Valley',
    'Cibecue',
    'Clay Springs',
    'Cochise',
    'Colorado City',
    'Concho',
    'Congress',
    'Cornville',
    'Cortaro',
    'Deer Valley',
    'Dolan Springs',
    'Dragoon',
    'Eagar',
    'El Mirage',
    'Elfrida',
    'Eloy',
    'Flagstaff',
    'Fort Apache',
    'Fort McDowell',
    'Fountain Hills',
    'Gila Bend',
    'Globe',
    'Goodyear',
    'Grand Canyon',
    'Happy Jack',
    'Higley',
    'Huachuca City',
    'Kaibito',
    'Kayenta',
    'Keams Canyon',
    'Lake Havasu City',
    'Lake Montezuma',
    'Laveen',
    'Litchfield Park',
    'Littlefield',
    'Many Farms',
    'Marana',
    'Maricopa',
    'McNeal',
    'Meadview',
    'Mesa',
    'Mohave Valley',
    'New River',
    'Nogales',
    'Oracle',
    'Overgaard',
    'Paradise Valley',
    'Paulden',
    'Peach Springs',
    'Peridot',
    'Pima',
    'Pine',
    'Pinedale',
    'Pinetop-Lakeside',
    'Pinon',
    'Polacca',
    'Prescott Valley',
    'Quartzsite',
    'Queen Creek',
    'Rimrock',
    'Rio Verde',
    'Rio Rico',
    'Sacaton',
    'Sahuarita',
    'Saint David',
    'Salome',
    'San Carlos',
    'San Luis',
    'San Simon',
    'Scottsdale',
    'Sedona',
    'Sells',
    'Shonto',
    'Show Low',
    'Sierra Vista',
    'Skull Valley',
    'Snowflake',
    'Somerton',
    'Sonoita',
    'Springerville',
    'Sun City',
    'Sun City West',
    'Supai',
    'Surprise',
    'Tacna',
    'Teec Nos Pos',
    'Tempe',
    'Thatcher',
    'Tolleson',
    'Tombstone',
    'Tonalea',
    'Tonto Basin',
    'Topock',
    'Tuba City',
    'Tubac',
    'Tucson',
    'Tucson Estates',
    'Valentine',
    'Waddell',
    'Wellton',
    'White Hills',
    'Whiteriver',
    'Wickenburg',
    'Willcox',
    'Willow Spring',
    'Window Rock',
    'Winkelman',
    'Wittmann',
    'Yarnell',
    'Young',
    'Youngtown',
    'Yucca',
    'Acampo',
    'Adelanto',
    'Agoura Hills',
    'Aguanga',
    'Ahwahnee',
    'Alameda',
    'Aliso Viejo',
    'Alpaugh',
    'Altadena',
    'Altaville',
    'Amador City',
    'Alviso',
    'American Canyon',
    'Anaheim',
    'City of Angels',
    'Angelus Oaks',
    'Angwin',
    'Antelope',
    'Anza',
    'Apple Valley',
    'Aptos',
    'Arbuckle',
    'Aromas',
    'Arroyo Grande',
    'Artesia',
    'Arvin',
    'Atascadero',
    'Atherton',
    'Auberry',
    'Avenal',
    'Avila Beach',
    'Azusa',
    'Baldwin Park',
    'Ballico',
    'Banning',
    'Barstow',
    'Bel Marin Keys',
    'Bell Gardens',
    'Ben Lomond',
    'Benicia',
    'Berry Creek',
    'Bethel Island',
    'Big Bear',
    'Big Bear Lake',
    'Big Pine',
    'Big Sur',
    'Biggs',
    'Blue Jay',
    'Blythe',
    'Bodega Bay',
    'Bodfish',
    'Bolinas',
    'Bonita',
    'Bonsall',
    'Boron',
    'Borrego Springs',
    'Boulder Creek',
    'Boulevard',
    'Boyle Heights',
    'Branscomb',
    'Brawley',
    'Brea',
    'Brisbane',
    'Brookdale',
    'Buellton',
    'Buena Park',
    'Buttonwillow',
    'Cabazon',
    'Calabasas',
    'Calexico',
    'Caliente',
    'California City',
    'Calipatria',
    'Calistoga',
    'Calimesa',
    'Camarillo',
    'Camino',
    'Campo',
    'Camptonville',
    'Canoga Park',
    'Canyon Country',
    'Capay',
    'Capistrano Beach',
    'Capitola',
    'Carlsbad',
    'Carmel Valley',
    'Carmel-by-the-Sea',
    'Carmichael',
    'Carnelian Bay',
    'Carpinteria',
    'Caruthers',
    'Castaic',
    'Castro Valley',
    'Cathedral City',
    'Catheys Valley',
    'Cayucos',
    'Cazadero',
    'Ceres',
    'Century City',
    'Cerritos',
    'Challenge',
    'Chilcoot',
    'Chino',
    'Chino Hills',
    'Chowchilla',
    'Chualar',
    'Chula Vista',
    'Citrus Heights',
    'Clearlake',
    'Clearlake Oaks',
    'Clovis',
    'Coachella',
    'Coalinga',
    'Coarsegold',
    'Coleville',
    'Colusa',
    'Comptche',
    'Copperopolis',
    'Corcoran',
    'Corona del Mar',
    'Coronado',
    'Corral de Tierra',
    'Corte Madera',
    'Costa Mesa',
    'Cotati',
    'Covelo',
    'Covina',
    'Crest Park',
    'Culver City',
    'Cupertino',
    'Daly City',
    'Dana Point',
    'Death Valley Junction',
    'Del Mar',
    'Del Rey',
    'Denair',
    'Descanso',
    'Desert Center',
    'Desert Hot Springs',
    'Diablo',
    'Diamond Bar',
    'Diamond Springs',
    'Dillon Beach',
    'Dinuba',
    'Discovery Bay',
    'Dos Palos',
    'Downey',
    'Downieville',
    'Duarte',
    'Ducor',
    'Dulzura',
    'Earlimart',
    'East Palo Alto',
    'El Cajon',
    'El Centro',
    'El Cerrito',
    'El Dorado Hills',
    'El Granada',
    'El Monte',
    'El Nido',
    'El Portal',
    'El Segundo',
    'El Sobrante',
    'El Verano',
    'Elk',
    'Elk Grove',
    'Elverta',
    'Emeryville',
    'Emigrant Gap',
    'Encinitas',
    'Escalon',
    'Escondido',
    'Esparto',
    'Fair Oaks',
    'Fallbrook',
    'Fiddletown',
    'Firebaugh',
    'Foothill Ranch',
    'Forbestown',
    'Forest Falls',
    'Forest Knolls',
    'Forest Ranch',
    'Foresthill',
    'Fort Bragg',
    'Fort Irwin',
    'Fountain Valley',
    'Frazier Park',
    'Friant',
    'Furnace Creek',
    'Garden Valley',
    'Gardena',
    'Geyserville',
    'Gilroy',
    'Glen Ellen',
    'Glenn',
    'Gold River',
    'Gold Run',
    'Goleta',
    'Granada Hills',
    'Grand Terrace',
    'Granite Bay',
    'Grass Valley',
    'Graton',
    'Greenbrae',
    'Grizzly Flat',
    'Grover Beach',
    'Guadalupe',
    'Gualala',
    'Guatay',
    'Guerneville',
    'Guinda',
    'Hacienda Heights',
    'Half Moon Bay',
    'Hamilton City',
    'Hanford',
    'Harbor City',
    'Hathaway Pines',
    'Hawaiian Gardens',
    'Healdsburg',
    'Heber',
    'Helendale',
    'Hemet',
    'Hercules',
    'Hermosa Beach',
    'Hidden Valley Lake',
    'Hilmar',
    'Holtville',
    'Homeland',
    'Hopland',
    'Hughson',
    'Huntington Beach',
    'Huntington Park',
    'Idyllwild',
    'Imperial Beach',
    'Indian Wells',
    'Indio',
    'City of Industry',
    'Inglewood',
    'Inyokern',
    'Ione',
    'Isleton',
    'Jacumba Hot Springs',
    'Jamul',
    'Jolon',
    'Joshua Tree',
    'Keeler',
    'Kelseyville',
    'Kenwood',
    'Kerman',
    'Kernville',
    'Keyes',
    'Kings Beach',
    'Kingsburg',
    'Knights Landing',
    'La Cañada Flintridge',
    'La Crescenta',
    'La Habra',
    'La Honda',
    'La Jolla',
    'La Mesa',
    'La Mirada',
    'La Palma',
    'La Puente',
    'La Quinta',
    'La Selva Beach',
    'La Verne',
    'Ladera Ranch',
    'Laguna Beach',
    'Laguna Hills',
    'Laguna Niguel',
    'Laguna Woods',
    'Lake Arrowhead',
    'Lake Elsinore',
    'Lake Hughes',
    'Lake Isabella',
    'Lakeport',
    'Lakeside',
    'Larkspur',
    'Laton',
    'Laytonville',
    'Le Grand',
    'Lebec',
    'Lee Vining',
    'Leggett',
    'Lemon Grove',
    'Lemoore',
    'Littlerock',
    'Lockeford',
    'Loma Linda',
    'Lomita',
    'Lompoc',
    'Lone Pine',
    'Long Barn',
    'Los Alamitos',
    'Los Alamos',
    'Los Altos',
    'Los Angeles',
    'Los Banos',
    'Los Gatos',
    'Los Olivos',
    'Los Osos',
    'Lotus',
    'Lower Lake',
    'Loyalton',
    'Lucerne',
    'Lucerne Valley',
    'Lynwood',
    'Lytle Creek',
    'Madera',
    'Magalia',
    'Malibu',
    'Mammoth Lakes',
    'Manhattan Beach',
    'Manteca',
    'Marina',
    'Marina del Rey',
    'Mariposa',
    'Markleeville',
    'Martinez',
    'Mather Field',
    'Meadow Vista',
    'Mecca',
    'Mendocino',
    'Menifee',
    'Menlo Park',
    'Merced',
    'Midway City',
    'Mill Valley',
    'Millbrae',
    'Milpitas',
    'Mira Loma',
    'Miramonte',
    'Mission Hills',
    'Mission Viejo',
    'Modesto',
    'Mojave',
    'Mokelumne Hill',
    'Montara',
    'Monte Rio',
    'Montebello',
    'Monterey Park',
    'Moorpark',
    'Moraga',
    'Moreno Valley',
    'Morgan Hill',
    'Morongo Valley',
    'Morro Bay',
    'Moss Beach',
    'Moss Landing',
    'Mount Wilson',
    'Mountain Center',
    'Mountain Ranch',
    'Murphys',
    'Murrieta',
    'Napa',
    'Needles',
    'Nevada City',
    'Newberry Springs',
    'Newbury Park',
    'Newhall',
    'Newman',
    'Newport Beach',
    'Nicasio',
    'Nice',
    'Nicolaus',
    'Niland',
    'Nipomo',
    'North Fork',
    'North Highlands',
    'North Hollywood',
    'North San Juan',
    'Northridge',
    'Novato',
    'Nuevo',
    'Oak View',
    'Occidental',
    'Oceano',
    'Ojai',
    'Olancha',
    'Olivehurst',
    'Orange Cove',
    'Orangevale',
    'Oregon House',
    'Orinda',
    'Oro Grande',
    'Orosi',
    'Oroville',
    'Oxnard',
    'Pacific Palisades',
    'Pacifica',
    'Pacific Grove',
    'Pacoima',
    'Paicines',
    'Pala',
    'Palm Desert',
    'Palm Springs',
    'Palmdale',
    'Palo Alto',
    'Palomar Mountain',
    'Palos Verdes Estates',
    'Panorama City',
    'Paramount',
    'Parlier',
    'Paso Robles',
    'Pauma Valley',
    'Pearblossom',
    'Pebble Beach',
    'Penn Valley',
    'Penngrove',
    'Penryn',
    'Perris',
    'Pescadero',
    'Petaluma',
    'Phelan',
    'Pico Rivera',
    'Pilot Hill',
    'Pine Mountain Club',
    'Pine Valley',
    'Pinecrest',
    'Pinole',
    'Pismo Beach',
    'Pixley',
    'Piñon Hills',
    'Placentia',
    'Placerville',
    'Planada',
    'Playa Vista',
    'Playa del Rey',
    'Point Arena',
    'Point Reyes Station',
    'Pollock Pines',
    'Pope Valley',
    'Port Hueneme',
    'Portola',
    'Porterville',
    'Portola Valley',
    'Potter Valley',
    'Poway',
    'Prather',
    'Quail Valley',
    'Rackerby',
    'Rancho Cordova',
    'Rancho Cucamonga',
    'Rancho Mirage',
    'Rancho Palos Verdes',
    'Rancho Santa Fe',
    'Rancho Santa Margarita',
    'Redlands',
    'Redondo Beach',
    'Redwood City',
    'Redwood Estates',
    'Redwood Shores',
    'Redwood Valley',
    'Reedley',
    'Reseda',
    'Rialto',
    'Ridgecrest',
    'Rio Linda',
    'Rio Nido',
    'Riverbank',
    'Rocklin',
    'Rodeo',
    'Rohnert Park',
    'Rolling Hills Estates',
    'Rosemead',
    'Ross',
    'Rough and Ready',
    'Rowland Heights',
    'Running Springs',
    'Saint Helena',
    'Salida',
    'Salinas',
    'San Andreas',
    'San Anselmo',
    'San Ardo',
    'San Bernardino',
    'San Bruno',
    'San Clemente',
    'San Dimas',
    'San Fernando',
    'San Francisco',
    'San Francisco De Las Llagas',
    'San Gabriel',
    'San Geronimo',
    'San Gregorio',
    'San Jacinto',
    'San Joaquin',
    'San Juan Bautista',
    'San Juan Capistrano',
    'San Leandro',
    'San Lorenzo',
    'San Lucas',
    'San Luis Obispo',
    'San Marino',
    'San Martin',
    'San Miguel',
    'San Pablo',
    'San Pedro',
    'San Rafael',
    'San Ramon',
    'San Simeon',
    'Santa Ana',
    'Santa Barbara',
    'Santa Clara',
    'Santa Clarita',
    'Santa Cruz',
    'Santa Fe Springs',
    'Santa Margarita',
    'Santa Maria',
    'Santa Monica',
    'Santa Paula',
    'Santa Rosa',
    'Santa Ynez',
    'Santa Ysabel',
    'Sausalito',
    'Scotts Valley',
    'The Sea Ranch',
    'Seal Beach',
    'Seaside',
    'Sebastopol',
    'Seeley',
    'North Hills',
    'Shafter',
    'Shandon',
    'Shaver Lake',
    'Sherman Oaks',
    'Shingle Springs',
    'Sierra Madre',
    'Signal Hill',
    'Silverado',
    'Simi Valley',
    'Sloughhouse',
    'Smartsville',
    'Snelling',
    'Solana Beach',
    'Soledad',
    'Solemint',
    'Solvang',
    'Somis',
    'Sonoma',
    'Soquel',
    'Soulsbyville',
    'South El Monte',
    'South Gate',
    'South Lake Tahoe',
    'South Pasadena',
    'South San Francisco',
    'Spreckels',
    'Squaw Valley',
    'Stevinson',
    'Stinson Beach',
    'Stirling City',
    'Strathmore',
    'Studio City',
    'Sugarloaf',
    'Suisun',
    'Summerland',
    'Sun Valley',
    'Sunland',
    'Sunol',
    'Sutter Creek',
    'Sylmar',
    'Tahoe Valley',
    'Tahoe Vista',
    'Tahoma',
    'Tahoe City',
    'Tarzana',
    'Tecate',
    'Tecopa',
    'Tehachapi',
    'Temecula',
    'Temple City',
    'Terra Bella',
    'Thermal',
    'Thousand Oaks',
    'Thousand Palms',
    'Belvedere Tiburon',
    'Tollhouse',
    'Tomales',
    'Topanga',
    'Topaz',
    'Torrance',
    'Trabuco Canyon',
    'Tranquillity',
    'Travis AFB',
    'Trona',
    'Truckee',
    'Tujunga',
    'Tulare',
    'Tuolumne',
    'Turlock',
    'Twain Harte',
    'Twentynine Palms',
    'Twin Cities',
    'Ukiah',
    'Upper Lake',
    'Vacaville',
    'Vallecito',
    'Vallejo',
    'Valley Ford',
    'Valyermo',
    'Van Nuys',
    'Ventura',
    'Vernalis',
    'Victorville',
    'Vina',
    'Visalia',
    'Vista',
    'Walnut Creek',
    'Warner Springs',
    'Watsonville',
    'West Covina',
    'West Hollywood',
    'West Sacramento',
    'Westlake Village',
    'Westley',
    'Westmorland',
    'White Water',
    'Wildomar',
    'Willits',
    'Willows',
    'Winterhaven',
    'Wofford Heights',
    'Woodacre',
    'Woodland Hills',
    'Wrightwood',
    'Yorba Linda',
    'Yosemite Village',
    'Yountville',
    'Yuba City',
    'Yucaipa',
    'Yucca Valley',
    'Aguilar',
    'Alamosa',
    'Anton',
    'Antonito',
    'Arvada',
    'Aspen',
    'Basalt',
    'Bedrock',
    'Black Hawk',
    'Blanca',
    'Bond',
    'Broomfield',
    'Cahone',
    'Calhan',
    'Cañon City',
    'Castle Rock',
    'Cedaredge',
    'Centennial',
    'Cheyenne Wells',
    'Cimarron',
    'Collbran',
    'Colorado Springs',
    'Commerce City',
    'Conifer',
    'Cortez',
    'Cotopaxi',
    'Creede',
    'Crested Butte',
    'Crestone',
    'Cripple Creek',
    'De Beque',
    'Deer Trail',
    'Del Norte',
    'Divide',
    'Dolores',
    'Dove Creek',
    'Durango',
    'Eckert',
    'Elbert',
    'Eldorado Springs',
    'Fairplay',
    'Federal Heights',
    'Flagler',
    'Fort Garland',
    'Fruita',
    'Gateway',
    'Glenwood Springs',
    'Green Mountain Falls',
    'Greenwood Village',
    'Guffey',
    'Gunnison',
    'Hartman',
    'Hartsel',
    'Hasty',
    'Haswell',
    'Hesperus',
    'Highlands Ranch',
    'Hotchkiss',
    'Idaho Springs',
    'Idalia',
    'Ignacio',
    'Joes',
    'Karval',
    'Kirk',
    'Kit Carson',
    'La Jara',
    'La Junta',
    'La Veta',
    'Las Animas',
    'Leadville',
    'Limon',
    'Loma',
    'Louviers',
    'Mack',
    'Manassa',
    'Mancos',
    'Manitou Springs',
    'Manzanola',
    'McClave',
    'Minturn',
    'Moffat',
    'Monte Vista',
    'Monument',
    'Mosca',
    'Naturita',
    'Nucla',
    'Ordway',
    'Ouray',
    'Pagosa Springs',
    'Palmer Lake',
    'Parachute',
    'Paradox',
    'Penrose',
    'Peyton',
    'Poncha Springs',
    'Pritchett',
    'Pueblo',
    'Pueblo West',
    'Ramah',
    'Red Cliff',
    'Rico',
    'Rifle',
    'Rocky Ford',
    'Rollinsville',
    'Saguache',
    'Seibert',
    'Silt',
    'Silver Plume',
    'Silverthorne',
    'Silverton',
    'Simla',
    'Snowmass',
    'Snowmass Village',
    'Tabernash',
    'Telluride',
    'Towaoc',
    'Vona',
    'Walsenburg',
    'Westcliffe',
    'Wheat Ridge',
    'Wiley',
    'Woodland Park',
    'Woodrow',
    'Woody Creek',
    'Bird City',
    'Dodge City',
    'Edson',
    'Ensign',
    'Hugoton',
    'Ingalls',
    'Kismet',
    'Lakin',
    'Lenora',
    'Leoti',
    'Ludell',
    'Marienthal',
    'Meade',
    'Minneola',
    'Quinter',
    'Satanta',
    'Tribune',
    'Abiquiu',
    'Acoma Pueblo',
    'Alamogordo',
    'Albuquerque',
    'Algodones',
    'Amistad',
    'Angel Fire',
    'Animas',
    'Anton Chico',
    'Arrey',
    'Arroyo Hondo',
    'Arroyo Seco',
    'Aztec',
    'Belen',
    'Bernalillo',
    'Bosque Farms',
    'Buckhorn',
    'Capitan',
    'Carrizozo',
    'Casa Blanca',
    'Cebolla',
    'Cedar Crest',
    'Cerrillos',
    'Chama',
    'Chaparral',
    'Church Rock',
    'Cliff',
    'Cloudcroft',
    'Cochiti Lake',
    'Conchas',
    'Continental Divide',
    'Corrales',
    'Counselor',
    'Coyote',
    'Crownpoint',
    'Cubero',
    'Datil',
    'Deming',
    'Dulce',
    'El Prado',
    'El Rito',
    'Elephant Butte',
    'Elida',
    'Española',
    'Estancia',
    'Fairacres',
    'Fort Sumner',
    'Gallup',
    'Gila',
    'Glorieta',
    'Grants',
    'Hachita',
    'Hagerman',
    'Hatch',
    'Hernandez',
    'High Rolls',
    'Hobbs',
    'Jal',
    'Jemez Pueblo',
    'Jemez Springs',
    'Kirtland',
    'La Luz',
    'Laguna',
    'Lamy',
    'Las Cruces',
    'Las Vegas',
    'Lemitar',
    'Lordsburg',
    'Los Lunas',
    'Los Ojos',
    'Magdalena',
    'Mayhill',
    'Mescalero',
    'Mesilla',
    'Mesilla Park',
    'Mimbres',
    'Moriarty',
    'Mosquero',
    'Mountainair',
    'Navajo',
    'Navajo Dam',
    'Ojo Caliente',
    'Pecos',
    'Penasco',
    'Pinehill',
    'Placitas',
    'Portales',
    'Prewitt',
    'Quemado',
    'Questa',
    'Ranchos de Taos',
    'Raton',
    'Red River',
    'Ribera',
    'Rio Rancho',
    'Roy',
    'Ruidoso',
    'Ruidoso Downs',
    'Salt Lake',
    'San Acacia',
    'San Cristobal',
    'San Jon',
    'Sandia Park',
    'Santa Teresa',
    'Santo Domingo Pueblo',
    'Serafina',
    'Shiprock',
    'Silver City',
    'Socorro',
    'Sunland Park',
    'Sunspot',
    'Taos',
    'Texico',
    'Thoreau',
    'Tierra Amarilla',
    'Tijeras',
    'Timberon',
    'Truth or Consequences',
    'Tucumcari',
    'Tularosa',
    'Vadito',
    'Vado',
    'Valdez',
    'Vanderwagen',
    'Vaughn',
    'Veguita',
    'Velarde',
    'Wagon Mound',
    'White Sands',
    'Zuni',
    'Amargosa Valley',
    'Arrolime',
    'Boulder City',
    'Crystal Bay',
    'Duckwater',
    'Fallon',
    'Fernley',
    'Gardnerville',
    'Incline Village',
    'Jean',
    'Laughlin',
    'Logandale',
    'Lund',
    'McGill',
    'Mina',
    'North Las Vegas',
    'Pahrump',
    'Panaca',
    'Pioche',
    'Schurz',
    'Silver Peak',
    'Stateline',
    'Verdi',
    'Virginia City',
    'Yerington',
    'Zephyr Cove',
    'Boise City',
    'Forgan',
    'Goodwell',
    'Guymon',
    'Hardesty',
    'Hooker',
    'Texhoma',
    'Turpin',
    'Abernathy',
    'Ackerly',
    'Amarillo',
    'Aspermont',
    'Balmorhea',
    'Barksdale',
    'Big Spring',
    'Booker',
    'Borger',
    'Bovina',
    'Boys Ranch',
    'Brackettville',
    'Briscoe',
    'Bronte',
    'Bushland',
    'Cactus',
    'Camp Wood',
    'Canutillo',
    'Childress',
    'Christoval',
    'Claude',
    'Clint',
    'Coahoma',
    'Coyanosa',
    'Crosbyton',
    'Dalhart',
    'Darrouzett',
    'Denver City',
    'Dimmitt',
    'Eagle Pass',
    'Earth',
    'El Indio',
    'Enochs',
    'Eola',
    'Fabens',
    'Floydada',
    'Fluvanna',
    'Follett',
    'Forsan',
    'Fort Davis',
    'Fort Hancock',
    'Fort Stockton',
    'Friona',
    'Fritch',
    'Gail',
    'Grandfalls',
    'Groom',
    'Hale Center',
    'Hedley',
    'Hermleigh',
    'Higgins',
    'Idalou',
    'Iraan',
    'Jayton',
    'Justiceburg',
    'Lamesa',
    'Lefors',
    'Lelia Lake',
    'Lenorah',
    'Levelland',
    'Lipscomb',
    'Lockney',
    'Lorenzo',
    'Lubbock',
    'Marfa',
    'Maryneal',
    'Matador',
    'McCamey',
    'McCaulley',
    'Meadow',
    'Merkel',
    'Mertzon',
    'Mobeetie',
    'Monahans',
    'Muleshoe',
    'New Deal',
    'New Home',
    "O'Donnell",
    'Old Glory',
    'Old Houston Place',
    'Olton',
    'Onion Spring',
    'Orla',
    'Ozona',
    'Pampa',
    'Panhandle',
    'Perryton',
    'Post',
    'Presidio',
    'Quitaque',
    'Ransom Canyon',
    'Roaring Springs',
    'Robert Lee',
    'Roby',
    'Rocksprings',
    'Ropesville',
    'Rotan',
    'Rowena',
    'San Angelo',
    'San Elizario',
    'Seagraves',
    'Shallowater',
    'Shamrock',
    'Sierra Blanca',
    'Skellytown',
    'Slaton',
    'Smyer',
    'Spearman',
    'Sterling City',
    'Sudan',
    'Sundown',
    'Sunray',
    'Tahoka',
    'Terlingua',
    'Texline',
    'Trent',
    'Tulia',
    'Van Horn',
    'Vancourt',
    'Vega',
    'Wall',
    'Whiteface',
    'Whitharral',
    'Wildorado',
    'Wink',
    'Wolfforth',
    'Antimony',
    'Beryl',
    'Blanding',
    'Bluff',
    'Boulder Town',
    'Castle Dale',
    'Cedar City',
    'Ducket Crossing',
    'East Carbon City',
    'Elmo',
    'Elsinore',
    'Escalante',
    'Ferron',
    'Fountain Green',
    'Green River',
    'Hanksville',
    'Helper',
    'Ivins',
    'Joseph',
    'Kanab',
    'Kanosh',
    'La Sal',
    'LaVerkin',
    'Levan',
    'Loa',
    'Manti',
    'Moab',
    'Montezuma Creek',
    'Moroni',
    'Nephi',
    'Orderville',
    'Panguitch',
    'Paragonah',
    'Parowan',
    'Price',
    'Redmond',
    'Santaquin',
    'Teasdale',
    'Torrey',
    'Toquerville',
    'Tropic',
    'Veyo',
    'Virgin',
    'Wasco',
    'Paonia',
    'Redvale',
    'Causey',
    'Gate',
    'Gruver',
    'Ralls',
    'Spur',
    'Aguila',
    'Ajo',
    'Amado',
    'Anthem',
    'Apache Junction',
    'Arivaca',
    'Arizona City',
    'Ash Fork',
    'House',
    'Bapchule',
    'Hydaburg',
    'Rathdrum',
    'Kake',
    'Hobson',
    'Beach',
    'Ketchikan',
    'Klawock',
    'Days Creek',
    'Wallowa',
    'Metlakatla',
    'Sitka',
    'Skagway',
    'Wrangell',
    'Yakutat',
    'Adin',
    'Alderpoint',
    'Alturas',
    'Arcata',
    'Blue Lake',
    'Burney',
    'Carlotta',
    'Castella',
    'Dorris',
    'Dunsmuir',
    'Fall River Mills',
    'Fort Jones',
    'Fortuna',
    'Garberville',
    'Gasquet',
    'Gerber',
    'Happy Camp',
    'Hayfork',
    'Herlong',
    'Hoopa',
    'Hornbrook',
    'Hyampom',
    'Hydesville',
    'Klamath',
    'Klamath River',
    'Kneeland',
    'Korbel',
    'Lakehead',
    'Loleta',
    'Los Molinos',
    'Macdoel',
    'McCloud',
    'McKinleyville',
    'Miranda',
    'Montgomery Creek',
    'Mount Shasta',
    'Myers Flat',
    'Palo Cedro',
    'Red Bluff',
    'Redway',
    'Rio Dell',
    'Salyer',
    'Seiad Valley',
    'Lake Shasta',
    'Shingletown',
    'Smith River',
    'Susanville',
    'Tulelake',
    'Weed',
    'Whitethorn',
    'Whitmore',
    'Willow Creek',
    'Yreka',
    'Allenspark',
    'Ault',
    'Bellvue',
    'Berthoud',
    'Boulder',
    'Brush',
    'Crook',
    'Dacono',
    'Drake',
    'Eckley',
    'Estes Park',
    'Firestone',
    'Fort Collins',
    'Fort Lupton',
    'Fort Morgan',
    'Gilcrest',
    'Glen Haven',
    'Grand Lake',
    'Haxtun',
    'Julesburg',
    'Keenesburg',
    'Kremmling',
    'Longmont',
    'Merino',
    'Milliken',
    'Nunn',
    'Peetz',
    'Rangely',
    'Roggen',
    'Severance',
    'Steamboat Springs',
    'Timnath',
    'Weldona',
    'Yampa',
    'American Falls',
    'Ammon',
    'Arbon',
    'Arco',
    'Arimo',
    'Blackfoot',
    'Bliss',
    'Boise',
    'Bonners Ferry',
    'Bovill',
    'Bruneau',
    'Burley',
    'Careywood',
    'Challis',
    'Cocolalla',
    "Coeur d'Alene",
    'Craigmont',
    'Culdesac',
    'Deary',
    'Declo',
    'Dietrich',
    'Donnelly',
    'Driggs',
    'Felt',
    'Fernwood',
    'Filer',
    'Fish Haven',
    'Fort Hall',
    'Glenns Ferry',
    'Gooding',
    'Grangeville',
    'Greencreek',
    'Hailey',
    'Hamer',
    'Hansen',
    'Heyburn',
    'Homedale',
    'Idaho City',
    'Idaho Falls',
    'Indian Valley',
    'Inkom',
    'Juliaetta',
    'Kamiah',
    'Kendrick',
    'Kooskia',
    'Kuna',
    'Lapwai',
    'Lava Hot Springs',
    'Leadore',
    'Lowman',
    'Mackay',
    'Malad City',
    'Marsing',
    'McCall',
    'McCammon',
    'Melba',
    'Menan',
    'Midvale',
    'Monteview',
    'Moyie Springs',
    'Mullan',
    'Nampa',
    'New Meadows',
    'New Plymouth',
    'Newdale',
    'Notus',
    'Oldtown',
    'Orofino',
    'Osburn',
    'Paul',
    'Payette',
    'Pocatello',
    'Ponderay',
    'Post Falls',
    'Potlatch',
    'Priest River',
    'Rexburg',
    'Rigby',
    'Riggins',
    'Ririe',
    'Rogerson',
    'Saint Maries',
    'Salmon',
    'Sandpoint',
    'Shelley',
    'Shoshone',
    'Soda Springs',
    'Stites',
    'Sugar City',
    'Swanlake',
    'Sweet',
    'Tensed',
    'Terreton',
    'Tetonia',
    'Twin Falls',
    'Weippe',
    'Weiser',
    'White Bird',
    'Wilder',
    'Worley',
    'Yellow Pine',
    'Absarokee',
    'Alberton',
    'Anaconda',
    'Arlee',
    'Bainville',
    'Ballantine',
    'Belt',
    'Biddle',
    'Big Arm',
    'Big Sky',
    'Big Timber',
    'Black Eagle',
    'Bonner',
    'Box Elder',
    'Bozeman',
    'Bridger',
    'Broadus',
    'Busby',
    'Bynum',
    'Canyon Creek',
    'Charlo',
    'Chinook',
    'Choteau',
    'Circle',
    'Clancy',
    'Colstrip',
    'Condon',
    'Corvallis',
    'Crow Agency',
    'Culbertson',
    'Cut Bank',
    'Dagmar',
    'De Borgia',
    'East Helena',
    'Ekalaka',
    'Emigrant',
    'Fishtail',
    'Flaxville',
    'Fort Benton',
    'Fortine',
    'Froid',
    'Fromberg',
    'Galata',
    'Gallatin Gateway',
    'Geyser',
    'Gildford',
    'Glendive',
    'Grass Range',
    'Hall',
    'Harlowton',
    'Hathaway',
    'Havre',
    'Helmville',
    'Heron',
    'Hilger',
    'Hogeland',
    'Huson',
    'Hysham',
    'Judith Gap',
    'Kalispell',
    'Kila',
    'Kinsey',
    'Kremlin',
    'Lambert',
    'Lame Deer',
    'Lavina',
    'Libby',
    'Lodge Grass',
    'Lolo',
    'Loring',
    'Martinsdale',
    'McAllister',
    'McLeod',
    'Miles City',
    'Missoula',
    'Moccasin',
    'Molt',
    'Norris',
    'Noxon',
    'Nye',
    'Outlook',
    'Ovando',
    'Pablo',
    'Pinesdale',
    'Plentywood',
    'Polson',
    'Pony',
    'Power',
    'Pray',
    'Rapelje',
    'Red Lodge',
    'Reed Point',
    'Richey',
    'Ronan',
    'Roundup',
    'Ryegate',
    'Saint Ignatius',
    'Saint Marie',
    'Sand Coulee',
    'Scobey',
    'Seeley Lake',
    'Silver Star',
    'Sun River',
    'Sunburst',
    'Thompson Falls',
    'Three Forks',
    'Toston',
    'Twin Bridges',
    'Ulm',
    'Volborg',
    'West Glacier',
    'West Yellowstone',
    'Whitefish',
    'Wibaux',
    'Wilsall',
    'Winifred',
    'Winnett',
    'Wisdom',
    'Wise River',
    'Wolf Point',
    'Yellowstone National Park County (historical)',
    'Zortman',
    'Anamoose',
    'Antler',
    'Belfield',
    'Berthold',
    'Bottineau',
    'Bowbells',
    'Coleharbor',
    'Deering',
    'Denhoff',
    'Dunn Center',
    'Dunseith',
    'Flasher',
    'Fort Yates',
    'Glen Ullin',
    'Glenburn',
    'Grenora',
    'Halliday',
    'Hettinger',
    'Kenmare',
    'Killdeer',
    'Makoti',
    'Mandan',
    'Mandaree',
    'McClusky',
    'Menoken',
    'Moffit',
    'Mohall',
    'Mott',
    'New England',
    'New Leipzig',
    'New Town',
    'Noonan',
    'Parshall',
    'Plaza',
    'Powers Lake',
    'Reeder',
    'Regent',
    'Rhame',
    'Richardton',
    'Selfridge',
    'Souris',
    'South Heart',
    'Surrey',
    'Towner',
    'Upham',
    'Velva',
    'Watford City',
    'Westhope',
    'Willow City',
    'Bartley',
    'Benkelman',
    'Chadron',
    'Chappell',
    'Elsmere',
    'Farnam',
    'Gering',
    'Gothenburg',
    'Haigler',
    'Hay Springs',
    'Hayes Center',
    'Hemingford',
    'Lewellen',
    'Lodgepole',
    'McCook',
    'Merriman',
    'Mullen',
    'North Platte',
    'Ogallala',
    'Scottsbluff',
    'Thedford',
    'Venango',
    'Wauneta',
    'Battle Mountain',
    'Carlin',
    'Deeth',
    'Gerlach',
    'Imlay',
    'Jackpot',
    'Jarbidge',
    'Lovelock',
    'Owyhee',
    'Spring Creek',
    'West Wendover',
    'Winnemucca',
    'Alsea',
    'Aumsville',
    'Azalea',
    'Baker City',
    'Bandon',
    'Bend',
    'Birkenfeld',
    'Blodgett',
    'Bonanza',
    'Boring',
    'Butte Falls',
    'Camas Valley',
    'Camp Sherman',
    'Cannon Beach',
    'Canyonville',
    'Cascade Locks',
    'Cave Junction',
    'Central Point',
    'Chiloquin',
    'Christmas Valley',
    'Clackamas',
    'Clatskanie',
    'Coos Bay',
    'Coquille',
    'Corbett',
    'Cove',
    'Crabtree',
    'Deadwood',
    'Deer Island',
    'Depoe Bay',
    'Donald',
    'Drain',
    'Dufur',
    'Eagle Point',
    'Estacada',
    'Forest Grove',
    'Fossil',
    'Gales Creek',
    'Garibaldi',
    'Gervais',
    'Gilchrist',
    'Gleneden Beach',
    'Glide',
    'Government Camp',
    'Grand Ronde',
    'Grants Pass',
    'Haines',
    'Halsey',
    'Happy Valley',
    'Hebo',
    'Helix',
    'Heppner',
    'Hermiston',
    'Hood River',
    'Idleyld Park',
    'Imbler',
    'Imnaha',
    'Irrigon',
    'John Day',
    'Jordan Valley',
    'Keizer',
    'Klamath Falls',
    'La Grande',
    'La Pine',
    'Lake Oswego',
    'Langlois',
    'Lincoln City',
    'Logsden',
    'Madras',
    'Marcola',
    'Maupin',
    'Meacham',
    'Merlin',
    'Milton-Freewater',
    'Molalla',
    'Mosier',
    'Mount Angel',
    'Myrtle Creek',
    'Myrtle Point',
    'Nehalem',
    'Neotsu',
    'Neskowin',
    'Newberg',
    'North Plains',
    'North Powder',
    'Nyssa',
    'Oakridge',
    'Oregon City',
    'Pacific City',
    'Parkdale',
    'Philomath',
    'Port Orford',
    'Powell Butte',
    'Prineville',
    'Rainier',
    'Reedsport',
    'Rhododendron',
    'Rickreall',
    'Riddle',
    'Rogue River',
    'Roseburg',
    'Saint Helens',
    'Sandy',
    'Scappoose',
    'Scotts Mills',
    'Seal Rock',
    'Shady Cove',
    'Shedd',
    'Siletz',
    'Sisters',
    'South Beach',
    'Spray',
    'Stayton',
    'Sublimity',
    'Sutherlin',
    'Sweet Home',
    'Talent',
    'Tangent',
    'Tenmile',
    'Terrebonne',
    'The Dalles',
    'Tigard',
    'Tillamook',
    'Troutdale',
    'Tualatin',
    'Tygh Valley',
    'Umpqua',
    'Veneta',
    'Vernonia',
    'Vida',
    'Waldport',
    'Welches',
    'West Linn',
    'Wilderville',
    'Willamina',
    'Wolf Creek',
    'Yachats',
    'Yamhill',
    'Yoncalla',
    'Akaska',
    'Batesland',
    'Belle Fourche',
    'Blackhawk',
    'Bullhead',
    'Camp Crook',
    'Dupree',
    'Eagle Butte',
    'Faith',
    'Fort Pierre',
    'Herreid',
    'Hermosa',
    'Interior',
    'Kadoka',
    'Lead',
    'Lemmon',
    'McLaughlin',
    'Mobridge',
    'Murdo',
    'New Underwood',
    'Nisland',
    'Oglala',
    'Onida',
    'Oral',
    'Philip',
    'Pierre',
    'Pine Ridge',
    'Porcupine',
    'Presho',
    'Quinn',
    'Saint Onge',
    'Spearfish',
    'Timber Lake',
    'Union Center',
    'Wakpala',
    'Wanblee',
    'White River',
    'Whitewood',
    'Wood',
    'Bear River City',
    'Bluffdale',
    'Bountiful',
    'Brigham City',
    'Cedar Fort',
    'Coalville',
    'Corinne',
    'Draper',
    'Duchesne',
    'Dugway',
    'Dutch John',
    'Eagle Mountain',
    'Fielding',
    'Fort Duchesne',
    'Grouse Creek',
    'Henefer',
    'Herriman',
    'Hyrum',
    'Kamas',
    'Kaysville',
    'Laketown',
    'Lapoint',
    'Lehi',
    'Lindon',
    'Magna',
    'Marriott-Slaterville',
    'Myton',
    'North Salt Lake',
    'Orem',
    'Park Valley',
    'Peoa',
    'Provo',
    'Rush Valley',
    'Salt Lake City',
    'South Jordan',
    'Spanish Fork',
    'Tabiona',
    'Talmage',
    'Tooele',
    'Tremonton',
    'Utah Labor Center Condominium',
    'Vernal',
    'Wendover',
    'West Jordan',
    'West Valley City',
    'Woods Cross',
    'Addy',
    'Airway Heights',
    'Allyn',
    'Almira',
    'Anacortes',
    'Ariel',
    'Asotin',
    'Bay Center',
    'Belfair',
    'Bickleton',
    'Bingen',
    'Black Diamond',
    'Bonney Lake',
    'Bothell',
    'Bremerton',
    'Brinnon',
    'Brush Prairie',
    'Camano',
    'Camas',
    'Carnation',
    'Cashmere',
    'Cathlamet',
    'Chattaroy',
    'Chehalis',
    'Chelan',
    'Chelan Falls',
    'Chewelah',
    'Chimacum',
    'Cle Elum',
    'College Place',
    'Colville',
    'Concrete',
    'Connell',
    'Copalis Beach',
    'Cosmopolis',
    'Cougar',
    'Coulee City',
    'Coulee Dam',
    'Coupeville',
    'Cowiche',
    'Curlew',
    'Darrington',
    'Deer Harbor',
    'DesMoines',
    'Dixie',
    'Doty',
    'DuPont',
    'Duvall',
    'East Olympia',
    'East Wenatchee',
    'Eastsound',
    'Eatonville',
    'Edmonds',
    'Edwall',
    'Elbe',
    'Ellensburg',
    'Eltopia',
    'Entiat',
    'Enumclaw',
    'Everson',
    'Fall City',
    'Federal Way',
    'Fife',
    'Forks',
    'Fort Spokane',
    'Four Lakes',
    'Fox Island',
    'Friday Harbor',
    'Gig Harbor',
    'Glenoma',
    'Gold Bar',
    'Goldendale',
    'Grand Coulee',
    'Grapeview',
    'Grayland',
    'Greenacres',
    'Greenbank',
    'Hansville',
    'Hoodsport',
    'Hoquiam',
    'Hunters',
    'Ilwaco',
    'Inchelium',
    'Issaquah',
    'Kalama',
    'Kenmore',
    'Kennewick',
    'Kettle Falls',
    'Kittitas',
    'Klickitat',
    'La Conner',
    'Lacey',
    'Lake Stevens',
    'Lakebay',
    'Latah',
    'Liberty Lake',
    'Lilliwaup',
    'Lind',
    'Little Boston',
    'Longbranch',
    'Loon Lake',
    'Lopez Island',
    'Lummi Island',
    'Lynden',
    'Lynnwood',
    'Lynwood Center',
    'Mabton',
    'Malaga',
    'Malo',
    'Malott',
    'Maple Falls',
    'Maple Valley',
    'Mattawa',
    'McCleary',
    'Medical Lake',
    'Mercer Island',
    'Metaline Falls',
    'Moclips',
    'Montesano',
    'Moses Lake',
    'Mossyrock',
    'Mountlake Terrace',
    'Moxee City',
    'Mukilteo',
    'Naches',
    'Napavine',
    'Naselle',
    'Neah Bay',
    'Newman Lake',
    'Nine Mile Falls',
    'Nordland',
    'North Bonneville',
    'Oakesdale',
    'Ocean Park',
    'Ocean Shores',
    'Okanogan',
    'Olalla',
    'Olga',
    'Omak',
    'Orcas',
    'Orondo',
    'Orting',
    'Othello',
    'Pacific Beach',
    'Pasco',
    'Peshastin',
    'Point Roberts',
    'Port Angeles',
    'Port Hadlock',
    'Port Ludlow',
    'Port Orchard',
    'Port Townsend',
    'Poulsbo',
    'Prosser',
    'Puyallup',
    'Randle',
    'Reardan',
    'Renton',
    'Ritzville',
    'Rosburg',
    'Royal City',
    'Ryderwood',
    'Sammamish',
    'Seabeck',
    'Seattle',
    'Seaview',
    'Sedro-Woolley',
    'Selah',
    'Sequim',
    'Shoreline',
    'Silverdale',
    'Skamokawa',
    'Skykomish',
    'Snohomish',
    'Snoqualmie',
    'Snoqualmie Pass',
    'Soap Lake',
    'South Cle Elum',
    'South Prairie',
    'Spanaway',
    'Spangle',
    'Spokane Valley',
    'Sprague',
    'Steilacoom',
    'Sultan',
    'Sumas',
    'Suquamish',
    'Tacoma',
    'Taholah',
    'Tahuya',
    'Tekoa',
    'Tenino',
    'Tieton',
    'Tokeland',
    'Tonasket',
    'Toppenish',
    'Touchet',
    'Tracyton',
    'Trout Lake',
    'Tukwila',
    'Tumtum',
    'Tumwater',
    'Twisp',
    'University Place',
    'Usk',
    'Valleyford',
    'Vancouver',
    'Vashon',
    'Veradale',
    'Waitsburg',
    'Walla Walla',
    'Wapato',
    'Warden',
    'Washington Park',
    'Washougal',
    'Wellpinit',
    'Wenatchee',
    'West Richland',
    'White Salmon',
    'White Swan',
    'Wilbur',
    'Winlock',
    'Bainbridge Island',
    'Wishram',
    'Woodinville',
    'Yacolt',
    'Yakima',
    'Yelm',
    'Zillah',
    'Baggs',
    'Basin',
    'Big Piney',
    'Casper',
    'Chugwater',
    'Cody',
    'Cokeville',
    'Cora',
    'Cowley',
    'Daniel',
    'Deaver',
    'Diamondville',
    'Encampment',
    'Farson',
    'Fort Laramie',
    'Fort Washakie',
    'Glendo',
    'Glenrock',
    'Greybull',
    'Guernsey',
    'Hawk Springs',
    'Hulett',
    'Hyattville',
    'Jelm',
    'Kaycee',
    'Kemmerer',
    'Kinnear',
    'La Barge',
    'Lander',
    'Laramie',
    'Lingle',
    'Little America',
    'Lusk',
    'Meeteetse',
    'Mills',
    'Moorcroft',
    'Moose',
    'Pavillion',
    'Pine Bluffs',
    'Ranchester',
    'Rawlins',
    'Robertson',
    'Rock River',
    'Rozet',
    'Shell',
    'Shoshoni',
    'Sinclair',
    'Sundance',
    'Ten Sleep',
    'Teton Village',
    'Thayne',
    'Thermopolis',
    'Wamsutter',
    'Worland',
    'Sagle',
    'Dell',
    'Max',
    'Gold Beach',
    'Noti',
    'Tiller',
    'American Fork',
    'Pateros',
    'Palouse',
    'Angoon',
    'Auke Bay',
    'Gustavus',
    'Hoonah',
    'Kahului',
    'Kailua',
    'Haiku',
    'Kailua-Kona',
    'Kalāheo',
    'Kaneohe',
    'Kapaa',
    'Kapaau',
    'Kaunakakai',
    'Kea‘au',
    'Kealakekua',
    'Keauhou',
    'Kekaha',
    'Kihei',
    'Kilauea',
    'Koloa',
    'Kurtistown',
    'Lahaina',
    "La'ie",
    'Lanai',
    'Laupāhoehoe',
    'Lawai',
    'Lihue',
    'Makawao',
    'Mililani Town',
    'Naalehu',
    "Pa'auilo",
    'Pāhala',
    'Pahoa',
    'Paia',
    "Papa'ikou",
    'Pukalani',
    'Volcano Village',
    'Wahiawa',
    'Waialua',
    'Waianae',
    'Waikoloa',
    'Wailuku',
    'Waimanalo',
    'Waimea',
    'Waipahu',
    'Eleele',
    "'Ewa Beach",
    'Hakalau',
    "Hale'iwa",
    'Hana',
    'Hanalei',
    'Hanapepe',
    "Hau'ula",
    'Hawaiian Ocean View',
    'Hāwī',
    'Hilo',
    'Holualoa',
    "Honoka'a",
    'Honolulu',
    'Hoʻolehua',
    '‘Aiea',
    'Kaaawa',
    'Anahola',
    'Captain Cook',
    'Cantwell',
    'Chefornak',
    'Chevak',
    'Chitina',
    'Chugiak',
    'Cold Bay',
    'Cooper Landing',
    'Delta Junction',
    'Dillingham',
    'Dutch Harbor',
    'Eek',
    'Egegik',
    'Emmonak',
    'Ester',
    'Fairbanks',
    'Fort Yukon',
    'Gambell',
    'Girdwood',
    'Glennallen',
    'Healy',
    'Hooper Bay',
    'Iliamna',
    'Kalskag',
    'Kasigluk',
    'Kenai',
    'King Cove',
    'King Salmon',
    'Kipnuk',
    'Kobuk',
    'Kongiganak',
    'Kotzebue',
    'Kwethluk',
    'Kwigillingok',
    'Manokotak',
    'Moose Pass',
    'Mountain Village',
    'Naknek',
    'Nenana',
    'New Stuyahok',
    'Nikiski',
    'North Pole',
    'Nuiqsut',
    'Point Lay',
    'Point Hope',
    'Quinhagak',
    'Sand Point',
    'Scammon Bay',
    'Selawik',
    'Shaktoolik',
    'Soldotna',
    'Stebbins',
    'Talkeetna',
    'Tanana',
    'Togiak',
    'Tok',
    'Toklat',
    'Trapper Creek',
    'Tuntutuliak',
    'Tununak',
    'Unalakleet',
    'Unalaska',
    'Venetie',
    'Wainwright',
    'Wasilla',
    'Willow',
    'Larsen Bay',
    'Adak',
    'Akutan',
    'Alakanuk',
    'Anaktuvuk Pass',
    'Anchorage',
    'Anchor Point',
    'Aniak',
    'Arctic Village',
    'Atqasuk',
    'Barrow',
    'Johns Creek',
    'Grant-Valkaria',
    'Cutler Bay',
    'Cooksville',
    'Cobb Island',
    'Millburn',
    'Stevenson Ranch',
    'Annapolis Junction',
    'Block Island',
    'North Brunswick',
    "Hunt's Point",
    'Kapolei',
    'Elko New Market',
    'Dewey-Humboldt',
    'Bryce Canyon City',
    'City of La Follette',
    'Chattahoochee Hills',
    'Lakeshore Palms Travel Park',
    'Tampa Oaks',
    'Dammeron Valley',
    'Cherry Point Marine Base Mobile Home Park',
    'Braintree',
    'Fort Belvoir',
    'Fort Drum',
    'Aberdeen Proving Ground',
    'Andrews Air Force Base',
    'South Park Township',
    'West Caldwell',
    'Fort Leonard Wood',
    'Fort Riley North',
    'Grosse Ile Township',
    'Scott Air Force Base',
    'Bloomfield Township',
    'West Bloomfield',
    'Whiteman Air Force Base',
    'Fort Campbell North',
    'Fort Knox',
    'Fort Rucker',
    'Fort Stewart',
    'Kings Bay Base',
    'Camp Pendleton South',
    'Challenge-Brownsville',
    'Fairchild Air Force Base',
    'Fort Bliss',
    'Fort Hood',
    'Gold Canyon',
    'Holloman Air Force Base',
    'Lemon Cove',
    'Ohkay Owingeh',
    'Oljato-Monument Valley',
    'Otis Orchards',
    'Tsaile',
    'Schofield Barracks',
    'Royal Kunia',
    'Eielson Air Force Base',
    'Fort Greely',
    'Kodiak Station',
    'San Francisco Naval Communication Station - Stockton',
    'Picatinny Arsenal',
    'Camp Murray',
    'Fort Eustis',
    'Fort George G Meade',
    'Fort Huachuca',
    'Fort Sill',
    'Marine Corps Base Camp Lejeune',
    'Fort Wainwright',
    'Valley Village',
    'Lansdowne Town Center',
    'San Tan Valley',
    'McClellan Park',
    'Saint Stephens Church District',
    'Blaine, Minnesota',
    'Kansas National Guard Armory Hiawatha',
    'West Hills',
    'St. Helena',
    'Oakland Estates Mobile Home Park',
    'West Greenwich',
    'Warren Township',
    'East Windsor',
    'Howell Township',
    'Notre Dame',
    'Randolph Township',
    'Merion',
    'Fort Peck',
    'Newport Coast',
    'Roma',
    'Mount Clare',
    'Garnet Valley',
    'Devens',
    'Kula',
    'Snowshoe',
    'Clear, Alaska',
    'Hot Springs National Park',
    'State University',
    'Cranberry Township',
    'Olympic Valley',
    'Grayton',
    'Maryknoll',
    'McCarran',
    'Rabun Gap',
    'Lincoln University',
    'South Richmond Hill',
    'Porter Ranch',
    'Cortlandt Manor',
    'Mount Hamilton',
    'Hester',
    'Brandeis',
    'Rescue',
    'China Spring',
    'New Century, KS',
    'Woodberry Forest',
    'Bevinsville',
    'Topmost',
  ],
  'Puerto Rico': [
    'Adjuntas',
    'Aguada',
    'Aguadilla',
    'Aguas Buenas',
    'Aibonito',
    'Anasco',
    'Arecibo',
    'Arroyo',
    'Bajadero',
    'Barceloneta',
    'Barranquitas',
    'Bayamón',
    'Boqueron',
    'Cabo Rojo',
    'Caguas',
    'Camuy',
    'Canovanas',
    'Carolina',
    'Catano',
    'Cayey',
    'Ceiba',
    'Central Aguirre',
    'Ciales',
    'Cidra',
    'Coamo',
    'Comerio',
    'Corozal',
    'Coto Laurel',
    'Dorado',
    'Ensenada',
    'Fajardo',
    'Florida',
    'Garrochales',
    'Guanica',
    'Guayama',
    'Guayanilla',
    'Guaynabo',
    'Gurabo',
    'Hatillo',
    'Hormigueros',
    'Humacao',
    'Isabela',
    'Jayuya',
    'Juana Diaz',
    'Juncos',
    'La Plata',
    'Lajas',
    'Lares',
    'Las Marias',
    'Las Piedras',
    'Loiza',
    'Luquillo',
    'Manati',
    'Maunabo',
    'Moca',
    'Morovis',
    'Mayagüez',
    'Naranjito',
    'Orocovis',
    'Patillas',
    'Penuelas',
    'Ponce',
    'Quebradillas',
    'Rincon',
    'Rio Grande',
    'Sabana Grande',
    'Sabana Hoyos',
    'Sabana Seca',
    'Salinas',
    'San Antonio',
    'San German',
    'San Juan',
    'San Lorenzo',
    'San Sebastian',
    'Santa Isabel',
    'Santurce',
    'Toa Alta',
    'Toa Baja',
    'Trujillo Alto',
    'Utuado',
    'Vega Alta',
    'Vega Baja',
    'Villalba',
    'Yabucoa',
    'Yauco',
    'Mercedita',
  ],
  'U.S. Virgin Islands': [
    'Charlotte Amalie',
    'Christiansted',
    'Cruz Bay',
    'Frederickstadt',
    'Kingshill',
    'St Croix',
  ],
  'American Samoa': [
    'Vaitogi',
    'Fitiuta',
    'Taulaga',
    'Tafuna',
    'Faleniu',
    'Leone',
    'Pago Pago',
    'Motu o Manu',
  ],
  Canada: [
    '100 Mile House',
    'Fort St. James',
    '70 Mile House',
    'Abbotsford',
    'Abercrombie',
    'Aberdeen',
    'Acadia Valley',
    'Acadieville',
    'Acheson',
    'Acme',
    'Acton',
    'Acton Vale',
    'Adstock',
    'Afton',
    'Agassiz',
    'Ahousat',
    'Ailsa Craig',
    'Airdrie',
    'Air Ronge',
    'Ajax',
    'Aklavik',
    'Akulivik',
    'Albanel',
    'Alberton',
    'Alder Point',
    'Alert Bay',
    'Alexandria',
    'Alexis Creek',
    'Alfred',
    'Algoma Mills',
    'Alida',
    'Alix',
    'Allenford',
    'Alliance',
    'Alliston',
    'Alma',
    'Altona',
    'Alvinston',
    'Amaranth',
    'Amherst',
    'Amherstburg',
    'Amherstview',
    'Amisk',
    'Amos',
    'Anchor Point',
    'Andrew',
    'Ange-Gardien',
    'Angling Lake',
    'Angus',
    'Anjou',
    'Anmore',
    'Annapolis Royal',
    'Antigonish',
    'Antler',
    'Apohaqui',
    'Apple Hill',
    'Appleton',
    'Arborg',
    'Archerwill',
    'Arcola',
    'Arctic Bay',
    'Ardendale',
    'Ardmore',
    'Ardoch',
    'Ardoise',
    'Ardrossan',
    'Arichat',
    'Ariss',
    'Armstrong',
    'Arnprior',
    'Arrow Creek',
    'Arrowwood',
    'Arthabaska Regional County Municipality',
    'Arthur',
    'Arva',
    'Arviat',
    'Asbestos',
    'Ashcroft',
    'Ashern',
    'Ashmont',
    'Ashton',
    'Asquith',
    'Assiniboia',
    'Aston-Jonction',
    'Astorville',
    'Athabasca',
    'Athelstan',
    'Atikameg',
    'Atikokan',
    'Atlin',
    'Attawapiskat',
    'Atwood',
    'Audet',
    'Aupaluk',
    'Aurora',
    'Austin',
    'Authier',
    'Avonmore',
    'Aylesford',
    'Aylmer',
    'Ayr',
    'Ayton',
    'Baddeck',
    'Baden',
    'Badger',
    'Baie-Comeau',
    'Baie-du-Febvre',
    "Baie-D'Urfe",
    'Baie Verte',
    'Baine Harbour',
    'Baker Lake',
    'Balderson',
    'Baldonnel',
    'Bald Rock',
    'Balgonie',
    'Ballinafad',
    'Bancroft',
    'Banff',
    'Barnaby River',
    'Barnwell',
    'Barons',
    'Barrhaven',
    'Barrhead',
    'Barrie',
    'Barriere',
    'Barrington',
    'Barrington Passage',
    "Barry's Bay",
    'Bashaw',
    'Bassano',
    'Bass River',
    'Bateston',
    'Bath',
    'Battleford',
    'Bawlf',
    'Bay Bulls',
    'Bayfield',
    'Bay Roberts',
    'Baytona',
    'Bay Tree',
    'Beachburg',
    'Beach Meadows',
    'Beachville',
    'Beaconia',
    'Beaconsfield',
    'Beamsville',
    'Bear Island',
    'Bearn',
    'Bear River',
    'Bearskin Lake',
    'Beauharnois',
    'Beaulac-Garthby',
    'Beaumont',
    'Beausejour',
    'Beauval',
    'Beaux-Rivages--Lac-des-Ecorces--Val-Barrette',
    'Beaver Bank',
    'Beaver Dam',
    'Beaverlodge',
    'Beaverton',
    'Becancour',
    'Bedford',
    'Beech Corners',
    'Beechmont',
    'Beechville',
    'Beechy',
    'Beeton',
    'Begin',
    'Beiseker',
    'Belfast',
    'Bella Bella',
    'Belle River',
    'Belleville',
    'Bell Island',
    'Bellsite',
    'Belnan',
    'Beloeil',
    'Belwood',
    'Benalto',
    'Bengough',
    'Benson',
    'Berry',
    'Berthierville',
    'Berwick',
    'Bethanie',
    'Bethany',
    'Bethel',
    'Bide Arm',
    'Bienfait',
    'Biggar',
    'Big Lake Ranch',
    'Big River',
    'Big Valley',
    'Binbrook',
    'Binscarth',
    'Birch Hills',
    'Birdell',
    'Birtle',
    "Bishop's Falls",
    'Bittern Lake',
    'Black Creek',
    'Black Diamond',
    'Blackfalds',
    'Blacks Harbour',
    'Blackville',
    'Blaine Lake',
    'Blainville',
    'Blairmore',
    'Blanc-Sablon',
    'Blenheim',
    'Blind Bay',
    'Blind River',
    'Blockhouse',
    'Blue River',
    'Blue Sea',
    'Bluffton',
    'Blumenort',
    'Blyth',
    'Boisbriand',
    'Boisdale',
    'Bois-des-Filion',
    'Boissevain',
    'Bolton',
    'Bolton-Est',
    'Bolton-Ouest',
    'Bon Accord',
    'Bonanza',
    'Bonnyville',
    'Bonsecours',
    'Bonshaw',
    'Borden',
    'Borden-Carleton',
    'Boston Bar',
    'Botwood',
    'Boucherville',
    'Bouctouche',
    'Bourget',
    'Bowen Island',
    'Bowmanville',
    'Bowsman',
    'Box Alder',
    'Boyle',
    'Bracebridge',
    'Brackendale',
    'Brackley',
    'Bradford West Gwillimbury',
    'Bramalea',
    'Brampton',
    'Brandon',
    'Brant',
    'Brantford',
    'Brechin',
    'Brentwood Bay',
    'Breslau',
    'Breton',
    'Bridesville',
    'Bridgetown',
    'Bridgewater',
    'Brig Bay',
    'Brigham',
    'Brighton',
    'Brisco',
    'Bristol Mines',
    'Broadview',
    'Brocket',
    'Brockville',
    'Brome',
    'Bromont',
    'Brookfield',
    'Brooklin',
    'Brooks',
    'Brossard',
    'Brownsburg-Chatham',
    'Browns Flat',
    'Bruce',
    'Bruce Mines',
    'Bruderheim',
    'Brunkild',
    'Bruno',
    'Bryson',
    'Buchans',
    'Buckhorn',
    'Buena Vista',
    'Buffalo Narrows',
    'Bulyea',
    'Burgeo',
    "Burk's Falls",
    'Burlington',
    'Burnaby',
    'Burns Lake',
    'Burton',
    'Busby',
    'Cabri',
    'Cache Creek',
    'Cadmus',
    'Caistor Centre',
    'Caledon',
    'Caledon East',
    'Caledonia',
    'Calgary',
    'Callander',
    'Calling Lake',
    'Calmar',
    'Cambridge',
    'Cambridge Bay',
    'Cambridge Station',
    'Cameron',
    'Camlachie',
    'Campbellcroft',
    'Campbellford',
    'Campbell River',
    "Campbell's Bay",
    'Campbellville',
    'Camp Creek',
    'Camrose',
    'Canal',
    'Canal Flats',
    'Candiac',
    'Candle Lake',
    'Cando',
    'Canfield',
    'Canim Lake',
    'Canmore',
    'Canning',
    'Cannington',
    'Canora',
    'Cantley',
    'Canwood',
    'Cap-aux-Meules',
    'Cap-Chat',
    'Cape Dorset',
    'Cap-Pele',
    'Carberry',
    'Carbon',
    'Carbonear',
    'Cardigan',
    'Cardinal',
    'Cardston',
    'Caribou Marsh',
    'Carignan',
    'Carleton Place',
    'Carling',
    'Carlisle',
    'Carlyle',
    'Carman',
    'Carnduff',
    'Carnwood',
    'Caroline',
    'Caron',
    'Caronport',
    'Carp',
    'Carrot River',
    'Carrying Place',
    'Carseland',
    'Carstairs',
    'Carters Cove',
    'Casimir',
    'Caslan',
    'Casselman',
    'Castlegar',
    'Castor',
    'Catalone',
    'Cathcart',
    'Cat Lake',
    'Cavan',
    'Cayamant',
    'Cayuga',
    'Cecil Lake',
    'Cedar Valley',
    'Centralia',
    'Central North River',
    'Central Saanich',
    'Centreville',
    'Cereal',
    'Chalk River',
    'Chambly',
    'Champion',
    'Champlain',
    'Channel-Port aux Basques',
    'Chapais',
    'Chapeau',
    'Chapleau',
    'Charette',
    'Charlemagne',
    'Charlie Lake',
    'Charlottetown',
    'Charlton',
    'Charters Settlement',
    'Chartierville',
    'Chartrand Corner',
    'Chase',
    'Chateauguay',
    'Chateh',
    'Chatham',
    'Chatsworth',
    'Chelmsford',
    'Chemainus',
    'Cheneville',
    'Cherry Grove',
    'Chertsey',
    'Chester',
    'Chester Basin',
    'Chesterfield Inlet',
    'Chestermere',
    'Chesterville',
    'Cheticamp',
    'Chetwynd',
    'Chibougamau',
    'Chicoutimi',
    'Chilanko Forks',
    'Chilliwack',
    'Chipman',
    'Chisasibi',
    'Chitek Lake',
    'Chomedey',
    'Christian Island',
    'Christina Lake',
    'Christmas Island',
    'Christopher Lake',
    'Churchbridge',
    'Churchill',
    'Church Point',
    'Chute-aux-Outardes',
    'Circonscription electorale de Jacques-Cartier',
    'Clairmont',
    'Clam Point',
    'Clandeboye',
    'Claremont',
    'Clarence',
    'Clarence-Rockland',
    'Clarenville',
    'Claresholm',
    "Clarke's Beach",
    'Clarksburg',
    "Clark's Harbour",
    'Clavet',
    'Clearwater',
    'Clifford',
    'Clifton Royal',
    'Clinton',
    'Clive',
    'Clyde River',
    'Coaldale',
    'Coaticook',
    'Cobalt',
    'Cobble Hill',
    'Cobden',
    'Coboconk',
    'Cobourg',
    'Cocagne',
    'Cochin',
    'Cochrane',
    'Codette',
    'Coe Hill',
    'Colborne',
    'Coldbrook',
    'Cold Lake',
    'Coldwater',
    'Coleman',
    'Colinet',
    'Collingwood',
    'Colonsay',
    'Comber',
    'Combermere',
    'Comox',
    'Compton',
    'Conception Bay South',
    'Conception Harbour',
    'Concord',
    'Conestogo',
    'Consort',
    'Consul',
    'Contrecoeur',
    'Cookshire-Eaton',
    'Copper Cliff',
    'Coquitlam',
    'Coral Harbour',
    'Corbeil',
    'Corbyville',
    'Corner Brook',
    'Corning',
    'Cornwall',
    'Coronation',
    'Coteau-du-Lac',
    'Cote-Saint-Luc',
    'Cottam',
    'Cottlesville',
    'Courcelles',
    'Courtenay',
    'Courtice',
    'Courtright',
    'Cowansville',
    'Cow Head',
    'Crabtree',
    'Cranberry Portage',
    'Cranbrook',
    'Crawford Bay',
    'Creemore',
    'Cremona',
    'Creston',
    'Crofton',
    'Crooked Creek',
    'Crosby',
    'Crossfield',
    'Cross Lake',
    'Crystal City',
    'Cudworth',
    'Cultus Lake',
    'Cumberland',
    'Cupids',
    'Curve Lake',
    'Cut Knife',
    'Cutler',
    'Czar',
    'Dalhousie Lake',
    'Dallas',
    'Dalmeny',
    'Danford Lake',
    'Danville',
    'Dapp',
    "D'Arcy",
    'Dartmouth',
    'Dashwood',
    'Dauphin',
    'Daveluyville',
    'Davidson',
    'Dawson Creek',
    "Dead Man's Flats",
    'Debden',
    'Debert',
    'DeBolt',
    'Deep River',
    'Deer Lake',
    'Delaware',
    'Deleage',
    'Delhi',
    'Delia',
    'Delisle',
    'Delmas',
    'Delson',
    'Delta',
    'Denare Beach',
    'Denfield',
    'Denman Island',
    'Deroche',
    'Derwent',
    'Desbarats',
    'Deschaillons-sur-Saint-Laurent',
    "D'Escousse",
    'Deseronto',
    'Deux-Montagnes',
    'Devon',
    'Dewberry',
    'Didsbury',
    'Didyme',
    'Dieppe',
    'Digby',
    'Dildo',
    'Dillon',
    'Dinorwic',
    'Dinsmore',
    'Disraeli',
    'Dixon',
    'Dobie',
    'Dolbeau-Mistassini',
    'Dollard-des-Ormeaux',
    'Dominion',
    'Dominion City',
    'Donalda',
    'Donkin',
    'Don Mills',
    'Donnelly',
    'Dorchester',
    'Dorintosh',
    'Dorset',
    'Dorval',
    'Dover',
    'Dover Centre',
    'Drayton',
    'Drayton Valley',
    'Dresden',
    'Drumbo',
    'Drumheller',
    'Drummondville',
    'Dryden',
    'Dublin',
    'Duchess',
    'Duck Lake',
    'Duclos',
    'Dudswell',
    'Dufferin',
    'Duffield',
    'Dugald',
    'Dumfries',
    'Duncan',
    'Dundalk',
    'Dundurn',
    'Dunham',
    'Dunnville',
    'Dunrobin',
    'Dunsford',
    'Duparquet',
    'Durham',
    'Durham Bridge',
    'Dutch Brook',
    'Dutch Valley',
    'Dwight',
    'Eagle River',
    'Ear Falls',
    'Earlton',
    'East Angus',
    'East Bay',
    'Eastend',
    'Eastern Passage',
    'East Gwillimbury',
    'Eastmain',
    'Eastman',
    'East Mountain',
    'Eastport',
    'East Preston',
    'Ebb and Flow',
    'Ebenezer',
    'Echo Bay',
    'Eckville',
    'Economy',
    'Edam',
    'Edberg',
    'Eden',
    'Eden Mills',
    'Edenwold',
    'Edgerton',
    'Edgewood',
    'Edmonton',
    'Edson',
    'Eel Ground',
    'Eganville',
    'Elbow',
    'Elgin',
    'Elkford',
    'Elkhorn',
    'Elk Lake',
    'Elk Point',
    'Ellerslie-Bideford',
    'Elliot Lake',
    'Elmira',
    'Elmsdale',
    'Elmvale',
    'Elmview',
    'Elnora',
    'Elora',
    'Elphinstone',
    'Elrose',
    'Embro',
    'Emerson',
    'Emo',
    'Empress',
    'Emsdale',
    'Enchant',
    'Enderby',
    'Enfield',
    'Engen',
    'Englee',
    'Englefeld',
    'Englehart',
    'Ennismore',
    'Enterprise',
    'Entrelacs',
    'Erickson',
    'Eriksdale',
    'Erin',
    'Erinsville',
    'Eskasoni',
    'Espanola',
    'Esquimalt',
    'Essex',
    'Esterhazy',
    'Estevan',
    'Eston',
    'Etobicoke',
    'Eureka',
    'Evansburg',
    'Everett',
    'Exeter',
    'Eyebrow',
    'Fabre',
    'Fabreville',
    'Fairford Reserve',
    'Fairground',
    'Fairmont',
    'Fairmont Hot Springs',
    'Fairview',
    'Falconbridge',
    'Falher',
    'Fall River',
    'Falun',
    'Farnham',
    'Fatima',
    'Fenelon Falls',
    'Fergus',
    'Ferme-Neuve',
    'Fermont',
    'Fernie',
    'Field',
    'Finch',
    'Fir Mountain',
    'Fisher Branch',
    'Flatbush',
    'Flatrock',
    'Fleming',
    'Flesherton',
    'Fletchers Lake',
    'Fleur de Lys',
    'Flin Flon',
    'Foam Lake',
    'Fogo',
    'Fond-du-Lac',
    'Fonthill',
    'Foothills',
    'Fords Mills',
    'Foremost',
    'Forest',
    'Forestburg',
    'Fort Chipewyan',
    'Fort-Coulonge',
    'Forteau',
    'Fort Erie',
    'Fort Frances',
    'Fort Hope',
    'Fort Kent',
    'Fort Langley',
    'Fort MacKay',
    'Fort Macleod',
    'Fort McMurray',
    'Fort Nelson',
    'Fort Providence',
    "Fort Qu'Appelle",
    'Fort Resolution',
    'Fort Saskatchewan',
    'Fort Severn',
    'Fort Simpson',
    'Fort Smith',
    'Fort St. John',
    'Fortune',
    'Fort Vermilion',
    'Foster',
    'Fox Creek',
    'Fox Harbour',
    'Fox Lake',
    'Fox Valley',
    'Foymount',
    'Francis',
    'Frankford',
    'Franklin',
    'Frankville',
    'Fraser Lake',
    'Fraserville',
    'Fredericton',
    'Fredericton Junction',
    'Freeland',
    'Freeport',
    'Frelighsburg',
    'Frenchvale',
    'Frog Lake',
    'Fruitvale',
    'Gabriola',
    'Gagetown',
    'Galahad',
    'Galloway',
    'Gambo',
    'Gananoque',
    'Gander',
    'Garibaldi',
    'Garlands Crossing',
    'Garnish',
    'Garson',
    'Gaspé',
    'Gatineau',
    'Geary',
    'Gem',
    'Georgetown',
    'Georgeville',
    'Georgina',
    'Geraldton',
    'Gibbons',
    'Gibsons',
    'Gift Lake',
    'Gilford',
    'Gillam',
    'Gillies Bay',
    'Gilmour',
    'Gimli',
    'Girardville',
    'Girouxville',
    'Gitwinksihlkw',
    'Gjoa Haven',
    'Glace Bay',
    'Gladmar',
    'Gladstone',
    'Glaslyn',
    'Glenavon',
    'Glenboro',
    'Glenbush',
    'Glencoe',
    'Glendon',
    'Glenevis',
    'Glen Robertson',
    'Glen Williams',
    'Glenwood',
    'Gloucester',
    'Glovertown',
    'Goderich',
    'Godfrey',
    'Gogama',
    'Gold Bridge',
    'Golden',
    'Golden Lake',
    'Golden Prairie',
    'Gold River',
    'Gooderham',
    'Good Harbour',
    'Goodsoil',
    'Gore Bay',
    'Gormley',
    'Goulais River',
    'Gowganda',
    'Grafton',
    'Granby',
    'Grand Bay–Westfield',
    'Grand Bend',
    'Grand Bruit',
    'Grande Cache',
    'Grande Pointe',
    'Grande Prairie',
    'Grand Falls-Windsor',
    'Grand Forks',
    'Grand Manan',
    'Grandora',
    'Grand Rapids',
    'Grand River',
    'Greater Sudbury',
    'Grand Valley',
    'Grandview',
    'Granisle',
    'Granton',
    'Granville Ferry',
    'Grasmere',
    'Grassie',
    'Grassland',
    'Gravelbourg',
    'Gravenhurst',
    'Napanee',
    'Great Falls',
    'Great Village',
    'Greely',
    'Green Bay',
    'Greenfield',
    'Greenfield Park',
    'Green Lake',
    'Greenleys Corners',
    'Greenwood',
    'Grenfell',
    'Grenville',
    'Grimsby',
    'Grimshaw',
    'Grindrod',
    'Grise Fiord',
    'Griswold',
    'Grono Road',
    'Gros-Morne',
    'Groundbirch',
    'Guelph',
    'Gull Lake',
    'Guy',
    'Guysborough',
    'Hafford',
    'Hagensborg',
    'Hagersville',
    'Hague',
    'Haileybury',
    'Hairy Hill',
    'Hall Beach',
    'Halton Hills',
    'Hamilton',
    'Hamiota',
    'Hammonds Plains',
    'Hampden',
    'Hampton',
    'Hanley',
    'Hanna',
    'Hanover',
    "Hant's Harbour",
    'Hantsport',
    'Happy Valley-Goose Bay',
    'Harbour Breton',
    'Hardisty',
    'Hare Bay',
    'Harley',
    'Harrietsfield',
    'Harrietsville',
    'Harrison Hot Springs',
    'Harriston',
    'Harrow',
    'Hartington',
    'Hartley Bay',
    'Harvey',
    'Harwood',
    'Hastings',
    'Hatchet Lake',
    'Hatley',
    'Havelock',
    'Havre-Aubert',
    'Havre-aux-Maisons',
    'Havre Boucher',
    'Havre-Saint-Pierre',
    'Hawkesbury',
    'Hawkestone Beach',
    'Hay River',
    'Hays',
    'Haywood',
    'Hazelton',
    'Headingley',
    'Hearst',
    "Heart's Delight-Islington",
    'Heatherton',
    'Heathland',
    'Heisler',
    'Hemmingford',
    'Henryville',
    'Hensall',
    'Hepburn',
    'Hepworth',
    'Herbert',
    'Heriot Bay',
    'Heron Bay',
    'Hiawatha',
    'Hickson',
    'Highfield',
    'Highland Grove',
    'High Level',
    'High Prairie',
    'High River',
    'Hillsborough',
    'Hillsburgh',
    'Hillsdale',
    'Hilton Beach',
    'Hinchinbrooke',
    'Hines Creek',
    'Hinton',
    'Hixon',
    'Hodgson',
    'Holdfast',
    'Ulukhaktok',
    'Holyrood',
    'Honeydale',
    'Hood',
    'Hornby Island',
    'Hornepayne',
    'Horseshoe Bay',
    'Houston',
    'Huberdeau',
    'Hudson',
    'Hudson Bay',
    'Hudson Heights',
    "Hudson's Hope",
    'Hull',
    'Humboldt',
    'Hunter River',
    'Huntingdon',
    'Hunts Point',
    'Huntsville',
    'Hurkett',
    'Huron East',
    'Hussar',
    'Hylo',
    'Hythe',
    'Igloolik',
    'Ignace',
    'Ilderton',
    'Ile-a-la-Crosse',
    'Imperial',
    'Indian Harbour',
    'Indian Head',
    'Indian Mountain',
    'Ingersoll',
    'Ingleside',
    'Ingonish',
    'Innisfail',
    'Innisfil',
    'Inukjuak',
    'Inuvik',
    'Invermere',
    'Inverness',
    'Inwood',
    'Iona Station',
    'Iqaluit',
    'Irishtown',
    'Irishtown-Summerside',
    'Irlande',
    'Irma',
    'Iroquois',
    'Iroquois Falls',
    'Irricana',
    'Isaacs Harbour',
    'Ivujivik',
    "Jackson's Point",
    'Janetville',
    'Jarvie',
    'Jemseg',
    "Joe Batt's Arm-Barr'd Islands-Shoal Bay",
    'Joggins',
    'Johnville',
    'Joliette',
    'Jordan',
    'Jordan Station',
    'Joussard',
    'Joyceville',
    'Kagawong',
    'Kaministiquia',
    'Kamloops',
    'Kamsack',
    'Kanata',
    'Kangiqsualujjuaq',
    'Kangiqsujuaq',
    'Kangirsuk',
    'Kapuskasing',
    'Kasabonika',
    'Kashechewan',
    'Kaslo',
    'Katrine',
    'Kawawachikamach',
    'Kearney',
    'Keene',
    'Keewatin',
    'Kegaska',
    'Kelowna',
    'Kelvington',
    'Kemptville',
    'Kenabeek',
    'Kenaston',
    'Kennedy',
    'Kennetcook',
    'Kenora',
    'Kenosee Lake',
    'Kensington',
    'Kent Bridge',
    'Kentville',
    'Keremeos',
    'Kerrobert',
    'Kerwood',
    'Keswick',
    'Killaloe',
    'Killam',
    'Killarney',
    'Killarney Road',
    'Kilworthy',
    'Kimberley',
    'Kincardine',
    'Kindersley',
    'King',
    'King City',
    'Kingfisher Lake',
    'Kingsbury',
    'Kingsclear',
    'Kingsey Falls',
    "King's Point",
    'Kingston',
    'Kingsville',
    'Kinistino',
    'Kinmount',
    'Kinuso',
    'Kipawa',
    'Kipling',
    'Kippen',
    'Kippens',
    'Kirkfield',
    'Kirkland',
    'Kirkland Lake',
    'Kirkton',
    'Kitchener',
    'Kitimat',
    'Kitscoty',
    'Kitwanga',
    'Kleinburg Station',
    'Klemtu',
    'Knowlton',
    'Kohler',
    'Komoka',
    'Kouchibouguac',
    'Kugaaruk',
    'Kugluktuk',
    'Kuujjuaq',
    'Kyle',
    'Kyuquot',
    'Labelle',
    'Labrador City',
    'Labrecque',
    'La Broquerie',
    'Lac-Bouchette',
    'Lac Brome',
    'Lac-des-Plages',
    'Lac du Bonnet',
    'Lac-du-Cerf',
    'Lachute',
    'Lac La Biche',
    'Lac-Megantic',
    'Lacolle',
    'Lacombe',
    'La Conception',
    'La Crete',
    'La Croche',
    'Lac-Sainte-Marie',
    'Lac Seul',
    'Lac-Simon',
    'Lac-Superieur',
    'Ladysmith',
    'Lafleche',
    'Lafond',
    'Laforce',
    'Laggan',
    'La Guadeloupe',
    'Lake Cowichan',
    'Lakefield',
    'Lakehurst',
    'Lake Lenore',
    'Lake Louise',
    'Lakeshore',
    'Lakeview',
    'Lakeville',
    'La Loche',
    'La Macaza',
    'Lamarche',
    'Lambton',
    'Lambton Shores',
    'La Minerve',
    'Lamont',
    'Lampman',
    'Lancaster',
    'Langenburg',
    'Langford',
    'Langham',
    'Langley',
    'Lanigan',
    'Lanoraie',
    "L'Anse-au-Loup",
    'La Patrie',
    'La Peche',
    'La Prairie',
    'Larder Lake',
    'La Reine',
    'La Riviere',
    'Lark Harbour',
    'La Ronge',
    'Larouche',
    'Larrys River',
    'La Salette',
    'La Salle',
    'La Sarre',
    "L'Ascension",
    'La Scie',
    'Lashburn',
    "L'Assomption",
    'La Tuque',
    'Laval',
    'Laval-Ouest',
    'Lavaltrie',
    'Laverlochere',
    'Lawrencetown',
    'Lawrenceville',
    "Lax Kw'alaams",
    'Leader',
    'Leamington',
    'Leask',
    'Lebel-sur-Quévillon',
    'Leclercville',
    'Leduc',
    'Lefroy',
    'Legal',
    'Lemieux',
    'Le Moyne',
    'Lennox Island',
    'Lennoxville',
    "L'Epiphanie",
    'Leroy',
    'Les Cedres',
    'Les Coteaux',
    'Les Mechins',
    'Lestock',
    'Lethbridge',
    'Levack',
    'Lewisporte',
    'Liberty',
    'Likely',
    "L'Ile Perrot",
    'Limehouse',
    'Limoges',
    'Lincoln',
    'Linden',
    'Lindsay',
    'Lions Bay',
    'Listowel',
    'Little Bay East',
    'Little Britain',
    'Little Burnt Bay',
    'Little Current',
    'Little Fort',
    'Little Judique',
    'Little Narrows',
    'Little Swan River',
    'Lively',
    'Liverpool',
    'Lloydminster',
    'Lochaber',
    'Lockeport',
    'Lockport',
    'Lockstead',
    'Logan Lake',
    'Logy Bay-Middle Cove-Outer Cove',
    'Londesborough',
    'London',
    'Londonderry',
    'Long Reach',
    'Long Sault',
    'Longue-Pointe-de-Mingan',
    'Longueuil',
    'Longview',
    'Longwood',
    'Loon Lake',
    'Loreburn',
    'Loretto',
    'Lorraine',
    'Lorrainville',
    'Louisbourg',
    'Louisdale',
    'Louiseville',
    'Lourdes',
    'Love',
    'Lower Coverdale',
    'Lower Island Cove',
    "Lower L'Ardoise",
    'Lower West Pubnico',
    'Lucan',
    'Lucknow',
    'Lucky Lake',
    'Lumby',
    'Lumsden',
    'Lund',
    'Lundbreck',
    'Lunenburg',
    'Luseland',
    'Luskville',
    'Lutes Mountain',
    'Lyalta',
    'Lyn',
    'Lyons Brook',
    'Lytton',
    'Maberly',
    'Mabou',
    'Macdiarmid',
    'Macdonald',
    'MacGregor',
    'Mackenzie',
    'Macklin',
    'MacTier',
    'Madden',
    'Madoc',
    'Magog',
    'Magrath',
    'Mahone Bay',
    'Maidstone',
    'Main Brook',
    'Malahide',
    'Malartic',
    'Mallaig',
    'Mallorytown Landing',
    'Malton',
    'Mandeville',
    'Manitou',
    'Manitouwadge',
    'Manitowaning',
    'Maniwaki',
    'Mankota',
    'Manning',
    'Mannville',
    'Manor',
    'Manotick Station',
    'Manouane',
    'Manseau',
    'Mansfield-et-Pontefract',
    'Mansons Landing',
    'Maple',
    'Maple Creek',
    'Maple Leaf',
    'Maple Ridge',
    'Marathon',
    'Marchand',
    'East Margaree',
    'Marieville',
    'Marion Bridge',
    'Markdale',
    'Markham',
    'Markstay',
    'Marmora',
    'Marshall',
    'Marston',
    'Martensville',
    'Martins Corner',
    'Martinville',
    'Marwayne',
    'Maryfield',
    "Mary's Harbour",
    'Mascouche',
    'Maskinonge',
    'Masset',
    'Massey',
    'Massey Drive',
    'Masson-Angers',
    'Massueville',
    'Matachewan',
    'Matagami',
    'Matawak',
    'Matheson',
    'Mattawa',
    'Maugerville',
    'Maxville',
    'Mayfair',
    'Mayne',
    'Maynooth',
    'Mayo',
    'Mazerolle Settlement',
    'McDougall',
    'McGregor',
    'McLennan',
    'McLeod Hill',
    'McMasterville',
    'Meadow Creek',
    'Meadow Lake',
    'Meaford',
    'Meaghers Grant',
    'Medicine Hat',
    'Medstead',
    'Melfort',
    'Melita',
    'Melvern Square',
    'Melville',
    'Memramcook',
    'Mercier',
    'Merigomish',
    'Merlin',
    'Merrickville',
    'Merritt',
    'Mesachie Lake',
    'Meskanaw',
    'Messines',
    'Meteghan',
    'Meteghan River',
    'Miami',
    'Midale',
    'Middle Musquodoboit',
    'Middle Sackville',
    'Middleton',
    'Midhurst',
    'Midland',
    'Mildmay',
    'Milestone',
    'Milk River',
    'Millarville',
    'Millbank',
    'Mill Bay',
    'Millbrook',
    'Mill Creek',
    'Mille-Isles',
    'Millet',
    'Millgrove',
    "Milltown-Head of Bay D'Espoir",
    'Milo',
    'Milton',
    'Milverton',
    'Minburn',
    'Mindemoya',
    'Minesing',
    'Minitonas',
    'Minnedosa',
    'Minnitaki',
    'Minto',
    'Mirabel',
    'Miramichi',
    'Miscouche',
    'Mission',
    'Mississauga',
    'Mississippi Mills',
    'Mistissini',
    'Mitchell',
    'Moberly Lake',
    'Mobile',
    'Moisie',
    'Monastery',
    'Moncton',
    'Monkland',
    'Monkton',
    'Mono',
    'Montague',
    'Montebello',
    'Mont-Laurier',
    'Montpellier',
    'Montreal',
    'Montreal East',
    'Montreal Lake',
    'Montreal West',
    'Mount Royal',
    'Mont-Saint-Gregoire',
    'Mont-Saint-Hilaire',
    'Mont-Saint-Michel',
    'Mont-Tremblant',
    'Moonbeam',
    'Mooretown',
    'Moose Creek',
    'Moosehorn',
    'Moose Jaw',
    'Moosomin',
    'Moosonee',
    'Moraviantown',
    'Morden',
    'Morell',
    'Morin-Heights',
    'Morinville',
    'Morley',
    'Morrin',
    'Morris',
    'Morrisburg',
    'Morrisville',
    'Morson',
    'Moser River',
    'Mossbank',
    'Mountain Grove',
    'Mount Brydges',
    'Mount Currie',
    'Mount Elgin',
    'Mount Forest',
    'Mount Moriah',
    'Mount Pearl',
    'Mount Stewart',
    'Mount Uniacke',
    'Muenster',
    'Mulgrave',
    'Mulmur',
    'Mundare',
    'Munson',
    'Murphy Lake',
    'Murray Harbour',
    'Musgrave Harbour',
    'Musgravetown',
    'Musquodoboit Harbour',
    'Nackawic',
    'Naicam',
    'Nairn Centre',
    'Nakusp',
    'Nampa',
    'Namur',
    'Nanaimo',
    'Nantes',
    'Nanton',
    'Napierville',
    'Narol',
    'Nazko',
    'Neebing',
    'Neilburg',
    'Nelson',
    'Nepean',
    'Nestleton Station',
    'Nestow',
    'Neudorf',
    'New Aiyansh',
    'New Albany',
    'New Bothwell',
    'New Brigden',
    'Newburgh',
    'Newbury',
    'Newcastle',
    'Newdale',
    'New Denver',
    'New Dundee',
    'New Germany',
    'New Glasgow',
    'New Hamburg',
    'New Harbour',
    'New Haven',
    'New Hazelton',
    'Newmarket',
    'New Minas',
    'New Norway',
    'New Osnaburgh',
    'New Ross',
    'Newtonville',
    'New Victoria',
    'New Waterford',
    'New Westminster',
    'Niagara Falls',
    'Niagara-on-the-Lake',
    'Nicolet',
    'Nictaux',
    'Nimpo Lake',
    'Nine Mile River',
    'Nipawin',
    'Nipigon',
    'Nisku',
    'Niton Junction',
    'Niverville',
    'Nobel',
    'Nobleford',
    'Nobleton',
    'Nominingue',
    'Nordegg',
    'Norfolk County',
    'Normandin',
    "Norman's Cove-Long Cove",
    'Norman Wells',
    'Norquay',
    'Norris Arm',
    'Norris Point',
    'North Alton',
    'North Battleford',
    'North Bay',
    'North Bend',
    'North Chegoggin',
    'North Cobalt',
    'North Delta',
    'North Hatley',
    'North Lunenburg',
    'North Perth',
    'North Preston',
    'North Russell',
    'North Saanich',
    'Northside East Bay',
    'North Spirit Lake',
    'North Tay',
    'North Vancouver',
    'Northville',
    'North West River',
    'North Woodslee',
    'North York',
    'Norton',
    'Norval',
    'Norwich',
    'Norwood',
    'Notre-Dame-de-Ham',
    'Notre-Dame-de-la-Merci',
    'Notre-Dame-de-la-Salette',
    "Notre-Dame-de-l'Ile-Perrot",
    'Notre Dame de Lourdes',
    'Notre-Dame-de-Montauban',
    'Notre-Dame-de-Pontmain',
    'Notre-Dame-des-Bois',
    'Notre-Dame-des-Prairies',
    'Notre-Dame-du-Bon-Conseil',
    'Notre-Dame-du-Laus',
    'Nottawa',
    'Novar',
    'Noyan',
    'Oakbank',
    'Oak Bay',
    'Oak Bluff',
    'Oakburn',
    'Oakfield',
    'Oak Lake',
    'Oakville',
    'Oakwood',
    'Obedjiwan',
    'Odessa',
    'Ogoki',
    'Ohaton',
    'Ohsweken',
    'Oil Springs',
    'Oka',
    'Okanagan Falls',
    'Okotoks',
    'Oldcastle',
    'Old Chelsea',
    'Old Crow',
    'Olds',
    "O'Leary",
    'Oliver',
    'Ompah',
    'Onanole',
    'Onion Lake',
    'Onoway',
    'Orangeville',
    'Orchardville',
    'Orford Lake',
    'Orillia',
    'Ormstown',
    'Oromocto',
    'Orton',
    'Osgoode',
    'Oshawa',
    'Osler',
    'Osoyoos',
    'Ottawa',
    'Otterburne',
    'Otterburn Park',
    'Outlook',
    'Outremont',
    'Owen Sound',
    'Oxbow',
    'Oxford',
    'Oxford Station',
    'Oyen',
    'Paisley',
    'Pakenham',
    'Pakwaw Lake',
    'Palmarolle',
    'Palmer Rapids',
    'Palmerston',
    'Pangnirtung',
    'Pansy',
    'Papineauville',
    'Paradise Hill',
    'Paradise Valley',
    'Parkhill',
    'Parksville',
    'Parkwood',
    'Parrsboro',
    'Parry Sound',
    'Pasadena',
    'Passekeag',
    'Patricia',
    'Paulatuk',
    'Peace River',
    'Peachland',
    'Peawanuck',
    'Pefferlaw',
    'Peguis',
    'Pelham',
    'Pelican Narrows',
    'Pelly',
    'Pelly Crossing',
    'Pemberton',
    'Pembroke',
    'Pender Island',
    'Penetanguishene',
    'Pennfield',
    'Penobsquis',
    'Pense',
    'Penticton',
    'Peribonka',
    'Perryvale',
    'Perth',
    'Petawawa',
    'Peterborough',
    'Petersfield',
    'Petit-Cap',
    'Petitcodiac',
    'Petitcodiac East',
    'Petrolia',
    'Petty Harbour',
    'Petty Harbour-Maddox Cove',
    'Pickardville',
    'Pickerel',
    'Pickering',
    'Pickle Lake',
    'Picton',
    'Pictou',
    'Picture Butte',
    'Piedmont',
    'Pierceland',
    'Pierrefonds',
    'Pierreville',
    'Pikangikum',
    'Pilot Butte',
    'Pilot Mound',
    'Pinantan Lake',
    'Pinawa',
    'Pincher Creek',
    'Pincourt',
    'Pine Falls',
    'Pine Grove',
    'Pinehouse Lake',
    'Pine River',
    'Pinewood',
    'Piney',
    'Pitt Meadows',
    'Placentia',
    'Plaisance',
    'Plamondon',
    'Plantagenet Station',
    'Pleasant Bay',
    'Pleasantville',
    'Plumas',
    'Pointe-aux-Loups',
    'Pointe-aux-Outardes',
    'Pointe-aux-Trembles',
    'Pointe-Calumet',
    'Pointe-Claire',
    'Pointe-Lebel',
    'Point Lance',
    'Point Leamington',
    'Pond Inlet',
    'Ponoka',
    'Pontypool',
    "Pool's Cove",
    'Poplar Hill',
    'Porcupine Plain',
    'Porquis Junction',
    'Portage la Prairie',
    'Port Alberni',
    'Port Alice',
    'Port au Port',
    'Port au Port East',
    'Port Blandford',
    'Port-Cartier',
    'Port Clements',
    'Port Colborne',
    'Port Coquitlam',
    'Port Dover',
    'Port Dufferin',
    'Port Elgin',
    'Porters Lake',
    'Port Hardy',
    'Port Hawkesbury',
    'Port Hood',
    'Port Hope',
    'Port Howe',
    'Port McNeill',
    'Port McNicoll',
    'Port-Menier',
    'Port Moody',
    'Port Philip',
    'Port Renfrew',
    'Port Rexton',
    'Port Royal',
    'Port Saunders',
    'Port Severn',
    'Port Stanley',
    'Port Sydney',
    'Portugal Cove',
    'Potter',
    'Powassan',
    'Powell River',
    'Powerview',
    'Prairie River',
    'Preeceville',
    'Preissac',
    'Prelate',
    'Prescott',
    'Prevost',
    'Priceville',
    'Priddis',
    'Prince Albert',
    'Prince Edward',
    'Prince George',
    'Princeport',
    'Prince Rupert',
    'Princeton',
    'Pritchard',
    'Prophet River',
    'Provost',
    'Pugwash',
    'Punnichy',
    'Puslinch',
    'Puvirnituq',
    'Qualicum Beach',
    "Qu'Appelle",
    'Quaqtaq',
    'Queen Charlotte',
    'Quesnel',
    'Quill Lake',
    'Quinte West',
    'Quispamsis',
    'Quyon',
    'Racine',
    'Radisson',
    'Radium Hot Springs',
    'Radville',
    'Radway',
    'Gamèti',
    'Rainbow Lake',
    'Raith',
    'Ramea',
    'Ramore',
    'Rankin Inlet',
    'Rapide-Danseur',
    'Rawdon',
    'Raymond',
    'Raymonds Corners',
    'Raymore',
    'Reaboro',
    'Red Bank',
    'Redbridge',
    'Redcliff',
    'Red Deer',
    'Red Lake',
    'Redvers',
    'Redwater',
    'Regina',
    'Regina Beach',
    'Reidville',
    'Renfrew',
    'Renous',
    'Repentigny',
    'Naujaat',
    'Reserve Mines',
    'Reston',
    'Revelstoke',
    'Rhodes Corner',
    'Richards Landing',
    'Richer',
    'Richibucto',
    'Richmond',
    'Richmond Hill',
    'Ridgetown',
    'Ridgeway',
    'Rigaud',
    'Rimbey',
    'Riondel',
    'Ripley',
    'Ripon',
    'Rivercourse',
    'River Denys',
    'River John',
    'Riverport',
    'Rivers',
    'Riverton',
    'River Valley',
    'Riverview',
    'Riviere-a-Pierre',
    'Riviere-Beaudette',
    'Riviere-Heva',
    'Riviere-Rouge',
    "St. Paul's River",
    'Roberta',
    'Roberts Creek',
    'Roberval',
    'Roblin',
    'Rocanville',
    'Rockglen',
    'Rockland',
    'Rockton',
    'Rockwood',
    'Rockyford',
    'Rocky Harbour',
    'Rocky Mountain House',
    'Rocky View',
    'Roddickton',
    'Rodney',
    'Rogersville',
    'Roland',
    'Rolla',
    'Roquemaure',
    'Rosedale',
    'Rosemary',
    'Rosemere',
    'Rosemont',
    'Roseneath',
    'Rosenort',
    'Rosetown',
    'Rose Valley',
    'Rosewood',
    'Rossburn',
    'Rosseau Falls',
    'Rossland',
    'Rosthern',
    'Rothesay',
    'Rougemont',
    'Round Lake Centre',
    'Rouyn-Noranda',
    'Roxboro',
    'Roxton Falls',
    'Roxton Pond',
    'Rumsey',
    'Rusagonis',
    'Rushoon',
    'Russell',
    'Rutherglen',
    'Rycroft',
    'Ryley',
    'Saanich',
    'Saanichton',
    'Sabrevois',
    'Sachigo Lake',
    'Sackville',
    'Saddle Lake',
    'Saguenay',
    "Saint-Adolphe-d'Howard",
    'Saint-Adrien',
    'Saint-Alban',
    'Saint-Albert',
    'Saint-Alphonse-Rodriguez',
    'Saint-Amable',
    'Saint-Ambroise',
    'Saint-Andre-Avellin',
    "Saint-Andre-d'Argenteuil",
    'Saint Andrews',
    'Saint-Anicet',
    'Saint-Armand',
    'Saint-Augustin',
    'Saint-Basile-le-Grand',
    'Saint-Benoit-du-Lac',
    'Saint-Bruno',
    'Saint-Bruno-de-Montarville',
    'Saint-Calixte',
    'Saint-Camille',
    'Saint-Cesaire',
    'Saint-Charles-Borromee',
    'Saint-Chrysostome',
    'Saint-Cleophas-de-Brandon',
    'Saint-Clet',
    'Saint-Colomban',
    'Saint-Come--Liniere',
    'Saint-Constant',
    'Saint-Cyrille-de-Wendover',
    'Saint-Denis',
    'Saint-Dominique',
    'Saint-Dominique-du-Rosaire',
    'Sainte-Adele',
    'Sainte-Agathe-des-Monts',
    'Sainte-Anne-de-Bellevue',
    'Sainte-Anne-de-la-Perade',
    'Sainte-Anne-de-la-Rochelle',
    'Sainte-Anne-des-Monts',
    'Sainte-Anne-des-Plaines',
    'Sainte-Anne-du-Lac',
    'Sainte-Catherine',
    'Sainte-Cecile-de-Masham',
    'Sainte-Clotilde-de-Chateauguay',
    'Sainte-Clotilde-de-Horton',
    'Saint-Edouard-de-Maskinonge',
    "Sainte-Emelie-de-l'Energie",
    'Sainte-Genevieve',
    'Sainte-Germaine-Boule',
    'Sainte-Gertrude-Manneville',
    'Sainte-Julie',
    'Sainte-Julienne',
    'Sainte-Lucie-des-Laurentides',
    'Sainte-Madeleine',
    'Sainte-Marcelline-de-Kildare',
    'Sainte-Marguerite-du-Lac-Masson',
    'Sainte-Marie-de-Blandford',
    'Sainte-Marthe',
    'Sainte-Marthe-sur-le-Lac',
    'Sainte-Melanie',
    'Saint-Emile-de-Suffolk',
    'Sainte-Monique',
    'Saint-Esprit',
    'Sainte-Thecle',
    'Sainte-Therese',
    'Saint-Etienne-de-Bolton',
    "Saint-Eugene-d'Argentenay",
    'Saint-Eustache',
    'Sainte-Victoire-de-Sorel',
    'Saint-Felicien',
    'Saint-Felix-de-Dalquier',
    'Saint-Felix-de-Kingsey',
    'Saint-Felix-de-Valois',
    'Saint-Francois-du-Lac',
    'Saint-Fulgence',
    'Saint-Gabriel',
    'Saint-Gabriel-de-Valcartier',
    'Saint-Gedeon',
    'Saint-Georges',
    'Saint-Georges-de-Clarenceville',
    'Saint-Georges-de-Windsor',
    'Saint-Germain-de-Grantham',
    'Saint-Guillaume',
    'Saint-Honore-de-Shenley',
    'Saint-Hugues',
    'Saint-Hyacinthe',
    'Saint-Jacques',
    'Saint-Jacques-le-Mineur',
    'Saint-Jean-de-Matha',
    'Saint-Jean-sur-Richelieu',
    'Saint-Jerome',
    'Saint-Joachim-de-Shefford',
    'Saint John',
    'Saint-Joseph-du-Lac',
    'Saint-Jude',
    'Saint-Lambert',
    'Saint-Laurent',
    'Saint-Leon',
    'Saint-Leonard',
    'Saint-Leonard-de-Portneuf',
    'Saint-Liboire',
    'Saint-Liguori',
    'Saint-Lin-Laurentides',
    'Saint-Louis-de-Gonzague',
    'Saint-Louis de Kent',
    'Saint-Luc-de-Vincennes',
    'Saint-Ludger',
    'Saint-Malo',
    'Saint-Marc-des-Carrieres',
    'Saint-Mathieu-de-Beloeil',
    "Saint-Mathieu-d'Harricana",
    'Saint-Maxime-du-Mont-Louis',
    'Saint-Michel',
    'Saint-Michel-des-Saints',
    'Saint-Modeste',
    'Saint-Narcisse',
    'Saint-Ours',
    'Saint-Patrice-de-Sherrington',
    'Saint-Paulin',
    'Saint-Philippe',
    'Saint-Pie',
    'Saint-Placide',
    'Saint-Polycarpe',
    'Saint-Prime',
    'Saint-Remi',
    'Saint-Remi-de-Tingwick',
    'Saint-Roch-de-Richelieu',
    'Saint-Romain',
    'Saint-Samuel',
    'Saint-Sauveur',
    'Saint-Stanislas',
    'Saint-Sylvere',
    'Saint-Telesphore',
    "Saint-Theodore-d'Acton",
    'Saint-Theophile',
    'Saint-Thomas',
    'Saint-Tite',
    'Saint-Ubalde',
    'Saint-Valere',
    'Saint-Wenceslas',
    'Saint-Zotique',
    'Salaberry-de-Valleyfield',
    'Salford',
    'Salisbury',
    'Salluit',
    'Salmo',
    'Salmon Arm',
    'Saltcoats',
    'Salt Springs',
    'Sandspit',
    'Sandy Cove',
    'Sandy Lake',
    'Sanford',
    'Sardis',
    'Sarnia',
    'Saskatoon',
    'Saturna',
    'Saugeen Shores',
    'Saulnierville',
    'Sault Ste. Marie',
    'Savona',
    'Sawyerville',
    'Sayward',
    'Scandia',
    'Schefferville',
    'Schreiber',
    'Schumacher',
    'Scotch Lake',
    'Scotland',
    'Scotsburn',
    'Scotstown',
    'Scoudouc',
    'Scugog',
    'Seaforth',
    'Seba Beach',
    'Sebright',
    'Sebringville',
    'Sechelt',
    'Second North River',
    'Sedgewick',
    'Seldom-Little Seldom',
    'Selkirk',
    'Senneterre',
    'Sept-Iles',
    'Severn Bridge',
    'Shallow Lake',
    'Shamattawa',
    'Sharbot Lake',
    'Sharon',
    'Shaunavon',
    'Shawinigan',
    'Shawville',
    'Shedden',
    'Shediac',
    'Shediac Bridge',
    'Shediac River',
    'Sheet Harbour',
    'Sheffield',
    'Shefford-Ouest',
    'Sheguiandah',
    'Shelburne',
    'Shellbrook',
    'Shell Lake',
    'Shemogue',
    'Sherbrooke',
    'Sherkston',
    'Sherwood Park',
    'Shoal Lake',
    'Shubenacadie',
    'Shuniah',
    'Sicamous',
    'Sidney',
    'Silver Creek',
    'Silver Valley',
    'Simcoe',
    'Simoom Sound',
    'Sioux Lookout',
    'Sioux Narrows',
    'Skidegate',
    'Skiff',
    'Slate Falls',
    'Slocan Park',
    'Smithers',
    'Smiths Falls',
    'Smithville',
    'Smoky Lake',
    'Snow Lake',
    'Sointula',
    'Sombra',
    'Somerset',
    'Sooke',
    'Sorel-Tracy',
    'Sorrento',
    'Souris',
    'South Augusta',
    'South Bruce Peninsula',
    'Southern Harbour',
    'South Esk',
    'Southey',
    'South Gillies',
    'South Gower',
    'South Mountain',
    'South Porcupine',
    'South River',
    'Southwold',
    "Spaniard's Bay",
    'Sparwood',
    'Spencerville',
    'Spences Bridge',
    'Spiritwood',
    'Split Lake',
    'Spragge',
    'Sprague',
    'Springdale',
    'Springfield',
    'Springford',
    'Springhill',
    'Spring Hill',
    'Springside',
    'Sprucedale',
    'Spruce Grove',
    'Spruce View',
    'Squamish',
    'St. Agatha',
    "St. Alban's",
    'St-Albert',
    'St. Albert',
    'Stanbridge East',
    'Standard',
    'Stand Off',
    'Stanley Mission',
    'Stanstead',
    'Stanstead-Est',
    'St. Anthony',
    'Star Corners',
    'Starkville',
    'Stauffer',
    'Stavely',
    'Stayner',
    "St. Bernard's-Jacques Fontaine",
    'St. Brieux',
    'St. Catharines',
    'Steady Brook',
    'Ste. Anne',
    'Ste-Anne-de-Prescott',
    'Steinbach',
    'Stellarton',
    'Stephenville',
    'Stephenville Crossing',
    'Ste. Rose du Lac',
    'Stettler',
    'Stewiacke',
    'St. George',
    'Stillwater Lake',
    'Stirling',
    'Stittsville',
    "St. Jacques-Coomb's Cove",
    'St. Laurent',
    'St. Lawrence',
    'St. Lina',
    'St. Louis',
    'St. Malo',
    'St. Marys',
    "St. Mary's",
    'Stockton',
    'Stonewall',
    'Stoney Creek',
    'Stoney Point/Pointe-aux-Roches',
    'Stony Mountain',
    'Stony Plain',
    'Stornoway',
    'Storthoaks',
    'Stouffville',
    'Stoughton',
    'St. Paul',
    'St. Peters Bay',
    'St-Pierre-Jolys',
    'Strasbourg',
    'Stratford',
    'Strathclair',
    'Strathmore',
    'Strathroy',
    'Strome',
    'St. Stephen',
    'St. Theresa Point',
    'St. Thomas',
    'Stukely-Sud',
    'Sturgeon Falls',
    'Sturgis',
    'St. Walburg',
    'Sugar Camp',
    'Summerland',
    'Summerside',
    'Summerstown',
    'Summerville',
    'Sunderland',
    'Sundre',
    'Sundridge',
    'Sun Peaks',
    'Sunset House',
    'Surrey',
    'Sussex',
    'Sutton',
    'Sutton West',
    'Swan Lake',
    'Swastika',
    'Swift Current',
    'Sydenham',
    'Sydney River',
    'Sylvan Lake',
    'Taber',
    'Tahsis',
    'Taloyoak',
    'Tangier',
    'Tantallon',
    'Tappen',
    'Taschereau',
    'Tasiujaq',
    'Tatamagouche',
    'Tavistock',
    'Taylor',
    'Tecumseh',
    'Teeswater',
    'Telkwa',
    'Temagami',
    'Temiscaming',
    'Temiskaming Shores',
    'Terrace',
    'Terrace Bay',
    'Terrebonne',
    'Terrenceville',
    'Teulon',
    'Thamesville',
    'The Pas',
    'Thessalon',
    'Thompson',
    'Thorburn',
    'Thorhild',
    'Thorndale',
    'Thornhill',
    'Thornton',
    'Thorold',
    'Thorsby',
    'Three Brooks',
    'Three Hills',
    'Thunder Bay',
    'Thurso',
    'Tignish',
    'Tilbury',
    'Tilley',
    'Tillsonburg',
    'Timberlea',
    'Timmins',
    'Tisdale',
    'Tiverton',
    'Tlell',
    'Tobermory',
    'Tofield',
    'Torbay',
    'Toronto',
    'Torquay',
    'Torrington',
    'Tottenham',
    'Townsend',
    'Trail',
    'Trenton',
    'Tres-Saint-Redempteur',
    'Trinity',
    'Trois-Rivières',
    'Troy',
    'Truro',
    'Tuktoyaktuk',
    'Tumbler Ridge',
    'Turner Valley',
    'Turnor Lake',
    'Turtleford',
    'Tusket',
    'Tweed',
    'Twillingate',
    'Tyne Valley',
    'Ucluelet',
    'Ulverton',
    'Umiujaq',
    'Ungers Corner',
    'Unionville',
    'Unity',
    'Upper Branch',
    'Upper Ferry',
    'Upper Golden Grove',
    'Upper Hammonds Plains',
    'Upper Island Cove',
    'Upper Kennetcook',
    'Upper Keswick',
    'Upper Mills',
    'Upper Rawdon',
    'Upper Tantallon',
    'Upper Tracy',
    'Upsala',
    'Upton',
    'Urbainville',
    'Utopia',
    'Utterson',
    'Uxbridge',
    'Valcourt',
    'Val-David',
    'Val-des-Monts',
    "Val-d'Or",
    'Valemount',
    'Val-Joli',
    'Valley',
    'Valleyview',
    'Val-Morin',
    'Van Anda',
    'Van Bruyssel',
    'Vancouver',
    'Vanderhoof',
    'Vanessa',
    'Vankleek Hill',
    'Vanscoy',
    'Varennes',
    'Vaudreuil-Dorion',
    'Vaudreuil-sur-le-Lac',
    'Vaughan',
    'Vavenby',
    'Vegreville',
    'Venise-en-Quebec',
    'Venosta',
    'Vercheres',
    'Verdun',
    'Vermilion',
    'Vermilion Bay',
    'Verner',
    'Vernon',
    'Vernon Bridge',
    'Verona',
    'Vibank',
    'Victoria',
    'Victoria Harbour',
    'Victoriaville',
    'Viking',
    'Ville-Marie',
    'Vilna',
    'Vineland Station',
    'Virden',
    'Vonda',
    'Vulcan',
    'Wabasca-Desmarais',
    'Wabigoon',
    'Wabowden',
    'Wabush',
    'Wadena',
    'Wainwright',
    'Wakaw',
    'Wakefield',
    'Waldheim',
    'Wallace',
    'Wallaceburg',
    'Wallenstein',
    'Walton',
    'Wandering River',
    'Wapawsik',
    'Warden',
    'Wardlow',
    'Wardner',
    'Warman',
    'Warminster',
    'Warner',
    'Warren',
    'Warsaw',
    'Warspite',
    'Warwick',
    'Warwick Settlement',
    'Wasaga Beach',
    'Washago',
    'Waskaganish',
    'Waskatenau',
    'Waskesiu Lake',
    'Waswanipi',
    'Waterdown',
    'Waterford',
    'Waterloo',
    'Water Valley',
    'Waterville',
    'Watford',
    'Watrous',
    'Watson',
    'Watson Lake',
    'Waubaushene',
    'Wawa',
    'Wawanesa',
    'Wawota',
    'Weagamow Lake',
    'Weedon',
    'Wekweètì',
    'Weldon',
    'Welland',
    'Wellington',
    'Wellington Station',
    'Wells',
    'Wembley',
    'Wemindji',
    'Wentworth-Nord',
    'Westbank',
    'West Bay',
    'West Brome',
    'West Chezzetcook',
    'West Collette',
    'Western Shore',
    'Westerose',
    'Westlock',
    'West Lorne',
    'Westmount',
    'West Nipissing',
    'Weston',
    'Westport',
    'Westville',
    'Wetaskiwin',
    'Weyburn',
    'Whapmagoostui',
    'Whatì',
    'Wheatley',
    'Wheatley River',
    'Whistler',
    'Whitbourne',
    'Whitby',
    'Whitchurch–Stouffville',
    'Whitecap',
    'White City',
    'Whitecourt',
    'Whitedog',
    'Whitefish',
    'Whitehorse',
    'White Lake',
    'Whitemouth',
    'White Rock',
    'Whiteway',
    'Whitewood',
    'Whitney',
    'Whycocomagh',
    'Wickham',
    'Wilberforce',
    'Wilburn',
    'Wilcox',
    'Wilkie',
    'Williamsburg',
    'Williams Lake',
    'Williamswood',
    'Willowdale',
    'Winchester',
    'Windermere',
    'Windsor',
    'Windsor Junction',
    'Winfield',
    'Lake Country',
    'Wingham',
    'Winkler',
    'Winlaw',
    'Winnipeg',
    'Winnipeg Beach',
    'Winnipegosis',
    'Winsloe South',
    'Winterton',
    'Witless Bay',
    'Wittenburg',
    'Wolfville',
    'Wollaston Lake',
    'Wolseley',
    'Woodbridge',
    'Wood Islands',
    'Woodlawn',
    'Woodmore',
    'Woodridge',
    'Woodstock',
    'Woodview',
    'Woodville',
    'Woody Point',
    'Worsley',
    'Wotton',
    'Wright-Gracefield-Northfield',
    'Wrigley',
    'Wyecombe',
    'Wyevale',
    'Wynyard',
    'Wyoming',
    'Yamachiche',
    'Yamaska',
    'Yarker',
    'Yarmouth',
    'Yellowknife',
    'Yorkton',
    'Youbou',
    'Young',
    'Youngstown',
    'Zealand',
    'Zeballos',
    'Zehner',
    'Zenon Park',
    'Zurich',
    'Beauceville',
    'Halifax',
    "St. John's",
    'Beauport',
    'Beaupre',
    'Berthier-Sur-Mer',
    'Charlesbourg',
    'Chateau-Richer',
    'Sainte-Foy',
    'Kamouraska',
    'Québec',
    'Lachenaie',
    'Levis',
    "L'Islet",
    "L'Isle-Verte",
    'St. Thomas de Montmagny',
    'Neuville',
    'Portneuf',
    'Rimouski',
    'Rivière-du-Loup',
    'Saint-Andre',
    'Sydney',
    'Tofino',
    'Jasper',
    "L'Ancienne-Lorette",
    'Saint-Michel-de-Bellechasse',
    'Saint-Lazare-de-Bellechasse',
    'Edmundston',
    'Lachine',
    'St-Jean-Port-Joli',
    'Grand Falls',
    'La Baie',
    'Percé',
    'Tadoussac',
    'Beresford',
    'Baie-Saint-Paul',
    'Calabogie',
    'Thornbury',
    'Celista',
    'Carleton-sur-Mer',
    'Chesley',
    'Walkerton',
    'Hopedale',
    'Boischatel',
    'Cacouna',
    'Campbellton',
    'Bathurst',
    'Matane',
    'White River',
    'North Sydney',
    'Langdon',
    "Saint-Jean-de-l'Ile-d'Orleans",
    'Trois Pistoles',
    'Bergeronnes',
    'Paris',
    'Haliburton',
    'Kedgwick',
    'Inkerman',
    'Caraquet',
    'Amqui',
    'Dalhousie',
    'Millville',
    'Perth-Andover',
    'Nain',
    "Saint Fintan's",
    'Siksika',
    'Sturgeon Lake',
    'Dawson City',
    'Lameque',
    'Neguac',
    'Allardville',
    'Saint-Quentin',
    'Big Trout Lake',
    'Postville',
    'Doaktown',
    'Boiestown',
    'Chandler',
    'Pabos',
    'Pabos Mills',
    'Newport',
    'Baracvhois',
    'Murdochville',
    'Paspebiac',
    'Bonaventure',
    'New-Richmond',
    'Thetford-Mines',
    'Port-Daniel',
    'Lac-au-Saumon',
    'New Carlisle',
    'Cabano',
    'Forestville',
    'Mont-Joli',
    'Lillooet',
    'Lac-Etchemin',
    'Armagh',
    'Saint-Malachie',
    'Saint-Anselme',
    'Degelis',
    'La Malbaie',
    'Sainte-Marie',
    'LaSalle',
    'Plessisville',
    'Betsiamites',
    'Saint-Pamphile',
    'Dundas',
    'Scarborough',
    'Stoneham',
    'Les Escoumins',
    'Cole Harbour',
    'West Kelowna',
    'Saint-Mathieu-de-Rioux',
    'Juniper',
    'Plaster Rock',
    'Garibaldi Highlands',
    'Madeira Park',
    'Saint-Ferreol-les-Neiges',
    'Les Eboulements',
    'Kaleden',
    'Wainfleet',
    'Tracadie–Sheila',
    'Pubnico',
    'Sydney Mines',
    'Hartland',
    'Jonquiere',
    'Panorama',
    'Port Perry',
    'Saint-Augustin-de-Desmaures',
    'Donnacona',
    'Ste. Anne de la Pocatière',
    'Saint-Joseph-de-Beauce',
    'Hobbema',
    'Aldergrove',
    'Saint-Léonard',
    'Norway House',
    'Omemee',
    'Red Earth',
    'Cowichan Bay',
    'Paradise Cove',
    'East York',
    'Natuashish',
    'Lansdowne',
    'Bragg Creek',
    'Bella Coola',
    'Galiano Island',
    'Lac Delage',
    'Almonte',
    'Bala',
    'Bloomfield',
    'Bobcaygeon',
    'Saint-Antoine-de-Tilly',
    'Saint-Joseph-de-la-Rive',
    'Ancaster',
    'Tadoule Lake',
    'Shannon',
    'Saint-Henri',
    'Saint-Lambert-de-Lauzon',
    'Pohenegamook',
    'Pont-Rouge',
    'Princeville',
    'Saint-Honore-de-Temiscouata',
    'Saint-Roch-des-Aulnaies',
    'Arkona',
    'York',
    'Naramata',
    'Nanoose Bay',
    'Minden',
    'Warkworth',
    'Schomberg',
    'Hebertville',
    'Coal Harbour',
    '150 Mile House',
    'Caplan',
    'Wiarton',
    'Johnstown',
    'Lanark',
    'Burgessville',
    'Saint-Fabien',
    'Orleans',
    'Grand pre',
    "L'Orignal",
    'Saint-Simon-de-Rimouski',
    'Cardiff',
    'Florenceville-Bristol',
    'Genelle',
    'Saint-Pascal',
    'Shawnigan Lake',
    'Lantzville',
    'Maryhill',
    'Sainte-Brigitte-de-Laval',
    'Thamesford',
    'Saint-Irenee',
    'Saint-Urbain',
    'Clermont',
    'Saint-Hilarion',
    'Petit-Rocher',
    'Vallee-Jonction',
    "L'Etang-du-Nord",
    'Port Carling',
    'Stevensville',
    'Petit-Saguenay',
    'Lac-Beauport',
    'Charny',
    'East Broughton',
    'Lyster',
    'Saint-Raymond-de-Portneuf',
    'Saint-Tite-des-Caps',
    'Deschambault',
    'Tara',
    'Orono',
    'Vienna',
    "L'Anse-Saint-Jean",
    'Saint-Apollinaire',
    'Sayabec',
    'Saint-Ferdinand',
    'Pointe-a-la-Croix',
    'Maria',
    'Val-Brillant',
    'Causapscal',
    "Lion's Head",
    'Shilo',
    'Embrun',
    'Saint-Jean-de-Dieu',
    'Sainte-Agathe',
    'Saint-Leon-de-Standon',
    'Nouvelle',
    'Saint-Etienne-De-Lauzon',
    'Saint Romuald',
    'Ludlow',
    'Wendake',
    'Sainte-Helene-de-Chester',
    'Saint-Antonin',
    'Saint-Rene-de-Matane',
    'Iron Bridge',
    "Saint-Francois-de-l'Ile-d'Orleans",
    'Lower Sackville',
    'Frampton',
    'Tamworth',
    'Southampton',
    'Redwood Meadows',
    'Seven Sisters Falls',
    'Kananaskis',
    'Saint-Damien-de-Buckland',
    'Sainte-Therese-de-Gaspe',
    'Mashteuiatsh',
    'Brookside',
    'Summer Beaver',
    'Webequie',
    'Matapedia',
    'East Lawrencetown',
    'Salt Harbour',
    'Nolalu',
    'Herouxville',
    'Sainte-Come',
    'Saint-Norbert',
    'Petite Riviere',
    'Lac Baker',
    'Emerald Park',
    'Anglemont',
    'Cape Tormentine',
    'Dosquet',
    'Sainte-Claire',
    'Sainte-Justine',
    'Saint-Odilon-de-Cranbourne',
    'Saint-Alexandre-de-Kamouraska',
    'Saint-Agapit',
    'Laurier-station',
    'Wellesley',
    'Keewaywin',
    'Saint-Basile',
    'Shippagan',
    'Clair',
    'Saint-Zacharie',
    'Behchokǫ̀',
    'Laterriere',
    'Atholville',
    'Nigadoo',
    'Eel River Crossing',
    'Saint-Francois-de-Madawaska',
    'Francois',
    'Clarington',
    'Saltspring Island',
    'Belledune',
    'Robertville',
    'Duguayville',
    'Tilley Road',
    'Four Roads',
  ],
}
export default Cities
