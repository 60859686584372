import React, { useContext, useState } from 'react'
import AccountDropDown from '../layout/AccountDropDown'
import './leftBar.scss'
import userIcon from '../../assets/icon/userIcon.svg'
import giftIcon from '../../assets/icon/userIcon.svg'
import archiveIcon from '../../assets/icon/userIcon.svg'
import wishlistIcon from '../../assets/icon/userIcon.svg'
import UserIcon from '../../assets/icon/UserIcon'
import MyOrders from '../../assets/icon/MyOrders'
import LoyaltyPoint from '../../assets/icon/LoyaltyPoint'
import MyWishlist from '../../assets/icon/MyWishlist'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import { CartContext } from '../../context/cart-context'

const LeftBar = () => {
  const [isHovering, setIsHovering] = useState(0)

  const handleMouseEnterOne = () => {
    setIsHovering(1)
  }

  const handleMouseEnterTwo = () => {
    setIsHovering(2)
  }

  const handleMouseEnterThree = () => {
    setIsHovering(3)
  }

  const handleMouseEnterFive = () => {
    setIsHovering(5)
  }

  const handleMouseLeave = () => {
    setIsHovering(0)
  }
  const { showLeftBar, setShowLeftBar } = useContext(CartContext)

  return (
    <div className={showLeftBar ? 'left-bar-fam active' : 'left-bar-fam'}>
      <div className='close'>
        <FaTimes onClick={() => setShowLeftBar(false)} />
      </div>
      <Link to='/account-checkout' style={{ textDecoration: 'none' }}>
        <div
          className='left-bar-optionOne'
          onMouseEnter={handleMouseEnterOne}
          onMouseLeave={handleMouseLeave}
        >
          <div className='left-bar-option-img'>
            {/* <img src={userIcon} alt="user_icon" /> */}
            <UserIcon fill={isHovering === 1 ? '#0C8DBA' : '#03232E'} />
          </div>
          <div className='left-bar-option-text'>
            {' '}
            <h3 style={{ color: isHovering === 1 ? '#0C8DBA' : '#03232E' }}>
              {' '}
              My Account{' '}
            </h3>
          </div>
        </div>
      </Link>

      <Link to='/orders' style={{ textDecoration: 'none' }}>
        <div
          className='left-bar-optionTwo'
          onMouseEnter={handleMouseEnterTwo}
          onMouseLeave={handleMouseLeave}
        >
          <div className='left-bar-option-img'>
            {/* <img src={userIcon} alt="user_icon" /> */}
            <MyOrders fill={isHovering === 2 ? '#0C8DBA' : '#03232E'} />
          </div>
          <div className='left-bar-option-text'>
            {' '}
            <h3 style={{ color: isHovering === 2 ? '#0C8DBA' : '#03232E' }}>
              {' '}
              My Orders{' '}
            </h3>
          </div>
        </div>
      </Link>
      <Link to='/experience-orders' style={{ textDecoration: 'none' }}>
        <div
          className='left-bar-optionTwo'
          onMouseEnter={handleMouseEnterFive}
          onMouseLeave={handleMouseLeave}
        >
          <div className='left-bar-option-img'>
            {/* <img src={userIcon} alt="user_icon" /> */}
            <MyOrders fill={isHovering === 5 ? '#0C8DBA' : '#03232E'} />
          </div>
          <div className='left-bar-option-text'>
            {' '}
            <h3 style={{ color: isHovering === 5 ? '#0C8DBA' : '#03232E' }}>
              {' '}
              Experience Orders{' '}
            </h3>
          </div>
        </div>
      </Link>
      {/* <div
        className="left-bar-optionThree"
        onMouseEnter={handleMouseEnterThree}
        onMouseLeave={handleMouseLeave}
      >
        <div className="left-bar-option-img">
          <LoyaltyPoint fill={isHovering === 3 ? "#0C8DBA" : "#03232E"} />
        </div>
        <div className="left-bar-option-text">
          {" "}
          <h3 style={{ color: isHovering === 3 ? "#0C8DBA" : "#03232E" }}>
            {" "}
            Loyalty Point{" "}
          </h3>
        </div>
      </div> */}
      {/* <div
        className="left-bar-optionFour"
        onMouseEnter={handleMouseEnterFour}
        onMouseLeave={handleMouseLeave}
      >
        <div className="left-bar-option-img">
          <MyWishlist fill={isHovering === 4 ? "#0C8DBA" : "#03232E"} />
        </div>
        <div className="left-bar-option-text">
          {" "}
          <h3 style={{ color: isHovering === 4 ? "#0C8DBA" : "#03232E" }}>
            {" "}
            My Wishlist{" "}
          </h3>
        </div>
      </div> */}
    </div>
  )
}

export default LeftBar
