import React from "react";
import "./securitySettings.scss";

const SecuritySettings = () => {
  return (
    <div>
      <div className="securitySettings-headText">Security </div>
      <div className="securitySettings-headBorder"></div>
      <div className="securitySettings-BodyFlex">
        <div className="securitySettings-formText">
          {" "}
          Change and update your email address.
        </div>
        <div className="securitySettings-FirstFormBody">
          <div className="securitySettings-FirstFormLabel">
            Current Password
          </div>
          <div className="securitySettings-FirstFormInput">
            <input type="password" />
          </div>
        </div>

        <div className="securitySettings-SecondFormBody"></div>
      </div>

      <div className="securitySettings-BodyFlex">
        <div className="securitySettings-formText"></div>
        <div className="securitySettings-FirstFormBody">
          <div
            className="securitySettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          >
            Password
          </div>
          <div className="securitySettings-FirstFormInput">
            <input type="password" />
          </div>
        </div>

        <div className="securitySettings-SecondFormBody">
          <div
            className="securitySettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          >
            Confirm Password
          </div>
          <div className="securitySettings-FirstFormInput">
            <input type="password" />
          </div>
        </div>
      </div>

      <div className="securitySettings-BodyFlex">
        <div className="securitySettings-formText"></div>
        <div className="securitySettings-SaveBtnBody">
          <div
            className=" securitySettings-FirstFormBtn"
            style={{ marginTop: "10px" }}
          >
            Save Changes
          </div>
        </div>
        <div className="securitySettings-SecondFormBody"></div>
      </div>
    </div>
  );
};

export default SecuritySettings;
