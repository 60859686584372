import React from "react";
import "./notificationSettings.scss";

const NotificationSettings = () => {
  return (
    <div>
      <div className="notificationSettings-headText">Security </div>
      <div className="notificationSettings-headBorder"></div>

      <div className="notificationSettings-bodyFlex">
        <div className="notificationSettings-firstFlex">
          Set how you will recieve notifications and alert.
        </div>
        <div className="notificationSettings-secondFlex">
          <div className="notificationSettings-secondFlexa">
            <div className="notificationSettings-notiA">Email Notification</div>
            <div className="notificationSettings-secondSubFlex">
              <div className="notificationSettings-secondCheckCont">
                <input type="checkbox" />
              </div>
              <div className="notificationSettings-checkText">
                Get notified of any changes to your account settings
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="notificationSettings-bodyFlex">
        <div className="notificationSettings-firstFlex"></div>
        <div className="notificationSettings-secondFlex">
          <div className="notificationSettings-secondFlexa">
            <div className="notificationSettings-notiA">SMS Notifications</div>
            <div className="notificationSettings-secondSubFlex">
              <div className="notificationSettings-secondCheckCont">
                <input type="checkbox" />
              </div>
              <div className="notificationSettings-checkText">
                Receive direct SMS notification on your phone
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
