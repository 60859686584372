import React, { useState } from "react";
import { GoCalendar } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import { OrdersModal, Table } from "./components";
import { NewProductModal } from "./components/newProductModal";
import "./index.css";
import { Container } from "./styled";

const SingleShopInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  function handleToggleModal() {
    setShowModal((prevState) => !prevState);
  }

  function handleToggleNewModal() {
    setShowNewModal((prevState) => !prevState);
  }
  return (
    <div className="app-scroll">
      <Container>
        <header>
          <h1 className="u--typo__title2">Shop Page</h1>
          <div className="action--container">
            <div className="select--container">
              <select>
                <option>Filter by</option>
              </select>
            </div>

            {/* <GoCalendar />
              <select>
                <option>This week</option>
              </select> */}

            <div className="product-exportBtn">
              <HiDownload />
              Export
            </div>

            <div
              onClick={handleToggleNewModal}
              className="products-newProductsbtn"
            >
              New Product
            </div>
          </div>
        </header>

        <div className="singleShop-contGen">
          <div className="singleShop-contGenProfBack"></div>
          <div className="singleShop-contGenProfFam">
            <div className="singleShop-contGenProfContt">
              {" "}
              <img />
              <div>Shop Img</div>
            </div>
          </div>
          <div className="singleShop-contGenDetails">
            <div className="singleShop-contGenDetailsN">
              <div className="singleShop-contGenDetailsFF">Shop Name</div>
              <div className="singleShop-contGenDetailII">
                little information of the shop
              </div>
            </div>
            <div className="singleShop-contGenDetailsSelec">
              <select>
                <option>Shop Action</option>
              </select>
            </div>
          </div>
          <div className="singleShop-contGenProfBotLine"></div>

          <div className="singleShop-SalesGen">
            <div className="singleShop-SalesFamSec">
              <div className="singleShop-SalesTotalText">Total Sales:</div>
              <div className="singleShop-SalesTotalFig">696</div>

              <div className="singleShop-SalesRevenText">Revenue:</div>

              <div className="singleShop-SalesRevenFig">N952,260</div>
            </div>

            <div className="singleShop-PDetails">
              <div className="singleShop-PContact">Contacts </div>
              <div className="singleShop-PDetailsM">
                Manager: Peter Omiwole{" "}
              </div>
              <div className="singleShop-PDetailNN">petero@shopname.com </div>{" "}
              <div className="singleShop-PDNum">
                +234(0) 70 6742 1332, +234(0) 70 6742 1332
              </div>
            </div>

            <div className="singleShop-PDetails">
              <div className="singleShop-PContact">Store Address </div>
              <div className="singleShop-PDetailsM">Country: Nigeria</div>
              <div className="singleShop-PDetailNN">
                Address: 34a, Ago Iwoye, IKD, Lagos Postal Code: 2340001{" "}
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="orders__table">
          <Table
            handleToggleModal={handleToggleModal}
            // handleToggleNewModal={handleToggleNewModal}
          />
        </div>
        <OrdersModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
        />

        <NewProductModal
          showNewModal={showNewModal}
          handleToggleNewModal={handleToggleNewModal}
        />
      </Container>
    </div>
  );
};

export default SingleShopInfo;
