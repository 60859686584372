import React from 'react';
import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import LeftBar from '../../components/leftBar/LeftBar';
import RightForm from '../../components/rightForm/RightForm';
import backIconBlue from '../../assets/icon/backIconBlue.svg';
import { Link, useNavigate } from 'react-router-dom';

import './addNewPersonalAddress.scss';

const AddNewPersonalAddress = () => {
	const navigate = useNavigate();

	return (
		<div>
			<Navbar />
			<div className='addAddress-checkout-add'>
				<LeftBar />

				<div className='addAddress-checkout-rightForm'>
					<div className='addAddress-over-goBack' onClick={() => navigate(-1)}>
						{/* <Link to="/account-checkout" style={{ textDecoration: "none" }}> */}
						<div className='addAddress-over-goBackImg'>
							<img src={backIconBlue} />
						</div>
						{/* </Link> */}
						<div className='addAddress-over-text'>Go Back</div>
					</div>
					<RightForm />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AddNewPersonalAddress;
