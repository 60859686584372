import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import icons from './icons'

export function Table({
  setAddSubAccountModalFun,
  cancelSubscription,
  url,
  accountData,
}) {
  const navigate = useNavigate()
  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: 'ID', field: 'ID' },
          { title: 'Vendor Name', field: 'vendor' },
          { title: 'Vendor Email', field: 'email' },
          { title: 'Contact Name', field: 'contact' },
          { title: 'Contact Phone', field: 'phone' },
          { title: 'Status', field: 'status' },
        ]}
        data={accountData.data.map((data) => {
          return {
            ID: data.id,
            vendor: (
              <div
                onClick={() => navigate(`/admin/dashboard/vendors/${data.id}`)}
              >
                <p> {data?.vendor_name}</p>
              </div>
            ),
            email: data.email,
            contact: data.contact_name,
            phone: data.phone_no,
            status: <Link to='/admin/dashboard/vendors/:id'>'heyy'</Link>,

            Action: (
              <div className='subAccountSub'>
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: 'rgba(128, 128, 128, 0.103)' }
                        : { background: ' rgb(199, 13, 13)' }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title=''
      />
    </div>
  )
}
