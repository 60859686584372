import React from 'react'
import './homepageSection.scss'

const HomepageSection = ({ sectionName, sectionImg, onclick }) => {
  return (
    <div className='baby-section-li' onClick={onclick}>
      <div className='baby-section-li-img'>
        <img src={sectionImg} />
      </div>
      <div className='baby-section-li-name'></div>
      <div className='baby-section-li-kk'>
        <p>{sectionName}</p>
      </div>
    </div>
  )
}

export default HomepageSection
