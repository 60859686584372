import React from 'react'
import LandingCont from '../src/pages/landingPage/index'
import { ProtectedLayout } from './protected'
import HomeCont from '../src/pages/homePage/index'
import SingleProductsCont from '../src/pages/singleProductsPage/index'
import Login from './pages/auth/login'
import EmailVerification from './pages/auth/EmailVerification'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import CreateAccount from './pages/auth/CreateAccount'
import CartCont from './pages/cartPage/index'
import CheckoutDelivery from './pages/checkoutDelivery/index'
import GuestCheckout from './pages/guestCheckout'

import CategoryProducts from './pages/categoryProducts/CategoryProducts'
import SearchProducts from './pages/searchProducts/SearchProducts'

import AdminDashboardHome from './pages/AdminDashboard/Home/index'
import AdminOrders from './pages/AdminDashboard/Orders/index'
import AdminVendors from './pages/AdminDashboard/Vendors/index.js'
import AdminTransactions from './pages/AdminDashboard/Transactions/Transactions'
import AdminCustomers from './pages/AdminDashboard/Customers/index'
import AdminProducts from './pages/AdminDashboard/ProductsPage/index'

import AccountCheckout from './pages/accountCheckout/AccountCheckout'
import PersonalInfoCheckout from './pages/personalInfoCheckout/PersonalInfoCheckout'
import PaymentMethod from './pages/paymentMethod/PaymentMethod'
import OrderSuccess from './pages/orderSuccess/OrderSuccess'
import OrderDecline from './pages/orderDecline/OrderDecline'
import PersonalInfoCheckoutAddress from './pages/personalInfoCheckoutAddress/PersonalInfoCheckoutAddress'
import OnSuccessSignup from './pages/auth/OnSuccessSignup'
import AddNewPersonalAddress from './pages/addNewPersonalAddress/AddNewPersonalAddress'
import MyOrdersBlank from './pages/myOrdersBlank/MyOrdersBlank'
import AdminLogin from './pages/auth/AdminLogin'
import SingleVendor from './pages/AdminDashboard/Vendors/components/table/SingleVendor'
import ViewOrder from './pages/myOrdersBlank/viewOrder'
import SingleShopInfo from './pages/AdminDashboard/ProductsPage/SingleShopInfo'
import SingleCustomer from './pages/AdminDashboard/Customers/components/SingleCustomer'
import EditAddress from './pages/addNewPersonalAddress/editAddress'
import UserManagement from './pages/AdminDashboard/UserManagement'
import Settings from './pages/AdminDashboard/Settings/Settings'
import Report from './pages/AdminDashboard/Report/Report'
import Experiences from './pages/AdminDashboard/events/index.js'
import ExperienceOrders from './pages/myOrdersBlank/ExperienceOrders.js'
import ProductCategories from './pages/AdminDashboard/AttributeOptions/ProductCategories/ProductCategories.js'
import ParentCategories from './pages/AdminDashboard/AttributeOptions/ParentCategories/ParentCategories.js'
import SubCategories from './pages/AdminDashboard/AttributeOptions/SubCategories/SubCategories.js'
import Colours from './pages/AdminDashboard/AttributeOptions/Colours/Colours.js'
import GiftShops from './pages/AdminDashboard/AttributeOptions/GiftShop/GiftShops.js'
import CartPage from './pages/cartPage/CartPage.js'
import EventCategories from './pages/AdminDashboard/AttributeOptions/EventCategories/EventCategories.js'
import EventSubCategories from './pages/AdminDashboard/AttributeOptions/EventSubCategories/EventSubCategories.js'

export const LandingPage = () => {
  return <LandingCont />
}

export const HomePage = () => {
  return <HomeCont />
}

export const SingleProductsPage = () => {
  return <SingleProductsCont />
}

export const CategoryProductsPage = () => {
  return <CategoryProducts />
}

export const SearchProductsPage = () => {
  return <SearchProducts />
}

export const ForgotPasswordPage = () => {
  return <ForgotPassword />
}

export const EmailVerificationPage = () => {
  return <EmailVerification />
}

export const LoginPage = () => {
  return <Login />
}

export const AdminLoginPage = () => {
  return <AdminLogin />
}

export const ResetPasswordPage = () => {
  return <ResetPassword />
}

export const CreateAccountPage = () => {
  return <CreateAccount />
}

export const OnSuccessSignupPage = () => {
  return <OnSuccessSignup />
}

// export const CartPage = () => {
//   return <CartCont />
// }

export const CartMainPage = () => {
  return <CartPage />
}

export const CheckoutDeliveryPage = () => {
  return (
    <ProtectedLayout>
      <CheckoutDelivery />
    </ProtectedLayout>
  )
}

export const GuestCheckoutPage = () => {
  return <GuestCheckout />
}

export const MyOrdersBlankPage = () => {
  return (
    <ProtectedLayout>
      <MyOrdersBlank />
    </ProtectedLayout>
  )
}
export const ExperienceOrdersPage = () => {
  return (
    <ProtectedLayout>
      <ExperienceOrders />
    </ProtectedLayout>
  )
}

export const ViewMyOrdersPage = () => {
  return (
    <ProtectedLayout>
      <ViewOrder />
    </ProtectedLayout>
  )
}

export const AdminDashboardHomePage = ({ type }) => {
  return (
    <ProtectedLayout type={type}>
      <AdminDashboardHome />
    </ProtectedLayout>
  )
}

export const AdminVendorsPage = ({ type }) => {
  return (
    <ProtectedLayout type={type}>
      <AdminVendors />
    </ProtectedLayout>
  )
}

export const AdminTransactionsPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <AdminTransactions />
    </ProtectedLayout>
  )
}

export const SingleVendorPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <SingleVendor />
    </ProtectedLayout>
  )
}

export const SingleShopInfoPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <SingleShopInfo />
    </ProtectedLayout>
  )
}

export const AdminProductPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <AdminProducts />
    </ProtectedLayout>
  )
}
export const AdminExperiencesPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <Experiences />
    </ProtectedLayout>
  )
}

export const SettingsPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <Settings />
    </ProtectedLayout>
  )
}

export const ReportPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <Report />
    </ProtectedLayout>
  )
}

export const UserManagementPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <UserManagement />
    </ProtectedLayout>
  )
}

export const AdminCustomersPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <AdminCustomers />
    </ProtectedLayout>
  )
}

export const AdminSingleCustomersPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <SingleCustomer />
    </ProtectedLayout>
  )
}
export const AdminOrdersPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <AdminOrders />
    </ProtectedLayout>
  )
}
/// Attribute Options
export const ProductCategoriesPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <ProductCategories />
    </ProtectedLayout>
  )
}
export const ParentCategoriesPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <ParentCategories />
    </ProtectedLayout>
  )
}
export const EventCategoryPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <EventCategories />
    </ProtectedLayout>
  )
}
export const EventSubCategoryPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <EventSubCategories />
    </ProtectedLayout>
  )
}
export const SubCategoriesPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <SubCategories />
    </ProtectedLayout>
  )
}
export const ColoursPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <Colours />
    </ProtectedLayout>
  )
}
export const GiftShopPage = () => {
  return (
    <ProtectedLayout type={'admin'}>
      <GiftShops />
    </ProtectedLayout>
  )
}

export const AccountCheckoutPage = () => {
  return (
    <ProtectedLayout>
      <AccountCheckout />
    </ProtectedLayout>
  )
}

export const PersonalInfoCheckoutPage = () => {
  return (
    <ProtectedLayout>
      <PersonalInfoCheckout />
    </ProtectedLayout>
  )
}

export const PersonalInfoCheckoutAddressPage = () => {
  return (
    <ProtectedLayout>
      <PersonalInfoCheckoutAddress />
    </ProtectedLayout>
  )
}

export const AddNewPersonalAddressPage = () => {
  return (
    <ProtectedLayout>
      <AddNewPersonalAddress />
    </ProtectedLayout>
  )
}

export const EditAddressPage = () => {
  return (
    <ProtectedLayout>
      <EditAddress />
    </ProtectedLayout>
  )
}

export const PaymentMethodPage = () => {
  return (
    <ProtectedLayout>
      <PaymentMethod />
    </ProtectedLayout>
  )
}

export const OrderSuccessPage = () => {
  return (
    // <ProtectedLayout>
    <OrderSuccess />
    // </ProtectedLayout>
  )
}

export const OrderDeclinePage = () => {
  return (
    <ProtectedLayout>
      <OrderDecline />
    </ProtectedLayout>
  )
}
