import React from 'react'

const FormatNumber = ({ value }) => {
  let formattedValue = parseFloat(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return <>{formattedValue}</>
}

export default FormatNumber
