import React from "react";
import "./childNameModal.scss";
import cancelIcon from "../../assets/icon/cancelIcon.svg";
import { Link } from "react-router-dom";

const ChildNameModal = ({ closeChildNameBtn }) => {
  return (
    <div className="child-name-modal">
      <div className="child-name-modal-content">
        <div className="child-name-heading-flex">
          <div className="child-name-heading-texttt">
            Which child are you buying for?
          </div>
          <div
            className="child-name-cancel-icon-btn"
            onClick={closeChildNameBtn}
          >
            <img src={cancelIcon} />
          </div>
        </div>
        <div className="child-name-fammm">
          <div className="child-name-texttl">
            Gain some credit points when you buy products for your child
          </div>
          <div>
            <div className="child-name-select-heading">Select child</div>
            <div className="child-name-selectii">
              <select>
                <option>Select child</option>
              </select>
            </div>
          </div>

          <div className=" child-name-select-submitf">Add Child</div>
        </div>
      </div>
    </div>
  );
};

export default ChildNameModal;
