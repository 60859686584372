import Styled from 'styled-components'
import { rgba } from 'polished'

export default Styled.div`
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div.action--container {
            display: flex;
            align-items: center;
            height: 2.5rem;
            div.select--container {
                display: flex;
                align-items: center;
                margin-right: 1em;
                height: 100%;
                background: #fff;
                padding-left: 0.8em;
                /* padding: 0px 0.8em; */
                border-radius: 4px;
                .icon {
                    color: #595F62;
                    opacity: 0.5;
                    font-size: 1.2rem;
                    margin-right: 0.5em;
                }
                select {
                    border: none;
                    width: 7rem;
                    height: 100%;
                    font-size: 1rem;
                    color: #595F62;
                    outline: none;
                }
                }
            button {
                display: flex;
                outline: none;
                border: none;
                height: 100%;
                padding: 0px 1em;
                font-size: 0.9rem;
                color: #0C8DBA;
                font-weight: 600;
                .icon {
                    color: #0C8DBA;
                    font-size: 1.2rem;
                    margin-right: 0.5em;
                }
                background: ${rgba('#0C8DBA', 0.1)};
                border-radius: 4px;
                align-items:center;
            }
        }
    }
  
`
