import { Toast } from './toast/index'

const logout = () => {
  Toast('success', 'You have been logged out successfully.')

  localStorage.clear()
  sessionStorage.clear()

  setTimeout(() => {
    window.location.href = '/'
  }, 2000)
}

export default logout
