/* eslint-disable jsx-a11y/no-redundant-roles */
import closeOrderModal from '../../../../../assets/icon/closeOrderModal.svg'
// @ts-ignore
import './newProductModal.scss'
import productTrashIcon from '../../../../../assets/icon/productTrashIcon.svg'
import productUploadImg from '../../../../../assets/icon/productUploadImg.png'
import { useState, useEffect } from 'react'
import Switch from 'react-switch'
import { FileUploader } from 'react-drag-drop-files'
import ModalLayout from '../../../../../components/layout/modalLayout/ModalLayout'
import { Toast } from '../../../../../helpers/toast'
import { axiosCalls } from '../../../../../_api'
import Select from 'react-select'
import { hideLoader, showLoader } from '../../../../../loader'
import ImageUrl from '../../../../../components/imageUrl/ImageUrl'
import Cities from '../../../../../assets/Data/cities'
const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG']

function NewProductModal({
  setSelectedProduct,
  handleToggleNewModal,
  selectedProduct,
}) {
  const [file, setFile] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)

  //Country and cities
  const sortedCities = Object.keys(Cities).reduce((acc, country) => {
    acc[country] = Cities[country].sort()
    return acc
  }, {})
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cities, setCities] = useState([])
  const handleCountryChange = (event) => {
    setSelectedCountry(event)
    setCities(sortedCities[event.label] || [])
  }
  //Images
  const handleChange = (file) => {
    setFile(Object.values(file))
    if (Object.values(file).length > 0) {
      const readers = Object.values(file).map((file) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type })
          const blobURL = URL.createObjectURL(blob)

          // When the reading is completed, update the state with the Blob URL and file name
          setSelectedFiles((prevFiles) => [
            ...prevFiles,
            { blobURL: blobURL, name: file.name },
          ])
        }

        // Read each selected file as a data URL
        reader.readAsArrayBuffer(file)
      })
    }
  }
  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles]
    const updatedFiles2 = [...file]
    updatedFiles.splice(index, 1)
    updatedFiles2.splice(index, 1)
    setSelectedFiles(updatedFiles)
    setFile(updatedFiles2)
  }
  const handleCheckedChange = (e) => {
    setLimitedStock(e)
  }
  //////////////////// API consumptions.
  const [productName, setProductName] = useState('')
  const [brand, setBrand] = useState('')
  const [description, setDescription] = useState('')
  const [retailPrice, setRetailPrice] = useState(0)
  const [markPercentage, setMarkPercentage] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [limitedStock, setLimitedStock] = useState(false)
  const [dimensions, setDimensions] = useState('')
  //Gift shop
  const [giftLoading, setGiftLoading] = useState(true)
  const [giftList, setGiftList] = useState([])
  const [selectedGiftShop, setSelectedGiftShop] = useState([])
  useEffect(() => {
    getGiftShop()
  }, [])
  const getGiftShop = async () => {
    setGiftLoading(true)
    const res = await axiosCalls(`admin/gift-shop`, 'get')
    if (res) {
      setGiftLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setGiftList([])
        return
      }
      setGiftList(res?.data ?? [])
      return
    }
    setGiftList([])
  }
  /// Product categories
  const [productCatLoading, setProductCatLoading] = useState(true)
  const [productCatList, setProductCatList] = useState([])
  const [selectedProductCats, setSelectedProductCats] = useState({})
  useEffect(() => {
    getProductCategories()
  }, [])

  const getProductCategories = async (from) => {
    setProductCatLoading(true)

    const res = await axiosCalls(`admin/product-category`, 'get')

    if (res) {
      setProductCatLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setProductCatList([])
        return
      }
      setProductCatList(res?.data ?? [])
      return
    }
    setProductCatList([])
  }
  /// Parent category
  const [parentCatLoading, setParentCatLoading] = useState(false)
  const [parentCatList, setParentCatList] = useState([])
  const [selectedParentCats, setSelectedParentCats] = useState({})
  const getParentCategories = async (value) => {
    setParentCatLoading(true)
    const res = await axiosCalls(
      `admin/parent-category?product-category-id=${value}`,
      'get'
    )
    if (res) {
      setParentCatLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setParentCatList([])
        return
      }
      setParentCatList(res?.data ?? [])
      return
    }
    setParentCatList([])
  }
  //Parent Sub category.
  const [subCatLoading, setSubCatLoading] = useState(false)
  const [subCatList, setSubCatList] = useState([])
  const [selectedSubCats, setSelectedSubCats] = useState({})

  const getSubCategories = async (value) => {
    setSubCatLoading(true)

    const res = await axiosCalls(
      `admin/parent-sub-category?parent-category-id=${value}`,
      'get'
    )

    if (res) {
      setSubCatLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setSubCatList([])
        return
      }
      setSubCatList(res?.data ?? [])
      return
    }
    setSubCatList([])
  }

  //Vendors
  const [vendorsLoading, setVendorsLoading] = useState(true)
  const [vendorsList, setVendorsList] = useState([])
  const [selectedVendors, setSelectedVendors] = useState({})
  useEffect(() => {
    getVendors()
  }, [])
  const getVendors = async () => {
    setVendorsLoading(true)
    const res = await axiosCalls('admin/vendor', 'get')
    if (res) {
      setVendorsLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setVendorsList([])
        return
      }
      setVendorsList(res?.data.data)
    }
    setVendorsLoading(false)
  }

  //colors
  const [colorsLoading, setColorsLoading] = useState(true)
  const [ColoursList, setColoursList] = useState([])
  const [SelectedColours, setSelectedColours] = useState([])
  useEffect(() => {
    getColours()
  }, [])
  const getColours = async () => {
    setColorsLoading(true)
    const res = await axiosCalls('admin/color', 'get')
    if (res) {
      setColorsLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setColoursList([])
        return
      }
      setColoursList(res?.data)
    }
    setColorsLoading(false)
  }
  ///////
  function checkIfFiledIEmpty(params) {
    if (typeof params === 'number' && params < 0) {
      return false
    } else if (typeof params === 'string' && params.length < 1) {
      return false
    } else if (typeof params === 'object' && Object.keys(params).length < 1) {
      return false
    } else {
      return true
    }
  }
  //
  async function handleSubmit() {
    if (
      checkIfFiledIEmpty(productName) &&
      checkIfFiledIEmpty(brand) &&
      checkIfFiledIEmpty(description) &&
      checkIfFiledIEmpty(retailPrice) &&
      checkIfFiledIEmpty(discount) &&
      checkIfFiledIEmpty(quantity) &&
      checkIfFiledIEmpty(selectedProductCats) &&
      checkIfFiledIEmpty(selectedParentCats) &&
      checkIfFiledIEmpty(selectedSubCats) &&
      checkIfFiledIEmpty(selectedVendors) &&
      file.length > 0
    ) {
      showLoader()
      const formData = new FormData()
      for (let i = 0; i < file.length; i++) {
        formData.append(`images[]`, file[i])
      }
      const request = axiosCalls(`upload-image`, 'post', formData)
      const res = await request

      if (res) {
        if (res.er) {
          Toast('error', res.er.message)
          return
        }
        const data = {
          name: productName,
          brand: brand,
          description: description,
          product_category_id: selectedProductCats.value,
          parent_category_id: selectedParentCats.value,
          parent_sub_category_id: selectedSubCats.value,
          retail_price: retailPrice,
          markup_percentage: markPercentage,
          vendor_id: selectedVendors.value,
          discount_percentage: discount,
          stock_quantity: quantity,
          images: res.data,
          country: selectedCountry.label,
          city: selectedCity.label,
          main_image:
            selectedImageIndex >= 0
              ? res.data[selectedImageIndex]
              : res.data[0],
          limited_stock: limitedStock,
        }
        if (selectedGiftShop.length > 0) {
          data.gift_shops = selectedGiftShop.map((item) => item.value)
        }
        if (SelectedColours.length > 0) {
          data.colors = SelectedColours.map((item) => item.value)
        }
        if (dimensions.length > 0) {
          data.dimensions = dimensions
        }
        if (markPercentage > 0) {
          const markupNaira = (markPercentage / 100) * retailPrice
          const price = markupNaira + parseInt(retailPrice)
          data.price = price
        }
        const response = await axiosCalls(`admin/product`, 'post', data)
        if (response) {
          hideLoader()
          if (Object.keys(response).includes('er')) {
            Toast('error', response.er.message)
            return
          }
          Toast('success', 'Product saved successfully.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      }
    }
  }
  async function handleEditSubmit() {
    if (
      checkIfFiledIEmpty(productName) &&
      checkIfFiledIEmpty(brand) &&
      checkIfFiledIEmpty(description) &&
      checkIfFiledIEmpty(retailPrice) &&
      checkIfFiledIEmpty(discount) &&
      checkIfFiledIEmpty(quantity) &&
      checkIfFiledIEmpty(selectedProductCats) &&
      checkIfFiledIEmpty(selectedParentCats) &&
      checkIfFiledIEmpty(selectedSubCats) &&
      checkIfFiledIEmpty(selectedVendors) &&
      file.length > 0
    ) {
      showLoader()
      const formData = new FormData()
      for (let i = 0; i < file.length; i++) {
        formData.append(`images[]`, file[i])
      }
      const request = axiosCalls(`upload-image`, 'post', formData)
      const res = await request

      if (res) {
        if (res.er) {
          Toast('error', res.er.message)
          return
        }
        const data = {
          name: productName,
          brand: brand,
          description: description,
          product_category_id: selectedProductCats.value,
          parent_category_id: selectedParentCats.value,
          parent_sub_category_id: selectedSubCats.value,
          retail_price: retailPrice,
          markup_percentage: markPercentage,
          vendor_id: selectedVendors.value,
          discount_percentage: discount,
          stock_quantity: quantity,
          images: res.data,
          country: selectedCountry.label,
          main_image:
            selectedImageIndex >= 0
              ? res.data[selectedImageIndex]
              : res.data[0],
          limited_stock: limitedStock,
        }
        if (selectedGiftShop.length > 0) {
          data.gift_shops = selectedGiftShop.map((item) => item.value)
        }
        if (SelectedColours.length > 0) {
          data.colors = SelectedColours.map((item) => item.value)
        }
        if (dimensions.length > 0) {
          data.dimensions = dimensions
        }
        if (markPercentage > 0) {
          const markupNaira = (markPercentage / 100) * retailPrice
          const price = markupNaira + parseInt(retailPrice)
          data.price = price
        }
        const response = await axiosCalls(
          `admin/product/${selectedProduct.id}`,
          'put',
          data
        )
        if (response) {
          hideLoader()
          if (Object.keys(response).includes('er')) {
            Toast('error', response.er.message)
            return
          }
          Toast('success', 'Product saved successfully.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      }
    } else {
      Toast('error', 'Make sure all the compulsory fields are filled')
    }
  }

  // this is the edit parts..
  const imageUrlsToFiles = async (urls) => {
    const files = []
    for (const url of urls) {
      try {
        const file = await ImageUrl(url)
        files.push(file)
      } catch (error) {
        console.error(error)
      }
    }
    return files
  }
  const Vendor = localStorage.getItem('role') === 'Vendor'

  useEffect(() => {
    if (selectedProduct) {
      setProductName(selectedProduct.name)
      setBrand(selectedProduct.brand)
      setDescription(selectedProduct.description)
      setSelectedProductCats({
        value: selectedProduct.product_category.id,
        label: selectedProduct.product_category.name,
      })
      setSelectedParentCats({
        value: selectedProduct.parent_category.id,
        label: selectedProduct.parent_category.name,
      })
      setSelectedSubCats({
        value: selectedProduct.parent_sub_category.id,
        label: selectedProduct.parent_sub_category.name,
      })
      setRetailPrice(selectedProduct.retail_price)
      setMarkPercentage(selectedProduct.markup_percentage)
      setSelectedVendors({
        value: selectedProduct.vendor.id,
        label: selectedProduct.vendor.vendor_name,
      })
      if (selectedProduct.gift_shops) {
        setSelectedGiftShop({
          value: selectedProduct.gift_shops,
        })
      }
      setDiscount(selectedProduct.discounted_price)
      if (selectedProduct.dimension) {
        setDimensions(selectedProduct.dimension)
      }
      if (selectedProduct.country) {
        setSelectedCountry({
          label: selectedProduct.country,
          value: selectedProduct.country,
        })
      }
      if (selectedProduct.city) {
        setSelectedCity({
          label: selectedProduct.city,
          value: selectedProduct.city,
        })
      }

      const selectedColours = ColoursList.filter((color) =>
        selectedProduct.colors.includes(color.name)
      ).map((color) => ({
        value: color.id,
        label: color.name,
      }))
      setSelectedColours(selectedColours)
      setQuantity(selectedProduct.stock_quantity)
      const files = async () => {
        const file = await imageUrlsToFiles(selectedProduct.images)
        handleChange(file)
      }
      files()
      setSelectedImageIndex(
        selectedProduct.main_image.substring(
          selectedProduct.main_image.lastIndexOf('/') + 1
        )
      )
    }
    if (Vendor) {
      const getAdminProfile = async () => {
        const res = await axiosCalls(`admin/profile`, 'get')
        if (res) {
          if (res.er) {
            Toast('error', res.er.message)
            return
          }
          setSelectedVendors({
            value: res.data.id,
            label: res.data.vendor_name,
          })
          return
        }
      }
      getAdminProfile()
    }
  }, [selectedProduct])
  return (
    <ModalLayout small>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>
          {selectedProduct ? 'Edit Product' : 'Create Product'}
        </div>
        <div
          className='adminOrders-headingClose'
          onClick={() => {
            handleToggleNewModal()
            setSelectedProduct(null)
          }}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <div className='add-product-info'>
        <p>
          Fields marked with <span>*</span> are required.
        </p>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Product Name <span className='add-product-required '>*</span>
        </div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your product a short name
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Brand Name <span className='add-product-required '>*</span>
        </div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Product Description <span className='add-product-required '>*</span>
        </div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your product a short and clear description
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Product Category <span className='add-product-required '>*</span>
        </div>
        <div className='newProductForm-selectCate'>
          <Select
            name='colors'
            options={productCatList.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => {
              setSelectedProductCats(e)
              getParentCategories(e.value)
            }}
            isLoading={productCatLoading}
            value={selectedProductCats}
          />
        </div>
      </div>

      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Parent category <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareselectCate'>
            <Select
              name='colors'
              options={parentCatList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => {
                setSelectedParentCats(e)
                getSubCategories(e.value)
              }}
              isLoading={parentCatLoading}
              value={selectedParentCats}
            />
          </div>
        </div>

        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Parent subcategory <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-ChilselectCate'>
            <Select
              name='colors'
              options={subCatList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => setSelectedSubCats(e)}
              isLoading={subCatLoading}
              value={selectedSubCats}
            />
          </div>
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Country <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareselectCate'>
            <Select
              name='colors'
              options={Object.keys(sortedCities)
                .sort()
                .map((country) => ({
                  label: country,
                  value: country,
                }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={handleCountryChange}
              value={selectedCountry}
            />
          </div>
        </div>

        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            City<span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-ChilselectCate'>
            <Select
              name='colors'
              options={cities.sort().map((item) => ({
                label: item,
                value: item,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => setSelectedCity(e)}
              value={selectedCity}
            />
          </div>
        </div>
      </div>

      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>
            Retail Price <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareNairCateOne'>
            <span className='newProductForm-nairaSign'>₦</span>
            <input
              type='number'
              value={retailPrice}
              onChange={(e) => setRetailPrice(e.target.value)}
            />
          </div>
        </div>

        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>
            Markup percentage <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareNairCateOne'>
            <input
              type='number'
              value={markPercentage}
              onChange={(e) => setMarkPercentage(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='newProductForm-addDiscount'>
        <div className='newProductForm-addDiscountText'>
          Does this product have limited stock?
        </div>
        <div className='newProductForm-discountToggle'>
          {' '}
          <Switch
            onChange={handleCheckedChange}
            checked={limitedStock}
            onColor='#86d3ff'
            onHandleColor='#2693e6'
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            height={15}
            width={35}
            className='react-switch'
            id='material-switch'
          />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Vendor <span className='add-product-required '>*</span>
        </div>
        <div className='newProductForm-selectCate'>
          <Select
            name='colors'
            options={vendorsList.map((item) => ({
              label: item.vendor_name,
              value: item.id,
            }))}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => setSelectedVendors(e)}
            value={selectedVendors}
            isLoading={vendorsLoading}
            isDisabled={Vendor}
          />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Gift Shop</div>
        <div className='newProductForm-selectCate'>
          <Select
            isMulti
            name='colors'
            options={giftList.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => setSelectedGiftShop(e)}
            isLoading={giftLoading}
            value={selectedGiftShop}
          />
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenTwo'>
          <div className='newProductForm-NameGenTag'>
            Discount percentage <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-ChilPriceCate'>
            <input
              type='number'
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />
          </div>
        </div>
        <div className='newProductForm-NameGenTwo'>
          <div className='newProductForm-NameGenTag'>Enter dimensions</div>
          <div className='newProductForm-ChilPriceCate'>
            <input
              type='number'
              value={dimensions}
              onChange={(e) => setDimensions(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='newProductForm-NameInputSub'>
        The value of the percentage discount will be deducted from the the price
      </div>

      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenTwo'>
          <div className='newProductForm-NameGenTag'>Colors</div>
          <div className='newProductForm-PareselectCateTwo'>
            <Select
              isMulti
              name='colors'
              options={ColoursList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => setSelectedColours(e)}
              value={SelectedColours}
              isLoading={colorsLoading}
            />
          </div>
        </div>

        <div className='newProductForm-NameGenTwo'>
          <div className='newProductForm-NameGenTag'>
            Product Quantity <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-QuantNairCateTwo'>
            <input
              type='number'
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='newProductForm-ProdImgText'>
        Product Images <span className='add-product-required '>*</span>
      </div>

      <div className='newProductForm-fileUploader'>
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name='file'
          types={fileTypes}
          label='Drop your images here, or click to browse 1600 x 1200 recommend, up to 10MB each.'
        />
        <p className='newProductForm-fileUploadName'>
          {file ? `File name: ${file[0].name}` : 'no files uploaded yet'}
        </p>
      </div>
      <div className='newProductForm-fileUploadText'>
        Add up to 8 images to your products. Used to represent your products
        during checkout, in email, social sharing and more.
      </div>

      <div className='newProductForm-fileFlexS'>
        <div className='newProductForm-fileFlexI'>Image</div>
        <div className='newProductForm-fileFlexM'>Select Main Image</div>
      </div>

      {file && (
        <>
          {selectedFiles.map((item, i) => (
            <div className='newProductForm-fileImgListGen' key={i + 1}>
              <div className='newProductForm-fileImgListFam'>
                <div className='newProductForm-fileImgMain'>
                  <img src={item.blobURL} />
                </div>
                <div className='newProductForm-fileImgName'>
                  {item.name.length > 30
                    ? `${item.name.substring(0, 30)}...`
                    : item.name}
                </div>
              </div>
              <div className='newProductForm-fileImgSwitch'>
                {' '}
                <Switch
                  onChange={(e) => {
                    if (e) {
                      setSelectedImageIndex(i)
                    } else {
                      setSelectedImageIndex(null)
                    }
                  }}
                  checked={selectedImageIndex === i}
                  onColor='#86d3ff'
                  onHandleColor='#2693e6'
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={15}
                  width={35}
                  className='react-switch'
                  id='material-switch'
                />
              </div>

              <div className='newProductForm-fileImgDel'>
                <img
                  src={productTrashIcon}
                  onClick={() => {
                    handleDelete(i)
                    if (selectedImageIndex === i) {
                      setSelectedImageIndex(null)
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
      <div className='newProductForm-SaveGen'>
        {/* <div className='newProductForm-SaveDraft'>Save as Draft</div> */}
        {selectedProduct ? (
          <div
            className='newProductForm-SaveChanges'
            onClick={handleEditSubmit}
          >
            Save Changes
          </div>
        ) : (
          <div className='newProductForm-SaveChanges' onClick={handleSubmit}>
            Create Product
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export { NewProductModal }

// <div>
//   <button onClick={this.handleOpenModal}>Trigger Modal</button>
//   <ReactModal
//      isOpen={this.state.showModal}
//      contentLabel="onRequestClose Example"
//      onRequestClose={this.handleCloseModal}
//      shouldCloseOnOverlayClick={true}
//   >
//     <p>Modal text!</p>
//     <button onClick={this.handleCloseModal}>Close Modal</button>
//   </ReactModal>
// </div>
