/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react'
import closeOrderModal from '../../../../../assets/icon/closeOrderModal.svg'

import orderAdminDots from '../../../../../assets/icon/orderAdminDots.svg'

// @ts-ignore
import './ordersModal.scss'
import productImgOne from '../../../../../assets/icon/productImgOne.png'

import productImgTwo from '../../../../../assets/icon/productImgTwo.png'
import ModalLayout from '../../../../../components/layout/modalLayout/ModalLayout'

function OrdersModal({ setShowDetails, setSelectedProduct, selectedProduct }) {
  const [showOpt, setShowOpt] = useState(false)
  const toggleShowOpt = () => {
    setShowOpt(!showOpt)
  }

  useEffect(() => {}, [])

  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>Product Details</div>
        <div
          className='adminOrders-headingClose'
          onClick={() => {
            setShowDetails(false)
            setSelectedProduct(null)
          }}
        >
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className='adminOrders-SubheadingStaFam'>
        <div className='adminOrders-SubheadingStaFlex'>
          <div className='adminOrders-SubheadingStaText'>Inventory Status:</div>
          {selectedProduct.limited_stock ? (
            <div className='adminOrders-SubheadingStaIconFlex'>
              <div className='adminOrders-SubheadingStaIcon'></div>
              <div className='adminOrders-SubheadingStaSt'>Limited Stock</div>
            </div>
          ) : (
            <div className='adminOrders-SubheadingStaIconFlex'>
              <div className='adminOrders-SubheadingStaIcon not'></div>
              <div className='adminOrders-SubheadingStaSt not'>
                Not Limited Stock
              </div>
            </div>
          )}
        </div>
        <div onClick={toggleShowOpt} className='adminOrders-SubheadingStaOpt'>
          <img src={orderAdminDots} />
        </div>
      </div>

      {showOpt && (
        <div className='adminOrders-SubheadingStaOptDrop'>
          <div className='adminOrders-SubheadingStaOptDrop1'>Edit Product</div>
          <div className='adminOrders-SubheadingStaOptDrop2'>
            Delete Product
          </div>
        </div>
      )}
      {/* <div className="adminOrders-SubheadingDateFam">
        <div className="adminOrders-SubheadingDateHead">Date:</div>
        <div className="adminOrders-SubheadingDate">24 February, 2021</div>
      </div> */}
      <div className='adminOrders-summaryFam'>
        <div className='adminProduct-productNameTagGenOne'>
          <div className='adminProduct-productNameTag'>Product Name</div>
          <div className='adminProduct-productNameTagCate'>
            Product Category
          </div>
        </div>

        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>
            {selectedProduct?.name}
          </div>
          <div className='adminProduct-productNameP'>
            {selectedProduct?.product_category.name}
          </div>
        </div>

        <div className='adminProduct-productNameTagGen'>
          <div className='adminProduct-productNameTag'>Product Code</div>
          <div className='adminProduct-productNameTagCate'>Quantity</div>
        </div>

        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>-</div>
          <div className='adminProduct-productNameP'>
            {selectedProduct?.stock_quantity}
          </div>
        </div>

        <div className='adminProduct-productNameTagGen'>
          <div className='adminProduct-productNameTag'>Vendor</div>
          <div className='adminProduct-productNameTagCate'>Product Price</div>
        </div>

        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>
            {selectedProduct?.vendor.vendor_name}
          </div>
          <div className='adminProduct-productNameP'>
            ₦{parseInt(selectedProduct?.price).toLocaleString()}
          </div>
        </div>

        <div className='adminProduct-productDiscountTag'>Product Discount</div>
        <div className='adminProduct-productDiscount'>
          {selectedProduct.discount_percentage}%
        </div>

        <div className='adminProduct-productDiscountTag'>
          Product Description
        </div>
        <div className='adminProduct-productDiscount'>
          {selectedProduct?.description}
        </div>
      </div>

      <div className='adminOrders-customerFam'>
        <div className='adminOrders-customerDetai'>Product Images</div>

        <div className='adminProduct-imageSec'>Image</div>
        {selectedProduct.images.map((item) => (
          <div className='adminProduct-imageSecThirdFlex'>
            <div className='adminProduct-imageSecThirdFlexT'>
              <div className='adminProduct-imageSecThirdImg'>
                <img src={item} />
              </div>
              <div className='adminProduct-imageSecThirdName'>IMG_1200</div>
            </div>
            <div className='adminProduct-imageSecThirdCheckFam'>
              <div className='adminProduct-imageSecThirdChecked'>
                <input type='checkbox' />
              </div>
              {item === selectedProduct.main_image && (
                <div className='adminProduct-imageSecThirdCheckImgtext'>
                  Image used as main image
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </ModalLayout>
  )
}

export { OrdersModal }

// <div>
//   <button onClick={this.handleOpenModal}>Trigger Modal</button>
//   <ReactModal
//      isOpen={this.state.showModal}
//      contentLabel="onRequestClose Example"
//      onRequestClose={this.handleCloseModal}
//      shouldCloseOnOverlayClick={true}
//   >
//     <p>Modal text!</p>
//     <button onClick={this.handleCloseModal}>Close Modal</button>
//   </ReactModal>
// </div>
