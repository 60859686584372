import React from 'react'
import { Icon } from '@iconify/react'

const TablePagination = (props) => {
  const { page, rowsPerPage, count, dataList, currentPage, fetchData } = props
  let from = rowsPerPage * page + 1
  let to = rowsPerPage * (page + 1)
  if (to > count) {
    to = count
  }
  const onPaginationClick = (e) => {
    fetchData(e)
  }
  return (
    <div className='custom-pagination'>
      <div
        className={currentPage === 1 ? 'one disabled' : 'one'}
        onClick={(e) => currentPage !== 1 && onPaginationClick(currentPage - 1)}
      >
        <Icon icon={'basil:skip-prev-outline'} />
        <p>Prev</p>
      </div>
      {from}-{to} of {count}
      <div
        className={currentPage === dataList.last_page ? 'one disabled' : 'one'}
        onClick={(e) =>
          currentPage !== dataList.last_page &&
          onPaginationClick(currentPage + 1)
        }
      >
        <p>Next</p>
        <Icon icon={'basil:skip-next-outline'} />
      </div>
    </div>
  )
}

export default TablePagination
