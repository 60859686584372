import React from "react";

const VendorIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6667 13.8333C16.6667 14.2933 16.2942 14.6667 15.8333 14.6667C15.3725 14.6667 15 14.2933 15 13.8333C15 12.455 13.8783 11.3333 12.5 11.3333C11.9308 11.3333 11.39 11.5283 10.9533 11.8742C11.4067 12.7042 11.6667 13.6558 11.6667 14.6667C11.6667 15.1267 11.2942 15.5 10.8333 15.5C10.3725 15.5 10 15.1267 10 14.6667C10 12.3692 8.13083 10.5 5.83333 10.5C3.53583 10.5 1.66667 12.3692 1.66667 14.6667C1.66667 15.1267 1.29417 15.5 0.833333 15.5C0.3725 15.5 0 15.1267 0 14.6667C0 11.45 2.6175 8.83333 5.83333 8.83333C7.43917 8.83333 8.89417 9.48583 9.95 10.5383C10.6742 9.97833 11.5658 9.66667 12.5 9.66667C14.7975 9.66667 16.6667 11.5358 16.6667 13.8333ZM12.5 5.5C12.96 5.5 13.3333 5.87417 13.3333 6.33333C13.3333 6.7925 12.96 7.16667 12.5 7.16667C12.04 7.16667 11.6667 6.7925 11.6667 6.33333C11.6667 5.87417 12.04 5.5 12.5 5.5ZM12.5 8.83333C13.8783 8.83333 15 7.71167 15 6.33333C15 4.955 13.8783 3.83333 12.5 3.83333C11.1217 3.83333 10 4.955 10 6.33333C10 7.71167 11.1217 8.83333 12.5 8.83333ZM5.83333 2.16667C6.7525 2.16667 7.5 2.91417 7.5 3.83333C7.5 4.7525 6.7525 5.5 5.83333 5.5C4.91417 5.5 4.16667 4.7525 4.16667 3.83333C4.16667 2.91417 4.91417 2.16667 5.83333 2.16667ZM5.83333 7.16667C7.67167 7.16667 9.16667 5.67167 9.16667 3.83333C9.16667 1.995 7.67167 0.5 5.83333 0.5C3.995 0.5 2.5 1.995 2.5 3.83333C2.5 5.67167 3.995 7.16667 5.83333 7.16667Z"
        fill={fill}
      />
    </svg>
  );
};

export default VendorIcon;
