/* eslint-disable jsx-a11y/no-redundant-roles */
import closeOrderModal from '../../../../../assets/icon/closeOrderModal.svg'
import Modal from 'react-modal'
import productTrashIcon from '../../../../../assets/icon/productTrashIcon.svg'
import { useState, useEffect } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { axiosCalls } from '../../../../../_api'
import { Toast } from '../../../../../helpers/toast'
import { hideLoader, showLoader } from '../../../../../loader'
import ModalLayout from '../../../../../components/layout/modalLayout/ModalLayout'

const fileTypes = ['JPEG', 'PNG', 'GIF']

Modal.setAppElement('#root')

function EditExperienceModal({ handleToggleNewModal, selectedExperience }) {
  const [file, setFile] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [name, setName] = useState('')
  const [adultPrice, setAdultPrice] = useState(0)
  const [childPrice, setChildPrice] = useState(0)
  const [description, setDescription] = useState('')
  const [venue, setVenue] = useState('')
  const [socialMedia, setSocialMedia] = useState('')
  const [date, setDate] = useState('')
  const [existingImages, setExistingImages] = useState([])

  useEffect(() => {
    if (selectedExperience) {
      setName(selectedExperience.name)
      setDate(selectedExperience.event_date)
      setDescription(selectedExperience.description)
      setVenue(selectedExperience.venue)
      setAdultPrice(selectedExperience.adult_price)
      setChildPrice(selectedExperience.child_price)
      setSocialMedia(
        JSON.parse(selectedExperience.social_media_links).join(', ')
      )
      setExistingImages(JSON.parse(selectedExperience.images))
    }
  }, [])

  const handleChange = (file) => {
    setFile(Object.values(file))
    if (Object.values(file).length > 0) {
      const readers = Object.values(file).map((file) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type })
          const blobURL = URL.createObjectURL(blob)

          // When the reading is completed, update the state with the Blob URL and file name
          setSelectedFiles((prevFiles) => [
            ...prevFiles,
            { blobURL: blobURL, name: file.name },
          ])
        }

        // Read each selected file as a data URL
        reader.readAsArrayBuffer(file)
      })
    }
  }
  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles]
    const updatedFiles2 = [...file]
    updatedFiles.splice(index, 1)
    updatedFiles2.splice(index, 1)
    setSelectedFiles(updatedFiles)
    setFile(updatedFiles2)
  }
  const handleExistDelete = (index) => {
    const updatedFiles = [...existingImages]
    updatedFiles.splice(index, 1)
    setExistingImages(updatedFiles)
  }
  async function handleSubmit() {
    showLoader()
    if (file.length > 0) {
      const formData = new FormData()
      for (let i = 0; i < file.length; i++) {
        formData.append(`images[]`, file[i])
      }
      const request = axiosCalls(`upload-image`, 'post', formData)
      const res = await request
      if (res) {
        if (res.er) {
          Toast('error', res.er.message)
          return
        }
        const data = {
          name: name,
          adult_price: parseInt(adultPrice),
          child_price: parseInt(childPrice),
          images: [...res.data, ...existingImages],
          main_image:
            existingImages.length > 0 ? existingImages[0] : res.data[0],
          description: description,
          venue: venue,
          social_media_links: socialMedia.split(',').map((item) => item.trim()),
          event_date: date,
        }
        const response = await axiosCalls(
          `admin/update-experience/${selectedExperience.id}`,
          'post',
          data
        )
        if (response) {
          hideLoader()
          if (response.er) {
            Toast('error', response.er.message)
            return
          }
          Toast('success', 'Experience saved successfully.')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      }
    } else {
      const data = {
        name: name,
        adult_price: parseInt(adultPrice),
        child_price: parseInt(childPrice),
        images: [...existingImages],
        main_image: existingImages[0],
        description: description,
        venue: venue,
        social_media_links: socialMedia.split(',').map((item) => item.trim()),
        event_date: date,
      }
      const response = await axiosCalls(
        `admin/update-experience/${selectedExperience.id}`,
        'post',
        data
      )
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Experience saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  return (
    <ModalLayout>
      {/* The Flow Class is in the root > index.css */}
      {/* Use --flow-spacer: 2rem..etc to overwrite it */}
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo' onClick={handleSubmit}>
          Experiences
        </div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your product a short name
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Event Date</div>
        <div className='newProductForm-NameInput'>
          <input
            type='date'
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Event Description</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your event a short and clear description
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Venue</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
          />
        </div>
      </div>

      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>Adult Price</div>
          <div className='newProductForm-PareNairCateOne'>
            <span className='newProductForm-nairaSign'>₦</span>
            <input
              type='number'
              value={adultPrice}
              onChange={(e) => setAdultPrice(e.target.value)}
            />
          </div>
        </div>

        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>Kid Price</div>
          <div className='newProductForm-PareNairCateOne'>
            <span className='newProductForm-nairaSign'>₦</span>
            <input
              type='number'
              value={childPrice}
              onChange={(e) => setChildPrice(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Event Social media Links
        </div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={socialMedia}
            onChange={(e) => setSocialMedia(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give us links to your event social media presence. Input link and
          separate with a comma (,).
        </div>
      </div>

      <div className='newProductForm-ProdImgText'>Product Images</div>

      <div className='newProductForm-fileUploader'>
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name='file'
          types={fileTypes}
          label='Drop your images here, or click to browse 1600 x 1200 recommend, up to 10MB each.'
        />
        {/* <p className='newProductForm-fileUploadName'>
          {file ? `File name: ${file[0].name}` : 'no files uploaded yet'}
        </p> */}
      </div>
      <div className='newProductForm-fileUploadText'>
        Add up to 8 images to your products. Used to represent your products
        during checkout, in email, social sharing and more.
      </div>

      <div className='newProductForm-fileFlexS'>
        <div className='newProductForm-fileFlexI'>Image</div>
        <div className='newProductForm-fileFlexM'>Select Main Image</div>
      </div>
      {existingImages.map((item, i) => (
        <div className='newProductForm-fileImgListGen' key={i + 1}>
          <div className='newProductForm-fileImgListFam'>
            <div className='newProductForm-fileImgMain'>
              <img src={item} />
            </div>
            <div className='newProductForm-fileImgName'>
              {item.substring(item.lastIndexOf('/') + 1)}
            </div>
          </div>

          <div className='newProductForm-fileImgDel'>
            <img src={productTrashIcon} onClick={() => handleExistDelete(i)} />
          </div>
        </div>
      ))}
      {file && (
        <>
          {selectedFiles.map((item, i) => (
            <div className='newProductForm-fileImgListGen' key={i + 1}>
              <div className='newProductForm-fileImgListFam'>
                <div className='newProductForm-fileImgMain'>
                  <img src={item.blobURL} />
                </div>
                <div className='newProductForm-fileImgName'>IMG_123000</div>
              </div>

              <div className='newProductForm-fileImgDel'>
                <img src={productTrashIcon} onClick={() => handleDelete(i)} />
              </div>
            </div>
          ))}
        </>
      )}

      <div className='newProductForm-SaveGen'>
        <div className='newProductForm-SaveChanges' onClick={handleSubmit}>
          Edit
        </div>
      </div>
    </ModalLayout>
  )
}

export default EditExperienceModal
