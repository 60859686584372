import React from 'react'

const ShortenName = ({ name, maxLength }) => {
  if (!name) return null

  if (name.length <= maxLength) {
    return <span>{name}</span>
  } else {
    const shortenedName = name.substring(0, maxLength) + '...'
    return <span>{shortenedName}</span>
  }
}

export default ShortenName
