import React from "react";

const MyOrders = ({ fill }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9999 3.83356H15.0666V1.46689C15.0666 1.20022 14.8666 1.00022 14.5999 1.00022H1.43326C1.16659 1.00022 0.966593 1.20022 0.966593 1.46689V3.83356H0.0332642V1.46689C0.0332642 0.700222 0.666591 0.0668945 1.43326 0.0668945H14.5999C15.3666 0.0668945 15.9999 0.700222 15.9999 1.46689V3.83356Z"
        fill={fill}
      />
      <path
        d="M13.6333 13.2667H2.33329C1.56663 13.2667 0.933289 12.6334 0.933289 11.8667V3.8667H1.86663V11.8667C1.86663 12.1334 2.06663 12.3334 2.33329 12.3334H13.6333C13.9 12.3334 14.1 12.1334 14.1 11.8667V3.8667H15.0333V11.8667C15.0666 12.6334 14.4333 13.2667 13.6333 13.2667Z"
        fill={fill}
      />
      <path
        d="M9.8667 6.66673H6.10003C5.83336 6.66673 5.63336 6.46673 5.63336 6.20006C5.63336 5.9334 5.83336 5.7334 6.10003 5.7334H9.8667C10.1334 5.7334 10.3334 5.9334 10.3334 6.20006C10.3334 6.46673 10.1334 6.66673 9.8667 6.66673Z"
        fill={fill}
      />
      <path d="M0 2.90039H16V3.83372H0V2.90039Z" fill={fill} />
    </svg>
  );
};

export default MyOrders;
