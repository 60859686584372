import React from 'react';
import './accountCheckout.scss';
import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import LeftBar from '../../components/leftBar/LeftBar';
import { Link, useNavigate } from 'react-router-dom';

const AccountCheckout = () => {
	const navigation = useNavigate();
	return (
		<div>
			<Navbar />
			<div className='account-checkout-fam'>
				<LeftBar />
				<div className='account-over-fam'>
					<div className='account-over-heading-cont'>
						<h3>Account Overview</h3>
					</div>
					<div className='account-over-fam-line'></div>
					<div className='account-over-fam-ffff'>
						<div className='account-overviewsub-fam'>
							<div className='account-overviewsub-prof'>
								<p>Profile Information</p>
							</div>

							<div className='account-overviewsub-prof-de'>
								<p>
									Your profile information can be accessed here, you can change
									your password and email address.
								</p>
							</div>
							<Link
								to='/personal-checkout-page'
								style={{ textDecoration: 'none' }}
							>
								<div className='account-overviewsub-prof-info'>
									<p>Edit Information</p>
								</div>
							</Link>
						</div>

						{/* <div className="account-overviewsub-fam">
              <div className="account-overviewsub-prof">
                <p>Children Profile</p>
              </div>

              <div className="account-overviewsub-prof-de">
                <p>
                  Add your children’s profile, to get discounts that can be
                  converted to cash.
                </p>
              </div>
              <div className="account-overviewsub-prof-info">
                <p>See Profile</p>
              </div>
            </div> */}

						<div className='account-overviewsub-fam'>
							<div className='account-overviewsub-prof'>
								<p>Address Book</p>
							</div>

							<div className='account-overviewsub-prof-de'>
								<p>View and add new delivery address here.</p>
							</div>
							<div
								className='account-overviewsub-prof-info'
								onClick={() => navigation('/personal-checkout-address')}
							>
								<p>See address books</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AccountCheckout;
