import React from "react";
import "./myOrdersHeaders.scss";

const MyOrdersHeaders = () => {
  return (
    <div>
      <div className="myOrderBlank-over-famFlex">
        <div className="myOrderBlank-selection-add-text">My Orders</div>
        {/* <div className="myOrderBlank-selection-select">
          <select>
            <option>All Orders</option>
            <option>Option 1</option>
            <option>Option 2</option>
          </select>
        </div> */}
      </div>
      <div className="myOrderBlank-selection-line"></div>
    </div>
  );
};

export default MyOrdersHeaders;
