import Ratings from '../../../components/ratings/Ratings'
import { getCurrencySignByShort } from '../../../helpers/currencyUtilis'

const SimilarExperiences = (data) => {
  let array = [
    <div className='flex justify-between slide-card'>
      {data.length < 1 ? (
        <p>No Similar Events</p>
      ) : (
        <>
          {' '}
          {data.slice(0, 3).map((item, i) => (
            <div className='single-card' key={i}>
              <div className='card-rest'>
                <img src={item.main_image} alt='' />
                <h4>{item.name}</h4>
                <div className='rows flex'>
                  <Ratings average_rating={item.average_rating} />{' '}
                  <span className='number'>{item.reviews.length}</span>
                </div>
                <div className='rows'>
                  <p>{item.duration}</p>
                </div>
                <div className='rows'>
                  <p>Free Cancellation</p>
                </div>
                <div className='rows'>
                  <h3>
                    From {getCurrencySignByShort(item.currency)}
                    {parseInt(item.adult_price) > parseInt(item.child_price)
                      ? parseInt(item.child_price)
                      : parseInt(item.adult_price)}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>,
  ]
  if (data.length > 3) {
    array.push(
      <div className='flex justify-between slide-card'>
        {data.slice(3, 6).map((item, i) => (
          <div className='single-card' key={i}>
            <div className='card-rest'>
              <img src={item.main_image} alt='' />
              <h4>{item.name}</h4>
              <div className='rows flex'>
                <Ratings average_rating={item.average_rating} />{' '}
                <span className='number'>{item.reviews.length}</span>
              </div>
              <div className='rows'>
                <p>{item.duration}</p>
              </div>
              <div className='rows'>
                <p>Free Cancellation</p>
              </div>
              <div className='rows'>
                <h3>
                  From {getCurrencySignByShort(item.currency)}
                  {parseInt(item.adult_price) > parseInt(item.child_price)
                    ? parseInt(item.child_price)
                    : parseInt(item.adult_price)}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  if (data.length > 6) {
    array.push(
      <div className='flex justify-between slide-card'>
        {data.slice(6, 9).map((item, i) => (
          <div className='single-card' key={i}>
            <div className='card-rest'>
              <img src={item.main_image} alt='' />
              <h4>{item.name}</h4>
              <div className='rows flex'>
                <Ratings average_rating={item.average_rating} />{' '}
                <span className='number'>{item.reviews.length}</span>
              </div>
              <div className='rows'>
                <p>{item.duration}</p>
              </div>
              <div className='rows'>
                <p>Free Cancellation</p>
              </div>
              <div className='rows'>
                <h3>
                  From {getCurrencySignByShort(item.currency)}
                  {parseInt(item.adult_price) > parseInt(item.child_price)
                    ? parseInt(item.child_price)
                    : parseInt(item.adult_price)}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
  return array
}
export default SimilarExperiences
