import React, { useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import { hideLoader, showLoader } from '../../loader';
import { axiosCalls } from '../../_api';
import { Toast } from '../../helpers/toast';

import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg';
import eye from '../../assets/icon/eye.svg';

import './resetPassword.scss';

const ResetPassword = () => {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();
	const token = searchParams.get('token');
	const email = searchParams.get('email');

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			token: token,
			email: email,
			password: password,
			password_confirmation: confirmPassword,
		};

		if (password !== confirmPassword) {
			return Toast('error', 'Passwords do not match.');
		}

		showLoader();

		const res = await axiosCalls('auth/password/reset', 'POST', data);

		if (res) {
			hideLoader();

			if (res.er) {
				return Toast('error', res.er.message);
			}

			Toast('success', res.message);

			setTimeout(() => {
				navigate('/login');
			}, 2000);
		}
	};

	return (
		<div>
			<div className='resetPass-page-header'>
				<div className='resetPass-page-logo'>
					<img src={bubbleColonyLogoHome} alt='home_logo' />
				</div>

				<Link to='/login' style={{ textDecoration: 'none' }}>
					<div className='login-account-btn'>Login</div>
				</Link>
			</div>

			<div className='resetPass-page-fam'>
				<div className='resetPass-text-page'>Reset Password?</div>

				<div className='reset-password-text-re'>
					Enter your new password, Your new password must be different from your
					previous password.
				</div>

				<form className='resetPass-input-fam'>
					<div className='reset-password-cont1'>
						<div className='reset-password-label'>Enter new password </div>

						<div className='reset-password-input'>
							<input
								type={showPass ? 'text' : 'password'}
								placeholder='Enter Password'
								onChange={(e) => setPassword(e.target.value)}
							/>

							<span onClick={() => setShowPass(!showPass)}>
								{showPass ? (
									<img className='password-icon' src={eye} alt='eye' />
								) : (
									<img className='password-icon' src={eye} alt='eye' />
								)}
							</span>
						</div>
					</div>

					<div className='reset-password-cont2'>
						<div className='reset-password-label'>Confirm new password</div>
						<div className='reset-password-input'>
							<input
								type={showConfirmPass ? 'text' : 'password'}
								placeholder='Confirm Password'
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>

							<span onClick={() => setShowConfirmPass(!showConfirmPass)}>
								{showConfirmPass ? (
									<img className='password-icon' src={eye} alt='eye' />
								) : (
									<img className='password-icon' src={eye} alt='eye' />
								)}
							</span>
						</div>
					</div>

					<div className='resetPass-btn'>
						<input type='submit' value='Confirm' onClick={handleSubmit} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default ResetPassword;
