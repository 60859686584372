import React, { useState } from 'react';
import './personalInfoCheckoutAddress.scss';
import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import LeftBar from '../../components/leftBar/LeftBar';
import backIconBlue from '../../assets/icon/backIconBlue.svg';
import addressIcon from '../../assets/icon/addressIcon.svg';
import addTickIcon from '../../assets/icon/addTickIcon.svg';

import eye from '../../assets/icon/eye.svg';
import { Link, useNavigate } from 'react-router-dom';
import CheckoutInfoAddressBox from '../../components/checkoutAddressInfoBox/CheckoutInfoAddressBox';
import { hideLoader, showLoader } from '../../loader';
import { axiosCalls } from '../../_api';
import { Toast } from '../../helpers/toast';

const PersonalInfoCheckoutAddress = () => {
	const [defaultAddress, setDefaultAddress] = useState(false);
	const [otherAddress, setOtherAddress] = useState(false);
	const [address, setAddress] = useState([]);

	const navigate = useNavigate();

	React.useEffect(() => {
		getAllAddress();
	}, []);

	const defaultAddressBtn = () => {
		setDefaultAddress(true);
		setOtherAddress(false);
	};

	const getAllAddress = async (from) => {
		showLoader();
		const res = await axiosCalls(`address-book`, 'get');
		if (res) {
			hideLoader();
			if (res.er) {
				Toast('error', res.er.message);
				setAddress([]);
				return;
			}
			setAddress(res.message);
			return;
		}
		setAddress([]);
	};

	const deleteAddress = async (id) => {
		showLoader();
		const res = await axiosCalls(`address-book/${id}`, 'delete');
		if (res) {
			hideLoader();
			if (res.er) {
				Toast('error', res.er.message);
				return;
			}
			getAllAddress();
			return;
		}
	};

	return (
		<div>
			<Navbar />
			<div className='personalCheckAdd-checkout-fam'>
				<LeftBar />
				<div className='personalCheckAdd-over-famwww'>
					<div className='personalCheckAdd-over-goBack'>
						{/* <Link to="/account-checkout" style={{ textDecoration: "none" }}> */}
						<div
							className='personalCheckAdd-over-goBackImg'
							onClick={() => navigate(-1)}
						>
							<img src={backIconBlue} />
						</div>
						{/* </Link> */}
						<div className='personalCheckAdd-over-text'>Go Back</div>
					</div>

					<div className=' personalCheckAdd-over-fam'>
						<div className='personalCheckAdd-over-heading-cont'>
							<h3>Profile Information</h3>
						</div>
						<div className='personalCheckAdd-over-fam-line'></div>

						<div className='personalCheckAddaddress-selection'>
							<CheckoutInfoAddressBox
								defaultAddressBtn={defaultAddressBtn}
								data={address}
								defaultAddress={defaultAddress}
								otherAddress={otherAddress}
								deleteAddress={deleteAddress}
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default PersonalInfoCheckoutAddress;
