import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import CartItemCardAcc from '../../components/cartItemCardAcc/CartItemCardAcc'
import CheckoutCom from '../../components/checkoutCom/CheckoutCom'
import CheckoutComTwo from '../../components/checkoutCom2/CheckoutComTwo'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import ConfirmAddressModal from '../../components/confirmAddressModal/ConfirmAddressModal'
import './style.scss'
import { hideLoader, showLoader } from '../../loader'
import { axiosCalls } from '../../_api'
import { Toast } from '../../helpers/toast/index'
import { PaystackButton } from 'react-paystack'
import { useSelector } from 'react-redux'

const CheckoutDelivery = () => {
  const navigate = useNavigate()

  const [openLoginModal, setOpenLoginModal] = useState(false)
  let [tab, setTab] = useState(1)
  const [address, setAddress] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [cartSummary, setCartSummary] = useState()
  const user = useSelector((state) => state.auth.user)

  React.useEffect(() => {
    getAllAddress()
  }, [])

  const closeLoginModal = () => {
    setOpenLoginModal(!openLoginModal)
  }

  const openLoginModalBtn = () => {
    setOpenLoginModal(true)
  }

  const tabOneHandleSubmit = () => {
    setTab(2)
  }

  const goBackHandleBtn = () => {
    setTab(tab - 1)
  }

  const getAllAddress = async (from) => {
    showLoader()

    const res = await axiosCalls(`address-book`, 'get')

    if (res) {
      hideLoader()
      if (res.er) {
        Toast('error', res.er.message)
        setAddress([])
        return
      }

      setSelectedAddress(res.message[0])
      setAddress(res.message)
      return
    }

    setAddress([])
  }

  const order = async (e) => {
    showLoader()

    const data = {
      payment_method_id: 1,
      amount: cartSummary?.estimated_total,
      address_book_id: selectedAddress.id,
      children_profile_id: 1,
      // product_id: "052a02ba-7fcd-4b5d-b0a4-21a9f31c2d8f",
      quantity: 3,
      trans_id: e.reference,
      reference: e.transaction,
      type: 1,
      coupon_code: '',
    }

    const res = await axiosCalls(`checkout`, 'post', data)

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)
        return
      }

      closeLoginModal()
      // Toast("success", res?.message);
      navigate('/order-success')
    }
  }

  const handleOnSuccess = (e) => {
    // Call payment buy ticket api
    // access transaction ID using e.trxref or e.reference
    order(e)
    localStorage.setItem('product_cart', JSON.stringify([]))
  }

  const handleOnClose = () => {
    // Optional
    // Do any custom action like show modal or log cart
    Toast.error('error', 'Payment failed Please retry.')
  }

  return (
    <div className='checkout-main'>
      <Navbar />
      <div className='checkout-add-fam'>
        {tab === 2 && (
          <CheckoutCom
            tabOneHandleSubmit={tabOneHandleSubmit}
            openLoginModalBtn={openLoginModalBtn}
            getAllAddress={getAllAddress}
          />
        )}

        {tab === 1 && (
          <CheckoutComTwo
            goBackHandleBtn={goBackHandleBtn}
            openLoginModalBtn={openLoginModalBtn}
            setTab={setTab}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            address={address[0]}
            allAddress={address}
            handleOnSuccess={handleOnSuccess}
            handleOnClose={handleOnClose}
            cartSummary={cartSummary}
            user={user}
          />
        )}

        <div className='checkout-add-cont-main2'>
          <div className='checkout-add-header-two'>
            <div className='checkout-add-header-step1'>
              <div className='step-1-cont'>1</div>{' '}
              <div className='step-1-text'>
                <h3>Delivery address</h3>
              </div>
            </div>
            <div className='checkout-step-dash'>-</div>
            <div className='checkout-add-header-step2'>
              <div className='step-2-cont'>2</div>
              <div className='step-2-text'>
                <h3>Payment Method</h3>
              </div>
            </div>
          </div>

          <CartItemCardAcc setCartSummary={setCartSummary} />
        </div>
      </div>

      {openLoginModal && (
        <ConfirmAddressModal
          closeLoginModal={closeLoginModal}
          address={address}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          handleOnSuccess={handleOnSuccess}
          handleOnClose={handleOnClose}
          cartSummary={cartSummary}
          user={user}
        />
      )}
      <Footer />
    </div>
  )
}

export default CheckoutDelivery
