import React from 'react'

function ObjectExist(object, value) {
  // Check if object is defined and not null
  if (object && typeof object === 'object') {
    // Use optional chaining to safely access object[value]
    return object?.[value] !== undefined
  } else {
    return false // Return false if object is undefined or null
  }
}

export default ObjectExist
