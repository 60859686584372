import Routes from './routes'
import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  )
}

export default App
