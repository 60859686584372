import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import './Slider.scss'

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      // If we're at the last slide, wrap around to the first slide
      return (prevIndex + 1) % slides.length
    })
  }

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      // If we're at the first slide, wrap around to the last slide
      return (prevIndex - 1 + slides.length) % slides.length
    })
  }
  return (
    <div className='slider-container'>
      <div className='slider-header flex justify-between'>
        <div className='un'></div>
        <div className='duo flex items-center'>
          <div className='slider-controls flex items-center '>
            <Icon
              icon={'mingcute:left-line'}
              onClick={prevSlide}
              disabled={currentIndex === 0}
            />
            <p>
              {currentIndex + 1}/{slides.length}
            </p>
            <Icon
              icon={'mingcute:right-line'}
              onClick={nextSlide}
              disabled={currentIndex === slides.length - 1}
            />
          </div>
        </div>
      </div>
      <div
        className='slider-content'
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className='slide'>
            {slide}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Slider
