import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../_api'
import productPencil from '../../../../../assets/icon/productPencil.svg'

import productThreeDot from '../../../../../assets/icon/productThreeDot.svg'
import '../../productTable.css'
import { hideLoader, showLoader } from '../../../../../loader'

export default function ExperienceTable({
  handleToggleEditModal,
  setSelectedExperience,
  handleToggleModal,
  setShowNewModal,
  // handleToggleNewModal,
}) {
  const [loading1, setLoading1] = useState(true)

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([])

  useEffect(() => {
    getAllMySubAccountsDat()
  }, [])

  const getAllMySubAccountsDat = async () => {
    showLoader()
    setLoading1(true)
    const res = await axiosCalls('admin/experiences', 'get')
    if (res) {
      hideLoader()
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        hideLoader()
        return
      }
      setAllMySubAccountsData(res?.data?.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: false,
          pageSize: 5,
        }}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Date', field: 'date' },
          { title: 'Child price', field: 'child' },
          { title: 'Adult price', field: 'adult' },
          { title: 'Action', field: 'Action' },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            name: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedExperience(data)
                  handleToggleModal()
                }}
                className='productName-GenFam'
              >
                <div className='productName-GenImgName'>{data?.name}</div>
                {/* {!data?.vendor ? "-" : data?.vendor}{" "} */}
              </div>
            ),
            date: new Date(data?.event_date).toDateString(),
            child: `₦${data?.child_price.toLocaleString()}`,
            adult: `₦${data?.adult_price.toLocaleString()}`,
            Action: (
              <div className='subAccountSub-action'>
                {/* <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: "rgba(128, 128, 128, 0.103)" }
                        : { background: " rgb(199, 13, 13)" }
                      : {}
                  }
                >
                  Cancel subscription
                </button> */}

                <div className='subAccountSub-actionPenProd'>
                  <img
                    src={productPencil}
                    onClick={() => {
                      setSelectedExperience(data)
                      setShowNewModal(true)
                    }}
                  />
                </div>

                <div className='subAccountSub-actionDotProd'>
                  <img src={productThreeDot} />
                </div>
              </div>
            ),
            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title=''
      />
    </div>
  )
}
