import React from 'react'
import './dashboardTop.scss'

function DashboardTop({ tag, buttonTag, onclick }) {
  return (
    <div className='DashboardTop'>
      <div className='DashboardTop-left'>
        <h2>{tag}</h2>
      </div>
      <div className='DashboardTop-right'>
        <button onClick={onclick}>{buttonTag}</button>
      </div>
    </div>
  )
}

export default DashboardTop
