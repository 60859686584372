import React, { useEffect, useState, useContext } from 'react'
import designerBabyToy from '../../assets/icon/designerBabyToy.png'
import designerBabyToyOp from '../../assets/icon/designerBabyToyOp.png'
import designerBabyToyCo from '../../assets/icon/designerBabyToyCo.png'
import ReactStars from 'react-rating-stars-component'
import './index.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { axiosCalls } from '../../_api'
import { hideLoader, showLoader } from '../../loader'
import { Toast } from '../../helpers/toast/index'
import { CartContext } from '../../context/cart-context'
import ObjectExist from '../objectExist/ObjectExist'

const SingleProductsCard = ({
  product,
  activeProductImage,
  setActiveProductImage,
  auth,
}) => {
  const { fetchLocalCart } = useContext(CartContext)

  const navigate = useNavigate()

  const [num, setNum] = useState(1)
  const ratingChanged = (newRating) => {
  }

  let incNum = () => {
    if (num < Number(product?.stock_quantity)) {
      setNum(Number(num) + 1)
    }
  }

  let decNum = () => {
    if (num > 1) {
      setNum(num - 1)
    }
  }

  let handleChange = (e) => {
    setNum(e.target.value)
  }

  const addToCart = async (from) => {
    const localData = {
      product_id: product.id,
      quantity: num,
      price: product.price,
      name: product.name,
      inventory_status: product.inventory_status,
      image: product.main_image,
      discounted_price: product.discounted_price,
    }
    let productCart = JSON.parse(localStorage.getItem('product_cart')) || []
    const existingProductIndex = productCart.findIndex(
      (item) => item.product_id === product.id
    )
    if (existingProductIndex !== -1) {
      productCart[existingProductIndex].quantity += num
    } else {
      productCart.push(localData)
    }
    localStorage.setItem('product_cart', JSON.stringify(productCart))
    fetchLocalCart()
    const data = {
      product_id: product.id,
      quantity: num,
    }
    if (auth) {
      showLoader()
      const res = await axiosCalls(`cart`, 'post', data)
      if (res) {
        hideLoader()
        if (res.er) {
          if (res.er.status_code === '401') {
            Toast('error', 'Please login to continue.')
            setTimeout(() => {
              navigate('/login')
            }, 3000)
          } else {
            Toast('error', res.er.message)
          }
          return
        }
        Toast('success', 'Product added to cart successfully')
        navigate('/cart-page')
        fetchLocalCart()
      }
    } else {
      Toast('success', 'Product added to cart successfully')
      navigate('/cart-page')
    }
  }

  const addToCartLocal = async (from) => {
    const data = {
      product_id: product.id,
      quantity: num,
    }

    Toast('success', 'Product added to cart successfully')

    if (from === 'buy') {
      navigate('/cart-page')
    }

    window.location.reload()
  }

  return (
    <div className='product-main'>
      <div className='single-product-main-flex'>
        <div className='single-product-left'>
          <div className='single-product-main-img'>
            <img src={activeProductImage} alt='product_img' />
          </div>
          <div className='single-product-op'>
            {product.images?.map((img, i) => {
              return (
                <div className='single-product-op-each' key={i + 1}>
                  <img
                    alt=''
                    src={img}
                    onClick={() => {
                      setActiveProductImage(img)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <div className='single-product-right'>
          <div className='single-product-main-name'>
            <h2>{product?.product_name} </h2>
          </div>
          <div className='single-product-star-family'>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={24}
              activeColor='#ffd700'
            />
            <p className='single-product-review-count'>
              32 Reviews |<span> Sold by {product?.vendor.vendor_name}</span>
            </p>
          </div>
          <div className='single-product-border'></div>
          <div className='single-product-main-price-tag'>
            <p>Price</p>
          </div>
          <div className='single-product-main-price'>
            <p>
              ₦{product?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </p>
          </div>
          {ObjectExist(product, 'colors') && (
            <div className='single-product-main-color-fam'>
              <div className='single-product-color-text'>Color:</div>
              <div className='single-product-color-op'>
                {product.colors ? (
                  <>
                    {' '}
                    {product.colors.map((item) => (
                      <div className='single-product-color-op-each'>
                        <div
                          style={{
                            background: item,
                            width: '100%',
                            height: '100%',
                          }}
                        ></div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p>As seen</p>
                )}
              </div>
            </div>
          )}

          <div className='single-product-color-size-fam'>
            <div className='single-product-color-size-text'>Size:</div>
            <div className='single-product-size-op'>
              <div className='single-product-each-size'>10</div>
              <div className='single-product-each-size'>12</div>
              <div className='single-product-each-size'>14</div>
            </div>
          </div>

          <div className='single-product-in-stock'>
            {' '}
            <p>In Stock </p>
          </div>
          <div className='single-product-quantity-fam'>
            <div className='single-product-quantity-text'>
              {' '}
              <p>Quantity:</p>
            </div>
            <div className='single-product-quantity-count-fam'>
              <div onClick={decNum} className='single-product-quantity-minus'>
                -
              </div>
              <div className='single-product-quantity-show'>
                <input type='text' value={num} onChange={handleChange} />
              </div>
              <div onClick={incNum} className='single-product-quantity-add'>
                +
              </div>
            </div>
          </div>

          <div className='single-product-quantity-btns'>
            <div
              className='single-product-add-cart-btn'
              onClick={() => addToCart()}
            >
              Add to Cart
            </div>

            <div
              onClick={
                // () => addToCart('buy')
                // auth ? () => addToCart('buy') : openLoginModalBtn
                auth ? () => addToCart('buy') : () => addToCartLocal('buy')
              }
              className='single-product-buy-now-btn'
            >
              BUY NOW
            </div>
          </div>
        </div>
      </div>
      <div className='product-card'>
        <div className='card-head'>
          <h2>Product description</h2>
        </div>
        <div className='des'>
          <p>{product.description}</p>
        </div>
      </div>
      <div className='product-card'>
        <div className='card-head'>
          <h2>Product details</h2>
        </div>
        <div className='det'>
          <p>
            <span> Date First Available :</span>
            {new Date(product.created_at).toDateString()}
          </p>
          {product.dimension && (
            <p>
              <span> Dimension :</span>
              {product.dimension}
            </p>
          )}

          <p>
            <span> Stock quantity :</span>
            {product.stock_quantity}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleProductsCard
