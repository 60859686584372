import React, { useState } from 'react'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'
import closeOrderModal from '../../../../assets/icon/closeOrderModal.svg'
import { Toast } from '../../../../helpers/toast'
import { axiosCalls } from '../../../../_api'
import { hideLoader, showLoader } from '../../../../loader'
import { useForm } from 'react-hook-form'

function NewVendor({ handleToggleNewModal }) {
  const [name, setName] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  async function onSubmit(data) {
    showLoader()
    const submitData = {
      ...data,
      email: data.email.toLowerCase(),
      commission_fee: parseInt(data.commission_fee),
    }
    const response = await axiosCalls(`admin/vendor`, 'post', submitData)
    if (response) {
      hideLoader()
      if (response.er) {
        Toast('error', response.er.message)
        return
      }
      Toast('success', 'Vendor created successfully.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>New Color</div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>Vendor Name</div>
          <div className='newProductForm-NameInput'>
            <input
              type='text'
              name='vendor_name'
              {...register('vendor_name', {
                required: 'Vendor name is required',
              })}
            />
            {errors.vendor_name && (
              <h6 className='vError'>{errors.vendor_name.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>Contact Name</div>
          <div className='newProductForm-NameInput'>
            <input
              type='text'
              name='contact_name'
              {...register('contact_name', {
                required: 'Contact name is required',
              })}
            />
            {errors.contact_name && (
              <h6 className='vError'>{errors.contact_name.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'> Phone No.</div>
          <div className='newProductForm-NameInput'>
            <input
              type='tel'
              name='phone_no'
              {...register('phone_no', {
                required: 'Phone number is required',
              })}
            />
            {errors.phone_no && (
              <h6 className='vError'>{errors.phone_no.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'> Email</div>
          <div className='newProductForm-NameInput'>
            <input
              type='email'
              name='email'
              {...register('email', {
                required: 'Email is required',
              })}
            />
            {errors.email && <h6 className='vError'>{errors.email.message}</h6>}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'> Store Address</div>
          <div className='newProductForm-NameInput'>
            <input
              type='text'
              name='store_address'
              {...register('store_address', {
                required: 'Store address is required',
              })}
            />
            {errors.store_address && (
              <h6 className='vError'>{errors.store_address.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'> Description</div>
          <div className='newProductForm-NameInput'>
            <input
              type='text'
              name='description'
              {...register('description', {
                required: 'Description is required',
              })}
            />
            {errors.description && (
              <h6 className='vError'>{errors.description.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'> Commission Fee</div>
          <div className='newProductForm-NameInput'>
            <input
              type='number'
              name='commission_fee'
              {...register('commission_fee', {
                required: 'Commission fee is required',
              })}
            />
            {errors.commission_fee && (
              <h6 className='vError'>{errors.commission_fee.message}</h6>
            )}
          </div>
        </div>
        <div className='newProductForm-SaveGen' style={{ marginTop: '30px' }}>
          <button className='newProductForm-SaveChanges'>Create</button>
        </div>
      </form>
    </ModalLayout>
  )
}

export default NewVendor
