import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../_api'
import { showLoader, hideLoader } from '../../../../../loader'

export function Table({
  setAddSubAccountModalFun,
  cancelSubscription,
  handleToggleModal,
}) {
  const [loading1, setLoading1] = useState(true)

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([])

  useEffect(() => {
    getAllMySubAccountsDat()
  }, [])

  const getAllMySubAccountsDat = async () => {
    setLoading1(true)

    showLoader()
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      hideLoader()
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setAllMySubAccountsData(res?.data?.recent_orders)
      hideLoader()
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  // const allMySubAccountsData = [
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Joe McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Mac McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Roland McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Eze McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Okeke McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Andrew McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  // ];

  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: 'ID', field: 'ID' },
          { title: 'Date', field: 'date' },
          { title: 'Customer', field: 'customer' },
          { title: 'Amount', field: 'amount' },
          { title: 'Vendor', field: 'vendor' },
          { title: 'Status', field: 'status' },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            ID: data?.id,
            date: data?.created_at.slice(0, 10),
            customer: `${data?.user?.first_name}`,
            amount: data?.total_price,
            vendor: !data?.vendor ? 'No Vendor from Data' : data?.vendor,
            status: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggleModal()
                }}
              >
                {!data?.status ? 'no server data' : data?.status}
              </div>
            ),
            Action: (
              <div className='subAccountSub'>
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: 'rgba(128, 128, 128, 0.103)' }
                        : { background: ' rgb(199, 13, 13)' }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title='Recent Orders'
      />
    </div>
  )
}
