const CurrencyData = [
  {
    name: 'Australian Dollar',
    sign: '$',
    short: 'AUD',
    country: 'Australia',
  },
  {
    name: 'Brazilian Real',
    sign: 'R$',
    short: 'BRL',
    country: 'Brazil',
  },
  {
    name: 'British Pound',
    sign: '£',
    short: 'GBP',
    country: 'United Kingdom',
  },
  {
    name: 'Canadian Dollar',
    sign: '$',
    short: 'CAD',
    country: 'Canada',
  },
  {
    name: 'Chinese Yuan',
    sign: '¥',
    short: 'CNY',
    country: 'China',
  },
  {
    name: 'Egyptian Pound',
    sign: '£',
    short: 'EGP',
    country: 'Egypt',
  },
  {
    name: 'Euro',
    sign: '€',
    short: 'EUR',
    country: 'Eurozone',
  },
  {
    name: 'Ghanaian Cedi',
    sign: '₵',
    short: 'GHS',
    country: 'Ghana',
  },
  {
    name: 'Kenyan Shilling',
    sign: 'KSh',
    short: 'KES',
    country: 'Kenya',
  },
  {
    name: 'Japanese Yen',
    sign: '¥',
    short: 'JPY',
    country: 'Japan',
  },
  {
    name: 'Moroccan Dirham',
    sign: 'د.م.',
    short: 'MAD',
    country: 'Morocco',
  },
  {
    name: 'Nigerian Naira',
    sign: '₦',
    short: 'NGN',
    country: 'Nigeria',
  },
  {
    name: 'South African Rand',
    sign: 'R',
    short: 'ZAR',
    country: 'South Africa',
  },
  {
    name: 'Swiss Franc',
    sign: 'CHF',
    short: 'CHF',
    country: 'Switzerland',
  },
  {
    name: 'Indian Rupee',
    sign: '₹',
    short: 'INR',
    country: 'India',
  },
  {
    name: 'US Dollars',
    sign: '$',
    short: 'USD',
    country: 'United States of America',
  },
  {
    name: 'Tanzanian Shilling',
    sign: 'TSh',
    short: 'TZS',
    country: 'Tanzania',
  },
  {
    name: 'Ugandan Shilling',
    sign: 'USh',
    short: 'UGX',
    country: 'Uganda',
  },
  {
    name: 'Botswana Pula',
    sign: 'P',
    short: 'BWP',
    country: 'Botswana',
  },
]

export default CurrencyData
