import React from 'react'
import './style.scss'
import masterCard11 from '../../assets/icon/masterCard11.png'
import visaCard11 from '../../assets/icon/visaCard11.png'
import verveCard from '../../assets/icon/verveCard.png'
import paga from '../../assets/icon/paga.svg'
import safeLock from '../../assets/icon/safeLock.png'
import { axiosCalls } from '../../_api'

const CartItemCardAcc = ({ setCartSummary }) => {
  const [cart, setCart] = React.useState()

  React.useEffect(() => {
    getCart()
  }, [])

  const getCart = async () => {
    const res = await axiosCalls(`cart-summary`, 'get')

    if (res) {
      if (res.er) {
        return
      }

      setCartSummary(res.data)
      setCart(res.data)
    }
  }

  return (
    <div className='checkout-add-cont-two'>
      <div className='cart-item-price-dis-fam'>
        <h3>Order Summary</h3>

        <div className='cart-item-num-price'>
          <div className='cart-num-item'>
            <p>Items ({cart?.total_items}):</p>
          </div>
          <div className='cart-num-price'>
            <p>₦{cart?.total_amount?.toLocaleString()}</p>
          </div>
        </div>

        {cart?.discount > 0 && (
          <div className='cart-item-num-discount'>
            <div className='cart-discount-item'>
              <p>Order Discounts:</p>
            </div>
            <div className='cart-discount-price'>
              <p>-₦{cart?.discount.toFixed(2)}</p>
            </div>
          </div>
        )}
      </div>

      <div className='cart-item-shipping-fam'>
        <div className='cart-item-shipping-cont'>
          <div className='cart-shipping-text'>
            <p>Estimated Shipping</p>
          </div>
          <div className='cart-shipping-add'>
            {/* <p>Add your Delivery address at checkout to see delivery charges</p> */}
            <p>₦0</p>
          </div>
        </div>
      </div>

      <div className='cart-item-total-fam'>
        <div className='cart-item-total-cont'>
          <div className='cart-total-text'>
            <p>Estimated Total</p>
          </div>
          <div className='cart-total-add'>
            <p>₦{cart?.estimated_total}</p>
          </div>
        </div>
      </div>

      {/* <div className="cart-item-btn-fam">
        <div className="cart-item-btn-check">Continue to Checkout</div>
        <div className="cart-item-btn-shopping">Continue Shopping</div>
      </div> */}

      <div className='cart-item-card-accept'>
        <div className='cart-card-list'>
          <p>We accept</p>
        </div>
        <div className='master-card-img'>
          <img src={masterCard11} />
        </div>
        <div className='visa-card-img'>
          <img src={visaCard11} />
        </div>
        <div className='verve-card-img'>
          <img src={verveCard} />
        </div>
        <div className='paga-img'>
          <img src={paga} />
        </div>
      </div>

      <div className='cart-secure-cont'>
        <div className='cart-secure-img'>
          <img src={safeLock} />
        </div>
        <div className='cart-secure-safe-text'>
          <p>100% Safe and Secure</p>
        </div>
      </div>
    </div>
  )
}

export default CartItemCardAcc
