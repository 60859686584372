import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './myOrdersBlank.scss';

import Footer from '../../components/layout/footer';
import Navbar from '../../components/layout/navbar';
import LeftBar from '../../components/leftBar/LeftBar';
import Status from '../../components/status/status';

import back from '../../assets/icon/chevron-left.svg';

const MyOrdersBlank = () => {
	const [order, setOrder] = useState({});

	const navigate = useNavigate();

	React.useEffect(() => {
		let data = localStorage.getItem('orders');
		let p = JSON.parse(data);

		setOrder(p);
	}, []);

	return (
		<div>
			<Navbar />
			<div className='myOrderBlank-checkout-add'>
				<LeftBar />
				<div className='myOrderBlank-checkout-rightForm'>
					<div className='myOrderBlank-over-fam'>
						<div className='back-btn' onClick={() => navigate(-1)}>
							<img src={back} alt='back' /> Go Back
						</div>

						<div className='order-info'>
							<div className='order-info-header'>
								<div className='order-info-item'>
									<p>Order ID</p>
									<h2>{order?.id}</h2>
								</div>

								<div className='order-info-item'>
									<p>No. of Items</p>
									<h2>{order?.order_item?.length}</h2>
								</div>

								<div className='order-info-item'>
									<p>Total Amount</p>
									<h2>₦{order?.total_price}</h2>
								</div>

								<div className='order-info-item'>
									<p>Status</p>
									<Status
										textColor={order?.order_status?.name}
										text={order?.delivery_status?.name}
									/>
								</div>

								{/* <div className='order-info-item'>
									<button>Cancel Order</button>
								</div> */}
							</div>
						</div>

						<div className='order-items'>
							<div className='header'>
								<h2>Items in your order</h2>
							</div>

							{order?.order_item?.map((data, index) => {
								return (
									<div className='order-item' style={{ marginBottom: '20px' }}>
										<div className='order-item-desc'>
											<div>
												<img src={data?.product?.main_image} alt='' />
											</div>
											<div>
												<p>{data?.product?.product_name}</p>
											</div>
										</div>

										<div className='order-item-info'>
											<p>{data?.quantity} piece(s)</p>
										</div>

										<div className='order-item-info'>
											<p>₦{data?.total_amount}</p>
										</div>
									</div>
								);
							})}
						</div>

						<div className='payment-delivery-info'>
							<div className='payment-info'>
								<h3>Payment Details</h3>

								<hr />

								<div className='payment-info-content'>
									<p>Payment Method:</p>
									<p>{order?.payment_method?.name}</p>
								</div>

								<div className='payment-info-content'>
									<p>Total Amount:</p>
									<p>₦{order?.total_price?.toLocaleString()}</p>
								</div>

								<div className='payment-info-content'>
									<p>Shipping Fees:</p>
									<p>₦{order?.shipping_price?.toLocaleString()}</p>
								</div>

								<div className='payment-info-content'>
									<p>Total Amount Paid:</p>
									<p
										style={{
											fontWeight: 'bold',
										}}
									>
										₦{order?.total_price?.toLocaleString()}
									</p>
								</div>
							</div>

							<div className='delivery-info'>
								<h3>Delivery Details</h3>

								<hr />

								<div className='delivery-info-content'>
									<p>Shipping Address:</p>
									<p>{order?.address_book?.address}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default MyOrdersBlank;
