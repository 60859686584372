import CurrencyData from '../assets/Data/CurrencyData'

/**
 * Function to get currency sign by short code
 * @param {string} short - The short code of the currency (e.g., 'USD')
 * @returns {string|null} - The currency sign (e.g., '$') or null if not found
 */
export const getCurrencySignByShort = (short) => {
  const currency = CurrencyData.find((currency) => currency.short === short)
  return currency ? currency.sign : null
}

/**
 * Generic function to get a property value based on another property value
 * @param {string} key - The key to match (e.g., 'short')
 * @param {string} value - The value to match (e.g., 'USD')
 * @param {string} returnKey - The key of the property to return (e.g., 'sign')
 * @returns {*} - The value of the returnKey or null if not found
 */
export const getCurrencyProperty = (key, value, returnKey) => {
  const currency = CurrencyData.find((currency) => currency[key] === value)
  return currency ? currency[returnKey] : null
}
