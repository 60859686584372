import React from "react";

const CustomersIcon = ({ fill }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16C0 14.3834 0.642175 12.8331 1.78525 11.69C2.92833 10.5469 4.47868 9.90476 6.09524 9.90476C7.7118 9.90476 9.26214 10.5469 10.4052 11.69C11.5483 12.8331 12.1905 14.3834 12.1905 16H10.6667C10.6667 14.7876 10.185 13.6248 9.32773 12.7675C8.47042 11.9102 7.30766 11.4286 6.09524 11.4286C4.88282 11.4286 3.72006 11.9102 2.86275 12.7675C2.00544 13.6248 1.52381 14.7876 1.52381 16H0ZM6.09524 9.14286C3.56952 9.14286 1.52381 7.09714 1.52381 4.57143C1.52381 2.04571 3.56952 0 6.09524 0C8.62095 0 10.6667 2.04571 10.6667 4.57143C10.6667 7.09714 8.62095 9.14286 6.09524 9.14286ZM6.09524 7.61905C7.77905 7.61905 9.14286 6.25524 9.14286 4.57143C9.14286 2.88762 7.77905 1.52381 6.09524 1.52381C4.41143 1.52381 3.04762 2.88762 3.04762 4.57143C3.04762 6.25524 4.41143 7.61905 6.09524 7.61905Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomersIcon;
