import { RiTruckLine } from 'react-icons/ri'
import { FiArchive } from 'react-icons/fi'
import { IoHeadset } from 'react-icons/io5'
import { BsCreditCardFill } from 'react-icons/bs'
const InfoData = [
  {
    id: 1,
    icon: RiTruckLine,
    tag: 'Easy Delivery Worldwide, ',
    details: 'we ship to your location.',
  },
  {
    id: 2,
    icon: FiArchive,
    tag: 'Discover over 1000+ Products ',
    details: 'Weekly new product drops',
  },
  {
    id: 3,
    icon: IoHeadset,
    tag: '24/7 Customer service',
    details: 'Call us 24/7 at 07034798939',
  },
  {
    id: 4,
    icon: BsCreditCardFill,
    tag: 'Secure payment',
    details: 'Your payment information is safe.',
  },
]
export default InfoData
