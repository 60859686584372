import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState: {
        open: false
    },
    reducers: {
        openSnackBar: (state, payload) => {
            const {message, title, severity} = payload
            state.open = true
            state.message = message
            state.title = title
            state.severity = severity
        },
        closeSnackBar: (state, payload) => {
            state.open = false
        },
    },

    extraReducers(builder) {},
});

export const { openSnackBar, closeSnackBar  } = snackbarSlice.actions;
export default snackbarSlice.reducer;
