import React from "react";
import babyImg from "../../assets/icon/babyImg.png";
import bubbleColonyLogo from "../../assets/icon/bubbleColonyLogo.svg";
import twitterIcon from "../../assets/icon/twitterIcon.svg";
import facebookIcon from "../../assets/icon/facebookIcon.svg";
import instagramIcon from "../../assets/icon/instagramIcon.svg";

import "./index.css";

const index = () => {
  return (
    <div className="app-padding">
      <div className="bubble_logo ">
        <img src={bubbleColonyLogo} alt="bubble_colony_logo" />
      </div>

      <div className="main_flex ">
        <div className="left_big_text">
          <div className="big_tag">
            {" "}
            <h2>
              The best stuff <br /> for children, all <br />
              in one place
            </h2>
          </div>
          <div className="left_small_text">
            <p>
              Join our community and be the first to know <br /> when we launch.
            </p>
          </div>

          <div class="search-container">
            <form>
              <div className="flex_search_input">
                <input type="text" placeholder="Your Email" />
                <button type="submit">Join Now</button>
              </div>
            </form>
          </div>
        </div>
        <div className="right_img">
          <img src={babyImg} alt="Baby_Img" />
        </div>
      </div>

      <div className="social_media_icon icons">
        <div>
          <img src={twitterIcon} alt="twitter_icon" />
        </div>
        <div>
          <img src={facebookIcon} alt="facebook_icon" />
        </div>
        <div>
          <img src={instagramIcon} alt="instagram_icon" />
        </div>
      </div>
    </div>
  );
};

export default index;
