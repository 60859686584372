import React from 'react'
import { Icon } from '@iconify/react'
import Ratings from '../ratings/Ratings'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import FormatNumber from '../formatNumber/FormatNumber'
import Slider from 'react-slick'

function ProductList({ data }) {
  let navigate = useNavigate()
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite looping of slides
    speed: 500, // Slide transition speed in ms
    slidesToShow: 4, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll per swipe
    autoplay: false, // Autoplay enabled
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true, // Enable autoplay for smaller screens
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true, // Enable autoplay for smaller screens
          autoplaySpeed: 2000,
        },
      },
    ],
  }
  return (
    <div className='productList'>
      <div className='wrapper'>
        <Slider {...settings}>
          {data.map((item, i) => (
            <div
              className='product-card'
              key={i}
              onClick={() => navigate(`/single-experience/${item.id}`)}
            >
              <div className='image-card'>
                <img src={item.main_image} alt='' />
              </div>
              <div className='flex items-center location'>
                <Icon icon='ic:round-location-on' />
                <span>
                  {item.city}, {item.country}
                </span>
              </div>
              <h2>{item.name}</h2>
              <div className='rows flex'>
                <Ratings average_rating={item.average_rating} />{' '}
                <span className='number'>{0}</span>
              </div>
              <div className='flex from'>
                <span>from</span>
                <h3>
                  {item.currency}
                  {parseInt(item.child_price) > parseInt(item.adult_price) ? (
                    <FormatNumber value={parseInt(item.adult_price)} />
                  ) : (
                    <FormatNumber value={parseInt(item.child_price)} />
                  )}
                </h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default ProductList
