import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg'

import './forgotPassword.scss'
import validator from 'validator'
import { hideLoader, showLoader } from '../../loader'
import { axiosCalls } from '../../_api'
import { Toast } from '../../helpers/toast'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const validateEmail = (e) => {
    let email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Please Enter valid a Email!')
    }
  }

  const handleReset = async (e) => {
    e.preventDefault()

    const data = {
      email: email,
    }

    if (email === '') {
      return Toast('error', 'Input email to continue')
    }

    showLoader()

    const res = await axiosCalls('password/email', 'POST', data)

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)
        return
      }

      Toast('success', res.message)
    }
  }

  return (
    <div className='forgot-pass-container'>
      <div className='forgotPass-page-header'>
        <div className='forgotPass-page-logo'>
          <img src={bubbleColonyLogoHome} alt='home_logo' />
        </div>

        <Link to='/login' style={{ textDecoration: 'none' }}>
          <div className='login-acc-btn'>Login</div>
        </Link>
      </div>

      <div className='forgotPass-page-fam'>
        <h1>Forgot Password?</h1>
        <p>
          Enter the email address for your Bubble account and we will send a
          reset link to your inbox. <br /> Follow the link to reset your
          password.
        </p>

        <form className='forgotPass-input-fam'>
          <div className='input-cont'>
            <label>Email</label>

            <input
              type='text'
              placeholder='Enter email address'
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </div>

          <div className='forgotPass-btn'>
            <input type='submit' value='Reset password' onClick={handleReset} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
