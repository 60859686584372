import React from "react";
// import userIcon from "../../assets/icon/userIcon.svg";
import "./layout.scss";
import { useNavigate } from "react-router-dom";

const AccountDropDown = ({ option, optionIcon, closeOption, link }) => {
  const navigation = useNavigate();
  return (
    <div
      className="account-drop-cont"
      onClick={() => {
        closeOption();
        navigation(link);
      }}
    >
      <img src={optionIcon} alt="user_icon" />

      <p>{option}</p>
    </div>
  );
};

export default AccountDropDown;
