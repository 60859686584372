import React, { useState, useEffect } from 'react'
import './InputToArray.scss'
import { Icon } from '@iconify/react'

// A reusable component that handles adding items to an array
const InputToArray = ({
  tag,
  placeholder = 'Type and press Enter',
  onItemsChange,
  initialItems,
}) => {
  const [inputValue, setInputValue] = useState('') // State for input value
  const [items, setItems] = useState([]) // State for items array

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  // Handle key press (enter key)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      const newItem = inputValue.trim()
      const newItems = [...items, newItem]
      setItems(newItems) // Add item to array
      setInputValue('') // Clear input field
      onItemsChange && onItemsChange(newItems)
    }
  }
  const handleRemoveItem = (indexToRemove) => {
    const newItems = items.filter((_, index) => index !== indexToRemove)
    setItems(newItems) // Remove item from array
    onItemsChange && onItemsChange(newItems) // Notify parent of items change
  }
  useEffect(() => {
    setItems(initialItems ?? [])
    onItemsChange && onItemsChange(initialItems)
  }, [initialItems])

  return (
    <div className='newProductForm-NameGen inputToArray'>
      <div className='newProductForm-NameGenTag'>{tag}</div>
      <div className='newProductForm-NameInput'>
        <input
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
        />
        <ul>
          {items.map((item, index) => (
            <li key={index} className='flex justify-between'>
              <span>{item}</span>
              <div>
                <Icon
                  icon={'humbleicons:times'}
                  onClick={() => handleRemoveItem(index)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default InputToArray
