import React from "react";
import "./customerLocation.scss";

const CustomerLocation = () => {
  return (
    <div className="customerLocation-genBody">
      <div className="chartHeader conversionRate-AverageHeading">
        Customer Location
      </div>

      <div className="averageOrder-recordFam">
        <div className="averageOrder-recordNum" style={{ marginRight: "20px" }}>
          Country
        </div>
        <div
          className="averageOrder-recordPercent"
          style={{ fontSize: "14px" }}
        >
          City
        </div>
      </div>
    </div>
  );
};

export default CustomerLocation;
