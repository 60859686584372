import React from "react";
import "./totalCustomers.scss";
import { GoCalendar } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
// import { Chart, Pallets, Table } from "./components";
import { Chart } from "../Chart";

import AdminArrowUp from "../../../../../assets/icon/AdminArrowUp.svg";

const TotalCustomers = () => {
  return (
    <div className="totalCustomer-GenBody">
      <div>
        <div className="chartHeader conversionRate-AverageHeading">
          Total Customer
          <select name="" id="">
            <GoCalendar />
            <option value="">Last Six Month</option>
          </select>
        </div>
      </div>
      <div className="averageOrder-recordFam">
        <div className="averageOrder-recordNum">6.37</div>
        <div className="averageOrder-recordPercent">+ 3.6%</div>
        <div className="averageOrder-recordArrow">
          <img src={AdminArrowUp} />
        </div>
      </div>
    </div>
  );
};

export default TotalCustomers;
