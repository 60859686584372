import React from 'react';
import { Link } from 'react-router-dom';
import { Toast } from '../../helpers/toast';
import { hideLoader, showLoader } from '../../loader';
import { axiosCalls } from '../../_api';
import AddressForm from '../addressForm/addressFormCreate';
import './checkoutCom.scss';

const CheckoutCom = ({
	tabOneHandleSubmit,
	openLoginModalBtn,
	getAllAddress,
}) => {
	const [info, setInfo] = React.useState({
		full_name: '',
		phone_no: '',
		address: '',
		city_id: 1,
		state_id: 1,
	});

	const create = async (from) => {
		showLoader();
		const data = info;

		const res = await axiosCalls(`address-book`, 'post', data);

		if (res) {
			hideLoader();

			if (res.er) {
				Toast('error', res.er.message);
				return;
			}

			Toast('success', 'Address saved successfully.');
		}
	};

	return (
		<div className='checkoutOne-add-cont-main1'>
			<div className='checkoutOne-add-header'>
				<h2>Checkout</h2>
			</div>

			<AddressForm
				formTitle='Delivery Address	'
				onSuccessSave={() => {
					getAllAddress();
					openLoginModalBtn();
					// if (from == "buy") {
					// }
				}}
				btnTitle='Continue to Payment'
			/>
		</div>
	);
};

export default CheckoutCom;
