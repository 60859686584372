import React from "react";
import orderAdminProduct from "../../../../../assets/icon/orderAdminProduct.png";
import "./ordersModal.scss";
const OrdersProduct = () => {
  return (
    <div className="adminOrders-summaryFamFlex">
      <div className="adminOrders-summaryFamImg">
        <img src={orderAdminProduct} />
      </div>{" "}
      <div className="adminOrders-summaryFamDetails">
        Baby Trend Serene Nursery Center, Hello Kitty Classic Dot
      </div>
      <div className="adminOrders-summaryItemNo">1 Item</div>
      <div className="adminOrders-summaryItemPrice">₦7,500.00</div>
    </div>
  );
};

export default OrdersProduct;
