import { ResponsiveLine } from "@nivo/line";
const Chart = () => {
  const data = [
    // {
    //   id: "Delivery",
    //   color: "#006FC1",
    //   data: [
    //     {
    //       x: "Mon",
    //       y: 50,
    //     },
    //     {
    //       x: "Tues",
    //       y: 63,
    //     },
    //     {
    //       x: "Wed",
    //       y: 63,
    //     },

    //     {
    //       x: "Thur",
    //       y: 23,
    //     },

    //     {
    //       x: "Fri",
    //       y: 23,
    //     },

    //     {
    //       x: "Sat",
    //       y: 2,
    //     },

    //     {
    //       x: "Sun",
    //       y: 90,
    //     },
    //   ],
    // },

    {
      id: "Sales",
      color: "#0b8dba",
      itemBackground: "#0b8dba",
      data: [
        {
          x: "Jan",
          y: 50,
        },
        {
          x: "Feb",
          y: 63,
        },
        {
          x: "Mar",
          y: 63,
        },

        {
          x: "Apr",
          y: 23,
        },

        {
          x: "May",
          y: 23,
        },

        {
          x: "Jun",
          y: 2,
        },

        {
          x: "Jul",
          y: 90,
        },

        {
          x: "Aug",
          y: 50,
        },
        {
          x: "Sep",
          y: 63,
        },
        {
          x: "Oct",
          y: 63,
        },
        {
          x: "Nov",
          y: 63,
        },

        {
          x: "Dec",
          y: 23,
        },
      ],
    },
  ];
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 5, right: 15, bottom: 85, left: 45 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   orient: "bottom",
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "transportation",
      //   legendOffset: 36,
      //   legendPosition: "middle",
      // }}
      axisLeft={{
        // @ts-ignore
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "count",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "#0b8dba",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "#0b8dba",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export { Chart };
