import React from "react";
import "./singleVendorRight.scss";
import SingleVendorRightProduct from "./SingleVendorRightProduct";

const SingleVendorRight = () => {
  return (
    <div className="singleVendor-bigFlexTwo">
      <div className="singleVendorRight-Heading">Product Uploaded</div>
      <div className="singleVendorRight-Heading-Border"></div>

      <div className="singleVendorRight-viewBtn">View all Products</div>

      <div>
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
        <SingleVendorRightProduct />
      </div>
    </div>
  );
};

export default SingleVendorRight;
