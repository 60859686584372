import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../../_api'
import { Link } from 'react-router-dom'

export function Table({
  setAddSubAccountModalFun,
  cancelSubscription,
  handleToggleModal,
}) {
  const [loading1, setLoading1] = useState(true)

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([])

  useEffect(() => {
    getAllMySubAccountsDat()
  }, [])

  const getAllMySubAccountsDat = async () => {
    setLoading1(true)
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setAllMySubAccountsData(res?.data?.recent_orders)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  // const allMySubAccountsData = [
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  // ];

  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: '#', field: 'ID' },
          { title: 'Date', field: 'created' },
          { title: 'Amount', field: 'amount' },
          //   { title: "Name", field: "name" },
          { title: 'Vendor', field: 'vendor' },
          { title: 'Status', field: 'status' },

          //   { title: "Last Visit", field: "last_seen" },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            ID: data?.id,

            // name: (
            //   <Link
            //     style={{ textDecoration: "none" }}
            //     to="/admin/dashboard/customers/:id"
            //   >
            //     {data?.user?.first_name}
            //   </Link>
            // ),

            created: data?.user?.created_at.slice(0, 10),
            amount: '-',
            vendor: '-',

            status: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggleModal()
                }}
              >
                {data?.is_active ? 'Active' : 'Not Active'}
              </div>
            ),
            Action: (
              <div className='subAccountSub'>
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: 'rgba(128, 128, 128, 0.103)' }
                        : { background: ' rgb(199, 13, 13)' }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title='Orders'
      />
    </div>
  )
}
