import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import eventsBanner from '../../assets/images/events.png'
import './style.scss'
import NewAdditions from '../../components/newAdditions/NewAdditions'
import { axiosCalls } from '../../_api'
import { CartContext } from '../../context/cart-context'
import InfoData from '../../helpers/data/InfoData'
import ContentLoader from 'react-content-loader'
import { Icon } from '@iconify/react'
import Cities from '../../assets/Data/cities'
import { Toast } from '../../helpers/toast'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import ProductList from '../../components/newAdditions/ProductList'

const flattenData = (data) => {
  const flatArray = []
  for (const country in data) {
    data[country].forEach((city) => {
      flatArray.push({ country, city })
    })
  }
  return flatArray
}

const flatData = flattenData(Cities)

const Home = () => {
  const { productCategory, productCategoryLoading } = useContext(CartContext)
  const [newAdditions, setNewAdditions] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [auth, setAuth] = useState(false)
  const [eventLoading, setEventLoading] = useState(true)
  const [eventList, setEventList] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    getAdditions()
    getTopSelling()
    const token = localStorage.getItem('token')
    if (token === '' || token == null) {
      setAuth(false)
    } else {
      setAuth(true)
    }
  }, [auth, navigate])

  const getAllEvents = async () => {
    setEventLoading(true)
    const res = await axiosCalls('landing-page-experiences', 'get')
    if (res) {
      setEventLoading(false)
      if (res.er) {
        setEventLoading(false)
        return
      }
      setEventList(res?.data.data)
      console.log(res?.data.data)
    }
    setTimeout(() => {
      setEventLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getAllEvents()
  }, [])

  const getAdditions = async () => {
    setLoading1(true)
    const res = await axiosCalls('landing-page-new-additions', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setNewAdditions(res.data.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }
  const getTopSelling = async () => {
    setLoading2(true)
    const res = await axiosCalls('landing-page-top-selling', 'get')
    if (res) {
      setTimeout(() => {
        setLoading2(false)
      }, 2000)
      if (res.er) {
        return
      }
      setTopSelling(res.data.data)
    }
    setTimeout(() => {
      setLoading2(false)
    }, 2000)
  }
  function findExperience() {
    if (inputValue.length < 1) {
      Toast('warn', 'please input a place')
    }
    navigate(
      `/search-experience?city=${selected.city}&country=${
        selected.country
      }&date=${format(new Date(date), 'yyyy-MM-dd')}`
    )
    // http://localhost:3000/search-experience/lagos/ii
  }

  // Dropdown  functionality
  const [suggestions, setSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [date, setDate] = useState(new Date())
  const [selected, setSelected] = useState({})

  useEffect(() => {
    if (inputValue) {
      const inputWords = inputValue.toLowerCase().split(/[\s,]+/)
      const filteredSuggestions = flatData.filter((item) =>
        inputWords.every(
          (word) =>
            item.city.toLowerCase().includes(word) ||
            item.country.toLowerCase().includes(word)
        )
      )
      setSuggestions(filteredSuggestions)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }, [inputValue])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSuggestionClick = (suggestion) => {
    setShowSuggestions(false)
    setSelected(suggestion)
    setInputValue(`${suggestion.city}, ${suggestion.country}`)
  }
  return (
    <div>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${eventsBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '550px',
        }}
        className='home-banner-family-cont'
      >
        <div className='home-banner-text'>
          <h2>Check out Our Exciting Experiences and Events for Kids</h2>
          <div className='search-inputs'>
            <div className='inputs'>
              <div className='input-card'>
                <p>where to?</p>
                <input
                  type='text'
                  name='location'
                  id=''
                  placeholder='Search for a place or activity'
                  required
                  value={inputValue}
                  onChange={handleInputChange}
                />
                {showSuggestions && (
                  <div className='drop-down'>
                    <ul>
                      {suggestions.slice(0, 10).map((item, i) => (
                        <li
                          key={i + 1}
                          onClick={() => handleSuggestionClick(item)}
                        >
                          {item.city}, <b>{item.country}</b>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className='input-card'>
                <p>when</p>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  placeholderText='heyy'
                  minDate={new Date()}
                  dateFormat='yyyy/mm/dd'
                />
              </div>
              <button onClick={findExperience}>
                <Icon icon='tabler:search' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='why'>
        <div className='top'>
          <h1>Why book with Bubble Colony?</h1>
          <div className='flex flex-wrap justify-between items-center'>
            <div className='why-card'>
              <svg
                width='48'
                height='48'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                class='advantageBannerIcon__p3My'
              >
                <path
                  d='M44.095 11.626c8.614 9.318 1.384 10.944-6.346 18.916-7.73 7.972-13.126 12.63-21.646 9.952C7.583 37.815-5.362 25.542 2.368 17.57c7.73-7.972 33.113-15.263 41.727-5.944z'
                  fill='#F6C5CD'
                ></path>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M17.869 10.004c-.93-.043-1.81.365-2.644 1.172-1.243 1.202-1.975 2.547-1.975 4.706 0 3.106 1.821 6.601 5.593 10.511C22.717 30.283 26.163 32 29.257 32h.026a6.893 6.893 0 004.817-2l.016-.016.016-.015a3.406 3.406 0 001.117-2.622 1.938 1.938 0 00-1.25-1.358l-.043-.015-3.505-1.579a1.197 1.197 0 00-1.295.268l-.012.012-.932.878c-.887.836-2.27 1.104-3.41.42a15.914 15.914 0 01-3.187-2.519 18.148 18.148 0 01-2.393-2.992c-.75-1.174-.477-2.64.433-3.553l.907-.912.024-.021c.347-.306.446-.807.243-1.223l-.008-.015-1.574-3.529-.006-.016a1.99 1.99 0 00-1.372-1.19zm-4.035-.266c1.124-1.087 2.555-1.843 4.244-1.725l.069.004.067.015a3.99 3.99 0 012.874 2.395l1.546 3.466a3.029 3.029 0 01-.698 3.558l-.864.869c-.323.324-.355.767-.165 1.065.614.96 1.328 1.853 2.131 2.665l.005.004a13.91 13.91 0 002.789 2.204c.28.168.691.138 1.009-.161l.908-.856a3.197 3.197 0 013.479-.689l.014.006 3.454 1.556a3.937 3.937 0 012.526 2.892l.016.077.005.078a5.407 5.407 0 01-1.75 4.274A8.893 8.893 0 0129.291 34H29.256c-3.863 0-7.794-2.14-11.836-6.202l-.006-.005-.005-.006c-3.903-4.044-6.159-8.021-6.159-11.905 0-2.756.994-4.606 2.584-6.144z'
                  fill='#000'
                ></path>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M17.868 10.003c-.93-.042-1.81.365-2.643 1.172-1.243 1.202-1.975 2.548-1.975 4.706 0 3.106 1.821 6.601 5.593 10.511C22.716 30.283 26.163 32 29.257 32h.026a6.892 6.892 0 004.817-2l.016-.016.016-.015a3.406 3.406 0 001.116-2.621A1.938 1.938 0 0034 25.989l-.043-.015-3.506-1.58a1.197 1.197 0 00-1.294.268l-.012.012-.932.878c-.887.836-2.27 1.104-3.41.42a15.914 15.914 0 01-3.188-2.518 18.156 18.156 0 01-2.392-2.993c-.75-1.174-.477-2.64.432-3.553l.907-.911.025-.022c.347-.306.446-.806.243-1.222l-.008-.016-1.574-3.528-.006-.017a1.99 1.99 0 00-1.373-1.189z'
                  fill='#fff'
                ></path>
              </svg>
              <h2>24/7 customer support</h2>
              <p>No matter the time zone, we’re here to help.</p>
            </div>
            <div className='why-card'>
              <svg
                width='49'
                height='36'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                class='advantageBannerIcon__p3My'
              >
                <path
                  d='M44.72 6.372c8.614 9.318 1.384 10.945-6.346 18.916-7.73 7.972-13.126 12.63-21.646 9.952-8.52-2.679-21.465-14.952-13.735-22.924 7.73-7.971 33.113-15.263 41.727-5.944z'
                  fill='var(--viator-color-primary-90)'
                ></path>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M26.625 18.7c5.176-1.054 9-4.773 9-9.2 0-5.247-5.373-9.5-12-9.5s-12 4.253-12 9.5c0 4.136 3.34 7.655 8 8.96V29h7V18.7z'
                  fill='#fff'
                ></path>
                <path
                  d='M20.685 31.154a2.274 2.274 0 01-2.271-2.272v-3.57h-.974a.974.974 0 010-1.947h1.502l-1.162-3.014c-4.354-1.785-7.155-5.548-7.155-9.642C10.625 4.804 16.303 0 23.281 0c6.979 0 12.657 4.804 12.657 10.71 0 4.093-2.8 7.856-7.156 9.641l-1.161 3.014h1.502a.974.974 0 010 1.948h-.974v3.57a2.274 2.274 0 01-2.272 2.27h-5.192zm-.324-2.272c0 .18.145.325.324.325h5.192c.18 0 .325-.146.325-.325v-3.57H20.36v3.57zm5.172-5.517l.88-2.282a14.691 14.691 0 01-3.132.335c-1.059 0-2.11-.111-3.132-.335l.88 2.282h4.504zM23.281 1.947c-2.639 0-4.867 4.012-4.867 8.762 0 4.75 2.228 8.762 4.867 8.762 2.64 0 4.868-4.012 4.868-8.762 0-4.75-2.229-8.762-4.868-8.762zm-4.603.857c-3.664 1.438-6.106 4.517-6.106 7.905s2.442 6.467 6.106 7.905c-1.389-2.005-2.212-4.877-2.212-7.905 0-3.028.823-5.9 2.212-7.905zm9.206 0c1.39 2.005 2.212 4.877 2.212 7.905 0 3.029-.823 5.9-2.212 7.905 3.665-1.438 6.106-4.517 6.106-7.905s-2.441-6.467-6.106-7.905z'
                  fill='#2A2D32'
                ></path>
              </svg>
              <h2>Earn rewards</h2>
              <p>Explore, earn, redeem, and repeat with our loyalty program.</p>
            </div>
            <div className='why-card'>
              <svg
                width='48'
                height='48'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                class='advantageBannerIcon__p3My'
              >
                <path
                  d='M44.095 12.232c8.614 9.319 1.384 10.945-6.346 18.917-7.73 7.971-13.126 12.63-21.646 9.951-8.52-2.678-21.465-14.952-13.735-22.923 7.73-7.972 33.113-15.263 41.727-5.945z'
                  fill='#F6ECBB'
                ></path>
                <path
                  d='M25.082 7.39l3.27 6.933a2.34 2.34 0 001.752 1.328l.008.002 7.297 1.109a.745.745 0 01.376 1.252l-.002.002-5.294 5.398-.004.004a2.447 2.447 0 00-.654 2.104v.001l1.25 7.62.001.01.002.01a.708.708 0 01-.66.837.597.597 0 01-.275-.076l-.006-.003-6.541-3.598H25.6a2.275 2.275 0 00-2.202 0l-6.541 3.598-.006.003a.597.597 0 01-.276.076.707.707 0 01-.66-.837l.003-.01.001-.01 1.25-7.62v-.001a2.447 2.447 0 00-.654-2.104l-.005-.004-5.294-5.398-.001-.002a.745.745 0 01.376-1.252l7.297-1.11h.008a2.34 2.34 0 001.752-1.33l3.27-6.931.004-.008.003-.008A.633.633 0 0124.5 7a.633.633 0 01.575.375l.003.008.004.008z'
                  fill='#fff'
                  stroke='#2A2D32'
                  stroke-width='2'
                ></path>
              </svg>
              <h2>Millions of reviews</h2>
              <p>
                Plan and book with confidence using reviews from fellow
                travelers.
              </p>
            </div>
            <div className='why-card'>
              <svg
                width='48'
                height='48'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                class='advantageBannerIcon__p3My'
              >
                <path
                  d='M44.095 12.372c8.614 9.318 1.384 10.945-6.346 18.916-7.73 7.972-13.126 12.63-21.646 9.952-8.52-2.679-21.465-14.952-13.735-22.924 7.73-7.971 33.113-15.263 41.727-5.944z'
                  fill='#DEECED'
                ></path>
                <rect
                  x='11'
                  y='7'
                  width='24'
                  height='24'
                  rx='2'
                  fill='#fff'
                ></rect>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M17 4a1 1 0 011 1v1h10V5a1 1 0 112 0v1h2.75A3.25 3.25 0 0136 9.25v19.5A3.25 3.25 0 0132.75 32h-19.5A3.25 3.25 0 0110 28.75V9.25A3.25 3.25 0 0113.25 6H16V5a1 1 0 011-1zm11 4v1a1 1 0 102 0V8h2.75c.69 0 1.25.56 1.25 1.25V12H12V9.25c0-.69.56-1.25 1.25-1.25H16v1a1 1 0 102 0V8h10zm-16 6v14.75c0 .69.56 1.25 1.25 1.25h19.5c.69 0 1.25-.56 1.25-1.25V14H12z'
                  fill='#2A2D32'
                ></path>
              </svg>
              <h2>Plan your way</h2>
              <p>
                Stay flexible with free cancellation and the option to reserve
                now and pay later at no additional cost.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='recent'>
        <div className='top'>
          <h1>Top Events</h1>
        </div>
        {!eventLoading && <ProductList data={eventList.slice(0, 4)} />}
      </div>
      {/* <div className='home-info'>
        {InfoData.map((item) => (
          <div className='card' key={item.id}>
            <div className='left'>
              <div className='round'>
                <item.icon />
              </div>
            </div>
            <div className='right'>
              <h2>{item.tag}</h2>
              <p>{item.details}</p>
            </div>
          </div>
        ))}
      </div> */}
      <div className='kiddies-banner'>
        <div className='kiddies-info'>
          <div className='left'>
            <h2>Event Vendors & Centers</h2>
            <p>
              Need an Vendor, decorator and planner for your child’s upcoming
              event?
            </p>
          </div>
          <div className='right'>
            <button>Browse Vendors </button>
          </div>
        </div>
      </div>
      {/* <div className='categories'>
        <div className='categories-top'>
          <h2>Our Categories</h2>
        </div>
        {productCategoryLoading ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            {['1', '2', '3', '4', 5].map((item, i) => (
              <div style={{ marginRight: '10px', width: '19%' }} key={i + 1}>
                <ContentLoader
                  speed={2}
                  width={'100%'}
                  height={250}
                  // viewBox="60 60 400 350"
                  backgroundColor=' #5e5d5d11'
                  foregroundColor='#2f2a293b'
                  // {...props}
                >
                  <rect width='100%' height='100%' />
                </ContentLoader>
              </div>
            ))}
          </div>
        ) : (
          <div className='rows'>
            {productCategory
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <div
                  className='card'
                  key={item.id}
                  onClick={() => {
                    navigate(`/product-category/${item.id}`)
                  }}
                >
                  <div className='img'>
                    <img src={item.thumbnail_image} alt='' />
                  </div>
                  <p>{item.name}</p>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='New Additions'
          data={newAdditions}
          loading={loading1}
        />
      </div>
      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='Top Selling Products'
          data={topSelling}
          loading={loading2}
        />
      </div> */}
      <Footer />
    </div>
  )
}

export default Home
