import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'

function MainImages({ images }) {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let sliderRef1 = useRef(null)
  let sliderRef2 = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    setNav1(sliderRef1)
    setNav2(sliderRef2)
  }, [])
  const usersImages = [
    'https://images.pexels.com/photos/1020016/pexels-photo-1020016.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1371360/pexels-photo-1371360.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/3822113/pexels-photo-3822113.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1024989/pexels-photo-1024989.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1154638/pexels-photo-1154638.jpeg?auto=compress&cs=tinysrgb&w=800',
  ]
  const handleAfterChange = (current) => {
    setActiveSlide(current) // Update active slide index
    console.log(current)
  }
  return (
    <div className='mainImage'>
      <div className='slider-container'>
        <div className='flex justify-between'>
          <div className='two'>
            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              slidesToShow={5}
              swipeToSlide={true}
              focusOnSelect={true}
              vertical={true}
            >
              {images.map((item, i) => (
                <div key={i} className='slider-con'>
                  <div className='img'>
                    <img src={item} alt='' />
                  </div>
                  {activeSlide !== i && <div className='overlay'></div>}
                </div>
              ))}
            </Slider>
          </div>
          <div className='one'>
            <Slider
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
              afterChange={handleAfterChange}
            >
              {images.map((item, i) => (
                <div key={i} className='slider-main'>
                  <div className='img'>
                    <img src={item} alt='' />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainImages
