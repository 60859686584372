import React from "react";
import "./index.scss";
import Navbar from "./navbar";
import Sidebar from "./sidebar";

export default function AdminLayout({ children }) {
  return (
    <div className="admin_layout">
      <div className="admin_layout-nav">
        <Navbar />
      </div>

      <div className="admin_layout-children">
        <Sidebar />
        <div className="content-children">{children}</div>
      </div>
    </div>
  );
}
