import React from 'react'

const TimelineItem = ({ number, title, description, duration, admission }) => (
  <div className='timeline-item'>
    <div className='timeline-icon'>
      <div className='circle'>{number}</div>
    </div>
    <div className='timeline-content'>
      <h4>{title}</h4>
      <p>{description}</p>
      <span className='timeline-info'>
        {duration} •{' '}
        {admission ? 'Admission ticket free' : 'Admission ticket required'}
      </span>
    </div>
  </div>
)

export default TimelineItem
