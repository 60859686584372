import React, { useState, useEffect, useContext } from 'react'
import { HiDownload } from 'react-icons/hi'
import { GoCalendar } from 'react-icons/go'
import Pallets from './Pallets'
import Container from './style'
import Chart from './lineChart'
import './index.css'
import Table from './table/subAccount'
import { CartContext } from '../../../context/cart-context'

const AdminDashboardHome = () => {
  const { getAdminProfile } = useContext(CartContext)
  useEffect(() => {
    if (localStorage.getItem('role') === 'Vendor') {
      getAdminProfile()
    }
  }, [])

  return (
    <div className='app-scroll'>
      <Container>
        <header>
          <h1 className='u--typo__title2'>Dashboard</h1>
          <div className='action--container'>
            <div className='select--container'>
              <GoCalendar />
              <select>
                <option>This week</option>
              </select>
            </div>
            <button>
              <HiDownload />
              Export
            </button>
          </div>
        </header>
        <Pallets />
        <div className='home-page-chart'>
          <div className='chartHeader'>
            <h2>Transaction Overview</h2>
            <select name='' id=''>
              <option value=''>2023</option>
            </select>
          </div>

          {/* <Chart /> */}
        </div>
        <div className='home-tabelwrap1'>
          <Table />
        </div>
      </Container>
    </div>
  )
}

export default AdminDashboardHome
