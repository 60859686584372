import React from "react";

const ProductsIcon = ({ fill }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.30056 5.03323C6.78889 5.52073 6.78889 6.31323 6.30056 6.80073C5.81222 7.28906 5.02139 7.28906 4.53306 6.80073C4.04472 6.31323 4.04472 5.52073 4.53306 5.03323C5.02139 4.54489 5.81222 4.54489 6.30056 5.03323ZM2.36181 8.74331L7.38847 13.7708L13.2701 7.88914L8.24347 2.86164L1.75597 2.25664L2.36181 8.74331ZM7.38847 15.4991C7.00097 15.4991 6.61431 15.3516 6.31848 15.0575L0.970975 9.70914C0.833475 9.57248 0.748475 9.39081 0.730142 9.19748L0.00347524 1.41081C-0.0190248 1.16414 0.0684752 0.919976 0.244309 0.744143C0.420142 0.568309 0.663475 0.479143 0.910975 0.504143L8.69764 1.23081C8.89097 1.24831 9.07181 1.33331 9.20931 1.47081L14.5568 6.81831C14.8426 7.10414 15.0001 7.48414 15.0001 7.88914C15.0001 8.29414 14.8426 8.67414 14.5568 8.95998L8.45931 15.0566C8.16431 15.3516 7.77681 15.4991 7.38847 15.4991Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProductsIcon;
