import React, { useState, useEffect } from 'react'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import ProductCartPage from './ProductCartPage'
import ExperienceCartPage from './ExperienceCartPage'
import { useSearchParams } from 'react-router-dom'

function CartPage() {
  const [showTab, setShowTab] = useState('experience')
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  useEffect(() => {
    if (tab) {
      setShowTab(tab)
    }
  }, [tab])

  function setTab(param) {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.searchParams.set('tab', param)
    window.history.pushState(null, '', url.toString())
    setShowTab(param)
  }
  return (
    <div className='newCart'>
      <Navbar />
      <div className='tabs'>
        {/* <button
          className={showTab === 'product' ? 'active' : ''}
          onClick={() => setTab('product')}
        >
          Product Cart
        </button>
        <button
          className={showTab === 'experience' ? 'active' : ''}
          onClick={() => setTab('experience')}
        >
          Experience Cart
        </button> */}
      </div>
      {showTab === 'product' && <ProductCartPage />}
      {showTab === 'experience' && <ExperienceCartPage />}
      <Footer />
    </div>
  )
}

export default CartPage
