import React from "react";

const OrdersIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8332 14.25C14.8332 14.9408 14.2732 15.5 13.5832 15.5C12.8932 15.5 12.3332 14.9408 12.3332 14.25C12.3332 13.5592 12.8932 13 13.5832 13C14.2732 13 14.8332 13.5592 14.8332 14.25ZM6.49984 14.25C6.49984 14.9408 5.93984 15.5 5.24984 15.5C4.55984 15.5 3.99984 14.9408 3.99984 14.25C3.99984 13.5592 4.55984 13 5.24984 13C5.93984 13 6.49984 13.5592 6.49984 14.25ZM12.6515 9.66667H6.30317L4.939 4.66667H15.1515L12.6515 9.66667ZM16.569 3.79C16.2632 3.295 15.7332 3 15.1515 3H4.48484L3.97067 1.11417C3.8715 0.751667 3.54234 0.5 3.1665 0.5H1.49984C1.039 0.5 0.666504 0.873333 0.666504 1.33333C0.666504 1.79333 1.039 2.16667 1.49984 2.16667H2.52984L4.86234 10.7192C4.9615 11.0817 5.29067 11.3333 5.6665 11.3333H13.1665C13.4823 11.3333 13.7707 11.155 13.9123 10.8725L16.6423 5.41167C16.9023 4.89083 16.8748 4.285 16.569 3.79Z"
        fill={fill}
      />
    </svg>
  );
};

export default OrdersIcon;
