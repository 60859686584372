import React from 'react';
import './checkoutInfoAddressBox.scss';
import addressIcon from '../../assets/icon/addressIcon.svg';
import addTickIcon from '../../assets/icon/addTickIcon.svg';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CheckoutInfoAddressBox = ({
	data,
	defaultAddressBtn,
	defaultAddress,
	otherAddress,
	deleteAddress,
}) => {
	const navigation = useNavigate();
	return (
		<div className='productWrapAddressBox '>
			<Link
				to='/personal-add-address'
				className='personalCheckAdd-selection-add-new'
				style={{ textDecoration: 'none' }}
			>
				<div>
					<img src={addressIcon} />
				</div>
				<div className='personalCheckAdd-selection-add-text'>
					Add New Address
				</div>
			</Link>

			{data.map((d) => {
				return (
					<div
						style={{ border: defaultAddress && '1px solid #0c8dba' }}
						className='addressBox-selection-default'
					>
						<div className='addressBox-selection-userName'>
							<p>{d?.full_name}</p>
						</div>
						<div className='addressBoxselection-location-address'>
							<p>{d?.address}</p>
						</div>
						<div className='addressBoxselection-location-phone'>
							<p>Phone number</p>
						</div>
						<div className='addressBoxselection-location-phone-digit'>
							<p>{d?.phone_no}</p>
						</div>
						<div className='addressBoxselection-location-option'>
							<div
								className='addressBoxselection-remove'
								onClick={() => deleteAddress(d?.id)}
							>
								<p>Remove</p>
							</div>
							<div
								className='addressBoxselection-edit'
								onClick={() => {
									navigation(`edit-address/${d.id}`);
									localStorage.setItem('editAddress', JSON.stringify(d));
								}}
							>
								<p>Edit</p>
							</div>

							{/* {!defaultAddress && (
                <div
                  onClick={defaultAddressBtn}
                  className="addressBoxselection-change-address"
                >
                  <p>Set as Default</p>
                </div>
              )} */}
						</div>

						{defaultAddress && (
							<div className='addressBoxselection-tick-icon'>
								<img src={addTickIcon} />
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default CheckoutInfoAddressBox;
