/* eslint-disable jsx-a11y/no-redundant-roles */
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import closeOrderModal from "../../../../../assets/icon/closeOrderModal.svg";

import orderAdminDots from "../../../../../assets/icon/orderAdminDots.svg";

import orderAdminProduct from "../../../../../assets/icon/orderAdminProduct.png";

import Modal from "react-modal";
// @ts-ignore
import ProductImage from "../../../../../assets/images/shopDenim.png";
// @ts-ignore
import styles from "./index.module.css";
import "./ordersModal.scss";
import OrdersProduct from "./OrdersProduct";
import productImgOne from "../../../../../assets/icon/productImgOne.png";

import productImgTwo from "../../../../../assets/icon/productImgTwo.png";
import productImgThree from "../../../../../assets/icon/productImgThree.png";
import { useState } from "react";

Modal.setAppElement("#root");

function OrdersModal({ showModal, handleToggleModal }) {
  const [showOpt, setShowOpt] = useState(false);

  const toggleShowOpt = () => {
    setShowOpt(!showOpt);
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleToggleModal}
      shouldCloseOnOverlayClick={true}
      className={`${styles.ordersModal}`}
      overlayClassName={`${styles.ordersModal__overlay}`}
    >
      {/* The Flow Class is in the root > index.css */}
      {/* Use --flow-spacer: 2rem..etc to overwrite it */}
      <div className="adminOrders-headingFam">
        <div className="adminOrders-headingNo">Product Details</div>
        <div className="adminOrders-headingClose" onClick={handleToggleModal}>
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className="adminOrders-SubheadingStaFam">
        <div className="adminOrders-SubheadingStaFlex">
          <div className="adminOrders-SubheadingStaText">Inventory Status:</div>
          <div className="adminOrders-SubheadingStaIconFlex">
            <div className="adminOrders-SubheadingStaIcon"></div>
            <div className="adminOrders-SubheadingStaSt">Limited Stock</div>
          </div>
        </div>
        <div onClick={toggleShowOpt} className="adminOrders-SubheadingStaOpt">
          <img src={orderAdminDots} />
        </div>
      </div>

      {showOpt && (
        <div className="adminOrders-SubheadingStaOptDrop">
          <div className="adminOrders-SubheadingStaOptDrop1">Edit Product</div>
          <div className="adminOrders-SubheadingStaOptDrop2">
            Delete Product
          </div>
        </div>
      )}
      {/* <div className="adminOrders-SubheadingDateFam">
        <div className="adminOrders-SubheadingDateHead">Date:</div>
        <div className="adminOrders-SubheadingDate">24 February, 2021</div>
      </div> */}
      <div className="adminOrders-summaryFam">
        <div className="adminProduct-productNameTagGenOne">
          <div className="adminProduct-productNameTag">Product Name</div>
          <div className="adminProduct-productNameTagCate">
            Product Category
          </div>
        </div>

        <div className="adminProduct-productNameFam">
          <div className="adminProduct-productNameN">
            The Product Name, shouldn't be long{" "}
          </div>
          <div className="adminProduct-productNameP">Boy’s shirt</div>
        </div>

        <div className="adminProduct-productNameTagGen">
          <div className="adminProduct-productNameTag">Product Code</div>
          <div className="adminProduct-productNameTagCate">Quantity</div>
        </div>

        <div className="adminProduct-productNameFam">
          <div className="adminProduct-productNameN">HXY-001</div>
          <div className="adminProduct-productNameP">30</div>
        </div>

        <div className="adminProduct-productNameTagGen">
          <div className="adminProduct-productNameTag">Vendor</div>
          <div className="adminProduct-productNameTagCate">Product Price</div>
        </div>

        <div className="adminProduct-productNameFam">
          <div className="adminProduct-productNameN">Summit Mobile</div>
          <div className="adminProduct-productNameP">₦15,000.00</div>
        </div>

        <div className="adminProduct-productDiscountTag">Product Discount</div>
        <div className="adminProduct-productDiscount">10% (-₦1,500.00)</div>

        <div className="adminProduct-productDiscountTag">
          Product Description
        </div>
        <div className="adminProduct-productDiscount">
          KILLA is a zit patch powered by ZitSticka’s signature microdart
          technology. These tiny microdarts self-dissolve to flood the epicenter
          of your early-stage zit with targeted ingredients, clearing the
          upcoming drama before it gets real.
        </div>
      </div>

      <div className="adminOrders-customerFam">
        <div className="adminOrders-customerDetai">Product Images</div>

        <div className="adminProduct-imageSec">Image</div>

        <div className="adminProduct-imageSecFistFlex">
          <div className="adminProduct-imageSecFistImg">
            <img src={productImgOne} />
          </div>
          <div className="adminProduct-imageSecFistImgName">IMG_123000</div>
        </div>
        <div className="adminProduct-imageSecSecondFlex">
          <div className="adminProduct-imageSecSecondImg">
            <img src={productImgTwo} />
          </div>
          <div className="adminProduct-imageSecSecondImgName">IMG_123000</div>
        </div>

        <div className="adminProduct-imageSecThirdFlex">
          <div className="adminProduct-imageSecThirdFlexT">
            <div className="adminProduct-imageSecThirdImg">
              <img src={productImgOne} />
            </div>
            <div className="adminProduct-imageSecThirdName">IMG_1200</div>
          </div>
          <div className="adminProduct-imageSecThirdCheckFam">
            <div className="adminProduct-imageSecThirdChecked">
              <input type="checkbox" />
            </div>
            <div className="adminProduct-imageSecThirdCheckImgtext">
              Image used as main image
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export { OrdersModal };

// <div>
//   <button onClick={this.handleOpenModal}>Trigger Modal</button>
//   <ReactModal
//      isOpen={this.state.showModal}
//      contentLabel="onRequestClose Example"
//      onRequestClose={this.handleCloseModal}
//      shouldCloseOnOverlayClick={true}
//   >
//     <p>Modal text!</p>
//     <button onClick={this.handleCloseModal}>Close Modal</button>
//   </ReactModal>
// </div>
