import React from 'react'
import Status from '../../components/status/status'
import { useNavigate } from 'react-router-dom'

const ExperienceOrderCard = ({ data }) => {
  const navigation = useNavigate()
  return (
    <div
      className='singleOrder'
      // onClick={() => {
      //   localStorage.setItem('orders', JSON.stringify(data))
      //   navigation(`/view-order/${data.id}`)
      // }}
    >
      <div className='singleOrderHeader'>
        <div className='singleOrderHeaderCol1'>
          <h2>Order #{data?.reference}</h2>
          <p>{new Date(data?.updated_at).toDateString()}</p>
        </div>
      </div>

      <div className='orderBodySec'>
        <div>
          <p>ID</p>
          <h2>{data?.id}</h2>
        </div>
        <div>
          <p>Name</p>
          <h2>{data?.experience.name}</h2>
        </div>

        <div>
          <p>Items</p>
          <h2>{data?.no_of_adults + data?.no_of_children} items</h2>
        </div>

        <div>
          <p>Amount</p>
          <h2>₦{parseInt(data?.amount).toLocaleString()}</h2>
        </div>

        {/* <div>
          <p>Sold By</p>
          <h2>Bubble Colony</h2>
        </div> */}
      </div>
    </div>
  )
}

export default ExperienceOrderCard
