import React from 'react';
import './status.scss';

const Status = ({ text, textColor }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					width: '10px',
					height: '10px',
					borderRadius: '50%',
					background:
						textColor === 'delivered'
							? '#30BA87'
							: text === 'canceled'
							? '#CB4342'
							: '#FFC107',
					marginRight: '2px',
				}}
			></div>
			<div>
				<p
					style={{
						color:
							textColor === 'delivered'
								? '#30BA87'
								: text === 'canceled'
								? '#CB4342'
								: '#FFC107',
						fontSize: '12px',
						fontWeight: '400',
						marginTop: '3px',
						marginLeft: '3px',
					}}
				>
					{text}
				</p>
			</div>
		</div>
	);
};

export default Status;
