import React, { useState, useEffect } from 'react'
import closeOrderModal from '../../../../assets/icon/closeOrderModal.svg'
import { FileUploader } from 'react-drag-drop-files'
import { Toast } from '../../../../helpers/toast'
import { axiosCalls } from '../../../../_api'
import { hideLoader, showLoader } from '../../../../loader'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'
import ImageUrl from '../../../../components/imageUrl/ImageUrl'

function AddProductCategory({ handleToggleNewModal, selectedData }) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [thumbnailImage, setThumbnailImage] = useState(null)
  const [bannerImage, setBannerImage] = useState(null)
  const [newCat, setNewCat] = useState(true)
  function handleThumbnailChange(params) {
    setThumbnailImage(params)
  }
  function handleBannerChange(params) {
    setBannerImage(params)
  }
  async function handleSubmit() {
    if (
      name.length < 1 &&
      description.length < 1 &&
      !thumbnailImage &&
      !bannerImage
    ) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const formData = new FormData()
    const file = [thumbnailImage, bannerImage]
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      const data = {
        name: name,
        description: description,
        thumbnail_image: res.data[0],
        banner_image: res.data[1],
      }
      const response = await axiosCalls(`admin/product-category`, 'post', data)
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Category saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  async function handleEditSubmit() {
    if (
      name.length < 1 &&
      description.length < 1 &&
      !thumbnailImage &&
      !bannerImage
    ) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const formData = new FormData()
    const file = [thumbnailImage, bannerImage]
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      const data = {
        name: name,
        description: description,
        thumbnail_image: res.data[0],
        banner_image: res.data[1],
      }
      const response = await axiosCalls(
        `admin/product-category/${selectedData.id}`,
        'put',
        data
      )
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Category saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  useEffect(() => {
    if (selectedData) {
      setNewCat(false)
      setName(selectedData.name)
      setDescription(selectedData.description)
      ImageUrl(selectedData.banner_image).then((fileData) => {
        setBannerImage(fileData)
      })
      ImageUrl(selectedData.thumbnail_image).then((fileData) => {
        setThumbnailImage(fileData)
      })
    }
  }, [])

  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>
          {newCat ? 'New Category' : 'Edit Category'}
        </div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Description</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-ProdImgText'>Thumbnail Image</div>
      <div className='newProductForm-fileUploader'>
        <FileUploader
          handleChange={handleThumbnailChange}
          name='file'
          types={['JPEG', 'PNG', 'JPG']}
          label={
            thumbnailImage
              ? 'Uploaded Successfully! Upload another?'
              : 'Drop your images here'
          }
        />
      </div>
      <div className='newProductForm-ProdImgText'>Banner Image</div>
      <div className='newProductForm-fileUploader'>
        <FileUploader
          handleChange={handleBannerChange}
          name='file'
          types={['JPEG', 'PNG', 'JPG']}
          label={
            bannerImage
              ? 'Uploaded Successfully! Upload another?'
              : 'Drop your images here'
          }
        />
      </div>
      <div className='newProductForm-SaveGen' style={{ marginTop: '30px' }}>
        {selectedData ? (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleEditSubmit()
            }}
          >
            Edit
          </div>
        ) : (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleSubmit()
            }}
          >
            Create
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export default AddProductCategory
