import React from 'react'
import Image from '../../assets/images/loader.gif'
import './Loader.scss'

function Loader({ direction, size, align }) {
  return (
    <div className={`LoaderX ${direction} ${size} ${align}`}>
      <div className='containerX'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
    </div>
  )
}

export default Loader
