import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../_api'
import productPencil from '../../../../../assets/icon/productPencil.svg'

import productThreeDot from '../../../../../assets/icon/productThreeDot.svg'
import ManaDeleteUser from '../../../../../assets/icon/ManaDeleteUser.svg'

import ManaResPassword from '../../../../../assets/icon/ManaResPassword.svg'
import ManaUserProfile from '../../../../../assets/icon/ManaUserProfile.png'

import '../../productTable.css'

export function Table({
  setAddSubAccountModalFun,
  cancelSubscription,
  handleToggleModal,
  // handleToggleNewModal,
}) {
  const [loading1, setLoading1] = useState(true)

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([])

  useEffect(() => {
    getAllMySubAccountsDat()
  }, [])

  const getAllMySubAccountsDat = async () => {
    setLoading1(true)
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setAllMySubAccountsData(res?.data?.recent_orders)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  // const allMySubAccountsData = [
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Joe McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Mac McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Roland McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Eze McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Okeke McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Andrew McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Tony McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Delivered',
  //   },
  // ];

  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Role', field: 'role' },
          { title: 'Action', field: 'action' },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            ID: data?.id,
            date: data?.created_at.slice(0, 10),
            // name: `${data?.user?.first_name}`,

            name: (
              <div className='userMana-nameGen'>
                <div className='userMana-nameProfPics'>
                  <img src={ManaUserProfile} />
                </div>
                <div className='userMana-nameProfFlex'>
                  <div className='userMana-nameProfName'>
                    {data?.user?.first_name}
                  </div>
                  <div className='userMana-nameProfEmail'>
                    {/* {data?.user?.first_name} */}
                    ifeanyiernestment@gmail.com
                  </div>
                </div>
              </div>
            ),
            role: (
              <div className='subAccountSub-roleStyle'>
                {!data?.status ? 'no server data' : data?.status}
              </div>
            ),
            vendor: !data?.vendor ? '-' : data?.vendor,
            quantity: !data?.vendor ? '-' : data?.vendor,

            // action: !data?.vendor ? "-" : data?.vendor,
            status: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggleModal()
                }}
              >
                {!data?.status ? 'no server data' : data?.status}
              </div>
            ),
            action: (
              <div className='subAccountSub-action'>
                {/* <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: "rgba(128, 128, 128, 0.103)" }
                        : { background: " rgb(199, 13, 13)" }
                      : {}
                  }
                >
                  Cancel subscription
                </button> */}

                <div className='subAccountSub-resPass'>
                  <img src={ManaResPassword} /> <span>Reset Password</span>
                </div>

                <div className='subAccountSub-delUser'>
                  <img src={ManaDeleteUser} /> <span>Delete User</span>
                </div>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title=''
      />
    </div>
  )
}
