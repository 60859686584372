import React from 'react'
import Status from '../../components/status/status'
import { useNavigate } from 'react-router-dom'

const OrderCard = ({ data }) => {
  const navigation = useNavigate()
  return (
    <div
      className='singleOrder'
      onClick={() => {
        localStorage.setItem('orders', JSON.stringify(data))
        navigation(`/view-order/${data.id}`)
      }}
    >
      <div className='singleOrderHeader'>
        <div className='singleOrderHeaderCol1'>
          <h2>Order #{data?.reference}</h2>
          <p>{data?.delivery_date}</p>
        </div>
        <div>
          <Status color={'#FFC107'} text={data?.delivery_status?.name} />
        </div>
      </div>

      <div className='orderBodySec'>
        <div>
          <p>ID</p>
          <h2>{data?.id}</h2>
        </div>

        <div>
          <p>Items</p>
          <h2>{data?.order_item?.length} items</h2>
        </div>

        <div>
          <p>Amount</p>
          <h2>₦{data?.total_price}</h2>
        </div>

        <div>
          <p>Sold By</p>
          <h2>Bubble Colony</h2>
        </div>
      </div>
    </div>
  )
}

export default OrderCard
