import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import icons from "./icons";
// import { axiosCalls } from "../../../../src/_api";
import { axiosCalls } from "../../../../../_api";
import { showLoader, hideLoader } from "../../../../../loader";

export function Table({ setAddSubAccountModalFun, cancelSubscription }) {
  const [loading1, setLoading1] = useState(true);

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([]);

  useEffect(() => {
    getAllMySubAccountsData();
  }, []);

  const getAllMySubAccountsData = async () => {
    showLoader();
    setLoading1(true);
    const res = await axiosCalls("admin/admin-dashboard", "get");
    if (res) {
      hideLoader();
      setTimeout(() => {
        setLoading1(false);
      }, 2000);
      if (res.er) {
        hideLoader();
        return;
      }
      setAllMySubAccountsData(res?.data?.recent_orders);
    }
    setTimeout(() => {
      setLoading1(false);
    }, 2000);
  };

  // const allMySubAccountsData = [
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  //   {
  //     id: '121091',
  //     date: '24 February, 2021',
  //     customer: 'Ebere McCoy',
  //     amount: '₦42,500.00',
  //     vendor: 'Vendor Name',
  //     status: 'Processed',
  //   },
  // ];

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: "Transaction Date", field: "date" },
          { title: "Transaction Reference", field: "ID" },
          { title: "Amount", field: "amount" },
          { title: "Customer", field: "customer" },
          { title: "Vendor", field: "vendor" },
          { title: "Status", field: "status" },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            ID: data.id,
            date: data?.created_at.slice(0, 10),
            amount: data?.total_price,
            customer: `${data?.user?.first_name}`,
            vendor: !data?.vendor ? "No Vendor from Data" : data?.vendor,
            status: data?.delivery_status_id,
            Action: (
              <div className="subAccountSub">
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: "rgba(128, 128, 128, 0.103)" }
                        : { background: " rgb(199, 13, 13)" }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className="publicationCheckbox">
                <input id="c1" type="checkbox" />
              </div>
            ),
          };
        })}
        title="Transactions"
      />
    </div>
  );
}
