import React, { useState, useEffect, useRef, useContext } from 'react'
import { ReactComponent as Logo } from '../../assets/icon/bubbleColonyLogo.svg'
import { ReactComponent as Cart } from '../../assets/icon/cartIcon.svg'
import { ReactComponent as ArrowDown } from '../../assets/icon/arrowDown.svg'
import { ReactComponent as UserIcon } from '../../assets/icon/userIcon.svg'
import { ReactComponent as ArchiveIcon } from '../../assets/icon/archiveIcon.svg'
import { FaBars } from 'react-icons/fa'
import './navbar.scss'
import AccountDropDown from './AccountDropDown'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { axiosCalls } from '../../_api'
import logout from '../../helpers/logout'
import { CartContext } from '../../context/cart-context'

function Navbar() {
  const {
    cartDetails,
    getCart,
    productCategory,
    productCategoryLoading,
    localCarts,
    localExperienceCart,
    fetchLocalCart,
    fetchLocalExperienceCart,
  } = useContext(CartContext)
  const userInfo = useSelector((state) => state)
  const allowed = userInfo.auth.isAuthenticated
  const [showDropDown, setShowDropDown] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const navigate = useNavigate()
  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false)
  const modalRef = useRef()
  const closeMore = () => {
    setShowMore(false)
  }
  const categoryRef = useRef()
  const closeCategory = () => {
    setShowCategoryDropDown(false)
  }
  const [cartQty, setCartQty] = useState(0)
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeMore()
        closeCategory()
      }
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        closeCategory()
      }
    }

    if (showMore) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    if (showCategoryDropDown) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showMore, closeMore, showCategoryDropDown, closeCategory])
  useEffect(() => {
    if (allowed) {
      getCart(true)
    }
    fetchLocalCart()
    fetchLocalExperienceCart()
  }, [])
  return (
    <div className={showDropDown ? 'new-nav drop' : 'new-nav'}>
      <div className='nav-top'>
        <div className='new-logo'>
          <Logo className='logo' onClick={() => navigate('/')} />
          <div className='right'>
            <div className='one'>{/* <h2>hello</h2> */}</div>
            <div className='two'>
              <Cart onClick={() => navigate('/cart-page')} />
              {/* {cartDetails.length > 0 && (
                <i
                  style={{
                    textDecoration: 'none',
                    background: 'red',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px',
                    color: '#fff',
                    padding: '3px 0',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {cartDetails.length}
                </i>
              )} */}
              {localCarts.length + localExperienceCart.length > 0 && (
                <i
                  style={{
                    textDecoration: 'none',
                    background: 'red',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px',
                    color: '#fff',
                    padding: '3px 0',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {localCarts.length + localExperienceCart.length}
                </i>
              )}
              <FaBars onClick={() => setShowDropDown(!showDropDown)} />
            </div>
          </div>
        </div>
        <div className='others'>
          <div className='left'>
            <ul>
              <li>ABOUT US</li>
              <li>PARTNERS</li>
              <li>COMMUNITY</li>
              <li onClick={() => navigate('/kids-events')}>KID EVENTS</li>
            </ul>
          </div>
          <div className='right'>
            <div className='one'>
              {allowed ? (
                <>
                  <p>
                    Hello,{' '}
                    {(userInfo?.auth?.user?.first_name ?? '').toUpperCase()}
                  </p>
                  <h2 onClick={() => setShowMore(true)}>
                    My Account <ArrowDown />
                  </h2>
                </>
              ) : (
                <h2
                  onClick={() => {
                    navigate('/login')
                  }}
                >
                  Login/Register
                </h2>
              )}
              {showMore && (
                <div className='dropdown' ref={modalRef}>
                  {allowed && (
                    <ul>
                      <li onClick={() => navigate('/account-checkout')}>
                        <UserIcon />
                        <p>My Account</p>
                      </li>
                      <li onClick={() => navigate('/orders')}>
                        <ArchiveIcon />
                        <p>My Orders</p>
                      </li>
                    </ul>
                  )}
                  <div className='logout'>
                    <h6 onClick={logout}>Logout</h6>
                  </div>
                </div>
              )}
            </div>
            <div className='two'>
              <Cart onClick={() => navigate('/cart-page')} />
              {/* {cartDetails.length > 0 && (
                <i
                  style={{
                    position: 'absolute',
                    textDecoration: 'none',
                    background: 'red',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px',
                    color: '#fff',
                    padding: '3px 0',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {cartDetails.length}
                </i>
              )} */}
              {localCarts.length + localExperienceCart.length > 0 && (
                <i
                  style={{
                    position: 'absolute',
                    textDecoration: 'none',
                    background: 'red',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px',
                    color: '#fff',
                    padding: '3px 0',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {localCarts.length + localExperienceCart.length}
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='nav-bottom'>
        <ul>
          <li>
            <div className='select'>
              <div className='select-head'>
                <p onClick={() => setShowCategoryDropDown(true)}>
                  Category A-Z <ArrowDown />
                </p>
              </div>
              {showCategoryDropDown && (
                <div className='select-body' ref={categoryRef}>
                  <ul>
                    <li>Hey</li>
                    <li>Hey</li>
                    <li>Hey</li>
                    <li>Hey</li>
                    <li>Hey</li>
                    <li>Hey</li>
                  </ul>
                </div>
              )}
            </div>
          </li>
          {productCategoryLoading ? (
            <li>Loading categories...</li>
          ) : (
            <>
              {productCategory
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, i) => (
                  <li
                    key={1 + i}
                    onClick={() => {
                      navigate(`/product-category/${item.id}`)
                    }}
                  >
                    {item.name}
                  </li>
                ))}
            </>
          )}
        </ul>
      </div> */}
    </div>
  )
}

export default Navbar
