import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { axiosCalls } from '../../_api'

import './style.scss'

import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'

import cerelac from '../../assets/images/cerelac.png'

const SearchProducts = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [total, setTotal] = useState(0)

  const { searchTerm } = useParams()
  const navigate = useNavigate()

  const getSearchResults = async () => {
    setLoading(true)

    const res = await axiosCalls('search-product', 'post', {
      search_name: searchTerm,
    })

    if (res) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)

      if (res.er) {
        return
      }

      setResults(res.data.data)
      setTotal(res.data.total)
    }

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  useEffect(() => {
    getSearchResults()
  }, [])

  if (loading) {
    return <>Loading...</>
  }

  return (
    <div>
      <Navbar />

      <div className='search-content'>
        <div>
          <button className='btn-back' onClick={() => navigate('/homepage')}>
            Back
          </button>
          <p>
            Showing {total} results for {searchTerm}
          </p>
        </div>

        <div className='products'>
          {results.length > 0 &&
            results.map((product) => (
              <div
                className='product'
                onClick={() => navigate(`/single-product/${product.slug}`)}
              >
                <img src={product.main_image} alt='' />

                <p>{product.name}</p>

                <h5>₦{product.retail_price}</h5>
              </div>
            ))}

          {results.length === 0 && (
            <div className='no-result'>
              <p>No Results Available for your Search</p>
              <button
                className='btn-back'
                onClick={() => navigate('/homepage')}
              >
                Home
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SearchProducts
