import React from "react";
import EmailSettings from "./components/emailSettings/EmailSettings";
import NotificationSettings from "./components/notificationSettings/NotificationSettings";
import PersonalSettings from "./components/personalInfoSettings/PersonalSettings";
import SecuritySettings from "./components/securitySettings/SecuritySettings";
import "./settings.scss";

const Settings = () => {
  return (
    <>
      <div className="settingsText-write">Settings</div>
      <div className="settings-formCont">
        <PersonalSettings />
        <EmailSettings />
        <SecuritySettings />
        <NotificationSettings />
      </div>
    </>
  );
};

export default Settings;
