import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useState, useEffect } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../_api'
import productPencil from '../../../../../assets/icon/productPencil.svg'

import productThreeDot from '../../../../../assets/icon/productThreeDot.svg'
import '../../productTable.scss'
import { hideLoader, showLoader } from '../../../../../loader'
import Loader from '../../../../../components/loader/Loader'
import ShortenName from '../../../../../components/shortenName/ShortenName'
import FormatNumber from '../../../../../components/formatNumber/FormatNumber'
import TablePagination from '../../../../../components/tablePagination/TablePagination'

export function Table({
  setSelectedProduct,
  setShowDetails,
  handleToggleNewModal,
}) {
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [productList, setProductList] = useState([])

  useEffect(() => {
    getProducts(1)
  }, [])

  const getProducts = async (num) => {
    setCurrentPage(num)
    setLoading(true)

    const res = await axiosCalls(`admin/product?page=${num}`, 'get')
    if (res) {
      hideLoader()
      setTimeout(() => {
        setLoading(false)
      }, 2000)
      if (res.er) {
        hideLoader()
        return
      }
      setProductList(res?.data)
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  const Vendor = localStorage.getItem('role') === 'Vendor'

  return loading ? (
    <Loader size={'small'} align={'center'} />
  ) : (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPage={10}
              count={productList.total}
              page={currentPage - 1}
              dataList={productList}
              currentPage={currentPage}
              fetchData={getProducts}
            />
          ),
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 10,
        }}
        columns={
          Vendor
            ? [
                { title: 'Product Name', field: 'ID' },
                { title: 'Price', field: 'price' },
                { title: 'Discount', field: 'discount' },
                { title: 'Quantity', field: 'quantity' },
                { title: 'Action', field: 'Action' },
              ]
            : [
                { title: 'Product Name', field: 'ID' },
                { title: 'Price', field: 'price' },
                { title: 'Discount', field: 'discount' },
                { title: 'Vendor', field: 'vendor' },
                { title: 'Quantity', field: 'quantity' },
                { title: 'Action', field: 'Action' },
              ]
        }
        data={productList.data.map((data) => {
          return {
            ID: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedProduct(data)
                  setShowDetails(true)
                }}
                className='productName-GenFam'
              >
                <div className='productName-GenImg'>
                  <img
                    src={data.main_image ? data.main_image : data?.images[0]}
                  />
                </div>
                <div className='productName-GenImgName'>
                  <ShortenName name={data?.name} maxLength={20} />
                </div>
                {/* {!data?.vendor ? "-" : data?.vendor}{" "} */}
              </div>
            ),
            price: <FormatNumber value={data?.price} />,
            discount: data?.discounted_price + '%',
            vendor: data?.vendor.vendor_name,
            quantity: data?.stock_quantity,
            // action: !data?.vendor ? "-" : data?.vendor,

            Action: (
              <div className='subAccountSub-action'>
                {/* <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: "rgba(128, 128, 128, 0.103)" }
                        : { background: " rgb(199, 13, 13)" }
                      : {}
                  }
                >
                  Cancel subscription
                </button> */}

                <div className='subAccountSub-actionPenProd'>
                  <img
                    src={productPencil}
                    onClick={() => {
                      setSelectedProduct(data)
                      handleToggleNewModal()
                    }}
                  />
                </div>

                <div className='subAccountSub-actionDotProd'>
                  <img src={productThreeDot} />
                </div>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title='Recent Orders'
      />
    </div>
  )
}
