import React, { useState } from 'react'
import { GoCalendar } from 'react-icons/go'
import { HiDownload } from 'react-icons/hi'
import OrdersModal from './components/Modals/OrdersModal'
import Table from './components/Table/index'
import NewProductModal from './components/newProductModal/NewProductModal'
import './index.css'
import { Container } from './styled'
import EditExperienceModal from './components/newProductModal/EditExperienceModal'

const Experiences = () => {
  const [showModal, setShowModal] = useState(false)
  const [showNewModal, setShowNewModal] = useState(false)
  const [selectedExperience, setSelectedExperience] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)

  function handleToggleModal() {
    setShowModal((prevState) => !prevState)
  }

  function handleToggleNewModal() {
    setShowNewModal((prevState) => !prevState)
  }
  function handleToggleEditModal() {
    setShowEditModal((prevState) => !prevState)
  }
  return (
    <div className='app-scroll'>
      <Container>
        <header>
          <h1 className='u--typo__title2'>Experiences</h1>
          <div className='action--container'>
            {/* <div className='select--container'>
              <select>
                <option>Filter by</option>
              </select>
            </div>

            {/* <GoCalendar />
              <select>
                <option>This week</option>
              </select> */}
            {/* <div className='product-exportBtn'>
              <HiDownload />
              Export
            </div>{' '} */}

            <div
              onClick={handleToggleNewModal}
              className='products-newProductsbtn'
            >
              New Experience
            </div>
          </div>
        </header>
        <div className='orders__table'>
          <Table
            handleToggleModal={handleToggleModal}
            handleToggleEditModal={handleToggleModal}
            setSelectedExperience={setSelectedExperience}
            setShowNewModal={setShowNewModal}
            // handleToggleNewModal={handleToggleNewModal}
          />
        </div>
        {showModal && (
          <OrdersModal
            handleToggleModal={handleToggleModal}
            setSelectedExperience={setSelectedExperience}
            selectedExperience={selectedExperience}
          />
        )}
        {showEditModal && (
          <EditExperienceModal
            handleToggleNewModal={handleToggleEditModal}
            selectedExperience={selectedExperience}
          />
        )}
        {showNewModal && (
          <NewProductModal
            handleToggleNewModal={handleToggleNewModal}
            selectedExperience={selectedExperience}
          />
        )}
      </Container>
    </div>
  )
}

export default Experiences
