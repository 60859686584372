import React, { useState, useEffect } from 'react'
import { GoCalendar } from 'react-icons/go'
import { HiDownload } from 'react-icons/hi'
// import { useRouteMatch } from "react-router-dom";
import { Table } from './components'
import './index.css'
import { Container } from './styled'
// import { axiosCalls } from "../../../../_api";
import { axiosCalls } from '../../../../src/_api'
import { showLoader, hideLoader } from '../../../loader'
import Loader from '../../../components/loader/Loader'
import NewVendor from './components/NewVendor'

const Vendors = () => {
  const [loading1, setLoading1] = useState(true)
  const [accountData, setAccountData] = useState({})
  const [showAddNew, setShowAddNew] = useState(false)
  function handleToggleNewModal() {
    setShowAddNew(!showAddNew)
  }

  useEffect(() => {
    getAllMyAccountData()
  }, [])

  const getAllMyAccountData = async () => {
    showLoader()
    setLoading1(true)
    const res = await axiosCalls('admin/vendor', 'get')
    if (res) {
      hideLoader()
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        hideLoader()
        return
      }
      setAccountData(res?.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  return loading1 ? (
    <Loader direction={'center'} size={'small'} />
  ) : (
    <div className='app-scroll'>
      <Container>
        <header>
          <h1 className='u--typo__title2'>Vendors</h1>
          <div className='action--container'>
            <div className='select--container'>
              <select>
                <option>Filter by</option>
              </select>
            </div>
            <div className='product-exportBtn'>
              <HiDownload />
              Export
            </div>
            <div
              onClick={handleToggleNewModal}
              className='products-newProductsbtn'
            >
              New Vendor
            </div>
          </div>
        </header>
        <div className='vendors__table'>
          <Table url={'url'} accountData={accountData} />
        </div>
        {showAddNew && (
          <NewVendor handleToggleNewModal={handleToggleNewModal} />
        )}
      </Container>
    </div>
  )
}

export default Vendors
