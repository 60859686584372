import React from "react";

const ReportIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6842 10.715C15.2483 10.56 14.7733 10.7875 14.6208 11.2225C13.6808 13.8808 11.1533 15.6667 8.33333 15.6667C4.6575 15.6667 1.66667 12.6767 1.66667 8.99999C1.66667 6.17999 3.4525 3.65249 6.11083 2.71249C6.545 2.55999 6.7725 2.08416 6.61917 1.64999C6.46583 1.21666 5.99 0.988322 5.55583 1.14249C2.2325 2.31666 0 5.47415 0 8.99999C0 13.595 3.73833 17.3333 8.33333 17.3333C11.8592 17.3333 15.0167 15.1017 16.1917 11.7775C16.345 11.3442 16.1183 10.8683 15.6842 10.715ZM9.99975 7.33366V2.39283C12.5506 2.75949 14.5739 4.78199 14.9406 7.33366H9.99975ZM9.16642 0.666992C8.70642 0.666992 8.33308 1.03949 8.33308 1.50033V8.16699C8.33308 8.62783 8.70642 9.00033 9.16642 9.00033H15.8331C16.2931 9.00033 16.6664 8.62783 16.6664 8.16699C16.6664 4.03116 13.3014 0.666992 9.16642 0.666992Z"
        fill={fill}
      />
    </svg>
  );
};

export default ReportIcon;
