import React, { useState, useEffect } from 'react'
// import { axiosCalls } from "../../_api";
import { Link } from 'react-router-dom'
import validator from 'validator'
import { showLoader, hideLoader } from '../../loader'
import bubbleColonyLogoHome from '../../assets/icon/bubbleColonyLogoHome.svg'
import eye from '../../assets/icon/eye.svg'
import './style.scss'
import { Toast } from '../../helpers/toast'
import { useDispatch } from 'react-redux'
import { setAuthenticated, setUser } from '../../reducers/auth'
import capitalize from '../../helpers/capitalize'
import { axiosCalls } from '../../_api'

const Login = () => {
  const dispach = useDispatch()
  const [auth, setAuth] = useState({
    email: '',
    password: '',
  })
  const [checked, setChecked] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const [emailAd, setEmailAd] = useState('')
  const [passwordAd, setPasswordAd] = useState('')

  const [emailError, setEmailError] = useState('')
  const [showPass, setShowPass] = useState(false)

  const passwordVisiblity = () => {
    setShowPass((showPass) => !showPass)
  }

  const validateEmail = (e) => {
    var email = e.target.value

    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Please Enter valid a Email!')
    }
  }

  const loginAuth = async (e) => {
    e.preventDefault()

    const data = {
      email: auth.email,
      password: auth.password,
    }
    showLoader()
    const res = await axiosCalls('admin/login', 'POST', data)
    if (res) {
      hideLoader()
      if (res.er) {
        Toast('error', res.er.message)

        return
      }
      localStorage.setItem('token', res.data.auth.token)
      localStorage.setItem('role', res.data.role.name)
      dispach(setAuthenticated(true))
      Toast('success', `Welcome back Admin`)
      window.location.href = '/admin/dashboard/home'
    }
  }

  const handleCheckedChange = () => {
    setChecked(!checked)
  }

  return (
    <div className='login-page-container'>
      <div className='login-page-header'>
        <div className='login-page-logo'>
          <img src={bubbleColonyLogoHome} alt='home_logo' />
        </div>
      </div>

      <div className='login-content'>
        <div className='login-form-container'>
          <h1>Login</h1>

          <form className='login-form' onSubmit={loginAuth}>
            <div className='form-group'>
              <label>Email</label>

              <input
                type='text'
                placeholder='Enter email address'
                name='uname'
                required
                onChange={(e) => {
                  setAuth({ ...auth, email: e.target.value })
                  validateEmail(e)
                  setEmailAd(e.target.value)
                }}
              />
            </div>

            {emailError && (
              <div
                style={{
                  fontSize: '10px',
                  marginTop: '5px',
                  marginLeft: '20px',
                  color: 'red',
                }}
              >
                {emailError}
              </div>
            )}

            <div className='form-group'>
              <label>Password</label>

              <div>
                <input
                  type={showPass ? 'text' : 'password'}
                  placeholder='Enter password'
                  name='pass'
                  required
                  onChange={(e) => {
                    setAuth({ ...auth, password: e.target.value })
                  }}
                />

                <span onClick={passwordVisiblity}>
                  {showPass ? (
                    <img className='password-icon' src={eye} alt='eye' />
                  ) : (
                    <img className='password-icon' src={eye} alt='eye' />
                  )}
                </span>
              </div>
            </div>

            <Link to='/forgot-password' style={{ textDecoration: 'none' }}>
              <div className='forgot-pass'>
                <p>Forgot Password?</p>
              </div>
            </Link>

            <div className='login-btn'>
              <input type='submit' value='Log In' />
            </div>

            {/* <div className='newTo'>
							<p>New to Bubble colony?</p> <a href='/create-account'>Sign up</a>
						</div> */}
          </form>
        </div>

        <div className='divider'></div>
      </div>
    </div>
  )
}

export default Login
