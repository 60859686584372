import React from 'react'
import loveIcon from '../../assets/icon/loveIcon.svg'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import ContentLoader from 'react-content-loader'

const NewAdditions = ({ data, headingTag, loading, extra, link }) => {
  let navigate = useNavigate()
  function navigateTo(product) {
    if (extra) {
      extra(product.slug)
    }
    navigate(`/single-product/${product.slug}`)
  }
  return (
    <>
      {headingTag.length > 1 && (
        <div className='new-additions-heading'>
          <div className='new-additions-line'>
            <p>{headingTag}</p>
          </div>
          <div className='new-additions-link'>
            <p
              onClick={() => {
                link && navigate(link)
              }}
            >
              See all
            </p>
          </div>
        </div>
      )}

      {loading ? (
        <div
          style={{
            display: 'flex',
          }}
        >
          {['1', '2', '3', '4'].map((item, i) => (
            <div style={{ marginRight: '15px', width: '23%' }} key={i + 1}>
              <ContentLoader
                speed={2}
                width={'100%'}
                height={250}
                // viewBox="60 60 400 350"
                backgroundColor=' #5e5d5d11'
                foregroundColor='#2f2a293b'
                // {...props}
              >
                <rect width='100%' height='100%' />
              </ContentLoader>
            </div>
          ))}
        </div>
      ) : (
        <div className='productWrap'>
          {data.map((product) => {
            return (
              <div
                className='new-addition-sale-container'
                onClick={() => navigateTo(product)}
                key={product.id}
              >
                {/* <div className='new-addition-love-icon'>
									<img src={loveIcon} alt='love_icon' />
								</div> */}
                <div className='new-selling-product'>
                  <img
                    src={product.main_image}
                    onError={(e) => {
                      e.target.src =
                        'https://ik.imagekit.io/wya0h5u2s/Empty_Image_48QhXVCGQ.png'
                    }}
                    alt='new_product'
                  />
                </div>
                <div className='new-selling-product-name'>
                  <p>{product?.product_name}</p>
                </div>
                <div className='new-selling-product-price'>
                  <p>
                    ₦
                    {product?.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default NewAdditions
