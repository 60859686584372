import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaystackButton } from 'react-paystack'

import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'

import { showLoader, hideLoader } from '../../loader'
import { Toast } from '../../helpers/toast'

import { axiosCalls } from '../../_api'

import { CartContext } from '../../context/cart-context'

import './style.scss'

import masterCard11 from '../../assets/icon/masterCard11.png'
import visaCard11 from '../../assets/icon/visaCard11.png'
import verveCard from '../../assets/icon/verveCard.png'
import paga from '../../assets/icon/paga.svg'
import safeLock from '../../assets/icon/safeLock.png'

const GuestCheckout = () => {
  const navigate = useNavigate()
  const { items, removeItem, clearStorage } = useContext(CartContext)

  const [cartSummary, setCartSummary] = useState({})
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_no: '',
    address: '',
    state_id: 0,
    city_id: 0,
  })

  const guest_code = localStorage.getItem('bubble_guest_code')

  useEffect(() => {
    if (guest_code.length > 0) {
      items.map((item) => addToGuestCart(item))

      getGuestCartSummary()
      getStates()
      getCity(formData.state_id)
    }
  }, [formData.state_id, guest_code.length, items])

  const addToGuestCart = async (product) => {
    const data = {
      product_id: product.product_id,
      quantity: product.quantity,
      access_code: guest_code,
    }

    showLoader()

    const res = await axiosCalls(`guest-cart`, 'post', data)

    if (res) {
      hideLoader()

      if (res.er) {
        return Toast('error', res.er.message)
      }

      Toast('success', 'Product added to cart successfully')

      removeItem(product.product_id)
    }
  }

  const getGuestCartSummary = async () => {
    showLoader()

    const res = await axiosCalls(`guest-cart-summary/${guest_code}`, 'get')

    if (res) {
      hideLoader()

      if (res.er) {
        return Toast('error', res.er.message)
      }

      setCartSummary(res.data)
    }
  }

  const getStates = async () => {
    showLoader()

    const res = await axiosCalls(`guest/all-states`, 'get')

    if (res) {
      hideLoader()

      if (res.er) {
        return Toast('error', res.er.message)
      }

      setStates(res.data)
    }
  }

  const getCity = async (id) => {
    showLoader()

    const res = await axiosCalls(`guest/all-state-cities/${id}`, 'get')

    if (res) {
      hideLoader()

      if (res.er) {
        return Toast('error', res.er.message)
      }

      setCities(res.data)
    }
  }

  const guestCheckout = async (data) => {
    showLoader()

    const res = await axiosCalls(`guest-product-checkout`, 'post', data)

    if (res) {
      hideLoader()

      if (res.er) {
        Toast('error', res.er.message)
        return
      }

      Toast('success', res?.message)
      clearStorage()
      navigate('/order-success')
    }
  }

  const handleOnSuccess = (e) => {
    const data = {
      ...formData,
      payment_method_id: 1,
      amount: cartSummary?.estimated_total,
      trans_id: e.transaction,
      reference: e.reference,
      type: 1,
      // coupon_code: '',
      access_code: guest_code,
    }

    guestCheckout(data)
  }

  if (cartSummary && Object.keys(cartSummary).length === 0) {
    return showLoader()
  }

  return (
    <div>
      <Navbar />
      <h2 className='header'>Checkout</h2>

      <div className='container'>
        <div className='checkout-info'>
          <h3>Delivery Details</h3>
          <p className='instructions'>
            Please enter a shipping address for this order. Please also indicate
            whether your billing address is the same as the shipping address
            entered. <br /> When finished, click the 'Continue' button.
          </p>
          <div className='form'>
            <div className='form-item-group'>
              <div className='form-item'>
                <label htmlFor='name'>Full Name</label>
                <input
                  type='text'
                  placeholder='Enter Full Name'
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }}
                />
              </div>

              <div className='form-item'>
                <label htmlFor='email'>Phone</label>
                <input
                  type='text'
                  maxLength={11}
                  placeholder='Enter Phone Number'
                  value={formData.phone_no}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phone_no: e.target.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-item'>
              <label htmlFor='address'>Address</label>
              <textarea
                col={20}
                rows={4}
                placeholder='Enter Address'
                value={formData.address}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  })
                }}
                style={{
                  fontFamily: 'Nunito',
                }}
              />
            </div>

            <div className='form-item'>
              <label htmlFor='email'>Email</label>
              <input
                type='text'
                placeholder='Enter Email'
                value={formData.email}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }}
              />
            </div>

            <div className='form-item-group'>
              <div className='form-item'>
                <label htmlFor='state'>State</label>
                <select
                  name='state'
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      state_id: e.target.value,
                    })
                  }
                >
                  {states.map((state) => (
                    <option value={state.id}>{state.name}</option>
                  ))}
                </select>
              </div>

              <div className='form-item'>
                <label htmlFor='city'>City</label>
                <select
                  disabled={formData.city === 0}
                  name='city'
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      city_id: e.target.value,
                    })
                  }
                >
                  {cities.map((city) => (
                    <option value={city.id}>{city.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className='form-item'>
              <div className='pay-button'>
                <PaystackButton
                  reference={new Date().getTime().toString()}
                  email={formData?.email}
                  amount={parseInt(cartSummary?.estimated_total * 100)}
                  publicKey={'pk_test_5d63ef54ed41265fd32396ddf37d383b3b5a8f3a'}
                  text='Continue to Payment'
                  onSuccess={handleOnSuccess}
                  // onClose={handleOnClose} // Optional
                />
              </div>
            </div>
          </div>
        </div>

        <div className='cart-summary'>
          <h3>Summary</h3>

          <div className='summary-item'>
            <p className='label'>Items ({cartSummary.total_items}):</p>
            <p>₦{parseFloat(cartSummary.total_amount).toLocaleString()}</p>
          </div>

          <div className='summary-item'>
            <p className='label'>Order Discounts:</p>
            <p className='discount'>
              -₦{parseFloat(cartSummary.discount).toLocaleString()}
            </p>
          </div>

          <div className='summary-item'>
            <p className='label'>Estimated Total:</p>
            <p className='total'>
              ₦{parseFloat(cartSummary.estimated_total).toLocaleString()}
            </p>
          </div>

          <div className='cart-item-card-accept'>
            <div className='cart-card-list'>
              <p>We accept</p>
            </div>
            <div className='master-card-img'>
              <img src={masterCard11} />
            </div>
            <div className='visa-card-img'>
              <img src={visaCard11} />
            </div>
            <div className='verve-card-img'>
              <img src={verveCard} />
            </div>
            <div className='paga-img'>
              <img src={paga} />
            </div>
          </div>

          <div className='cart-secure-cont'>
            <div className='cart-secure-img'>
              <img src={safeLock} />
            </div>
            <div className='cart-secure-safe-text'>
              <p>100% Safe and Secure</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default GuestCheckout
