/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react'
import closeOrderModal from '../../../../../assets/icon/closeOrderModal.svg'
import Modal from 'react-modal'
// @ts-ignore
// @ts-ignore
import './ordersModal.scss'
import ModalLayout from '../../../../../components/layout/modalLayout/ModalLayout'

Modal.setAppElement('#root')

function OrdersModal({
  handleToggleModal,
  selectedExperience,
  setSelectedExperience,
}) {
  const [showOpt, setShowOpt] = useState(false)
  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>Experience Details</div>
        <div
          className='adminOrders-headingClose'
          onClick={() => {
            handleToggleModal()
            setSelectedExperience(null)
          }}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      {showOpt && (
        <div className='adminOrders-SubheadingStaOptDrop'>
          <div className='adminOrders-SubheadingStaOptDrop1'>Edit Product</div>
          <div className='adminOrders-SubheadingStaOptDrop2'>
            Delete Product
          </div>
        </div>
      )}
      <div className='adminOrders-summaryFam'>
        <div className='adminProduct-productNameTagGenOne'>
          <div className='adminProduct-productNameTag'>Product Name</div>
        </div>
        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>
            {selectedExperience?.name}
          </div>
        </div>

        <div className='adminProduct-productNameTagGen'>
          <div className='adminProduct-productNameTag'>Adult price</div>
          <div className='adminProduct-productNameTagCate'>Child price</div>
        </div>

        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>
            ₦{parseInt(selectedExperience?.adult_price).toLocaleString()}
          </div>
          <div className='adminProduct-productNameP'>
            ₦{parseInt(selectedExperience?.child_price).toLocaleString()}
          </div>
        </div>

        <div className='adminProduct-productNameTagGen'>
          <div className='adminProduct-productNameTag'>Date</div>
          <div className='adminProduct-productNameTagCate'>Venue</div>
        </div>

        <div className='adminProduct-productNameFam'>
          <div className='adminProduct-productNameN'>
            {new Date(selectedExperience?.event_date).toDateString()}
          </div>
          <div className='adminProduct-productNameP'>
            {selectedExperience?.venue}
          </div>
        </div>
        <div className='adminProduct-productDiscountTag'>
          Product Description
        </div>
        <div className='adminProduct-productDiscount'>
          {selectedExperience.description}
        </div>
        <div className='adminProduct-productDiscountTag'>
          Additional Information
        </div>
        <div className='adminProduct-productDiscount'>
          {selectedExperience.additional_information}
        </div>
        <div className='adminProduct-productDiscountTag'>
          Social media links
        </div>
        {JSON.parse(selectedExperience?.social_media_links).map((item, i) => (
          <div className='adminProduct-productDiscount' key={i}>
            {item}
          </div>
        ))}
      </div>

      <div className='adminOrders-customerFam'>
        <div className='adminOrders-customerDetai'>Product Images</div>

        <div className='adminProduct-imageSec'>Image</div>
        {JSON.parse(selectedExperience?.images).map((item, i) => (
          <div className='adminProduct-imageSecFistFlex' key={i + 1}>
            <div className='adminProduct-imageSecFistImg'>
              <img src={item} />
            </div>
            <div className='adminProduct-imageSecFistImgName'>
              {item.substring(item.lastIndexOf('/') + 1)}
            </div>
          </div>
        ))}
      </div>
    </ModalLayout>
  )
}

export default OrdersModal
