import React, { useState, useEffect } from 'react'
import DurationData from '../../assets/Data/DurationData'
import Select from 'react-select'
import Cities from '../../assets/Data/cities'
import { axiosCalls } from '../../_api'
function SearchBar({
  date,
  city,
  country,
  setLoading,
  setEventData,
  selectedCountry,
  setSelectedCountry,
  selectedCity,
  setSelectedCity,
  filterDate,
  setFilterDate,
}) {
  const sortedCities = Object.keys(Cities).reduce((acc, country) => {
    acc[country] = Cities[country].sort()
    return acc
  }, {})
  const [cities, setCities] = useState([])
  const handleCountryChange = (event) => {
    setSelectedCountry(event)
    setCities(sortedCities[event.label] || [])
  }
  useEffect(() => {
    setSelectedCountry({
      label: country,
      value: country,
    })
    setSelectedCity({
      label: city,
      value: city,
    })
    setCities(sortedCities[country] || [])
    if (date) {
      setFilterDate(date)
    }
  }, [])

  const filterSearch = async () => {
    setLoading(true)
    window.history.pushState(
      {},
      '',
      `/search-experience?city=${selectedCity.label}&country=${selectedCountry.label}&date=${filterDate}`
    )

    let url = ''
    if (filterDate.length > 1) {
      url = `search/experiences?city=${selectedCity.label}&country=${selectedCountry.label}&date=${filterDate}`
    } else {
      url = `search/experiences?city=${selectedCity.label}&country=${selectedCountry.label}`
    }
    const res = await axiosCalls(url, 'get')
    if (res) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
      if (res.er) {
        return
      }
      setEventData(res)
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  return (
    <>
      <div className='left-top'>
        <h2>When are you traveling?</h2>
        <input
          type='date'
          name=''
          id=''
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
        />
      </div>
      <div className='left-body'>
        <div className='search-card'>
          <h2>Duration</h2>
          {DurationData.map((item) => (
            <div className='check-card' key={item}>
              <input type='checkbox' name='' id={item} className='check' />
              <label htmlFor={item}>{item}</label>
            </div>
          ))}
        </div>
        <div className='search-card'>
          <h2>Country</h2>
          <Select
            name='colors'
            options={Object.keys(sortedCities)
              .sort()
              .map((country) => ({
                label: country,
                value: country,
              }))}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={handleCountryChange}
            value={selectedCountry}
          />
        </div>
        <div className='search-card'>
          <h2>City</h2>
          <Select
            name='colors'
            options={cities.sort().map((item) => ({
              label: item,
              value: item,
            }))}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => setSelectedCity(e)}
            value={selectedCity}
          />
        </div>
        {/* <div className='search-card'>
          <h2>Price</h2>
          <input type='range' name='' id='' />
        </div> */}
        <div className='search-card diff'>
          <button onClick={filterSearch}>Filter</button>
        </div>
      </div>
    </>
  )
}

export default SearchBar
