import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import icons from './icons'
import { axiosCalls } from '../../../../../_api'
import { Link } from 'react-router-dom'
import { showLoader, hideLoader } from '../../../../../loader'

export function Table({
  setAddSubAccountModalFun,
  cancelSubscription,
  handleToggleModal,
}) {
  const [loading1, setLoading1] = useState(true)

  const [allMySubAccountsData, setAllMySubAccountsData] = useState([])

  useEffect(() => {
    getAllMySubAccountsDat()
  }, [])

  const getAllMySubAccountsDat = async () => {
    showLoader()
    setLoading1(true)
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      hideLoader()
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        hideLoader()
        return
      }
      setAllMySubAccountsData(res?.data?.recent_orders)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  // const allMySubAccountsData = [
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  //   {
  //     id: '121091',
  //     name: 'Ebere McCoy',
  //     email: 'ebere.mcoy@example.com',
  //     phone: '07069359072',
  //     created: '24 February, 2019',
  //     last_seen: '24 February, 2021, 07:30pm',
  //   },
  // ];

  return (
    <div className='table-wrap border styleTable'>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSize: 5,
        }}
        columns={[
          { title: 'ID', field: 'ID' },
          { title: 'Name', field: 'name' },
          { title: 'Email', field: 'email' },
          { title: 'Phone Number', field: 'phone' },
          { title: 'Date Created', field: 'created' },
          { title: 'Last Visit', field: 'last_seen' },
        ]}
        data={allMySubAccountsData.map((data) => {
          return {
            ID: data?.id,

            name: (
              <Link
                style={{ textDecoration: 'none' }}
                to='/admin/dashboard/customers/:id'
              >
                {data?.user?.first_name}
              </Link>
            ),

            email: data?.user?.email,
            phone: data?.user?.phone_no,
            created: data?.user?.created_at.slice(0, 10),
            last_seen: data?.user?.updated_at.slice(0, 10),
            status: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggleModal()
                }}
              >
                {data?.is_active ? 'Active' : 'Not Active'}
              </div>
            ),
            Action: (
              <div className='subAccountSub'>
                <button
                  disabled={data ? (data.isCancelled ? true : false) : false}
                  // onClick={() => cancelSubscription(data.id)}
                  style={
                    data
                      ? data.isCancelled
                        ? { background: 'rgba(128, 128, 128, 0.103)' }
                        : { background: ' rgb(199, 13, 13)' }
                      : {}
                  }
                >
                  Cancel subscription
                </button>
              </div>
            ),

            Check: (
              <div className='publicationCheckbox'>
                <input id='c1' type='checkbox' />
              </div>
            ),
          }
        })}
        title='Recent Orders'
      />
    </div>
  )
}
