import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import './emailVerification.scss';

import verifySuccess from '../../assets/images/successful_verification.svg';
import verifyFail from '../../assets/images/failed_verification.svg';

const EmailVerification = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();
	const success = searchParams.get('success');

	useEffect(() => {
		if (success === 'false') {
			setTimeout(() => {
				navigate('/login');
			}, 5000);
		}
	}, []);

	return (
		<div className='page-container'>
			<h2>Email Verification</h2>

			{success === 'true' ? (
				<div className='content'>
					<img src={verifySuccess} alt='Success' width={290} />

					<p>Your email was verified successfully. Proceed to Login below.</p>

					<button onClick={() => navigate('/login')}>Login</button>
				</div>
			) : (
				<div className='content'>
					<img src={verifyFail} alt='Success' width={290} />

					<p>Verification Failed, please retry.</p>
				</div>
			)}
		</div>
	);
};

export default EmailVerification;
