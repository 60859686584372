import React from 'react';
import './layout.scss';
import mobileIcon from '../../assets/icon/mobileIcon.svg';
import locationIcon from '../../assets/icon/locationIcon.svg';
import mailIcon from '../../assets/icon/mailIcon.svg';
import bubbleColonyLogoWhite from '../../assets/icon/bubbleColonyLogoWhite.svg';
import instagramIconFooterr from '../../assets/icon/instagramIconFooterr.svg';
import twitterIconFooterr from '../../assets/icon/twitterIconFooterr.svg';
import youtubeIconFooterr from '../../assets/icon/youtubeIconFooterr.svg';

const Footer = () => {
	return (
		<div className='footer-body'>
			<div className='first-line-footer-family'>
				<div className='subscribe-head-text'>
					<h2>New to Bubble Colony?</h2>
				</div>

				<div className='subscribe-para-text'>
					<p>
						Subscribe to our newsletter to stay updated with the latest news and
						special sales. <br />
						Leave your email address here!
					</p>
				</div>

				<div className='subscribe-btn-cont'>
					<input type='text' placeholder='Enter your email address' />{' '}
					<button>Subscribe</button>
				</div>
			</div>

			<div className='second-line-footer-family'>
				<div className='second-line-footer-flex'>
					<div className='second-footer-about'>
						<div className='bubble-footer-logo'>
							<img src={bubbleColonyLogoWhite} alt='bubble_colony_logo' />
						</div>
						<div className='footer-about'>
							<p>
								Bubble Colony is a fast baby business offering the best and
								largest collection of baby products for infants to toddlers from
								trusted local & international brands.
							</p>
						</div>

						{window.innerWidth > 768 && (
							<div className='footer-social-family'>
								<div className='footer-social-icon'>
									<img src={instagramIconFooterr} alt='instagram_icon' />
								</div>
								<div className='footer-social-icon'>
									<img src={twitterIconFooterr} alt='twitter_icon' />
								</div>
								<div className='footer-social-icon'>
									<img src={youtubeIconFooterr} alt='youtube_icon' />
								</div>
							</div>
						)}
					</div>

					<div className='footer-info'>
						<div>
							<div className='footer-info-d'>
								<p>About</p>
							</div>
							<div className='footer-info-d'>
								<p>Contact Us</p>
							</div>
							<div className='footer-info-d'>
								<p>Terms & Conditions</p>
							</div>
							<div className='footer-info-d'>
								<p>Privacy Policy</p>
							</div>

							{window.innerWidth <= 768 && (
								<>
									<div className='footer-info-d'>
										<p>Sell on Bubble Colony</p>
									</div>
									<div className='footer-info-d'>
										<p>FAQs</p>
									</div>
								</>
							)}
						</div>

						{window.innerWidth <= 768 && (
							<div className='footer-body-family'>
								<div className='footer-contact-info'>
									<div className='footer-contact-icon'>
										<img src={mailIcon} alt='mail_icon' />{' '}
									</div>
									<div className='footer-contact-text'>
										<p>hello@bubblecolony.com</p>
									</div>
								</div>

								<div className='footer-contact-info'>
									<div className='footer-contact-icon'>
										<img src={mobileIcon} alt='mobile_icon' />
									</div>
									<div className='footer-contact-text'>
										<p>+234 876 43210</p>
									</div>
								</div>
								<div className='footer-contact-info'>
									<div className='footer-contact-icon'>
										<img src={locationIcon} alt='location_icon' />
									</div>
									<div className='footer-contact-text'>
										<p>2343 Somewhere in lagos, NIgeria</p>
									</div>
								</div>

								{window.innerWidth <= 768 && (
									<div className='footer-social-family'>
										<div className='footer-social-icon'>
											<img src={instagramIconFooterr} alt='instagram_icon' />
										</div>
										<div className='footer-social-icon'>
											<img src={twitterIconFooterr} alt='twitter_icon' />
										</div>
										<div className='footer-social-icon'>
											<img src={youtubeIconFooterr} alt='youtube_icon' />
										</div>
									</div>
								)}
							</div>
						)}
					</div>

					{window.innerWidth > 768 && (
						<div className='footer-info'>
							<div className='footer-info-d'>
								<p>Sell on Bubble Colony</p>
							</div>
							<div className='footer-info-d'>
								<p>FAQs</p>
							</div>
						</div>
					)}

					{window.innerWidth > 768 && (
						<div className='footer-body-family'>
							<div className='footer-contact-info'>
								<div className='footer-contact-icon'>
									<img src={mailIcon} alt='mail_icon' />{' '}
								</div>
								<div className='footer-contact-text'>
									<p>hello@bubblecolony.com</p>
								</div>
							</div>

							<div className='footer-contact-info'>
								<div className='footer-contact-icon'>
									<img src={mobileIcon} alt='mobile_icon' />
								</div>
								<div className='footer-contact-text'>
									<p>+234 876 43210</p>
								</div>
							</div>
							<div className='footer-contact-info'>
								<div className='footer-contact-icon'>
									<img src={locationIcon} alt='location_icon' />
								</div>
								<div className='footer-contact-text'>
									<p>2343Somewhere in lagos, NIgeria</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className='footer-copyright'>
				<div>
					{' '}
					<p>© 2022 Bubble Colony. All rights reserved</p>{' '}
				</div>
			</div>
		</div>
	);
};

export default Footer;
