import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import babyHomeBanner from '../../assets/icon/babyHomeBanner.png'
import homeSearchIcon from '../../assets/icon/homeSearchIcon.svg'
import babyBunnySale from '../../assets/icon/babyBunnySale.png'
import babyBookTop from '../../assets/icon/babyBookTop.png'
import babyEssentials from '../../assets/icon/babyEssentials.svg'
import babyToy from '../../assets/icon/babyToy.svg'
import babyHappyFeet from '../../assets/icon/babyHappyFeet.svg'

import './style.scss'
import NewAdditions from '../../components/newAdditions/NewAdditions'
import { Link } from 'react-router-dom'
import HomepageSection from '../../components/homepageSection/HomepageSection'
import { axiosCalls } from '../../_api'

// import Skeleton from "react-loading-skeleton";

import { CartContext } from '../../context/cart-context'

const Index = () => {
  const { items } = useContext(CartContext)

  const [newAdditions, setNewAdditions] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [auth, setAuth] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getAdditions()
    getTopSelling()

    const token = localStorage.getItem('token')

    if (token === '' || token == null) {
      setAuth(false)
    } else {
      setAuth(true)
    }
  }, [auth, navigate])

  const getAdditions = async () => {
    setLoading1(true)

    const res = await axiosCalls('landing-page-new-additions', 'get')

    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)

      if (res.er) {
        return
      }

      setNewAdditions(res.data.data)
    }

    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  const getTopSelling = async () => {
    setLoading2(true)

    const res = await axiosCalls('landing-page-top-selling', 'get')

    if (res) {
      setTimeout(() => {
        setLoading2(false)
      }, 2000)

      if (res.er) {
        return
      }

      setTopSelling(res.data.data)
    }
    setTimeout(() => {
      setLoading2(false)
    }, 2000)
  }

  return (
    <div>
      <Navbar />

      <div
        style={{
          backgroundImage: `url(${babyHomeBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '450px',
        }}
        className='home-banner-family-cont'
      >
        <div className='home-banner-text'>
          <h2>Find a great selection of kids items, all curated for you.</h2>
        </div>

        <div className='home-search-cont'>
          <input
            type='text'
            placeholder='Search for products'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            onClick={() => navigate(`/search/${searchTerm}`, { replace: true })}
          >
            <img src={homeSearchIcon} alt='home_search_icon' />
          </button>
        </div>
      </div>

      <div className='home-sections-fam app-padding'>
        <HomepageSection
          sectionName='Baby Essentials'
          sectionImg={babyEssentials}
        />
        <HomepageSection sectionName='Baby Toys' sectionImg={babyToy} />
        <HomepageSection sectionName='Happy Feet' sectionImg={babyHappyFeet} />
      </div>

      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='New Additions'
          data={newAdditions}
          loading={loading1}
        />
      </div>

      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='Top Selling Products'
          data={topSelling}
          loading={loading2}
        />
      </div>

      <Footer />
    </div>
  )
}

export default Index
