import React from 'react'
import { GoCalendar } from 'react-icons/go'
import { HiDownload } from 'react-icons/hi'
import { Chart, Pallets, Table } from './components'
import './index.css'
import { Container } from './styled'

function Transactions() {
  return (
    <div className='app-scroll'>
      <Container>
        <header>
          <h1 className='u--typo__title2'>Transactions</h1>
          <div className='action--container'>
            <div className='select--container'>
              <GoCalendar />
              <select>
                <option>This week</option>
              </select>
            </div>
            <button>
              <HiDownload />
              Export
            </button>
          </div>
        </header>
        <Pallets />
        <div className='chart'>
          <div className='chartHeader'>
            <h2>Transaction Overview</h2>
            <select name='' id=''>
              <GoCalendar />
              <option value=''>This year</option>
            </select>
          </div>

          {/* <Chart /> */}
        </div>
        <div className='transactions__table'>
          <Table />
        </div>
      </Container>
    </div>
  )
}

export default Transactions
