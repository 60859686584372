import { createContext, useState, useEffect } from 'react'
import { axiosCalls } from '../_api'
import { hideLoader, showLoader } from '../loader'
import { Toast } from '../helpers/toast'

export const CartProvider = ({ children }) => {
  const allowed = localStorage.getItem('token')
  const [adminProfile, setAdminProfile] = useState({})
  const [adminLoading, setAdminLoading] = useState(true)
  const [cartDetails, setCartDetails] = useState([])
  const [cartLoading, setCartLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [showLeftBar, setShowLeftBar] = useState(false)
  const [productCart, setProductCart] = useState([])

  const getCart = async (allow) => {
    setCartLoading(true)
    if (allowed || allow) {
      const res = await axiosCalls(`experience-cart`, 'get')
      const res2 = await axiosCalls(`cart`, 'get')
      if (res) {
        if (res.er) {
          return
        }
      }
      const data = [...res.data, ...res2.data]
      setProductCart(res2.data)
      setCartDetails(data)
      checkExperienceDiscrepancies(res.data)
      updateLocalExperienceStorage(res.data)
      updateLocalStorage(res2.data)
      checkDiscrepancies(res2.data)
      setCartLoading(false)
    } else {
      const savedCart = localStorage.getItem('cart')
      if (savedCart) {
        setCartDetails(JSON.parse(savedCart))
        setCartLoading(false)
      }
    }
  }
  const addToCart = async (details) => {
    const existingItemIndex = cartDetails.findIndex((item) => {
      return (
        (item.experience_id && item.experience_id === details.experience_id) ||
        (item.product_id && item.product_id === details.product_id)
      )
    })
    if (allowed) {
      if (details.type === 'product') {
        const data = {
          product_id: details.product_id,
          quantity: details.quantity,
        }
        showLoader()
        const res = await axiosCalls(`cart`, 'post', data)
        if (res) {
          hideLoader()
          if (res.er) {
            return Toast('error', res.er.message)
          }
          Toast('success', 'Product added to cart successfully')
        }
      } else {
        const data = {
          experience_id: details.experience_id,
          user_type: 'user',
          no_of_adults: details.no_of_adults,
          no_of_children: details.no_of_children,
        }
        showLoader()
        const res = await axiosCalls(`experience-cart`, 'post', data)
        if (res) {
          hideLoader()
          if (res.er) {
            return Toast('error', res.er.message)
          }
          Toast('success', 'Experience added to cart successfully')
        }
        localStorage.setItem('cart', JSON.stringify([...cartDetails, data]))
        getCart()
        setTimeout(() => {
          window.location.replace('/cart-page')
        }, 2000)
      }
    }
  }
  const checkAuth = () => {
    const token = localStorage.getItem('token')
    const tokenGuest = localStorage.getItem('bubble_guest_code')
    if (token || tokenGuest) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }
  const getAdminProfile = async () => {
    setAdminLoading(true)
    const res = await axiosCalls(`admin/profile`, 'get')
    if (res) {
      setAdminLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setAdminProfile({})
        return
      }
      setAdminProfile(res?.data ?? [])
      // Toast("success", "Addresses retrieved successfully");
      return
    }
    setAdminProfile({})
  }

  /////// Fetch product category
  const [productCategory, setProductCategory] = useState([])
  const [productCategoryLoading, setProductCategoryLoading] = useState(true)
  const getProductCategory = async () => {
    setProductCategoryLoading(true)
    const res = await axiosCalls('get-Product-category', 'get')
    if (res) {
      setProductCategoryLoading(false)
      if (res.er) {
        setProductCategoryLoading(false)
        return
      }
      setProductCategory(res.data)
    }
    setTimeout(() => {
      setProductCategoryLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getProductCategory()
  }, [])

  //New Cart work through
  const [localCarts, setLocalCarts] = useState([])
  const [localExperienceCart, setLocalExperienceCart] = useState([])
  const fetchLocalCart = () => {
    const productCart = JSON.parse(localStorage.getItem('product_cart')) || []
    setLocalCarts(productCart)
  }
  const fetchLocalExperienceCart = () => {
    const experienceCart =
      JSON.parse(localStorage.getItem('experience_cart')) || []
    setLocalExperienceCart(experienceCart)
  }

  const updateLocalStorage = (fetchedCart) => {
    let productCart = JSON.parse(localStorage.getItem('product_cart')) || []

    fetchedCart.forEach((fetchedItem) => {
      const existingProductIndex = productCart.findIndex(
        (item) => item.product_id === fetchedItem.product_id
      )
      if (existingProductIndex !== -1) {
        productCart[existingProductIndex].quantity = fetchedItem.quantity
      } else {
        const newItem = {
          product_id: fetchedItem.product_id,
          quantity: fetchedItem.quantity,
          price: fetchedItem.product.price,
          discounted_price: fetchedItem.product.discounted_price,
          name: fetchedItem.product.name,
          inventory_status: fetchedItem.product.inventory_status,
          image: fetchedItem.product.main_image,
        }
        productCart.push(newItem)
      }
    })

    localStorage.setItem('product_cart', JSON.stringify(productCart))
    fetchLocalCart()
  }
  const updateLocalExperienceStorage = (fetchedCart) => {
    let experienceCart =
      JSON.parse(localStorage.getItem('experience_cart')) || []
    if (fetchedCart.length > 0) {
      const newItem = {
        name: fetchedCart[0].experience.name,
        experience_id: fetchedCart[0].experience_id,
        user_type: 'user',
        no_of_adults: fetchedCart[0].no_of_adults,
        no_of_children: fetchedCart[0].no_of_children,
        adult_price: fetchedCart[0].experience.adult_price,
        child_price: fetchedCart[0].experience.child_price,
        image: fetchedCart[0].experience.main_image,
      }
      localStorage.setItem('experience_cart', JSON.stringify([newItem]))
    }
    // fetchedCart.forEach((fetchedItem) => {
    //   const existingExperienceIndex = experienceCart.findIndex(
    //     (item) => item.experience_id === fetchedItem.experience_id
    //   )

    //   if (existingExperienceIndex !== -1) {
    //     // Adjust no_of_adults
    //     const existingNoOfAdults = parseInt(
    //       experienceCart[existingExperienceIndex].no_of_adults
    //     )
    //     const fetchedNoOfAdults = parseInt(fetchedItem.no_of_adults)

    //     if (existingNoOfAdults !== fetchedNoOfAdults) {
    //       experienceCart[existingExperienceIndex].no_of_adults =
    //         fetchedNoOfAdults
    //     }

    //     // Adjust no_of_children
    //     const existingNoOfChildren = parseInt(
    //       experienceCart[existingExperienceIndex].no_of_children
    //     )
    //     const fetchedNoOfChildren = parseInt(fetchedItem.no_of_children)

    //     if (existingNoOfChildren !== fetchedNoOfChildren) {
    //       experienceCart[existingExperienceIndex].no_of_children =
    //         fetchedNoOfChildren
    //     }
    //   } else {
    //     if (fetchedItem.length > 0) {
    //       const newItem = {
    //         name: fetchedItem.experience.name,
    //         experience_id: fetchedItem.experience_id,
    //         user_type: 'user',
    //         no_of_adults: fetchedItem.no_of_adults,
    //         no_of_children: fetchedItem.no_of_children,
    //         adult_price: fetchedItem.experience.adult_price,
    //         child_price: fetchedItem.experience.child_price,
    //         image: fetchedItem.experience.main_image,
    //       }
    //       localStorage.setItem('experience_cart', JSON.stringify(newItem))
    //     }
    //   }
    // })

    fetchLocalCart()
  }

  const checkDiscrepancies = async (fetchedCart) => {
    let productCart = JSON.parse(localStorage.getItem('product_cart')) || []
    const discrepancies = []

    // Check for quantity discrepancies and missing items in fetched cart
    productCart.forEach((localItem) => {
      const fetchedItem = fetchedCart.find(
        (item) => item.product_id === localItem.product_id
      )

      if (!fetchedItem || localItem.quantity !== fetchedItem.quantity) {
        discrepancies.push({
          product_id: localItem.product_id,
          quantity: localItem.quantity,
        })
      }
    })

    // Check for items in the fetched cart that are not in the local storage cart
    productCart.forEach((localItem) => {
      const fetchedItem = fetchedCart.find(
        (item) => item.product_id === localItem.product_id
      )

      if (!fetchedItem) {
        discrepancies.push({
          product_id: localItem.product_id,
          quantity: localItem.quantity,
        })
      }
    })
    for (let discrepancy of discrepancies) {
      try {
        await axiosCalls(`cart`, 'post', discrepancy)
      } catch (error) {
        console.error('Error sending discrepancy data:', error)
      }
    }
  }
  const checkExperienceDiscrepancies = async (fetchedCart) => {
    let productCart = JSON.parse(localStorage.getItem('experience_cart')) || []
    const discrepancies = []

    // Check for quantity discrepancies and missing items in fetched cart
    productCart.forEach((localItem) => {
      const fetchedItem = fetchedCart.find(
        (item) => item.experience_id === localItem.experience_id
      )
      if (
        !fetchedItem ||
        localItem.no_of_adults !== fetchedItem.no_of_adults ||
        localItem.no_of_children !== fetchedItem.no_of_children
      ) {
        discrepancies.push({
          experience_id: localItem.experience_id,
          user_type: 'user',
          no_of_adults: localItem.no_of_adults,
          no_of_children: localItem.no_of_children,
        })
      }
    })

    // Check for items in the fetched cart that are not in the local storage cart
    //Experiences do not allow you buy 2 at item at a time. Uncomment out when the API starts allowing 2 at once.
    productCart.forEach((fetchedItem) => {
      const localItem = fetchedCart.find(
        (item) => item.experience_id === fetchedItem.experience_id
      )

      if (!localItem && fetchedCart.length < 1) {
        discrepancies.push({
          experience_id: fetchedItem.experience_id,
          user_type: 'user',
          no_of_adults: fetchedItem.no_of_adults,
          no_of_children: fetchedItem.no_of_children,
        })
      }
    })
    for (let discrepancy of discrepancies) {
      try {
        await axiosCalls(`experience-cart`, 'post', discrepancy)
      } catch (error) {
        console.error('Error sending discrepancy data:', error)
      }
    }
  }

  return (
    <CartContext.Provider
      value={{
        cartDetails,
        getCart,
        addToCart,
        cartLoading,
        checkAuth,
        isLoggedIn,
        showLeftBar,
        setShowLeftBar,
        getAdminProfile,
        adminProfile,
        adminLoading,
        productCategory,
        productCategoryLoading,
        fetchLocalCart,
        localCarts,
        checkDiscrepancies,
        productCart,
        fetchLocalExperienceCart,
        localExperienceCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const CartContext = createContext()
