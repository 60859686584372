import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "users",
  initialState: {
    value: {
      basicDetails: null,
      themeCopy: {
        white: "#fff",
        black: "#000",
        grey: " #dadada",
        sidebarText: "#5a5b5d",
        graydark: "#666",
        baseBackGroundColor: "#faf9fa",
        secondaryColor: "#571d7a",
        activeTextColor: "#571d7a",
        secondaryColorLight: "#f1f0fd",
        SystemTextColor: "#717375",
        headerColorOne: "#41304b",
        green: " #12b76a",
      },
    },
    loading: false,
  },
  reducers: {
    updateBasicDetails: (state, payload) => {
      state.value = { ...state.value, basicDetails: payload.payload };
    },
  },

  extraReducers(builder) {},
});

export const { updateBasicDetails } = globalSlice.actions;
export default globalSlice.reducer;
