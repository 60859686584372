import React from 'react'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import LeftBar from '../../components/leftBar/LeftBar'
import RightForm from '../../components/rightForm/RightForm'
import backIconBlue from '../../assets/icon/backIconBlue.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'

import './addNewPersonalAddress.scss'
import AddressForm from '../../components/addressForm/addressFormCreate'

const EditAddress = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [edit, setEdit] = React.useState({
    full_name: '',
    phone_no: '',
    address: '',
    city_id: 1,
    state_id: 1,
  })
  const [loading, setloading] = React.useState(true)
  React.useEffect(() => {
    let p = localStorage.getItem('editAddress')
    let x = JSON.parse(p)
    let data = {
      full_name: x.full_name,
      phone_no: x.phone_no,
      address: x.address,
      city_id: x.city_id,
      state_id: x.state_id,
    }
    setEdit(data)
    setloading(false)
  }, [])
  return (
    <div>
      <Navbar />
      <div className='addAddress-checkout-add'>
        <LeftBar />

        <div className='addAddress-checkout-rightForm'>
          <div className='addAddress-over-goBack' onClick={() => navigate(-1)}>
            {/* <Link to="/account-checkout" style={{ textDecoration: "none" }}> */}
            <div className='addAddress-over-goBackImg'>
              <img src={backIconBlue} />
            </div>
            {/* </Link> */}
            <div className='addAddress-over-text'>Go Back</div>
          </div>
          {loading ? null : (
            <AddressForm
              formTitle='Add New Address'
              onSuccessSave={() => {
                navigate(-1)
              }}
              btnTitle='Save'
              mode='edit'
              id={params.id}
              edit={edit}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EditAddress
