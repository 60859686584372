import { createSlice } from "@reduxjs/toolkit";
// import { getProfile } from "../asyncCalls/auth";

export const userSlice = createSlice({
  name: "users",
  initialState: { loading: false, user: {}, isAuthenticated: false },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },

});

export const { setUser, setLoading, setAuthenticated } = userSlice.actions;
export default userSlice.reducer;
