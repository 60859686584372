import React from "react";
import vendorBackIcon from "../../../../assets/icon/vendorBackIcon.svg";
import vendorThreeDot from "../../../../assets/icon/vendorThreeDot.svg";
import { Link, useNavigate } from "react-router-dom";
import SingleVendorLeft from "./singleVendorLeft/SingleVendorLeft";
import SingleVendorRight from "./singleVendorRight/SingleVendorRight";

import "./singleCustomer.css";

import { useState } from "react";

const SingleCustomer = () => {
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div>
      <div className="singleVendor-topGen">
        <div className="singleVendor-backFam">
          <div onClick={() => navigate(-1)} className="singleVendor-backArrow">
            <img src={vendorBackIcon} />
          </div>
          <div className="singleVendor-backText">Customer Details</div>
        </div>
        <div onClick={toggleOptions} className="singleVendor-threeDot">
          <img src={vendorThreeDot} />
        </div>
      </div>

      {showOptions && (
        <div className="singleVendor-options">
          <div className="singleVendor-optionsEdit">Edit Detail</div>
          <div className="singleVendor-optionsDisab">Disable Vendor</div>
          <div className="singleVendor-optionsDel">Delete Vendor</div>
        </div>
      )}
      <div className="singleVendor-bigFlex">
        <SingleVendorLeft />

        <SingleVendorRight />
      </div>
    </div>
  );
};

export default SingleCustomer;
