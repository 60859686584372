import React, { useContext, useState } from 'react'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import './checkoutStyle.scss'
import { axiosCalls } from '../../_api'
import { Toast } from '../../helpers/toast'
import { setAuthenticated, setUser } from '../../reducers/auth'
import capitalize from '../../helpers/capitalize'
import { CartContext } from '../../context/cart-context'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { usePaystackPayment } from 'react-paystack'
import { useSelector } from 'react-redux'
import Ratings from '../../components/ratings/Ratings'
import { Icon } from '@iconify/react'

function ExperienceCheckout() {
  const allowed = localStorage.getItem('token')
  const userInfo = useSelector((state) => state)
  const dispatch = useDispatch()
  const { getCart, localExperienceCart } = useContext(CartContext)
  const [newUser, setNewUser] = useState(true)
  const [OTPSent, setOTPSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_no: '',
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const { first_name, last_name, email, phone_no } = formData

    // Simple form validation
    if (!first_name || !last_name || !email || !phone_no) {
      alert('All fields are required!')
      return
    }
    setLoading(true)
    const res = await axiosCalls('experience/register', 'POST', formData)
    if (res) {
      if (res.er) {
        setLoading(false)
        Toast('error', res.er.message)
        return
      }
      const otpRes = await axiosCalls('get-login-otp', 'POST', {
        email: formData.email,
      })
      if (otpRes) {
        if (otpRes.er) {
          Toast('error', otpRes.er.message)
          return
        }
        setLoading(false)
        setOTPSent(true)
      }
    }
  }
  //Otp
  const [otp, setOtp] = useState('')
  const [configData, setConfigData] = useState({})
  const [configFetched, setConfigFetched] = useState(false)

  const handleOtpChange = (e) => {
    setOtp(e.target.value)
  }
  async function checkOutExperience() {
    let emailUse = ''
    if (!allowed && newUser) {
      emailUse = formData.email
    } else if (email.length > 5) {
      emailUse = email
    } else if (userInfo?.auth?.user?.email) {
      emailUse = userInfo?.auth?.user?.email
    } else {
      emailUse = 'osasfavourene@gmail.com'
    }
    const res = await axiosCalls(`proceed-to-checkout`, 'post', {})
    if (res) {
      if (res.er) {
        return
      }
      const config = {
        reference: res.data.reference,
        email: emailUse,
        amount: parseInt(res.data?.amount_to_be_paid * 100),
        publicKey: 'pk_test_5d63ef54ed41265fd32396ddf37d383b3b5a8f3a',
      }
      setConfigData(config)
      setConfigFetched(true)
      setLoading(false)
    }
  }

  const handleVerify = async () => {
    if (!otp) {
      alert('OTP is required for verification')
      return
    }
    setLoading(true)
    let emailUse = ''
    if (newUser) {
      emailUse = formData.email
    } else {
      emailUse = email
    }
    const res = await axiosCalls('experience/login', 'POST', {
      email: emailUse,
      code: otp,
    })
    if (res) {
      if (res.er) {
        setLoading(false)
        Toast('error', res.er.message)
        return
      }
      localStorage.setItem('token', res.data.auth.token)
      dispatch(setUser({ ...res.data.profile, email: emailUse }))
      dispatch(setAuthenticated(true))
      Toast('success', `Welcome ${capitalize(res.data.profile.first_name)}`)
      await getCart()
      await checkOutExperience()
    }
  }
  const onSuccess = (reference) => {
    Toast('success', 'Experience Purchased')
    localStorage.setItem('experience_cart', JSON.stringify([]))
    setTimeout(() => {
      navigate('/experience-orders')
    }, 2000)
  }

  async function getOtp(params) {
    let emailUse = ''
    if (newUser) {
      emailUse = formData.email
    } else {
      emailUse = email
    }
    setOtp('')
    setLoading(true)
    const otpRes = await axiosCalls('get-login-otp', 'POST', {
      email: emailUse,
    })
    if (otpRes) {
      if (otpRes.er) {
        setLoading(false)
        Toast('error', otpRes.er.message)
        return
      }
      setLoading(false)
      Toast('success', 'OTP Sent')
    }
  }
  const onClose = () => {
  }
  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(configData)
    return (
      <div>
        <button
          onClick={() => {
            initializePayment(onSuccess, onClose)
          }}
        >
          Proceed to Pay
        </button>
      </div>
    )
  }
  //Login
  const [email, setEmail] = useState('')

  async function login() {
    setLoading(true)
    const otpRes = await axiosCalls('get-login-otp', 'POST', {
      email: email,
    })
    if (otpRes) {
      if (otpRes.er) {
        setLoading(false)
        Toast('error', otpRes.er.message)
        return
      }
      setLoading(false)
      setOTPSent(true)
    }
  }
  const calculateTotalPrice = () => {
    return localExperienceCart.reduce(
      (total, item) =>
        total +
        parseInt(item.adult_price) * item.no_of_adults +
        parseInt(item.child_price) * item.no_of_children,
      0
    )
  }
  return (
    <div className='experience-checkout'>
      <Navbar />
      <div className='forms'>
        <div className='left-top'>
          <div className='left-inner'>
            {allowed ? (
              <>
                {!configFetched ? (
                  <div
                    className='cart-item-btn-check'
                    onClick={() => {
                      checkOutExperience()
                    }}
                  >
                    Continue
                  </div>
                ) : (
                  <PaystackHookExample />
                )}
              </>
            ) : (
              <>
                <div className='heading'>
                  <h2>Contact details</h2>
                  <p>
                    We'll use this information to send you confirmation and
                    updates about your booking
                  </p>
                </div>
                {newUser ? (
                  <>
                    {OTPSent ? (
                      <>
                        {configFetched ? (
                          <PaystackHookExample />
                        ) : (
                          <>
                            <div className='flex dif'>
                              <div className='input-card'>
                                <p>OTP</p>
                                <input
                                  type='number'
                                  value={otp}
                                  onChange={handleOtpChange}
                                  required
                                />
                              </div>
                              <div className='input-button'>
                                <button
                                  onClick={handleVerify}
                                  disabled={loading}
                                >
                                  {loading ? 'Loading...' : 'Verify'}
                                </button>
                              </div>
                            </div>
                            <div className='texts'>
                              <p
                                onClick={() => getOtp()}
                                style={{ cursor: 'pointer' }}
                              >
                                Resend OTP.
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <div className='flex'>
                          <div className='input-card'>
                            <p>First Name</p>
                            <input
                              type='text'
                              name='first_name'
                              value={formData.first_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className='input-card'>
                            <p>Last Name</p>
                            <input
                              type='text'
                              name='last_name'
                              value={formData.last_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='flex'>
                          <div className='input-card'>
                            <p>Email</p>
                            <input
                              type='email'
                              name='email'
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className='input-card'>
                            <p>phone_no</p>
                            <input
                              type='tel'
                              name='phone_no'
                              value={formData.phone_no}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='button'>
                          <button type='submit' disabled={loading}>
                            {loading ? 'Loading...' : 'Next'}
                          </button>
                        </div>
                        <div className='texts'>
                          <p
                            onClick={() => setNewUser(false)}
                            style={{ cursor: 'pointer' }}
                          >
                            Already have an email wit us? continue here.
                          </p>
                        </div>
                      </form>
                    )}
                  </>
                ) : (
                  <>
                    {!OTPSent ? (
                      <>
                        {' '}
                        <div className='flex dif'>
                          <div className='input-card'>
                            <p>Email Address</p>
                            <input
                              type='email'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div className='input-button'>
                            <button onClick={login} disabled={loading}>
                              {loading ? 'Loading...' : 'Next'}
                            </button>
                          </div>
                        </div>
                        <div className='texts'>
                          <p
                            onClick={() => setNewUser(true)}
                            style={{ cursor: 'pointer' }}
                          >
                            Email doesn't exist? continue here.
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <div className='flex dif'>
                          <div className='input-card'>
                            <p>OTP</p>
                            <input
                              type='number'
                              value={otp}
                              onChange={handleOtpChange}
                              required
                            />
                          </div>
                          <div className='input-button'>
                            <button onClick={handleVerify}>Verify</button>
                          </div>
                        </div>
                        <div className='texts'>
                          <p
                            onClick={() => getOtp()}
                            style={{ cursor: 'pointer' }}
                          >
                            Resend OTP.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className='right-top'>
          {localExperienceCart.map((item, i) => (
            <div
              className='event-card'
              key={i + 1}
              onClick={() => navigate(`/single-experience/${item.id}`)}
            >
              <div className='one'>
                <img src={item.image} alt='' />
              </div>
              <div className='two'>
                <div className='two-top'>
                  <div className='un'>
                    <h2>{item.name}</h2>
                  </div>
                  <div className='duo'>
                    <h3>
                      {calculateTotalPrice()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ExperienceCheckout
