import React, { useState } from "react";
import { GoCalendar } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import { OrdersModal } from "../Orders/components";
import { Table } from "./components";
import "./index.css";
import { Container } from "./styled";

const Customers = () => {
  const [showModal, setShowModal] = useState(false);

  function handleToggleModal() {
    setShowModal((prevState) => !prevState);
  }

  return (
    <div className="app-scroll">
      <Container>
        <header>
          <h1 className="u--typo__title2">Customers</h1>
          <div className="action--container">
            <div className="select--container">
              <select>
                <option>Filter by</option>
              </select>
            </div>
            <div className="select--container">
              <GoCalendar />
              <select>
                <option>This week</option>
              </select>
            </div>
            <button>
              <HiDownload />
              Export
            </button>
          </div>
        </header>
        <div className="customers__table">
          <Table handleToggleModal={handleToggleModal} />
        </div>
        <OrdersModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
        />
      </Container>
    </div>
  );
};

export default Customers;
