import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CartContext } from '../../context/cart-context'
import { Toast } from '../../helpers/toast'
import { axiosCalls } from '../../_api'
import { usePaystackPayment } from 'react-paystack'
import { hideLoader, showLoader } from '../../loader'

function ExperienceCartPage() {
  const auth = localStorage.getItem('token')
  const { localExperienceCart, fetchLocalExperienceCart } =
    useContext(CartContext)
  const calculateTotalPrice = () => {
    return localExperienceCart.reduce(
      (total, item) =>
        total +
        parseInt(item.adult_price) * item.no_of_adults +
        parseInt(item.child_price) * item.no_of_children,
      0
    )
  }
  const navigate = useNavigate()

  const removeFromCart = async (productId) => {
    // Filter out the product to be removed
    let productsCart = localExperienceCart
    productsCart = productsCart.filter(
      (item) => item.experience_id !== productId
    )
    localStorage.setItem('experience_cart', JSON.stringify(productsCart))
    fetchLocalExperienceCart()
    calculateTotalPrice()
    if (auth) {
      await axiosCalls(`remove-experience-cart/${productId}`, 'post')
    }
    Toast('success', 'Item removed')
  }
  function navigateToLogin() {
    Toast('warn', 'Login to continue')
    setTimeout(() => {
      navigate('/login?location=cart')
    }, 2000)
  }
  useEffect(() => {
    fetchLocalExperienceCart()
  }, [])

  const [configData, setConfigData] = useState({})
  const [configFetched, setConfigFetched] = useState(false)

  const onSuccess = (reference) => {
    Toast('success', 'Experience Purchased')
    localStorage.setItem('experience_cart', JSON.stringify([]))
    setTimeout(() => {
      navigate('/experience-orders')
    }, 2000)
  }

  const onClose = () => {}

  async function checkOutExperience() {
    showLoader()
    const res = await axiosCalls(`proceed-to-checkout`, 'post', {})
    if (res) {
      if (res.er) {
        return
      }
      const config = {
        reference: res.data.reference,
        email: 'favoureneosas@gmail.com',
        amount: parseInt(res.data?.amount_to_be_paid * 100),
        publicKey: 'pk_test_5d63ef54ed41265fd32396ddf37d383b3b5a8f3a',
      }
      setConfigData(config)
      setConfigFetched(true)
      hideLoader()
    }
  }
  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(configData)
    return (
      <div>
        <button
          onClick={() => {
            initializePayment(onSuccess, onClose)
          }}
        >
          Proceed to Pay
        </button>
      </div>
    )
  }

  return (
    <>
      <div className='cart-main'>
        <div className='left'>
          <div className='left-top'>
            <h2>Order Summary</h2>
          </div>
          <div className='left-rest'>
            {localExperienceCart.map((item) => (
              <div className='cart-card' key={item.experience_id}>
                <div className='one'>
                  <div className='un'>
                    <img src={item.image} alt='' />
                  </div>
                  <div className='duo'>
                    <div>
                      <h2>{item.name}</h2>
                      <h3>
                        Adult : ₦
                        {item.adult_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </h3>
                      <h3>
                        Child : ₦
                        {item.child_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </h3>
                    </div>
                    <p className='quantity'>
                      child Quantity: {item.no_of_children}
                    </p>
                    <p className='quantity'>
                      Adult Quantity: {item.no_of_adults}
                    </p>
                    <h4 onClick={() => removeFromCart(item.experience_id)}>
                      Remove
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='right'>
          <div className='cart-items-main-container'>
            <>
              <div className='cart-item-price-dis-fam'>
                <div className='cart-item-num-price'>
                  <div className='cart-num-item'>
                    <p>Items ({localExperienceCart.length}):</p>
                  </div>
                  <div className='cart-num-price'>
                    <p>
                      ₦
                      {calculateTotalPrice()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  </div>
                </div>
              </div>

              <div className='cart-item-total-fam'>
                <div className='cart-item-total-cont'>
                  <div className='cart-total-text'>
                    <p>Estimated Total</p>
                  </div>

                  <div className='cart-total-add'>
                    <p>
                      ₦
                      {calculateTotalPrice()
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  </div>
                </div>
              </div>
            </>

            <div className='cart-item-btn-fam'>
              {/* {items.length > 0 && (
					<div
						className='cart-item-btn-check'
						onClick={initiateGuestSession}
						style={{
							marginTop: '10px',
							marginBottom: '10px',
						}}
					>
						Guest Checkout
					</div>
				)} */}

              {/* {!configFetched ? (
                <div
                  className='cart-item-btn-check'
                  onClick={() => {
                    auth ? checkOutExperience() : navigate('/login')
                  }}
                >
                  Continue to Checkout
                </div>
              ) : (
                <PaystackHookExample />
              )} */}
              <div
                className='cart-item-btn-check'
                onClick={() => {
                  navigate('/experience-checkout')
                }}
              >
                Continue to Checkout
              </div>
              {/* <Link to='/checkout-delivery' style={{ textDecoration: 'none' }}>
                <div className='cart-item-btn-check'>Continue to Checkout</div>
              </Link> */}
              <Link to='/' style={{ textDecoration: 'none' }}>
                <div className='cart-item-btn-shopping'>Continue Shopping</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExperienceCartPage
