import React, { useState } from 'react'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'
import { axiosCalls } from '../../../../_api'
import { Toast } from '../../../../helpers/toast'
import Loader from '../../../../components/loader/Loader'

function DeleteParent({ setShowDel, showDel }) {
  const [loading, setLoading] = useState(false)
  async function handleSubmit() {
    setLoading(true)
    const data = {}
    const response = await axiosCalls(
      `admin/parent-category/${showDel.id}`,
      'delete',
      data
    )
    if (response) {
      setLoading(false)
      if (response.er) {
        Toast('error', response.er.message)
        return
      }
      Toast('success', 'Category deleted successfully.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  return (
    <ModalLayout type={'small'}>
      <div className='delete-modal'>
        <h1>Delete parent category</h1>
        <p>Are you sure you want to delete this category?</p>
        {loading ? (
          <Loader size={'small'} />
        ) : (
          <div className='flex'>
            <button className='grey' onClick={() => setShowDel(null)}>
              Cancel
            </button>
            <button className='delete' onClick={handleSubmit}>
              Delete
            </button>
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export default DeleteParent
