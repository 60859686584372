import React from "react";
import "./averageOrder.scss";
import AdminArrowUp from "../../../../../assets/icon/AdminArrowUp.svg";

const AverageOrder = () => {
  return (
    <div className="averageOrder-bodyGen">
      <div className="averageOrder-conversionText">Conversion Rate</div>

      <div className="averageOrder-recordFam">
        <div className="averageOrder-recordNum">6.37</div>
        <div className="averageOrder-recordPercent">+ 3.6%</div>
        <div className="averageOrder-recordArrow">
          <img src={AdminArrowUp} />
        </div>
      </div>

      <div className="averageOrder-detailsFam">
        <div className="averageOrder-detailsFlexO">
          <div className="averageOrder-detailsFlexCart">Added to cart</div>
          <div className="averageOrder-detailsFlexVis">5 visits</div>
        </div>
        <div className="averageOrder-detailsFlexT">7.04</div>
        <div className="averageOrder-detailsFlexTPer">
          <div className="averageOrder-detailsFlexTPerTT">+ 3.6%</div>
          <div className="averageOrder-detailsFlexTPerImg">
            <img src={AdminArrowUp} />
          </div>
        </div>
      </div>

      <div className="averageOrder-detailsFam">
        <div className="averageOrder-detailsFlexO">
          <div className="averageOrder-detailsFlexCart">
            Reached the checkout
          </div>
          <div className="averageOrder-detailsFlexVis">8 visits</div>
        </div>
        <div className="averageOrder-detailsFlexT">7.04</div>
        <div className="averageOrder-detailsFlexTPer">
          <div className="averageOrder-detailsFlexTPerTT">+ 3.6%</div>
          <div className="averageOrder-detailsFlexTPerImg">
            <img src={AdminArrowUp} />
          </div>
        </div>
      </div>

      <div className="averageOrder-detailsFam">
        <div className="averageOrder-detailsFlexO">
          <div className="averageOrder-detailsFlexCart">Purchased</div>
          <div className="averageOrder-detailsFlexVis">10 orders</div>
        </div>
        <div className="averageOrder-detailsFlexT">7.04</div>
        <div className="averageOrder-detailsFlexTPer">
          <div className="averageOrder-detailsFlexTPerTT">+ 3.6%</div>
          <div className="averageOrder-detailsFlexTPerImg">
            <img src={AdminArrowUp} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AverageOrder;
