import React, { useState, useEffect } from 'react'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'
import closeOrderModal from '../../../../assets/icon/closeOrderModal.svg'
import { FileUploader } from 'react-drag-drop-files'
import { Toast } from '../../../../helpers/toast'
import { axiosCalls } from '../../../../_api'
import { hideLoader, showLoader } from '../../../../loader'
import ImageUrl from '../../../../components/imageUrl/ImageUrl'

function AddSubCategories({ handleToggleNewModal, selectedData }) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [productCategory, setProductCategory] = useState('')
  const [thumbnailImage, setThumbnailImage] = useState({})
  const [bannerImage, setBannerImage] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [loading, setLoading] = useState(true)
  function handleThumbnailChange(params) {
    setThumbnailImage(params)
  }
  function handleBannerChange(params) {
    setBannerImage(params)
  }
  async function handleSubmit() {
    if (
      name.length < 1 &&
      description.length < 1 &&
      productCategory.length < 1 &&
      Object.keys(thumbnailImage).length < 1 &&
      Object.keys(bannerImage).length < 1
    ) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const formData = new FormData()
    const file = [thumbnailImage, bannerImage]
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      const data = {
        name: name,
        description: description,
        parent_category_id: productCategory,
        thumbnail_image: res.data[0],
        banner_image: res.data[1],
      }
      const response = await axiosCalls(
        `admin/parent-sub-category`,
        'post',
        data
      )
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Category saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  async function handleEditSubmit() {
    if (
      name.length < 1 &&
      description.length < 1 &&
      productCategory.length < 1 &&
      Object.keys(thumbnailImage).length < 1 &&
      Object.keys(bannerImage).length < 1
    ) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const formData = new FormData()
    const file = [thumbnailImage, bannerImage]
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      const data = {
        name: name,
        description: description,
        product_category_id: productCategory,
        thumbnail_image: res.data[0],
        banner_image: res.data[1],
      }
      const response = await axiosCalls(
        `admin/parent-sub-category/${selectedData.id}`,
        'put',
        data
      )
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Category saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  useEffect(() => {
    getCategories()
    if (selectedData) {
      setName(selectedData.name)
      setDescription(selectedData.description)
      setProductCategory(selectedData.product_category_id)
      if (selectedData.banner_image) {
        ImageUrl(selectedData.banner_image).then((fileData) => {
          setBannerImage(fileData)
        })
      }
      if (selectedData.thumbnail_image) {
        ImageUrl(selectedData.thumbnail_image).then((fileData) => {
          setThumbnailImage(fileData)
        })
      }
    }
  }, [])

  const getCategories = async () => {
    setLoading(true)

    const res = await axiosCalls(`admin/parent-category`, 'get')

    if (res) {
      setLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setCategoriesList([])
        return
      }
      setCategoriesList(res?.data.data ?? [])
      // Toast("success", "Addresses retrieved successfully");
      return
    }
    setCategoriesList([])
  }
  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>
          {selectedData ? 'Edit Sub Category' : 'New Sub Category'}
        </div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Description</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Parent category</div>
        <div className='newProductForm-NameInput'>
          <select
            name=''
            id=''
            onChange={(e) => setProductCategory(e.target.value)}
          >
            {loading ? (
              <option value=''>Loading</option>
            ) : (
              <>
                <option value=''>-select-</option>
                {categoriesList.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </>
            )}
          </select>
        </div>
      </div>
      <div className='newProductForm-ProdImgText'>Thumbnail Image</div>
      <div className='newProductForm-fileUploader'>
        <FileUploader
          handleChange={handleThumbnailChange}
          name='file'
          types={['JPEG', 'PNG', 'JPG']}
          label='Drop your images here'
        />
      </div>
      <div className='newProductForm-ProdImgText'>Banner Image</div>
      <div className='newProductForm-fileUploader'>
        <FileUploader
          multiple
          handleChange={handleBannerChange}
          name='file'
          types={['JPEG', 'PNG', 'JPG']}
          label='Drop your images here'
        />
      </div>
      <div className='newProductForm-SaveGen' style={{ marginTop: '30px' }}>
        {selectedData ? (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleEditSubmit()
            }}
          >
            Edit
          </div>
        ) : (
          <div
            className='newProductForm-SaveChanges'
            onClick={() => {
              handleSubmit()
            }}
          >
            Create
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export default AddSubCategories
