import React from 'react'
import { Icon } from '@iconify/react'
import './Rating.scss'

const Ratings = ({ average_rating }) => {
  const MAX_STARS = 5

  if (average_rating === null) {
    return (
      <div className='ratings-stars'>
        {Array(MAX_STARS)
          .fill(<Icon icon={'fa-regular:star'} />)
          .map((icon, index) => (
            <span key={`empty-${index}`}>{icon}</span>
          ))}
      </div>
    )
  }

  // Calculate the number of full, half, and empty stars
  const fullStars = Math.floor(average_rating)
  const halfStars = average_rating % 1 >= 0.5 ? 1 : 0
  const emptyStars = MAX_STARS - fullStars - halfStars

  return (
    <div className='ratings-stars'>
      {Array(fullStars)
        .fill(<Icon icon={'fa-solid:star'} />)
        .map((icon, index) => (
          <span key={`full-${index}`}>{icon}</span>
        ))}
      {Array(halfStars)
        .fill(<Icon icon={'fa6-solid:star-half-stroke'} />)
        .map((icon, index) => (
          <span key={`half-${index}`}>{icon}</span>
        ))}
      {Array(emptyStars)
        .fill(<Icon icon={'fa-regular:star'} />)
        .map((icon, index) => (
          <span key={`empty-${index}`}>{icon}</span>
        ))}
    </div>
  )
}

export default Ratings
