import React, { useState } from "react";
import { GoCalendar } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import SuccessScreen from "../components/successScreen/SuccessScreen";
import { OrdersModal, Table } from "./components";
import { NewProductModal } from "./components/newProductModal";
import { NewUsers } from "./components/newUsers";
import "./index.css";
import { Container } from "./styled";

const UserManagement = () => {
  const [showModal, setShowModal] = useState(false);

  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const [showNewModal, setShowNewModal] = useState(false);
  const [onSuccessLoad, setOnSuccessLoad] = useState(false);

  function handleToggleModal() {
    setShowModal((prevState) => !prevState);
  }

  function handleToggleNewModal() {
    setShowNewModal((prevState) => !prevState);
  }

  function handleToggleNewUserModal() {
    setShowNewUserModal((prevState) => !prevState);
  }

  const showSuccessPage = () => {
    setOnSuccessLoad(true);
  };

  const hideSuccessPage = () => {
    setOnSuccessLoad(false);
  };
  return (
    <div className="app-scroll">
      <Container>
        <header>
          <h1 className="u--typo__title2">User Management</h1>
          <div className="action--container">
            <div className="select--container">
              <select>
                <option>Filter by</option>
              </select>
            </div>

            {/* <GoCalendar />
              <select>
                <option>This week</option>
              </select> */}

            {/* <div className="product-exportBtn">
              <HiDownload />
              Export
            </div> */}

            <div
              onClick={handleToggleNewUserModal}
              className="products-newProductsbtn"
            >
              New User
            </div>
          </div>
        </header>
        <div className="orders__table">
          <Table
            handleToggleModal={handleToggleModal}
            // handleToggleNewModal={handleToggleNewModal}
          />
        </div>
        {/* <OrdersModal
          showModal={showModal}
          handleToggleModal={handleToggleModal}
        /> */}

        {/* <NewProductModal
          showNewModal={showNewModal}
          handleToggleNewModal={handleToggleNewModal}
        /> */}

        <NewUsers
          showNewUserModal={showNewUserModal}
          handleToggleNewUserModal={handleToggleNewUserModal}
          showSuccessPage={showSuccessPage}
        />
        {onSuccessLoad && (
          <SuccessScreen
            hideSuccessPage={hideSuccessPage}
            successText="You have successfully created a new user, details of the user has been sent to the email address provided"
          />
        )}
      </Container>
    </div>
  );
};

export default UserManagement;
