import React, { useState } from "react";
import { OrdersModal } from "../../../Orders/components";
import OrderActivity from "./orderActivity/OrderActivity";
import "./singleVendorRight.scss";
import SingleVendorRightProduct from "./SingleVendorRightProduct";

const SingleVendorRight = () => {
  const [showModal, setShowModal] = useState(false);

  function handleToggleModal() {
    setShowModal((prevState) => !prevState);
  }
  return (
    <div className="singleVendor-bigFlexTwo">
      <div className="singleVendorRight-Select">
        <div className="singleVendorRight-OrderActivity">Order Activity</div>
        <div className="singleVendorRight-ChildrenProf">Children Profile</div>
        <div className="singleVendorRight-Wishlist">Wishlist</div>
      </div>

      <OrderActivity handleToggleModal={handleToggleModal} />

      <OrdersModal
        showModal={showModal}
        handleToggleModal={handleToggleModal}
      />
    </div>
  );
};

export default SingleVendorRight;
