import React from "react";
import "./index.scss";
import searchIconNavbar from "../../assets/icon/searchIconNavbar.svg";
import adminBell from "../../assets/icon/adminBell.svg";
import adminArrowDown from "../../assets/icon/adminArrowDown.svg";
const Navbar = () => {
  return (
    <div className="navbar-admin">
      <div className="navbar-admin-col-1">
        {/* put input box */}

        <div className="navbar-admin-searchIcon">
          <img src={searchIconNavbar} />
        </div>

        <input type="text" placeholder="Search..." />
      </div>
      <div className="navbar-admin-col-2">
        <div className="navbar-admin-col2Bell">
          <div className="navbar-admin-col2BellIMgg">
            <img src={adminBell} />
          </div>
          <div className="navbar-admin-col2BellNot"></div>
        </div>
        <div className="navbar-admin-col2userImg"></div>
        <div className="navbar-admin-arrowDown">
          <img src={adminArrowDown} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
