import React from "react";

const DashboardIcon = ({ fill }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3333 15.6568H13.825L13.8333 8.67679L7.99833 2.68345L2.17167 8.64262L2.16667 15.6568H4.66667V9.82345C4.66667 9.36262 5.03917 8.99012 5.5 8.99012H10.5C10.96 8.99012 11.3333 9.36262 11.3333 9.82345V15.6568ZM9.66667 15.6568H6.33333V10.6568H9.66667V15.6568ZM15.02 7.47762L8.59583 0.907617C8.28167 0.586784 7.71833 0.586784 7.40417 0.907617L0.979167 7.47845C0.675 7.79095 0.5 8.22762 0.5 8.67678V15.6568C0.5 16.576 1.20583 17.3235 2.07333 17.3235H5.5H10.5H13.9258C14.7933 17.3235 15.5 16.576 15.5 15.6568V8.67678C15.5 8.22762 15.325 7.79095 15.02 7.47762Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
