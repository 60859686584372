import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CartContext } from '../../context/cart-context'
import { Toast } from '../../helpers/toast'
import { axiosCalls } from '../../_api'

function ProductCartPage() {
  const auth = localStorage.getItem('token')
  const { localCarts, fetchLocalCart } = useContext(CartContext)
  const calculateTotalPrice = () => {
    return localCarts.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    )
  }
  const navigate = useNavigate()

  const calculateTotalDiscountedPrice = () => {
    return localCarts.reduce(
      (total, item) => total + item.discounted_price * item.quantity,
      0
    )
  }
  const removeFromCart = async (productId) => {
    // Filter out the product to be removed
    let productsCart = localCarts
    productsCart = productsCart.filter((item) => item.product_id !== productId)
    localStorage.setItem('product_cart', JSON.stringify(productsCart))
    fetchLocalCart()
    calculateTotalPrice()
    calculateTotalDiscountedPrice()
    if (auth) {
      // const productToRemove = productCart.find(
      //   (item) => item.product_id === productId
      // )
      await axiosCalls(`cart/${productId}`, 'delete')
    }
    Toast('success', 'Item removed')
  }
  function navigateToLogin() {
    Toast('warn', 'Login to continue')
    setTimeout(() => {
      navigate('/login?location=cart')
    }, 2000)
  }
  return (
    <div className='cart-main'>
      <div className='left'>
        <div className='left-top'>
          <h2>Order Summary</h2>
        </div>
        <div className='left-rest'>
          {localCarts.map((item) => (
            <div className='cart-card' key={item.product_id}>
              <div className='one'>
                <div className='un'>
                  <img src={item.image} alt='' />
                </div>
                <div className='duo'>
                  <div className='flex-price'>
                    <h2>{item.name}</h2>
                    <h3>
                      ₦
                      {item.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </h3>
                  </div>

                  <p
                    className={`stock ${
                      item.inventory_status === 'In stock' ? 'in' : 'out'
                    }`}
                  >
                    {item.inventory_status}
                  </p>
                  <p className='quantity'>Quantity: {item.quantity}</p>
                  <h4 onClick={() => removeFromCart(item.product_id)}>
                    Remove
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='right'>
        <div className='cart-items-main-container'>
          <>
            <div className='cart-item-price-dis-fam'>
              <div className='cart-item-num-price'>
                <div className='cart-num-item'>
                  <p>Items ({localCarts.length}):</p>
                </div>
                <div className='cart-num-price'>
                  <p>
                    ₦
                    {calculateTotalPrice()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                </div>
              </div>

              <div className='cart-item-num-discount'>
                <div className='cart-discount-item'>
                  <p>Order Discounts:</p>
                </div>
                <div className='cart-discount-price'>
                  <p>
                    ₦
                    {calculateTotalDiscountedPrice()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                </div>
              </div>
            </div>

            <div className='cart-item-shipping-fam'>
              <div className='cart-item-shipping-cont'>
                <div className='cart-shipping-text'>
                  <p>Estimated Shipping</p>
                </div>
                <div className='cart-shipping-add'>
                  {/* <p>Add your Delivery address at checkout to see delivery charges</p> */}
                  <p>₦0</p>
                </div>
              </div>
            </div>

            <div className='cart-item-total-fam'>
              <div className='cart-item-total-cont'>
                <div className='cart-total-text'>
                  <p>Estimated Total</p>
                </div>

                <div className='cart-total-add'>
                  <p>
                    ₦
                    {calculateTotalPrice()
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                </div>
              </div>
            </div>
          </>

          <div className='cart-item-btn-fam'>
            {/* {items.length > 0 && (
					<div
						className='cart-item-btn-check'
						onClick={initiateGuestSession}
						style={{
							marginTop: '10px',
							marginBottom: '10px',
						}}
					>
						Guest Checkout
					</div>
				)} */}
            <div
              className='cart-item-btn-check'
              onClick={() => {
                auth ? navigate('/checkout-delivery') : navigateToLogin()
              }}
            >
              Continue to Checkout
            </div>
            {/* <Link to='/checkout-delivery' style={{ textDecoration: 'none' }}>
                <div className='cart-item-btn-check'>Continue to Checkout</div>
              </Link> */}
            <Link to='/' style={{ textDecoration: 'none' }}>
              <div className='cart-item-btn-shopping'>Continue Shopping</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCartPage
