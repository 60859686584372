import React, { useState } from 'react'
import ModalLayout from '../../../../components/layout/modalLayout/ModalLayout'
import closeOrderModal from '../../../../assets/icon/closeOrderModal.svg'
import { FileUploader } from 'react-drag-drop-files'
import { Toast } from '../../../../helpers/toast'
import { axiosCalls } from '../../../../_api'
import { hideLoader, showLoader } from '../../../../loader'

function AddColor({ handleToggleNewModal }) {
  const [name, setName] = useState('')

  async function handleSubmit() {
    if (name.length < 1) {
      Toast('error', 'All fields are required ')
      return
    }
    showLoader()
    const data = {
      name: name,
    }
    const response = await axiosCalls(`admin/color`, 'post', data)
    if (response) {
      hideLoader()
      if (response.er) {
        Toast('error', response.er.message)
        return
      }
      Toast('success', 'Color saved successfully.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>New Color</div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-SaveGen' style={{ marginTop: '30px' }}>
        <div
          className='newProductForm-SaveChanges'
          onClick={() => {
            handleSubmit()
          }}
        >
          Create
        </div>
      </div>
    </ModalLayout>
  )
}

export default AddColor
