// import { Table } from "@material-ui/core";
import React from "react";
import { Table } from "./Table";

const OrderActivity = ({ handleToggleModal }) => {
  return (
    <div>
      <Table handleToggleModal={handleToggleModal} />
    </div>
  );
};

export default OrderActivity;
