import React from 'react';
import { useNavigate } from 'react-router-dom';
import './checkoutComTwo.scss';
import addressIcon from '../../assets/icon/addressIcon.svg';
import addTickIcon from '../../assets/icon/addTickIcon.svg';
import backArrow from '../../assets/icon/backArrow.svg';
import { PaystackButton } from 'react-paystack';

const CheckoutComTwo = ({
	openLoginModalBtn,
	// goBackHandleBtn,
	setTab,
	address,
	setselectedAddres,
	selectedAddres,
	allAddress,
	handleOnSuccess,
	handleOnClose,
	cartSummary,
	user,
}) => {
	const navigate = useNavigate();

	return (
		<div className='checkout-add-cont-main1'>
			<div className='checkout-back-btn-fam' onClick={() => navigate(-1)}>
				<div className='checkout-back-icon'>
					<img src={backArrow} alt='back-arrow' />
				</div>
				<div className='checkout-add-header'>
					<h2>Checkout</h2>
				</div>
			</div>
			<div className='checkout-add-cont-one'>
				<div className='checkout-delivery-text2'>
					<h3>Delivery Address</h3>
				</div>

				<div className='address-selection'>
					<div
						className='address-selection-add-new'
						onClick={() => {
							setTab(2);
						}}
						style={allAddress.length === 0 ? { width: '100%' } : {}}
					>
						<div>
							<img src={addressIcon} />
						</div>
						<div className='address-selection-add-text'>Add New Address</div>
					</div>

					{allAddress.length === 0 || allAddress === undefined ? null : (
						<div className='address-selection-default'>
							<div className='address-selection-userName'>
								<p>{address?.full_name}</p>
							</div>
							<div className='selection-location-address'>
								<p>{address?.address}</p>
							</div>
							<div className='selection-location-phone'>
								<p>Phone number</p>
							</div>
							<div className='selection-location-phone-digit'>
								<p>{address?.phone_no}</p>
							</div>
							<div className='selection-location-option'>
								{/* <div className="selection-remove">
                {" "}
                <p>Remove</p>
              </div> */}
								{/* <div className="selection-edit">
                <p>Edit</p>
              </div> */}

								<div
									className='selection-change-address'
									style={{ marginLeft: '0px' }}
									onClick={openLoginModalBtn}
								>
									<p>Change Address</p>
								</div>
							</div>

							<div className='selection-tick-icon'>
								<img src={addTickIcon} />
							</div>
						</div>
					)}
				</div>

				{allAddress.length === 0 ? null : (
					<div className='paymentTotalbtn'>
						<PaystackButton
							reference={new Date().getTime().toString()}
							email={user?.email ?? 'andy@gmail.com'}
							amount={parseInt(cartSummary?.estimated_total * 100)}
							publicKey={'pk_test_5d63ef54ed41265fd32396ddf37d383b3b5a8f3a'}
							text='PROCEED TO PAYMENT'
							onSuccess={handleOnSuccess}
							onClose={handleOnClose} // Optional
							className='font-main'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CheckoutComTwo;
