import React from "react";
import "./emailSettings.scss";

const EmailSettings = () => {
  return (
    <div>
      <div className="emailSettings-headText">Email Address </div>
      <div className="emailSettings-headBorder"></div>
      <div className="emailSettings-BodyFlex">
        <div className="emailSettings-formText">
          {" "}
          Change and update your email address
        </div>
        <div className="emailSettings-FirstFormBody">
          <div className="emailSettings-FirstFormLabel">Email Address</div>
          <div className="emailSettings-FirstFormInput">
            <input type="email" />
          </div>
        </div>

        <div className="emailSettings-SecondFormBody"></div>
      </div>

      <div className="emailSettings-BodyFlex">
        <div className="emailSettings-formText"></div>
        <div className="emailSettings-FirstFormBody">
          <div
            className="emailSettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          >
            Password
          </div>
          <div className="emailSettings-FirstFormInput">
            <input type="password" />
          </div>
        </div>

        <div className="emailSettings-SecondFormBody">
          <div
            className="emailSettings-FirstFormLabel"
            style={{ marginTop: "10px" }}
          ></div>
        </div>
      </div>

      <div className="emailSettings-BodyFlex">
        <div className="emailSettings-formText"></div>
        <div className="emailSettings-SaveBtnBody">
          <div
            className=" emailSettings-FirstFormBtn"
            style={{ marginTop: "10px" }}
          >
            Save Changes
          </div>
        </div>
        <div className="emailSettings-SecondFormBody"></div>
      </div>
    </div>
  );
};

export default EmailSettings;
