import React from "react";

const UserIcon = ({ fill }) => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 16C3.58159 16 0 12.4184 0 8C0 3.58159 3.58159 0 8 0C12.4184 0 16 3.58159 16 8C16 12.4184 12.4184 16 8 16ZM8 0.870295C4.08368 0.870295 0.870295 4.08368 0.870295 8C0.870295 11.9163 4.08368 15.1297 8 15.1297C11.9163 15.1297 15.1297 11.9163 15.1297 8C15.1297 4.08368 11.9163 0.870295 8 0.870295Z"
          fill={fill}
        />
        <path
          d="M3.31384 13.7908L2.5105 13.4226C2.74481 12.8536 3.48121 12.5523 4.31803 12.1841C5.12138 11.8159 6.12556 11.3808 6.12556 10.8452V10.1088C5.8243 9.87451 5.35568 9.33894 5.28874 8.56907C5.05443 8.33476 4.68623 7.89962 4.68623 7.36405C4.68623 7.02932 4.82012 6.76154 4.92054 6.59418C4.85359 6.22597 4.7197 5.48957 4.7197 4.92053C4.7197 3.07953 5.99167 1.87451 8.00004 1.87451C8.56908 1.87451 9.272 2.04187 9.6402 2.44354C10.544 2.61091 11.2804 3.68204 11.2804 4.92053C11.2804 5.72388 11.1465 6.39334 11.0461 6.69459C11.1465 6.86196 11.2469 7.09627 11.2469 7.36405C11.2469 8.00003 10.9456 8.40171 10.6444 8.60254C10.5774 9.37242 10.1423 9.87451 9.87452 10.1088V10.8452C9.87452 11.3138 10.7113 11.6151 11.4812 11.9164C12.385 12.2511 13.3222 12.5858 13.6235 13.3557L12.7867 13.6569C12.6528 13.2553 11.8494 12.9875 11.18 12.7197C10.1423 12.3515 8.97075 11.9164 8.97075 10.8452V9.6402L9.17159 9.50631C9.20506 9.50631 9.7741 9.10464 9.7741 8.33476V8.03351L10.0419 7.93309C10.0754 7.89961 10.3431 7.76572 10.3431 7.36405C10.3431 7.23016 10.2427 7.09627 10.2092 7.06279L10.0419 6.86196L10.1088 6.62765C10.1088 6.62765 10.3431 5.89124 10.3431 4.88706C10.3431 4.05024 9.87452 3.28036 9.37242 3.28036H9.10464L8.97075 3.04606C8.87033 2.8787 8.46866 2.71133 7.93309 2.71133C6.42682 2.71133 5.52305 3.51467 5.52305 4.88706C5.52305 5.52304 5.75736 6.5607 5.75736 6.5607L5.8243 6.79501L5.65694 6.96237C5.65694 6.96237 5.52305 7.12974 5.52305 7.2971C5.52305 7.53141 5.8243 7.83267 5.95819 7.96656L6.12556 8.10045V8.33476C6.12556 9.07116 6.76154 9.47283 6.76154 9.47283L6.96238 9.60673V10.8117C6.96238 11.9163 5.72388 12.4854 4.61928 12.954C4.15066 13.2218 3.44773 13.5565 3.31384 13.7908Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default UserIcon;
