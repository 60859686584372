import React, { useState, useEffect } from 'react'
import Navbar from '../../components/layout/navbar'
import './EventSearch.scss'
import SearchBar from './SearchBar'
import { Icon } from '@iconify/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { axiosCalls } from '../../_api'
import Loader from '../../components/loader/Loader'
import Ratings from '../../components/ratings/Ratings'
import BlankImage from '../../assets/images/blank.png'

function EventSearch() {
  const [showSideBar, setShowSideBar] = useState(false)
  const location = useLocation()

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search)

  const city = queryParams.get('city')
  const country = queryParams.get('country')
  const date = queryParams.get('date')
  const [eventData, setEventData] = useState({})
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const getAdditions = async () => {
    setLoading(true)
    let url = ''
    if (date) {
      url = `search/experiences?city=${city}&country=${country}&date=${date}`
    } else {
      url = `search/experiences?city=${city}&country=${country}`
    }
    const res = await axiosCalls(url, 'get')
    if (res) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
      if (res.er) {
        return
      }
      setEventData(res)
    }
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getAdditions()
  }, [])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [filterDate, setFilterDate] = useState('')

  return (
    <div className='eventSearch'>
      <Navbar />
      <div className='search-top'>
        <h1>
          Showing {eventData.length} results for{' '}
          <span>
            "{selectedCity.label}, {selectedCountry.label}"
          </span>
        </h1>
      </div>
      <div className='filter' onClick={() => setShowSideBar(!showSideBar)}>
        <Icon icon={'mage:filter'} />
      </div>
      {loading ? (
        <Loader align={'middle'} />
      ) : (
        <div className='search-layout'>
          <div className={showSideBar ? 'left active' : 'left'}>
            <SearchBar
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              filterDate={filterDate}
              setFilterDate={setFilterDate}
              city={city}
              country={country}
              date={date}
              setLoading={setLoading}
              setEventData={setEventData}
            />
          </div>
          <div className='right'>
            {eventData.length > 0 ? (
              <>
                {' '}
                {eventData.map((item, i) => (
                  <div
                    className='event-card'
                    key={i + 1}
                    onClick={() => navigate(`/single-experience/${item.id}`)}
                  >
                    <div className='one'>
                      <img src={item.main_image} alt='' />
                    </div>
                    <div className='two'>
                      <div className='two-top'>
                        <div className='un'>
                          <h2>{item.name}</h2>
                        </div>
                        <div className='duo'>
                          <h3>{parseInt(item.child_price).toLocaleString()}</h3>
                        </div>
                      </div>
                      <div className='stars'>
                        <Ratings average_rating={null} />
                      </div>
                      <div className='info'>
                        <p>{item.description}</p>
                      </div>
                      <div className='duration'>
                        <Icon icon={'mdi:clock-outline'} />{' '}
                        <p>{item.duration}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className='empty'>
                <img src={BlankImage} alt='' />
                <h2>No Experience found.</h2>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default EventSearch
