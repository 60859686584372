import React from 'react'
import { useState } from 'react'
import bubbleColonyLogoWhite from '../../assets/icon/bubbleColonyLogoWhite.svg'
import CustomersIcon from '../../assets/icon/CustomersIcon'
import DashboardIcon from '../../assets/icon/DashboardIcon'

import dashboardIcon from '../../assets/icon/dashboardIcon.svg'
import OrdersIcon from '../../assets/icon/OrdersIcon'
import ProductsIcon from '../../assets/icon/ProductsIcon'
import ReportIcon from '../../assets/icon/ReportIcon'
import TransactionIcon from '../../assets/icon/TransactionIcon'
import UserMana from '../../assets/icon/UserMana'
import VendorIcon from '../../assets/icon/VendorIcon'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { TbBuildingCircus } from 'react-icons/tb'
import { CiLogout, CiSettings } from 'react-icons/ci'
import { Icon } from '@iconify/react'

import './index.scss'
import logout from '../../helpers/logout'

const Sidebar = () => {
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')
  const navigate = useNavigate()
  const [showExtras, setShowExtras] = useState('false')
  const adminRole = localStorage.getItem('role')
  return (
    <div className='admin_layout-sidebar'>
      <div className='admin_layout-sidebarLogo'>
        <img src={bubbleColonyLogoWhite} />
      </div>
      <div className='admin_layout-sidebarOptGene'>
        <Link
          to='/admin/dashboard/home'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            {/* <img src={dashboardIcon} /> */}
            <DashboardIcon
              fill={splitLocation[3] === 'home' ? '#ffffff' : '#979c9e'}
            />
          </div>
          <div
            className='admin_layout-sidebarOptText'
            style={{
              color: splitLocation[3] === 'home' ? '#ffffff' : '#979c9e',
            }}
          >
            Dashboard
          </div>
        </Link>

        <Link
          to='/admin/dashboard/orders'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            {/* <img src={dashboardIcon} /> */}

            <OrdersIcon
              fill={splitLocation[3] === 'orders' ? '#ffffff' : '#979c9e'}
            />
          </div>
          <div
            style={{
              color: splitLocation[3] === 'orders' ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Orders
          </div>
        </Link>
        <Link
          to='/admin/dashboard/experiences'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            {/* <img src={dashboardIcon} /> */}

            <TbBuildingCircus
              style={{
                color:
                  splitLocation[3] === 'experiences' ? '#ffffff' : '#979c9e',
              }}
            />
          </div>
          <div
            style={{
              color: splitLocation[3] === 'experiences' ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Experiences
          </div>
        </Link>

        <Link
          to='/admin/dashboard/products'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            <ProductsIcon
              fill={splitLocation[3] === 'products' ? '#ffffff' : '#979c9e'}
            />
          </div>
          <div
            style={{
              color: splitLocation[3] === 'products' ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Products
          </div>
        </Link>

        <Link
          to='/admin/dashboard/transactions'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            <TransactionIcon
              fill={splitLocation[3] === 'transactions' ? '#ffffff' : '#979c9e'}
            />
          </div>
          <div
            style={{
              color:
                splitLocation[3] === 'transactions' ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Transactions
          </div>
        </Link>
        {adminRole === 'Admin' && (
          <>
            <Link
              to='/admin/dashboard/report'
              className='admin_layout-sidebarOptFam'
              style={{ textDecoration: 'none' }}
            >
              <div className='admin_layout-sidebarOptImg'>
                <ReportIcon
                  fill={splitLocation[3] === 'report' ? '#ffffff' : '#979c9e'}
                />
              </div>
              <div
                style={{
                  color: splitLocation[3] === 'report' ? '#ffffff' : '#979c9e',
                }}
                className='admin_layout-sidebarOptText'
              >
                Report
              </div>
            </Link>
            <div className='cover-drop'>
              <div
                className='admin_layout-sidebarOptFam'
                onClick={() => setShowExtras(!showExtras)}
              >
                <div className='admin_layout-sidebarOptImg'>
                  <Icon
                    style={{
                      color: !showExtras ? '#ffffff' : '#979c9e',
                    }}
                    icon='codicon:settings'
                  ></Icon>
                </div>
                <div
                  style={{
                    color: !showExtras ? '#ffffff' : '#979c9e',
                  }}
                  className='admin_layout-sidebarOptText'
                >
                  Attribute Options
                  <Icon
                    style={{
                      color: !showExtras ? '#ffffff' : '#979c9e',
                    }}
                    icon={
                      showExtras ? 'mingcute:down-fill' : 'mingcute:up-fill'
                    }
                  ></Icon>
                </div>
              </div>
              {!showExtras && (
                <ul>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'product-category'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() =>
                      navigate('/admin/dashboard/product-category')
                    }
                  >
                    <Icon icon='ant-design:product-outlined'></Icon>
                    <p>Product Category</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'parent-category'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() => navigate('/admin/dashboard/parent-category')}
                  >
                    <Icon icon='ant-design:product-outlined'></Icon>
                    <p>Parent Category</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'parent-sub-category'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() =>
                      navigate('/admin/dashboard/parent-sub-category')
                    }
                  >
                    <Icon icon='ant-design:product-outlined'></Icon>
                    <p>Parent Sub Category</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'colours' ? '#ffffff' : '#979c9e',
                    }}
                    onClick={() => navigate('/admin/dashboard/colours')}
                  >
                    <Icon icon='vaadin:palete'></Icon>
                    <p>Product Colours</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'gift-shops'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() => navigate('/admin/dashboard/gift-shops')}
                  >
                    <Icon icon='guidance:gift-shop'></Icon>
                    <p>Gift Shops</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'event-category'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() => navigate('/admin/dashboard/event-category')}
                  >
                    <Icon icon='ant-design:product-outlined'></Icon>
                    <p>Event Category</p>
                  </li>
                  <li
                    style={{
                      color:
                        splitLocation[3] === 'event-sub-category'
                          ? '#ffffff'
                          : '#979c9e',
                    }}
                    onClick={() =>
                      navigate('/admin/dashboard/event-sub-category')
                    }
                  >
                    <Icon icon='ant-design:product-outlined'></Icon>
                    <p>Event Sub Category</p>
                  </li>
                </ul>
              )}
            </div>
            <Link
              to='/admin/dashboard/vendors'
              className='admin_layout-sidebarOptFam'
              style={{ textDecoration: 'none' }}
            >
              <div className='admin_layout-sidebarOptImg'>
                <VendorIcon
                  fill={splitLocation[3] === 'vendors' ? '#ffffff' : '#979c9e'}
                />
              </div>
              <div
                style={{
                  color: splitLocation[3] === 'vendors' ? '#ffffff' : '#979c9e',
                }}
                className='admin_layout-sidebarOptText'
              >
                Vendors
              </div>
            </Link>
            <Link
              to='/admin/dashboard/customers'
              className='admin_layout-sidebarOptFam'
              style={{ textDecoration: 'none' }}
            >
              <div className='admin_layout-sidebarOptImg'>
                <CustomersIcon
                  fill={
                    splitLocation[3] === 'customers' ? '#ffffff' : '#979c9e'
                  }
                />
              </div>
              <div
                style={{
                  color:
                    splitLocation[3] === 'customers' ? '#ffffff' : '#979c9e',
                }}
                className='admin_layout-sidebarOptText'
              >
                Customers
              </div>
            </Link>
            <Link
              to='/admin/dashboard/usermanagement'
              className='admin_layout-sidebarOptFam'
              style={{ textDecoration: 'none' }}
            >
              <div className='admin_layout-sidebarOptImg'>
                <UserMana
                  fill={
                    splitLocation[3] === 'usermanagement'
                      ? '#ffffff'
                      : '#979c9e'
                  }
                />
              </div>
              <div
                style={{
                  color:
                    splitLocation[3] === 'usermanagement'
                      ? '#ffffff'
                      : '#979c9e',
                }}
                className='admin_layout-sidebarOptText'
              >
                User Management
              </div>
            </Link>
          </>
        )}
        <Link
          to='/admin/dashboard/settings'
          className='admin_layout-sidebarOptFam'
          style={{ textDecoration: 'none' }}
        >
          <div className='admin_layout-sidebarOptImg'>
            <CiSettings
              fill={splitLocation[3] === 'home' ? '#ffffff' : '#979c9e'}
            />
          </div>
          <div
            style={{
              color: splitLocation[3] === 'settings' ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Settings
          </div>
        </Link>
        <div className='admin_layout-sidebarOptFam' onClick={logout}>
          <div className='admin_layout-sidebarOptImg'>
            <Icon
              style={{
                color: !showExtras ? '#ffffff' : '#979c9e',
              }}
              icon='solar:logout-3-broken'
            ></Icon>
          </div>
          <div
            style={{
              color: !showExtras ? '#ffffff' : '#979c9e',
            }}
            className='admin_layout-sidebarOptText'
          >
            Log Out
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
