import React, { useState, useEffect } from 'react'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import './index.css'
import { axiosCalls } from '../../../../_api'

const Pallets = () => {
  const [loading1, setLoading1] = useState(true)
  const [adminHomeInfo, setadminHomeInfo] = useState([])

  useEffect(() => {
    getAdminHomeInfo()
  }, [])

  const getAdminHomeInfo = async () => {
    setLoading1(true)
    const res = await axiosCalls('admin/admin-dashboard', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setadminHomeInfo(res?.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }

  return (
    <div className='palleteHomeCards'>
      <div className='palleteHomeCardsChild'>
        <div className='palleteHomeCardsChildTotal-Rev-col1'>
          <p>Total Revenue</p>
        </div>
        <div className='palleteHomeCardsChildTotal-Rev-col2'>
          <h2>{adminHomeInfo?.total_sales_value}</h2>
          <p>
            <span>+ 1%</span>
            <BiUpArrowAlt className='palleteHomeCardsChildTotal-Rev-col2-iconSuc' />
          </p>
        </div>
      </div>

      <div className='palleteHomeCardsChild'>
        <div className='palleteHomeCardsChildTotal-Rev-col1'>
          <p>Total Orders</p>
        </div>
        <div className='palleteHomeCardsChildTotal-Rev-col2'>
          <h2>{adminHomeInfo?.total_sales_volume}</h2>
          <p>
            <span>+ 1%</span>
            <BiDownArrowAlt className='palleteHomeCardsChildTotal-Rev-col2-iconfail' />
          </p>
        </div>
      </div>

      <div className='palleteHomeCardsChild'>
        <div className='palleteHomeCardsChildTotal-Rev-col1'>
          <p>Total Vendors</p>
        </div>
        <div className='palleteHomeCardsChildTotal-Rev-col2'>
          <h2>{adminHomeInfo?.total_vendors}</h2>
          <p>
            <span>+ 1%</span>
            <BiUpArrowAlt className='palleteHomeCardsChildTotal-Rev-col2-iconSuc' />
          </p>
        </div>
      </div>
    </div>
  )
}

export default Pallets
