import React, { useRef, useEffect, useState, useContext } from 'react'
import './style.scss'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import { Icon } from '@iconify/react'
import Ratings from '../../components/ratings/Ratings'
import SliderComp from '../../components/slider/Slider'
import SimilarExperiences from './components/SimilarExperiences'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosCalls } from '../../_api'
import Loader from '../../components/loader/Loader'
import { CartContext } from '../../context/cart-context'
import { getCurrencySignByShort } from '../../helpers/currencyUtilis'
import { Toast } from '../../helpers/toast'
import { hideLoader, showLoader } from '../../loader'
import InfoModal from '../../components/infoModal/InfoModal'
import TimelineItem from './components/TimelineItem'
import AlsoBought from './components/AlsoBought'
import MainImages from './components/MainImages'
import Slider from 'react-slick'

function NewSingleEvent() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [selectedImage, setSelectedImage] = useState('')
  const [included, setIncluded] = useState(true)
  const [meet, setMeet] = useState(true)
  const [expect, setExpect] = useState(true)
  const [additional, setAdditional] = useState(true)
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState({})
  const [similarLoading, setSimilarLoading] = useState(true)
  const [similarEvents, setSimilarEvents] = useState([])
  const [showUserSelect, setShowUserSelect] = useState(false)
  const reviews = {
    totalStars: 48,
    starCount: {
      5: 40,
      4: 0,
      3: 3,
      2: 5,
      1: 0,
    },
  }

  const [date, setDate] = useState(new Date())

  const containerRef = useRef(null)

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowUserSelect(false) // Close the element if clicked outside
      }
    }

    // Attach event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [showLowestPriceGuarantee, setShowLowestPriceGuarantee] =
    useState(false)
  const getProduct = async () => {
    const res = await axiosCalls(`single-experience/${id}`, 'get')
    if (res) {
      setLoading(false)
      if (res.er) {
        return
      }
      console.log(res.data[0])
      setEvent(res.data[0])
      setSelectedImage(JSON.parse(res.data[0].images))
      setLoading(false)
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('token')
    const tokenGuest = localStorage.getItem('bubble_guest_code')
    if (token || tokenGuest) {
      setAuth(true)
    } else {
      // navigate("/cart-page");
      setAuth(false)
    }
    getProduct()
  }, [])
  const getSimilarEvent = async () => {
    setSimilarLoading(true)
    const res = await axiosCalls(
      `experience-under-a-category/${event.experience_category_id}`,
      'get'
    )

    if (res) {
      if (res.er) {
        return
      }
      setSimilarEvents(res.data)
      // setSimilarEvents(res.data.filter((item) => item.id !== event.id))
      setSimilarLoading(false)
    }
  }
  useEffect(() => {
    if (!loading) {
      getSimilarEvent()
    }
  }, [loading])
  const convertTo12HourFormat = (time) => {
    let [hours, minutes] = time.split(':').map(Number)
    const amOrPm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12 || 12 // Convert hour from 24-hour to 12-hour format
    return `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`
  }
  //Add to cart <------------------->
  const { fetchLocalExperienceCart, checkAuth, isLoggedIn } =
    useContext(CartContext)
  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)
  function totalPrice() {
    let adult = adultCount * event.adult_price
    let child = childCount * event.child_price
    const data = adult + child
    return data.toLocaleString()
  }
  useEffect(() => {
    checkAuth()
  }, [isLoggedIn])
  const addToCart = async () => {
    let newNum = childCount + adultCount
    if (newNum < 1) {
      Toast('error', 'Select at least 1 ticket to purchase.')
      return
    }
    const localData = {
      name: event.name,
      experience_id: event.id,
      user_type: 'user',
      no_of_adults: adultCount,
      no_of_children: childCount,
      adult_price: event.adult_price,
      child_price: event.child_price,
      image: event.main_image,
      description: event.description,
      duration: event.duration,
    }
    let experienceCart =
      JSON.parse(localStorage.getItem('experience_cart')) || []
    const existingExperienceIndex = experienceCart.findIndex(
      (item) => item.experience_id === event.id
    )
    if (existingExperienceIndex !== -1) {
      experienceCart[existingExperienceIndex].no_of_adults += adultCount
      experienceCart[existingExperienceIndex].no_of_children += childCount
    } else if (experienceCart.length < 1) {
      experienceCart.push(localData)
    } else {
      Toast('error', 'An experience already exists in your cart.')
    }
    localStorage.setItem('experience_cart', JSON.stringify(experienceCart))
    fetchLocalExperienceCart()
    const data = {
      experience_id: event.id,
      user_type: 'user',
      no_of_adults: adultCount,
      no_of_children: childCount,
    }
    if (auth) {
      showLoader()
      const res = await axiosCalls(`experience-cart`, 'post', data)
      if (res) {
        hideLoader()
        if (res.er) {
          if (res.er.status_code === '401') {
            Toast('error', 'Please login to continue.')
            setTimeout(() => {
              navigate('/login')
            }, 3000)
          } else {
            Toast('error', res.er.message)
          }
          return
        }
        Toast('success', 'Event added to cart successfully')
        navigate('/cart-page?tab=experience')
        fetchLocalExperienceCart()
      }
    } else {
      Toast('success', 'Event added to cart successfully')
      navigate('/cart-page?tab=experience')
    }
  }

  const image = [
    'https://media.tacdn.com/media/photo-m/1280/2d/5a/9e/b0/caption.jpg',
    'https://images.pexels.com/photos/3756686/pexels-photo-3756686.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/319973/pexels-photo-319973.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/2087391/pexels-photo-2087391.jpeg?auto=compress&cs=tinysrgb&w=800',
  ]
  const starLevels = [5, 4, 3, 2, 1]

  const usersImages = [
    'https://images.pexels.com/photos/413960/pexels-photo-413960.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1371360/pexels-photo-1371360.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/208701/pexels-photo-208701.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1024989/pexels-photo-1024989.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/1154638/pexels-photo-1154638.jpeg?auto=compress&cs=tinysrgb&w=800',
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return loading ? (
    <Loader align={'center'} />
  ) : (
    <div className='new-single'>
      <Navbar />
      <div className='new-single-center'>
        <InfoModal
          showModal={showLowestPriceGuarantee}
          closeModal={() => setShowLowestPriceGuarantee(false)}
        >
          <div className='info-wrap'>
            <h1>Lowest Price Guarantee</h1>
            <p className='desc'>
              We guarantee the lowest price on all 300,000+ experiences
              available to book on our site. If you find the same product
              cheaper online up until 48 hours after your trip starts we'll
              refund the difference.
            </p>
            <h4>It's Easy To Do</h4>
            <ol>
              <li className='flex'>
                <div className='circle'>1</div>
                <div>
                  <h5>Gather your information</h5>
                  <p>
                    Provide your Booking Number or Itinerary Reference Number
                    and the proof of the lower price you found .
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>2</div>
                <div>
                  <h5>Send it to us</h5>
                  <p>
                    Email{' '}
                    {/* <a href='' class=''>
                      priceguarantee@viator.com
                    </a>{' '} */}
                    with the documents, your name, and your home address.
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>3</div>
                <div>
                  <h5>We'll review</h5>
                  <p>
                    We'll examine your materials and get in touch as soon as we
                    can.
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>4</div>
                <div>
                  <h5>Receive your refund</h5>
                  <p>
                    If everything checks out, we'll refund you the difference
                    between the two prices.
                  </p>
                </div>
              </li>
            </ol>
            <div className='info-foot'>
              <p>
                <a
                  target='_blank'
                  href='/support/lowestPriceGuarantee'
                  class=''
                >
                  Refund Terms &amp; Conditions
                </a>
              </p>
            </div>
          </div>
        </InfoModal>
        <InfoModal
          showModal={showLowestPriceGuarantee}
          closeModal={() => setShowLowestPriceGuarantee(false)}
        >
          <div className='info-wrap'>
            <h1>Lowest Price Guarantee</h1>
            <p className='desc'>
              We guarantee the lowest price on all 300,000+ experiences
              available to book on our site. If you find the same product
              cheaper online up until 48 hours after your trip starts we'll
              refund the difference.
            </p>
            <h4>It's Easy To Do</h4>
            <ol>
              <li className='flex'>
                <div className='circle'>1</div>
                <div>
                  <h5>Gather your information</h5>
                  <p>
                    Provide your Booking Number or Itinerary Reference Number
                    and the proof of the lower price you found .
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>2</div>
                <div>
                  <h5>Send it to us</h5>
                  <p>
                    Email{' '}
                    {/* <a href='' class=''>
                      priceguarantee@viator.com
                    </a>{' '} */}
                    with the documents, your name, and your home address.
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>3</div>
                <div>
                  <h5>We'll review</h5>
                  <p>
                    We'll examine your materials and get in touch as soon as we
                    can.
                  </p>
                </div>
              </li>
              <li className='flex'>
                <div className='circle'>4</div>
                <div>
                  <h5>Receive your refund</h5>
                  <p>
                    If everything checks out, we'll refund you the difference
                    between the two prices.
                  </p>
                </div>
              </li>
            </ol>
            <div className='info-foot'>
              <p>
                <a
                  target='_blank'
                  href='/support/lowestPriceGuarantee'
                  class=''
                >
                  Refund Terms &amp; Conditions
                </a>
              </p>
            </div>
          </div>
        </InfoModal>
        <div className='wrap'>
          <div className='top flex justify-between'>
            <p className='direction'>
              Home / Things to do in Portugal / Things to do in Algarve /{' '}
              {event.name}
            </p>
            <div className='top-contacts'>
              <ul>
                <li className='flex items-center'>
                  <Icon icon={'ic:round-call'} />
                  <span>Book online or call: +1 (702) 648-5873</span>
                </li>
                <li className='flex items-center'>
                  <Icon icon={'bi:chat-text-fill'} />
                  <span>Chat now</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='title'>
            <h1>{event.name}</h1>
          </div>
          <div className=' rates flex'>
            <div className='one flex items-center'>
              <Ratings average_rating={event.average_rating} />
              <span className='review-count'>
                {event.reviews.length} Review
              </span>
            </div>
            {/* <div className='two flex'>
              <p>Vendor Name</p>
            </div> */}
            <div className='three'>
              <p>
                {event.city}, {event.country}
              </p>
            </div>
          </div>
          <div
            className='main-details flex justify-between'
            style={{ minHeight: '800px' }}
          >
            <div className='left'>
              <div className='flex justify-between flex-wrap'>
                <MainImages images={selectedImage} />
                <div className={'booking-card'}>
                  {totalPrice() > 1 ? (
                    <h2>
                      Total:- {getCurrencySignByShort(event.currency)}
                      {totalPrice()}
                    </h2>
                  ) : (
                    <h2>
                      From {getCurrencySignByShort(event.currency)}
                      {parseInt(event.adult_price) > parseInt(event.child_price)
                        ? parseInt(event.child_price)
                        : parseInt(event.adult_price)}
                      <span> per person</span>
                    </h2>
                  )}
                  <p
                    className='low'
                    onClick={() => setShowLowestPriceGuarantee(true)}
                  >
                    Lowest Price Guarantee
                  </p>
                  <h3>Select Date and Travelers</h3>
                  <div className='dateAnd'>
                    <div className='new-input '>
                      <div className=''>
                        <div className='date-input'>
                          <DatePicker
                            selected={date}
                            onChange={(date) => setDate(date)}
                            placeholderText='Enter Date'
                            minDate={new Date()}
                            dateFormat='yyyy/MM/dd'
                            showIcon
                            icon={<Icon icon={'octicon:calendar-16'} />}
                          />
                        </div>
                        <div
                          className='users flex items-center'
                          onClick={() => setShowUserSelect(!showUserSelect)}
                        >
                          <Icon icon='ph:user-bold' />
                          <p>
                            {adultCount + childCount < 1
                              ? 'Number of Visitors'
                              : adultCount > 0 && childCount > 0
                              ? `${adultCount} Adult${
                                  adultCount > 1 ? 's' : ''
                                } and ${childCount} Child${
                                  childCount > 1 ? 'ren' : ''
                                }`
                              : adultCount > 0
                              ? `${adultCount} Adult${
                                  adultCount > 1 ? 's' : ''
                                }`
                              : `${childCount} Child${
                                  childCount > 1 ? 'ren' : ''
                                }`}
                          </p>
                        </div>
                      </div>
                      {showUserSelect && (
                        <div className='user-select' ref={containerRef}>
                          <p className='select'>
                            You can select up to 9 travelers in total.
                          </p>
                          <div className='flex justify-between user-card'>
                            <div className='one'>
                              <h5>Adult (12-99)</h5>
                              <p>
                                {getCurrencySignByShort(event.currency)}
                                {parseInt(event.adult_price)}
                              </p>
                            </div>
                            <div className='two flex items-center'>
                              <Icon
                                icon='zondicons:minus-outline'
                                onClick={() =>
                                  adultCount > 0
                                    ? setAdultCount(adultCount - 1)
                                    : setAdultCount(0)
                                }
                              />
                              <span>{adultCount}</span>
                              <Icon
                                icon='zondicons:add-outline'
                                onClick={() => setAdultCount(adultCount + 1)}
                              />
                            </div>
                          </div>
                          <div className='flex justify-between  user-card'>
                            <div className='one'>
                              <h5>Child (0-11)</h5>
                              <p>
                                {getCurrencySignByShort(event.currency)}
                                {parseInt(event.child_price)}
                              </p>
                            </div>
                            <div className='two flex items-center'>
                              <Icon
                                icon='zondicons:minus-outline'
                                onClick={() =>
                                  childCount > 0
                                    ? setChildCount(childCount - 1)
                                    : setChildCount(0)
                                }
                              />
                              <span>{childCount}</span>
                              <Icon
                                icon='zondicons:add-outline'
                                onClick={() => setChildCount(childCount + 1)}
                              />
                            </div>
                          </div>
                          <button onClick={() => setShowUserSelect(false)}>
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='dateAnd'>
                    <div className='input-card'>
                      <button onClick={addToCart}>Add to cart</button>
                    </div>
                  </div>
                  <div className='others'>
                    <div className='flex '>
                      <div>
                        {' '}
                        <Icon icon='lets-icons:check-fill' />
                      </div>
                      <p>
                        <span>Free cancelation</span>
                        up to 24 hours before the experience starts (local time)
                      </p>
                    </div>
                    <div className='flex '>
                      <div>
                        {' '}
                        <Icon icon='lets-icons:check-fill' />
                      </div>
                      <p>
                        <span>Reserve Now and Pay Later</span> - Secure your
                        spot while staying flexible
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='rest-main'>
                <div className='rest-wrapper'>
                  <div className='flex duration'>
                    <div className='flex items-center'>
                      <Icon icon={'iconamoon:clock'} />
                      <span>{event.duration}</span>
                    </div>
                    <div className='flex items-center'>
                      <Icon icon={'uil:mobile-android-alt'} />
                      <span>Mobile ticket</span>
                    </div>
                    <div className='flex items-center'>
                      <Icon icon={'token:chat'} />
                      <span>Offered in: English, Spanish</span>
                    </div>
                  </div>
                  {/* /// */}
                  {/* ////// */}
                  <div className='overview'>
                    <h2>Overview</h2>
                    <p>{event.overview}</p>
                    {/* <ul>
                      <li className='flex items-center'>
                        <Icon icon={'radix-icons:dot-filled'} /> Head to a
                        beautiful lookout point for the sunset
                      </li>
                      <li className='flex items-center'>
                        <Icon icon={'radix-icons:dot-filled'} /> Tuck into a
                        delicious meal on the beach with drinks
                      </li>
                      <li className='flex items-center'>
                        <Icon icon={'radix-icons:dot-filled'} /> Discover the
                        beautiful landscapes of the west coast
                      </li>
                      <li className='flex items-center'>
                        <Icon icon={'radix-icons:dot-filled'} /> A small group
                        means more attention from your guide
                      </li>
                    </ul> */}
                  </div>
                  <div className='accordion'>
                    <div
                      className='accordion-head flex items-center justify-between'
                      onClick={() => setIncluded(!included)}
                    >
                      <h2>What's Included</h2>
                      {included ? (
                        <Icon icon={'ph:caret-up-bold'} />
                      ) : (
                        <Icon icon={'ph:caret-down-bold'} />
                      )}
                    </div>
                    {included && (
                      <div className='accordion-body flex justify-between'>
                        <ul className='include'>
                          {JSON.parse(event.what_included).map((item, i) => (
                            <li className='flex' key={i}>
                              <div>
                                {' '}
                                <Icon icon={'mingcute:check-fill'} />
                              </div>
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                        <ul>
                          {JSON.parse(event.whats_not_included).map(
                            (item, i) => (
                              <li className='flex' key={i}>
                                <div className='times'>
                                  {' '}
                                  <Icon icon={'uil:times'} />
                                </div>
                                <span>{item}</span>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='accordion'>
                    <div
                      className='accordion-head flex items-center justify-between'
                      onClick={() => setMeet(!meet)}
                    >
                      <h2>Meeting and Pickup</h2>
                      {meet ? (
                        <Icon icon={'ph:caret-up-bold'} />
                      ) : (
                        <Icon icon={'ph:caret-down-bold'} />
                      )}
                    </div>
                    {meet && (
                      <div className='accordion-body'>
                        <div className='flex meet-card'>
                          <div className='one'>
                            <div className='flex items-center'>
                              <Icon icon={'ion:location'} />
                              <h3>Meeting point</h3>
                            </div>
                            <p>{JSON.parse(event.meet_point).address}</p>
                            <a href={JSON.parse(event.meet_point).map_link}>
                              <p>Open in Google Maps</p>
                            </a>
                            <p className='more-meet'>
                              {JSON.parse(event.meet_point).extra}
                            </p>
                          </div>
                          <div className='two'>
                            <div className='flex items-center'>
                              <Icon icon={'hugeicons:racing-flag'} />
                              <h3>End point</h3>
                            </div>
                            {JSON.parse(event.meet_point).address ===
                            JSON.parse(event.end_point).address ? (
                              <p>
                                This activity ends back at the meeting point.
                              </p>
                            ) : (
                              <>
                                <div className='flex items-center'>
                                  <Icon icon={'ion:location'} />
                                  <h3>Meeting point</h3>
                                </div>
                                <p>{JSON.parse(event.end_point).address}</p>
                                <a href={JSON.parse(event.end_point).map_link}>
                                  <p>Open in Google Maps</p>
                                </a>
                                <p className='more-meet'>
                                  {JSON.parse(event.end_point).extra}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        {event.start_time && (
                          <div className='start-time flex items-center'>
                            <Icon icon={'clarity:clock-line'} />{' '}
                            <b>Start time</b>{' '}
                            <span>
                              {' '}
                              :- {convertTo12HourFormat(event.start_time)}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='accordion'>
                    <div
                      className='accordion-head flex items-center justify-between'
                      onClick={() => setExpect(!expect)}
                    >
                      <h2>What To Expect</h2>
                      {expect ? (
                        <Icon icon={'ph:caret-up-bold'} />
                      ) : (
                        <Icon icon={'ph:caret-down-bold'} />
                      )}
                    </div>
                    {expect && (
                      <div className='accordion-body'>
                        <p className='desc'>
                          {JSON.parse(event.what_to_expect)[0]}
                        </p>
                        <div className='timeline'>
                          {JSON.parse(event.what_to_expect_steps).map(
                            (item, i) => (
                              <TimelineItem
                                number={i + 1}
                                title={item.name}
                                description={item.description}
                                duration={item.time_taken}
                                admission={item.admission_ticket_free}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='accordion'>
                    <div
                      className='accordion-head flex items-center justify-between'
                      onClick={() => setAdditional(!additional)}
                    >
                      <h2>Additional Info</h2>
                      {additional ? (
                        <Icon icon={'ph:caret-up-bold'} />
                      ) : (
                        <Icon icon={'ph:caret-down-bold'} />
                      )}
                    </div>
                    {additional && (
                      <div className='accordion-body'>
                        <ul className=' addi flex flex-wrap justify-between'>
                          {JSON.parse(event.additional_info).map((item, i) => (
                            <li className='flex' key={i}>
                              <Icon icon={'icon-park-outline:dot'} />
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='policy flex justify-between'>
                    <div className='one'>
                      <h2>Cancellation Policy</h2>
                      <p>
                        You can cancel up to 24 hours in advance of the
                        experience for a full refund.
                      </p>
                    </div>
                    <div className='two'>
                      <h2>Questions?</h2>
                      <p>Visit the Help Centre for any further questions.</p>
                      <p>Product code: 120441P3</p>
                      <button>Bubble Help Center</button>
                    </div>
                  </div>
                  <div className='photos'>
                    <h2>Traveler Photos</h2>
                    <div className='grid-photos'>
                      {/* {usersImages.map((item, i) => (
                        <div key={i + 1} className='img-cont'>
                          <img
                            src={item}
                            alt=''
                            className={item === selectedImage ? 'active' : ''}
                          />
                        </div>
                        
                      ))} */}
                    </div>
                    <div className='gallery-container'>
                      {event.travelers_images &&
                        event.travelers_images.map((image, index) => (
                          <div
                            className={`gallery-item ${
                              index === 4 ? 'overlay-container' : ''
                            }`}
                            key={index}
                          >
                            <img src={image} alt={image} />
                            {index === 4 && (
                              <div className='overlay'>
                                <button className='see-more-button'>
                                  <span>See more</span>
                                  <div className='arrow'>&#8594;</div>
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <div className='reviews'>
                    <h2>Reviews</h2>
                    <div className='product-rating flex'>
                      <div className='one'>
                        <div className='flex items-center'>
                          <h1>5.0</h1> <Ratings average_rating={5} />
                        </div>
                        <p>233 reviews</p>
                      </div>
                      <div className='two'>
                        <p>
                          Total review count and overall rating based on Bubble
                          colony reviews
                        </p>
                        {starLevels.map((level) => (
                          <div key={level} className='star-level'>
                            <span className='star-label'>{level} stars</span>
                            <div className='bar-container'>
                              <div
                                className='bar'
                                style={{
                                  width: `${
                                    (reviews.starCount[level] /
                                      reviews.totalStars) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                            <span className='star-count'>
                              {reviews.starCount[level]}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='comments'>
                      <div className='comments-top'>
                        <h3>Reviews by Bubble travelers.</h3>
                        <p>Showing 1-10 of 223 reviews</p>
                      </div>
                      <div className='comments-wrap'>
                        <div className='comments-card'>
                          <div className='flex rates'>
                            <Ratings average_rating={3} />
                            <h4>Wonderful</h4>
                          </div>
                          <p className='date'>Jill_P, Jun 2024</p>
                          <p className='comments-word'>
                            Fabulous trip! I don't know how they spotted the
                            dolphins, but they did. Everything i hoped for.
                            Really friendly staff running the trip.
                          </p>
                          <div className='image-container flex'>
                            <div className='slider-container'>
                              {image.length > 3 ? (
                                <Slider {...settings}>
                                  {image.map((item, i) => (
                                    <div key={i} className='slider-card'>
                                      <div className='img'>
                                        <img src={item} alt='' />
                                      </div>
                                    </div>
                                  ))}
                                </Slider>
                              ) : (
                                <div className='flex imageX'>
                                  {image.map((item, i) => (
                                    <div className='none' key={i}>
                                      <img src={item} alt='' />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='comments-card'>
                          <div className='flex rates'>
                            <Ratings average_rating={3} />
                            <h4>
                              Best dolphin watching tour I have ever seen!
                            </h4>
                          </div>
                          <p className='date'>Anthony_N, Jun 2024</p>
                          <p className='comments-word'>
                            This was such a fun tour. There is something so
                            special about seeing dolphins in their natural
                            environment. Kit and John did a great job finding
                            the dolphins. The conditions were perfect and the
                            boat felt very safe. We are a family of 5 and we all
                            loved this tour. Highly recommend!!! Read more about
                            Dolphin Watching from Lagos
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='compare'>
                    <h2>Compare Similar Experiences</h2>
                    <div className='flex'>
                      <div className='left'>
                        <div className='single-card'>
                          <div className='card-top'>
                            <h3>CURRENT</h3>
                          </div>
                          <div className='card-rest'>
                            <img src={event.main_image} alt='' />
                            <h4>{event.name}</h4>
                            <div className='rows flex'>
                              <Ratings average_rating={event.average_rating} />{' '}
                              <span className='number'>
                                {event.reviews.length}
                              </span>
                            </div>
                            <div className='rows'>
                              <p>{event.duration}</p>
                            </div>
                            <div className='rows'>
                              <p>Free Cancellation</p>
                            </div>
                            <div className='rows'>
                              <h3>
                                From {getCurrencySignByShort(event.currency)}
                                {parseInt(event.adult_price) >
                                parseInt(event.child_price)
                                  ? parseInt(event.child_price)
                                  : parseInt(event.adult_price)}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='right'>
                        {similarLoading ? (
                          <Loader direction={'middle'} size={'small'} />
                        ) : (
                          <SliderComp
                            slides={SimilarExperiences(similarEvents)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className='compare'>
                    <h2>Customers Who Bought This Tour Also Bought</h2>
                    <AlsoBought />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default NewSingleEvent
