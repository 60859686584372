import React from "react";
import "./singleVendorLeft.scss";

const SingleVendorLeft = () => {
  return (
    <div className="singleVendor-bigFlexOne">
      <div className=" singleVendorLeft-StatusGen">
        <div className="singleVendorLeft-StatusText">Status:</div>
        <div className="singleVendorLeft-StatusCont"></div>
        <div className="singleVendorLeft-StatusClass">Active</div>
      </div>
      <div className=" singleVendorLeft-orderPlacedGenFam">
        <div className=" singleVendorLeft-orderPlacedGen">
          <div className="singleVendorLeft-orderPlacedText">
            Total Order Placed
          </div>
          <div className="singleVendorLeft-orderPlacedNo">1250</div>
        </div>

        <div className=" singleVendorLeft-orderPlacedGen">
          <div className="singleVendorLeft-orderPlacedText">
            Total Sale Made
          </div>
          <div className="singleVendorLeft-orderPlacedNo">₦2,340,000.00</div>
        </div>
      </div>
      <div className="singleVendorLeft-VendorGenFam">
        <div className="singleVendorLeft-VendorTag">Vendor Name</div>
        <div className="singleVendorLeft-CreatedTag">Date Created</div>
      </div>

      <div className="singleVendorLeft-VendorGenInfoFam">
        <div className="singleVendorLeft-VendorInfoTag">Summit Mobile</div>
        <div className="singleVendorLeft-CreatedInfoTag">24 February, 2021</div>
      </div>

      <div className="singleVendorLeft-VendorGenFam">
        <div className="singleVendorLeft-VendorTag">Contact Name</div>
        <div className="singleVendorLeft-CreatedTag">Phone Number</div>
      </div>

      <div className="singleVendorLeft-VendorGenInfoFam">
        <div className="singleVendorLeft-VendorInfoTag">Adebusayo Obisesan</div>
        <div className="singleVendorLeft-CreatedInfoTag">07045495450</div>
      </div>

      <div className="singleVendorLeft-EmailTag">Email address</div>
      <div className="singleVendorLeft-EmailAddress">
        michael.mitc@example.com
      </div>
      <div className="singleVendorLeft-StoreAdTag">Store Address</div>
      <div className="singleVendorLeft-StoreAdd">
        12 Quits Aviation Services Free Zone Murtala Muhammed International
        Airport{" "}
      </div>

      <div className="singleVendorLeft-StoreAdTag">Description</div>
      <div className="singleVendorLeft-StoreAdd">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus nulla
        fames cursus praesent habitant accumsan pretium cursus.
      </div>
    </div>
  );
};

export default SingleVendorLeft;
